import React from 'react';
import PropTypes from 'prop-types';
import { FilterPanel } from '@gsa/afp-component-library';
import { VMSOperations, VMSSubjects } from '../../../utilities/consts';
import AgencyFilterItem from './agency-filter-item';

const { FilterPanelFilterShape } = FilterPanel;

const LicensePlateAgencyFilterItem = ({ filter }) => {
  return (
    <AgencyFilterItem
      filter={filter}
      operation={VMSOperations.View}
      subject={VMSSubjects.TAG}
    />
  );
};

LicensePlateAgencyFilterItem.propTypes = {
  filter: PropTypes.shape(FilterPanelFilterShape).isRequired,
};

export default LicensePlateAgencyFilterItem;
