import React from 'react';

export default function FormHeading({ step }) {
  switch (step) {
    case 1:
      return (
        <>
          <h2 className="margin-bottom-1 text-bold">Vehicle information</h2>
          <div className="grid-row grid-gap">
            <div className="tablet:grid-col-8">
              <p className="afp-vehicle-registration__section_text">
                Please review the information below to confirm it is the correct
                information and vehicle. If you are manually entering a VIN for
                vehicle registration, please enter the entire VIN in the VIN
                field below. If the VIN is verified, the Make, Model and Year of
                the vehicle will be populated. Please review the information to
                ensure accuracy.
              </p>
            </div>
          </div>
        </>
      );
    case 2:
      return (
        <>
          <h2 className="margin-bottom-1 text-bold">Agency information</h2>
          <div className="grid-row grid-gap">
            <div className="tablet:grid-col-8">
              <p className="afp-vehicle-registration__section_text">
                Please review the agency information to ensure accuracy. You can
                enter "Office" information if desired.
              </p>
            </div>
          </div>
        </>
      );
    case 3:
      return (
        <>
          <h2 className="margin-bottom-1 text-bold">
            Vehicle and license plate information
          </h2>
          <div className="grid-row grid-gap">
            <div className="tablet:grid-col-8">
              <p className="afp-vehicle-registration__section_text">
                Please review the information below to confirm accuracy. You can
                edit any information if needed. If the information is correct,
                you can complete the registration by clicking the register
                button.
              </p>
            </div>
          </div>
        </>
      );
    default:
      return null;
  }
}
