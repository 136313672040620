import {
  Icon,
  Button,
  Alert,
  Spinner,
  ErrorMessage,
} from '@gsa/afp-component-library';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useUploadContext } from './upload-context';

const SELECT_FILE_MESSAGE = 'Please select a file';

const UploadForm = ({ requireFile }) => {
  const [fileErrorMessage, setFileErrorMessage] = useState();

  const {
    fileTypes,
    maxFileSize,
    uploadError,
    submitError,
    setFile,
    file,
    isUploading,
    setUploadError,
    setSubmitError,
  } = useUploadContext();

  const {
    register,
    formState: { errors },
    clearErrors,
  } = useForm();

  const fileInputRef = useRef();
  const { ref, ...rest } = register('file');

  useEffect(() => {
    if (errors?.file?.message) {
      setFileErrorMessage(errors?.file?.message);
    } else if (!file?.type && errors?.file?.type === 'required') {
      setFileErrorMessage(SELECT_FILE_MESSAGE);
    }
    fileInputRef.current.focus();
  }, [errors && errors.file]);

  const fileInputGroupStyles = classnames('usa-form-group', {
    [`usa-form-group--error`]: !!fileErrorMessage,
  });

  const handleFiles = (files) => {
    let newFiles = [];
    let uploadErrors = {};

    for (let i = 0; i < files.length; i++) {
      const selected = files[i];
      const extension = selected.name.substr(selected.name.lastIndexOf('.'));

      if (!fileTypes?.includes(extension)) {
        uploadErrors[file.length > 0 ? file.length + i : i] =
          'File format not supported';
        fileInputRef.current.focus();
        // return;
      }

      // Checks file size
      if (selected.size === 0 || selected.size > maxFileSize) {
        uploadErrors[file.length > 0 ? file.length + i : i] =
          'Size should be under 5 MB';

        if (selected.size === 0)
          uploadErrors[file.length > 0 ? file.length + i : i] =
            'Invalid file size';
        fileInputRef.current.focus();
        // return;
      }
      newFiles.push(selected);
    }
    const allFiles = [...file, ...newFiles];
    const allError = { ...uploadError, ...uploadErrors };
    setFile(allFiles);
    setUploadError(allError);
  };

  const deleteFile = (index) => {
    const newFiles = file.filter((item, i) => {
      return i !== index;
    });
    delete uploadError[index];
    for (const key in uploadError) {
      if (key > index) {
        uploadError[key - 1] = uploadError[key];
        delete uploadError[key];
      }
    }
    if (Object.keys(uploadError).length === 0) setSubmitError();
    setFile(newFiles);
    setUploadError(uploadError);
  };

  return (
    <form id="attachment-upload-form" data-testid="modal-attachment-form">
      {isUploading && <Spinner data-testid="adding-spinner" size="small" />}
      {submitError && <Alert type="error">{submitError}</Alert>}

      <div className={fileInputGroupStyles}>
        <label className="usa-label" htmlFor="attachment-file-input-single">
          Files <span style={{ color: '#E41D3D' }}>*</span>
        </label>
        <label
          className="usa-label helper-text"
          style={{ color: '#71767A', margin: 0 }}
        >
          Files must be: .DAT, .TXT
        </label>
        {fileErrorMessage && (
          <span className="usa-error-message">{fileErrorMessage}</span>
        )}

        <div className="usa-file-input">
          <div className="usa-file-input__target">
            <div className="usa-file-input__instructions" aria-hidden="true">
              <span className="usa-file-input__drag-text">
                Drag file here or&nbsp;
              </span>
              <span className="usa-file-input__choose">choose from folder</span>{' '}
              (maximum size: 5 MB)
            </div>

            <div className="usa-file-input__box" />
            <input
              // disabled={addUploadState?.adding}
              {...rest}
              name="file"
              multiple={true}
              ref={(e) => {
                ref(e);
                fileInputRef.current = e;
              }}
              id="attachment-file-input-single"
              data-testid="attachment-file-input"
              className="usa-file-input__input"
              type="file"
              aria-describedby="file-input-specific-hint"
              onChange={(e) => handleFiles(e.target.files)}
              accept={fileTypes}
            />
          </div>
        </div>

        {file.length > 0 && (
          <div
            className="usa-file-input"
            style={{
              border: '1px dashed #a9aeb1',
              borderTop: 'none',
            }}
          >
            {file.map((fil, i) => {
              return (
                <div
                  key={i}
                  className={`display-flex padding-x-1 padding-y-2 bg-primary-lighter justify-space-between${
                    i > 0 ? ' margin-top-2px' : ''
                  }`}
                >
                  <div className="flex-1 text-left padding-top-05">
                    <Icon
                      type="custom"
                      className="usa-icon--size-2 margin-right-1 file-icon ink text-ink"
                      iconName="document_file"
                    />
                    <span
                      className="font-body-2xs break-all"
                      data-testid="file-name"
                    >
                      {fil?.name}
                    </span>
                    {uploadError[i] && (
                      <div>
                        <ErrorMessage>{uploadError[i]}</ErrorMessage>
                      </div>
                    )}
                  </div>

                  <div className="flex-auto margin-left-5 text-right">
                    <Button
                      variant="unstyled"
                      className="action-btn"
                      label="Delete"
                      labelClass="font-body-xs"
                      leftIcon={{
                        name: 'delete',
                        iconName: 'delete',
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        deleteFile(i);
                      }}
                      data-testid="cancel-btn"
                      aria-label="remove file"
                    />
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>

      <Alert type="warning">
        Help prevent a privacy incident by ensuring that any supporting document
        uploaded here does not contain{' '}
        <a
          href="https://www.gsa.gov/reference/gsa-privacy-program/rules-and-policies-protecting-pii-privacy-act"
          target="_blank"
          rel="noreferrer"
        >
          personally identifiable information
        </a>{' '}
        (PII).
      </Alert>
    </form>
  );
};

export default UploadForm;

UploadForm.defaultProps = {
  requireFile: true,
};
UploadForm.propTypes = {
  requireFile: PropTypes.bool,
};
