import React, { useEffect, useReducer, useState } from 'react';
import usePortalModal from 'utilities/portal-modal';
import {
  Button,
  TextInput,
  RequiredFieldIndicator,
  Label,
} from '@gsa/afp-component-library';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

const EditWeightAndCapacity = ({ vehicle, onSave, onClose }) => {
  const formReducer = (state, newState) => {
    return {
      ...state,
      ...newState,
    };
  };

  const [errors, setErrors] = useState({});
  const [isErrors, setIsErrors] = useState(false);

  const [state, dispatch] = useReducer(formReducer, {
    gvwr: vehicle?.assetTechnical?.gvwr?.toString(),
    payload: vehicle?.assetTechnical?.payload,
    wheelBase: vehicle?.assetTechnical?.wheelBase,
    cabToAxle: vehicle?.assetTechnical?.cabToAxle,
    bumperToBack: vehicle?.assetTechnical?.bumperToBack,
  });

  const handleTextInputChange = (e, field) => {
    dispatch({
      [field]: e.target.value,
    });
  };

  const validate = (e, field) => {
    if (e.target.value <= 500 || e.target.value >= 100000) {
      setErrors({
        [field]: 'Must be between 500 and 100000',
      });
      setIsErrors(true);
    } else {
      setErrors({ ...errors, [field]: '' });
      setIsErrors(false);
    }
  };

  const [WeightAndCapacityEditModal, openModal, closeModal] = usePortalModal();
  useEffect(() => {
    openModal();
  }, []);

  const close = () => {
    // eslint-disable-next-line no-unused-expressions
    onClose && onClose();
    closeModal();
  };

  const getNumberFormat = ({
    handleChange,
    validate,
    value,
    label,
    id,
    maxValue,
    suffix,
  }) => {
    return (
      <>
        <Label>
          <span className="text-bold">{label}</span>
        </Label>
        {errors?.[id] && (
          <span className="usa-error-message">{errors?.[id]}</span>
        )}
        <div className="miles-input">
          <span className="prefix-icon-miles">{suffix ? suffix : ''}</span>
          <NumberFormat
            className={`usa-input`}
            id={`${id}`}
            data-testid={id}
            displayType="input"
            type="text"
            allowNegative={false}
            onChange={handleChange}
            value={value}
            onBlur={(e) => {
              if (validate) {
                validate(e, id);
              }
            }}
            isAllowed={(values) => {
              const { floatValue, value } = values;
              return floatValue <= maxValue || value === '';
            }}
          />
        </div>
      </>
    );
  };

  const saveWeightAndCapacity = (e) => {
    if (isErrors) {
      return;
    }
    closeModal();
    onSave(e);
  };

  return (
    <WeightAndCapacityEditModal
      actions={
        <>
          <Button
            data-testid="weight-and-capacity-close"
            variant="unstyled"
            label="Cancel"
            onClick={close}
          />
          <Button
            className="margin-left-2"
            onClick={() => saveWeightAndCapacity(state)}
            label="Save and close"
            data-testid="weight-and-capacity-save-and-close"
          />
        </>
      }
      title={<h2>Edit weight & capacity information</h2>}
      onClose={close}
    >
      <div className="grid-col-12 grid-gap">
        <p>
          Edit weight & capacity information for VIN{' '}
          <strong>{vehicle?.id}</strong> in the form below.
        </p>
        <p>
          Required fields are marked with an asterisk (
          <RequiredFieldIndicator />
          ).
        </p>
      </div>
      <div className="grid-row grid-gap">
        <div className="grid-col-6">
          {getNumberFormat({
            handleChange: (e) => {
              handleTextInputChange(e, 'gvwr');
            },
            validate: (e) => {
              validate(e, 'weight-and-capacity-gvwr');
            },
            value: state.gvwr,
            label: 'GVWR',
            id: 'weight-and-capacity-gvwr',
            maxValue: 100000,
            suffix: 'lbs',
          })}
        </div>
        <div className="grid-col-6">
          {getNumberFormat({
            handleChange: (e) => {
              handleTextInputChange(e, 'payload');
            },
            value: state.payload,
            label: 'Payload',
            id: 'weight-and-capacity-payload',
            maxValue: 999999,
            suffix: 'lbs',
          })}
        </div>
        <div className="grid-col-6">
          {getNumberFormat({
            handleChange: (e) => {
              handleTextInputChange(e, 'wheelBase');
            },
            value: state.wheelBase,
            label: 'Wheelbase',
            id: 'weight-and-capacity-wheelbase',
            maxValue: 9999,
            suffix: 'in',
          })}
        </div>
        <div className="grid-col-6">
          {getNumberFormat({
            handleChange: (e) => {
              handleTextInputChange(e, 'cabToAxle');
            },
            value: state.cabToAxle,
            label: 'Cab to axle',
            id: 'weight-and-capacity-cab-to-axle',
            maxValue: 999999,
            suffix: 'in',
          })}
        </div>
        <div className="grid-col-6">
          {getNumberFormat({
            handleChange: (e) => {
              handleTextInputChange(e, 'bumperToBack');
            },
            value: state.bumperToBack,
            label: 'Bumper to back of cab',
            id: 'weight-and-capacity-bumper-to-back',
            maxValue: 9999,
            suffix: 'in',
          })}
        </div>
      </div>
    </WeightAndCapacityEditModal>
  );
};

export default EditWeightAndCapacity;

EditWeightAndCapacity.propTypes = {
  vehicle: PropTypes.shape({
    id: PropTypes.string,
    assetTechnical: PropTypes.shape({
      assetTechnicalIce: PropTypes.shape({
        cylinders: PropTypes.string,
        transmission: PropTypes.string,
        tankSize: PropTypes.number,
        mpgCity: PropTypes.number,
        mpgHighway: PropTypes.number,
        mpgCombined: PropTypes.number,
        flexFuelMpgCity: PropTypes.number,
        flexFuelMpgHighway: PropTypes.number,
        flexFuelMpgCombined: PropTypes.number,
        gramPerMile: PropTypes.number,
        flexFuelGramPerMile: PropTypes.number,
      }),
    }),
    tagNumber: PropTypes.string,
    fuel: PropTypes.shape({
      description: PropTypes.string,
    }),
    modelYear: PropTypes.number,
    fuelCode: PropTypes.string,
    fastReportable: PropTypes.bool,
    makeColor: PropTypes.shape({
      name: PropTypes.string,
    }),
    vehicleModel: PropTypes.shape({
      modelDescription: PropTypes.string,
    }),
    vehicleMake: PropTypes.shape({
      makeName: PropTypes.string,
    }),
    tag: PropTypes.shape({
      expirationDate: PropTypes.number,
    }),
    inventoryStatus: PropTypes.shape({
      id: PropTypes.string,
      description: PropTypes.string,
    }),
  }),
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};
