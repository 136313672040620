import React from 'react';
import { Icon } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';
import { useLicensePlate } from '../context';

const SORT_ICON_MAP = {
  ASC: 'expand_less',
  DESC: 'expand_more',
};

const SORT_DIRECTION = {
  ASC: 'DESC',
  DESC: 'ASC',
};

const SortIndicator = ({ field, currentSort }) => {
  const [sortField, sortDirection] = currentSort[0];
  if (field === sortField) {
    return <Icon iconName={SORT_ICON_MAP[sortDirection]} />;
  }
  return <Icon iconName="unfold_more" />;
};

SortIndicator.propTypes = {
  field: PropTypes.string.isRequired,
  currentSort: PropTypes.array.isRequired,
};

export default function SortManager(label) {
  if (useLicensePlate()) {
    let { sortOrder, setSortOrder } = useLicensePlate();
    if (!sortOrder) {
      sortOrder = [[]];
    }
    const [sortField, sortDirection] = sortOrder[0];
    const updateSortOrder = (field) => {
      if (field === sortField) {
        const newSortDirection = SORT_DIRECTION[sortDirection];
        const newSortOrder = [...sortOrder];
        newSortOrder[0][1] = newSortDirection;
        setSortOrder(newSortOrder);
      } else {
        field[0] = field[0].replaceAll('`', '');
        setSortOrder([field]);
      }
    };

    const Indicator = () => {
      return <SortIndicator field={field} currentSort={sortOrder} />;
    };

    const ariaLabel = `sort by ${label} in ${SORT_DIRECTION[sortDirection]} order`;

    return [updateSortOrder, Indicator, ariaLabel];
  } else {
    return [null];
  }
}
