import React, { useEffect } from 'react';
import { Button, Modal, Spinner } from '@gsa/afp-component-library';
import { useOrder } from '../context';

export default function OrderTagReconModal() {
  const {
    isReconcileModalOpen,
    closeReconcileModal,
    tag,
    setTag,
    reconTags,
    reconciledTagsLoading,
  } = useOrder();

  useEffect(() => {
    if (!isReconcileModalOpen) {
      closeReconcileModal();
      setTag(null);
    }
  }, [isReconcileModalOpen]);

  if (!isReconcileModalOpen) {
    return null;
  }

  const reconcileTags = () => {
    if (tag) {
      const variables = {
        tagNumber: tag.tagNumber,
        tagExpirationDate: tag.tagExpirationDate,
      };
      reconTags({
        variables,
      });
    }
  };

  if (reconciledTagsLoading) return <Spinner className="padding-y-9" />;

  return (
    <div className="afp-modal-wrapper">
      <div className="afp-modal-overlay">
        <Modal
          variant="large"
          title={<h2>Update license plate to received</h2>}
          onClose={closeReconcileModal}
          actions={
            <>
              <Button
                variant="unstyled"
                onClick={() => {
                  closeReconcileModal();
                }}
                label="Cancel"
              />
              <Button
                className="margin-left-2"
                onClick={reconcileTags}
                disabled={reconciledTagsLoading}
                data-testid="reconcile-plate"
                label={`Update license plate to "received"`}
              />
            </>
          }
        >
          <div
            data-testid="reconcile-tag-modal-mody"
            className="padding-bottom-3 padding-top-1"
          >
            You&apos;re about to reconcile license plate{' '}
            <strong>{tag?.tagNumber}</strong>. the status of{' '}
            <strong>received</strong> will be applied to both A and B plates (if
            a 2-plate set).
          </div>
        </Modal>
      </div>
    </div>
  );
}
