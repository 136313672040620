/* eslint-disable filenames/match-exported */

export const pageId = 'expenseDisplay';

const pageConfigs = {
  expenseRecords: {
    default: { rows: [], count: 0 },
  },
  expenseTableData: {
    default: {},
  },
  dependantFunctions: {
    default: {},
  },
  vehicle: {
    default: {
      value: {},
    },
  },
  deleteExpenseModalInfo: {
    default: {
      id: null,
    },
  },
  pagination: {
    default: {
      currentPage: 1,
      itemsPerPage: 10,
    },
  },
  tableOrder: {
    default: {
      order: [['transactionDate', 'ASC']],
    },
  },
  dateFilter: {
    default: {
      filters: undefined,
    },
  },
};

const atomStateSchema = {
  pageConfigs,
};

export default atomStateSchema;
