export const fieldGetter = (fields) => {
  const formats = {
    exp: (f) => {
      if (!f) return '—';
      return [f.toString().slice(4), '/', f.toString().slice(0, 4)].join('');
    },
    phone: (f) => {
      if (!f) return '—';
      if (f.toString().length >= 10 && f.indexOf('-') === -1) {
        return [
          f.toString().slice(0, 3),
          '-',
          f.toString().slice(3, 6),
          '-',
          f.toString().slice(6),
        ].join('');
      } else {
        return f;
      }
    },
    ext: (f) => {
      if (f) return ` ext. ${f}`;
      return '';
    },
  };
  let output = '';
  fields.forEach((obj) => {
    if (!obj.formatter) {
      if (!obj.field) return '';
      output += output ? `, ${obj.field}` : obj.field;
    } else {
      output += formats[obj.formatter](obj.field);
    }
  });
  return output || '—';
};
