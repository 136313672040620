import PropTypes from 'prop-types';
import React, { createContext } from 'react';
import { EmptyState } from '@gsa/afp-component-library';
import './attachment.css';
import AttachmentAdd from './attachment-add';
import AttachmentLoadMore from './attachment-load-more';
import AttachmentTable from './attachment-table';
import ComponentContext from './component-context';

export const AttachmentContext = createContext();

const NoData = ({ title, message }) => {
  return (
    <div
      data-testid="attachment-no-data"
      className="attachment-no-data text-center padding-top-5 padding-bottom-1"
    >
      <EmptyState alt="Image not available" />
      <div className="attachment-no-data-title">{title}</div>
      <div className="attachment-no-data-message">{message}</div>
    </div>
  );
};

NoData.defaultProps = {
  title: <h3>No Supporting Documents Available</h3>,
  message: (
    <p>
      You don’t have any supporting documents at this time. Please check back
      again or start uploading documents. Input accepts a single file. Supported
      file formats are .jpg, .jpeg, .png, .bmp, .pdf, .doc, .docx, .rtf, .xls,
      .xlsx, .ppt, .pptx, .txt, .csv and file size should be under 5 MB.
    </p>
  ),
};

NoData.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.string,
  ]),
  message: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.string,
  ]),
};

export default function Attachment({
  noDataTitle,
  noDataMessage,
  allowChanges = true,
  ...props
}) {
  const { title } = props;
  const NoDataMessage = React.useMemo(
    () => () => <NoData title={noDataTitle} message={noDataMessage} />,
    [],
  );

  return (
    <ComponentContext {...props}>
      <>
        {title}

        <AttachmentTable noData={NoDataMessage} allowChanges={allowChanges} />

        <AttachmentLoadMore />

        {allowChanges ? <AttachmentAdd /> : null}
      </>
    </ComponentContext>
  );
}

const noop = () => {
  // noop
};

Attachment.defaultProps = {
  title: null,
  data: [],
  count: 0,
  hasMore: false,
  uploadPath: '',
  loading: false,
  adding: false,
  added: false,
  updating: false,
  updated: false,
  deleting: false,
  deleted: false,
  linkedEntities: {},
  loadMoreLimit: 5,
  currentUser: { email: '' },
  errors: {
    fetch: { message: '' },
    save: { message: '' },
    edit: { message: '' },
    delete: { message: '' },
    download: { message: '' },
  },
  fileTypes: '.jpg,.jpeg,.png,.bmp,.pdf,.doc,.docx,.xls,.xlsx,.txt,.csv',
  maxFileSize: 5242880, // 5MB
  noDataTitle: '',
  noDataMessage: <p>There are not yet any documents.</p>,
  setErrors: noop,
  onSort: noop,
  onDelete: noop,
  onDownload: noop,
  onLoadMore: noop,
  onUpdateDescription: noop,
  onAdd: noop,
  allowChanges: true,
};

Attachment.propTypes = {
  title: PropTypes.node,
  data: PropTypes.arrayOf(Object),
  count: PropTypes.number,
  hasMore: PropTypes.bool,
  uploadPath: PropTypes.node,
  loading: PropTypes.bool,
  adding: PropTypes.bool,
  added: PropTypes.bool,
  updating: PropTypes.bool,
  updated: PropTypes.bool,
  deleting: PropTypes.bool,
  deleted: PropTypes.bool,
  linkedEntities: PropTypes.shape(Object),
  loadMoreLimit: PropTypes.number,
  paginationLimit: PropTypes.number.isRequired,
  currentUser: PropTypes.shape({
    email: PropTypes.string,
  }),
  errors: PropTypes.shape({
    fetch: PropTypes.shape(Object),
    save: PropTypes.shape(Object),
    update: PropTypes.shape(Object),
    delete: PropTypes.shape(Object),
    download: PropTypes.shape(Object),
  }),
  noDataTitle: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.string,
  ]),
  noDataMessage: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.string,
  ]),
  fileTypes: PropTypes.string,
  maxFileSize: PropTypes.number,
  setErrors: PropTypes.func,
  onSort: PropTypes.func,
  onDelete: PropTypes.func,
  onDownload: PropTypes.func,
  onLoadMore: PropTypes.func,
  onUpdateDescription: PropTypes.func,
  onAdd: PropTypes.func,
  allowChanges: PropTypes.bool,
};
