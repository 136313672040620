/* eslint-disable react/prop-types */
/* eslint-disable filenames/match-exported */
import React, { useEffect } from 'react';
import { Button } from '@gsa/afp-component-library';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useLocation } from 'react-router-dom';
import DeleteModalComponent from 'utilities/delete-modal';
import usePortalModal from 'utilities/portal-modal';
import { changeNumberFormat, currencyFormat } from 'utilities/format';
import { LoadingQueryResult } from 'components/loading-query-result/loading-query-result';
import {
  pageConfigUpdateSelector,
  handleActionSelector,
  createPageSetupSelector,
  paginationSelector,
  tableOrderSelector,
  dateFilterSelector,
} from './model/store/selectors';
import ExpenseDisplayTable from './components/expense-display-table';
import atomStateSchema, { pageId } from './model/schema/atom-state-schema';
import * as atoms from './model/store/atoms';
import { useVehicle } from '../../../../vehicle-context-provider';
import useDateRangeFilter from '../../../hooks/use-date-range-filter';

const ExpenseDisplay = ({ dependencies, loading }) => {
  const { vehicle, setPageMsg } = useVehicle();
  const location = useLocation();

  const [DeleteModal, openDeleteExpenseModal, closeDeleteExpenseModal] =
    usePortalModal();

  const expenseRecordsState = useRecoilValue(
    pageConfigUpdateSelector({ id: 'expenseRecords' }),
  );
  const deleteExpenseModalInfoState = useRecoilValue(
    pageConfigUpdateSelector({ id: 'deleteExpenseModalInfo' }),
  );

  const [paginationState, setPaginationState] = useRecoilState(
    paginationSelector('pagination'),
  );

  const [tableOrderState, setTableOrderState] = useRecoilState(
    tableOrderSelector('tableOrder'),
  );

  const setDateFiltersState = useSetRecoilState(
    dateFilterSelector('dateFilter'),
  );

  const setupPageState = useSetRecoilState(createPageSetupSelector(pageId));

  const handleAction = useSetRecoilState(handleActionSelector());

  useEffect(() => {
    const { message } = location?.state ?? {};
    setupPageState({
      pageConfigs: {
        ...atomStateSchema.pageConfigs,
        vehicle: { default: vehicle },
        dependantFunctions: {
          default: {
            ...dependencies,
            setPageMsg,
            openDeleteExpenseModal,
            closeDeleteExpenseModal,
          },
        },
      },
      atoms,
    });
    if (message)
      setPageMsg({
        type: message.type,
        message: message.text,
      });
  }, []);

  const { getDateRangeComponent, fromDate, toDate, touched } =
    useDateRangeFilter({
      classes: 'grid-row grid-gap flex-1',
      dateFormat: 'YYYY-MM-DD',
    });

  useEffect(() => {
    if (!fromDate || !toDate) {
      setDateFiltersState({ filters: undefined });
    } else if (touched) {
      setDateFiltersState({
        filters: {
          operator: '$and',
          conditions: [
            { operator: '$exact', key: 'asset_id', value: vehicle.uuid },
            {
              operator: '$between',
              key: 'transaction_date',
              value: [fromDate, toDate],
            },
          ],
        },
      });
    }
  }, [fromDate, toDate]);

  return (
    <article>
      <LoadingQueryResult loadingObject={loading}>
        {' '}
        <ul className="usa-card-group">
          <li className="usa-card tablet:grid-col-5">
            <div
              style={{ background: '#EFF6FB' }}
              className="usa-card__container"
            >
              <div className="usa-card__header">
                <h4>Total expense</h4>
              </div>
              <div className="usa-card__body">
                <h2 style={{ fontWeight: 'normal' }}>
                  {changeNumberFormat(
                    expenseRecordsState.totalExpense,
                    currencyFormat,
                  )}
                </h2>
              </div>
            </div>
          </li>
        </ul>
      </LoadingQueryResult>

      <div className="grid-row grid-gap flex-justify">
        <div>
          <span className="text-bold">Transaction date</span>
          {getDateRangeComponent()}
        </div>
        <div className="tablet:grid-col flex-2 flex-align-self-end margin-top-3 tablet:margin-top-0">
          <div className="float-right">
            <Button
              label="Add transaction"
              leftIcon={{
                name: 'add',
              }}
              onClick={() => {
                handleAction({ label: 'Add' });
              }}
            />
          </div>
        </div>
      </div>

      <LoadingQueryResult loadingObject={loading}>
        <div className="padding-top-2">
          <ExpenseDisplayTable
            paginationState={paginationState}
            setPaginationState={setPaginationState}
            tableOrderState={tableOrderState}
            setTableOrderState={setTableOrderState}
            expenseRecordsState={expenseRecordsState}
            NoDataMessages={{
              text: 'No expense records at this time',
              ExtraNoDataContent: () => {
                return (
                  <Button
                    label="Add transaction"
                    leftIcon={{
                      name: 'add',
                    }}
                    onClick={() => {
                      handleAction({ label: 'Add' });
                    }}
                  />
                );
              },
            }}
          />
        </div>
        <DeleteModalComponent
          closeModal={closeDeleteExpenseModal}
          saveFn={() => {
            const variables = { id: deleteExpenseModalInfoState?.id };
            dependencies.mutation.deleteExpense({ variables });
          }}
          subtitle="Are you sure you want to delete this transaction?"
          Modal={DeleteModal}
        />
      </LoadingQueryResult>
    </article>
  );
};

export default ExpenseDisplay;
