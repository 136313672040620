/* eslint-disable filenames/match-regex */
import { gql } from '@apollo/client';

export const UPDATE_FLEET_CARD_TRANSACTION = gql`
  mutation updateFleetCardTransaction(
    $fcTransaction: FCTransactionInputUpdate!
    $fcMerchant: FCMerchantInput!
  ) {
    updateFleetCardTransaction(
      fcTransaction: $fcTransaction
      fcMerchant: $fcMerchant
    )
  }
`;
