import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useLazyQuery } from '@apollo/client';
import {
  GET_AGENCIES_BY_PERMISSION,
  GET_BUREAUS_BY_PERMISSION,
  GET_OFFICES_BY_PERMISSION,
  GET_TAGS_BY_PARTIAL_TAG_NUMBER,
  GET_VEHICLES_BY_PARTIAL_VIN,
} from '../../../services/data-layer';
import {
  BureauFilterItem,
  OfficeFilterItem,
  ExpenseDateRangeFilter,
  CCN4Filter,
} from '../widgets';
import { VMSOperations, VMSSubjects } from '../../../utilities/consts';

const ExpenseFilterContext = createContext({});
const useExpenseFilter = () => useContext(ExpenseFilterContext);
const feature = 'Basic';

const filterStructure = (agencyCode, options) => [
  {
    title: 'Agency',
    expanded: true,
    key: 'organization',
    requiredAsterisk: true,
    filters: [
      {
        title: 'Agency',
        key: '$vehicle.agency_code$',
        id: 'filter-vehicle-agency-code',
        type: 'select',
        hideClear: true,
        operator: '$exact',
        options: [{ value: '', label: '-Select agency-' }, ...options],
        value: agencyCode,
      },
      {
        title: 'Bureau',
        key: '$vehicle.bureau_code$',
        id: 'filter-vehicle-bureau-code',
        component: BureauFilterItem,
        permanent: false,
        operator: '$exact',
        hideClear: true,
      },
      {
        title: 'Office',
        key: '$vehicle.office_code$',
        id: 'filter-vehicle-office-code',
        component: OfficeFilterItem,
        permanent: false,
        operator: '$exact',
        hideClear: true,
      },
    ],
  },
  {
    title: 'Transaction date',
    key: 'transaction_date',
    id: 'filter-expense_date',
    component: ExpenseDateRangeFilter,
    requiredAsterisk: true,
    hideClear: true,
    operator: '$between',
    value: [],
    defaultValue: {
      from: moment().subtract(1, 'month'),
      to: moment(),
    },
  },
  {
    title: 'VIN',
    ariaLabel: 'Search by VIN',
    id: 'filter-vehicle-vin',
    key: '$vehicle.serial_number_vin$',
    type: 'text',
    permanent: false,
    operator: '$startsWith',
    hideClear: false,
    position: 'top',
    label: 'Search by VIN',
    showSearchButton: true,
    minSearchLength: 6,
  },
  {
    title: 'License plate',
    ariaLabel: 'Search by license plate',
    key: '$vehicle.tag_number$',
    placeholder: 'Search by license plate',
    id: 'filter-license-plate',
    type: 'typeahead',
    operator: '$startsWith',
    value: [],
    customFieldProps: {
      inputCharNum: 3,
      debounceDelay: 500,
      promptText: 'Search requires 3 characters',
      showNoResults: false,
      clearPanelFilterOnEmpty: true,
    },
    hideClear: true,
  },
  {
    title: 'Last 4 digits of card number',
    ariaLabel: 'Search by credit card number',
    placeholder: 'Search by credit card number',
    key: 'ccn4',
    id: 'expenses-ccn4-filter',
    operator: '$exact',
    component: CCN4Filter,
  },
];

const initialState = {
  error: {},
  agencies: undefined,
  bureaus: [],
  offices: [],
  filters: [],
  filterStructure: undefined,
  vehiclesByPartialVin: [],
  tagsByPartialTagNumber: [],
  ccn4: [],
  typeaheadData: { field: '', values: [] },
};

const actions = {
  setError: 'SET_ERROR',
  setStructure: 'SET_STRUCTURE',
  setFilters: 'SET_FILTERS',
  setAgencies: 'SET_AGENCIES',
  setBureaus: 'SET_BUREAUS',
  setOffices: 'SET_OFFICES',
  setScope: 'SET_SCOPE',
  setTypeaheadData: 'SET_TYPEAHEAD_DATA',
  setVehiclesByPartialVin: 'SET_VEHICLES_BY_PARTIAL_VIN',
  setTagsByPartialTagNumber: 'SET_VEHICLES_BY_PARTIAL_TAG_NUMBER',
  setCCN4: 'SET_CCN4',
};

const extractErrorName = (err) => err.name || 'Unknown Error';
const mapTypeaheadData = (field, values) => {
  return {
    field,
    values,
  };
};
const expenseFilterReducer = (state, { action, payload }) => {
  const mergeState = (value, field) => {
    if (!field) {
      return { ...state, error: initialState.error, ...value };
    }
    const merged = { ...state, error: initialState.error };
    merged[field] = value || initialState[field];
    return merged;
  };
  switch (action) {
    case actions.setAgencies: {
      return mergeState(payload, 'agencies');
    }
    case actions.setBureaus: {
      return mergeState(payload, 'bureaus');
    }
    case actions.setOffices: {
      return mergeState(payload, 'offices');
    }
    case actions.setVehiclesByPartialVin: {
      return mergeState({
        vehiclesByPartialVin: payload,
        typeaheadData: mapTypeaheadData(
          '$vehicle.serial_number_vin$',
          payload.map((c) => c.id),
        ),
      });
    }
    case actions.setTagsByPartialTagNumber: {
      return mergeState({
        tagsByPartialTagNumber: payload,
        typeaheadData: mapTypeaheadData('$vehicle.tag_number$', [
          ...new Set(payload.map((c) => c.id)),
        ]),
      });
    }
    case actions.setCCN4: {
      return mergeState({
        tagsByPartialTagNumber: payload,
        typeaheadData: mapTypeaheadData('$vehicle.ccn4$', [
          ...new Set(payload.map((c) => c.id)),
        ]),
      });
    }
    case actions.setStructure: {
      return mergeState(payload, 'filterStructure');
    }
    case actions.setScope: {
      return mergeState(payload);
    }
    case actions.setError: {
      return mergeState(extractErrorName(payload), 'error');
    }
    case actions.setFilters: {
      return mergeState(
        {
          operator: '$and',
          conditions: payload || [],
        },
        'filters',
      );
    }
    default:
      throw new Error('Invalid user filter action');
  }
};

const mapOptions = (data, map, sort) => {
  if (!data) {
    return [];
  }

  const fn =
    map ||
    ((c) => ({
      value: c.id,
      label: c.name,
    }));
  const options = data.map(fn);
  if (sort) {
    options.sort(sort);
  }
  return options;
};

const ExpenseFilterProvider = ({ children, structure }) => {
  const [state, setDispatch] = useReducer(
    expenseFilterReducer,
    initialState,
    () => initialState,
  );
  const dispatch = (action, payload) => setDispatch({ action, payload });

  const dispatchError = (error) => dispatch(actions.setError, error);
  const dispatchFilters = (conditions) =>
    dispatch(actions.setFilters, conditions);

  const getFilterStructure = (agency, options) => {
    if (structure) {
      return structure(agency, options);
    }
    return filterStructure(agency, options);
  };

  const [agenciesQuery] = useLazyQuery(GET_AGENCIES_BY_PERMISSION, {
    onError: dispatchError,
    onCompleted: (data) => {
      const agencies = data?.getAgenciesByPermission || [];
      const options = mapOptions(agencies, (c) => ({
        value: c.id,
        label: `${c.id} - ${c.name}`,
      }));
      const agencyCode = agencies.length === 1 ? agencies[0].id : '';
      dispatch(actions.setStructure, getFilterStructure(agencyCode, options));
      dispatch(actions.setAgencies, agencies);
    },
  });

  const [bureausQuery] = useLazyQuery(GET_BUREAUS_BY_PERMISSION, {
    fetchPolicy: 'cache-and-network',
    onError: dispatchError,
    onCompleted: (data) => {
      const bureaus = mapOptions(data?.getBureausByPermission || [], (c) => ({
        value: c.id,
        label: `${c.id} - ${c.name}`,
      }));
      const { offices } = initialState;
      dispatch(actions.setScope, { bureaus, offices });
    },
  });

  const [officesQuery] = useLazyQuery(GET_OFFICES_BY_PERMISSION, {
    fetchPolicy: 'cache-and-network',
    onError: dispatchError,
    onCompleted: (data) => {
      const offices = mapOptions(data?.getOfficesByPermission || [], (c) => ({
        value: c.officeCode,
        label: `${c.officeCode} - ${c.officeName}`,
      }));
      dispatch(actions.setOffices, offices);
    },
  });

  const [vehiclesByPartialVinQuery] = useLazyQuery(
    GET_VEHICLES_BY_PARTIAL_VIN,
    {
      onError: dispatchError,
      onCompleted: (data) => {
        dispatch(
          actions.setVehiclesByPartialVin,
          data?.getVehiclesByPartialVin || [],
        );
      },
    },
  );
  const [tagsByPartialTagNumberQuery] = useLazyQuery(
    GET_TAGS_BY_PARTIAL_TAG_NUMBER,
    {
      fetchPolicy: 'no-cache',
      onError: dispatchError,
      onCompleted: (data) => {
        dispatch(
          actions.setTagsByPartialTagNumber,
          data?.getTagsByPartialTagNumber || [],
        );
      },
    },
  );

  const getAgencies = () => {
    const operation = VMSOperations.View;
    const subject = VMSSubjects.VEHICLE;
    agenciesQuery({
      variables: {
        operation,
        subject,
        order: [['agencycode', 'ASC']],
        feature,
      },
    });
  };

  const getBureaus = (agencyCode) => {
    const operation = VMSOperations.View;
    const subject = VMSSubjects.VEHICLE;
    bureausQuery({
      variables: {
        agencyCode,
        operation,
        subject,
        order: [['bureaucode', 'ASC']],
        feature,
      },
    });
  };

  const getOffices = ({ agencyCode, bureauCode }) => {
    const operation = VMSOperations.View;
    const subject = VMSSubjects.VEHICLE;
    officesQuery({
      variables: {
        agencyCode,
        bureauCode,
        operation,
        subject,
        order: [['officecode', 'ASC']],
        feature,
      },
    });
  };

  const getVehiclesByPartialVin = (partialVin) => {
    vehiclesByPartialVinQuery({
      variables: { partialVin },
    });
  };

  const getTagsByPartialTagNumber = (partialTagNumber) => {
    tagsByPartialTagNumberQuery({
      variables: { partialTagNumber },
    });
  };

  return (
    <ExpenseFilterContext.Provider
      value={{
        ...state,
        setFilters: dispatchFilters,
        getAgencies,
        getBureaus,
        getOffices,
        getVehiclesByPartialVin,
        getTagsByPartialTagNumber,
      }}
    >
      {children}
    </ExpenseFilterContext.Provider>
  );
};

ExpenseFilterProvider.defaultProps = {
  structure: undefined,
};

ExpenseFilterProvider.propTypes = {
  structure: PropTypes.func,
};

export { ExpenseFilterProvider as default, useExpenseFilter };
