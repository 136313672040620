import React, { useState } from 'react';
import usePortalModal from '../../../utilities/portal-modal';
import UnverifiedVinModal from '../widgets/unverified-vin-modal';

export default function userVerifyModal({
  formState,
  registrationType,
  getPurchaseOrderNumber,
  isRequirePurchaseOrderNumber,
}) {
  const [PortalModal, openModal, closeModal, modalState] = usePortalModal();
  const [vinVerification, setVinVerification] = useState(null);

  const selectOptionAndClose = (option) => {
    if (option) {
      setVinVerification(option);
      closeModal();
      isRequirePurchaseOrderNumber &&
        getPurchaseOrderNumber({
          variables: {
            vin: formState?.vin,
          },
        });
    }
  };

  const title = formState.nhtsaVerified
    ? 'This VIN has been validated by NHTSA'
    : 'This VIN cannot be validated by NHTSA';

  const Modal = () => (
    <PortalModal
      title={<h2>{title}</h2>}
      hideClose={registrationType !== 'manual' || formState.nhtsaVerified}
    >
      <UnverifiedVinModal
        vin={formState.vin}
        nhtsaVerified={formState.nhtsaVerified}
        onGoBack={closeModal}
        setOption={selectOptionAndClose}
        registrationType={registrationType}
        getPurchaseOrderNumber={getPurchaseOrderNumber}
      />
    </PortalModal>
  );

  return [Modal, openModal, modalState, vinVerification, setVinVerification];
}
