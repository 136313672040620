import React from 'react';
import { itemInventoryStatuses } from '../../../../utilities/consts';

export const EN_DASH = '—';

export const REPAIR_STATUS = {
  DONE: 'Yes',
  PROG: 'No',
};
export const STATUS_BADGE_VAR = {
  [REPAIR_STATUS.DONE]: { variant: 'Ready-Gray', label: 'Done' },
  [REPAIR_STATUS.PROG]: {
    variant: 'Warning-Gray',
    label: <>In&nbsp;progress</>,
  },
};

export const ORDER_TAB_INDEX = '1';
export const SERVICE_TAB_INDEX = '2';

export const numberToStr = (num) => {
  if (typeof num !== 'number') return EN_DASH;
  return num.toLocaleString();
};
export const dollarToStr = (dollar) => {
  if (typeof dollar !== 'number') return EN_DASH;
  return `$${dollar.toLocaleString(undefined, { minimumFractionDigits: 2 })}`;
};

export const utcDateStrToUS = (str) => {
  if (!str) return EN_DASH;
  return `${str.substr(5, 2)}/${str.substr(8, 2)}/${str.substr(0, 4)}`;
};

export const getLineDesc = (serviceCode) => {
  if (!serviceCode?.componentOne) return EN_DASH;
  const chainFields = (list) => {
    if (list.length === 0 || !serviceCode[list[0]]) return '';
    return ` > ${serviceCode[list[0]]}${chainFields(list.slice(1))}`;
  };
  const fields = [
    'componentTwo',
    'componentThree',
    'componentFour',
    'componentFive',
  ];
  return `${serviceCode.componentOne}${chainFields(fields)}`;
};

export const getLineCost = (serviceLineCosts) => {
  const cost = serviceLineCosts.find((c) => c.type === 'Actual');
  return dollarToStr(cost?.totalAmount);
};

export const sortToOrder = (sortBy) => {
  const [sortCol, sortOrder] = sortBy.split(' ');
  return [...sortCol.replace(/`/g, '').split('.'), sortOrder];
};

function getVehicleStatus(statusCode) {
  const statusObj = itemInventoryStatuses[statusCode];
  return statusObj?.altStatus || statusObj?.displayStatus || 'N/A';
}
export const isRepairEditable = (vehicle) => {
  const { agencyCode, ownershipTypeCode, itemInventoryStatusCode } = vehicle;
  const status = getVehicleStatus(itemInventoryStatusCode);
  const inactiveList = ['Missing/Stolen', 'Sold'];
  return (
    agencyCode && // has agency
    ownershipTypeCode === 'AO' && // agency owned
    !inactiveList.includes(status) // active vehicle
  );
};
