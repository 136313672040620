/* eslint-disable react/prop-types */
/* eslint-disable filenames/match-exported */
import { useMutation } from '@apollo/client';
import { EXPORT_REPORT } from 'services/data-layer';
import exportMessage from 'utilities/export-message';
import { REPORT_DELIVERY_METHOD, REPORT_TYPE } from 'utilities/consts';
import { useMileagePage, useMileageFilter } from './providers';

const useDataController = () => {
  const { setBannerMsg, mileageCount } = useMileagePage();
  const { filters } = useMileageFilter();
  const [exportData] = useMutation(EXPORT_REPORT, {
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      setBannerMsg(exportMessage.exportSuccessMessage);
    },
    onError: () => {
      setBannerMsg(exportMessage.exportMsgError);
    },
  });

  const onExport = () => {
    setBannerMsg(null);
    if (mileageCount === 0) {
      setBannerMsg(exportMessage.exportMsgNoData('mileage'));
      return;
    }
    if (mileageCount > 950000) {
      setBannerMsg(exportMessage.exportMsgTooMany);
    } else {
      exportData({
        variables: {
          request: {
            reportType: REPORT_TYPE.MILEAGE_DATA_EXPORT,
            deliveryMethod: REPORT_DELIVERY_METHOD.EMAIL,
            reportCriteria: {
              filters,
            },
          },
        },
      });
    }
  };

  return {
    onExport,
  };
};

export default useDataController;
