import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { Spinner, FilterPanel, Typeahead } from '@gsa/afp-component-library';
import { useCustomerAccountFilter } from '../providers/filter-provider';
import { GET_BOACS_BY_PARTIAL } from '../../../services/data-layer/fed-hierarchy.gql';

const { FilterPanelFilterShape, useFilterPanel } = FilterPanel;

const BoacFilterItem = ({ filter }) => {
  const [tpState, setTpState] = useState([]);
  const { setFilters, filters } = useCustomerAccountFilter();
  const [tpFilterValue, setTpFilterValue] = useState('');

  const {
    getFilterFromState,
    mergedFilters,
    clearOneFilter,
    setFilters: setAllFilters,
  } = useFilterPanel();

  const [getBoacs, { data: boacOptions }] = useLazyQuery(GET_BOACS_BY_PARTIAL, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (boacOptions) {
      setTpState(
        new Set(boacOptions?.getBoacsByPartialBoac?.map(({ boac }) => boac)),
      );
    }
  }, [boacOptions]);

  const onClear = () => {
    setFilters([
      ...(Array.isArray(filters?.value)
        ? filters.value.filter((item) => item.key !== filter.key)
        : []),
    ]);
    setAllFilters({
      type: 'setOne',
      fetchNewData: true,
      filter: { ...filter, value: '' },
    });
  };

  return (
    <div data-testid="boac-filter-typeahead">
      <Typeahead
        name="boac-filter-field"
        {...filter}
        label={null}
        filterValue={tpFilterValue}
        className="margin-top-neg-2"
        onOptionEnter={(value) => {
          setTpFilterValue(value);
          setFilters([
            ...filters.conditions,
            {
              operator: filter.operator,
              key: filter.key,
              value,
            },
          ]);
          setAllFilters({
            type: 'setOne',
            fetchNewData: true,
            filter: { ...filter, value },
          });
        }}
        typeaheadValues={tpState}
        fetchTypeaheadValues={(accessor, value) => {
          getBoacs({ variables: { partialBoac: value } });
        }}
        onClear={onClear}
      />
    </div>
  );
};

BoacFilterItem.propTypes = {
  filter: PropTypes.shape(FilterPanelFilterShape).isRequired,
};

export default BoacFilterItem;
