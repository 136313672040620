/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Button, Spinner, Modal, useModal } from '@gsa/afp-component-library';
import { useVehicle } from 'components/vehicle-details/vehicle-context-provider';
import TerminateVehicleForm from './terminate-vehicle-form';

const TerminateVehicle = ({
  terminateVehicleError,
  setModalError,
  vehicle,
  onSave,
  onClose,
  terminateVehicleLoading,
}) => {
  const { showAlert } = useVehicle();
  const [terminationData, setTerminationData] = useState({
    assetId: vehicle?.uuid,
  });
  const { openModal, closeModal } = useModal();

  useEffect(() => {
    openModal();
  }, []);

  const submit = () => {
    const toSave = {
      ...terminationData,
      endOdometer: +terminationData.endOdometer,
    };
    onSave && onSave(toSave);
  };

  const close = () => {
    onClose && onClose();
    closeModal();
  };
  const ModalHeader = () => {
    return (
      <>
        <div>
          <h1>Terminate {vehicle?.tagNumber} from customer account</h1>
          <div className="font-size-3">VIN {vehicle?.id}</div>
        </div>
      </>
    );
  };
  return (
    <div className="afp-modal-wrapper">
      <div className="afp-modal-overlay">
        <Modal
          title={<ModalHeader />}
          variant="large"
          actions={
            <>
              <Button
                variant="unstyled"
                id="cancelTerminate"
                data-testId="cancelTerminate"
                label="Cancel"
                onClick={close}
              />

              <Button
                className="margin-left-2"
                type="submit"
                label="Submit"
                form="terminate-vehicle-form"
                disabled={terminateVehicleLoading}
              />
              {terminateVehicleLoading && (
                <Spinner
                  size="small"
                  aria-busy="true"
                  className="display-inline-block margin-left-2"
                />
              )}
            </>
          }
          onClose={close}
        >
          {terminateVehicleError?.context === 'terminateVehicle' &&
            showAlert(terminateVehicleError, setModalError)}
          Edit details for VIN <b>{vehicle?.id}</b> in the form below.
          <br />
          <i>Required fields are marked with an asterisk (*).</i>
          <br />
          <br />
          <TerminateVehicleForm
            setModalError={setModalError}
            onSubmit={submit}
            terminationData={terminationData}
            setTerminationData={setTerminationData}
          />
        </Modal>
      </div>
    </div>
  );
};

export default TerminateVehicle;
