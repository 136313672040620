/* eslint-disable no-useless-computed-key */
/* eslint-disable react/prop-types */
import React from 'react';

import { StatusBadge, Tag } from '@gsa/afp-component-library';

const LifeCycleIndicatorMap = {
  Active: 'Ready-Gray',
  Request: 'Info-Gray',
  Order: 'Info-Gray',
  Delivered: 'Ready-Gray',
  ['Delivery Issue']: 'Warning-Gray',
  ['Missing/Stolen']: 'Urgent-Gray',
  Disposed: 'Inactive-Gray',
};

export const LifeCycleIndicatorTag = ({ value }) => {
  if (!value) return null;
  return (
    <StatusBadge variant={LifeCycleIndicatorMap[value]}>
      <span className="text-uppercase">{value}</span>
    </StatusBadge>
  );
};

export const LifeCycleTags = ({ vehicle }) =>
  vehicle?.assetLifecycle
    ? vehicle?.assetLifecycle
        .filter((x) => x && x?.lifeCycle?.lifecycleIndicatorStatus.length > 0)
        .map((assetLifecycle) => {
          return (
            <Tag>{assetLifecycle?.lifeCycle?.lifecycleIndicatorStatus}</Tag>
          );
        })
    : null;
