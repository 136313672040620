/* eslint-disable react/prop-types */
import React from 'react';
import { getCodeAndNameByOwnerShip } from 'utilities/format';
import { fieldGetter } from '../../../helpers/field-getter';
import POCCard from './poc-card';

const POC = ({ data, ownershipTypeCode, agencyCode, agency, customer }) => {
  const secondaryPoCInfo =
    data?.ownershipTypeCode === 'AO'
      ? {
          title: 'Point OF CONTACT #2',
          agencyOrFMC: `${getCodeAndNameByOwnerShip({
            ownershipTypeCode,
            code: agencyCode,
            name: agency?.name,
            customer,
          })}`,
        }
      : {
          title: 'GSA Fleet Service Representative',
          agencyOrFMC: data.secondaryPoC?.primaryAddress,
        };

  return (
    <div className="vehicle-overview-row margin-bottom-2">
      <POCCard
        title={
          data?.ownershipTypeCode === 'AO'
            ? 'POINT OF CONTACT #1'
            : 'Customer Primary Point of Contact'
        }
        fullName={data.primaryPoC?.fullName}
        email={data.primaryPoC?.email}
        agencyOrFMC={`${getCodeAndNameByOwnerShip({
          ownershipTypeCode,
          code: agencyCode,
          name: agency?.name,
          customer,
        })}`}
        phoneInfo={fieldGetter([
          { field: data.primaryPoC?.primaryPhone, formatter: 'phone' },
          { field: data.primaryPoC?.primaryPhoneExt, formatter: 'ext' },
        ])}
      />

      <POCCard
        title={secondaryPoCInfo.title}
        fullName={data.secondaryPoC?.fullName}
        email={data.secondaryPoC?.email}
        agencyOrFMC={secondaryPoCInfo.agencyOrFMC}
        phoneInfo={fieldGetter([
          { field: data.secondaryPoC?.primaryPhone, formatter: 'phone' },
          { field: data.secondaryPoC?.primaryPhoneExt, formatter: 'ext' },
        ])}
      />
    </div>
  );
};

export default POC;
