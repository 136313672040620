/* eslint-disable react/prop-types */
/* eslint-disable filenames/match-exported */

import React from 'react';
import { TextInput } from '@gsa/afp-component-library';
import classnames from 'classnames';

import { useRecoilState, useSetRecoilState } from 'recoil';

const TextInputField = ({
  pageId,
  selectors,
  id,
  disabled,
  dependency = null,
  required,
  label,
  isVisible,
  inputType = 'text',
  keyPressFilter = null,
  ...inputProps
}) => {
  const { fieldSelector, fieldAfterSelector = null } = selectors;
  const [fieldValueState, setFieldValueState] = useRecoilState(
    fieldSelector({ pageId, id }),
  );

  let afterFieldUpdated = ({ value }) => value;
  if (fieldAfterSelector) {
    afterFieldUpdated = useSetRecoilState(fieldAfterSelector({ pageId, id }));
  }
  return (
    <div
      className={classnames(fieldValueState.errorMessage && 'margin-left-205')}
    >
      <TextInput
        data-testid={id}
        name={id}
        disabled={fieldValueState?.disabled || disabled}
        id={id}
        type={inputType}
        label={label}
        value={fieldValueState.value}
        onChange={(e) => {
          setFieldValueState({ value: e.target.value, dependency });
        }}
        onBlur={(e) => {
          afterFieldUpdated({ value: e.target.value, dependency });
        }}
        onKeyDown={(e) => {
          if (keyPressFilter && keyPressFilter(e)) {
            e.preventDefault();
          }
        }}
        errorMessage={fieldValueState.errorMessage}
        required={required}
        characterLimit={fieldValueState.characterLimit}
        {...inputProps}
      />
    </div>
  );
};

export default TextInputField;
