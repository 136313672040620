import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Button, DetailsTable, Spinner } from '@gsa/afp-component-library';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import SectionBlock from './section-block';
import { useVehicle } from '../../../vehicle-context-provider';
import { AddressHelper } from 'utilities/address';
import VehicleLocationEditModal from './vehicle-location-edit';
import { CREATE_ASSET_CUSTODIAN } from '../../../../../services/data-layer';
import { canUpdateGFVehicleAdmin, canUpdateGFVehicleFSR } from 'utilities/authorization';

const VehicleLocation = ({ data }) => {
  const [editVehicleLocation, setEditVehicleLocation] = useState(false);
  const [updateVehicleLocationError, setUpdateVehicleLocationError] = useState(false);
  const { setSectionMsg, refetchVehicle } = useVehicle();

  const [updateVehicleLocation, { loading }] = useMutation(
    CREATE_ASSET_CUSTODIAN,
    {
      fetchPolicy: 'no-cache',
      onCompleted: () => {
        refetchVehicle();
        setSectionMsg({
          type: 'success',
          message: 'Vehicle location is successfully updated.',
        });
      },
      onError: () => {
        setUpdateVehicleLocationError(true);
      },
    },
  );

  const ability = useAppAbility();
  const isVehicleEditable =
    canUpdateGFVehicleAdmin(ability)
    || canUpdateGFVehicleFSR(ability);
  
  const saveVehicleLocation = (
    entityType,
    entityId,
  ) => {
    console.log(`data: `, data);
    setUpdateVehicleLocationError(false);
    updateVehicleLocation({
      variables: {
        assetCustodianInput: {
          assetId: data.uuid,
          entityId,
          entityType,
        },
      }
    });
  };
  
  return (
    <>
      <SectionBlock title="Vehicle location">
        <DetailsTable
          className="vehicle-overview-details-table vehicle-location"
          data={[
            [
              'Address',
              <AddressHelper
                addressObject={data.assetLocation}
              />,
            ],
          ]}
        />
        <div className="margin-top-2">
          {isVehicleEditable && (
            <Button
              data-testid="vehicle-location-edit-button-1"
              variant="outline"
              label="Edit"
              onClick={() => setEditVehicleLocation(true)}
            />
          )}
          {editVehicleLocation && (
            <VehicleLocationEditModal
              onSave={saveVehicleLocation}
              onClose={() => setEditVehicleLocation(false)}
              vehicle={data}
              updateVehicleLocationLoading={loading}
              updateVehicleLocationError={updateVehicleLocationError}
            />
          )}
        </div>
      </SectionBlock>
      {loading && (
        <Spinner aria-busy="true" className="loading_backdrop" size="large" />
      )}
    </>
  );
};

export default VehicleLocation;
