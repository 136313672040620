/* eslint-disable react/prop-types */
import React from 'react';
import moment from 'moment-timezone';
import classnames from 'classnames';
import Clamp from 'components/common/clamp/clamp';
import { emdash } from 'components/common';

export const ReservationDetailsRow = ({ reservation, timezone }) => {
  const {
    isDriverNeeded,
    reservationPurpose,
    comment,
    reservationNumber,
    motorPoolVehicle,
    motorPoolMember,
    vehicleCondition,
    actualPickupDate,
    actualReturnDate,
    odometerAtPickup,
    odometerAtReturn,
  } = reservation;

  const formatReservationDate = (value) => {
    return <>{moment.tz(value, timezone).format('L h:mm A z')}</>;
  };

  const borderClasses = classnames(
    'border-bottom border-base-lighter padding-y-1 display-flex flex-justify',
  );

  return (
    <div className="display-flex flex-justify-center">
      <div className="grid-col-11">
        <div className="grid-row grid-gap flex-justify">
          <div className="tablet:grid-col-6">
            <div
              className={`${borderClasses}`}
              data-testid="reservation-number"
            >
              <strong>Confirmation number</strong>
              {reservationNumber || emdash}
            </div>
            <div className={`${borderClasses}`} data-testid="driver-name">
              <strong>Created by</strong>
              {motorPoolMember?.member?.fullName || emdash}
            </div>
            <div className={`${borderClasses}`} data-testid="driver-needed">
              <strong>Driver needed</strong>
              {isDriverNeeded ? 'Yes' : 'No'}
            </div>
            <div className={`${borderClasses}`} data-testid="plate-number">
              <strong>Starting odometer</strong>
              {odometerAtPickup ? `${odometerAtPickup} miles` : emdash}
            </div>
            <div className={`${borderClasses}`} data-testid="plate-number">
              <strong>Ending odometer</strong>
              {odometerAtReturn ? `${odometerAtReturn} miles` : emdash}
            </div>
            <div
              className={`${borderClasses}`}
              data-testid="actual-pickup-date"
            >
              <strong>Actual pickup date</strong>
              {actualPickupDate
                ? formatReservationDate(actualPickupDate)
                : emdash}
            </div>
            <div
              className={`${borderClasses}`}
              data-testid="actual-return-date"
            >
              <strong>Actual return date</strong>
              {actualReturnDate
                ? formatReservationDate(actualReturnDate)
                : emdash}
            </div>
          </div>
          <div className="tablet:grid-col-6">
            <div className={`${borderClasses}`} data-testid="plate-number">
              <strong>Plate number</strong>
              {motorPoolVehicle?.vehicle?.tagNumber || emdash}
            </div>
            <div
              className={`${borderClasses}`}
              data-testid="reservation-purpose"
            >
              <strong>Reservation purpose</strong>
              {reservationPurpose?.purpose || emdash}
            </div>
            <div className={`${borderClasses}`} data-testid="vehicle-condition">
              <strong>Vehicle condition</strong>
              {vehicleCondition ? (
                <span className="padding-left-5">
                  <Clamp text={vehicleCondition} />
                </span>
              ) : (
                emdash
              )}
            </div>
            <div className={`${borderClasses}`} data-testid="comment">
              <strong>Reservation comments</strong>
              {comment ? (
                <span className="padding-left-3">
                  <Clamp text={comment} />
                </span>
              ) : (
                emdash
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReservationDetailsRow;
