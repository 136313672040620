/* eslint-disable */
export const vehicleInfoValidations = {
  vin: [
    { guard: 'notEmpty', msg: 'VIN is required.' },
    {
      guard: 'isVerifiedVin',
      msg: 'VIN must be verified by NHTSA or User to proceed.',
    },
  ],
  itemType: [{ guard: 'notEmpty', msg: 'Vehicle type is required' }],
  modelYear: [
    { guard: 'notEmpty', msg: 'Year is required' },
    { guard: 'isValidYear', msg: 'Must be a valid model year' },
  ],
  makeCode: [{ guard: 'notEmpty', msg: 'Make is required' }],
  modelCode: [{ guard: 'modelNotEmpty', msg: 'Model is required' }],
  makeColorName: [{ guard: 'notEmpty', msg: 'Color is required' }],
  fuelCode: [{ guard: 'notEmpty', msg: 'Fuel type is required' }],
  originalAcquisitionDate: [
    { guard: 'notEmpty', msg: 'Received date is required' },
  ],
  fastReportable: [{ guard: 'notEmpty', msg: 'Fast reportable is required' }],
  tagNumber: [
    { guard: 'notEmptyOrExempt', msg: 'Federal license plate is required' },
  ],
  tagExpirationDate: [
    {
      guard: 'notEmptyOrExempt',
      msg: 'Federal license plate expiration date is required',
    },
  ],
};

export const agencyInfoValidations = {
  agencyCode: [{ guard: 'isAgencyProvided', msg: 'Agency is required' }],
  bureauCode: [{ guard: 'isBureauProvided', msg: 'Agency is required' }],
};

export const pointOfContact1Validations = {
  'pointOfContact1.email': [
    {
      guard: 'validEmail',
      msg: `Email cannot be blank for point of contact #1`,
    },
    {
      guard: 'isUniqueEmail',
      msg: 'PoC emails cannot be the same.',
    },
  ],
  'pointOfContact1.firstName': [
    { guard: 'notEmpty', msg: 'First Name is required' },
  ],
  'pointOfContact1.lastName': [
    { guard: 'notEmpty', msg: 'Last Name is required' },
  ],
  'pointOfContact1.countryCode': [
    { guard: 'notEmpty', msg: 'Country is required' },
  ],
  'pointOfContact1.primaryAddress': [
    { guard: 'notEmpty', msg: 'Address is required' },
  ],
  'pointOfContact1.city': [{ guard: 'notEmpty', msg: 'City is required' }],
  'pointOfContact1.stateCode': [
    { guard: 'notEmpty', msg: 'State is required' },
  ],
  'pointOfContact1.postalCode': [{ guard: 'notEmpty', msg: 'Zip is required' }],
  'pointOfContact1.primaryPhone': [
    { guard: 'notEmpty', msg: 'Phone is required' },
  ],
};

export const pointOfContact2Validations = {
  'pointOfContact2.email': [
    {
      guard: 'validEmail',
      msg: `Email cannot be blank for point of contact #2`,
    },
    {
      guard: 'isUniqueEmail',
      msg: 'PoC emails cannot be the same.',
    },
  ],
  'pointOfContact2.firstName': [
    { guard: 'notEmpty', msg: 'First Name is required' },
  ],
  'pointOfContact2.lastName': [
    { guard: 'notEmpty', msg: 'Last Name is required' },
  ],
  'pointOfContact2.countryCode': [
    { guard: 'notEmpty', msg: 'Country is required' },
  ],
  'pointOfContact2.primaryAddress': [
    { guard: 'notEmpty', msg: 'Address is required' },
  ],
  'pointOfContact2.city': [{ guard: 'notEmpty', msg: 'City is required' }],
  'pointOfContact2.stateCode': [
    { guard: 'notEmpty', msg: 'State is required' },
  ],
  'pointOfContact2.postalCode': [{ guard: 'notEmpty', msg: 'Zip is required' }],
  'pointOfContact2.primaryPhone': [
    { guard: 'notEmpty', msg: 'Phone is required' },
  ],
};

export const agencyAndPocValidations = {
  ...agencyInfoValidations,
  ...pointOfContact1Validations,
  ...pointOfContact2Validations,
};
