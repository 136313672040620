import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Breadcrumbs,
  Button,
  PageTitle,
  SummaryBox,
} from '@gsa/afp-component-library';

const trail = [<a href={`${window.AFP_CONFIG.appURLs.home}/home`}>Home</a>];

const Recalls = () => {
  const history = useHistory();
  const enabledFeatures = window?.AFP_CONFIG?.features || {};
  return (
    <>
      <Breadcrumbs trail={trail} current="Recalls" />
      <div className="grid-col margin-bottom-2">
        <PageTitle title="Recalls" />
        <div className="grid-row margin-bottom-4 grid-gap-2">
          <div className="tablet:grid-col-8">
            <p>
              GSAFleet.gov&apos;s recall management feature provides awareness
              and transparency of recalls affecting your fleet. The feature
              includes:
            </p>
            <ul>
              <li>Recall information that is updated daily.</li>
              <li>Ad hoc reporting.</li>
              <li>
                Notifications of special instructions such as &quot;Stop
                Drive&quot; and &quot;Park Outside Due to Enhanced Fire
                Risk&quot;.
              </li>
            </ul>
            <p>
              <strong>Important:</strong>
            </p>
            <ul>
              <li>
                Recall information provided in GSAFleet.gov is the best
                information available electronically to GSA.
              </li>
              <li>
                Paper notification letters from the manufacturer as well as
                recall information on{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.nhtsa.gov/recalls"
                >
                  nhtsa.gov/recalls
                </a>{' '}
                and manufacturer recall websites should also be considered in
                evaluating whether a vehicle has an open safety recall.
              </li>
              <li>
                Recall information is not available electronically for all
                vehicle types. GSA generally does not receive this information
                for larger vehicles, small production run and specialty
                vehicles, vehicles built for international use, and recalls
                older than 15 years.
              </li>
              <li>
                GSA does not issue or close vehicle recalls. This is done by the
                vehicle manufacturer.
              </li>
              <li>
                Recall closure by the manufacturer requires the dealer
                performing the remedy to submit the claim package to the
                manufacturer. Only after that occurs will the recall either
                appear as closed or not appear as open, depending on where you
                look.
              </li>
              <li>
                GSA does not issue special instructions for &quot;Stop
                Drive&quot; and &quot;Park Outside Due to Enhanced Fire
                Risk&quot;. This is done by the vehicle manufacturer.
              </li>
            </ul>
          </div>
          <div className="tablet:grid-col-4">
            <div data-testid="vehicle-recalls-summary-card">
              <SummaryBox.SummaryBox>
                <SummaryBox.SummaryHeader>
                  Vehicle recalls
                </SummaryBox.SummaryHeader>
                <SummaryBox.SummaryBody>
                  <p>
                    View and track safety recalls that exist on your vehicle.
                  </p>
                </SummaryBox.SummaryBody>
                <SummaryBox.SummaryFooter>
                  <Button
                    label="View vehicle recalls"
                    onClick={() => {
                      history.push(
                        '/recalls/vehicle-recalls?filter-status=Open&filter-user_closed_date=%24isNull',
                      );
                    }}
                  />
                </SummaryBox.SummaryFooter>
              </SummaryBox.SummaryBox>
            </div>
            {enabledFeatures?.recalls && (
              <div
                data-testid="recall-campaigns-summary-card"
                className="margin-top-3"
              >
                <SummaryBox.SummaryBox>
                  <SummaryBox.SummaryHeader>
                    Recall campaigns
                  </SummaryBox.SummaryHeader>
                  <SummaryBox.SummaryBody>
                    <p>
                      View and track recalls at a campaign level for your fleet.
                    </p>
                  </SummaryBox.SummaryBody>
                  <SummaryBox.SummaryFooter>
                    <Button
                      label="View recall campaigns"
                      onClick={() => {
                        history.push('recalls/campaigns');
                      }}
                    />
                  </SummaryBox.SummaryFooter>
                </SummaryBox.SummaryBox>
              </div>
            )}
          </div>
        </div>
        <p className="margin-top-4">
          For more information, visit{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.gsa.gov/buy-through-us/products-and-services/transportation-and-logistics-services/fleet-management/vehicle-leasing/gov-recalls"
          >
            GSA Fleet&apos;s Vehicle Recalls page
          </a>
          .
        </p>
      </div>
    </>
  );
};

export default Recalls;
