import React from 'react';
import { Link } from 'react-router-dom';
import { Alert } from '@gsa/afp-component-library';
import { formatTagExpDate } from '../../../utilities/common';

export const SoldWarning = ({ licensePlate }) => {
  return (
    <Alert
      type="warning"
      className="margin-top-4 margin-bottom-1"
      focused
      heading="When this vehicle is updated to sold status:"
    >
      <ul>
        <li>
          The attached license plate{' '}
          <span className="text-bold">{licensePlate}</span> will be removed from
          the vehicle registration
        </li>
        <li>
          The vehicle will become{' '}
          <span className="text-bold">unregistered</span>
        </li>
        <li>
          You will need to submit license plate{' '}
          <span className="text-bold">{licensePlate}</span> for destruction
        </li>
      </ul>
    </Alert>
  );
};

export const StatusWarningNoPlate = ({ statusName }) => (
  <Alert
    type="warning"
    slim
    className="margin-top-4 margin-bottom-1"
    focused
    data-testid={`status-warning-${statusName}`}
  >
    The vehicle will be updated to{' '}
    <span className="text-bold">{statusName}</span> status on clicking save and
    close.
  </Alert>
);

export const MissingWarning = ({ licensePlate }) => {
  return (
    <Alert
      type="warning"
      className="margin-top-4 margin-bottom-1"
      focused
      heading="When this vehicle is updated to missing/stolen status:"
    >
      <ul>
        <li>
          The attached license plate{' '}
          <span className="text-bold">{licensePlate}</span> will be removed from
          the vehicle registration
        </li>
        <li>
          The vehicle will become{' '}
          <span className="text-bold">unregistered</span>
        </li>
        <li>
          License plate <span className="text-bold">{licensePlate}</span> will
          be updated to the status of missing
        </li>
      </ul>
    </Alert>
  );
};

export const ExemptConfirmation = ({ licensePlate, expirationDate, vin }) => {
  return (
    <Alert
      type="warning"
      heading="Previous license plate has been removed from the vehicle"
    >
      <p>
        License plate{' '}
        <Link
          style={{ textDecoration: 'underline' }}
          to={{
            pathname: `/license-plate/${encodeURIComponent(licensePlate)}`,
            state: { vin },
          }}
        >
          {licensePlate}
        </Link>{' '}
        with expiration date{' '}
        <span className="text-bold">{formatTagExpDate(expirationDate)}</span>{' '}
        has been removed from the vehicle. The status of unattached has been
        applied to both A and B plates (if a 2-plate set).
      </p>
      <p className="text-bold">What to do next:</p>
      <p>
        Please submit license plate{' '}
        <Link
          style={{ textDecoration: 'underline' }}
          to={{
            pathname: `/license-plate/${encodeURIComponent(licensePlate)}`,
            state: { vin },
          }}
        >
          {licensePlate}
        </Link>{' '}
        with expiration date of{' '}
        <span className="text-bold">{formatTagExpDate(expirationDate)}</span>{' '}
        for destruction.
      </p>
    </Alert>
  );
};

export const SoldConfirmation = ({ licensePlate, expirationDate }) => {
  return (
    <Alert
      type="warning"
      heading="License plate has been removed from the vehicle"
    >
      <p>
        License plate{' '}
        <Link
          style={{ textDecoration: 'underline' }}
          to={`/license-plate/${encodeURIComponent(licensePlate)}`}
        >
          {licensePlate}
        </Link>{' '}
        with expiration date{' '}
        <span className="text-bold">{formatTagExpDate(expirationDate)}</span>{' '}
        has been removed from the vehicle. The status of{' '}
        <span className="text-bold">unattached</span> has been applied to both A
        and B plates (if a 2-plate set).
      </p>
      <p className="text-bold">What to do next:</p>
      <p>
        Please submit license plate{' '}
        <Link
          style={{ textDecoration: 'underline' }}
          to={`/license-plate/${encodeURIComponent(licensePlate)}`}
        >
          {licensePlate}
        </Link>{' '}
        for destruction
      </p>
    </Alert>
  );
};

export const MissingConfirmation = ({ licensePlate, expirationDate }) => {
  return (
    <Alert type="warning" heading="License plate reported lost or stolen">
      <p>
        License plate{' '}
        <Link
          style={{ textDecoration: 'underline' }}
          to={`/license-plate/${encodeURIComponent(licensePlate)}`}
        >
          {licensePlate}
        </Link>{' '}
        with expiration date {formatTagExpDate(expirationDate)} has been removed
        from the vehicle. The status of missing has been applied to both A and B
        plates (if a 2-plate set).
      </p>
      <p className="text-bold">What to do next:</p>
      <ol>
        <li>
          Report the missing license plate to your local security office and
          your local police department (or equivalent).
        </li>
        <li>
          Contact UNICOR immediately to obtain a replacement license plate.
        </li>
      </ol>
    </Alert>
  );
};

const incompleteEditSuggestion = (
  <p>
    Please use the <strong>edit</strong> button below the Vehicle block to
    update the empty fields.
  </p>
);

export const IncompleteWarning = ({ statusName, ownership }) => (
  <Alert
    type="error"
    heading="Critical information is missing on this vehicle registration"
    data-testid="incomplete-registration-alert"
    className="margin-bottom-1"
  >
    Certain vehicle fields (VIN, Make, Model, Year, Color, Fuel type, FAST
    reportable, Primary & Secondary POC) are mandatory data elements used to
    produce a Vehicle Identification Card (VIC). These are needed to ensure that
    the vehicle is properly registered in the Federal Motor Vehicle Registration
    System (FMVRS) when queried through the National Law Enforcement
    Telecommunication System (NLETS).
    {ownership === 'GF' ? null : incompleteEditSuggestion}
  </Alert>
);

export const LicensePlateUpdateWarning = () => {
  return (
    <Alert type="warning" slim>
      When the license plate information is updated:
      <ul>
        <li>
          The vehicle will become{' '}
          <span className="text-bold">unregistered</span>
        </li>
        <li>
          You will need to add a federal license plate to re-register the
          vehicle
        </li>
      </ul>
    </Alert>
  );
};
