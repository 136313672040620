export const schemaToContent = ({ schema, mode, context }) => {
  if (!schema[`${mode}`]?.content) {
    return null;
  }
  if (typeof schema[`${mode}`]?.content !== 'function') {
    return null;
  }
  return schema[`${mode}`]?.content(context);
};

export const schemaToModalAction = ({ schema, mode, context }) => {
  if (!schema[`${mode}`]?.action) {
    return null;
  }
  if (typeof schema[`${mode}`]?.action !== 'function') {
    return null;
  }
  return schema[`${mode}`]?.action(context);
};

export const schemaToModalTitle = ({ schema, mode, context }) => {
  if (!schema[`${mode}`]?.title) {
    return null;
  }
  if (typeof schema[`${mode}`]?.title !== 'function') {
    return null;
  }
  return schema[`${mode}`]?.title(context);
};
