import { YesNoSelector } from '../../../forms/motor-pool-form-components';

export const generateCreateUpdateReservationFields = (isManagesPool, isDisabled) => {
  return {
    isOnBehalf: {
      label: 'Reservation on behalf of different driver?',
      name: 'isOnBehalf',
      id: 'isOnBehalf',
      required: true,
      editable: true,
      visible: isManagesPool,
      component: YesNoSelector,
    },
    driverName: {
      label: 'Driver name',
      name: 'driverName',
      id: 'driverName',
      dataPath: 'driverName',
      editable: true,
      required: true,
      readOnly: !isManagesPool,
      visible: true,
      additionalAttributes: {
        disabled: isDisabled,
        maxLength: 51,
      },
    },
    driverNeeded: {
      label: 'Motor pool driver needed?',
      name: 'isDriverNeeded',
      id: 'isDriverNeeded',
      editable: true,
      visible: true,
      component: YesNoSelector,
    },
    reservationPurpose: {
      label: 'Reservation purpose',
      name: 'reservationPurposeId',
      id: 'reservationPurposeId',
      dataId: 'reservationPurposeId',
      required: true,
      editable: true,
      visible: true,
      component: YesNoSelector,
    },
    comment: {
      label: 'Reservation comments',
      name: 'comment',
      id: 'comment',
      dataId: 'comment',
      editable: true,
      visible: true,
      additionalAttributes: {
        type: 'textarea',
        maxLength: 201,
        characterLimit: 200,
      },
    },
  };
};

export const CREATE_UPDATE_RESERVATION_DATE_FIELDS = {
  startDate: {
    label: 'Reservation start date',
    name: 'scheduledPickupDate',
    id: 'scheduledPickupDate',
    dataId: 'scheduledPickupDate',
    required: true,
  },
  startTime: {
    label: 'Start time',
    name: 'scheduledStartTime',
    id: 'scheduledStartTime',
    dataId: 'scheduledStartTime',
    required: true,
  },
  endDate: {
    label: 'Reservation end date',
    name: 'scheduledReturnDate',
    id: 'scheduledReturnDate',
    dataId: 'scheduledReturnDate',
    required: true,
  },
  endTime: {
    label: 'End time',
    name: 'scheduledReturnTime',
    id: 'scheduledReturnTime',
    dataId: 'scheduledReturnTime',
    required: true,
  },
};

export const START_RESERVATION_FIELDS = {
  userName: {
    label: 'User name',
    name: 'userName',
    id: 'userName',
    dataPath: 'motorPoolMember.member.fullName',
    editable: false,
  },
  poolName: {
    label: 'Pool name',
    name: 'poolName',
    id: 'poolName',
    editable: false,
  },
  scheduledPickupDate: {
    label: 'Scheduled pickup date',
    name: 'scheduledPickupDate',
    id: 'scheduledPickupDate',
    format: 'dateTime',
    editable: false,
  },
  scheduledReturnDate: {
    label: 'Scheduled return date',
    name: 'scheduledReturnDate',
    id: 'scheduledReturnDate',
    format: 'dateTime',
    editable: false,
  },
  actualPickupDate: {
    label: 'Actual pickup date',
    name: 'actualPickupDate',
    id: 'actualPickupDate',
    format: 'dateTime',
    editable: false,
  },
  odometerAtPickup: {
    label: 'Vehicle beginning odometer reading',
    name: 'odometerAtPickup',
    id: 'odometerAtPickup',
    editable: true,
    required: true,
    dataPath: 'newOdometerReading.odometer',
    additionalAttributes: {
      suffix: 'miles',
    },
  },
  totalKeysIssued: {
    label: 'Number of keys issued',
    name: 'totalKeysIssued',
    id: 'totalKeysIssued',
    editable: true,
  },
  vehicleCondition: {
    label: 'Vehicle condition',
    name: 'vehicleCondition',
    id: 'vehicleCondition',
    editable: true,
    additionalAttributes: {
      type: 'textarea',
      maxLength: 51,
      characterLimit: 50,
    },
  },
  comment: {
    label: 'Reservation comments',
    name: 'comment',
    id: 'comment',
    dataId: 'comment',
    editable: true,
    additionalAttributes: {
      type: 'textarea',
      maxLength: 201,
      characterLimit: 200,
    },
  },
  contactInfo: {
    label: 'Contact number',
    name: 'contactInfo',
    id: 'contactInfo',
    editable: true,
    additionalAttributes: {
      maxLength: 14,
      placeholder: 'XXX-XXX-XXXX',
    },
  },
};

export const CLOSE_COMPLETE_RESERVATION_FIELDS = {
  userName: {
    label: 'User name',
    name: 'userName',
    id: 'userName',
    dataPath: 'motorPoolMember.member.fullName',
    editable: false,
  },
  poolName: {
    label: 'Pool name',
    name: 'poolName',
    id: 'poolName',
    editable: false,
  },
  scheduledPickupDate: {
    label: 'Scheduled pickup date',
    name: 'scheduledPickupDate',
    id: 'scheduledPickupDate',
    format: 'dateTime',
    editable: false,
  },
  scheduledReturnDate: {
    label: 'Scheduled return date',
    name: 'scheduledReturnDate',
    id: 'scheduledReturnDate',
    format: 'dateTime',
    editable: false,
  },
  actualReturnDate: {
    label: 'Actual return date',
    name: 'actualReturnDate',
    id: 'actualReturnDate',
    format: 'dateTime',
    editable: false,
  },
  odometerAtPickup: {
    label: 'Vehicle beginning odometer reading',
    name: 'odometerAtPickup',
    id: 'odometerAtPickup',
    editable: false,
    additionalAttributes: {
      suffix: 'miles',
    },
  },
  odometerAtReturn: {
    label: 'Vehicle ending odometer reading',
    name: 'odometerAtReturn',
    id: 'odometerAtReturn',
    editable: true,
    required: true,
    additionalAttributes: {
      suffix: 'miles',
    },
  },
  totalKeysReturned: {
    label: 'Number of keys returned',
    name: 'totalKeysReturned',
    id: 'totalKeysReturned',
    editable: true,
  },
  vehicleCondition: {
    label: 'Vehicle condition',
    name: 'vehicleCondition',
    id: 'vehicleCondition',
    editable: true,
    additionalAttributes: {
      type: 'textarea',
      maxLength: 51,
      characterLimit: 50,
    },
  },
  comment: {
    label: 'Reservation comments',
    name: 'comment',
    id: 'comment',
    dataId: 'comment',
    editable: true,
    additionalAttributes: {
      type: 'textarea',
      maxLength: 201,
      characterLimit: 200,
    },
  },
  contactInfo: {
    label: 'Contact number',
    name: 'contactInfo',
    id: 'contactInfo',
    editable: true,
    additionalAttributes: {
      maxLength: 14,
      placeholder: 'XXX-XXX-XXXX',
    },
  },
};
