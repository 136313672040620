import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { phoneRegExp } from 'utilities/validation';
import { useMotorPool } from '../../../motor-pool-provider';
import ReservationStartFields from './reservation-start-fields';
import { START_RESERVATION_FIELDS } from './reservation-constants';

const numericRegExp = /^\d+$/;

const ReservationStartForm = () => {
  const generateReservationFormSchema = () =>
    yup.object().shape({
      [START_RESERVATION_FIELDS.odometerAtPickup.name]: yup
        .string()
        .label(START_RESERVATION_FIELDS.odometerAtPickup.label)
        .matches(numericRegExp, 'Should be numeric.')
        .required()
        .typeError('Vehicle beginning odometer reading is a required field.'),
      [START_RESERVATION_FIELDS.totalKeysIssued.name]: yup
        .string()
        .label(START_RESERVATION_FIELDS.totalKeysIssued.label)
        .matches(numericRegExp, 'Should be numeric.')
        .length(1)
        .nullable(),
      [START_RESERVATION_FIELDS.vehicleCondition.name]: yup
        .string()
        .max(50, 'Vehicle condition cannot be longer than 50 characters.')
        .label(START_RESERVATION_FIELDS.comment.label)
        .nullable(),
      [START_RESERVATION_FIELDS.comment.name]: yup
        .string()
        .max(200, 'Comment cannot be longer than 200 characters.')
        .label(START_RESERVATION_FIELDS.comment.label)
        .nullable(),
      [START_RESERVATION_FIELDS.contactInfo.name]: yup
        .string()
        .label(START_RESERVATION_FIELDS.contactInfo.label)
        .matches(phoneRegExp, 'Contact number is not valid.')
        .nullable(),
    });

  const { startPoolReservation, reservationListingSelected } = useMotorPool();

  const methods = useForm({
    resolver: yupResolver(generateReservationFormSchema()),
    defaultValues: {},
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const onSubmit = (values) => {
    const dateTimeFormat = 'YYYY-MM-DDTHH:mm:ss.SSSZ';
    startPoolReservation({
      variables: {
        startReservation: {
          ...values,
          id: reservationListingSelected.id,
          motorPoolVehicleId: reservationListingSelected.motorPoolVehicleId,
          odometerAtPickup:
            values?.odometerAtPickup && Number(values.odometerAtPickup),
          totalKeysIssued:
            values?.totalKeysIssued && Number(values.totalKeysIssued),
          actualPickupDate: moment().format(dateTimeFormat),
        },
      },
    });
  };

  return (
    <FormProvider {...methods}>
      <form
        data-testid="motor-pool-reservation-start-form"
        id="motor-pool-reservation-start-form"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <ReservationStartFields />
      </form>
    </FormProvider>
  );
};

export default ReservationStartForm;
