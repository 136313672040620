import React from 'react';
import { DetailsTable, Button } from '@gsa/afp-component-library';
import phoneNumberFormatter from '../../../../utilities/phoneNumberFormatter';
import countries from '../countries';

export default function PocReview({
  pointOfContact,
  pocNum,
  handleNavToStep2,
}) {
  return (
    <>
      <div className="tablet:grid-col-6">
        <h4 className="title-s-caps text-primary margin-bottom-1">
          POINT OF CONTACT #{pocNum}
        </h4>
        <div className="bg-gray-3 radius-md padding-y-2 padding-x-4">
          <DetailsTable
            className="text-right"
            data={[
              [
                `Name`,
                `${pointOfContact.firstName} ${pointOfContact.lastName}`,
              ],
              ['Street Address', pointOfContact.primaryAddress],
              ['Street Address 2', pointOfContact.secondaryAddress || '-'],
              ['City', pointOfContact.city],
              ['State / Province / Region', pointOfContact.stateCode],
              ['Zip Code', pointOfContact.postalCode],
              ['Country', countries[pointOfContact.countryCode] || '-'],
              ['Email', pointOfContact.email],
              [
                'Telephone Number',
                `${phoneNumberFormatter(pointOfContact.primaryPhone)} ${
                  pointOfContact.primaryPhoneExt
                    ? `ext. ${pointOfContact.primaryPhoneExt}`
                    : ''
                }`,
              ],
            ]}
          />
          <Button
            variant="outline"
            className="margin-top-2 margin-left-0 bg-white"
            aria-label={`edit point of contact ${pocNum}`}
            onClick={handleNavToStep2}
            label="Edit"
          />
        </div>
      </div>
    </>
  );
}
