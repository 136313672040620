/* eslint-disable import/extensions */

/* eslint-disable filenames/match-exported */
import removeVinModalSchema from './remove-vin';

const schema = {
  removeVinModalSchema,
  name: 'RecallCampaignDetail',
};
export default schema;
