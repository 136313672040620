import { useLazyQuery, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useReducer } from 'react';
import { useModal } from '@gsa/afp-component-library';
import {
  GET_SCHEDULES,
  SET_SCHEDULE_STATUS,
  DELETE_REPORT_SCHEDULES,
} from './schedules.gql';
import {
  SUSPEND_SCHEDULE_MESSAGE,
  UNSUSPEND_SCHEDULE_MESSAGE,
  DELETE_SCHEDULE_MESSAGE,
} from './messages';

export const SchedulesContext = createContext();

const initialState = {
  schedules: {
    rows: [],
    hasMore: false,
    count: 0,
  },
  alertMessage: { context: '', message: '', type: '', header: '' },
  actionMode: '',
  selectedSchedule: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_SCHEDULES':
      return { ...state, schedules: action.payload };
    case 'SET_SELECTED_SCHEDULE':
      return { ...state, selectedSchedule: action.payload };
    case 'SET_ALERT_MESSAGE':
      return { ...state, alertMessage: action.payload };
    case 'SET_ACTION_MODE':
      return { ...state, actionMode: action.payload };
    default:
      throw new Error('Invalid action');
  }
};
function SchedulesProvider({ children, ...props }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    isOpen: isScheduleActionsModalOpen,
    openModal: openScheduleActionsModal,
    closeModal: closeScheduleActionsModal,
  } = useModal();

  const dispatchAction = (type, payload) => {
    dispatch({
      type,
      payload,
    });
  };

  const setRequestError = (requestError, context) => {
    dispatchAction('SET_ALERT_MESSAGE', {
      type: 'error',
      message: requestError?.message,
      error: requestError,
      context,
    });
  };

  const setRequestMessage = (message, context) => {
    dispatchAction('SET_ALERT_MESSAGE', {
      type: 'success',
      message,
      context,
    });
  };

  const [
    getReportSchedules,
    { loading: schedulesLoading, refetch: refetchReportSchedules },
  ] = useLazyQuery(GET_SCHEDULES, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onError: (error) => setRequestError(error, 'scheduleListing'),
    onCompleted: (responseData) => {
      if (responseData?.getReportSchedules) {
        dispatchAction('SET_SCHEDULES', responseData.getReportSchedules);
      }
    },
  });

  const [setScheduleStatus, { loading: suspendingReport }] = useMutation(
    SET_SCHEDULE_STATUS,
    {
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
      onError: (error) => setRequestError(error, 'scheduleModal'),
      onCompleted: (response) => {
        if (response?.setScheduleStatus) {
          closeScheduleActionsModal();
          refetchReportSchedules();
          if (response?.setScheduleStatus.scheduleStatus === 'Suspended') {
            setRequestMessage(
              SUSPEND_SCHEDULE_MESSAGE.body(),
              'scheduleListing',
            );
          } else {
            setRequestMessage(
              UNSUSPEND_SCHEDULE_MESSAGE.body(),
              'scheduleListing',
            );
          }
        }
      },
    },
  );

  const [deleteReportSchedule, { loading: deletingReport }] = useMutation(
    DELETE_REPORT_SCHEDULES,
    {
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
      onError: (error) => setRequestError(error, 'scheduleModal'),
      onCompleted: (response) => {
        if (response?.deleteReportSchedule) {
          closeScheduleActionsModal();
          refetchReportSchedules();
          setRequestMessage(DELETE_SCHEDULE_MESSAGE.body(), 'scheduleListing');
        }
      },
    },
  );

  return (
    <SchedulesContext.Provider
      value={{
        ...state,
        getReportSchedules,
        schedulesLoading,
        isScheduleActionsModalOpen,
        openScheduleActionsModal,
        closeScheduleActionsModal,
        setScheduleStatus,
        suspendingReport,
        deleteReportSchedule,
        deletingReport,
        dispatchAction,
        setRequestError,
        setRequestMessage,
        ...props,
      }}
    >
      {children}
    </SchedulesContext.Provider>
  );
}

export default SchedulesProvider;

SchedulesProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export const useSchedulesContext = () => useContext(SchedulesContext);
