import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { TextInput, Button } from '@gsa/afp-component-library';
import PrevButton from '../common/prev-button';
import PropTypes from 'prop-types';
import UpsTrackingNumberTable from './widgets/ups-tracking-number-table';
import { validate } from '../../utilities/validation';
import { useLicensePlateDetails } from '../license-plate-details/license-plate-details-provider';
import { BULK_TAGS_PENDING_DESTRUCTION } from '../../services/data-layer';
import './license-plate-destruction.css';
import _ from 'lodash';
import { useLicensePlateDestruction } from './context';
import { useAlert } from '../alert/alert';

function StepDestructionReturnInformation({
  setCurrentStep,
  setShowAlert,
  returnRoute,
}) {
  const {
    destructionInitData,
    setDestructionInitData,
    setForDestructionList,
    forDestructionList,
  } = useLicensePlateDestruction();

  const [errors, setErrors] = useState({});
  const [Alert, scrollToAlert] = useAlert();

  const { setFilters, getTag } = useLicensePlateDetails() || {};

  const destructionValidationRules = [
    {
      name: `numBoxes`,
      rules: ['required'],
      msg: { required: 'Number of boxes is required' },
    },
    {
      name: `reasonForReturning`,
      rules: ['required'],
      msg: { required: 'Reason for returning is required' },
    },
    {
      name: `trackingNumbers`,
      rules: ['required'],
      msg: { required: 'Tracking number for returning is required' },
    },
  ];

  const accessorToLabel = {
    numBoxes: 'Number of boxes',
    trackingNumbers: 'UPS tracking numbers',
    reasonForReturning: 'Reason for returning',
  };

  const [bulkTagsPendingDestruction] = useMutation(
    BULK_TAGS_PENDING_DESTRUCTION,
    {
      onError: (requestError) => {
        setShowAlert({
          status: 'error',
          message: _.get(requestError, 'message', 'Unknown Error.'),
          key: '',
        });
      },
      onCompleted: (data) => {
        setForDestructionList(
          data.bulkTagsPendingDestruction.map((destruction) => {
            return {
              ...destruction,
              agencyName: destructionInitData?.agencyName,
            };
          }),
        );
        setShowAlert({
          status: 'success',
          message:
            `${forDestructionList?.length} license plates have been successfully updated to the\n` +
            'status',
          key: <span className="text-bold"> pending destruction</span>,
        });
        setCurrentStep(4);
      },
    },
  );

  const submitReturn = async () => {
    const validDestructionInitData = validate({
      data: destructionInitData,
      rules: destructionValidationRules,
      setErrors: setErrors,
    });
    const tags = forDestructionList.map((tag) => {
      const { id, expirationDate, tagActivityTypeCode } = tag;
      return {
        tagNumber: id,
        expirationDate: expirationDate,
        tagActivityTypeCode: tagActivityTypeCode,
      };
    });
    if (
      tags &&
      !_.isEmpty(destructionInitData.trackingNumbers) &&
      validDestructionInitData
    ) {
      await bulkTagsPendingDestruction({
        variables: {
          tags,
        },
      });
      if (returnRoute.includes('license-plate/')) {
        getTag({
          variables: {
            id: tags[0].tagNumber,
            tagExpirationDate: tags[0].expirationDate
              ? _.toNumber(tags[0].expirationDate)
              : null,
          },
        });
        setFilters({}, tags[0].expirationDate); // trigger refetch TagActivityList if coming from tag details page
      }
    } else {
      scrollToAlert();
    }
  };

  const handleChange = (e) => {
    setDestructionInitData({
      ...destructionInitData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div>
      <div data-testid="destruction-step2" className="destruction-step">
        <h2 className="margin-bottom-0">Returning information</h2>
        <Alert errors={errors} accessorToLabel={accessorToLabel} />
        <p>Please include a reason for returning the license plates.</p>
        <div className="maxw-tablet">
          <TextInput
            id="reason for returning"
            name="reasonForReturning"
            className="maxw-full"
            type="textarea"
            label="Reason for returning plates"
            value={destructionInitData.reasonForReturning}
            onChange={handleChange}
            required
            errorMessage={errors['reasonForReturning']}
            data-testid="test-textarea"
          />

          <h2 className="margin-bottom-0">Shipping information</h2>
          <p>
            Use the table below to add UPS tracking numbers for the boxes that
            will be shipped to UNICOR for destruction{' '}
          </p>
          <TextInput
            data-testid="numb-boxes"
            name="numBoxes"
            type="number"
            label="How many boxes are in your shipment?"
            value={destructionInitData?.numBoxes}
            onChange={handleChange}
            required
            errorMessage={errors['numBoxes']}
            data-testid="test-textinput"
          />

          <UpsTrackingNumberTable
            handleChange={handleChange}
            errors={errors}
            setErrors={setErrors}
          />

          <h2 className="margin-bottom-0">Submit</h2>

          <p>
            {' '}
            Please review all the information before submitting plates for
            destruction below. Upon submission all license plates will be marked
            pending destruction. A PDF will be generated that{' '}
            <span className="text-bold"> must</span> be included with your
            license plates shipment to UNICOR.
          </p>
        </div>

        <div className="grid-row margin-bottom-6 margin-top-6">
          <PrevButton onClick={() => setCurrentStep(2)} />
          <Button
            type="button"
            onClick={submitReturn}
            data-testid="btn-next"
            label="Submit plates for destruction"
          />
        </div>
      </div>
    </div>
  );
}

StepDestructionReturnInformation.defaultProps = {
  setCurrentStep: undefined,
  returnRoute: '',
};

StepDestructionReturnInformation.propTypes = {
  setCurrentStep: PropTypes.func,
  returnRoute: PropTypes.string,
};

export default StepDestructionReturnInformation;
