import registrationFormValidation from './registration-form-validation';
import {
  vehicleInfoValidations,
  pointOfContact1Validations,
  pointOfContact2Validations,
  agencyAndPocValidations,
} from './validation-rules';

const pocValidations = {
  pointOfContact1Validations,
  pointOfContact2Validations,
};

const pocFields = [
  'email',
  'firstName',
  'lastName',
  'countryCode',
  'primaryAddress',
  'city',
  'stateCode',
  'postalCode',
  'primaryPhone',
];
export const getPocFields = (pocNum) => {
  return pocFields.map((field) => `pointOfContact${pocNum}.${field}`);
};

export default function useFormValidation(
  step,
  { isRequirePurchaseOrderNumber, purchaseOrderNumber },
) {
  const validateStep = (formState) => {
    if (step === 1) {
      return registrationFormValidation(formState, vehicleInfoValidations, {
        isRequirePurchaseOrderNumber,
        purchaseOrderNumber,
      });
    }
    if (step === 2) {
      return registrationFormValidation(formState, agencyAndPocValidations);
    }
    return [];
  };

  const singleFieldValidation = (formState, field) => {
    if (step === 1) {
    } else if (step === 2) {
      const validation = { [field]: agencyAndPocValidations[field] };
      return registrationFormValidation(formState, validation);
    }
  };

  const validatePoc = (formState, pocNum) => {
    const validation = pocValidations[`pointOfContact${pocNum}Validations`];
    return registrationFormValidation(formState, validation);
  };

  return [validateStep, singleFieldValidation, validatePoc];
}
