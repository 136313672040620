import React from 'react';
import { Alert, PageTitle } from '@gsa/afp-component-library';
import { useFcReplacement } from './fc-replacement-context-provider';
import { MAX_ORDERS_LIMIT_MESSAGE } from './consts';

const FcReplacementHeader = () => {
  const { alertMessage, ordersMaxLimitReached } = useFcReplacement();
  const { header, message, type, context } = alertMessage;
  const { header: maxOrdersLimitHeader, body: maxOrdersLimitBody } =
    MAX_ORDERS_LIMIT_MESSAGE;
  return (
    <>
      {context === 'fcReplacementHistory' && message && (
        <div className="margin-bottom-2" data-testid="fc-history-alert">
          <Alert type={type} heading={header || null}>
            {message}
          </Alert>
        </div>
      )}
      {ordersMaxLimitReached && (
        <div className="margin-bottom-2" data-testid="fc-max-limit-alert">
          <Alert type="error" heading={maxOrdersLimitHeader}>
            {maxOrdersLimitBody}
          </Alert>
        </div>
      )}
      <header>
        <PageTitle title="Fleet Leasing Card Replacement" />
        <div className="margin-bottom-4 tablet:grid-col-7">
          <p>
            Use this tool to order a replacement fleet service card for your GSA
            Fleet leased vehicle. Requests must be submitted by 1 PM EST
            otherwise your card will be ordered the next business day. Requests
            submitted on a weekend or federal holiday will be ordered the next
            business day. You are allowed to edit or cancel a request while it
            is in pending status. Please note: you may order up to 10 cards per
            day.
          </p>
          <p>
            For more information, please see the fleet replacement card&nbsp;
            <a
              href={`${process.env.PUBLIC_URL}/GSAFleet.gov-Fleet-Leasing-Card-Replacement-User-Guide.pdf`}
              className="mobile:display-block"
              data-testid="gsa-fleet-card-user-guide"
              target="_blank"
              rel="noopener noreferrer"
            >
              user guide
            </a>{' '}
            and&nbsp;
            <a
              href="https://vimeo.com/gsavisualcommunications/review/803072721/bf94b1f5fe"
              className="mobile:display-block"
              data-testid="gsa-fleet-card-user-guide"
              target="_blank"
              rel="noopener noreferrer"
            >
              How to
            </a>{' '}
            video.
          </p>
        </div>
      </header>
    </>
  );
};

export default FcReplacementHeader;
