import React from 'react';
import FilterAndGrid from './widgets/filter-and-grid';

const LicensePlateTabs = ({ filters, sortOrder, alertUtils }) => {
  return (
    <>
      <FilterAndGrid
        filters={filters}
        sortOrder={sortOrder}
        alertUtils={alertUtils}
      />
    </>
  );
};

export default LicensePlateTabs;
