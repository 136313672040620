import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';

import { AddressConfirmationModal } from '@gsa/afp-component-library';
import { VALIDATE_ADDRESS } from '../../../services/data-layer';
import AddressErrorModal from '../../vehicle-registration/address-error-modal';

const PoCToAddressFormat = (poc) => {
  const {
    primaryAddress: address,
    secondaryAddress: address2,
    city,
    stateCode: state,
    postalCode: zip,
    countryCode,
  } = poc;

  return {
    address: address ?? '',
    address2: address2 ?? '',
    city,
    countryCode,
    state,
    zip,
  };
};

const AddresstoPoCFormat = (a) => {
  const {
    address: primaryAddress,
    address2: secondaryAddress,
    city,
    state: stateCode,
    zip: postalCode,
  } = a;

  return {
    primaryAddress,
    secondaryAddress,
    city,
    stateCode,
    postalCode,
  };
};

export default function addressValidator({ onSavenClose, openEditAddress }) {
  const [poc, validateAddress] = useState(null);
  const [userAddress, setUserAddress] = useState(null);
  const [validatedAddress, setValidatedAddress] = useState(null);

  const [uspsValidation, { data, error, loading }] = useLazyQuery(
    VALIDATE_ADDRESS,
    {
      fetchPolicy: 'no-cache',
    },
  );

  useEffect(() => {
    if (poc) {
      const addressPoC = PoCToAddressFormat(poc);
      setUserAddress(addressPoC);
      const { countryCode } = poc;
      if (countryCode === 'US') {
        uspsValidation({
          variables: addressPoC,
        });
      } else if (countryCode !== 'US') {
        onSavenClose(addressPoC);
      }
    }
  }, [poc]);

  useEffect(() => {
    if (data?.validateAddress) {
      const { updates, __typename, ...addrs } = data.validateAddress;
      setValidatedAddress(addrs);
    }
  }, [data]);

  const handleSaveClose = () => {
    setUserAddress(null);
    onSavenClose();
  };

  const ConfirmAddress = ({ setAddress }) => {
    return (
      <>
        <AddressConfirmationModal
          addressToValidate={userAddress}
          validatedAddress={validatedAddress}
          saveButtonText={'Save and close'}
          onSave={(d) => {
            setValidatedAddress(null);
            setAddress && setAddress(AddresstoPoCFormat(d));
          }}
        />
        {userAddress && (
          <AddressErrorModal
            addressToValidate={userAddress}
            error={error}
            validatedAddress={validatedAddress}
            onSavenClose={handleSaveClose}
            setUserAddress={setUserAddress}
            openEditAddress={openEditAddress}
          />
        )}
      </>
    );
  };

  return [ConfirmAddress, validateAddress, loading];
}
