import React from 'react';
import { StatusBadge } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';

const RESERVATION_STATUS_COLORS = {
  DUE: 'Warning-White',
  UpToDate: 'Ready-White',
  OVERDUE: 'Urgent-White',
};

const RESERVATION_STATUS = {
  DUE: 'Due',
  UpToDate: 'Up to date',
  OVERDUE: 'Overdue',
};

export default function PmStatusBadge({ status }) {
  return (
    <StatusBadge variant={RESERVATION_STATUS_COLORS[status]}>
      {RESERVATION_STATUS[status]}
    </StatusBadge>
  );
}

PmStatusBadge.propTypes = {
  status: PropTypes.string.isRequired,
};
