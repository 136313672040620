/* eslint-disable react/prop-types */
import React from 'react';
import { Accordion, Button } from '@gsa/afp-component-library';

const ReviewList = ({ needToReviewRowsState, setShowFinalAlertBox }) => {
  return (
    <>
      <div className="display-flex flex-justify padding-y-2 ">
        <div>
          <h4>2.Review and add selected accounts</h4>
        </div>
      </div>

      <Accordion
        bordered
        items={[
          {
            title: (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {needToReviewRowsState?.length} account(s) selected
              </div>
            ),
            content: (
              <div className=" bg-blue-5">
                {needToReviewRowsState.map((x) => {
                  return (
                    <div className="grid-row border-bottom border-base-lighter padding-y-1">
                      <div className="grid-col-5 grid-offset-1">
                        {x?.accountName}
                      </div>
                      <div className="grid-col-5 grid-offset-1">
                        {x?.primaryContactAssociation?.pointOfContact
                          ?.pocEmailAddress ?? ''}
                      </div>
                    </div>
                  );
                })}
              </div>
            ),
            expanded: false,
            id: 'review-list',
          },
        ]}
      />

      <div className="display-flex flex-justify padding-y-2 ">
        <div>
          <Button
            type="button"
            label="Add selected accounts"
            onClick={() => {
              setShowFinalAlertBox(true);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default ReviewList;
