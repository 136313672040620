import { useLazyQuery, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useModal } from '@gsa/afp-component-library';
import React, {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';
// import useCanPerformActions from 'hooks/use-can-perform-actions';
import { GET_ZONES_CENTERS } from 'services/data-layer/fed-hierarchy.gql';
import { GET_PM_LIST, REPORT_PMS } from './pm-express.gql';
import {
  UPDATE_PM_SUCCESS_MESSAGE,
  UPDATE_PM_FAIL_MESSAGE,
  NO_MODIFIED_PMS,
} from './messages';
import { formatModifiedPms, scrollInto } from './helpers/utils';

export const PmExpressContext = createContext();

export const PM_LISTING_MODAL_MODES = {
  ADD_PM_LISTING: 'ADD_MOTOR_POOL_LISTING',
};

const initialState = {
  pmList: {
    rows: [],
    hasMore: false,
    count: 0,
  },
  alertMessage: { context: '', message: '', type: '', header: null }, // type => error || success || info e.g.: => {type: 'error', 'message': 'Something went wrong', context: 'motorPoolListing'}
  modalState: {
    currentModalName: null,
    currentMode: null,
    currentModalAlert: { type: null, message: null, header: null },
  },
  modifiedPms: [],
  interceptSave: false,
  resetPms: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_PM_LIST':
      return { ...state, pmList: action.payload };
    case 'SET_SELECTED_PM_BY_ID':
      return { ...state, selectedPmById: action.payload };
    case 'SET_SELECTED_PM':
      return { ...state, pmListingSelected: action.payload };
    // case 'SET_PM_VEHICLE_MODAL_MODE': {
    //   return { ...state, motorPoolVehicleModalMode: action.payload };
    // }
    case 'SET_SELECTED_PM_IDS':
      return {
        ...state,
        pmIdsSelected: action.payload,
      };
    case 'SET_MODIFIED_PMS':
      return {
        ...state,
        modifiedPms: action.payload,
      };
    case 'SET_INTERCEPT_SAVE':
      return {
        ...state,
        interceptSave: action.payload,
      };
    case 'RESET_PMS':
      return {
        ...state,
        resetPms: action.payload,
      };
    case 'SET_MODAL':
      return {
        ...state,
        modalState: { ...state.modalState, ...action.payload },
      };
    case 'SET_ALERT_MESSAGE':
      return { ...state, alertMessage: action.payload };
    case 'SET_SELECTED_PM_FOR_UPLOAD':
      return { ...state, selectedPmForUpload: action.payload };
    case 'SET_OPTIONS_DATA_ZONES': {
      const optionData = {
        ...state.optionsData,
        zones: action.payload.zones,
        fmcsWithZoneAndRegion: action.payload.fmcsWithZoneAndRegion,
      };
      return { ...state, optionsData: optionData };
    }
    default:
      throw new Error('Invalid action');
  }
};

function PmExpressProvider({ children, ...props }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [localMergedFilter, setLocalMergedFilter] = useState();
  const dispatchAction = (type, payload) => {
    dispatch({
      type,
      payload,
    });
  };

  const resetModal = () => {
    // console.log('reset modal');
  };

  const setRequestError = (requestError, context) => {
    dispatchAction('SET_ALERT_MESSAGE', {
      type: 'error',
      message: requestError?.message,
      error: requestError,
      context,
    });
  };

  const setRequestMessage = (message, context) => {
    dispatchAction('SET_ALERT_MESSAGE', {
      type: 'success',
      message: message?.message,
      header: 'Export Initiated:',
      context,
    });
  };

  // Get pm express list.
  const [getPmList, { refetch: refetchPmList, loading: pmListLoading }] =
    useLazyQuery(GET_PM_LIST, {
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
      onError: (error) => setRequestError(error, 'pmListing'),
      onCompleted: (responseData) => {
        if (responseData?.getPmExpressList) {
          dispatchAction('SET_PM_LIST', responseData.getPmExpressList);
        }
      },
    });

  const [reportPms, { loading: reportingPm }] = useMutation(REPORT_PMS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: (error) => setRequestError(error, 'pmListing'),
    onCompleted: (response) => {
      if (response.reportPms.length > 0) {
        refetchPmList();
        dispatchAction('SET_ALERT_MESSAGE', {
          type: 'success',
          message: UPDATE_PM_SUCCESS_MESSAGE.body(),
        });
      }
    },
  });

  const {
    isOpen: isPmInvoiceModalOpen,
    openModal: openPmInvoiceModal,
    closeModal: closePmInvoiceModal,
  } = useModal();

  const saveAllPms = () => {
    const formattedPms = formatModifiedPms(state.modifiedPms);

    if (formattedPms?.payload?.length === 0) {
      dispatchAction('SET_ALERT_MESSAGE', {
        type: 'error',
        message: NO_MODIFIED_PMS.body(),
        context: 'pm-listing',
      });
      scrollInto('main-content');
    } else if (formattedPms?.isValid) {
      dispatchAction('SET_ALERT_MESSAGE', { type: '', message: '' });
      reportPms({
        variables: {
          reportPmsFields: formattedPms?.payload,
        },
      });
    } else {
      dispatchAction('SET_ALERT_MESSAGE', {
        type: 'error',
        message: UPDATE_PM_FAIL_MESSAGE.body(),
        context: 'pm-listing',
      });
      scrollInto('main-content');
    }
  };

  const [getZones] = useLazyQuery(GET_ZONES_CENTERS, {
    fetchPolicy: 'network-only', // Used for first execution
    nextFetchPolicy: 'cache-first', // Used for subsequent executions
    onError: (error) => setRequestError(error),
    onCompleted: (responseData) => {
      if (responseData?.getZones) {
        const zones = [];
        const fmcsWithZoneAndRegion = [];
        const zoneOptions = responseData?.getZones.map((zone) => ({
          value: zone.id,
          label: zone.name,
        }));
        zones.push(...zoneOptions);

        const fmcOptions = responseData?.getZones.flatMap((zone) =>
          zone.centers.map((center) => ({
            value: `${zone.id}:${center.id}:${center.regionId}`,
            label: center.name,
            zoneId: zone.id,
            regionId: center.regionId,
            centerId: center.id,
          })),
        );
        fmcsWithZoneAndRegion.push(...fmcOptions);

        dispatchAction('SET_OPTIONS_DATA_ZONES', {
          zones,
          fmcsWithZoneAndRegion,
        });
      }
    },
  });

  useEffect(() => {
    getZones();
  }, []);

  return (
    <PmExpressContext.Provider
      value={{
        ...state,
        getPmList,
        pmListLoading,
        dispatchAction,
        resetModal,
        setRequestError,
        setRequestMessage,
        reportPms,
        reportingPm,
        saveAllPms,
        isPmInvoiceModalOpen,
        openPmInvoiceModal,
        closePmInvoiceModal,
        localMergedFilter,
        setLocalMergedFilter,
        ...props,
      }}
    >
      {children}
    </PmExpressContext.Provider>
  );
}

export default PmExpressProvider;

PmExpressProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export const usePmExpress = () => useContext(PmExpressContext);
