import React from 'react';
import { Alert, Spinner } from '@gsa/afp-component-library';
import LicensePlateDestructionModal from './license-plate-destruction-modal';
import { useLicensePlateDestruction } from '../../license-plate-destruction/context';

export default function LicensePlateInitDestruction({
  editing,
  onClose,
  onSave,
}) {
  const {
    forDestructionList,
    ineligibleDestructionList,
    destructionInitData,
    setDestructionInitData,
    onGetTagsByExpirationDateLoading,
  } = useLicensePlateDestruction();

  const getAlertMessage = (statusDescription, count) => {
    return (
      <Alert type="warning">
        {' '}
        {count} license plates <span className="text-bold">cannot </span>
        be added for destruction because their status is currently{' '}
        <span className="text-bold">{statusDescription}</span>.
        {statusDescription === 'pending destruction' && (
          <>
            <br />
            If you have questions about the status of these records, please
            contact UNICOR at <a mailto="UNICOR@DOJ.com">UNICOR@DOJ.com</a>.
          </>
        )}
      </Alert>
    );
  };

  const getGTagAlertMessage = (statusDescription, count) => {
    return (
      <Alert type="warning">
        {' '}
        {count} license plate{count > 1 ? 's' : ''}
        <span className="text-bold"> cannot </span>
        be added for destruction because{' '}
        {count > 1 ? 'they are' : 'the plate is'} owned by GSA Fleet. Please
        contact your Fleet Services Representative (FSR) for more information.
      </Alert>
    );
  };

  const title = onGetTagsByExpirationDateLoading ? (
    <Spinner className="padding-y-9" />
  ) : (
    <div className="margin-top-2 margin-right-8">
      {ineligibleDestructionList?.shipped > 0 &&
        getAlertMessage('shipped', ineligibleDestructionList?.shipped)}
      {ineligibleDestructionList?.pendingDestruction > 0 &&
        getAlertMessage(
          'pending destruction',
          ineligibleDestructionList?.pendingDestruction,
        )}
      {ineligibleDestructionList?.destroyed > 0 &&
        getAlertMessage('destroyed', ineligibleDestructionList?.destroyed)}
      {ineligibleDestructionList?.GTag > 0 &&
        getGTagAlertMessage('G-Tag', ineligibleDestructionList?.GTag)}
      <h2>
        {forDestructionList.length} license plate
        {forDestructionList.length > 1 && 's'} will be added for destruction
      </h2>
    </div>
  );

  return (
    <LicensePlateDestructionModal
      editing={editing}
      onClose={onClose}
      onSave={onSave}
      title={title}
      destructionInitData={destructionInitData}
      setDestructionInitData={setDestructionInitData}
      forDestructionList={forDestructionList}
      onGetTagsByExpirationDateLoading={onGetTagsByExpirationDateLoading}
    />
  );
}
