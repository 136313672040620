import React, { useRef, forwardRef, useEffect } from 'react';
import { Alert as AfpAlert } from '@gsa/afp-component-library';
import './alert.css';

const constructLabels = (errFieldArray, accessorToLabel) => {
  return errFieldArray.reduce((labels, field) => {
    if (accessorToLabel && accessorToLabel[field]) {
      labels.push(accessorToLabel[field]);
    }
    return labels;
  }, []);
};

const Alert = forwardRef(({ errors, accessorToLabel }, ref) => {
  const errFields = Object.keys(errors);
  if (errFields.length > 0) {
    return (
      <AfpAlert type="error" heading="Error">
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
        <span className="display-block" ref={ref} tabIndex="0">
          <div className="margin-x-05">Field(s) below need your attention:</div>
          <ul className="usa-list usa-list--unstyled display-flex flex-wrap">
            {constructLabels(errFields, accessorToLabel).map((label) => (
              <li className="err-field-label margin-x-05" key={label}>
                <strong>{label}</strong>
              </li>
            ))}
          </ul>
        </span>
      </AfpAlert>
    );
  }
  return null;
});
export default Alert;

export const useAlert = () => {
  const fieldList = useRef(null);
  const scroll = useRef(false);
  const AlertWithRef = ({ ref, ...props }) => {
    useEffect(() => {
      if (scroll.current && fieldList.current) {
        // eslint-disable-next-line no-unused-expressions
        fieldList.current.scrollIntoView &&
          fieldList.current.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
            inline: 'nearest',
          });
        fieldList.current.focus();
        scroll.current = false;
      }
    }, [fieldList.current, scroll.current]);
    return (
      <Alert ref={fieldList} {...props}>
        {props.children}
      </Alert>
    );
  };
  const scrollToRef = () => {
    scroll.current = true;
  };
  return [AlertWithRef, scrollToRef];
};
