const TABLE_HEADER = [
  {
    Header: 'Order number',
    accessor: 'orderNumber',
    headerClassName: 'width-card-lg',
  },
  {
    Header: 'Date ordered',
    accessor: 'createdAt',
    headerClassName: 'width-card-lg',
  },
  {
    Header: 'Date shipped',
    accessor: 'processedAt',
    headerClassName: 'width-card-lg',
  },
  {
    Header: 'Status',
    accessor: 'orderStatusCode',
  },
  {
    Header: 'Point of contact',
    accessor: 'pocName',
  },
  { Header: 'Actions', accessor: 'action', sortable: false },
];

export default TABLE_HEADER;
