import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMotorPool } from '../motor-pool-provider';

const MotorPoolListingDelete = () => {
  const { deleteMotorPool, motorPoolListingSelected } = useMotorPool();
  const methods = useForm();

  const onDelete = () => {
    deleteMotorPool({
      variables: {
        id: motorPoolListingSelected.id,
      },
    });
  };

  return (
    <FormProvider {...methods}>
      <form
        data-testid="motor-pool-listing-delete-form"
        id="motor-pool-listing-delete-form"
        onSubmit={methods.handleSubmit(onDelete)}
      />
      <div className="display-flex flex-row margin-top-1">
        <div>
          Deleting the pool cannot be undone and all reservations and users of
          the pool will no longer access the pool.
        </div>
      </div>
    </FormProvider>
  );
};

export default MotorPoolListingDelete;
