import React from 'react';
import { Button, Icon } from '@gsa/afp-component-library';

function PrevButton({ ...props }) {
  return (
    <Button
      type="button"
      className="afp-vehicle-prev-btn"
      {...props}
      variant="outline"
      leftIcon={{ name: 'arrow_back' }}
      label="Previous"
    />
  );
}

export default PrevButton;
