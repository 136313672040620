/* eslint-disable filenames/match-exported */

import React from 'react';
import { useParams } from 'react-router-dom';
import { RecallCampaignDetailPageProvider } from './providers';
import RecallCampaignDetailPage from './recall-campaign-detail-page';
import RecallCampaignDetailModal from './recall-campaign-detail-modal';

const RecallCampaignDetail = () => {
  // eslint-disable-next-line prefer-const
  let { id } = useParams();

  return (
    <RecallCampaignDetailPageProvider>
      <RecallCampaignDetailPage campaignId={id} />
      <RecallCampaignDetailModal />
    </RecallCampaignDetailPageProvider>
  );
};

export default RecallCampaignDetail;
