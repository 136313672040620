import { FilterTableFrame } from '@gsa/afp-component-library';
import React, { useMemo } from 'react';
import ReservationsListingTable from './reservations-listing-table';
import ReservationsSidebar from './reservations-sidebar';

const ReservationsListing = () => {
  const FilterGrid = useMemo(
    () =>
      FilterTableFrame(
        null,
        null,
        ReservationsSidebar,
        ReservationsListingTable,
      ),
    [],
  );

  return <FilterGrid filterToggle />;
};

export default ReservationsListing;
