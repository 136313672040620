import React, { useState, useEffect } from 'react';
import { Breadcrumbs, PageTitle } from '@gsa/afp-component-library';
import { useLicensePlate } from '../context';
import useUser from '../../../utilities/use-user';
import LpDisassociation from '../../license-plate-details/widgets/lp-disassociation';
import LpReconciliation from '../../license-plate-details/widgets/lp-reconciliation';
import LicensePlateInitDestruction from './license-plate-init-destruction';
import useLicensePlateDestructionManager from './helpers/license-plate-destruction-manager';
import ServerErrorMessage from '../../error-handlers/server-error-message';

const trail = [<a href={`${window.AFP_CONFIG.appURLs.home}/home`}>Home</a>];

const fsrEmailIsMe = (user, filters) => {
  if (
    filters[0] &&
    filters[0].value[1] &&
    Array.isArray(filters[0].value[1].value)
  ) {
    const fsrFilter = filters[0].value[1].value.find(
      (f) => f.key === 'customerpointofcontactemailaddress',
    );
    if (fsrFilter && fsrFilter.value === user.email) {
      return true;
    }
    return false;
  }
  return false;
};

export default function Header({ Alert }) {
  const { userContext } = useUser();
  const {
    filters,
    refetchLicensePlates,
    disassociationAlert,
    disassociationPayload,
    setDisassociationPayload,
    disassociateTag,
    reconcileTag,
    reconciliationAlert,
    reconciliationPayload,
    setReconciliationPayload,
    nestedLicensePlateError,
  } = useLicensePlate();

  const {
    toggleDestructionEditing,
    destructionEditing,
    saveDestruction,
    destructionInitData,
    setDestructionInitData,
  } = useLicensePlateDestructionManager();

  const [fsrIsMe, setFsrIsMe] = useState(false);

  useEffect(() => {
    setFsrIsMe(fsrEmailIsMe(userContext?.currentUser, filters));
  }, [filters]);

  useEffect(() => {
    if (
      disassociationPayload?.disassociatedTag ||
      reconciliationPayload?.reconciledTags
    ) {
      refetchLicensePlates();
    }
  }, [
    disassociationPayload?.disassociatedTag,
    reconciliationPayload?.reconciledTags,
  ]);

  return (
    <header>
      <Breadcrumbs trail={trail} current="License Plate Inventory" />
      {nestedLicensePlateError && (
        <ServerErrorMessage graphQLErrors={nestedLicensePlateError} />
      )}
      <Alert />
      <LpDisassociation
        disassociationPayload={disassociationPayload}
        setDisassociationPayload={setDisassociationPayload}
        disassociateTag={disassociateTag}
        disassociationAlert={disassociationAlert}
      />
      <LpReconciliation
        reconciliationPayload={reconciliationPayload}
        setReconciliationPayload={setReconciliationPayload}
        reconcileTag={reconcileTag}
        reconciliationAlert={reconciliationAlert}
      />
      <LicensePlateInitDestruction
        destructionInitData={destructionInitData}
        setDestructionInitData={setDestructionInitData}
        editing={destructionEditing}
        onClose={toggleDestructionEditing}
        onSave={saveDestruction}
      />
      <PageTitle title="License Plate Inventory" />
    </header>
  );
}
