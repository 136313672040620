/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { Button } from '@gsa/afp-component-library';
import usePortalModal from 'utilities/portal-modal';

const DeleteMileageModal = ({ vehicle, onSave, onClose, actionData }) => {
  const [MileageModal, openModal, closeModal] = usePortalModal();

  useEffect(() => {
    openModal();
  }, []);

  const save = () => {
    onSave(
      vehicle?.uuid,
      actionData.mileageDate,
      actionData.daysInUse,
      actionData.odometer,
      'delete',
    );
    closeModal();
  };

  const close = () => {
    // eslint-disable-next-line no-unused-expressions
    onClose && onClose();
    closeModal();
  };

  const ModalHeader = () => {
    return (
      <>
        <h2>Delete confirmation</h2>
        <p className="font-body-xs margin-bottom-10">
          Are you sure you want to delete the mileage record?
        </p>
      </>
    );
  };

  return (
    <MileageModal
      actions={
        <>
          <Button variant="unstyled" label="Cancel" onClick={close} />
          <Button
            variant="secondary"
            className="margin-left-2"
            onClick={save}
            label="Delete"
          />
        </>
      }
      title={<ModalHeader />}
      onClose={close}
    />
  );
};

export default DeleteMileageModal;
