import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import _ from 'lodash';
import { useCurrentUser } from '@gsa/afp-shared-ui-utils';
import { Comments, Spinner } from '@gsa/afp-component-library';
import {
  GET_LP_COMMENTS,
  CREATE_LP_COMMENT,
  DELETE_LP_COMMENT,
} from '../../../services/data-layer/comments';
import { useLicensePlateDetails } from '../license-plate-details-provider';

const LpComments = () => {
  const { tagDetails, refetch: refetchTagDetails } = useLicensePlateDetails();

  const { currentUser } = useCurrentUser();

  const [comments, setComments] = useState({
    rows: [],
    count: 0,
    hasMore: false,
  });
  const [showAllComments, setShowAllComments] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState({});

  const {
    loading: loadingComments,
    data: commentsData,
    refetch: refetchComments,
  } = useQuery(GET_LP_COMMENTS, {
    variables: {
      id: _.get(tagDetails, 'id'),
      order: [['createdAt', 'DESC']],
      offset: 0,
      limit: showAllComments ? comments.count : 5,
    },
    fetchPolicy: 'no-cache',
  });

  const [createComment, { loading: submittingComment }] = useMutation(
    CREATE_LP_COMMENT,
    {
      onError: (error) => {
        setSubmissionStatus({
          action: 'add',
          status: 'fail',
          message: `An unexpected error has occurred: ${error.message}`,
        });
      },
      onCompleted: () => {
        refetchComments();
        refetchTagDetails();
        setSubmissionStatus({
          action: 'add',
          status: 'success',
          message: '',
        });
      },
    },
  );

  const [deleteComment, { loading: deletingComment }] = useMutation(
    DELETE_LP_COMMENT,
    {
      onError: (error) => {
        setSubmissionStatus({
          action: 'delete',
          status: 'fail',
          message: `${error.message}`,
        });
      },
      onCompleted: (deleteRespose) => {
        if (deleteRespose && deleteRespose.deleteTagComment) {
          refetchComments();
          refetchTagDetails();
          setSubmissionStatus({
            action: 'delete',
            status: 'success',
            message: '',
          });
        }
      },
    },
  );

  useEffect(() => {
    if (commentsData) {
      setComments(commentsData.getTagComments);
    }
  }, [commentsData]);

  const submitComment = (comment) => {
    const contextId = _.get(tagDetails, 'id');
    createComment({
      variables: {
        id: contextId,
        comment,
      },
    });
  };

  const removeComment = (id) => {
    deleteComment({
      variables: { id },
    });
  };

  return (
    <>
      <Comments
        comments={comments}
        showAllComments={(value) => setShowAllComments(value)}
        loadingComments={loadingComments}
        submitComment={(comment) => submitComment(comment)}
        submittingComment={submittingComment}
        deleteComment={(commentId) => removeComment(commentId)}
        deletingComment={deletingComment}
        submissionStatus={submissionStatus}
        currentUser={currentUser.id}
        allowChanges
      />
      {(submittingComment || deletingComment) && (
        <Spinner aria-busy="true" className="loading_backdrop" size="large" />
      )}
    </>
  );
};

export default LpComments;
