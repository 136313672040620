/* eslint-disable no-prototype-builtins */
import moment from 'moment';

export const formatModifiedMileageExpress = (data, wholeData) => {
  const mileageExpressList = [...data];

  const formattedMileageExpressList = [];
  let isValidData = true;
  mileageExpressList.forEach((mileageExpress) => {
    if (
      (mileageExpress?.errors.hasOwnProperty('currentMileage') &&
        !mileageExpress?.errors?.currentMileage) ||
      (mileageExpress?.errors.hasOwnProperty('daysInUse') &&
        !mileageExpress?.errors?.daysInUse)
    ) {
      isValidData = false;
    }
    if (mileageExpress?.modified) {
      const formattedData = {
        odometer: Number(mileageExpress?.currentMileage),
        daysInUse: Number(mileageExpress?.daysInUse),
        assetId: mileageExpress.assetId,
        assetMileageSourceId: 4,
        customerId: mileageExpress?.vehicle?.customer.customerId,
        mileageReportingStatus: mileageExpress?.mileageReportingStatus,
        sourceType: mileageExpress?.sourceType,
      };
      const previousData = wholeData?.rows.find(
        ({ assetId }) => assetId === mileageExpress.assetId,
      );
      if (previousData?.account1 !== mileageExpress?.account1)
        formattedData.account1 = mileageExpress?.account1;
      if (previousData?.account2 !== mileageExpress?.account2)
        formattedData.account2 = mileageExpress?.account2;
      if (
        mileageExpress?.fundCode &&
        previousData.vehicle?.fundCode !== mileageExpress?.fundCode
      )
        formattedData.fundCode = mileageExpress?.fundCode;

      formattedMileageExpressList.push(formattedData);
    }
  });

  return {
    isValid: isValidData,
    payload: formattedMileageExpressList,
  };
};

export const scrollInto = (elementId) => {
  const element = document.getElementById(elementId);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
    });
  }
};

export const isAcceptedDateFormat = (date) => {
  return (
    moment(date, 'M/D/YY', true).isValid() ||
    moment(date, 'MM/D/YY', true).isValid() ||
    moment(date, 'M/DD/YY', true).isValid() ||
    moment(date, 'MM/DD/YY', true).isValid() ||
    moment(date, 'M/D/YYYY', true).isValid() ||
    moment(date, 'MM/D/YYYY', true).isValid() ||
    moment(date, 'M/DD/YYYY', true).isValid() ||
    moment(date, 'MM/DD/YYYY', true).isValid() ||
    moment(date, moment.ISO_8601, true).isValid()
  );
};

export const fileTypes = ['.pdf', '.jpeg', '.jpg', '.png', 'bmp'];
export const title = 'Import USCAN / SAP files';
