/* eslint react/prop-types: 0 */
import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import {
  RadioButton,
  RequiredFieldIndicator,
  Label,
} from '@gsa/afp-component-library';
import { useExport } from '../export-provider';

export const VehicleOwnership = ({ control, setValue }) => {
  const { ownership, setOwnership, setIsScheduled } = useExport();

  useEffect(() => {
    if (ownership.defaultValue) {
      setValue('vehicle_options', ownership.defaultValue);
    }
  }, [ownership?.defaultValue]);

  const { ownershipOptions, defaultValue } = ownership || {};

  return (
    <>
      <div className="usa-form-group mobile-lg:grid-col-10">
        <Label className="text-bold">
          Vehicle ownership options <RequiredFieldIndicator />
        </Label>
        <Controller
          control={control}
          name="vehicle_options"
          defaultValue={defaultValue === 'AO' ? 'AO' : 'GF'}
          rules={{ required: 'This is a required field' }}
          render={({
            field: { onChange, name, value },
            formState: { errors },
          }) => (
            <>
              {ownershipOptions?.length > 0 &&
                ownershipOptions.map(({ radioValue, label, ...restProps }) => {
                  const key = `${label}-${radioValue}-${defaultValue}-${
                    ownershipOptions.length > 1 ? 'multi' : 'single'
                  }`;
                  const isFieldError = errors?.[name];
                  const fieldErrorMessage = isFieldError
                    ? errors[name].message
                    : null;
                  return (
                    <div
                      className={`usa-form-group ${
                        isFieldError
                          ? 'usa-form-group--error margin-left-05'
                          : null
                      }`}
                    >
                      {isFieldError && (
                        <span className="usa-error-message">
                          {fieldErrorMessage}
                        </span>
                      )}
                      <RadioButton
                        name={name}
                        key={key}
                        value={radioValue}
                        checked={radioValue === value}
                        label={<p>{label}</p>}
                        {...restProps}
                        onChange={(e) => {
                          onChange(e.target.value);
                          setOwnership({
                            ...ownership,
                            defaultValue: e.target.value,
                          });
                          setValue('reportType', 'DEFAULT');
                          setValue('schedule_options', 'no');
                          setIsScheduled('no');
                        }}
                      />
                    </div>
                  );
                })}
            </>
          )}
        />
      </div>
    </>
  );
};
