import { useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { VERIFY_VIN } from '../../../services/data-layer';

export const cleanVin = (vin) =>
  vin.replace(/[~`!#$%^&*+@=[\]\\';,/{}()_.|\\":<>?]/g, '');

export const isCompleteValidVin = (vin) => {
  return !/[~`!#$%^&*+=\-[\]\\';,/{}|\\":<>?]/g.test(vin);
};

export default function useVerifyVin() {
  const [errorMsg, setErrorMsg] = useState(null);
  const [verifyVIN, { data = null, loading }] = useLazyQuery(VERIFY_VIN, {
    fetchPolicy: 'no-cache',
    onError(err) {
      setErrorMsg(err);
    },
  });

  const resetError = () => {
    setErrorMsg(null);
  };

  const verifyVin = (vin) => {
    if (isCompleteValidVin(vin)) {
      verifyVIN({ variables: { id: vin } });
    }
  };
  return [verifyVin, data, loading, errorMsg, resetError, setErrorMsg];
}
