/* eslint-disable filenames/match-exported */

import React, { useEffect, useState } from 'react';
import { Spinner } from '@gsa/afp-component-library';
import { Redirect } from 'react-router-dom';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import { canUpdateGFVehicle } from 'utilities/authorization';
import useDataController from './model/use-data-controller';
import BulkImport from './bulk-import';
import { VMSOperations, VMSSubjects } from '../../../../utilities/consts';

const BulkImportPage = () => {
  const ability = useAppAbility();
  const hasGFUpdateAbility = canUpdateGFVehicle(ability);
  
  const [agencies, setAgencies] = useState();
  const [internalError, setInternalError] = useState();
  const { query, loading } = useDataController({
    setInternalError,
    setAgencies,
  });

  useEffect(() => {
    query.agenciesQuery({
      variables: {
        operation: hasGFUpdateAbility
          ? VMSOperations.UPDATE_VEHICLE_GF
          : VMSOperations.Update,
        subject: VMSSubjects.VEHICLE,
        // optin not required for GF vehicles
        feature: hasGFUpdateAbility ? undefined : 'Basic',
      },
    });
  }, []);

  if (loading.agenciesQueryLoading) {
    return <Spinner size="small" data-testid="agencies-query-spinner" />;
  }

  if (agencies?.length === 0 && !internalError) {
    return <Redirect to="/unauthorized" />;
  }

  return <BulkImport agencies={agencies} internalError={internalError} />;
};

export default BulkImportPage;
