import React from 'react';
import { useVehicle } from 'components/vehicle-details/vehicle-context-provider';
import Valuation from './components/valuation';
import { DisposalInfo } from '../vehicle-overview/components';
import { canViewGFVehicleBM, canViewAOVehicles } from 'utilities/authorization';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';

const VehicleValuations = () => {
  const { vehicle } = useVehicle();
  const ability = useAppAbility();

  const canView =
    vehicle?.ownershipTypeCode === 'GF'
      ? canViewGFVehicleBM(ability)
      : canViewAOVehicles(ability);

  return (
    <div>
      {canView && (
        <div className="grid-container padding-x-0">
          <div className="grid-row grid-gap">
            <div className="tablet:grid-col-6">
              <Valuation data={vehicle} />
            </div>

            <div className="tablet:grid-col-6">
              <div className="grid-col-12 margin-bottom-4">
                <DisposalInfo data={vehicle} />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default VehicleValuations;
