// eslint-disable-next-line react/prop-types
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  AFPTable,
  EmptyState,
  Pagination,
  Spinner,
  Tooltip,
  Icon,
} from '@gsa/afp-component-library';
import { emdash } from 'components/common';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import useUser from 'utilities/use-user';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import {
  canUpdateGFVehicleAdmin,
  canUpdateGFVehicleFSR,
} from 'utilities/authorization';
import { usePmExpressFilter } from './filters/filter-provider';
import { usePmExpress } from './pm-express-provider';
import { RowSubDetail } from './row-details';
import PmStatusBadge from './pm-status-badge';
import EditableCell from '../../utilities/editable-cell';
import InterceptionModal from './interception-modal';
import UploadInvoiceContextProvider from './upload-invoice-context';
import { isAcceptedDateFormat } from './helpers/utils';
import AttachInvoiceModal from './attach-invoice-modal';
import InvoiceUpload from './invoice-upload';

const ErrorMapping = {
  invalidDate: 'Invalid date',
  futureDate: 'Date cannot be in the future.',
  DateAfterReportedPM: 'Date cannot be equal or prior to \na last reported PM.',
  twoYearsPast: 'Date cannot be more than 2 years in the past.',
  invoiceError: 'Enter the PM date and mileage \nbefore uploading an invoice.',
  invalidMileage: 'Invalid mileage',
  nonNumericMileage: 'Only numeric values are allowed.',
  mileageLessThanReportedPM:
    'Mileage must be greater than \na last reported PM.',
  mileageGraterThan9999:
    "Mileage must not be greater than 9,999 \nmiles from the vehicle's current odometer.",
};

const tableRef = React.createRef();
const initialPaginationState = {
  limit: 10,
  offset: 0,
  currentPage: 1,
  isReset: false,
};
const initialOrderState = 'pmStatus ASC';
const alphanumericRegExp = /^[0-9]+$/;

// Browser Alert on browser close or page refresh to prompt user for unsaved changes
const initBeforeUnLoad = () => {
  window.onbeforeunload = (e) => {
    e.preventDefault();
    if (e) {
      e.returnValue = '';
    }
    return '';
  };
};

const PmListingTable = () => {
  const [paginationState, setPaginationState] = useState(
    initialPaginationState,
  );
  const [order, setOrder] = useState(initialOrderState);
  const [data, setData] = useState([]);
  const [modifiedRecords, setModifiedRecords] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [skipPageReset, setSkipPageReset] = useState(false);

  const {
    getPmList,
    pmList,
    pmListLoading,
    dispatchAction,
    resetModal,
    modifiedPms,
    resetPms,
    openPmInvoiceModal,
  } = usePmExpress();

  const { filters } = usePmExpressFilter();
  const history = useHistory();

  const getData = () => {
    getPmList({
      variables: {
        limit: paginationState.limit,
        offset: paginationState.offset,
        order,
        filters,
      },
    });
  };

  window.onload = () => {
    if (modifiedPms?.length > 0) {
      initBeforeUnLoad(modifiedPms);
    }
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (modifiedPms?.length > 0) {
      initBeforeUnLoad(modifiedPms);
      const currentLocation = history?.location;
      const unListen = history.listen((location) => {
        const newLocation = location?.pathname;
        if (currentLocation?.pathname !== newLocation) {
          // eslint-disable-next-line no-alert
          if (
            window.confirm(
              'Changes you made may not be saved. Are you sure you want to leave?',
            )
          ) {
            // continue to navigate
          } else {
            window.close();
            history.push({
              pathname: currentLocation?.pathname,
              search: currentLocation?.search,
            });
          }
        }
      });
      return unListen;
      // eslint-disable-next-line no-else-return
    } else {
      window.onbeforeunload = () => {
        // remove window.onbeforeunload functionality when no modified PMs exist
      };
    }
  }, [modifiedPms]);

  useEffect(() => {
    resetModal();
  }, []);

  useEffect(() => {
    if (resetPms) {
      setData(originalData);
      setModifiedRecords(originalData);
      setPaginationState(initialPaginationState);
      setOrder(initialOrderState);
      const [field, direction] = initialOrderState.split(' ');
      tableRef.current.toggleSortBy(field, direction.toLowerCase() === 'desc');
      dispatchAction('SET_MODIFIED_PMS', []);
      dispatchAction('RESET_PMS', false);
    }
  }, [resetPms]);

  useEffect(() => {
    if (modifiedPms?.length > 0) {
      dispatchAction('SET_INTERCEPT_SAVE', true);
    } else if (filters?.conditions?.length) {
      getData();
    } else {
      dispatchAction('SET_PM_LIST', { count: 0, hasMore: false, rows: [] });
    }
  }, [paginationState, order, filters]);

  useEffect(() => {
    setPaginationState({
      ...paginationState,
      offset: 0,
      currentPage: 1,
      isReset: true,
    });
  }, [filters]);

  useEffect(() => {
    setData(pmList.rows);
    setModifiedRecords(pmList.rows);
    setOriginalData(pmList.rows);
  }, [pmList]);

  // After data changes, we turn the skip flag back off
  // so that if data actually changes when we're not
  // editing it, the page is reset
  useEffect(() => {
    setSkipPageReset(false);
    const modifiedRows = data.filter(
      (item) => item.modified && item.currentPmDate && item.currentPmMileage,
    );
    setModifiedRecords(data);
    dispatchAction('SET_MODIFIED_PMS', modifiedRows);
  }, [data]);

  useEffect(() => {
    setSkipPageReset(false);
    const modifiedRows = modifiedRecords.filter(
      (item) => item.modified && item.currentPmDate && item.currentPmMileage,
    );
    dispatchAction('SET_MODIFIED_PMS', modifiedRows);
  }, [modifiedRecords]);

  const openEditModal = (detail) => {
    dispatchAction('SET_SELECTED_PM_FOR_UPLOAD', detail);
    openPmInvoiceModal();
  };

  const { isCustomer } = useUser();

  const ability = useAppAbility();
  const canReportMoreThanThreshold =
    canUpdateGFVehicleAdmin(ability) || canUpdateGFVehicleFSR(ability);

  const getIsLocked = (detail) => detail.pmStatus === 'UpToDate';

  // eslint-disable-next-line react/prop-types
  const columns = useMemo(() => {
    const columnList = [
      {
        Header: 'License plate',
        accessor: 'vehicle.tagNumber',
        sortable: true,
      },
      {
        Header: (
          <div className="display-flex flex-align-center">
            Report PM date
            <Tooltip
              className="usa-button--unstyled"
              label={
                <ul className="usa-list margin-0">
                  <li className="text-thin">{ErrorMapping.futureDate}</li>
                  <li className="text-thin">
                    {ErrorMapping.DateAfterReportedPM}
                  </li>
                  <li className="text-thin">{ErrorMapping.invoiceError}</li>
                  {isCustomer() && (
                    <li className="text-thin">{ErrorMapping.twoYearsPast}</li>
                  )}
                </ul>
              }
              position="bottom"
            >
              <Icon
                iconName="info"
                className="text-primary margin-left-1 text-ink"
              />
            </Tooltip>
          </div>
        ),
        accessor: 'currentPmDate',
        sortable: false,
        // eslint-disable-next-line react/prop-types
        Cell: ({ value, row, column, updateCellData }) => {
          // eslint-disable-next-line react/prop-types
          if (getIsLocked(row.original)) {
            return isAcceptedDateFormat(value)
              ? moment.utc(value).format('MM/DD/YYYY')
              : emdash;
          }

          return (
            <EditableCell
              placeholder="mm/dd/yyyy"
              inputType="number"
              numberFormat="##/##/####"
              row={row}
              column={column}
              updateCellData={updateCellData}
              aria-label={`${column?.id} editable field`}
            />
          );
        },
      },
      {
        Header: (
          <div className="display-flex flex-align-center">
            Report PM mileage
            <Tooltip
              className="usa-button--unstyled"
              label={
                <ul className="usa-list margin-0">
                  <li className="text-thin">
                    {ErrorMapping.nonNumericMileage}
                  </li>
                  <li className="text-thin">
                    {ErrorMapping.mileageLessThanReportedPM}
                  </li>
                  <li className="text-thin">
                    {ErrorMapping.mileageGraterThan9999}
                  </li>
                </ul>
              }
              position="bottom"
            >
              <Icon
                iconName="info"
                className="text-primary margin-left-1 text-ink"
              />
            </Tooltip>
          </div>
        ),
        accessor: 'currentPmMileage',
        sortable: false,
        // eslint-disable-next-line react/prop-types
        Cell: ({ value, row, column, updateCellData }) => {
          // eslint-disable-next-line react/prop-types
          if (getIsLocked(row.original)) {
            return `${value ? value?.toLocaleString() : emdash} ${
              value ? 'mi' : ''
            }`;
          }
          return (
            <>
              <EditableCell
                row={row}
                column={column}
                updateCellData={updateCellData}
                aria-label={`${column?.id} editable field`}
              />
              <InvoiceUpload
                row={row}
                updateCellData={updateCellData}
                openEditModal={openEditModal}
              />
            </>
          );
        },
      },
      {
        Header: 'PM due date',
        accessor: 'nextPmDate',
        sortable: true,
        // eslint-disable-next-line react/prop-types
        Cell: ({ row: { original } }) =>
          moment(original?.nextPmDate).isValid()
            ? moment.utc(original?.nextPmDate).format('MM/DD/YYYY')
            : emdash,
      },
      {
        Header: 'Time since last PM',
        accessor: 'timeSincePm',
        sortable: false,
        Cell: ({ row: { original } }) =>
          original?.timeSincePm ? `${original?.timeSincePm} month(s)` : emdash,
      },
      {
        Header: 'Mileage since last PM',
        accessor: 'milesSinceLastPm',
        sortable: false,
        Cell: ({ row: { original } }) =>
          original?.milesSinceLastPm
            ? `${original?.milesSinceLastPm.toLocaleString()} (mi/km)`
            : emdash,
      },
      {
        Header: 'Status',
        accessor: 'pmStatus',
        sortable: true,
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => {
          return value ? <PmStatusBadge status={value} /> : emdash;
        },
      },
    ];

    return columnList;
  }, []);

  const renderRowSubComponent = useCallback(({ row: { original } }) => {
    const {
      vehicle,
      pmSchedule,
      currentPmDate,
      currentPmMileage,
      methodOfEntry,
      createdAt,
      updatedAt,
      assetPmId,
      recall,
    } = original;
    return (
      <div className="display-flex flex-justify-center">
        <div className="grid-col-11">
          <RowSubDetail
            detail={{
              recall,
              vehicle,
              currentPmDate,
              currentPmMileage,
              methodOfEntry,
              createdAt,
              updatedAt,
              assetPmId,
              pmSchedule,
            }}
          />
        </div>
      </div>
    );
  }, []);

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    // Calculate new offset.
    const offset = (currentPage - 1) * itemsPerPage;
    setPaginationState({
      limit: itemsPerPage,
      offset,
      currentPage,
    });
  };

  const handleValidationError = (errorMessage) => {
    return [false, errorMessage];
  };

  const validatePmMileage = (rowIndex, value) => {
    const isValidCell = true;
    const errorMessage = '';
    const thresholdMileage = 9999;
    const mileage = Number(value);
    const previousMileage = Number(pmList?.rows[rowIndex].currentPmMileage);
    const odometer = Number(pmList?.rows[rowIndex]?.odometer);

    if (!alphanumericRegExp.test(value)) {
      return handleValidationError(ErrorMapping.invalidMileage);
    }

    if (mileage === previousMileage || mileage < previousMileage) {
      return handleValidationError(ErrorMapping.mileageLessThanReportedPM);
    }

    if (!canReportMoreThanThreshold && mileage - odometer > thresholdMileage) {
      return handleValidationError(ErrorMapping.mileageGraterThan9999);
    }

    return [isValidCell, errorMessage];
  };

  const validateActualPmDate = (rowIndex, value) => {
    const isValidCell = true;
    const errorMessage = '';
    const previousReportedPmDate = moment(
      pmList?.rows[rowIndex].currentPmDate,
    ).format('MM/DD/YYYY');

    if (!isAcceptedDateFormat(value)) {
      return handleValidationError(ErrorMapping.invalidDate);
    }

    if (moment(value).isAfter(moment(), 'day')) {
      return handleValidationError(ErrorMapping.futureDate);
    }

    if (moment(value).isSameOrBefore(previousReportedPmDate, 'day')) {
      return handleValidationError(ErrorMapping.DateAfterReportedPM);
    }

    if (moment().diff(moment(value), 'years', true) >= 2 && isCustomer()) {
      return handleValidationError(ErrorMapping.twoYearsPast);
    }

    return [isValidCell, errorMessage];
  };

  const handleCellDate = (columnId, rowIndex, value, isValueValid) => {
    const modifiedOnes = modifiedRecords.map((row, index) => {
      if (index === rowIndex) {
        return {
          ...modifiedRecords[rowIndex],
          [columnId]: isValueValid ? value : '',
          modified: Number(value) !== originalData[rowIndex][columnId],
        };
      }
      return row;
    });
    setModifiedRecords(modifiedOnes);
  };

  const validateCellData = (
    rowIndex,
    columnId,
    cellValue,
    original,
    openModalFunction = undefined,
  ) => {
    let isValidCell = true;
    let errorMessage = '';
    if (cellValue.isDirty) {
      if (columnId === 'currentPmDate') {
        setSkipPageReset(true); // turn on the flag to not reset the page
        [isValidCell, errorMessage] = validateActualPmDate(
          rowIndex,
          cellValue.value,
        );
      }
      if (columnId === 'currentPmMileage') {
        setSkipPageReset(true); // turn on the flag to not reset the page
        [isValidCell, errorMessage] = validatePmMileage(
          rowIndex,
          cellValue.value,
        );
      }
    }

    if (!errorMessage && openModalFunction) openModalFunction();

    handleCellDate(columnId, rowIndex, cellValue.value, isValidCell);

    return {
      columnId,
      rowIndex,
      value: cellValue.value,
      isValidCell,
      errorMessage,
    };
  };

  return (
    <>
      <AFPTable
        fullWidth
        ref={tableRef}
        testId="pm-listing-table"
        columns={columns}
        data={!pmListLoading ? data : []}
        defaultSort={order}
        onSort={setOrder}
        expandable
        renderRowSubComponent={renderRowSubComponent}
        updateCellData={validateCellData}
        skipPageReset={skipPageReset}
      />
      {pmListLoading && <Spinner className="padding-y-9" />}
      {pmList?.rows?.length > 0 && (
        <Pagination
          fullWidth
          variant="advanced"
          itemsPerPageOptions={[10, 25, 50]}
          itemsCount={pmList.count}
          itemsPerPage={paginationState.limit}
          currentPage={paginationState.currentPage}
          onPageChange={handlePaginationChange}
          isReset={paginationState.isReset}
        />
      )}
      {(!pmList || pmList.rows.length === 0) && !pmListLoading && (
        <div className="bg-gray-3 padding-y-5">
          <div className="text-center padding-y-4">
            <EmptyState alt="Image not available" hasBackground />
          </div>
          <div className="text-center text-bold">No data available</div>
        </div>
      )}
      <UploadInvoiceContextProvider
        setData={setData}
        setSkipPageReset={setSkipPageReset}
        modifiedRecords={modifiedRecords}
      >
        <AttachInvoiceModal />
      </UploadInvoiceContextProvider>

      <InterceptionModal />
    </>
  );
};

export default PmListingTable;
