import { gql } from '@apollo/client';

export const SERVICE_LINE_DROPDOWNS = gql`
  query {
    getAllServiceClasses {
      serviceClassId
      serviceClassCode
      serviceClassDescription
    }
    getAllServiceReasons {
      serviceReasonId
      serviceReasonCode
      serviceReasonDescription
    }
    getAllFailureCauses {
      failureCauseId
      failureCauseCode
      failureCauseDescription
    }
    getAllServiceWorkAccomplished {
      serviceWorkAccomplishedId
      serviceWorkAccomplishedCode
      serviceWorkAccomplishedDescription
    }
  }
`;

export const VENDOR_DESCRIPTIONS = gql`
  query {
    getNationalVendorServices {
      nvServiceId
      nationalVendorService
    }
  }
`;

export const GET_REPAIR_PAYMENT_LOOKUPS = gql`
  query getRepairPaymentLookups {
    getPaymentForms
    getPaymentStatus
  }
`;

export const GET_REPAIR_VENDOR_LOOKUPS = gql`
  query getRepairVendorLookups {
    getVendorDescriptions
    getStates {
      id
      stateCode
      stateName
      isoCountryCode2
    }
  }
`;

export const GET_REPAIR_VEHICLE = gql`
  query getRepairVehicle($id: String!) {
    getVehicle(id: $id) {
      id
      vin
      modelYear
      tagNumber
      makeCode
      ownershipTypeCode
      vehicleMake {
        makeName
      }
      modelCode
      vehicleModel {
        modelCode
        modelDescription: modelName
      }
      inventoryStatus {
        id
        description
      }
      newOdometerReading {
        odometer
      }
      assetTechnical {
        gvwr
      }
      agency {
        prefixedName
      }
      bureau {
        prefixedName
      }
      office {
        prefixedName
      }
    }
  }
`;

export const GET_SERVICE_CODES = gql`
  query getServiceCodes($offset: Float, $limit: Float, $searchParams: String) {
    getServiceCodes(
      offset: $offset
      limit: $limit
      searchParams: $searchParams
    ) {
      count
      hasMore
      rows {
        id
        componentOne
        componentTwo
        combinedComponent
      }
    }
  }
`;

export const GET_SERVICE_COMMON_CODES = gql`
  query getServiceCommonCodes($types: [String!]) {
    getServiceCommonCodes(types: $types) {
      type
      code
      value
    }
  }
`;

export const GET_REPAIR_ORDER_BY_ID = gql`
  query getRepairOrderById($id: Float!) {
    getRepairOrderById(id: $id) {
      id
      assetId
      invoiceNumber
      transactionDate
      serviceDate
      isTransactionComplete
      mileage
      vendor {
        id
        serviceId
        name
        description
        address1
        address2
        city
        stateCode
        postalCode
        phone
        phoneExt
      }
      payment {
        id
        serviceId
        billTo
        paymentStatus
        paymentForm
        postedDate
        totalLabor
        totalParts
        miscCost
        taxCost
        serviceTotal
      }
      comments {
        id
        serviceId
        commentType
        comment
        createdAt
        createdBy
        createdByUserInfo {
          fullName
        }
      }
      serviceLines {
        id
        serviceId
        serviceCodeId
        serviceClassCode
        serviceReasonCode
        failureCauseCode
        workAccomplishedCode
        serviceLineCosts {
          id
          serviceLineId
          type
          partsCost
          partsQuantity
          laborRate
          laborHours
          miscCost
        }
      }
    }
  }
`;

export const GET_REPAIR_SERVICE_LINES = gql`
  query getServiceLinesByServiceId($serviceId: Float!) {
    getServiceLinesByServiceId(serviceId: $serviceId) {
      id
      serviceId
      serviceCodeId
      serviceCode {
        id
        componentOne
        componentTwo
        combinedComponent
      }
      serviceClassCode
      serviceReasonCode
      failureCauseCode
      workAccomplishedCode
      serviceLineCosts {
        id
        serviceLineId
        type
        partsCost
        partsQuantity
        laborRate
        laborHours
        miscCost
        partsTotal
        laborTotal
        totalAmount
      }
    }
  }
`;

export const GET_TOTAL_COST_BY_VEHICLE = gql`
  query getTotalCostByVehicle($assetId: String!) {
    getTotalCostByVehicle(assetId: $assetId)
  }
`;

export const GET_REPAIR_ORDERS_BY_VEHICLE = gql`
  query getRepairOrdersByVehicle(
    $assetId: String!
    $offset: Float
    $limit: Float
    $order: OrderBy
    $filters: [Filter!]
  ) {
    getRepairOrdersByVehicle(
      assetId: $assetId
      offset: $offset
      limit: $limit
      order: $order
      filters: $filters
    ) {
      count
      rows {
        id
        serviceNumber
        invoiceNumber
        assetId
        transactionDate
        isTransactionComplete
        vendor {
          id
          name
        }
        payment {
          id
          totalLabor
          miscCost
          taxCost
          serviceTotal
        }
        serviceLines {
          id
          serviceCodeId
          serviceCode {
            id
            componentOne
            componentTwo
            componentThree
            componentFour
            componentFive
          }
          serviceLineCosts {
            id
            type
            totalAmount
          }
        }
        comments {
          id
          comment
          createdAt
          updatedAt
          createdByUserInfo {
            fullName
            email
          }
          updatedByUserInfo {
            fullName
            email
          }
        }
      }
    }
  }
`;

export const GET_REPAIR_SERVICE_LINES_BY_VEHICLE = gql`
  query getRepairServiceLinesByVehicle(
    $assetId: String!
    $offset: Float
    $limit: Float
    $order: OrderBy
    $filters: [Filter!]
  ) {
    getRepairServiceLinesByVehicle(
      assetId: $assetId
      offset: $offset
      limit: $limit
      order: $order
      filters: $filters
    ) {
      count
      rows {
        id
        serviceId
        serviceCodeId
        serviceCode {
          id
          componentOne
          componentTwo
          componentThree
          componentFour
          componentFive
        }
        serviceClassCode
        serviceClass
        serviceReasonCode
        serviceReason
        serviceLineCosts {
          id
          type
          totalAmount
        }
        service {
          id
          serviceNumber
          invoiceNumber
          assetId
          transactionDate
        }
      }
    }
  }
`;

export const DELETE_REPAIR_ORDER = gql`
  mutation deleteRepairOrder($id: Float!) {
    deleteRepairOrder(id: $id)
  }
`;

export const CREATE_REPAIR_ORDER = gql`
  mutation createRepairOrder(
    $service: ServiceInput!
    $serviceVendor: ServiceVendorInput!
    $servicePayment: ServicePaymentInput!
    $serviceLines: [ServiceLineInput!]!
    $serviceComments: [ServiceCommentInput!]!
  ) {
    createRepairOrder(
      service: $service
      ServiceVendor: $serviceVendor
      servicePayment: $servicePayment
      serviceLines: $serviceLines
      serviceComments: $serviceComments
    ) {
      id
    }
  }
`;

export const UPDATE_REPAIR_ORDER = gql`
  mutation updateRepairOrder(
    $service: ServiceInput!
    $serviceVendor: ServiceVendorInput!
    $servicePayment: ServicePaymentInput!
    $serviceLines: CURServiceLinesInput!
    $serviceComments: CURServiceCommentsInput!
  ) {
    updateRepairOrder(
      service: $service
      ServiceVendor: $serviceVendor
      servicePayment: $servicePayment
      serviceLines: $serviceLines
      serviceComments: $serviceComments
    )
  }
`;

export const GET_ALL_REPAIR_ORDER_BASED_ON_ASSET = gql(/* GraphQL */ `
  query GetAllRepairOrderBasedOnAsset(
    $limit: Float
    $order: OrderBy
    $offset: Float
    $filters: FilterInput
  ) {
    getAllRepairOrderBasedOnAsset(
      limit: $limit
      order: $order
      offset: $offset
      filters: $filters
    ) {
      count
      rows {
        assetId
        repairOrderNumber
        aiRoidNumber
        authorizedOn
        id
        poNumber
        vendorId
        fleetVendorId
        repairTotal
        authorizedBy
        mileage
        isEuVehicle
        repairOrderStatus
        paymentSubmittedBy {
          id
          fullName
          email
        }
        authorizedByUser {
          fullName
        }
        vendor {
          name
        }
        serviceLines {
          serviceCode {
            componentOne
            componentTwo
            componentThree
            componentFour
            componentFive
          }
          commonWorkAccomplishedReason {
            value
          }
          commonServiceReason {
            value
          }
          serviceLineCosts {
            totalAmount
            partsQuantity
            partsCost
          }
        }
      }
    }
  }
`);
