/* eslint-disable react/prop-types */
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import MotorPoolFields from './motor-pool-fields';

const MotorPoolForm = ({
  providerContext,
  formValidationMethods,
  formSchema,
  mode,
}) => {
  const methods = useForm(formValidationMethods);

  return (
    <FormProvider {...methods}>
      <form
        data-testid="formId"
        id={formSchema[mode]?.formId}
        onSubmit={methods.handleSubmit(
          formSchema[mode]?.onSubmit(providerContext),
        )}
      >
        <MotorPoolFields
          providerContext={providerContext}
          formSchema={formSchema[mode]}
        />
      </form>
    </FormProvider>
  );
};

export default MotorPoolForm;
