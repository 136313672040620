import React from 'react';
import { Button, useModal } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';
import AgencyEditModal from './agency-edit-modal';

export default function OrderAgencyEdit({
  agency,
  bureau,
  office,
  orderNumber,
  onEditAgency,
}) {
  const { isOpen, openModal, closeModal } = useModal();

  return (
    <>
      <Button
        variant="outline"
        className="bg-white margin-top-2"
        data-testid="order-agency-edit"
        aria-label="edit agency"
        onClick={() => {
          openModal();
        }}
        label="Edit"
      />
      {isOpen && (
        <AgencyEditModal
          onEditAgency={onEditAgency}
          orderNumber={{ orderNumber }}
          agency={agency}
          bureau={bureau}
          office={office}
          isOpen={isOpen}
          closeModal={closeModal}
        />
      )}
    </>
  );
}

OrderAgencyEdit.propTypes = {
  agency: PropTypes.shape(Object),
  bureau: PropTypes.shape(Object),
  office: PropTypes.shape(Object),
  orderNumber: PropTypes.string,
  canUpdateOrders: PropTypes.bool,
};
