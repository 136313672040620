import React from 'react';

import { Button, Icon } from '@gsa/afp-component-library';
import OrderNumberLookup from './order-number-lookup';

export default function OrderDetailsHeader({ orderNumber }) {
  return (
    <header>
      <div className="header-content">
        <div className="order-number tablet:display-flex flex-align-center flex-justify">
          <h1 className="title-xl">{`UNICOR Order #${orderNumber}`}</h1>
          <div className="flex-align-end">
            <OrderNumberLookup
              ariaLabel="Search by order number"
              orderNumber={orderNumber}
            />
          </div>
        </div>
        <div className="order-tracking display-none">
          <Button
            className="display-flex"
            leftIcon={{ name: 'launch' }}
            label="View tracking on FedEx website"
          />
        </div>
      </div>
    </header>
  );
}
