import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { REGISTER_VEHICLE } from '../../../services/data-layer';
import useCreatePdf from './use-create-pdf';

const falsyToFalse = (value) => !!value;
const falsyToNull = (value) => value || null;

const notTraditional = (formState) => {
  const { unverifiedVin, internationalVin, pre1981 } = formState;
  return unverifiedVin || internationalVin || pre1981;
};

const getMakeCode = (formState) => {
  const { makeCode, makeName } = formState;
  if (notTraditional(formState)) {
    return makeCode;
  }
  return makeCode;
};

const getMakeName = (formState) => {
  const { makeName } = formState;
  if (notTraditional(formState)) {
    return makeName;
  }
  return null;
};

const getModelCode = (formState) => {
  const { modelCode, modelName } = formState;
  return modelCode || modelName || null;
};

export const getAgencyCode = (formState) => {
  const { agencyCode, tagAgencyCode, selectedAgencyCode } = formState;
  return selectedAgencyCode || agencyCode || tagAgencyCode || null;
};

export const getBureauCode = (formState) => {
  const { bureauCode, tagBureauCode, selectedBureauCode } = formState;
  return selectedBureauCode || bureauCode || tagBureauCode || null;
};

export const getSubsectionCode = (formState) => {
  const { subSectionCode, selectedOfficeCode } = formState;
  return selectedOfficeCode || subSectionCode || null;
};

const getPoc = (pointOfContact) => {
  const { __typename, validated, ...poc } = pointOfContact;
  return Object.keys(poc).reduce((nulled, field) => {
    nulled[field] = falsyToNull(poc[field]);
    return nulled;
  }, {});
};

const getVinVerification = (formState) => {
  const { userVerifiedVin } = formState;
  const userVerified = {
    unverifiedVin: false,
    internationalVin: false,
    pre1981: false,
  };
  if (userVerifiedVin === 'unverifiedVin') {
    return {
      ...userVerified,
      unverifiedVin: true,
    };
  }
  if (userVerifiedVin === 'internationalVin') {
    return {
      ...userVerified,
      internationalVin: true,
    };
  }
  if (userVerifiedVin === 'pre1981') {
    return {
      ...userVerified,
      pre1981: true,
    };
  }
  return userVerified;
};

const constructPaylodForRegistration = (
  formState,
  registrationType,
  purchaseOrderNumberEntryMethod,
  purchaseOrderNumber,
) => {
  const {
    vin,
    modelYear,
    makeColorName,
    subSectionTypeCode,
    fuelCode,
    tagNumber,
    statePlate,
    tagExpirationDate,
    fastReportable,
    pointOfContact1,
    pointOfContact2,
    itemType,
    nhtsaVerified,
    exemptPlate,
    originalAcquisitionDate,
  } = formState;
  const registration = {
    vin,
    makeCode: getMakeCode(formState),
    makeName: getMakeName(formState),
    modelCode: getModelCode(formState),
    modelYear: falsyToNull(modelYear),
    makeColorName: falsyToNull(makeColorName),
    fuelCode: falsyToNull(fuelCode),
    tagNumber: falsyToNull(tagNumber),
    statePlate: falsyToNull(statePlate),
    tagExpirationDate: tagExpirationDate || null,
    agencyCode: getAgencyCode(formState),
    bureauCode: getBureauCode(formState),
    officeCode: getSubsectionCode(formState),
    subSectionTypeCode: falsyToNull(subSectionTypeCode),
    fastReportable: falsyToFalse(fastReportable),
    pointOfContact1: getPoc(pointOfContact1),
    pointOfContact2: getPoc(pointOfContact2),
    modelTypeCode: falsyToNull(itemType),
    nhtsaVerified: falsyToFalse(nhtsaVerified),
    exemptPlate: falsyToFalse(exemptPlate),
    mode: registrationType,
    purchaseOrderNumberEntryMethod,
    purchaseOrderNumber,
    originalAcquisitionDate,
  };
  if (
    registrationType === 'manual' ||
    registrationType === 'regular' ||
    registrationType === 'edit'
  ) {
    const manualRegistration = {
      ...registration,
      ...getVinVerification(formState),
      mode: registrationType,
    };
    return {
      variables: {
        registration: manualRegistration,
      },
    };
  }
  return { variables: { registration } };
};

export default function useRegisterVehicle({
  formState,
  registrationType,
  vinSource,
  purchaseOrderNumberEntryMethod,
  purchaseOrderNumber,
}) {
  const [complete, setComplete] = useState(null);
  const [registerVehicle, { data, loading, error }] = useMutation(
    REGISTER_VEHICLE,
    {
      fetchPolicy: 'no-cache',
      onError(err) {
        console.warn(err);
      },
    },
  );
  const [createPdf, { complete: pdfComplete, data: pdfUrl }] =
    useCreatePdf(formState);

  useEffect(() => {
    if (data?.registerVehicle) {
      setComplete(true);
      createPdf();
    }
  }, [data]);

  const handleRegisterVehicle = () => {
    const payload = constructPaylodForRegistration(
      formState,
      registrationType,
      purchaseOrderNumberEntryMethod,
      purchaseOrderNumber,
    );
    registerVehicle(payload);
  };

  return [
    handleRegisterVehicle,
    { data, loading, error, complete, pdfComplete, pdfUrl },
  ];
}
