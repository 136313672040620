/* eslint-disable react/prop-types */
/* eslint-disable filenames/match-exported */
import React from 'react';
import { Alert } from '@gsa/afp-component-library';

// need to make sure provider contains  bannerMsg and  setBannerMsg
const BannerMessage = ({ useProvider }) => {
  const { bannerMsg, setBannerMsg } = useProvider();

  if (!bannerMsg) return null;

  if (bannerMsg.alert) return bannerMsg.alert;

  return (
    <Alert
      type={bannerMsg.type}
      focused={bannerMsg.focused || true}
      heading={bannerMsg.heading}
      onClose={() => setBannerMsg(null)}
    >
      {bannerMsg.message}
    </Alert>
  );
};

export default BannerMessage;
