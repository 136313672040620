import React from 'react';

import { useOfficeManagement } from './office-management-provider';
import { showAlert } from './utils';

const OfficeManagementHeader = () => {
  const { alertMessage } = useOfficeManagement();
  return <>{showAlert({ alertPosition: undefined, alertMessage })}</>;
};

export default OfficeManagementHeader;
