/* eslint-disable filenames/match-exported */

import React, { useEffect } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { isNil } from 'lodash';
import ServerErrorMessage from 'components/error-handlers/server-error-message';
import { isMileageEnabled } from 'components/vehicle-details/helpers/feature-helper';
import { OptIn } from '@gsa/afp-shared-ui-utils';
import ExpenseUpdate from './expense-update';
import useDataController from './model/use-data-controller';
import { pageConfigUpdateSelector } from './model/store/selectors';

const UpdateExpense = () => {
  // eslint-disable-next-line prefer-const
  let { vin, action } = useParams();
  if (vin) {
    vin = decodeURIComponent(vin);
  }
  const { query, mutation, loading } = useDataController(vin);
  const location = useLocation();

  const vehicle = useRecoilValue(pageConfigUpdateSelector('vehicle'));
  const history = useHistory();
  useEffect(() => {
    if (!['new', 'edit'].includes(action)) {
      history.replace('/NotFound');
    }

    if (action === 'edit' && !location.state) {
      history.replace(`/vehicles/${vin}/expenses`);
    }
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {vehicle.error && <ServerErrorMessage error={vehicle.error} />}
      <ExpenseUpdate
        action={action}
        vin={vin}
        graphqlOperations={{ query, mutation }}
        loading={loading}
      />
    </>
  );
};

export default UpdateExpense;
