/* eslint-disable react/prop-types */
/* eslint-disable dot-notation */
import React from 'react';
import PropTypes from 'prop-types';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import moment from 'moment';
import { MANGE_PM_FIELDS } from './consts';
import { usePm } from './pm-provider';
import useUser from 'utilities/use-user';
import useCanPerformActions from 'hooks/use-can-perform-actions';

const generateManagePmFormSchema = (
  currentPmMileage,
  currentPmDate,
  rows,
  selectedPm,
  isCustomer,
  isEditInvoiceOnly,
) => {
  const schemaShape = isEditInvoiceOnly
    ? {}
    : {
        [MANGE_PM_FIELDS['date'].name]: yup
          .date()
          .test(
            'greater than currentDate',
            'Entry date should be greater than last reported date',
            (value) => {
              if (!value || !currentPmDate) {
                return true;
              }
              if (!selectedPm) {
                return moment(value).isAfter(moment(currentPmDate));
              }
              return true;
            },
          )
          .test(
            'not equal to any PM date',
            'Should not be equal to any PM date and not after the last reported date',
            (value) => {
              if (selectedPm) {
                // eslint-disable-next-line no-restricted-syntax
                let maximumDate = moment(rows[0].currentPmDate);
                for (const row of rows) {
                  maximumDate = maximumDate.isBefore(
                    moment(row.currentPmDate),
                    'day',
                  )
                    ? moment(row.currentPmDate)
                    : maximumDate;
                  if (
                    moment(value).isSame(moment(row.currentPmDate), 'day') &&
                    !moment(value).isSame(
                      moment(selectedPm.currentPmDate),
                      'day',
                    )
                  )
                    return false;
                }
                if (moment(value).isAfter(maximumDate, 'day')) return false;
              }
              return true;
            },
          )
          .typeError('Should be date.')
          .required('Entry date is a required field')
          .label('Entry date'),
        [MANGE_PM_FIELDS['mileage'].name]: yup
          .number()
          .test(
            'greater than previous mileage',
            'Odometer reading should be greater than the last reported mileage.',
            (value) => {
              if (!value || !currentPmMileage) {
                return true;
              }
              if (!selectedPm) {
                return value > currentPmMileage;
              }
              return true;
            },
          )
          .test(
            'less than previous mileage',
            'Mileage must not be greater than 9,999 miles from the vehicle&apos;s current odometer.',
            (value) => {
              if (!value || !currentPmMileage) {
                return true;
              }
              if (!selectedPm && isCustomer()) {
                return value < currentPmMileage + 9999;
              }
              return true;
            },
          )
          .test(
            'not equal to any PM mile',
            'Should not be equal to any PM mile or more than last reported mileage',
            (value) => {
              if (selectedPm) {
                // eslint-disable-next-line no-restricted-syntax
                let maximumMileage = Number(rows[0].currentPmMileage);
                for (const row of rows) {
                  maximumMileage =
                    maximumMileage < Number(row.currentPmMileage)
                      ? Number(row.currentPmMileage)
                      : maximumMileage;
                  if (
                    Number(value) === Number(row.currentPmMileage) &&
                    Number(value) !== selectedPm.currentPmMileage
                  )
                    return false;
                }

                if (Number(value) > maximumMileage) return false;
              }
              return true;
            },
          )
          .typeError('Should be numeric.')
          .label('Odometer reading')
          .required('Odometer reading is a required field'),
      };

  return yup.object().shape(schemaShape);
};

const ManagePmFormContext = ({ children }) => {
  const { isCustomer } = useUser();
  const { selectedPm, pmHistoryList } = usePm();
  const inputData = {
    [MANGE_PM_FIELDS['date'].name]: selectedPm?.currentPmDate || '',
    [MANGE_PM_FIELDS['mileage'].name]: selectedPm?.currentPmMileage || '',
  };

  const currentPmMileage = pmHistoryList?.rows?.[0]?.currentPmMileage;
  const currentPmDate = pmHistoryList?.rows?.[0]?.currentPmDate;

  const canPerformActions = useCanPerformActions();

  const isEditMode = !!selectedPm;

  const canEditPmField = (fieldName) =>
    canPerformActions.canEditPmField(fieldName);

  const isEditInvoiceOnly =
    isEditMode &&
    !canEditPmField(MANGE_PM_FIELDS.mileage.name) &&
    !canEditPmField(MANGE_PM_FIELDS.date.name);

  // if mode isediting and canEditPmField then remove from schema

  const methods = useForm({
    resolver: yupResolver(
      generateManagePmFormSchema(
        currentPmMileage,
        currentPmDate,
        pmHistoryList?.rows,
        selectedPm,
        isCustomer,
        isEditInvoiceOnly,
      ),
    ),
    defaultValues: inputData,
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  return <FormProvider {...methods}>{children}</FormProvider>;
};

ManagePmFormContext.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ManagePmFormContext;
