import { useState, useEffect, useCallback } from 'react';
import { FilterPanel } from '@gsa/afp-component-library';

const isValidDateRange = (from, to) => {
  if (!(from instanceof Date && to instanceof Date)) {
    return false;
  }
  if (from > to) {
    return false;
  }
  return true;
};

const useDateRange = ({ filter, errorMsg }) => {
  const { setFilters, getFilterFromState, clearOneFilter } =
    FilterPanel.useFilterPanel();
  const [dateTo, setDateTo] = useState(undefined);
  const [dateFrom, setDateFrom] = useState(undefined);
  const [error, setError] = useState(undefined);
  const { value: dateRangeFromPanel } = getFilterFromState(filter.key);

  const [from, to] = dateRangeFromPanel || [];

  // sync local state when useFilterPanel hook state updates
  useEffect(() => {
    // validate date check
    if (Array.isArray(dateRangeFromPanel) && dateRangeFromPanel.length === 2) {
      if (isValidDateRange(from, to)) {
        setDateFrom(from);
        setDateTo(to);
      }
    }
    if (dateRangeFromPanel === '') {
      setDateFrom('');
      setDateTo('');
    }
  }, [from, to]);

  const clear = () => {
    clearOneFilter(filter);
  };

  const applyFilter = useCallback(() => {
    if (dateFrom && dateTo) {
      if (isValidDateRange(dateFrom, dateTo)) {
        setError('');
        setFilters({
          type: 'setOne',
          fetchNewData: true,
          filter: { ...filter, value: [dateFrom, dateTo] },
        });
      } else {
        setError(errorMsg);
      }
    } else {
      setError('');
    }
  });

  return {
    dateFrom,
    setDateFrom,
    dateTo,
    setDateTo,
    error,
    clear,
    applyFilter,
  };
};

export default useDateRange;
