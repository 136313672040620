import React from 'react';
import { FilterPanel } from '@gsa/afp-component-library';
import { useMileageFilter } from '../providers/filter-provider';

const Sidebar = () => {
  const {
    filterStructure,
    setFilters,
    typeaheadData,
    getVehiclesByPartialVin,
    getTagsByPartialTagNumber,
    filters,
  } = useMileageFilter();

  const handleFilterChange = (conditions) => {
    setFilters(conditions);
  };

  const handleTypeaheadSearch = ({ variables }) => {
    const { conditions } = variables.filters[0];
    const query = conditions.filter((c) => c.key === variables.field)[0]?.value;
    if (variables.field === '$vehicle.serial_number_vin$') {
      getVehiclesByPartialVin(query);
    }
    if (variables.field === '$vehicle.tag_number$') {
      getTagsByPartialTagNumber(query);
    }
  };

  return (
    <FilterPanel.FilterPanel
      filterStructure={filterStructure}
      setQueryFiltersState={handleFilterChange}
      clearButtonLabel="Reset all"
      showClearIcon
      order={[['id', 'ASC']]}
      showSearchIcon
      fetchTypeaheads={handleTypeaheadSearch}
      typeaheadData={typeaheadData}
    />
  );
};

export default Sidebar;
