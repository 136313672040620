import React from 'react';

export const CREATE_POOL_SUCCESS_MESSAGE = {
  body: (poolName) => (
    <>
      You have successfully created a new motor pool for{' '}
      <strong>{poolName}</strong>.
    </>
  ),
};

export const UPDATE_POOL_SUCCESS_MESSAGE = {
  body: (poolName) => (
    <>
      You have successfully updated the motor pool for{' '}
      <strong>{poolName}</strong>.
    </>
  ),
};

export const DELETE_POOL_SUCCESS_MESSAGE = {
  body: () => <>You have successfully deleted the motor pool.</>,
};

export const SELF_INVITE_SUCCESS_MESSAGE = {
  body: (poolName) => (
    <>
      You have been successfully added to the <strong>{poolName}</strong>.
    </>
  ),
};

export const DELETE_VEHICLE_SUCCESS_MESSAGE = {
  body: () => <>You have successfully deleted the motor pool vehicle.</>,
};

export const UPDATE_POOL_VEHICLE_SUCCESS_MESSAGE = {
  body: () => <>You have successfully updated the motor pool vehicle.</>,
};

export const MEMBER_SUCCESS_MESSAGE = (poolName, memberName, actionType) => {
  return {
    body: () => (
      <>
        {memberName} successfully {actionType} {poolName}.
      </>
    ),
  };
};

export const CREATE_RESERVATION_SUCCESS_MESSAGE = {
  body: () => <>You have successfully reserved the vehicle.</>,
};

export const UPDATE_RESERVATION_SUCCESS_MESSAGE = {
  body: () => <>You have successfully updated the reservation.</>,
};

export const START_RESERVATION_SUCCESS_MESSAGE = {
  body: () => <>You have successfully started the reservation.</>,
};

export const CLOSE_RESERVATION_SUCCESS_MESSAGE = {
  body: () => <>You have successfully completed the reservation.</>,
};

export const CANCEL_RESERVATION_SUCCESS_MESSAGE = {
  body: () => <>You have successfully canceled the reservation.</>,
};

export const NO_MOTOR_POOL_TIME_ZONE = {
  body: () => (
    <>
      This motor pool does not have a time zone assigned and cannot accept new
      reservations. Please contact one of the motor pool dispatchers to assign a
      time zone.
    </>
  ),
};

export const NO_MOTOR_POOL_RESERVATION_TIME_ZONE = {
  body: () => (
    <>
      There is no time zone associated with this motor pool, all times will be
      displayed in UTC.
    </>
  ),
};
