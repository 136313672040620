import React from 'react';
import ServerErrorMessage from 'components/error-handlers/server-error-message';
import { Alert } from '@gsa/afp-component-library';

export const getStateById = (id, options, accessor = 'stateCode') => {
  const state = options?.find((item) => item.id === id?.toString());
  return state ? state[accessor] : '';
};

export const getCountryById = (id, internationalStateName, options) => {
  if (internationalStateName) {
    const country = options?.find((item) => item.id === id?.toString());
    return country ? country.countryName : '';
  }
  return 'USA';
};

export const createFilter = (currentFiltersStatus, filterOptionMap) => {
  return Object.entries(currentFiltersStatus)
    // eslint-disable-next-line no-unused-vars
    .filter(([_, v]) => v)
    .map(([key, value]) => {
      const filter = filterOptionMap[key].condition;
      filter.value = typeof value === 'object' ? value.value : value;
      return filter;
    });
};

export const getData = ({
  getModalFilterCustomerAccounts,
  selectedOrganization,
  paginationState,
  currentFiltersStatus,
  filterOptionMap,
  order,
}) => {
  getModalFilterCustomerAccounts({
    variables: {
      limit: paginationState.limit,
      offset: paginationState.offset,
      order,
      filters: [
        {
          operator: '$and',
          conditions: [
            ...createFilter(currentFiltersStatus, filterOptionMap),
            ...(selectedOrganization?.officeCode
              ? [
                  {
                    operator: '$exact',
                    key: '$customer_agency_code$',
                    value: selectedOrganization?.agencyCode,
                  },
                  {
                    operator: '$exact',
                    key: '$customer_bureau_code$',
                    value: selectedOrganization?.bureauCode,
                  },
                ]
              : []),
          ],
        },
        {
          operator: '$or',
          conditions: [
            {
              operator: '$not',
              key: '$customer_physical_office_code$',
              value: selectedOrganization?.officeCode,
            },
            {
              operator: '$isNull',
              key: '$customer_physical_office_code$',
              value: '',
            },
          ],
        },
      ],
    },
  });
};

export const filterOptionMap = {
  boac: {
    label: 'BOAC',
    condition: {
      key: '$boac$',
      operator: '$startsWith',
    },
  },
  customer_account_number: {
    label: 'Customer account number',
    condition: {
      key: '$`CustomerAccount`.`customer_account_id`$',
      operator: '$startsWith',
    },
  },
  fsr_email: {
    label: 'FSR email',
    condition: {
      key: '$fsr_user_email_address$',
      operator: '$startsWith',
    },
  },

  legacy_customer_number: {
    label: 'Legacy customer number',
    condition: {
      key: '$legacy_customer_number$',
      operator: '$startsWith',
    },
  },
  poc_email: {
    label: 'POC email',
    condition: {
      key: '$primaryContactAssociation.pointOfContact.email_address$',
      operator: '$startsWith',
    },
  },
  account_postal_code: {
    label: 'Account Postal code',
    condition: {
      key: '$CustomerAccount.postal_code$',
      operator: '$exact',
    },
  },
  state: {
    label: 'State',
    condition: {
      key: '$CustomerAccount.us_state_id$',
      operator: '$exact',
    },
  },
};

export const getSelectedRows = (
  needToReviewRowsState,
  indexRowsCurrentPageObj,
) => {
  const currentPageSelected = {};

  needToReviewRowsState.map((x) => {
    if (indexRowsCurrentPageObj[x.customerId]) {
      const index = indexRowsCurrentPageObj[x.customerId];
      currentPageSelected[index === 'x' ? 0 : index] = true;
    }
  });

  return currentPageSelected;
};

export const showAlert = ({ alertPosition, alertMessage }) => {
  const { header, message, type, error, position } = alertMessage;
  if (!message) {
    return null;
  }

  if (type === 'error' && position === alertPosition) {
    return <ServerErrorMessage graphQLErrors={error} />;
  }

  return (
    position === alertPosition && (
      <div className="margin-top-2" data-testid="office-management-alert">
        <Alert type={type} heading={header || null}>
          {message}
        </Alert>
      </div>
    )
  );
};

export const getDisabledRows = (modalFilterCustomerAccountsList) =>
  modalFilterCustomerAccountsList?.rows
    .map((account, ind) => {
      if (account?.customerId && account.customerPhysicalOfficeCode) {
        return ind;
      }
    })
    .filter((x) => x !== undefined) ?? [];
