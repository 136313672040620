/* eslint-disable react/prop-types */
// eslint-disable-next-line filenames/match-exported
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import PurchaseOrderNumberFormFields from './purchase-order-number-form-fields';

const PurchaseOrderNumberForm = ({
  setIsRequirePurchaseOrderNumber,
  verifyPurchaseOrderNumber,
  setModalState,
  modalState,
  resetCurrentModal,
  isValidOrderNumber,
  setIsValidOrderNumber,
 formState
}) => {
  const getValidateSchema = () =>
    yup.object().shape({
      orderNumber: yup.string().when('purchasedThroughGSA', {
        is: 'true',
        then: yup
          .string()
          .required()
          .matches(/^[a-zA-Z0-9]*$/, {
            message: 'Invalid format',
          })
          .min(5, 'Must be 5 characters')
          .max(5, 'Must be 5 characters')
          .label('This'),
      }),
    });

  const methods = useForm({
    resolver: yupResolver(getValidateSchema()),
    defaultValues: {},
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });
  const { setError } = methods;
  // the error message is depend on  verifyPurchaseOrderNumber query result
  // this is a necessary side effect because the error message is depend on the query result and setError is not a global method
  useEffect(() => {
    if (!isValidOrderNumber) {
      setError('orderNumber', {
        type: 'custom',
        message:
          'The order information provided cannot be validated.  If you continue to experience difficulties, please contact vehicle.buying@gsa.gov for assistance.',
      });
    }
  }, [isValidOrderNumber]);
  const onSubmit = (values) => {
    if (values?.purchasedThroughGSA === 'true') {
      // the follow logic is depend on the query onCompleted result
      // when verifyPurchaseOrderNumber test the order number is valid the modal will close and purchase order number will show up on the register page
      // otherwise the orderNumber error will trigger by the useEffect
      verifyPurchaseOrderNumber({
        variables: {
          vin: formState.vin,
          purchaseOrderNumber: values.orderNumber,
        },
      });
    } else {
      setIsRequirePurchaseOrderNumber(false);
      resetCurrentModal();
    }
  };

  return (
    <FormProvider {...methods}>
      <form
        data-testid="purchase-order-number-form"
        id="purchase-order-number-form"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <PurchaseOrderNumberFormFields
          setModalState={setModalState}
          modalState={modalState}
          setIsValidOrderNumber={setIsValidOrderNumber}
        />
      </form>
    </FormProvider>
  );
};

export default PurchaseOrderNumberForm;
