import React, { useEffect, useState } from 'react';
import { FilterPanel } from '@gsa/afp-component-library';
import { difference } from 'lodash';
import { usePmExpress } from '../pm-express-provider';

const { FilterPanelField, useFilterPanel } = FilterPanel;

// eslint-disable-next-line react/prop-types
const PmStatusItem = ({ filter, pmStatusFilterPath }) => {
  const { modifiedPms, localMergedFilter, setLocalMergedFilter } =
    usePmExpress();
  const { getFilterFromState, mergedFilters, clearOneFilter } =
    useFilterPanel();
  const [pmStatus, setPmStatus] = useState('');

  const options = [
    {
      value: '',
      label: '- Select status -',
      defaultValue: true,
    },
    {
      value: 'DUE',
      label: 'Due',
      defaultValue: true,
    },
    {
      value: 'OVERDUE',
      label: 'Overdue',
      defaultValue: true,
    },
    {
      value: 'UpToDate',
      label: 'Up to date',
      defaultValue: true,
    },
  ];

  const getFilterValues = (filterData) => {
    return filterData.map(({ value, key }) => {
      return {
        key,
        value,
      };
    });
  };

  useEffect(() => {
    if (modifiedPms?.length > 0) {
      const getLastAddedFilterItemId = getFilterValues(
        difference(mergedFilters, localMergedFilter),
      ).filter(({ value }) => value.length)[0]?.key;
      const lastAddedFilter = getFilterFromState(getLastAddedFilterItemId);
      clearOneFilter(lastAddedFilter);
    } else {
      setLocalMergedFilter(mergedFilters);
    }
  }, [modifiedPms, mergedFilters]);

  useEffect(() => {
    const pmStatusFilter = getFilterFromState(pmStatusFilterPath);
    setPmStatus(pmStatusFilter.value);
  }, [mergedFilters]);

  return (
    <div className="margin-top-3">
      <FilterPanelField
        key={[filter.key]}
        filter={{
          ...filter,
          type: 'select',
          options,
          value: pmStatus || [],
        }}
      />
    </div>
  );
};

export default PmStatusItem;
