// eslint-disable-next-line filenames/match-exported
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import { licensePlateWithVehicleType } from '../filter-structures/license-plate';
import { canViewAOTags, canViewGFTags } from '../../../utilities/authorization';

export const fsrNestedFilter = () => {
  const ability = useAppAbility();
  const aoTags = canViewAOTags(ability);
  const gfTags = canViewGFTags(ability);

  const filters = [
    licensePlateWithVehicleType.topLevelSearch,
    {
      ...licensePlateWithVehicleType.licensePlateFSR,
      value: '',
    },
    licensePlateWithVehicleType.agency,
    licensePlateWithVehicleType.licensePlateStatus,
    licensePlateWithVehicleType.licensePlateOrder,
  ];
  if (aoTags && gfTags) {
    filters.splice(4, 0, licensePlateWithVehicleType.licensePlateOwnership);
  }
  return filters;
};

export default fsrNestedFilter;
