/* eslint-disable import/extensions */

/* eslint-disable filenames/match-exported */
import deleteOfficeDetailModalSchema from './office-detail/delete-office-detail/delete-office-detail-schema';
import editOfficeDetailSchema from './office-detail/edit-office-detail-schema';
import deleteOfficeDetailErrorModalSchema from './office-detail/delete-office-detail/delete-office-detail-error-schema';

import addCustomerAccountModalSchema from './customer-account/add-customer-account-schema';
import removeCustomerAccountModalSchema from './customer-account/remove-customer-account-schema';

const schema = {
  editOfficeDetailSchema,
  deleteOfficeDetailModalSchema,
  deleteOfficeDetailErrorModalSchema,

  addCustomerAccountModalSchema,
  removeCustomerAccountModalSchema,
  name: 'OfficeDetail',
};
export default schema;
