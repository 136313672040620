/* eslint-disable react/prop-types */
/* eslint-disable filenames/match-exported */

import React, { Suspense } from 'react';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { Button } from '@gsa/afp-component-library';
import Sections from './Sections';
import { createLayoutSelector } from '../../utilities/recoil/store';
import FallbackLoader from './widgets/FallbackLoader';

// add customizedWidgets prop.
// customized widgets should be developers last option. you need to have a really urgent deadline, or you are 100% sure this widget is 1 time use only
// and you can not use the existing field pattern to create your layout
//   the customizedWidgets is an object, keys need to be string and they need to have the '_customized' suffix and the values should be your customized component
//   please checking "createFieldByComponentType without customizedWidgets"  in fieldMap.test

const FormFrame = ({
  pageId = 'default',
  atoms,
  atomStateSchema,
  submitSelector,
  FormButtons,
  customizedWidgets,
}) => {
  const formLayoutState = useRecoilValue(
    createLayoutSelector({ atoms })(pageId),
  );

  const submit = useSetRecoilState(submitSelector);

  const onSubmit = (e) => {
    e.preventDefault();

    submit();
  };

  // for the forms do not need indicator we will consider it only have 1 step and use layout[0]
  let currentStepFormLayOutSections;
  if (formLayoutState?.layout) {
    currentStepFormLayOutSections = formLayoutState?.currentStep
      ? formLayoutState?.layout[formLayoutState?.currentStep - 1]?.sections
      : formLayoutState?.layout[0];
  }

  return (
    <>
      <Suspense fallback={<FallbackLoader />}>
        <form
          id={`${pageId}-form`}
          data-testid={`${pageId}-form`}
          onSubmit={onSubmit}
        >
          {currentStepFormLayOutSections && (
            <Sections
              pageId="expense_update"
              sections={currentStepFormLayOutSections}
              atoms={atoms}
              atomStateSchema={atomStateSchema}
              customizedWidgets={customizedWidgets}
            />
          )}

          <div className="margin-top-8">
            {FormButtons ? (
              <FormButtons pageId={pageId} />
            ) : (
              <Button
                size="sm"
                data-testid={`${pageId}-form`}
                variant="primary"
                leftIcon={{ name: 'check' }}
                id={`${pageId}--form-submit`}
                aria-label="Submit"
                hidden="hidden"
                type="submit"
                label="Submit"
              />
            )}
          </div>
        </form>
      </Suspense>
    </>
  );
};

export default FormFrame;
