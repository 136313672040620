import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import moment from 'moment';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCurrentUser } from '@gsa/afp-shared-ui-utils';
import { useMotorPool } from '../../../motor-pool-provider';
import ReservationDateField from './reservation-date-field';
import ReservationAddEditFields from './reservation-add-edit-fields';
import {
  generateCreateUpdateReservationFields,
  CREATE_UPDATE_RESERVATION_DATE_FIELDS,
} from './reservation-constants';
import { formatReservationScheduledDates } from '../../../helpers/utils';

const ReservationAddEditForm = () => {
  const {
    createPoolReservation,
    updatePoolReservation,
    motorPoolVehicleSelected,
    motorPoolVehicleModalMode,
    reservationModalMode,
    reservationListingSelected,
    selectedMotorPoolById,
  } = useMotorPool();

  const { currentUser } = useCurrentUser();

  const driverFullName = `${currentUser.firstName} ${currentUser.lastName}`;

  const CREATE_UPDATE_RESERVATION_FIELDS =
    generateCreateUpdateReservationFields();
  const isManagesPool = selectedMotorPoolById?.managesPool;

  const generateReservationFormSchema = (isDispatchedUpdate) =>
    yup.object().shape({
      [CREATE_UPDATE_RESERVATION_FIELDS.driverName.name]: yup
        .string()
        .when([], {
          is: () => isManagesPool,
          then: yup
            .string()
            .required()
            .max(50, 'Driver name cannot be longer than 50 characters.'),
        })
        .label(CREATE_UPDATE_RESERVATION_FIELDS.driverName.label),
      [CREATE_UPDATE_RESERVATION_DATE_FIELDS.startDate.name]: yup
        .date()
        .when([], {
          is: () => !isDispatchedUpdate,
          then: yup
            .date()
            .required()
            .typeError('Reservation start date is a required field.'),
        })
        .label(CREATE_UPDATE_RESERVATION_DATE_FIELDS.startDate.label),
      [CREATE_UPDATE_RESERVATION_DATE_FIELDS.startTime.name]: yup
        .string()
        .when([], {
          is: () => !isDispatchedUpdate,
          then: yup.string().required(),
        })
        .label(CREATE_UPDATE_RESERVATION_DATE_FIELDS.startTime.label),
      [CREATE_UPDATE_RESERVATION_DATE_FIELDS.endDate.name]: yup
        .date()
        .required()
        .label(CREATE_UPDATE_RESERVATION_DATE_FIELDS.endDate.label)
        .typeError('Reservation end date is a required field.'),
      [CREATE_UPDATE_RESERVATION_DATE_FIELDS.endTime.name]: yup
        .string()
        .when(
          ['scheduledPickupDate', 'scheduledReturnDate', 'scheduledStartTime'],
          (scheduledPickupDate, scheduledReturnDate, scheduledStartTime) =>
            yup
              .string()
              .test(
                'is-greater',
                'End time must be greater than start time.',
                (value) => {
                  if (
                    moment(scheduledPickupDate).format('YYYY-MM-DD') ===
                    moment(scheduledReturnDate).format('YYYY-MM-DD')
                  ) {
                    return value > scheduledStartTime;
                  }
                  return true;
                },
              ),
        )
        .required()
        .label(CREATE_UPDATE_RESERVATION_DATE_FIELDS.endTime.label),
      [CREATE_UPDATE_RESERVATION_FIELDS.reservationPurpose.name]: yup
        .string()
        .required()
        .label(CREATE_UPDATE_RESERVATION_FIELDS.reservationPurpose.label),
      [CREATE_UPDATE_RESERVATION_FIELDS.comment.name]: yup
        .string()
        .max(200, 'Comment cannot be longer than 200 characters.')
        .label(CREATE_UPDATE_RESERVATION_FIELDS.comment.label)
        .nullable(),
    });

  const isReservationUpdate = reservationModalMode === 'UPDATE_RESERVATION';
  const isDispatchedUpdate =
    reservationListingSelected?.reservationStatus?.status ===
      'Vehicle Dispatched' && isReservationUpdate;

  const methods = useForm({
    resolver: yupResolver(generateReservationFormSchema(isDispatchedUpdate)),
    defaultValues: {},
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const validateReservationDates = (values) => {
    const {
      scheduledPickupDate,
      scheduledReturnDate,
      scheduledStartTime,
      scheduledReturnTime,
    } = values;
    const isSameDay = moment(scheduledPickupDate).isSame(
      scheduledReturnDate,
      'day',
    );
    const isPickUpAfterReturn =
      moment(scheduledPickupDate).isAfter(scheduledReturnDate);
    const isInvalidTime = scheduledStartTime >= scheduledReturnTime;
    if ((isSameDay && isInvalidTime) || isPickUpAfterReturn) {
      return false;
    }
    return true;
  };

  const onSubmit = (values) => {
    methods.trigger().then(() => {
      const isValid = validateReservationDates(values);
      if (!isValid) {
        methods.setError(CREATE_UPDATE_RESERVATION_DATE_FIELDS.endTime.name, {
          type: 'custom',
          message: 'End date/time must be greater than start date/time.',
        });
      } else {
        const { formattedScheduledPickupDate, formattedScheduledReturnDate } =
          formatReservationScheduledDates(
            values,
            selectedMotorPoolById?.timezone,
          );
        if (reservationModalMode === 'UPDATE_RESERVATION') {
          updatePoolReservation({
            variables: {
              updatePoolReservation: {
                id: reservationListingSelected.id,
                scheduledPickupDate: isDispatchedUpdate
                  ? reservationListingSelected.scheduledPickupDate
                  : formattedScheduledPickupDate,
                scheduledReturnDate: formattedScheduledReturnDate,
                motorPoolId: reservationListingSelected.motorPoolId,
                motorPoolVehicleId:
                  reservationListingSelected.motorPoolVehicleId,
                isDriverNeeded: values.isDriverNeeded === 'yes',
                isOnBehalf: values.isOnBehalf === 'yes',
                driverName: values.driverName,
                reservationPurposeId: values.reservationPurposeId,
                comment: values.comment,
              },
            },
          });
        } else if (motorPoolVehicleModalMode === 'RESERVE_MOTOR_POOL_VEHICLE') {
          createPoolReservation({
            variables: {
              createPoolReservation: {
                scheduledPickupDate: formattedScheduledPickupDate,
                scheduledReturnDate: formattedScheduledReturnDate,
                motorPoolId: motorPoolVehicleSelected.motorPoolId,
                motorPoolVehicleId: motorPoolVehicleSelected.id,
                isDriverNeeded: values.isDriverNeeded === 'yes',
                isOnBehalf: values.isOnBehalf === 'yes',
                driverName: values.driverName || driverFullName,
                reservationPurposeId: values.reservationPurposeId,
                comment: values.comment,
              },
            },
          });
        }
      }
    });
  };

  return (
    <FormProvider {...methods}>
      <form
        data-testid="reservation-add-edit-form"
        id="reservation-add-edit-form"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <ReservationDateField />
        <ReservationAddEditFields />
      </form>
    </FormProvider>
  );
};

export default ReservationAddEditForm;
