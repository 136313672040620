import React from 'react';
import { Breadcrumbs } from '@gsa/afp-component-library';
import FcReplacementContextProvider from './fc-replacement-context-provider';
import FCReplacementFilterProvider from './fc-filter-provider';
import AddReplacementCardModal from './add-replacement-card-modal';
import AddReplacementCardConfirmationModal from './add-replacement-card-confirmation-modal';
import FcReplacementHeader from './fc-replacement-header';
import FcReplacementFilterGrid from './fc-replacement-filter-grid';

const trail = [<a href={`${window.AFP_CONFIG.appURLs.home}/home`}>Home</a>];

const FcReplacementHistory = () => {
  return (
    <>
      <Breadcrumbs trail={trail} current="Fleet leasing card replacement" />
      <FcReplacementContextProvider>
        <FCReplacementFilterProvider>
          <div className="margin-bottom-3">
            <FcReplacementHeader />
          </div>
          <div className="grid-row">
            <div className="grid-col-12">
              <FcReplacementFilterGrid />
            </div>
          </div>
          <AddReplacementCardModal />
          <AddReplacementCardConfirmationModal />
        </FCReplacementFilterProvider>
      </FcReplacementContextProvider>
    </>
  );
};

export default FcReplacementHistory;
