import React, { createContext, useContext, useReducer } from 'react';

const MileagePageContext = createContext({});
const useMileagePage = () => useContext(MileagePageContext);

const initialState = {
  bannerMsg: null,
  mileageCount: 0,
};

const actions = {
  setBannerMsg: 'SET_BANNER_MSG',
  setMileageCount: 'SET_RECALL_COUNT',
};

const mileagePageReducer = (state, { action, payload }) => {
  switch (action) {
    case actions.setBannerMsg:
      return { ...state, bannerMsg: payload };
    case actions.setMileageCount:
      return { ...state, mileageCount: payload };
    default:
      return {
        ...state,
        bannerMsg: { type: 'error', message: 'Unknown page action' },
      };
  }
};

const MileagePageProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    mileagePageReducer,
    initialState,
    () => initialState,
  );

  const setBannerMsg = (payload) =>
    dispatch({ action: actions.setBannerMsg, payload });

  const setMileageCount = (payload) =>
    dispatch({ action: actions.setMileageCount, payload });

  return (
    <MileagePageContext.Provider
      value={{
        ...state,
        setBannerMsg,
        setMileageCount,
      }}
    >
      {children}
    </MileagePageContext.Provider>
  );
};

export { MileagePageProvider as default, useMileagePage };
