import {
  Button,
  RequiredFieldIndicator,
  SelectDropdown,
  Spinner,
  TextInput,
} from '@gsa/afp-component-library';
import SelectState from 'components/vehicle-registration/widgets/agency-and-poc/poc/select-state';
import PhoneInputMask from 'components/vehicle-registration/widgets/agency-and-poc/poc/phone-input-mask';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import useUser from 'utilities/use-user';
import { isPoBoxAddress, onlyAllowNumber } from 'utilities/format';
import FleetCardAddressOption from './address-option';
import {
  REPLACEMENT_CARD_FORM_FIELDS,
  REPLACEMENT_REASON_OPTIONS,
} from './consts';
import FleetCardContactOption from './contact-option';
import FcAddressConfirmation from './fc-address-confirmation';
import { useFcReplacement } from './fc-replacement-context-provider';

const ReplacementCardForm = () => {
  const {
    dispatch,
    getLeasedVehicle,
    fetchingVehicleData,
    vehicle,
    createReplacementOrder,
    updateReplacementOrder,
    selectedAddressOption,
    addressId,
    selectedContactOption,
    contactId,
    validateAddress,
    editMode,
    addCardConfirmationData,
  } = useFcReplacement();
  const { isGsaEmployee } = useUser();

  const generateReplacementReasonOptions = () => {
    return [
      ...REPLACEMENT_REASON_OPTIONS,
      ...(isGsaEmployee() ? [{ value: 'Transfer', label: 'Transfer' }] : []),
    ];
  };

  const [customerData, setCustomerData] = useState();
  const [orderId, setOrderId] = useState(undefined);

  const {
    getValues,
    setError,
    handleSubmit,
    clearErrors,
    setValue,
    formState: { errors },
    control,
    reset,
  } = useFormContext();

  const reason = useWatch({
    name: 'reason',
  });

  const clearFields = (type) => {
    Object.keys(REPLACEMENT_CARD_FORM_FIELDS).forEach((key) => {
      if (REPLACEMENT_CARD_FORM_FIELDS[key].type === type)
        setValue(REPLACEMENT_CARD_FORM_FIELDS[key].name, '');
    });
  };

  const onAddressOptionChange = (option, selectedAddressId) => {
    clearFields('address');
    dispatch({
      type: 'SET_SELECTED_ADDRESS_OPTION',
      payload: option,
    });
    dispatch({
      type: 'SET_ADDRESS_ID',
      payload: selectedAddressId, // will only be defined for last option
    });
  };

  const onContactOptionChange = (option, selectedContactId) => {
    clearFields('contact');
    dispatch({
      type: 'SET_SELECTED_CONTACT_OPTION',
      payload: option,
    });
    dispatch({
      type: 'SET_CONTACT_ID',
      payload: selectedContactId, // will only be defined for last option
    });
  };

  // NOTE - All of the rules for what will be shown and what will be hidden are here
  useEffect(() => {
    if (vehicle && vehicle !== 'error') {
      setOrderId(vehicle.id); // if vehicle.id is present, it is actually the id of the order (consider renaming vehicle)

      dispatch({
        type: 'SET_EDIT_MODE',
        payload: !!vehicle.id,
      });

      if (vehicle.id) {
        // edit mode - form will not submit if these aren't set
        setValue('tagNumber', vehicle.tagNumber);
        setValue('reason', vehicle.reason);
        setValue('comment', vehicle.comment);
      }

      const addressFields = [
        'address1',
        'address2',
        'address3',
        'city',
        'stateCode',
        'postalCode',
      ];

      const contactFields = [
        'firstName',
        'lastName',
        'phoneNumber',
        'extension',
        'email',
      ];

      // Creating new object because object coming in from rows (edit mode) is immutable
      const data = {
        ...(vehicle.customer.primaryContactAssociation.pointOfContact || {}),
      };
      data.customerId = vehicle?.customer?.customerId?.toString() || '';
      const lastFcAddress = vehicle.lastFcOrder?.address || {};
      const lastFcContact = vehicle.lastFcOrder?.contact || {};

      data.phoneNumber = data.phone; // fixing naming discrepancies between customer and contact records
      data.extension = '';

      const defaultIsPoBox = isPoBoxAddress(
        `${data.address1} ${data.address2} ${data.address3}`,
      );

      // address fields with out address 3
      const addressFieldsWithoutAddress3 = addressFields.filter(
        (field) => field !== 'address3',
      );

      // Every field in the default address must have a value to be shown
      // address line 3 is not required to show default address
      const showDefaultAddress =
        addressFieldsWithoutAddress3.every((field) => !!data[field]) &&
        !defaultIsPoBox;

      // If all fields of last address have a value and at least one field of the last address does not match the default address, then show last address
      // address line 3 is not required to show last address
      const showLastAddress =
        addressFieldsWithoutAddress3.some(
          (field) => data[field] !== lastFcAddress[field],
        ) && // Should this be changed to use vehicle.lastFcOrder?.addressSelection === 'default' ?
        addressFieldsWithoutAddress3.every((field) => !!lastFcAddress[field]);

      // Specified fields in list must
      const showDefaultContact = contactFields
        .filter((field) => field !== 'extension')
        .every((field) => !!data[field]);

      const showLastContact =
        contactFields.some((field) => data[field] !== lastFcContact[field]) &&
        contactFields
          .filter((field) => field !== 'extension')
          .every((field) => !!lastFcContact[field]);

      const initiallySelectedAddressOption =
        (vehicle.addressSelection?.includes('default') && 'default') || // edit mode - default address was selected previously
        (vehicle.addressSelection?.includes('custom') && 'new') || // edit mode - new or last address was selected previously
        (showDefaultAddress && 'default') ||
        (showLastAddress && 'last') ||
        'new';

      const initiallySelectedAddressId =
        initiallySelectedAddressOption === 'last'
          ? lastFcAddress.id
          : undefined;

      const initiallySelectedContactOption =
        (vehicle.contactSelection?.includes('default') && 'default') || // edit mode - default contact was selected previously
        (vehicle.contactSelection?.includes('custom') && 'new') || // edit mode - new or last contact was selected previously
        (showDefaultContact && 'default') ||
        (showLastAddress && 'last') ||
        'new';

      const initiallySelectedContactId =
        initiallySelectedContactOption === 'last'
          ? lastFcContact.id
          : undefined;

      setCustomerData({
        ...data,
        showDefaultAddress,
        showLastAddress,
        lastFcAddress,
        showDefaultContact,
        showLastContact,
        lastFcContact,
      });
      clearErrors(REPLACEMENT_CARD_FORM_FIELDS.tagNumber.name);
      onAddressOptionChange(
        initiallySelectedAddressOption,
        initiallySelectedAddressId,
      );

      onContactOptionChange(
        initiallySelectedContactOption,
        initiallySelectedContactId,
      );

      if (vehicle.addressSelection?.includes('custom')) {
        // edit mode - setting field values if new/last was selected previously
        addressFields.push('agency');
        addressFields.forEach((field) => {
          setValue(field, vehicle.address?.[field]);
        });
      }

      if (vehicle.contactSelection?.includes('custom')) {
        contactFields.forEach((field) => {
          setValue(field, vehicle.contact?.[field]);
        });
      }

      if (!showDefaultContact)
        dispatch({
          type: 'SET_ALERT_MESSAGE',
          payload: {
            type: 'warning',
            message:
              'There is no customer contact associated with this vehicle.',
            context: 'addReplacementCard',
          },
        });

      if (defaultIsPoBox)
        dispatch({
          type: 'SET_ALERT_MESSAGE',
          payload: {
            type: 'error',
            message:
              'The customer address associated with this vehicle is a PO Box.  Replacement cards cannot be delivered to a PO Box.  Enter a different delivery address below.',
            context: 'addReplacementCard',
          },
        });
      else if (!showDefaultAddress)
        dispatch({
          type: 'SET_ALERT_MESSAGE',
          payload: {
            type: 'warning',
            message:
              'There is no customer address associated with this vehicle.',
            context: 'addReplacementCard',
          },
        });
    }
    if (vehicle === 'error') {
      reset();
      setError(REPLACEMENT_CARD_FORM_FIELDS.tagNumber.name, {
        type: 'custom',
        message: 'Enter a different plate number to proceed',
      });
    }
  }, [vehicle, fetchingVehicleData]);

  // eslint-disable-next-line consistent-return
  const onSubmit = (formData, _, addressValidationData) => {
    if (vehicle && vehicle !== 'error') {
      if (selectedAddressOption === 'new' && !addressValidationData)
        return validateAddress(formData);

      let payload = {
        ...formData,
        customerId: customerData?.customerId,
        status: 'Pending',
        addressType: selectedAddressOption,
        addressId,
        contactType: selectedContactOption,
        contactId,
      };

      if (addressValidationData?.address) {
        const {
          address: address1,
          address2,
          city,
          zip: postalCode,
          state: stateCode,
        } = addressValidationData;
        payload = {
          ...payload,
          address1,
          address2,
          city,
          postalCode,
          stateCode,
        };
      }

      if (addCardConfirmationData) {
        // flag the api to bypass the 3 days rule
        payload = {
          ...payload,
          bypassThreeDaysRule: true,
        };
      }

      if (editMode)
        updateReplacementOrder({
          variables: {
            fleetCardReplacementOrderInput: {
              ...payload,
              orderId,
              status: undefined,
              reason: undefined,
              comment: undefined,
            },
          },
        });
      else
        createReplacementOrder({
          variables: {
            fleetCardReplacementOrderInput: {
              ...payload,
            },
          },
        });
    } else {
      dispatch({
        type: 'SET_ALERT_MESSAGE',
        payload: {
          type: 'error',
          message: 'Click search to continue.',
          context: 'addReplacementCard',
        },
      });
    }
  };

  const getCustomerContactInfo = () => {
    dispatch({
      type: 'SET_VEHICLE_DATA',
      payload: undefined,
    });
    dispatch({
      type: 'SET_ALERT_MESSAGE',
      payload: { type: '', message: '', context: '' },
    });
    const tagNumber = getValues(REPLACEMENT_CARD_FORM_FIELDS.tagNumber.name);
    if (tagNumber) {
      getLeasedVehicle({
        variables: {
          tagNumber,
        },
      });
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      getCustomerContactInfo();
    }
  };

  const handleTagChange = (value) => {
    let tagValue = value;
    const maxTagNumberLength = 7;
    if (tagValue?.[0] === 'G' || tagValue?.[0] === 'g') {
      tagValue = tagValue.substring(1);
    } else if (tagValue.length !== maxTagNumberLength) {
      setError(REPLACEMENT_CARD_FORM_FIELDS.tagNumber.name, {
        type: 'custom',
        message: `Must be ${maxTagNumberLength} characters.`,
      });
    } else {
      clearErrors(REPLACEMENT_CARD_FORM_FIELDS.tagNumber.name);
    }
    setValue(REPLACEMENT_CARD_FORM_FIELDS.tagNumber.name, tagValue);
  };

  const renderAddressOptions = (data) => {
    if (!data) return null;

    const options = [
      { label: 'Ship card to new address', addressOption: 'new' },
    ];

    if (data.showLastAddress)
      options.unshift({
        address: { ...data, ...data.lastFcAddress },
        label: 'Ship card to last order address',
        addressOption: 'last',
      });

    if (data.showDefaultAddress)
      options.unshift({
        address: data,
        label: 'Ship card to customer address',
        addressOption: 'default',
      });

    return (
      <>
        {options.map((option) => (
          <FleetCardAddressOption
            key={option.addressOption}
            address={option.address || {}}
            label={option.label}
            addressOption={option.addressOption}
            selectedAddressOption={selectedAddressOption}
            onAddressOptionChange={onAddressOptionChange}
          />
        ))}
      </>
    );
  };

  const renderContactOptions = (data) => {
    if (!data) return null;

    const options = [
      { label: 'Ship card to new contact', contactOption: 'new' },
    ];

    if (data.showLastContact)
      options.unshift({
        contact: { ...data, ...data.lastFcContact },
        label: 'Ship card to last order contact',
        contactOption: 'last',
      });

    if (data.showDefaultContact)
      options.unshift({
        contact: data,
        label: 'Ship card to customer contact',
        contactOption: 'default',
      });

    return (
      <>
        {options.map((option) => (
          <FleetCardContactOption
            key={option.contactOption}
            contact={option.contact || {}}
            label={option.label}
            contactOption={option.contactOption}
            selectedContactOption={selectedContactOption}
            onContactOptionChange={onContactOptionChange}
          />
        ))}
      </>
    );
  };

  return (
    <form
      data-testid="replacement-card-form"
      id="replacement-card-form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="grid-row grid-gap margin-bottom-8">
        <div className="grid-col-12">
          {editMode ? (
            <>
              <div className="text-bold">
                Plate number <RequiredFieldIndicator />
              </div>
              {vehicle?.tagNumber}
            </>
          ) : (
            <>
              <Controller
                name={REPLACEMENT_CARD_FORM_FIELDS.tagNumber.name}
                control={control}
                render={({ field, ref }) => {
                  return (
                    <TextInput
                      {...field}
                      ref={ref}
                      type="text"
                      prefix="G"
                      maxLength="8"
                      inputClass="text-uppercase"
                      label={
                        <span className="text-bold">
                          Plate number <RequiredFieldIndicator />
                        </span>
                      }
                      help="No dash or space required"
                      onChange={(e) => {
                        handleTagChange(e.target.value);
                      }}
                      onBlur={(e) => {
                        handleTagChange(e.target.value);
                      }}
                      name={REPLACEMENT_CARD_FORM_FIELDS.tagNumber.name}
                      id={REPLACEMENT_CARD_FORM_FIELDS.tagNumber.id}
                      onKeyDown={(e) => {
                        handleKeyDown(e);
                      }}
                      errorMessage={
                        errors &&
                        errors[REPLACEMENT_CARD_FORM_FIELDS.tagNumber.name]
                          ? errors[REPLACEMENT_CARD_FORM_FIELDS.tagNumber.name]
                              .message
                          : null
                      }
                      aria-invalid={
                        errors &&
                        errors[REPLACEMENT_CARD_FORM_FIELDS.tagNumber.name]
                          ? 'true'
                          : 'false'
                      }
                    />
                  );
                }}
              />
              <div className="margin-top-2">
                <Button
                  variant="primary"
                  type="button"
                  onClick={() => {
                    !errors[REPLACEMENT_CARD_FORM_FIELDS.tagNumber.name] &&
                      getCustomerContactInfo();
                  }}
                  label="Search"
                  data-testid="search-tag-button"
                />
                {fetchingVehicleData && (
                  <Spinner
                    size="small"
                    className="display-inline-block margin-left-2"
                  />
                )}
              </div>
            </>
          )}
          {vehicle && vehicle !== 'error' && (
            <>
              <div data-testid="customer-contact-information">
                <h4 className="title-m">Select the contact for delivery:</h4>
                {renderContactOptions(customerData)}
                {selectedContactOption === 'new' && (
                  <>
                    <div
                      className={`display-inline-block grid-col-4 margin-right-${
                        errors &&
                        errors[REPLACEMENT_CARD_FORM_FIELDS.firstName.name]
                          ? '2em'
                          : '1em'
                      }`}
                    >
                      <Controller
                        name={REPLACEMENT_CARD_FORM_FIELDS.firstName.name}
                        control={control}
                        render={({ field, ref }) => {
                          return (
                            <TextInput
                              {...field}
                              ref={ref}
                              label={
                                <div className="text-bold">
                                  First name <RequiredFieldIndicator />
                                </div>
                              }
                              maxLength="40"
                              name={REPLACEMENT_CARD_FORM_FIELDS.firstName.name}
                              id={REPLACEMENT_CARD_FORM_FIELDS.firstName.id}
                              errorMessage={
                                errors &&
                                errors[
                                  REPLACEMENT_CARD_FORM_FIELDS.firstName.name
                                ]
                                  ? errors[
                                      REPLACEMENT_CARD_FORM_FIELDS.firstName
                                        .name
                                    ].message
                                  : null
                              }
                              aria-invalid={
                                errors &&
                                errors[
                                  REPLACEMENT_CARD_FORM_FIELDS.firstName.name
                                ]
                                  ? 'true'
                                  : 'false'
                              }
                            />
                          );
                        }}
                      />
                    </div>

                    <div className="display-inline-block grid-col-4">
                      <Controller
                        name={REPLACEMENT_CARD_FORM_FIELDS.lastName.name}
                        control={control}
                        render={({ field, ref }) => {
                          return (
                            <TextInput
                              {...field}
                              ref={ref}
                              label={
                                <div className="text-bold">
                                  Last name <RequiredFieldIndicator />
                                </div>
                              }
                              maxLength="40"
                              name={REPLACEMENT_CARD_FORM_FIELDS.lastName.name}
                              id={REPLACEMENT_CARD_FORM_FIELDS.lastName.id}
                              errorMessage={
                                errors &&
                                errors[
                                  REPLACEMENT_CARD_FORM_FIELDS.lastName.name
                                ]
                                  ? errors[
                                      REPLACEMENT_CARD_FORM_FIELDS.lastName.name
                                    ].message
                                  : null
                              }
                              aria-invalid={
                                errors &&
                                errors[
                                  REPLACEMENT_CARD_FORM_FIELDS.lastName.name
                                ]
                                  ? 'true'
                                  : 'false'
                              }
                            />
                          );
                        }}
                      />
                    </div>

                    <div className="display-inline-block grid-col-6 margin-right-1em">
                      <Controller
                        name={REPLACEMENT_CARD_FORM_FIELDS.phoneNumber.name}
                        control={control}
                        render={({ field, ref }) => {
                          return (
                            <PhoneInputMask
                              {...field}
                              ref={ref}
                              label={
                                <div className="text-bold">
                                  Phone <RequiredFieldIndicator />
                                </div>
                              }
                              maxLength="20"
                              name={
                                REPLACEMENT_CARD_FORM_FIELDS.phoneNumber.name
                              }
                              id={REPLACEMENT_CARD_FORM_FIELDS.phoneNumber.id}
                              errorMessage={
                                errors &&
                                errors[
                                  REPLACEMENT_CARD_FORM_FIELDS.phoneNumber.name
                                ]
                                  ? errors[
                                      REPLACEMENT_CARD_FORM_FIELDS.phoneNumber
                                        .name
                                    ].message
                                  : null
                              }
                              aria-invalid={
                                errors &&
                                errors[
                                  REPLACEMENT_CARD_FORM_FIELDS.phoneNumber.name
                                ]
                                  ? 'true'
                                  : 'false'
                              }
                            />
                          );
                        }}
                      />
                    </div>

                    <div className="display-inline-block grid-col-2">
                      <Controller
                        name={REPLACEMENT_CARD_FORM_FIELDS.extension.name}
                        control={control}
                        render={({ field, ref }) => {
                          return (
                            <TextInput
                              {...field}
                              ref={ref}
                              label={<strong>Extension</strong>}
                              name={REPLACEMENT_CARD_FORM_FIELDS.extension.name}
                              id={REPLACEMENT_CARD_FORM_FIELDS.extension.id}
                              errorMessage={
                                errors &&
                                errors[
                                  REPLACEMENT_CARD_FORM_FIELDS.extension.name
                                ]
                                  ? errors[
                                      REPLACEMENT_CARD_FORM_FIELDS.extension
                                        .name
                                    ].message
                                  : null
                              }
                              onKeyDown={(e) => {
                                if (onlyAllowNumber(e)) {
                                  e.preventDefault();
                                }
                              }}
                              aria-invalid={
                                errors &&
                                errors[
                                  REPLACEMENT_CARD_FORM_FIELDS.extension.name
                                ]
                                  ? 'true'
                                  : 'false'
                              }
                            />
                          );
                        }}
                      />
                    </div>

                    <Controller
                      name={REPLACEMENT_CARD_FORM_FIELDS.email.name}
                      control={control}
                      render={({ field, ref }) => {
                        return (
                          <TextInput
                            {...field}
                            ref={ref}
                            label={
                              <strong>
                                Email address <RequiredFieldIndicator />
                              </strong>
                            }
                            name={REPLACEMENT_CARD_FORM_FIELDS.email.name}
                            id={REPLACEMENT_CARD_FORM_FIELDS.email.id}
                            errorMessage={
                              errors &&
                              errors[REPLACEMENT_CARD_FORM_FIELDS.email.name]
                                ? errors[
                                    REPLACEMENT_CARD_FORM_FIELDS.email.name
                                  ].message
                                : null
                            }
                            aria-invalid={
                              errors &&
                              errors[REPLACEMENT_CARD_FORM_FIELDS.email.name]
                                ? 'true'
                                : 'false'
                            }
                          />
                        );
                      }}
                    />
                    <div className="margin-top-1em">
                      <Button
                        variant="unstyled"
                        className="margin-right-2"
                        onClick={() => clearFields('contact')}
                        data-testid="clear-contact-button"
                        label="Clear Contact"
                      />
                    </div>
                  </>
                )}

                <h4 className="title-m">Select the address for delivery:</h4>
                {renderAddressOptions(customerData)}
                {selectedAddressOption === 'new' && (
                  <div>
                    <Controller
                      name={REPLACEMENT_CARD_FORM_FIELDS.agency.name}
                      control={control}
                      render={({ field, ref }) => {
                        return (
                          <TextInput
                            {...field}
                            ref={ref}
                            label={
                              <div className="text-bold">
                                Agency name <RequiredFieldIndicator />
                              </div>
                            }
                            maxLength="40"
                            name={REPLACEMENT_CARD_FORM_FIELDS.agency.name}
                            id={REPLACEMENT_CARD_FORM_FIELDS.agency.id}
                            errorMessage={
                              errors &&
                              errors[REPLACEMENT_CARD_FORM_FIELDS.agency.name]
                                ? errors[
                                    REPLACEMENT_CARD_FORM_FIELDS.agency.name
                                  ].message
                                : null
                            }
                            aria-invalid={
                              errors &&
                              errors[REPLACEMENT_CARD_FORM_FIELDS.agency.name]
                                ? 'true'
                                : 'false'
                            }
                          />
                        );
                      }}
                    />
                    <Controller
                      name={REPLACEMENT_CARD_FORM_FIELDS.address1.name}
                      control={control}
                      render={({ field, ref }) => {
                        return (
                          <TextInput
                            {...field}
                            ref={ref}
                            label={
                              <>
                                <div className="text-bold">
                                  Address line 1 <RequiredFieldIndicator />
                                </div>
                                <span className="text-base">
                                  Note: do not enter a PO Box
                                </span>
                              </>
                            }
                            maxLength="40"
                            name={REPLACEMENT_CARD_FORM_FIELDS.address1.name}
                            id={REPLACEMENT_CARD_FORM_FIELDS.address1.id}
                            errorMessage={
                              errors &&
                              errors[REPLACEMENT_CARD_FORM_FIELDS.address1.name]
                                ? errors[
                                    REPLACEMENT_CARD_FORM_FIELDS.address1.name
                                  ].message
                                : null
                            }
                            aria-invalid={
                              errors &&
                              errors[REPLACEMENT_CARD_FORM_FIELDS.address1.name]
                                ? 'true'
                                : 'false'
                            }
                          />
                        );
                      }}
                    />
                    <Controller
                      name={REPLACEMENT_CARD_FORM_FIELDS.address2.name}
                      control={control}
                      render={({ field, ref }) => {
                        return (
                          <TextInput
                            {...field}
                            ref={ref}
                            label={
                              <div className="text-bold">Address line 2</div>
                            }
                            maxLength="40"
                            name={REPLACEMENT_CARD_FORM_FIELDS.address2.name}
                            id={REPLACEMENT_CARD_FORM_FIELDS.address2.id}
                            errorMessage={
                              errors &&
                              errors[REPLACEMENT_CARD_FORM_FIELDS.address2.name]
                                ? errors[
                                    REPLACEMENT_CARD_FORM_FIELDS.address2.name
                                  ].message
                                : null
                            }
                            aria-invalid={
                              errors &&
                              errors[REPLACEMENT_CARD_FORM_FIELDS.address2.name]
                                ? 'true'
                                : 'false'
                            }
                          />
                        );
                      }}
                    />

                    <div className="display-inline-block grid-col-6 margin-right-105em">
                      <Controller
                        name={REPLACEMENT_CARD_FORM_FIELDS.city.name}
                        control={control}
                        render={({ field, ref }) => {
                          return (
                            <TextInput
                              {...field}
                              ref={ref}
                              label={
                                <>
                                  <strong>City</strong>{' '}
                                  <RequiredFieldIndicator />
                                </>
                              }
                              name={REPLACEMENT_CARD_FORM_FIELDS.city.name}
                              id={REPLACEMENT_CARD_FORM_FIELDS.city.id}
                              errorMessage={
                                errors &&
                                errors[REPLACEMENT_CARD_FORM_FIELDS.city.name]
                                  ? errors[
                                      REPLACEMENT_CARD_FORM_FIELDS.city.name
                                    ].message
                                  : null
                              }
                              aria-invalid={
                                errors &&
                                errors[REPLACEMENT_CARD_FORM_FIELDS.city.name]
                                  ? 'true'
                                  : 'false'
                              }
                            />
                          );
                        }}
                      />
                    </div>
                    <div className="display-inline-block">
                      <Controller
                        name={REPLACEMENT_CARD_FORM_FIELDS.state.name}
                        control={control}
                        render={({ field, ref }) => {
                          return (
                            <SelectState
                              {...field}
                              ref={ref}
                              name={REPLACEMENT_CARD_FORM_FIELDS.state.name}
                              label={
                                <>
                                  <strong>State</strong>{' '}
                                  <RequiredFieldIndicator />
                                </>
                              }
                              onChange={(e) => {
                                setValue(
                                  REPLACEMENT_CARD_FORM_FIELDS.state.name,
                                  e.target.value,
                                );
                              }}
                              countryCode="US"
                              errorMessage={
                                errors &&
                                (!getValues(
                                  REPLACEMENT_CARD_FORM_FIELDS.state.name,
                                ) ||
                                  getValues(
                                    REPLACEMENT_CARD_FORM_FIELDS.state.name,
                                  ) === '- Select -') &&
                                errors[REPLACEMENT_CARD_FORM_FIELDS.state.name]
                                  ? errors[
                                      REPLACEMENT_CARD_FORM_FIELDS.state.name
                                    ].message
                                  : null
                              }
                              aria-invalid={
                                errors &&
                                errors[REPLACEMENT_CARD_FORM_FIELDS.state.name]
                                  ? 'true'
                                  : 'false'
                              }
                            />
                          );
                        }}
                      />
                    </div>
                    <div className="grid-col-3">
                      <Controller
                        name={REPLACEMENT_CARD_FORM_FIELDS.postalCode.name}
                        control={control}
                        render={({ field, ref }) => {
                          return (
                            <TextInput
                              {...field}
                              ref={ref}
                              label={
                                <>
                                  <strong>ZIP code</strong>{' '}
                                  <RequiredFieldIndicator />
                                </>
                              }
                              name={
                                REPLACEMENT_CARD_FORM_FIELDS.postalCode.name
                              }
                              id={REPLACEMENT_CARD_FORM_FIELDS.postalCode.id}
                              errorMessage={
                                errors &&
                                errors[
                                  REPLACEMENT_CARD_FORM_FIELDS.postalCode.name
                                ]
                                  ? errors[
                                      REPLACEMENT_CARD_FORM_FIELDS.postalCode
                                        .name
                                    ].message
                                  : null
                              }
                              aria-invalid={
                                errors &&
                                errors[
                                  REPLACEMENT_CARD_FORM_FIELDS.postalCode.name
                                ]
                                  ? 'true'
                                  : 'false'
                              }
                            />
                          );
                        }}
                      />
                    </div>
                    <div className="margin-top-1em">
                      <Button
                        variant="unstyled"
                        className="margin-right-2"
                        onClick={() => clearFields('address')}
                        data-testid="clear-address-button"
                        label="Clear Address"
                      />
                    </div>
                  </div>
                )}
              </div>
              {editMode ? (
                <div>
                  <div className="text-bold margin-top-1em">
                    Reason for replacement <RequiredFieldIndicator />
                  </div>
                  {vehicle?.reason}
                  <div className="text-bold margin-top-1em">
                    Comment{' '}
                    {(reason === 'Stolen' || reason === 'Lost') && (
                      <RequiredFieldIndicator />
                    )}
                  </div>
                  {vehicle?.comment}
                </div>
              ) : (
                <>
                  <Controller
                    name={REPLACEMENT_CARD_FORM_FIELDS.reason.name}
                    control={control}
                    render={({ field, ref }) => {
                      return (
                        <SelectDropdown
                          {...field}
                          ref={ref}
                          label={
                            <span className="text-bold">
                              Reason for replacement <RequiredFieldIndicator />
                            </span>
                          }
                          onChange={(e) => {
                            setValue(
                              REPLACEMENT_CARD_FORM_FIELDS.reason.name,
                              e.target.value,
                            );
                            clearErrors(
                              REPLACEMENT_CARD_FORM_FIELDS.comment.name,
                            );
                          }}
                          name={REPLACEMENT_CARD_FORM_FIELDS.reason.name}
                          id={REPLACEMENT_CARD_FORM_FIELDS.reason.id}
                          options={generateReplacementReasonOptions()}
                          errorMessage={
                            errors &&
                            errors[REPLACEMENT_CARD_FORM_FIELDS.reason.name]
                              ? errors[REPLACEMENT_CARD_FORM_FIELDS.reason.name]
                                  .message
                              : null
                          }
                          aria-invalid={
                            errors &&
                            errors[REPLACEMENT_CARD_FORM_FIELDS.reason.name]
                              ? 'true'
                              : 'false'
                          }
                        />
                      );
                    }}
                  />
                  <Controller
                    name={REPLACEMENT_CARD_FORM_FIELDS.comment.name}
                    control={control}
                    render={({ field, ref }) => {
                      return (
                        <TextInput
                          {...field}
                          ref={ref}
                          type="textarea"
                          label={
                            <span className="text-bold">
                              Comment{' '}
                              {(reason === 'Stolen' || reason === 'Lost') && (
                                <RequiredFieldIndicator />
                              )}
                            </span>
                          }
                          maxLength="101"
                          name={REPLACEMENT_CARD_FORM_FIELDS.comment.name}
                          id={REPLACEMENT_CARD_FORM_FIELDS.comment.id}
                          errorMessage={
                            errors &&
                            errors[REPLACEMENT_CARD_FORM_FIELDS.comment.name]
                              ? errors[
                                  REPLACEMENT_CARD_FORM_FIELDS.comment.name
                                ].message
                              : null
                          }
                          aria-invalid={
                            errors &&
                            errors[REPLACEMENT_CARD_FORM_FIELDS.comment.name]
                              ? 'true'
                              : 'false'
                          }
                          characterLimit={100}
                        />
                      );
                    }}
                  />
                </>
              )}
            </>
          )}
        </div>
      </div>
      <FcAddressConfirmation
        onSave={(data) => onSubmit(getValues(), undefined, data)}
      />
    </form>
  );
};

export default ReplacementCardForm;
