import React from 'react';
import PropTypes from 'prop-types';

const emDash = '–';

const formatText = (text) => {
  if (Array.isArray(text)) {
    return text.map((item) => (
      <div className="text-right" key={item}>
        {item}
      </div>
    ));
  }
  return text || emDash;
};

export default function LicenseDetail({ label, value, className }) {
  return (
    <div className="detail-row display-flex flex-justify padding-y-1 border-bottom border-width-2px border-base-lighter">
      <div className="detail-title">
        <strong>{label}</strong>
      </div>
      <div className={`detail-value ${className}`}>{formatText(value)}</div>
    </div>
  );
}

LicenseDetail.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};
