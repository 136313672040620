/* eslint-disable react/prop-types */
/* eslint-disable filenames/match-exported */

import React from 'react';
import { useRecoilValue } from 'recoil';
import { Button } from '@gsa/afp-component-library';
import { useHistory } from 'react-router-dom';
import { pageConfigAtom } from '../model/store/atoms';

const FormButtons = () => {
  // need thesetFromLayoutState for the disable field
  const vehicleState = useRecoilValue(pageConfigAtom('vehicle'));
  const history = useHistory();
  return (
    <>
      <Button
        size="sm"
        data-testid="expense-update-form-cancel"
        type="button"
        variant="outline"
        aria-label="Cancel form"
        label="Cancel"
        onClick={() => history.push(`/vehicles/${vehicleState.id}/expenses`)}
      />
      <Button
        size="sm"
        data-testid="expense-update-form"
        variant="primary"
        leftIcon={{ name: 'check' }}
        id="expense-update-form-save"
        aria-label="Export report to email"
        hidden="hidden"
        type="submit"
        label="Save expense record"
      />
    </>
  );
};

export default FormButtons;
