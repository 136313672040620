import React from 'react';

export const UPDATE_PM_SUCCESS_MESSAGE = {
  body: () => <>You have successfully submitted PMs.</>,
};

export const UPDATE_PM_FAIL_MESSAGE = {
  body: () => <>There are errors in the form.</>,
};

export const NO_MODIFIED_PMS = {
  body: () => <>There are no PMs to save.</>,
};
