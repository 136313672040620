import React from 'react';
import { Breadcrumbs, PageTitle } from '@gsa/afp-component-library';

const trail = [<a href={`${window.AFP_CONFIG.appURLs.home}/home`}>Home</a>];

const HeaderWidget = ({ Alert }) => (
  <header>
    <Breadcrumbs trail={trail} current="Vehicle Inventory" />
    <Alert />
    <PageTitle title="Vehicle Inventory" />
  </header>
);

export default HeaderWidget;
