/* eslint-disable no-shadow */
/* eslint-disable array-callback-return */
/* eslint-disable import/prefer-default-export */
import { selectorFamily, selector } from 'recoil';
import { historyStateAtom } from 'components/RecoilHistoryListener';
import { navigateByHistoryState } from 'utilities/recoil/helper';
import { updateSort, orderQueryParser } from 'utilities/format';
import * as atoms from './atoms';
import { pageId } from '../schema/atom-state-schema';

// the selector for set the field
// code function for update the field, include validation and change state value and after value change

export const createPageSetupSelector = selectorFamily({
  key: `${pageId}/setupSelector`,
  get: () => () => null,
  set:
    () =>
    ({ get, set }, { pageConfigs, atoms }) => {
      if (pageConfigs) {
        Object.keys(pageConfigs).map((key) => {
          const pageState = get(atoms.pageConfigAtom(key));
          set(atoms.pageConfigAtom(key), {
            ...pageState,
            ...pageConfigs[key].default,
          });
        });
      }
    },
});
// single pageConfigUpdate
export const pageConfigUpdateSelector = selectorFamily({
  key: `${pageId}/pageConfigUpdate`,
  get:
    ({ id }) =>
    ({ get }) => {
      return get(atoms.pageConfigAtom(id));
    },
  set:
    ({ id }) =>
    ({ get, set }, newObj) => {
      const pageConfigAtom = atoms.pageConfigAtom(id);
      set(pageConfigAtom, { ...get(pageConfigAtom), ...newObj });
    },
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
});

export const paginationSelector = selectorFamily({
  key: `${pageId}/paginationSelector`,
  get:
    (id = 'pagination') =>
    ({ get }) => {
      return get(atoms.pageConfigAtom(id));
    },
  set:
    (id = 'pagination') =>
    ({ get, set }, { currentPage, itemsPerPage }) => {
      const paginationConfigAtom = atoms.pageConfigAtom(id);
      const dependantFunctions = get(
        atoms.pageConfigAtom('dependantFunctions'),
      );
      const vehicleState = get(atoms.pageConfigAtom('vehicle'));
      const dateFilter = get(atoms.pageConfigAtom('dateFilter'));
      const offset = (currentPage - 1) * itemsPerPage;
      set(paginationConfigAtom, { currentPage, itemsPerPage });

      const variables = {
        filters: dateFilter.filters,
        id: vehicleState.uuid,
        offset,
        limit: itemsPerPage,
        order: [['transactionDate', 'ASC']],
      };
      dependantFunctions.query.getExpenses({ variables });
    },
});

export const dateFilterSelector = selectorFamily({
  key: `${pageId}/dateFilterSelector`,
  get:
    (id = 'dateFilter') =>
    ({ get }) => {
      return get(atoms.pageConfigAtom(id));
    },
  set:
    (id = 'dateFilter') =>
    ({ get, set }, { filters }) => {
      const dateFilterAtom = atoms.pageConfigAtom(id);

      const paginationState = get(atoms.pageConfigAtom('pagination'));
      const dependantFunctions = get(
        atoms.pageConfigAtom('dependantFunctions'),
      );
      const vehicleState = get(atoms.pageConfigAtom('vehicle'));

      const offset =
        (paginationState.currentPage - 1) * paginationState.itemsPerPage;

      set(dateFilterAtom, { filters });

      const variables = {
        filters: filters,
        id: vehicleState.uuid,
        offset,
        limit: paginationState.itemsPerPage,
        order: [['transactionDate', 'ASC']],
      };

      dependantFunctions.query.getExpenses({ variables });
    },
});

export const tableOrderSelector = selectorFamily({
  key: `${pageId}/tableOrderSelector`,
  get:
    (id = 'tableOrder') =>
    ({ get }) => {
      return get(atoms.pageConfigAtom(id));
    },
  set:
    (id = 'tableOrder') =>
    ({ get, set }, { orderStr }) => {
      const tableOrderAtom = atoms.pageConfigAtom(id);
      const tableOrderState = get(tableOrderAtom);

      const dependantFunctions = get(
        atoms.pageConfigAtom('dependantFunctions'),
      );
      const paginationState = get(atoms.pageConfigAtom('pagination'));
      const vehicleState = get(atoms.pageConfigAtom('vehicle'));
      const dateFilter = get(atoms.pageConfigAtom('dateFilter'));
      const offset =
        (paginationState.currentPage - 1) * paginationState.itemsPerPage;

      const order = updateSort({
        originalSort: tableOrderState.order,
        orderStr,
      });
      set(tableOrderAtom, { order });
      const variables = {
        filters: dateFilter.filters,
        id: vehicleState.uuid,
        offset,
        limit: paginationState.itemsPerPage,
        order: orderQueryParser(order),
      };

      dependantFunctions.query.getExpenses({ variables });
    },
});

export const handleActionSelector = selectorFamily({
  key: `${pageId}/pageConfigUpdate`,
  set:
    (id = 'DEFAULT') =>
    ({ get, set }, { label, original }) => {
      const vehicleAtom = atoms.pageConfigAtom('vehicle');
      const deleteExpenseModalInfoAtom = atoms.pageConfigAtom(
        'deleteExpenseModalInfo',
      );
      const dependantFunctions = get(
        atoms.pageConfigAtom('dependantFunctions'),
      );

      const historyState = get(historyStateAtom);

      if (label === 'Add') {
        navigateByHistoryState({
          historyState,
          pathname: `/vehicles/${get(vehicleAtom).id}/expense/new`,
        });
        return;
      }

      if (label === 'Delete') {
        set(deleteExpenseModalInfoAtom, { id: original.id });
        dependantFunctions.openDeleteExpenseModal();
        return;
      }

      if (label === 'Edit') {
        const createEditData = (original) => {
          return {
            lastFourAccountNum: original?.acctn4,
            lastFourCardNum: original?.ccn4,
            transactionDate: original?.transactionDate,
            productDescription:
              original?.fleetCardProductIdOnline ||
              original?.fleetCardProductId,
            unitOfMeasure:
              original?.unitOfMeasureOnline || original?.unitOfMeasure,
            transactionOdometer: original?.odometerReading,
            unitCost: original?.unitCost,
            quantity: original?.unitsBought,
            netDollar: original?.netCost,
            taxAmount: original?.tax?.fuelTax,
            totalCost: original?.grossCost,
            merchantName: original?.fleetCardMerchant?.merchantName,
            phone: original?.fleetCardMerchant?.merchantPhone,
            extension: original?.fleetCardMerchant?.merchantPhoneExt,
            address: original?.fleetCardMerchant?.merchantAddress,
            address2: original?.fleetCardMerchant?.merchantAddress2,
            address3: original?.fleetCardMerchant?.merchantAddress3,
            city: original?.fleetCardMerchant?.merchantCity,
            state: original?.fleetCardMerchant?.merchantStateCode,
            zip: original?.fleetCardMerchant?.merchantPostalCode,
            comment: original?.comment,
          };
        };

        const stateData = {
          editData: createEditData(original),
          editId: original?.id,
        };
        if (original?.recordType === 'WEX') {
          stateData.isWEX = true;
        } else {
          stateData.isWEX = false;
        }

        navigateByHistoryState({
          historyState,
          pathname: `/vehicles/${get(vehicleAtom).id}/expense/edit`,
          state: { data: stateData },
        });
      }
    },
});

export const pageAlertSelector = selector({
  key: `${pageId}/setPageAlert`,
  get: () => () => null,
  set: ({ get }, { pageMsg }) => {
    const dependantFunctions = get(atoms.pageConfigAtom('dependantFunctions'));
    if (pageMsg && dependantFunctions.setPageMsg) {
      dependantFunctions.setPageMsg(pageMsg);
    }
  },
});
