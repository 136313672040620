/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Spinner,
  Tag,
  Modal,
  useModal,
} from '@gsa/afp-component-library';
import {
  UPDATE_VEHICLE_LIFE_CYCLE_OPTIONS,
  LIFECYCLE_UPDATE_TYPE,
} from 'utilities/consts';
import { useVehicle } from 'components/vehicle-details/vehicle-context-provider';
import UpdateVehicleStatusForm from './update-vehicle-status-form';

const UpdateVehicleStatus = ({
  updateVehicleStatusError,
  setModalError,
  vehicle,
  onSave,
  onClose,
  updateVehicleStatusLoading,
  deactiveVehicleStatusLoading,
  isAdmin,
}) => {
  const { showAlert } = useVehicle();
  const [assetLifecycle, setAssetLifecycle] = useState({
    operator: '',
    value: '',
  });

  const { openModal, closeModal } = useModal();

  // assumes vehicle has an indicator and all statuses share the same indicator
  const currentIndicator =
    vehicle.assetLifecycle[0].lifeCycle.lifecycleIndicator;
  const statusOptions = [];
  const allowedStatuses = UPDATE_VEHICLE_LIFE_CYCLE_OPTIONS[currentIndicator];
  for (let value in allowedStatuses) {
    if (
      // AFP-129784, AC2
      parseInt(value) === LIFECYCLE_UPDATE_TYPE.GF_ACTIVE_SPECIAL_DISPOSAL &&
      !isAdmin
    ) {
      continue;
    }
    statusOptions.push({
      value,
      label: allowedStatuses[value],
    });
  }

  useEffect(() => {
    openModal();
  }, []);

  const submit = (data) => {
    const toSave = {
      ...data,
    };

    let statusList = new Set(
      vehicle.assetLifecycle.map(
        (entry) => entry.lifeCycle.lifecycleIndicatorId,
      ),
    );

    // prepare the ID array
    if (toSave.statusOprator === 'add') {
      statusList.add(toSave.newStatusValue);
    } else {
      //remove
      statusList = new Set([toSave.newStatusValue]);
    }

    const ids = [...statusList.values()].map((v) => parseInt(v));

    onSave &&
      onSave(
        {
          assetId: vehicle.uuid,
          lifecycleIndicatorIDs: ids,
        },
        toSave.statusOprator,
      );
  };

  const close = () => {
    onClose && onClose();
    closeModal();
  };
  const ModalHeader = () => {
    return (
      <>
        <div>
          <h1>Update vehicle status</h1>
          <div className="font-size-3">
            Edit status information for VIN {vehicle?.id} in the form below. You
            can remove and add one status at a time.
          </div>
        </div>
      </>
    );
  };

  const CurrentStatusList = () => (
    <>
      {vehicle?.assetLifecycle.map((lifecycleEntry) => (
        <>
          <br />
          <Tag variant="gray">
            <span className="text-uppercase">
              {lifecycleEntry?.lifeCycle?.lifecycleIndicatorStatus}
            </span>
          </Tag>
        </>
      ))}
    </>
  );

  return (
    <div className="afp-modal-wrapper">
      <div className="afp-modal-overlay">
        <Modal
          title={<ModalHeader />}
          variant="large"
          actions={
            <>
              <Button
                variant="unstyled"
                id="cancelUpdateVehicleStatus"
                data-testid="cancelUpdateVehicleStatus"
                label="Cancel"
                onClick={close}
              />

              <Button
                className="margin-left-2"
                type="submit"
                label="Save and close"
                form="update-vehicle-status-form"
                disabled={
                  updateVehicleStatusLoading || deactiveVehicleStatusLoading
                }
              />
              {updateVehicleStatusLoading && (
                <Spinner
                  size="small"
                  aria-busy="true"
                  className="display-inline-block margin-left-2"
                />
              )}
            </>
          }
          onClose={close}
        >
          <b>Current status:</b>
          <CurrentStatusList />
          <br />
          {updateVehicleStatusError?.context === 'updateStatus' &&
            showAlert(updateVehicleStatusError, setModalError)}
          <br />
          <br />
          <UpdateVehicleStatusForm
            onSubmit={submit}
            statusData={assetLifecycle}
            setStatusData={setAssetLifecycle}
            statusOptions={statusOptions}
            vehicle={vehicle}
          />
        </Modal>
      </div>
    </div>
  );
};

export default UpdateVehicleStatus;
