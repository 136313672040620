import { FilterTableFrame, Button } from '@gsa/afp-component-library';
import React, { useMemo } from 'react';
import MotorPoolMembersTable from './motor-pool-members-table';
import MembersSidebar from './members-sidebar';
import { useMotorPool } from '../../motor-pool-provider';

const MembersListing = () => {
  const { dispatchAction } = useMotorPool();
  const FilterUpperRightAction = () => (
    <div className="display-flex flex-column flex-align-end margin-top-neg-05">
      <Button
        label="Add member"
        leftIcon={{
          name: 'add',
          class: 'usa-icon--size-2',
        }}
        variant="outline"
        onClick={() => {
          dispatchAction('SET_MOTOR_POOL_MEMBER_MODAL_MODE', 'ADD_DRIVER');
          dispatchAction('SET_SHOW_MOTOR_POOL_MEMBER_MODAL', true);
        }}
        data-testid="add-member-button"
      />
    </div>
  );
  
  const FilterGrid = useMemo(
    () =>
      FilterTableFrame(
        null,
        FilterUpperRightAction,
        MembersSidebar,
        MotorPoolMembersTable,
      ),
    [],
  );

  return <FilterGrid filterToggle />;
};

export default MembersListing;
