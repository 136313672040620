export const DEFAULT_VALUE = 'DEFAULT';

export const DEFAULT_OPTION = {
  value: DEFAULT_VALUE,
  label: '- Select -',
};

export const DEFAULT_STATE = {
  value: DEFAULT_VALUE,
  options: [DEFAULT_OPTION],
};

export const TYPE = {
  REPORT: 'report',
  TEMPLATE: 'template',
};

export const OWNERSHIP_TYPES = {
  AO: {
    value: 'AO',
    label: 'Agency owned',
  },
  GF: {
    value: 'GF',
    label: 'GSA Leased',
  },
};

export const OWNERSHIP_OPTIONS = [
  { label: 'Agency owned', radioValue: 'AO' },
  { label: 'GSA leased', radioValue: 'GF' },
];

export const FOREIGN_DOMESTIC_OPTIONS = [
  { label: 'Combined', radioValue: 'All' },
  { label: 'Foreign', radioValue: 'F' },
  { label: 'Domestic', radioValue: 'D' },
];

export const FUEL_UNIT_TYPE_OPTIONS = [
  {
    label: 'Natural Units',
    radioValue: 'naturalUnits',
  },
  {
    label: 'Gasoline Gallon Equivalent',
    radioValue: 'gasolineGallonEquivalent',
  },
];

export const SCHEDULE_OPTIONS = [
  { label: 'No, run report now', radioValue: 'no' },
  { label: 'Yes', radioValue: 'yes' },
];

export const PURCHASE_FUEL_TYPES_OPTIONS = [
  DEFAULT_OPTION,
  { label: 'B20', value: 'B20' },
  { label: 'CNG', value: 'CNG' },
  { label: 'DSL', value: 'DSL' },
  { label: 'E85', value: 'E85' },
  { label: 'ELE', value: 'ELE' },
  { label: 'GAS', value: 'GAS' },
  { label: 'LNG', value: 'LNG' },
  { label: 'LPG', value: 'LPG' },
];

export const ADDITIONAL_FIELDS_OPTIONS = [
  { label: 'Fund Code', value: 'fundCode' },
  { label: 'Description 1', value: 'account1' },
  { label: 'Description 2', value: 'account2' },
];

export const generateAccidentOptions = (isScheduled) => [
  {
    label: 'History of all accidents',
    radioValue: 'All',
  },
  ...(isScheduled === 'no'
    ? [
        {
          label: 'Status of only open accidents',
          radioValue: 'Open',
        },
      ]
    : []),
];

export const REPORT_SEARCH_OPTIONS = {
  AIE: [
    { label: 'All AIE History', value: 'all', defaultValue: true },
    { label: 'Select by license plate', value: 'tag' },
    { label: 'Select by VIN', value: 'vin' },
  ],
  FUEL: [
    { label: 'All fuel use history', value: 'all', defaultValue: true },
    { label: 'Select by license plate', value: 'tag' },
    { label: 'Select by VIN', value: 'vin' },
  ],
  REPAIR: [
    { label: 'Select by license plate', value: 'tag', defaultValue: true },
    { label: 'Select by VIN', value: 'vin' },
  ],
};

export const handleSetAgencySelectionValues = (setValue, selectedReport) => {
  setValue('agency', selectedReport?.agencyCode);
  setValue('bureau', selectedReport?.bureauCode);
  // boac and legacyCustomers controls are getting set inside their MultiSelectDropdown components
  setValue('agencyIndicator', selectedReport?.reportCriteria?.agencyIndicator);
  setValue(
    'fuel_report_selection',
    selectedReport?.reportCriteria?.fuelReportType,
  );
};

export const handleSetReportFiltersValues = (
  setValue,
  selectedReport,
  reportType,
) => {
  if (!setValue || !selectedReport || !reportType) return null;
  switch (reportType) {
    case 'FUEL_REPORT':
      setValue(
        'fuel_report_selection',
        selectedReport?.reportCriteria?.fuelReportType,
      );
      setValue(
        'purchase_fuel_type',
        selectedReport?.reportCriteria?.purchaseFuelType,
      );
      setValue(
        'merchant_state',
        selectedReport?.reportCriteria?.merchantStateCode,
      );
      setValue('merchant_zip', selectedReport?.reportCriteria?.merchantZip);
      setValue('fuel_unit_type', selectedReport?.reportCriteria?.fuelUnitType);
      setValue(
        'additional_fields',
        selectedReport?.reportCriteria?.additionalOptions,
      );
      setValue(
        'vehicle_selection_option',
        // eslint-disable-next-line no-nested-ternary
        selectedReport?.reportCriteria?.vin
          ? 'vin'
          : selectedReport?.reportCriteria?.tag
          ? 'tag'
          : 'all',
      );
      // Vehicle options VIN/TAG are getting set inside their Typeahead component
      break;
    case 'CUSTOM_INVENTORY':
      setValue(
        'section_options',
        selectedReport?.reportCriteria?.section_options,
      );
      break;
    case 'AIE':
      setValue(
        'vehicle_selection_option',
        // eslint-disable-next-line no-nested-ternary
        selectedReport?.reportCriteria?.vin
          ? 'vin'
          : selectedReport?.reportCriteria?.tag
          ? 'tag'
          : 'all',
      );
      break;
    case 'CRASH':
      // No need to things here as this type has one prefilled radio box in step 3
      break;
    default:
      return null;
  }
};

export const handleSetReportScheduleValues = (setValue, selectedReport) => {
  setValue('report_name', selectedReport?.reportName);
  setValue('frequency', selectedReport?.frequency);
  setValue('frequency_end_date', selectedReport?.scheduleEndDate);
  setValue(
    'additional_recipients',
    selectedReport?.reportCriteria?.additional_recipients,
  );
};
