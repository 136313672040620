// eslint-disable-next-line filenames/match-exported
const getIncompletePocFilter = (pocKey, operator) => {
  const pocKeys = [
    `$${pocKey}.email_address$`,
    `$${pocKey}.first_name$`,
    `$${pocKey}.last_name$`,
    `$${pocKey}.address_1$`,
    `$${pocKey}.city$`,
    `$${pocKey}.state_code$`,
    `$${pocKey}.postal_code$`,
    `$${pocKey}.phone_1$`,
  ];
  const pocFilters = [];

  pocKeys.forEach((key) => {
    pocFilters.push({
      operator,
      key,
      value: undefined,
    });
    pocFilters.push({
      operator,
      key,
      value: '',
    });
  });

  return pocFilters;
};

// we also find when the *agency code* do not have matching agency name **
// we will see  incomplete vehicle in the table when we try to fetch registered vehicle.
// but in stage we are not see this data problem.

export const getIncompleteFilter = (operator) => {
  return [
    { operator, key: 'agencyCode', value: undefined },
    { operator, key: 'agencyCode', value: '' },
    { operator, key: 'bureauCode', value: undefined },
    { operator, key: 'bureauCode', value: '' },
    { operator, key: 'vin', value: undefined },
    {
      operator,
      key: '$assetLifecycle.lifeCycle.lifecycle_indicator$',
      value: undefined,
    },
    { operator, key: 'makeCode', value: undefined },
    { operator, key: 'modelCode', value: undefined },
    { operator, key: 'modelCode', value: '' },
    { operator, key: 'modelCode', value: -1 },
    {
      operator,
      key: 'makeColorName',
      value: undefined,
    },
    { operator, key: '$makeColor.makeCode$', value: undefined },
    { operator, key: 'fuelCode', value: undefined },
    {
      operator,
      key: 'fastReportable',
      value: undefined,
    },
    ...getIncompletePocFilter(
      'assetContactAssociationPrimary->pointOfContactPrimary',
      operator,
    ),
    ...getIncompletePocFilter(
      'assetContactAssociationSecondary->pointOfContactSecondary',
      operator,
    ),
  ];
};

export default function getVehicleRegistrationFilter(vehicleStatus) {
  switch (vehicleStatus) {
    case 'Registered':
      return [
        {
          operator: 'AND',
          value: [
            {
              operator: 'EQ',
              key: 'isRegistered',
              value: 1,
            },
          ],
        },
      ];

    case 'Exempt':
      return [{ operator: 'EQ', key: 'exemptPlate', value: 1 }];

    case 'Incomplete':
      return [
        {
          operator: 'AND',
          value: [
            {
              operator: 'EQ',
              key: 'isRegistered',
              value: 1,
            },
            {
              operator: 'OR',
              value: getIncompleteFilter('EQ'),
            },
          ],
        },
      ];

    case 'Unregistered':
      return [
        {
          operator: 'AND',
          value: [
            {
              operator: 'NOT',
              key: 'isRegistered',
              value: 1,
            },
          ],
        },
      ];
    case 'Other':
      return [
        {
          operator: 'AND',
          value: [
            {
              operator: 'NOT',
              key: '$assetLifecycle.lifeCycle.lifecycle_indicator$',
              value: 'Active',
            },
            {
              operator: 'AND',
              value: [
                { operator: 'EQ', key: 'exemptPlate', value: 0 },
                {
                  operator: 'OR',
                  value: [
                    {
                      operator: 'EQ',
                      key: '$Vehicle.tag_number$',
                      value: undefined,
                    },
                    { operator: 'EQ', key: '$Vehicle.tag_number$', value: '' },
                  ],
                },
              ],
            },
          ],
        },
      ];
    default:
      return null;
  }
}
