/* eslint-disable react/prop-types */
/* eslint-disable filenames/match-exported */

import React from 'react';

import { ActivityTracker } from '@gsa/afp-component-library';

import {
  MMDDYYYDateFormat,
  ToUTCFormatDate,
  changeNumberFormat,
  currencyFormat,
  threeDecimalCurrencyFormat,
  threeDecimalFormat,
} from 'utilities/format';
import PhoneFormatter from 'utilities/phoneNumberFormatter';

const DetailRow = ({ label, value, address, cityState }) => (
  <div className="expanded-row display-flex flex-justify padding-y-1 border-bottom border-width-2px border-primary-lighter">
    <div>
      <strong>{label}</strong>
    </div>
    <div>
      {value && <div className="float-right align-right">{value}</div>}
      {address && (
        <div
          style={{
            maxWidth: 200,
            overflowWrap: 'break-word',
          }}
          className="float-right"
        >
          {address}
        </div>
      )}
      {cityState && (
        <div
          style={{
            maxWidth: 200,
            overflowWrap: 'break-word',
          }}
          className="float-right align-right"
        >
          {cityState}
        </div>
      )}
    </div>
  </div>
);
const showValueOrEmpty = (value, extraString = ' ') =>
  value ? `${value}${extraString}` : '';
const createAddress = (fleetCardMerchant) => {
  if (!fleetCardMerchant) {
    return null;
  }
  const { merchantAddress, merchantAddress2, merchantAddress3 } =
    fleetCardMerchant;

  return (
    showValueOrEmpty(merchantAddress) +
    showValueOrEmpty(merchantAddress2) +
    showValueOrEmpty(merchantAddress3)
  );
};
const createCityState = (fleetCardMerchant) => {
  if (!fleetCardMerchant) {
    return null;
  }
  const { merchantCity, merchantStateCode, merchantPostalCode } =
    fleetCardMerchant;

  return (
    showValueOrEmpty(merchantCity, ',') +
    showValueOrEmpty(merchantStateCode) +
    showValueOrEmpty(merchantPostalCode, '')
  );
};

const SubRowComponent = ({ row }) => {
  const rowValue = row?.original;

  return (
    <div className="grid-row grid-gap grid-col-12 padding-left-2 ">
      <div className="grid-col-6">
        <DetailRow
          label="Last 4 digit of account number"
          value={rowValue?.acctn4 ?? ''}
        />
      </div>
      <div className="grid-col-6">
        <DetailRow
          label="Unit cost"
          value={changeNumberFormat(
            rowValue?.unitCost,
            threeDecimalCurrencyFormat,
          )}
        />
      </div>
      <div className="grid-col-6">
        <DetailRow
          label="Last 4 digit of card number"
          value={rowValue?.ccn4 ?? ''}
        />
      </div>
      <div className="grid-col-6">
        <DetailRow
          label="Quantity"
          value={changeNumberFormat(rowValue?.unitsBought, threeDecimalFormat)}
        />
      </div>
      <div className="grid-col-6">
        <DetailRow
          label="Posted date"
          value={ToUTCFormatDate({
            dateVal: rowValue?.transactionDate,
            format: MMDDYYYDateFormat,
          })}
        />
      </div>
      <div className="grid-col-6">
        <DetailRow
          label="Net dollar"
          value={changeNumberFormat(rowValue?.netCost, currencyFormat)}
        />
      </div>
      <div className="grid-col-6">
        <DetailRow
          label="Unit of measure"
          value={rowValue?.unitOfMeasureOnline || rowValue?.unitOfMeasure}
        />
      </div>
      <div className="grid-col-6">
        <DetailRow
          label="Tax amount"
          value={changeNumberFormat(rowValue?.tax?.fuelTax, currencyFormat)}
        />
      </div>
      <div className="grid-col-6">
        <DetailRow
          label="Transaction odometer"
          value={changeNumberFormat(rowValue?.odometerReading)}
        />
      </div>
      <div className="grid-col-6">
        <DetailRow
          label="Merchant name"
          value={rowValue?.fleetCardMerchant?.merchantName}
        />
      </div>
      <div className="grid-col-6">
        <DetailRow label="Method of Entry" value={rowValue?.recordType} />
      </div>
      <div className="grid-col-6">
        <DetailRow
          label="Merchant address"
          address={createAddress(rowValue?.fleetCardMerchant)}
          cityState={createCityState(rowValue?.fleetCardMerchant)}
        />
      </div>
      <div className="grid-col-6 grid-offset-6">
        <DetailRow
          label="Merchant phone"
          value={PhoneFormatter(rowValue?.fleetCardMerchant?.merchantPhone)}
        />
      </div>
      {rowValue?.comment && (
        <>
          <div className="grid-col-12 margin-top-3 margin-bottom-neg-4">
            <strong>Comment</strong>
          </div>
          <div className="grid-col-12">
            <ActivityTracker
              className="margin-left-neg-1"
              activities={[
                {
                  type: 'Comment added',
                  date: rowValue?.updatedAt || rowValue?.createdAt,
                  userName: rowValue?.enteredByUserInfo?.fullName,
                  email: rowValue?.enteredByUserInfo?.email,
                  comment: rowValue?.comment,
                },
              ]}
              variant="highlight"
            />
          </div>
        </>
      )}
    </div>
  );
};

export default SubRowComponent;
