import { gql } from '@apollo/client';

export const AttachmentFields = gql`
  fragment AttachmentFields on Attachment {
    id
    name
    description
    contentURL
    type
    size
    lastModified
    createdAt
    updatedAt
    createdByUser
    user {
      fullName
    }
  }
`;

export const GET_ATTACHMENTS = gql`
  query GetAttachments(
    $filter: AttachmentFilter
    $limit: Float
    $offset: Float
    $order: OrderBy
  ) {
    getAttachments(
      filter: $filter
      limit: $limit
      offset: $offset
      order: $order
    ) {
      rows {
        ...AttachmentFields
      }
      count
      hasMore
    }
  }
  ${AttachmentFields}
`;

export const GET_AWS_SIGNED_UPLOAD_URL = gql`
  query GenerateUploadSignedURL($path: String!, $type: String!) {
    generateUploadSignedURL(path: $path, type: $type) {
      signedURL
      fileKey
    }
  }
`;

export const GET_AWS_SIGNED_READ_URL = gql`
  query GenerateReadSignedURL($fileKey: String!, $fileType: String!, $bucket: String) {
    generateReadSignedURL(fileKey: $fileKey, fileType: $fileType, bucket: $bucket)
  }
`;

export const DELETE_VMS_DOC = gql`
  mutation DeleteSupportingDoc(
    $metadataId: String!
    $model: String!
    $modelId: String!
  ) {
    deleteSupportingDoc(
      metadataId: $metadataId
      model: $model
      modelId: $modelId
    )
  }
`;

export const UPDATE_VMS_DOC_DESCRIPTION = gql`
  mutation UpdateDocMetadata($input: DocMetadataUpdateInput!) {
    updateMetadataRecord(input: $input) {
      id
      name
      docStoreUri
      scanStatus
    }
  }
`;
