import React from 'react';
import { useVehicle } from 'components/vehicle-details/vehicle-context-provider';
import { canViewGFVehicleBM, canViewGFVehicles } from 'utilities/authorization';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import { isFeatureEnabled } from 'utilities/feature-toggle';
import LeasingRates from './leasing-rates';

const VehicleRates = () => {
  const { vehicle } = useVehicle();
  const ability = useAppAbility();

  const canView =
    vehicle?.ownershipTypeCode === 'GF' &&
    (canViewGFVehicleBM(ability) || canViewGFVehicles(ability)) &&
    isFeatureEnabled('gf-rates');

  return (
    <div>
      {canView && (
        <div className="grid-container padding-x-0">
          <div className="grid-row grid-gap">
            <div className="tablet:grid-col-6">
              <LeasingRates data={vehicle} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default VehicleRates;
