import React from 'react';
import { Breadcrumbs } from '@gsa/afp-component-library';
import { Link } from 'react-router-dom';
import CampaignsProvider from './recall-campaigns-provider';
import RecallCampaignsFilterProvider from './filters/filter-provider';
import RecallCampaignsHeader from './recall-campaigns-header';
import RecallCampaignsListing from './recall-campaigns-listing';

const RecallCampaigns = () => {
  const trail = [
    <a href={`${window.AFP_CONFIG.appURLs.home}/home`}>Home</a>,
    <Link to="/recalls">Recalls</Link>,
  ];

  return (
    <>
      <CampaignsProvider>
        <RecallCampaignsFilterProvider>
          <Breadcrumbs trail={trail} current="Recall campaigns " />
          <div className="margin-bottom-3">
            <RecallCampaignsHeader />
          </div>
          <RecallCampaignsListing />
        </RecallCampaignsFilterProvider>
      </CampaignsProvider>
    </>
  );
};

export default RecallCampaigns;
