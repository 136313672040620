import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FilterPanel } from '@gsa/afp-component-library';
import { useExpenseFilter } from '../providers';

const { FilterPanelField, FilterPanelFilterShape, useFilterPanel } =
  FilterPanel;

const placeholderOption = {
  value: '',
  label: '-Select office-',
  defaultValue: true,
};

const OfficeFilter = ({ filter }) => {
  const { getFilterFromState, mergedFilters, clearOneFilter } =
    useFilterPanel();

  const { getOffices, offices } = useExpenseFilter();

  const [options, setOptions] = useState([placeholderOption]);
  const [agencyCode, setAgencyCode] = useState('');
  const [bureauCode, setBureauCode] = useState('');

  useEffect(() => {
    const agencyFilter = getFilterFromState('$vehicle.agency_code$');
    const bureauFilter = getFilterFromState('$vehicle.bureau_code$');
    setAgencyCode(agencyFilter.value || '');
    setBureauCode(bureauFilter.value || '');
  }, [mergedFilters]);

  useEffect(() => {
    // clear office when agency, and bureau are changed
    clearOneFilter(filter);

    // eslint-disable-next-line no-unused-expressions
    bureauCode
      ? getOffices({ bureauCode, agencyCode })
      : setOptions([placeholderOption]);
  }, [bureauCode]);

  useEffect(() => {
    setOptions([placeholderOption, ...offices]);
  }, [offices]);

  return (
    <>
      <FilterPanelField
        key={[filter.key]}
        filter={{
          ...filter,
          type: 'select',
          options,
        }}
      />
    </>
  );
};

OfficeFilter.propTypes = {
  filter: PropTypes.shape(FilterPanelFilterShape).isRequired,
};

export default OfficeFilter;
