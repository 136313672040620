import React, { useState } from 'react';
import { Button, TextInput, Icon } from '@gsa/afp-component-library';
import { useLicensePlateDestruction } from '../context';
import _ from 'lodash';

function UpsTrackingNumberTable({ errors, setErrors }) {
  const { destructionInitData, setDestructionInitData } =
    useLicensePlateDestruction();
  const [newUpsTrackingNumber, setNewUpsTrackingNumber] = useState('');

  const handleAddTrackingNumber = () => {
    // check if value exist
    if (_.isEmpty(newUpsTrackingNumber)) {
      setErrors({ trackingNumbers: 'Tracking number is required' });
      return;
    } else if (
      _.indexOf(destructionInitData.trackingNumbers, newUpsTrackingNumber) > -1
    ) {
      setErrors({ trackingNumbers: 'Tracking number cannot be repeated' });
      return;
    } else {
      delete errors['trackingNumbers'];
    }

    setDestructionInitData({
      ...destructionInitData,
      ['trackingNumbers']: [
        ...destructionInitData.trackingNumbers,
        newUpsTrackingNumber,
      ],
    });
    setNewUpsTrackingNumber('');
  };

  const handleDeleteTrackingNumber = (trackingNumber) => {
    setDestructionInitData({
      ...destructionInitData,
      ['trackingNumbers']: [
        ...destructionInitData.trackingNumbers.filter(
          (x) => x !== trackingNumber,
        ),
      ],
    });
  };

  return (
    <>
      <div className="bg-primary-lighter padding-left-2 padding-top-1 padding-bottom-2 margin-top-3">
        <div className="grid-row flex-wrap margin-top-neg-2px">
          <div className="grid-col">
            <TextInput
              id="ups tracking number"
              data-testid="tracking-number"
              type="text"
              label="UPS tracking number"
              name="trackingNumbers"
              onChange={(e) => setNewUpsTrackingNumber(e.target.value)}
              className="width-mobile"
              placeholder="Enter UPS tracking number"
              value={newUpsTrackingNumber}
              required={_.isEmpty(destructionInitData.trackingNumbers)}
              errorMessage={errors['trackingNumbers']}
            />
          </div>
          <div className="grid-col-5 flex-align-end flex-align-self-end">
            <Button
              variant="inversed"
              data-testid="button-tracking-number"
              onClick={handleAddTrackingNumber}
              leftIcon={{ name: 'add', class: 'usa-icon--size-2' }}
              label="Add tracking number"
            />
          </div>
        </div>
      </div>

      <table className="afp-details-table margin-top-5">
        <tbody>
          <tr className="border-bottom-2px">
            <th className="text-left" scope="row">
              UPS tracking number
            </th>
            <th className="text-left" scope="row">
              Actions
            </th>
          </tr>

          <tr className="border-bottom"></tr>
          {destructionInitData.trackingNumbers.map((upsNumber, index) => (
            <tr className="border-bottom" key={index}>
              <td>
                <p className="margin-left-2">{upsNumber}</p>
              </td>
              <td>
                <Button
                  variant="unstyled"
                  onClick={() => handleDeleteTrackingNumber(upsNumber)}
                  data-testid="delete-tracking-number"
                  label="Delete"
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}

export default UpsTrackingNumberTable;
