import React from 'react';
import { emdash } from '../../common';
import ContactInfoDisplay from './contact-info-display';

const CaTableRowDetail = (customerAccount) => {
  const {
    customerAccount: {
      customerAgency,
      customerBureau,
      zoneId,
      managementCenter,
      boac,
      customerId,
      legacyCustomerNumber,
      primaryContactAssociation,
      customerPhysicalOffice,
    },
  } = customerAccount;

  const borderClasses = 'border-bottom border-base-lighter padding-y-1';

  const formatText = (text) => {
    if (Array.isArray(text)) {
      return text.map((item) => (
        <span className="text-right" key={item}>
          {item}
        </span>
      ));
    }
    return text || emdash;
  };

  return (
    <div className="grid-row grid-gap flex-justify">
      <div className="tablet:grid-col-6">
        <div
          className={`${borderClasses} display-flex flex-justify`}
          data-testid="data-section"
        >
          <strong>Agency</strong>
          {customerAgency?.prefixedName || emdash}
        </div>
        <div
          className={`${borderClasses} display-flex flex-justify`}
          data-testid="data-section"
        >
          <strong>Bureau</strong>
          {customerBureau?.prefixedName || emdash}
        </div>

        <div className="detail-row display-flex flex-justify padding-y-1 border-bottom border-width-2px border-base-lighter">
          <div className="detail-title">
            <strong>Office</strong>
          </div>
          <div className="detail-value">
            {formatText(customerPhysicalOffice?.prefixedName)}
          </div>
        </div>

        <div
          className={`${borderClasses} display-flex flex-justify`}
          data-testid="data-section"
        >
          <strong>Agency POC</strong>
          {
            // display primary contact info if available}
            primaryContactAssociation ? (
              <ContactInfoDisplay
                contact={primaryContactAssociation.pointOfContact}
              />
            ) : emdash
          }
        </div>
      </div>
      <div className="tablet:grid-col-6">
        <div
          className={`${borderClasses} display-flex flex-justify`}
          data-testid="data-section"
        >
          <strong>Zone</strong>
          {zoneId || emdash}
        </div>
        <div
          className={`${borderClasses} display-flex flex-justify`}
          data-testid="data-section"
        >
          <strong>FMC</strong>
          <div>
            <div>{managementCenter?.name || emdash}</div>
            {managementCenter?.city && managementCenter?.state && (
              <div>{`${managementCenter.city}, ${managementCenter.state}`}</div>
            )}
          </div>
        </div>
        <div
          className={`${borderClasses} display-flex flex-justify`}
          data-testid="data-section"
        >
          <strong>BOAC</strong>
          {boac || emdash}
        </div>
        <div
          className={`${borderClasses} display-flex flex-justify`}
          data-testid="data-section"
        >
          <strong>Customer account number</strong>
          {customerId || emdash}
        </div>
        <div
          className={`${borderClasses} display-flex flex-justify`}
          data-testid="data-section"
        >
          <strong>Legacy customer number</strong>
          {legacyCustomerNumber || emdash}
        </div>
      </div>
    </div>
  );
};

export default CaTableRowDetail;
