import React, { useEffect, useState } from 'react';
import {
  Button,
  TextInput,
  SelectDropdown,
  Label,
  RequiredFieldIndicator,
  Spinner,
} from '@gsa/afp-component-library';
import { getMonths, getYears } from 'components/forms/widgets/DateMonthYear';
import usePortalModal from '../../../utilities/portal-modal';
import { useLicensePlateDetails } from '../license-plate-details-provider';

const LpInfoEditModal = () => {
  const {
    tagDetails,
    updateLpInfo,
    updatingLpInfoLoading,
    updatedLpData,
    setUpdatingLpInfo,
    updatingLpInfo,
  } = useLicensePlateDetails();

  const [expirationDate, setExpirationDate] = useState({
    month: '',
    year: '',
  });

  const monthOptions = getMonths(true);
  const [yearOptions, setYearOptions] = useState([]);
  const [expirationError, setExpirationDateError] = useState('');
  const [orderNumberError, setOrderNumberError] = useState('');
  const [orderNumber, setOrderNumber] = useState('');

  const [LpEditModal, openModal, closeModal] = usePortalModal();

  useEffect(() => {
    if (updatingLpInfo) {
      openModal();
    }
    if (tagDetails?.unicorTag?.orderNumber)
      setOrderNumber(tagDetails.unicorTag.orderNumber);
    if (tagDetails?.expirationDate) {
      const year = Number(tagDetails.expirationDate.toString().substr(0, 4));
      const month = Number(tagDetails.expirationDate.toString().substr(4, 6));
      setExpirationDate({ month, year });
    }

    const currentYear = new Date().getFullYear();
    const years = getYears(
      new Date((currentYear - 5).toString()),
      new Date((currentYear + 15).toString()),
      true,
    );

    setYearOptions(years);
  }, [updatingLpInfo]);

  const handleEditClose = () => {
    setUpdatingLpInfo(false);
    closeModal();
  };

  useEffect(() => {
    if (updatedLpData) {
      handleEditClose();
    }
  }, [updatedLpData]);

  const handleSave = () => {
    setExpirationDateError(
      !expirationDate.year || !expirationDate.month
        ? 'Month and year are required'
        : undefined,
    );
    setOrderNumberError(!orderNumber ? 'This is required' : undefined);
    if (!orderNumber || !expirationDate.month || !expirationDate.year) return;

    const formatedExpirationDate =
      Number(expirationDate.year) * 100 + Number(expirationDate.month);

    updateLpInfo(formatedExpirationDate, orderNumber);
    handleEditClose();
  };

  const handleChangeOrderNumber = (e) => {
    setOrderNumber(e.target.value);
  };

  const handleMonthChange = (e) => {
    const expDate = { ...expirationDate };
    expDate.month = e.target.value;
    setExpirationDate(expDate);
  };

  const handleYearChange = (e) => {
    const expDate = { ...expirationDate };
    expDate.year = e.target.value;
    setExpirationDate(expDate);
  };

  if (updatingLpInfoLoading) return <Spinner className="padding-y-9" />;

  return (
    <LpEditModal
      title={<h1>Update license plate {tagDetails.id}</h1>}
      onClose={handleEditClose}
      actions={
        <>
          <Button
            variant="unstyled"
            onClick={handleEditClose}
            label="Close"
            id="close-modal-button"
          />
          <Button
            className="margin-left-2"
            onClick={handleSave}
            label="Save and close"
          />
        </>
      }
    >
      <div className="edit-poc grid-column grid-gap">
        <>
          <Label className="text-bold">
            Expiration date <RequiredFieldIndicator />
          </Label>
          {expirationError && (
            <span className="usa-error-message margin-top-3">
              {expirationError}
            </span>
          )}

          <div className="grid-col-4">
            <div className="grid-row grid-gap-2">
              <div className="grid-col-6">
                <SelectDropdown
                  label={<span className="usa-hint">Month</span>}
                  onChange={handleMonthChange}
                  options={monthOptions}
                  value={expirationDate?.month ?? ''}
                />
              </div>
              <div className="grid-col-6">
                <SelectDropdown
                  label={<span className="usa-hint">Year</span>}
                  onChange={handleYearChange}
                  options={yearOptions}
                  value={expirationDate?.year ?? ''}
                />
              </div>
            </div>
          </div>
        </>
        <div className="grid-col-6">
          <TextInput
            label="Order number"
            name="orderNumber"
            required
            value={orderNumber}
            onChange={(val) => handleChangeOrderNumber(val)}
            errorMessage={orderNumberError}
          />
        </div>
      </div>
    </LpEditModal>
  );
};

export default LpInfoEditModal;
