import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';

const MileageExpressFilterContext = createContext({});
const useMileageExpressFilter = () => useContext(MileageExpressFilterContext);

const initialState = {
  error: {},
  filters: [],
  filterStructure: [],
};

const actions = {
  setFilters: 'SET_FILTERS',
  setError: 'SET_ERROR',
  setStructure: 'SET_STRUCTURE',
};

const extractErrorName = (err) => err.name || 'Unknown Error';

const mileageExpressFilterReducer = (state, { action, payload }) => {
  const mergeState = (value, field) => {
    if (!field) {
      return { ...state, error: initialState.error, ...value };
    }
    const merged = { ...state, error: initialState.error };
    merged[field] = value || initialState[field];
    return merged;
  };

  switch (action) {
    case actions.setFilters: {
      return mergeState(
        {
          operator: '$and',
          conditions: payload || [],
        },
        'filters',
      );
    }
    case actions.setStructure: {
      return mergeState(payload, 'filterStructure');
    }
    case actions.setError: {
      return mergeState(extractErrorName(payload), 'error');
    }
    default:
      throw new Error('Invalid user filter action');
  }
};

const MileageExpressFilterProvider = ({ children }) => {
  const [state, setDispatch] = useReducer(
    mileageExpressFilterReducer,
    initialState,
    () => initialState,
  );

  const dispatch = (action, payload) => setDispatch({ action, payload });
  const dispatchError = (error) => dispatch(actions.setError, error);

  const dispatchFilters = (conditions) =>
    dispatch(actions.setFilters, conditions);

  const dispatchFilterStructure = (structure) =>
    dispatch(actions.setStructure, structure);

  return (
    <MileageExpressFilterContext.Provider
      value={{
        ...state,
        dispatch,
        setFilters: dispatchFilters,
        setStructure: dispatchFilterStructure,
      }}
    >
      {children}
    </MileageExpressFilterContext.Provider>
  );
};

MileageExpressFilterProvider.defaultProps = {
  children: undefined,
};

MileageExpressFilterProvider.propTypes = {
  children: PropTypes.node,
};

export { MileageExpressFilterProvider as default, useMileageExpressFilter };
