import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useFormContext, Controller } from 'react-hook-form';
import { TextInput, SelectDropdown, Spinner } from '@gsa/afp-component-library';
import { GET_REPAIR_VENDOR_LOOKUPS } from '../../../services/data-layer/repair-order';

const VendorFields = {
  vendorDescription: { name: 'vendorDescription' },
  vendorName: { name: 'vendorName' },
  vendorPhone: { name: 'vendorPhone' },
  vendorPhoneExt: { name: 'vendorPhoneExt' },
  vendorAddress1: { name: 'vendorAddress1' },
  vendorAddress2: { name: 'vendorAddress2' },
  vendorCity: { name: 'vendorCity' },
  vendorState: { name: 'vendorState' },
  vendorZipcode: { name: 'vendorZipcode' },
};

const maskPhoneNumber = (value) => {
  const stripped = value.replace(/\D/g, '');
  const len = stripped.length;
  if (stripped && len > 0) {
    return `${stripped.substring(0, 3)}${
      len > 3 ? '-' + stripped.substring(3, 6) : ''
    }${len > 6 ? '-' + stripped.substring(6) : ''}`;
  } else {
    return '';
  }
};

const onChangeNumerals = (event, onChange) => {
  const match = event.target.value.match(/\D/);
  if (match) {
    event.target.value = event.target.value.replace(/\D/g, '');
    const curPos = match.index;
    event.target.selectionStart = curPos;
    event.target.selectionEnd = curPos;
  }
  onChange(event);
};

const VendorForm = () => {
  const [stateOptions, setStateOptions] = useState(undefined);
  const [vendorDescriptionOptions, setVendorDescriptionOptions] =
    useState(undefined);
  const {
    formState: { errors },
    control,
  } = useFormContext();

  // Queries
  const [getRepairVendorLookupsQuery] = useLazyQuery(
    GET_REPAIR_VENDOR_LOOKUPS,
    {
      onError: () => {
        // TODO error handling
      },
      onCompleted: (data) => {
        const { getVendorDescriptions = [], getStates = [] } = data || {};

        const stateOpts = getStates
          .filter((s) => s.isoCountryCode2 === 'US')
          .map((c) => ({
            value: c.stateCode,
            label: `${c.stateCode} - ${c.stateName}`,
          }));

        const vendorDescriptionOpts = getVendorDescriptions.map((vd) => {
          return { value: vd, label: vd };
        });

        setStateOptions(stateOpts);
        setVendorDescriptionOptions([
          { value: '', label: '-Select-' },
          ...vendorDescriptionOpts,
        ]);
      },
      fetchPolicy: 'no-cache',
    },
  );

  const {
    vendorDescription,
    vendorName,
    vendorPhone,
    vendorPhoneExt,
    vendorAddress1,
    vendorAddress2,
    vendorCity,
    vendorState,
    vendorZipcode,
  } = VendorFields;

  useEffect(() => {
    getRepairVendorLookupsQuery();
  }, []);

  if (!stateOptions || !vendorDescriptionOptions) {
    return <Spinner data-testid="loading-vendor-lookups" />;
  }

  return (
    <div data-testid="repair-vendor-form">
      <div className="grid-row grid-gap">
        <div className="grid-col-12">
          <div className="grid-col-7">
            <Controller
              name={vendorDescription.name}
              control={control}
              render={({ field }) => {
                return (
                  <SelectDropdown
                    {...field}
                    label={
                      <span className="text-bold">Vendor description</span>
                    }
                    name={vendorDescription.name}
                    id={vendorDescription.name}
                    options={vendorDescriptionOptions}
                    errorMessage={
                      errors && errors[vendorDescription.name]
                        ? errors[vendorDescription.name].message
                        : null
                    }
                    aria-invalid={
                      errors && errors[vendorDescription.name]
                        ? 'true'
                        : 'false'
                    }
                  />
                );
              }}
            />
          </div>
        </div>

        <div className="grid-col-12">
          <div className="grid-col-7">
            <Controller
              name={vendorName.name}
              control={control}
              render={({ field }) => {
                return (
                  <TextInput
                    {...field}
                    type="text"
                    label={<span className="text-bold">Vendor name</span>}
                    name={vendorName.name}
                    id={vendorName.name}
                    characterLimit={30}
                    errorMessage={
                      errors && errors[vendorName.name]
                        ? errors[vendorName.name].message
                        : null
                    }
                    aria-invalid={
                      errors && errors[vendorName.name] ? 'true' : 'false'
                    }
                  />
                );
              }}
            />
          </div>
        </div>

        <div className="grid-col-12">
          <div className="grid-row">
            <div className="grid-col-7">
              <Controller
                name={vendorPhone.name}
                control={control}
                render={({ field: { ref, value, onChange, ...fieldRest } }) => {
                  const phoneValue = maskPhoneNumber(value);
                  return (
                    <TextInput
                      {...fieldRest}
                      inputRef={ref}
                      type="text"
                      label={<span className="text-bold">Phone</span>}
                      name={vendorPhone.name}
                      id={vendorPhone.name}
                      value={phoneValue}
                      errorMessage={
                        errors && errors[vendorPhone.name]
                          ? errors[vendorPhone.name].message
                          : null
                      }
                      aria-invalid={
                        errors && errors[vendorPhone.name] ? 'true' : 'false'
                      }
                      onChange={(e) => onChangeNumerals(e, onChange)}
                    />
                  );
                }}
              />
            </div>
            <div className="grid-col-5 padding-left-3">
              <Controller
                name={vendorPhoneExt.name}
                control={control}
                render={({ field: { onChange, ...restProps } }) => {
                  return (
                    <TextInput
                      {...restProps}
                      type="text"
                      label={<span className="text-bold">Extension</span>}
                      name={vendorPhoneExt.name}
                      id={vendorPhoneExt.name}
                      errorMessage={
                        errors && errors[vendorPhoneExt.name]
                          ? errors[vendorPhoneExt.name].message
                          : null
                      }
                      aria-invalid={
                        errors && errors[vendorPhoneExt.name] ? 'true' : 'false'
                      }
                      onChange={(e) => onChangeNumerals(e, onChange)}
                    />
                  );
                }}
              />
            </div>
          </div>
        </div>

        <div className="grid-col-12">
          <div className="grid-col-7">
            <Controller
              name={vendorAddress1.name}
              control={control}
              render={({ field }) => {
                return (
                  <TextInput
                    {...field}
                    type="text"
                    label={<span className="text-bold">Address line 1</span>}
                    name={vendorAddress1.name}
                    id={vendorAddress1.name}
                    errorMessage={
                      errors && errors[vendorAddress1.name]
                        ? errors[vendorAddress1.name].message
                        : null
                    }
                    aria-invalid={
                      errors && errors[vendorAddress1.name] ? 'true' : 'false'
                    }
                  />
                );
              }}
            />
          </div>
        </div>

        <div className="grid-col-12">
          <div className="grid-col-7">
            <Controller
              name={vendorAddress2.name}
              control={control}
              render={({ field }) => {
                return (
                  <TextInput
                    {...field}
                    type="text"
                    label={<span className="text-bold">Address line 2</span>}
                    name={vendorAddress2.name}
                    id={vendorAddress2.name}
                    errorMessage={
                      errors && errors[vendorAddress2.name]
                        ? errors[vendorAddress2.name].message
                        : null
                    }
                    aria-invalid={
                      errors && errors[vendorAddress2.name] ? 'true' : 'false'
                    }
                  />
                );
              }}
            />
          </div>
        </div>

        <div className="grid-col-12">
          <div className="grid-row">
            <div className="grid-col-7">
              <Controller
                name={vendorCity.name}
                control={control}
                render={({ field }) => {
                  return (
                    <TextInput
                      {...field}
                      type="text"
                      label={<span className="text-bold">City</span>}
                      name={vendorCity.name}
                      id={vendorCity.name}
                      errorMessage={
                        errors && errors[vendorCity.name]
                          ? errors[vendorCity.name].message
                          : null
                      }
                      aria-invalid={
                        errors && errors[vendorCity.name] ? 'true' : 'false'
                      }
                    />
                  );
                }}
              />
            </div>
            <div className="grid-col-5 padding-left-3">
              <Controller
                name={vendorState.name}
                control={control}
                render={({ field }) => {
                  return (
                    <SelectDropdown
                      {...field}
                      label={<span className="text-bold">State</span>}
                      name={vendorState.name}
                      id={vendorState.name}
                      options={[
                        { value: '', label: '-Select-' },
                        ...stateOptions,
                      ]}
                      errorMessage={
                        errors && errors[vendorState.name]
                          ? errors[vendorState.name].message
                          : null
                      }
                      aria-invalid={
                        errors && errors[vendorState.name] ? 'true' : 'false'
                      }
                    />
                  );
                }}
              />
            </div>
          </div>
        </div>

        <div className="grid-col-12">
          <div className="grid-col-7">
            <Controller
              name={vendorZipcode.name}
              control={control}
              render={({ field: { onChange, ...restProps } }) => {
                return (
                  <TextInput
                    {...restProps}
                    type="text"
                    label={<span className="text-bold">ZIP code</span>}
                    name={vendorZipcode.name}
                    id={vendorZipcode.name}
                    errorMessage={
                      errors && errors[vendorZipcode.name]
                        ? errors[vendorZipcode.name].message
                        : null
                    }
                    aria-invalid={
                      errors && errors[vendorZipcode.name] ? 'true' : 'false'
                    }
                    onChange={(e) => onChangeNumerals(e, onChange)}
                  />
                );
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorForm;
