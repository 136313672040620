import ordersFilters from '../filter-structures/orders';

const defaultFilters = () => {
  return [
    ordersFilters.orderNumber,
    ordersFilters.orderStatus,
    ordersFilters.orderPoC,
  ];
};

export default defaultFilters;
