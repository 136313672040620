import React, { createContext, useContext, useReducer } from 'react';
import { useLazyQuery } from '@apollo/client';
import {
  GET_REPAIR_SERVICE_LINES,
  GET_SERVICE_CODES,
  GET_SERVICE_COMMON_CODES,
} from '../../../services/data-layer/repair-order';
import { PageMode } from './page-provider';

export const ServiceLinesErrors = {
  MinOneServiceLineItem: {
    message:
      'Add at least one repair service type before saving the repair order.',
  },
};

const ServiceLinesContext = createContext({});
const useServiceLine = () => useContext(ServiceLinesContext);

const initialState = {
  serviceLines: [],
  totals: {
    laborCost: 0,
    partsCost: 0,
    miscCost: 0,
  },
  classes: [{ value: '', label: '-Select-' }],
  reasons: [{ value: '', label: '-Select-' }],
  failureCauses: [{ value: '', label: '-Select-' }],
  worksAccomplished: [{ value: '', label: '-Select-' }],
  serviceTypes: [],
  typeaheadData: { field: '', values: [] },
  repairNumber: undefined,
  mode: undefined,
  error: {},
};

const actions = {
  setServiceClass: 'SET_CLASS',
  setReason: 'SET_REASON',
  setFailureCause: 'SET_FAILURE_CAUSE',
  setWorkAccomplished: 'SET_WORK_ACCOMPLISHED',
  setServiceTypes: 'SET_SERVICE_TYPES',
  setServiceLines: 'SET_SERVICE_LINES',
  setPayload: 'SET_PAYLOAD',
  setError: 'SET_ERROR',
};

const mapTypeaheadData = (field, values) => {
  return {
    field,
    values,
  };
};

const serviceLinesReducer = (state, { action, payload }) => {
  switch (action) {
    case actions.setError:
      return { ...state, error: payload };
    case actions.setPayload:
      return { ...state, error: {}, ...payload };
    case actions.setServiceClass:
      return { ...state, error: {}, classes: payload };
    case actions.setReason:
      return { ...state, error: {}, reasons: payload };
    case actions.setFailureCause:
      return { ...state, error: {}, failureCauses: payload };
    case actions.setWorkAccomplished:
      return { ...state, error: {}, worksAccomplished: payload };
    case actions.setServiceTypes:
      return {
        ...state,
        error: {},
        serviceTypes: payload,
        typeaheadData: mapTypeaheadData(
          'serviceTypes',
          payload.map((p) => `${p.id}-${p.combinedComponent}`),
        ),
      };
    case actions.setServiceLines:
      return { ...state, error: {}, serviceLines: payload };
    default:
      return state;
  }
};

const ServiceLinesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    serviceLinesReducer,
    initialState,
    () => initialState,
  );

  const setError = (error) => {
    dispatch({ action: actions.setError, payload: error });
  };

  const getActualCosts = (lineItem) => {
    const actualCosts = (lineItem?.serviceLineCosts || []).filter(
      (c) => c.type === 'Actual',
    );

    const isMiscellaneous = isMiscellaneousService(lineItem?.serviceCode || {});
    const actualCost = actualCosts.length ? actualCosts[0] : {};
    const cost = {
      serviceLineCostId: actualCost.id,
      serviceLineId: actualCost.serviceLineId,
      type: actualCost.type || 'Actual',
      partsCost: actualCost.partsCost || 0,
      partsQuantity: actualCost.partsQuantity || 0,
      laborRate: actualCost.laborRate || 0,
      laborHours: actualCost.laborHours || 0,
      miscCost: actualCost.miscCost || 0,
      partsTotal: actualCost.partsTotal || 0,
      laborTotal: actualCost.laborTotal || 0,
      totalAmount: actualCost.totalAmount || 0,
      isMiscellaneous,
    };

    return cost;
  };

  const calculatePartsTotal = (costs) => {
    return costs.partsQuantity * costs.partsCost || 0.0;
  };
  const calculateLaborTotal = (costs) => {
    return costs.laborRate * costs.laborHours || 0.0;
  };
  const calculateMiscCost = (costs) => {
    return costs.partsTotal + costs.laborTotal;
  };

  // Queries
  const [getServiceCommonCodesQuery] = useLazyQuery(GET_SERVICE_COMMON_CODES, {
    onError: () => {
      // TODO error handling
    },
    onCompleted: (data) => {
      const commonCodes = data?.getServiceCommonCodes || [];
      const typeMap = {
        Service_reason: 'reasons',
        Service_class: 'classes',
        Failure_cause: 'failureCauses',
        Work_accomplished: 'worksAccomplished',
      };
      const allCommonCodes = commonCodes.reduce(
        (acc, cc) => {
          const { type, code, value } = cc;
          if (typeMap[type] && acc[typeMap[type]]) {
            acc[typeMap[type]].push({ label: value, value: code });
          }
          return acc;
        },
        {
          classes: [{ value: '', label: '-Select-' }],
          reasons: [{ value: '', label: '-Select-' }],
          failureCauses: [{ value: '', label: '-Select-' }],
          worksAccomplished: [{ value: '', label: '-Select-' }],
        },
      );

      dispatch({
        action: actions.setPayload,
        payload: { ...allCommonCodes },
      });
    },
  });
  const [serviceTypesQuery] = useLazyQuery(GET_SERVICE_CODES, {
    onError: () => {
      // TODO error handling
    },
    onCompleted: (data) => {
      const serviceTypes = data?.getServiceCodes
        ? data?.getServiceCodes?.rows || []
        : [];

      dispatch({
        action: actions.setServiceTypes,
        payload: serviceTypes,
      });
    },
  });
  const [getRepairServiceLinesQuery] = useLazyQuery(GET_REPAIR_SERVICE_LINES, {
    onError: () => {
      // TODO error handling
    },
    onCompleted: (data) => {
      const serviceLines = data?.getServiceLinesByServiceId || [];
      const totals = {
        laborCost: 0,
        partsCost: 0,
        miscCost: 0,
        totalAmount: 0,
      };
      const items = serviceLines.map((item) => {
        const costs = getActualCosts(item);
        if (costs.isMiscellaneous) {
          totals.miscCost += costs.miscCost;
        } else {
          totals.laborCost += costs.laborTotal;
          totals.partsCost += costs.partsTotal;
        }
        totals.totalAmount += costs.totalAmount;

        item = { ...item, ...costs };
        return item;
      });
      dispatch({
        action: actions.setPayload,
        payload: { serviceLines: items, totals },
      });
    },
  });

  const searchServiceTypes = (query) => {
    serviceTypesQuery({
      variables: {
        offset: 0,
        limit: Infinity,
        searchParams: query,
      },
    });
  };

  const extractServiceType = (compoundLabel) => {
    const parts = compoundLabel.split('-');
    if (parts.length < 2) {
      return undefined;
    }
    const [id, ...rest] = parts;
    const serviceType = state.serviceTypes.filter((c) => c.id == id);

    if (serviceType.length) {
      return serviceType[0];
    }

    const combinedComponent = rest.join('-');
    const componentLevels = combinedComponent.split('>');
    return {
      id,
      componentOne: componentLevels[0],
      componentTwo: componentLevels.length > 1 ? componentLevels[1] : '',
      combinedComponent,
    };
  };
  const isMiscellaneousService = (serviceCode) => {
    const componentOne = (serviceCode?.componentOne || '').toLowerCase().trim();
    const componentTwo = (serviceCode?.componentTwo || '').toLowerCase().trim();

    if (componentOne === 'miscellaneous') {
      return true;
    }

    if (componentOne === 'fees' && componentTwo === 'miscellaneous charge') {
      return true;
    }

    return false;
  };
  const addTotalCosts = (item, totals) => {
    if (isMiscellaneousService(item.serviceCode)) {
      totals.miscCost += item.miscCost;
    } else {
      totals.laborCost += item.laborTotal;
      totals.partsCost += item.partsTotal;
    }
  };
  const restTotalCosts = (item, totals) => {
    if (isMiscellaneousService(item.serviceCode)) {
      totals.miscCost -= item.miscCost;
    } else {
      totals.laborCost -= item.laborTotal;
      totals.partsCost -= item.partsTotal;
    }
  };

  const isLaborChange = (field) =>
    (field === 'laborRate') | (field === 'laborHours');
  const isPartsChange = (field) =>
    field === 'partsQuantity' || field === 'partsCost';

  const updateServiceItem = ({ id, field, value }) => {
    const { serviceLines } = state;
    const totals = {
      laborCost: 0,
      partsCost: 0,
      miscCost: 0,
      totalAmount: 0,
    };
    const items = serviceLines.map((item) => {
      if (item.id === id) {
        item[field] = value;
        if (isPartsChange(field)) {
          item.partsTotal = calculatePartsTotal(item);
        }
        if (isLaborChange(field)) {
          item.laborTotal = calculateLaborTotal(item);
        }
        if (isMiscellaneousService(item.serviceCode)) {
          item.miscCost = calculateMiscCost(item);
        } else {
          item.miscCost = 0;
        }
      }

      item.totalAmount = item.partsTotal + item.laborTotal;

      if (!item.deleted) {
        addTotalCosts(item, totals);
      }

      return item;
    });

    dispatch({
      action: actions.setPayload,
      payload: {
        serviceLines: items,
        totals,
      },
    });
  };

  const serviceLineDuplicated = (serviceLines, serviceTypeId) => {
    return (serviceLines || []).some(
      (sl) => !sl.deleted && sl.serviceCode.id == serviceTypeId,
    );
  };

  const randomLetter = () => {
    const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    return alphabet[Math.floor(Math.random() * alphabet.length)];
  };
  const newId = () =>
    randomLetter() + Math.random().toString(3, 6).substring(2, 9);

  const addServiceItem = (serviceTypeLabel, force = false) => {
    const { serviceLines } = state;
    const serviceCode = extractServiceType(serviceTypeLabel);

    if (!serviceCode) {
      return {};
    }

    const { id: serviceCodeId } = serviceCode;

    if (!force && serviceLineDuplicated(serviceLines, serviceCodeId)) {
      return { duplicated: true };
    }
    serviceLines.push({
      id: newId(),
      serviceId: state.repairNumber,
      new: true,
      serviceCodeId,
      serviceCode,
      serviceClassCode: '',
      serviceReasonCode: '',
      serviceFailureCause: '',
      serviceWorkAccomplished: '',
      partsCost: 0,
      partsQuantity: 0,
      laborRate: 0,
      laborHours: 0,
      miscCost: 0,
      partsTotal: 0,
      laborTotal: 0,
      totalAmount: 0,
    });

    dispatch({
      action: actions.setPayload,
      payload: {
        serviceLines,
      },
    });

    return { success: true };
  };

  const removeServiceItem = ({ id }) => {
    const { serviceLines, totals: t } = state;
    const totals = {
      laborCost: t.laborCost,
      partsCost: t.partsCost,
      miscCost: t.miscCost,
    };
    const items = serviceLines.reduce((result, item) => {
      if (item.id === id) {
        restTotalCosts(item, totals);
        item.deleted = true;
      }
      if (item.id === id && item.new) {
        return result;
      }

      result.push(item);
      return result;
    }, []);

    dispatch({
      action: actions.setPayload,
      payload: {
        serviceLines: items,
        totals,
      },
    });
  };

  const initServiceLines = ({ repairNumber, mode }) => {
    getServiceCommonCodesQuery({
      variables: {
        types: [
          'Service reason',
          'Service class',
          'Failure cause',
          'Work accomplished',
        ],
      },
    });
    if (mode === PageMode.New) {
      dispatch({
        action: actions.setPayload,
        payload: {
          mode,
          repairNumber,
          serviceLines: [],
        },
      });
    } else {
      getRepairServiceLinesQuery({
        variables: { serviceId: repairNumber },
      });
      dispatch({
        action: actions.payload,
        payload: { repairNumber, mode },
      });
    }
  };

  return (
    <ServiceLinesContext.Provider
      value={{
        ...state,
        searchServiceTypes,
        initServiceLines,
        updateServiceItem,
        addServiceItem,
        removeServiceItem,
        setError,
      }}
    >
      {children}
    </ServiceLinesContext.Provider>
  );
};

export { ServiceLinesProvider as default, useServiceLine };
