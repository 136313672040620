/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { StatusBadge } from '@gsa/afp-component-library';
import classnames from 'classnames';
import { emdash } from 'components/common';
import moment from 'moment';
import { getPmDistanceByLocation } from 'components/vehicle-details/sidenav-widgets/preventative-maintenance/helper';
import { useLazyQuery } from '@apollo/client';
import { usePmExpress } from './pm-express-provider';
import { GET_PM_EXPANDED_ROW } from './pm-express.gql';

const formatDate = (date) => {
  if (date) return moment(date).format('MM/DD/YYYY');
  return emdash;
};

const getDateEntry = (createdAt, updatedAt) => {
  if (updatedAt) return formatDate(updatedAt);
  if (createdAt) return formatDate(createdAt);
  return emdash;
};

const getRecallStatusLabel = (recallStatus) => {
  if (Number(recallStatus))
    return <StatusBadge variant="Urgent-White">Yes</StatusBadge>;
  return <StatusBadge variant="Inactive-Gray">No</StatusBadge>;
};

export const RowSubDetail = ({ detail }) => {
  const [fetchedPmRowData, setFetchedPmRowData] = useState({ ...detail });

  const [getExpandedRowPmData] = useLazyQuery(GET_PM_EXPANDED_ROW, {
    fetchPolicy: 'network-only',
    // eslint-disable-next-line no-console
    onError: (err) => console.log(err),
    onCompleted: ({ getExpandedRowPmData: expandedData }) => {
      setFetchedPmRowData({ ...fetchedPmRowData, ...expandedData });
    },
  });

  useEffect(() => {
    detail.assetPmId &&
      getExpandedRowPmData({
        variables: {
          assetPmId: detail.assetPmId,
        },
      });
  }, [detail.assetPmId]);

  const borderClasses = classnames(
    'border-bottom border-base-lighter padding-y-1',
  );

  const { pmList } = usePmExpress();

  const selectedPmUnchangedData = pmList?.rows.find(
    (pmData) => pmData.assetPmId === detail.assetPmId,
  );

  return (
    <div className="grid-row grid-gap flex-justify">
      <div className="tablet:grid-col-6">
        <div
          className={`${borderClasses} display-flex flex-justify`}
          data-testid="data-section"
        >
          <strong>VIN</strong>
          {fetchedPmRowData?.vehicle?.vin ? (
            <Link
              to={`/vehicles/${encodeURIComponent(
                fetchedPmRowData?.vehicle.vin,
              )}/pm`}
            >
              {fetchedPmRowData?.vehicle.vin}
            </Link>
          ) : (
            emdash
          )}
        </div>
        <div
          className={`${borderClasses} display-flex flex-justify`}
          data-testid="data-section"
        >
          <strong>Legacy customer number</strong>
          {fetchedPmRowData?.vehicle?.customer?.legacyCustomerNumber ?? emdash}
        </div>

        <div
          className={`${borderClasses} display-flex flex-justify`}
          data-testid="data-section"
        >
          <strong>PM schedule type</strong>
          {fetchedPmRowData?.pmSchedule?.pmScheduleType || emdash}
        </div>
        <div
          className={`${borderClasses} display-flex flex-justify`}
          data-testid="data-section"
        >
          <strong>PM schedule interval mileage</strong>
          {getPmDistanceByLocation(
            fetchedPmRowData?.vehicle?.fmcId,
            fetchedPmRowData?.pmSchedule?.pmMilesInterval,
            fetchedPmRowData?.pmSchedule?.pmKilometersInterval,
          ) || emdash}
        </div>
        <div
          className={`${borderClasses} display-flex flex-justify`}
          data-testid="data-section"
        >
          <strong>Open safety recalls</strong>
          {getRecallStatusLabel(fetchedPmRowData?.recall?.status)}
        </div>
        <div
          className={`${borderClasses} display-flex flex-justify`}
          data-testid="data-section"
        >
          <strong>Invoice attached?</strong>

          {fetchedPmRowData?.invoiceData?.fileType &&
          fetchedPmRowData?.invoiceData?.docStoreUri ? (
            <span>Yes</span>
          ) : (
            <span>No</span>
          )}
        </div>
      </div>
      <div className="tablet:grid-col-6">
        <div
          className={`${borderClasses} display-flex flex-justify`}
          data-testid="data-section"
        >
          <strong>Last PM date</strong>
          {selectedPmUnchangedData?.currentPmDate
            ? moment
                .utc(selectedPmUnchangedData?.currentPmDate)
                .format('MM/DD/YYYY')
            : emdash}
        </div>
        <div
          className={`${borderClasses} display-flex flex-justify`}
          data-testid="data-section"
        >
          <strong>Last PM mileage</strong>
          {selectedPmUnchangedData?.currentPmMileage
            ? `${selectedPmUnchangedData?.currentPmMileage?.toLocaleString()} (mi/km)`
            : emdash}
        </div>
        <div
          className={`${borderClasses} display-flex flex-justify`}
          data-testid="data-section"
        >
          <strong>Method of entry</strong>
          {fetchedPmRowData?.methodOfEntry || emdash}
        </div>
        <div
          className={`${borderClasses} display-flex flex-justify`}
          data-testid="data-section"
        >
          <strong>PM schedule interval months</strong>
          {fetchedPmRowData?.pmSchedule?.pmMonthsInterval || emdash}
        </div>
        <div
          className={`${borderClasses} display-flex flex-justify`}
          data-testid="data-section"
        >
          <strong>User</strong>
          {fetchedPmRowData?.createdByUser?.fullName || emdash}
        </div>
        <div
          className={`${borderClasses} display-flex flex-justify`}
          data-testid="data-section"
        >
          <strong>Date of entry</strong>
          {getDateEntry(
            selectedPmUnchangedData?.createdAt,
            selectedPmUnchangedData?.updatedAt,
          )}
        </div>
      </div>
    </div>
  );
};
