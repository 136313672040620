import React from 'react';
import moment from 'moment';
import { Controller } from 'react-hook-form';
// eslint-disable-next-line import/named
import {
  RadioButton,
  RequiredFieldIndicator,
} from '@gsa/afp-component-library';
import DateRangeField from './date-range-field';
import { generateAccidentOptions } from '../config/helpers';
import { useExport } from '../export-provider';
import { HiddenField } from '../components/hidden-field';

const dateRangeDesc =
  'Choose a specific date range for the report, based on the date the accident occurred. By default all available records will be included, per selections made in other sections.';

const fromProps = {
  required: true,
  id: 'crashReportFromDate',
  defaultValue: moment().subtract(1, 'months').format('YYYY-MM-DD'),
};
const toProps = {
  required: true,
  id: 'crashReportToDate',
  defaultValue: moment().format('YYYY-MM-DD'),
};
const rangeDesc = {
  required: false,
  id: 'crashReportRangeDesc',
  value: dateRangeDesc,
};

// eslint-disable-next-line react/prop-types
export const CrashReportFilters = ({ control, watch, register }) => {
  const accidentSelection = watch('accident_selection') || 'All';

  const { isScheduled } = useExport();
  const accidentOptions = generateAccidentOptions(isScheduled);

  return (
    <div className="margin-bottom-6">
      <section className="margin-bottom-6">
        <h2 className="text-primary margin-bottom-1">CRASH Report Options</h2>
        <div className="usa-form-group mobile-lg:grid-col-10">
          <div className="margin-top-3">
            <b>Select status</b> <RequiredFieldIndicator />
          </div>
          <Controller
            control={control}
            name="accident_selection"
            defaultValue={accidentOptions[0].radioValue}
            rules={{ required: 'This is a required field' }}
            render={({ field: { onChange, name, value } }) => {
              return (
                <>
                  {accidentOptions.map(({ label, radioValue }) => (
                    <RadioButton
                      name={name}
                      key={label}
                      value={radioValue}
                      checked={radioValue === value}
                      label={<p>{label}</p>}
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                    />
                  ))}
                </>
              );
            }}
          />
        </div>
      </section>
      {isScheduled === 'no' && accidentSelection === 'All' ? (
        <DateRangeField
          control={control}
          fromProps={fromProps}
          toProps={toProps}
          rangeDesc={rangeDesc}
        />
      ) : (
        <>
          <HiddenField register={register} name={fromProps.id} />
          <HiddenField register={register} name={toProps.id} />
        </>
      )}
    </div>
  );
};
