import { DatePicker, Label, TimePicker } from '@gsa/afp-component-library';
import React, { useState, useEffect } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import moment from 'moment-timezone';
import { addTimes } from 'utilities/time-util';
import { CREATE_UPDATE_RESERVATION_DATE_FIELDS } from './reservation-constants';
import { useMotorPool } from '../../../motor-pool-provider';

const ReservationDateField = () => {
  const {
    setError,
    clearErrors,
    formState: { errors },
    control,
    setValue,
  } = useFormContext();

  const [minStartTime, setMinStartTime] = useState('00:00');
  const [minEndTime, setMinEndTime] = useState('00:00');

  const { startDate, startTime, endDate, endTime } =
    CREATE_UPDATE_RESERVATION_DATE_FIELDS;

  const {
    reservationModalMode,
    reservationListingSelected,
    selectedMotorPoolById,
    vehiclesAvailableDatesSelected,
  } = useMotorPool();

  const isStartTimesDisabled =
    reservationModalMode === 'UPDATE_RESERVATION' &&
    reservationListingSelected?.reservationStatus?.status ===
      'Vehicle Dispatched';

  useEffect(() => {
    if (isStartTimesDisabled) {
      setValue(
        startTime.name,
        moment
          .tz(
            reservationListingSelected?.scheduledPickupDate,
            selectedMotorPoolById?.timezone,
          )
          .format('hh:mm'),
      );
      setValue(
        startDate.name,
        moment
          .tz(
            reservationListingSelected?.scheduledPickupDate,
            selectedMotorPoolById?.timezone,
          )
          .format('MM/DD/YYYY'),
      );
    }
  }, [isStartTimesDisabled]);

  const startDateValue = useWatch({
    name: startDate.name,
  });

  const endDateValue = useWatch({
    name: endDate.name,
  });

  const startTimeValue = useWatch({
    name: startTime.name,
  });

  const endTimeValue = useWatch({
    name: endTime.name,
  });

  useEffect(() => {
    if (
      vehiclesAvailableDatesSelected?.scheduledPickupDate ===
        vehiclesAvailableDatesSelected?.scheduledReturnDate &&
      vehiclesAvailableDatesSelected?.scheduledReturnTime <=
        vehiclesAvailableDatesSelected?.scheduledStartTime
    ) {
      setError(endTime.name, {
        type: 'custom',
        message: 'End time must be greater than start time.',
      });
    }
  }, [vehiclesAvailableDatesSelected]);

  const formatDefaultDate = (dateType) => {
    if (reservationModalMode === 'UPDATE_RESERVATION') {
      return moment
        .tz(
          reservationListingSelected?.[dateType],
          selectedMotorPoolById?.timezone,
        )
        .format('YYYY-MM-DD');
    }

    // if date is selected in availablity filters, use that date
    if (
      vehiclesAvailableDatesSelected &&
      vehiclesAvailableDatesSelected?.[dateType]
    ) {
      return moment(vehiclesAvailableDatesSelected?.[dateType]).format(
        'YYYY-MM-DD',
      );
    }
    return '';
  };

  const formatDefaultTime = (dateType, timeType) => {
    // if time is selected in availablity filters, use that time
    if (
      vehiclesAvailableDatesSelected &&
      vehiclesAvailableDatesSelected?.[timeType]
    ) {
      return vehiclesAvailableDatesSelected[timeType];
    }

    return reservationListingSelected?.[dateType]
      ? moment
          .tz(
            reservationListingSelected?.[dateType],
            selectedMotorPoolById?.timezone,
          )
          .format('HH:mm')
      : '08:00';
  };

  // FIXME: after release. default values not setting properly
  const handleMinStartTime = (selectedDate = startDateValue) => {
    const minutesToBeAdded = moment().minute() <= 30 ? 30 : 0;
    // rounding to the nearest 30 minutes
    selectedDate === moment().format('YYYY-MM-DD')
      ? setMinStartTime(
          moment()
            .add(moment().minute() > 30 && 1, 'hours')
            .minutes(minutesToBeAdded)
            .format('HH:mm'),
        )
      : setMinStartTime('00:00');
  };

  // FIXME: after release. default values not setting properly
  const handleMinEndTime = (selectedEndDate) => {
    if (selectedEndDate === startDateValue) {
      startTimeValue && setMinEndTime(addTimes(startTimeValue, '00:30'));
      return;
    }
    selectedEndDate === moment().format('YYYY-MM-DD')
      ? startTimeValue && setMinEndTime(addTimes(startTimeValue, '00:30'))
      : setMinEndTime('00:00');
  };

  const handleBlur = (e, name, label) => {
    const { id, value } = e.target;
    /**
     * if the value is empty and the user is not selecting the time from the dropdown
     * then show the error message, with out the nodeName check the error message will show
     * when the user clicks on the time picker and tries to select a time
     * */
    if (
      !value &&
      (id === startTime.id || id === endTime.id) &&
      e.relatedTarget?.nodeName !== 'LI'
    ) {
      setError(name, {
        type: 'required',
        message: `${label} is a required field.`,
      });
    }
  };

  return (
    <>
      <div className="grid-row">
        <div className="tablet:grid-col-5">
          {!isStartTimesDisabled ? (
            <Controller
              name={startDate.name}
              control={control}
              rules={{ required: `${startDate.label} is required.` }}
              render={({ field, ref }) => {
                return (
                  <DatePicker
                    {...field}
                    ref={ref}
                    name={startDate.name}
                    id={startDate.id}
                    label={
                      <Label required className="text-bold">
                        {startDate.label}
                      </Label>
                    }
                    hint="mm/dd/yyyy"
                    minDate={moment().format('YYYY-MM-DD')}
                    defaultValue={formatDefaultDate(startDate.name)}
                    maxDate={endDateValue}
                    onChange={(val) => {
                      field.onChange(
                        val ? moment(val).format('YYYY-MM-DD') : null,
                      );
                      handleMinStartTime(moment(val).format('YYYY-MM-DD'));
                      if (
                        endDateValue === moment(val).format('YYYY-MM-DD') &&
                        endTimeValue <= startTimeValue
                      ) {
                        setError(endTime.name, {
                          type: 'custom',
                          message: `${endTime.label} must be greater than start time.`,
                        });
                      } else {
                        clearErrors(startDate.name);
                      }
                    }}
                    errorMessage={
                      errors && errors[startDate.name]
                        ? errors[startDate.name].message
                        : null
                    }
                    aria-invalid={
                      errors && errors[startDate.name] ? 'true' : 'false'
                    }
                  />
                );
              }}
            />
          ) : (
            <>
              <Label className="text-bold">{startDate.label}</Label>
              {moment
                .tz(
                  reservationListingSelected?.scheduledPickupDate,
                  selectedMotorPoolById?.timezone,
                )
                .format('MM/DD/YYYY')}
            </>
          )}
        </div>
        <div className="tablet:grid-col-3">
          {!isStartTimesDisabled ? (
            <div data-testid={startTime.id} className="margin-right-2">
              <Controller
                key={`${startTime.id}-${minStartTime}`}
                name={startTime.name}
                control={control}
                rules={{ required: `${startTime.label} is required.` }}
                render={({ field: { ref, ...fieldProps } }) => (
                  <TimePicker
                    id={startTime.id}
                    name={startTime.name}
                    label={`${startTime.label} ${
                      selectedMotorPoolById?.timezone
                        ? `(${moment
                            .tz(selectedMotorPoolById?.timezone)
                            .format('z')})`
                        : ''
                    }`}
                    required
                    hint="hh:mm"
                    minTime={minStartTime}
                    defaultValue={formatDefaultTime(
                      startDate.name,
                      startTime.name,
                    )}
                    {...fieldProps}
                    onChange={(val) => {
                      fieldProps.onChange(val);
                      if (
                        endDateValue === startDateValue &&
                        endTimeValue <= val
                      ) {
                        setError(endTime.name, {
                          type: 'custom',
                          message: `${endTime.label} must be greater than start time.`,
                        });
                      } else {
                        clearErrors(startTime.name);
                      }
                    }}
                    onBlur={(e) => {
                      handleBlur(e, startTime.name, startTime.label);
                    }}
                    errorMessage={
                      errors && errors[startTime.name]
                        ? errors[startTime.name].message
                        : null
                    }
                    aria-invalid={
                      errors && errors[startTime.name] ? 'true' : 'false'
                    }
                  />
                )}
              />
            </div>
          ) : (
            <>
              <Label className="text-bold">
                {`${startTime.label} ${
                  selectedMotorPoolById?.timezone
                    ? `(${moment
                        .tz(selectedMotorPoolById?.timezone)
                        .format('z')})`
                    : ''
                }`}
              </Label>
              {moment
                .tz(
                  reservationListingSelected?.scheduledPickupDate,
                  selectedMotorPoolById?.timezone,
                )
                .format('hh:mm A')}
            </>
          )}
        </div>
      </div>
      <div className="grid-row">
        <div className="tablet:grid-col-5">
          <Controller
            name={endDate.name}
            control={control}
            rules={{ required: `${endDate.label} is required.` }}
            render={({ field, ref }) => {
              return (
                <DatePicker
                  {...field}
                  ref={ref}
                  name={endDate.name}
                  id={endDate.id}
                  label={
                    <Label required className="text-bold">
                      {endDate.label}
                    </Label>
                  }
                  hint="mm/dd/yyyy"
                  minDate={startDateValue || moment().format('YYYY-MM-DD')}
                  defaultValue={formatDefaultDate(endDate.name)}
                  onChange={(val) => {
                    field.onChange(
                      val ? moment(val).format('YYYY-MM-DD') : null,
                    );
                    handleMinEndTime(moment(val).format('YYYY-MM-DD'));
                    if (
                      moment(val).format('YYYY-MM-DD') === startDateValue &&
                      endTimeValue <= startTimeValue
                    ) {
                      setError(endTime.name, {
                        type: 'required',
                        message: `${endTime.label} must be greater than start time.`,
                      });
                    }
                    clearErrors(endDate.name);
                  }}
                  errorMessage={
                    errors && errors[endDate.name]
                      ? errors[endDate.name].message
                      : null
                  }
                  aria-invalid={
                    errors && errors[endDate.name] ? 'true' : 'false'
                  }
                />
              );
            }}
          />
        </div>
        <div className="tablet:grid-col-3">
          <div data-testid={endTime.id} className="margin-right-2">
            <Controller
              key={`${endTime.id}-${minEndTime}`}
              name={endTime.name}
              control={control}
              rules={{ required: `${endTime.label} is required.` }}
              render={({ field: { ref, ...fieldProps } }) => (
                <TimePicker
                  id={endTime.id}
                  name={endTime.name}
                  label={`${endTime.label} ${
                    selectedMotorPoolById?.timezone
                      ? `(${moment
                          .tz(selectedMotorPoolById?.timezone)
                          .format('z')})`
                      : ''
                  }`}
                  required
                  hint="hh:mm"
                  minTime={minEndTime}
                  defaultValue={formatDefaultTime(endDate.name, endTime.name)}
                  {...fieldProps}
                  onChange={(val) => {
                    fieldProps.onChange(val);
                    if (
                      endDateValue === startDateValue &&
                      val <= startTimeValue
                    ) {
                      setError(endTime.name, {
                        type: 'required',
                        message: `${endTime.label} must be greater than start time.`,
                      });
                    } else {
                      clearErrors(endTime.name);
                    }
                  }}
                  onBlur={(e) => {
                    handleBlur(e, endTime.name, endTime.label);
                  }}
                  errorMessage={
                    errors && errors[endTime.name]
                      ? errors[endTime.name].message
                      : null
                  }
                  aria-invalid={
                    errors && errors[endTime.name] ? 'true' : 'false'
                  }
                />
              )}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ReservationDateField;
