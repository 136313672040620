import { useLazyQuery } from '@apollo/client';
import { Typeahead } from '@gsa/afp-component-library';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { GET_VEHICLES_BY_PARTIAL_VIN } from '../../../services/data-layer';
import { renderSubComponent } from './header-tag-lookup';

function HeaderVINLookup({ onSelect, disabled }) {
  const [getVehiclesByPartialVin, { data }] = useLazyQuery(
    GET_VEHICLES_BY_PARTIAL_VIN,
    {
      fetchPolicy: 'no-cache',
      onError(err) {
        // eslint-disable-next-line no-console
        console.warn(err);
      },
    },
  );

  const [typeaheads, setTypeaheads] = useState([]);
  const [typeaheadValues, setTypeaheadValues] = useState([]);
  const history = useHistory();

  useEffect(() => {
    if (data) {
      setTypeaheads(data?.getVehiclesByPartialVin);
    }
  }, [data]);

  useEffect(() => {
    const ids = [];
    if (typeaheads && typeaheads.length > 0) {
      typeaheads.forEach((item) => {
        ids.push(item.id);
      });
    }
    setTypeaheadValues(ids);

    // route to vehicle details if only one matching result
    if (typeaheads?.length === 1) {
      onSelect
        ? onSelect(typeaheads[0].id)
        : history.push(
            `/vehicles/${encodeURIComponent(typeaheads[0].id)}/overview`,
          );
    }
  }, [typeaheads]);

  const fetchVehicles = (_, val) => {
    if (val) {
      getVehiclesByPartialVin({
        variables: { partialVin: val },
      });
    }
  };

  const handleSelect = (vin) => {
    if (vin) {
      onSelect
        ? onSelect(vin)
        : history.push(`/vehicles/${encodeURIComponent(vin)}/overview`);
    }
  };

  const handleClear = () => {
    onSelect && onSelect('');
  };

  return (
    <Typeahead
      id="vin"
      filterValue=""
      fetchTypeaheadValues={fetchVehicles}
      typeaheadValues={typeaheadValues}
      placeholder="Search vehicle by VIN number"
      inputCharNum={6}
      ariaLabel="Search vehicle by VIN number"
      debounceDelay={500}
      promptText="Search requires 6 characters"
      noResultsText="No results found. The VIN number does not exist or you do not have permission to access the vehicle."
      generateCustomOption={(vin) => renderSubComponent(typeaheads, vin, 'vin')}
      onOptionEnter={handleSelect}
      disabled={disabled}
      onClear={handleClear}
    />
  );
}

HeaderVINLookup.propTypes = {
  onSelect: PropTypes.func,
  disabled: PropTypes.bool,
};
HeaderVINLookup.defaultProps = {
  onSelect: undefined,
  disabled: false,
};

export default HeaderVINLookup;
