import React from 'react';
import { StatusBadge } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';

const TAG_STATUS_LABELS = {
  SH: 'Shipped',
  SHIP: 'Shipped',
  RECV: 'Received',
  MISS: 'Missing',
  RETN: 'Pending destruction',
  ATTD: 'Attached',
  DESV: 'Destroyed',
  ACKO: 'Acknowledged',
};

const TAG_STATUS_COLORS = {
  SH: 'Warning-Gray', //this should be removed when we update import
  SHIP: 'Warning-Gray',
  ACKO: 'Info-Gray',
  RECV: 'Info-Gray',
  MISS: 'Urgent-Gray',
  RETN: 'Warning-Gray',
  ATTD: 'Ready-Gray',
  DESV: 'Inactive-Gray',
};

export default function TagStatus({ tagStatus }) {
  return (
    <StatusBadge variant={TAG_STATUS_COLORS[tagStatus]}>
      {TAG_STATUS_LABELS[tagStatus]}
    </StatusBadge>
  );
}

TagStatus.propTypes = {
  tagStatus: PropTypes.string.isRequired,
};
