import { gql } from '@apollo/client';

export const EDIT_OFFICE = gql`
  mutation EditOffice(
    $officeName: String!
    $agencyCode: String!
    $bureauCode: String!
    $officeCode: String!
  ) {
    editOffice(
      officeName: $officeName
      agencyCode: $agencyCode
      bureauCode: $bureauCode
      officeCode: $officeCode
    ) {
      agencyCode
      bureauCode
      officeCode
      officeName
    }
  }
`;

export const DELETE_VMS_OFFICE = gql`
  mutation DeleteVMSOffice($office: DeleteOfficeInput!) {
    deleteVMSOffice(office: $office) {
      message
      affectedOffice {
        officeCode
        officeName
      }
    }
  }
`;

export const ASSOCIATE_CUSTOMER_ACCOUNTS_TO_OFFICE = gql`
  mutation AssociateCustomerAccountsToOffice(
    $customerAccountIds: [Int!]!
    $officeCode: String!
  ) {
    associateCustomerAccountsToOffice(
      customerAccountIds: $customerAccountIds
      officeCode: $officeCode
    ) {
      customerId
    }
  }
`;

export const DISASSOCIATE_CUSTOMER_ACCOUNT_FROM_OFFICE = gql`
  mutation DisassociateCustomerAccountFromOffice($customerId: Int!) {
    disassociateCustomerAccountFromOffice(
      customerId: $customerId
    ) {
      customerId
      accountName
    }
  }
`;
