/* eslint-disable react/prop-types */
import React from 'react';
import { DatePicker, RequiredFieldIndicator } from '@gsa/afp-component-library';
import moment from 'moment';
import { USDateStrToUTCStr } from 'utilities/format';
import { Controller, useWatch } from 'react-hook-form';

const DateRangeField = ({
  fromProps,
  toProps,
  control,
  isValidRange,
  rangeDesc,
  customErrorMessage
}) => {
  const dateRangeDescDefault =
    'Choose a specific date range for the report. By default all available records will be included, per selections made in other sections.';

  const fromDateValue = useWatch({ name: fromProps?.id, control });
  const toDateValue = useWatch({ name: toProps?.id, control });
  const dateRangeDesc =
    rangeDesc !== undefined ? rangeDesc.value : dateRangeDescDefault;

  const validateDates = (required, fieldName) => {
    return {
      validate: (value) => {
        if (required && !value) return 'This is required';

        if (isValidRange !== undefined)
          return !isValidRange && fieldName === fromProps.id
            ? customErrorMessage || 'The start date should be less than or equal to 1 year from the end date'
            : undefined;
        return undefined;
      },
    };
  };

  return (
    <section className="margin-bottom-6">
      <h2 className="text-primary margin-bottom-1">Date selection</h2>
      <div className="usa-form-group mobile-lg:grid-col-10">
        <span>{dateRangeDesc}</span>
        <div className="grid-row grid-gap">
          <div className="grid-col-6">
            <Controller
              name={fromProps?.id}
              control={control}
              rules={validateDates(fromProps.required, fromProps?.id)}
              render={({
                field: { ref, value, ...rest },
                formState: { errors },
              }) => {
                return (
                  <DatePicker
                    {...rest}
                    id={fromProps?.id}
                    name={fromProps?.id}
                    data-testid={fromProps?.id}
                    value={value}
                    label={
                      <span>
                        {fromProps?.label || 'From date'}{' '}
                        {fromProps?.required && <RequiredFieldIndicator />}
                      </span>
                    }
                    defaultValue={value || fromProps?.defaultValue}
                    maxDate={toDateValue || moment().format('YYYY-MM-DD')}
                    hint="mm/dd/yyyy"
                    onChange={(val) => {
                      rest.onChange(val ? USDateStrToUTCStr(val) : null);
                    }}
                    errorMessage={errors?.[fromProps?.id]?.message}
                  />
                );
              }}
            />
          </div>
          <div className="grid-col-6">
            <Controller
              name={toProps?.id}
              control={control}
              rules={validateDates(toProps.required, toProps?.id)}
              render={({
                field: { ref, value, ...rest },
                formState: { errors },
              }) => {
                return (
                  <DatePicker
                    {...rest}
                    id={toProps?.id}
                    name={toProps?.id}
                    data-testid={toProps?.id}
                    value={value}
                    label={
                      <span>
                        {toProps?.label || 'To date'}{' '}
                        {toProps?.required && <RequiredFieldIndicator />}
                      </span>
                    }
                    defaultValue={value || toProps?.defaultValue}
                    minDate={fromDateValue}
                    maxDate={moment().format('YYYY-MM-DD')}
                    hint="mm/dd/yyyy"
                    onChange={(val) => {
                      rest.onChange(val ? USDateStrToUTCStr(val) : null);
                    }}
                    errorMessage={errors?.[toProps?.id]?.message}
                  />
                );
              }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default DateRangeField;
