import React from 'react';
import { Button } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';
import { useRegistration } from '../reg-context-provider';

function NextButton(props) {
  return (
    <Button
      {...props}
      type="button"
      size="large"
      data-testid="button-next"
      className="tablet:display-flex align-center"
      rightIcon={{ name: 'arrow_forward' }}
      label="Next"
    />
  );
}

function PrevButton(props) {
  return (
    <Button
      type="button"
      size="large"
      className="tablet:display-flex align-center"
      {...props}
      variant="outline"
      leftIcon={{ name: 'arrow_back' }}
      label="Previous"
    />
  );
}

// eslint-disable-next-line react/prop-types
function ButtonContainer({ children, ...props }) {
  return (
    <div
      className="form-navigation-buttons tablet:display-flex margin-bottom-5"
      {...props}
    >
      {children}
    </div>
  );
}

export default function FormStepButtons({ accessError }) {
  const {
    prevStep,
    nextStep,
    step,
    registerVehicle,
    isActiveStatus,
    isRegistered,
  } = useRegistration();
  switch (step) {
    case 1:
      return isRegistered() || (accessError && isActiveStatus()) ? null : (
        <ButtonContainer>
          <NextButton onClick={nextStep} />
        </ButtonContainer>
      );
    case 2:
      return (
        <ButtonContainer>
          <PrevButton
            className="margin-bottom-2 tablet:margin-bottom-0"
            onClick={prevStep}
          >
            Prev
          </PrevButton>
          <NextButton onClick={nextStep} />
        </ButtonContainer>
      );
    case 3:
      return (
        <ButtonContainer>
          <PrevButton onClick={prevStep}>Prev</PrevButton>
          <Button
            type="button"
            size="large"
            data-testid="btn-complete-reg"
            onClick={registerVehicle}
            label="Complete Registration"
          />
        </ButtonContainer>
      );
    default:
      return null;
  }
}

FormStepButtons.defaultProps = {
  accessError: false,
};
FormStepButtons.propTypes = {
  accessError: PropTypes.bool,
};
