import { useLazyQuery } from '@apollo/client';
import { FilterPanel } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FETCH_TYPEAHEAD } from '../../../services/data-layer';
import getNestedOperatorFilter from '../../user-filters/license-plate-filters/nested-operator-filter';
import { useLicensePlate } from '../context';
import { INIT_NESTED_FILTERS } from '../helpers/use-license-plate-manager';
import usePaginationManager from '../helpers/pagination-manager';
// TODO: handle OR conditions in cmp-lib

const formatFsrFilter = (conditions) => {
  if (conditions?.length > 0) {
    const tagExists =
      conditions.findIndex((f) => f.key?.includes('tagNumber')) !== -1;
    const fsrFilterIndex = conditions.findIndex((f) =>
      f.key?.includes('fsrEmail'),
    );
    if (fsrFilterIndex > -1) {
      const fsrFilter = conditions.splice(fsrFilterIndex, 1)[0];

      const { operator, value } = fsrFilter;
      const fsrOrConditions = [
        {
          operator,
          value,
          key: '$vehicle.secondary_poc_email_address$',
        },
        {
          operator,
          value,
          key: 'customerpointofcontactemailaddress',
        },
      ];

      const defaultFilters = [];
      if (!tagExists) {
        const tagFilter = { operator: 'LIKE', key: 'tagnumber', value: 'G%' };
        defaultFilters.push(INIT_NESTED_FILTERS, tagFilter);
      }
      return [
        {
          operator: 'AND',
          value: [
            ...defaultFilters,
            { operator: 'OR', value: fsrOrConditions },
            ...conditions,
          ],
        },
      ];
    }

    return [
      {
        operator: 'AND',
        value: tagExists ? conditions : [INIT_NESTED_FILTERS, ...conditions],
      },
    ];
  }

  return [];
};

const SidebarWidget = ({ updateAppliedFilters }) => {
  const { setFilters, order, limit, filterStructure } = useLicensePlate();
  const [handlePaginationChange] = usePaginationManager();

  const [getTypeaheads, { data }] = useLazyQuery(FETCH_TYPEAHEAD, {
    fetchPolicy: 'no-cache',
  });

  const [typeaheadData, setTypeaheadData] = useState();

  useEffect(() => {
    if (data) {
      setTypeaheadData(data.getTypeaheads.typeaheads);
    }
  }, [data]);

  const onChange = (conditions) => {
    for (let i = 0; i < conditions.length; i += 1) {
      if (conditions[i].operator === 'nestedOperator') {
        const nestedOperatorFilters = getNestedOperatorFilter(
          conditions[i].value,
        );
        conditions.splice(i, 1, ...nestedOperatorFilters);
      }
    }
    handlePaginationChange(1, limit);
    setFilters(formatFsrFilter(conditions));
  };

  if (filterStructure) {
    return (
      <>
        <FilterPanel.FilterPanel
          filterStructure={filterStructure}
          setQueryFiltersState={onChange}
          clearButtonLabel="Reset all"
          showClearIcon
          model="Tag"
          order={order || [['id', 'ASC']]}
          fetchTypeaheads={getTypeaheads}
          typeaheadData={typeaheadData}
          showSearchIcon
          updateAppliedFilters={updateAppliedFilters}
        />
      </>
    );
  }
  return null;
};

SidebarWidget.propTypes = {
  updateAppliedFilters: PropTypes.func.isRequired,
};

export default SidebarWidget;
