import React, { useEffect, useState } from 'react';
import { useQuery, useLazyQuery } from '@apollo/client';
import { isFeatureEnabled } from 'utilities/feature-toggle';
import { Link } from 'react-router-dom';
import { isEqual } from 'lodash';
import { itemInventoryStatuses, ENTRY_METHOD } from 'utilities/consts';
import {
  GET_FUEL_TYPES,
  GET_PURCHASE_ORDER_NUMBER,
  VERIFY_PURCHASE_ORDER,
} from '../../../services/data-layer';
import useRegistrationParams from './registration-params';
import useVerifyVin from './use-verify-vin';
import useImpossibleRegistration from '../widgets/impossible-registration';
import useRegistrationFormState from './registration-form-state';
import useStepFSM from './steps-fsm';
import useErrorManager from './error-manager';
import useUserVerifyModal from './user-verify-modal';
import useFormValidation from './use-form-validation';
import useAddressValidation from './use-address-validation';
import useRegisterVehicle from './use-register-vehicle';
import vinVerificationTypes from '../widgets/vin-verification-enum';
import { pointOfContactIsInvalid } from '../../vehicle-registration-status-tag/index';
import { getPoc } from '../../../utilities/format';

const RESET_VEH_INFO = {
  modelYear: null,
  makeColorName: null,
  makeColorLabel: null,
  makeCode: null,
  makeName: null,
  modelCode: null,
  modelName: null,
  agencyCode: null,
  tagAgencyCode: null,
  tagBureauCode: null,
  tagOfficeCode: null,
  selectedAgencyCode: null,
  selectedBureauCode: null,
  selectedOfficeCode: null,
  bureauCode: null,
  subSectionTypeCode: null,
  fuelCode: null,
  fuelDescription: null,
  fastReportable: true,
  nhtsaVerified: null,
  exemptPlate: null,
  itemType: null,
  tagNumber: null,
  tagExpirationDate: null,
  statePlate: null,
  unverifiedVinOption: null,
};

export const modalMap = {
  modalName: 'REGISTER_VEHICLE',
  modalMode: { PURCHASE_ORDER_NUMBER: 'PURCHASE_ORDER_NUMBER' },
};

export const initPurchaseOrderNumberState = {
  purchaseOrderNumberEntryMethod: null,
  purchaseOrderNumber: null,
  isRequirePurchaseOrderNumber: isFeatureEnabled('purchase-order-number'),
  isValidOrderNumber: true,
};

export const isValidAddress = (poc) => {
  if (
    poc.primaryAddress &&
    poc.city &&
    poc.countryCode &&
    poc.postalCode &&
    poc.stateCode
  ) {
    return true;
  }
  return false;
};

export default function registrationManager() {
  const { data: fuelTypes } = useQuery(GET_FUEL_TYPES);
  const [vinFromParam, registrationType, setRegistrationType] =
    useRegistrationParams();
  const [purchaseOrderNumberEntryMethod, setEntryMethod] = useState(
    initPurchaseOrderNumberState.purchaseOrderNumberEntryMethod,
  );

  const [isRequirePurchaseOrderNumber, setIsRequirePurchaseOrderNumber] =
    useState(initPurchaseOrderNumberState.isRequirePurchaseOrderNumber);
  const initialModalState = {
    currentModalName: null,
    currentMode: null,
    currentModalAlert: { type: null, message: null, header: null },
  };
  const [modalState, setModalState] = useState(initialModalState);
  const [purchaseOrderNumber, setPurchaseOrderNumber] = useState(
    initPurchaseOrderNumberState.purchaseOrderNumber,
  );
  const [formState, setFormState] = useRegistrationFormState();
  const [verifyVin, data, loading, verifyErr, resetError, setErrorMsg] =
    useVerifyVin({
      setModalState,
      modalMap,
      setPurchaseOrderNumber,
      formState,
    });
  const [isCurrentlyRegistered, setIsCurrentlyRegistered] = useState(false);
  // because the special requirement this state use for show the error message on the orderNumber form field in PURCHASE_ORDER_NUMBER modal
  const [isValidOrderNumber, setIsValidOrderNumber] = useState(
    initPurchaseOrderNumberState.isValidOrderNumber,
  );
  const [UnavailableModal, showUnavailable, setBodyTextType] =
    useImpossibleRegistration(formState.vin);

  const resetCurrentModal = () => {
    setModalState(initialModalState);
  };

  const [getPurchaseOrderNumber] = useLazyQuery(GET_PURCHASE_ORDER_NUMBER, {
    fetchPolicy: 'no-cache',
    onError(err) {
      setErrorMsg(err);
    },
    onCompleted(purchaseOrderNumberData) {
      if (!purchaseOrderNumberData?.getPurchaseOrderNumber) {
        setModalState({
          currentModalName: modalMap.modalName,
          currentMode: modalMap.modalMode.PURCHASE_ORDER_NUMBER,
          currentModalAlert: {
            type: 'error',
            message:
              'Please provide the corresponding order number to register this vehicle. If this information cannot be located, please contact your agency vehicle purchasing official to obtain the order information.',
            header: null,
          },
        });
        setEntryMethod(ENTRY_METHOD.MANUAL);
      } else {
        setPurchaseOrderNumber(purchaseOrderNumberData?.getPurchaseOrderNumber);
        setEntryMethod(ENTRY_METHOD.STORE);
      }
    },
  });

  const [verifyPurchaseOrderNumber] = useLazyQuery(VERIFY_PURCHASE_ORDER, {
    fetchPolicy: 'no-cache',
    onError(err) {
      setErrorMsg(err);
    },
    onCompleted(verifyPurchaseOrderNumberData) {
      if (verifyPurchaseOrderNumberData) {
        if (verifyPurchaseOrderNumberData?.verifyPurchaseOrder?.isVerified) {
          setPurchaseOrderNumber(
            verifyPurchaseOrderNumberData?.verifyPurchaseOrder
              ?.purchaseOrderNumber,
          );
          resetCurrentModal();
        } else {
          setIsValidOrderNumber(false);
        }
      }
    },
  });

  const [
    UserVerifyModal,
    openUserVerifyModal,
    verifyModalState,
    verifyOption,
    setVinVerification,
  ] = useUserVerifyModal({
    formState,
    registrationType,
    getPurchaseOrderNumber,
    isRequirePurchaseOrderNumber,
  });

  const [step, prevStep, nextStep, setStep] = useStepFSM();
  const [validateStep] = useFormValidation(step, {
    isRequirePurchaseOrderNumber,
    purchaseOrderNumber,
  });

  const [validations, setvalidations] = useState({});
  const [errors, { addError, clearError }] = useErrorManager();
  const [vinSource, setVinSource] = useState(null);
  const [registrationError, setRegistrationError] = useState(null);
  const [validatePocAddress, { AddressConfirmModal }] = useAddressValidation(
    formState,
    setFormState,
  );
  const [
    registerVehicle,
    {
      complete,
      pdfComplete,
      pdfUrl,
      loading: submittingRegistration,
      error: submitRegistrationError,
    },
  ] = useRegisterVehicle({
    formState,
    registrationType,
    vinSource,
    purchaseOrderNumberEntryMethod,
    purchaseOrderNumber,
  });
  const [accessError, setAccessError] = useState(false);
  const [isIncomplete, setIsIncomplete] = useState(true);

  const resetFirstStepState = (vin = null) => {
    setEntryMethod(initPurchaseOrderNumberState.purchaseOrderNumberEntryMethod);
    setPurchaseOrderNumber(initPurchaseOrderNumberState.purchaseOrderNumber);
    setIsRequirePurchaseOrderNumber(
      initPurchaseOrderNumberState.isRequirePurchaseOrderNumber,
    );
    setFormState({
      vin: vin ? vin.toUpperCase() : '',
      tagNumber: null,
      modelYear: null,
      makeName: null,
      modelName: null,
      [vinVerificationTypes.INTERNATIONAL]: null,
      [vinVerificationTypes.PRE_1981]: null,
      [vinVerificationTypes.UNVERIFIED]: null,
      userVerifiedVin: null,
      itemType: null,
      caseNumber: null,
      originalAquisitionDate: null,
    });
    setIsValidOrderNumber(true);
  };

  const handleNextStep = () => {
    const vals = validateStep(formState);
    if (Object.values(vals).some((val) => !!val)) {
      setvalidations(vals);
      addError('Some fields require your attention');
    } else {
      setvalidations({});
      clearError();
      nextStep();
    }
  };

  const handlePrevStep = () => {
    setvalidations({});
    prevStep();
  };

  const getFieldValidation = (field) => {
    return validations[field] || '';
  };

  const updatePoc = (pocNum, poc) => {
    const pocRefField = `pointOfContact${pocNum}`;

    const existingPocAddress = {
      city: formState[pocRefField].city,
      countryCode: formState[pocRefField].countryCode,
      postalCode: formState[pocRefField].postalCode,
      primaryAddress: formState[pocRefField].primaryAddress,
      stateCode: formState[pocRefField].stateCode,
    };

    const newPocAddress = {
      city: poc.city,
      countryCode: poc.countryCode,
      postalCode: poc.postalCode,
      primaryAddress: poc.primaryAddress,
      stateCode: poc.stateCode,
    };

    if (
      existingPocAddress &&
      !isEqual(existingPocAddress, newPocAddress) &&
      isValidAddress(poc)
    ) {
      validatePocAddress(pocNum, poc);
    }

    setFormState({
      [pocRefField]: { ...poc },
    });
  };

  const vmsVehicleIsIncomplete = (vehicle) => {
    const primaryPoCIsInvalid = pointOfContactIsInvalid(vehicle?.primaryPoC);
    const secondaryPoCIsInvalid = pointOfContactIsInvalid(
      vehicle?.secondaryPoC,
    );
    return (
      !vehicle?.agencyCode ||
      !vehicle?.bureauCode ||
      !vehicle?.itemInventoryStatusCode ||
      !vehicle?.makeCode ||
      !vehicle?.modelCode ||
      // eslint-disable-next-line eqeqeq
      vehicle?.modelName == 'UNKNOWN' ||
      !vehicle?.modelYear ||
      !vehicle?.makeColorName ||
      !vehicle?.fuelCode ||
      vehicle?.fastReportable === null ||
      primaryPoCIsInvalid ||
      secondaryPoCIsInvalid
    );
  };

  useEffect(() => {
    if (vinFromParam) {
      setFormState({ vin: vinFromParam });
    }
  }, [vinFromParam]);

  useEffect(() => {
    if (submitRegistrationError) {
      setRegistrationError(submitRegistrationError);
    }
  }, [submitRegistrationError]);

  useEffect(() => {
    if (verifyOption) {
      setFormState({
        userVerifiedVin: verifyOption,
        [verifyOption]: 1,
        nhtsaVerified: false,
      });
      setVinSource('user');
    }
  }, [verifyOption]);

  useEffect(() => {
    if (verifyModalState) {
      resetError();
    }
  }, [verifyModalState]);

  const resetForm = () => {
    setVinSource(null);
    setFormState(RESET_VEH_INFO);
  };

  // eslint-disable-next-line consistent-return
  const isActiveStatus = () => {
    if (data && data.verifyVin && data?.verifyVin?.vmsVehicle) {
      const { itemInventoryStatusCode, ownershipTypeCode } =
        data?.verifyVin?.vmsVehicle;
      if (
        itemInventoryStatuses[itemInventoryStatusCode.trim()]?.displayStatus !==
          'Active' ||
        (ownershipTypeCode === 'AO' && itemInventoryStatusCode.trim() === 'AC')
      ) {
        return true;
      }
    } else {
      return false;
    }
  };

  const isRegistered = () => {
    const vehicleData = data?.verifyVin?.vmsVehicle;
    const isVehicleRegistered = vehicleData?.exemptPlate || vehicleData?.tag;
    const errorCode = data?.verifyVin?.ErrorCode;
    return errorCode === 'REGISTERED' || isVehicleRegistered;
  };

  const isSoldOrMissing = (itemInventoryStatusCode) => {
    return (
      itemInventoryStatuses[
        itemInventoryStatusCode
      ].displayStatus.toLowerCase() === 'missing/stolen' ||
      itemInventoryStatuses[
        itemInventoryStatusCode
      ].displayStatus.toLowerCase() === 'sold'
    );
  };

  useEffect(() => {
    const msg = verifyErr?.message;
    if (
      (!isActiveStatus() || !isRegistered()) &&
      ((msg && msg === 'Invalid VIN format.') ||
        (msg && msg?.includes('invalid')) ||
        (!isIncomplete && registrationType === 'regular'))
    ) {
      resetForm();
      openUserVerifyModal(true);
    } else {
      setRegistrationError(verifyErr);
    }
  }, [verifyErr]);

  useEffect(() => {
    if (formState?.vin && (formState.vin.length === 17 || vinFromParam)) {
      verifyVin(formState.vin);
    } else {
      resetForm();
    }
  }, [formState.vin]);

  // TODO: need to clean up those code  in HIP , side effect in vin state, need to refactor
  const vinToState = (vinData) => {
    const source = vinData.Source;
    const errorCode = vinData.ErrorCode;
    const { vmsVehicle } = vinData;
    const isVmsVehicleIncomplete = vmsVehicleIsIncomplete(vmsVehicle);
    setVinSource(source);

    if (vmsVehicle) {
      const {
        unverifiedVin,
        pre1981,
        internationalVin,
        nhtsaVerified,
        itemInventoryStatusCode,
      } = vmsVehicle;

      if (isSoldOrMissing(itemInventoryStatusCode)) {
        setBodyTextType('soldOrMissing');
        showUnavailable(true);
        // the soldOrMissing is not able to register and it does not need the following logic to check
        // add return to avoid two modal shows up in the same time which will freeze the page
        return;
      }

      if (
        source === 'VMS' &&
        !unverifiedVin &&
        !pre1981 &&
        !internationalVin &&
        !nhtsaVerified
      ) {
        setFormState({ userVerifiedVin: true, nhtsaVerified: false });
      }

      if (!isActiveStatus() || !isRegistered()) {
        setRegistrationType('edit');
        setIsIncomplete(isVmsVehicleIncomplete);
      }
      // for non standard vin if the vehicle is already registered,
      // it should not be able to register again and need to show the error message
      // and not to show the user verify modal
      if (
        !isRegistered() &&
        !nhtsaVerified &&
        !isSoldOrMissing(itemInventoryStatusCode)
      ) {
        openUserVerifyModal(true);
        // add return to avoid two modal shows up in the same time which will freeze the page
        return;
      }
    } else {
      setIsIncomplete(false);
    }

    if (errorCode === 'WRONG_AGENCY') {
      setAccessError(true);
      addError(`
          The VIN number ${formState.vin}
          is being used by another account. Please use a different VIN number
          for vehicle registration.`);
      setFormState(RESET_VEH_INFO);
    } else if (errorCode === 'REGISTERED') {
      addError(
        <span>
          The VIN number <span className="text-bold">{formState.vin}</span> was
          already registered.{' '}
          <Link to={`vehicles/${formState.vin}/registration`}>
            View this vehicle&apos;s registration
          </Link>{' '}
          .
        </span>,
      );
      setIsCurrentlyRegistered(true);
      setFormState(RESET_VEH_INFO);
    } else if (source === 'VMS' && vinData.vmsVehicle) {
      const { __typename, ...vehicle } = vinData.vmsVehicle;
      if (vehicle?.exemptPlate || vehicle?.tag) {
        addError(
          <span>
            The VIN number <span className="text-bold">{formState.vin}</span>{' '}
            was already registered.{' '}
            <Link to={`vehicles/${formState.vin}/registration`}>
              View this vehicle&apos;s registration
            </Link>{' '}
            .
          </span>,
        );
        setIsCurrentlyRegistered(true);
        setFormState(RESET_VEH_INFO);
        return;
        // eslint-disable-next-line no-else-return
      } else if (!isActiveStatus() || isRegistered()) {
        addError(
          `The VIN number ${formState.vin} does not have an Active Vehicle Status. Please update Vehicle Status to "Active".`,
        );
        resetForm();
      } else {
        const primaryPoC = getPoc(
          vehicle?.assetContactAssociationPrimary?.pointOfContactPrimary,
        );
        const secondaryPoC = getPoc(
          vehicle?.assetContactAssociationSecondary?.pointOfContactSecondary,
        );

        const pointOfContact1 = {
          ...primaryPoC,
          validated: !!primaryPoC,
        };
        const pointOfContact2 = {
          ...secondaryPoC,
          validated: !!secondaryPoC,
        };
        setFormState({ ...vehicle, pointOfContact1, pointOfContact2 });
      }

      if (isActiveStatus() && isRegistered()) {
        resetForm();
        openUserVerifyModal(true);
        return;
      }
      isRequirePurchaseOrderNumber &&
        getPurchaseOrderNumber({
          variables: {
            vin: formState?.vin,
          },
        });
    } else if (source === 'NHTSA') {
      const { Make, MakeID, Model, ModelYear, VehicleType } = vinData;
      setFormState({
        makeName: Make,
        makeCode: +MakeID,
        modelYear: +ModelYear,
        modelName: Model,
        nhtsaVerified: true,
        userVerifiedVin: false,
        itemType: VehicleType,
      });
      isRequirePurchaseOrderNumber &&
        getPurchaseOrderNumber({
          variables: {
            vin: formState?.vin,
          },
        });
    }
  };

  useEffect(() => {
    if (data && data.verifyVin) {
      vinToState(data.verifyVin);
    }
  }, [data]);

  return {
    fetchedVehicle: data,
    verifyVin,
    isCurrentlyRegistered,
    verifyingVin: loading,
    UnavailableModal,
    showUnavailable,
    setBodyTextType,
    step,
    prevStep: handlePrevStep,
    nextStep: handleNextStep,
    setStep,
    validateStep,
    updatePoc,
    validations,
    getFieldValidation,
    registrationType,
    formState,
    setFormState,
    errors,
    addError,
    clearError,
    fuelTypes,
    vinSource,
    UserVerifyModal,
    openUserVerifyModal,
    setVinVerification,
    validatePocAddress,
    AddressConfirmModal,
    registerVehicle,
    submittingRegistration,
    submitRegistrationError,
    complete,
    pdfComplete,
    pdfUrl,
    setRegistrationError,
    registrationError,
    accessError,
    setAccessError,
    isActiveStatus,
    isRegistered,
    modalState,
    setModalState,
    isRequirePurchaseOrderNumber,
    setIsRequirePurchaseOrderNumber,
    resetCurrentModal,
    getPurchaseOrderNumber,
    purchaseOrderNumber,
    setPurchaseOrderNumber,
    verifyPurchaseOrderNumber,
    isValidOrderNumber,
    setIsValidOrderNumber,
    resetFirstStepState,
    resetForm,
  };
}
