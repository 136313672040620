/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { SelectDropdown } from '@gsa/afp-component-library';

export default function SelectFastReportable({
  value,
  onChange = () => {},
  errorMessage = '',
  disabled,
}) {
  const [val, setVal] = useState();
  useEffect(() => {
    if (disabled) {
      setVal(value);
      onChange(value);
      return;
    }
    if (value === null || value === undefined) {
      setVal(true);
      onChange(true);
    } else {
      setVal(value);
    }
  }, [value, disabled]);
  const handleChange = (e) => {
    onChange(e.target.value === 'true');
  };
  return (
    <div>
      <div>
        <SelectDropdown
          id="fastReportable"
          label="Fast Reportable"
          required
          name="fastReportable"
          options={[
            {
              value: true,
              label: 'Yes',
            },
            {
              value: false,
              label: 'No',
            },
          ]}
          value={val}
          onChange={handleChange}
          errorMessage={errorMessage}
          disabled={disabled}
        />
      </div>
    </div>
  );
}
