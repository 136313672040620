import PropTypes from 'prop-types';
import React from 'react';
import Attachment from '../../attachment';
import AttachmentProvider, {
  useAttachments,
} from '../../attachment/attachment-provider';
import { useLicensePlateDetails } from '../license-plate-details-provider';
import { useMutation } from '@apollo/client';
import {
  GET_SUPPORTING_ATTACHMENTS,
  UPLOAD_SUPPORTING_DOC,
} from '../../../services/data-layer';

const AttachmentWrapper = ({ uploadPath }) => {
  const {
    fetching,
    rows,
    count,
    hasMore,
    loadMore,
    saving,
    saved,
    updating,
    updated,
    deleting,
    currentFetchVariables: { limit },
    downloadAttachment,
    deleteAttachment,
    sortAttachment,
    saveAttachment,
    updateDescription,
    attachmentErrors,
    setAttachmentErrors,
    currentUser,
    linkedEntities,
  } = useAttachments();

  const { tagDetails } = useLicensePlateDetails();

  const [uploadSupportingVMSDocument] = useMutation(UPLOAD_SUPPORTING_DOC);

  const handleDocUpload = async (formData) => {
    const docMetadata = await uploadSupportingVMSDocument({
      variables: {
        input: {
          modelId: tagDetails.id,
          model: 'Tag',
          documentType: 0,
          documentName: 'upload',
          fileName: formData.get('attachment').name,
          description: formData.get('description'),
          size: formData.get('attachment').size,
          fileMimeType: formData.get('attachment').type,
          status: formData.get('status'),
        },
      },
    });

    if (docMetadata?.data?.uploadSupportingVMSDocument) {
      await saveAttachment(
        formData,
        docMetadata.data.uploadSupportingVMSDocument,
      );
    }
  };

  return (
    <div
      className="afp-vehicle-details-container__attachments"
      data-testid="attachment-container"
    >
      <Attachment
        title=""
        loading={fetching}
        data={rows}
        count={count}
        hasMore={hasMore}
        loadMoreLimit={5}
        paginationLimit={limit}
        uploadPath={uploadPath}
        onLoadMore={loadMore}
        adding={saving}
        added={saved}
        updating={updating}
        updated={updated}
        deleting={deleting}
        onDownload={downloadAttachment}
        onSort={sortAttachment}
        onDelete={deleteAttachment}
        onUpdateDescription={updateDescription}
        onAdd={handleDocUpload}
        currentUser={currentUser}
        errors={attachmentErrors}
        setErrors={setAttachmentErrors}
        linkedEntities={linkedEntities}
      />
    </div>
  );
};

AttachmentWrapper.defaultProps = {
  uploadPath: '',
};
AttachmentWrapper.propTypes = {
  uploadPath: PropTypes.string,
};

const LpDocuments = () => {
  const { tagDetails, refetch } = useLicensePlateDetails();

  return (
    <AttachmentProvider
      fetchGql={GET_SUPPORTING_ATTACHMENTS}
      fetchVariables={{
        filters: {
          operator: 'AND',
          value: [
            {
              operator: 'EQ',
              key: 'modelId',
              value: tagDetails?.id,
            },
          ],
        },
        limit: 5,
        offset: 0,
        order: 'createdAt DESC',
      }}
      setParentContext={refetch}
      linkedEntities={{}}
      currentUser={{}}
    >
      <AttachmentWrapper uploadPath={`tags/${tagDetails.id}`} />
    </AttachmentProvider>
  );
};

export default LpDocuments;
