/* eslint-disable camelcase */
import { isFeatureEnabled } from 'utilities/feature-toggle';
import {
  REPORT_TYPE,
  REPORT_DELIVERY_METHOD,
} from '../../../helpers/report-types';
import { DEFAULT_VALUE, TYPE, OWNERSHIP_TYPES } from './helpers';
import { FastReportFilters } from '../widgets/fast-report/fast-report-filters';
import { RepairReportFilters } from '../widgets/repair-report-filters';
import { FuelReportFilters } from '../widgets/fuel-report-filters';
import { CustomInventoryFieldsSelection } from '../widgets/custom-inventory-report/custom-inventory-fields-selection';
import { AieReportFilters } from '../widgets/aie-report-filters';
import { CrashReportFilters } from '../widgets/crash-report-filters';

export const isGFMileageEnabled = isFeatureEnabled('gf-mileage');
export const isVehicleConsolidationEnabled = isFeatureEnabled(
  'vehicle-consolidation',
);

const commonHook = (formValues) => {
  const { agency, bureau, office } = formValues;

  return {
    agencyCode: agency,
    bureauCode: bureau === DEFAULT_VALUE ? null : bureau,
    officeCode: office === DEFAULT_VALUE ? null : office,
  };
};

const exportsConfig = {
  DEFAULT: {
    value: 'DEFAULT',
    label: '-Select-',
  },
  FAST: {
    value: 'FAST',
    label: 'FAST report',
    deliveryMethod: REPORT_DELIVERY_METHOD.EMAIL,
    reportType: REPORT_TYPE.FAST,
    type: TYPE.REPORT,
    criteriaProcessorHook: (formValues) => {
      const {
        agency,
        bureau,
        office,
        date_range,
        vehicle_options,
        foreign_domestic,
        boac,
        legacyCustomers,
        agencyIndicator,
      } = formValues;

      return {
        agencyCode: agency,
        bureauCode: bureau === DEFAULT_VALUE ? null : bureau,
        officeCode: office === DEFAULT_VALUE ? null : office,
        reportVersion: date_range,
        vehicleOptions: vehicle_options,
        foreignDomestic: foreign_domestic,
        boac,
        legacyCustomers,
        agencyIndicator: agencyIndicator === 'DEFAULT' ? null : agencyIndicator,
      };
    },
    stepConfig: [
      {
        label: 'Report filters',
        component: FastReportFilters,
        stateIds: ['date_range'],
      },
    ],
  },
  ROLLING_MILEAGE: {
    value: 'ROLLING_MILEAGE',
    label: 'Rolling Mileage report',
    deliveryMethod: REPORT_DELIVERY_METHOD.EMAIL,
    reportType: REPORT_TYPE.ROLLING_MILEAGE,
    canBeScheduled: false,
    ownershipType: OWNERSHIP_TYPES.GF.value,
    type: TYPE.REPORT,
    criteriaProcessorHook: (formValues) => {
      const { agency, bureau, office, boac, legacyCustomers, agencyIndicator } =
        formValues;

      return {
        agencyCode: agency,
        bureauCode: bureau === DEFAULT_VALUE ? null : bureau,
        officeCode: office === DEFAULT_VALUE ? null : office,
        boac,
        legacyCustomers,
        agencyIndicator: agencyIndicator === 'DEFAULT' ? null : agencyIndicator,
      };
    },
    stepConfig: [],
  },
  REPAIR: {
    value: 'REPAIR',
    label: 'Vehicle repair order report',
    deliveryMethod: REPORT_DELIVERY_METHOD.EMAIL,
    reportType: REPORT_TYPE.REPAIR,
    type: TYPE.REPORT,
    ownershipType: OWNERSHIP_TYPES.AO.value,
    criteriaProcessorHook: (formValues) => {
      const {
        vehicle_selection_option,
        vehicle_selection_vin,
        vehicle_selection_tag,
        agency,
        bureau,
        office,
        repair_from_date,
        repair_to_date,
      } = formValues;
      const commonFields = {
        startDate: repair_from_date || null,
        endDate: repair_to_date || null,
      };
      switch (vehicle_selection_option) {
        case 'tag':
          return { tagNumber: vehicle_selection_tag, ...commonFields };
        case 'vin':
          return { vin: vehicle_selection_vin, ...commonFields };
        default:
          return {
            agencyCode: agency,
            bureauCode: bureau === DEFAULT_VALUE ? null : bureau,
            officeCode: office === DEFAULT_VALUE ? null : office,
            ...commonFields,
          };
      }
    },
    stepConfig: [
      {
        label: 'Report filters',
        component: RepairReportFilters,
        stateIds: ['repair_from_date', 'repair_to_date'],
      },
    ],
  },
  FUEL_REPORT: {
    value: 'FUEL_REPORT',
    label: 'Vehicle fuel use report',
    deliveryMethod: REPORT_DELIVERY_METHOD.EMAIL,
    reportType: REPORT_TYPE.FUEL_REPORT,
    type: TYPE.REPORT,
    canBeScheduled: true,
    criteriaProcessorHook: (formValues) => {
      const {
        boac,
        agency,
        bureau,
        office,
        fuelReportToDate,
        fuelReportFromDate,
        vehicle_options,
        fuel_report_selection,
        vehicle_selection_option,
        vehicle_selection_vin,
        vehicle_selection_tag,
        merchant_state,
        merchant_zip,
        agencyIndicator,
        fuel_unit_type,
        purchase_fuel_type,
        additional_fields,
        legacyCustomers,
        report_name,
        frequency,
        frequency_end_date,
        additional_recipients,
      } = formValues;

      const vehicleSelection = {};
      if (vehicle_selection_option[0] === 'vin')
        vehicleSelection.vin = vehicle_selection_vin;
      if (vehicle_selection_option[0] === 'tag')
        vehicleSelection.tagNumber = vehicle_selection_tag;

      // if (fuel_report_selection === 'transByVehicle') {
      vehicleSelection.merchantStateCode = merchant_state;
      vehicleSelection.merchantZip = merchant_zip;
      // }

      const gsaLeasedOptions = {};
      if (vehicle_options === 'GF') {
        gsaLeasedOptions.boac = boac;
        gsaLeasedOptions.agencyIndicator = agencyIndicator;
        if (fuel_report_selection !== 'summaryByReportingLevel') {
          gsaLeasedOptions.additionalOptions = additional_fields;
        }
      }

      return {
        agencyCode: agency,
        bureauCode: bureau === 'DEFAULT' ? null : bureau,
        officeCode: office === 'DEFAULT' ? null : office,
        startDate: fuelReportFromDate || null,
        endDate: fuelReportToDate || null,
        vehicleOptions: vehicle_options,
        fuelReportType: fuel_report_selection,
        fuelUnitType: fuel_unit_type,
        purchaseFuelType: purchase_fuel_type,
        ...vehicleSelection,
        ...gsaLeasedOptions,
        legacyCustomers,
        report_name,
        frequency,
        frequency_end_date,
        additional_recipients,
      };
    },
    stepConfig: [
      {
        label: 'Report filters',
        component: FuelReportFilters,
        stateIds: [
          'fuelReportFromDate',
          'fuelReportToDate',
          'vehicle_options',
          'fuel_report_selection',
          'merchant_state',
          'merchant_zip',
          'fuel_unit_type',
          'purchase_fuel_type',
          'additional_fields',
        ],
      },
    ],
  },
  CUSTOM_INVENTORY: {
    value: 'CUSTOM_INVENTORY',
    label: 'Custom Inventory Report',
    deliveryMethod: REPORT_DELIVERY_METHOD.EMAIL,
    reportType: REPORT_TYPE.CUSTOM_INVENTORY,
    ownershipType: OWNERSHIP_TYPES.GF.value,
    type: TYPE.REPORT,
    canBeScheduled: true,
    criteriaProcessorHook: (formValues) => {
      const {
        agency,
        bureau,
        section_options,
        date_range,
        yearOption,
        legacyCustomers,
        boac,
        report_name,
        frequency,
        frequency_end_date,
        additional_recipients,
        vehicle_options,
        agencyIndicator,
      } = formValues;

      const filteredSectionOptions = Object.keys(section_options).reduce(
        (acc, key) => {
          if (section_options[key].checkedList?.length > 0) {
            acc[key] = section_options[key].checkedList;
          }
          return acc;
        },
        {},
      );
      return {
        agencyCode: agency,
        bureauCode: bureau === 'DEFAULT' ? null : bureau,
        date_range,
        vehicleOptions: vehicle_options,
        section_options: filteredSectionOptions,
        yearOption,
        boac,
        agencyIndicator:
          agencyIndicator === 'DEFAULT' ? undefined : agencyIndicator,
        legacyCustomers,
        report_name,
        frequency,
        frequency_end_date,
        additional_recipients,
      };
    },
    stepConfig: [
      {
        label: 'Report filters',
        component: CustomInventoryFieldsSelection,
        stateIds: ['section_options'],
      },
    ],
  },
  INVENTORY: {
    value: 'INVENTORY',
    label: 'Vehicle inventory report',
    deliveryMethod: REPORT_DELIVERY_METHOD.EMAIL,
    reportType: REPORT_TYPE.INVENTORY,
    ownershipType: OWNERSHIP_TYPES.AO.value,
    type: TYPE.REPORT,
    criteriaProcessorHook: (formValues) => {
      const { agency, bureau, office, vehicle_options } = formValues;
      return {
        agencyCode: agency,
        bureauCode: bureau === 'DEFAULT' ? null : bureau,
        officeCode: office === 'DEFAULT' ? null : office,
        vehicleOptions: vehicle_options,
      };
    },
    stepConfig: [],
  },
  AIE: {
    value: 'AIE',
    label: 'AIE report',
    deliveryMethod: REPORT_DELIVERY_METHOD.EMAIL,
    reportType: REPORT_TYPE.AIE_REPORT,
    type: TYPE.REPORT,
    ownershipType: OWNERSHIP_TYPES.GF.value,
    canBeScheduled: true,
    criteriaProcessorHook: (formValues) => {
      const {
        boac,
        agency,
        bureau,
        aieReportFromDate,
        aieReportToDate,
        vehicle_options,
        vehicle_selection_option,
        vehicle_selection_vin,
        vehicle_selection_tag,
        legacyCustomers,
        agencyIndicator,
        report_name,
        frequency,
        frequency_end_date,
        additional_recipients,
      } = formValues;

      const vehicleSelection = {};
      if (vehicle_selection_option[0] === 'vin')
        vehicleSelection.vin = vehicle_selection_vin;
      if (vehicle_selection_option[0] === 'tag')
        vehicleSelection.tagNumber = vehicle_selection_tag;

      return {
        boac,
        agencyCode: agency,
        bureauCode: bureau === 'DEFAULT' ? null : bureau,
        startDate: aieReportFromDate || null,
        endDate: aieReportToDate || null,
        vehicleOptions: vehicle_options,
        legacyCustomers,
        agencyIndicator:
          agencyIndicator === 'DEFAULT' ? undefined : agencyIndicator,
        ...vehicleSelection,
        report_name,
        frequency,
        frequency_end_date,
        additional_recipients,
      };
    },
    stepConfig: [
      {
        label: 'Report filters',
        component: AieReportFilters,
        stateIds: ['vehicle_options'],
      },
    ],
  },
  CRASH: {
    value: 'CRASH',
    label: 'CRASH report',
    deliveryMethod: REPORT_DELIVERY_METHOD.EMAIL,
    reportType: REPORT_TYPE.CRASH,
    ownershipType: OWNERSHIP_TYPES.GF.value,
    type: TYPE.REPORT,
    canBeScheduled: true,
    criteriaProcessorHook: (formValues) => {
      const {
        boac,
        agency,
        bureau,
        legacyCustomers,
        vehicle_options,
        accident_selection,
        crashReportFromDate,
        crashReportToDate,
        agencyIndicator,
        report_name,
        frequency,
        frequency_end_date,
        additional_recipients,
      } = formValues;

      return {
        boac,
        agencyCode: agency,
        bureauCode: bureau === 'DEFAULT' ? null : bureau,
        legacyCustomers: legacyCustomers || null,
        startDate: crashReportFromDate || null,
        endDate: crashReportToDate || null,
        vehicleOptions: vehicle_options,
        accidentSelection: accident_selection,
        agencyIndicator: agencyIndicator === 'DEFAULT' ? null : agencyIndicator,
        report_name,
        frequency,
        frequency_end_date,
        additional_recipients,
      };
    },
    stepConfig: [
      {
        label: 'Report Filters',
        component: CrashReportFilters,
      },
    ],
  },
  CDD: {
    value: 'CDD',
    label: 'Customer driven data (CDD) template',
    deliveryMethod: REPORT_DELIVERY_METHOD.EMAIL,
    reportType: REPORT_TYPE.CDD,
    type: TYPE.TEMPLATE,
    criteriaProcessorHook: (formValues) => {
      const {
        agency,
        bureau,
        office,
        vehicle_options,
        boac,
        legacyCustomers,
        agencyIndicator,
      } = formValues;
      return {
        agencyCode: agency,
        bureauCode: bureau === 'DEFAULT' ? null : bureau,
        officeCode: office === 'DEFAULT' ? null : office,
        vehicleOptions: vehicle_options,
        boac,
        legacyCustomers,
        agencyIndicator: agencyIndicator === 'DEFAULT' ? null : agencyIndicator,
      };
    },
    stepConfig: [],
  },
  MILEAGE: {
    value: 'MILEAGE',
    label: 'Vehicle mileage data template',
    deliveryMethod: REPORT_DELIVERY_METHOD.EMAIL,
    reportType: REPORT_TYPE.MILEAGE,
    type: TYPE.TEMPLATE,
    ownershipType: OWNERSHIP_TYPES.AO.value,
    criteriaProcessorHook: commonHook,
    stepConfig: [],
  },
  MILEAGE_LEASED: isGFMileageEnabled
    ? {
        value: 'MILEAGE_LEASED',
        label: 'Vehicle mileage data template',
        deliveryMethod: REPORT_DELIVERY_METHOD.EMAIL,
        reportType: REPORT_TYPE.MILEAGE,
        ownershipType: OWNERSHIP_TYPES.GF.value,
        type: TYPE.TEMPLATE,
        criteriaProcessorHook: (formValues) => {
          const {
            agency,
            bureau,
            office,
            vehicle_options,
            boac,
            legacyCustomers,
            agencyIndicator,
          } = formValues;
          return {
            agencyCode: agency,
            bureauCode: bureau === 'DEFAULT' ? null : bureau,
            officeCode: office === 'DEFAULT' ? null : office,
            vehicleOptions: vehicle_options,
            boac,
            legacyCustomers,
            agencyIndicator:
              agencyIndicator === 'DEFAULT' ? null : agencyIndicator,
          };
        },
        stepConfig: [],
      }
    : {},
  EXPENSE: {
    value: 'EXPENSE',
    label: 'Vehicle expenses template',
    deliveryMethod: REPORT_DELIVERY_METHOD.EMAIL,
    reportType: REPORT_TYPE.EXPENSE,
    ownershipType: OWNERSHIP_TYPES.AO.value,
    type: TYPE.TEMPLATE,
    criteriaProcessorHook: commonHook,
    stepConfig: [],
  },
};

export default exportsConfig;
