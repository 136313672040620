import { useMutation } from '@apollo/client';
import { IMPORT_GF_BULK_MILEAGE_TXT } from '../../bulk-import.gql';

const useGfMileageTxtDataProcessor = (successCallBack, errorCallBack) => {
  const [process, { loading }] = useMutation(IMPORT_GF_BULK_MILEAGE_TXT, {
    onCompleted: (data) => {
      successCallBack(data.importGfBulkMileageTxt);
    },
    onError: (error) => {
      errorCallBack(error);
    },
  });
  const MILEAGE_FSR_WARNING =
    'Record successfully saved. Reported ending mileage is less than or more than 9,999 miles from the prior odometer reading.';

  const handleSystemErrors = (batchData) => {
    const data = [];
    batchData.forEach((item) => {
      const error = {
        errorResponse: {
          tag: item.tagNumber,
          validationErrors: {
            errors: [
              {
                message:
                  'System Error. Please try to upload again.  If you continue to experience technical difficulties with this page, please contact the GSA Fleet Technical Support team at fleet.helpdesk@gsa.gov or 866-472-6711 from 8:00 a.m. - 7:00 p.m. ET, Monday-Friday.',
              },
            ],
          },
        },
      };
      data.push(error);
    });
    return data;
  };

  const processDataForFile = (response) => {
    const uploadResponse = [];
    if (response?.length > 0) {
      response.forEach((resp) => {
        if (resp?.tagNumber) {
          uploadResponse.push({
            tagNumber: resp.tagNumber,
            vin: resp?.vin,
            status: 'Success',
            errors: '',
          });
        } else {
          let errorMessage = '';
          resp?.errorResponse?.validationErrors?.errors?.forEach((error) => {
            errorMessage += `${error.message}, `;
          });
          errorMessage = errorMessage.substring(0, errorMessage.length - 2);
          const isFSRWarning = errorMessage === MILEAGE_FSR_WARNING;
          uploadResponse.push({
            tagNumber: resp.errorResponse?.tagNumber,
            vin: resp.errorResponse?.vin,
            status: isFSRWarning ? 'Warning' : 'Error',
            errors: errorMessage,
          });
        }
      });
    }
    return uploadResponse;
  };

  return { process, loading, processDataForFile, handleSystemErrors };
};

export default useGfMileageTxtDataProcessor;
