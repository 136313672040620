import React from 'react';
import PropTypes from 'prop-types';

const SectionBlock = ({
  title,
  children,
  basic = false,
  border = true,
  className = '',
}) => {
  const withBackground = !basic
    ? 'bg-gray-3 padding-x-3 radius-md'
    : 'border-top-2px border-gray-20';
  const containerClass =
    className ||
    `${border ? withBackground : ''} padding-top-1 padding-bottom-4`;
  return (
    <>
      <h4 className="title-s-caps text-primary margin-bottom-2">{title}</h4>
      <div className={containerClass}>{children}</div>
    </>
  );
};

SectionBlock.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  basic: PropTypes.bool,
  border: PropTypes.bool,
  className: PropTypes.string,
};

export default SectionBlock;
