const phoneNumberFormatter = (phoneNumber, phoneNumberExt) => {
  if (phoneNumber && phoneNumberExt) return `${phoneNumber} x${phoneNumberExt}`;
  if (phoneNumber) return phoneNumber;
  return '';
};

export const convertToFormUser = (userInput) => {
  const {
    addrline1: address1,
    addrline2: address2,
    addrline3: address3,
    city,
    state,
    postalCode: zip,
    email: pocEmail,
    firstName,
    lastName,
    phoneNumber,
    phoneNumberExt,
  } = userInput;

  return {
    pocEmail,
    pocName: `${firstName} ${lastName}`,
    pocPhone: phoneNumberFormatter(phoneNumber, phoneNumberExt),
    address1,
    address2,
    address3,
    city,
    state,
    zip,
  };
};

export const convertUnicortagToTag = (unicorTag) => {
  return {
    id: unicorTag.tagNumber,
    aOrB: unicorTag.aOrB,
    tagActivityTypeCode: unicorTag.tagStatus,
    agency: unicorTag.agency,
    expirationDate: unicorTag.tagExpirationDate,
  };
};
// Formats dates for the tag destruction form (MM/YYYY or 00/0000)
export const formatDateForForm = (expirationDate) => {
  const dateString = expirationDate?.toString() || '';
  if (!dateString || dateString.length < 5) {
    return '00/0000';
  }
  const year = dateString.substring(0, 4);
  const month = dateString.substring(4, dateString.length);
  return `${month}/${year}`;
};

export const checkSingleOrDoubleTag = (allTags, id) => {
  const matching = allTags.filter((tag) => tag.id === id);
  if (matching.length === 1) {
    return 'Single';
  }
  if (matching.length === 2) {
    return 'Pair';
  }
  return '';
};

export const formatTrackingNumber = (number, numbers) => {
  if (numbers && Array.isArray(numbers) && numbers.length > 1) {
    return numbers.join(', ');
  }
  return number;
};

export const convertToPdfPayload = (data) => {
  const {
    address1,
    agencyName,
    city,
    numBoxes,
    pocEmail,
    pocName,
    pocPhone,
    reasonForReturning,
    state,
    trackingNumber,
    trackingNumbers,
    zip,
    forDestructionList,
  } = data;
  return {
    address1,
    agencyName,
    city,
    numBoxes,
    pocEmail,
    pocName,
    pocPhone,
    reasonForReturning,
    state,
    trackingNumber: formatTrackingNumber(trackingNumber, trackingNumbers),
    zip,
    tagsDetails: forDestructionList.map((tag) => ({
      aOrB: tag.aOrB || 'N/A',
      expirationDate: formatDateForForm(tag.expirationDate),
      id: tag.id,
      singleOrPair: checkSingleOrDoubleTag(forDestructionList, tag.id),
    })),
  };
};

export const setWindowLocation = (url) => {
  window.location.href = url;
};
