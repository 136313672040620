import React, { useEffect } from 'react';
import { useTitle } from '@gsa/afp-shared-ui-utils';
import { useLocation } from 'react-router-dom';

import ServerErrorMessage from 'components/error-handlers/server-error-message';
import { Alert, PageTitle } from '@gsa/afp-component-library';
import { useRecallCampaigns } from './recall-campaigns-provider';

const RecallCampaignsHeader = () => {
  useTitle('Recall Campaigns');
  const { alertMessage, dispatchAction } = useRecallCampaigns();
  const location = useLocation();
  useEffect(() => {
    if (location?.state?.totalCount) {
      dispatchAction('SET_ALERT_MESSAGE', {
        type: 'success',
        message: `Recall successfully created. To edit this recall campaign, 
        expand the row of one of the vehicles impacted by the campaign and click the “Edit  campaign” button.`,
        context: 'campaignListing',
      });
    }
  }, []);

  const { header, message, type, context, error } = alertMessage;

  const showAlert = () => {
    if (!message) {
      return null;
    }
    if (type === 'error' && !context) {
      return <ServerErrorMessage graphQLErrors={error} />;
    }
    return (
      <div className="margin-top-2" data-testid="recall-campaigns-alert">
        <Alert type={type} heading={header || null}>
          {message}
        </Alert>
      </div>
    );
  };

  const headerText = () => {
    return (
      <>
        <p>
          Use this feature to view recalls at the Campaign level. This page will
          not contain extended warranty bulletins or technical safety bulletins.
        </p>
        <p>
          There may be a delay with recently announced campaigns for which not
          all VINs have been identified by the manufacturer. VINs are added
          continuously so you may want to check back often. Only a manufacturer
          can close a recall. For GSA Fleet-leased vehicles, if you would like
          to mark the recall addressed (closed by customer), please contact your
          local Fleet Service Representative, providing them with the invoice
          showing recall completion. For Agency-owned vehicles, you can mark
          them addressed (closed by customer) on the Recalls tab of the Vehicle
          Details page for the vehicle that has received the remediation.
        </p>
        <p>
          For more information, please see the vehicle recalls{' '}
          <a
            href={`${process.env.PUBLIC_URL}/GSAFleet_gov-Recalls-User-Guide.pdf`}
            className="mobile:display-block"
            data-testid="recalls-user-guide"
            target="_blank"
            rel="noopener noreferrer"
          >
            user guide
          </a>{' '}
          and{' '}
          <a
            href="https://vimeo.com/gsavisualcommunications/review/906419962/b66d65abd5"
            className="mobile:display-block"
            data-testid="recalls-user-video"
            target="_blank"
            rel="noopener noreferrer"
          >
            How to
          </a>{' '}
          video.
        </p>
      </>
    );
  };

  return (
    <>
      {showAlert()}
      <header>
        <PageTitle title="Recall Campaigns" />
        <div className="margin-bottom-4 tablet:grid-col-7">{headerText()}</div>
      </header>
    </>
  );
};

export default RecallCampaignsHeader;
