import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { FilterPanel } from '@gsa/afp-component-library';
import { isEmpty } from 'lodash';
import { GET_LIFECYCLE_INDICATORS } from 'services/data-layer/fed-hierarchy.gql';

const { FilterPanelFilterShape, FilterPanelField, useFilterPanel } =
  FilterPanel;

const VehiclesStatusFilterItem = ({ filter }) => {
  const [lifecycleIndicatorValue, setLifecycleIndicatorValue] = useState();
  const [lifecycleStatus, setLifecycleStatus] = useState({});
  const [options, setOptions] = useState([]);
  const [lifecycleStatusValue, setLifecycleStatusValue] = useState('');

  const { getFilterFromState, mergedFilters, clearOneFilter } =
    useFilterPanel();

  const [getLifecycles] = useLazyQuery(GET_LIFECYCLE_INDICATORS, {
    fetchPolicy: 'network-only', // Used for first execution
    nextFetchPolicy: 'cache-first', // Used for subsequent executions
    onCompleted: (responseData) => {
      if (responseData?.getLifecycleIndicators) {
        const lifecycleStatusOptions = {};
        const gfLifecycles = responseData?.getLifecycleIndicators.filter(
          ({ lifecycleIndicatorOwnershipType }) =>
            lifecycleIndicatorOwnershipType === 'GF',
        );
        gfLifecycles.forEach((lifecycle) => {
          const option = {
            value: lifecycle.lifecycleIndicatorStatus,
            label: lifecycle.lifecycleIndicatorStatus,
            ownershipType: lifecycle?.lifecycleIndicatorOwnershipType,
          };
          if (!lifecycleStatusOptions[lifecycle.lifecycleIndicator])
            lifecycleStatusOptions[lifecycle.lifecycleIndicator] = [];
          if (
            lifecycleStatusOptions[lifecycle.lifecycleIndicator].findIndex(
              ({ value }) => value === lifecycle.lifecycleIndicatorStatus,
            ) === -1 &&
            !isEmpty(lifecycle.lifecycleIndicatorStatus)
          )
            lifecycleStatusOptions[lifecycle.lifecycleIndicator].push(option);
        });
        setLifecycleStatus(lifecycleStatusOptions);
        if (lifecycleIndicatorValue) {
          setOptions(lifecycleStatusOptions[lifecycleIndicatorValue]);
        }
      }
    },
  });

  useEffect(() => {
    getLifecycles();
  }, []);

  useEffect(() => {
    const lifecycleIndicatorFilter =
      getFilterFromState('$assetLifecycle.lifeCycle.lifecycle_indicator$') ||
      {};
    setLifecycleIndicatorValue(lifecycleIndicatorFilter.value);
    const lifecycleStatusFilter =
      getFilterFromState(
        '$assetLifecycle.lifeCycle.lifecycle_indicator_status$',
      ) || {};
    setLifecycleStatusValue(lifecycleStatusFilter.value);
  }, [mergedFilters]);

  useEffect(() => {
    clearOneFilter(filter);
    setOptions([]);
    if (lifecycleIndicatorValue && !isEmpty(lifecycleStatus)) {
      setOptions(lifecycleStatus[lifecycleIndicatorValue]);
    } else {
      setOptions([]);
      getLifecycles();
    }
  }, [lifecycleIndicatorValue]);

  return (
    <div className="margin-top-3">
      <p className="text-bold">Vehicle status</p>
      <FilterPanelField
        key={[filter.key]}
        filter={{
          ...filter,
          type: 'multiselectdropdown',
          options,
          value: lifecycleStatusValue || [],
        }}
      />
    </div>
  );
};

VehiclesStatusFilterItem.propTypes = {
  filter: PropTypes.shape(FilterPanelFilterShape).isRequired,
};

export default VehiclesStatusFilterItem;
