/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { SelectDropdown } from '@gsa/afp-component-library';
import { DEFAULT_SELECT_OPTION_LABEL } from '../../../utilities/consts';

const formatFuelOptions = (fuelTypes = []) => {
  return [
    {
      value: '',
      label: DEFAULT_SELECT_OPTION_LABEL,
    },
    ...fuelTypes.map((ft) => ({ value: ft.id, label: ft.description })),
  ];
};

export default function SelectFuelType({
  fuelTypes = [],
  value,
  onChange = () => {},
  errorMessage = '',
  disabled,
}) {
  const [options, setOptions] = useState(formatFuelOptions([]));

  useEffect(() => {
    if (fuelTypes && fuelTypes.getFuelNames) {
      const formattedFuelOptions = formatFuelOptions(fuelTypes.getFuelNames);
      setOptions(formattedFuelOptions);
    }
  }, [fuelTypes]);

  useEffect(() => {
    if (value) {
      const option = options.find((opt) => opt.value === value);
      if (option) {
        onChange(option);
      }
    }
  }, [value]);

  const handleChange = (e) => {
    const fuelCode = e.target.value;
    const selectedOption = options.find((fuel) => fuel.value === fuelCode);
    onChange(selectedOption);
  };

  return (
    <SelectDropdown
      value={value}
      id="fueltype"
      label="Fuel Type"
      required
      name="fueltype"
      options={options}
      onChange={handleChange}
      errorMessage={errorMessage}
      disabled={disabled}
    />
  );
}
