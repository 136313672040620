/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  FileUpload,
  Alert,
  RequiredFieldIndicator,
} from '@gsa/afp-component-library';
import { joinStringArrayExceptLast } from '../vehicle-details/sidenav-widgets/preventative-maintenance/helper';
import { usePmExpress } from './pm-express-provider';
import { useUploadInvoiceContext } from './upload-invoice-context';

const fileTypes = [
  '.pdf',
  '.xlsx',
  '.xls',
  'jpeg',
  'jpg',
  'png',
  'bmp',
  'txt',
  'rtf',
  'doc',
  'docx',
];

const maxFileSize = 5;

const AttachPmInvoiceForm = () => {
  const { selectedPmForUpload } = usePmExpress();
  const { setFile } = useUploadInvoiceContext();
  const [defaultFileName, setDefaultFileName] = useState(
    selectedPmForUpload?.invoiceData?.name
      ? {
          name: selectedPmForUpload?.invoiceData?.name,
        }
      : null,
  );

  return (
    <div data-testid="manage-pm-form">
      <p>
        Attach an invoice for VIN{' '}
        <strong>{selectedPmForUpload.vehicle?.vin}</strong> in the form below.
      </p>
      <p>
        Required fields are marked with an asterisk (
        <RequiredFieldIndicator />
        ).
      </p>

      <FileUpload
        label="Attach Invoice"
        defaultValue={defaultFileName}
        acceptableFiles={[
          'application/pdf',
          'application/msword',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'application/vnd.ms-excel',
          'application/rtf',
          'image/bmp',
          'image/jpeg',
          'image/png',
          'text/plain',
        ]}
        acceptableFilesLabel={` Accept ${joinStringArrayExceptLast(
          fileTypes,
        )} file below
                 ${maxFileSize} MB`}
        fileSizeLimit={maxFileSize} // MB
        onChange={(file) => {
          if (!file) setDefaultFileName();

          setFile(file);
        }}
      />

      <Alert type="warning">
        Help prevent a privacy incident by ensuring that any supporting document
        uploaded here does not contain{' '}
        <a
          href="https://www.gsa.gov/reference/gsa-privacy-program/rules-and-policies-protecting-pii-privacy-act"
          target="_blank"
          rel="noreferrer"
        >
          personally identifiable information
        </a>{' '}
        (PII).
      </Alert>
    </div>
  );
};

export default AttachPmInvoiceForm;
