import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import moment from 'moment';
import { useFormContext, Controller } from 'react-hook-form';
import {
  DatePicker,
  TextInput,
  SelectDropdown,
  Spinner,
} from '@gsa/afp-component-library';
import { GET_REPAIR_PAYMENT_LOOKUPS } from '../../../services/data-layer/repair-order';

const PaymentFields = {
  billedTo: { name: 'billedTo' },
  paymentStatus: { name: 'paymentStatus' },
  paymentForm: { name: 'paymentForm' },
  postedDate: { name: 'postedDate' },
};

const PaymentForm = () => {
  const [paymentStatusOptions, setPaymentStatusOptions] = useState(undefined);
  const [paymentFormOptions, setPaymentFormOptions] = useState(undefined);
  const {
    formState: { errors },
    control,
  } = useFormContext();

  const { billedTo, paymentStatus, paymentForm, postedDate } = PaymentFields;

  // Queries
  const [getRepairPaymentLookupsQuery] = useLazyQuery(
    GET_REPAIR_PAYMENT_LOOKUPS,
    {
      onError: () => {
        // TODO error handling
      },
      onCompleted: (data) => {
        const { getPaymentForms = [], getPaymentStatus = [] } = data || {};

        const statusOpts = getPaymentStatus.map((text) => {
          return { value: text, label: text };
        });
        const formOpts = getPaymentForms.map((text) => {
          return { value: text, label: text };
        });

        setPaymentStatusOptions([
          { value: '', label: '-Select-' },
          ...statusOpts,
        ]);
        setPaymentFormOptions([{ value: '', label: '-Select-' }, ...formOpts]);
      },
      fetchPolicy: 'no-cache',
    },
  );

  useEffect(() => {
    getRepairPaymentLookupsQuery();
  }, []);

  if (!paymentFormOptions || !paymentStatusOptions) {
    return <Spinner data-testid="loading-payment-lookups" />;
  }

  return (
    <div data-testid="repair-payment-form">
      <Controller
        name={billedTo.name}
        control={control}
        render={({ field, ref }) => {
          return (
            <TextInput
              {...field}
              ref={ref}
              type="text"
              label={<span className="text-bold">Billed to</span>}
              name={billedTo.name}
              id={billedTo.name}
              characterLimit={300}
              errorMessage={
                errors && errors[billedTo.name]
                  ? errors[billedTo.name].message
                  : null
              }
              aria-invalid={errors && errors[billedTo.name] ? 'true' : 'false'}
            />
          );
        }}
      />
      <Controller
        name={paymentStatus.name}
        control={control}
        render={({ field, ref }) => {
          return (
            <SelectDropdown
              {...field}
              ref={ref}
              label={<span className="text-bold">Payment status</span>}
              name={paymentStatus.name}
              id={paymentStatus.name}
              options={paymentStatusOptions}
              errorMessage={
                errors && errors[paymentStatus.name]
                  ? errors[paymentStatus.name].message
                  : null
              }
              aria-invalid={
                errors && errors[paymentStatus.name] ? 'true' : 'false'
              }
            />
          );
        }}
      />
      <Controller
        name={paymentForm.name}
        control={control}
        render={({ field, ref }) => {
          return (
            <SelectDropdown
              {...field}
              ref={ref}
              label={<span className="text-bold">Form of payment</span>}
              name={paymentForm.name}
              id={paymentForm.name}
              options={paymentFormOptions}
              errorMessage={
                errors && errors[paymentForm.name]
                  ? errors[paymentForm.name].message
                  : null
              }
              aria-invalid={
                errors && errors[paymentForm.name] ? 'true' : 'false'
              }
            />
          );
        }}
      />
      <Controller
        name={postedDate.name}
        control={control}
        render={({ field, ref }) => {
          return (
            <DatePicker
              {...field}
              ref={ref}
              name={postedDate.name}
              id={postedDate.name}
              maxDate={moment().format('YYYY-MM-DD')}
              label={<span className="text-bold">Posted date</span>}
              defaultValue={field.value}
              onChange={(val) => {
                field.onChange(
                  val
                    ? moment(val).format('YYYY-MM-DD').concat('T00:00:00.000Z')
                    : null,
                );
              }}
              errorMessage={
                errors && errors[postedDate.name]
                  ? errors[postedDate.name].message
                  : null
              }
              aria-invalid={
                errors && errors[postedDate.name] ? 'true' : 'false'
              }
            />
          );
        }}
      />
    </div>
  );
};

export default PaymentForm;
