import React, { useState, useCallback, useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  AFPTable,
  EmptyState,
  Spinner,
  Pagination,
} from '@gsa/afp-component-library';
import { useLazyQuery } from '@apollo/client';
import {
  GET_MILEAGE_HISTORY,
  GET_MILEAGE_HISTORY_VIEW_DATA,
} from '../../../services/data-layer';
import { useMileageFilter, useMileagePage } from '../providers';

const DEFAULT_PAGE_PROPS = {
  itemsCount: 0,
  currentPage: 1,
  itemsPerPage: 10,
  isReset: false,
};

const emDash = '\u2014';

const numberFormatter = (value) => {
  if (Number.isNaN(value)) return emDash;
  return value.toLocaleString();
};

const dateFormatter = (value, format) => {
  if (!value) {
    return emDash;
  }
  const date = moment.utc(value);
  if (!date.isValid()) {
    return emDash;
  }
  return date.format(format);
};

const MileageTable = () => {
  const [tableData, setTableData] = useState([]);
  const [order, setOrder] = useState(null);
  const [pageProps, setPageProps] = useState(DEFAULT_PAGE_PROPS);
  const updatePagination = (newProps) =>
    setPageProps((currentProps) => ({
      ...currentProps,
      ...newProps,
    }));

  const { filters } = useMileageFilter();
  const { setMileageCount, setBannerMsg } = useMileagePage();

  // update mileages count to be used by Export
  useEffect(() => {
    setMileageCount(pageProps.itemsCount);
  }, [pageProps.itemsCount]);

  const [getMileageHistory, { loading }] = useLazyQuery(GET_MILEAGE_HISTORY, {
    onError: () => {
      setBannerMsg({
        type: 'error',
        message: 'Error occured while retrieving mileage data.',
      });
    },
    onCompleted: ({ getMileageHistory: mileageHistory }) => {
      // adding a monthYear property just to satisfy the AFPTable unique property issue
      setBannerMsg(null);

      const customizedData = mileageHistory.rows.map((x) => {
        return {
          monthYear: x.mileageDate,
          ...x,
        };
      });
      setTableData(customizedData);
      updatePagination({ itemsCount: mileageHistory.count });
    },
    fetchPolicy: 'network-only',
  });

  const fetchData = (currPage, limit) => {
    const variables = {
      limit,
      offset: limit * (currPage - 1),
      order,
      filters,
    };
    getMileageHistory({ variables });
  };

  const isAgencySelected = () =>
    filters?.conditions?.some((c) => c.key === '$vehicle.agency_code$');
  const isDateRangeSelected = () =>
    filters?.conditions?.some((c) => c.key === 'mileage_date');

  const resetPageProps = { currentPage: 1, isReset: true };

  useEffect(() => {
    if (isAgencySelected() && isDateRangeSelected()) {
      updatePagination(resetPageProps);
      fetchData(1, pageProps.itemsPerPage);
    } else {
      setTableData([]);
      updatePagination({ itemsCount: 0 });
    }
  }, [filters]);

  useEffect(() => {
    if (tableData?.length) {
      updatePagination(resetPageProps);
      fetchData(1, pageProps.itemsPerPage);
    }
  }, [order]);

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    if (itemsPerPage !== pageProps.itemsPerPage) {
      updatePagination({ ...resetPageProps, itemsPerPage });
      fetchData(1, itemsPerPage);
    } else if (currentPage !== pageProps.currentPage) {
      updatePagination({ currentPage, isReset: false });
      fetchData(currentPage, itemsPerPage);
    }
  };

  const onSort = (sort) => {
    if (sort) {
      const segs = sort.split(' ');
      const col = segs[0]
        .replace(/`/g, '')
        .split('.')
        .map((x) => {
          // sort by monthYear should be on sort by mileageDate
          // just to satisfy the AFPTable unique property issue
          return x === 'monthYear' ? 'mileageDate' : x;
        });
      setOrder([[...col, segs[1]]]);
    } else {
      setOrder(null);
    }
  };

  const getCell = ({ value }) => value || emDash;

  const tableHeaderData = [
    {
      Header: 'Month/year',
      accessor: 'monthYear',
      headerClassName: 'width-card-lg',
      sortable: true,
      Cell: ({ value }) => `${dateFormatter(value, 'MM/YYYY')}`,
    },
    {
      Header: 'Mileage date',
      accessor: 'mileageDate',
      headerClassName: 'width-card-lg',
      sortable: true,
      Cell: ({ value }) => `${dateFormatter(value, 'MM/DD/YYYY')}`,
    },
    {
      Header: 'License plate',
      accessor: 'vehicle.tagNumber',
      headerClassName: 'width-card-lg',
      sortable: true,
      // eslint-disable-next-line
      Cell: ({ value }) => (
        <a
          href={`${
            window.AFP_CONFIG.appURLs.vms
          }/license-plate/${encodeURIComponent(value)}`}
          aria-label={`show vehicle information for vin number ${value} in a new window`}
        >
          {value}
        </a>
      ),
    },
    {
      Header: 'VIN',
      accessor: 'vehicle.vin',
      headerClassName: 'width-card-lg',
      sortable: true,
      // eslint-disable-next-line
      Cell: ({ value }) => (
        <a
          href={`${window.AFP_CONFIG.appURLs.vms}/vehicles/${encodeURIComponent(
            value,
          )}/overview`}
          aria-label={`show vehicle information for vin number ${value} in a new window`}
        >
          {value}
        </a>
      ),
    },
    {
      Header: 'Odometer',
      accessor: 'odometer',
      headerClassName: 'width-card-lg',
      sortable: true,
      Cell: ({ value }) => `${numberFormatter(value)}`,
    },
    {
      Header: 'Days used',
      accessor: 'daysInUse',
      headerClassName: 'width-card-lg',
      sortable: true,
      Cell: getCell,
    },
  ];

  const renderRowSubComponent = useCallback(({ row: { original = {} } }) => {
    const variables = {
      agencyCode: original.vehicle?.agencyCode,
      bureauCode: original.vehicle?.bureauCode,
      officeCode: original.vehicle?.officeCode,
      userId: original.createdBy,
      methodofEntry: original.sourceType || emDash,
      dateOfEntry: dateFormatter(
        original.updatedAt || original.createdAt,
        'MM/DD/YYYY',
      ),
    };
    return <MileageViewData {...variables} />;
  }, []);

  return (
    <>
      <AFPTable
        columns={tableHeaderData}
        renderRowSubComponent={renderRowSubComponent}
        data={loading ? [] : tableData || []}
        testId="afp-mileage-table-test-id"
        fullWidth
        bordered={false}
        defaultSort="campaign asc"
        expandable
        scrollable={false}
        stacked
        onSort={onSort}
      />
      {loading && <Spinner className="padding-y-9" />}
      {!loading &&
        (tableData.length ? (
          <Pagination
            variant="advanced"
            itemsPerPage={pageProps.itemsPerPage}
            itemsCount={pageProps.itemsCount}
            currentPage={pageProps.currentPage}
            isReset={pageProps.isReset}
            onPageChange={handlePaginationChange}
          />
        ) : (
          <div className="bg-gray-3 padding-y-5">
            <div className="text-center padding-y-4">
              <EmptyState
                alt="Image not available"
                hasBackground
                bottomText={
                  <>
                    <p className="text-bold">No mileage showing</p>
                    <p>
                      No mileage records found. Agency and dates selection are
                      required.
                    </p>
                  </>
                }
              />
            </div>
          </div>
        ))}
    </>
  );
};

const MileageViewData = ({
  agencyCode,
  bureauCode,
  officeCode,
  userId,
  methodofEntry,
  dateOfEntry,
}) => {
  const [viewData, setViewData] = useState(null);

  // Query to fetch mileages
  const [getMileageHistoryViewData] = useLazyQuery(
    GET_MILEAGE_HISTORY_VIEW_DATA,
    {
      onCompleted: (data) => {
        setViewData(data);
      },
    },
  );

  const variables = {
    agencyCode,
    bureauCode,
    officeCode,
    key: 'id',
    value: userId,
  };
  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    getMileageHistoryViewData({ variables });
  }, [agencyCode, bureauCode, officeCode, userId]);

  if (!viewData) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }
  return (
    <div className="grid-container">
      <div className="grid-row grid-gap margin-bottom-2">
        <div className="grid-col-6">
          <div className="display-flex flex-row flex-justify margin-top-4 border-primary-light border-bottom-1px">
            <div className="grid-col-4">
              <b>Agency</b>
            </div>
            <div className="grid-col-8">{viewData.getAgency?.name}</div>
          </div>
          <div className="display-flex flex-row flex-justify margin-top-4 border-primary-light border-bottom-1px">
            <div className="grid-col-4">
              <b>Bureau</b>
            </div>
            <div className="grid-col-8">{viewData.getBureau?.name}</div>
          </div>
          <div className="display-flex flex-row flex-justify margin-top-4 border-primary-light border-bottom-1px">
            <div className="grid-col-4">
              <b>Office</b>
            </div>
            <div className="grid-col-8">{viewData.getOfficeNext?.name}</div>
          </div>
        </div>
        <div className="grid-col-6">
          <div className="display-flex flex-row flex-justify margin-top-4 border-primary-light border-bottom-1px">
            <div>
              <b>Method of entry</b>
            </div>
            <div>{methodofEntry}</div>
          </div>
          <div className="display-flex flex-row flex-justify margin-top-4 border-primary-light border-bottom-1px">
            <div>
              <b>User</b>
            </div>
            <div>
              {viewData.getUserByKey && (
                <a
                  aria-label="mailto"
                  href={`mailto:${viewData.getUserByKey?.email}`}
                >
                  {viewData.getUserByKey?.fullName}
                </a>
              )}
              {!viewData.getUserByKey && emDash}
            </div>
          </div>
          <div className="display-flex flex-row flex-justify margin-top-4 border-primary-light border-bottom-1px">
            <div>
              <b>Date of entry</b>
            </div>
            <div>{dateOfEntry}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

MileageViewData.propTypes = {
  agencyCode: PropTypes.string.isRequired,
  bureauCode: PropTypes.string.isRequired,
  officeCode: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  methodOfEntry: PropTypes.string,
  dateOfEntry: PropTypes.string,
};

export default MileageTable;
