import { Button, DetailsTable, Spinner } from '@gsa/afp-component-library';
import { useMutation } from '@apollo/client';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { getCodeAndNameByOwnerShip } from 'utilities/format';

import { fieldGetter } from '../../helpers/field-getter';
import useCanPerformActions from '../../../../hooks/use-can-perform-actions';
import useUser from '../../../../utilities/use-user';
import AgencyEdit from './agency-edit';
import { UPDATE_VEHICLE_AGENCY_DETAILS } from '../../../../services/data-layer';

import { getOfficeDetails } from '../vehicle-overview/helpers/vehicle-details-helper';

const AgencyDetails = ({ refetchVehicle, vehicle, setRegistrationUpdated }) => {
  const {
    agency,
    ownershipTypeCode,
    customer,
    agencyCode,
    bureau,
    bureauCode,
  } = vehicle;

  const [editingAgency, setEditingAgency] = useState(false);
  const { isRole } = useUser();
  const hasFMVRSrole = isRole('FMVRSAdminRole');
  const canPerformActions = useCanPerformActions();
  const canEditPoc = canPerformActions.canEditPoc(vehicle, hasFMVRSrole);
  const [refetchingData, setRefetchingData] = useState(false);

  const [
    updateVehicleAgencyDetails,
    { data: updatedVehicleAgencyDetails, loading: updatingVehicleAgency },
  ] = useMutation(UPDATE_VEHICLE_AGENCY_DETAILS, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (updatedVehicleAgencyDetails) {
      setRefetchingData(true);
      refetchVehicle(
        updatedVehicleAgencyDetails?.updateVehicleAgencyDetails?.id,
      )?.finally(() => {
        setRefetchingData(false);
      });
      setRegistrationUpdated(true);
    }
  }, [updatedVehicleAgencyDetails]);

  const handleAgencySave = (agencyCode, bureauCode, officeCode) => {
    setEditingAgency();

    updateVehicleAgencyDetails({
      variables: {
        vin: vehicle.id,
        agencyDetails: {
          agencyCode,
          bureauCode,
          officeCode,
        },
      },
    });
  };
  return (
    <div
      className="bg-gray-3 radius-md padding-y-2 padding-x-4"
      data-testid="afp-registration__agency_info"
    >
      <DetailsTable
        className="afp-registration__section_container"
        data={[
          [
            'Agency',
            getCodeAndNameByOwnerShip({
              ownershipTypeCode,
              code: agencyCode,
              name: agency?.name,
              customer,
            }),
          ],
          [
            'Bureau',
            getCodeAndNameByOwnerShip({
              ownershipTypeCode,
              code: bureauCode,
              name: bureau?.name,
              customer,
              isAgency: false,
            }),
          ],
          ['Office', getOfficeDetails(vehicle.office, vehicle.officeCode)],
        ]}
      />
      {canEditPoc && (
        <Button
          data-testid="agency-edit-button"
          onClick={() => {
            setEditingAgency(true);
          }}
          variant="outline"
          className="bg-white margin-top-2"
          aria-label="edit agency"
          label="Edit"
        />
      )}
      <AgencyEdit
        agencyCode={vehicle.agencyCode}
        bureauCode={vehicle.bureauCode}
        subSectionCode={vehicle.officeCode}
        editing={editingAgency}
        onClose={() => {
          setEditingAgency(false);
        }}
        onSave={handleAgencySave}
      />
      {(updatingVehicleAgency || refetchingData) && (
        <Spinner aria-busy="true" className="loading_backdrop" size="large" />
      )}
    </div>
  );
};

AgencyDetails.defaultProps = {
  refetchVehicle: () => {
    // noop
  },
  vehicle: {},
  setRegistrationUpdated: () => {
    // noop
  },
};

AgencyDetails.propTypes = {
  refetchVehicle: PropTypes.func,
  vehicle: PropTypes.shape({
    customer: PropTypes.shape({
      name: PropTypes.string,
    }),
    office: PropTypes.shape({
      officeCode: PropTypes.string,
      officeName: PropTypes.string,
    }),
    id: PropTypes.string,
    agencyCode: PropTypes.string,
    bureauCode: PropTypes.string,
    officeCode: PropTypes.string,
    ownershipTypeCode: PropTypes.string,
  }),
  setRegistrationUpdated: PropTypes.func,
};
export default AgencyDetails;
