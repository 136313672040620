import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { Spinner, FilterPanel } from '@gsa/afp-component-library';
import { GET_OFFICES_BY_PERMISSION } from '../../../services/data-layer';
import { VMSOperations, VMSSubjects } from '../../../utilities/consts';

const { FilterPanelFilterShape, FilterPanelField, useFilterPanel } =
  FilterPanel;

const VehiclesOfficeFilterItem = ({ filter }) => {
  const [agencyCode, setAgencyCode] = useState();
  const [bureauCode, setBureauCode] = useState();
  const [selectedOfficeCode, setSelectedOfficeCode] = useState();
  const [options, setOptions] = useState([]);

  const { getFilterFromState, mergedFilters, clearOneFilter } =
    useFilterPanel();

  const [getOffices, { data: officeOptions, loading: officeOptionsLoading }] =
    useLazyQuery(GET_OFFICES_BY_PERMISSION, {
      fetchPolicy: 'network-only',
    });

  useEffect(() => {
    const agencyFilter =
      getFilterFromState('$customer.customer_agency_code$') ||
      getFilterFromState('customerAgencyCode') ||
      {};

    setAgencyCode(agencyFilter.value);

    const bureauFilter =
      getFilterFromState('$customer.customer_bureau_code$') ||
      getFilterFromState('customerBureauCode') ||
      {};

    setBureauCode(bureauFilter.value);

    const officeFilter = getFilterFromState('$office_code$') || {};
    setSelectedOfficeCode(officeFilter.value);
  }, [mergedFilters]);

  useEffect(() => {
    clearOneFilter(filter);
    setOptions([]);
    if (agencyCode && bureauCode.length === 1) {
      getOffices({
        variables: {
          agencyCode,
          bureauCode: bureauCode[0],
          operation: VMSOperations.VIEW_VEHICLE_GF,
          subject: VMSSubjects.VEHICLE,
        },
      });
    }
  }, [bureauCode]);

  useEffect(() => {
    if (officeOptions?.getOfficesByPermission) {
      setOptions([
        ...officeOptions?.getOfficesByPermission.map(
          ({ officeCode, officeName }) => ({
            value: officeCode,
            label: `${officeCode} - ${officeName}`,
          }),
        ),
      ]);
    }
  }, [officeOptions]);

  if (officeOptionsLoading) {
    return <Spinner />;
  }

  if (bureauCode?.length > 1) {
    return <p>Cannot filter by office when multiple bureaus are selected</p>;
  }

  return (
    <div className="margin-top-3">
      <FilterPanelField
        key={[filter.key]}
        filter={{
          ...filter,
          type: 'multiselectdropdown',
          options,
          value: selectedOfficeCode || [],
        }}
      />
    </div>
  );
};

VehiclesOfficeFilterItem.propTypes = {
  filter: PropTypes.shape(FilterPanelFilterShape).isRequired,
};

export default VehiclesOfficeFilterItem;
