import vehicleFilters, {
  vehicleWithNestedFilters,
} from '../filter-structures/vehicle';

const defaultFilters = () => {
  return [
    vehicleFilters.vehicleLicensePlateTypeahead,
    vehicleFilters.vehicleVINTypeahead,
    vehicleFilters.vehiclePoCTypeahead,
    vehicleFilters.customerAccount,
    vehicleFilters.vehicleMakeModel,
    vehicleFilters.registrationStaus,
  ];
};

export const defaultVehicleNestedFilters = () => {
  return [
    vehicleWithNestedFilters.vehicleLicensePlateTypeahead,
    vehicleWithNestedFilters.vehicleVINTypeahead,
    vehicleWithNestedFilters.vehiclePoCTypeahead,
    vehicleWithNestedFilters.customerAccount,
    vehicleWithNestedFilters.vehicleMakeModel,
    vehicleWithNestedFilters.vehicleLifecycleAndStatus,
    vehicleWithNestedFilters.registrationStaus,
  ];
};

export default defaultFilters;
