import PropTypes from 'prop-types';

export const TaskPropType = {
  id: PropTypes.string.isRequired,
  metaData: PropTypes.object.isRequired,
};

export const VehiclePropType = {
  id: PropTypes.string.isRequired,
};

export const CommentPropType = {
  id: PropTypes.string,
  comment: PropTypes.string.isRequired,
};

export const PaginationPropType = {
  rows: PropTypes.arrayOf(PropTypes.object),
  count: PropTypes.number.isRequired,
  hasMore: PropTypes.bool.isRequired,
};

export const GQLPropType = {
  kind: PropTypes.string.isRequired,
  definitions: PropTypes.array.isRequired,
  loc: PropTypes.object.isRequired,
};

export const UserType = {
  id: PropTypes.string,
  email: PropTypes.string.isRequired,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  name: PropTypes.string,
  roles: PropTypes.arrayOf(PropTypes.string),
  token: PropTypes.string,
};

export const AttachmentPropType = {
  contentURL: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  createdByUser: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  lastModified: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
  user: PropTypes.shape({ fullName: PropTypes.string }),
};

export default {
  TaskPropType,
  CommentPropType,
  PaginationPropType,
  AttachmentPropType,
};
