import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { FilterPanel, Icon, TextInput } from '@gsa/afp-component-library';
import './ccn-4.css';

// Credit Card 4 digits filter
const CCN4 = ({ filter }) => {
  const { setFilters, getFilterFromState } = FilterPanel.useFilterPanel();
  const [ccn4, setCCN4] = useState('');
  const [error, setError] = useState('');
  const [showClearIcon, setShowClearIcon] = useState(false);
  const { value: ccn4FromFilterState } = getFilterFromState(filter.key);

  // sync local state when FilterPanel state changes
  useEffect(() => {
    setCCN4(ccn4FromFilterState);
  }, [ccn4FromFilterState]);

  // reload table data on valid filter input
  useEffect(() => {
    if (!new RegExp('^([0-9]{4})$').test(ccn4)) {
      return;
    }
    setFilters({
      type: 'setOne',
      fetchNewData: true,
      filter: { ...filter, value: ccn4 },
    });
  }, [ccn4]);

  // show/hide error
  useEffect(() => {
    if (ccn4 && ccn4.length >= 1 && !new RegExp('^([0-9]{1,4})$').test(ccn4)) {
      setError('Invalid input');
      return;
    }
    setError('');
  }, [ccn4]);

  // show/hide clear icon
  useEffect(() => {
    if (ccn4 && ccn4.length >= 1) {
      setShowClearIcon(true);
      return;
    }
    setShowClearIcon(false);
  }, [ccn4]);

  const searchInputRef = useRef(null);

  const resetFilter = () => {
    setFilters({
      type: 'setOne',
      fetchNewData: true,
      filter: { ...filter, value: '' },
    });
  };

  return (
    <div className="grid-row">
      <div className="ccn-filter-wrapper">
        <Icon iconName="search" className="filter-search-icon" />
        <TextInput
          inputRef={searchInputRef}
          type="text"
          value={ccn4}
          filter={filter}
          placeholder="Search by credit card number"
          maxLength="4"
          className="filter-text-input"
          onChange={(v) => {
            if (!v.target.value && !error) {
              resetFilter();
            }
            setCCN4(v.target.value);
          }}
        />
        {showClearIcon && (
          // eslint-disable-next-line react/button-has-type
          <button
            type="reset"
            onClick={() => {
              if (searchInputRef && searchInputRef.current) {
                searchInputRef.current.focus();
              }
              resetFilter();
              setCCN4('');
            }}
            className="filter-clear-button"
            aria-label="Clear 4 digits filter"
          >
            <Icon iconName="close" />
          </button>
        )}
      </div>
    </div>
  );
};

CCN4.propTypes = {
  filter: PropTypes.shape(FilterPanel.FilterPanelFilterShape).isRequired,
};

CCN4.defaultProps = {};

export default CCN4;
