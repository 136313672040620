const TABLE_HEADER = [
  {
    Header: 'Plate Number ',
    accessor: 'id',
    headerClassName: 'width-card-lg',
  },
  {
    Header: 'Plate Status ',
    accessor: 'tagActivityTypeCode',
    headerClassName: 'width-card-lg',
  },
  {
    Header: 'A or B ',
    accessor: 'aOrB',
    headerClassName: 'width-card-lg',
    sortable: false,
  },
  {
    Header: 'Expiration ',
    accessor: 'expirationDate',
  },
  {
    Header: 'Agency ',
    accessor: 'customerAgencyCode',
    sortable: false,
  },
  { Header: 'Actions ', accessor: 'action', sortable: false },
];

export default TABLE_HEADER;
