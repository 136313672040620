import React from 'react';
import { emdash } from 'components/common';
import { Tag } from '@gsa/afp-component-library';
import { LifeCycleIndicatorTag } from 'components/vehicle-details/widgets/life-cycle';
import { startCase, uniqBy } from 'lodash';
import moment from 'moment';

export const pmSchedulesTemplate = (data) => {
  return (
    <>
      {data?.length === 0 ? (
        emdash
      ) : (
        <>
          <div>
            <div>
              {data?.[0]?.pmType ? (
                <>
                  <Tag>{data?.[0]?.pmType}</Tag>
                  <br />
                </>
              ) : null}
            </div>
            {data?.[0]?.pmTitle ? (
              <>
                <strong>{data?.[0]?.pmTitle || emdash}</strong>
                <br />
              </>
            ) : null}
            {data?.[0]?.pmScheduleNumber ? (
              <>
                Inspection number: {data[0].pmScheduleNumber}
                <br />
              </>
            ) : null}
            {data?.[0]?.pmMonthsInterval ? (
              <>
                Months: {data[0].pmMonthsInterval}
                <br />
              </>
            ) : null}
            {data?.[0]?.pmMilesInterval ? (
              <>
                Miles interval: {data[0].pmMonthsInterval?.toLocaleString()} mi
                <br />
              </>
            ) : null}
            {data?.[0]?.pmKilometersInterval ? (
              <>
                Miles interval: {data[0].pmKilometersInterval?.toLocaleString()}{' '}
                km
                <br />
              </>
            ) : null}
          </div>
        </>
      )}
    </>
  );
};

export const assignmentTemplate = (data) => {
  return (
    <>
      {data?.length === 0 ? (
        <div>
          <Tag>Unassigned</Tag>
        </div>
      ) : (
        <>
          <div>
            <div>
              <Tag>Assigned</Tag>
            </div>
            {data?.[0]?.customerAccountName ? (
              <>
                <strong>{data?.[0]?.customerAccountName}</strong>
                <br />
              </>
            ) : null}
            {data?.[0]?.customerAccountNumber ? (
              <>
                {data[0].customerAccountNumber}
                <br />
              </>
            ) : null}
            {data?.[0]?.customerNumber ? (
              <>
                Leasing account number: {data[0].customerNumber}
                <br />
              </>
            ) : null}
            {data?.[0]?.boac ? (
              <>
                BOAC: {data[0].boac}
                <br />
              </>
            ) : null}
            {data?.[0]?.assignmentDate ? (
              <>
                Assignment date:{' '}
                {moment(data[0].assignmentDate).format('MM/DD/YYYY')}
                <br />
              </>
            ) : null}
            {data?.[0]?.beginningMileage ? (
              <>
                Starting mileage: {data[0].beginningMileage?.toLocaleString()}
                <br />
              </>
            ) : null}
            {data?.[0]?.endingMileage ? (
              <>Ending mileage: {data[0].endingMileage?.toLocaleString()}</>
            ) : null}
          </div>
        </>
      )}
    </>
  );
};

export const dataChangeTemplate = (data) => {
  const excludedFields = [
    'id',
    'createdBy',
    'createdAt',
    'updatedBy',
    'updatedAt',
  ];
  return (
    <>
      {data.updatedData.map((item, index) => {
        return (
          <>
            {Object.keys(item)
              // remove excluded fields and only bring items with truthy diff
              .filter(
                (itemKey) =>
                  !excludedFields.includes(itemKey) &&
                  (item[itemKey] || data.previousData?.[index]?.[itemKey]) &&
                  item[itemKey] !== data.previousData?.[index]?.[itemKey],
              )
              .map((key) => {
                // different templates for CDD fields vs category-based fields
                return (
                  <>
                    {key === 'fieldValue' ? (
                      <>
                        <div className="grid-col-4">
                          <strong>{startCase(item?.fieldName)}</strong>
                        </div>
                        <div className="grid-col-4">
                          {item?.fieldValue || emdash}
                        </div>
                        <div className="grid-col-4">
                          {data.previousData?.[index]?.fieldValue || emdash}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="grid-col-4">
                          <strong>{startCase(key)}</strong>
                        </div>
                        <div className="grid-col-4">{item[key] || emdash}</div>
                        <div className="grid-col-4">
                          {data.previousData?.[index]?.[key] || emdash}
                        </div>
                      </>
                    )}
                  </>
                );
              })}
          </>
        );
      })}
    </>
  );
};

export const statusChangeTemplate = (data) => {
  return (
    <>
      {uniqBy(data, 'lifecycleIndicatorStatus').map((item) => (
        <div>
          <Tag>{item.lifecycleIndicatorStatus}</Tag>
        </div>
      ))}
      {uniqBy(data, 'lifecycleIndicator').map((item) => (
        <div>
          <LifeCycleIndicatorTag value={item.lifecycleIndicator} />
        </div>
      ))}
    </>
  );
};

export const terminationTemplate = (data) => {
  return (
    <>
      {data?.length === 0 ? (
        <div>
          <Tag>Assigned</Tag>
        </div>
      ) : (
        <>
          <div>
            <div>
              <Tag>Unassigned</Tag>
            </div>
            {data?.[0]?.customerAccountName ? (
              <>
                <strong>{data?.[0]?.customerAccountName}</strong>
                <br />
              </>
            ) : null}
            {data?.[0]?.customerAccountNumber ? (
              <>
                {data[0].customerAccountNumber}
                <br />
              </>
            ) : null}
            {data?.[0]?.customerNumber ? (
              <>
                Leasing account number: {data[0].customerNumber}
                <br />
              </>
            ) : null}
            {data?.[0]?.boac ? (
              <>
                BOAC: {data[0].boac}
                <br />
              </>
            ) : null}
            {data?.[0]?.terminationDate ? (
              <>
                Termination date:{' '}
                {moment(data[0].terminationDate).format('MM/DD/YYYY')}
                <br />
              </>
            ) : null}
            {data?.[0]?.beginningMileage ? (
              <>
                Starting mileage: {data[0].beginningMileage?.toLocaleString()}
                <br />
              </>
            ) : null}
            {data?.[0]?.endingMileage ? (
              <>Ending mileage: {data[0].endingMileage?.toLocaleString()}</>
            ) : null}
          </div>
        </>
      )}
    </>
  );
};
