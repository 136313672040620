import React from 'react';
import { PageTitle } from '@gsa/afp-component-library';
import DestructionFinalTable from './widgets/destruction-final-table';
import DownloadPdfButton from './widgets/download-pdf-button';

function StepDestructionEnd() {
  return (
    <div>
      <PageTitle title="License plates are ready to ship" />

      <div className="maxw-tablet-lg">
        <p>
          Click the "Download license plate return form" button below to print a
          form for each box in your shipment. Place a copy of this form in an
          envelope and tape to the inside of each box being returned to UNICOR.
          Keep a copy of this form for your records.
        </p>
        <DownloadPdfButton />
        <h2>License plates to include in return box</h2>
        <p>
          When the following license plates are destroyed, the status will
          update to Destroyed by Vendor.
        </p>
      </div>

      <DestructionFinalTable />
      <DownloadPdfButton />
      <br />
      <br />
    </div>
  );
}

export default StepDestructionEnd;
