import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Typeahead } from '@gsa/afp-component-library';
import { GET_BOACS_BY_PARTIAL } from '../../../services/data-layer/fed-hierarchy.gql';
import { emdash, SectionBlock } from '../../common';
import { GET_BUREAU } from '../../../services/data-layer';
import { useCustomerAccounts } from '../providers/customer-account-provider';

const BoacField = ({ config, setValue, clearErrors = () => {} }) => {
  //const { setValue } = useFormContext();
  const { setRequestError } = useCustomerAccounts();

  const [typeaheads, setTypeaheads] = useState([]);
  const [typeaheadValues, setTypeaheadValues] = useState([]);
  const [selectedBoac, setSelectedBoac] = useState(null);
  const [selectedBureau, setSelectedBureau] = useState(null);

  const [queryBOACsForSearch] = useLazyQuery(GET_BOACS_BY_PARTIAL, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => setTypeaheads(data.getBoacsByPartialBoac),
    onError: (err) => setRequestError(err),
  });

  const [querySpecificBOAC] = useLazyQuery(GET_BOACS_BY_PARTIAL, {
    onCompleted: (data) => setSelectedBoac(data.getBoacsByPartialBoac[0]),
    onError: (err) => setRequestError(err),
  });

  const [fetchBureau] = useLazyQuery(GET_BUREAU, {
    onCompleted: (data) => setSelectedBureau(data.getBureau),
    onError: (err) => setRequestError(err),
  });

  const fetchBOACs = (_, val) => {
    if (val) {
      queryBOACsForSearch({ variables: { partialBoac: val } });
    }
  };

  useEffect(() => {
    if (selectedBoac) {
      fetchBureau({
        variables: {
          agencyCode: selectedBoac.agencyCode,
          id: selectedBoac.bureauCode,
        },
      });
    }
  }, [selectedBoac]);

  useEffect(() => {
    const values = [];
    if (typeaheads && typeaheads?.length > 0) {
      typeaheads.forEach((item) => {
        values.push(item.boac);
      });
    }
    setTypeaheadValues(values);
  }, [typeaheads]);

  useEffect(() => {
    if (config.defaultValue && !selectedBoac) {
      querySpecificBOAC({ variables: { partialBoac: config.defaultValue } });
    }
  }, [config.defaultValue, selectedBoac]);

  return (
    <>
      {/*errorMessage && <p className="usa-error-message">{errorMessage === 'Required' ? 'BOAC is required' : errorMessage}</p>*/}
      <Typeahead
        id={config.id}
        date-testid={`${config.id}-typeahead`}
        name={config.id}
        filterValue={config.defaultValue || ''}
        fetchTypeaheadValues={fetchBOACs}
        typeaheadValues={typeaheadValues}
        placeholder="Enter BOAC"
        inputCharNum={3}
        ariaLabel="Enter BOAC"
        debounceDelay={500}
        promptText="Search requires 3 characters"
        noResultsText="No BOAC found for the given search."
        onOptionEnter={(v) => {
          if (v) clearErrors('boac');
          const sb = typeaheads.find((item) => item.boac === v);
          setSelectedBoac(sb);
          setValue(config.id, v);
          setValue('customerAgencyCode', sb.agencyCode);
          setValue('customerBureauCode', sb.bureauCode);
        }}
        onClear={() => {
          setValue(config.id, '');
          setSelectedBoac(null);
        }}
        required={false} // to bypass browser required field check
        disabled={config.readOnly || false}
      />
      {selectedBoac && (
        <SectionBlock title={''} basic={true} border={false}>
          <div className="grid-row">
            <div className="grid-col-3 bg-primary-lighter">
              <div className="padding-2">
                <span className="usa-label">Agency </span>
                {selectedBureau?.agency?.prefixedName || emdash}
              </div>
            </div>
            <div className="grid-col-3 bg-primary-lighter">
              <div className="padding-2">
                <span className="usa-label">Bureau </span>{' '}
                {selectedBureau?.prefixedName || emdash}
              </div>
            </div>
          </div>
        </SectionBlock>
      )}
    </>
  );
};

export default BoacField;
