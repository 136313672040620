import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import {
  Button,
  TextInput,
  RequiredFieldIndicator,
} from '@gsa/afp-component-library';
import { SEARCH_POINTS_OF_CONTACT } from '../../../services/data-layer/point-of-contact';
import ContactInfoCard from './contact-info-card';

// eslint-disable-next-line react/prop-types
const PocLookup = ({ handlePocLookupActions }) => {
  const [lookupEmail, setLookupEmail] = useState('');
  const [availableContacts, setAvailableContacts] = useState(undefined);
  const [searched, setSearched] = useState(false);

  const [searchPointsOfContact, { loading, data, error }] = useLazyQuery(
    SEARCH_POINTS_OF_CONTACT,
    {
      fetchPolicy: 'network-only',
    },
  );

  const handleSearch = async () => {
    if (lookupEmail) {
      setSearched(true);
      await searchPointsOfContact({
        variables: {
          limit: 10,
          offset: 0,
          filters: [
            {
              operator: '$and',
              conditions: [
                { operator: '$like', key: 'email_address', value: lookupEmail },
              ],
            },
          ],
        },
      });
    }
  };

  const handleAddNewContact = () => {
    const contactInitData = {
      pocEmailAddress: lookupEmail,
      pocPhone1CountryCode: '+1 (United States)',
    };
    handlePocLookupActions('ADD_NEW_CONTACT', contactInitData);
  };

  useEffect(() => {
    if (data?.searchPointsOfContact) {
      setAvailableContacts(data.searchPointsOfContact);
    } else {
      setAvailableContacts([]);
    }
  }, [data]);

  useEffect(() => {
    setSearched(false);
  }, [lookupEmail]);

  const showAddNewContact = () => {
    return (
      <div className="grid-col-12">
        <h2>Can&apos;t find your contact?</h2>
        <Button
          variant="outline"
          className="margin-right-2"
          data-testid="add-poc-button"
          label="Add new contact"
          onClick={handleAddNewContact}
        />
      </div>
    );
  };

  return (
    <>
      <div className="grid-row padding-top-2">
        <p className="text-bold margin-bottom-neg-1">
          {' '}
          Enter email address of the contact you wish to add{' '}
          <RequiredFieldIndicator />
        </p>
        <div className="tablet:grid-col-8">
          <TextInput
            name="lookupemail"
            data-testid="modal-poc-lookup-email"
            required
            value={lookupEmail}
            placeholder=""
            onChange={(e) => {
              setLookupEmail(e.target.value);
            }}
          />
        </div>

        <div className="tablet:grid-col-4">
          <Button
            className="margin-top-3 margin-left-1"
            style={{ height: '2.4rem' }}
            variant="outline"
            data-testid="modal-poc-lookup-button"
            label="Find contact"
            size="medium"
            onClick={handleSearch}
          />
        </div>
        {availableContacts?.length <= 0 && searched && (
          <>
            <div className="grid-col-12">
              <p>No contacts exist for that email address</p>
            </div>

            {showAddNewContact()}
          </>
        )}

        {availableContacts?.length > 0 && (
          <div className="grid-row padding-3 margin-top-3px">
            <div className="grid-col-12">
              <h4>Select contact to add POC</h4>
              {availableContacts.map((contact, index) => (
                <div className="bg-blue-5 padding-2">
                  <ContactInfoCard
                    key={`contact-card-${index}`}
                    contact={contact}
                    displayContext="PoCModal"
                    handleContactCardAction={handlePocLookupActions}
                  />
                </div>
              ))}
            </div>

            {showAddNewContact()}
          </div>
        )}
      </div>
    </>
  );
};

export default PocLookup;
