import React from 'react';
import { VMSOperations, VMSSubjects } from 'utilities/consts';
import AgencyFilterItem from 'components/user-filters/custom-filters/agency-filter-item';
import BureauFilterItem from 'components/user-filters/custom-filters/bureau-filter-item';

// eslint-disable-next-line react/prop-types
const schedulesgencyFilter = ({ filter }) => {
  return (
    <AgencyFilterItem
      filter={filter}
      operation={VMSOperations.VIEW_VEHICLE_GF}
      subject={VMSSubjects.VEHICLE}
    />
  );
};

// eslint-disable-next-line react/prop-types
const schedulesBureauFilter = ({ filter }) => {
  return (
    <BureauFilterItem
      filter={filter}
      operation={VMSOperations.VIEW_VEHICLE_GF}
      subject={VMSSubjects.VEHICLE}
      agencyFilterPath="$agencycode$"
      bureauFilterPath="$bureaucode$"
    />
  );
};

export const REPORT_STATUS_OPTIONS = [
  { label: '-Select-', value: '' },
  { label: 'Active', value: 'Active' },
  { label: 'Inactive', value: 'Inactive' },
  { label: 'Suspended', value: 'Suspended' },
];

export const REPORT_TYPE_OPTIONS = [
  { label: '-Select-', value: '' },
  { label: 'Vehicle fuel use report', value: '6' },
  { label: 'AIE report', value: '15' },
  { label: 'Custom inventory report', value: '16' },
  { label: 'CRASH report', value: '19' },
];

export const generateSchedulesFilterStructure = () => {
  const filterStructure = [
    {
      title: 'Email address',
      key: '$user.email$',
      type: 'text',
      permanent: false,
      operator: '$startsWith',
      hideClear: false,
      position: 'top',
      showSearchButton: true,
      minSearchLength: 3,
    },
    {
      title: 'Organization',
      key: 'agency-bureau-boac',
      filters: [
        {
          key: '$agencycode$',
          title: 'Agency',
          component: schedulesgencyFilter,
          permanent: false,
          operator: '$exact',
          value: '',
        },
        {
          key: '$bureaucode$',
          title: 'Bureau',
          component: schedulesBureauFilter,
          placeholder: '- Select bureau -',
          noResults: 'No bureaus found',
          permanent: false,
          operator: '$in',
        },
      ],
    },
    {
      title: 'Report status',
      key: 'scheduleStatus',
      type: 'select',
      options: REPORT_STATUS_OPTIONS,
      value: '',
      operator: '$exact',
      expanded: false,
      hideClear: true,
    },
    {
      title: 'Report type',
      key: 'reportTypeId',
      type: 'select',
      options: REPORT_TYPE_OPTIONS,
      value: '',
      operator: '$exact',
      expanded: false,
      hideClear: true,
    },
  ];

  return filterStructure;
};
