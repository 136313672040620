import React from 'react';

import {
  FleetTechnicalSupportContact,
  InternalError,
} from '@gsa/afp-shared-ui-utils';

const exportSuccessMessage = {
  type: 'success',
  heading: 'Export Initiated:',
  message: (
    <>
      The data you&apos;ve selected is now being processed for export and will
      be emailed to you upon completion. Depending on the file size, this may
      take up to 15 minutes or more, and will expire after 7 days. If you
      experience technical difficulties exporting,{' '}
      <FleetTechnicalSupportContact />.
    </>
  ),
};

const exportMsgTooMany = {
  type: 'error',
  message:
    'The report you are requesting is too large.  Please edit your request to generate a smaller report.',
};

const exportMsgNoData = (dataName) => ({
  type: 'error',
  message: `No ${dataName} records found to export. Please update filters to search ${dataName} records.`,
});

const exportMsgError = {
  alert: <InternalError />,
};

export default {
  exportSuccessMessage,
  exportMsgTooMany,
  exportMsgError,
  exportMsgNoData,
};
