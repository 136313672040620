import React from 'react';
import { RadioButton } from '@gsa/afp-component-library';
import { Controller, useWatch } from 'react-hook-form';
import { VehicleSelection } from './vehicle-selection';
import { HiddenField } from '../components/hidden-field';
import { useExport } from '../export-provider';

// eslint-disable-next-line react/prop-types
export const VehicleSelectionOptions = ({
  control,
  register,
  reportType,
  setValue,
}) => {
  const { reportSearchOptions } = useExport().reportSearchOptions;

  const searchOptions = reportSearchOptions[reportType] || {};
  const defaultValueFound = searchOptions.find(
    (item) => item.defaultValue === true,
  );
  const defaultValue = defaultValueFound ? defaultValueFound.value : null;
  const [option] = useWatch({
    name: ['vehicle_selection_option'],
    control,
    defaultValue: [defaultValue] || null,
  });

  return (
    <div className="margin-bottom-6">
      <section className="margin-bottom-6">
        <h2 className="text-primary margin-bottom-1">Vehicle options</h2>
        <div className="usa-form-group mobile-lg:grid-col-10">
          <span>
            Choose the types of vehicles you would like to include in your
            report. By default all vehicles will be included, per selection made
            in other sections.
          </span>

          <Controller
            control={control}
            name="vehicle_selection_option"
            defaultValue={defaultValue}
            render={({ field: { onChange, name, value } }) => (
              <div className="margin-bottom-4">
                <p>
                  Please select one of the following options to filter down the
                  vehicle report.
                </p>
                {searchOptions.map((opt) => {
                  return (
                    <RadioButton
                      name={name}
                      key={opt.value}
                      data-testid={`${name}-${value}`}
                      checked={value?.includes(opt.value)}
                      value={opt.value}
                      label={<b>{opt.label}</b>}
                      onChange={(e) => {
                        onChange(
                          value?.includes(e.target.value)
                            ? []
                            : [e.target.value],
                        );
                      }}
                    />
                  );
                })}
              </div>
            )}
          />

          {option && option?.includes('vin') ? (
            <VehicleSelection
              control={control}
              type="vin"
              name="vehicle_selection_vin"
              setValue={setValue}
            />
          ) : (
            <HiddenField register={register} name="vehicle_selection_vin" />
          )}
          {option && option.includes('tag') ? (
            <VehicleSelection
              control={control}
              type="tag"
              name="vehicle_selection_tag"
              setValue={setValue}
            />
          ) : (
            <HiddenField register={register} name="vehicle_selection_tag" />
          )}
        </div>
      </section>
    </div>
  );
};
