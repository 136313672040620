import React from 'react';
import { useTitle, VehicleSearch } from '@gsa/afp-shared-ui-utils';
import ServerErrorMessage from 'components/error-handlers/server-error-message';
import { Alert, PageTitle } from '@gsa/afp-component-library';
import { useCustomerAccounts } from '../providers/customer-account-provider';

// eslint-disable-next-line react/prop-types
const CustomerAccountHeader = ({ title, headingText, showVehicleSearch }) => {
  useTitle(title);
  const { alertMessage } = useCustomerAccounts();
  const { header, message, type, error, location, context } = alertMessage;

  const showAlert = () => {
    if (type === 'error' && context === 'SET_VEHICLE_COUNT_ERROR') {
      return (
        <div className="margin-bottom-2" data-testid="customer-account-alert">
          <Alert type={type} heading={header || null}>
            {message}
          </Alert>
        </div>
      );
    }
    if (type === 'error' && !context) {
      return <ServerErrorMessage graphQLErrors={error} />;
    }

    return (
      message &&
      location === 'header' && (
        <div className="margin-top-2" data-testid="customer-account-alert">
          <Alert type={type} heading={header || null}>
            {message}
          </Alert>
        </div>
      )
    );
  };

  const headerTextComponent = () => {
    return (
      <>
        <p>{headingText}</p>
      </>
    );
  };

  return (
    <>
      {showAlert()}
      <header>
        <PageTitle title={title} />
        <div className="grid-row grid-gap flex-justify">
          {headingText && (
            <div className="margin-bottom-4 tablet:grid-col-7">
              {headerTextComponent()}
            </div>
          )}
          {showVehicleSearch && (
            <div className="margin-bottom-4 tablet:grid-col-5">
              <VehicleSearch />
            </div>
          )}
        </div>
      </header>
    </>
  );
};

export default CustomerAccountHeader;
