import React from 'react';
import formatPhoneNumber from '../../../utilities/phoneNumberFormatter';

const ContactInfoDisplay = ({ contact, displayContext }) => {
  if (displayContext === 'FSR') {
    return (
      <div>
        <div className="text-bold">
          {contact.firstName} {contact.lastName}
        </div>
        <div>
          <a href={`mailto:${contact.email}`}>{contact.email}</a>
        </div>
        {contact.phoneNumber && (
          <div> {formatPhoneNumber(contact.phoneNumber)} </div>
        )}
      </div>
    );
  }

  return (
    <div>
      <div className="text-bold">
        {contact.pocFirstName} {contact.pocLastName}
      </div>
      <div>{contact.pocEmailAddress}</div>
      <div>
        {contact.pocPhone1CountryCode &&
          contact.pocPhone1CountryCode.split(' ')[0] !== '+1' && (
            <span>{contact.pocPhone1CountryCode.split(' ')[0]} </span>
          )}
        {contact.pocPhone1CountryCode?.startsWith('+1')
          ? formatPhoneNumber(contact.pocPhone1)
          : contact.pocPhone1}
        {contact.pocPhone1Ext && (
          <span>
            {' ext '} {contact.pocPhone1Ext}
          </span>
        )}
      </div>
      {contact.pocPhone2 && (
        <div>
          {contact.pocPhone2CountryCode &&
            contact.pocPhone2CountryCode.split(' ')[0] !== '+1' && (
              <span>{contact.pocPhone2CountryCode.split(' ')[0]} </span>
            )}
          {contact.pocPhone2CountryCode?.startsWith('+1')
            ? formatPhoneNumber(contact.pocPhone2)
            : contact.pocPhone2}
        </div>
      )}
    </div>
  );
};

export default ContactInfoDisplay;
