import React from 'react';

import OfficeDetailModal from 'utilities/modal-utils';
import { useOfficeManagement } from './office-management-provider';

import modalSchema from './schema/modal';

const OfficeModal = () => {
  const context = useOfficeManagement();

  return (
    <>
      <OfficeDetailModal schema={modalSchema} context={context} />
    </>
  );
};

export default OfficeModal;
