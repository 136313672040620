import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@gsa/afp-component-library';
import usePortalModal from '../../../utilities/portal-modal';

export default function impossibleRegistration(vin) {
  const [Modal, openModal, closeModal, modalState] = usePortalModal();
  const [bodyTextType, setBodyTextType] = useState('incomplete');

  const UnavailableModal = () => (
    <Modal
      variant="large"
      title={
        <div className="padding-4 padding-bottom-0">
          <h2>Vehicle Registration is Unavailable</h2>
          <br />
          <hr />
        </div>
      }
      hideClose
      actions={
        <>
          <Link
            type="button"
            to={`/vehicles/${encodeURIComponent(vin)}/overview`}
          >
            <Button label="View vehicle details" />
          </Link>
        </>
      }
    >
      <div className="padding-4">
        {bodyTextType === 'incomplete' && (
          <p>
            You cannot complete the registration on this vehicle because some or
            all of the following critical information about this vehicle is
            missing: Make, Model and/or Model Year.
          </p>
        )}
        {bodyTextType === 'soldOrMissing' && (
          <p>
            This VIN has a Vehicle Status of "Sold" or "Missing/Stolen". Please
            update the Vehicle Status to "Active" on the vehicle registration
            details page before registering the vehicle
          </p>
        )}
        <div>Please update this information on the vehicle details page.</div>
      </div>
    </Modal>
  );
  return [UnavailableModal, openModal, setBodyTextType, closeModal, modalState];
}
