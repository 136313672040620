import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Icon, Label } from '@gsa/afp-component-library';
import NumberFormat from 'react-number-format';
import { CDD_FORM_FIELDS } from './consts';

const VehicleCostsForm = () => {
  const {
    formState: { errors },
    control,
  } = useFormContext();

  return (
    <div data-testid="cdd-vehicle-costs-form">
      <div className="grid-col-6">
        <Controller
          name={CDD_FORM_FIELDS.indirectCost.name}
          control={control}
          render={({ field, ref }) => {
            const isFieldError =
              errors && errors[CDD_FORM_FIELDS.indirectCost.name];
            const fieldErrorMessage = isFieldError
              ? errors[CDD_FORM_FIELDS.indirectCost.name].message
              : null;
            return (
              <div
                className={`usa-form-group ${
                  isFieldError ? 'usa-form-group--error' : null
                }`}
              >
                <Label
                  className={`${isFieldError ? 'usa-label--error' : null}`}
                  htmlFor="vehicle-acquisition-cost"
                >
                  <span className="text-bold">Indirect cost</span>
                </Label>
                {isFieldError && (
                  <span className="usa-error-message">{fieldErrorMessage}</span>
                )}
                <div className="dollar-input">
                  <Icon
                    iconName="attach_money"
                    className="prefix-icon-dollar"
                  />
                  <NumberFormat
                    {...field}
                    ref={ref}
                    className={`usa-input ${
                      isFieldError ? 'usa-input--error' : null
                    }`}
                    id={CDD_FORM_FIELDS.indirectCost.id}
                    thousandsGroupStyle="thousand"
                    fixedDecimalScale
                    decimalScale={2}
                    decimalSeparator="."
                    displayType="input"
                    type="text"
                    thousandSeparator
                    allowNegative={false}
                    aria-invalid={isFieldError ? 'true' : 'false'}
                  />
                </div>
              </div>
            );
          }}
        />
        <Controller
          name={CDD_FORM_FIELDS.leaseCost.name}
          control={control}
          render={({ field, ref }) => {
            const isFieldError =
              errors && errors[CDD_FORM_FIELDS.leaseCost.name];
            const fieldErrorMessage = isFieldError
              ? errors[CDD_FORM_FIELDS.leaseCost.name].message
              : null;
            return (
              <div
                className={`usa-form-group ${
                  isFieldError ? 'usa-form-group--error' : null
                }`}
              >
                <Label
                  className={`${isFieldError ? 'usa-label--error' : null}`}
                  htmlFor="vehicle-acquisition-cost"
                >
                  <span className="text-bold">Lease cost</span>
                </Label>
                {isFieldError && (
                  <span className="usa-error-message">{fieldErrorMessage}</span>
                )}
                <div className="dollar-input">
                  <Icon
                    iconName="attach_money"
                    className="prefix-icon-dollar"
                  />
                  <NumberFormat
                    {...field}
                    ref={ref}
                    className={`usa-input ${
                      isFieldError ? 'usa-input--error' : null
                    }`}
                    id={CDD_FORM_FIELDS.leaseCost.id}
                    thousandsGroupStyle="thousand"
                    fixedDecimalScale
                    decimalScale={2}
                    decimalSeparator="."
                    displayType="input"
                    type="text"
                    thousandSeparator
                    allowNegative={false}
                    aria-invalid={isFieldError ? 'true' : 'false'}
                  />
                </div>
              </div>
            );
          }}
        />
        <Controller
          name={CDD_FORM_FIELDS.accidentCost.name}
          control={control}
          render={({ field, ref }) => {
            const isFieldError =
              errors && errors[CDD_FORM_FIELDS.accidentCost.name];
            const fieldErrorMessage = isFieldError
              ? errors[CDD_FORM_FIELDS.accidentCost.name].message
              : null;
            return (
              <div
                className={`usa-form-group ${
                  isFieldError ? 'usa-form-group--error' : null
                }`}
              >
                <Label
                  className={`${isFieldError ? 'usa-label--error' : null}`}
                  htmlFor="vehicle-acquisition-cost"
                >
                  <span className="text-bold">Accident repair cost</span>
                </Label>
                {isFieldError && (
                  <span className="usa-error-message">{fieldErrorMessage}</span>
                )}
                <div className="dollar-input">
                  <Icon
                    iconName="attach_money"
                    className="prefix-icon-dollar"
                  />
                  <NumberFormat
                    {...field}
                    ref={ref}
                    className={`usa-input ${
                      isFieldError ? 'usa-input--error' : null
                    }`}
                    id={CDD_FORM_FIELDS.accidentCost.id}
                    thousandsGroupStyle="thousand"
                    fixedDecimalScale
                    decimalScale={2}
                    decimalSeparator="."
                    displayType="input"
                    type="text"
                    thousandSeparator
                    allowNegative={false}
                    aria-invalid={isFieldError ? 'true' : 'false'}
                  />
                </div>
              </div>
            );
          }}
        />
      </div>
    </div>
  );
};

export default VehicleCostsForm;
