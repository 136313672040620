// need clean up use the global one in the HIP
export const REPORT_TYPE = {
  FAST: 1,
  CDD: 2,
  MILEAGE: 3,
  EXPENSE: 4,
  REPAIR: 5,
  FUEL_REPORT: 6,
  INVENTORY: 8,
  CUSTOM_INVENTORY: 16,
  AIE_REPORT: 15,
  CRASH: 19,
  ROLLING_MILEAGE: 20,
};

export const REPORT_TYPE_BY_ID = {
  1: 'FAST',
  2: 'CDD',
  3: 'MILEAGE',
  4: 'EXPENSE',
  5: 'REPAIR',
  6: 'FUEL_REPORT',
  8: 'INVENTORY',
  16: 'CUSTOM_INVENTORY',
  15: 'AIE',
  19: 'CRASH',
  20: 'ROLLING_MILEAGE',
};

export const REPORT_DELIVERY_METHOD = {
  EMAIL: 1,
};
