import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from '@gsa/afp-component-library';

const ConfirmDeleteCommentModal = ({ onCancel, onAccept }) => {
  const handleAccept = () => {
    onAccept();
  };
  const handleCancel = () => {
    onCancel();
  };

  return (
    <div className="afp-modal-wrapper">
      <div className="afp-modal-overlay">
        <Modal
          id="confirm-delete-service-line-modal"
          variant="medium"
          title={<h2>Delete confirmation</h2>}
          onClose={handleCancel}
          actions={
            <div>
              <Button
                variant="unstyled"
                onClick={handleCancel}
                label="Cancel"
                className="padding-right-1"
              />
              <Button
                variant="secondary"
                onClick={handleAccept}
                label="Delete"
              />
            </div>
          }
        >
          <p>
            Are you sure you want to delete this comment from the repair oder?
          </p>
        </Modal>
      </div>
    </div>
  );
};

ConfirmDeleteCommentModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
};

export default ConfirmDeleteCommentModal;
