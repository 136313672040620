/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  AFPTable,
  EmptyState,
  Pagination,
  Spinner,
} from '@gsa/afp-component-library';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import { useLazyQuery } from '@apollo/client';
import { GET_TAGS_BY_EXPIRATION_DATE } from 'services/data-layer';
import {
  canUpdateTagAO,
  canUpdateTagGF,
  canViewAOTags,
  canViewGFTags,
} from 'utilities/authorization';
import { canSubmitDestruction } from 'utilities/common';
import useUser from 'utilities/use-user';
import { useLicensePlateDestruction } from 'components/license-plate-destruction/context';
import { useOrder } from '../context';
import TagMenu from './table-widgets/table-tag-actions';
import OrderStatus from '../../orders/widgets/table-widgets/tag-status-badge';

export const formatTagExpDate = (exp) => {
  const stringExp = `${exp}`;
  if (stringExp === '0') {
    return '00/0000'; // needed to properly format legacy data, where exp date is 0
  }

  if (stringExp) {
    return `${stringExp.slice(4)}/${stringExp.slice(0, 4)}`;
  }
  return '—';
};

const OrderTagTable = () => {
  const [order, setOrder] = useState('createdAt DESC');
  const [paginationState, setPaginationState] = useState({
    limit: 100,
    offset: 0,
    currentPage: 1,
  });

  const ability = useAppAbility();
  const canViewAOPlates = canViewAOTags(ability);
  const canViewGFPlates = canViewGFTags(ability);
  const canUpdateAOPlates = canUpdateTagAO(ability);
  const canUpdateGFPlates = canUpdateTagGF(ability);

  const {
    tags,
    details,
    filters,
    getOrderTags,
    loadingOrderTags,
    orderNumber,
    openLostStolenModalWithTag,
    openDamagedModalWithTag,
    openReconcileModalWithTag,
    loadingDamagedTag,
    loadingOrderTagLostStolen,
    reconciledTagsLoading,
    reconcileOrderLoading,
  } = useOrder();

  const { isCustomer, isRole } = useUser();

  const tableRef = React.createRef();

  const { toggleDestructionEditing, setForDestructionList } =
    useLicensePlateDestruction();

  const [getTagsByExpirationDate] = useLazyQuery(GET_TAGS_BY_EXPIRATION_DATE, {
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      const filteredTags = data?.getTagsByExpirationDate?.filter((tag) => {
        return canSubmitDestruction(tag.tagActivityTypeCode);
      });
      setForDestructionList(filteredTags);
      toggleDestructionEditing();
    },
  });

  const handleLicensePlateDestruction = (tag) => {
    getTagsByExpirationDate({
      variables: {
        input: [{ id: tag.tagNumber, expirationDate: tag.tagExpirationDate }],
      },
    });
  };

  const shouldShowLinkAndActionMenu = (original) => {
    let showLink = false;
    let showActions = false;

    const { tagNumber: id, tag } = original;
    const isGTag = id.substr(0, 1) === 'G';
    const isFMVRS = isRole('FMVRSAdminRole');

    if (id && tag) {
      showLink = true;
    }

    if (
      (isGTag && !canViewGFPlates && isCustomer()) ||
      (!isGTag && !canViewAOPlates)
    ) {
      showLink = false;
    }

    if (isFMVRS) {
      showActions = true;
    }

    if (showLink) {
      if (
        (isGTag && canUpdateGFPlates && !isCustomer()) ||
        (!isGTag && canUpdateAOPlates)
      ) {
        showActions = true;
      }
    }

    return { showLink, showActions };
  };

  const shouldAllowUpdateOrder = (original) => {
    let should = true;
    if (!original.tagNumber) {
      should = false;
    } else {
      const { tagNumber } = original;
      if (
        (tagNumber.substr(0, 1) === 'G' && !canUpdateGFPlates) ||
        (tagNumber.substr(0, 1) !== 'G' && !canUpdateAOPlates)
      ) {
        should = false;
      }
      if (
        ![
          'SHIP',
          'SH',
          'RETN',
          'DESV',
          'RECV',
          'MISS',
          'ATTD',
          'ACKO',
        ].includes(original.tagStatus)
      ) {
        should = false;
      }
    }
    return should;
  };

  const reportLostStolen = (tag) => {
    openLostStolenModalWithTag(tag);
  };

  const reportDamaged = (tag) => {
    openDamagedModalWithTag(tag);
  };

  const reconcileTags = (tag) => {
    openReconcileModalWithTag(tag);
  };

  const getExchangeDetail = (data) => {
    if (
      data?.tagByTagNumberAndAorB &&
      data?.tagByTagNumberAndAorB?.length > 0
    ) {
      const recievedNewTag = data?.tagByTagNumberAndAorB?.find(
        (tag) => tag?.tagActivityTypeCode === 'RECV',
      );

      let attachedTag;

      if (recievedNewTag)
        attachedTag = data?.tagByTagNumberAndAorB?.find(
          (tag) =>
            tag?.tagActivityTypeCode === 'ATTD' &&
            tag.vehicle?.vin &&
            tag?.expirationDate &&
            tag?.expirationDate < recievedNewTag?.expirationDate,
        );

      if (attachedTag && recievedNewTag) {
        return {
          vehicleVin: attachedTag?.vehicle?.vin,
          isExchangeReady:
            attachedTag?.vehicle?.vin &&
            recievedNewTag &&
            data?.tagStatus === 'RECV' &&
            data?.tagExpirationDate > attachedTag?.expirationDate,
        };
      }
      return {};
    }
    return {};
  };

  const getBureauDescription = (original) => {
    const detailBureau = `${
      details?.bureau?.id ? details?.bureau?.id : '- '
    } - ${details?.bureau?.name ? details?.bureau?.name : ' -'}`;
    const rowBureau = `${
      original?.bureau?.id ? original?.bureau?.id : '- '
    } - ${original?.bureau?.name ? original?.bureau?.name : ' -'}`;
    return original?.bureau?.id ? rowBureau : detailBureau;
  };

  const columns = useMemo(() => {
    const columnList = [
      {
        Header: 'Plate number',
        accessor: 'tagNumber',
        sortable: true,
        Cell: ({ row: { original } }) => {
          return shouldShowLinkAndActionMenu(original).showLink ? (
            <Link
              to={`/license-plate/${encodeURIComponent(
                original.tagNumber,
              )}?tagExpirationDate=${original.tagExpirationDate}`}
            >
              {original.tagNumber}
            </Link>
          ) : (
            original.tagNumber
          );
        },
      },
      {
        Header: 'Exchange',
        accessor: 'exchange',
        sortable: false,
        Cell: ({ row: { original } }) => {
          return getExchangeDetail(original)?.isExchangeReady ? (
            <Link
              to={`/vehicles/${encodeURIComponent(
                getExchangeDetail(original).vehicleVin,
              )}/overview`}
            >
              {' '}
              Yes{' '}
            </Link>
          ) : (
            <>&ndash;</>
          );
        },
      },
      {
        Header: 'Plate status',
        accessor: 'tagStatus',
        sortable: false,
        Cell: ({ row: { original } }) => {
          return original?.tagStatus ? (
            <OrderStatus tagStatus={original.tagStatus} />
          ) : (
            <>&ndash;</>
          );
        },
      },
      {
        Header: 'A or B',
        accessor: 'aOrB',
        sortable: false,
      },
      {
        Header: 'Expiration',
        accessor: 'tagExpirationDate',
        sortable: true,
        Cell: ({ row: { original } }) => {
          return formatTagExpDate(original.tagExpirationDate);
        },
      },
      {
        Header: 'Bureau',
        sortable: false,
        Cell: ({ row: { original } }) => {
          return getBureauDescription(original) || ' -';
        },
      },
      {
        Header: 'Actions',
        sortable: false,
        Cell: ({ row: { original } }) => {
          return shouldShowLinkAndActionMenu(original).showActions ? (
            <TagMenu
              tag={original}
              reconcile={reconcileTags}
              reportLostStolen={reportLostStolen}
              reportDamaged={reportDamaged}
              submitForDestruction={handleLicensePlateDestruction}
              shouldAllowUpdateOrder={shouldAllowUpdateOrder(original)}
              orderNumber={details?.orderNumber}
            />
          ) : (
            '-'
          );
        },
      },
    ];
    return columnList;
  }, []);

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    const offset = (currentPage - 1) * itemsPerPage;
    setPaginationState({
      limit: itemsPerPage,
      offset,
      currentPage,
    });
  };

  const getData = () => {
    getOrderTags({
      variables: {
        limit: paginationState.limit,
        offset: paginationState.offset,
        order,
        filters,
        orderNumber,
      },
    });
  };

  useEffect(() => {
    getData();
  }, [paginationState, order]);

  return (
    <>
      <AFPTable
        fullWidth
        ref={tableRef}
        testId="order-tag-table"
        columns={columns}
        data={
          loadingDamagedTag ||
          loadingOrderTagLostStolen ||
          reconciledTagsLoading ||
          reconcileOrderLoading
            ? []
            : tags.rows
        }
        defaultSort={order}
        onSort={setOrder}
      />
      {(loadingDamagedTag ||
        loadingOrderTagLostStolen ||
        reconciledTagsLoading ||
        reconcileOrderLoading) && <Spinner className="padding-y-9" />}
      {tags?.rows?.length > 0 && (
        <Pagination
          fullWidth
          variant="advanced"
          itemsPerPageOptions={[100, 200, 300]}
          itemsCount={tags.count}
          itemsPerPage={paginationState.limit}
          currentPage={paginationState.currentPage}
          onPageChange={handlePaginationChange}
        />
      )}

      {(!tags?.rows || tags?.rows?.length === 0) && !loadingOrderTags && (
        <div className="bg-gray-3 padding-y-5">
          <div className="text-center padding-y-4">
            <EmptyState
              alt="Image not available"
              hasBackground
              bottomText={
                <>
                  <p className="text-bold">No license plates found</p>
                  <p>
                    Add or remove filters to search license plates for this
                    order.
                  </p>
                </>
              }
            />
          </div>
        </div>
      )}
    </>
  );
};

export default OrderTagTable;
