/* eslint-disable filenames/match-exported */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { SelectDropdown } from '@gsa/afp-component-library';

export const getYears = (startDate, endDate, showSelectOption = false) => {
  const years = [{ label: showSelectOption ? 'Select' : '', value: '' }];
  let currentDate = endDate;

  while (currentDate instanceof Date && currentDate >= startDate) {
    const year = currentDate.getFullYear();
    years.push({ label: year, value: year });
    currentDate = new Date(`${year - 1}/01`);
  }
  return years;
};

export const getMonths = (showSelectOption = false) => {
  const leftPad = (value) => `${value}`.padStart(2, '0');
  return [
    { label: showSelectOption ? 'Select' : '', value: '' },
    ...Array.from({ length: 12 }, (_, i) => ({
      label: leftPad(i + 1),
      value: i + 1,
    })),
  ];
};

export const STARTING_YEAR = '1900';

export const DateMonthYear = ({
  showSelectOption,
  label,
  value,
  defaultMonth = '',
  defaultYear = '',
  name,
  onChange,
  fieldFormat,
  error,
}) => {
  const monthOptions = (showSelect = undefined) => getMonths(showSelect);
  const yearOptions = (showSelect = undefined) =>
    getYears(new Date(STARTING_YEAR), new Date(), showSelect);
  const [month, setMonth] = useState(defaultMonth);
  const [year, setYear] = useState(defaultYear);

  useEffect(() => {
    if (value === '') {
      setMonth('');
      setYear('');
    }
  }, [value && value.toString()]);

  useEffect(() => {
    if ((month && year) || (!month && !year)) {
      onChange({ month, year });
    }
  }, [month, year]);

  const handleMonthChange = (evt) => {
    setMonth(evt.target.value);
  };
  const handleYearChange = (evt) => {
    setYear(evt.target.value);
  };

  return (
    <div className="grid-col">
      {label}
      <div className="grid-row grid-gap-2">
        <div className="grid-col-5">
          <SelectDropdown
            label={
              fieldFormat ? <span className="usa-hint">Month</span> : undefined
            }
            id={`month-${name}`}
            name={`month-${name}`}
            onChange={handleMonthChange}
            options={monthOptions(showSelectOption)}
            value={month}
          />
        </div>
        <div className="grid-col-7">
          <SelectDropdown
            label={
              fieldFormat ? <span className="usa-hint">Year</span> : undefined
            }
            id={`year-${name}`}
            name={`year-${name}`}
            onChange={handleYearChange}
            options={yearOptions(showSelectOption)}
            value={year}
          />
        </div>
      </div>
    </div>
  );
};

DateMonthYear.propTypes = {
  label: PropTypes.node.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)])
    .isRequired,
  name: PropTypes.string.isRequired,
  fieldFormat: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
  defaultMonth: PropTypes.number,
  defaultYear: PropTypes.number,
  showSelectOption: PropTypes.bool,
};

DateMonthYear.defaultProps = {
  error: false,
  defaultMonth: -1,
  defaultYear: -1,
  fieldFormat: undefined,
  showSelectOption: false,
};

export default DateMonthYear;
