import React from 'react';

export const CREATE_MILEAGE_SUCCESS_MESSAGE = {
  body: () => <>Mileage was successfully submitted.</>,
};

export const CREATE_MILEAGE_FAIL_MESSAGE = {
  body: () => <>There are errors in the form.</>,
};

export const NO_MODIFIED_MILEAGE_EXPRESS = {
  body: () => <>There are no mileage records to save.</>,
};
