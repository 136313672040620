import React from 'react';
import PropTypes from 'prop-types';
import { StatusBadge } from '@gsa/afp-component-library';
import { itemInventoryStatuses } from '../../utilities/consts';

const InventoryStatusTag = ({ statusCode, ...restProps }) => {
  const status = itemInventoryStatuses[statusCode?.trim()];
  if (!statusCode || !status) {
    return '—';
  }
  return (
    <StatusBadge variant={status.color} {...restProps}>
      {status.displayStatus}
    </StatusBadge>
  );
};

InventoryStatusTag.propTypes = {
  statusCode: PropTypes.string.isRequired,
};

export default InventoryStatusTag;
