import React from 'react';
import { useWatch } from 'react-hook-form';
import { AgencySelection } from '../agency-selection';
import { LegacyCustomerSelection } from '../legacy-customer-selection';
import { BoacsSelection } from '../boacs-selection';
import { AgencyIndicatorSelection } from '../agency-indicator-selection';
// eslint-disable-next-line react/prop-types
export const AgencySelectionGroup = ({ control, setValue, register }) => {
  const [selectedVehicleOption] = useWatch({
    name: ['vehicle_options'],
    control,
  });

  return (
    <section className="margin-bottom-6">
      <AgencySelection
        control={control}
        setValue={setValue}
        title="Agency selection"
        register={register}
      />
      {selectedVehicleOption === 'GF' ? (
        <>
          <BoacsSelection control={control} setValue={setValue} />
          <LegacyCustomerSelection control={control} setValue={setValue} />
          <AgencyIndicatorSelection control={control} />
        </>
      ) : null}
    </section>
  );
};
