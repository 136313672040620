import React, { useEffect, useState } from 'react';
import { Button, RadioButton, TextInput } from '@gsa/afp-component-library';
import { useHistory } from 'react-router-dom';
import usePortalModal from '../../../utilities/portal-modal';
import { validate } from '../../../utilities/validation';
import { useAlert } from '../../alert/alert';
import { isTest } from '../../../utilities/consts';
import { INIT_DESTRUCTION_DATA } from '../helpers/use-license-plate-manager';

export default function LicensePlateDestructionModal({
  editing,
  onClose,
  onSave,
  title,
  destructionInitData,
  setDestructionInitData,
  forDestructionList,
  onGetTagsByExpirationDateLoading,
}) {
  const history = useHistory();
  const [DestructionModal, openModal, closeModal] = usePortalModal();
  const [errors, setErrors] = useState({});
  const [Alert, scrollToAlert] = useAlert();

  const destructionValidationRules = [
    {
      name: `numBoxes`,
      rules: ['required'],
      msg: { required: 'Number of boxes is required' },
    },
    {
      name: `trackingNumber`,
      rules: ['required'],
      msg: { required: 'UPS tracking number is required' },
    },
  ];

  const accessorToLabel = {
    numBoxes: 'Number of boxes',
    trackingNumber: 'UPS tracking number',
  };

  useEffect(() => {
    editing ? openModal() : closeModal();
  }, [editing]);

  const close = () => {
    setErrors({});
    setDestructionInitData(INIT_DESTRUCTION_DATA);
    onClose && onClose();
    closeModal();
  };

  const handleChange = (e) => {
    setDestructionInitData({
      ...destructionInitData,
      [e.target.name]: e.target.value,
    });
  };

  const save = () => {
    const validDestructionInitData = validate({
      data: destructionInitData,
      rules: destructionValidationRules,
      setErrors,
    });
    if (validDestructionInitData && onSave) {
      onSave(
        `${history.location.pathname}/destruction${history.location?.search}`,
      );
    } else {
      scrollToAlert();
    }
  };

  return (
    <DestructionModal
      variant="large"
      title={title}
      actions={
        <>
          <Button variant="unstyled" onClick={close} label="Close" />
          {forDestructionList?.length > 0 && (
            <>
              {destructionInitData?.shippingLabel === 'no' ? (
                <Button className="margin-left-2" onClick={close} label="OK" />
              ) : (
                <Button
                  className="margin-left-2"
                  onClick={save}
                  label="Begin license plate destruction process"
                />
              )}
            </>
          )}
        </>
      }
      onClose={close}
      showAlert
      alert={<Alert errors={errors} accessorToLabel={accessorToLabel} />}
    >
      {((!onGetTagsByExpirationDateLoading && forDestructionList?.length > 0) ||
        isTest) && (
        <>
          <p className="padding-right-6">
            You are about to begin the license plate destruction process. This
            process includes three steps that will end with the selected license
            plates being changed to pending destruction and a PDF will be
            generated that you will need to include in your box to UNICOR.
          </p>
          <p>
            <b>
              Do you have a UPS shipping label for your return boxes?{' '}
              <span className="afp-required-field">*</span>
            </b>
          </p>
          <RadioButton
            className="text-normal margin-top-1"
            name="shippingLabel"
            label="No, I need a shipping label"
            value="no"
            required
            checked={destructionInitData?.shippingLabel === 'no'}
            onChange={handleChange}
          />
          <RadioButton
            className="text-normal margin-top-1"
            name="shippingLabel"
            required
            value="yes"
            checked={destructionInitData?.shippingLabel === 'yes'}
            onChange={handleChange}
            label="Yes"
          />
          {destructionInitData?.shippingLabel === 'no' && (
            <p className="margin-top-5">
              Before you begin, please send an email with the pick up address
              and weight of the shipment to{' '}
              <a
                aria-label="mailto"
                href="mailto:unicor.tag.recycling@usdoj.gov"
              >
                unicor.tag.recycling@usdoj.gov
              </a>{' '}
              and UPS will email you a shipping label to put on your box.
            </p>
          )}
          {destructionInitData?.shippingLabel === 'yes' && (
            <>
              <TextInput
                data-testid="numb-boxes"
                name="numBoxes"
                type="number"
                label="How many boxes are in your shipment?"
                value={destructionInitData?.numBoxes}
                onChange={handleChange}
                required
                min={0}
                errorMessage={errors.numBoxes}
              />
              <TextInput
                id="tracking-number"
                data-testid="tracking-number"
                name="trackingNumber"
                type="text"
                label="UPS tracking number for shipment"
                value={destructionInitData?.trackingNumber}
                onChange={handleChange}
                required
                errorMessage={errors.trackingNumber}
              />
            </>
          )}
        </>
      )}
    </DestructionModal>
  );
}
