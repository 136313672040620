import React from 'react';
import { fieldGetter } from '../../vehicle-details/helpers/field-getter';
import { Button, DetailsTable } from '@gsa/afp-component-library';

const getPocEmail = (email) => {
  const pocEmail = fieldGetter([{ field: email }]);
  if (pocEmail === '-') {
    return pocEmail;
  }
  return <a href={`mailto:${pocEmail}`}>{pocEmail}</a>;
};

const getFullName = (fullName) => {
  if (!fullName) {
    return '';
  }
  return fullName.toUpperCase();
};

const PointOfContact = ({ poc, canEdit, handlePoCEditOpen }) => {
  return (
    <div
      className="bg-gray-3 radius-md padding-y-2 padding-x-4"
      data-testid={`afp-registration__poc_${poc?.ind}`}
    >
      <DetailsTable
        className="afp-registration__section_container"
        data={[
          ['Email', getPocEmail(poc?.email)],
          ['Name', fieldGetter([{ field: getFullName(poc?.fullName) }])],
          [
            'Address',
            <>
              <div className="text-uppercase">
                {fieldGetter([{ field: poc?.primaryAddress }])}
              </div>
              <div className="text-uppercase">
                {fieldGetter([{ field: poc?.secondaryAddress }])}
              </div>
              <div className="text-uppercase">
                {fieldGetter([
                  { field: poc?.city },
                  { field: poc?.stateCode + ' ' + poc?.postalCode },
                ])}
              </div>
            </>,
          ],
          [
            'Phone',
            fieldGetter([
              { field: poc?.primaryPhone, formatter: 'phone' },
              { field: poc?.primaryPhoneExt, formatter: 'ext' },
            ]),
          ],
        ]}
      />
      {canEdit && (
        <Button
          data-testid={`poc-edit-${poc?.ind}`}
          onClick={handlePoCEditOpen}
          variant="outline"
          className="bg-white margin-top-2"
          aria-label={`edit point of contact ${poc?.ind}`}
          label="Edit"
        />
      )}
    </div>
  );
};

export default PointOfContact;
