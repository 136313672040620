import React from 'react';
import _ from 'lodash';
import { Link, useLocation } from 'react-router-dom';
import { Alert } from '@gsa/afp-component-library';
import { formatTagExpDate } from '../../../utilities/common';
import { useLicensePlateDetails } from '../license-plate-details-provider';

const AlertWithVin = ({ tagDetails, vin }) => (
  <div className="padding-bottom-1">
    <Alert
      type="warning"
      heading="License plate has been removed from the vehicle"
    >
      License plate <strong>{_.get(tagDetails, 'id')}</strong> with expiration
      date{' '}
      <strong>{formatTagExpDate(_.get(tagDetails, 'expirationDate'))}</strong>{' '}
      has been removed from the vehicle with the{' '}
      <Link to={`/vehicles/${encodeURIComponent(vin)}`}>
        <strong>VIN {vin}</strong>
      </Link>
      . The status of <strong>unattached</strong> has been applied to both A and
      B plates (if a 2-plate set).
      <div className="padding-y-2">
        <strong>What to do next:</strong>
      </div>
      Please submit license plate <strong>{_.get(tagDetails, 'id')}</strong> for
      destruction.
    </Alert>
  </div>
);

const AlertNoVin = ({ tagDetails }) => (
  <div className="padding-bottom-1">
    <Alert
      type="warning"
      heading="License plate has been removed from the vehicle"
    >
      License plate <strong>{_.get(tagDetails, 'id')}</strong> with expiration
      date{' '}
      <strong>{formatTagExpDate(_.get(tagDetails, 'expirationDate'))}</strong>{' '}
      has been removed from the vehicle. The status of{' '}
      <strong>unattached</strong> has been applied to both A and B plates (if a
      2-plate set).
      <div className="padding-y-2">
        <strong>What to do next:</strong>
      </div>
      Please submit license plate <strong>{_.get(tagDetails, 'id')}</strong> for
      destruction.
    </Alert>
  </div>
);
const LpUnattachedAlert = () => {
  const { tagDetails } = useLicensePlateDetails();
  const location = useLocation();
  if (!tagDetails || _.get(tagDetails, 'tagActivityTypeCode') !== 'UNAT') {
    return null;
  }

  const vin = _.get(tagDetails, 'vehicle.id')
    ? _.get(tagDetails, 'vehicle.id')
    : location?.state?.vin;
  if (!vin) {
    return <AlertNoVin tagDetails={tagDetails} />;
  }
  return <AlertWithVin tagDetails={tagDetails} vin={vin} />;
};

export default LpUnattachedAlert;
