/* eslint react/prop-types: 0 */
import React from 'react';
import { Controller } from 'react-hook-form';
import {
  RequiredFieldIndicator,
  Label,
  TextInput,
  SelectDropdown,
  DatePicker,
} from '@gsa/afp-component-library';
import moment from 'moment';
import { USDateStrToUTCStr } from 'utilities/format';
import { HiddenField } from '../components/hidden-field';
import { useExport } from '../export-provider';

const reportNameRegexp = /^[a-zA-Z0-9-_ ]*$/;
const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const FREQUENCY_OPTIONS = [
  { value: '', label: '- Select -' },
  {
    value: 'Weekly',
    label: 'Weekly',
    hint: 'You will receive this report each week starting on the Monday after your start date.',
  },
  {
    value: 'Monthly',
    label: 'Monthly',
    hint: 'You will receive this report each month starting on the 1st of the month after your start date.',
  },
  {
    value: 'Quarterly',
    label: 'Quarterly (FY)',
    hint: 'You will receive this report each fiscal year quarter starting with the first quarter after your start date.',
  },
];

const FREQUENCY_OPTIONS_FUR = [
  { value: '', label: '- Select -' },
  {
    value: 'Monthly',
    label: 'Monthly',
    hint: 'You will get this report starting on the 5th of the month after your start date.',
  },
  {
    value: 'Quarterly',
    label: 'Quarterly (FY)',
    hint: 'You will get this report starting on the 5th of the quarter after your start date.',
  },
];

const getFrequencyDescription = (reportType) => {
  return (
    <div>
      <p>
        Select your reporting frequency, and then select the start and end dates
        for the period you wish to receive the recurring report.
      </p>
    </div>
  );
};

const getFrequencyOptions = (reportType) => {
  if (reportType === 'FUEL_REPORT') {
    return FREQUENCY_OPTIONS_FUR;
  }
  return FREQUENCY_OPTIONS;
};

const getFrequencyHint = (reportType, value) => {
  const options =
    reportType === 'FUEL_REPORT' ? FREQUENCY_OPTIONS_FUR : FREQUENCY_OPTIONS;
  return options.find((option) => option.value === value)?.hint;
};

const validateAdditionalEmails = (emails) => {
  let validList = true;
  const emailList = emails.split(',');
  if (emailList.length > 9) {
    validList = false;
  }
  emailList.forEach((email) => {
    if (!email.toLowerCase().trim().match(emailRegex)) {
      validList = false;
    }
  });
  return validList;
};

export const ReportScheduler = ({
  control,
  watch,
  register,
  setError,
  clearErrors,
}) => {
  const selectedReportType = watch('reportType');

  const { selectedReport } = useExport();

  const scheduleStartDate = selectedReport
    ? moment(selectedReport.scheduleStartDate).utc().format('MM/DD/YYYY')
    : moment().format('MM/DD/YYYY');

  return (
    <>
      <div className="grid-row">
        <div className="grid-col-6">
          <Controller
            name="report_name"
            control={control}
            rules={{
              required: 'This is a required field',
              pattern: {
                value: reportNameRegexp,
                message: 'Report name is not valid',
              },
            }}
            render={({
              field: { onChange, name, value },
              formState: { errors },
            }) => {
              return (
                <TextInput
                  type="text"
                  characterLimit="30"
                  label={
                    <span className="text-bold">
                      Report name <RequiredFieldIndicator />
                    </span>
                  }
                  onChange={(e) => {
                    onChange(e.target.value);
                  }}
                  name={name}
                  id={name}
                  value={value}
                  errorMessage={
                    errors && errors?.[name] ? errors?.[name].message : null
                  }
                  aria-invalid={errors && errors?.[name] ? 'true' : 'false'}
                />
              );
            }}
          />
        </div>
      </div>
      <div className="grid-row margin-top-3">
        <div className="grid-col-12">
          <h4 className="title-m-bold">Frequency</h4>
          {getFrequencyDescription(selectedReportType)}
        </div>
        <div className="grid-col-7">
          <div className="margin-top-4">
            <Controller
              control={control}
              name="frequency"
              rules={{ required: 'This is a required field' }}
              render={({
                field: { ref, name, value, ...rest },
                formState: { errors },
              }) => {
                return (
                  <>
                    <SelectDropdown
                      id={name}
                      name={name}
                      value={value}
                      label={
                        <span className="text-bold">
                          Reporting Frequency <RequiredFieldIndicator />
                        </span>
                      }
                      className="margin-bottom-05"
                      options={getFrequencyOptions(selectedReportType)}
                      errorMessage={errors[name]?.message}
                      {...rest}
                    />
                    <span className="usa-hint">
                      {getFrequencyHint(selectedReportType, value)}
                    </span>
                  </>
                );
              }}
            />
          </div>
          <div className="grid-row margin-top-3">
            <div className="grid-col-6">
              <Label className="text-bold">Start date</Label>
              <p>{scheduleStartDate}</p>
            </div>
            <div className="grid-col-6">
              <Controller
                name="frequency_end_date"
                control={control}
                rules={{ required: 'This is a required field' }}
                render={({
                  field: { ref, name, value, ...rest },
                  formState: { errors },
                }) => {
                  return (
                    <DatePicker
                      {...rest}
                      id={name}
                      name={name}
                      defaultValue={value || ''}
                      label={
                        <span className="text-bold">
                          End date <RequiredFieldIndicator />
                        </span>
                      }
                      minDate={moment().format('YYYY-MM-DD')}
                      maxDate={moment().add(2, 'Y').format('YYYY-MM-DD')}
                      hint="mm/dd/yyyy"
                      onChange={(val) => {
                        rest.onChange(val ? USDateStrToUTCStr(val) : null);
                      }}
                      errorMessage={errors?.[name]?.message}
                    />
                  );
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="grid-row margin-bottom-6">
        <div className="grid-col-7">
          {selectedReportType !== 'FUEL_REPORT' ? (
            <Controller
              name="additional_recipients"
              control={control}
              render={({
                field: { onChange, name, value },
                formState: { errors },
              }) => {
                return (
                  <TextInput
                    type="textarea"
                    label={
                      <span className="text-bold">Additional recipients</span>
                    }
                    onChange={(e) => {
                      onChange(e.target.value);
                    }}
                    onBlur={(e) => {
                      let validList = true;
                      if (e.target.value !== '') {
                        validList = validateAdditionalEmails(e.target.value);
                      }
                      if (!validList) {
                        setError(name, {
                          type: 'custom',
                          message:
                            'Enter valid email address. Max 9 emails allowed.',
                        });
                      } else {
                        clearErrors(name);
                      }
                    }}
                    help="Besides yourself, who else should receive a copy of this report? Enter up to 9 email addresses below, separated by a comma."
                    name={name}
                    id={name}
                    value={value}
                    errorMessage={
                      errors && errors?.[name] ? errors?.[name].message : null
                    }
                    aria-invalid={errors && errors?.[name] ? 'true' : 'false'}
                  />
                );
              }}
            />
          ) : (
            <HiddenField register={register} name="additional_recipients" />
          )}
        </div>
      </div>
    </>
  );
};
