import React from 'react';
import { StatusBadge } from '@gsa/afp-component-library';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { tagStatuses } from '../../../utilities/consts';

export default function TagStatusBadge({ tagStatus }) {
  return tagStatus ? (
    <StatusBadge variant={_.get(tagStatuses, [tagStatus, 'color'])}>
      {_.get(tagStatuses, [tagStatus, 'statusShort'])}
    </StatusBadge>
  ) : null;
}

TagStatusBadge.defaultProps = {
  tagStatus: undefined,
};

TagStatusBadge.propTypes = {
  tagStatus: PropTypes.string,
};
