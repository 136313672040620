import { FilterTableFrame } from '@gsa/afp-component-library';
import React, { useMemo } from 'react';
import MotorPoolVehicleListingTable from './motor-pool-vehicles-table';
import MotorPoolVehiclesSidebar from './motor-pool-vehicles-sidebar';

const MotorPoolVehiclesListing = () => {
  const FilterGrid = useMemo(
    () =>
      FilterTableFrame(
        null,
        null,
        MotorPoolVehiclesSidebar,
        MotorPoolVehicleListingTable,
      ),
    [],
  );

  return <FilterGrid filterToggle />;
};

export default MotorPoolVehiclesListing;
