import React from 'react';

import { startCase } from 'lodash';
import {
  DetailsTable,
  ButtonDropdown,
  ButtonDropdownItem,
} from '@gsa/afp-component-library';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import { useOfficeManagement } from './office-management-provider';
import { VMSOperations, VMSSubjects } from '../../utilities/consts'
// eslint-disable-next-line import/named

const OfficeDetails = () => {
  const context = useOfficeManagement();
  const { selectedOrganization, dispatchAction, currentModalName } =
    context;
  const {
    agencyCode,
    bureauCode,
    officeCode,
    agencyName,
    bureauName,
    officeName,
  } = selectedOrganization || {};

  const ability = useAppAbility();
  const canManageOffice = ability.can(
    VMSOperations.Manage,
    VMSSubjects.ORGANIZATION_PROFILE,
  );
  
  return (
    <>
      <div className="grid-row margin-top-5 border-bottom border-base-lighter">
        <div className="grid-col-10">
          <h4 className="title-s-caps text-primary">OFFICE DETAILS</h4>
        </div>
        <div className="grid-col-2">
          {canManageOffice && (
            <ButtonDropdown label="Office actions" variant="primary">
              <ButtonDropdownItem
                label="Edit detail information"
                iconName="edit"
                className="hover:bg-primary-lighter"
                onClick={() => {
                  dispatchAction('SET_MODAL', {
                    currentModalName,
                    currentMode: 'editOfficeDetailSchema',
                  });
                }}
              />
              <ButtonDropdownItem
                label="Delete office"
                iconName="delete"
                className="hover:bg-primary-lighter"
                onClick={() => {
                  dispatchAction('SET_MODAL', {
                    currentModalName,
                    currentMode: 'deleteOfficeDetailModalSchema',
                  });
                }}
              />
            </ButtonDropdown>
          )}
        </div>
      </div>
      <div className="grid-row margin-top-5">
        <div className="grid-col-10">
          <div className="grid-row flex-justify">
            <div className="grid-col-5">
              <DetailsTable
                bordered
                data={[
                  [
                    'Agency',
                    <span className="float-right align-right">
                      {`${agencyCode} - ${startCase(
                        agencyName?.toLocaleLowerCase(),
                      )}`}
                    </span>,
                  ],
                  [
                    'Bureau',
                    <span className="float-right align-right">
                      {`${bureauCode} - ${startCase(
                        bureauName?.toLocaleLowerCase(),
                      )}`}
                    </span>,
                  ],
                ]}
              />
            </div>
            <div className="grid-col-5">
              <DetailsTable
                bordered
                data={[
                  [
                    'Office code',
                    <span className="float-right align-right">
                      {`${officeCode}`}
                    </span>,
                  ],
                  [
                    'Office name',
                    <span className="float-right align-right">
                      {`${startCase(officeName?.toLocaleLowerCase())}`}
                    </span>,
                  ],
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OfficeDetails;
