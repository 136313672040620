import React from 'react';
import { Button, DetailsTable, Spinner } from '@gsa/afp-component-library';

export default function EquipmentDetail({
  details,
  canEditEquipment,
  handleEquipmentEdit,
}) {
  return (
    <div
      className="bg-gray-3 radius-md padding-y-2 padding-x-4"
      data-testid="afp-registration__equipment_info"
    >
      <DetailsTable
        className="afp-registration__section_container"
        data={details}
      />

      {canEditEquipment && (
        <Button
          data-testid="edit_equipment_button"
          onClick={handleEquipmentEdit}
          variant="outline"
          className="bg-white margin-top-2"
          aria-label="edit equipment"
          label="Edit"
        />
      )}
    </div>
  );
}
