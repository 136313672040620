/* eslint-disable react/prop-types */
import React from 'react';
import {
  RequiredFieldIndicator,
  ErrorMessage,
} from '@gsa/afp-component-library';
import classnames from 'classnames';
import { useRecoilState } from 'recoil';
import { cloneDeep } from 'lodash';
import HeaderSearch from '../../vehicle-details/widgets/header-search';

const VehiclePickerField = ({
  selectors,
  pageId,
  id,
  label,
  required,
  disabled,
}) => {
  const { fieldSelector } = selectors;
  const [fieldValueState, setFieldValueState] = useRecoilState(
    fieldSelector({ pageId, id }),
  );

  const onSelect = (vehicleId) => {
    const newState = cloneDeep(fieldValueState);
    newState.value = vehicleId;
    setFieldValueState(newState);
  };

  return (
    <div
      className={`grid-col-8 minh-15 ${classnames(
        fieldValueState.errorMessage &&
          'border-left-05 error-border-color padding-left-2',
      )}`}
    >
      {label && (
        <label>
          {label} {required && <RequiredFieldIndicator />}
        </label>
      )}
      {fieldValueState.errorMessage && (
        <ErrorMessage>{fieldValueState.errorMessage}</ErrorMessage>
      )}
      <div style={{ height: '6.5rem' }}>
        <HeaderSearch onSelect={onSelect} disabled={disabled} forExport />
      </div>
    </div>
  );
};

export default VehiclePickerField;
