import _ from 'lodash';

export default function getNestedOperatorFilter(values) {
  let combinedFilters = [];
  _.map(values, (value) => {
    let filters;
    switch (value) {
      case 'Standard Vehicle':
        filters = [
          {
            operator: 'OR',
            value: [
              { operator: 'NOTLIKE', key: 'id', value: 'G09%' },
              {
                operator: 'AND',
                value: [
                  { operator: 'LIKE', key: 'id', value: 'G%' },
                  { operator: 'NOTLIKE', key: 'id', value: '%M' },
                ],
              },
            ],
          },
          {
            operator: 'OR',
            value: [
              { operator: 'NOTLIKE', key: 'id', value: 'G91%' },
              {
                operator: 'AND',
                value: [
                  { operator: 'LIKE', key: 'id', value: 'G%' },
                  { operator: 'NOTLIKE', key: 'id', value: '%T' },
                ],
              },
            ],
          },
        ];
        break;
      case 'Motorcycle':
        filters = [
          {
            operator: 'OR',
            value: [
              { operator: 'STARTWITH', key: 'id', value: 'G09' },
              {
                operator: 'AND',
                value: [
                  { operator: 'NOTLIKE', key: 'id', value: 'G%' },
                  { operator: 'ENDWITH', key: 'id', value: 'M' },
                ],
              },
            ],
          },
        ];
        break;
      case 'Trailer':
        filters = [
          {
            operator: 'OR',
            value: [
              { operator: 'STARTWITH', key: 'id', value: 'G91' },
              {
                operator: 'AND',
                value: [
                  { operator: 'NOTLIKE', key: 'id', value: 'G%' },
                  { operator: 'ENDWITH', key: 'id', value: 'T' },
                ],
              },
            ],
          },
        ];
        break;
      case 'Agency Owned':
        filters = [
          {
            operator: 'AND',
            value: [{ operator: 'NOTLIKE', key: 'id', value: 'G%' }],
          },
        ];
        break;
      case 'GSA Leased':
        filters = [
          {
            operator: 'AND',
            value: [{ operator: 'LIKE', key: 'id', value: 'G%' }],
          },
        ];
        break;
      default:
        filters = [];
    }
    combinedFilters = _.concat(combinedFilters, filters);
  });
  return [
    {
      operator: 'OR',
      value: combinedFilters,
    },
  ];
}
