/* eslint-disable react/prop-types */
import React from 'react';
import { useTitle, InternalError } from '@gsa/afp-shared-ui-utils';
import { Link } from 'react-router-dom';
import { PageTitle, Breadcrumbs, Accordion } from '@gsa/afp-component-library';

const ZonalReportsHeader = ({ error }) => {
  const title = 'Zonal Operations Report';
  useTitle(title);

  const getAvailableReports = () => (
    <table className="usa-table">
      <thead>
        <tr>
          <th>Report Name</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            8 Days to Assign/ New Vehicles loaded into GSAFleet.gov (Legacy
            Status 40)
          </td>
          <td>
            A list of vehicles that are at a marshaling site that have not been
            picked up by a customer within 8 days, no eVAF has been created and
            new vehicle status applied.
          </td>
        </tr>
        <tr>
          <td>Customers with assigned vehicle count</td>
          <td>
            A list of all Leasing accounts that includes the number of vehicles
            assigned, customer and FSR information. This report is in lieu of an
            export from the Leasing account management screen (temporarily).
          </td>
        </tr>
        <tr>
          <td>Temporary Internal Use (formerly known as Dispatch/25 status)</td>
          <td>
            Shows which vehicles are marked for FMC&#39;s internal use. This
            report shows the number of days that the vehicle has been in this
            status. Note: This status is non-revenue generating.
          </td>
        </tr>
        <tr>
          <td>Unassigned Vehicles (Idle vehicles - 45, 46, 47 statuses)</td>
          <td>
            Monitors which vehicles are unassigned and for how long, also
            includes information regarding replacement eligibility.
          </td>
        </tr>
        <tr>
          <td>Non GSA Plates (State plates or AFI)</td>
          <td>
            A list of Active/Assigned (20 status) vehicles that have non-GSA
            issued license plates.
          </td>
        </tr>
        <tr>
          <td>Overdue PMs</td>
          <td>
            Provides a list of vehicles that show an overdue PM and includes a
            count of PMs that are overdue.
          </td>
        </tr>
        <tr>
          <td>Assignment and Termination History</td>
          <td>
            Until a Vehicle Transaction History interface can be built in
            GSAFleet.gov, this report will serve as an assignment and
            termination history record.
          </td>
        </tr>
        <tr>
          <td>Tag Changes</td>
          <td>
            Until a Vehicle Transaction History interface can be built in
            GSAFleet.gov, this report will serve as a reference to see if a
            leased vehicle (VIN) has had a tag change performed.
          </td>
        </tr>
        <tr>
          <td>VAT List (Vehicles Available for Transfer)</td>
          <td>
            Lists vehicles that have the available for transfer stats. Users can
            also filter the vehicle inventory page using Vehicle status to view
            this information.
          </td>
        </tr>
        <tr>
          <td>Ready for Sale (formerly 6X) vehicles with open recalls</td>
          <td>
            A list of vehicles marked as Ready for sale (formerly 6X) during the
            prior month that have open actionable safety recalls.
          </td>
        </tr>
        <tr>
          <td>Vehicles that have been ordered against with open recalls</td>
          <td>
            A list of vehicles with open actionable safety recalls that have had
            a replacement ordered to emphasize to customers to get these recalls
            completed promptly.
          </td>
        </tr>
        <tr>
          <td>Consolidated Vehicle Repairs</td>
          <td>
            A list of all repairs authorized on a vehicle that was consolidated
            within the last 90 days. This report also references if AIEs have
            been generated against these repairs.
          </td>
        </tr>
        <tr>
          <td>Vehicle Utilization Report</td>
          <td>
            A list of Active, Assigned vehicles that average less than 300
            reported miles per month OR greater than 2,500 miles per month.
          </td>
        </tr>
        <tr>
          <td>Code 7 Vendors</td>
          <td>A list of all new (code 7) vendors for review.</td>
        </tr>
        <tr>
          <td>Early disposals</td>
          <td>
            All vehicles that were marked as &#39;Ready for sale&#39; (formerly
            6X) that do not meet FMR disposal criteria. The data provided in
            this report is to assist FSRs and FMC managers to prepare a 2553
            requesting authorization for early disposal.
          </td>
        </tr>
        <tr>
          <td>Leasing Accounts not on GORP</td>
          <td>
            Lists all leasing customer accounts that are not marked as Mileage
            capture type &#39;GORP&#39; or &#39;DESC&#39; for review.
          </td>
        </tr>
        <tr>
          <td>CNG Tank Inspection</td>
          <td>
            Displays all Active, Assigned (20 status) CNG vehicles that are CNG
            fuel type, and suspected tank inspection transactions.
          </td>
        </tr>
        <tr>
          <td>CNG Vehicle Accidents</td>
          <td>
            Displays all Active, Assigned (20 status) CNG vehicles that are CNG
            fuel type that have reported an accident or incident in the previous
            month so that the CNG tank can be inspected.
          </td>
        </tr>
      </tbody>
    </table>
  );

  const accordionContents = [
    {
      title: 'List of available reports',
      content: getAvailableReports(),
      expanded: false,
      id: 'available-reports',
      className: 'grid-col-12 margin-y-2',
    },
  ];

  return (
    <header>
      <Breadcrumbs
        trail={[
          <a href={`${window.AFP_CONFIG.appURLs.home}/home`}>Home</a>,
          <Link to="/reports">Vehicle Reports Manager</Link>,
        ]}
        current={title}
      />
      {error && <InternalError />}
      <PageTitle title={title} />
      <Accordion items={accordionContents} />
    </header>
  );
};

export default ZonalReportsHeader;
