import vehicleFilters, {
  vehicleWithNestedFilters,
} from '../filter-structures/vehicle';

export default function bfm() {
  return [
    vehicleFilters.vehicleLicensePlateTypeahead,
    vehicleFilters.vehicleVINTypeahead,
    vehicleFilters.vehiclePoCTypeahead,
    vehicleFilters.vehicleMakeModel,
    vehicleFilters.vehicleOwnership,
    vehicleFilters.registrationStaus,
  ];
}

export const bfmVehicleNestedFilters = () => {
  return [
    vehicleWithNestedFilters.vehicleVINTypeahead,
    vehicleWithNestedFilters.vehicleLicensePlateTypeahead,
    vehicleWithNestedFilters.vehiclePoCTypeahead,
    vehicleWithNestedFilters.vehicleMakeModel,
    vehicleWithNestedFilters.vehicleOwnership,
    vehicleWithNestedFilters.vehicleLifecycleAndStatus,
    vehicleWithNestedFilters.registrationStaus,
  ];
};
