import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { VMSOperations, VMSSubjects } from 'utilities/consts';
import { omit } from 'lodash';
import useCanPerformActions from 'hooks/use-can-perform-actions';
import { useMotorPool } from '../motor-pool-provider';
import MotorPoolAddEditFields from './motor-pool-add-edit-fields';
import { MOTOR_POOL_FORM_FIELDS } from './consts';

const MotorPoolAddEditForm = () => {
  const canPerformActions = useCanPerformActions();
  const [requiredFields, setRequiredFields] = useState();
  const numericRegExp = /^(\s*|\d+)$/;
  const notAllowedRegExp = /^[^%$]*$/;

  const {
    addMotorPool,
    updateMotorPool,
    motorPoolAgency,
    motorPoolListingSelected,
    motorPoolListingModalMode,
    getAgenciesByPermission,
  } = useMotorPool();

  useEffect(() => {
    if (!motorPoolAgency && motorPoolListingModalMode === 'ADD_MOTOR_POOL_LISTING') {
      getAgenciesByPermission({
        variables: {
          operation: VMSOperations.View,
          subject: VMSSubjects.MOTOR_POOL,
          order: 'id ASC',
        },
      });
    }
  }, []);

  useEffect(() => {
    let initRequiredFields = {
      [MOTOR_POOL_FORM_FIELDS.mpName.name]: yup
        .string()
        .required()
        .min(6, 'Pool name must be at least 6 characters')
        .max(50, 'Pool name cannot be longer than 50 characters')
        .test(
          'not-allowed',
          'Pool name cannot contain special characters like % or $',
          (value) => notAllowedRegExp.test(value),
        )
        .label('Pool name'),
      [MOTOR_POOL_FORM_FIELDS.description.name]: yup
        .string()
        .required()
        .max(200, 'Maximum description length is 200 characters')
        .label('Description'),
      [MOTOR_POOL_FORM_FIELDS.timezone.name]: yup
        .string()
        .required()
        .label('Time zone'),
      [MOTOR_POOL_FORM_FIELDS.zip.name]: yup
        .string()
        .test('is-numeric', 'ZIP code should be numeric', (value) => {
          if (!value) {
            return true;
          }
          return numericRegExp.test(value);
        })
        .test('is-length', 'ZIP code should be 5 digits', (value) => {
          if (!value) {
            return true;
          }
          return value.length === 5;
        })
        .label('ZIP code')
        .nullable(),
    };
    if (canPerformActions.canEditMotorPoolAgency()) {
      initRequiredFields = {
        ...initRequiredFields,
        ...{
          [MOTOR_POOL_FORM_FIELDS.mpAgency.name]: yup
            .string()
            .required()
            .label('Agency'),
          [MOTOR_POOL_FORM_FIELDS.primaryDispatcher.name]: yup
            .string()
            .required()
            .label('Primary dispatcher'),
        },
      };
    }
    setRequiredFields(initRequiredFields);
  }, [canPerformActions.canEditMotorPoolAgency()]);

  const generateMotorPoolFormSchema = () => yup.object().shape(requiredFields);

  const methods = useForm({
    resolver: yupResolver(generateMotorPoolFormSchema()),
    defaultValues: {},
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const onSubmit = (values) => {
    let newValues = { ...values };
    let agencyCodeParam;
    if (canPerformActions.canEditMotorPoolAgency()) {
      agencyCodeParam = values.agencyCode;
    } else {
      newValues = omit(newValues, ['primaryDispatcher']);
      agencyCodeParam = motorPoolAgency?.id;
    }

    const booleeanFields = [
      'isActive',
      'isPublic',
      'allowDriverSelfAcknowledge',
    ];
    const reformatedValues = {};
    Object.keys(newValues).forEach((key) => {
      if (booleeanFields.includes(key)) {
        reformatedValues[key] = newValues[key] === 'yes';
      } else {
        reformatedValues[key] = newValues[key];
      }
    });

    if (motorPoolListingModalMode === 'UPDATE_MOTOR_POOL_LISTING') {
      updateMotorPool({
        variables: {
          updatePool: {
            id: motorPoolListingSelected.id,
            agencyCode: agencyCodeParam,
            ...reformatedValues,
            maxReservableDays: +newValues.maxReservableDays,
          },
        },
      });
    } else {
      const createPool = {
        ...reformatedValues,
        maxReservableDays: +newValues.maxReservableDays,
        agencyCode: agencyCodeParam,
      };
      addMotorPool({
        variables: {
          createPool,
        },
      });
    }
  };

  return (
    <FormProvider {...methods}>
      <form
        data-testid="motor-pool-listing-add-form"
        id="motor-pool-listing-add-form"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <MotorPoolAddEditFields />
      </form>
    </FormProvider>
  );
};

export default MotorPoolAddEditForm;
