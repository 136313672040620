import React, { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { Button, DetailsTable, Spinner } from '@gsa/afp-component-library';
import { isFeatureEnabled } from 'utilities/feature-toggle';
import { canUpdateGFVehicleBM } from 'utilities/authorization';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import { emdash } from 'components/common';
import LeasingRatesEdit from './leasing-rates-edit';
import { VehiclePropType } from '../../../../utilities/types';
import {
  GET_LEASING_RATES,
  UPDATE_LEASING_RATES,
} from '../../../../services/data-layer';
import { useVehicle } from '../../vehicle-context-provider';
import { formatUSCurrency, getYesOrNo } from '../vehicle-overview/helpers/common';

const LeasingRates = ({ data }) => {
  const { setTabMsg } = useVehicle();
  const [ratesData, setRatesData] = useState({});

  const [editingRates, setRatesEditing] = useState(false);

  const ability = useAppAbility();

  const canUpdateRates =
    isFeatureEnabled('gf-rates') && canUpdateGFVehicleBM(ability);

  const [getLeasingRate, { data: leasingRate, loading: ratesDataLoading }] =
    useLazyQuery(GET_LEASING_RATES, {
      fetchPolicy: 'no-cache',
    });

  useEffect(() => {
    if (data?.id) {
      getLeasingRate({
        variables: {
          leasingRateRequest: {
            assetId: data?.uuid,
            sinNumber: data?.standardItem?.standardItemNumber,
            fmcId: data?.fmcId,
            agencyCode: data?.customer?.customerAgencyCode,
            afvAgencyIndicator: data?.customer?.bureau?.agencyIndicatorCode,
          },
        },
      });
    }
  }, []);

  const [
    updateOrCreateRates,
    { data: vehicleRatesData, loading: updateRatesDataLoading },
  ] = useMutation(UPDATE_LEASING_RATES, {
    fetchPolicy: 'no-cache',
    onError: (error) => {
      setTabMsg({
        type: 'error',
        message: error?.message,
      });
    },
  });

  useEffect(() => {
    if (vehicleRatesData?.updateLeasingRate) {
      setRatesData(vehicleRatesData.updateLeasingRate);
      setTabMsg({
        type: 'success',
        message: 'Rate is successfully saved',
      });
    }
  }, [vehicleRatesData]);

  const handleSaveRates = (editedRatesData) => {
    const {
      manualEntryRate,
      monthlyRate,
      mileageRate,
      modifiedMileageRateType,
      location,
      assetId,
      optionalEquipmentRate,
    } = editedRatesData;

    const updatedRatesData = {
      manualEntryRate,
      monthlyRate: Number(monthlyRate),
      mileageRate: Number(mileageRate),
      modifiedMileageRateType,
      optionalEquipmentRate: Number(optionalEquipmentRate),
      location,
      assetId,
      fmcId: data?.fmcId,
      sinNumber: data?.standardItem?.standardItemNumber,
      agencyCode: data?.customer?.customerAgencyCode,
      afvAgencyIndicator: data?.customer?.bureau?.agencyIndicatorCode,
    };

    updateOrCreateRates({
      variables: {
        leasingRate: updatedRatesData,
      },
    });
  };

  useEffect(() => {
    if (leasingRate) {
      setRatesData(leasingRate.getLeasingRate);
    }
  }, [leasingRate]);

  const rates = [
    ['Monthly rate', formatUSCurrency(ratesData?.monthlyRate) || emdash],
    ['Daily rate', formatUSCurrency(ratesData?.dailyRate) || emdash],
    ['Mileage rate', formatUSCurrency(ratesData?.mileageRate, 3) || emdash],
    ['Optional equipment rate', formatUSCurrency(ratesData?.optionalEquipmentRate) || emdash],
    ['AFV surcharge', formatUSCurrency(ratesData?.afvSurcharge) || emdash],
    [
      'Modified mileage rate type',
      ratesData?.modifiedMileageRateType || emdash,
    ],
    ['High cost threshold', getYesOrNo(ratesData?.highCostThreshold) || emdash],
    ['Location ', ratesData?.location || emdash],
    ['Manual entry rate', getYesOrNo(ratesData?.manualEntryRate) || emdash],
    [
      'Telematics opt-in subscription rate',
      formatUSCurrency(ratesData?.optInSubscriptionRate) || emdash,
    ],
  ];

  const handleCloseRates = () => {
    setRatesEditing(false);
  };

  return (
    <>
      <h4 className="title-s-caps text-primary margin-bottom-1">
        LEASING RATES
      </h4>
      <div
        className="bg-gray-3 radius-md padding-y-2 padding-x-4"
        data-testid={`afp-rates-${ratesData?.id}`}
      >
        <DetailsTable
          className="afp-registration__section_container text-tabular"
          data={rates}
        />
        {canUpdateRates && (
          <Button
            data-testid="rates-edit"
            onClick={() => setRatesEditing(true)}
            variant="outline"
            className="bg-white margin-top-2"
            aria-label={`edit rates ${ratesData?.id}`}
            label="Edit"
          />
        )}
        {editingRates && (
          <div className="grid-col-12 pull-right">
            <LeasingRatesEdit
              vehicle={data}
              ratesData={ratesData}
              editingRates={editingRates}
              manualEntryRate={ratesData?.manualEntryRate}
              onSave={handleSaveRates}
              onClose={handleCloseRates}
            />
          </div>
        )}
      </div>
      {ratesDataLoading ||
        (updateRatesDataLoading && (
          <Spinner aria-busy="true" className="loading_backdrop" size="large" />
        ))}
    </>
  );
};

LeasingRates.propTypes = {
  data: PropTypes.shape(VehiclePropType).isRequired,
};

export default LeasingRates;
