import { isFeatureEnabled } from 'utilities/feature-toggle';
import { itemInventoryStatuses } from 'utilities/consts';
import {
  canViewModification,
  canViewGFVehicles,
  canViewGFVehicleBM,
} from 'utilities/authorization';

export const NO_RECALL_STATUSES = ['Missing/Stolen', 'Sold', 'Disposed'];
export const CDD_STATUSES = ['Active', 'Missing/Stolen'];

export function getVehicleStatus(statusCode) {
  const statusObj = itemInventoryStatuses[statusCode];
  return statusObj?.altStatus || statusObj?.displayStatus || 'N/A';
}

// use this function in conjunction with OptIn shared-ui-utils
// to show/hide recall components
export const isRecallEnabled = (
  agencyCode,
  ownershipTypeCode,
  itemInventoryStatusCode,
) => {
  const status = getVehicleStatus(itemInventoryStatusCode);
  return (
    agencyCode && // has agency
    !NO_RECALL_STATUSES.includes(status) // not in no-recall list
  );
};

// use this function in conjunction with OptIn shared-ui-utils
// to show/hide mileage components
export const isMileageEnabled = (agencyCode, ownershipTypeCode) => {
  return (
    (agencyCode && // has agency
      ownershipTypeCode === 'AO') ||
    ownershipTypeCode === 'GF' // agency owned or GSA leased
  );
};

// use this function in conjunction with OptIn shared-ui-utils
// to show/hide cdd components
export const isCDDEnabled = (
  agencyCode,
  ownershipTypeCode,
  itemInventoryStatusCode,
) => {
  const status = getVehicleStatus(itemInventoryStatusCode);
  const isGFCDDEnabled = isFeatureEnabled('gf-cdd');

  if (ownershipTypeCode === 'GF' && isGFCDDEnabled) {
    return agencyCode && itemInventoryStatusCode === '20';
  }
  return (
    agencyCode && // has agency
    ownershipTypeCode === 'AO' && // agency owned
    CDD_STATUSES.includes(status) // in cdd list
  );
};

// use this function to show/hide modification component
export const isModificationEnabled = (ownershipTypeCode, ability) => {
  return (
    ownershipTypeCode === 'GF' && // Government Furnished
    canViewModification(ability)
  );
};

export const isPmEnabled = (ability, ownershipTypeCode) => {
  return (
    isFeatureEnabled('pm-express') &&
    ownershipTypeCode === 'GF' &&
    canViewGFVehicles(ability)
  );
};

export const isValuationEnabled = (ownershipTypeCode, ability) => {
  return (
    ownershipTypeCode === 'GF' && // Government Furnished
    canViewGFVehicleBM(ability)
  );
};

export const isTelematicsEnabled = (ownershipTypeCode) => {
  return isFeatureEnabled('telematics') && ownershipTypeCode === 'GF';
};

export const isBillingHistoryEnabled = (ability, ownershipTypeCode) => {
  return (
    isFeatureEnabled('billing-history') &&
    ownershipTypeCode === 'GF' &&
    canViewGFVehicles(ability)
  );
};
