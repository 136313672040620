import React, { useEffect } from 'react';
import { FilterPanel } from '@gsa/afp-component-library';
import useCanPerformActions from 'hooks/use-can-perform-actions';
import { useCurrentUser } from '@gsa/afp-shared-ui-utils';
import { useMotorPoolFilter } from './filters/filter-provider';
import { generateMotorPoolFilterStructure } from './filters/motor-pool-filter-structure';

const MotorPoolSidebar = () => {
  const canPerformActions = useCanPerformActions();
  const canEditAgency = canPerformActions.canEditMotorPoolAgency();
  const {
    filterStructure,
    setFilters,
    setStructure,
    typeaheadData,
    getReservationsByConfirmationNumber,
    getPoolsByEmail,
  } = useMotorPoolFilter();
  const { currentUser } = useCurrentUser();

  const setMotorPoolFilters = (filters) => {
    setFilters({
      context: 'motorPoolsListing',
      filters,
    });
  };

  useEffect(() => {
    setStructure({
      context: 'motorPoolsListing',
      filterStructure: generateMotorPoolFilterStructure(
        canEditAgency,
        currentUser.id,
      ),
    });
  }, []);

  const handleTypeaheadSearch = ({ variables }) => {
    const { conditions } = variables.filters[0];
    const query = conditions.filter((c) => c.key === variables.field)[0]?.value;
    if (variables.field === 'confirmationNumber') {
      getReservationsByConfirmationNumber({
        variables: {
          confirmationNumber: query,
        },
      });
    } else if (variables.field === 'email') {
      getPoolsByEmail({
        variables: {
          email: query,
        },
      });
    }
  };

  if (filterStructure?.motorPoolsListing?.length > 0) {
    return (
      <FilterPanel.FilterPanel
        filterStructure={filterStructure.motorPoolsListing}
        setQueryFiltersState={(filters) => setMotorPoolFilters(filters)}
        clearButtonLabel="Reset all"
        showClearIcon
        order={[['updateAt', 'DESC']]}
        fetchTypeaheads={handleTypeaheadSearch}
        typeaheadData={typeaheadData}
        showSearchIcon
      />
    );
  }
  return null;
};

export default MotorPoolSidebar;
