import React from 'react';
import ReactDOM from 'react-dom';
import { RecoilRoot } from 'recoil';

import App from './app';

import './index.css';

export const ReactStrictMode = (
  <React.StrictMode>
    <RecoilRoot>
      <App />
    </RecoilRoot>
  </React.StrictMode>
);

export const rootElement = document.getElementById('root');

ReactDOM.render(ReactStrictMode, rootElement);
