/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
/* eslint-disable filenames/match-exported */
import { useEffect } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useSetRecoilState, useRecoilState } from 'recoil';
import { omit } from 'lodash';
import { useHistory } from 'react-router-dom';
import { getIsOrgOptInLazyQuery } from '@gsa/afp-shared-ui-utils';
import { isMileageEnabled } from 'components/vehicle-details/helpers/feature-helper';
import { VALIDATE_ADDRESS, VEHICLE_DETAIL } from 'services/data-layer';
import { GET_FLEET_CARD_PRODUCTS_AND_UNIT_OF_MEASURES } from './operation/query/get-fleet-card-products';
import { GET_UNIT_OF_MEASURE_LOOK_UP } from './operation/query/get-unit-of-measure-lookup';
import { CREATE_FLEET_CARD_TRANSACTION } from './operation/mutation/create-fleet-card-transaction';
import { UPDATE_FLEET_CARD_TRANSACTION } from './operation/mutation/update-fleet-card-transaction';
import { UPDATE_FLEET_CARD_TRANSACTION_WEX } from './operation/mutation/update-fleet-card-transaction-wex';

import {
  addressSelector,
  pageConfigUpdateSelector,
  updateOptionsSelector,
} from './store/selectors';

export const showExpensePageOrRedirect = ({
  data,
  history,
  execIsOrgOptIn,
}) => {
  if (
    !isMileageEnabled(
      data.getVehicle?.agencyCode,
      data.getVehicle?.ownershipTypeCode,
    )
  ) {
    history.replace('/not-opted');
    return;
  }
  execIsOrgOptIn({
    variables: {
      agencyCode: data.getVehicle?.agencyCode,
      bureauCode: data.getVehicle?.bureauCode,
    },
  });
};

const useDataController = (vin) => {
  const setAddressState = useSetRecoilState(addressSelector);
  const [vehicle, setVehicle] = useRecoilState(
    pageConfigUpdateSelector('vehicle'),
  );
  const history = useHistory();

  const updateProductOptions = useSetRecoilState(updateOptionsSelector);

  const [getProductOptions, { loading: getProductOptionsLoading }] =
    useLazyQuery(GET_FLEET_CARD_PRODUCTS_AND_UNIT_OF_MEASURES, {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (data?.getFleetCardProductsAndUnitOfMeasures) {
          const productOptions =
            data?.getFleetCardProductsAndUnitOfMeasures.productLists.map(
              (product) => {
                const { id, fullProductDescription, productDescription } =
                  product;

                return {
                  value: id,
                  label: fullProductDescription || productDescription,
                };
              },
            );

          const unitOfMeasureStrs =
            data?.getFleetCardProductsAndUnitOfMeasures.unitOfMeasureLookup;

          updateProductOptions({
            productOptions,
            unitOfMeasureStrs,
          });
        }
      },
      onError: (error) => {
        setVehicle({ error });
      },
    });

  const [getUnitOfMeasureOptions] = useLazyQuery(GET_UNIT_OF_MEASURE_LOOK_UP, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (data?.getUnitOfMeasureLookup) {
        updateProductOptions({
          unitOfMeasureStrs: data?.getUnitOfMeasureLookup,
          setUnitOfMeasureToDefault: true,
        });
      }
    },
    onError: (error) => {
      setVehicle({ error });
    },
  });
  const [execIsOrgOptIn, { loading: execIsOrgOptInLoading }] =
    getIsOrgOptInLazyQuery({
      onCompleted: ({ isOrgOptedIn }) => {
        if (!isOrgOptedIn) {
          history.replace('/not-opted');
        }
      },
      onError: () => {
        history.replace('/not-opted');
      },
    });

  const {
    data: vehicleData,
    refetch: getVehicleDetail,
    loading: vehicleDetailLoading,
    error: vehicleError,
  } = useQuery(VEHICLE_DETAIL, {
    variables: { id: vin },
    fetchPolicy: 'no-cache',
    //  errorPolicy: 'all'  ---- Both data and error.graphQLErrors are populated, enabling you to render both partial results and error information.
    // we need implement this error Policy for page to still show the data when we have the system error because the request from AFP43154.
    // it may create some unexpected defects.  the data fetching  and the code logic of this page is not implement properly may need some refactor in the future.
    // errorPolicy: 'all', is not compatible with useLazyQuery, we need to use useLazyQuery until the  apollographql fix this problem  https://github.com/apollographql/react-apollo/issues/4038
    errorPolicy: 'all',
  });

  useEffect(() => {
    if (vehicleData?.getVehicle) {
      showExpensePageOrRedirect({ data: vehicleData, history, execIsOrgOptIn });
      setVehicle(vehicleData?.getVehicle);
    }
    if (
      vehicleError &&
      !vehicleError?.message.includes(
        'Cannot return null for non-nullable field',
      )
    ) {
      return setVehicle({ error: vehicleError });
    }
  }, [vehicleData, vehicleError]);

  const [validateAddress, { loading: validateAddressLoading }] = useLazyQuery(
    VALIDATE_ADDRESS,
    {
      fetchPolicy: 'no-cache',

      onCompleted: (data) => {
        if (data?.validateAddress) {
          const validatedAddress = omit(data.validateAddress, [
            '__typename',
            'updates',
          ]);
          setAddressState({
            validatedAddress,
            hasValidationError: false,
            show: true,
          });
        } else {
          setAddressState({ hasValidationError: true, show: true });
        }
      },
      onError: (err) => {
        console.error(err.message);
        setAddressState({ hasValidationError: true, show: true });
      },
    },
  );

  const [createExpense, { loading: createExpenseLoading }] = useMutation(
    CREATE_FLEET_CARD_TRANSACTION,
    {
      fetchPolicy: 'no-cache',
      onError: (err) => {
        console.error(err);
      },
      onCompleted: (data) => {
        if (data) {
          history.push({
            pathname: `/vehicles/${vehicle.id}/expenses`,
            state: {
              message: {
                type: 'success',
                text: 'Transaction was successfully added.',
              },
            },
          });
        }
      },
    },
  );
  const [wexUpdateExpense, { loading: wexUpdateExpenseLoading }] = useMutation(
    UPDATE_FLEET_CARD_TRANSACTION_WEX,
    {
      fetchPolicy: 'no-cache',
      onError: (err) => {
        console.error(err);
      },
      onCompleted: (data) => {
        if (data) {
          history.push({
            pathname: `/vehicles/${vehicle.id}/expenses`,
            state: {
              message: {
                type: 'success',
                text: 'Transaction was successfully updated.',
              },
            },
          });
        }
      },
    },
  );
  const [updateExpense, { loading: updateExpenseLoading }] = useMutation(
    UPDATE_FLEET_CARD_TRANSACTION,
    {
      fetchPolicy: 'no-cache',
      onError: (err) => {
        console.error(err);
      },
      onCompleted: (data) => {
        if (data) {
          history.push({
            pathname: `/vehicles/${vehicle.id}/expenses`,
            state: {
              message: {
                type: 'success',
                text: 'Transaction was successfully updated.',
              },
            },
          });
        }
      },
    },
  );

  return {
    query: {
      getVehicleDetail,
      validateAddress,
      getProductOptions,
      getUnitOfMeasureOptions,
    },
    mutation: { createExpense, updateExpense, wexUpdateExpense },
    loading: {
      vehicleDetailLoading,
      validateAddressLoading,
      wexUpdateExpenseLoading,
      updateExpenseLoading,
      getProductOptionsLoading,
      createExpenseLoading,
      execIsOrgOptInLoading,
    },
  };
};

export default useDataController;
