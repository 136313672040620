import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useReducer } from 'react';
import { useHistory } from 'react-router-dom';
import { CREATE_LICENSE_PLATE_ORDERS } from './schema/create-orders-gql';

export const CreateOrderContext = createContext();

export const createOrdersList = (state) =>
  [
    ...state.ordersList1,
    ...state.ordersList2,
    ...state.ordersList3,
    ...state.ordersList4,
  ]
    .filter((order) => order.quantity && order.quantity !== '0')
    .map((order) => {
      return { class: order.class, quantity: order.quantity.toString() };
    });

export const initialState = {
  ordersList1: [
    { class: 'G10', quantity: 0 },
    { class: 'G12', quantity: 0 },
    { class: 'G31', quantity: 0 },
  ],
  ordersList2: [
    { class: 'G32', quantity: 0 },
    { class: 'G41', quantity: 0 },
    { class: 'G42', quantity: 0 },
  ],

  ordersList3: [
    { class: 'G61', quantity: 0 },
    { class: 'G62', quantity: 0 },
    { class: 'G71', quantity: 0 },
  ],

  ordersList4: [
    { class: 'G81', quantity: 0 },
    { class: 'G90', quantity: 0 },
    { class: 'G91', quantity: 0 },
  ],

  alertMessage: { context: '', message: '', type: '', header: null },
  modalState: {
    currentModalName: null,
    currentMode: null,
    currentModalAlert: { type: null, message: null, header: null },
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_ORDERS_LIST1':
      return { ...state, ordersList1: action.payload };
    case 'SET_ORDERS_LIST2':
      return { ...state, ordersList2: action.payload };
    case 'SET_ORDERS_LIST3':
      return { ...state, ordersList3: action.payload };
    case 'SET_ORDERS_LIST4':
      return { ...state, ordersList4: action.payload };
    case 'SET_MODAL':
      return {
        ...state,
        modalState: { ...state.modalState, ...action.payload },
      };
    case 'SET_ALERT_MESSAGE':
      return { ...state, alertMessage: action.payload };

    default:
      throw new Error('Invalid action');
  }
};

function CreateOrdersProvider({ children, ...props }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const history = useHistory();
  const dispatchAction = (type, payload) => {
    dispatch({
      type,
      payload,
    });
  };
  const resetCurrentModal = () => {
    dispatchAction('SET_MODAL', initialState.modalState);
  };

  const setRequestError = (requestError, context) => {
    dispatchAction('SET_ALERT_MESSAGE', {
      type: 'error',
      message: requestError?.message,
      error: requestError,
      context,
    });
  };

  const [createLicensePlate] = useMutation(CREATE_LICENSE_PLATE_ORDERS, {
    fetchPolicy: 'no-cache',

    context: {
      clientName: 'store',
    },
    onError: () => {
      // To do :according to the ticket 154506 we are only show error message 'Order cannot be submitted at this time.' for MVP we may need the show detail error function below after MVP
      // setRequestError(error);
      resetCurrentModal();
      dispatchAction('SET_ALERT_MESSAGE', {
        type: 'error',
        message: `Order cannot be submitted at this time.`,

        location: 'header',
      });
    },
    onCompleted: () => {
      resetCurrentModal();
      history.push({
        pathname: '/orders',
      });
    },
  });

  const saveAllOrders = () => {
    const createInput = createOrdersList(state);

    createLicensePlate({
      variables: { createInput },
    });
  };

  return (
    <CreateOrderContext.Provider
      value={{
        ...state,
        dispatchAction,
        setRequestError,
        saveAllOrders,
        resetCurrentModal,
        ...props,
      }}
    >
      {children}
    </CreateOrderContext.Provider>
  );
}

export default CreateOrdersProvider;

CreateOrdersProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export const useCreateOrders = () => useContext(CreateOrderContext);
