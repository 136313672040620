const typeaheadOpts = {
  type: 'typeahead',
  placeholder: 'All',
  value: '',
  permanent: false,
  operator: '$like',
};

const orders = {
  orderNumber: {
    title: 'Order number',
    key: 'orderNumber',
    type: 'text',
    permanent: false,
    operator: '$startsWith',
    hideClear: false,
    position: 'top',
    label: 'Search by order number',
  },
  orderStatus: {
    title: 'Order status',
    key: 'tagStatus',
    type: 'multiselect',
    value: [],
    options: [
      { value: ['SHIP', 'SH'], label: 'Shipped' },
      { value: ['PRECV'], label: 'Partially received' },
      { value: ['RECV'], label: 'Received' },
      { value: ['MISS'], label: 'Missing' },
    ],
    permanent: false,
    operator: '$in',
    hideClear: true,
  },
  orderPoC: {
    title: 'Point of contact',
    key: 'pocName',
    ariaLabel: 'Search Point of Contact',
    ...typeaheadOpts,
  },
};

export default orders;
