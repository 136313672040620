import PropTypes from 'prop-types';
import React, { useState, useEffect, createContext, useContext } from 'react';

export const AttachmentComponentContext = createContext();

export default function ComponentContext({
  children,
  title,
  data,
  count,
  hasMore,
  uploadPath,
  loading,
  adding,
  added,
  updating,
  updated,
  deleting,
  deleted,
  linkedEntities,
  loadMoreLimit,
  paginationLimit,
  currentUser,
  errors,
  fileTypes,
  maxFileSize,
  onSort,
  onDelete,
  onDownload,
  onLoadMore,
  onUpdateDescription,
  onAdd,
}) {
  /**
   * Error state
   */
  const [attachmentErrors, setAttachmentErrors] = useState(errors);

  useEffect(() => {
    setAttachmentErrors(errors);
  }, [errors]);

  /**
   * Add state
   */
  const [addState, setAddState] = useState({
    show: false,
    adding: false,
    added: false,
  });

  useEffect(() => {
    setAddState((prev) => ({ ...prev, adding, added }));
  }, [adding, added]);

  /**
   * Edit state
   */
  const [editState, setEditState] = useState({
    data: {},
    show: false,
  });
  useEffect(() => {
    if (updated) setEditState({ show: false, data: {} });
  }, [updated]);
  const resetEditState = () => {
    setEditState({ show: false, data: {} });
    setAttachmentErrors((prev) => ({ ...prev, update: {} }));
  };

  /**
   * Delete State
   */
  const [deleteState, setDeleteState] = useState({
    data: {},
    show: false,
  });
  useEffect(() => {
    if (deleted) setDeleteState({ show: false, data: {} });
  }, [deleted]);
  const resetDeleteState = () => {
    setDeleteState({ show: false, data: {} });
    setAttachmentErrors((prev) => ({ ...prev, delete: {} }));
  };

  return (
    <AttachmentComponentContext.Provider
      value={{
        rows: data,
        count,
        hasMore,
        title,
        uploadPath,
        loading,
        updating,
        updated,
        deleting,
        deleted,
        linkedEntities,
        loadMoreLimit,
        paginationLimit,
        currentUser,
        fileTypes,
        maxFileSize,
        attachmentErrors,
        editState,
        deleteState,
        addState,
        setAddState,
        setEditState,
        setDeleteState,
        resetEditState,
        resetDeleteState,
        setAttachmentErrors,
        onSort,
        onDelete,
        onDownload,
        onLoadMore,
        onUpdateDescription,
        onAdd,
      }}
    >
      {children}
    </AttachmentComponentContext.Provider>
  );
}

ComponentContext.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.arrayOf(Object).isRequired,
  count: PropTypes.number.isRequired,
  hasMore: PropTypes.bool.isRequired,
  title: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.string,
  ]).isRequired,
  uploadPath: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  adding: PropTypes.bool.isRequired,
  added: PropTypes.bool.isRequired,
  updating: PropTypes.bool.isRequired,
  updated: PropTypes.bool.isRequired,
  deleting: PropTypes.bool.isRequired,
  deleted: PropTypes.bool.isRequired,
  linkedEntities: PropTypes.shape(Object).isRequired,
  loadMoreLimit: PropTypes.number.isRequired,
  paginationLimit: PropTypes.number.isRequired,
  currentUser: PropTypes.shape({
    email: PropTypes.string,
  }).isRequired,
  errors: PropTypes.shape({
    fetch: PropTypes.shape(Object),
    save: PropTypes.shape(Object),
    update: PropTypes.shape(Object),
    delete: PropTypes.shape(Object),
    download: PropTypes.shape(Object),
  }).isRequired,
  fileTypes: PropTypes.string.isRequired,
  maxFileSize: PropTypes.number.isRequired,
  onSort: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired,
  onLoadMore: PropTypes.func.isRequired,
  onUpdateDescription: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
};

// Hook to exposes context value.
export const useAttachmentComponent = () =>
  useContext(AttachmentComponentContext);
