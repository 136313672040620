import React, { createContext, useContext, useReducer } from 'react';

const ExpensePageContext = createContext({});
const useExpensePage = () => useContext(ExpensePageContext);

const initialState = {
  bannerMsg: null,
  expenseCount: 0,
};

const actions = {
  setBannerMsg: 'SET_BANNER_MSG',
  setExpenseCount: 'SET_EXPENSE_COUNT',
};

const expensePageReducer = (state, { action, payload }) => {
  switch (action) {
    case actions.setBannerMsg:
      return { ...state, bannerMsg: payload };
    case actions.setExpenseCount:
      return { ...state, expenseCount: payload };
    default:
      return {
        ...state,
        bannerMsg: { type: 'error', message: 'Unknown page action' },
      };
  }
};

const ExpensePageProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    expensePageReducer,
    initialState,
    () => initialState,
  );

  const setBannerMsg = (payload) =>
    dispatch({ action: actions.setBannerMsg, payload });

  const setExpenseCount = (payload) =>
    dispatch({ action: actions.setExpenseCount, payload });

  return (
    <ExpensePageContext.Provider
      value={{
        ...state,
        setBannerMsg,
        setExpenseCount,
      }}
    >
      {children}
    </ExpensePageContext.Provider>
  );
};

export { ExpensePageProvider as default, useExpensePage };
