import { useMutation } from '@apollo/client';
import { UPDATE_VEHICLE_FIELDS } from '../../../services/data-layer';

export default function updateVehicleFields(vin) {
  const [updateVehicleField, { data: updatedFields, error, loading }] =
    useMutation(UPDATE_VEHICLE_FIELDS, {
      fetchPolicy: 'no-cache',
    });

  const updateFields = (fields) => {
    updateVehicleField({ variables: { vin, fields } });
  };

  return [updateFields, updatedFields, loading, error];
}
