import React from 'react';

export const InventoryMgmtGuideText = () => {
  return (
    <p>
      For more information, please see the{' '}
      <a
        href={`${process.env.PUBLIC_URL}/Vehicle-Inventory-Management-User-Guide-Oct2022.pdf`}
        className="mobile:display-block"
        data-testid="gsa-vms--inventory-mgmt-user-guide"
        target="_blank"
        rel="noopener noreferrer"
      >
        Vehicle Inventory Management User Guide.
      </a>
    </p>
  );
};
