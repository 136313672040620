import React from 'react';
import { useVehicle } from 'components/vehicle-details/vehicle-context-provider';
import { CardList, Card } from '@gsa/afp-component-library';
import moment from 'moment';
import { emdash } from 'components/common';
import PmStatusBadge from 'components/pm-express/pm-status-badge';
import { getAverageMonthlyMiles } from '../vehicle-specifications/components/helpers';
import PmReport from './pm-report';
import EditPmSchedule from './edit-pm-schedule';
import { getPmDistanceByLocation } from './helper';

const CARDS = [
  {
    title: 'Next PM due',
    width: 6,
    pmStatusValue: ({ vehicle }) =>
      vehicle?.assetPreventativeMaintenanceSummary?.pmStatus,
    primaryValue: ({ vehicle }) => {
      return (
        <>
          <span>
            {vehicle?.assetPreventativeMaintenanceSummary?.nextPmDate
              ? `${moment
                  .utc(vehicle?.assetPreventativeMaintenanceSummary?.nextPmDate)
                  .format('MM/DD/YYYY')}`
              : null}
          </span>
          <span>
            {vehicle?.assetPreventativeMaintenanceSummary?.nextPmMileage &&
            vehicle?.assetPreventativeMaintenanceSummary?.nextPmDate
              ? ' or '
              : ''}
          </span>
          <span>
            {vehicle?.assetPreventativeMaintenanceSummary?.nextPmMileage
              ? `${vehicle?.assetPreventativeMaintenanceSummary?.nextPmMileage?.toLocaleString()} (mi/km)`
              : emdash}
          </span>
        </>
      );
    },
    footer: ({ vehicle }) => (
      <div className="grid-row margin-y-5" data-testid="pm-actions">
        <div className="grid-col-12">
          <PmReport
            pmStatus={vehicle?.assetPreventativeMaintenanceSummary?.pmStatus}
          />
        </div>
      </div>
    ),
  },
  {
    title: 'Inspection schedule',
    width: 6,
    primaryValue: ({ vehicle }) => {
      const { pmMonthsInterval, pmMilesInterval, pmKilometersInterval } =
        vehicle?.assetTechnical?.pmSchedule || {};
      return (
        <>
          <span>{pmMonthsInterval ? `${pmMonthsInterval} months ` : null}</span>
          <span>
            {pmMonthsInterval && (pmMilesInterval || pmKilometersInterval)
              ? 'or '
              : null}
          </span>
          <span>
            {pmMilesInterval || pmKilometersInterval
              ? getPmDistanceByLocation(
                  vehicle?.fmcId,
                  pmMilesInterval,
                  pmKilometersInterval,
                )
              : null}
          </span>
        </>
      );
    },
    secondaryValue: ({ vehicle }) => {
      const { pmScheduleNumber, pmTitle } =
        vehicle?.assetTechnical?.pmSchedule || {};
      return (
        <span className="title-m display-block padding-top-1">
          {pmScheduleNumber === pmTitle
            ? pmScheduleNumber
            : `${pmScheduleNumber} - ${pmTitle}`}
        </span>
      );
    },
    footer: () => (
      <div className="grid-row margin-y-5" data-testid="edit-pm-schedule">
        <div className="grid-col-12">
          <EditPmSchedule />
        </div>
      </div>
    ),
  },
  {
    title: 'Vehicle odometer',
    width: 6,
    primaryValue: ({ vehicle }) => {
      const odometer =
        vehicle?.newOdometerReading?.odometer?.toLocaleString() ||
        vehicle?.assetAcquisition?.deliveredMiles?.toLocaleString();
      return odometer ? `${odometer} (mi/km)` : emdash;
    },
    secondaryValue: ({ vehicle }) => {
      // eslint-disable-next-line no-nested-ternary
      const mileageDate = vehicle?.newOdometerReading?.mileageDate
        ? moment(vehicle?.newOdometerReading?.mileageDate).format('MM/YYYY')
        : vehicle?.assetAcquisition?.originalAcquisitionDate
        ? moment(vehicle?.assetAcquisition?.originalAcquisitionDate).format(
            'MM/YYYY',
          )
        : emdash;
      return <span>{`as of ${mileageDate}`}</span>;
    },
  },
  {
    title: 'Average monthly mileage',
    width: 6,
    primaryValue: ({ vehicle }) => {
      const averageMonthlyMiles = getAverageMonthlyMiles(
        vehicle?.assetAcquisition,
        vehicle?.newOdometerReading,
      )?.toLocaleString();
      return averageMonthlyMiles
        ? `${averageMonthlyMiles.toLocaleString()} (mi/km)`
        : emdash;
    },
  },
];

const PmCards = () => {
  const { vehicle } = useVehicle();

  return (
    <div>
      <CardList.CardList>
        {CARDS.map((card) => (
          <>
            <Card.Card className={`tablet:grid-col-${card.width}`}>
              <Card.CardHeader className="bg-primary-lightest title-xs">
                {card.title}{' '}
                {card.pmStatusValue && (
                  <span className="margin-left-2">
                    <PmStatusBadge status={card.pmStatusValue({ vehicle })} />
                  </span>
                )}
              </Card.CardHeader>
              <Card.CardBody className="bg-primary-lightest">
                <span className="title-m">
                  {card.primaryValue({ vehicle })}
                </span>
                {card?.secondaryValue && (
                  <>
                    <br />
                    {card.secondaryValue({ vehicle })}
                  </>
                )}
                {card?.footer && <>{card.footer({ vehicle })}</>}
              </Card.CardBody>
            </Card.Card>
          </>
        ))}
      </CardList.CardList>
    </div>
  );
};

export default PmCards;
