/* eslint-disable react/prop-types */
/* eslint-disable filenames/match-exported */

import React from 'react';

const Row = ({
  children,
  className = 'grid-row grid-gap unset-max-width display-flex flex-row',
}) => {
  return <div className={className}>{children}</div>;
};

export default Row;
