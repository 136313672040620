import React from 'react';
import { DetailsTable } from '@gsa/afp-component-library';
import {
  SectionBlock,
  emdash,
  formatShortDate,
  displayModel,
  displayVehicleGVWR,
} from '../../common';
import Actions from './actions';
import { PageMode } from '../providers';
import './general-info.css';
import phoneNumberFormatter from '../../../utilities/phoneNumberFormatter';
import Totals from './totals';
import VehicleStatus from './vehicle-status-badge';
import ServiceLines from './service-lines';
import RepairComments from './repair-comments';

const GeneralInfoView = ({ data }) => {
  const {
    transactionDetails,
    vehicle,
    repairOrder,
    vendorInfo,
    agencyInfo,
    paymentInfo,
  } = data;

  const buildVendorAddress = (vendorInfo) => {
    if (!vendorInfo) {
      return '';
    }
    const {
      vendorAddress1,
      vendorAddress2,
      vendorCity,
      vendorState,
      vendorZipcode,
    } = vendorInfo;
    return `
    ${vendorAddress1 || ''} 
    ${vendorAddress2 || ''}
    ${(vendorAddress1 || vendorAddress2) && vendorCity ? ', ' : ''}
    ${vendorCity || ''}
    ${vendorCity && vendorState ? ', ' : ''}
    ${vendorState || ''}
    ${vendorZipcode || ''}`;
  };

  const transactionCompleteText = (status) => {
    return status;
  };

  const getTransactionDetails = () => {
    return [
      [
        'Transaction date',
        transactionDetails?.date
          ? formatShortDate(transactionDetails?.date)
          : emdash,
      ],
      ['Invoice number', transactionDetails?.invoiceNumber || emdash],
      [
        'Transaction complete',
        transactionCompleteText(transactionDetails?.transactionCompleted) ||
          emdash,
      ],
      [
        'Vehicle mileage at repair',
        transactionDetails?.mileageAtRepair?.toLocaleString() || emdash,
      ],
    ];
  };

  const getVehicleInfo = () => {
    return [
      ['VIN', vehicle?.vin || emdash],
      ['License plate', vehicle?.tagNumber || emdash],
      ['Year', vehicle?.modelYear || emdash],
      ['Make', vehicle?.vehicleMake?.makeName || emdash],
      ['Model', displayModel(vehicle) || emdash],
      [
        'Current mileage',
        vehicle?.newOdometerReading?.odometer?.toLocaleString() || emdash,
      ],
      ['GVWR', displayVehicleGVWR(vehicle) || emdash],
      [
        'Vehicle status',
        <VehicleStatus status={vehicle?.inventoryStatus?.id} />,
      ],
    ];
  };
  const getRepairOrderInfo = () => {
    return [
      ['Repair order number', repairOrder?.repairOrderNumber || emdash],
      [
        'Repair date',
        repairOrder?.repairDate
          ? formatShortDate(repairOrder?.repairDate)
          : emdash,
      ],
    ];
  };
  const getAgencyInfo = () => {
    return [
      ['Agency', agencyInfo?.agencyName || emdash],
      ['Bureau', agencyInfo?.bureauName || emdash],
      ['Office', agencyInfo?.officeName || emdash],
    ];
  };
  const getPaymentInfo = () => {
    return [
      ['Billed to', paymentInfo?.billedTo || emdash],
      ['Payment status', paymentInfo?.paymentStatus || emdash],
      ['Form of payment', paymentInfo?.paymentForm || emdash],
      [
        'Posted date',
        paymentInfo?.postedDate
          ? formatShortDate(paymentInfo?.postedDate)
          : emdash,
      ],
    ];
  };
  const getVendorInfo = () => {
    let phone = vendorInfo?.vendorPhone
      ? phoneNumberFormatter(vendorInfo?.vendorPhone)
      : emdash;
    if (vendorInfo?.vendorPhone && vendorInfo?.vendorPhoneExt)
      phone = phone.concat(' ext ', vendorInfo?.vendorPhoneExt);
    return [
      ['Vendor description', vendorInfo?.vendorDescription || emdash],
      ['Vendor name', vendorInfo?.vendorName || emdash],
      ['Vendor phone', phone],
      ['Vendor address', buildVendorAddress(vendorInfo) || emdash],
    ];
  };

  return (
    <div className="grid-row grid-gap">
      <div className="grid-col-6">
        <SectionBlock title="Transaction details">
          <DetailsTable
            className="repair-order-details-table"
            bordered
            wrapText
            data={getTransactionDetails()}
          />
        </SectionBlock>
        <SectionBlock title="Repair order">
          <DetailsTable
            className="repair-order-details-table"
            bordered
            wrapText
            data={getRepairOrderInfo()}
          />
        </SectionBlock>
        <SectionBlock title="Vendor information">
          <DetailsTable
            className="repair-order-details-table"
            bordered
            wrapText
            data={getVendorInfo()}
          />
        </SectionBlock>
      </div>
      <div className="grid-col-6">
        <SectionBlock title="Vehicle">
          <DetailsTable
            className="repair-order-details-table"
            bordered
            wrapText
            data={getVehicleInfo()}
          />
        </SectionBlock>
        <SectionBlock title="Agency">
          <DetailsTable
            className="repair-order-details-table"
            bordered
            wrapText
            data={getAgencyInfo()}
          />
        </SectionBlock>
        <SectionBlock title="Payment information">
          <DetailsTable
            className="repair-order-details-table"
            bordered
            wrapText
            data={getPaymentInfo()}
          />
        </SectionBlock>
      </div>
    </div>
  );
};

const RepairView = ({ data }) => {
  const mode = PageMode.View;
  const { repairOrder = {} } = data;
  const { repairOrderNumber } = repairOrder;
  return (
    <>
      <section
        className="grid-col-12 margin-y-3"
        data-testid="repair-order-view-top-actions"
      >
        <Actions mode={mode} />
      </section>
      <section
        className="grid-col-12"
        data-testid="repair-order-view-generalInfo"
      >
        <GeneralInfoView data={data} />
      </section>
      <section
        className="grid-col-12"
        data-testid="repair-order-view-servicelines"
      >
        <ServiceLines mode={mode} repairNumber={repairOrderNumber} />
      </section>
      <section className="grid-col-12">
        <div className="grid-row grid-gap-6">
          <div className="grid-col-6" data-testid="repair-order-view-totals">
            <Totals mode={mode} />
          </div>
          <div className="grid-col-6" data-testid="repair-order-view-comments">
            <RepairComments mode={mode} />
          </div>
        </div>
      </section>
      <section
        className="grid-col-12 padding-top-4 margin-y-2 border-top-2px border-gray-20"
        data-testid="repair-order-view-bottom-actions"
      >
        <Actions mode={mode} />
      </section>
    </>
  );
};

export default RepairView;
