/* eslint-disable react/prop-types */
/* eslint-disable filenames/match-exported */

import React from 'react';
import SectionGenerator from './SectionGenerator';
import {
  createFieldSelector,
  createFieldAfterSelector,
} from '../../utilities/recoil/store';

const SectionsComponent = ({
  pageId,
  sections,
  atoms,
  atomStateSchema,
  selectors = undefined,
  customizedWidgets,
}) => {
  return sections.map((sectionProps) => (
    <div key={`${sectionProps.name}`}>
      {sectionProps.isVisible && (
        <SectionGenerator
          pageId={pageId}
          customizedWidgets={customizedWidgets}
          selectors={
            selectors ?? {
              fieldSelector: createFieldSelector({ atoms }),
              fieldAfterSelector: createFieldAfterSelector({
                atoms,
                atomStateSchema,
              }),
              createFieldAfterSelector,
            }
          }
          {...sectionProps}
        />
      )}
    </div>
  ));
};

export default SectionsComponent;
