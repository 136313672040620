/* eslint-disable react/prop-types */
import React from 'react';
import { Button, Spinner } from '@gsa/afp-component-library';
import classnames from 'classnames';
import { emdash } from 'components/common';
import moment from 'moment';
import { GET_AWS_SIGNED_READ_URL } from 'components/attachment/helpers';
import { useLazyQuery } from '@apollo/client';
import fileDownload from 'js-file-download';

const formatDate = (date) => moment(date).format('MM/DD/YYYY');

const calculatePmDueDate = (currentPmDate, currentPmInterval) => {
  if (!currentPmDate || !currentPmInterval) {
    return undefined;
  }
  return moment(currentPmDate)
    .add('months', currentPmInterval)
    .format('MM/DD/YYYY');
};

export const PmRowDetails = ({ detail }) => {
  const {
    customer,
    methodOfEntry,
    createdByUser,
    createdAt,
    updatedAt,
    invoiceData,
    pmSchedule,
  } = detail;
  const borderClasses = classnames(
    'border-bottom border-base-lighter padding-y-1',
  );

  const [downloadSignedReadURL, { loading: signedReadUrlLoading }] =
    useLazyQuery(GET_AWS_SIGNED_READ_URL, {
      onError: (error) => {
        console.log(error);
      },
      onCompleted: async (readURLData) => {
        if (readURLData?.generateReadSignedURL) {
          const response = await fetch(readURLData.generateReadSignedURL, {
            method: 'GET',
            headers: { 'Content-Type': invoiceData?.fileType },
          });
          const blob = await response.blob();
          fileDownload(blob, invoiceData?.name);
        }
      },
      fetchPolicy: 'no-cache',
    });

  const handleDownloadInvoice = () => {
    if (invoiceData?.fileLocation && invoiceData?.fileType) {
      downloadSignedReadURL({
        variables: {
          fileKey: invoiceData?.fileLocation,
          fileType: invoiceData?.fileType,
        },
      });
    }
  };

  return (
    <div className="grid-row grid-gap flex-justify">
      <div className="tablet:grid-col-6">
        <div
          className={`${borderClasses} display-flex flex-justify`}
          data-testid="inspection-schedule-section"
        >
          <strong>Inspection schedule</strong>
          {pmSchedule?.pmTitle || emdash}
        </div>

        <div
          className={`${borderClasses} display-flex flex-justify`}
          data-testid="customer-number-section"
        >
          <strong>Legacy customer number</strong>
          {customer?.legacyCustomerNumber || emdash}
        </div>
        <div
          className={`${borderClasses} display-flex flex-justify`}
          data-testid="invoice-section"
        >
          <strong>Invoice</strong>
          {invoiceData?.name ? (
            <Button
              onClick={handleDownloadInvoice}
              size={2}
              className="usa-button--unstyled"
              label={signedReadUrlLoading ? <Spinner /> : 'Download invoice'}
            />
          ) : (
            emdash
          )}
        </div>
      </div>
      <div className="tablet:grid-col-6">
        <div
          className={`${borderClasses} display-flex flex-justify`}
          data-testid="method-of-entry-section"
        >
          <strong>Method of entry</strong>
          {methodOfEntry || emdash}
        </div>
        <div
          className={`${borderClasses} display-flex flex-justify`}
          data-testid="user-section"
        >
          <strong>User</strong>
          {createdByUser?.fullName || emdash}
        </div>
        <div
          className={`${borderClasses} display-flex flex-justify`}
          data-testid="date-of-entry-section"
        >
          <strong>Date of entry</strong>
          {formatDate(updatedAt) || formatDate(createdAt) || emdash}
        </div>
      </div>
    </div>
  );
};
