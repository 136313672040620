import React from 'react';
import PropTypes from 'prop-types';
import { FilterPanel } from '@gsa/afp-component-library';
import CCN4 from 'components/filters/widgets/ccn-4';

const CCN4Filter = ({ filter }) => (
  <FilterPanel.FilterPanelField
    key={[filter.key]}
    filter={filter}
    component={CCN4}
  />
);

CCN4Filter.propTypes = {
  filter: PropTypes.shape(FilterPanel.FilterPanelFilterShape).isRequired,
};

export default CCN4Filter;
