import React, { useEffect, useRef, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { FormGenerator } from '@gsa/afp-shared-form-utils';
import { SelectDropdown, Typeahead } from '@gsa/afp-component-library';
import {
  GET_FILTERED_VENDORS_TYPE_AHEAD_OPTIONS,
  GET_FLEET_MANAGEMENT_CENTERS,
} from 'services/data-layer/fed-hierarchy.gql';
import { z } from 'zod';
import { find } from 'lodash';

const schema = z
  .object({
    locationType: z.any(),
    FMC: z.any(),
    VENDOR: z.any(),
  })
  .superRefine((val, ctx) => {
    const { locationType, FMC, VENDOR } = val;
    if (locationType === 'FMC' && !FMC) {
      ctx.addIssue({
        code: 'custom',
        path: ['FMC'],
        fatal: true,
        message: 'FMC selection is required',
      });
    }
    if (locationType === 'VENDOR' && !VENDOR) {
      ctx.addIssue({
        code: 'custom',
        path: ['VENDOR'],
        fatal: true,
        message: 'Vendor name is required',
      });
    }
    if (locationType === '-Select-') {
      ctx.addIssue({
        code: 'custom',
        path: ['locationType'],
        fatal: true,
        message: 'Location type selection is required',
      });
    }
  });

const VehicleLocationEditForm = ({
  onSave,
  vehicle,
}) => {
  const vehicleLocationRef = useRef(null);
  const [typeaheads, setTypeaheads] = useState([]);
  const [formData, setFormData] = useState({
    entityId: vehicle?.assetCustodian?.entityId || null,
    entityType: vehicle?.assetCustodian?.entityType || null,
  });
  const [fmcOptions, setFmcOptions] = useState([
    { value: '', label: '-Select-' },
  ]);
  const [vendorOptions, setVendorOptions] = useState([]);

  const [getFilteredVendors] = useLazyQuery(
    GET_FILTERED_VENDORS_TYPE_AHEAD_OPTIONS,
    {
      fetchPolicy: 'network-only', // Used for first execution
      onCompleted: (vendors) => {
        if (vendors?.getVendorTypeAheadOptions) {
          const { options } = vendors?.getVendorTypeAheadOptions;
          setVendorOptions(options);
          setTypeaheads(options.map(({ vendorName }) => vendorName));
        }
      },
    },
  );

  const [getManagementCenters] = useLazyQuery(GET_FLEET_MANAGEMENT_CENTERS, {
    fetchPolicy: 'network-only',
    onCompleted: (managementCenters) => {
      if (managementCenters.getFleetManagementCenters) {
        setFmcOptions([
          ...fmcOptions,
          ...managementCenters.getFleetManagementCenters.map((center) => ({
            value: center.id,
            label: center.name,
          })),
        ]);
      }
    },
  });

  const fetchFilteredVendors = (_, name) => {
    if (name) {
      getFilteredVendors({
        variables: {
          key: 'id',
          search: name,
        },
      });
    }
  };

  useEffect(() => {
    if (!vehicleLocationRef.current) return () => {};
    const subscription = vehicleLocationRef.current.watch((data) => {
      setFormData({
        ...formData,
        ...data,
      });
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [vehicleLocationRef.current]);

  const formContent = {
    buttonControls: {
      submit: false,
      cancel: false,
    },
    sections: [
      {
        fields: [
          {
            id: 'locationType',
            label: 'Location type',
            type: 'select',
            required: true,
            options: [
              { value: '', label: '-Select-' },
              { value: 'FMC', label: 'FMC' },
              { value: 'VENDOR', label: 'Vendor name' },
            ],
          },
          {
            id: 'FMC',
            label: 'FMC',
            dependentOn: 'locationType',
            dependentValue: 'FMC',
            dependentWaitType: 'hidden',
            required: true,
            component: (_config, useFormContext) => {
              const { setValue } = useFormContext();
              useEffect(() => {
                getManagementCenters();
              }, []);
              const handleFMCChange = (e) => {
                setValue('FMC', e.target.value);
              };
              return (
                <SelectDropdown
                  data-testid="fmc"
                  name="fmc"
                  options={fmcOptions}
                  onChange={handleFMCChange}
                />
              );
            },
          },
          {
            id: 'VENDOR',
            label: 'Vendor name',
            required: true,
            dependentOn: 'locationType',
            dependentValue: 'VENDOR',
            dependentWaitType: 'hidden',
            component: (_config, useFormContext) => {
              const { setValue } = useFormContext();
              const handleVendorNameChange = (value) => {
                const vendor = find(vendorOptions, { vendorName: value });
                setValue('VENDOR', vendor.id);
              };
              return (
                <>
                  <Typeahead
                    id="vendorName"
                    name="vendorName"
                    data-testid="vendorName"
                    filterValue=""
                    typeaheadValues={typeaheads}
                    fetchTypeaheadValues={fetchFilteredVendors}
                    promptText="Minimum of 3 characters required."
                    placeholder="Search by vendor name"
                    inputCharNum={3}
                    ariaLabel="Search by vendor name"
                    debounceDelay={500}
                    noResultsText="No vendors found for the given search."
                    onOptionEnter={(value) => {
                      handleVendorNameChange(value);
                    }}
                    onClear={() => {
                      setValue('vendorName', '');
                    }}
                  />
                </>
              );
            },
          },
        ],
      },
    ],
  };

  const submit = () => {
    const entityType = formData.locationType;
    const entityId = formData[entityType];

    onSave(entityType, entityId);
  };
  return (
    <FormGenerator
      ref={vehicleLocationRef}
      id="vehicleLocationEditForm"
      data-testid="vehicleLocationEditForm"
      schema={schema}
      content={formContent}
      onSubmit={submit}
      useFormProps={{
        mode: 'onBlur',
        reValidateMode: 'onBlur',
      }}
    />
  );
};

export default VehicleLocationEditForm;
