import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

export const LicensePlateDestructionContext = createContext(null);

export default function Context({ children, ...props }) {
  return (
    <LicensePlateDestructionContext.Provider value={{ ...props }}>
      {children}
    </LicensePlateDestructionContext.Provider>
  );
}

Context.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useLicensePlateDestruction = () =>
  useContext(LicensePlateDestructionContext);
