import React, { useState } from 'react';
import {
  Button,
  SelectDropdown,
  TextInput,
  Chip,
} from '@gsa/afp-component-library';
import { useOfficeManagement } from '../office-management-provider';
import { filterOptionMap, getStateById } from '../utils';

const FilterForm = ({ currentFiltersStatus, setCurrentFiltersState }) => {
  const [currentFilterType, setCurrentFilterType] = useState('default');
  const [currentFilterValue, setCurrentFilterValue] = useState(null);

  const { statesOptions } = useOfficeManagement();

  const getFormattedStateOptions = () => {
    if (!statesOptions || statesOptions.length === 0) {
      return [];
    }
    return statesOptions?.map((item) => {
      return {
        label: item.stateName,
        value: item.id,
      };
    });
  };

  const getFilterOptions = (filterOptionMap) =>
    Object.keys(filterOptionMap).map((key) => ({
      value: key,
      label: filterOptionMap[key].label,
    }));

  const filterOptions = [
    { value: 'default', label: '-Select-' },
    ...getFilterOptions(filterOptionMap),
  ];

  const stateOptions = [
    { value: 'default', label: '-Select-' },
    ...getFormattedStateOptions(),
  ];

  return (
    <header className="padding-3 bg-blue-5 grid-container maxw-desktop">
      <div className="grid-row grid-gap-3 margin-bottom-2">
        <SelectDropdown
          options={filterOptions}
          containerClassName="grid-col-4"
          data-testid="select-customer-account-dropdown"
          onChange={(e) => {
            setCurrentFilterType(e.target.value);
          }}
          value={currentFilterType}
        />
        {currentFilterType !== 'default' && currentFilterType !== 'state' && (
          <TextInput
            containerClassName="grid-col-5"
            data-testid="customer-account-filter-value"
            value={currentFilterValue}
            onChange={(evt) => setCurrentFilterValue(evt.target.value)}
          />
        )}
        {currentFilterType === 'state' && (
          <SelectDropdown
            options={stateOptions}
            containerClassName="grid-col-5"
            data-testid="customer-account-filter-value"
            value={currentFilterValue?.value}
            onChange={(evt) => {
              setCurrentFilterValue({
                value: evt.target.value,
                label: getStateById(
                  evt.target.value,
                  statesOptions,
                  'stateName',
                ),
              });
            }}
          />
        )}
        <Button
          label="View accounts"
          variant="outline"
          data-testid="filter-submit"
          className="margin-top-4"
          onClick={() => {
            if (currentFilterType !== 'default') {
              setCurrentFiltersState((prevFilters) => {
                return {
                  ...prevFilters,
                  [currentFilterType]: currentFilterValue,
                };
              });
              setCurrentFilterValue(null);
              setCurrentFilterType('default');
            }
          }}
        />
      </div>

      <section
        className="padding-2 border border-base-light radius-md bg-white minh-7 display-flex flex-wrap"
        data-testid="filter-chips"
      >
        {Object.keys(currentFiltersStatus).map(
          (filterType) =>
            currentFiltersStatus[filterType] && (
              <section
                key={filterType}
                className="margin-right-1 margin-bottom-1"
              >
                <Chip
                  label={`${filterOptionMap[filterType].label}:${
                    typeof currentFiltersStatus[filterType] === 'object'
                      ? currentFiltersStatus[filterType].label
                      : currentFiltersStatus[filterType]
                  }`}
                  onClose={() => {
                    setCurrentFiltersState((prevFilters) => {
                      const { [filterType]: _, ...rest } = prevFilters;
                      return rest;
                    });
                  }}
                  data-testid="filter-chip"
                />
              </section>
            ),
        )}
      </section>
    </header>
  );
};

export default FilterForm;
