import React, { useState, useEffect } from 'react';
import { Typeahead } from '@gsa/afp-component-library';
import { useLazyQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { FETCH_TYPEAHEAD } from '../../../services/data-layer';

const getTypeaheadQueryVariables = (value) => ({
  model: 'UnicorTag',
  field: 'orderNumber',
  order: 'id ASC',
  filters: [
    {
      operator: '$and',
      conditions: [{ operator: '$startsWith', key: 'orderNumber', value }],
    },
  ],
});

export default function OrderNumberLookup({ orderNumber = '', ariaLabel }) {
  const [getTypeaheads, { data: typeaheadData }] = useLazyQuery(
    FETCH_TYPEAHEAD,
    {
      fetchPolicy: 'no-cache',
    },
  );
  const [orderNumberList, setOrderNumberList] = useState([]);
  const history = useHistory();
  useEffect(() => {
    if (
      typeaheadData &&
      typeaheadData.getTypeaheads &&
      typeaheadData.getTypeaheads.typeaheads
    ) {
      setOrderNumberList(typeaheadData.getTypeaheads.typeaheads.values || []);
    }
  }, [typeaheadData]);

  return (
    <div className="afp-vehicle-registration-wrapper">
      <Typeahead
        label="Search by order number"
        labelClass="text-bold"
        ariaLabel={ariaLabel}
        accessor="orderNumber"
        filterValue={orderNumber}
        placeholder="Search Order Numbers..."
        onOptionEnter={(value) => {
          if (value) history.push(`/order/${value}`);
        }}
        typeaheadValues={orderNumberList}
        fetchTypeaheadValues={(_accessor, value) => {
          getTypeaheads({ variables: getTypeaheadQueryVariables(value) });
        }}
        inputCharNum={3}
        debounceDelay={500}
      />
    </div>
  );
}
