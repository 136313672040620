import { Alert } from '@gsa/afp-component-library';

export const MatchingEmailAlert = () => (
  <Alert
    type="error"
    className="margin-top-4 margin-bottom-2"
    focused
    heading="Point of contact #1 and point of contact #2 cannot be the same"
  >
    <p>
      Please enter the email address of a point of contact (POC) to look up the
      POC information. If there is not an existing record for the email address
      entered, you will be prompted to enter the details for the new POC.
    </p>
  </Alert>
);

export const InvalidEmailAlert = () => (
  <Alert
    type="error"
    className="margin-top-4 margin-bottom-2"
    focused
    heading="E-mail address must be valid"
  >
    <p>
      Please enter the email address of a point of contact (POC) to look up the
      POC information. If there is not an existing record for the email address
      entered, you will be prompted to enter the details for the new POC.
    </p>
  </Alert>
);

export const getEmailAlert = (isMatching, isInvalid) => {
  if (isInvalid) {
    return InvalidEmailAlert();
  } else if (isMatching) {
    return MatchingEmailAlert();
  } else {
    return null;
  }
};

export const InvalidPoCAlert = () => (
  <Alert
    type="error"
    className="margin-top-4 margin-bottom-2"
    focused
    heading="Registration updates could not be saved"
  >
    <p>
      The vehicle must have two unique points of contact with valid e-mail
      addresses. Please update below and try the update again.
    </p>
  </Alert>
);

export const NetworkErrorAlert = () => (
  <Alert
    type="error"
    className="margin-top-4 margin-bottom-2"
    focused
    heading="Updates could not be saved"
  >
    <p>
      Please verify that all required fields have valid values and try again.
    </p>
  </Alert>
);
