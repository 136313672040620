import { useMutation } from '@apollo/client';
import { PROCESS_BULK_ALD } from '../../bulk-import.gql';

const useCDDDataProcessor = (successCallBack, errorCallBack) => {
  const [process, { loading }] = useMutation(PROCESS_BULK_ALD, {
    onCompleted: (data) => {
      successCallBack(data.bulkValidateAld);
    },
    onError: (error) => {
      errorCallBack(error);
    },
  });

  const handleSystemErrors = (batchData) => {
    const data = [];
    batchData.forEach((item) => {
      const error = {
        errorResponse: {
          vin: item.vin,
          validationErrors: {
            errors: [
              {
                message:
                  'System Error. Please try to upload again.  If you continue to experience technical difficulties with this page, please contact the GSA Fleet Technical Support team at fleet.helpdesk@gsa.gov or 866-472-6711 from 8:00 a.m. - 7:00 p.m. ET, Monday-Friday.',
              },
            ],
          },
        },
      };
      data.push(error);
    });
    return data;
  };

  const processDataForFile = (response) => {
    const uploadResponse = [];
    if (response?.length > 0) {
      response.forEach((resp) => {
        if (resp?.vin) {
          uploadResponse.push({
            vin: resp.vin,
            status: 'Success',
            errors: '',
          });
        } else {
          let errorMessage = '';
          resp?.errorResponse?.validationErrors?.errors?.forEach((error) => {
            errorMessage += `${error.message}, `;
          });
          uploadResponse.push({
            vin: resp.errorResponse?.vin,
            status: 'Error',
            errors: errorMessage.substring(0, errorMessage.length - 2),
          });
        }
      });
    }
    return uploadResponse;
  };

  return { process, loading, processDataForFile, handleSystemErrors };
};

export default useCDDDataProcessor;
