import React from 'react';
import { Controller } from 'react-hook-form';
import {
  RadioButton,
  RequiredFieldIndicator,
} from '@gsa/afp-component-library';
import { isFeatureEnabled } from 'utilities/feature-toggle';
import VehicleForeignDomestic from '../vehicle-foreign-domestic';

const previousFY = +window.AFP_CONFIG.previous_fy;
const options = [
  {
    label: 'Previous year',
    description: `Fiscal year October 1, ${
      previousFY - 1
    } - September 30, ${previousFY}`,
    radioValue: 'prev',
  },
  {
    label: 'Future year projections',
    description: `October 1, ${previousFY} - September 30, ${previousFY + 3}`,
    radioValue: 'next',
  },
];

// eslint-disable-next-line react/prop-types
export const FastReportFilters = ({ control, setValue, watch }) => {
  const selectedVehicleOption = watch('vehicle_options');
  const isGFFASTCDDEnabled = isFeatureEnabled('gf-fast');
  return (
    <section className="margin-bottom-6">
      <h2 className="text-primary margin-bottom-1">Date selection</h2>
      <div className="usa-form-group mobile-lg:grid-col-10">
        <span>
          {<b>Choose a date range for the reports</b>}{' '}
          <RequiredFieldIndicator />
        </span>
        <Controller
          control={control}
          name="date_range"
          defaultValue={options[0].radioValue}
          rules={{ required: 'This is a required field' }}
          render={({ field: { onChange, name, value } }) => (
            <>
              {options.map(({ radioValue, label, ...restProps }) => {
                return (
                  <RadioButton
                    name={name}
                    key={radioValue}
                    value={radioValue}
                    checked={radioValue === value}
                    label={<b>{label}</b>}
                    variant="tile"
                    {...restProps}
                    onChange={(e) => {
                      onChange(e.target.value);
                    }}
                  />
                );
              })}
            </>
          )}
        />
        {isGFFASTCDDEnabled ? (
          <section className="margin-bottom-6">
            {selectedVehicleOption === 'GF' ? (
              <VehicleForeignDomestic control={control} setValue={setValue} />
            ) : null}
          </section>
        ) : null}
      </div>
    </section>
  );
};
