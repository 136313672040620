import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TextInput, Button, Spinner } from '@gsa/afp-component-library';
import usePortalModal from '../../../../utilities/portal-modal';
import { validEmail } from '../../../../utilities/validation';
import { useLazyQuery } from '@apollo/client';
import { GET_POC } from '../../../../services/data-layer';
import { EMPTY_POC } from '../../../vehicle-registration/widgets/agency-and-poc/poc/poc';
import { isEmailDifferent } from '../../../registration-details/helpers/utils';
import { getEmailAlert } from '../../../registration-details/widgets/poc-status-alerts';

export default function PocEmailChanger({
  handleCancel,
  handleSelect,
  poc,
  otherPocEmail,
  editingEmail,
  matchingEmail,
  invalidPoc,
}) {
  const [PocModal, openModal, closeModal] = usePortalModal();

  const [newEmail, setNewEmail] = useState('');
  const [errors, setErrors] = useState({});

  const handleCancelModal = () => {
    setNewEmail('');
    handleCancel();
  };

  const handleSave = () => {
    if (newEmail === '') {
      setErrors({ message: 'Email can not be blank' });
      return;
    } else if (!validEmail(newEmail)) {
      setErrors({ message: 'Enter a valid email' });
      return;
    } else if (!isEmailDifferent(newEmail, otherPocEmail)) {
      setErrors({ message: 'Email cannot be the same as the other POC' });
    } else {
      handleEditEmail();
    }
  };

  const [getPoC, { loading, data: returnedPoC }] = useLazyQuery(GET_POC, {
    fetchPolicy: 'network-only',
  });

  const handleEditEmail = () => {
    getPoC({
      variables: {
        id: newEmail,
      },
    });
  };

  useEffect(() => {
    if (returnedPoC?.getPoC || newEmail) {
      const payload = returnedPoC?.getPoC
        ? { ...returnedPoC.getPoC, ind: poc?.ind }
        : { ...EMPTY_POC, email: newEmail, countryCode: 'US', ind: poc?.ind };
      handleSelect(payload);
    }
  }, [returnedPoC]);

  useEffect(() => {
    if (editingEmail) {
      openModal();
    } else {
      setNewEmail('');
      setErrors({});
      closeModal();
    }
  }, [editingEmail]);

  return (
    <PocModal
      title={
        <>
          {getEmailAlert(matchingEmail, invalidPoc)}
          <h1 className="margin-top-neg-2">
            Change to new point of contact #{poc?.ind}
          </h1>
        </>
      }
      onClose={handleCancelModal}
      actions={
        <Button
          variant="unstyled"
          onClick={() => {
            handleCancelModal();
          }}
          label="Cancel"
        />
      }
    >
      <span className="text-bold text-primary">
        POINT OF CONTACT #{poc?.ind}
      </span>
      {loading ? (
        <Spinner />
      ) : (
        <div className="edit-poc grid-row grid-gap">
          <div className="grid-col-6">
            <TextInput
              placeholder={`Email for point of contact #${poc?.ind}`}
              label="Email"
              aria-label={
                errors['message'] ? errors['message'] : 'Enter another email'
              }
              name="email"
              required
              value={newEmail}
              onChange={(e) => {
                setNewEmail(e.target.value);
              }}
              errorMessage={errors['message']}
            />
          </div>
          <div className="grid-col-6">
            <Button
              className="margin-left-2 margin-top-7"
              variant="outline"
              onClick={handleSave}
              label="+ Add contact"
            />
          </div>
        </div>
      )}
    </PocModal>
  );
}

PocEmailChanger.propTypes = {
  ind: PropTypes.number,
  editingEmail: PropTypes.bool,
  onClose: PropTypes.func,
  togglePocEdit: PropTypes.func,
  dispatch: PropTypes.func,
  openEdit: PropTypes.func,
};
PocEmailChanger.defaultProps = {
  ind: 1,
  editingEmail: false,
  onClose: () => {
    // intentional just an FYI SQ
  },
  togglePocEdit: () => {
    // intentional just an FYI SQ
  },
  openEdit: () => {
    // intentional just an FYI SQ
  },
};
