import React from 'react';
import { Can } from '@gsa/afp-shared-ui-utils';
import { Button } from '@gsa/afp-component-library';
import { VMSOperations, VMSSubjects } from 'utilities/consts';
import useCanPerformActions from 'hooks/use-can-perform-actions';
import { usePm } from './pm-provider';

// eslint-disable-next-line react/prop-types
const PmReport = ({ pmStatus }) => {
  const { openManagePmModal } = usePm();
  const canPerformActions = useCanPerformActions();
  return (
    <Can I={VMSOperations.UPDATE_VEHICLE_GF} a={VMSSubjects.VEHICLE}>
      <>
        {canPerformActions.canAddNewPm(pmStatus) && (
          <Button
            label="Report a PM"
            leftIcon={{ name: 'add', class: 'usa-icon--size-2' }}
            variant="primary"
            onClick={() => {
              openManagePmModal();
            }}
            data-testid="report-pm-button"
          />
        )}
      </>
    </Can>
  );
};

export default PmReport;
