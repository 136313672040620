import React from 'react';
import VehicleInformationManual from './vehicle-information-manual';

export default function VehicleInformation() {
  return (
    <main
      data-testid="step1"
      className="afp-vehicle-registration__step afp-vehicle-registration-wrapper"
    >
      <VehicleInformationManual />
    </main>
  );
}
