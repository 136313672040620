import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import Icon from '../icon';
import { useAttachmentComponent } from './component-context';

const AttachmentRowAction = ({ row: { original } }) => {
  const { onDownload, setEditState, setDeleteState } = useAttachmentComponent();

  const [open, setOpen] = useState(false);
  const dropContentRef = useRef(null);

  const dropDownClasses = classnames('dropdown-content', { [`show`]: open });

  const handleClickOutside = () => {
    setOpen(false);
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <div className="dropdown">
      <button
        type="button"
        title="document actions"
        className="usa-button usa-button--outline usa-button dropdown-button"
        onClick={() => setOpen(!open)}
      >
        <span className="usa-sr-only">Document Action</span>
        <span aria-hidden="true">•••</span>
      </button>
      <div className={dropDownClasses} ref={dropContentRef}>
        <button
          type="button"
          className="usa-button usa-button--outline"
          onClick={() => {
            setOpen(false);
            onDownload(original);
          }}
        >
          <span aria-hidden="true" className="dropdown-content-icon">
            <Icon type="download" />
          </span>
          Download
        </button>

        <button
          type="button"
          className="usa-button usa-button--outline"
          onClick={() => {
            setOpen(false);
            setEditState({ data: original, show: true });
          }}
        >
          <span aria-hidden="true" className="dropdown-content-icon">
            <Icon type="edit" />
          </span>
          Edit Description
        </button>

        <button
          data-testid="attachment-delete-button"
          type="button"
          className="usa-button usa-button--outline attachment-delete-link"
          onClick={() => {
            setOpen(false);
            setDeleteState({ data: original, show: true });
          }}
        >
          <span aria-hidden="true" className="dropdown-content-icon">
            <Icon type="delete" />
          </span>
          Delete
        </button>
      </div>
    </div>
  );
};

AttachmentRowAction.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.string,
      description: PropTypes.string,
      name: PropTypes.string,
      contentURL: PropTypes.string,
      type: PropTypes.string,
      lastModified: PropTypes.number,
      size: PropTypes.number,
      createdByUser: PropTypes.string,
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
    }),
  }).isRequired,
};

export default AttachmentRowAction;
