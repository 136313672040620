import React from 'react';
import { Spinner } from '@gsa/afp-component-library';
import { hasValue } from '../../utilities/format';
/* eslint-disable react/prop-types */

const LoadingComponent = ({
  size = 'large',
  className = 'loading_backdrop',
}) => <Spinner aria-busy="true" className={className} size={size} />;

const LoadingQueryResult = ({
  children,
  onLoading,
  loadingObject = null,
  spinnerClassName = null,
  spinnerSize = null,
  loadingIsOff = false,
}) => {
  if (loadingObject) {
    return hasValue(true)(loadingObject) && !loadingIsOff ? (
      <LoadingComponent
        spinnerClassName={spinnerClassName}
        spinnerSize={spinnerSize}
      />
    ) : (
      children
    );
  }
  return onLoading ? (
    <LoadingComponent
      spinnerClassName={spinnerClassName}
      spinnerSize={spinnerSize}
    />
  ) : (
    children
  );
};

export { LoadingQueryResult, LoadingComponent };
