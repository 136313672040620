/* eslint-disable react/prop-types */
import {
  Breadcrumbs,
  PageTitle,
  RequiredFieldIndicator,
} from '@gsa/afp-component-library';
import moment from 'moment';
import { InternalError } from '@gsa/afp-shared-ui-utils';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { utils, writeFile } from 'xlsx';
import { InventoryMgmtGuideText } from 'utilities/user-guide';
import useAlertManager from '../../../../utilities/alert-manager';
import ShowChildrenByCriteria from '../../../../utilities/show-children-by-criteria';
import { ImportForm, ProcessImport } from './components';
import { reportsConfig } from './config';
import { alertMap } from './model/schema/variable-maps';
import { useImport } from './state/hook';

const BulkImport = ({ agencies, internalError }) => {
  const title = 'Import Template for Bulk Updates';

  const [Alert, alertUtils] = useAlertManager(true, true, true);

  const {
    importedData,
    setMappedData,
    importType,
    setProcessedResults,
    processedResponse,
    reset,
  } = useImport();

  const createErrorFile = async () => {
    const reportConfig = reportsConfig[importType.type];
    const fileName = `${reportConfig.errorFileName}_${moment()
      .utcOffset('-06:00')
      .format('MMDDYYYY_HHMMSS')}.xlsx`;
    const worksheet = utils.json_to_sheet(processedResponse);
    worksheet['!cols'] = reportConfig.errorSheetColumnWidths;
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, reportConfig.errorSheetName);
    utils.sheet_add_aoa(worksheet, [reportConfig.errorSheetHeaders]);
    writeFile(workbook, fileName);
  };

  useEffect(() => {
    if (processedResponse?.length > 0) {
      setMappedData([]);
      setProcessedResults([]);
      if (
        processedResponse?.some(
          (resp) => resp?.status === 'Error' || resp?.status === 'Warning',
        )
      ) {
        const formatRecordCount = (count, type) =>
          `${count} ${count > 1 ? 'records' : 'record'}${type}`;
        const errorCount =
          processedResponse?.filter((resp) => resp?.status === 'Error')
            ?.length || 0;
        const warningCount =
          processedResponse?.filter((resp) => resp?.status === 'Warning')
            ?.length || 0;
        const totalCount = processedResponse?.length || 0;

        const processedRecords = formatRecordCount(totalCount, '');
        const errorRecords = formatRecordCount(errorCount, '');
        const warningText =
          warningCount > 0
            ? formatRecordCount(warningCount, ' has warnings. ')
            : '';
        alertUtils.showErrorAlert(
          alertMap.validationError.getContent(
            createErrorFile,
            processedRecords,
            errorRecords,
            errorCount,
            warningText,
          ),
          alertMap.validationError.heading,
        );
      } else {
        const report = reportsConfig[importType.type];
        alertUtils.showSuccessAlert(
          alertMap.success.getContent(`${report?.label} (${report?.value})`),
        );
      }
    }
    return () => reset();
  }, [processedResponse]);

  return (
    <article>
      <header>
        <Breadcrumbs
          trail={[
            <a href={`${window.AFP_CONFIG.appURLs.home}/home`}>Home</a>,
            <Link to="/reports">Vehicle Reports Manager</Link>,
          ]}
          current={title}
        />
        {internalError && <InternalError />}
        <Alert />
        {agencies?.length && (
          <>
            <PageTitle title={title} />
            <div className="margin-bottom-4 tablet:grid-col-7">
              <p>
                If you would like to update multiple records at once, this tool
                allows you to bulk update the vehicle data. You must first
                <Link to="/reports/export?type=template">
                  {' '}
                  export the template
                </Link>{' '}
                provided, complete all the required steps, and upload the
                template for bulk updates.
              </p>
              <InventoryMgmtGuideText />
              <span>
                Required fields are marked with an asterisk (
                <RequiredFieldIndicator />
                ).
              </span>
            </div>
            <div className="margin-bottom-4 tablet:grid-col-7">
              <hr />
            </div>
          </>
        )}
      </header>
      <ShowChildrenByCriteria criteriaToShow={agencies?.length}>
        <div>
          <h4 className="title-s-caps text-primary margin-bottom-1">
            Upload file
          </h4>
          <div className="margin-bottom-4 tablet:grid-col-7">
            <p>
              Please indicate which vehicle report you would like to update. You
              must use the template generated from{' '}
              <Link to="/reports/export?type=template">
                {' '}
                Export Template for Bulk Update
              </Link>
              .
            </p>
          </div>
          <div className="margin-bottom-4 tablet:grid-col-7">
            <ImportForm alertUtils={alertUtils} />
            {importedData?.length > 0 && (
              <ProcessImport
                useDataProcessor={reportsConfig[importType.type].processorHook}
              />
            )}
          </div>
        </div>
      </ShowChildrenByCriteria>
    </article>
  );
};

export default BulkImport;
