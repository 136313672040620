import React, { useEffect } from 'react';
import { FilterPanel } from '@gsa/afp-component-library';
import { useMotorPool } from '../../motor-pool-provider';
import { useMotorPoolFilter } from '../../filters/filter-provider';
import { generateMPVehiclesFilterStructure } from '../../filters/motor-pool-filter-structure';
import { formatReservationScheduledDates } from '../../helpers/utils';

const formatAvailabilityDatesFilters = (
  appliedFilters,
  dispatchAction,
  motorPoolTimezone,
) => {
  const reservationDateRange = appliedFilters.find(
    (filter) => filter.key === 'reservation',
  );

  let formattedDates;
  if (reservationDateRange) {
    const defaultScheduledPickupTime = '00:00';
    const defaultScheduledReturnTime = '23:59';
    const scheduledPickupDate = reservationDateRange.value.beginning;
    const scheduledReturnDate = reservationDateRange.value.end;
    const scheduledStartTimeFiltered = appliedFilters.find(
      (filter) => filter.key === 'reservation-start-time',
    )?.value;
    const scheduledReturnTimeFiltered = appliedFilters.find(
      (filter) => filter.key === 'reservation-end-time',
    )?.value;

    dispatchAction('SET_SELECTED_VEHICLE_AVAILABLE_DATES', {
      scheduledPickupDate: scheduledPickupDate || '',
      scheduledReturnDate: scheduledReturnDate || '',
      scheduledStartTime: scheduledStartTimeFiltered || '',
      scheduledReturnTime: scheduledReturnTimeFiltered || '',
    });

    formattedDates = formatReservationScheduledDates(
      {
        scheduledPickupDate,
        scheduledReturnDate,
        scheduledStartTime:
          scheduledStartTimeFiltered || defaultScheduledPickupTime,
        scheduledReturnTime:
          scheduledReturnTimeFiltered || defaultScheduledReturnTime,
      },
      motorPoolTimezone,
    );
  }
  // re-structure the filter to manually apply it on the backend side
  const otherAppliedFilters = appliedFilters.filter(
    (filter) =>
      filter.key !== 'reservation' &&
      filter.key !== 'reservation-start-time' &&
      filter.key !== 'reservation-end-time',
  );
  if (
    reservationDateRange?.length === 0 &&
    !otherAppliedFilters?.length === 0
  ) {
    return [];
  }
  return [
    ...otherAppliedFilters,
    ...(reservationDateRange
      ? [
          {
            operator: '$notBetween',
            key: 'availabilityDates',
            value: [
              formattedDates.formattedScheduledPickupDate,
              formattedDates.formattedScheduledReturnDate,
            ],
          },
        ]
      : []),
  ];
};

const MotorPoolVehiclesSidebar = () => {
  const { selectedMotorPoolById, dispatchAction } = useMotorPool();
  const { filterStructure, setFilters, setStructure } = useMotorPoolFilter();

  const setVehiclesListingFilters = (filters) => {
    const formattedFilters = formatAvailabilityDatesFilters(
      filters,
      dispatchAction,
      selectedMotorPoolById?.timezone,
    );
    setFilters({
      context: 'vehiclesListing',
      filters: formattedFilters,
    });
  };

  useEffect(() => {
    setStructure({
      context: 'vehiclesListing',
      filterStructure: generateMPVehiclesFilterStructure(),
    });
  }, []);

  if (filterStructure?.vehiclesListing?.length > 0) {
    return (
      <FilterPanel.FilterPanel
        filterStructure={filterStructure.vehiclesListing}
        setQueryFiltersState={(filters) => setVehiclesListingFilters(filters)}
        clearButtonLabel="Reset all"
        showClearIcon
        order={[['id', 'ASC']]}
        showSearchIcon
      />
    );
  }
  return null;
};

export default MotorPoolVehiclesSidebar;
