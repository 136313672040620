import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AFPTable } from '@gsa/afp-component-library';
import TagStatus from '../../license-plate/table-widgets/tag-status-badge';
import LicenseDetails from '../../license-plate/table-widgets/license-details';
import { LicenseAorB } from '../../license-plate/widgets/license-plate-table';
import { useLicensePlateDestruction } from '../context';
import { formatTagExpDate } from '../../../utilities/common';

const TABLE_HEADER = [
  {
    Header: 'Plate Number ',
    accessor: 'id',
    sortable: false,
  },
  {
    Header: 'Plate Status ',
    accessor: 'tagActivityTypeCode',
    sortable: false,
  },
  {
    Header: 'A or B ',
    accessor: 'aOrB',
    sortable: false,
  },
  {
    Header: 'Expiration ',
    accessor: 'expirationDate',
    sortable: false,
  },
  {
    Header: 'Agency ',
    accessor: 'customerAgencyCode',
    sortable: false,
  },
];

export default function DestructionFinalTable() {
  const [tableData, setTableData] = useState();
  const { forDestructionList } = useLicensePlateDestruction();

  const generateDestructionFinalTable = () => {
    const data = forDestructionList.map((row) => {
      return {
        id: (
          <Link to={`/license-plate/${encodeURIComponent(row.id)}`}>
            {row.id}
          </Link>
        ),
        tagActivityTypeCode: <TagStatus tagStatus={row?.tagActivityTypeCode} />,
        aOrB: <LicenseAorB value={row.aOrB} />,
        expirationDate: formatTagExpDate(row.expirationDate),
        customerAgencyCode: row?.agencyName,
      };
    });
    setTableData(data);
  };

  useEffect(() => {
    if (forDestructionList?.length > 0) {
      generateDestructionFinalTable();
    }
  }, [forDestructionList]);

  if (tableData) {
    return (
      <div className="plate-content flex-10 margin-right-15">
        <AFPTable
          columns={TABLE_HEADER}
          data={tableData}
          testId="afp-destruction-table-test-id"
          sortable={false}
          fullWidth
          bordered={false}
          scrollable={false}
          stacked
          renderRowSubComponent={LicenseDetails}
        />
      </div>
    );
  }

  return <div className="plate-content flex-10" />;
}
