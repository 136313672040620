import { gql } from '@apollo/client';

export const GET_TRANSACTION_HISTORY_RECORDS = gql`
  query GetTransactionHistoryRecords(
    $assetId: String!
    $order: OrderBy
    $offset: Float
    $limit: Float
    $filters: [Filter!]
  ) {
    getTransactionHistoryRecords(
      assetId: $assetId
      order: $order
      offset: $offset
      limit: $limit
      filters: $filters
    ) {
      rows {
        assetTransactionHistoryId
        assetId
        transactionHistoryCategoryTypeId
        transactionHistoryCategoryTypeName {
          description
        }
        previousDataDisplay
        updatedDataDisplay
        comment
        transactionDate
        createdBy
        createdByUser {
          fullName
        }
      }
      count
      hasMore
    }
  }
`;

export const GET_HISTORY_ACTIVITY_TYPES = gql`
  query GetHistoryActivityTypes {
    getHistoryActivityTypes {
      transactionHistoryCategoryTypeId
      description
    }
  }
`;
