import React from 'react';
import { Tag } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';

const PM_SCHEDULE_TYPE_COLORS = {
  Severe: 'Urgent-Dark',
  Standard: 'Inactive-Gray',
};

export default function PmScheduleTypeTag({ pmScheduleType }) {
  return (
    <Tag
      className="margin-left-3"
      variant={PM_SCHEDULE_TYPE_COLORS[pmScheduleType]}
    >
      {pmScheduleType}
    </Tag>
  );
}

PmScheduleTypeTag.propTypes = {
  pmScheduleType: PropTypes.string.isRequired,
};
