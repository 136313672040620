import { gql } from '@apollo/client';

export const SEARCH_POINTS_OF_CONTACT = gql`
  query SearchPointsOfContact(
    $limit: Float!
    $offset: Float!
    $order: OrderBy
    $filters: [Filter!]
  ) {
    searchPointsOfContact(
      limit: $limit
      offset: $offset
      order: $order
      filters: $filters
    ) {
      contactId
      pocEmailAddress
      pocFirstName
      pocLastName
      pocPhone1CountryCode
      pocPhone1
      pocPhone1Ext
      pocPhone2CountryCode
      pocPhone2
      pocPhone2Ext
    }
  }
`;

export const CREATE_CONTACT = gql`
  mutation CreateContact($pointOfContact: PointOfContactInput!) {
    createContact(pointOfContact: $pointOfContact) {
      contactId
      pocEmailAddress
      pocFirstName
      pocLastName
      pocPhone1
      pocPhone1Ext
      pocPhone1CountryCode
      pocPhone2
      pocPhone2Ext
      pocPhone2CountryCode
    }
  }
`;

export const UPDATE_CONTACT = gql`
  mutation UpdateContact($pointOfContact: PointOfContactInput!) {
    updateContact(pointOfContact: $pointOfContact) {
      contactId
      pocEmailAddress
      pocFirstName
      pocLastName
      pocPhone1CountryCode
      pocPhone1
      pocPhone1Ext
      pocPhone2CountryCode
      pocPhone2
      pocPhone2Ext
    }
  }
`;
