import React, { useEffect } from 'react';
import { FilterPanel } from '@gsa/afp-component-library';
import { useCurrentUser } from '@gsa/afp-shared-ui-utils';
import useGetTypeaheadValues from 'hooks/use-get-typeahead-values';
import useUser from 'utilities/use-user';
import { useMileageExpressFilter } from './filters/mileage-express-filter-provider';

import {
  primaryPocEmailPath,
  generateMileageExpressFilterStructure,
  fsrEmailFilterPath,
  tagNumberFilterPath,
} from './filters/mileage-express-filter-structure';
import { useMileageExpress } from './mileage-express-provider';

const MileageExpressSidebar = () => {
  const {
    filterStructure,
    setFilters,
    setStructure,
    filters: appliedFilters,
  } = useMileageExpressFilter();
  const { optionsData } = useMileageExpress();
  const { currentUser } = useCurrentUser();
  const { isGsaEmployee } = useUser();

  const setMileageFilters = (filters) => {
    setFilters(filters);
  };
  const { handleTypeaheadSearch, typeaheadData } = useGetTypeaheadValues({
    fsrEmailFilterPath,
    tagNumberFilterPath,
    primaryPocEmailPath,
  });

  useEffect(() => {}, []);
  useEffect(() => {
    if (optionsData?.fmcsWithZoneAndRegion && currentUser) {
      const initialFilters = {
        zone: null,
        fmc: null,
        fsrEmail: null,
      };

      const { zones, fmcsWithZoneAndRegion } = optionsData;

      setStructure(
        generateMileageExpressFilterStructure(
          fmcsWithZoneAndRegion,
          zones,
          initialFilters,
          isGsaEmployee,
        ),
      );
    }
  }, [currentUser, optionsData, appliedFilters]);

  if (filterStructure?.length > 0) {
    return (
      <FilterPanel.FilterPanel
        filterStructure={filterStructure}
        setQueryFiltersState={(filters) => setMileageFilters(filters)}
        clearButtonLabel="Reset all"
        fetchTypeaheads={handleTypeaheadSearch}
        typeaheadData={typeaheadData}
        showClearIcon
        order={[['updateAt', 'DESC']]}
        showSearchIcon
      />
    );
  }
  return null;
};

export default MileageExpressSidebar;
