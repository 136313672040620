import React, { createContext, useContext, useReducer } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  CREATE_REPAIR_ORDER,
  GET_REPAIR_ORDER_BY_ID,
  GET_REPAIR_VEHICLE,
  UPDATE_REPAIR_ORDER,
} from '../../../services/data-layer/repair-order';

const RepairOrderContext = createContext({});
const useRepairOrder = () => useContext(RepairOrderContext);

const PageErrors = {
  InvalidData: {
    heading: 'This page contains errors',
    message: 'Please address all errors before saving the repair order.',
  },
  FailToUpdate: {
    heading: 'Fail to update the repair order',
    message: '',
  },
  FailToCreate: {
    heading: 'Fail to create a repair order',
    message: '',
  },
  FailToGetVehicle: {
    heading: 'Fail to fetch vehicle',
    message: '',
  },
};

const PageMode = {
  New: 'new',
  View: 'view',
  Edit: 'edit',
};

const initialState = {
  mode: undefined,
  vehicle: undefined,
  transactionDetails: undefined,
  repairOrder: undefined,
  paymentInfo: undefined,
  vendorInfo: undefined,
  vehicleId: undefined,
  agencyInfo: undefined,
  repairComments: [],
  commentText: '',
  savingRepairOrder: false,
  savedSuccefully: false,
  taxCost: 0,
  error: {},
  repairNotFound: false,
};

const actions = {
  setMode: 'SET_MODE',
  setVehicle: 'SET_VEHICLE',
  setTaxCost: 'SET_TAX_COST',
  setRepairComments: 'SET_COMMENTS',
  setCommentText: 'SET_COMMENT_TEXT',
  setSaving: 'SET_SAVING',
  setError: 'SET_ERROR',
  setNotFound: 'SET_NOT_FOUND',
  setPayload: 'SET_PAYLOAD',
};

const repairOrderPageReducer = (state, { action, payload }) => {
  switch (action) {
    case actions.setMode:
      return { ...state, error: {}, mode: payload };
    case actions.setVehicle:
      return { ...state, error: {}, vehicle: payload };
    case actions.setSaving:
      return { ...state, error: {}, savingRepairOrder: payload };
    case actions.setTaxCost:
      return { ...state, error: {}, taxCost: payload };
    case actions.setRepairComments:
      return { ...state, error: {}, repairComments: payload };
    case actions.setCommentText:
      return { ...state, error: {}, commentText: payload };
    case actions.setNotFound:
      return { ...state, error: {}, repairNotFound: payload };
    case actions.setError:
      return { ...state, error: payload };
    case actions.setPayload:
      return { ...state, error: {}, ...payload };
    default:
      return state;
  }
};

const RepairOrderProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    repairOrderPageReducer,
    initialState,
    () => initialState,
  );

  const setError = (error) => {
    dispatch({ action: actions.setError, payload: error });
  };

  const setTaxCost = (amount) => {
    dispatch({ action: actions.setTaxCost, payload: amount });
  };

  const setNotFound = (payload) => {
    dispatch({ action: actions.setNotFound, payload });
  };

  // Queries
  const [getRepairOrderQuery] = useLazyQuery(GET_REPAIR_ORDER_BY_ID, {
    onError: () => {
      // TODO
    },
    onCompleted: (data) => {
      const getRepairOrderById = data?.getRepairOrderById || {};
      // transaction details
      const {
        transactionDate: date,
        invoiceNumber,
        mileage: mileageAtRepair,
        isTransactionComplete: transactionCompleted,
      } = getRepairOrderById;
      // Order
      const { id: repairOrderNumber, serviceDate: repairDate } =
        getRepairOrderById;
      // Vendor info
      const vendor = getRepairOrderById?.vendor || {};
      const {
        id: vendorId,
        name: vendorName,
        description: vendorDescription,
        phone: vendorPhone,
        phoneExt: vendorPhoneExt,
        address1: vendorAddress1,
        address2: vendorAddress2,
        city: vendorCity,
        stateCode: vendorState,
        postalCode: vendorZipcode,
      } = vendor;
      // Payment info
      const payment = getRepairOrderById?.payment || {};
      const {
        id: paymentId,
        billTo: billedTo,
        paymentStatus,
        paymentForm,
        postedDate,
      } = payment;
      // Repair comment
      const comments = getRepairOrderById?.comments || [];

      const repairOrder = {
        transactionDetails: {
          date,
          invoiceNumber,
          transactionCompleted,
          mileageAtRepair,
        },
        repairOrder: {
          repairDate,
          repairOrderNumber,
        },
        vendorInfo: {
          vendorId,
          vendorDescription,
          vendorName,
          vendorPhone,
          vendorPhoneExt,
          vendorAddress1,
          vendorAddress2,
          vendorCity,
          vendorState,
          vendorZipcode,
        },
        paymentInfo: {
          paymentId,
          billedTo,
          paymentStatus,
          paymentForm,
          postedDate,
        },
        repairComments: comments?.length ? comments.map((c) => ({ ...c })) : [],
        vehicleId: getRepairOrderById?.assetId || undefined,
        taxCost: payment.taxCost ? parseFloat(payment.taxCost) : 0,
      };

      dispatch({
        action: actions.setPayload,
        payload: repairOrder,
      });
    },
    fetchPolicy: 'no-cache',
  });
  const [getRepairVehicleQuery] = useLazyQuery(GET_REPAIR_VEHICLE, {
    onError: (error) => {
      if (error.message === 'NOT_FOUND_ERROR') {
        setNotFound(true);
      } else {
        setError(PageErrors.FailToGetVehicle);
      }
    },
    onCompleted: (data) => {
      const { getVehicle } = data || {};
      const { agency, bureau, office } = getVehicle || {};
      dispatch({
        action: actions.setPayload,
        payload: {
          vehicle: getVehicle,
          agencyInfo: {
            agencyName: agency?.prefixedName,
            bureauName: bureau?.prefixedName,
            officeName: office?.prefixedName,
          },
        },
      });
    },
    fetchPolicy: 'no-cache',
  });
  // Mutation
  const [createRepairOrderQuery] = useMutation(CREATE_REPAIR_ORDER, {
    onError: () => {
      dispatch({
        action: actions.setPayload,
        payload: {
          savingRepairOrder: false,
          error: PageErrors.FailToCreate,
        },
      });
    },
    onCompleted: (data) => {
      dispatch({
        action: actions.setPayload,
        payload: {
          repairOrder: {
            ...state.repairOrder,
            repairOrderNumber: data.createRepairOrder.id,
          },
          savingRepairOrder: false,
          savedSuccefully: !!data.createRepairOrder.id,
        },
      });
    },
  });
  const [updateRepairOrderQuery] = useMutation(UPDATE_REPAIR_ORDER, {
    onError: () => {
      dispatch({
        action: actions.setPayload,
        payload: {
          savingRepairOrder: false,
          error: PageErrors.FailToUpdate,
        },
      });
    },
    onCompleted: (data) => {
      dispatch({
        action: actions.setPayload,
        payload: {
          savingRepairOrder: false,
          savedSuccefully: data.updateRepairOrder,
        },
      });
    },
  });

  const getRepairOrder = (vin, orderNumber) => {
    const serviceId = parseFloat(orderNumber);
    if (!Number.isNaN(serviceId)) {
      getRepairOrderQuery({
        variables: { id: serviceId },
      });
    }
    getRepairVehicleQuery({
      variables: { id: vin },
    });
  };
  const setMode = (mode) => {
    dispatch({ action: actions.setMode, payload: mode });
  };
  const setSavingRepairOrder = (saving) => {
    dispatch({ action: actions.setSaving, payload: saving });
  };

  const initPage = ({ vin, orderNumber, mode = PageMode.New }) => {
    getRepairOrder(vin, orderNumber);
    dispatch({ action: actions.setPayload, payload: { mode, vehicleId: vin } });
  };

  const saveRepairOrder = ({ generalInfo, serviceLines, totals }) => {
    const serviceId = state.repairOrder?.repairOrderNumber || undefined;
    const vendorId = state.vendorInfo?.vendorId;
    const paymentId = state.paymentInfo?.paymentId;
    const { vehicle, taxCost, commentText } = state;

    const {
      transactionDate,
      transactionCompleted,
      invoiceNumber,
      mileageAtRepair: mileage,
      repairDate: serviceDate,
      vendorDescription,
      vendorName,
      vendorPhone,
      vendorPhoneExt,
      vendorAddress1,
      vendorAddress2,
      vendorCity,
      vendorState,
      vendorZipcode,
      billedTo,
      paymentForm,
      paymentStatus,
      postedDate,
    } = generalInfo;

    const isNew = state.mode === PageMode.New;

    const serviceLineParams = serviceLines.reduce(
      (acc, sl) => {
        const line = {
          id: !isNew ? parseFloat(sl.id) : undefined,
          serviceId: !isNew ? serviceId : undefined,
          serviceCodeId: parseFloat(sl.serviceCodeId),
          serviceClassCode: sl.serviceClassCode,
          serviceReasonCode: sl.serviceReasonCode,
          failureCauseCode: sl.failureCauseCode,
          workAccomplishedCode: sl.workAccomplishedCode,
          serviceLineCost: {
            id: !isNew ? parseFloat(sl.serviceLineCostId) : undefined,
            serviceLineId: !isNew ? parseFloat(sl.id) : undefined,
            type: 'Actual',
            partsCost: parseFloat(sl.partsCost),
            partsQuantity: parseFloat(sl.partsQuantity),
            laborRate: parseFloat(sl.laborRate),
            laborHours: parseFloat(sl.laborHours),
            miscCost: parseFloat(sl.miscCost),
            partsTotal: parseFloat(sl.partsTotal),
            laborTotal: parseFloat(sl.laborTotal),
            totalAmount: parseFloat(sl.totalAmount),
          },
        };
        if (isNew || sl.new) {
          delete line.id;
          acc.create.push(line);
          return acc;
        }
        if (sl.deleted) {
          acc.remove.push(parseFloat(sl.id));
          return acc;
        }
        acc.update.push(line);
        return acc;
      },
      { create: [], update: [], remove: [] },
    );

    const service = {
      id: serviceId,
      assetId: vehicle?.id,
      transactionDate,
      serviceDate: serviceDate || undefined,
      invoiceNumber,
      isTransactionComplete: transactionCompleted || 'No',
      mileage: parseFloat(mileage),
    };
    const serviceVendor = {
      id: !isNew ? parseFloat(vendorId) : undefined,
      serviceId,
      description: vendorDescription || undefined,
      name: vendorName,
      address1: vendorAddress1,
      address2: vendorAddress2,
      city: vendorCity,
      stateCode: vendorState,
      postalCode: vendorZipcode,
      phone: vendorPhone,
      phoneExt: vendorPhoneExt,
    };
    const servicePayment = {
      id: !isNew ? parseFloat(paymentId) : undefined,
      serviceId,
      billTo: billedTo,
      paymentStatus: paymentStatus || undefined,
      paymentForm: paymentForm || undefined,
      postedDate: postedDate || undefined,
      totalLabor: parseFloat(totals.laborCost),
      totalParts: parseFloat(totals.partsCost),
      miscCost: parseFloat(totals.miscCost),
      taxCost: parseFloat(taxCost),
      serviceTotal:
        totals.laborCost + totals.partsCost + totals.miscCost + taxCost,
    };

    const initCommentParams = { create: [], update: [], remove: [] };
    if (commentText) {
      initCommentParams.create.push({
        serviceId: !isNew ? serviceId : undefined,
        commentType: 'Service',
        comment: commentText,
      });
    }
    const serviceCommentParams = state.repairComments.reduce((acc, c) => {
      const comment = {
        id: parseFloat(c.id),
        serviceId: serviceId,
        commentType: c.commentType || 'Service',
        comment: c.comment,
      };
      if (c.deleted) {
        acc.remove.push(parseFloat(c.id));
        return acc;
      }
      acc.update.push(comment);
      return acc;
    }, initCommentParams);

    if (!isNew) {
      updateRepairOrderQuery({
        variables: {
          service,
          serviceVendor,
          servicePayment,
          serviceLines: serviceLineParams,
          serviceComments: serviceCommentParams,
        },
      });
    }

    if (isNew) {
      createRepairOrderQuery({
        variables: {
          service,
          serviceVendor,
          servicePayment,
          serviceLines: serviceLineParams.create,
          serviceComments: serviceCommentParams.create,
        },
      });
    }
    setSavingRepairOrder(true);
  };

  const setCommentText = (text) => {
    dispatch({
      action: actions.setCommentText,
      payload: text,
    });
  };
  const deleteComment = ({ id }) => {
    const { repairComments } = state;
    const comments = repairComments.reduce((acc, c) => {
      if (c.id === id) {
        c.deleted = true;
      }
      acc.push(c);
      return acc;
    }, []);

    dispatch({
      action: actions.setRepairComments,
      payload: comments,
    });
  };

  return (
    <RepairOrderContext.Provider
      value={{
        ...state,
        initPage,
        saveRepairOrder,
        setMode,
        setTaxCost,
        setCommentText,
        deleteComment,
        setError,
      }}
    >
      {children}
    </RepairOrderContext.Provider>
  );
};

export { RepairOrderProvider as default, useRepairOrder, PageMode, PageErrors };
