import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { GET_ORDERS } from '../../../services/data-layer';

export const INIT_FILTERS = [
  {
    conditions: [],
    operator: '$and',
  },
];
export const INIT_LIMIT = 10;
export const INIT_OFFSET = 0;
export const INIT_SORT_ORDER = [['processedAt', 'ASC']];

export default function useOrdersManager() {
  const [getOrders, { data, loading, error: getOrdersError }] = useLazyQuery(
    GET_ORDERS,
    {
      fetchPolicy: 'no-cache',
    },
  );
  const [orders, setOrders] = useState(null);

  const [filters, setOrderFilters] = useState(INIT_FILTERS);
  const [limit, setLimit] = useState(INIT_LIMIT);
  const [offset, setOffset] = useState(INIT_OFFSET);
  const [sortOrder, setSortOrder] = useState(INIT_SORT_ORDER);

  useEffect(() => {
    if (data && data.getUniqueOrders && filters[0]?.conditions?.length) {
      setOrders(data.getUniqueOrders);
    }
  }, [data]);

  useEffect(() => {
    if (getOrdersError) {
      setOrders(null);
    }
  }, [getOrdersError]);

  useEffect(() => {
    if (filters[0]?.conditions?.length) {
      getOrders({
        variables: {
          limit,
          offset,
          order: sortOrder,
          filters,
        },
      });
    } else {
      setOrders(null);
    }
  }, [limit, offset, sortOrder, filters]);

  const setFilters = (newFilters) => {
    setOrderFilters(newFilters);
    setOffset(0);
  };

  return {
    getOrders,
    loading,
    getOrdersError,
    data,
    orders,
    setOrders,
    filters,
    setFilters,
    limit,
    setLimit,
    offset,
    setOffset,
    sortOrder,
    setSortOrder,
  };
}
