/* eslint-disable filenames/match-exported */
import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { PrivateRoute, NotFound } from '@gsa/afp-shared-ui-utils';
import CustomerAccountProvider from './providers/customer-account-provider';
import CustomerAccountHome from './pages/customer-account-home';
import { VMSOperations, VMSSubjects } from '../../utilities/consts';
import CustomerAccountAddEdit from './pages/customer-account-add-edit';
import CustomerAccountViewPage from './pages/customer-account-view';
import { useFeatureToggle } from '../../utilities/feature-toggle';

const CustomerAccount = () => {
  const { feature } = useFeatureToggle();
  return (
    <CustomerAccountProvider>
      <Switch>
        <PrivateRoute
          exact
          path="/customer-accounts"
          component={feature(CustomerAccountHome, 'customer-accounts-view')}
          title="Customer Account Home"
          operation={VMSOperations.View}
          subject={VMSSubjects.CUSTOMER_ACCOUNT}
        />
        <PrivateRoute
          exact
          path="/customer-accounts/create"
          component={feature(
            CustomerAccountAddEdit,
            'customer-accounts-change',
          )}
          title="Create an Account"
          operation={VMSOperations.Create}
          subject={VMSSubjects.CUSTOMER_ACCOUNT}
        />
        <PrivateRoute
          exact
          path="/customer-accounts/update/:id"
          component={feature(
            CustomerAccountAddEdit,
            'customer-accounts-change',
          )}
          title="Customer Account update"
          operation={VMSOperations.Update}
          subject={VMSSubjects.CUSTOMER_ACCOUNT}
        />
        <PrivateRoute
          exact
          path="/customer-accounts/view/:id"
          component={feature(CustomerAccountViewPage, 'customer-accounts-view')}
          title="Customer Account details"
          operation={VMSOperations.View}
          subject={VMSSubjects.CUSTOMER_ACCOUNT}
        />
        <Route path="*" component={NotFound} />
      </Switch>
    </CustomerAccountProvider>
  );
};

export default CustomerAccount;
