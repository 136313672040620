import React from 'react';

export const CREATE_SCHEDULE_MESSAGE = {
  body: (createdScheduleAlert) => (
    <>
      Your recurring report <strong>{createdScheduleAlert?.name}</strong> has
      been {createdScheduleAlert?.type}. To make changes, edit this report from
      the table below.
    </>
  ),
};

export const SUSPEND_SCHEDULE_MESSAGE = {
  body: () => (
    <>
      Your recurring report has been suspended. It will not be emailed to
      recipients until it is unsuspended. To unsuspend this report, use the
      Actions menu in the table below.
    </>
  ),
};

export const UNSUSPEND_SCHEDULE_MESSAGE = {
  body: () => <>Your recurring report has been unsuspended.</>,
};

export const DELETE_SCHEDULE_MESSAGE = {
  body: () => (
    <>
      Your recurring report has been deleted. It will no longer be emailed to
      recipients.
    </>
  ),
};
