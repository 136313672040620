import React from 'react';
import { Link } from 'react-router-dom';
import { Alert, Button } from '@gsa/afp-component-library';
import { tagExpStrToDate, formatTagExpDate } from '../../../utilities/common';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import { vehicleRegistrationIsIncomplete } from '../../vehicle-registration-status-tag';

const SwapLicensePlateAlerts = ({
  registrationDetailVehicle,
  swapTag,
  swappingTag,
  swapData,
}) => {
  const isNewTagsAvailable = () => {
    // checks if the vehicle has a received tag with greater expiration date
    const tagExpirationDate = tagExpStrToDate(
      _.get(registrationDetailVehicle, 'tag.expirationDate'),
    );
    const newTagExpirationDate = tagExpStrToDate(
      _.get(registrationDetailVehicle, 'tagNew.expirationDate'),
    );

    if (
      registrationDetailVehicle?.tag &&
      ((!tagExpirationDate &&
        moment(newTagExpirationDate).diff(new Date()) > 0) || // making sure the new tag is not expired
        (newTagExpirationDate &&
          moment(newTagExpirationDate).diff(tagExpirationDate) > 0))
    ) {
      return true;
    }
    return false;
  };

  const tagNumber = _.get(registrationDetailVehicle, 'tagNumber');
  const expDate = _.get(registrationDetailVehicle, 'tag.expirationDate');
  const newExpDate = _.get(registrationDetailVehicle, 'tagNew.expirationDate');
  const vin = _.get(registrationDetailVehicle, 'id');
  const ownershipTypeCode = _.get(
    registrationDetailVehicle,
    'ownershipTypeCode',
  );

  return (
    <>
      {isNewTagsAvailable() && ownershipTypeCode !== 'GF' && (
        <div className="padding-bottom-1">
          <Alert type="info" heading="New license plate available">
            License plate <strong>{tagNumber}</strong> with the newer expiration
            date <strong>{formatTagExpDate(newExpDate)}</strong> is ready to be
            attached to this vehicle.
            <div className="margin-top-1">
              <Button
                data-testid="swap-tag-button"
                disabled={swappingTag}
                type="button"
                variant="primary"
                onClick={() => {
                  swapTag({
                    variables: {
                      tagNumber,
                      vin,
                      tagExpirationDate: _.toNumber(expDate),
                      newTagExpirationDate: _.toNumber(newExpDate),
                    },
                  });
                }}
                label="Update vehicle registration with new license plate"
              />
            </div>
          </Alert>
        </div>
      )}
      {_.get(swapData, 'newExpDate') && (
        <>
          <div className="padding-bottom-1">
            <Alert type="success" slim>
              Vehicle registration and license plate information were
              successfully updated.
              {!vehicleRegistrationIsIncomplete(registrationDetailVehicle) && (
                <>
                  {' '}
                  <a
                    href={_.get(
                      registrationDetailVehicle,
                      'supportingDocument.signedUrl',
                    )}
                    download
                  >
                    Download updated vehicle registration card
                  </a>
                </>
              )}
            </Alert>
          </div>
          <div className="padding-bottom-1">
            <Alert
              type="warning"
              heading="Previous license plate has been removed from the vehicle"
            >
              License plate{' '}
              <Link
                to={`/license-plate/${_.get(
                  swapData,
                  'tagNumber',
                )}?tagExpirationDate=${_.get(swapData, 'oldExpDate')}`}
              >
                <strong>{_.get(swapData, 'tagNumber')}</strong>
              </Link>{' '}
              with expiration date{' '}
              <strong>{formatTagExpDate(_.get(swapData, 'oldExpDate'))}</strong>{' '}
              has been removed from the vehicle. The status of{' '}
              <strong>unattached</strong> has been applied to both A and B
              plates (if a 2-plate set).
              <div className="padding-y-2">
                <strong>What to do next:</strong>
              </div>
              Please submit license plate{' '}
              <Link
                to={`/license-plate/${_.get(
                  swapData,
                  'tagNumber',
                )}?tagExpirationDate=${_.get(swapData, 'oldExpDate')}`}
              >
                <strong>{_.get(swapData, 'tagNumber')}</strong>
              </Link>{' '}
              with expiration date of{' '}
              <strong>{formatTagExpDate(_.get(swapData, 'oldExpDate'))}</strong>{' '}
              for destruction.
            </Alert>
          </div>
        </>
      )}
      {_.get(swapData, 'error') && (
        <>
          <div className="padding-bottom-1">
            <Alert type="error" slim>
              {_.get(swapData, 'error')}
            </Alert>
          </div>
        </>
      )}
    </>
  );
};

export default SwapLicensePlateAlerts;

SwapLicensePlateAlerts.defaultProps = {
  registrationDetailVehicle: {},
  swapTag: () => {
    // noop
  },
  swappingTag: false,
  swapData: {},
};

SwapLicensePlateAlerts.propTypes = {
  registrationDetailVehicle: PropTypes.object,
  swapTag: PropTypes.func,
  swappingTag: PropTypes.bool,
  swapData: PropTypes.object,
};
