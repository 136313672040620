import React from 'react';
import { FleetTechnicalSupportContact } from '@gsa/afp-shared-ui-utils';

const AlertConfig = {
  success: {
    heading: 'Export Initiated',
    getContent: () => (
      <div className="margin-y-1">
        The report you&apos;ve created is now being processed for export and
        will be emailed to you upon completion. Depending on the file size, this
        may take up to 15 minutes or more. If you experience technical
        difficulties exporting, <FleetTechnicalSupportContact />.
      </div>
    ),
  },
  systemError: {
    heading: 'System error',
    getContent: () => (
      <div className="margin-y-1">
        Please try again later. If you continue to experience technical
        difficulties with this page, <FleetTechnicalSupportContact />.
      </div>
    ),
  },
  sizeError: {
    heading: 'Report is too large',
    getContent: () => (
      <div className="margin-y-1">
        Please use the filters to reduce the amount of data you are retrieving.
      </div>
    ),
  },
};

export default AlertConfig;
