/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Alert,
  Spinner,
  Modal,
  useModal,
} from '@gsa/afp-component-library';
import { ReportStolenForm } from 'components/vehicle-details/widgets/report-stolen/report-stolen-form';
import { useLicensePlateDetails } from 'components/license-plate-details/license-plate-details-provider';

const LicensePlateStolen = ({ submitMissingReport, onClose }) => {
  const {
    tagDetails,
    createOrUpdateMissingTags,
    missingPlateData,
    getMissingPlateDataLoading,
    reportMissingPlateError,
    reportMissingPlateLoading,
  } = useLicensePlateDetails();
  const { openModal, closeModal } = useModal();
  const [reportData, setReportData] = useState({ tagNumber: tagDetails?.id });

  useEffect(() => {
    if (createOrUpdateMissingTags.aOrB !== 'both') {
      missingPlateData.forEach((tag) => {
        if (tag.tagId === createOrUpdateMissingTags.tagId) {
          setReportData({
            ...reportData,
            ...tag,
            tagA: createOrUpdateMissingTags.aOrB === 'A',
            tagB: createOrUpdateMissingTags.aOrB === 'B',
          });
        }
      });
    } else {
      setReportData({
        ...reportData,
        ...missingPlateData[0],
      });
    }
  }, [missingPlateData]);

  const getPlatesStolenCheckbox = () => {
    return createOrUpdateMissingTags.createOrUpdate === 'create' &&
      createOrUpdateMissingTags.aOrB === 'both'
      ? {
          gap: '4px',
          fields: [
            {
              id: 'plateMissing',
              label: 'Which plate is missing?',
              required: true,
              component: () => {
                return (
                  <>
                    <span className="usa-hint">Select all that apply</span>
                  </>
                );
              },
            },
            {
              id: 'tagA',
              type: 'checkbox',
              label: 'Plate A',
            },
            {
              id: 'tagB',
              type: 'checkbox',
              label: 'Plate B',
            },
            {
              id: 'bothStolen',
              disabled: true,
              onFormChange: (data) => {
                const val = data.tagA === data.tagB;
                // forcing to Y/N string values because of weird logic with form generator dependentValue prop
                return {
                  value: val ? 'Y' : 'N',
                };
              },
            },
          ],
        }
      : null;
  };

  const today = new Date().toISOString();

  const formContent = {
    buttonControls: {
      submit: false,
      cancel: false,
    },
    gap: '4px',
    sections: [
      getPlatesStolenCheckbox(),
      {
        fields: [
          {
            dependentOn: 'bothStolen',
            dependentValue: 'N',
            dependentWaitType: 'hidden',
            component: () => {
              return (
                <Alert id="licensePlateAlert" type="warning">
                  <p>
                    You&apos;re about to report <b>a single license plate</b> as
                    &quot;missing&quot;. If this license plate has a second
                    plate associated, it will be marked as &quot;pending
                    destruction&quot;.
                  </p>
                </Alert>
              );
            },
          },
        ],
      },
      {
        fieldLayout: 'horizontal',
        fields: [
          {
            columnWidth: 'col-4-12',
            id: 'stolenDate',
            type: 'datepicker',
            required: true,
            label: 'Date missing/stolen',
            element: {
              control: {
                maxDate: today,
              },
            },
          },
          {
            columnWidth: 'col-4-12',
            id: 'gsaReportedDate',
            type: 'datepicker',
            label: 'Date reported to GSA',
            required: true,
            element: {
              control: {
                maxDate: today,
              },
            },
          },
        ],
      },
      {
        fieldLayout: 'horizontal',
        fields: [
          {
            columnWidth: 'col-4-12',
            id: 'dhsReportedDate',
            type: 'datepicker',
            label: 'Date reported to DHS',
            element: {
              control: {
                maxDate: today,
              },
            },
          },
          {
            columnWidth: 'col-4-12',
            id: 'dhsCaseNumber',
            type: 'text',
            label: 'DHS Case number',
            element: {
              control: {
                maxLength: 20,
                countdown: true,
              },
            },
          },
          {
            columnWidth: 'col-4-12',
            id: 'ncicNumber',
            type: 'text',
            label: 'NCIC Case number',
            element: {
              control: {
                maxLength: 20,
                countdown: true,
              },
            },
          },
        ],
      },
      {
        fields: [
          {
            columnWidth: 'col-12-12',
            id: 'comment',
            type: 'textarea',
            label: 'Comments',
            required: true,
            element: {
              control: {
                maxLength: 250,
                countdown: true,
              },
            },
          },
        ],
      },
    ],
  };
  const MissingPlateTitle = () => {
    return (
      <>
        <div>
          <h1>Report missing/stolen license plate</h1>
          <div>
            Report missing/stolen license plate <b>{tagDetails?.id}</b> in the form
            below.
          </div>
        </div>
      </>
    );
  };

  useEffect(() => {
    openModal();
  }, []);

  const close = () => {
    onClose && onClose();
    closeModal();
  };

  return (
    <div className="afp-modal-wrapper">
      <div className="afp-modal-overlay">
        <Modal
          variant="large"
          actions={
            <>
              <Button
                variant="unstyled"
                id="cancelReportStolen"
                data-testId="cancelReportStolen"
                label="Cancel"
                onClick={close}
              />
              <Button
                className="margin-left-2"
                type="submit"
                label="Save and close"
                form="report-stolen-vehicle-form"
                disabled={reportMissingPlateLoading}
              />
              {reportMissingPlateLoading && (
                <Spinner
                  size="small"
                  aria-busy="true"
                  className="display-inline-block margin-left-2"
                />
              )}
            </>
          }
          title={<MissingPlateTitle />}
          onClose={close}
        >
          {reportMissingPlateError && (
            <Alert
              id="reportError"
              data-testId="reportError"
              type="error"
              heading="Error"
            >
              <p>
                An unexpected error has occurred while attempting to report the
                plate missing
              </p>
            </Alert>
          )}
          {getMissingPlateDataLoading && (
            <Spinner
              aria-busy="true"
              className="loading_backdrop"
              size="large"
            />
          )}
          {(createOrUpdateMissingTags.createOrUpdate === 'create' ||
            (createOrUpdateMissingTags.createOrUpdate === 'update' &&
              reportData.stolenDate)) && (
              <ReportStolenForm
                content={formContent}
                mode="plate"
                reportData={reportData}
                setReportData={setReportData}
                onSubmit={submitMissingReport}
              />
          )}
        </Modal>
      </div>
    </div>
  );
};

export default LicensePlateStolen;
