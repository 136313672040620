import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from '@gsa/afp-component-library';

export const WarningComponent = ({ type }) => {
  return (
    <Alert
      className="error-alert margin-bottom-2"
      onClose={false}
      slim
      type="warning"
    >
      Odometer will be greater than or less than 10,000 miles from the last
      reading. Are you sure you want to {type} this record?
    </Alert>
  );
};

WarningComponent.propTypes = {
  type: PropTypes.string.isRequired,
};

export const MaxWarningComponent = () => {
  return (
    <Alert
      className="error-alert margin-bottom-2"
      onClose={false}
      slim
      type="warning"
    >
      Maximum Odometer entry allowed is 4,294,967,295
    </Alert>
  );
};

export const NetworkErrorComponent = () => {
  return (
    <Alert
      className="error-alert margin-bottom-2"
      onClose={false}
      slim
      type="error"
    >
      Error getting last and next odometer
    </Alert>
  );
};
