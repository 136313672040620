import React from 'react';
import { CheckboxesComponent } from '../checkboxes-components';

export const getItems = ({
  setState,
  state,
  checkStateChange,
  clearErrors,
  defaultState,
  sessionMap,
}) =>
  Object.keys(sessionMap).map((section) => ({
    title: (
      <div className="grid-row">
        <div className="grid-col-8">{sessionMap[section]?.title}</div>
      </div>
    ),
    content: (
      <CheckboxesComponent
        section={section}
        headersList={sessionMap[section]?.headersList}
        setState={setState}
        state={state}
        checkStateChange={checkStateChange}
        clearErrors={clearErrors}
      />
    ),
    expanded: defaultState[section].isOpen,
    id: section,
    className: 'myCustomAccordionItem',
  }));

export const SECTION_TYPE = {
  vehicle_information: 'vehicle_information',
  customer: 'customer',
  cdd: 'cdd',
  afv: 'afv',
  fsr: 'fsr',
  preventive: 'preventive',
  recall: 'recall',
  fuel: 'fuel',
  mileage: 'mileage',
  billing: 'billing',
  telematics_information: 'telematics_information',
};

export const defaultState = Object.keys(SECTION_TYPE).reduce((acc, key) => {
  const currentKey = SECTION_TYPE[key];

  acc[currentKey] = {
    isOpen: currentKey === SECTION_TYPE.vehicle_information,
    checkedList: [],
    isCheckedAll: false,
  };

  return acc;
}, {});

export const sessionMap = {
  [SECTION_TYPE.vehicle_information]: {
    title: 'Vehicle Information',
    headersList: [
      'License Plate',
      'VIN',
      'Vehicle Type',
      'Vehicle Color',
      'State Plate',
      'State Plate Expiration Date',
      'Make Name',
      'Model Name',
      'Model Year',
      'Date Assigned',
      'Previous License Plate',
      'Standard Item Number (SIN)',
      'Fuel Type',
      'Fuel Capacity',
      'Engine Type',
      'Oil Life Sensor',
      'GVWR',
    ],
  },
  [SECTION_TYPE.customer]: {
    title: 'Customer Information',
    headersList: [
      'Region',
      'FMC',
      'Sub-FMC',
      'Account Code (BOAC)',
      'Serial',
      'Customer Bureau Name',
      'Customer Addr 1',
      'Customer Addr 2',
      'Customer Addr 3',
      'City',
      'State',
      'Zip',
      'Customer Contact',
      'Customer Phone No.',
      'Customer Email Addr',
      'Agency Indicator',
      'Agency Bureau',
    ],
  },
  [SECTION_TYPE.cdd]: {
    title: 'CDD Information',
    headersList: [
      'Covered (MSA)',
      'Garage Address 1',
      'Garage Address 2',
      'Garage Address 3',
      'Garaged City',
      'Garaged State',
      'Garaged Zip',
      'Garage Domestic',
      'Home to Work',
      'Location Withheld',
      'Agency Budget Element Code',
      'Vehicle Assignment Type',
      'EPAct Coverage Designation',
      'EPAct 2005 Section 701 Designation',
      'EISA Section 141 Designation',
      'Law Enforcement Designation',
      'Emergency Response Designation',
      'Agency Sort Field',
      'GAS AF',
      'Reporting Organization',
      'Comments',
    ],
  },
  [SECTION_TYPE.afv]: {
    title: 'AFV Information',
    headersList: ['AFV Surcharge', 'AFV Start Date', 'AFV End Date'],
  },
  [SECTION_TYPE.fsr]: {
    title: 'FSR Information',
    headersList: ['FSR Email Address', 'FSR Contact', 'FSR Telephone Number'],
  },
  [SECTION_TYPE.preventive]: {
    title: 'Preventive Maintenance Information',
    headersList: ['Last Preventive Maintenance', 'Next Preventive Maintenance'],
  },
  [SECTION_TYPE.fuel]: {
    title: 'Fuel Economy / Environmental Information',
    headersList: [
      'City MPG (Primary/Conventional Fuel)',
      'Highway MPG (Primary/Conventional Fuel)',
      'Combined MPG (Primary/Conventional Fuel)',
      'Greenhouse Gas Score (Primary/Conventional Fuel)',
      'City MPG (Secondary/Alternative Fuel)',
      'Highway MPG (Secondary/Alternative Fuel)',
      'Combined MPG (Secondary/Alternative Fuel)',
      'Greenhouse Gas Score (Secondary/Alternative Fuel)',
      'City MPG (Electric)',
      'Highway MPG (Electric)',
      'Combined MPG (Electric)',
      'Greenhouse Gas Score (Electric)',
    ],
  },
  [SECTION_TYPE.mileage]: {
    title: 'Mileage Information',
    headersList: [
      'Current Monthly Mileage',
      `Previous Month's Mileage`,
      'Average Monthly Mileage',
      'Miles Driven (Current Fiscal Year to Date)',
      'Miles Driven (Previous Fiscal Year)',
      'Mileage Description',
      'Mileage Transaction Date',
    ],
  },
  [SECTION_TYPE.billing]: {
    title: 'Billing Information',
    headersList: [
      'Fund Code',
      `Description 1`,
      'Description 2',
      'Monthly Rate',
      'Mileage Rate',
      'Optional Equipment Monthly Rate',
      'Optional Equipment Mileage Rate',
    ],
  },
  [SECTION_TYPE.telematics_information]: {
    title: 'Telematics Information',
    headersList: [
      'Telematics Installed',
      'Serial Number',
      'Customer Subscription Activated',
      'Customer Subscription Activation Date',
      'Customer Subscription Deactivation Date',
      'Package Description',
      'Package Monthly Rate',
      'Ancillary Service/Hardware Description',
      'Ancillary One-Time Fee Ancillary Monthly Rate',
      'Ancillary Activation Date',
      'Telematics Odometer',
      'Telematics Odometer Date',
      'Telematics Odometer Time',
    ],
  },
};
