import React, { useEffect } from 'react';
import { Label, Button } from '@gsa/afp-component-library';
import usePortalModal from '../../../utilities/portal-modal';

const AddressNotFoundModal = ({
  address,
  showError,
  handleAccept,
  handleClose,
}) => {
  const [Modal, openModal, closeModal] = usePortalModal();
  useEffect(() => {
    if (showError) {
      openModal();
    } else {
      closeModal();
    }
  }, [showError]);
  return (
    <>
      <Modal
        variant="large"
        title={
          <>
            <h2>Address not found</h2>
          </>
        }
        actions={
          <>
            <Button
              type="button"
              variant="outline"
              onClick={handleClose}
              label="Edit address"
            />
            <Button type="button" onClick={handleAccept} label="Ok" />
          </>
        }
      >
        <div className="usa-card__container padding-x-1 margin-y-2 cursor-pointer bg-primary-lighter">
          <div className="afp-formatted-address">
            <Label className="margin-bottom-05 margin-top-05">
              Use address as was entered:
            </Label>
            {address?.primaryAddress}
            <br />
            {address?.secondaryAddress ? (
              <>
                {address?.secondaryAddress}
                <br />
              </>
            ) : (
              ''
            )}
            {address?.city}, {address?.stateCode} {address?.postalCode}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddressNotFoundModal;
