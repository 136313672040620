import React, { useEffect, useState } from 'react';
import {
  TextInput,
  Label,
  RequiredFieldIndicator,
} from '@gsa/afp-component-library';
import { get } from 'lodash';
import { useCurrentUser } from '@gsa/afp-shared-ui-utils';
import { Controller, useFormContext } from 'react-hook-form';
import { useMotorPool } from '../../../motor-pool-provider';
import { generateCreateUpdateReservationFields } from './reservation-constants';

const ReservationAddEditFields = () => {
  const {
    reservationModalMode,
    reservationListingSelected,
    selectedMotorPoolById,
  } = useMotorPool();

  const { currentUser } = useCurrentUser();

  const driverFullName = `${currentUser.firstName} ${currentUser.lastName}`;
  const driverName =
    reservationModalMode === 'UPDATE_RESERVATION'
      ? reservationListingSelected.driverName
      : driverFullName;

  const {
    getValues,
    clearErrors,
    setValue,
    formState: { errors },
    control,
    watch,
  } = useFormContext();

  const watchIsOnBehalf = watch('isOnBehalf');

  const extractValue = (value, isField = false) => {
    if (typeof value !== 'boolean') {
      return value;
    }
    if (isField) return value ? 'Yes' : 'No';
    return value ? 'yes' : 'no';
  };

  const isManagesPool = selectedMotorPoolById?.managesPool;

  const CREATE_UPDATE_RESERVATION_FIELDS =
    generateCreateUpdateReservationFields(isManagesPool);
  const [formFields, setFormFields] = useState(
    CREATE_UPDATE_RESERVATION_FIELDS,
  );

  useEffect(() => {
    if (reservationModalMode === 'UPDATE_RESERVATION') {
      // To pre-populate the form with selected row values
      Object.values(formFields).forEach(({ name }) => {
        setValue(name, extractValue(reservationListingSelected[name]));
      });
    }
  }, []);

  useEffect(() => {
    if (watchIsOnBehalf === 'yes') {
      setFormFields(
        generateCreateUpdateReservationFields(isManagesPool, false),
      );
    } else {
      setFormFields(generateCreateUpdateReservationFields(isManagesPool, true));
    }
  }, [watchIsOnBehalf, isManagesPool]);

  /**
   * this use effect takes cares of user switching from yes to no,
   * then it will default the driver name to the current user
   */
  useEffect(() => {
    if (watchIsOnBehalf === 'no') {
      setValue('driverName', driverFullName);
    } else if (reservationModalMode === 'UPDATE_RESERVATION') {
      const onBehalfValue = reservationListingSelected.isOnBehalf
        ? 'yes'
        : 'no';
      if (onBehalfValue === watchIsOnBehalf) {
        setValue('driverName', reservationListingSelected?.driverName);
      } else {
        setValue('driverName', '');
      }
    } else if (watchIsOnBehalf === 'yes') {
      setValue('driverName', '');
    }

    if (watchIsOnBehalf === 'no') {
      clearErrors('driverName');
    }
  }, [watchIsOnBehalf]);

  return (
    <div>
      {Object.values(formFields)
        .filter((fieldsFilter) => fieldsFilter.visible)
        .map((mpField) => {
          if (mpField?.readOnly) {
            return (
              <>
                <div className="text-bold margin-top-2">
                  {mpField?.label}{' '}
                  {mpField?.required && <RequiredFieldIndicator />}
                </div>
                {mpField.name === 'driverName'
                  ? driverName
                  : get(
                      reservationListingSelected,
                      mpField?.dataPath || mpField?.name,
                    )}
              </>
            );
          }
          return (
            <div key={mpField.name} className={mpField.wrapperClass || ''}>
              <Controller
                name={mpField.name}
                control={control}
                defaultValue={
                  mpField.name === 'driverName'
                    ? driverName
                    : null ||
                      get(
                        reservationListingSelected,
                        mpField?.dataPath || mpField?.name,
                      )
                }
                render={({ field, ref }) => {
                  return mpField.component ? (
                    <mpField.component
                      fieldAttrs={mpField}
                      getValues={getValues}
                      setValue={setValue}
                      errors={errors}
                      clearErrors={clearErrors}
                      field={field}
                      ref={ref}
                    />
                  ) : (
                    <TextInput
                      {...field}
                      ref={ref}
                      name={mpField.name}
                      id={mpField.id}
                      type="text"
                      {...(mpField.additionalAttributes || {})}
                      label={
                        <Label
                          required={mpField.required}
                          className="text-bold"
                        >
                          {mpField.label}
                        </Label>
                      }
                      errorMessage={
                        errors && errors[mpField.name]
                          ? errors[mpField.name].message
                          : null
                      }
                      aria-invalid={
                        errors && errors[mpField.name] ? 'true' : 'false'
                      }
                    />
                  );
                }}
              />
            </div>
          );
        })}
    </div>
  );
};

export default ReservationAddEditFields;
