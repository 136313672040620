import React, { createContext, useContext, useReducer } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useModal } from '@gsa/afp-component-library';
import {
  GET_PAGINATED_REPLACEMENT_ORDERS,
  GET_LEASED_VEHICLE_BY_TAG_NUMBER,
  CREATE_REPLACEMENT_ORDER,
  UPDATE_REPLACEMENT_ORDER,
} from '../helpers/fc-replacement.gql';
import { VALIDATE_ADDRESS, EXPORT_REPORT } from '../../../services/data-layer';
import {
  REPLACEMENT_CARD_SUCCESS_MESSAGE,
  REPLACEMENT_CARD_CANCEL_MESSAGE,
  REPLACEMENT_CARD_UPDATE_MESSAGE,
  REPLACEMENT_ORDER_EXPORT_MESSAGE,
} from './consts';

export const replacementContext = createContext(null);

const initialState = {
  alertMessage: { type: '', message: '', context: '' }, // context is either fcReplacementHistory or addReplacementCard
  showModal: false,
  ordersData: [],
  orderData: undefined,
  vehicle: undefined,
  ordersCount: 0,
  addressToValidate: undefined,
  validatedAddress: undefined,
  ordersMaxLimitReached: false,
  selectedAddressOption: 'new',
  selectedContactOption: 'new',
  addressId: undefined,
  contactId: undefined,
  editMode: false,
  addCardConfirmationData: undefined,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_ALERT_MESSAGE':
      return { ...state, alertMessage: action.payload };
    case 'SET_ORDERS_DATA':
      return { ...state, ordersData: action.payload };
    case 'SET_ORDER_DATA':
      return { ...state, orderData: action.payload };
    case 'SET_ORDERS_COUNT':
      return { ...state, ordersCount: action.payload };
    case 'SET_VEHICLE_DATA':
      return { ...state, vehicle: action.payload };
    case 'SET_ORDERS_MAX_LIMIT':
      return { ...state, ordersMaxLimitReached: action.payload };
    case 'SET_ADDRESS_TO_VALIDATE':
      return { ...state, addressToValidate: action.payload };
    case 'SET_VALIDATED_ADDRESS':
      return { ...state, validatedAddress: action.payload };
    case 'SET_SELECTED_ADDRESS_OPTION':
      return { ...state, selectedAddressOption: action.payload };
    case 'SET_SELECTED_CONTACT_OPTION':
      return { ...state, selectedContactOption: action.payload };
    case 'SET_ADDRESS_ID':
      return { ...state, addressId: action.payload };
    case 'SET_CONTACT_ID':
      return { ...state, contactId: action.payload };
    case 'SET_EDIT_MODE':
      return { ...state, editMode: action.payload };
    case 'SET_ADD_CARD_CONFIRMATION_DATA':
      return { ...state, addCardConfirmationData: action.payload };
    default:
      return { ...state };
  }
};

const FcReplacementContextProvider = ({ children, ...rest }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const clearAddress = () => {
    dispatch({
      type: 'SET_ADDRESS_TO_VALIDATE',
      payload: initialState.addressToValidate,
    });
    dispatch({
      type: 'SET_VALIDATED_ADDRESS',
      payload: initialState.validatedAddress,
    });
  };

  const clearAddressAndContactOptions = () => {
    dispatch({
      type: 'SET_SELECTED_ADDRESS_OPTION',
      payload: initialState.selectedAddressOption,
    });
    dispatch({
      type: 'SET_SELECTED_CONTACT_OPTION',
      payload: initialState.selectedContactOption,
    });
  };

  const clearData = () => {
    dispatch({
      type: 'SET_VEHICLE_DATA',
      payload: initialState.vehicle,
    });
    dispatch({
      type: 'SET_ORDER_DATA',
      payload: initialState.orderData,
    });
    dispatch({
      type: 'SET_ALERT_MESSAGE',
      payload: initialState.alertMessage,
    });
    dispatch({
      type: 'SET_EDIT_MODE',
      payload: initialState.editMode,
    });
    clearAddressAndContactOptions();
    clearAddress();
  };

  const {
    isOpen: isAddReplacementCardModalOpen,
    openModal: openAddReplacementCardModal,
    closeModal: closeAddReplacementCardModal,
  } = useModal();

  const {
    isOpen: isAddReplacementCardConfirmationModalOpen,
    openModal: openAddReplacementCardConfirmationModal,
    closeModal: closeAddReplacementCardConfirmationModal,
  } = useModal();

  const [
    getPaginatedReplacementOrders,
    { refetch: paginatedOrdersRefetch, loading: loadingOrders },
  ] = useLazyQuery(GET_PAGINATED_REPLACEMENT_ORDERS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: (err) => {
      dispatch({
        type: 'SET_ALERT_MESSAGE',
        payload: {
          type: 'error',
          message: err.message,
          context: 'fcReplacementHistory',
        },
      });
    },
    onCompleted: ({ getPaginatedReplacementOrders: data }) => {
      dispatch({
        type: 'SET_ORDERS_MAX_LIMIT',
        payload: data?.ordersMaxLimitReached,
      });
      dispatch({
        type: 'SET_ORDERS_DATA',
        payload: data.rows,
      });

      dispatch({
        type: 'SET_ORDERS_COUNT',
        payload: data?.count ?? 0,
      });
    },
  });

  const [
    uspsValidation,
    { loading: loadingValidateAddress, error: validateAddressError },
  ] = useLazyQuery(VALIDATE_ADDRESS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: (err) => {
      dispatch({
        type: 'SET_ALERT_MESSAGE',
        payload: {
          type: 'error',
          message: err.message,
          context: 'fcReplacementHistory',
        },
      });
    },
    onCompleted: ({ validateAddress: data }) => {
      dispatch({
        type: 'SET_VALIDATED_ADDRESS',
        payload: data,
      });
    },
  });

  const validateAddress = ({
    address1,
    address2,
    city,
    stateCode,
    postalCode,
  }) => {
    const payload = {
      address: address1,
      address2,
      city,
      state: stateCode,
      zip: postalCode,
      countryCode: 'US',
    };

    dispatch({
      type: 'SET_ADDRESS_TO_VALIDATE',
      payload,
    });

    uspsValidation({ variables: payload });
  };

  const [getLeasedVehicle, { loading: fetchingVehicleData }] = useLazyQuery(
    GET_LEASED_VEHICLE_BY_TAG_NUMBER,
    {
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
      onError: () => {
        dispatch({
          type: 'SET_ALERT_MESSAGE',
          payload: {
            type: 'error',
            message:
              'Plate number does not exist or you do not have permission to access it.',
            context: 'addReplacementCard',
          },
        });
        dispatch({
          type: 'SET_VEHICLE_DATA',
          payload: 'error',
        });
      },
      onCompleted: (data) => {
        if (data?.getLeasedVehicleByTagNumber) {
          dispatch({
            type: 'SET_VEHICLE_DATA',
            payload: data?.getLeasedVehicleByTagNumber,
          });
          dispatch({
            type: 'SET_ALERT_MESSAGE',
            payload: initialState.alertMessage,
            context: initialState.context,
          });
        } else {
          dispatch({
            type: 'SET_ALERT_MESSAGE',
            payload: {
              type: 'error',
              message:
                'Plate number does not exist or you do not have permission to access it.',
              context: 'addReplacementCard',
            },
          });
          dispatch({
            type: 'SET_VEHICLE_DATA',
            payload: 'error',
          });
          dispatch({
            type: 'SET_ORDER_DATA',
            payload: initialState.orderData,
          });
        }
      },
    },
  );

  const [createReplacementOrder, { loading: submittingReplacementOrder }] =
    useMutation(CREATE_REPLACEMENT_ORDER, {
      onError: (error) => {
        dispatch({
          type: 'SET_ALERT_MESSAGE',
          payload: {
            type: 'error',
            message: error.message,
            context: 'addReplacementCard',
          },
        });
        if (
          error &&
          error?.message.includes('This card was recently ordered')
        ) {
          dispatch({
            type: 'SET_VEHICLE_DATA',
            payload: 'error',
          });
          clearAddressAndContactOptions();
        }
      },
      onCompleted: (response) => {
        const confirmThreeDaysRuleOverwrite =
          response.createReplacementOrder?.confirmThreeDaysRuleOverwrite
            ?.confirm;
        if (confirmThreeDaysRuleOverwrite) {
          dispatch({
            type: 'SET_ADD_CARD_CONFIRMATION_DATA',
            payload: {
              tag: response.createReplacementOrder
                ?.confirmThreeDaysRuleOverwrite?.tagNumber,
              date: response.createReplacementOrder
                ?.confirmThreeDaysRuleOverwrite?.createdAt,
            },
          });
          openAddReplacementCardConfirmationModal();
        } else {
          closeAddReplacementCardModal();
          closeAddReplacementCardConfirmationModal();
          dispatch({
            type: 'SET_ADD_CARD_CONFIRMATION_DATA',
            payload: undefined,
          });
          paginatedOrdersRefetch();
          dispatch({
            type: 'SET_ALERT_MESSAGE',
            payload: {
              type: 'success',
              header: REPLACEMENT_CARD_SUCCESS_MESSAGE.header,
              message: REPLACEMENT_CARD_SUCCESS_MESSAGE.body(),
              context: 'fcReplacementHistory',
            },
          });
          dispatch({
            type: 'SET_VEHICLE_DATA',
            payload: undefined,
          });
          clearAddressAndContactOptions();
          clearAddress();
        }
      },
    });

  const [updateReplacementOrder, { loading: updatingReplacementOrder }] =
    useMutation(UPDATE_REPLACEMENT_ORDER, {
      onError: (error) => {
        dispatch({
          type: 'SET_ALERT_MESSAGE',
          payload: {
            type: 'error',
            message: error.message,
            context: 'fcReplacementHistory',
          },
        });
      },
      onCompleted: (data) => {
        paginatedOrdersRefetch();
        clearData();
        closeAddReplacementCardModal();
        clearAddressAndContactOptions();
        clearAddress();

        const payload = {
          type: 'success',
          context: 'fcReplacementHistory',
        };

        if (data.updateReplacementOrder?.status === 'Canceled') {
          payload.message = REPLACEMENT_CARD_CANCEL_MESSAGE.body(
            data.updateReplacementOrder?.tagNumber,
          );
        } else
          payload.message = REPLACEMENT_CARD_UPDATE_MESSAGE.body(
            data.updateReplacementOrder?.tagNumber,
          );

        dispatch({
          type: 'SET_ALERT_MESSAGE',
          payload,
        });
      },
    });

  const [exportData] = useMutation(EXPORT_REPORT, {
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      dispatch({
        type: 'SET_ALERT_MESSAGE',
        payload: {
          type: 'success',
          header: REPLACEMENT_ORDER_EXPORT_MESSAGE.header,
          message: REPLACEMENT_ORDER_EXPORT_MESSAGE.body(),
          context: 'fcReplacementHistory',
        },
      });
    },
    onError: (error) => {
      dispatch({
        type: 'SET_ALERT_MESSAGE',
        payload: {
          type: 'error',
          message: error.message,
          context: 'fcReplacementHistory',
        },
      });
    },
  });

  return (
    <replacementContext.Provider
      value={{
        ...state,
        dispatch,
        getPaginatedReplacementOrders,
        paginatedOrdersRefetch,
        loadingOrders,
        getLeasedVehicle,
        fetchingVehicleData,
        createReplacementOrder,
        submittingReplacementOrder,
        isAddReplacementCardModalOpen,
        openAddReplacementCardModal,
        closeAddReplacementCardModal,
        isAddReplacementCardConfirmationModalOpen,
        openAddReplacementCardConfirmationModal,
        closeAddReplacementCardConfirmationModal,
        clearData,
        clearAddress,
        updateReplacementOrder,
        updatingReplacementOrder,
        validateAddress,
        loadingValidateAddress,
        validateAddressError,
        exportData,
        ...rest,
      }}
    >
      {children}
    </replacementContext.Provider>
  );
};

FcReplacementContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FcReplacementContextProvider;

// Hook to exposes context value.
export const useFcReplacement = () => useContext(replacementContext);
