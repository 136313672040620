/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable import/prefer-default-export */
import { useSetRecoilState } from 'recoil';
import { genRowActions } from 'utilities/common';
import {
  CurrencyCell,
  MMDDYYYDateFormat,
  ToUTCFormatDate,
  keyByState,
} from 'utilities/format';

import { actionsMap } from 'utilities/consts';
import { handleActionSelector } from '../store/selectors';

export const columns = () => {
  const handleSelectedActionSelector = useSetRecoilState(
    handleActionSelector(),
  );

  const cols = [
    {
      Header: 'Transaction date',
      accessor: 'transactionDate',
      sortable: true,
      Cell: ({ value }) =>
        ToUTCFormatDate({ dateVal: value, format: MMDDYYYDateFormat }),
    },
    {
      Header: 'Product description',
      accessor: 'fleetCardProduct.productDescription',
      sortable: true,
      Cell: ({ row }) =>
        row?.original?.fleetCardProductOnline?.productDescription ||
        row?.original?.fleetCardProduct?.productDescription,
    },
    {
      Header: 'Merchant name',
      accessor: 'fleetCardMerchant.merchantName',
      sortable: true,
      headerClassName: 'width-card-lg',
    },
    {
      Header: 'Merchant state',
      accessor: 'fleetCardMerchant.merchantStateCode',
      headerClassName: 'width-card-lg',
      sortable: false,

      Cell: ({ value }) => `${value}-${keyByState[value]?.stateName}`,
    },
    {
      Header: 'Total cost',
      accessor: 'grossCost',
      sortable: true,
      Cell: CurrencyCell,
    },
  ];

  const handleSelectedAction = (label, original, row) => {
    handleSelectedActionSelector({ label, original, row });
  };
  cols.push({
    Header: 'Actions',
    accessor: 'action',
    sortable: false,
    Cell: (rowData) => {
      const dataSource = rowData?.row?.original?.recordType;
      return genRowActions({
        actions: [actionsMap.EDIT, actionsMap.DELETE],
        rowData,
        onSelectAction: handleSelectedAction,
        skipRoleCheck: true,
        actionFilterFn: (rowActions) => {
          if (dataSource === 'WEX') {
            return rowActions.filter((x) => x.label !== 'Delete');
          }
          return rowActions;
        },
      });
    },
  });

  return cols;
};
