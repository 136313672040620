import React from 'react';
import LpDisassociation from './lp-disassociation';
import { useLicensePlateDetails } from '../license-plate-details-provider';

const LpDisassociationSection = () => {
  const {
    disassociationAlert,
    disassociationPayload,
    setDisassociationPayload,
    disassociateTag,
  } = useLicensePlateDetails();

  return (
    <div className="grid-row margin-bottom-1" data-testid="lp-disassociation">
      <div className="grid-col-fill">
        <LpDisassociation
          disassociationPayload={disassociationPayload}
          setDisassociationPayload={setDisassociationPayload}
          disassociateTag={disassociateTag}
          disassociationAlert={disassociationAlert}
        />
      </div>
    </div>
  );
};

export default LpDisassociationSection;
