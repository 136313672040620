/* eslint-disable filenames/match-exported */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable filenames/match-regex */
import React from 'react';
import { Button, Alert } from '@gsa/afp-component-library';

const DELETE_MOTOR_POOL_MEMBER = {
  title: ({ selectedMotorPoolById }) => (
    <>
      <Alert type="warning" slim className="margin-top-4 margin-bottom-2">
        <span data-testid="motor-pool-warning-alert">
          Removing this member may result in canceling any future reservations
          and any on behalf future reservations will be transferred to{' '}
          <b>
            {selectedMotorPoolById?.primaryDispatcher?.member?.fullName ||
              'primary dispatcher'}
          </b>
          .
        </span>
      </Alert>
      <h2>Remove member</h2>
    </>
  ),
  content: ({ motorPoolMemberSelected }) => (
    <>
      <div>
        Are you sure you want to remove{' '}
        <b>
          {motorPoolMemberSelected?.member?.fullName || 'the selected member'}
        </b>{' '}
        from the pool? Removing the member is a permanent action and cannot be
        undone.
      </div>
    </>
  ),

  action: ({
    resetMotorPoolMemberModal,
    deleteMotorPoolMember,
    motorPoolMemberSelected,
  }) => (
    <>
      <Button
        variant="unstyled"
        className="margin-right-2"
        onClick={resetMotorPoolMemberModal}
        data-testid="motor-pool-members-listing-cancel-button"
        label="Cancel"
      />
      <Button
        variant="secondary"
        onClick={() => {
          deleteMotorPoolMember({
            variables: {
              id: motorPoolMemberSelected.id,
              motorPoolId: motorPoolMemberSelected.motorPoolId,
            },
          });
        }}
        data-testid="motor-pool-member-delete-button"
        form="motor-pool-member-delete-form"
        label="Remove member"
      />
    </>
  ),
};

export default DELETE_MOTOR_POOL_MEMBER;
