import React from 'react';
import { TabSet } from '@gsa/afp-component-library';
import LpComments from './lp-comments';
import LpTagActivityTable from './lp-table-list';
import LpDocuments from './lp-documents';

const LpTabs = () => {
  return (
    <div id="license-plate-history">
      <TabSet
        tabs={[
          {
            heading: 'History',
            content: (
              <div className="grid-row">
                <div className="grid-col-12">
                  <LpTagActivityTable />
                </div>
              </div>
            ),
            tabSelectedWhenOpen: true,
          },
          {
            heading: 'Comments',
            content: (
              <div className="grid-row">
                <div className="grid-col-8">
                  <LpComments />
                </div>
              </div>
            ),
            tabSelectedWhenOpen: false,
          },
          {
            heading: 'Documents',
            content: <LpDocuments />,
            tabSelectedWhenOpen: false,
          },
        ]}
      />
    </div>
  );
};

export default LpTabs;
