import React from 'react';
import PropTypes from 'prop-types';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { formatShortDate } from '../../common';
import moment from 'moment';

const phoneNumberRegExp = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
const phoneExtRegExp = /^\d+$/;
const zipcodeRegExp = /^\d{5}$/;

export const FORM_FIELDS = {
  transactionDetails: {
    date: { name: 'transactionDate' },
    invoiceNumber: { name: 'invoiceNumber' },
    transactionCompleted: { name: 'transactionCompleted' },
    mileageAtRepair: { name: 'mileageAtRepair' },
  },
  repairOrder: {
    repairDate: { name: 'repairDate' },
    repairOrderNumber: { name: 'repairOrderNumber' },
  },
  vendorInfo: {
    vendorDescription: { name: 'vendorDescription' },
    vendorName: { name: 'vendorName' },
    vendorPhone: { name: 'vendorPhone' },
    vendorPhoneExt: { name: 'vendorPhoneExt' },
    vendorAddress1: { name: 'vendorAddress1' },
    vendorAddress2: { name: 'vendorAddress2' },
    vendorCity: { name: 'vendorCity' },
    vendorState: { name: 'vendorState' },
    vendorZipcode: { name: 'vendorZipcode' },
  },
  paymentInfo: {
    billedTo: { name: 'billedTo' },
    paymentStatus: { name: 'paymentStatus' },
    paymentForm: { name: 'paymentForm' },
    postedDate: { name: 'postedDate' },
  },
};

const maxDate = new Date();
maxDate.setHours(23, 59, 59, 0);

const formSchema = yup.object().shape({
  [FORM_FIELDS.transactionDetails.date.name]: yup
    .date()
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .required(
      'The transaction Date must be provided before the repair can be saved',
    )
    .test('valid_date_format', 'Please enter a valid date', (value) => {
      if (!value) {
        return false;
      }
      const m = `${value.getMonth() + 1}`.padStart(2, '0');
      const d = `${value.getDate()}`.padStart(2, '0');
      const dt = `${m}/${d}/${value.getFullYear()}`;
      return moment(dt, 'MM/DD/YYYY', true).format() !== 'Invalid date';
    })
    .max(maxDate, `Max date is ${formatShortDate(maxDate)}`),
  [FORM_FIELDS.transactionDetails.mileageAtRepair.name]: yup
    .string()
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .test('valid_mileage_format', 'Enter a valid mileage', (value) => {
      return value.match(/^[0-9]*$/);
    })
    .max(maxDate, `Max date is ${formatShortDate(maxDate)}`),
  [FORM_FIELDS.vendorInfo.vendorPhone.name]: yup
    .string()
    .nullable()
    .trim()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .matches(phoneNumberRegExp, 'Enter a valid phone number'),
  [FORM_FIELDS.vendorInfo.vendorPhoneExt.name]: yup
    .string()
    .nullable()
    .trim()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .matches(phoneExtRegExp, 'Enter a valid extension number'),
  [FORM_FIELDS.vendorInfo.vendorZipcode.name]: yup
    .string()
    .nullable()
    .trim()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .matches(zipcodeRegExp, 'Enter a valid zip code'),
});

const RepairFormContext = ({ children, data }) => {
  const { transactionDetails, repairOrder, vendorInfo, paymentInfo } =
    data || {};
  const inputData = {
    // Transaction details
    [FORM_FIELDS.transactionDetails.date.name]: transactionDetails?.date || '',
    [FORM_FIELDS.transactionDetails.invoiceNumber.name]:
      transactionDetails?.invoiceNumber || '',
    [FORM_FIELDS.transactionDetails.transactionCompleted.name]:
      transactionDetails?.transactionCompleted || 'No',
    [FORM_FIELDS.transactionDetails.mileageAtRepair.name]:
      transactionDetails?.mileageAtRepair || 0,

    // Repair order number
    [FORM_FIELDS.repairOrder.repairDate.name]: repairOrder?.repairDate || '',
    [FORM_FIELDS.repairOrder.repairOrderNumber.name]:
      repairOrder?.repairOrderNumber || '',

    // Vendor information
    [FORM_FIELDS.vendorInfo.vendorDescription.name]:
      vendorInfo?.vendorDescription || '',
    [FORM_FIELDS.vendorInfo.vendorName.name]: vendorInfo?.vendorName || '',
    [FORM_FIELDS.vendorInfo.vendorPhone.name]: vendorInfo?.vendorPhone || '',
    [FORM_FIELDS.vendorInfo.vendorPhoneExt.name]:
      vendorInfo?.vendorPhoneExt || '',
    [FORM_FIELDS.vendorInfo.vendorAddress1.name]:
      vendorInfo?.vendorAddress1 || '',
    [FORM_FIELDS.vendorInfo.vendorAddress2.name]:
      vendorInfo?.vendorAddress2 || '',
    [FORM_FIELDS.vendorInfo.vendorCity.name]: vendorInfo?.vendorCity || '',
    [FORM_FIELDS.vendorInfo.vendorState.name]: vendorInfo?.vendorState || '',
    [FORM_FIELDS.vendorInfo.vendorZipcode.name]:
      vendorInfo?.vendorZipcode || '',

    // Payment information
    [FORM_FIELDS.paymentInfo.billedTo.name]: paymentInfo?.billedTo || '',
    [FORM_FIELDS.paymentInfo.paymentForm.name]: paymentInfo?.paymentForm || '',
    [FORM_FIELDS.paymentInfo.paymentStatus.name]:
      paymentInfo?.paymentStatus || '',
    [FORM_FIELDS.paymentInfo.postedDate.name]: paymentInfo?.postedDate || '',
  };

  const methods = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: inputData,
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  return <FormProvider {...methods}>{children}</FormProvider>;
};

RepairFormContext.defaultProps = {};

RepairFormContext.propTypes = {
  children: PropTypes.node.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object,
};

export default RepairFormContext;
