/* eslint-disable filenames/match-exported */
/* eslint-disable filenames/match-regex */
/* eslint-disable react/prop-types */

import React from 'react';
import { Label, RequiredFieldIndicator } from '@gsa/afp-component-library';
import { useRecoilState, useSetRecoilState } from 'recoil';
import './MilesInputField.css';
import NumberFormat from 'react-number-format';

// eslint-disable-next-line import/prefer-default-export
const MilesInputField = ({
  pageId = 'DEFAULT',
  selectors,
  id,
  dependency = null,
  required,
  label,
  gridStyle,
  isVisible,
  disabled,
  ...restProps
}) => {
  const { fieldSelector, fieldAfterSelector = null } = selectors;
  const [fieldValueState, setFieldValueState] = useRecoilState(
    fieldSelector({ pageId, id }),
  );

  let afterFieldUpdated = ({ value }) => value;
  if (fieldAfterSelector) {
    afterFieldUpdated = useSetRecoilState(fieldAfterSelector({ pageId, id }));
  }

  const hasError = fieldValueState?.errorMessage;
  return (
    <div
      className={`usa-form-group ${hasError ? 'usa-form-group--error' : null}`}
    >
      <Label
        htmlFor={`${id}-id`}
        className={`${hasError ? 'usa-label--error' : null}`}
      >
        <span>
          {label} {required && <RequiredFieldIndicator />}
        </span>
      </Label>
      {hasError && (
        <span className="usa-error-message">
          {fieldValueState.errorMessage}
        </span>
      )}
      <div className="miles-input">
        <span className="prefix-icon-miles">miles</span>
        <NumberFormat
          className={`usa-input ${hasError ? 'usa-input--error' : null}`}
          id={`${id}-id`}
          data-testid={id}
          onValueChange={(val) => {
            setFieldValueState({ value: val.value, dependency });
          }}
          thousandsGroupStyle="thousand"
          fixedDecimalScale
          decimalScale={0}
          decimalSeparator="."
          displayType="input"
          type="text"
          thousandSeparator
          disabled={fieldValueState?.disabled || disabled}
          allowNegative
          aria-invalid={hasError ? 'true' : 'false'}
          value={fieldValueState.value}
          onBlur={() => {
            afterFieldUpdated({ value: fieldValueState.value });
          }}
          {...restProps}
        />
      </div>
    </div>
  );
};

export default MilesInputField;
