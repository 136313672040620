import { useReducer } from 'react';

const INITIAL_FORM_STATE = {
  regular: {
    vin: null,
    makeCode: null,
    makeName: null,
    modelCode: null,
    modelName: null,
    modelYear: null,
    makeColorName: null,
    colorName: null,
    fuelCode: null,
    tagNumber: null,
    statePlate: null,
    tagExpirationDate: null,
    agencyCode: null,
    bureauCode: null,
    subSectionCode: null,
    fastReportable: null,
    subSectionTypeCode: null,
    originalAcquisitionDate: null,
    pointOfContact1: {
      email: null,
      firstName: null,
      lastName: null,
      primaryAddress: null,
      secondaryAddress: null,
      city: null,
      stateCode: null,
      countryCode: null,
      postalCode: null,
      primaryPhone: null,
      primaryPhoneExt: null,
    },
    pointOfContact2: {
      email: null,
      firstName: null,
      lastName: null,
      primaryAddress: null,
      secondaryAddress: null,
      city: null,
      stateCode: null,
      countryCode: null,
      postalCode: null,
      primaryPhone: null,
      primaryPhoneExt: null,
    },
    modelTypeCode: null,
  },
  manual: {
    mode: 'manual',
    unverifiedVin: null,
    internationalVin: null,
    pre1981: null,
    nhtsaVerified: null,
    exemptPlate: null,
  },
};
const stateReducer = (state, updates) => ({ ...state, ...updates });

export default function registrationFormState() {
  const [state, setState] = useReducer(
    stateReducer,
    INITIAL_FORM_STATE.regular,
  );

  const initFormStateWithVehicle = (vehicle) => {
    const {
      id: vin,
      modelYear,
      vehicleMake,
      makeColor,
      fuelCode,
      vehicleModel,
      tagNumber,
      tag,
    } = vehicle;
    const { name: makeColorName } = makeColor || {};
    const { makeName, makeCode } = vehicleMake || {};
    const { modelCode, modelName } = vehicleModel || {};
    const { expirationDate } = tag || {};

    setState({
      vin,
      makeCode,
      makeName,
      modelCode,
      modelName,
      modelYear,
      makeColorName,
      fuelCode,
      tagNumber,
      statePlate: null,
      tagExpirationDate: expirationDate,
    });
  };

  const initManualFormState = () => {
    setState({
      ...INITIAL_FORM_STATE.regular,
      ...INITIAL_FORM_STATE.manual,
    });
  };

  return [state, setState, initFormStateWithVehicle, initManualFormState];
}
