import { Button } from '@gsa/afp-component-library';
import { useMutation } from '@apollo/client';
import { useLicensePlateDestruction } from '../context';
import { CREATE_TAG_DESTRUCTION } from '../../../services/data-layer';
import {
  convertToPdfPayload,
  setWindowLocation,
} from '../helpers/destruction-helper';

const DownloadPdfButton = () => {
  const { destructionInitData, forDestructionList } =
    useLicensePlateDestruction();
  const [createTagDestruction] = useMutation(CREATE_TAG_DESTRUCTION, {
    fetchPolicy: 'no-cache',
  });

  const createTagDestructionPDF = async () => {
    const payload = convertToPdfPayload({
      ...destructionInitData,
      trackingNumber:
        destructionInitData.trackingNumbers[
          destructionInitData.trackingNumbers.length - 1
        ] || destructionInitData.trackingNumber,
      forDestructionList,
    });
    if (
      payload.tagsDetails &&
      Array.isArray(payload.tagsDetails) &&
      payload.tagsDetails.length
    ) {
      const response = await createTagDestruction({
        variables: {
          data: payload,
          tags: payload.tagsDetails.map((tag) => tag.id),
        },
      });
      setWindowLocation(response?.data?.createTagDestructions);
    }
  };

  return (
    <Button
      className="margin-top-3"
      onClick={createTagDestructionPDF}
      label="Download license plate return form (.pdf)"
    />
  );
};

export default DownloadPdfButton;
