/* eslint-disable react/prop-types */
import React from 'react';
import { Menu, StatusBadge, ActivityTracker } from '@gsa/afp-component-library';
import { get } from 'lodash';
import { recallStatuses } from 'utilities/consts';
import { formatDate } from 'utilities/format';
import { getNhtsaLink } from 'components/recalls/recall-campaign-detail/helper';
import Clamp from 'components/common/clamp/clamp';
import { emdash } from 'components/common';

const classNm =
  'detail-row display-flex flex-justify padding-y-1 border-bottom border-width-2px border-base-lighter';
const DetailRow = ({ label, value, className }) => (
  <div className={className}>
    <div className="detail-title">
      <strong>{label}</strong>
    </div>
    <div>{value}</div>
  </div>
);

export const VehicleDescription = ({ original }) => {
  return (
    <div>
      <div data-testid="recall-description">
        <h5 className="title-s margin-top-0">Campaign</h5>
        <p>{original?.description}</p>
      </div>
      <div data-testid="recall-description">
        <h5 className="title-s margin-top-0">Description</h5>
        <p>{original?.recallCampaign?.detailDescription || emdash}</p>
      </div>
      <div className="grid-row grid-gap grid-col-12">
        <div className="grid-col-6">
          <div className={classNm}>
            <div className="detail-title">
              <strong>Safety risk</strong>
            </div>
            <div>
              {original?.recallCampaign?.safetyRisk ? (
                <span className="margin-left-3 float-right align-right">
                  <Clamp
                    text={original?.recallCampaign?.safetyRisk}
                    maxCharLength={30}
                  />
                </span>
              ) : (
                emdash
              )}
            </div>
          </div>
        </div>
        <div className="grid-col-6">
          <div className={classNm}>
            <div className="detail-title">
              <strong>Remedy</strong>
            </div>
            <div>
              {original?.recallCampaign?.remedyDescription ? (
                <span className="margin-left-3 float-right align-right">
                  <Clamp
                    text={original?.recallCampaign?.remedyDescription}
                    maxCharLength={30}
                  />
                </span>
              ) : (
                emdash
              )}
            </div>
          </div>
        </div>
        <div className="grid-col-6">
          <DetailRow
            label="NHTSA recall ID"
            value={getNhtsaLink(original?.recallCampaign)}
            className={classNm}
          />
        </div>
        <div className="grid-col-6">
          <DetailRow
            label="Recall type"
            value={original?.recallCampaign?.recallType || emdash}
            className={classNm}
          />
        </div>
        <div className="grid-col-6">
          <DetailRow
            label="GSA notified date"
            value={formatDate(original?.gsaRecallNotifiedDate)}
            className={classNm}
          />
        </div>
        <div className="grid-col-6">
          <DetailRow
            label="OEM closed date"
            value={formatDate(original?.closedDate)}
            className={classNm}
          />
        </div>
        <div className="grid-col-6">
          <DetailRow
            label="Remedy date entered by"
            value={original?.closedByUserInfo?.fullName || emdash}
            className={classNm}
          />
        </div>
      </div>
      {original?.comments?.length > 0 && (
        <div data-testid="recall-comments">
          <h5 className="title-s">Comments</h5>
          <ActivityTracker
            className="margin-top-neg-3 margin-left-neg-1 recall-comments"
            activities={original?.comments}
            variant="page"
          />
        </div>
      )}
    </div>
  );
};

export const RecallRowActionButton = ({ onClick, menuItems, rowItem }) => {
  if (menuItems?.length === 0) {
    return emdash;
  }
  return (
    <div className="height-4 width-4">
      <Menu
        onActionClick={(action) => {
          onClick(action, rowItem);
        }}
        menuItems={menuItems}
        iconColor="text-primary"
      />
    </div>
  );
};

export const RecallStatus = ({ status }) => {
  return (
    <StatusBadge variant={get(recallStatuses, [status, 'color'])}>
      <span className="text-no-wrap">
        {get(recallStatuses, [status, 'status'])}
      </span>
    </StatusBadge>
  );
};
