import React from 'react';
import { StatusBadge } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';

const ORDER_STATUS_COLORS = {
  Processing: 'Info-Gray',
  Pending: 'Warning-Gray',
  Canceled: 'Inactive-Gray',
  Ordered: 'Ready-Gray',
};

export default function OrderStatusBadge({ orderStatus }) {
  return (
    <StatusBadge variant={ORDER_STATUS_COLORS[orderStatus]}>
      {orderStatus}
    </StatusBadge>
  );
}

OrderStatusBadge.propTypes = {
  orderStatus: PropTypes.string.isRequired,
};
