import React, { useState, useCallback, useEffect } from 'react';
import moment from 'moment';
import useUser from 'utilities/use-user';
import {
  AFPTable,
  EmptyState,
  Spinner,
  Pagination,
  StatusBadge,
  Tooltip,
  Icon,
  Tag,
} from '@gsa/afp-component-library';
import { useLazyQuery } from '@apollo/client';
import { emdash } from 'components/common';
import Clamp from 'components/common/clamp/clamp';
import { GET_RECALLS } from '../../../../services/data-layer';
import { useRecallFilter, useRecallPage } from '../providers';

const DEFAULT_PAGE_PROPS = {
  itemsCount: 0,
  currentPage: 1,
  itemsPerPage: 10,
  isReset: false,
};

const DetailRow = ({ label, value, className }) => (
  <div className="detail-row display-flex flex-justify padding-y-1 border-bottom border-width-2px border-base-lighter">
    <div className="detail-title">
      <strong>{label}</strong>
    </div>
    <div className={`detail-value ${className}`}>{value}</div>
  </div>
);

const RecallTable = () => {
  const [tableData, setTableData] = useState([]);
  const [order, setOrder] = useState(
    'recallCampaign.should_stop_drive DESC, recallCampaign.should_park_outside DESC',
  );
  const [pageProps, setPageProps] = useState(DEFAULT_PAGE_PROPS);
  const updatePagination = (newProps) =>
    setPageProps((currentProps) => ({
      ...currentProps,
      ...newProps,
    }));

  const { filters } = useRecallFilter();
  const { setRecallCount, setBannerMsg } = useRecallPage();

  // update recalls count to be used by Export
  useEffect(() => {
    setRecallCount(pageProps.itemsCount);
  }, [pageProps.itemsCount]);

  // Query to fetch recalls
  const [getRecallData, { loading }] = useLazyQuery(GET_RECALLS, {
    onError: () =>
      setBannerMsg({
        type: 'error',
        message: 'Error occured while retrieving recall data.',
      }),
    onCompleted: ({ getRecalls }) => {
      setBannerMsg(null);
      setTableData(getRecalls.rows);
      updatePagination({ itemsCount: getRecalls.count });
    },
    fetchPolicy: 'network-only',
  });
  let filterData;
  if (filters?.conditions?.[0]?.legacyCustomerNumber) {
    filterData = filters?.conditions[0]?.legacyCustomerNumber;
  } else {
    filterData = filters;
  }

  const fetchData = (currPage, limit) => {
    const variables = {
      limit,
      offset: limit * (currPage - 1),
      order,
      filters: filterData,
    };
    getRecallData({ variables });
  };

  const isAgencySelected = () =>
    filters?.conditions?.some(
      (c) => c.key === '$vehicle.customer.customer_agency_code$',
    );
  const resetPageProps = { currentPage: 1, isReset: true };
  const gsaEmployee = useUser().isGsaEmployee();

  useEffect(() => {
    // For GSA employees, there is no required filter, but at least one has to be selected to fetch results
    if (gsaEmployee) {
      if (filters?.conditions?.length > 0) {
        updatePagination(resetPageProps);
        fetchData(1, pageProps.itemsPerPage);
      } else {
        setTableData([]);
        updatePagination({ itemsCount: 0 });
      }
    } else {
      // For NON-GSA employees, the Agency filter is required to fetch any results
      if (isAgencySelected()) {
        updatePagination(resetPageProps);
        fetchData(1, pageProps.itemsPerPage);
      } else {
        setTableData([]);
        updatePagination({ itemsCount: 0 });
      }
    }
  }, [filters]);

  useEffect(() => {
    if (tableData?.length) {
      updatePagination(resetPageProps);
      fetchData(1, pageProps.itemsPerPage);
    }
  }, [order]);

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    if (itemsPerPage !== pageProps.itemsPerPage) {
      updatePagination({ ...resetPageProps, itemsPerPage });
      fetchData(1, itemsPerPage);
    } else if (currentPage !== pageProps.currentPage) {
      updatePagination({ currentPage, isReset: false });
      fetchData(currentPage, itemsPerPage);
    }
  };

  const onSort = (sort) => {
    if (sort) {
      const segs = sort.split(' ');
      const col = segs[0].replace(/`/g, '');
      setOrder([[...col.split('.'), segs[1]]]);
    } else {
      setOrder(null);
    }
  };

  const getUSDateStr = (isoDateStr) =>
    isoDateStr ? moment(isoDateStr).utc().format('MM/DD/YYYY') : emdash;

  const getCell = ({ value }) => value || emdash;

  const tableHeaderData = [
    {
      Header: 'Campaign',
      accessor: 'recallCampaign.recallCode',
      headerClassName: 'width-card-lg',
      sortable: false,
      // eslint-disable-next-line
      Cell: ({ value, row: { original } }) => {
        return (
          <>
            {value || emdash}
            <br />
            {/* eslint-disable-next-line */}
            {value && original?.recallCampaign?.shouldStopDrive && (
              <div>
                <Tag key="Urgent-Dark" variant="Urgent-Dark">
                  STOP DRIVE
                </Tag>
              </div>
            )}
            {/* eslint-disable-next-line */}
            {value && original?.recallCampaign?.shouldParkOutside && (
              <div>
                <Tag
                  key="Important-Dark"
                  variant="Important-Dark"
                  className="text-no-wrap"
                >
                  PARK OUTSIDE
                </Tag>
              </div>
            )}
          </>
        );
      },
    },
    {
      Header: 'VIN',
      accessor: 'vehicle.vin',
      headerClassName: 'width-card-lg',
      sortable: false,
      // eslint-disable-next-line
      Cell: ({ value }) => (
        <a
          href={`${window.AFP_CONFIG.appURLs.vms}/vehicles/${encodeURIComponent(
            value,
          )}/recalls`}
          aria-label={`show vehicle information for vin number ${value} in a new window`}
        >
          {value}
        </a>
      ),
    },
    {
      Header: 'License Plate Number',
      accessor: 'vehicle.tagNumber',
      headerClassName: 'width-card',
      sortable: false,
      Cell: getCell,
    },
    {
      Header: (
        <div className="display-flex flex-align-center">
          Remedy available
          <Tooltip
            className="usa-button--unstyled"
            label={
              'Remedy Available: "Yes" means \n a recall  repair or parts are \n available. Contact your local dealership \n to verify and schedule an appointment.'
            }
            position="left"
          >
            <Icon
              iconName="info"
              className="text-primary margin-left-1 text-ink"
            />
          </Tooltip>
        </div>
      ),
      accessor: 'recallCampaign.isRemedyAvailable',
      headerClassName: 'width-card-lg',
      sortable: true,
      Cell: ({ value }) => (value ? 'Yes' : 'No'),
    },
    {
      Header: 'Status',
      accessor: 'status',
      headerClassName: 'width-card-lg',
      sortable: true,
      // eslint-disable-next-line
      Cell: ({ value }) =>
        value === 'Open' ? (
          <StatusBadge variant="Ready-Gray"> Open </StatusBadge>
        ) : (
          <StatusBadge variant="Inactive-Gray"> Closed </StatusBadge>
        ),
    },
  ];

  const renderRowSubComponent = useCallback(({ row: { original = {} } }) => {
    const OWNERSHIP_TYPE_MAPPING = {
      GF: 'GSA Leased',
      AO: 'Agency Owned',
    };
    return (
      <div className="grid-container">
        <div className="grid-row">
          <div className="grid-col-12 margin-bottom-2">
            <b>Campaign</b>
          </div>
          <div className="grid-col-12 margin-bottom-2">
            {original.recallCampaign?.recallDescription || emdash}
          </div>
        </div>
        <div className="grid-row">
          <div className="grid-col-12 margin-bottom-2">
            <b>Description</b>
          </div>
          <div className="grid-col-12 margin-bottom-2">
            {original.recallCampaign?.detailDescription || emdash}
          </div>
        </div>
        <div className="grid-row grid-gap margin-bottom-1">
          <div className="grid-col-6">
            <div className="detail-row display-flex flex-justify padding-y-1 border-bottom border-width-2px border-base-lighter">
              <div className="detail-title">
                <strong>Safety risk</strong>
              </div>
              <div>
                {original?.recallCampaign?.safetyRisk ? (
                  <span className="margin-left-3 float-right align-right">
                    <Clamp
                      text={original?.recallCampaign?.safetyRisk}
                      maxCharLength={30}
                    />
                  </span>
                ) : (
                  emdash
                )}
              </div>
            </div>
          </div>
          <div className="grid-col-6">
            <div className="detail-row display-flex flex-justify padding-y-1 border-bottom border-width-2px border-base-lighter">
              <div className="detail-title">
                <strong>Remedy</strong>
              </div>
              <div>
                {original?.recallCampaign?.remedyDescription ? (
                  <span className="margin-left-3 float-right align-right">
                    <Clamp
                      text={original?.recallCampaign?.remedyDescription}
                      maxCharLength={30}
                    />
                  </span>
                ) : (
                  emdash
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="grid-row grid-gap margin-bottom-1">
          <div className="grid-col-6">
            <DetailRow
              label="NHTSA recall ID"
              value={original.recallCampaign?.nhtsaCampaignId || emdash}
            />
          </div>
          <div className="grid-col-6">
            <DetailRow
              label="GSA notified date"
              value={getUSDateStr(original?.gsaRecallNotifiedDate)}
            />
          </div>
        </div>
        <div className="grid-row grid-gap margin-bottom-1">
          <div className="grid-col-6">
            <DetailRow
              label="NHTSA URL"
              value={
                original?.recallCampaign.recallUrl ? (
                  <a target="blank" href={original.recallCampaign.recallUrl}>
                    URL
                  </a>
                ) : (
                  emdash
                )
              }
            />
          </div>
          <div className="grid-col-6">
            <DetailRow
              label="Remedy date"
              value={getUSDateStr(original.gsaNotifiedDate)}
            />
          </div>
        </div>
        <div className="grid-row grid-gap margin-bottom-2">
          <div className="grid-col-6">
            <DetailRow
              label="Ownership type"
              value={
                original?.vehicle?.ownershipTypeCode
                  ? OWNERSHIP_TYPE_MAPPING[original.vehicle.ownershipTypeCode]
                  : emdash
              }
            />
          </div>
          <div className="grid-col-6">
            <DetailRow
              label="OEM closed date"
              value={getUSDateStr(original.closedDate)}
            />
          </div>
        </div>
      </div>
    );
  }, []);

  const noResultsText = (
    <>
      <p className="text-bold">No recalls showing</p>
      <p>
        Add or remove filters to search recalls.
        {gsaEmployee ? '' : 'Agency selection is required.'}
      </p>
    </>
  );
  return (
    <>
      <AFPTable
        columns={tableHeaderData}
        renderRowSubComponent={renderRowSubComponent}
        data={loading ? [] : tableData || []}
        testId="afp-recall-table-test-id"
        fullWidth
        bordered={false}
        defaultSort="campaign asc"
        expandable
        scrollable={false}
        stacked
        onSort={onSort}
      />
      {loading && <Spinner className="padding-y-9" />}
      {!loading &&
        (tableData.length ? (
          <Pagination
            variant="advanced"
            itemsPerPage={pageProps.itemsPerPage}
            itemsCount={pageProps.itemsCount}
            currentPage={pageProps.currentPage}
            isReset={pageProps.isReset}
            onPageChange={handlePaginationChange}
          />
        ) : (
          <div className="bg-gray-3 padding-y-5">
            <div className="text-center padding-y-4">
              <EmptyState
                alt="Image not available"
                hasBackground
                bottomText={noResultsText}
              />
            </div>
          </div>
        ))}
    </>
  );
};

export default RecallTable;
