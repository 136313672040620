import { gql } from '@apollo/client';

export const GET_PAGINATED_REPLACEMENT_ORDERS = gql`
  query GetPaginatedReplacementOrders(
    $offset: Float
    $order: OrderBy
    $limit: Float
    $filters: [Filter!]!
  ) {
    getPaginatedReplacementOrders(
      offset: $offset
      order: $order
      limit: $limit
      filters: $filters
    ) {
      rows {
        id
        tagNumber
        status
        reason
        createdAt
        comment
        customer {
          customerId
          accountName
          primaryContactAssociation {
            pointOfContact {
              email: pocEmailAddress
              firstName: pocFirstName
              lastName: pocLastName
              fullName: pocFullName
              phone: pocPhone1
              extension: pocPhone1Ext
              address1: pocAddress1
              address2: pocAddress2
              address3: pocAddress3
              city: pocCity
              postalCode: pocPostalCode
              stateCode: pocStateCode
              usStateId
            }
          }
        }
        addressSelection
        contactSelection
        address {
          id
          agency
          address1
          address2
          city
          stateCode
          postalCode
        }
        contact {
          id
          firstName
          lastName
          phoneNumber
          extension
          email
        }
        archivedContact {
          agency
          address1
          address2
          city
          stateCode
          postalCode
          countryCode
          firstName
          lastName
          email
          phoneNumber
          extension
        }
        canceledByUser {
          firstName
          lastName
        }
        canceledAt
      }
      hasMore
      count
      ordersMaxLimitReached
    }
  }
`;

export const GET_LEASED_VEHICLE_BY_TAG_NUMBER = gql`
  query GetLeasedVehicleByTagNumber($tagNumber: String!) {
    getLeasedVehicleByTagNumber(tagNumber: $tagNumber) {
      customer {
        customerId
        accountName
        primaryContactAssociation {
          pointOfContact {
            email: pocEmailAddress
            firstName: pocFirstName
            lastName: pocLastName
            fullName: pocFullName
            phone: pocPhone1
            extension: pocPhone1Ext
            address1: pocAddress1
            address2: pocAddress2
            address3: pocAddress3
            city: pocCity
            postalCode: pocPostalCode
            stateCode: pocStateCode
            usStateId
          }
        }
      }
      lastFcOrder {
        addressSelection
        contactSelection
        address {
          id
          address1: agency
          address2: address1
          address3: address2
          city
          stateCode
          postalCode
        }
        contact {
          id
          firstName
          lastName
          phoneNumber
          extension
          email
        }
      }
    }
  }
`;

export const CREATE_REPLACEMENT_ORDER = gql`
  mutation CreateReplacementOrder(
    $fleetCardReplacementOrderInput: FleetCardReplacementOrderInput!
  ) {
    createReplacementOrder(
      fleetCardReplacementOrderInput: $fleetCardReplacementOrderInput
    ) {
      replacementOrder {
        tagNumber
      }
      confirmThreeDaysRuleOverwrite {
        confirm
        tagNumber
        createdAt
      }
    }
  }
`;

export const UPDATE_REPLACEMENT_ORDER = gql`
  mutation UpdateReplacementOrder(
    $fleetCardReplacementOrderInput: FleetCardReplacementOrderInput!
  ) {
    updateReplacementOrder(
      fleetCardReplacementOrderInput: $fleetCardReplacementOrderInput
    ) {
      tagNumber
      status
    }
  }
`;
