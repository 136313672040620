import React, { useEffect } from 'react';
import { FilterPanel } from '@gsa/afp-component-library';
import { useMotorPoolFilter } from '../../filters/filter-provider';
import { useMotorPool } from '../../motor-pool-provider';
import { generateMPMembersFilterStructure } from '../../filters/motor-pool-filter-structure';

const MembersSidebar = () => {
  const { selectedMotorPoolById } = useMotorPool();
  const {
    filterStructure,
    setFilters,
    setStructure,
    getMemberEmails,
    typeaheadData,
  } = useMotorPoolFilter();

  const setMembersListingFilters = (filters) => {
    setFilters({
      context: 'membersListing',
      filters,
    });
  };

  useEffect(() => {
    setStructure({
      context: 'membersListing',
      filterStructure: generateMPMembersFilterStructure(),
    });
  }, []);

  const handleTypeaheadSearch = ({ variables }) => {
    const { conditions } = variables.filters[0];
    const query = conditions.filter((c) => c.key === variables.field)[0]?.value;
    if (variables.field === '$member.email$') {
      getMemberEmails({
        variables: {
          motorPoolId: selectedMotorPoolById?.id,
          limit: 10,
          filters: {
            operator: '$and',
            conditions: [
              {
                operator: '$like',
                key: '$member.email$',
                value: query,
              },
            ],
          },
        },
      });
    }
  };

  if (filterStructure?.membersListing?.length > 0) {
    return (
      <FilterPanel.FilterPanel
        filterStructure={filterStructure.membersListing}
        setQueryFiltersState={
          (filters) => setMembersListingFilters(filters)
          // eslint-disable-next-line react/jsx-curly-newline
        }
        clearButtonLabel="Reset all"
        showClearIcon
        order={[['updateAt', 'DESC']]}
        fetchTypeaheads={handleTypeaheadSearch}
        typeaheadData={typeaheadData}
        showSearchIcon
      />
    );
  }
  return null;
};

export default MembersSidebar;
