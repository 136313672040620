import React from 'react';
import { Link } from 'react-router-dom';
import { AFPTable } from '@gsa/afp-component-library';
import tableHeaderStructure from '../../license-plate/table-widgets/table-header';
import TagStatus from '../../license-plate/table-widgets/tag-status-badge';
import LicenseDetails from '../../license-plate/table-widgets/license-details';
import { LicenseAorB } from '../../license-plate/widgets/license-plate-table';
import { useLicensePlateDestruction } from '../context';
import { formatTagExpDate } from '../../../utilities/common';

export default function DestructionTable() {
  const { forDestructionList, setForDestructionList } =
    useLicensePlateDestruction();
  const removeDestructionRow = (rowIdx) => {
    const removalRows = [...forDestructionList];
    removalRows.splice(rowIdx, 1);
    setForDestructionList(removalRows);
  };

  if (forDestructionList) {
    const tableData = forDestructionList.map((row, rowIdx) => {
      return {
        id: (
          <Link to={`/license-plate/${encodeURIComponent(row.id)}`}>
            {row.id}
          </Link>
        ),
        tagActivityTypeCode: <TagStatus tagStatus={row?.tagActivityTypeCode} />,
        aOrB: <LicenseAorB value={row.aOrB} />,
        expirationDate: formatTagExpDate(row.expirationDate),
        customerAgencyCode: `${
          row?.customerAgencyCode ? row?.customerAgencyCode : '- '
        } - ${row?.agency?.name ? row?.agency?.name : ' -'}`,
        action: (
          <button
            type="button"
            className={`usa-button display-flex flex-align-center usa-button--unstyled`}
            onClick={() => {
              removeDestructionRow(rowIdx);
            }}
          >
            Remove
          </button>
        ),
      };
    });

    return (
      <div className="plate-content flex-10">
        <AFPTable
          columns={tableHeaderStructure}
          data={tableData}
          testId={'afp-destruction-table-test-id'}
          sortable={false}
          fullWidth={true}
          bordered={false}
          scrollable={false}
          stacked={true}
          renderRowSubComponent={LicenseDetails}
        />
      </div>
    );
  }

  return <div className="plate-content flex-10" />;
}
