import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FilterPanel } from '@gsa/afp-component-library';
// eslint-disable-next-line import/no-cycle
import { useVehicleInventoryFilter } from '../../vehicle-inventory/vehicle-inventory-filter-provider';

const { FilterPanelField, FilterPanelFilterShape, useFilterPanel } =
  FilterPanel;

const CustomerAccountFilterItem = ({ filter }) => {
  const { customerNumber } = useVehicleInventoryFilter();
  const { clearOneFilter } = useFilterPanel();

  useEffect(() => {
    if (!customerNumber) {
      clearOneFilter(filter);
    }
  }, [customerNumber]);

  return (
    <div className="margin-top-3">
      <FilterPanelField
        key={[filter.key]}
        filter={{
          ...filter,
          type: 'text',
          value: customerNumber,
        }}
      />
    </div>
  );
};

CustomerAccountFilterItem.propTypes = {
  filter: PropTypes.shape(FilterPanelFilterShape).isRequired,
};

export default CustomerAccountFilterItem;
