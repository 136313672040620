/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { Button, Modal } from '@gsa/afp-component-library';
import { usePmExpress } from './pm-express-provider';

const InterceptionModal = () => {
  const { interceptSave, dispatchAction, saveAllPms } = usePmExpress();

  const onDiscardAndCloseModal = () => {
    dispatchAction('RESET_PMS', true);
    dispatchAction('SET_INTERCEPT_SAVE', false);
  };

  const onSaveAndClose = () => {
    saveAllPms();
    dispatchAction('SET_INTERCEPT_SAVE', false);
  };

  if (!interceptSave) {
    return null;
  }

  useEffect(() => {
    dispatchAction('RESET_PMS', false);
  }, []);

  return (
    <div className="afp-modal-wrapper">
      <div className="afp-modal-overlay">
        <Modal
          title={<h2>You have unsaved data</h2>}
          actions={
            <>
              <Button
                variant="unstyled"
                className="margin-right-2"
                onClick={onDiscardAndCloseModal}
                data-testid="discard-save-cancel-button"
                label="Discard and close"
              />
              <Button
                variant="primary"
                type="submit"
                data-testid="confirm-save-all-pms"
                label="Save and close"
                onClick={onSaveAndClose}
              />
            </>
          }
          onClose={onDiscardAndCloseModal}
          variant="large"
        >
          You must save or you will lose the data you have entered.
        </Modal>
      </div>
    </div>
  );
};

export default InterceptionModal;
