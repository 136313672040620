import { gql } from '@apollo/client';

export const GET_MILEAGE_EXPRESS_LIST = gql`
  query GetMileageExpressList(
    $order: OrderBy
    $limit: Float
    $offset: Float
    $filters: [Filter!]!
  ) {
    getMileageExpressList(
      order: $order
      limit: $limit
      offset: $offset
      filters: $filters
    ) {
      rows {
        assetId
        mileageDate
        daysInUse
        updatedAt
        updatedBy
        createdAt
        createdBy
        stateTagNumber
        vehicle {
          fmcId
          fundCode
          id
          tagNumber
          vin
          customer {
            accountName
            legacyCustomerNumber
            customerId
          }
        }
        mileageReportingStatus
        mileageReportingMonth
        mileageReportingYear
        previousMileage
        sourceType
        account2
        account1
        currentMileage
        enteredByUserFullname
      }
      count
      hasMore
    }
  }
`;

export const REPORT_MILEAGE_EXPRESS = gql`
  mutation ReportMileageExpresss(
    $reportMileageExpresssFields: [ReportMileageExpressFieldInput!]!
  ) {
    reportMileageExpresss(
      reportMileageExpresssFields: $reportMileageExpresssFields
    ) {
      assetMileageExpressId
      assetId
    }
  }
`;

export const GET_BOACS = gql`
  query GetBoacs($getBoacsArgs: GetBoacsInput!) {
    getBoacs(getBoacsArgs: $getBoacsArgs) {
      boacId
      boac
    }
  }
`;
