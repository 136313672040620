import React from 'react';
import { Button, DetailsTable } from '@gsa/afp-component-library';
import PocReview from './poc-review';
import { DEFAULT_SELECT_OPTION_LABEL } from '../../../../utilities/consts';

export default function AgencyAndPocReview({ formState, setStep = () => {} }) {
  const handleNavToStep2 = () => {
    setStep(2);
  };
  return (
    <>
      <div className="width-full margin-top-2">
        <h2 className="margin-bottom-1 margin-top-2 text-bold">
          Agency and point of contact information
        </h2>
        <div className="grid-row grid-gap">
          <div className="tablet:grid-col-8">
            <p className="afp-vehicle-registration__section_text">
              Registration for federally owned and operated vehicles with GSA.
              All vehicle registrations must must contain the following: a valid
              VIN, a federal license plate, and{' '}
              <strong>two points of contact.</strong>
            </p>
          </div>
        </div>
        <div className="grid-row grid-gap margin-y-2">
          <PocReview
            pocNum="1"
            pointOfContact={formState.pointOfContact1}
            handleNavToStep2={handleNavToStep2}
          />
          <PocReview
            pocNum="2"
            pointOfContact={formState.pointOfContact2}
            handleNavToStep2={handleNavToStep2}
          />
        </div>
        <div className="grid-row grid-gap margin-y-2">
          <div className="tablet:grid-col-6">
            <h4 className="title-s-caps text-primary margin-y-1">
              AGENCY DETAILS
            </h4>
            <div className="bg-gray-3 radius-md padding-y-2 padding-x-4">
              <DetailsTable
                className="text-right"
                data={[
                  ['Agency', formState.agencyName],
                  ['Bureau', formState.bureauName],
                  [
                    'Office',
                    formState.subSectionName === DEFAULT_SELECT_OPTION_LABEL
                      ? '-'
                      : formState.subSectionName,
                  ],
                ]}
              />
              <Button
                variant="outline"
                className="margin-top-2 margin-left-0 bg-white"
                aria-label="edit agency"
                onClick={handleNavToStep2}
                label="Edit"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
