import React, { createContext, useContext } from 'react';

export const RegContext = createContext(null);

export default function RegContextProvider({ children, ...props }) {
  return (
    <RegContext.Provider value={{ ...props }}>{children}</RegContext.Provider>
  );
}

export const useRegistration = () => useContext(RegContext);
