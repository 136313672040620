/* eslint-disable react/prop-types */
import React from 'react';
import { Button, Modal, Alert, Spinner } from '@gsa/afp-component-library';
import { useVehicle } from 'components/vehicle-details/vehicle-context-provider';
import { useMileageHistoryGF } from '../mileage-history-gf-provider';

const DeleteGFMileageModal = () => {
  const {
    closeDeleteGFMileageModal,
    alertMessage,
    deleteGFMileage,
    deleteGFMileageLoading,
    selectedMileageHistoryRecord,
    dispatchAction,
  } = useMileageHistoryGF();

  const { vehicle } = useVehicle();

  const onCloseModal = () => {
    dispatchAction('SET_SELECTED_MILEAGE_HISTORY_RECORD', undefined);
    dispatchAction('SET_ALERT_MESSAGE', {
      type: '',
      message: '',
      location: 'deleteGFMileageModal',
    });
    closeDeleteGFMileageModal();
  };

  const deleteGFMileageRecord = () => {
    deleteGFMileage({
      variables: {
        id: selectedMileageHistoryRecord?.assetMileageLogId,
        assetId: vehicle?.uuid,
      },
    });
  };

  return (
    <div className="afp-modal-wrapper">
      <div className="afp-modal-overlay">
        <Modal
          title={<h2>Delete record</h2>}
          actions={
            <>
              <Button
                variant="unstyled"
                className="margin-right-2"
                onClick={onCloseModal}
                data-testid="delete-gm-mileage-modal-cancel-button"
                label="Cancel"
              />
              <Button
                variant="secondary"
                type="submit"
                onClick={() => {
                  deleteGFMileageRecord();
                }}
                data-testid="delete-gm-mileage-modal-submit-button"
                disabled={deleteGFMileageLoading}
                label="Delete record"
              />
              {deleteGFMileageLoading && (
                <Spinner
                  size="small"
                  className="display-inline-block margin-left-2"
                />
              )}
            </>
          }
          onClose={onCloseModal}
          variant="large"
        >
          {alertMessage?.location === 'deleteGFMileageModal' &&
            alertMessage?.message && (
              <Alert type={alertMessage.type} slim>
                {alertMessage.message}
              </Alert>
            )}
          <p>
            Are you sure you want to delete the mileage record for{' '}
            <strong>VIN {vehicle?.id}</strong>? This action cannot be undone.
          </p>
        </Modal>
      </div>
    </div>
  );
};

export default DeleteGFMileageModal;
