import React from 'react';
import moment from 'moment';
import { useFormContext, Controller } from 'react-hook-form';
import { DatePicker, TextInput } from '@gsa/afp-component-library';

const RepairOrderFields = {
  repairDate: { name: 'repairDate' },
  repairOrderNumber: { name: 'repairOrderNumber' },
};

const RepairOrderForm = () => {
  const {
    formState: { errors },
    control,
  } = useFormContext();

  const { repairOrderNumber, repairDate } = RepairOrderFields;

  return (
    <div data-testid="repair-order-form">
      <Controller
        name={repairOrderNumber.name}
        control={control}
        render={({ field, ref }) => {
          return (
            <TextInput
              {...field}
              ref={ref}
              readOnly={true}
              type="text"
              label={<span className="text-bold">Repair order number</span>}
              help="Automatically generated by system"
              name={repairOrderNumber.name}
              id={repairOrderNumber.name}
              errorMessage={
                errors && errors[repairOrderNumber.name]
                  ? errors[repairOrderNumber.name].message
                  : null
              }
              aria-invalid={
                errors && errors[repairOrderNumber.name] ? 'true' : 'false'
              }
            />
          );
        }}
      />
      <Controller
        name={repairDate.name}
        control={control}
        render={({ field, ref }) => {
          return (
            <DatePicker
              {...field}
              ref={ref}
              name={repairDate.name}
              id={repairDate.name}
              maxDate={moment().format('YYYY-MM-DD')}
              label={<span className="text-bold">Repair date</span>}
              defaultValue={field.value}
              onChange={(val) => {
                field.onChange(
                  val
                    ? moment(val).format('YYYY-MM-DD').concat('T00:00:00.000Z')
                    : null,
                );
              }}
              errorMessage={
                errors && errors[repairDate.name]
                  ? errors[repairDate.name].message
                  : null
              }
              aria-invalid={
                errors && errors[repairDate.name] ? 'true' : 'false'
              }
            />
          );
        }}
      />
    </div>
  );
};

export default RepairOrderForm;
