import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { Spinner, FilterPanel } from '@gsa/afp-component-library';
import { sortBy } from 'lodash';
import { GET_VEHICLE_FILTERABLE_MODELS_BY_MAKE } from '../../../services/data-layer';

const { FilterPanelFilterShape, FilterPanelField, useFilterPanel } =
  FilterPanel;

const placeholderOption = {
  value: '',
  label: '- Select model -',
  defaultValue: true,
};

const VehiclesModelFilterItem = ({ filter }) => {
  const [make, setMake] = useState();
  const [options, setOptions] = useState(filter?.options);
  const { getFilterFromState, mergedFilters, clearOneFilter } =
    useFilterPanel();

  const [
    fetchVehicleFilterableModelsByMake,
    { data: modelOptions, loading: modelOptionsLoading },
  ] = useLazyQuery(GET_VEHICLE_FILTERABLE_MODELS_BY_MAKE, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    const makeFilter = getFilterFromState('makeCode');
    setMake(makeFilter.value);
  }, [mergedFilters]);

  useEffect(() => {
    clearOneFilter(filter);
    setOptions([placeholderOption]);
    if (!!make) {
      fetchVehicleFilterableModelsByMake({
        variables: { makeCode: make },
      });
    }
  }, [make]);

  useEffect(() => {
    if (modelOptions?.getVehicleFilterableModelsByMake?.models) {
      const formattedModelOptions = sortBy(
        modelOptions.getVehicleFilterableModelsByMake.models,
        'id',
      ).map((modelOption) => ({
        value: modelOption.modelCode,
        label: modelOption.modelDescription,
      }));
      setOptions(
        formattedModelOptions
          ? [placeholderOption, ...formattedModelOptions]
          : [placeholderOption],
      );
    }
  }, [modelOptions]);

  if (modelOptionsLoading) {
    return <Spinner />;
  }

  return (
    <FilterPanelField
      key={[filter.key]}
      filter={{
        ...filter,
        type: 'select',
        options,
      }}
    />
  );
};

VehiclesModelFilterItem.propTypes = {
  filter: PropTypes.shape(FilterPanelFilterShape).isRequired,
};

export default VehiclesModelFilterItem;
