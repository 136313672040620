import React from 'react';
import { Button } from '@gsa/afp-component-library';
import useDataController from './use-data-controller';
import { usePmExpress } from './pm-express-provider';

const PmExpressActions = () => {
  const { saveAllPms, reportingPm } = usePmExpress();
  const { onExport } = useDataController();
  return (
    <div className="display-flex flex-justify-end">
      <Button
        data-testid="save-all-pm-submit-btn"
        onClick={() => {
          saveAllPms();
        }}
        label="Save all"
        disabled={reportingPm}
      />
      <Button
        data-testid="export-pm-express-btn"
        variant="outline"
        onClick={onExport}
        leftIcon={{ name: 'file_download' }}
        label="Export"
      />
    </div>
  );
};

export default PmExpressActions;
