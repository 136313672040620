import React, { useEffect, useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import {
  Breadcrumbs,
  PageTitle,
  Spinner,
  FilterTableFrame,
} from '@gsa/afp-component-library';
import { InventoryMgmtGuideText } from 'utilities/user-guide';
import FilterUpRightActions from 'utilities/filter-up-right-actions';

import {
  MileageFilterProvider,
  useMileageFilter,
  MileagePageProvider,
} from './providers';
import { Sidebar, MileageTable, BannerMessage } from './widgets';

import useDataController from './use-data-controller';

const title = 'Vehicle Mileage History';
const trail = [<a href={`${window.AFP_CONFIG.appURLs.home}/home`}>Home</a>];
const Page = () => {
  const { agencies, getAgencies, filterStructure } = useMileageFilter();

  const { onExport } = useDataController();
  const FilterGrid = useMemo(
    () => FilterTableFrame(null, FilterUpRightActions, Sidebar, MileageTable),
    [],
  );
  useEffect(() => {
    getAgencies();
  }, []);

  if (agencies === undefined) {
    return <Spinner size="small" data-testid="mileage-spinner" />;
  }
  if (agencies.length == 0) {
    return <Redirect to="/not-opted" />;
  }

  return (
    <article>
      <header>
        <Breadcrumbs trail={trail} current={title} />
        <BannerMessage />
        <PageTitle title={title} />

        <div className="margin-bottom-4 tablet:grid-col-7">
          <p>Use the search and filters to locate vehicle mileage records.</p>
          <p>
            If you would like to perform a bulk upload of mileage data, please
            go to the <a href="/reports/">Vehicle Reports Manager</a>. From
            there you can download a template and upload your completed
            template.
          </p>
          <InventoryMgmtGuideText />
        </div>
      </header>
      {filterStructure ? (
        <FilterGrid
          filterToggle
          upRightProps={{
            DropDownItems: [
              {
                label: 'Export data',
                iconName: 'file_download',
                onClick: onExport,
              },
            ],
          }}
        />
      ) : (
        <Spinner size="medium" data-testid="mileage-spinner" />
      )}
    </article>
  );
};

const VehicleMileage = () => {
  return (
    <MileageFilterProvider>
      <MileagePageProvider>
        <Page />
      </MileagePageProvider>
    </MileageFilterProvider>
  );
};

export default VehicleMileage;
