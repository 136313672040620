/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  TextInput,
  RequiredFieldIndicator,
  Alert,
} from '@gsa/afp-component-library';
import { FormGenerator } from '@gsa/afp-shared-form-utils';
import { useVehicle } from 'components/vehicle-details/vehicle-context-provider';
import _ from 'lodash';
import { getTerminateSchema } from './terminate-vehicle-form-schema';

const TerminateVehicleForm = ({
  onSubmit,
  terminationData,
  setTerminationData,
}) => {
  const { vehicle } = useVehicle();
  // get assignment date by way of assigned indicator begin time
  const assignmentDate = new Date(
    _.find(vehicle?.assetLifecycle, {
      lifeCycle: { lifecycleIndicatorId: '603' },
    })?.lifecycleIndicatorBeginDate,
  );

  const terminateVehicleRef = useRef(null);

  useEffect(() => {
    if (!terminateVehicleRef.current) return () => {};
    const subscription = terminateVehicleRef.current.watch((data) => {
      setTerminationData({
        ...terminationData,
        ...data,
      });
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [terminateVehicleRef.current]);

  const today = new Date();

  // start of month
  let minDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    1,
  ).toISOString();

  if (assignmentDate?.getMonth() === today.getMonth()) {
    minDate = assignmentDate.toISOString();
  }

  const defaultFormData = useMemo(
    () => ({
      terminationDate: '',
    }),
    [],
  );
  const formContent = {
    buttonControls: {
      submit: false,
      cancel: false,
    },

    sections: [
      {
        fields: [
          {
            id: 'terminationDate',
            type: 'datepicker',
            label: 'Termination date',
            required: true,
            columnWidth: 'col-6-12',
            element: {
              control: {
                maxDate: today.toISOString(),
                minDate,
              },
            },
          },
          {
            id: 'endOdometer',
            label: (
              <>
                End mileage <RequiredFieldIndicator />
              </>
            ),
            component: (config, useFormContext) => {
              const [warning, setWarning] = useState('');
              const { setValue } = useFormContext();
              const handleEndOdometerChange = (e) => {
                e.preventDefault();
                const { value } = e.target;
                setValue(config.id, value);
                const currentOdometer =
                  vehicle?.assetMileageReportingStatus?.[0]?.currentMileage ||
                  0;

                if (Math.abs(Number(value) - Number(currentOdometer)) > 9999) {
                  setWarning(
                    <Alert
                      size="small"
                      type="warning"
                      className="usa-error-message"
                    >
                      Mileage exceeds +/- 9,999 from current odometer. Verify
                      mileage before terminating.
                    </Alert>,
                  );
                } else {
                  setWarning('');
                }
              };
              return (
                <>
                  {warning}
                  <TextInput
                    style={{ width: 300 }}
                    name="endOdometer"
                    id="endOdometer"
                    required
                    onChange={handleEndOdometerChange}
                  />
                </>
              );
            },
          },
        ],
      },
      {
        fields: [
          {
            id: 'comment',
            type: 'textarea',
            label: 'Remarks',
            element: {
              control: {
                maxLength: 100,
                countdown: true,
              },
            },
          },
        ],
      },
    ],
  };
  return (
    <FormGenerator
      ref={terminateVehicleRef}
      schema={getTerminateSchema(vehicle?.inventoryStatusMileage)}
      id="terminate-vehicle-form"
      data-testid="terminate-vehicle-form"
      defaultValues={defaultFormData}
      content={formContent}
      onSubmit={onSubmit}
      useFormProps={{
        mode: 'onBlur',
        reValidateMode: 'onBlur',
      }}
    />
  );
};

export default TerminateVehicleForm;
