import { gql } from '@apollo/client';
import PropTypes from 'prop-types';

export const VALIDATE_ADDRESS = gql`
  query ValidateAddress(
    $address: String!
    $address2: String
    $city: String!
    $state: String!
    $zip: String!
  ) {
    validateAddress(
      address: $address
      address2: $address2
      city: $city
      state: $state
      zip: $zip
    ) {
      address
      address2
      city
      state
      zip
    }
  }
`;

export const addressShape = {
  address: PropTypes.string,
  address2: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zip: PropTypes.string,
  countryCode: PropTypes.string,
};
