/* eslint react/prop-types: 0 */
import React, { useState, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { SelectDropdown } from '@gsa/afp-component-library';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import { canViewAOVehicles, canViewGFVehicles } from 'utilities/authorization';
import { isFeatureEnabled } from 'utilities/feature-toggle';
import exportsConfig from '../config/exports-config';

const DEFAULT_VEHICLE_OPTION = 'AO';

export const ReportSelection = ({ control, mode, watch }) => {
  const ability = useAppAbility();
  const canGenerateInventoryReport =
    canViewAOVehicles(ability) || canViewGFVehicles(ability);
  const canGenerateGFFastReport = isFeatureEnabled('gf-fast');
  const canGenerateCustomReport = isFeatureEnabled('custom-inventory-report');

  // need to revisit this logic to pass ownership type to exportsConfig to better control it from one place
  const generateReportOptions = (selectedVehicleOption) => {
    return Object.values(exportsConfig).filter((opt) => {
      return (
        (opt.type === mode || opt.value === 'DEFAULT') &&
        (!opt.ownershipType || opt.ownershipType === selectedVehicleOption) &&
        (opt.value !== exportsConfig.INVENTORY.value ||
          (canGenerateInventoryReport &&
            opt.value === exportsConfig.INVENTORY.value)) &&
        (opt.value !== exportsConfig.FAST.value ||
          (opt.value === exportsConfig.FAST.value &&
            selectedVehicleOption === 'AO') ||
          (opt.value === exportsConfig.FAST.value &&
            selectedVehicleOption === 'GF' &&
            canGenerateGFFastReport)) &&
        (opt.value !== exportsConfig.CUSTOM_INVENTORY.value ||
          (canGenerateCustomReport &&
            opt.value === exportsConfig.CUSTOM_INVENTORY.value))
      );
    });
  };

  const [reportOptions, setReportOptions] = useState([]);

  const selectedVehicleOption =
    watch('vehicle_options') || DEFAULT_VEHICLE_OPTION;

  useEffect(() => {
    if (selectedVehicleOption) {
      setReportOptions(generateReportOptions(selectedVehicleOption));
    }
  }, [selectedVehicleOption]);

  return (
    <section className="grid-row text-bold">
      <div className="grid-col-12" data-testid="vehicle-ownership-section">
        <Controller
          control={control}
          name="reportType"
          rules={{
            validate: (value) =>
              exportsConfig.DEFAULT.value !== value ||
              'This is a required field',
          }}
          render={({ field: { ref, ...rest }, formState: { errors } }) => (
            <SelectDropdown
              id="reportType"
              data-testid="reportType"
              name="reportType"
              label={`Choose from available ${mode}s`}
              required
              options={reportOptions}
              errorMessage={errors.reportType?.message}
              {...rest}
            />
          )}
        />
      </div>
    </section>
  );
};
