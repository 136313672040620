/* eslint-disable react/prop-types */
/* eslint-disable filenames/match-exported */

import { useLazyQuery, useMutation } from '@apollo/client';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { onErrorFn } from 'utilities/format';
import { GET_FLEET_CARD_TRANSACTIONS_BY_VEHICLE } from './operation/query';
import { pageConfigUpdateSelector } from './store/selectors';
import { DELETE_FLEET_CARD_TRANSACTION } from './operation/mutation/delete-fleet-card-transaction';

export const deleteAction = ({
  data,
  getExpenses,
  dependantFunctionsState,
  vehicleState,
}) => {
  if (data) {
    getExpenses({
      variables: {
        id: vehicleState.uuid,
        offset: 0,
        limit: 10,
        order: null,
      },
    });

    dependantFunctionsState.closeDeleteExpenseModal();

    dependantFunctionsState.setPageMsg({
      type: 'success',
      message: 'Expense record was successfully deleted ',
    });
  }
};

const useDataController = () => {
  const vehicleState = useRecoilValue(
    pageConfigUpdateSelector({ id: 'vehicle' }),
  );

  const dependantFunctionsState = useRecoilValue(
    pageConfigUpdateSelector({ id: 'dependantFunctions' }),
  );

  const setExpenseRecords = useSetRecoilState(
    pageConfigUpdateSelector({ id: 'expenseRecords' }),
  );

  const [getExpenses, { loading: getExpensesLoading }] = useLazyQuery(
    GET_FLEET_CARD_TRANSACTIONS_BY_VEHICLE,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (data?.getFleetCardTransactionsByVehicle?.rows) {
          setExpenseRecords({
            rows: data?.getFleetCardTransactionsByVehicle?.rows,
            count: data?.getFleetCardTransactionsByVehicle?.count,
          });
          setExpenseRecords({
            totalExpense: data?.getFleetCardTransactionsByVehicle.totalExpense,
          });
        } else {
          onErrorFn(dependantFunctionsState.setPageMsg)({
            message: 'No Response Data ',
          });
        }
      },
      onError: onErrorFn(dependantFunctionsState.setPageMsg),
    },
  );

  const [deleteExpense, { loading: deleteExpenseLoading }] = useMutation(
    DELETE_FLEET_CARD_TRANSACTION,
    {
      fetchPolicy: 'no-cache',
      onError: onErrorFn(dependantFunctionsState.setPageMsg),

      onCompleted: (data) => {
        deleteAction({
          data,
          getExpenses,
          dependantFunctionsState,
          vehicleState,
        });
      },
    },
  );

  return {
    query: { getExpenses },
    mutation: { deleteExpense },
    loading: { getExpensesLoading, deleteExpenseLoading },
  };
};

export default useDataController;
