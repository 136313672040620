import React, { useState, useEffect } from 'react';
import { Button, EmptyState, Spinner } from '@gsa/afp-component-library';
import EquipmentDetail from './equipment-details';
import EquipmentEdit from './equipment-edit';
import { useVehicle } from '../../vehicle-context-provider';
import { useMutation } from '@apollo/client';
import {
  CREATE_VEHICLE_ACCESSORY,
  UPDATE_VEHICLE_ACCESSORY,
} from '../../../../services/data-layer';
import useUser from '../../../../utilities/use-user';
import useCanPerformActions from 'hooks/use-can-perform-actions';

export default function Equipment() {
  const { vehicle, setTabMsg, accessories, setVehicle } = useVehicle();
  const canPerformActions = useCanPerformActions();
  const { isRole } = useUser();
  const hasFSRrole = isRole('FSR');
  const hasCFMrole = isRole('CustFltMan');
  const canEditEquipment = canPerformActions.canEditEquipment(
    vehicle,
    hasFSRrole,
    hasCFMrole,
  );
  const isAbleToViewAoAndGfVehicle =
    canPerformActions.isAbleToViewAoAndGfVehicle(vehicle);

  const [refetchingData, setRefetchingData] = useState(false);
  const [editing, setEditing] = useState(false);

  const [
    createVehicleAccessory,
    { data: createVehicleAccessoryData, loading: creatingVehicleAccessory },
  ] = useMutation(CREATE_VEHICLE_ACCESSORY, {
    fetchPolicy: 'no-cache',
  });

  const [
    updatedVehicleAccessory,
    { data: updateVehicleAccessoryData, loading: updatingVehicleAccessory },
  ] = useMutation(UPDATE_VEHICLE_ACCESSORY, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (createVehicleAccessoryData?.createVehicleAccessory) {
      setVehicle(createVehicleAccessoryData.createVehicleAccessory);
      setTabMsg({
        type: 'success',
        message: 'Equipment was successfully updated',
      });
      setRefetchingData(false);
    }
  }, [createVehicleAccessoryData]);

  useEffect(() => {
    if (updateVehicleAccessoryData?.updateVehicleAccessory) {
      setVehicle(updateVehicleAccessoryData.updateVehicleAccessory);
      setTabMsg({
        type: 'success',
        message: 'Equipment was successfully updated',
      });
      setRefetchingData(false);
    }
  }, [updateVehicleAccessoryData]);

  const onSave = (newAccessories, uuid) => {
    setRefetchingData(true);
    if (vehicle?.assetAccessory.length === 0) {
      createVehicleAccessory({
        variables: {
          id: uuid,
          accessories: newAccessories,
        },
      });
    } else {
      updatedVehicleAccessory({
        variables: {
          id: uuid,
          accessories: newAccessories,
        },
      });
    }
  };

  const onClose = () => {
    setEditing(false);
  };

  const getAccessoryDescription = (code) => {
    return accessories.find((accessory) => accessory.code === code).description;
  };

  const getVehicleAccessories = (vehicle) => {
    let codes = [];
    let descriptions = [];
    for (let accessory of vehicle.assetAccessory) {
      codes.push(String(accessory.accessoryCode));
      descriptions.push([
        getAccessoryDescription(String(accessory.accessoryCode)),
        'Yes',
      ]);
    }
    return {
      descriptions,
      codes,
    };
  };

  const [vehicleAccessories, setVehicleAccessories] = useState({
    codes: [],
    descriptions: [],
  });

  useEffect(() => {
    if (vehicle.assetAccessory) {
      setVehicleAccessories(getVehicleAccessories(vehicle));
    }
  }, []);

  useEffect(() => {
    if (vehicle.assetAccessory) {
      setVehicleAccessories(getVehicleAccessories(vehicle));
    }
  }, [vehicle]);

  const handleEquipmentEdit = () => {
    setEditing(true);
  };

  return (
    <div>
      {vehicleAccessories?.descriptions.length === 0 ? (
        <div className="text-center bg-gray-3">
          <div className="padding-top-6">
            <EmptyState alt="Image not available" hasBackground />
          </div>
          <div className="margin-2">There is no equipments at this time.</div>
          {canEditEquipment && (
            <Button
              data-testid="add_equipment_button"
              onClick={handleEquipmentEdit}
              className="margin-bottom-6"
              aria-label="add equipment"
              label="Add equipment"
            />
          )}
        </div>
      ) : (
        isAbleToViewAoAndGfVehicle && (
          <div className="grid-row grid-gap">
            <div className="grid-col-6">
              <EquipmentDetail
                details={vehicleAccessories.descriptions}
                canEditEquipment={canEditEquipment}
                handleEquipmentEdit={handleEquipmentEdit}
              />
            </div>
          </div>
        )
      )}

      <EquipmentEdit
        accessories={accessories}
        editing={editing}
        vehAccessories={vehicleAccessories.codes}
        vehicle={vehicle}
        onSave={onSave}
        onClose={onClose}
      />

      {(creatingVehicleAccessory ||
        refetchingData ||
        updatingVehicleAccessory) && (
        <Spinner aria-busy="true" className="loading_backdrop" size="large" />
      )}
    </div>
  );
}
