/* eslint-disable filenames/match-exported */
import React from 'react';
import { Button } from '@gsa/afp-component-library';

const ConfirmSubmitOrders = {
  title: () => <h2>Confirm submission</h2>,
  content: ({ ordersList1, ordersList2, ordersList3, ordersList4 }) => {
    const totalOrdersQuantity = [
      ...ordersList1,
      ...ordersList2,
      ...ordersList3,
      ...ordersList4,
    ].reduce((acc, order) => {
      return acc + Number(order.quantity);
    }, 0);
    return (
      <>
        <p>
          {' '}
          You have selected to place an order for a total of{' '}
          <span className="text-bold">{totalOrdersQuantity}</span> license
          plates.{' '}
        </p>
        <p>
          Please confirm the required quantity prior to proceeding with the
          order.{' '}
        </p>
      </>
    );
  },
  action: ({ resetCurrentModal, saveAllOrders }) => (
    <>
      <Button
        variant="unstyled"
        className="margin-right-2"
        onClick={resetCurrentModal}
        data-testid="remove-contact-cancel-button"
        label="Cancel"
      />
      <Button
        variant="primary"
        type="submit"
        label="Confirm"
        onClick={() => {
          saveAllOrders();
        }}
      />
    </>
  ),

  onClose: ({ resetCurrentModal }) => {
    resetCurrentModal();
  },
};

export default ConfirmSubmitOrders;
