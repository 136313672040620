import React from 'react';
import { StatusBadge } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';
import { itemInventoryStatuses } from 'utilities/consts';

export default function VehicleStatus({ status }) {
  const { displayStatus, color } = itemInventoryStatuses[status?.trim()] || {
    color: 'Inactive-Gray',
    displayStatus: 'Unknown',
  };
  return (
    <StatusBadge variant={color} data-testid="repair-order-vehicle-status">
      {displayStatus}
    </StatusBadge>
  );
}

VehicleStatus.propTypes = {
  status: PropTypes.string,
};
