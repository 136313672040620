import React from 'react';

export default ({ message, extensions: { code } }) => {
  switch (code) {
    case 'ORDER_NOT_FOUND':
      return (
        <>
          GSAFLeet.gov does not have any sales order information related to your
          order number. Contact UNICOR at{' '}
          <a href="mailto:CUSGDLP@usdoj.gov">CUSGDLP@usdoj.gov</a> and request
          to have your order number loaded into GSAFLeet.gov
        </>
      );
    case 'INSUFFICIENT_PERMISSIONS':
      return <>You do not have permission to access this license plate order</>;
    case 'ORDER_NOT_IN_SCOPE':
      return <>{message.split(':')[1]}</>;
    case 'INTERNAL_SERVER_ERROR':
      return message;
    default:
      return null;
  }
};
