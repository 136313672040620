export const MOTOR_POOL_VEHICLE_FORM_FIELDS = {
  location: {
    label: 'Vehicle location',
    required: false,
    name: 'location',
    id: 'location',
    dataId: 'location',
    editable: true,
    additionalAttributes: {
      type: 'textarea',
      maxLength: 101,
      characterLimit: 100,
    },
  },
  comment: {
    label: 'Comments',
    name: 'comment',
    id: 'comment',
    dataId: 'comment',
    editable: true,
    additionalAttributes: {
      type: 'textarea',
      maxLength: 201,
      characterLimit: 200,
    },
  },
};
