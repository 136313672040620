import React from 'react';
import { Button, Modal, Alert, Spinner } from '@gsa/afp-component-library';
import { useSchedulesContext } from './schedules-provider';

const ScheduleActionsModal = () => {
  const {
    selectedSchedule,
    actionMode,
    alertMessage,
    isScheduleActionsModalOpen,
    closeScheduleActionsModal,
    setScheduleStatus,
    suspendingReport,
    deleteReportSchedule,
    deletingReport,
  } = useSchedulesContext();

  const { message, type, header, context } = alertMessage;

  const onCloseModal = () => {
    closeScheduleActionsModal();
  };

  if (!isScheduleActionsModalOpen) {
    return null;
  }

  return (
    <div className="afp-modal-wrapper">
      <div className="afp-modal-overlay">
        <Modal
          id="schedule-actions-modal"
          variant="large"
          title={<h2>{actionMode} scheduled report</h2>}
          onClose={onCloseModal}
          actions={
            <>
              <Button
                variant="unstyled"
                onClick={onCloseModal}
                label="Cancel"
                data-testid="cancel-schedule-actions-modal-button"
              />
              <Button
                variant={actionMode === 'Delete' ? 'secondary' : 'primary'}
                type="submit"
                disabled={suspendingReport || deletingReport}
                label={`${actionMode} report`}
                data-testid={`schedule-actions-modal-${actionMode.toLowerCase()}-button`}
                onClick={() => {
                  if (actionMode === 'Suspend' || actionMode === 'Unsuspend') {
                    const payload = {
                      variables: {
                        id: selectedSchedule.id,
                        scheduleStatus:
                          actionMode === 'Suspend' ? 'Suspended' : 'Active',
                      },
                    };
                    setScheduleStatus(payload);
                  } else {
                    const payload = {
                      variables: {
                        id: selectedSchedule.id,
                      },
                    };
                    deleteReportSchedule(payload);
                  }
                }}
              />
              {(suspendingReport || deletingReport) && (
                <Spinner
                  size="small"
                  className="display-inline-block margin-left-2"
                />
              )}
            </>
          }
        >
          {context === 'scheduleModal' && message && (
            <Alert type={type} header={header}>
              {message}
            </Alert>
          )}
          <p>
            Are you sure you want to {actionMode.toLowerCase()}{' '}
            <strong>Scheduled Report {selectedSchedule?.reportName}</strong>?
          </p>
        </Modal>
      </div>
    </div>
  );
};

export default ScheduleActionsModal;
