import React, { useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import {
  DetailsTable,
  AddressConfirmationModal,
} from '@gsa/afp-component-library';
import {
  emdash,
  SectionBlock,
  displayModel,
  displayVehicleGVWR,
} from '../../common';
import {
  Actions,
  RequiredFieldsText,
  TransactionDetailsForm,
  RepairOrderForm,
  VendorForm,
  PaymentForm,
  Totals,
} from '.';
import { useFormContext } from 'react-hook-form';
import { FORM_FIELDS } from '../providers/form-provider';
import { VALIDATE_ADDRESS } from '../../../services/data-layer/address';
import VehicleStatus from './vehicle-status-badge';
import ServiceLines from './service-lines';
import {
  useServiceLine,
  ServiceLinesErrors,
  PageErrors,
  useRepairOrder,
} from '../providers';
import RepairComments from './repair-comments';

const GeneralInfoEdit = ({ data }) => {
  const { vehicle, agencyInfo } = data || {};

  const getVehicleInfo = () => {
    return [
      ['VIN', vehicle?.vin || emdash],
      ['License plate', vehicle?.tagNumber || emdash],
      ['Year', vehicle?.modelYear || emdash],
      ['Make', vehicle?.vehicleMake?.makeName || emdash],
      ['Model', displayModel(vehicle) || emdash],
      [
        'Current mileage',
        vehicle?.newOdometerReading?.odometer?.toLocaleString() || emdash,
      ],
      ['GVWR', displayVehicleGVWR(vehicle) || emdash],
      [
        'Vehicle status',
        <VehicleStatus status={vehicle?.inventoryStatus?.id} />,
      ],
    ];
  };
  const getAgencyInfo = () => {
    return [
      ['Agency', agencyInfo?.agencyName || emdash],
      ['Bureau', agencyInfo?.bureauName || emdash],
      ['Office', agencyInfo?.officeName || emdash],
    ];
  };

  return (
    <div className="grid-row grid-gap">
      <div className="grid-col-6">
        <SectionBlock title="Transaction details" basic>
          <div className="grid-col-5">
            <TransactionDetailsForm />
          </div>
        </SectionBlock>
        <SectionBlock title="Repair order" basic>
          <div className="grid-col-5">
            <RepairOrderForm />
          </div>
        </SectionBlock>
        <SectionBlock title="Vendor information" basic>
          <VendorForm />
        </SectionBlock>
      </div>
      <div className="grid-col-6">
        <SectionBlock title="Vehicle">
          <DetailsTable
            className="repair-order-details-table"
            bordered
            wrapText
            data={getVehicleInfo()}
          />
        </SectionBlock>
        <SectionBlock title="Agency">
          <DetailsTable
            className="repair-order-details-table"
            bordered
            wrapText
            data={getAgencyInfo()}
          />
        </SectionBlock>
        <SectionBlock title="Payment information" basic>
          <div className="grid-col-5">
            <PaymentForm />
          </div>
        </SectionBlock>
      </div>
    </div>
  );
};

const RepairEdit = ({ mode, data, setPageError }) => {
  const {
    getValues,
    setValue,
    formState: { errors, dirtyFields },
    handleSubmit,
    trigger,
  } = useFormContext();
  const {
    serviceLines,
    totals,
    setError: setServiceLineError,
  } = useServiceLine();
  const { saveRepairOrder } = useRepairOrder();
  const { repairOrder = {}, taxCost } = data;
  const { repairOrderNumber } = repairOrder;

  const hasFormErrors = () => Object.keys(errors || {}).length > 0;

  const getVendorAddress = () => {
    return {
      countryCode: 'US',
      address: getValues(FORM_FIELDS.vendorInfo.vendorAddress1.name) || '',
      address2: getValues(FORM_FIELDS.vendorInfo.vendorAddress2.name) || '',
      city: getValues(FORM_FIELDS.vendorInfo.vendorCity.name) || '',
      state: getValues(FORM_FIELDS.vendorInfo.vendorState.name) || '',
      zip: getValues(FORM_FIELDS.vendorInfo.vendorZipcode.name) || '',
    };
  };

  const [vendorAddressToValidate, setVendorAddressToValidate] = useState(
    getVendorAddress(),
  );
  const [validatedVendorAddress, setValidatedVendorAddress] = useState(null);

  const validateServiceLines = () => {
    const currentServiceLines = serviceLines.filter((sl) => !sl.deleted);
    if (!currentServiceLines.length) {
      const { MinOneServiceLineItem } = ServiceLinesErrors;
      setServiceLineError({ MinOneServiceLineItem });
      return false;
    }
    return true;
  };

  const handleSave = (formData) => {
    setPageError({});
    saveRepairOrder({
      generalInfo: formData,
      serviceLines,
      totals: { ...totals, taxCost },
    });
  };

  const [
    validateAddress,
    { loading: validatingVendorAddress, error: validateVendorAddressError },
  ] = useLazyQuery(VALIDATE_ADDRESS, {
    fetchPolicy: 'no-cache',
    onCompleted: (address) => {
      if (address && address.validateAddress) {
        setValidatedVendorAddress(address.validateAddress);
      }
    },
  });

  const validateVendorAddress = (formData) => {
    const addressFields = [
      FORM_FIELDS.vendorInfo.vendorAddress1.name,
      FORM_FIELDS.vendorInfo.vendorAddress2.name,
      FORM_FIELDS.vendorInfo.vendorCity.name,
      FORM_FIELDS.vendorInfo.vendorState.name,
      FORM_FIELDS.vendorInfo.vendorZipcode.name,
    ];
    const dirtyFieldsList = Object.keys(dirtyFields);
    const isAddressFieldsDirty = addressFields.some(
      (field) => dirtyFieldsList.indexOf(field) >= 0,
    );

    const address = getVendorAddress();

    if (isAddressFieldsDirty) {
      setVendorAddressToValidate(address);
      validateAddress({
        variables: address,
      });
    } else {
      handleSave({ ...formData });
    }
  };

  const saveSelectedVendorAddress = (selectedAddress) => {
    setValue(
      FORM_FIELDS.vendorInfo.vendorAddress1.name,
      selectedAddress?.address,
    );
    setValue(
      FORM_FIELDS.vendorInfo.vendorAddress2.name,
      selectedAddress?.address2,
    );
    setValue(FORM_FIELDS.vendorInfo.vendorCity.name, selectedAddress?.city);
    setValue(FORM_FIELDS.vendorInfo.vendorState.name, selectedAddress?.state);
    setValue(FORM_FIELDS.vendorInfo.vendorZipcode.name, selectedAddress?.zip);
    handleSave(getValues());
  };

  const onSubmit = (formData) => {
    validateVendorAddress(formData);
  };

  const handleOnSave = () => {
    trigger().then(() => {
      const serviceLineValid = validateServiceLines();
      if (hasFormErrors() || !serviceLineValid) {
        setPageError(PageErrors.InvalidData);
      } else {
        handleSubmit(onSubmit)();
      }
    });
  };

  return (
    <form id="repair-order-edit-form" data-testid="repair-order-edit-form">
      <section className="grid-col-12 margin-y-3">
        <Actions mode={mode} onSave={handleOnSave} />
        <RequiredFieldsText mode={mode} className="padding-top-2" />
      </section>
      <section className="grid-col-12">
        <GeneralInfoEdit data={data} />
        <AddressConfirmationModal
          validating={validatingVendorAddress}
          hasValidationError={!!validateVendorAddressError}
          addressToValidate={vendorAddressToValidate}
          validatedAddress={validatedVendorAddress}
          onSave={(vAddress) => {
            saveSelectedVendorAddress(vAddress);
          }}
          saveButtonText="Save and close"
        />
      </section>
      <section className="grid-col-12">
        <ServiceLines mode={mode} repairNumber={repairOrderNumber} />
      </section>
      <section className="grid-col-12">
        <div className="grid-row grid-gap grid-gap-6">
          <div className="grid-col-6">
            <Totals mode={mode} />
          </div>
          <div className="grid-col-6">
            <RepairComments mode={mode} />
          </div>
        </div>
      </section>
      <section className="grid-col-12 padding-top-4 margin-y-2 border-top-2px border-gray-20">
        <Actions mode={mode} onSave={handleOnSave} />
      </section>
    </form>
  );
};

export default RepairEdit;
