// eslint-disable-next-line react/prop-types
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import {
  AFPTable,
  EmptyState,
  Pagination,
  Spinner,
  Menu,
} from '@gsa/afp-component-library';
import { useHistory } from 'react-router-dom';
import { emdash } from 'components/common';
import { VMSSubjects, VMSOperations } from 'utilities/consts';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import { useSchedulesFilter } from './filters/filter-provider';
import { useSchedulesContext } from './schedules-provider';
import { RowSubDetail } from './row-details';
import SchedulesStatusBadge from './schedules-status-badge';
import ScheduleActionsModal from './schedule-actions-modal';

const tableRef = React.createRef();
const initialPaginationState = {
  limit: 10,
  offset: 0,
  currentPage: 1,
  isReset: false,
};
const initialOrderState = 'createdAt DESC';

const getActionList = (scheduleStatus) => {
  return [
    {
      icon: 'edit',
      label: 'Edit',
      action: 'editSchedule',
      operation: VMSOperations.UPDATE_VEHICLE_GF,
      canShowIndicator: scheduleStatus.toLowerCase() !== 'suspended',
    },
    {
      icon: 'schedule',
      label: 'Suspend',
      action: 'suspendSchedule',
      operation: VMSOperations.UPDATE_VEHICLE_GF,
      canShowIndicator: scheduleStatus.toLowerCase() === 'active',
    },
    {
      icon: 'schedule',
      label: 'Unsuspend',
      action: 'unsuspendSchedule',
      operation: VMSOperations.UPDATE_VEHICLE_GF,
      canShowIndicator: scheduleStatus.toLowerCase() === 'suspended',
    },
    {
      icon: 'cancel',
      label: 'Delete',
      action: 'deleteSchedule',
      operation: VMSOperations.UPDATE_VEHICLE_GF,
      canShowIndicator: true,
    },
  ];
};

const SchedulesListingTable = () => {
  const [paginationState, setPaginationState] = useState(
    initialPaginationState,
  );
  const [order, setOrder] = useState(initialOrderState);
  const [data, setData] = useState([]);
  const history = useHistory();

  const {
    getReportSchedules,
    schedules,
    schedulesLoading,
    dispatchAction,
    openScheduleActionsModal,
  } = useSchedulesContext();

  const { filters } = useSchedulesFilter();
  const ability = useAppAbility();

  const getAllowedActionList = (actionList) => {
    return actionList.filter((action) => {
      const canShow = action?.canShowIndicator;
      return ability.can(action.operation, VMSSubjects.VEHICLE) && canShow;
    });
  };

  const getData = () => {
    getReportSchedules({
      variables: {
        limit: paginationState.limit,
        offset: paginationState.offset,
        order,
        filters,
      },
    });
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setData(schedules?.rows || []);
  }, [schedules]);

  useEffect(() => {
    getData();
  }, [paginationState, order, filters]);

  const handleSelectedAction = (action, row) => {
    dispatchAction('SET_SELECTED_SCHEDULE', row.original);
    switch (action) {
      case 'editSchedule':
        history.push({
          pathname: `export/${row.original.id}`,
        });
        break;
      case 'suspendSchedule':
        dispatchAction('SET_ACTION_MODE', 'Suspend');
        openScheduleActionsModal();
        break;
      case 'unsuspendSchedule':
        dispatchAction('SET_ACTION_MODE', 'Unsuspend');
        openScheduleActionsModal();
        break;
      case 'deleteSchedule':
        dispatchAction('SET_ACTION_MODE', 'Delete');
        openScheduleActionsModal();
        break;
      default:
        break;
    }
  };

  const columns = useMemo(() => {
    const columnList = [
      {
        Header: 'Report name',
        accessor: 'reportName',
        sortable: true,
      },
      {
        Header: 'Start date',
        accessor: 'scheduleStartDate',
        sortable: true,
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => {
          return moment(value).utc().format('MM/DD/YYYY');
        },
      },
      {
        Header: 'End date',
        accessor: 'scheduleEndDate',
        sortable: true,
        Cell: ({ value }) => {
          return moment(value).utc().format('MM/DD/YYYY');
        },
      },
      {
        Header: 'Report status',
        accessor: 'scheduleStatus',
        sortable: true,
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => {
          return value ? (
            <SchedulesStatusBadge schedlueStatus={value} />
          ) : (
            emdash
          );
        },
      },
      {
        Header: 'Actions',
        sortable: false,
        Cell: ({ row }) => {
          return (
            <div className="height-4 width-4 margin-left-05em">
              <Menu
                actionIconSize="usa-icon--size-4"
                menuItems={getAllowedActionList(
                  getActionList(row?.original?.scheduleStatus),
                )}
                onActionClick={(action) => {
                  handleSelectedAction(action, row);
                }}
                iconColor="text-primary"
                menuDialogState="hover"
              />
            </div>
          );
        },
      },
    ];

    return columnList;
  }, []);

  const renderRowSubComponent = useCallback(({ row: { original } }) => {
    const {
      reportType,
      frequency,
      nextRunDate,
      user,
      lastRunReport,
      agencyInfo,
      bureauInfo,
      scheduleStatus,
    } = original;
    return (
      <div className="display-flex flex-justify-center">
        <div className="grid-col-11">
          <RowSubDetail
            detail={{
              reportType,
              frequency,
              nextRunDate,
              user,
              lastRunReport,
              agencyInfo,
              bureauInfo,
              scheduleStatus,
            }}
          />
        </div>
      </div>
    );
  }, []);

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    const offset = (currentPage - 1) * itemsPerPage;
    setPaginationState({
      limit: itemsPerPage,
      offset,
      currentPage,
    });
  };

  return (
    <>
      <AFPTable
        fullWidth
        ref={tableRef}
        testId="schedules-table"
        columns={columns}
        data={!schedulesLoading ? data : []}
        defaultSort={order}
        onSort={(order) => {
          if (order) {
            let [sortBy, sortOrder] = order.split(' ');
            setOrder(`${sortBy} ${sortOrder}`);
          }
        }}
        expandable
        renderRowSubComponent={renderRowSubComponent}
      />
      {schedulesLoading && <Spinner className="padding-y-9" />}
      {schedules?.rows?.length > 0 && (
        <Pagination
          fullWidth
          variant="advanced"
          itemsPerPageOptions={[10, 15, 20, 25, 50]}
          itemsCount={schedules.count}
          itemsPerPage={paginationState.limit}
          currentPage={paginationState.currentPage}
          onPageChange={handlePaginationChange}
          isReset={paginationState.isReset}
        />
      )}
      {(!schedules || schedules.rows.length === 0) && !schedulesLoading && (
        <div className="bg-gray-3 padding-y-5">
          <div className="text-center padding-y-4">
            <EmptyState alt="Image not available" hasBackground />
          </div>
          <div className="text-center text-bold">No data available</div>
        </div>
      )}
      <ScheduleActionsModal />
    </>
  );
};

export default SchedulesListingTable;
