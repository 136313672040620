/* eslint-disable react/prop-types */
import React from 'react';
import { DetailsTable, Button } from '@gsa/afp-component-library';
import { formatTagExpDate } from 'utilities/common';
import ExemptPopover from '../exempt-popover';

export default function LicensePlateReview({
  tagNumber,
  tagExpirationDate,
  statePlate,
  exemptPlate,
  handleNavToStep1,
}) {
  const getLicensePlateLabel = () => {
    if (exemptPlate) {
      return (
        <div className="display-flex">
          <span className="font-ui-2xs">
            The Agency has an exemption from the requirement to display official
            U.S. Government license plates
            <ExemptPopover />
          </span>
        </div>
      );
    }
    return tagNumber;
  };
  return (
    <>
      <h4 className="title-s-caps text-primary margin-bottom-1">
        LICENSE PLATE
      </h4>
      <div className="bg-gray-3 radius-md padding-y-2 padding-x-4">
        <DetailsTable
          className="text-right"
          data={[
            ['License Plate', getLicensePlateLabel(tagNumber, exemptPlate)],
            [
              'License Plate Expiration Date',
              formatTagExpDate(tagExpirationDate),
            ],
            ['State License Plate', statePlate],
          ]}
        />
        <Button
          data-testid="btn-edit-step-1-license-plate"
          variant="outline"
          className="margin-top-2 margin-left-0 bg-white"
          aria-label="edit license plate"
          onClick={handleNavToStep1}
          label="Edit"
        />
      </div>
    </>
  );
}
