import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { AddressConfirmationModal } from '@gsa/afp-component-library';
import { VALIDATE_ADDRESS } from './address-gql';
import { nullForForm } from '../../../utilities/common';

export default function useAddressValidation(formState, setFormState) {
  const [pocRef, setPocRef] = useState(null);
  const [addressToValidate, setAddressToValidate] = useState(null);
  const [validatedAddress, setValidatedAddress] = useState(null);
  const [pocNum, setPocNum] = useState(null);
  const [uspsValidation, { data, loading, error }] = useLazyQuery(
    VALIDATE_ADDRESS,
    {
      fetchPolicy: 'no-cache',
      onError(err) {
        console.warn(err);
      },
    },
  );

  const [uspsValidationError, setUspsValidationError] = useState(false);

  useEffect(() => {
    setUspsValidationError(!!error);
  }, [error]);

  useEffect(() => {
    if (data?.validateAddress) {
      setValidatedAddress(data?.validateAddress);
    }
  }, [data]);

  const handlePocSelect = (selectedAddress) => {
    const { address, address2, city, state, zip } = selectedAddress;
    const poc = `pointOfContact${pocNum}`;
    setFormState({
      [poc]: {
        ...formState[poc],
        primaryAddress: address || null,
        secondaryAddress: address2 || null,
        city: city || null,
        stateCode: state || null,
        postalCode: zip || null,
        validated: true,
      },
    });
    setAddressToValidate(null);
    setValidatedAddress(null);
    setPocNum(null);

    pocRef?.current?.focus();
  };

  const handleConfirmUnvalidAddress = () => {
    const poc = `pointOfContact${pocNum}`;

    setFormState({
      [poc]: {
        ...formState[poc],
        validated: true,
      },
    });
    setAddressToValidate(null);
    setPocNum(null);
  };

  const AddressConfirmModal = () => {
    return (
      <>
        <AddressConfirmationModal
          validating={loading}
          hasValidationError={uspsValidationError}
          addressToValidate={addressToValidate}
          validatedAddress={validatedAddress}
          saveButtonText={'Save and close'}
          onSave={(d) => {
            if (uspsValidationError) {
              handleConfirmUnvalidAddress();
            } else {
              handlePocSelect(d);
            }
          }}
          onCancel={() => {
            setUspsValidationError(false);
            setAddressToValidate(null);
            setPocNum(null);
          }}
        />
      </>
    );
  };

  const validateAddress = (address, pocNumber) => {
    setAddressToValidate({ ...address });
    setPocNum(pocNumber);
    uspsValidation({ variables: { ...address } });
  };

  const validatePocAddress = (pocNumber, poc, ref) => {
    const {
      primaryAddress,
      secondaryAddress,
      city,
      stateCode,
      postalCode,
      countryCode,
    } = poc;

    const payload = {
      address: nullForForm(primaryAddress),
      address2: nullForForm(secondaryAddress),
      city: nullForForm(city),
      state: nullForForm(stateCode),
      zip: nullForForm(postalCode),
      countryCode: nullForForm(countryCode),
    };

    setPocRef(ref);
    validateAddress(payload, pocNumber);
  };

  return [validatePocAddress, { data, loading, error, AddressConfirmModal }];
}
