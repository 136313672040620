import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const emDash = '–';

export default function LicenseDetailRowLink({
  label,
  value,
  href,
  shouldSeeLink,
  className,
}) {
  const getOutput = () => {
    return shouldSeeLink ? (
      <Link aria-label="detail link" to={href}>
        {value}
      </Link>
    ) : (
      value
    );
  };
  return (
    <div className="detail-row display-flex flex-justify padding-y-1 border-bottom border-width-2px border-base-lighter">
      <div className="detail-title">
        <strong>{label}</strong>
      </div>
      <div className={`detail-value ${className}`}>
        {href && value ? getOutput() : emDash}
      </div>
    </div>
  );
}

LicenseDetailRowLink.defaultProps = {
  value: undefined,
  href: undefined,
  shouldSeeLink: true,
};

LicenseDetailRowLink.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  href: PropTypes.string,
  shouldSeeLink: PropTypes.bool,
};
