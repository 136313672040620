import React from 'react';
import { FleetTechnicalSupportContact } from '@gsa/afp-shared-ui-utils';

export const REPLACEMENT_CARD_SUCCESS_MESSAGE = {
  header: 'Replacement card order received',
  body: () => (
    <p>
      The replacement card you&apos;ve ordered is now being processed. During
      the processing time you can modify or cancel the order. Orders will be
      processed at 1PM EST. Orders cannot be modified or canceled after 1PM EST.
      If you experience technical difficulties, please contact the GSA Fleet
      technical support team at 866-472-1711 or{' '}
      <a href="mailto:fleet.helpdesk@gsa.gov">fleet.helpdesk@gsa.gov</a>.
    </p>
  ),
};

export const REPLACEMENT_CARD_CANCEL_MESSAGE = {
  body: (tagNumber) => <>{tagNumber} has been successfully canceled.</>,
};

export const REPLACEMENT_CARD_UPDATE_MESSAGE = {
  body: (tagNumber) => <>{tagNumber} has been successfully updated.</>,
};

export const REPLACEMENT_ORDER_EXPORT_MESSAGE = {
  header: 'Export Initiated:',
  body: () => (
    <>
      The data you&apos;ve selected is now being processed for export and will
      be emailed to you upon completion. Depending on the file size, this may
      take up to 15 minutes or more. If you experience technical difficulties
      exporting, <FleetTechnicalSupportContact />.
    </>
  ),
};

export const MAX_ORDERS_LIMIT_MESSAGE = {
  header: 'Daily limit reached for card ordering',
  body: 'You have reached the limit of 10 card orders for this time period. Additional cards may be ordered after 1PM EST. If you have an immediate need, please contact your Fleet Service Representative.',
};

export const MAX_ORDERS_LIMIT_MODAL_MESSAGE = {
  header: 'You have reached the daily limit for card ordering',
  body: 'You may only order up to 10 cards per day. If you have an immediate need, please contact your Fleet Service Representative.',
};

export const REPLACEMENT_CARD_FORM_FIELDS = {
  agency: {
    name: 'agency',
    id: 'agency',
    type: 'address',
  },
  address1: {
    name: 'address1',
    id: 'address1',
    type: 'address',
  },
  address2: {
    name: 'address2',
    id: 'address2',
    type: 'address',
  },
  city: {
    name: 'city',
    id: 'city',
    type: 'address',
  },
  state: {
    name: 'stateCode',
    id: 'stateCode',
    type: 'address',
  },
  postalCode: {
    name: 'postalCode',
    id: 'postalCode',
    type: 'address',
  },
  firstName: {
    name: 'firstName',
    id: 'firstName',
    type: 'contact',
  },
  lastName: {
    name: 'lastName',
    id: 'lastName',
    type: 'contact',
  },
  phoneNumber: {
    name: 'phoneNumber',
    id: 'phoneNumber',
    type: 'contact',
  },
  extension: {
    name: 'extension',
    id: 'extension',
    type: 'contact',
  },
  email: {
    name: 'email',
    id: 'email',
    type: 'contact',
  },
  tagNumber: {
    name: 'tagNumber',
    id: 'tagNumber',
  },
  reason: {
    name: 'reason',
    id: 'reason',
  },
  comment: {
    name: 'comment',
    id: 'comment',
  },
};

export const REPLACEMENT_REASON_OPTIONS = [
  { value: '', label: 'Select' },
  { value: 'Lost', label: 'Lost' },
  { value: 'Broken', label: 'Broken' },
  { value: 'Demagnetized', label: 'Demagnetized' },
  { value: 'Stolen', label: 'Stolen' },
];
