import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumbs } from '@gsa/afp-component-library';
import SchedulesProvider from './schedules-provider';
import SchedulesFilterProvider from './filters/filter-provider';
import SchedulesHeader from './schedules-header';
import SchedulesListing from './schedules-listing';

const ScheduledReports = () => {
  return (
    <>
      <SchedulesProvider>
        <SchedulesFilterProvider>
          <Breadcrumbs
            trail={[
              <a href={`${window.AFP_CONFIG.appURLs.home}/home`}>Home</a>,
              <Link to="/reports">Vehicle Reports Manager</Link>,
            ]}
            current="Scheduled Reports"
          />
          <div className="margin-bottom-3">
            <SchedulesHeader />
          </div>
          <SchedulesListing />
        </SchedulesFilterProvider>
      </SchedulesProvider>
    </>
  );
};

export default ScheduledReports;
