import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import { Menu } from '@gsa/afp-component-library';
import { useHistory } from 'react-router-dom';
import {
  canReconcile,
  canSubmitDestruction,
} from '../../../../utilities/common';
import { canUpdateTagAO } from '../../../../utilities/authorization';

const getPlateDetails = (order) => {
  if (order && order.tag) {
    return {
      action: 'viewPlate',
      icon: 'visibility',
      label: 'View license plate details',
    };
  }
  return null;
};
const reconcileLP = (tagNumber, status, activityCreator, orderNumber) => {
  if (canReconcile(tagNumber, status, activityCreator, orderNumber)) {
    return {
      action: 'reconcileOrderTag',
      icon: 'check',
      label: 'Reconcile license plate',
    };
  }
  return null;
};
const getReportLostStolen = (status) => {
  if (status !== 'MISS' && status !== 'RETN' && status !== 'DESV') {
    return {
      action: 'reportLostStolen',
      icon: 'warning',
      label: 'Report license plate lost or stolen',
    };
  }
  return null;
};
const getReportDamaged = (status) => {
  if (status !== 'MISS' && status !== 'RETN' && status !== 'DESV') {
    return {
      action: 'reportDamaged',
      icon: 'warning',
      label: 'Report license plate damaged',
    };
  }
};

const getSubmitForDestruction = (status) => {
  if (canSubmitDestruction(status)) {
    return {
      action: 'submitForDestruction',
      icon: 'delete',
      label: 'Submit license plate for destruction',
    };
  }
  return null;
};

const constructTagMenu = (
  order,
  shouldAllowUpdateOrder,
  canUpdateTag = true,
  orderNumber,
) => {
  const { tagStatus, tagNumber } = order;

  const menuList = [getPlateDetails(order)];

  if (
    shouldAllowUpdateOrder &&
    canUpdateTag &&
    order.tagNumber.substr(0, 1) !== 'G'
  ) {
    if (order.tag || ['SH', 'MISS', 'SHIP'].includes(tagStatus)) {
      menuList.push(
        reconcileLP(
          tagNumber,
          tagStatus,
          order.activity?.createdBy || order.updatedBy,
          orderNumber,
        ),
      );
    }

    if (order.tag) {
      menuList.push(
        getReportLostStolen(tagStatus),
        getReportDamaged(tagStatus),
        getSubmitForDestruction(tagStatus),
      );
    }
  }
  return menuList.filter((action) => !!action);
};

export default function TableTagActions({
  tag: order,
  reconcile,
  reportLostStolen,
  reportDamaged,
  submitForDestruction,
  shouldAllowUpdateOrder,
  orderNumber,
}) {
  const history = useHistory();
  const ability = useAppAbility();
  const canUpdateTag = canUpdateTagAO(ability);

  const [menuItems, setMenuItems] = useState(
    constructTagMenu(order, shouldAllowUpdateOrder, canUpdateTag, orderNumber),
  );

  useEffect(() => {
    if (order || orderNumber) {
      setMenuItems(
        constructTagMenu(order, shouldAllowUpdateOrder, true, orderNumber),
      );
    }
  }, [order, orderNumber]);

  const hanlderActions = (action) => {
    switch (action) {
      case 'viewPlate':
        history.push(
          `/license-plate/${order.tagNumber}?tagExpirationDate=${order.tagExpirationDate}`,
        );
        break;
      case 'reconcileOrderTag':
        reconcile(order);
        break;
      case 'reportLostStolen':
        reportLostStolen(order);
        break;
      case 'reportDamaged':
        reportDamaged(order);
        break;
      case 'submitForDestruction':
        submitForDestruction(order);
        break;
      default:
        break;
    }
  };

  return (
    <>
      {menuItems.length > 0 ? (
        <Menu
          actionIconSize="usa-icon--size-4"
          menuItems={menuItems}
          onActionClick={hanlderActions}
        />
      ) : (
        '–'
      )}
    </>
  );
}

TableTagActions.propTypes = {
  tag: PropTypes.object.isRequired,
  reconcile: PropTypes.func.isRequired,
  reportLostStolen: PropTypes.func.isRequired,
  reportDamaged: PropTypes.func.isRequired,
  orderNumber: PropTypes.string.isRequired,
};
