import React, { useEffect } from 'react';
import alertManager from '../../utilities/alert-manager';
import { useRegistration } from './reg-context-provider';

const fieldLabels = {
  vin: 'VIN',
  itemType: 'Vehicle type',
  modelYear: 'Year',
  makeCode: 'Make',
  modelCode: 'Model',
  orderNumber: 'Order number',
  makeColorName: 'Color',
  fuelCode: 'Fuel Type',
  tagNumber: 'Federal license plate',
  tagExpirationDate: 'License plate expiration date',
  agencyCode: 'Agency',
  bureauCode: 'Bureau',
  'pointOfContact1.email': 'PoC 1 email',
  'pointOfContact1.firstName': 'PoC 1 first name',
  'pointOfContact1.lastName': 'PoC 1 last name',
  'pointOfContact1.countryCode': 'PoC 1 country',
  'pointOfContact1.primaryAddress': 'PoC 1 address',
  'pointOfContact1.city': 'PoC 1 city',
  'pointOfContact1.stateCode': 'PoC 1 state',
  'pointOfContact1.postalCode': 'PoC 1 ZIP code',
  'pointOfContact1.primaryPhone': 'PoC 1 phone',
  'pointOfContact2.email': 'PoC 2 email',
  'pointOfContact2.firstName': 'PoC 2 first name',
  'pointOfContact2.lastName': 'PoC 2 last name',
  'pointOfContact2.countryCode': 'PoC 2 country',
  'pointOfContact2.primaryAddress': 'PoC 2 address',
  'pointOfContact2.city': 'PoC 2 city',
  'pointOfContact2.stateCode': 'PoC 2 state',
  'pointOfContact2.postalCode': 'PoC 2 ZIP code',
  'pointOfContact2.primaryPhone': 'PoC 2 phone',
  'Point of Contact #1': 'Point of Contact #1',
  'Point of Contact #2': 'Point of Contact #2',
};

export default function Alert() {
  const [AlertCmp, { showErrorAlert, closeAlert }] = alertManager(false, true);
  const { errors, validations } = useRegistration();

  useEffect(() => {
    if (errors && errors.length) {
      showErrorAlert(errors[0], 'Error');
      window.scrollTo(0, 0);
    } else {
      closeAlert();
    }
  }, [errors]);

  useEffect(() => {
    const keys = Object.keys(validations);
    const fieldsWithError = keys.reduce((fields, currField) => {
      if (validations[currField] && fieldLabels[currField]) {
        if (fieldLabels[currField].includes('PoC')) {
          const pocLabelToParts = fieldLabels[currField].split(' ');
          const pocGenericLabel = `Point of Contact #${pocLabelToParts[1]}`;
          if (!fields.includes(pocGenericLabel)) {
            fields.push(pocGenericLabel);
          }
        } else {
          fields.push(currField);
        }
      }
      return fields;
    }, []);
    if (fieldsWithError.length) {
      showErrorAlert(
        <div>
          <div>Field(s) below need your attention:</div>
          {fieldsWithError.map((field) => (
            <span className="field-validation-label" key={field}>
              {fieldLabels[field]}
            </span>
          ))}
        </div>,
        'Error',
      );
    } else {
      closeAlert();
    }
  }, [validations]);

  return <AlertCmp />;
}
