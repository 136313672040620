import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';

export default function registrationParams() {
  const [vin, setVin] = useState();
  const [registrationType, setRegistrationType] = useState(null);
  const params = useParams();

  useEffect(() => {
    if (params.vin) {
      const decodedVin = decodeURIComponent(params.vin);
      if (decodedVin) {
        setVin(decodedVin);
        setRegistrationType('regular');
      }
    } else {
      setRegistrationType('manual');
    }
  }, [params, setVin, setRegistrationType]);

  return [vin, registrationType, setRegistrationType];
}
