import { gql } from '@apollo/client';

export const GET_USERS_BY_ROLE = gql`
  query GetUsersByRole(
    $limit: Float
    $offset: Float
    $order: OrderBy
    $filters: [Filter!]!
    $roleId: [Int!]
  ) {
    getUsersByRole(
      limit: $limit
      offset: $offset
      order: $order
      filters: $filters
      roleId: $roleId
    ) {
      rows {
        id
        email
        firstName
        lastName
        phoneNumber
        phoneNumberExt
        phoneNumberCountryCode
      }
      count
      hasMore
    }
  }
`;

export const GET_USER_BY_EMAIL = gql`
  query GetUserByEmail($email: String!, $roles: [String!]) {
    getUserByEmail(email: $email, roles: $roles) {
      id
      email
      fullName
      phoneNumber
    }
  }
`;

export const GET_FSRS_BY_FMC = gql`
  query GetFsrsByFmc($fmc: String!) {
    getFsrsByFmc(fmc: $fmc) {
      email
    }
  }
`;
