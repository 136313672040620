import { useLazyQuery, useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { GET_BOACS_BY_AGENCY } from 'components/vehicle-reports/helpers/vehicle-reports.gql';
import {
  GET_REPORT_AGENCIES_BY_PERMISSION,
  EXPORT_REPORT,
  CREATE_REPORT_SCHEDULES,
  UPDATE_REPORT_SCHEDULES,
} from '../../../../services/data-layer';
import { GET_REPORT_SCHEDULE_BY_ID } from '../scheduled-reports/schedules.gql';

const useDataController = ({
  setInternalError,
  setAgencies,
  supressError,
  setBoacs,
  setSelectedReport,
}) => {
  const history = useHistory();
  const [agenciesQuery, { loading: agenciesQueryLoading }] = useLazyQuery(
    GET_REPORT_AGENCIES_BY_PERMISSION,
    {
      onError: (err) => {
        setInternalError(err?.message);
      },
      onCompleted: (data) => {
        // remove later
        setAgencies(data?.getAgenciesByPermission || []);
        const agencies = data?.getAgenciesByPermission || [];

        return [...agencies].map(({ id, name }) => ({
          value: id,
          label: name,
        }));
      },
    },
  );

  const [boacsQuery, { loading: boacsQueryLoading }] = useLazyQuery(
    GET_BOACS_BY_AGENCY,
    {
      fetchPolicy: 'no-cache',
      onError: (err) => {
        if (!supressError) {
          setInternalError(err);
        }
      },
      onCompleted: (data) => {
        setBoacs(data?.getBoacsByAgency || []);
        setInternalError(undefined);
      },
    },
  );

  const [getReportScheduleById, { loading: getReportScheduleByIdLoading }] =
    useLazyQuery(GET_REPORT_SCHEDULE_BY_ID, {
      fetchPolicy: 'no-cache',
      onError: (err) => {
        if (!supressError) {
          setInternalError(err);
        }
      },
      onCompleted: (data) => {
        setSelectedReport(data?.getReportScheduleById);
        setInternalError(undefined);
      },
    });

  const [triggerReportExport] = useMutation(EXPORT_REPORT, {
    fetchPolicy: 'network-only',
  });

  const [triggerReportSchedule] = useMutation(CREATE_REPORT_SCHEDULES, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      history.push({
        pathname: 'scheduled-reports',
        state: { createdSchedule: data.createReportSchedule.reportName },
      });
    },
  });

  const [triggerUpdateSchedule] = useMutation(UPDATE_REPORT_SCHEDULES, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      history.push({
        pathname: '/reports/scheduled-reports',
        state: {
          createdSchedule: data.updateReportSchedule.reportName,
          updated: true,
        },
      });
    },
  });

  return {
    query: { agenciesQuery, boacsQuery, getReportScheduleById },
    mutation: {
      triggerReportExport,
      triggerReportSchedule,
      triggerUpdateSchedule,
    },
    loading: {
      agenciesQueryLoading,
      boacsQueryLoading,
      getReportScheduleByIdLoading,
    },
  };
};

export default useDataController;
