/* eslint-disable react/prop-types */
import React, { createContext, useContext, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  GET_CUSTOMER_ACCOUNTS,
  GET_CUSTOMER_ACCOUNTS_FOR_MODAL,
} from 'components/customer-accounts/customer-account-gql';
import { GET_COUNTRIES, GET_STATES } from 'services/data-layer/address';
import {
  EDIT_OFFICE,
  DELETE_VMS_OFFICE,
  ASSOCIATE_CUSTOMER_ACCOUNTS_TO_OFFICE,
  DISASSOCIATE_CUSTOMER_ACCOUNT_FROM_OFFICE,
} from './office.gql';

export const OfficeManagementContext = createContext();

const initialState = {
  officeManagements: {
    rows: [],
    hasMore: false,
    count: 0,
  },
  modalFilterCustomerAccountsList: {
    rows: [],
    hasMore: false,
    count: 0,
  },
  currentSelectedAccountRow: null,
  currentModalName: 'OfficeDetail',
  backEndFieldValidationError: {
    fieldName: null,
    message: null,
  },
  modalState: {
    currentModalName: null,
    currentMode: null,
    currentModalAlert: { type: null, message: null, header: null },
  },
  // for custom alert set  position to 'bottom'
  alertMessage: {
    context: '',
    message: '',
    type: '',
    header: null,
    position: undefined,
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_SELECTED_ORGANIZATION':
      return { ...state, selectedOrganization: action.payload };
    case 'SET_CUSTOMER_ACCOUNTS':
      return { ...state, customerAccountsList: action.payload };
    case 'SET_MODAL_FILTER_CUSTOMER_ACCOUNTS_LIST':
      return { ...state, modalFilterCustomerAccountsList: action.payload };

    case 'SET_ALERT_MESSAGE':
      return { ...state, alertMessage: action.payload };
    case 'SET_COUNTRIES_OPTIONS':
      return { ...state, countriesOptions: action.payload };
    case 'SET_STATES_OPTIONS':
      return { ...state, statesOptions: action.payload };
    case 'SET_FIELD_VALIDATION_ERRORS':
      return { ...state, backEndFieldValidationError: action.payload };
    case 'SET_CURRENT_SELECTED_ACCOUNT_ROW':
      return { ...state, currentSelectedAccountRow: action.payload };

    case 'SET_MODAL': {
      return {
        ...state,
        modalState: { ...state.modalState, ...action.payload },
      };
    }
    default:
      throw new Error('Invalid action');
  }
};

function OfficeManagementProvider({ children, ...props }) {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    selectedOrganization: props.organizationData,
  });

  const dispatchAction = (type, payload) => {
    dispatch({
      type,
      payload,
    });
  };

  const resetCurrentModal = () => {
    dispatchAction('SET_MODAL', initialState.modalState);
  };

  const setRequestError = (requestError, context) => {
    dispatchAction('SET_ALERT_MESSAGE', {
      type: 'error',
      message: requestError?.message,
      error: requestError,
      context,
    });
  };

  // Get customer accounts list.
  const [
    getCustomerAccountsList,
    {
      loading: customerAccountsListLoading,
      refetch: refetchCustomerAccountsList,
    },
  ] = useLazyQuery(GET_CUSTOMER_ACCOUNTS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: (error) => setRequestError(error, 'customerAccountsListing'),
    onCompleted: (responseData) => {
      if (responseData?.getCustomerAccounts) {
        dispatchAction(
          'SET_CUSTOMER_ACCOUNTS',
          responseData.getCustomerAccounts,
        );
      }
    },
  });

  const [
    getModalFilterCustomerAccounts,
    { loading: modalFilterCustomerAccountsLoading },
  ] = useLazyQuery(GET_CUSTOMER_ACCOUNTS_FOR_MODAL, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: (error) => setRequestError(error, 'customerAccountsListing'),
    onCompleted: (responseData) => {
      if (responseData?.getCustomerAccounts) {
        dispatchAction(
          'SET_MODAL_FILTER_CUSTOMER_ACCOUNTS_LIST',
          responseData.getCustomerAccounts,
        );
      }
    },
  });

  const [getCountriesOptions] = useLazyQuery(GET_COUNTRIES, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: (error) => setRequestError(error, 'customerAccountsListing'),
    onCompleted: (responseData) => {
      dispatchAction('SET_COUNTRIES_OPTIONS', responseData.getCountries);
    },
  });

  const [getStatesOptions] = useLazyQuery(GET_STATES, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: (error) => setRequestError(error, 'customerAccountsListing'),
    onCompleted: (responseData) => {
      dispatchAction('SET_STATES_OPTIONS', responseData.getStates);
    },
  });

  const formatErrorResponse = (errorResponse) => {
    return {
      type: errorResponse.message?.split(':')[0]?.trim(),
      name: errorResponse.message?.split(':')[1]?.split('-')[0]?.trim(),
      message: errorResponse.message?.split(':')[1]?.split('-')[1]?.trim(),
    };
  };

  const [editOfficeDetail, { loading: loadingEditOfficeDetail }] = useMutation(
    EDIT_OFFICE,
    {
      onError: (error) => {
        const { type, name, message } = formatErrorResponse(error);
        if (type === 'fieldsvalidationError') {
          dispatchAction('SET_FIELD_VALIDATION_ERRORS', {
            fieldName: name,
            message,
          });
        } else {
          resetCurrentModal();
          dispatchAction('SET_ALERT_MESSAGE', {
            type: 'error',
            message: `There is an error updating office name ${error.message}`,
          });
        }
      },
      onCompleted: async (response) => {
        resetCurrentModal();
        dispatchAction('SET_SELECTED_ORGANIZATION', {
          ...state.selectedOrganization,
          ...response?.editOffice,
        });
        dispatchAction('SET_ALERT_MESSAGE', {
          type: 'success',
          message: `You have successfully edited ${state.selectedOrganization.officeCode} - ${state.selectedOrganization.officeName}`,
        });
      },
    },
  );

  const [
    deleteOfficeDetail,
    { loading: loadingDeleteOfficeDetail, data: deletedOfficeDetailData },
  ] = useMutation(DELETE_VMS_OFFICE, {
    onError: () => {
      dispatchAction('SET_MODAL', {
        currentModalName: 'OfficeDetail',
        currentMode: 'deleteOfficeDetailErrorModalSchema',
      });
    },
    onCompleted: async () => {
      resetCurrentModal();
      window.location.href = `${window.AFP_CONFIG.appURLs.home}/office-management`;
    },
  });

  // associate customer accounts to office
  const [
    associateCustomerAccountsToOffice,
    { loading: loadingAssociateCustomerAccountsToOffice },
  ] = useMutation(ASSOCIATE_CUSTOMER_ACCOUNTS_TO_OFFICE, {
    onError: (error) => {
      resetCurrentModal();
      dispatchAction('SET_ALERT_MESSAGE', {
        type: 'error',
        message: `There is an error associating the customer accounts to this office: ${error.message}`,
      });
    },
    onCompleted: (response) => {
      resetCurrentModal();
      dispatchAction('SET_ALERT_MESSAGE', {
        type: 'success',
        message: (
          <>
            You have successfully added{' '}
            <span className="text-bold">
              {response?.associateCustomerAccountsToOffice?.length} customer
              account(s)
            </span>{' '}
            to this office
          </>
        ),
        position: 'bottom',
      });
      refetchCustomerAccountsList();
    },
  });

  // disassociate customer account from office
  const [
    disassociateCustomerAccountFromOffice,
    { loading: loadingDisassociateCustomerAccountFromOffice },
  ] = useMutation(DISASSOCIATE_CUSTOMER_ACCOUNT_FROM_OFFICE, {
    onError: (error) => {
      resetCurrentModal();
      dispatchAction('SET_ALERT_MESSAGE', {
        type: 'error',
        message: `There is an error disassociating the customer account from this office: ${error.message}`,
      });
    },
    onCompleted: (response) => {
      resetCurrentModal();
      dispatchAction('SET_CURRENT_SELECTED_ACCOUNT_ROW', null);
      dispatchAction('SET_ALERT_MESSAGE', {
        type: 'success',
        message: (
          <>
            You have successfully removed{' '}
            <span className="text-bold">
              {response.disassociateCustomerAccountFromOffice?.accountName}
            </span>{' '}
            from this office
          </>
        ),
        position: 'bottom',
      });
      refetchCustomerAccountsList();
    },
  });

  useEffect(() => {
    getCountriesOptions();
    getStatesOptions();
  }, []);

  return (
    <OfficeManagementContext.Provider
      value={{
        ...props,
        ...state,
        getCustomerAccountsList,
        customerAccountsListLoading,
        dispatchAction,
        setRequestError,
        loadingEditOfficeDetail,
        resetCurrentModal,
        editOfficeDetail,
        deleteOfficeDetail,
        loadingDeleteOfficeDetail,
        deletedOfficeDetailData,
        associateCustomerAccountsToOffice,
        loadingAssociateCustomerAccountsToOffice,
        disassociateCustomerAccountFromOffice,
        loadingDisassociateCustomerAccountFromOffice,
        getModalFilterCustomerAccounts,
        modalFilterCustomerAccountsLoading,
      }}
    >
      {children}
    </OfficeManagementContext.Provider>
  );
}

export default OfficeManagementProvider;

OfficeManagementProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export const useOfficeManagement = () => useContext(OfficeManagementContext);
