import React, { useState, useEffect, useReducer } from 'react';
import {
  TextInput,
  Label,
  CountrySelect,
  Button,
} from '@gsa/afp-component-library';
import usePortalModal from '../../../../../utilities/portal-modal';
import { nullForForm } from '../../../../../utilities/common';
import PhoneInputMask from './phone-input-mask';
import SelectState from './select-state';
import registrationFormValidation from '../../../helpers/registration-form-validation';
import pocValidationRules from './poc-validation-rules';

const validatePocForm = (poc) => {
  return registrationFormValidation(poc, pocValidationRules);
};
const stateReducer = (state, updates) => ({ ...state, ...updates });

export const fieldLengthValidator = (e) => {
  const { name, value } = e.target;
  let validLength = true;
  const fieldLengths = {
    email: 128,
    firstName: 50,
    mi: 1,
    lastName: 50,
    primaryPhone: 20,
    primaryPhoneExt: 10,
    secondaryPhone: 20,
    secondaryPhoneExt: 10,
    primaryAddress: 120,
    secondaryAddress: 129,
    tertiaryAddress: 120,
    city: 30,
    postalCode: 15,
    stateCode: 120,
    countryCode: 2,
  };
  if (value.length > fieldLengths[name]) {
    validLength = false;
  }
  return validLength;
};

export default function CreatePocModal({
  showPocModal,
  pointOfContact,
  onClose = () => {},
  onCancel = () => {},
  onSave = () => {},
}) {
  const [poc, setPoc] = useReducer(stateReducer, pointOfContact);
  const [validations, setValidations] = useState({});
  const [Modal, openModal, closeModal] = usePortalModal();

  useEffect(() => {
    if (showPocModal === true) {
      setPoc(pointOfContact);
      openModal(true);
    } else if (showPocModal === false) {
      closeModal();
      setValidations({});
    }
  }, [showPocModal]);

  useEffect(() => {
    if (pointOfContact) {
      setPoc(pointOfContact);
    }
  }, [pointOfContact]);

  useEffect(() => {
    if (!poc.countryCode) {
      setPoc({ countryCode: 'US' });
    }
  }, [poc]);

  const getFieldValidation = (field) => validations[field] || '';

  const handleNonUSPhoneNumberChange = (e) => {
    const { name, value } = e.target;
    const sanitizedValue = value.replace(/\D/g, '');
    if (fieldLengthValidator({ target: { name, value: sanitizedValue } })) {
      setPoc({ [name]: sanitizedValue });
    }
  };

  const handlePostalCodeChange = (e) => {
    const { name, value } = e.target;
    const sanitizedValue = value.replace(/\D/g, '');
    if (fieldLengthValidator({ target: { name, value: sanitizedValue } })) {
      setPoc({ [name]: sanitizedValue });
    }
  };

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    if (fieldLengthValidator(e)) {
      setPoc({ [name]: value });
    }
  };

  const handleSave = () => {
    const vals = validatePocForm(poc);
    if (Object.values(vals).some((val) => !!val)) {
      setValidations(vals);
    } else {
      onSave(poc);
    }
  };

  return (
    <Modal
      actions={
        <>
          <Button
            type="button"
            onClick={onCancel}
            variant="unstyled"
            className="margin-right-205"
            label="Cancel"
          />
          <Button
            type="button"
            data-testid="poc-button-save"
            onClick={handleSave}
            label="Save and close"
          />
        </>
      }
      onClose={onClose}
    >
      <p>Please provide additional information for this point of contact.</p>
      <div className="grid-row grid-gap">
        <div className="grid-col-12">
          <TextInput
            value={nullForForm(poc.email)}
            label="Email"
            name="email"
            type="text"
            disabled
            required
            data-testid="poc-email"
          />
        </div>
      </div>
      <div className="grid-row grid-gap">
        <div className="tablet:grid-col-6">
          <TextInput
            label="First Name"
            data-testid="poc firstName"
            name="firstName"
            onChange={handleFieldChange}
            value={nullForForm(poc.firstName)}
            required
            errorMessage={getFieldValidation(`firstName`)}
          />
        </div>
        <div className="tablet:grid-col-6">
          <TextInput
            label="Last Name"
            data-testid="poc lastName"
            name="lastName"
            value={nullForForm(poc.lastName)}
            required
            onChange={handleFieldChange}
            errorMessage={getFieldValidation(`lastName`)}
          />
        </div>
      </div>
      <div className="grid-row grid-gap">
        <div className="grid-col-12">
          <Label className="text-primary text-heavy">MAILING ADDRESS</Label>
        </div>
      </div>
      <div className="grid-row grid-gap">
        <div className="tablet:grid-col-9">
          <CountrySelect
            label="Country"
            name="countryCode"
            withUsTerritories={false}
            value={nullForForm(poc.countryCode)}
            onChange={handleFieldChange}
            errorMessage={getFieldValidation(`countryCode`)}
            required
          />
        </div>
      </div>
      <div className="grid-row grid-gap">
        <div className="grid-col-12">
          <TextInput
            label="Street Address"
            name="primaryAddress"
            value={nullForForm(poc.primaryAddress)}
            required
            onChange={handleFieldChange}
            errorMessage={getFieldValidation(`primaryAddress`)}
          />
        </div>
      </div>
      <div className="grid-row grid-gap">
        <div className="grid-col-12">
          <TextInput
            label="Street Address 2 (optional)"
            name="secondaryAddress"
            value={nullForForm(poc.secondaryAddress)}
            onChange={handleFieldChange}
            errorMessage={getFieldValidation(`secondaryAddress`)}
          />
        </div>
      </div>
      <div className="grid-row grid-gap">
        <div className="tablet:grid-col-5">
          <TextInput
            label="City"
            name="city"
            value={nullForForm(poc.city)}
            required
            onChange={handleFieldChange}
            errorMessage={getFieldValidation(`city`)}
          />
        </div>
        <div className="tablet:grid-col-7">
          <SelectState
            name="stateCode"
            onChange={handleFieldChange}
            countryCode={nullForForm(poc.countryCode)}
            value={nullForForm(poc.stateCode)}
            required
            errorMessage={getFieldValidation(`stateCode`)}
          />
        </div>
      </div>
      <div className="grid-row grid-gap">
        <div className="tablet:grid-col-4">
          <TextInput
            label="Zip"
            name="postalCode"
            value={nullForForm(poc.postalCode)}
            required
            onChange={handlePostalCodeChange}
            errorMessage={getFieldValidation(`postalCode`)}
          />
        </div>
      </div>
      <div className="grid-row grid-gap">
        <div className="grid-col-12">
          <Label className="text-primary text-heavy">PHONE NUMBER</Label>
        </div>
      </div>
      <div className="grid-row grid-gap">
        <div className="tablet:grid-col-6">
          {poc.countryCode === 'US' ? (
            <PhoneInputMask
              label="Phone Number"
              name="primaryPhone"
              value={nullForForm(poc.primaryPhone)}
              required
              onChange={handleFieldChange}
              errorMessage={getFieldValidation(`primaryPhone`)}
            />
          ) : (
            <TextInput
              label="Phone number"
              name="primaryPhone"
              required
              value={nullForForm(poc.primaryPhone)}
              onChange={handleNonUSPhoneNumberChange}
              errorMessage={getFieldValidation(`primaryPhone`)}
            />
          )}
        </div>
        <div className="tablet:grid-col-3">
          <TextInput
            label="Extension"
            name="primaryPhoneExt"
            value={nullForForm(poc.primaryPhoneExt)}
            onChange={handleFieldChange}
            errorMessage={getFieldValidation(`primaryPhoneExt`)}
          />
        </div>
      </div>
    </Modal>
  );
}
