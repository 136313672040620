import { useState } from 'react';
export default function errorManager() {
  const [errors, setError] = useState([]);

  const addError = (error) => {
    setError((oldErr) => [...oldErr, error]);
  };
  const clearError = () => {
    setError([]);
  };

  return [errors, { addError, clearError }];
}
