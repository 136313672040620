import React, { useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useTitle } from '@gsa/afp-shared-ui-utils';
import { PageTitle, Breadcrumbs, Spinner } from '@gsa/afp-component-library';
import PoolDetailsTabs from './pool-details-tabs';
import { useMotorPool } from '../motor-pool-provider';
import MotorPoolDetailsHeader from './motor-pool-details-header';

const MotorPoolDetails = () => {
  useTitle('Motor Pool Details');
  const params = useParams();
  const { id } = params;

  const { getMotorPoolById, selectedMotorPoolById, getMotorPoolByIdLoading } =
    useMotorPool();

  const trail = [
    <a href={`${window.AFP_CONFIG.appURLs.home}/home`}>Home</a>,
    <Link to="/motor-pools">Dispatch and Reservation</Link>,
  ];

  useEffect(() => {
    if (id) {
      getMotorPoolById({
        variables: {
          id,
        },
      });
    }
  }, []);

  if (getMotorPoolByIdLoading) {
    return <Spinner className="padding-y-9" />;
  }

  return (
    <>
      <Breadcrumbs
        trail={trail}
        current={selectedMotorPoolById?.poolName || 'Motor Pool Details'}
      />
      <MotorPoolDetailsHeader />
      {selectedMotorPoolById && !getMotorPoolByIdLoading ? (
        <>
          <header>
            <PageTitle
              title={selectedMotorPoolById?.poolName || 'Motor Pool Details'}
            />
          </header>
          <div className="tablet:grid-col-7">
            {selectedMotorPoolById?.description && (
              <p>{selectedMotorPoolById?.description}</p>
            )}
          </div>
          <div className="margin-top-4">
            <PoolDetailsTabs />
          </div>
        </>
      ) : null}
    </>
  );
};

export default MotorPoolDetails;
