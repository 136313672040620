import React from 'react';
import { Button, Modal } from '@gsa/afp-component-library';
import moment from 'moment';
import { useFcReplacement } from './fc-replacement-context-provider';

const AddReplacementCardConfirmationModal = () => {
  const {
    isAddReplacementCardConfirmationModalOpen,
    closeAddReplacementCardConfirmationModal,
    addCardConfirmationData,
    dispatch,
  } = useFcReplacement();

  const onCloseModal = () => {
    dispatch({
      type: 'SET_ADD_CARD_CONFIRMATION_DATA',
      payload: undefined,
    });
    closeAddReplacementCardConfirmationModal();
  };

  if (!isAddReplacementCardConfirmationModalOpen) {
    return null;
  }

  return (
    <div className="afp-modal-wrapper">
      <div className="afp-modal-overlay">
        <Modal
          id="replacement-card-max-order-conf-modal"
          variant="large"
          title={<h2>Confirm card replacement</h2>}
          onClose={onCloseModal}
          actions={
            <>
              <Button
                variant="unstyled"
                onClick={onCloseModal}
                label="Cancel"
                data-testid="cancel-replacement-card-confirmation-button"
              />
              <Button
                variant="primary"
                type="submit"
                form="replacement-card-form"
                label="Proceed"
                data-testid="submit-replacement-card-confirmation-button"
              />
            </>
          }
        >
          This card was recently ordered on{' '}
          {moment(addCardConfirmationData?.date).format('ll')}. Do you want to
          proceed with ordering the replacement card for{' '}
          {addCardConfirmationData?.tag} ?
        </Modal>
      </div>
    </div>
  );
};

export default AddReplacementCardConfirmationModal;
