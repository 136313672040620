import React from 'react';
import { useRegistration } from '../../reg-context-provider';
import VehicleInfoReview from './vehicle-info-review';
import AgencyAndPocReview from './agency-and-poc-review';

export default function ReviewAndRegister() {
  const { formState, setStep } = useRegistration();
  return (
    <main
      data-testid="step2"
      className="afp-vehicle-registration__step afp-vehicle-registration-wrapper"
    >
      <div className="grid-row grid-gap margin-bottom-4">
        <VehicleInfoReview formState={formState} setStep={setStep} />
        <AgencyAndPocReview formState={formState} setStep={setStep} />
      </div>
    </main>
  );
}
