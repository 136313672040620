import React from 'react';
import _ from 'lodash';
import { Icon, ButtonDropdown, Button } from '@gsa/afp-component-library';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import { useLicensePlateDetails } from '../license-plate-details-provider';
import {
  canDisassociate,
  canReconcile,
  canSubmitDestruction,
} from '../../../utilities/common';
import { useLicensePlateDestruction } from '../../license-plate-destruction/context';
import {
  canUpdateTagAO,
  canUpdateTagGF,
} from '../../../utilities/authorization';
import LicensePlateStolen from './lp-stolen/license-plate-stolen';

const LpActions = () => {
  const {
    tagDetails,
    setDisassociationPayload,
    setReconciliationPayload,
    reportingStolenLPGF,
    setReportingStolenLPGF,
    reportStolenLPGF,
    getMissingPlates,
    setCreateOrUpdateMissingTags,
  } = useLicensePlateDetails();

  const { setForDestructionList, toggleDestructionEditing } =
    useLicensePlateDestruction();

  const ability = useAppAbility();
  const canUpdateTag = canUpdateTagAO(ability);
  const canUpdateGFTag = canUpdateTagGF(ability);

  const MISSING_TAG_STATUS = 'MISS';
  // null undefined only if a plate does not exist.
  const NO_STOLEN_ACTION = [MISSING_TAG_STATUS, 'RETN', 'DESV', 'DESA', 'TGCH', null, undefined];

  const plateAStatus = _.get(tagDetails, 'tagActivityTypeCode');
  const plateBStatus = _.get(tagDetails, 'counterPartTag.tagActivityTypeCode');
  const plateAMissing = plateAStatus === MISSING_TAG_STATUS;
  const plateBMissing = plateBStatus === MISSING_TAG_STATUS;

  const isGFTag = () => {
    return _.get(tagDetails, 'id')?.substr(0, 1) === 'G';
  };

  const disassociateLp = (type) => {
    setDisassociationPayload({
      type,
      vinToDisassociateFrom: _.get(tagDetails, 'vehicle.id'), // preserve it for the alert message
      tagNumber: _.get(tagDetails, 'id'),
      expirationDate: _.get(tagDetails, 'expirationDate'),
    });
  };

  const reconcileLp = () => {
    setReconciliationPayload({
      tagNumber: _.get(tagDetails, 'id'),
      expirationDate: _.get(tagDetails, 'expirationDate'),
    });
  };

  const submitDestructionLp = () => {
    const destTags = [tagDetails];
    if (
      tagDetails.counterPartTag &&
      canSubmitDestruction(
        _.get(tagDetails, 'counterPartTag.tagActivityTypeCode'),
      )
    ) {
      destTags.push({
        ...tagDetails,
        aOrB: tagDetails.counterPartTag.aOrB,
      });
    }
    setForDestructionList(destTags);
    toggleDestructionEditing();
  };

  const actionTrigger = (trigger) => {
    if (_.isFunction(trigger)) {
      trigger();
    }
  };

  const VIEW_HISTORY_ACTION = {
    label: 'View license plate history',
    iconName: 'calendar_today',
    anchorLink: '#license-plate-history',
  };
 
  const GF_ACTIONS_MAPPING = [
    {
      label: 'Report plate(s) missing',
      iconName: 'warning',
      trigger: () => {
        setCreateOrUpdateMissingTags({
          aOrB: 'both',
          createOrUpdate: 'create',
        });
        setReportingStolenLPGF(true);
      },
      hidden:
        !isGFTag() ||
        !canUpdateGFTag ||
        NO_STOLEN_ACTION.includes(plateAStatus) ||
        NO_STOLEN_ACTION.includes(plateBStatus),
    },
    {
      label: 'Report plate A missing',
      iconName: 'warning',
      trigger: async () => {
        setCreateOrUpdateMissingTags({
          tagId: _.get(tagDetails, 'uuid'),
          aOrB: 'A',
          createOrUpdate: 'create',
        });
        setReportingStolenLPGF(true);
      },
      hidden:
        !isGFTag() ||
        !canUpdateGFTag ||
        NO_STOLEN_ACTION.includes(plateAStatus) ||
        !NO_STOLEN_ACTION.includes(plateBStatus),
    },
    {
      label: 'Report plate B missing',
      iconName: 'warning',
      trigger: async () => {
        setCreateOrUpdateMissingTags({
          tagId: _.get(tagDetails, 'counterPartTag.uuid'),
          aOrB: 'B',
          createOrUpdate: 'create',
        });
        setReportingStolenLPGF(true);
      },
      hidden:
        !isGFTag() ||
        !canUpdateGFTag ||
        NO_STOLEN_ACTION.includes(plateBStatus) ||
        !NO_STOLEN_ACTION.includes(plateAStatus),
    },
    {
      label: 'Update missing plates',
      iconName: 'edit',
      trigger: () => {
        setCreateOrUpdateMissingTags({
          tagId: _.get(tagDetails, 'uuid'),
          aOrB: 'both',
          createOrUpdate: 'update',
        });
        getMissingPlates({ tagNumber: _.get(tagDetails, 'id') });
        setReportingStolenLPGF(true);
      },
      hidden:
        !isGFTag() || !canUpdateGFTag || !(plateAMissing && plateBMissing),
    },
    {
      label: 'Update missing plate A',
      iconName: 'edit',
      trigger: async () => {
        setCreateOrUpdateMissingTags({
          tagId: _.get(tagDetails, 'uuid'),
          aOrB: 'A',
          createOrUpdate: 'update',
        });
        getMissingPlates({ tagNumber: _.get(tagDetails, 'id') });
        setReportingStolenLPGF(true);
      },
      hidden:
        !isGFTag() ||
        !canUpdateGFTag ||
        !plateAMissing ||
        (plateAMissing && plateBMissing),
    },
    {
      label: 'Update missing plate B',
      iconName: 'edit',
      trigger: async () => {
        setCreateOrUpdateMissingTags({
          tagId: _.get(tagDetails, 'counterPartTag.uuid'),
          aOrB: 'B',
          createOrUpdate: 'update',
        });
        getMissingPlates({ tagNumber: _.get(tagDetails, 'id') });
        setReportingStolenLPGF(true);
      },
      hidden:
        !isGFTag() ||
        !canUpdateGFTag ||
        !plateBMissing ||
        (plateAMissing && plateBMissing),
    },
  ];

  const AO_ACTIONS_MAPPING = [
    {
      label: 'Reconcile license plate',
      iconName: 'check',
      trigger: () => reconcileLp(),
      hidden:
        !canReconcile(
          _.get(tagDetails, 'id'),
          _.get(tagDetails, 'tagActivityTypeCode'),
          _.get(tagDetails, 'createdBy'),
          _.get(tagDetails, 'unicorTag.orderNumber'),
        ) || !canUpdateTag,
    },
    {
      label: 'Report license plate lost or stolen',
      iconName: 'warning',
      trigger: () => disassociateLp('lostStolen'),
      hidden:
        isGFTag() ||
        !canDisassociate(
          _.get(tagDetails, 'tagActivityTypeCode'),
          _.get(tagDetails, 'unicorTag.orderNumber'),
        ) ||
        !canUpdateTag,
    },
    {
      label: 'Report license plate damaged',
      iconName: 'warning',
      trigger: () => disassociateLp('damaged'),
      hidden:
        isGFTag() ||
        !canDisassociate(
          _.get(tagDetails, 'tagActivityTypeCode'),
          _.get(tagDetails, 'unicorTag.orderNumber'),
        ) ||
        !canUpdateTag,
    },
    {
      label: 'Submit plate for destruction',
      iconName: 'delete',
      trigger: () => submitDestructionLp(),
      hidden:
        !canUpdateTag ||
        isGFTag() ||
        !canSubmitDestruction(_.get(tagDetails, 'tagActivityTypeCode')),
    },
  ];

  if (_.get(tagDetails, 'tagActivityTypeCode') === 'UNAT') {
    return (
      <Button
        data-testid="tag-destruction-button"
        className="margin-top-2"
        type="button"
        variant="primary"
        onClick={() => {
          submitDestructionLp();
        }}
        label="Submit plate for destruction"
        leftIcon={{ name: 'delete' }}
      />
    );
  }

  return (
    <div className="margin-top-5 margin-bottom-2">
      <ButtonDropdown
        label="License plate Actions"
        variant="primary"
        side="right"
      >
        {_.map(
          [VIEW_HISTORY_ACTION, ...AO_ACTIONS_MAPPING, ...GF_ACTIONS_MAPPING],
          (action, index) => {
            if (_.get(action, 'hidden')) {
              return null;
            }
            return (
              <div key={action.label.split(' ').join('')}>
                <div className="display-flex flex-align-center width-full height-6 text-no-underline text-ink hover:text-no-underline hover:text-ink text-no-wrap min-width-100p">
                  <Icon
                    iconName={_.get(action, 'iconName')}
                    className="margin-right-2 margin-left-1 text-bottom text-base"
                  />
                  <a
                    className="text-no-underline margin-right-1"
                    href={
                      _.get(action, 'anchorLink')
                        ? _.get(action, 'anchorLink')
                        : '#'
                    }
                    onClick={() =>
                      _.get(action, 'trigger')
                        ? actionTrigger(_.get(action, 'trigger'))
                        : undefined
                    }
                  >
                    {_.get(action, 'label')}
                  </a>
                </div>
                <div className="width-full border-bottom border-base-lighter" />
              </div>
            );
          },
        )}
      </ButtonDropdown>
      {reportingStolenLPGF && (
        <LicensePlateStolen
          onClose={() => setReportingStolenLPGF(false)}
          submitMissingReport={(data) => {
            const {
              tagNumber,
              stolenDate,
              gsaReportedDate,
              dhsReportedDate,
              dhsCaseNumber,
              ncicNumber,
              comment,
              tagA,
              tagB,
            } = data;
            reportStolenLPGF({
              tagNumber,
              stolenDate,
              gsaReportedDate,
              dhsReportedDate,
              dhsCaseNumber,
              ncicNumber,
              comment,
              tagA,
              tagB,
            });
          }}
        />
      )}
    </div>
  );
};

export default LpActions;
