import React from 'react';
import CreateOrdersProvider from './create-orders-provider';
import CreateOrdersHeader from './header';
import CreateOrdersTables from './create-orders-tables';
import CreateOrdersButtons from './create-orders-buttons';
import CreateOrdersModal from './create-orders-modal';

const CreateOrders = () => {
  return (
    <>
      <CreateOrdersProvider>
        <CreateOrdersHeader />
        <CreateOrdersTables />
        <CreateOrdersButtons />
        <CreateOrdersModal />
      </CreateOrdersProvider>
    </>
  );
};

export default CreateOrders;
