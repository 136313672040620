import moment from 'moment';

export const formatModifiedPms = (data) => {
  const pmList = [...data];
  const formattedPmList = [];
  let isValidData = true;
  pmList.forEach((pm) => {
    if (
      pm?.errors?.currentPmDate ||
      pm?.errors?.currentPmMileage ||
      (!pm.currentPmMileage && !pm.currentPmDate)
    ) {
      isValidData = false;
    }
    if (pm?.modified) {
      formattedPmList.push({
        currentPmDate: pm?.currentPmDate,
        currentPmMileage: Number(pm?.currentPmMileage),
        assetId: pm?.assetId,
        invoiceModificationFlag: pm?.invoiceModificationFlag,
        file: pm?.file,
        fileName: pm?.fileName,
        fileType: pm?.fileType,
        docMetaId: pm?.docMetaId,
      });
    }
  });
  return {
    isValid: isValidData,
    payload: formattedPmList,
  };
};

export const scrollInto = (elementId) => {
  const element = document.getElementById(elementId);
  if (element) {
    element.scrollIntoView({
      behavior: 'smooth',
    });
  }
};

export const isAcceptedDateFormat = (date) => {
  return (
    moment(date, 'M/D/YY', true).isValid() ||
    moment(date, 'MM/D/YY', true).isValid() ||
    moment(date, 'M/DD/YY', true).isValid() ||
    moment(date, 'MM/DD/YY', true).isValid() ||
    moment(date, 'M/D/YYYY', true).isValid() ||
    moment(date, 'MM/D/YYYY', true).isValid() ||
    moment(date, 'M/DD/YYYY', true).isValid() ||
    moment(date, 'MM/DD/YYYY', true).isValid() ||
    moment(date, moment.ISO_8601, true).isValid()
  );
};

export const fileTypes = ['.pdf', '.jpeg', '.jpg', '.png', 'bmp'];
export const title = 'Import USCAN / SAP files';
