import React from 'react';
import { Breadcrumbs } from '@gsa/afp-component-library';
import { useParams } from 'react-router-dom';
import MotorPoolProvider from './motor-pool-provider';
import MotorPoolFilterProvider from './filters/filter-provider';
import MotorPoolHeader from './motor-pool-header';
import MotorPoolDetails from './motor-pool-details';
import MotorPoolListing from './motor-pool-listing';

const MotorPool = () => {
  const params = useParams();
  const { id } = params;
  const trail = [<a href={`${window.AFP_CONFIG.appURLs.home}/home`}>Home</a>];

  return (
    <>
      <MotorPoolProvider>
        <MotorPoolFilterProvider>
          {id ? (
            <MotorPoolDetails />
          ) : (
            <>
              <Breadcrumbs trail={trail} current="Dispatch and Reservation" />
              <div className="margin-bottom-3">
                <MotorPoolHeader />
              </div>
              <MotorPoolListing />
            </>
          )}
        </MotorPoolFilterProvider>
      </MotorPoolProvider>
    </>
  );
};

export default MotorPool;
