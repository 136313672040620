import React, { createContext, useContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import userFilterManager from '../user-filters';
import { getFilterWithCustomerNumber } from './widgets/utils';
import { GET_FILTERED_VENDORS_TYPE_AHEAD_OPTIONS } from 'services/data-layer/fed-hierarchy.gql';
import { vehicleFilterKeys } from 'components/user-filters/filter-structures/constants';
import { useLazyQuery } from '@apollo/client';

const VehicleInventoryFilterContext = createContext({});
const useVehicleInventoryFilter = () =>
  useContext(VehicleInventoryFilterContext);

const initialState = {
  filterStructure: [],
  customerNumber: '',
  typeaheadData: { field: '', values: [] },
  isGetVehicles: process.env.NODE_ENV === 'test',
};

export const actions = {
  SET_TYPEAHEAD_DATA: 'SET_TYPEAHEAD_DATA',
  SET_FILTERS_STRUCTURE: 'SET_FILTERS_STRUCTURE',
  SET_CUSTOMER_NUMBER: 'SET_CUSTOMER_NUMBER',
  SET_FETCH_VEHICLES: 'SET_FETCH_VEHICLES',
};

const vehicleInventoryReducer = (state, { action, payload }) => {
  switch (action) {
    case actions.SET_CUSTOMER_NUMBER: {
      return { ...state, customerNumber: payload };
    }
    case actions.SET_FILTERS_STRUCTURE: {
      return { ...state, filterStructure: payload };
    }
    case actions.SET_TYPEAHEAD_DATA: {
      return { ...state, typeaheadData: payload };
    }
    case actions.SET_FETCH_VEHICLES: {
      return { ...state, isGetVehicles: payload };
    }
    default:
      break;
  }
};

const VehicleInventoryFilterProvider = ({ children, legacyCustomerNumber }) => {
  const [state, setDispatch] = useReducer(
    vehicleInventoryReducer,
    initialState,
  );

  const dispatchAction = (action, payload) => setDispatch({ action, payload });

  const setAssembledFilters = (structure) => {
    dispatchAction(actions.SET_FILTERS_STRUCTURE, structure);
  };

  const setFetchVehicles = (isGetVehicles) => {
    dispatchAction(actions.SET_FETCH_VEHICLES, isGetVehicles);
  };

  const { userFilter } = userFilterManager('vehicle-inventory');

  const [getFilteredVendors] = useLazyQuery(
    GET_FILTERED_VENDORS_TYPE_AHEAD_OPTIONS,
    {
      fetchPolicy: 'network-only', // Used for first execution
      onCompleted: (vendors) => {
        setFetchVehicles(false);
        if (vendors?.getVendorTypeAheadOptions) {
          const { options } = vendors?.getVendorTypeAheadOptions;
          dispatchAction(actions.SET_TYPEAHEAD_DATA, {
            field: vehicleFilterKeys.VEHICLE_LOCATION,
            values: options.map(({ vendorName }) => vendorName),
            options,
          });
        }
      },
    },
  );

  useEffect(() => {
    dispatchAction(actions.SET_FILTERS_STRUCTURE, userFilter);
  }, []);

  useEffect(() => {
    if (legacyCustomerNumber) {
      getFilterWithCustomerNumber(userFilter, legacyCustomerNumber);
      dispatchAction(actions.SET_CUSTOMER_NUMBER, legacyCustomerNumber);
    }
  }, [legacyCustomerNumber]);

  return (
    <VehicleInventoryFilterContext.Provider
      value={{
        ...state,
        dispatchAction,
        getFilteredVendors,
        setAssembledFilters,
        setFetchVehicles,
      }}
    >
      {children}
    </VehicleInventoryFilterContext.Provider>
  );
};

VehicleInventoryFilterProvider.defaultProps = {
  children: undefined,
  legacyCustomerNumber: undefined,
};

VehicleInventoryFilterProvider.propTypes = {
  children: PropTypes.node,
  legacyCustomerNumber: PropTypes.string,
};

export { VehicleInventoryFilterProvider as default, useVehicleInventoryFilter };
