/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { emdash } from 'components/common';
import moment from 'moment';
import EditableCell from 'utilities/editable-cell';
import { result } from 'lodash';
import useUser from 'utilities/use-user';

export const RowSubDetail = ({ row, updateCellData }) => {
  const borderClasses = classnames(
    'border-bottom border-base-lighter padding-y-1',
  );

  const { original: detail } = row;

  const getDetailValue = (path) => {
    return result(detail, path) || emdash;
  };

  const date = new Date();

  const mileageReportingMonth = row?.original?.mileageReportingMonth;
  const mileageReportingYear = row?.original?.mileageReportingYear;
  const isCurrentMonthReported =
    mileageReportingMonth === date.getMonth() + 1 &&
    date.getFullYear() === mileageReportingYear;

  const { isCustomer } = useUser();

  const getSourceType = (data) => {
    if (data?.mileageReportingStatus === 'Ready for update') return emdash;
    if (data?.sourceType === 'GORP') return 'GORP/FCT';
    if (data?.sourceType === 'DESC') return 'GORP/DESC';

    if (data?.mileageReportingStatus === 'Telematics' && !isCurrentMonthReported)
      return emdash;

    return detail?.sourceType || emdash;
  };

  const getEnteredBy = (data) => {
    if (
      data?.mileageReportingStatus === 'Pending' ||
      data?.mileageReportingStatus === 'Reported'
    ) {
      if (data?.sourceType === 'GORP' || data?.sourceType === 'DESC') {
        return emdash;
      }

      if (data?.enteredByUserFullname?.toUpperCase().includes('VMS'))
        return emdash;

      return data?.enteredByUserFullname || emdash;
    }
    return emdash;
  };

  const getDateEntered = (data) => {
    if (data?.mileageReportingStatus === 'Ready for update') return emdash;
    if (data?.mileageReportingStatus === 'Telematics' && !isCurrentMonthReported)
      return emdash;
    // need to handle telematics scenario
    return data?.createdAt
      ? moment.utc(detail?.createdAt).format('MM/DD/YYYY')
      : emdash;
  };

  return (
    <div className="grid-row grid-gap flex-justify">
      <div className="tablet:grid-col-6">
        <div
          className={`${borderClasses} display-flex flex-justify`}
          data-testid="data-section"
        >
          <strong>Fund code</strong>
          {detail.mileageReportingStatus === 'Reported' ||
          detail?.sourceType === 'Telematics' ? (
            getDetailValue('vehicle.fundCode')
          ) : (
            <EditableCell
              inputType="text"
              characterLimit={2}
              defaultValue={detail?.vehicle?.fundCode}
              row={row}
              column={{ id: 'fundCode' }}
              updateCellData={updateCellData}
              aria-label="fundCode editable field"
            />
          )}
        </div>
        <div
          className={`${borderClasses} display-flex flex-justify`}
          data-testid="data-section"
        >
          <strong>Description 1</strong>
          {detail.mileageReportingStatus === 'Reported' ||
          detail?.sourceType === 'Telematics' ? (
            getDetailValue('account1')
          ) : (
            <EditableCell
              characterLimit={8}
              defaultValue={detail?.account1}
              row={row}
              column={{ id: 'account1' }}
              updateCellData={updateCellData}
              aria-label="description-1 editable field"
            />
          )}
        </div>
        <div
          className={`${borderClasses} display-flex flex-justify`}
          data-testid="data-section"
        >
          <strong>Descripton 2</strong>
          {detail?.mileageReportingStatus === 'Reported' ||
          detail?.sourceType === 'Telematics' ? (
            getDetailValue('account2')
          ) : (
            <EditableCell
              characterLimit={8}
              defaultValue={detail?.account2}
              row={row}
              column={{ id: 'account2' }}
              updateCellData={updateCellData}
              aria-label="description-2 editable field"
            />
          )}
        </div>

        <div
          className={`${borderClasses} display-flex flex-justify`}
          data-testid="data-section"
        >
          <strong>VIN</strong>
          {detail?.vehicle?.vin ? (
            <Link
              to={`/vehicles/${encodeURIComponent(
                detail?.vehicle.vin,
              )}/mileage`}
            >
              {detail?.vehicle.vin}
            </Link>
          ) : (
            emdash
          )}
        </div>

        {isCustomer() && (
          <div
            className={`${borderClasses} display-flex flex-justify`}
            data-testid="data-section"
          >
            <strong>State plate</strong>
            {detail?.stateTagNumber || emdash}
          </div>
        )}
      </div>
      <div className="tablet:grid-col-6">
        <div
          className={`${borderClasses} display-flex flex-justify`}
          data-testid="data-section"
        >
          <strong>Legacy customer number</strong>
          {detail?.vehicle?.customer?.legacyCustomerNumber || emdash}
        </div>

        <div
          className={`${borderClasses} display-flex flex-justify`}
          data-testid="data-section"
        >
          <strong>Customer account </strong>
          {detail?.vehicle?.customer?.accountName || emdash}
        </div>

        <div
          className={`${borderClasses} display-flex flex-justify`}
          data-testid="data-section"
        >
          <strong>Method of entry</strong>
          {getSourceType(detail)}
        </div>

        <div
          className={`${borderClasses} display-flex flex-justify`}
          data-testid="data-section"
        >
          <strong>Entered by</strong>
          {getEnteredBy(detail)}
        </div>
        <div
          className={`${borderClasses} display-flex flex-justify`}
          data-testid="data-section"
        >
          <strong>Date entered </strong>
          {getDateEntered(detail)}
        </div>
      </div>
    </div>
  );
};
