import React from 'react';
import PropTypes from 'prop-types';

export default function LicenseDetailEmail({ label, value }) {
  return (
    <div className="detail-row display-flex flex-justify padding-y-1 border-bottom border-width-2px border-base-lighter">
      <div className="detail-title">
        <strong>{label}</strong>
      </div>
      <div className="detail-value">
        <a aria-label="mailto" href={`mailto:${value}`}>
          {value}
        </a>
      </div>
    </div>
  );
}

LicenseDetailEmail.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};
