import React from 'react';
import { OptIn } from '@gsa/afp-shared-ui-utils';
import MileageSummary from './mileage-summary';
import MileageHistoryTable from './mileage-history-table';
import { useVehicle } from '../../vehicle-context-provider';
import { isMileageEnabled } from '../../helpers/feature-helper';
import MileageHistoryGFProvider from './mileage-history-gf-provider';

const MileageHistory = () => {
  const { vehicle } = useVehicle();
  if (!vehicle) return null;
  const { agencyCode, bureauCode, ownershipTypeCode } = vehicle;

  if (
    ownershipTypeCode === 'GF' &&
    isMileageEnabled(agencyCode, ownershipTypeCode)
  ) {
    return (
      <MileageHistoryGFProvider>
        <MileageSummary />
        <div className="margin-top-5">
          <MileageHistoryTable />
        </div>
      </MileageHistoryGFProvider>
    );
  }

  return (
    <OptIn
      agencyCode={agencyCode}
      bureauCode={bureauCode}
      isHidden={!isMileageEnabled(agencyCode, ownershipTypeCode)}
    >
      <MileageHistoryGFProvider>
        <MileageSummary />
        <MileageHistoryTable />
      </MileageHistoryGFProvider>
    </OptIn>
  );
};

export default MileageHistory;
