const guards = {
  notEmpty: (value, _formState) => {
    return value !== null && value !== undefined && value !== '';
  },
  validEmail: (value, _) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(value);
  },
  modelNotEmpty: (_, formState) => {
    return formState?.modelName;
  },
  validAndUniqueEmail: (value, formState) => {
    const { pointOfContact1: poc1, pointOfContact2: poc2 } = formState;

    const unique =
      poc1.email !== poc2.email &&
      poc1.email.toLowerCase() !== poc2.email.toLowerCase();
    return guards.validEmail(value, formState) && unique;
  },
  isUniqueEmail: (_, formState) => {
    const { email: poc1Email } = formState?.pointOfContact1;
    const { email: poc2Email } = formState?.pointOfContact2;

    if (!poc1Email || !poc2Email) {
      return true;
    }
    return poc1Email.toLowerCase() !== poc2Email.toLowerCase();
  },
  isUniquePoCEmail: (_, state) => {
    const { email, otherEmail } = state;

    if (!email || !otherEmail) {
      return true;
    }
    return email.toLowerCase() !== otherEmail.toLowerCase();
  },
  isVerifiedVin: (_, formState) => {
    return formState?.nhtsaVerified || formState?.userVerifiedVin;
  },
  isValidYear: (value) => {
    return value && value > 0; // legacy data will either have a valid year or equal -1
  },
  isNumber: (value) => {
    return typeof value === 'number';
  },
  isString: (value) => {
    return typeof value === 'string';
  },
  isBoolean: (value) => {
    return typeof value === 'boolean';
  },
  notEmptyOrExempt: (value, formState) => {
    return formState.exemptPlate || guards.notEmpty(value);
  },
  isAgencyProvided: (value, formState) => {
    return (
      !!value || !!formState.tagAgencyCode || !!formState.selectedAgencyCode
    );
  },
  isBureauProvided: (value, formState) => {
    return (
      !!value || !!formState.tagBureauCode || !!formState.selectedBureauCode
    );
  },
  minLength10(value) {
    return value && value.length >= 10;
  },
};

export default guards;
