import React, { useState, useEffect } from 'react';
import { SelectDropdown } from '@gsa/afp-component-library';
import { useLazyQuery } from '@apollo/client';
import { sortBy } from 'lodash';
import { GET_BUREAUS_BY_PERMISSION } from '../../../../services/data-layer';
import {
  VMSOperations,
  VMSSubjects,
  DEFAULT_SELECT_OPTION_LABEL,
} from '../../../../utilities/consts';

export default function SelectBureau({
  agencyCode,
  setIsMultiBureauScoped,
  bureauCode,
  onChange = () => {},
  onUpdate = () => {},
  ...props
}) {
  const [getBureaus, { data }] = useLazyQuery(GET_BUREAUS_BY_PERMISSION, {
    fetchPolicy: 'no-cache',
    onError(err) {
      console.warn(err);
    },
  });
  const [bureaus, setBureaus] = useState([]);

  useEffect(() => {
    if (agencyCode) {
      getBureaus({
        variables: {
          agencyCode,
          operation: VMSOperations.CREATE_VEHICLE,
          subject: VMSSubjects.VEHICLE,
        },
      });
    } else {
      setBureaus([{ value: '', label: DEFAULT_SELECT_OPTION_LABEL }]);
    }
  }, [agencyCode]);

  useEffect(() => {
    if (data?.getBureausByPermission) {
      setBureaus([
        { value: '', label: DEFAULT_SELECT_OPTION_LABEL },
        ...sortBy(data.getBureausByPermission, 'id').map((b) => ({
          value: b.id,
          label: `${b.id} - ${b.name}`,
        })),
      ]);
      if (data?.getBureausByPermission.length === 1) {
        const valueToPrepoluate = data?.getBureausByPermission[0];

        onChange({
          value: valueToPrepoluate.id,
          label: `${valueToPrepoluate.id} - ${valueToPrepoluate.name}`,
        });
      } else if (data?.getBureausByPermission.length > 1) {
        setIsMultiBureauScoped(true);
      }
    }
  }, [data]);

  useEffect(() => {
    if (bureauCode && bureaus.length) {
      const selectedBureau = bureaus.find(
        (bureau) => bureau.value === bureauCode,
      );
      if (selectedBureau) {
        onUpdate(selectedBureau);
      }
    }
  }, [bureauCode, bureaus]);

  const handleChange = (e) => {
    const { value } = e.target;
    const selectedBureau = bureaus.find((bureau) => bureau.value === value);
    if (selectedBureau) {
      onChange(selectedBureau);
    }
  };

  return (
    <SelectDropdown
      id="bureau"
      name="bureau"
      label="Bureau"
      required
      options={bureaus}
      onChange={handleChange}
      value={bureauCode}
      {...props}
    />
  );
}
