/* eslint-disable no-shadow */
/* eslint-disable array-callback-return */
/* eslint-disable import/prefer-default-export */
import { selectorFamily } from 'recoil';
import { runWatcher, setValueByPath } from '../form';
import { getFieldsKeys } from './helper';

export const createLayoutSelector = ({ atoms }) =>
  selectorFamily({
    key: 'form/layoutSelector',
    get:
      (pageId) =>
      ({ get }) => {
        return get(atoms.layoutSchemaAtom(pageId));
      },
    set:
      (pageId) =>
      ({ get, set }, { pathInfo = [], layoutSchema, currentStep = 1 }) => {
        const pageLayoutAtom = atoms.layoutSchemaAtom(pageId);
        const layout = setValueByPath({
          data: layoutSchema ?? get(pageLayoutAtom),
          pathInfo,
        });

        set(pageLayoutAtom, {
          currentStep,
          layout,
        });
      },
  });

export const createFormFieldsInfoSelector = ({ atoms }) =>
  selectorFamily({
    key: 'form/setupSelector',
    get:
      (pageId) =>
      ({ get }) => {
        const currentStepKeys = getFieldsKeys({
          createLayoutSelector,
          atoms,
          pageId,
          get,
        });
        const fieldInfoMap = new Map();
        if (currentStepKeys) {
          currentStepKeys.map((key) => {
            const { value, isDirty, errorMessage } = get(
              atoms.formFieldAtom(key),
            );
            fieldInfoMap.set(key, { value, isDirty, errorMessage });
          });
        }

        return fieldInfoMap;
      },
  });

// the selector for set the field
// code function for update the field, include validation and atoms state value and after value change
export const createFieldSelector = ({ atoms }) =>
  selectorFamily({
    key: 'form/fieldSelector',
    get:
      ({ id }) =>
      ({ get }) => {
        return get(atoms.formFieldAtom(id));
      },
    set:
      ({ id }) =>
      ({ get, set }, { dependency, ...newObj }) => {
        set(atoms.formFieldAtom(id), {
          ...get(atoms.formFieldAtom(id)),
          ...newObj,
        });
        if (atoms.pageConfigAtom) {
          const dependantFunctions = get(
            atoms.pageConfigAtom('dependantFunctions'),
          );
          if (dependantFunctions.watcher) {
            runWatcher({
              atoms,
              currentId: id,
              currentVal: newObj.value,
              get,
              set,
              dependantFunctions,
            });
          }
        }
      },
  });

//  it contains temporary fix will  refactor  entire process
export const createFieldAfterSelector = ({ atoms, atomStateSchema }) =>
  selectorFamily({
    key: 'form/fieldAfterSelector',

    set:
      ({ id }) =>
      ({ get, set }, { onWatcher = false, ...newObj }) => {
        const { value } = get(atoms.formFieldAtom(id));

        if (atomStateSchema?.formFields[id]?.validate) {
          const validationResult = atomStateSchema.formFields[id]?.validate({
            value: newObj ? newObj.value : value,
          });
          if (onWatcher) {
            set(atoms.formFieldAtom(id), {
              ...get(atoms.formFieldAtom(id)),
              ...validationResult,
              ...newObj,
            });
          } else {
            set(atoms.formFieldAtom(id), {
              ...get(atoms.formFieldAtom(id)),
              ...validationResult,
              isDirty: true,
            });
          }
        }

        if (atoms.pageConfigAtom) {
          const dependantFunctions = get(
            atoms.pageConfigAtom('dependantFunctions'),
          );
          if (dependantFunctions.watcher) {
            runWatcher({
              atoms,
              currentId: id,
              currentVal: newObj.value,
              get,
              set,
              dependantFunctions,
              type: 'onBlur',
            });
          }
        }
      },
  });

export const createPageSetupSelector = selectorFamily({
  key: 'form/setupSelector',
  get: () => () => null,
  set:
    () =>
    ({ get, set }, { pageConfigs, atoms }) => {
      if (pageConfigs) {
        Object.keys(pageConfigs).map((key) => {
          const pageState = get(atoms.pageConfigAtom(key));
          set(atoms.pageConfigAtom(key), {
            ...pageState,
            ...pageConfigs[key].default,
          });
        });
      }
    },
});

export const createFormSetupSelector = ({ atoms }) =>
  selectorFamily({
    key: 'form/setupSelector',
    get: () => () => null,
    set:
      (pageId) =>
      ({ get, set }, { newFields, caches }) => {
        // need pathInfo for snapshot
        let pathInfo;
        if (newFields) {
          if (newFields.setupType === 'update') {
            pathInfo = Object.keys(newFields.formFields).map((key) => {
              const fieldState = atoms.formFieldAtom(key);
              set(fieldState, {
                ...get(fieldState),
                ...newFields.formFields[key].default,
                ...(newFields.editData[key].value && {
                  ...newFields.editData[key],
                }),
              });
              return [
                key,
                newFields.editData[key].value ??
                  newFields.formFields[key].default.value,
              ];
            });
          } else {
            pathInfo = Object.keys(newFields).map((key) => {
              const fieldState = get(atoms.formFieldAtom(key));
              set(atoms.formFieldAtom(key), {
                ...fieldState,
                ...newFields[key].default,
              });
              return [key, newFields[key].default.value];
            });
          }
        }
        // will have another round upgrade for snapshot if recoil snapshot api still under develop
        if (caches?.takeSnapshot) {
          if (caches.takeSnapshot === 'init') {
            set(
              atoms.pageConfigAtom('snapshot'),
              setValueByPath({
                data: get(atoms.pageConfigAtom('snapshot')),
                pathInfo,
              }),
            );
          }
          if (caches.takeSnapshot === 'recent') {
            const layoutAtom = get(atoms.layoutSchemaAtom(pageId));
            const stateIdsOfCurrentStep = layoutAtom.layout[0].stateIds;
            pathInfo = stateIdsOfCurrentStep.map((key) => {
              const fieldState = get(atoms.formFieldAtom(key));
              return [key, fieldState?.value];
            });
            set(
              atoms.pageConfigAtom('snapshot'),
              setValueByPath({
                data: get(atoms.pageConfigAtom('snapshot')),
                pathInfo,
              }),
            );
          }
        }
      },
  });
