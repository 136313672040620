/* eslint-disable no-case-declarations */
import { useReducer, useEffect } from 'react';

const getValidSteps = (stepConfig, formState) =>
  stepConfig.filter((step) =>
    step.includeStep ? step.includeStep(formState) : true,
  );

const getStepperValues = (state, steps, stepIndex) => {
  const totalSteps = steps.length;
  const step = steps[stepIndex];
  return {
    ...state,
    steps,
    totalSteps,
    stepIndex,
    stepLabel: step.label,
    stepStateIds: step.stateIds,
    StepComponent: step.component,
    isFirstStep: stepIndex === 0,
    isLastStep: stepIndex === totalSteps - 1,
  };
};

const getInitialState = (stepConfig) => {
  const steps = getValidSteps(stepConfig, {});
  return getStepperValues({ stepConfig }, steps, 0);
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_STEPS':
      return { ...state, steps: action.payload };
    case 'INCREMENT_STEP':
      const { formState } = action.payload;
      const steps = getValidSteps(state.steps, formState);
      const maxIndex = steps.length - 1;
      const nextIndex = state.stepIndex + 1;
      if (nextIndex <= maxIndex) {
        return getStepperValues(state, steps, nextIndex);
      }
      break;
    case 'DECREMENT_STEP':
      if (!state.isFirstStep) {
        return getStepperValues(state, state.steps, state.stepIndex - 1);
      }
      break;
    case 'JUMP_TO_STEP':
      const { label } = action.payload;
      const stepIndex = state.steps.findIndex((step) => step.label === label);
      if (stepIndex >= 0) {
        return getStepperValues(state, state.steps, stepIndex);
      }
      break;
    default:
      return state;
  }
};

const useStepper = (stepConfig) => {
  const [state, dispatch] = useReducer(reducer, getInitialState(stepConfig));

  useEffect(() => {
    dispatch({ type: 'SET_STEPS', payload: stepConfig });
  }, [stepConfig]);

  const incrementStep = (formState) => {
    dispatch({ type: 'INCREMENT_STEP', payload: { formState } });
  };

  const decrementStep = () => {
    dispatch({ type: 'DECREMENT_STEP' });
  };

  const jumpToStep = (label) => {
    dispatch({ type: 'JUMP_TO_STEP', payload: { label } });
  };

  const scrollToStepper = () => {
    const scrollDiv = document.getElementById('stepper-wrapper').offsetTop;
    window.scrollTo({ top: scrollDiv, behavior: 'smooth' });
  };

  return {
    ...state,
    incrementStep,
    decrementStep,
    jumpToStep,
    scrollToStepper,
  };
};

export default useStepper;
