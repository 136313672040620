import {
  DetailsTable,
  StatusBadge,
  Tag,
  Button,
} from '@gsa/afp-component-library';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import useCanPerformActions from 'hooks/use-can-perform-actions';
import { Link } from 'react-router-dom';
import {
  canUpdateGFVehicleAdmin,
  canUpdateGFVehicleFSR,
} from 'utilities/authorization';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import { formatTagExpDate } from '../../../utilities/common';
import { tagStatuses } from '../../../utilities/consts';
import { useLicensePlateDetails } from '../license-plate-details-provider';
import LpInfoEditModal from './lp-info-edit-modal';
import LpLocationEditModal from './lp-location-edit-modal';

const LpInfo = ({ id }) => {
  const ability = useAppAbility();
  const { tagDetails, setUpdatingLpInfo, setUpdatingLpLocation } =
    useLicensePlateDetails();
  const canEditLpInfo = useCanPerformActions().canEditLpInfo();
  const lpLocation = {
    ...tagDetails?.tagLocation,
  };
  const isGFTag = _.get(tagDetails, 'id')?.substr(0, 1) === 'G';

  const formatAddress = () => {
    if (lpLocation) {
      if (lpLocation.address1) {
        return (
          <div className="text-right">
            <div id="tag-address1">{lpLocation?.address1}</div>
            <div id="tag-address2">{lpLocation?.address2}</div>
            <div id="tag-address3">{lpLocation?.address3}</div>
            <div id="tag-city-state-zip">
              {lpLocation?.city}, {lpLocation?.stateCode}{' '}
              {lpLocation?.postalCode} {lpLocation?.countryCode}
            </div>
          </div>
        );
      }
      if (lpLocation.locationName === 'ZVRC') {
        return <div className="text-right">ZVRC</div>;
      }
    }
    return <div className="text-right">—</div>;
  };

  const isFSROrAdmin =
    canUpdateGFVehicleFSR(ability) || canUpdateGFVehicleAdmin(ability);
  return (
    <div className="grid-row grid-gap">
      <div className="grid-col-12 margin-bottom-2">
        <div
          data-testid="tag-number"
          className="tablet:display-flex flex-align-center"
        >
          <h1 className="title-xl margin-0">License Plate {id}</h1>
          <Tag className="text-middle bg-primary-lighter margin-x-3">
            <span className="text-base-darker text-normal padding-x-3 tablet:padding-x-1">
              EXPIRES: {formatTagExpDate(_.get(tagDetails, 'expirationDate'))}
            </span>
          </Tag>
        </div>
      </div>
      <div className="tablet:grid-col-8">
        <div className="bg-gray-3 padding-3">
          <DetailsTable
            bordered
            data={[
              [
                'License plate A status',
                <span className="float-right align-right status-info">
                  {_.get(tagDetails, 'tagActivityTypeCode') ? (
                    <StatusBadge
                      variant={_.get(tagStatuses, [
                        _.get(tagDetails, 'tagActivityTypeCode'),
                        'color',
                      ])}
                    >
                      {_.get(tagStatuses, [
                        _.get(tagDetails, 'tagActivityTypeCode'),
                        'statusShort',
                      ])}
                    </StatusBadge>
                  ) : (
                    '—'
                  )}
                </span>,
              ],
              [
                'License plate B status',
                <span className="float-right align-right status-info">
                  {_.get(tagDetails, 'counterPartTag.tagActivityTypeCode') ? (
                    <StatusBadge
                      variant={_.get(tagStatuses, [
                        _.get(tagDetails, 'counterPartTag.tagActivityTypeCode'),
                        'color',
                      ])}
                    >
                      {_.get(tagStatuses, [
                        _.get(tagDetails, 'counterPartTag.tagActivityTypeCode'),
                        'statusShort',
                      ])}
                    </StatusBadge>
                  ) : (
                    '—'
                  )}
                </span>,
              ],
              [
                'Expiration Date',
                <span className="float-right align-right">
                  {formatTagExpDate(_.get(tagDetails, 'expirationDate'))}
                </span>,
              ],
              [
                'UNICOR order',
                <span className="float-right align-right">
                  {_.get(tagDetails, 'unicorTag.orderNumber') ? (
                    <Link
                      to={`/order/${_.get(
                        tagDetails,
                        'unicorTag.orderNumber',
                      )}`}
                    >
                      {_.get(tagDetails, 'unicorTag.orderNumber')}
                    </Link>
                  ) : (
                    '—'
                  )}
                </span>,
              ],
              [
                'Last Updated',
                <span className="float-right align-right">
                  {_.get(tagDetails, 'updatedAt')
                    ? `${moment(
                        new Date(_.get(tagDetails, 'updatedAt')),
                      ).format('L')} • ${moment(
                        new Date(_.get(tagDetails, 'updatedAt')),
                      ).format('h:mm a')}`
                    : '—'}
                </span>,
              ],
              [
                'License plate location',
                <span className="float-right align-right">
                  {formatAddress()}
                </span>,
              ],
            ]}
          />
          {isFSROrAdmin && isGFTag && (
            <Button
              label="Edit plate location"
              variant="outline"
              className="bg-white margin-top-2"
              onClick={() => {
                setUpdatingLpLocation(true);
              }}
            />
          )}
          {canEditLpInfo && (
            <Button
              label="Edit"
              variant="outline"
              className="bg-white margin-top-2"
              onClick={() => {
                setUpdatingLpInfo(true);
              }}
            />
          )}
        </div>
      </div>
      <LpInfoEditModal />
      <LpLocationEditModal />
    </div>
  );
};

export default LpInfo;
