import React from 'react';
import { Menu } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

const MENU_ITEMS = [
  {
    action: 'viewOrder',
    icon: 'visibility',
    label: 'View order',
  },
];

export default function OrderAction({ order }) {
  const history = useHistory();

  const handleActions = (action) => {
    switch (action) {
      case 'viewOrder':
        history.push(`/order/${order.orderNumber}`);
        break;
      default:
        break;
    }
  };

  return (
    <div className="height-4 width-4">
      <Menu
        actionIconSize="usa-icon--size-4"
        menuItems={MENU_ITEMS}
        iconColor="text-primary"
        onActionClick={handleActions}
      />
    </div>
  );
}

OrderAction.propTypes = {
  order: PropTypes.shape(Object).isRequired,
};
