import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { SelectDropdown } from '@gsa/afp-component-library';
import { CDD_FORM_FIELDS, YES_NO_OPTIONS } from './consts';
import { generationOptions } from '../../helpers/utils';

const VehicleExemptionsForm = () => {
  const {
    formState: { errors },
    control,
    aldCategories,
  } = useFormContext();

  return (
    <div data-testid="cdd-vehicle-exemptions-form">
      <Controller
        name={CDD_FORM_FIELDS.isGasAfv.name}
        control={control}
        render={({ field, ref }) => {
          return (
            <SelectDropdown
              {...field}
              ref={ref}
              label={
                <span className="text-bold">
                  Petroleum-dedicated LGHG vehicle qualifying as AFV
                </span>
              }
              name={CDD_FORM_FIELDS.isGasAfv.name}
              id={CDD_FORM_FIELDS.isGasAfv.id}
              options={YES_NO_OPTIONS}
              errorMessage={
                errors && errors[CDD_FORM_FIELDS.isGasAfv.name]
                  ? errors[CDD_FORM_FIELDS.isGasAfv.name].message
                  : null
              }
              aria-invalid={
                errors && errors[CDD_FORM_FIELDS.isGasAfv.name]
                  ? 'true'
                  : 'false'
              }
            />
          );
        }}
      />
      <Controller
        name={CDD_FORM_FIELDS.aldCoverageDesignation.name}
        control={control}
        render={({ field, ref }) => {
          return (
            <SelectDropdown
              {...field}
              ref={ref}
              label={
                <span className="text-bold">EPAct coverage designation</span>
              }
              name={CDD_FORM_FIELDS.aldCoverageDesignation.name}
              id={CDD_FORM_FIELDS.aldCoverageDesignation.id}
              options={generationOptions(
                'epact_coverage_designation',
                aldCategories,
              )}
              errorMessage={
                errors && errors[CDD_FORM_FIELDS.aldCoverageDesignation.name]
                  ? errors[CDD_FORM_FIELDS.aldCoverageDesignation.name].message
                  : null
              }
              aria-invalid={
                errors && errors[CDD_FORM_FIELDS.aldCoverageDesignation.name]
                  ? 'true'
                  : 'false'
              }
            />
          );
        }}
      />
      <Controller
        name={CDD_FORM_FIELDS.ald2005701.name}
        control={control}
        render={({ field, ref }) => {
          return (
            <SelectDropdown
              {...field}
              ref={ref}
              label={
                <span className="text-bold">
                  EPAct 2005 section 701 designation
                </span>
              }
              name={CDD_FORM_FIELDS.ald2005701.name}
              id={CDD_FORM_FIELDS.ald2005701.id}
              options={generationOptions(
                'epact_2005_section_701_designation',
                aldCategories,
              )}
              errorMessage={
                errors && errors[CDD_FORM_FIELDS.ald2005701.name]
                  ? errors[CDD_FORM_FIELDS.ald2005701.name].message
                  : null
              }
              aria-invalid={
                errors && errors[CDD_FORM_FIELDS.ald2005701.name]
                  ? 'true'
                  : 'false'
              }
            />
          );
        }}
      />
      <Controller
        name={CDD_FORM_FIELDS.ald141Designation.name}
        control={control}
        render={({ field, ref }) => {
          return (
            <SelectDropdown
              {...field}
              ref={ref}
              label={
                <span className="text-bold">EISA section 141 designation</span>
              }
              name={CDD_FORM_FIELDS.ald141Designation.name}
              id={CDD_FORM_FIELDS.ald141Designation.id}
              options={generationOptions(
                'eisa_section_141_designation',
                aldCategories,
              )}
              errorMessage={
                errors && errors[CDD_FORM_FIELDS.ald141Designation.name]
                  ? errors[CDD_FORM_FIELDS.ald141Designation.name].message
                  : null
              }
              aria-invalid={
                errors && errors[CDD_FORM_FIELDS.ald141Designation.name]
                  ? 'true'
                  : 'false'
              }
            />
          );
        }}
      />
    </div>
  );
};

export default VehicleExemptionsForm;
