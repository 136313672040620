import { useLazyQuery, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useModal } from '@gsa/afp-component-library';
import React, { createContext, useContext, useReducer } from 'react';
import { useVehicle } from 'components/vehicle-details/vehicle-context-provider';
import {
  ADD_MILEAGE_LOG_FOR_GF_VEHICLES,
  GET_MILEAGE_BY_VEHICLE,
  GET_MILEAGE_BY_GF_VEHICLE,
  EDIT_MILEAGE_LOG_FOR_GF_VEHICLE,
  DELETE_MILEAGE_LOG,
  IS_TELEMATICS_ACTIVE,
} from './mileage-history.gql';

export const MileageHistoryGFContext = createContext();

const initialState = {
  alertMessage: { message: '', type: '', header: null, location: '' },
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_MILEAGE_HISTORY':
      return { ...state, mileageHistory: action.payload };
    case 'SET_SELECTED_MILEAGE_HISTORY_RECORD':
      return { ...state, selectedMileageHistoryRecord: action.payload };
    case 'SET_IS_TELEMATICS_ACTIVE':
      return { ...state, isTelematicsActive: action.payload };
    case 'SET_ALERT_MESSAGE':
      return { ...state, alertMessage: action.payload };
    default:
      throw new Error('Invalid action');
  }
};

function MileageHistoryGFProvider({ children, ...props }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { setAlertMessages, refetchVehicle, showSystemError } = useVehicle();

  const {
    isOpen: isManageGFMileageModalOpen,
    openModal: openManageGFMileageModal,
    closeModal: closeManageGFMileageModal,
  } = useModal();

  const {
    isOpen: isDeleteGFMileageModalOpen,
    openModal: openDeleteGFMileageModal,
    closeModal: closeDeleteGFMileageModal,
  } = useModal();

  const dispatchAction = (type, payload) => {
    dispatch({
      type,
      payload,
    });
  };

  const setRequestError = (requestError, location) => {
    dispatchAction('SET_ALERT_MESSAGE', {
      type: 'error',
      message: requestError?.message,
      error: requestError,
      location,
    });
  };

  const [
    getMileageHistoryForVehicle,
    {
      loading: mileageHistoryLoading,
      error: mileageHistoryError,
      networkStatus: mileageHistoryNetworkStatus,
    },
  ] = useLazyQuery(GET_MILEAGE_BY_VEHICLE, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (responseData) => {
      if (responseData) {
        dispatchAction('SET_MILEAGE_HISTORY', responseData);
      }
    },
  });

  const [
    getMileageHistoryForGFVehicle,
    {
      loading: mileageHistoryGFLoading,
      error: mileageHistoryGFError,
      refetch: refetchMileageHistory,
      networkStatus: mileageHistoryGFNetworkStatus,
    },
  ] = useLazyQuery(GET_MILEAGE_BY_GF_VEHICLE, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (responseData) => {
      if (responseData) {
        dispatchAction('SET_MILEAGE_HISTORY', responseData);
      }
    },
  });

  const [checkTelematicsDevice] = useLazyQuery(IS_TELEMATICS_ACTIVE, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: () => {
      showSystemError(true);
    },
    onCompleted: (responseData) => {
      if (responseData) {
        dispatchAction(
          'SET_IS_TELEMATICS_ACTIVE',
          responseData.isTelematicsActive,
        );
      }
    },
  });

  const [addMileageLogForGFVehicles, { loading: addingMileageForGFVehicle }] =
    useMutation(ADD_MILEAGE_LOG_FOR_GF_VEHICLES, {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onError: (error) => setRequestError(error, 'manageGFMileageModal'),
      onCompleted: (response) => {
        if (response?.addMileageLogForGFVehicles) {
          refetchVehicle();
          refetchMileageHistory();
          closeManageGFMileageModal();
          setAlertMessages([
            {
              type: 'success',
              message: 'Mileage was successfully submitted.',
            },
          ]);
        }
      },
    });

  const [updateMileageForGFVehicle, { loading: editingMileageForGFVehicle }] =
    useMutation(EDIT_MILEAGE_LOG_FOR_GF_VEHICLE, {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onError: (error) => setRequestError(error, 'manageGFMileageModal'),
      onCompleted: (response) => {
        if (response?.editMileageForGFVehicle) {
          refetchVehicle();
          refetchMileageHistory();
          closeManageGFMileageModal();
          setAlertMessages([
            {
              type: 'success',
              message: 'Mileage was successfully updated.',
            },
          ]);
        }
      },
    });

  const [deleteGFMileage, { loading: deleteGFMileageLoading }] = useMutation(
    DELETE_MILEAGE_LOG,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onError: (error) => setRequestError(error, 'deleteGFMileageModal'),
      onCompleted: (response) => {
        if (response?.deleteMileageLog) {
          refetchVehicle();
          refetchMileageHistory();
          closeManageGFMileageModal();
          setAlertMessages([
            {
              type: 'success',
              message: 'Mileage record successfully deleted.',
            },
          ]);
        }
      },
    },
  );

  return (
    <MileageHistoryGFContext.Provider
      value={{
        ...state,
        dispatchAction,
        addMileageLogForGFVehicles,
        addingMileageForGFVehicle,
        isManageGFMileageModalOpen,
        openManageGFMileageModal,
        closeManageGFMileageModal,
        isDeleteGFMileageModalOpen,
        openDeleteGFMileageModal,
        closeDeleteGFMileageModal,
        getMileageHistoryForVehicle,
        mileageHistoryLoading,
        mileageHistoryError,
        mileageHistoryNetworkStatus,
        getMileageHistoryForGFVehicle,
        mileageHistoryGFLoading,
        mileageHistoryGFError,
        mileageHistoryGFNetworkStatus,
        updateMileageForGFVehicle,
        editingMileageForGFVehicle,
        deleteGFMileage,
        deleteGFMileageLoading,
        checkTelematicsDevice,
        ...props,
      }}
    >
      {children}
    </MileageHistoryGFContext.Provider>
  );
}

export default MileageHistoryGFProvider;

MileageHistoryGFProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export const useMileageHistoryGF = () => useContext(MileageHistoryGFContext);
