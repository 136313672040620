import React from 'react';
import { Alert } from '@gsa/afp-component-library';
import ServerErrorMessage from 'components/error-handlers/server-error-message';
import { useMotorPool } from '../motor-pool-provider';
import { NO_MOTOR_POOL_TIME_ZONE } from '../messages';

const MotorPoolDetailsHeader = () => {
  const { alertMessage, selectedMotorPoolById, getMotorPoolByIdLoading } =
    useMotorPool();
  const { header, message, type, context, error } = alertMessage;

  if (
    selectedMotorPoolById &&
    !getMotorPoolByIdLoading &&
    !selectedMotorPoolById?.timezone
  ) {
    return (
      <div className="margin-top-2" data-testid="motor-pool-no-timezone-alert">
        <Alert type="error">{NO_MOTOR_POOL_TIME_ZONE.body()}</Alert>
      </div>
    );
  }

  if (
    (context !== 'motorPoolVehicleListing' &&
      context !== 'reservationListing' &&
      context !== 'motorPoolMembersListing' &&
      context !== 'deleteMotorPoolVehicle' &&
      context !== 'deleteMotorPoolReservation') ||
    !message
  ) {
    return null;
  }

  if (
    type === 'error' &&
    (context === 'motorPoolMembersListing' ||
      context === 'deleteMotorPoolVehicle' ||
      context === 'deleteMotorPoolReservation')
  ) {
    return (
      <div className="margin-bottom-2" data-testid="motor-pool-details-alert">
        <Alert type={type} heading={header || null}>
          {message}
        </Alert>
      </div>
    );
  }

  if (type === 'error') {
    return <ServerErrorMessage graphQLErrors={error} />;
  }

  return (
    <div className="margin-top-2" data-testid="motor-pool-details-alert">
      <Alert type={type} heading={header || null}>
        {message}
      </Alert>
    </div>
  );
};

export default MotorPoolDetailsHeader;
