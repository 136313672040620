import React from 'react';
import PropTypes from 'prop-types';
import { FilterPanel } from '@gsa/afp-component-library';
import DateMonthYearRange, {
  displayValue,
} from '../../filters/widgets/date-month-year-range';

const MileageDateRangeFilter = ({ filter }) => (
  <FilterPanel.FilterPanelField
    key={[filter.key]}
    filter={{
      ...filter,
      isSingleValueFilter: true,
      displayValue,
    }}
    component={DateMonthYearRange}
  />
);

MileageDateRangeFilter.propTypes = {
  filter: PropTypes.shape(FilterPanel.FilterPanelFilterShape).isRequired,
};

export default MileageDateRangeFilter;
