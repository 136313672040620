/* eslint-disable filenames/match-exported */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable filenames/match-regex */
import React from 'react';
import { Button, Spinner } from '@gsa/afp-component-library';

const DeleteOfficeDetailModalSchema = {
  title: () => <h2>Delete office</h2>,
  content: (providerContext) => {
    const {
      loadingDeleteOfficeDetail,
      selectedOrganization: { officeCode, officeName },
    } = providerContext;

    return (
      <>
        {loadingDeleteOfficeDetail ? (
          <Spinner />
        ) : (
          <p>
            {' '}
            Are you sure you want to delete {officeCode} - {officeName}{' '}
          </p>
        )}
      </>
    );
  },
  action: ({
    resetCurrentModal,
    deleteOfficeDetail,
    selectedOrganization: { agencyCode, officeCode, bureauCode },
  }) => (
    <>
      <Button
        variant="unstyled"
        className="margin-right-2"
        onClick={resetCurrentModal}
        data-testid="motor-pool-export-cancel-button"
        label="Cancel"
      />
      <Button
        variant="secondary"
        type="submit"
        form="office-delete-detail-form"
        data-testid="utilization-report-form-submit-button"
        label="Delete office"
        onClick={() => {
          deleteOfficeDetail({
            variables: {
              office: {
                agencyCode,
                bureauCode,
                officeCode,
              },
            },
          });
        }}
      />
    </>
  ),
  onClose: ({ resetCurrentModal }) => {
    resetCurrentModal();
  },
};

export default DeleteOfficeDetailModalSchema;
