import React from 'react';
import { StatusBadge } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';

const RESERVATION_STATUS_COLORS = {
  'Vehicle Reserved': 'Ready-Gray',
  'Vehicle Dispatched': 'Info-Gray',
  'Reservation Closed/Completed': 'Inactive-Gray',
};

const RESERVATION_STATUS = {
  'Vehicle Reserved': 'Reserved',
  'Vehicle Dispatched': 'Dispatched',
  'Reservation Closed/Completed': 'Completed',
};

export default function ReservationStatusBadge({ status }) {
  return (
    <StatusBadge variant={RESERVATION_STATUS_COLORS[status]}>
      {RESERVATION_STATUS[status]}
    </StatusBadge>
  );
}

ReservationStatusBadge.propTypes = {
  status: PropTypes.string.isRequired,
};
