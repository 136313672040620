/* eslint-disable react/prop-types */
// eslint-disable-next-line filenames/match-exported
import React from 'react';
import {
  RequiredFieldIndicator,
  TextInput,
  RadioButton,
  SelectDropdown,
} from '@gsa/afp-component-library';

import { Controller, useFormContext } from 'react-hook-form';

import { optionsToRadioButton } from 'utilities/format';

const RecallActionFields = ({
  action,
  getDisabledField,
  recallCampaignData,
}) => {
  const {
    formState: { errors },
    control,
    setValue,
  } = useFormContext();

  const sourceMap = {
    Manual: 'Manual entry',
    CarFax: 'CarFax',
    Ford: 'Ford',
    GM: 'GM',
    Chrysler: 'Chrysler',
  };
  const selectDropDownOptions = [
    { label: 'Safety recall campaign', value: 'Safety' },
    { label: 'Non-safety campaign', value: 'Non-Safety' },
    { label: 'Warranty campaign', value: 'Warranty' },
  ];

  return (
    <>
      <div>
        <Controller
          name="recallCode"
          control={control}
          render={({ field, ref }) => {
            return (
              <TextInput
                {...field}
                ref={ref}
                type="text"
                characterLimit="36"
                label={
                  <span className="text-bold">
                    Manufacturer campaign ID <RequiredFieldIndicator />
                  </span>
                }
                readOnly={getDisabledField(
                  recallCampaignData,
                  action,
                  'recallCode',
                )}
                name="recallCode"
                id="recallCode"
                errorMessage={
                  errors && errors['recallCode']
                    ? errors['recallCode'].message
                    : null
                }
                aria-invalid={errors && errors['recallCode'] ? 'true' : 'false'}
              />
            );
          }}
        />

        <Controller
          name="recallDescription"
          control={control}
          render={({ field, ref }) => {
            return (
              <TextInput
                {...field}
                ref={ref}
                type="text"
                characterLimit="36"
                label={
                  <span className="text-bold">
                    Campaign <RequiredFieldIndicator />
                  </span>
                }
                readOnly={getDisabledField(
                  recallCampaignData,
                  action,
                  'recallDescription',
                )}
                name="recallDescription"
                id="recallDescription"
                errorMessage={
                  errors && errors['recallDescription']
                    ? errors['recallDescription'].message
                    : null
                }
                aria-invalid={
                  errors && errors['recallDescription'] ? 'true' : 'false'
                }
              />
            );
          }}
        />

        {action === 'edit' && (
          <TextInput
            type="text"
            label={<span className="text-bold">Source Name</span>}
            readOnly
            name="sourceName"
            id="sourceName"
            value={sourceMap[recallCampaignData?.recallSource] ?? '-'}
            aria-invalid={errors && errors['recallCode'] ? 'true' : 'false'}
          />
        )}

        <Controller
          name="detailDescription"
          control={control}
          render={({ field, ref }) => {
            return (
              <TextInput
                {...field}
                ref={ref}
                label={<span className="text-bold">Recall description</span>}
                type="textarea"
                name="detailDescription"
                id="detailDescription"
                readOnly={getDisabledField(
                  recallCampaignData,
                  action,
                  'detailDescription',
                )}
                errorMessage={
                  errors && errors['detailDescription']
                    ? errors['detailDescription'].message
                    : null
                }
                aria-invalid={
                  errors && errors['detailDescription'] ? 'true' : 'false'
                }
              />
            );
          }}
        />

        <Controller
          name="safetyRisk"
          control={control}
          render={({ field, ref }) => {
            return (
              <TextInput
                {...field}
                ref={ref}
                label={<span className="text-bold">Safety Risk</span>}
                type="textarea"
                name="safetyRisk"
                id="safetyRisk"
                readOnly={getDisabledField(
                  recallCampaignData,
                  action,
                  'safetyRisk',
                )}
              />
            );
          }}
        />

        <Controller
          name="remedyDescription"
          control={control}
          render={({ field, ref }) => {
            return (
              <TextInput
                {...field}
                ref={ref}
                label={<span className="text-bold">Remedy</span>}
                type="textarea"
                name="remedyDescription"
                id="remedyDescription"
                readOnly={getDisabledField(
                  recallCampaignData,
                  action,
                  'remedyDescription',
                )}
              />
            );
          }}
        />

        <Controller
          name="nhtsaCampaignId"
          control={control}
          render={({ field, ref }) => {
            return (
              <TextInput
                {...field}
                ref={ref}
                type="text"
                label={<span className="text-bold">NHTSA recall ID</span>}
                name="nhtsaCampaignId"
                id="nhtsaCampaignId"
                readOnly={getDisabledField(
                  recallCampaignData,
                  action,
                  'nhtsaCampaignId',
                )}
                errorMessage={
                  errors && errors['nhtsaCampaignId']
                    ? errors['nhtsaCampaignId'].message
                    : null
                }
                aria-invalid={
                  errors && errors['nhtsaCampaignId'] ? 'true' : 'false'
                }
              />
            );
          }}
        />
        <Controller
          name="recallType"
          control={control}
          render={({ field }) => {
            return (
              <SelectDropdown
                {...field}
                label={
                  <span className="text-bold">
                    Recall type <RequiredFieldIndicator />
                  </span>
                }
                disabled={getDisabledField(
                  recallCampaignData,
                  action,
                  'recallType',
                )}
                name="recallType"
                id="recallType"
                options={[
                  { value: '', label: '-Select-' },
                  ...selectDropDownOptions,
                ]}
                errorMessage={
                  errors && errors['recallType']
                    ? errors['recallType'].message
                    : null
                }
                aria-invalid={errors && errors['recallType'] ? 'true' : 'false'}
              />
            );
          }}
        />
        <div className="margin-top-4">
          <b>Remedy available? </b>
        </div>
        <Controller
          control={control}
          name="isRemedyAvailable"
          defaultValue={false}
          render={({ field: { value } }) => (
            <>
              <RadioButton
                name="isRemedyAvailable"
                key="isRemedyAvailable1"
                value={value}
                checked={value === false}
                label="No"
                disabled={getDisabledField(
                  recallCampaignData,
                  action,
                  'isRemedyAvailable',
                )}
                onChange={(e) => {
                  setValue('isRemedyAvailable', false);
                }}
              />
              <RadioButton
                name="isRemedyAvailable"
                key="isRemedyAvailable2"
                value={value}
                checked={value === true}
                label="Yes"
                disabled={getDisabledField(
                  recallCampaignData,
                  action,
                  'isRemedyAvailable',
                )}
                onChange={(e) => {
                  setValue('isRemedyAvailable', true);
                }}
              />
            </>
          )}
        />
        <div className="margin-top-4">
          <b>Customer should stop driving the vehicle? </b>
        </div>
        <Controller
          control={control}
          name="shouldStopDrive"
          defaultValue={false}
          render={({ field: { onChange, name, value } }) => (
            <>
              <RadioButton
                name="shouldStopDrive"
                key="shouldStopDrive1"
                value={value}
                checked={value === false}
                label="No"
                disabled={getDisabledField(
                  recallCampaignData,
                  action,
                  'shouldStopDrive',
                )}
                onChange={(e) => {
                  setValue('shouldStopDrive', false);
                }}
              />
              <RadioButton
                name="shouldStopDrive"
                key="shouldStopDrive2"
                value={value}
                checked={value === true}
                label="Yes"
                disabled={getDisabledField(
                  recallCampaignData,
                  action,
                  'shouldStopDrive',
                )}
                onChange={(e) => {
                  setValue('shouldStopDrive', true);
                }}
              />
            </>
          )}
        />
        <div className="margin-top-4">
          <b>Does the vehicle need to be parked outside? </b>
        </div>
        <Controller
          control={control}
          name="shouldParkOutside"
          defaultValue={false}
          render={({ field: { onChange, name, value } }) => (
            <>
              <RadioButton
                name="shouldParkOutside"
                key="shouldParkOutside1"
                value={value}
                checked={value === false}
                label="No"
                disabled={getDisabledField(
                  recallCampaignData,
                  action,
                  'shouldParkOutside',
                )}
                onChange={(e) => {
                  setValue('shouldParkOutside', false);
                }}
              />
              <RadioButton
                name="shouldParkOutside"
                key="shouldParkOutside2"
                value={value}
                checked={value === true}
                label="Yes"
                disabled={getDisabledField(
                  recallCampaignData,
                  action,
                  'shouldParkOutside',
                )}
                onChange={(e) => {
                  setValue('shouldParkOutside', true);
                }}
              />
            </>
          )}
        />
      </div>
    </>
  );
};

export default RecallActionFields;
