import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { StatusBadge } from '@gsa/afp-component-library';

const statusMap = {
  1: { text: 'Completed', variant: 'Ready-Gray' },
  0: { text: 'In progress', variant: 'Warning-Gray' },
  Yes: { text: 'Completed', variant: 'Ready-Gray' },
  No: { text: 'In progress', variant: 'Warning-Gray' },
  '': { text: 'In progress', variant: 'Warning-Gray' },
  undefined: { text: 'In progress', variant: 'Warning-Gray' },
};

const RepairOrderStatus = ({ status }) => {
  const [_status, setStatus] = useState({});

  useEffect(() => {
    setStatus(statusMap[status]);
  }, [status]);

  if (!_status?.text) {
    return null;
  }

  return <StatusBadge variant={_status.variant}>{_status.text}</StatusBadge>;
};

RepairOrderStatus.propTypes = {
  repairOrder: PropTypes.object,
};

export default RepairOrderStatus;
