import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { phoneRegExp } from 'utilities/validation';
import { useMotorPool } from '../../../motor-pool-provider';
import ReservationStartFields from './reservation-start-fields';
import { CLOSE_COMPLETE_RESERVATION_FIELDS } from './reservation-constants';

const numericRegExp = /^(\s*|\d+)$/;

const ReservationCloseCompleteForm = () => {
  const generateReservationFormSchema = (startingOdometer) =>
    yup.object().shape({
      [CLOSE_COMPLETE_RESERVATION_FIELDS.odometerAtReturn.name]: yup
        .number()
        .moreThan(
          startingOdometer,
          'Ending odometer reading should be greater than starting odometer reading.',
        )
        .label(CLOSE_COMPLETE_RESERVATION_FIELDS.odometerAtReturn.label)
        .required()
        .typeError('Should be numeric.'),
      [CLOSE_COMPLETE_RESERVATION_FIELDS.totalKeysReturned.name]: yup
        .string()
        .label(CLOSE_COMPLETE_RESERVATION_FIELDS.totalKeysReturned.label)
        .matches(numericRegExp, 'Should be numeric.')
        .length(1)
        .nullable(),
      [CLOSE_COMPLETE_RESERVATION_FIELDS.vehicleCondition.name]: yup
        .string()
        .max(50, 'Vehicle condition cannot be longer than 50 characters.')
        .label(CLOSE_COMPLETE_RESERVATION_FIELDS.comment.label)
        .nullable(),
      [CLOSE_COMPLETE_RESERVATION_FIELDS.comment.name]: yup
        .string()
        .max(200, 'Comment cannot be longer than 200 characters.')
        .label(CLOSE_COMPLETE_RESERVATION_FIELDS.comment.label)
        .nullable(),
      [CLOSE_COMPLETE_RESERVATION_FIELDS.contactInfo.name]: yup
        .string()
        .label(CLOSE_COMPLETE_RESERVATION_FIELDS.contactInfo.label)
        .matches(phoneRegExp, 'Contact number is not valid.')
        .nullable(),
    });

  const { closeAndCompleteReservation, reservationListingSelected } =
    useMotorPool();

  const startingOdometer = reservationListingSelected?.odometerAtPickup || 0;

  const methods = useForm({
    resolver: yupResolver(generateReservationFormSchema(startingOdometer)),
    defaultValues: {},
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const onSubmit = (values) => {
    const dateTimeFormat = 'YYYY-MM-DDTHH:mm:ss.SSSZ';
    const {
      odometerAtPickup,
      odometerAtReturn,
      totalKeysReturned,
      comment,
      contactInfo,
      vehicleCondition,
    } = values;
    closeAndCompleteReservation({
      variables: {
        closeReservation: {
          id: reservationListingSelected.id,
          motorPoolVehicleId: reservationListingSelected.motorPoolVehicleId,
          actualReturnDate: moment().format(dateTimeFormat),
          odometerAtPickup: odometerAtPickup && Number(odometerAtPickup),
          odometerAtReturn: odometerAtReturn && Number(odometerAtReturn),
          totalKeysReturned: totalKeysReturned && Number(totalKeysReturned),
          comment,
          contactInfo,
          vehicleCondition,
        },
      },
    });
  };

  return (
    <FormProvider {...methods}>
      <form
        data-testid="reservation-close-complete-form"
        id="reservation-close-complete-form"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <ReservationStartFields />
      </form>
    </FormProvider>
  );
};

export default ReservationCloseCompleteForm;
