/* eslint-disable react/prop-types */
/* eslint-disable filenames/match-exported */

import React from 'react';
import { cloneDeep } from 'lodash';
import { DatePicker, RequiredFieldIndicator } from '@gsa/afp-component-library';
import classnames from 'classnames';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { USDateStrToUTCStr } from 'utilities/format';

const DatePickerField = ({
  selectors,
  pageId = 'DEFAULT',
  id,
  dependency = null,
  required,
  disabled,
  label,
  ...restProps
}) => {
  const { fieldSelector, fieldAfterSelector = null } = selectors;
  const [fieldValueState, setFieldValueState] = useRecoilState(
    fieldSelector({ pageId, id }),
  );

  let afterFieldUpdated = ({ value }) => value;
  if (fieldAfterSelector) {
    afterFieldUpdated = useSetRecoilState(fieldAfterSelector({ pageId, id }));
  }
  const hasError = fieldValueState?.errorMessage;
  return (
    <div
      className={classnames(fieldValueState.errorMessage && 'margin-left-205')}
    >
      <div
        className={`usa-form-group ${
          hasError ? 'usa-form-group--error' : null
        }`}
      >
        {hasError && (
          <span className="usa-error-message">
            {fieldValueState.errorMessage}
          </span>
        )}
        <DatePicker
          id={id}
          name={id}
          disabled={fieldValueState?.disabled || disabled}
          data-testid={id}
          label={
            <span>
              {label} {required && <RequiredFieldIndicator />}
            </span>
          }
          defaultValue={fieldValueState.value}
          hint="mm/dd/yyyy"
          onChange={(val) => {
            setFieldValueState({
              value: USDateStrToUTCStr(val),
            });
          }}
          onBlur={(e) => {
            afterFieldUpdated({ value: e.target.value, dependency });
          }}
          aria-invalid={hasError ? 'true' : 'false'}
          {...restProps}
        />
      </div>
    </div>
  );
};

export default DatePickerField;
