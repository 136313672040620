import React, { useState, useEffect, createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { VMSOperations, VMSSubjects } from 'utilities/consts';
import useDataController from './use-data-controller';
import {
  OWNERSHIP_OPTIONS,
  REPORT_SEARCH_OPTIONS,
} from './config/helpers';

export const ExportContext = createContext();

function ExportProvider({ children, scheduleId, ...props }) {
  const history = useHistory();
  const [internalError, setInternalError] = useState();
  const [agencies, setAgencies] = useState();
  const [selectedOwnership, setSelectedOwnership] = useState();
  const [canBeScheduled, setCanBeScheduled] = useState(false);
  const [isScheduled, setIsScheduled] = useState('no');
  const [selectedReport, setSelectedReport] = useState();

  const [ownership, setOwnership] = useState({
    ownershipOptions: OWNERSHIP_OPTIONS,
    defaultValue: OWNERSHIP_OPTIONS[0].radioValue,
  });

  const [reportSearchOptions, setReportSearchOptions] = useState({
    reportSearchOptions: REPORT_SEARCH_OPTIONS,
  });

  const { query, mutation, loading } = useDataController({
    setAgencies,
    setSelectedReport,
    setInternalError,
  });

  /*
   * we are fetching the agencies to determine if the user has the opt-in permission
   * if they do, we will show both options for reports
   * for internal user, we will only show GF option
   * */
  const showOwnershipOptions = () => {
    if (agencies?.length > 0) {
      setOwnership({
        ownershipOptions: OWNERSHIP_OPTIONS,
        defaultValue: OWNERSHIP_OPTIONS[0].radioValue,
      });
    } else if (!agencies) {
      setOwnership({
        ownershipOptions: [OWNERSHIP_OPTIONS[1]],
        defaultValue: OWNERSHIP_OPTIONS[1].radioValue,
      });
    } else if (
      internalError?.includes('user does not have the required permission')
    ) {
      history.push('/unauthorized');
    }
  };

  // verify opt-in permission,
  // workaround to determine if agency owned is an option
  useEffect(() => {
    query.agenciesQuery({
      variables: {
        operation: VMSOperations.Update,
        subject: VMSSubjects.VEHICLE,
        feature: 'Basic',
        order: 'id ASC',
      },
    });
  }, []);

  useEffect(() => {
    if (scheduleId) {
      query.getReportScheduleById({
        variables: {
          id: scheduleId,
        },
      });
      setIsScheduled('yes');
    }
  }, [scheduleId]);

  useEffect(() => {
    if (!loading.agenciesQueryLoading) {
      showOwnershipOptions();
    }
  }, [agencies, internalError]);

  return (
    <ExportContext.Provider
      value={{
        mutation,
        loading,
        internalError,
        ownership,
        setOwnership,
        reportSearchOptions,
        setReportSearchOptions,
        selectedOwnership,
        setSelectedOwnership,
        canBeScheduled,
        setCanBeScheduled,
        isScheduled,
        setIsScheduled,
        selectedReport,
        ...props,
      }}
    >
      {children}
    </ExportContext.Provider>
  );
}

export default ExportProvider;

ExportProvider.defaultProps = {
  scheduleId: '',
};

ExportProvider.propTypes = {
  children: PropTypes.element.isRequired,
  scheduleId: PropTypes.string,
};

export const useExport = () => useContext(ExportContext);
