import React, { createContext, useContext } from 'react';

export const OrderContext = createContext(null);

export default function Context({ children, ...props }) {
  return (
    <OrderContext.Provider value={{ ...props }}>
      {children}
    </OrderContext.Provider>
  );
}

export const useOrder = () => useContext(OrderContext);
