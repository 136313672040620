import React, { useEffect, useState } from 'react';
import { RequiredFieldIndicator, TextInput } from '@gsa/afp-component-library';
import { Controller, useFormContext } from 'react-hook-form';
import { omit } from 'lodash';
import useCanPerformActions from 'hooks/use-can-perform-actions';
import { useMotorPool } from '../motor-pool-provider';
import { MOTOR_POOL_FORM_FIELDS } from './consts';

const MotorPoolAddEditFields = () => {
  const {
    motorPoolListingModalMode,
    motorPoolListingSelected,
    dispatchAction,
    getAgencyPrimaryDispatchers,
    primaryDispatchers,
  } = useMotorPool();

  const {
    getValues,
    clearErrors,
    setValue,
    formState: { errors },
    control,
    watch,
  } = useFormContext();

  const [motorPoolFormFields, setMotorPoolFormField] = useState(
    MOTOR_POOL_FORM_FIELDS,
  );

  const canPerformActions = useCanPerformActions();

  const extractValue = (value, isField = false) => {
    if (typeof value !== 'boolean') {
      return value;
    }
    if (isField) return value ? 'Yes' : 'No';
    return value ? 'yes' : 'no';
  };

  useEffect(() => {
    // Set Component based on roles
    if (!canPerformActions.canEditMotorPoolAgency()) {
      const newMpFormFields = omit(MOTOR_POOL_FORM_FIELDS, [
        'mpAgency',
        'primaryDispatcher',
      ]);
      setMotorPoolFormField(newMpFormFields);
    }

    // To pre-populate the form with selected row values
    if (motorPoolListingModalMode === 'UPDATE_MOTOR_POOL_LISTING') {
      Object.values(MOTOR_POOL_FORM_FIELDS).forEach(({ name }) => {
        setValue(name, extractValue(motorPoolListingSelected[name]));
      });
    }
  }, []);

  const watchAgencyCode = watch('agencyCode');
  const watchPrimaryDispatcher = watch('primaryDispatcher');

  useEffect(() => {
    dispatchAction('SET_PRIMARY_DISPATCHERS', []);
    if (watchAgencyCode && canPerformActions.canEditMotorPoolAgency()) {
      getAgencyPrimaryDispatchers({
        variables: {
          agencyCode: watchAgencyCode,
        },
      });
    }
    setValue('primaryDispatcher', undefined);
    clearErrors('primaryDispatcher');
  }, [watchAgencyCode]);

  useEffect(() => {
    setValue('backupDispatchers', []);
    if (primaryDispatchers.length > 0 && watchPrimaryDispatcher)
      dispatchAction(
        'SET_DISPATCHERS',
        primaryDispatchers.filter(
          (dispatcher) => dispatcher.value !== watchPrimaryDispatcher,
        ),
      );
  }, [primaryDispatchers, watchPrimaryDispatcher]);

  return (
    <div>
      {Object.values(motorPoolFormFields).map((mpField) => {
        // To disable the timezone field when updating the motor pool
        const mpEditable =
          mpField.name === 'timezone' && motorPoolListingSelected?.timezone
            ? false
            : mpField.editable;
        return (
          <div key={mpField.name} className={mpField.wrapperClass || ''}>
            {motorPoolListingModalMode === 'UPDATE_MOTOR_POOL_LISTING' &&
            !mpEditable ? (
              <>
                <div className="text-bold margin-top-2">
                  {mpField.label}{' '}
                  {mpField.required && <RequiredFieldIndicator />}
                </div>
                {extractValue(motorPoolListingSelected[mpField.name], true)}
              </>
            ) : (
              <Controller
                name={mpField.name}
                control={control}
                render={({ field, ref }) => {
                  return mpField.component ? (
                    <mpField.component
                      fieldAttrs={mpField}
                      getValues={getValues}
                      setValue={setValue}
                      errors={errors}
                      clearErrors={clearErrors}
                      field={field}
                      ref={ref}
                    />
                  ) : (
                    <TextInput
                      {...field}
                      ref={ref}
                      name={mpField.name}
                      id={mpField.id}
                      type="text"
                      {...(mpField.additionalAttributes || {})}
                      label={
                        <span className="text-bold">
                          {mpField.label}{' '}
                          {mpField.required && <RequiredFieldIndicator />}
                        </span>
                      }
                      errorMessage={
                        errors && errors[mpField.name]
                          ? errors[mpField.name].message
                          : null
                      }
                      aria-invalid={
                        errors && errors[mpField.name] ? 'true' : 'false'
                      }
                    />
                  );
                }}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default MotorPoolAddEditFields;
