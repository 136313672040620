import React from 'react';
import { Button } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';
import DetailsEditModal from './details-edit-modal';
import useUser from '../../../utilities/use-user';

export default function OrderDetailsEdit({ editing, setEditOrderDetails }) {
  const { isRole } = useUser();

  return (
    (isRole('FMVRSAdminRole') || isRole('SiteAdmin') || isRole('LpVendor')) && (
      <>
        <Button
          variant="outline"
          className="bg-white margin-top-2"
          data-testid="order-details-edit"
          aria-label="edit order details"
          onClick={() => {
            setEditOrderDetails(true);
          }}
          label="Edit"
        />
        {editing && (
          <DetailsEditModal
            editing={editing}
            setEditOrderDetails={setEditOrderDetails}
          />
        )}
      </>
    )
  );
}

OrderDetailsEdit.propTypes = {
  editing: PropTypes.bool.isRequired,
  setEditOrderDetails: PropTypes.func.isRequired,
};
