/* eslint-disable react/prop-types */
import React, { useState, useEffect, useReducer } from 'react';
import {
  Button,
  DatePicker,
  Alert,
  Label,
  TextInput,
} from '@gsa/afp-component-library';
import { useLazyQuery } from '@apollo/client';
import moment from 'moment';
import usePortalModal from 'utilities/portal-modal';
import useDebounce from 'hooks/use-debounce';
import { GET_NEXT_LAST_ODOMETER_BY_DATE_VEHICLE } from '../mileage-history.gql';
import {
  WarningComponent,
  MaxWarningComponent,
  NetworkErrorComponent,
} from '../widgets/mileage-alerts';
import './mileage-modal.css';

const formReducer = (state, newState) => {
  return {
    ...state,
    ...newState,
  };
};

const requiredFields = {
  mileageDate: (state) => state.mileageDate,
  odometer: (state) => state.odometer,
};

const formatOdometerReading = (reading) => {
  return reading !== ''
    ? parseFloat(reading.toString().replace(/,/g, '')).toLocaleString()
    : '';
};

// eslint-disable-next-line consistent-return
const validateFields = (reqFields, state) => {
  if (reqFields && state) {
    return Object.keys(reqFields).reduce((validations, field) => {
      const selector = reqFields[field];
      const fieldValue = selector(state);
      // 0 is valid
      if (
        fieldValue === undefined ||
        fieldValue === null ||
        fieldValue === ''
      ) {
        // eslint-disable-next-line no-param-reassign
        validations[field] = 'This is a required field';
      }

      return validations;
    }, {});
  }
};

const validateMileageField = (state) => {
  if (state.errorMessage !== '') {
    return {
      mileageDate: state.errorMessage,
    };
  }
  return {};
};

const UpdateMileageModal = ({ vehicle, onSave, onClose, actionData, type }) => {
  const [MileageModal, openModal, closeModal] = usePortalModal();
  const [state, dispatch] = useReducer(formReducer, {
    mileageDate:
      type === 'edit' ? actionData.mileageDate : new Date().toISOString(),
    odometer: type === 'edit' ? actionData.odometer : 0,
    daysUsed: type === 'edit' ? actionData.daysInUse : 0,
    errorMessage: '',
    errorDate: false,
  });

  const [errors, setErrors] = useState({});
  const [errorsCount, setErrorsCount] = useState(0);
  const [, setIsValueChanged] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [maxWarning, setMaxWarning] = useState(false);
  const [networkError, setNetworkError] = useState(false);

  const [getLastAndNextOdometerByVehicleAndDate] = useLazyQuery(
    GET_NEXT_LAST_ODOMETER_BY_DATE_VEHICLE,
    {
      onError: () => setNetworkError(true),
      onCompleted: (data) => {
        const lastEnteredOdometerValue =
          data.getLastAndNextOdometerByVehicleAndDate?.lastEnteredOdometerVal;
        const nextEnteredOdometerValue =
          data.getLastAndNextOdometerByVehicleAndDate?.nextEnteredOdometerVal;
        if (nextEnteredOdometerValue === 0) {
          if (state.odometer - lastEnteredOdometerValue >= 10000) {
            setShowWarning(true);
          }
        } else if (
          Math.abs(state.odometer - lastEnteredOdometerValue) >= 10000 ||
          Math.abs(state.odometer - nextEnteredOdometerValue) >= 10000
        ) {
          setShowWarning(true);
        }
      },
      fetchPolicy: 'network-only',
    },
  );

  const debouncedOdometerInput = useDebounce(state.odometer, 300);
  const debouncedMileageDate = useDebounce(state.mileageDate, 300);

  useEffect(() => {
    openModal();
  }, []);

  useEffect(() => {
    if (debouncedOdometerInput && debouncedMileageDate) {
      const mileageInputArgs = {
        assetId: vehicle?.uuid,
        mileageDate: moment(state.mileageDate).format('YYYY-MM-DD'),
        odometer: parseInt(state.odometer, 10),
      };

      getLastAndNextOdometerByVehicleAndDate({
        variables: {
          mileageInput: mileageInputArgs,
        },
      });
    }
  }, [debouncedOdometerInput, debouncedMileageDate]);

  //   Save and close modal
  const save = () => {
    const validations = validateFields(requiredFields, state);
    const mileageValidation = validateMileageField(state);
    const noError =
      Object.keys(validations).length +
        Object.keys(mileageValidation).length ===
      0;
    if (noError && moment(state.mileageDate).isValid()) {
      setErrors({});
      setErrorsCount(0);
      const date = state.mileageDate
        ? moment(state.mileageDate)
            .format('YYYY-MM-DD')
            .concat('T00:00:00.000Z')
        : null;
      onSave(
        vehicle?.uuid,
        date,
        parseInt(state.daysUsed, 10),
        parseInt(state.odometer, 10),
        type,
      );
      closeModal();
    } else {
      setErrors(validations);
      setErrorsCount(
        Object.keys(validations).length + Object.keys(mileageValidation).length,
      );
    }
  };

  const close = () => {
    // eslint-disable-next-line no-unused-expressions
    onClose && onClose();
    closeModal();
  };

  const handleDateChange = (e) => {
    dispatch({
      errorMessage: '',
    });
    if (e) {
      dispatch({
        errorMessage: '',
      });
      if (moment(e).isValid() && moment(e, 'MM/DD/YYYY', true).isValid()) {
        if (moment().diff(e, 'MM/DD/YYYY') > 0) {
          dispatch({
            mileageDate: e,
            errorMessage: '',
            errorDate: false,
          });
        } else {
          dispatch({
            errorMessage: 'Mileage date cannot be in the future',
            errorDate: true,
          });
        }
      } else {
        // datepicker has invalid date format message
        // no need to add the error message.
        dispatch({
          errorDate: true,
        });
      }
    } else {
      dispatch({
        mileageDate: null,
        errorDate: false,
      });
    }
  };

  const handleOdometerFocus = () => {
    if (state.odometer === 0) {
      dispatch({ odometer: '' });
    }
  };

  const handleDaysUsedFocus = () => {
    if (state.daysUsed === 0) {
      dispatch({ daysUsed: '' });
    }
  };

  const handleOdometerInput = (e) => {
    const { value } = e.target;
    const formattedValue = value.replace(/,/g, '');
    // Allow numbers and decimal point
    const re = /^\d*\.?\d*$/;
    setIsValueChanged(true);
    setShowWarning(false);
    setMaxWarning(false);
    if (formattedValue === '' || re.test(formattedValue)) {
      if (parseInt(formattedValue, 10) > 4294967295) {
        setMaxWarning(true);
      }
      dispatch({ odometer: formattedValue });
    }
  };

  const handleDaysUsed = (e) => {
    const { value } = e.target;
    const re = /^\d*\.?\d*$/;
    setIsValueChanged(true);
    if (value === '' || re.test(value)) {
      dispatch({ daysUsed: value });
    }
  };

  const getErrorComponent = () => {
    return (
      <Alert
        className="error-alert margin-bottom-2"
        onClose={false}
        slim
        type="error"
      >
        This form has{' '}
        <span className="text-bold">
          {errorsCount === 1
            ? `${errorsCount} error. `
            : `${errorsCount} errors. `}
        </span>
        Please correct all fields outlined in red before saving.
      </Alert>
    );
  };

  const ModalHeader = ({ vin }) => {
    return (
      <div>
        {errorsCount > 0 && getErrorComponent()}
        {networkError && <NetworkErrorComponent />}
        {maxWarning && <MaxWarningComponent />}
        {showWarning && !maxWarning && <WarningComponent type={type} />}
        <h2>{`${type === 'edit' ? 'Edit' : 'Add'} vehicle mileage record`}</h2>
        <p className="font-body-xs">
          {type === 'edit' ? 'Edit' : 'Add'} vehicle mileage record for VIN{' '}
          <span className="text-bold">{vin}</span> in the form below.
        </p>
        <p className="font-body-2xs text-italic">
          Required fields are marked with an asterisk (
          <span className="text-secondary-dark">*</span>).
        </p>
      </div>
    );
  };

  return (
    <MileageModal
      actions={
        <>
          <Button variant="unstyled" label="Cancel" onClick={close} />
          <Button
            className="margin-left-2"
            onClick={save}
            label="Save and close"
            disabled={maxWarning}
          />
        </>
      }
      title={<ModalHeader vin={vehicle?.id} />}
      onClose={close}
    >
      <div className="grid-row grid-gap grid-col-12 margin-bottom-10">
        {type === 'edit' ? (
          <div className="grid-row grid-gap grid-col-12">
            <div className="grid-col-6">
              <TextInput
                id="mileage-date"
                aria-label="mileage-date"
                label="Entry date"
                type="input"
                value={moment(state.mileageDate, 'YYYY-MM-DD').format(
                  'MM/DD/YYYY',
                )}
                className="usa-input"
                showLabelError={false}
                data-testid="mileage-date-input"
                disabled
              />
            </div>
          </div>
        ) : (
          <div className="grid-row grid-gap grid-col-12">
            <div className="grid-col-12">
              <div
                className={
                  state.errorMessage || errors?.mileageDate
                    ? 'usa-form-group usa-form-group--error'
                    : ''
                }
              >
                <Label htmlFor="mileage-date-picker" required>
                  Entry date
                </Label>
                <div className="mileage-date-picker">
                  <DatePicker
                    id="mileage-date-picker"
                    label={
                      state.errorMessage || errors?.mileageDate || 'mm/dd/yyyy'
                    }
                    labelClass={
                      state.errorMessage || errors?.mileageDate
                        ? 'labelError'
                        : 'usa-hint'
                    }
                    className={
                      state.errorMessage || errors?.mileageDate
                        ? 'date-picker-error'
                        : 'datePicker margin-top-0'
                    }
                    name="mileage-date-picker"
                    defaultValue={state.mileageDate}
                    onChange={(val) => {
                      handleDateChange(val);
                    }}
                    format="MM/DD/YYYY"
                    disabled={type === 'edit'}
                    data-testid="mileage-date-picker"
                    required
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="grid-row grid-gap grid-col-12">
          <div className="grid-col-6">
            <div className="odometer-input">
              <span className="prefix-icon-odometer">miles</span>
              <TextInput
                id="odometer-reading"
                aria-label="odometer-reading"
                label="Odometer reading"
                type="input"
                value={formatOdometerReading(state.odometer)}
                onChange={handleOdometerInput}
                onFocus={handleOdometerFocus}
                className="usa-input"
                showLabelError={false}
                data-testid="odometer-reading-input"
                errorMessage={errors.odometer}
                required
              />
            </div>
          </div>
        </div>
        <div className="grid-row grid-gap grid-col-12">
          <div className="grid-col-6">
            <div className="days-used">
              <TextInput
                id="days-used"
                aria-label="days-used-reading"
                label="Days used"
                type="input"
                value={state.daysUsed}
                onChange={handleDaysUsed}
                onFocus={handleDaysUsedFocus}
                className="usa-input"
                showLabelError={false}
                data-testid="days-used-input"
              />
            </div>
          </div>
        </div>
      </div>
    </MileageModal>
  );
};

export default UpdateMileageModal;
