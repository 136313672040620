import { STATES_LIST, VMSOperations, VMSSubjects } from 'utilities/consts';
import React from 'react';
import AgencyFilterItem from 'components/user-filters/custom-filters/agency-filter-item';

// eslint-disable-next-line react/prop-types
const MotorPoolAgencyFilterItem = ({ filter }) => {
  return (
    <AgencyFilterItem
      filter={filter}
      operation={VMSOperations.View}
      subject={VMSSubjects.MOTOR_POOL}
    />
  );
};

export const generateMPVehiclesFilterStructure = () => {
  return [
    {
      title: 'Availability Dates',
      expanded: true,
      filters: [
        {
          title: 'Date Range Picker',
          key: 'reservation',
          type: 'daterange',
          operator: '$between',
          options: {
            wrapperClass: 'margin-top-0',
            startDate: {
              key: 'beginning',
              label: 'Start Date',
              hint: 'mm/dd/yyyy',
              minDate: new Date().toISOString().slice(0, 10),
            },
            endDate: {
              key: 'end',
              label: 'End Date',
              hint: 'mm/dd/yyyy',
              minDate: new Date().toISOString().slice(0, 10),
            },
          },
          value: {
            beginning: '',
            end: '',
          },
          hideClear: true,
        },
        {
          title: 'Start time',
          key: 'reservation-start-time',
          operator: '$exact',
          type: 'time',
          options: {
            hint: 'Default: 12:00am',
            label: 'Start time',
            labelClass: 'text-normal',
          },
          hideClear: true,
        },
        {
          title: 'End time',
          key: 'reservation-end-time',
          operator: '$exact',
          type: 'time',
          options: {
            hint: 'Default: 11:59pm',
            label: 'End time',
            labelClass: 'text-normal',
          },
          hideClear: true,
        },
      ],
    },
    {
      title: 'License plate',
      key: '$vehicle.tag_number$',
      type: 'text',
      permanent: false,
      operator: '$startsWith',
      hideClear: false,
      position: 'top',
      label: 'Search by plate number',
      showSearchButton: true,
      minSearchLength: 3,
    },
    {
      title: 'VIN',
      ariaLabel: 'Search by VIN',
      id: 'filter-vehicle-vin',
      key: '$vehicle.serial_number_vin$',
      type: 'text',
      permanent: false,
      operator: '$startsWith',
      hideClear: false,
      position: 'top',
      label: 'Search by VIN',
      showSearchButton: true,
      minSearchLength: 6,
    },
    {
      title: 'Ownership',
      key: '$vehicle.ownership_type_code$',
      type: 'multiselect',
      operator: '$exact',
      value: [],
      hideClear: true,
      options: [
        { value: 'GF', label: 'GSA Leased' },
        { value: 'AO', label: 'Agency Owned' },
      ],
    },
  ];
};

export const generateMPReservationsFilterStructure = (userId) => {
  return [
    {
      title: 'My reservations',
      key: '$motorPoolMember.member_user_id$',
      id: 'filter-my-reservations',
      type: 'multiselect',
      permanent: false,
      operator: '$exact',
      hideClear: false,
      value: [userId],
      position: 'top',
      options: [{ value: userId, label: 'My current reservations' }],
    },
    {
      title: 'Confirmation number',
      ariaLabel: 'Search by confirmation number',
      id: 'filter-confirmation-number',
      key: 'reservationNumber',
      type: 'text',
      permanent: false,
      operator: '$startsWith',
      hideClear: false,
      position: 'top',
      label: 'Search by confirmation number',
      showSearchButton: true,
      minSearchLength: 6,
    },
    {
      title: 'VIN',
      ariaLabel: 'Search by VIN',
      id: 'filter-reservations-vin',
      key: '$motorPoolVehicle.vehicle.serial_number_vin$',
      type: 'text',
      permanent: false,
      operator: '$startsWith',
      hideClear: false,
      position: 'top',
      label: 'Search by VIN',
      showSearchButton: true,
      minSearchLength: 6,
    },
    {
      title: 'License plate',
      key: '$motorPoolVehicle.vehicle.tag_number$',
      type: 'text',
      permanent: false,
      operator: '$startsWith',
      hideClear: false,
      position: 'top',
      label: 'Search by plate number',
      showSearchButton: true,
      minSearchLength: 3,
    },
  ];
};

export const generateMPMembersFilterStructure = () => {
  return [
    {
      title: 'Email',
      ariaLabel: 'Search by email',
      key: '$member.email$',
      placeholder: 'sample@gsa.gov',
      id: 'filter-member-email',
      type: 'typeahead',
      operator: '$like',
      value: [],
      customFieldProps: {
        inputCharNum: 6,
        debounceDelay: 500,
        promptText: 'Search requires 6 characters',
        showNoResults: false,
        clearPanelFilterOnEmpty: true,
      },
      hideClear: true,
    },
    {
      title: 'Member type',
      key: 'is_dispatcher',
      type: 'multiselect',
      operator: '$exact',
      value: [],
      hideClear: true,
      options: [
        { value: '1', label: 'Dispatcher' },
        { value: '0', label: 'Member' },
      ],
    },
  ];
};

export const generateMotorPoolFilterStructure = (
  canEditMotorPoolAgency,
  userId,
) => {
  const statesOptions = STATES_LIST.map(({ stateCode }) => ({
    value: stateCode,
    label: stateCode,
  }));

  const filterStructure = [
    {
      title: 'My pools',
      key: 'myPools',
      id: 'filter-my-pools',
      type: 'multiselect',
      permanent: false,
      operator: '$exact',
      hideClear: false,
      value: [userId],
      position: 'top',
      options: [{ value: userId, label: 'My pools' }],
    },
    {
      title: 'Pool name',
      key: 'poolName',
      type: 'text',
      permanent: false,
      operator: '$like',
      hideClear: false,
      position: 'top',
      label: 'Search by pool name',
      showSearchButton: true,
      minSearchLength: 4,
    },
    {
      title: 'Pool type',
      key: 'isPublic',
      type: 'multiselect',
      operator: '$exact',
      value: [],
      hideClear: true,
      options: [
        { value: '1', label: 'Public' },
        { value: '0', label: 'Private' },
      ],
    },
    {
      title: 'State',
      key: 'state',
      type: 'select',
      operator: '$exact',
      value: [],
      hideClear: true,
      options: [{ value: '', label: '- Select -' }, ...statesOptions],
    },
    {
      title: 'Confirmation number',
      ariaLabel: 'Search by confirmation number',
      key: 'confirmationNumber',
      placeholder: 'Search by confirmation number',
      id: 'filter-confirmation-number',
      type: 'typeahead',
      operator: '$exact',
      value: [],
      customFieldProps: {
        inputCharNum: 6,
        debounceDelay: 500,
        promptText: 'Search requires 6 characters',
        showNoResults: false,
        clearPanelFilterOnEmpty: true,
      },
      hideClear: true,
    },
    {
      title: 'Email',
      ariaLabel: 'Search by email',
      key: 'email',
      placeholder: 'sample@gsa.gov',
      id: 'filter-email',
      type: 'typeahead',
      operator: '$exact',
      value: [],
      customFieldProps: {
        inputCharNum: 6,
        debounceDelay: 500,
        promptText: 'Search requires 6 characters',
        showNoResults: false,
        clearPanelFilterOnEmpty: true,
      },
      hideClear: true,
    },
  ];

  if (canEditMotorPoolAgency) {
    filterStructure.push({
      title: 'Agency',
      key: 'agencyCode',
      component: MotorPoolAgencyFilterItem,
      permanent: false,
      operator: '$exact',
      value: '',
    });
  }

  return filterStructure;
};
