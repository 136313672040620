/* eslint-disable react/prop-types */
import {
  AFPTable,
  Menu,
  EmptyState,
  Pagination,
  useModal,
} from '@gsa/afp-component-library';
import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import { VMSSubjects, VMSOperations } from 'utilities/consts';
import { useFcReplacement } from './fc-replacement-context-provider';
import DetailRow from './detail-row';
import CancelReplacementCardModal from './cancel-replacement-card-modal';
import OrderStatusBadge from './order-status-badge';
import { useFCReplacementFilter } from './fc-filter-provider';

const actionList = [
  {
    icon: 'edit',
    label: 'Edit',
    action: 'editOrder',
    operation: VMSOperations.Update,
  },
  {
    icon: 'cancel',
    label: 'Cancel',
    action: 'cancelOrder',
    operation: VMSOperations.Update,
  },
];

const tableRef = React.createRef();

const ReplacementOrderTable = () => {
  const { filters } = useFCReplacementFilter();
  const [paginationState, setPaginationState] = useState({
    limit: 10,
    offset: 0,
    currentPage: 1,
  });
  const [order, setOrder] = useState('createdAt DESC');
  const [orderToCancel, setOrderToCancel] = useState();

  const ability = useAppAbility();

  const resetPaginationState = () => {
    setPaginationState({
      limit: 10,
      offset: 0,
      currentPage: 1,
    });
  };

  const {
    isOpen: isCancelReplacementCardModalOpen,
    openModal: openCancelReplacementCardModal,
    closeModal: closeCancelReplacementCardModal,
  } = useModal();

  const renderRowSubComponent = useCallback(({ row: { original } }) => {
    const {
      comment,
      canceledByUser,
      canceledAt,
      address,
      contact,
      archivedContact,
    } = original;

    return (
      <div className="display-flex flex-justify-center">
        <div className="grid-col-11">
          <DetailRow
            data={{
              comment,
              canceledByUser,
              canceledAt,
              address,
              contact,
              archivedContact,
            }}
          />
        </div>
      </div>
    );
  }, []);

  const {
    getPaginatedReplacementOrders,
    ordersData,
    loadingOrders,
    ordersCount,
    openAddReplacementCardModal,
    dispatch,
  } = useFcReplacement();

  const getData = () => {
    getPaginatedReplacementOrders({
      variables: {
        limit: paginationState.limit,
        offset: paginationState.offset,
        order,
        filters,
      },
    });
  };

  useEffect(() => {
    getData();
  }, [paginationState, order]);

  useEffect(() => {
    resetPaginationState();
  }, [filters]);

  const handleEditOrder = (data) => {
    dispatch({
      type: 'SET_VEHICLE_DATA',
      payload: data,
    });

    openAddReplacementCardModal();
  };

  const handleCancelOrder = (data) => {
    const { id } = data;
    setOrderToCancel(id);
    openCancelReplacementCardModal();
  };

  const handleSelectedAction = (action, row) => {
    switch (action) {
      case 'editOrder':
        handleEditOrder(row);
        break;
      case 'cancelOrder':
        handleCancelOrder(row);
        break;
      default:
        break;
    }
  };

  const allowedActions = actionList.filter((action) => {
    return ability.can(action.operation, VMSSubjects.FC_REPLACEMENT);
  });

  const columns = useMemo(() => {
    const columnList = [
      {
        Header: 'Plate Number',
        accessor: 'tagNumber',
        sortable: false,
      },
      {
        Header: 'Contact',
        accessor: 'contact?.firstName',
        Cell: ({ row: { original } }) => {
          return `${
            original?.archivedContact?.firstName ||
            original?.contact?.firstName ||
            ''
          } ${
            original?.archivedContact?.lastName ||
            original?.contact?.lastName ||
            ''
          }`;
        },
        sortable: false,
      },
      {
        Header: 'Reason',
        accessor: 'reason',
        sortable: false,
      },
      {
        Header: 'Date',
        accessor: 'createdAt',
        sortable: true,
        Cell: ({ row: { original } }) => {
          return new Date(original?.createdAt).toLocaleDateString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          });
        },
      },
      {
        Header: 'Order Status',
        accessor: 'status',
        sortable: false,
        Cell: ({ row: { original } }) => {
          return original?.status ? (
            <OrderStatusBadge orderStatus={original.status} />
          ) : (
            <>&ndash;</>
          );
        },
      },
      {
        Header: 'Actions',
        sortable: false,
        Cell: ({ row: { original } }) => {
          if (original?.status !== 'Pending') return null;
          return (
            <div className="height-4 width-4 margin-left-05em">
              <Menu
                actionIconSize="usa-icon--size-4"
                menuItems={allowedActions}
                // eslint-disable-next-line react/prop-types
                onActionClick={(action) => {
                  handleSelectedAction(action, original);
                }}
                iconColor="text-primary"
                menuDialogState="hover"
              />
            </div>
          );
        },
      },
    ];

    return columnList;
  }, []);

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    // Calculate new offset.
    const offset = (currentPage - 1) * itemsPerPage;
    setPaginationState({
      limit: itemsPerPage,
      offset,
      currentPage,
    });
  };

  return (
    <>
      <AFPTable
        expandable
        fullWidth
        ref={tableRef}
        testId="replacement-order-table"
        columns={columns}
        data={ordersData}
        defaultSort={order}
        onSort={setOrder}
        renderRowSubComponent={renderRowSubComponent}
      />
      {ordersData?.length > 0 && (
        <Pagination
          fullWidth
          variant="advanced"
          itemsPerPageOptions={[10, 25, 50]}
          itemsCount={ordersCount}
          itemsPerPage={paginationState.limit}
          currentPage={paginationState.currentPage}
          onPageChange={handlePaginationChange}
        />
      )}
      {(!ordersData || ordersData.length === 0) && !loadingOrders && (
        <div className="bg-gray-3 padding-y-5">
          <div className="text-center padding-y-4">
            <EmptyState
              alt="Image not available"
              hasBackground
              bottomText={
                <>
                  <p className="text-bold">No card replacement order found</p>
                  <p>
                    Add or remove filters to search card replacement orders.
                  </p>
                </>
              }
            />
          </div>
        </div>
      )}
      <CancelReplacementCardModal
        isOpen={isCancelReplacementCardModalOpen}
        closeModal={closeCancelReplacementCardModal}
        id={orderToCancel}
      />
    </>
  );
};

export default ReplacementOrderTable;
