import { useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { GET_CUSTOMER_FSR_EMAILS_BY_PARTIAL_EMAIL } from 'components/customer-accounts/customer-account-gql';
import { uniq } from 'lodash';
import { FETCH_TYPEAHEAD } from 'services/data-layer';
import { SEARCH_POINTS_OF_CONTACT } from 'services/data-layer/point-of-contact';
import { GET_BOACS_BY_PARTIAL } from 'services/data-layer/fed-hierarchy.gql';

const useGetTypeaheadValues = (typeaheadFilterPaths) => {
  const { fsrEmailFilterPath, tagNumberFilterPath, primaryPocEmailPath, boacFilterPath } =
    typeaheadFilterPaths;
  const [typeaheadData, setTypeaheadData] = useState([]);

  const [getCustomerFSREmailsByPartialEmail] = useLazyQuery(
    GET_CUSTOMER_FSR_EMAILS_BY_PARTIAL_EMAIL,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        const fsrEmails = data.getCustomerFSREmailsByPartialEmail.map(
          (account) => account.fsrUserEmail,
        );
        setTypeaheadData({
          field: fsrEmailFilterPath,
          values: uniq(fsrEmails),
        });
      },
    },
  );

  const [getPointsOfContact] = useLazyQuery(SEARCH_POINTS_OF_CONTACT, {
    onCompleted: (data) => {
      const pocEmails = Array.from(
        new Set(data.searchPointsOfContact.map((poc) => poc.pocEmailAddress)),
      );
      setTypeaheadData({
        field: primaryPocEmailPath,
        values: pocEmails,
      });
    },
  });

  const [getTagsByPartialTagNumber] = useLazyQuery(FETCH_TYPEAHEAD, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const tagNumbers = data?.getTypeaheads?.typeaheads?.values;
      setTypeaheadData({
        field: tagNumberFilterPath,
        values: tagNumbers,
      });
    },
  });

  const [getBoacsByPartialBoac] = useLazyQuery(GET_BOACS_BY_PARTIAL, {
    onCompleted: (data) => {
      const boacs = Array.from(
        new Set(data?.getBoacsByPartialBoac?.map((boac) => boac.boac)),
      );
      setTypeaheadData({
        field: boacFilterPath,
        values: boacs,
      });
    },
  });

  const handleTypeaheadSearch = ({ variables }) => {
    const { conditions } = variables.filters[0];

    const query = conditions.filter((c) => c.key === variables.field)[0]?.value;
    if (variables.field === fsrEmailFilterPath) {
      getCustomerFSREmailsByPartialEmail({
        variables: {
          limit: 25,
          fsrUserEmail: query,
        },
      });
    }
    if (variables.field === tagNumberFilterPath) {
      getTagsByPartialTagNumber({
        variables: {
          model: 'Vehicle',
          field: 'tagNumber',
          order: [['registered', 'ASC']],
          filters: [
            {
              operator: '$and',
              conditions: [
                {
                  operator: '$startsWith',
                  key: 'tagNumber',
                  value: query,
                },
              ],
            },
          ],
        },
      });
    }
    if (variables.field === primaryPocEmailPath) {
      getPointsOfContact({
        variables: {
          limit: 10,
          offset: 0,
          filters: [
            {
              operator: '$and',
              conditions: [
                {
                  operator: '$like',
                  key: 'email_address',
                  value: `%${query}%`,
                },
              ],
            },
          ],
        },
      });
    }
    if (variables.field === boacFilterPath) {
      getBoacsByPartialBoac({
        variables: {
          partialBoac: `${query}%`          
        },
      });
    }
  };

  return {
    typeaheadData,
    handleTypeaheadSearch,
  };
};

export default useGetTypeaheadValues;
