/* eslint-disable filenames/match-regex */
import { gql } from '@apollo/client';

export const UPDATE_FLEET_CARD_TRANSACTION_WEX = gql`
  mutation updateFleetCardTransactionWEX(
    $fcTransaction: FCTransactionInputWEX!
  ) {
    updateFleetCardTransactionWEX(fcTransaction: $fcTransaction)
  }
`;
