/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  AFPTable,
  Button,
  EmptyState,
  Pagination,
  Spinner,
} from '@gsa/afp-component-library';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import { VMSOperations, VMSSubjects } from '../../utilities/consts'
import { useOfficeManagement } from './office-management-provider';
import OfficeManagementRowDetail from './office-management-row-detail';
import { useOfficeManagementFilter } from './filters/filter-provider';

const tableRef = React.createRef();

const initialPaginationState = {
  limit: 10,
  offset: 0,
  currentPage: 1,
  isReset: false,
};

const OfficeManagementListingTable = () => {
  const [paginationState, setPaginationState] = useState(
    initialPaginationState,
  );
  const [order, setOrder] = useState('accountName ASC');
  const history = useHistory();

  const {
    getCustomerAccountsList,
    customerAccountsList,
    customerAccountsListLoading,
    dispatchAction,
    selectedOrganization,
    currentModalName,
  } = useOfficeManagement();

  const { filters } = useOfficeManagementFilter();

  const ability = useAppAbility();
  const canManageOffice = ability.can(
    VMSOperations.Manage,
    VMSSubjects.ORGANIZATION_PROFILE,
  );

  const getData = () => {
    getCustomerAccountsList({
      variables: {
        limit: paginationState.limit,
        offset: paginationState.offset,
        order,
        filters: {
          operator: '$and',
          conditions: [
            ...(filters?.conditions?.length > 0 ? filters.conditions : []),
            ...(selectedOrganization?.officeCode
              ? [
                  {
                    operator: '$exact',
                    key: '$customer_agency_code$',
                    value: selectedOrganization?.agencyCode,
                  },
                  {
                    operator: '$exact',
                    key: '$customer_bureau_code$',
                    value: selectedOrganization?.bureauCode,
                  },
                  {
                    operator: '$exact',
                    key: '$customer_physical_office_code$',
                    value: selectedOrganization?.officeCode,
                  },
                ]
              : []),
          ],
        },
      },
    });
  };

  useEffect(() => {
    dispatchAction('SET_ALERT_MESSAGE', { type: '', message: '' });
    getData();
  }, []);

  useEffect(() => {
    getData();
  }, [paginationState, order, filters]);

  const columns = useMemo(() => {
    const actionColumn = {
      Header: 'Actions',
      sortable: false,
      Cell: ({ row }) => {
        return (
          <Button
            label="Remove"
            variant="unstyled"
            data-testid="attachment-add-button"
            type="button"
            className="display-flex"
            onClick={() => {
              dispatchAction('SET_CURRENT_SELECTED_ACCOUNT_ROW', row?.original);
              dispatchAction('SET_MODAL', {
                currentModalName,
                currentMode: 'removeCustomerAccountModalSchema',
              });
            }}
            leftIcon={{ name: 'close', class: 'usa-icon--size-3 text-bold' }}
          />
        );
      },
    };

    const columnList = [
      {
        Header: 'Account name',
        accessor: 'accountName',
        sortable: true,
        Cell: ({ value, row }) => (
          <Button
            variant="unstyled"
            label={value}
            onClick={() => {
              history.push(
                `/customer-accounts/view/${encodeURIComponent(
                  row.original.customerId,
                )}`,
              );
            }}
          />
        ),
      },
      {
        Header: 'Agency POC',
        sortable: true,
        accessor: 'primaryContactAssociation.pointOfContact.pocEmailAddress',
        Cell: ({ row }) => {
          return (
            <>
              {row?.original?.primaryContactAssociation?.pointOfContact
                ?.pocFullName && (
                <span>{`${row?.original?.primaryContactAssociation?.pointOfContact?.pocFullName}`}</span>
              )}
            </>
          );
        },
      },
      {
        Header: 'FSR',
        accessor: 'fsrUserEmail',
        sortable: true,
        Cell: ({ row }) => (
          <>
            {row?.original?.fsrUser && (
              <span>{`${row.original.fsrUser?.firstName} ${row.original.fsrUser?.lastName}`}</span>
            )}
          </>
        ),
      },
      {
        Header: 'No. of vehicles',
        accessor: 'vehicleCount',
        sortable: true,
      },
    ];
    if (canManageOffice) columnList.push(actionColumn);
    return columnList;
  }, []);

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    const offset = (currentPage - 1) * itemsPerPage;
    setPaginationState({
      limit: itemsPerPage,
      offset,
      currentPage,
    });
  };

  const renderRowSubComponent = useCallback(({ row: { original } }) => {
    return (
      <div className="display-flex flex-justify-center">
        <div className="grid-col-11">
          <OfficeManagementRowDetail customerAccount={original} />
        </div>
      </div>
    );
  }, []);

  return (
    <>
      <AFPTable
        fullWidth
        ref={tableRef}
        testId="customer-account-listing-table"
        columns={columns}
        data={
          !customerAccountsListLoading ? customerAccountsList?.rows || [] : []
        }
        defaultSort={order}
        onSort={setOrder}
        expandable
        renderRowSubComponent={renderRowSubComponent}
      />
      {customerAccountsListLoading && <Spinner className="padding-y-9" />}
      {customerAccountsList?.rows?.length > 0 && (
        <Pagination
          fullWidth
          variant="advanced"
          itemsPerPageOptions={[10, 25, 50]}
          itemsCount={customerAccountsList.count}
          itemsPerPage={paginationState.limit}
          currentPage={paginationState.currentPage}
          onPageChange={handlePaginationChange}
          isReset={paginationState.isReset}
        />
      )}
      {(!customerAccountsList || customerAccountsList.rows.length === 0) &&
        !customerAccountsListLoading && (
          <div className="bg-gray-3 padding-y-5">
            <div className="text-center padding-y-4">
              <EmptyState alt="Image not available" hasBackground />
            </div>
            <div className="text-center text-bold">
              No Customer Accounts available
            </div>
          </div>
        )}
    </>
  );
};

export default OfficeManagementListingTable;
