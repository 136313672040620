/* eslint-disable filenames/match-exported */
import React from 'react';
import { useParams } from 'react-router-dom';
import ExportVehicleReports from './export-vehicle-reports';
import ExportProvider from './export-provider';

const VehicleReports = () => {
  const { reportId } = useParams();
  return (
    <>
      <ExportProvider scheduleId={reportId}>
        <ExportVehicleReports />
      </ExportProvider>
    </>
  );
};

export default VehicleReports;
