import React, { useMemo } from 'react';
import { FilterTableFrame } from '@gsa/afp-component-library';
import useCanPerformActions from 'hooks/use-can-perform-actions';
import RecallCampaignsTable from './recall-campaigns-table';
import RecallCampaignsSidebar from './recall-campaigns-sidebar';
import RecallCampaignsActions from './recall-campaigns-actions';

const RecallCampaignsListing = () => {
  const canPerformActions = useCanPerformActions();
  const canManageRecallCampaigns = canPerformActions.canManageRecallCampaigns();

  const FTF = useMemo(
    () =>
      FilterTableFrame(
        null,
        RecallCampaignsActions,
        RecallCampaignsSidebar,
        RecallCampaignsTable,
      ),
    [],
  );

  return (
    <FTF
      upRightProps={{
        canManageRecallCampaigns,
      }}
      filterToggle
    />
  );
};

export default RecallCampaignsListing;
