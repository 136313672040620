import {
  ContactInformation,
  Registration,
  VehicleOverview,
  VehicleRecallDetails,
  CustomerDrivenData,
  EditCustomerDrivenData,
  VehicleComments,
  MileageHistory,
  RepairOrders,
  VehicleExpenses,
  VehicleSpecifications,
  VehicleValuations,
  VehicleModification,
  PreventativeMaintenance,
  VehicleRates,
  Telematics,
  BillingHistory,
  VehicleActivities,
} from '.';
import Equipment from './equipment/equipment';

// eslint-disable-next-line import/prefer-default-export
export const VEHICLE_DETAILS_SIDENAV_MAPPING = {
  overview: {
    component: VehicleOverview,
    label: 'Vehicle overview',
  },
  contact: {
    component: ContactInformation,
    label: 'Contact information',
  },
  recalls: {
    component: VehicleRecallDetails,
    label: 'Recalls',
    featureName: 'fedfms',
    hidden: true,
  },
  registration: {
    component: Registration,
    label: 'Registration',
  },
  repair: {
    component: RepairOrders,
    label: 'Repair orders',
    featureName: 'fedfms',
    hidden: true,
  },
  mileage: {
    component: MileageHistory,
    label: 'Mileage history',
    featureName: 'fedfms',
    hidden: true,
  },
  pm: {
    component: PreventativeMaintenance,
    label: 'Preventative Maintenance',
    hidden: true,
  },
  equipment: {
    component: Equipment,
    label: 'Equipment',
    featureName: 'fedfms',
  },
  specifications: {
    component: VehicleSpecifications,
    label: 'Specifications',
    featureName: 'fedfms',
  },
  expenses: {
    component: VehicleExpenses,
    label: 'Expenses',
    featureName: 'fedfms',
  },
  telematics: {
    component: Telematics,
    label: 'Telematics',
    hidden: true,
  },
  valuations: {
    component: VehicleValuations,
    label: 'Valuations',
    featureName: 'fedfms',
  },
  billingHistory: {
    component: BillingHistory,
    label: 'Billing history',
    hidden: true,
  },
  cdd: {
    component: CustomerDrivenData,
    label: 'Customer driven data',
    featureName: 'fedfms',
    hidden: true,
  },
  cddEdit: {
    component: EditCustomerDrivenData,
    label: 'Edit customer driven data',
    featureName: 'fedfms',
    hidden: true,
  },
  activities: {
    component: VehicleActivities,
    label: 'Activities',
  },
  tasks: {
    component: 'Tasks',
    label: 'Tasks',
  },
  docs: {
    component: 'Docs',
    label: 'Documents/Photos',
  },
  comments: {
    component: VehicleComments,
    label: 'Comments',
    hidden: true,
  },
  leasingRates: {
    component: VehicleRates,
    label: 'Rates',
    hidden: true,
  },
  modification: {
    component: VehicleModification,
    label: 'Modifications',
    hidden: true,
  },
};
// controlling turn on the feature checking function by adding the tab name
// 1.prevent direct access URL
// 2 hidden the tabs without permission
export const OPT_IN_SIDENAV_ITEMS = [
  'mileage',
  'repair',
  'modification',
  'expenses',
  'recalls',
  'cdd',
  'cddEdit',
  'equipment',
  'valuations',
  'specifications',
  'pm',
  'leasingRates',
  'telematics',
  'billingHistory',
  'comments',
  'activities',
];

export const getVehicleDetailsSideMap = (
  isFeatureEnabled = () => false,
  getOpenRecallCount = () => null,
  getPmWarningIcon = () => null,
) => {
  let menuItems = { ...VEHICLE_DETAILS_SIDENAV_MAPPING };
  OPT_IN_SIDENAV_ITEMS.forEach((item) => {
    if (item !== 'cddEdit')
      menuItems = {
        ...menuItems,
        [item]: {
          ...menuItems[item],
          hidden: !isFeatureEnabled(item),
        },
      };
  });

  if (!menuItems.recalls.hidden) {
    const count = getOpenRecallCount();
    if (count) {
      menuItems = {
        ...menuItems,
        recalls: {
          ...menuItems.recalls,
          count,
        },
      };
    }
  }

  if (!menuItems.pm.hidden) {
    const icon = getPmWarningIcon();
    if (icon) {
      menuItems = {
        ...menuItems,
        pm: {
          ...menuItems.pm,
          icon,
        },
      };
    }
  }
  return menuItems;
};
