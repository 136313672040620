import React, { useState, useEffect, useReducer } from 'react';
import usePortalModal from '../../../../utilities/portal-modal';
import { Button } from '@gsa/afp-component-library';
import { AccessoriesMultiSelect } from './accessory-multi-select';
import { titleCase } from 'utilities/common';

export default function EquipmentEdit({
  vehicle,
  accessories,
  editing,
  onSave,
  onClose,
  vehAccessories,
}) {
  const [EquipmentDetailModal, openModal, closeModal] = usePortalModal();
  const filterReducer = (_state, action) => {
    return [...action.payload];
  };

  const [filterState, setFilterState] = useReducer(filterReducer, []);

  const handleClose = () => {
    setFilterState({ payload: [...vehAccessories] });
    closeModal();
    onClose();
  };

  useEffect(() => {
    if (editing) {
      setFilterState({ payload: vehAccessories });
      openModal();
    } else {
      handleClose();
    }
  }, [editing]);

  // const { isRole } = useUser();

  const handleSave = () => {
    onSave(filterState, vehicle.uuid);
    handleClose();
  };

  const multiSelectChange = (checked, checkboxValue) => {
    let value = [...filterState];
    if (checked) {
      value.push(checkboxValue);
    } else {
      value = value.filter((el) => el !== checkboxValue);
    }
    setFilterState({ payload: value });
  };

  const getOptions = () => {
    let options = [];
    for (let option of accessories) {
      options.push({
        value: String(option.code),
        label: option.description ? titleCase(option.description) : '-',
      });
    }
    return options;
  };

  return (
    <EquipmentDetailModal
      title={<h2>Edit accessories details</h2>}
      onClose={handleClose}
      actions={
        <>
          <Button variant="unstyled" onClick={handleClose} label="Close" />
          <Button
            className="margin-left-2"
            onClick={handleSave}
            label="Save and close"
          />
        </>
      }
    >
      <p>
        Edit accessories details for VIN <strong>{vehicle.id}</strong> in the
        form below. Please select below all that applies.
      </p>

      <AccessoriesMultiSelect
        options={getOptions()}
        selectedValues={filterState}
        onChange={(val, checked) => {
          multiSelectChange(checked, val);
        }}
      />
    </EquipmentDetailModal>
  );
}
