import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { Button, AddressConfirmationModal } from '@gsa/afp-component-library';
import { useFormContext } from 'react-hook-form';
import { useVehicle } from '../../vehicle-context-provider';
import VehicleDesignationsForm from './vehicle-designations-form';
import VehicleOwnershipForm from './vehicle-ownership-form';
import VehicleExemptionsForm from './vehicle-exemptions-form';
import VehicleLocationForm from './vehicle-location-form';
import VehicleCostsForm from './vehicle-costs-form';
import TelematicsForm from './telematics-form';
import { VALIDATE_ADDRESS } from '../../../vehicle-registration/helpers/address-gql';
import { CDD_FORM_FIELDS } from './consts';
import CommentsForm from './comments-form';

const EditCustomerDrivenDataForm = ({ handleSave, vin }) => {
  const history = useHistory();
  const { vehicle } = useVehicle();
  const [validatedAddress, setValidatedAddress] = useState(null);
  const isAOVehicle = vehicle.ownershipTypeCode === 'AO';

  const headerClasses = classnames(
    'title-s-caps',
    'text-bold',
    'text-primary',
    'margin-bottom-2',
    'border-bottom',
    'border-base-light',
    'padding-bottom-2',
  );

  const formBlockClasses = classnames('margin-bottom-8', 'margin-top-2');

  const {
    getValues,
    setValue,
    handleSubmit,
    formState: { dirtyFields },
    ald,
  } = useFormContext();

  const [
    validateAddress,
    { loading: validatingAddress, error: validateAddressError },
  ] = useLazyQuery(VALIDATE_ADDRESS, {
    fetchPolicy: 'no-cache',
    onCompleted: (address) => {
      if (address && address.validateAddress) {
        setValidatedAddress(address.validateAddress);
      }
    },
  });

  const getGarageAddressValues = () => ({
    countryCode: getValues(CDD_FORM_FIELDS.garageCountryCode.name) || '',
    address: getValues(CDD_FORM_FIELDS.garageAddress1.name) || '',
    address2: getValues(CDD_FORM_FIELDS.garageAddress2.name) || '',
    address3: getValues(CDD_FORM_FIELDS.garageAddress3.name) || '',
    city: getValues(CDD_FORM_FIELDS.garageCity.name) || '',
    state: getValues(CDD_FORM_FIELDS.garageStateCode.name) || '',
    zip: getValues(CDD_FORM_FIELDS.garagePostalCode.name) || '',
  });

  const [addressToValidate, setAddressToValidate] = useState(
    getGarageAddressValues(),
  );

  const validateGarageAddress = (formData) => {
    const addressFields = [
      'garageAddress1',
      'garageAddress2',
      'garageCity',
      'garagePostalCode',
      'garageStateCode',
    ];
    const dirtyFieldsList = Object.keys(dirtyFields);
    const isAddressFieldsDirty = addressFields.some(
      (field) => dirtyFieldsList.indexOf(field) >= 0,
    );
    const isGarageDomestic =
      getValues(CDD_FORM_FIELDS.garageDomestic.name) === 'true' ||
      getValues(CDD_FORM_FIELDS.garageDomestic.name) === true;
    const addressToUspsValidate = getGarageAddressValues();
    // only run USPS validation on domestic addresses
    if (isGarageDomestic && isAddressFieldsDirty) {
      setAddressToValidate({ ...addressToUspsValidate });
      validateAddress({
        variables: {
          countryCode: addressToUspsValidate.countryCode,
          address: addressToUspsValidate.address,
          address2: addressToUspsValidate.address2,
          address3: addressToUspsValidate.address3,
          city: addressToUspsValidate.city,
          state: addressToUspsValidate.state,
          zip: addressToUspsValidate.zip,
        },
      });
    } else {
      handleSave({ ...formData });
    }
  };

  const saveSelectedAddress = (selectedAddress) => {
    setValue(CDD_FORM_FIELDS.garageAddress1.name, selectedAddress?.address);
    setValue(CDD_FORM_FIELDS.garageAddress2.name, selectedAddress?.address2);
    setValue(CDD_FORM_FIELDS.garageCity.name, selectedAddress?.city);
    setValue(CDD_FORM_FIELDS.garageStateCode.name, selectedAddress?.state);
    setValue(CDD_FORM_FIELDS.garagePostalCode.name, selectedAddress?.zip);
    const formValues = getValues();
    handleSave({ ...formValues });
  };

  const onSubmit = (formData) => {
    validateGarageAddress(formData);
  };

  return (
    <form
      data-testid="cdd-edit-form"
      id="cdd-edit-form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="padding-y-2" data-testid="cdd-actions">
        <Button
          id="cancel-cdd-edit"
          type="button"
          variant="outline"
          onClick={() => {
            history.push(`/vehicles/${vin}/cdd`);
          }}
          label="Cancel"
        />
        <Button
          id="save-cdd"
          type="submit"
          form="cdd-edit-form"
          leftIcon={{ name: 'check', class: 'usa-icon--size-2' }}
          label="Save customer driven data"
        />
        <p>
          Use the form below to edit customer driven data (CDD) for a federally
          owned and operated vehicle.
        </p>
      </div>
      <div className="grid-row grid-gap margin-bottom-8">
        <div className="tablet:grid-col-6">
          <div className={formBlockClasses}>
            <h4 className={headerClasses}>VEHICLE DESIGNATIONS</h4>
            {ald && (
              <div className="tablet:grid-col-6">
                <VehicleDesignationsForm isAOVehicle={isAOVehicle} />
              </div>
            )}
          </div>
          <div className={formBlockClasses}>
            <h4 className={headerClasses}>VEHICLE LOCATION</h4>
            {ald && <VehicleLocationForm isAOVehicle={isAOVehicle} />}
            {addressToValidate && (
              <AddressConfirmationModal
                validating={validatingAddress}
                hasValidationError={!!validateAddressError}
                addressToValidate={addressToValidate}
                validatedAddress={validatedAddress}
                onSave={(vAddress) => {
                  saveSelectedAddress(vAddress);
                }}
                saveButtonText="Save and close"
              />
            )}
          </div>
          {isAOVehicle ? (
            <div className={formBlockClasses}>
              <h4 className={headerClasses}>VEHICLE COSTS</h4>
              {ald && <VehicleCostsForm />}
            </div>
          ) : null}
        </div>
        <div className="tablet:grid-col-6">
          <div className={formBlockClasses}>
            <h4 className={headerClasses}>VEHICLE OWNERSHIP</h4>
            {ald && <VehicleOwnershipForm isAOVehicle={isAOVehicle} />}
          </div>
          <div className={formBlockClasses}>
            <h4 className={headerClasses}>VEHICLE EXEMPTIONS</h4>
            {ald && <VehicleExemptionsForm />}
          </div>
          <div className={formBlockClasses}>
            <h4 className={headerClasses}>VEHICLE TELEMATICS</h4>
            {ald && <TelematicsForm isAOVehicle={isAOVehicle} />}
          </div>
          <div className={formBlockClasses}>
            <h4 className={headerClasses}>COMMENTS</h4>
            {ald && <CommentsForm />}
          </div>
        </div>
      </div>
    </form>
  );
};

export default EditCustomerDrivenDataForm;

EditCustomerDrivenDataForm.propTypes = {
  handleSave: PropTypes.func.isRequired,
  vin: PropTypes.string.isRequired,
};
