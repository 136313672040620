import React, { useEffect } from 'react';
import { FilterPanel } from '@gsa/afp-component-library';
import { useVehicleActivitiesFilter } from './filters/filter-provider';
import { generateVehicleActivityFilterStructure } from './filters/vehicle-activities-filter-structure';
import { useVehicleActivities } from './vehicle-activities-provider';
import { formatDateRangeFilter } from './utils';

const VehicleActivitiesFilterBar = () => {
  const {
    filterStructure,
    setFilters,
    setStructure,
    filters: appliedFilters,
  } = useVehicleActivitiesFilter();

  const { activityTypeOptions } = useVehicleActivities();

  const setVehicleActivitiesFilters = (filters) => {
    setFilters(filters);
  };

  useEffect(() => {
    if (activityTypeOptions?.length > 0) {
      setStructure(generateVehicleActivityFilterStructure(activityTypeOptions));
    }
  }, [activityTypeOptions, appliedFilters]);

  if (filterStructure?.length > 0) {
    return (
      <div
        className="grid-col-12 bg-primary-lightest padding-3"
        id="filter-bar-wrapper"
      >
        <FilterPanel.FilterPanel
          filterStructure={filterStructure}
          mode="horizontal"
          setQueryFiltersState={(filters) => {
            let formattedFilters = formatDateRangeFilter(filters);
            formattedFilters = formattedFilters.filter(
              (item) => item !== 'error',
            );
            setVehicleActivitiesFilters(formattedFilters);
          }}
        />
      </div>
    );
  }
  return null;
};

export default VehicleActivitiesFilterBar;
