import React from 'react';

import VehicleRecallSummary from './vehicle-recall-summary';
import VehicleRecallTable from './vehicle-recall-table';
import { useVehicle } from '../../vehicle-context-provider';

const VehicleRecallDetails = () => {
  const { vehicle } = useVehicle();
  if (!vehicle) return null;

  return (
    <>
      <VehicleRecallSummary />
      <VehicleRecallTable ownerShipType={vehicle.ownershipTypeCode} />
    </>
  );
};

export default VehicleRecallDetails;
