/* eslint-disable react/prop-types */
// eslint-disable-next-line filenames/match-exported
import React from 'react';
import {
  RequiredFieldIndicator,
  TextInput,
  RadioButton,
} from '@gsa/afp-component-library';

import { Controller, useFormContext } from 'react-hook-form';

const PurchaseOrderNumberFormFields = ({
  setModalState,
  modalState,
  setIsValidOrderNumber,
}) => {
  const {
    formState: { errors },
    control,
  } = useFormContext();
  const [isPurchasedThroughGSA, setIsPurchasedThroughGSA] =
    React.useState(true);
  const options = [
    { label: 'Yes', radioValue: 'true' },
    { label: 'No', radioValue: 'false' },
  ];

  return (
    <>
      <div>
        <div className="margin-top-6">
          <b>Was this vehicle purchased through GSA? </b>
        </div>
        <Controller
          control={control}
          name="purchasedThroughGSA"
          defaultValue={options[0].radioValue}
          render={({ field: { onChange, name, value } }) => (
            <>
              {options.map(({ radioValue, label, ...restProps }) => {
                return (
                  <RadioButton
                    name={name}
                    key={radioValue}
                    value={radioValue}
                    checked={radioValue === value}
                    label={<p>{label}</p>}
                    {...restProps}
                    onChange={(e) => {
                      if (e.target.value === 'true') {
                        setModalState({
                          ...modalState,
                          currentModalAlert: {
                            type: 'error',
                            message:
                              'Please provide the corresponding order number to register this vehicle. If this information cannot be located, please contact your agency vehicle purchasing official to obtain the order information.',
                            header: null,
                          },
                        });
                      } else {
                        setModalState({
                          ...modalState,
                          currentModalAlert: {
                            type: null,
                            message: null,
                            header: null,
                          },
                        });
                      }
                      setIsPurchasedThroughGSA(e.target.value === 'true');
                      onChange(e.target.value);
                    }}
                  />
                );
              })}
            </>
          )}
        />
        {isPurchasedThroughGSA && (
          <>
            <Controller
              name="orderNumber"
              control={control}
              render={({ field: { onChange, value }, ref }) => {
                return (
                  <TextInput
                    ref={ref}
                    type="text"
                    prefix="0"
                    maxLength="6"
                    value={value}
                    label={
                      <span className="text-bold">
                        Order number <RequiredFieldIndicator />
                      </span>
                    }
                    onChange={(e) => {
                      onChange(e.target.value.toUpperCase());
                    }}
                    name="orderNumber"
                    id="orderNumber"
                    onKeyDown={() => {
                      setIsValidOrderNumber(true);
                    }}
                    help="Purchase order number example: 012345"
                    errorMessage={
                      errors && errors?.orderNumber
                        ? errors?.orderNumber?.message
                        : null
                    }
                    aria-invalid={
                      errors && errors?.orderNumber ? 'true' : 'false'
                    }
                  />
                );
              }}
            />
          </>
        )}
      </div>
    </>
  );
};

export default PurchaseOrderNumberFormFields;
