/* eslint-disable react/prop-types */
import React from 'react';
import { Controller } from 'react-hook-form';

import {
  RadioButton,
  RequiredFieldIndicator,
} from '@gsa/afp-component-library';

// eslint-disable-next-line react/prop-types
export const FuelReportLevelSelection = ({
  control,
  reportSelectionOptions,
}) => {
  return (
    <section className="margin-bottom-6">
      <h2 className="text-primary margin-bottom-1">Report selection</h2>
      <div className="usa-form-group mobile-lg:grid-col-10">
        <span>
          <b>Choose an option for the report</b> <RequiredFieldIndicator />
        </span>
        <Controller
          control={control}
          name="fuel_report_selection"
          defaultValue={reportSelectionOptions[0].radioValue}
          rules={{ required: 'This is a required field' }}
          render={({ field: { onChange, name, value } }) => (
            <>
              {reportSelectionOptions.map(
                ({ radioValue, label, ...restProps }) => {
                  return (
                    <RadioButton
                      name={name}
                      key={radioValue}
                      value={radioValue}
                      checked={radioValue === value}
                      label={<b>{label}</b>}
                      variant="tile"
                      {...restProps}
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                    />
                  );
                },
              )}
            </>
          )}
        />
      </div>
    </section>
  );
};
