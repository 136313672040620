/* eslint-disable react/prop-types */
import { Modal } from '@gsa/afp-component-library';
import React, { useEffect } from 'react';
import { useMotorPool } from '../../../motor-pool-provider';
import * as schema from '../schema/modal/index';
import {
  schemaToModalTitle,
  schemaToContent,
  schemaToModalAction,
} from '../helper';

const MotorPoolMembersModal = ({ mode }) => {
  const context = useMotorPool();
  useEffect(() => {
    context.dispatchAction('SET_ALERT_MESSAGE', { type: '', message: '' });
  }, []);

  return context.motorPoolMemberModalShow ? (
    <div className="afp-modal-wrapper">
      <div className="afp-modal-overlay">
        <Modal
          title={schemaToModalTitle({
            schema,
            mode,
            context,
          })}
          actions={schemaToModalAction({
            schema,
            mode,
            context,
          })}
          onClose={context.resetMotorPoolMemberModal}
          variant="large"
        >
          {schemaToContent({
            schema,
            mode,
            context,
          })}
        </Modal>
      </div>
    </div>
  ) : null;
};

export default MotorPoolMembersModal;
