/* eslint-disable react/prop-types */
import React from 'react';
import { Tag } from '@gsa/afp-component-library';

export default function VerificationTag({
  nhtsaVerified,
  vinSource,
  internationalVin,
  pre1981,
  unverifiedVin,
}) {
  if (nhtsaVerified) {
    return (
      <Tag
        data-testid="tag"
        className="margin-left-2 afp-green-5v text-black margin-bottom-1 padding-left-1 padding-right-1"
      >
        NHTSA VERIFIED
      </Tag>
    );
  }

  if (
    (internationalVin || pre1981 || unverifiedVin) &&
    (vinSource === 'VMS' || vinSource === 'user')
  ) {
    return (
      <Tag className="margin-left-2 afp-green-5v text-black margin-bottom-1 padding-left-1 padding-right-1">
        USER VERIFIED
      </Tag>
    );
  }

  return null;
}
