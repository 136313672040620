import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Button } from '@gsa/afp-component-library';

const canClamp = (txt, maxCharLength) => txt && txt.length > maxCharLength;

const clampText = (txt, maxCharLength) => {
  const clampedTxt = txt.substring(0, maxCharLength);
  if (clampedTxt && clampedTxt.lastIndexOf(' ') > 0) {
    // re-trim if we are in the middle of a word
    return clampedTxt.substr(
      0,
      Math.min(clampedTxt.length, clampedTxt.lastIndexOf(' ')),
    );
  }
  return clampedTxt;
};

const Clamp = ({ text, maxCharLength }) => {
  const [clamped, setClamped] = useState(false);

  useEffect(() => {
    setClamped(canClamp(text, maxCharLength));
  }, [text, maxCharLength])
  
  const toggleClamp = () => {
    setClamped(!clamped);
  };

  return (
    <>
      <p className="margin-0 overflow-wrap-text">
        {clamped ? clampText(text, maxCharLength).concat('...') : text}
      </p>
      {canClamp(text, maxCharLength) && (
        <Button
          variant="unstyled"
          label={clamped ? 'Read more' : 'Read less'}
          onClick={toggleClamp}
          data-testid="clamp-button"
        />
      )}
    </>
  );
};

Clamp.defaultProps = {
  maxCharLength: 75,
};

Clamp.propTypes = {
  text: PropTypes.string.isRequired,
  maxCharLength: PropTypes.number,
};

export default Clamp;
