import { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useModal } from '@gsa/afp-component-library';
import {
  GET_ORDER,
  ORDER_TAGS,
  BULK_UPDATE_ORDER,
  RECONCILE_TAGS,
  REPORT_LOST_STOLEN_TAGS,
  RECONCILE_ORDER_TAGS,
} from '../../../services/data-layer';

const INIT_FILTERS = [
  {
    conditions: [],
    operator: '$and',
  },
];
const INIT_LIMIT = 100;
const INIT_OFFSET = 0;
const INIT_SORT_ORDER = [['tagNumber', 'ASC']];

export default function OrderDetailsManager(orderNumber, handleReconcile) {
  const [tag, setTag] = useState(null);

  const [getOrder, { data, loading, refetch: refetchOrder }] = useLazyQuery(
    GET_ORDER,
    {
      fetchPolicy: 'no-cache',
    },
  );
  const [
    getOrderTags,
    { data: orderTags, refetch: refetchOrderTags, loading: loadingOrderTags },
  ] = useLazyQuery(ORDER_TAGS, {
    fetchPolicy: 'no-cache',
  });

  const [updateOrderDetails] = useMutation(BULK_UPDATE_ORDER, {
    onCompleted: () => {
      refetchOrder();
      refetchOrderTags();
    },
  });

  const [orderInitialized, setOrderInitialized] = useState(false);
  const [details, setDetails] = useState(null);
  const [tags, setTags] = useState({ rows: [] });

  const [filters, setTagFilter] = useState(INIT_FILTERS);
  const [limit, setLimit] = useState(INIT_LIMIT);
  const [offset, setOffset] = useState(INIT_OFFSET);
  const [sortOrder, setSortOrder] = useState(INIT_SORT_ORDER);

  const [reconcileEditing, setReconcileEditing] = useState(false);

  const updateDetails = (updatedDetails) => {
    setDetails((currentDetails) => {
      return { ...currentDetails, ...updatedDetails };
    });
  };

  const setFilter = (newFilter) => {
    setTagFilter(newFilter);
    setOffset(0);
  };

  useEffect(() => {
    if (orderInitialized) {
      const cpFilters = JSON.parse(JSON.stringify(filters));

      cpFilters[0].conditions = cpFilters[0].conditions.map((condition) => {
        if (condition.key === 'orderNumber') {
          return {
            ...condition,
            value: orderNumber,
          };
        }

        return condition;
      });

      getOrderTags({
        variables: {
          orderNumber,
          limit,
          filters: cpFilters,
          offset,
          order: sortOrder,
        },
      });
    }
  }, [filters, limit, offset, sortOrder]);

  useEffect(() => {
    setOrderInitialized(true);
    getOrder({
      variables: {
        orderNumber,
      },
    });
  }, [orderNumber]);

  useEffect(() => {
    if (data && data.getOrder) {
      setDetails(data.getOrder);
    }
  }, [data]);

  useEffect(() => {
    if (orderTags?.getOrderTags) {
      setTags(orderTags.getOrderTags);
    }
  }, [orderTags]);

  const updateTagsDetails = (oTags) => {
    const newOrderTags = { ...tags };
    oTags.forEach((tag) => {
      const tIndex = newOrderTags.rows.findIndex(
        (t) =>
          t.tagNumber === tag.tagNumber &&
          t.aOrB === tag.aOrB &&
          t.tagExpirationDate === tag.tagExpirationDate,
      );
      if (tIndex > -1) {
        newOrderTags.rows[tIndex] = { ...newOrderTags.rows[tIndex], ...tag };
      }
    });
    setTags(newOrderTags);
  };

  const {
    isOpen: isOrderReconcileModalOpen,
    openModal: openOrderReconcileModal,
    closeModal: closeOrderReconcileModal,
  } = useModal();

  const {
    isOpen: isReconcileModalOpen,
    openModal: openReconcileModal,
    closeModal: closeReconcileModal,
  } = useModal();

  const {
    isOpen: isDamagedModalOpen,
    openModal: openDamagedModal,
    closeModal: closeDamagedModal,
  } = useModal();

  const {
    isOpen: isLostStolenTagModalOpen,
    openModal: openLostStolenTagModal,
    closeModal: closeLostStolenTagModal,
  } = useModal();

  const [
    reconTags,
    {
      data: reconciledTags,
      loading: reconciledTagsLoading,
      error: reconciledTagsError,
    },
  ] = useMutation(RECONCILE_TAGS, {
    onError: () => {
      closeReconcileModal();
    },

    onCompleted: (reconciledData) => {
      if (reconciledData) {
        refetchOrder();
        refetchOrderTags();
        closeReconcileModal();
      }
    },
  });

  const openReconcileModalWithTag = (t) => {
    if (t) {
      setTag(t);
      openReconcileModal();
    }
  };

  const [
    reportTag,
    {
      data: reportedTag,
      loading: loadingOrderTagLostStolen,
      error: errorOrderTagLostStolen,
    },
  ] = useMutation(REPORT_LOST_STOLEN_TAGS, {
    onError: () => {
      closeLostStolenTagModal();
    },
    onCompleted: (reportedData) => {
      if (reportedData) {
        refetchOrder();
        refetchOrderTags();
        closeLostStolenTagModal();
      }
    },
  });

  const openLostStolenModalWithTag = (t) => {
    if (t) {
      setTag(t);
      openLostStolenTagModal();
    }
  };

  const [
    reportDamageTag,
    {
      data: reportedDamageTag,
      error: errorDamagedTag,
      loading: loadingDamagedTag,
    },
  ] = useMutation(REPORT_LOST_STOLEN_TAGS, {
    onError: () => {
      closeDamagedModal();
    },
    onCompleted: (reportDamageTagData) => {
      if (reportDamageTagData) {
        refetchOrder();
        refetchOrderTags();
        closeDamagedModal();
      }
    },
  });

  const openDamagedModalWithTag = (t) => {
    if (t) {
      setTag(t);
      openDamagedModal();
    }
  };

  const [ReconcileOrderTags, { loading: reconcileOrderLoading }] = useMutation(
    RECONCILE_ORDER_TAGS,
    {
      onError: () => {
        handleReconcile('error');
      },
      onCompleted: (responseData) => {
        if (responseData?.reconcileOrderTags) {
          updateDetails(responseData.reconcileOrderTags);
          handleReconcile('success');
          refetchOrder();
          refetchOrderTags();
        }
      },
    },
  );

  const isAbleToReconcile = (tagX) => {
    return (
      tagX.tagStatus === 'SH' ||
      tagX.tagStatus === 'SHIP' ||
      tagX.tagStatus === 'RETN' ||
      tagX.tagStatus === 'DESV' ||
      (tagX.activity &&
        tagX.activity.typeCode === 'MISS' &&
        tagX.activity.createdBy === 'system') ||
      (tagX.tagStatus === 'MISS' && tagX.updatedBy === 'system')
    );
  };

  const getLpCount = () => {
    return tags?.rows.filter((x) => isAbleToReconcile(x)).length;
  };

  const getExceptionCount = () => {
    return tags?.rows.filter((y) => !isAbleToReconcile(y)).length;
  };

  const toggleReconcileEdit = () => {
    setReconcileEditing(!reconcileEditing);
  };

  const saveReconcile = (comment) => {
    if (getLpCount()) {
      ReconcileOrderTags({
        variables: {
          orderNumber,
          comment,
        },
      });
    }

    toggleReconcileEdit();
  };

  return {
    details,
    setDetails,
    tags,
    setTags,
    loading,
    updateDetails,
    getOrder,
    updateOrderDetails,
    refetchOrder,
    refetchOrderTags,
    filters,
    limit,
    offset,
    sortOrder,
    setSortOrder,
    setFilter,
    setLimit,
    setOffset,
    updateTagsDetails,
    orderNumber,
    getOrderTags,
    loadingOrderTags,
    isReconcileModalOpen,
    openReconcileModal,
    closeReconcileModal,
    isDamagedModalOpen,
    openDamagedModal,
    closeDamagedModal,
    isLostStolenTagModalOpen,
    openLostStolenTagModal,
    closeLostStolenTagModal,
    tag,
    setTag,
    reconTags,
    reconciledTags,
    reconciledTagsLoading,
    reconciledTagsError,
    openReconcileModalWithTag,

    reportTag,
    reportedTag,
    loadingOrderTagLostStolen,
    errorOrderTagLostStolen,
    openLostStolenModalWithTag,

    reportDamageTag,
    reportedDamageTag,
    errorDamagedTag,
    loadingDamagedTag,
    openDamagedModalWithTag,

    getLpCount,
    getExceptionCount,
    saveReconcile,
    toggleReconcileEdit,
    reconcileEditing,

    isOrderReconcileModalOpen,
    openOrderReconcileModal,
    closeOrderReconcileModal,
    reconcileOrderLoading,
  };
}
