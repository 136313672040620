import { gql } from '@apollo/client';
import { TagCommentFields } from './fragments';

export const DELETE_VEHICLE_COMMENT = gql`
  mutation DeleteVehicleComment($id: Float!, $vin: String!) {
    deleteVehicleComment(id: $id, vin: $vin)
  }
`;

export const GET_LP_COMMENTS = gql`
  query getTagComments($id: String!, $limit: Float, $offset: Float) {
    getTagComments(id: $id, limit: $limit, offset: $offset) {
      rows {
        ...TagCommentFields
      }
      count
      hasMore
    }
  }
  ${TagCommentFields}
`;

export const CREATE_LP_COMMENT = gql`
  mutation AddTagComment($id: String!, $comment: String!) {
    addTagComment(id: $id, comment: $comment) {
      ...TagCommentFields
    }
  }
  ${TagCommentFields}
`;

export const DELETE_LP_COMMENT = gql`
  mutation DeleteTagComment($id: Float!) {
    deleteTagComment(id: $id)
  }
`;

export default {
  DELETE_VEHICLE_COMMENT,
  GET_LP_COMMENTS,
  CREATE_LP_COMMENT,
  DELETE_LP_COMMENT,
};
