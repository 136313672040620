/* eslint-disable filenames/match-exported */

import RESERVATION_HISTORY_REPORT from './RESERVATION_HISTORY_REPORT';

import MOTOR_POOL_UTILIZATION_REPORT from './MOTOR_POOL_UTILIZATION_REPORT';

const schema = {
  MOTOR_POOL_UTILIZATION_REPORT,
  RESERVATION_HISTORY_REPORT,
  name: 'MOTOR_POOL_LISTING_MODAL',
};
export default schema;
