import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { SelectDropdown } from '@gsa/afp-component-library';
import { sortBy } from 'lodash';
import { GET_OFFICES_BY_PERMISSION } from '../../../../services/data-layer';
import {
  VMSOperations,
  VMSSubjects,
  DEFAULT_SELECT_OPTION_LABEL,
} from '../../../../utilities/consts';

export default function SelectOffice({
  agencyCode,
  bureauCode,
  subSectionCode,
  onChange = () => {},
  onUpdate = () => {},
}) {
  const [getOfficesNext, { data }] = useLazyQuery(GET_OFFICES_BY_PERMISSION, {
    fetchPolicy: 'no-cache',
    onError(err) {
      console.warn(err);
    },
  });
  const [offices, setOffices] = useState([]);

  useEffect(() => {
    if (agencyCode && bureauCode) {
      getOfficesNext({
        variables: {
          agencyCode,
          bureauCode,
          subject: VMSSubjects.VEHICLE,
          operation: VMSOperations.CREATE_VEHICLE,
        },
      });
    } else {
      setOffices([{ value: '', label: DEFAULT_SELECT_OPTION_LABEL }]);
    }
  }, [agencyCode, bureauCode]);

  useEffect(() => {
    if (data?.getOfficesByPermission) {
      setOffices([
        { value: '', label: DEFAULT_SELECT_OPTION_LABEL },
        ...sortBy(data.getOfficesByPermission, 'officeCode').map((o) => ({
          value: o.officeCode,
          label: `${o.officeCode} - ${o.officeName}`,
        })),
      ]);
      if (data?.getOfficesByPermission.length === 1) {
        const { officeCode, officeName } = data?.getOfficesByPermission[0];
        onChange({
          value: officeCode,
          label: `${officeCode} - ${officeName}`,
        });
      }
    }
  }, [data]);

  useEffect(() => {
    if (subSectionCode && offices.length) {
      const selectedOffice = offices.find(
        (office) => office.value === subSectionCode,
      );
      if (selectedOffice) {
        onUpdate(selectedOffice);
      }
    }
  }, [subSectionCode, offices]);

  const handleChange = (e) => {
    const { value } = e.target;
    const selectedOffice = offices.find((office) => office.value === value);
    onChange(selectedOffice);
  };

  return (
    <SelectDropdown
      id="office"
      name="office"
      label="Office"
      options={offices}
      value={subSectionCode}
      onChange={handleChange}
      //   errorMessage={errors['office.id']}
      //   disabled={
      //     !agencyInformation.bureau.id ||
      //     currentUser.customerAttrs?.registeredOfficeCode != null
      //   }
    />
  );
}
