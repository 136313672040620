import React from 'react';
import moment from 'moment';
import { useVehicle } from '../../vehicle-context-provider';
import { useMileageHistoryGF } from './mileage-history-gf-provider';
import { SummaryCard } from '../../widgets/summary-card';
import { getLastReportedMileage } from './utils';

const calculateMonthsSinceLastReportedMileage = (mileageHistory) => {
  const lastMileageHistoryRecord = getLastReportedMileage(mileageHistory, true); // true to exclude estimated records
  return moment().diff(
    moment(lastMileageHistoryRecord?.mileageDate).endOf('month'),
    'months',
  );
};

const MileageSummary = () => {
  const {
    vehicle: { assetAcquisition, newOdometerReading, ownershipTypeCode },
  } = useVehicle();

  const { mileageHistory } = useMileageHistoryGF();

  const getOdometerFootnote = () => {
    const { mileageDate } = newOdometerReading || {};
    if (!mileageDate) return '';
    return `as of ${mileageDate.substr(5, 2)}/${mileageDate.substr(
      8,
      2,
    )}/${mileageDate.substr(0, 4)}`;
  };

  const getAvgMiles = () => {
    try {
      const { deliveredMiles, originalAcquisitionDate } = assetAcquisition;
      const { odometer, mileageDate } = newOdometerReading;

      const totalMiles = odometer - deliveredMiles;
      const totalMonth =
        moment(mileageDate.substr(0, 7), 'YYYY-MM').diff(
          moment(originalAcquisitionDate.substr(0, 7), 'YYYY-MM'),
          'months',
        ) + 1;

      if (totalMiles <= 0 || totalMonth <= 0) return undefined;
      return Math.round(totalMiles / totalMonth);
    } catch {
      return undefined;
    }
  };

  return (
    <div className="grid-row flex-row" data-testid="mileage-summary">
      <SummaryCard
        title="Vehicle odometer"
        count={newOdometerReading?.odometer}
        footnote={getOdometerFootnote()}
      />
      <SummaryCard
        title="Average monthly miles"
        count={getAvgMiles()}
        footnote=" "
      />
      {ownershipTypeCode === 'GF' && (
        <SummaryCard
          title="Estimated months"
          count={calculateMonthsSinceLastReportedMileage(mileageHistory)}
          footnote="months"
        />
      )}
    </div>
  );
};

export default MileageSummary;
