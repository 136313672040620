import {
  Button,
  connectModal,
  Modal,
  SelectDropdown,
  Spinner,
} from '@gsa/afp-component-library';
import React, { useState } from 'react';
import { VMSOperations, VMSSubjects } from '../../../utilities/consts';
import useAgencyManager from '../helpers/order-agency-manager';
import useUser from '../../../utilities/use-user';

export default function AgencyEditModal({
  agency,
  office,
  bureau,
  isOpen,
  orderNumber,
  closeModal,
  onEditAgency,
}) {
  const [newOffice, setNewOffice] = useState(office?.officeCode);
  const [newBureau, setNewBureau] = useState(bureau?.id);
  const [newAgency, setNewAgency] = useState(agency?.id);
  const [inputErrors, setInputErrors] = useState({
    agency: '',
    bureau: '',
  });

  const { isRole } = useUser();

  const {
    agencies,
    changeAgency,
    offices,
    getOffices,
    bureaus,
    getBureaus,
    agencyLoading,
    bureausLoading,
    officeLoading,
  } = useAgencyManager({ orderNumber, agency, bureau, office, onEditAgency });

  const handleOfficeChange = (newOffice) => {
    setNewOffice(newOffice);
    if (newOffice) setInputErrors({ ...inputErrors, office: '' });
  };

  const handleAgencyChange = (newAgency) => {
    setNewAgency(newAgency);
    setNewBureau('');
    if (newAgency) setInputErrors({ ...inputErrors, agency: '' });
    getBureaus({
      variables: {
        agencyCode: newAgency,
        subject: VMSSubjects.ORDER,
        operation: VMSOperations.UPDATE_ORDER,
        order: 'id ASC',
      },
    });
  };

  const handleBureauChange = (newBureau) => {
    setNewBureau(newBureau);
    if (newBureau) setInputErrors({ ...inputErrors, bureau: '' });
    setNewOffice('');
    getOffices({
      variables: {
        agencyCode: newAgency,
        bureauCode: newBureau,
        subject: VMSSubjects.ORDER,
        operation: VMSOperations.UPDATE_ORDER,
        order: 'officeCode ASC',
      },
    });
  };

  const resetNewAgency = () => {
    setNewBureau('');
    setNewAgency('');
    setNewOffice('');
    setInputErrors({ agency: undefined, bureau: undefined, office: undefined });
  };

  const handleSave = () => {
    if (!newAgency || !newBureau) {
      setInputErrors({
        agency: !newAgency ? 'Agency is a required field' : undefined,
        bureau: !newBureau ? 'Bureau is a required field' : undefined,
      });
      return;
    } else {
      changeAgency &&
        changeAgency(newAgency, newBureau, newOffice, orderNumber);
      resetNewAgency();
      closeModal();
    }
  };

  const AddModal = () => (
    <Modal
      title={<h2>Update agency</h2>}
      onClose={closeModal}
      variant="large"
      actions={
        <>
          <Button
            type="button"
            variant="unstyled"
            onClick={closeModal}
            label="Cancel"
          />
          <Button
            type="button"
            variant="primary"
            data-testid="agency-edit-button"
            onClick={handleSave}
            label="Save and close"
          />
        </>
      }
    >
      <div className="text-bold">
        <div className="grid-row">
          <div className="grid-col-8">
            <SelectDropdown
              className="agency-select"
              name="agency"
              label="Agency"
              options={agencies}
              default
              onChange={(e) => handleAgencyChange(e.target.value)}
              value={newAgency}
              errorMessage={inputErrors?.agency}
              disabled={!(isRole('FMVRSAdminRole') || isRole('SiteAdmin'))}
              required
            />
          </div>
          {agencyLoading && (
            <Spinner
              data-testid="agency-loading-spinner"
              className="grid-col-1 padding-top-7"
            />
          )}
        </div>

        <div className="grid-row">
          <div className="grid-col-8">
            <SelectDropdown
              className="bureau-select"
              name="bureau"
              label="Bureau"
              options={bureaus}
              default
              onChange={(e) => handleBureauChange(e.target.value)}
              value={newBureau}
              errorMessage={inputErrors?.bureau}
              disabled={
                !(
                  isRole('FMVRSAdminRole') ||
                  isRole('SiteAdmin') ||
                  isRole('CustFltMan')
                )
              }
              required
            />
          </div>
          {bureausLoading && (
            <Spinner
              data-testid="bureau-loading-spinner"
              className="grid-col-1 padding-top-7"
            />
          )}
        </div>

        <div className="grid-row">
          <div className="grid-col-8">
            <SelectDropdown
              data-testid="office-edit"
              className="office-select"
              name="office"
              label="Office"
              options={offices}
              default
              onChange={(e) => handleOfficeChange(e.target.value)}
              value={newOffice}
              disabled={
                !(
                  isRole('FMVRSAdminRole') ||
                  isRole('SiteAdmin') ||
                  isRole('CustFltMan')
                )
              }
            />
          </div>
          {officeLoading && (
            <Spinner
              data-testid="office-loading-spinner"
              className="grid-col-1 padding-top-7"
            />
          )}
        </div>
      </div>
    </Modal>
  );

  const ConnectedModal = connectModal(AddModal);

  return <ConnectedModal isOpen={isOpen} onClose={closeModal} />;
}
