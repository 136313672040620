/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  Textbox,
  RequiredFieldIndicator,
  Modal,
  Spinner,
  Label,
  ErrorMessage,
  Alert,
} from '@gsa/afp-component-library';
import useCharCounter from '../helpers/use-char-counter';
import { ADD_ASSET_RECALL_COMMENT } from '../components/graphql-queries';
import { useVehicle } from '../vehicle-context-provider';

const MAX_LEN_DESC = 500;

const AddCommentModal = ({ onClose, actionData }) => {
  const [descCounter, setDescCount] = useCharCounter(MAX_LEN_DESC);
  const [errorMessage, setErrorMessage] = useState('');
  const [comment, setComment] = useState('');
  const [apiErrorMessage, setApiErrorMessage] = useState('');

  const { setSectionMsg } = useVehicle();

  const [addRecallComment, { loading }] = useMutation(
    ADD_ASSET_RECALL_COMMENT,
    {
      onCompleted: () => {
        onClose();
        setSectionMsg({
          type: 'success',
          message: (
            <>
              Your comment for recall{' '}
              <strong>{actionData.recallCampaign.recallCode}</strong> is saved
              successfully.
            </>
          ),
        });
      },
      onError: (err) => setApiErrorMessage(`Error on Submit: ${err.message}`),
    },
  );

  const submitForm = () => {
    if (!comment.trim()) {
      setErrorMessage('Comments is required');
    } else {
      addRecallComment({
        variables: {
          assetRecallId: parseFloat(actionData.id),
          comment: comment.trim(),
        },
      });
    }
  };

  const onFieldChange = (e) => {
    const { value } = e.target;
    setComment(value);
    setDescCount(value.length);
  };

  const onBlur = (e) => {
    const { value } = e.target;
    if (!value) {
      setErrorMessage('Comments is required');
    } else {
      setErrorMessage('');
    }
  };

  return (
    <div className="afp-modal-wrapper">
      <div className="afp-modal-overlay">
        <Modal
          isOpen
          id="cancel-registration-modal"
          variant="large"
          title={<h2>Add new comment</h2>}
          onClose={onClose}
          actions={
            <div>
              <button
                type="button"
                className="continue usa-button--unstyled padding-right-2"
                onClick={onClose}
              >
                Cancel
              </button>
              <button
                type="button"
                className="cancel usa-button"
                onClick={submitForm}
              >
                Submit
              </button>
            </div>
          }
        >
          {loading && (
            <Spinner
              style={{
                position: 'absolute',
                top: '50%',
                transform: 'translate(0, -50%)',
              }}
            />
          )}

          {apiErrorMessage && (
            <Alert type="error" slim>
              {apiErrorMessage}
            </Alert>
          )}
          <p style={{ margin: '10px 0px' }}>
            Your comment will be added to the recall campaign{' '}
            <b>{actionData.recallCampaign.recallCode}</b>.
          </p>
          <span>
            Required fields are marked with an asterisk (
            <RequiredFieldIndicator />
            ).
          </span>
          <Label className="changeFontWeight">
            Comments <RequiredFieldIndicator />
          </Label>
          {errorMessage && <ErrorMessage> {errorMessage} </ErrorMessage>}
          <Textbox
            id="comments"
            style={errorMessage ? { borderColor: '#b50909' } : {}}
            className={errorMessage ? 'border-05' : ''}
            aria-label="enter comments"
            data-testid="comments"
            name="comments"
            type="textarea"
            rows={2}
            maxLength={MAX_LEN_DESC}
            onChange={(e) => {
              onFieldChange(e);
            }}
            onBlur={onBlur}
          />
          {descCounter}
        </Modal>
      </div>
    </div>
  );
};

export default AddCommentModal;
