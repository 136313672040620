import React from 'react';
import { DetailsTable } from '@gsa/afp-component-library';
import MotorPoolVehicleAddEditForm from './motor-pool-vehicle-edit-form';
import { useMotorPool } from '../../../motor-pool-provider';

const VEHICLE_OWNERSHIP = {
  GF: 'GSA Leased',
  AO: 'Agency Owned',
};

const ManageMotorPoolVehicle = () => {
  const { motorPoolVehicleSelected } = useMotorPool();
  return (
    <>
      <div className="bg-gray-3 padding-3 margin-top-3">
        <DetailsTable
          bordered
          data={[
            [
              'Pool',
              <span className="float-right align-right">
                {motorPoolVehicleSelected?.motorPool?.poolName}
              </span>,
            ],
            [
              'Description',
              <span className="float-right align-right">
                {motorPoolVehicleSelected?.motorPool?.description}
              </span>,
            ],
            [
              'Vehicle ownership',
              <span className="float-right align-right">
                {
                  VEHICLE_OWNERSHIP[
                    motorPoolVehicleSelected?.vehicle?.ownershipTypeCode
                  ]
                }
              </span>,
            ],
          ]}
        />
      </div>
      <MotorPoolVehicleAddEditForm />
    </>
  );
};

export default ManageMotorPoolVehicle;
