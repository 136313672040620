/* eslint-disable filenames/match-exported */
/* eslint-disable react/prop-types */
import { Modal, Alert } from '@gsa/afp-component-library';
import React, { useEffect } from 'react';

export const schemaToModal = ({ schema, mode, context, partOfModal }) => {
  if (partOfModal === 'onClose') {
    if (schema[mode]) {
      return schema[mode]?.onClose;
    }
    return null;
  }
  if (!schema[mode]) {
    return null;
  }
  if (!schema[mode][partOfModal]) {
    return null;
  }
  if (typeof schema[mode][partOfModal] !== 'function') {
    return null;
  }
  return schema[mode][partOfModal](context);
};

const getSchemaForCurrentModal = (schema, context) => {
  const modalName = context?.modalState?.currentModalName;

  if (schema[modalName]) {
    return schema[modalName];
  }

  if (schema.name) {
    if (typeof schema.name === 'string' && schema.name === modalName) {
      const { name, ...schemaInfo } = schema;
      return schemaInfo;
    }

    return null;
  }

  return null;
};

const ModalWrapper = ({
  schema,
  context,
  onCloseModal = () => null,
  clearAlertMessage = () => null,
  isAlertMessage = true,
}) => {
  // all init useEffect goes here
  useEffect(() => {
    if (context?.dispatchAction && !context?.stopResetAlertMsgInitModal) {
      if (isAlertMessage) {
        context.dispatchAction('SET_ALERT_MESSAGE', { type: '', message: '' });
      } else {
        context.dispatchAction('SET_BANNER_MESSAGE', null);
      }
    } else {
      clearAlertMessage();
    }
  }, []);
  return context?.modalState?.currentModalName &&
    context?.modalState?.currentMode ? (
    <div className="afp-modal-wrapper">
      <div className="afp-modal-overlay">
        <Modal
          title={schemaToModal({
            schema: getSchemaForCurrentModal(schema, context),
            mode: context.modalState.currentMode,
            context,
            partOfModal: 'title',
          })}
          actions={schemaToModal({
            schema: getSchemaForCurrentModal(schema, context),
            mode: context.modalState.currentMode,
            context,
            partOfModal: 'action',
          })}
          onClose={() => {
            //  onCloseFromSchema is defined in schema
            //  the resetCurrentModal function from  context
            //  onCloseModal is an option to pass to the modal component for that feature

            const onCloseFromSchema = schemaToModal({
              schema: getSchemaForCurrentModal(schema, context),
              mode: context.modalState.currentMode,
              context,
              partOfModal: 'onClose',
            });

            if (typeof onCloseFromSchema === 'function') {
              onCloseFromSchema(context);
            } else {
              context?.resetCurrentModal === 'function' &&
                context.resetCurrentModal();
            }

            onCloseModal();
          }}
          variant="large"
        >
          {context?.modalState?.currentModalAlert?.type &&
            context?.modalState?.currentModalAlert?.message && (
              <div
                className="margin-bottom-2"
                data-testid="reservation-modal-alert"
              >
                <Alert
                  type={context?.modalState?.currentModalAlert?.type}
                  heading={
                    context?.modalState?.currentModalAlert?.header || null
                  }
                >
                  {context?.modalState?.currentModalAlert?.message}
                </Alert>
              </div>
            )}

          {schemaToModal({
            schema: getSchemaForCurrentModal(schema, context),
            mode: context.modalState.currentMode,
            context,
            partOfModal: 'content',
          })}
        </Modal>
      </div>
    </div>
  ) : null;
};

export default ModalWrapper;
