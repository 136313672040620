import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useVehicle } from '../vehicle-context-provider';
import { VehicleRegistrationStatusTag } from '../../vehicle-registration-status-tag';
import { LifeCycleIndicatorTag, LifeCycleTags } from './life-cycle';

const getValue = (value, fallbackValue) => value || fallbackValue || '—';

const Header = ({ vin }) => {
  const { vehicle } = useVehicle();
  return (
    <>
      <div className="display-flex flex-align-center margin-top-3">
        <h1 className="margin-0">
          {vehicle?.ownershipTypeCode === 'GF' && vehicle?.tagNumber && (
            <Link
              className="margin-right-3"
              data-testid="vehicle-attached-license-plate"
              to={`/license-plate/${vehicle?.tagNumber}?tagExpirationDate=${vehicle?.tag?.expirationDate}`}
            >
              {vehicle?.tagNumber}
            </Link>
          )}
          {vehicle?.ownershipTypeCode === 'AO' && (
            <span className="margin-right-3">VIN {vin}</span>
          )}
        </h1>

        {vehicle?.ownershipTypeCode === 'GF' &&
        vehicle?.assetLifecycle?.[0]?.lifeCycle?.lifecycleIndicator ? (
          <LifeCycleIndicatorTag
            value={vehicle?.assetLifecycle?.[0]?.lifeCycle?.lifecycleIndicator}
          />
        ) : (
          vehicle?.inventoryStatus && (
            <VehicleRegistrationStatusTag
              data-testid="vehicle-status afp-registration__status"
              vehicle={vehicle}
              page="vehicle-details"
            />
          )
        )}
      </div>
      <h2
        data-testid="vehicle-make-model"
        className="title-m margin-top-3 tablet:margin-top-1 margin-bottom-1 text-uppercase"
      >
        {getValue(vehicle?.modelYear <= 0 ? 'UNKNOWN' : vehicle?.modelYear)}{' '}
        {getValue(vehicle?.vehicleMake?.makeName)}{' '}
        {getValue(
          vehicle?.vehicleModel?.modelDescription || vehicle?.modelCode,
        )}
      </h2>
      <h2 className="title-m margin-top-0">
        {vehicle?.tagNumber && vehicle?.ownershipTypeCode === 'AO' && (
          <Link
            data-testid="vehicle-attached-license-plate"
            to={`/license-plate/${vehicle?.tagNumber}?tagExpirationDate=${vehicle?.tag?.expirationDate}`}
          >
            {vehicle?.tagNumber}
          </Link>
        )}
        {vehicle?.ownershipTypeCode === 'GF' && (
          <span className="margin-top-0">VIN {vin}</span>
        )}
      </h2>
      {vehicle?.ownershipTypeCode === 'GF' && (
        <LifeCycleTags vehicle={vehicle} />
      )}
    </>
  );
};

export default Header;

Header.propTypes = {
  vin: PropTypes.string.isRequired,
};
