import React, { useEffect } from 'react';
import { FilterPanel } from '@gsa/afp-component-library';
import { useOfficeManagement } from './office-management-provider';
import { useOfficeManagementFilter } from './filters/filter-provider';

const OfficeManagementSidebar = () => {
  const { statesOptions } = useOfficeManagement();

  const { filterStructure, setFilters, generateStructure } =
    useOfficeManagementFilter();

  const setOfficeManagementFilters = (filters) => {
    setFilters(filters);
  };

  useEffect(() => {
    if (statesOptions?.length > 0) {
      generateStructure(statesOptions);
    }
  }, [statesOptions]);

  if (filterStructure?.length > 0) {
    return (
      <FilterPanel.FilterPanel
        filterStructure={filterStructure}
        setQueryFiltersState={(filters) => setOfficeManagementFilters(filters)}
        clearButtonLabel="Reset all"
        showClearIcon
        order={[['updateAt', 'DESC']]}
        showSearchIcon
      />
    );
  }
  return null;
};

export default OfficeManagementSidebar;
