/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  RequiredFieldIndicator,
  SelectDropdown,
  MultiSelectDropdown,
} from '@gsa/afp-component-library';
import useCanPerformActions from 'hooks/use-can-perform-actions';
import { STATES_LIST, VMSOperations, VMSSubjects } from 'utilities/consts';
import { useMotorPool } from '../motor-pool-provider';

const reservationPurposeOptions = [
  { value: '', label: '- Select -' },
  { value: '1', label: 'Recurring Dispatch/Permanent Assignment' },
  { value: '2', label: 'Local Travel' },
  { value: '3', label: 'Extended Distance Travel/TDY' },
  {
    value: '4',
    label: 'Scheduled Maintenance, Repair or Otherwise out of Service',
  },
  { value: '5', label: 'Other' },
];

export const YesNoSelector = ({
  fieldAttrs,
  setValue,
  errors,
  clearErrors,
  field,
  ref,
  options,
}) => {
  const isReservationPurpose = fieldAttrs.name === 'reservationPurposeId';
  const defaultOptions =
    fieldAttrs.name === 'isOnBehalf' ? options?.slice(1)?.reverse() : options;
  return (
    <SelectDropdown
      {...field}
      ref={ref}
      name={fieldAttrs.name}
      id={fieldAttrs.id}
      label={
        <span className="text-bold">
          {fieldAttrs.label} {fieldAttrs.required && <RequiredFieldIndicator />}
        </span>
      }
      className={isReservationPurpose ? '' : 'tablet:grid-col-2'}
      options={isReservationPurpose ? reservationPurposeOptions : defaultOptions}
      onChange={(e) => {
        setValue(fieldAttrs.name, e.target.value);
        clearErrors(fieldAttrs.name);
      }}
      errorMessage={
        errors && errors[fieldAttrs.name]
          ? errors[fieldAttrs.name].message
          : null
      }
      aria-invalid={errors && errors[fieldAttrs.name] ? 'true' : 'false'}
    />
  );
};

YesNoSelector.defaultProps = {
  options: [
    { value: '', label: '- Select -' },
    { value: 'yes', label: 'Yes' },
    { value: 'no', label: 'No' },
  ],
  errors: null,
};

YesNoSelector.propTypes = {
  clearErrors: PropTypes.func.isRequired,
  errors: PropTypes.any,
  field: PropTypes.any.isRequired,
  fieldAttrs: PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    required: PropTypes.bool,
  }).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  ref: PropTypes.any.isRequired,
  setValue: PropTypes.func.isRequired,
};

export const WrappedStateSelector = ({
  fieldAttrs,
  setValue,
  getValues,
  errors,
  clearErrors,
  field,
  ref,
}) => {
  const statesOptions = [
    { value: '', label: '- Select -' },
    ...STATES_LIST.map(({ stateCode }) => ({
      value: stateCode,
      label: `${stateCode}`,
    })),
  ];
  return (
    <SelectDropdown
      {...field}
      ref={ref}
      name={fieldAttrs.name}
      id={fieldAttrs.id}
      label={
        <span className="text-bold">
          {fieldAttrs.label} {fieldAttrs.required && <RequiredFieldIndicator />}
        </span>
      }
      onChange={(e) => {
        setValue(fieldAttrs.name, e.target.value);
        clearErrors(fieldAttrs.name);
      }}
      options={statesOptions}
      errorMessage={
        errors &&
        (!getValues(fieldAttrs.name) ||
          getValues(fieldAttrs.name) === '- Select -') &&
        errors[fieldAttrs.name]
          ? errors[fieldAttrs.name].message
          : null
      }
      aria-invalid={errors && errors[fieldAttrs.name] ? 'true' : 'false'}
    />
  );
};

WrappedStateSelector.defaultProps = {
  errors: null,
};

WrappedStateSelector.propTypes = {
  clearErrors: PropTypes.func.isRequired,
  errors: PropTypes.any,
  field: PropTypes.any.isRequired,
  fieldAttrs: PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    required: PropTypes.string,
  }).isRequired,
  getValues: PropTypes.func.isRequired,
  ref: PropTypes.any.isRequired,
  setValue: PropTypes.func.isRequired,
};

export const BackupDispatcherSelector = ({
  fieldAttrs,
  setValue,
  field,
  ref,
}) => {
  const [selectedDispatchers, setSelectedDispatchers] = useState([]);

  const {
    motorPoolListingModalMode,
    motorPoolListingSelected,
    dispatchers,
    getDispatchers,
  } = useMotorPool();

  useEffect(() => {
    setSelectedDispatchers(field.value || []);
  }, [field.value]);

  useEffect(() => {
    if (motorPoolListingModalMode === 'UPDATE_MOTOR_POOL_LISTING') {
      const formattedSelectedDispatchers =
        motorPoolListingSelected?.members?.reduce(
          (acc, { member, isDispatcher, isPrimaryDispatcher }) => {
            if (member?.id && !isPrimaryDispatcher && isDispatcher)
              acc.push(member.id);
            return acc;
          },
          [],
        );
      setSelectedDispatchers(formattedSelectedDispatchers || []);
      setValue(fieldAttrs.name, formattedSelectedDispatchers);
    }
  }, [dispatchers]);

  const canPerformActions = useCanPerformActions();
  useEffect(() => {
    if (!canPerformActions.canEditMotorPoolAgency()) getDispatchers();
  }, []);

  return (
    <>
      <label className="usa-label">
        <span className="text-bold">
          {fieldAttrs.label} {fieldAttrs.required && <RequiredFieldIndicator />}
        </span>
      </label>
      <MultiSelectDropdown
        {...field}
        ref={ref}
        name={fieldAttrs.name}
        id={fieldAttrs.id}
        placeholder="Select dispatcher"
        onChange={(items) => {
          setValue(fieldAttrs.name, items);
        }}
        options={dispatchers}
        selectedValues={selectedDispatchers}
      />
    </>
  );
};

BackupDispatcherSelector.propTypes = {
  field: PropTypes.any.isRequired,
  fieldAttrs: PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    required: PropTypes.string,
  }).isRequired,
  ref: PropTypes.any.isRequired,
  setValue: PropTypes.func.isRequired,
};

export const AgencySelector = ({
  fieldAttrs,
  setValue,
  errors,
  clearErrors,
  field,
  ref,
}) => {
  const { getAgenciesByPermission, agencies } = useMotorPool();

  const canPerformActions = useCanPerformActions();
  useEffect(() => {
    if (canPerformActions.canEditMotorPoolAgency())
      getAgenciesByPermission({
        variables: {
          operation: VMSOperations.View,
          subject: VMSSubjects.MOTOR_POOL,
          order: 'id ASC',
        },
      });
  }, [canPerformActions.canEditMotorPoolAgency()]);

  const getOptions = () => {
    if (canPerformActions.canEditMotorPoolAgency() && agencies.length > 0)
      return [
        { value: '', label: '- Select -' },
        ...agencies.map(({ id, name }) => {
          return { value: id, label: `${id} - ${name}` };
        }),
      ];
    return [{ value: '', label: '- Select -' }];
  };

  return (
    <SelectDropdown
      {...field}
      ref={ref}
      name={fieldAttrs.name}
      id={fieldAttrs.id}
      label={
        <span className="text-bold">
          {fieldAttrs.label} {fieldAttrs.required && <RequiredFieldIndicator />}
        </span>
      }
      className="grid-col-6"
      options={getOptions()}
      onChange={(e) => {
        setValue(fieldAttrs.name, e.target.value);
        clearErrors(fieldAttrs.name);
      }}
      errorMessage={
        errors && errors[fieldAttrs.name]
          ? errors[fieldAttrs.name].message
          : null
      }
      aria-invalid={errors && errors[fieldAttrs.name] ? 'true' : 'false'}
    />
  );
};

AgencySelector.defaultProps = {
  errors: null,
};

AgencySelector.propTypes = {
  clearErrors: PropTypes.func.isRequired,
  errors: PropTypes.any,
  field: PropTypes.any.isRequired,
  fieldAttrs: PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    required: PropTypes.string,
  }).isRequired,
  ref: PropTypes.any.isRequired,
  setValue: PropTypes.func.isRequired,
};

export const PrimaryDispatcherSelector = ({
  fieldAttrs,
  setValue,
  errors,
  field,
  ref,
  clearErrors,
}) => {
  const {
    primaryDispatchers,
    motorPoolListingModalMode,
    motorPoolListingSelected,
  } = useMotorPool();

  useEffect(() => {
    if (motorPoolListingModalMode === 'UPDATE_MOTOR_POOL_LISTING') {
      setValue(
        fieldAttrs.name,
        motorPoolListingSelected.poolPrimaryDispatcher?.member.id,
      );
    }
  }, [primaryDispatchers]);

  return (
    <>
      <SelectDropdown
        {...field}
        ref={ref}
        name={fieldAttrs.name}
        id={fieldAttrs.id}
        label={
          <span className="text-bold">
            {fieldAttrs.label}{' '}
            {fieldAttrs.required && <RequiredFieldIndicator />}
          </span>
        }
        className="grid-col-6"
        options={[{ value: '', label: '- Select -' }, ...primaryDispatchers]}
        onChange={(e) => {
          setValue(fieldAttrs.name, e.target.value);
          clearErrors(fieldAttrs.name);
        }}
        errorMessage={
          errors && errors[fieldAttrs.name]
            ? errors[fieldAttrs.name].message
            : null
        }
        aria-invalid={errors && errors[fieldAttrs.name] ? 'true' : 'false'}
      />
    </>
  );
};

PrimaryDispatcherSelector.defaultProps = {
  errors: null,
};

PrimaryDispatcherSelector.propTypes = {
  clearErrors: PropTypes.func.isRequired,
  errors: PropTypes.any,
  field: PropTypes.any.isRequired,
  fieldAttrs: PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    required: PropTypes.string,
  }).isRequired,
  ref: PropTypes.any.isRequired,
  setValue: PropTypes.func.isRequired,
};

export const TimezoneSelector = ({
  fieldAttrs,
  setValue,
  field,
  errors,
  ref,
}) => {
  const [formattedMotorPoolTimezones, setFormattedMotorPoolTimezones] =
    useState([]);
  const { selectedMotorPoolById, motorPoolTimezones, getMotorPoolTimezones } =
    useMotorPool();

  useEffect(() => {
    getMotorPoolTimezones();
  }, []);

  useEffect(() => {
    setFormattedMotorPoolTimezones([
      { value: '', label: '- Select -' },
      ...(motorPoolTimezones.length > 0
        ? motorPoolTimezones.map((tz) => {
            return {
              label: `${tz.name} (${tz.standardCode})`,
              value: tz.standardCode,
            };
          })
        : []),
    ]);
  }, [motorPoolTimezones]);

  if (fieldAttrs?.mode === 'motorPoolReservation') {
    return (
      <>
        <div className="text-bold margin-top-2">{fieldAttrs?.label}</div>
        {selectedMotorPoolById[fieldAttrs?.name] || '—'}
      </>
    );
  }

  return (
    <>
      <SelectDropdown
        {...field}
        ref={ref}
        name={fieldAttrs.name}
        id={fieldAttrs.id}
        label={
          <span className="text-bold">
            {fieldAttrs.label}{' '}
            {fieldAttrs.required && <RequiredFieldIndicator />}
          </span>
        }
        onChange={(evt) => {
          setValue(fieldAttrs.name, evt.target.value);
        }}
        options={formattedMotorPoolTimezones}
        errorMessage={
          errors && errors[fieldAttrs.name]
            ? errors[fieldAttrs.name].message
            : null
        }
        aria-invalid={errors && errors[fieldAttrs.name] ? 'true' : 'false'}
      />
    </>
  );
};

TimezoneSelector.defaultProps = {
  errors: null,
};

TimezoneSelector.propTypes = {
  field: PropTypes.any.isRequired,
  fieldAttrs: PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    required: PropTypes.string,
    mode: PropTypes.string,
  }).isRequired,
  ref: PropTypes.any.isRequired,
  setValue: PropTypes.func.isRequired,
  errors: PropTypes.any,
};
