export const constructOrderPoc = (poc) => {
  const {
    pocName,
    pocEmail,
    address1,
    address2,
    address3,
    address4,
    city,
    state,
    zip,
    pocPhone,
  } = poc;

  return {
    pocName,
    pocEmail,
    address1,
    address2,
    address3,
    address4,
    city,
    state,
    zip,
    pocPhone,
  };
};
