import { nullForForm } from '../../../utilities/common';
import { validEmail } from '../../../utilities/validation';

export const setWindowLocation = (url) => {
  window.location.href = url;
};

export const formatAddressForValidation = (poc) => {
  if (!poc) {
    return {};
  }
  const {
    primaryAddress,
    secondaryAddress,
    city,
    stateCode,
    postalCode,
    countryCode,
    ind,
  } = poc;

  return {
    address: nullForForm(primaryAddress),
    address2: nullForForm(secondaryAddress),
    city: nullForForm(city),
    state: nullForForm(stateCode),
    zip: nullForForm(postalCode),
    countryCode: nullForForm(countryCode),
    ind,
  };
};

export const formatAddressForSubmission = (poc) => {
  if (!poc) {
    return {};
  }
  const { address, address2, city, state, zip, countryCode, ind } = poc;
  return {
    primaryAddress: address,
    secondaryAddress: address2,
    city,
    stateCode: state,
    postalCode: zip,
    countryCode,
    ind,
  };
};

export const isEmailDifferent = (newEmail, otherEmail) => {
  if (!newEmail || !newEmail.trim() || !otherEmail || !otherEmail.trim) {
    return true;
  }
  const sanitizedNewEmail = newEmail.toLowerCase().trim();
  const sanitizedOtherEmail = otherEmail.toLowerCase().trim();
  return !(sanitizedOtherEmail === sanitizedNewEmail);
};

export const checkOtherPoCEmail = (
  primaryPoCEmail,
  secondaryPoCEmail,
  editPocEmail,
) => {
  const otherPocEmail =
    editPocEmail?.ind === 1 ? secondaryPoCEmail : primaryPoCEmail;
  return !validEmail(otherPocEmail);
};
