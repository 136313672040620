import React from 'react';
import { Route } from 'react-router-dom';
import useAlertManager from 'utilities/alert-manager';
import { LoadingQueryResult } from 'components/loading-query-result/loading-query-result';
import Header from './widgets/header';
import Tabs from './license-plate-tabs';
import LicensePlateContextProvider from './context';
import LicensePlateDestructionContextProvider from '../license-plate-destruction/context';
import useLicensePlateManager from './helpers/use-license-plate-manager';
import useLpDisassociationManager from '../license-plate-details/helpers/lp-disassociation-manager';
import useLpReconciliationManager from '../license-plate-details/helpers/lp-reconciliation-manager';
import LicensePlateDestruction from '../license-plate-destruction';
import useLicensePlateDestructionManager from './widgets/helpers/license-plate-destruction-manager';

const LicensePlate = ({ match }) => {
  const [Alert, alertUtils] = useAlertManager(true, true, true, 'margin-top-2');

  const {
    appliedFilters,
    setAppliedFilters,
    licensePlates,
    setLicensePlates,
    filters,
    setFilters,
    limit,
    setLimit,
    offset,
    setOffset,
    sortOrder,
    setSortOrder,
    selectedRows,
    setSelectedRows,
    loading,
    nestedLicensePlateError,
    filterStructure,
    refetchNestedLicensePlates,
  } = useLicensePlateManager(alertUtils);
  const {
    disassociateTag,
    disassociationAlert,
    disassociationPayload,
    setDisassociationPayload,
  } = useLpDisassociationManager();
  const {
    reconcileTag,
    reconciliationAlert,
    reconciliationPayload,
    setReconciliationPayload,
  } = useLpReconciliationManager();
  const {
    forDestructionList,
    setForDestructionList,
    setEligibleForDestruction,
    getIneligiblePlateDistribution,
    ineligibleDestructionList,
    destructionInitData,
    setDestructionInitData,
    toggleDestructionEditing,
    destructionEditing,
    saveDestruction,
    onGetTagsByExpirationDateLoading,
  } = useLicensePlateDestructionManager();

  return (
    <article>
      <LicensePlateContextProvider
        appliedFilters={appliedFilters}
        setAppliedFilters={setAppliedFilters}
        licensePlates={licensePlates}
        setLicensePlates={setLicensePlates}
        filters={filters}
        setFilters={setFilters}
        limit={limit}
        setLimit={setLimit}
        offset={offset}
        setOffset={setOffset}
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        loading={loading}
        nestedLicensePlateError={nestedLicensePlateError}
        filterStructure={filterStructure}
        refetchLicensePlates={refetchNestedLicensePlates}
        disassociateTag={disassociateTag}
        disassociationAlert={disassociationAlert}
        disassociationPayload={disassociationPayload}
        setDisassociationPayload={setDisassociationPayload}
        reconcileTag={reconcileTag}
        reconciliationAlert={reconciliationAlert}
        reconciliationPayload={reconciliationPayload}
        setReconciliationPayload={setReconciliationPayload}
      >
        <LicensePlateDestructionContextProvider
          forDestructionList={forDestructionList}
          setForDestructionList={setForDestructionList}
          setEligibleForDestruction={setEligibleForDestruction}
          getIneligiblePlateDistribution={getIneligiblePlateDistribution}
          ineligibleDestructionList={ineligibleDestructionList}
          setDestructionInitData={setDestructionInitData}
          destructionInitData={destructionInitData}
          destructionEditing={destructionEditing}
          toggleDestructionEditing={toggleDestructionEditing}
          onGetTagsByExpirationDateLoading={onGetTagsByExpirationDateLoading}
          saveDestruction={saveDestruction}
        >
          <Route
            exact
            path={`${match.url}`}
            render={() => {
              return (
                <>
                  <Header Alert={Alert} />
                  <Tabs
                    filters={filters}
                    sortOrder={sortOrder}
                    alertUtils={alertUtils}
                  />
                </>
              );
            }}
          />
          <Route
            exact
            path={`${match.url}/destruction`}
            render={() => {
              return (
                <LicensePlateDestruction
                  setSelectedRows={setSelectedRows}
                  returnRoute={`${match.url}`}
                />
              );
            }}
          />
        </LicensePlateDestructionContextProvider>
      </LicensePlateContextProvider>
    </article>
  );
};

export default LicensePlate;
