/* eslint-disable react/prop-types */
/* eslint-disable filenames/match-exported */

import React from 'react';
import { SelectDropdown } from '@gsa/afp-component-library';
import classnames from 'classnames';

import { useRecoilState, useSetRecoilState } from 'recoil';

const SelectDropDownField = ({
  pageId = 'DEFAULT',
  selectors,
  id,
  dependency = null,
  required = false,
  disabled,
  label,
}) => {
  const { fieldSelector, fieldAfterSelector = null } = selectors;
  const [fieldValueState, setFieldValueState] = useRecoilState(
    fieldSelector({ pageId, id }),
  );
  let afterFieldUpdated = ({ value }) => value;
  if (fieldAfterSelector) {
    afterFieldUpdated = useSetRecoilState(fieldAfterSelector({ pageId, id }));
  }

  React.useEffect(() => {
    if (!fieldValueState?.options) {
      console.log(`${id} have not set options`);
    }
  }, []);

  return (
    <div
      className={classnames(fieldValueState.errorMessage && 'margin-left-205')}
    >
      <SelectDropdown
        label={label}
        name={id}
        id={id}
        options={fieldValueState?.options ?? []}
        value={fieldValueState.value}
        onChange={(e) => {
          setFieldValueState({ value: e.target.value, dependency });
        }}
        disabled={fieldValueState?.disabled || disabled}
        errorMessage={fieldValueState?.errorMessage}
        required={required}
        onBlur={(e) => {
          afterFieldUpdated({ value: e.target.value });
        }}
      />
    </div>
  );
};

export default SelectDropDownField;
