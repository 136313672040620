import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from '@gsa/afp-component-library';
import { useFcReplacement } from './fc-replacement-context-provider';

const CancelReplacementCardModal = ({ closeModal, id, isOpen }) => {
  const { updateReplacementOrder } = useFcReplacement();

  const cancelReplacementCard = () => {
    closeModal();
    updateReplacementOrder({
      variables: {
        fleetCardReplacementOrderInput: {
          orderId: id,
          status: 'Canceled',
        },
      },
    });
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="afp-modal-wrapper">
      <div className="afp-modal-overlay">
        <Modal
          id="delete-repair-order-modal"
          variant="large"
          title={<h2>Are you sure you want to cancel your card order?</h2>}
          onClose={closeModal}
          actions={
            <>
              <Button
                variant="unstyled"
                onClick={closeModal}
                label="Return"
                data-testid="abort-replacement-card-button"
              />
              <Button
                variant="secondary"
                onClick={cancelReplacementCard}
                form="replacement-card-form"
                label="Cancel order"
                data-testid="cancel-replacement-card-button"
              />
            </>
          }
        >
          <p>If you cancel now, you will lose your progress.</p>
        </Modal>
      </div>
    </div>
  );
};

export default CancelReplacementCardModal;

CancelReplacementCardModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
};
