import React, { useEffect, useMemo } from 'react';
import { Redirect, Link } from 'react-router-dom';
import {
  Breadcrumbs,
  PageTitle,
  Spinner,
  FilterTableFrame,
} from '@gsa/afp-component-library';
import FilterUpRightActions from 'utilities/filter-up-right-actions';
import {
  RecallFilterProvider,
  useRecallFilter,
  RecallPageProvider,
} from './providers';
import { Sidebar, RecallTable, BannerMessage } from './widgets';
import useDataController from './use-data-controller';

const title = 'Vehicle Recalls';
const trail = [
  <a href={`${window.AFP_CONFIG.appURLs.home}/home`}>Home</a>,
  <Link to="/recalls">Recalls</Link>,
];

const Page = () => {
  const { agencies, getAgencies, filterStructure } = useRecallFilter();
  useEffect(() => {
    getAgencies();
  }, []);
  const FilterGrid = useMemo(
    () => FilterTableFrame(null, FilterUpRightActions, Sidebar, RecallTable),
    [],
  );

  const { onExport } = useDataController();

  if (agencies === undefined) {
    return <Spinner size="small" data-testid="recall-spinner" />;
  }
  if (agencies.length === 0) {
    return <Redirect to="/not-opted" />;
  }

  return (
    <article>
      <header>
        <Breadcrumbs trail={trail} current={title} />
        <BannerMessage />
        <PageTitle title={title} />

        <div className="margin-bottom-4 tablet:grid-col-7">
          <p>
            Use this feature to track vehicles with open safety recalls and view
            history of your closed recalls. This page will not contain extended
            warranty bulletins or technical safety bulletins.
          </p>
          <p>
            There may be a delay with recently announced safety recalls for
            which not all VINs have been identified by the manufacturer. VINs
            are added continuously so you may want to check back often. Only a
            manufacturer can close a recall. For GSA Fleet-leased vehicles, if
            you would like to mark the recall addressed (closed by customer),
            please contact your local Fleet Service Representative, providing
            them with the invoice showing recall completion. For Agency-owned
            vehicles, you can mark them addressed (closed by customer) on the
            Recalls tab of the Vehicle Details page for the vehicle that has
            received the remediation.
          </p>
          <p>
            For more information, please see the vehicle recalls{' '}
            <a
              href={`${process.env.PUBLIC_URL}/GSAFleet_gov-Recalls-User-Guide.pdf`}
              className="mobile:display-block"
              data-testid="recalls-user-guide"
              target="_blank"
              rel="noopener noreferrer"
            >
              user guide
            </a>{' '}
            and{' '}
            <a
              href="https://vimeo.com/gsavisualcommunications/review/906419962/b66d65abd5"
              className="mobile:display-block"
              data-testid="recalls-user-video"
              target="_blank"
              rel="noopener noreferrer"
            >
              How to
            </a>{' '}
            video.
          </p>
        </div>
      </header>
      {filterStructure ? (
        <FilterGrid
          filterToggle
          upRightProps={{
            DropDownItems: [
              {
                label: 'Export data',
                iconName: 'file_download',
                onClick: onExport,
              },
            ],
          }}
        />
      ) : (
        <Spinner size="medium" data-testid="recall-spinner" />
      )}
    </article>
  );
};

const VehicleRecalls = () => {
  return (
    <RecallFilterProvider>
      <RecallPageProvider>
        <Page />
      </RecallPageProvider>
    </RecallFilterProvider>
  );
};

export default VehicleRecalls;
