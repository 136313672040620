// eslint-disable-next-line filenames/match-exported, import/prefer-default-export
export const mileageMapping = [
  ['License Plate', 'tagNumber', 'string'],
  ['VIN', 'vin', 'string'],
  ['Mileage Transaction Date', 'mileageDate', 'string'],
  ['Odometer Reading', 'odometer', 'string'],
  ['Days Used', 'daysInUse', 'string'],
];

export const mileageLeaseMapping = [
  ['License Plate', 'tagNumber', 'string'],
  ['VIN', 'vin', 'string'],
  ['Customer Account Name', '', 'readonly'],
  ['BOAC', 'boac', 'string'],
  ['Mileage Status', '', 'readonly'],
  ['Fund Code', 'fundCode', 'string'],
  ['Description 1', 'account1', 'string'],
  ['Description 2', 'account2', 'string'],
  ['Ending Mileage', 'odometer', 'string'],
  ['Days Used', 'daysInUse', 'string'],
];

export const mileageLeaseTxtMapping = [
  ['tagNumber', 'tagNumber', 'string'],
  ['odometer', 'odometer', 'string'],
  ['mileageDateTxt', 'mileageDateTxt', 'string'],
  ['rowLength', 'rowLength', 'string'],
];

export const mileageLeaseTxtValuePositions = (lines) => {
  /* txt file format:
    1-3: 32M (always the same)
    26-28: License Class
    30-34: License Tag
    37-42: Mileage
    43-44: Region
    45-48: Date(MMYY)
  */
  return lines.map((line) => {
    const licenseClass = line.substring(25, 28).trim();
    const licenseTag = line.substring(29, 34).trim();
    const odometer = line.substring(36, 42).trim();
    const date = line.substring(44, 48).trim();
    return {
      tagNumber: `${licenseClass}${licenseTag}`,
      odometer,
      mileageDateTxt: date,
      rowLength: line?.length?.toString(), // used to validate the length of the line
    };
  });
};
