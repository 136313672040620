import React from 'react';
import { useRegistration } from './reg-context-provider';
import VehicleInformation from './widgets/vehicle-information';
import AgencyAndPoc from './widgets/agency-and-poc/agency-and-poc';
import ReviewAndRegister from './widgets/review-and-register/review-and-register';

export default function RegistrationForms() {
  const { step } = useRegistration();

  switch (step) {
    case 1:
      return <VehicleInformation />;
    case 2:
      return <AgencyAndPoc />;
    case 3:
      return <ReviewAndRegister />;
    default:
      return <VehicleInformation />;
  }
}
