import React, { useEffect, useReducer } from 'react';
import usePortalModal from 'utilities/portal-modal';
import {
  Button,
  TextInput,
  RequiredFieldIndicator,
  Label,
  SelectDropdown,
} from '@gsa/afp-component-library';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { DEFAULT_SELECT_OPTION_LABEL } from 'utilities/consts';
import { SPEED_RATINGS } from './speed-ratings';

const EditWheelsAndTires = ({ vehicle, onSave, onClose }) => {
  const formReducer = (state, newState) => {
    return {
      ...state,
      ...newState,
    };
  };

  const [state, dispatch] = useReducer(formReducer, {
    tireSize: vehicle?.assetTechnical?.tireSize,
    tireLoadRange: vehicle?.assetTechnical?.tireLoadRange,
    tireQuantity: vehicle?.assetTechnical?.tireQuantity?.toString(),
    tireSpeedRating: vehicle?.assetTechnical?.tireSpeedRating,
  });

  const [TiresEditModal, openModal, closeModal] = usePortalModal();
  useEffect(() => {
    openModal();
  }, []);

  const close = () => {
    // eslint-disable-next-line no-unused-expressions
    onClose && onClose();
    closeModal();
  };

  const handleTextInputChange = (e, field) => {
    dispatch({
      [field]: e.target.value,
    });
  };

  const setTireSpeedRating = (e) => {
    dispatch({
      tireSpeedRating: e.target.value,
    });
  };

  const tireSize = ({ handleChange, value }) => {
    return (
      <>
        <TextInput
          maxLength={15}
          label="Tire size"
          type="input"
          value={value}
          onChange={(e) => handleChange(e, 'tireSize')}
          data-testid="wheels-and-tires-tire-size"
        />
      </>
    );
  };

  const tireLoadRange = ({ handleChange, value }) => {
    return (
      <>
        <TextInput
          maxLength={20}
          label="Tire load index"
          type="input"
          value={value}
          onChange={(e) => handleChange(e, 'tireLoadRange')}
          data-testid="wheels-and-tires-tire-load-range"
        />
      </>
    );
  };

  //   tire lookup Dropdown
  const tireSpeedRatingLookup = (props) => {
    return (
      <SelectDropdown
        id="tireSpeedRating"
        data-testid="tireSpeedRating"
        label="Tire speed rating"
        name="tireSpeedRating"
        options={[
          { value: '', label: DEFAULT_SELECT_OPTION_LABEL },
          ...(props.options || []),
        ]}
        value={props.value}
        onChange={props.handleChange}
      />
    );
  };

  const tireSpeedRating = ({ handleChange, value }) => {
    return (
      <>
        {tireSpeedRatingLookup({
          options: SPEED_RATINGS?.map((opt) => ({
            label: opt.description,
            value: opt.value,
          })),
          value: value,
          handleChange: setTireSpeedRating,
        })}
      </>
    );
  };

  const tireQuantity = ({ handleChange, value }) => {
    return (
      <>
        <Label>
          <span className="text-bold">Tires in use</span>
        </Label>
        <NumberFormat
          maxLength={2}
          className="usa-input"
          displayType="input"
          type="text"
          allowNegative={false}
          label="Tires in use"
          value={value}
          inputMode="numeric"
          pattern="[0-9]*"
          onChange={(e) => handleChange(e, 'tireQuantity')}
          data-testid="wheels-and-tires-tire-quantity"
        />
      </>
    );
  };

  const saveWheelsAndTires = (e) => {
    closeModal();
    onSave(e);
  };

  return (
    <TiresEditModal
      actions={
        <>
          <Button
            data-testid="wheels-and-tires-close"
            variant="unstyled"
            label="Cancel"
            onClick={close}
          />
          <Button
            className="margin-left-2"
            onClick={() => saveWheelsAndTires(state)}
            label="Save and close"
            data-testid="wheels-and-tires-save-and-close"
          />
        </>
      }
      title={<h2>Edit wheels and tires information</h2>}
      onClose={close}
    >
      <div className="grid-col-12 grid-gap">
        <p>
          Edit wheels and tires information for VIN{' '}
          <strong>{vehicle?.id}</strong> in the form below.
        </p>
        <p>
          Required fields are marked with an asterisk (
          <RequiredFieldIndicator />
          ).
        </p>
      </div>
      <div className="grid-row grid-gap">
        <div className="grid-col-6">
          {tireSize({
            value: state.tireSize,
            handleChange: handleTextInputChange,
          })}
        </div>
        <div className="grid-col-6">
          {tireLoadRange({
            value: state.tireLoadRange,
            handleChange: handleTextInputChange,
          })}
        </div>
      </div>
      <div className="grid-row grid-gap">
        <div className="grid-col-6">
          {tireSpeedRating({
            value: state.tireSpeedRating,
            handleChange: handleTextInputChange,
          })}
        </div>
        <div className="grid-col-6">
          {tireQuantity({
            value: state.tireQuantity,
            handleChange: handleTextInputChange,
          })}
        </div>
      </div>
    </TiresEditModal>
  );
};

export default EditWheelsAndTires;

EditWheelsAndTires.propTypes = {
  vehicle: PropTypes.shape({
    id: PropTypes.string,
    assetTechnical: PropTypes.shape({
      assetTechnicalIce: PropTypes.shape({
        cylinders: PropTypes.string,
        transmission: PropTypes.string,
        tankSize: PropTypes.number,
        mpgCity: PropTypes.number,
        mpgHighway: PropTypes.number,
        mpgCombined: PropTypes.number,
        flexFuelMpgCity: PropTypes.number,
        flexFuelMpgHighway: PropTypes.number,
        flexFuelMpgCombined: PropTypes.number,
        gramPerMile: PropTypes.number,
        flexFuelGramPerMile: PropTypes.number,
      }),
    }),
    tagNumber: PropTypes.string,
    fuel: PropTypes.shape({
      description: PropTypes.string,
    }),
    modelYear: PropTypes.number,
    fuelCode: PropTypes.string,
    fastReportable: PropTypes.bool,
    makeColor: PropTypes.shape({
      name: PropTypes.string,
    }),
    vehicleModel: PropTypes.shape({
      modelDescription: PropTypes.string,
    }),
    vehicleMake: PropTypes.shape({
      makeName: PropTypes.string,
    }),
    tag: PropTypes.shape({
      expirationDate: PropTypes.number,
    }),
    inventoryStatus: PropTypes.shape({
      id: PropTypes.string,
      description: PropTypes.string,
    }),
  }),
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};
