/* eslint-disable filenames/match-exported */
import React from 'react';
import { RequiredFieldIndicator, TextInput } from '@gsa/afp-component-library';
import { Controller, useFormContext } from 'react-hook-form';

// eslint-disable-next-line react/prop-types
const MotorPoolAddEditFields = ({ providerContext, formSchema }) => {
  const {
    getValues,
    clearErrors,
    setValue,
    formState: { errors },
    control,
    setError,
  } = useFormContext();

  return (
    <div>
      {/* eslint-disable-next-line react/prop-types */}
      {Object.values(formSchema.formLayout).map((mpField) => {
        return (
          <div key={mpField.name} className={mpField.wrapperClass || ''}>
            <Controller
              name={mpField.name}
              control={control}
              render={({ field, ref }) => {
                return mpField.component ? (
                  <mpField.component
                    fieldAttrs={mpField}
                    getValues={getValues}
                    setValue={setValue}
                    errors={errors}
                    clearErrors={clearErrors}
                    field={field}
                    ref={ref}
                    setError={setError}
                    providerContext={providerContext}
                  />
                ) : (
                  <TextInput
                    {...field}
                    ref={ref}
                    name={mpField.name}
                    id={mpField.id}
                    type="text"
                    {...(mpField.additionalAttributes || {})}
                    label={
                      <span className="text-bold">
                        {mpField.label}{' '}
                        {mpField.required && <RequiredFieldIndicator />}
                      </span>
                    }
                    errorMessage={
                      errors && errors[mpField.name]
                        ? errors[mpField.name].message
                        : null
                    }
                    aria-invalid={
                      errors && errors[mpField.name] ? 'true' : 'false'
                    }
                  />
                );
              }}
            />
          </div>
        );
      })}
    </div>
  );
};

export default MotorPoolAddEditFields;
