/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import {
  Button,
  Modal,
  Alert,
  Spinner,
  RequiredFieldIndicator,
  TextInput,
} from '@gsa/afp-component-library';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import { canUpdateGFVehicleFSR } from 'utilities/authorization';
import { useVehicle } from 'components/vehicle-details/vehicle-context-provider';
import moment from 'moment';
import { omit, isEmpty } from 'lodash';
import { useMileageHistoryGF } from '../mileage-history-gf-provider';
import {
  isMileageBetweenAllowedThreshold,
  isMileageReportedForThisMonth,
} from '../utils';

const numericRegExp = /^(\s*|\d+)$/;
const allowedThreshold = 9999;

const isValidNumber = (value) => {
  return value === '' || numericRegExp.test(value);
};

const ManageGFMileageModal = () => {
  const ability = useAppAbility();
  const canUpdateGFVehicleFSRUser = canUpdateGFVehicleFSR(ability);

  const [odometer, setOdometer] = useState();
  const [daysUsed, setDaysUsed] = useState();
  const [errors, setErrors] = useState({});

  const { vehicle } = useVehicle();

  const {
    closeManageGFMileageModal,
    alertMessage,
    addingMileageForGFVehicle,
    addMileageLogForGFVehicles,
    mileageHistory,
    selectedMileageHistoryRecord,
    dispatchAction,
    updateMileageForGFVehicle,
  } = useMileageHistoryGF();

  useEffect(() => {
    if (selectedMileageHistoryRecord) {
      setOdometer(selectedMileageHistoryRecord?.odometer);
      setDaysUsed(selectedMileageHistoryRecord?.daysInUse);
    }
  }, [selectedMileageHistoryRecord]);

  const onCloseModal = () => {
    dispatchAction('SET_SELECTED_MILEAGE_HISTORY_RECORD', undefined);
    dispatchAction('SET_ALERT_MESSAGE', {
      type: '',
      message: '',
      location: 'manageGFMileageModal',
    });
    closeManageGFMileageModal();
  };

  const isMileageReported = (assetMileageReportingStatus) => {
    // check if mileage has been reported for this month. only check for report mode, not edit mode
    return (
      !selectedMileageHistoryRecord &&
      isMileageReportedForThisMonth(assetMileageReportingStatus)
    );
  };

  const addGFMileage = () => {
    const payload = {
      assetId: vehicle?.uuid,
      assetMileageSourceId: 4, // mileage reporting
      odometer: Number(odometer),
      daysInUse: Number(daysUsed),
    };
    addMileageLogForGFVehicles({
      variables: {
        mileageGFFields: payload,
      },
    });
  };

  const editGFMileage = () => {
    const payload = {
      id:
        selectedMileageHistoryRecord?.status === 'Pending'
          ? selectedMileageHistoryRecord?.assetMileageLogId
          : selectedMileageHistoryRecord?.assetMileageId,
      assetId: vehicle?.uuid,
      sourceType: selectedMileageHistoryRecord?.assetMileageSourceTypeId,
      odometer: Number(odometer),
      daysInUse: Number(daysUsed),
      status: selectedMileageHistoryRecord?.status,
    };
    updateMileageForGFVehicle({
      variables: {
        updateMileageGFFields: payload,
      },
    });
  };

  const validateOdometer = () => {
    if (!odometer) {
      setErrors({
        ...errors,
        odometer: 'Odometer is a required field.',
      });
      return;
    }
    if (Number(odometer) <= 0) {
      setErrors({
        ...errors,
        odometer: 'Odometer must be greater than 0.',
      });
      return;
    }
    if (!isValidNumber(odometer)) {
      setErrors({
        ...errors,
        odometer: 'Odometer must be a number.',
      });
      return;
    }
    if (
      odometer &&
      Number(odometer) > 0 &&
      !canUpdateGFVehicleFSRUser &&
      !isMileageBetweenAllowedThreshold(
        allowedThreshold,
        mileageHistory,
        odometer,
      )
    ) {
      setErrors({
        ...errors,
        odometer:
          'Mileage cannot be +/- 9,999 from the previous mileage record.',
      });
      return;
    }
    setErrors(omit(errors, 'odometer'));
  };

  const validateDaysUsed = (selectedMileageDate) => {
    const daysInReportedMonth = moment(
      selectedMileageDate || new Date(),
    ).daysInMonth();
    if (daysUsed && !isValidNumber(daysUsed)) {
      setErrors({
        ...errors,
        daysUsed: 'Days used must be a number',
      });
      return;
    }
    if (daysUsed > daysInReportedMonth) {
      setErrors({
        ...errors,
        daysUsed:
          'Days used cannot exceed the number of days in the month reported.',
      });
      return;
    }
    setErrors(omit(errors, 'daysUsed'));
  };

  return (
    <div className="afp-modal-wrapper">
      <div className="afp-modal-overlay">
        <Modal
          title={<h2>Add vehicle mileage record</h2>}
          actions={
            <>
              <Button
                variant="unstyled"
                className="margin-right-2"
                onClick={onCloseModal}
                data-testid="motor-pool-listing-add-cancel-button"
                label="Cancel"
              />
              <Button
                variant="primary"
                type="submit"
                onClick={() => {
                  if (selectedMileageHistoryRecord) {
                    editGFMileage();
                  } else {
                    addGFMileage();
                  }
                }}
                data-testid="select-pm-schedule-submit-button"
                disabled={
                  !isEmpty(errors) ||
                  isMileageReported(vehicle?.assetMileageReportingStatus) ||
                  addingMileageForGFVehicle
                }
                label="Save and close"
              />
              {addingMileageForGFVehicle && (
                <Spinner
                  size="small"
                  className="display-inline-block margin-left-2"
                />
              )}
            </>
          }
          onClose={onCloseModal}
          variant="large"
        >
          {alertMessage?.location === 'manageGFMileageModal' &&
            alertMessage?.message && (
              <Alert type={alertMessage.type} slim>
                {alertMessage.message}
              </Alert>
            )}
          {isMileageReported(vehicle?.assetMileageReportingStatus) && (
            <Alert type="warning" slim>
              Mileage has been reported for this month.
            </Alert>
          )}
          {odometer &&
            Number(odometer) > 0 &&
            !isMileageBetweenAllowedThreshold(
              allowedThreshold,
              mileageHistory,
              odometer,
            ) && (
              <Alert
                type={canUpdateGFVehicleFSRUser ? 'warning' : 'error'}
                slim
              >
                {`Mileage ${
                  canUpdateGFVehicleFSRUser ? 'is' : 'cannot be'
                } +/- 9,999 from the previous mileage record. ${
                  canUpdateGFVehicleFSRUser
                    ? 'Verify mileage before saving.'
                    : ''
                }`}
              </Alert>
            )}
          <p>
            Add vehicle mileage record for VIN <strong>{vehicle.id}</strong> in
            the form below.
          </p>
          <p>
            Required fields are marked with an asterisk (
            <RequiredFieldIndicator />
            ).
          </p>
          <div className="grid-row">
            <div className="grid-col-5">
              <TextInput
                name="odometer"
                label={<span className="text-bold">Odometer reading</span>}
                required
                value={odometer}
                disabled={
                  isMileageReported(vehicle?.assetMileageReportingStatus) ||
                  selectedMileageHistoryRecord?.status === 'Reported' ||
                  selectedMileageHistoryRecord?.status === 'Telematics'
                }
                onChange={(e) => {
                  setOdometer(e.target.value);
                }}
                onBlur={() => {
                  validateOdometer();
                }}
                errorMessage={
                  errors && errors.odometer ? errors.odometer : null
                }
                aria-invalid={errors && errors.odometer ? 'true' : 'false'}
              />
            </div>
          </div>
          <div className="grid-row">
            <div className="grid-col-5">
              <TextInput
                label={<span className="text-bold">Days used</span>}
                name="daysUsed"
                value={daysUsed}
                disabled={isMileageReported(
                  vehicle?.assetMileageReportingStatus,
                )}
                onChange={(e) => {
                  setDaysUsed(e.target.value);
                }}
                onBlur={() =>
                  validateDaysUsed(
                    selectedMileageHistoryRecord
                      ? selectedMileageHistoryRecord?.mileageDate
                      : null,
                  )
                }
                errorMessage={
                  errors && errors.daysUsed ? errors.daysUsed : null
                }
                aria-invalid={errors && errors.daysUsed ? 'true' : 'false'}
              />
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default ManageGFMileageModal;
