import React, { useEffect } from 'react';
import {
  connectModal,
  Modal,
  useModal,
  Button,
  Label,
} from '@gsa/afp-component-library';

export default ({
  addressToValidate,
  error,
  onSavenClose,
  openEditAddress,
  setUserAddress,
}) => {
  const { isOpen, openModal, closeModal } = useModal();

  useEffect(() => {
    if (error && error.message.includes('UserInputError')) {
      openModal();
    }
  }, [error]);

  const close = () => {
    setUserAddress(null);
    closeModal();
  };

  const AddressCard = ({ children, selected, ...restProps }) => (
    <div
      className={`usa-card__container padding-x-1 margin-y-2 cursor-pointer ${
        selected ? 'bg-primary-lighter' : ''
      }`}
      {...restProps}
    >
      {children}
    </div>
  );

  const FormattedAddress = ({ address }) => {
    return (
      <div className="afp-formatted-address">
        <Label className="margin-bottom-05 margin-top-05">
          Use address as was entered:
        </Label>
        {address.address}
        <br />
        {address.address2 ? (
          <>
            {address.address2}
            <br />
          </>
        ) : (
          ''
        )}
        {address.city}, {address.state} {address.zip}
      </div>
    );
  };

  const ModalAlert = connectModal(() => {
    return (
      <Modal
        variant="large"
        title={
          <>
            <h2>Address not found</h2>
          </>
        }
        onClose={close}
        actions={
          <>
            <Button
              type="button"
              onClick={() => {
                closeModal();
                setUserAddress(null);
                if (openEditAddress) {
                  openEditAddress(true);
                }
              }}
              className="outline"
              label="Edit address"
            />
            <Button
              type="button"
              onClick={() => {
                onSavenClose();
                close();
              }}
              label="Save and close"
            />
          </>
        }
      >
        <div>
          <AddressCard selected={addressToValidate}>
            <div className="padding-left-4 padding-y-1">
              <FormattedAddress address={addressToValidate} />
            </div>
          </AddressCard>
        </div>
      </Modal>
    );
  });

  return (
    <div className="afp-registration__address_validation">
      <ModalAlert isOpen={isOpen} onClose={close} />
    </div>
  );
};
