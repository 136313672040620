import React, { useState, useEffect } from 'react';
import { Alert } from '@gsa/afp-component-library';
import PocSearch from './poc-search';
import ValidPocCard from './valid-poc-card';
import CreatePocModal from './create-poc-modal';
import registrationFormValidation from '../../../helpers/registration-form-validation';
import pocValidationRules from './poc-validation-rules';

export const EMPTY_POC = {
  email: null,
  firstName: null,
  lastName: null,
  primaryAddress: null,
  secondaryAddress: null,
  city: null,
  stateCode: null,
  countryCode: null,
  postalCode: null,
  primaryPhone: null,
  primaryPhoneExt: null,
  validated: false,
};

export default function Poc({
  pocNumber,
  label,
  pointOfContact,
  onValidPocChange,
  onEmailChange,
  validations,
  otherEmail,
  inputRef,
}) {
  const [poc, setPoc] = useState(pointOfContact || EMPTY_POC);
  const [showPocModal, setShowPocModal] = useState(null);
  const [genericValidationMsg, setGenericValidationMsg] = useState(null);
  const [genericMissingPocInfoError, setGenericMissingPocInfoError] =
    useState(null);
  useEffect(() => {
    if (pointOfContact) {
      setPoc(pointOfContact);
    }
  }, [pointOfContact]);

  useEffect(() => {
    const withErrMsg = Object.values(validations).some((msg) => !!msg);
    if (withErrMsg && validations.email) {
      setGenericValidationMsg(validations.email);
    }
  }, [validations]);

  const handleEmailChange = (e) => {
    const { value } = e.target;
    onEmailChange({ ...EMPTY_POC, email: value });
  };

  const handlePocChange = (newPoc) => {
    if (newPoc) {
      onValidPocChange({ ...newPoc, validated: true });
    } else {
      setShowPocModal(true);
    }
  };

  const handlePocRemove = () => {
    onValidPocChange(EMPTY_POC);
  };

  const handlePocEdit = () => {
    setShowPocModal(true);
  };

  const handlePocModalCancel = () => {
    setShowPocModal(false);
  };

  const handlePocModalSave = (newPoc) => {
    onValidPocChange({ ...newPoc });
    setShowPocModal(false);
  };

  useEffect(() => {
    const pocInfoValidities = registrationFormValidation(
      poc,
      pocValidationRules,
    );
    const hasErrMsg = Object.values(pocInfoValidities).some((msg) => !!msg);
    if (hasErrMsg && !pocInfoValidities.email && poc.validated) {
      setGenericMissingPocInfoError(
        'Point of contact is missing required info.',
      );
    } else {
      setGenericMissingPocInfoError(null);
    }
  }, [poc]);

  return (
    <div className="tablet:grid-col-6">
      <h3 className="title-s-caps text-primary">{label}</h3>
      {genericMissingPocInfoError && (
        <Alert type="error" slim>
          {genericMissingPocInfoError}
        </Alert>
      )}
      <PocSearch
        pocNumber={{ pocNumber }}
        data-testid={label}
        label="Email"
        value={poc.email}
        otherEmail={otherEmail}
        validated={poc.validated}
        onChange={handleEmailChange}
        onPocChange={handlePocChange}
        errorMessage={genericValidationMsg}
        required
        inputRef={inputRef}
      />
      <ValidPocCard
        data-testid={`valid card ${label}`}
        pointOfContact={poc}
        validated={poc.validated}
        onRemove={handlePocRemove}
        onEdit={handlePocEdit}
      />
      <CreatePocModal
        pointOfContact={poc}
        showPocModal={showPocModal}
        onClose={handlePocModalCancel}
        onCancel={handlePocModalCancel}
        onSave={handlePocModalSave}
      />
    </div>
  );
}
