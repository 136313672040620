/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Alert } from '@gsa/afp-component-library';
import { FormGenerator } from '@gsa/afp-shared-form-utils';
import { LIFECYCLE_UPDATE_TYPE } from 'utilities/consts';
import { updateVehicleStatusSchema } from './update-vehicle-status-schema';

const UpdateVehicleStatusForm = ({
  onSubmit,
  statusData,
  setStatusData,
  statusOptions,
  vehicle,
}) => {
  const updateVehicleStatusRef = useRef(null);

  useEffect(() => {
    if (!updateVehicleStatusRef.current) return () => {};
    const subscription = updateVehicleStatusRef.current.watch((data) => {
      if (data?.statusOprator && data?.newStatusValue) {
        setStatusData({
          operator: data.statusOprator,
          value: data.newStatusValue,
        });
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [updateVehicleStatusRef.current]);

  const [availableOptions, setAvailableOptions] = useState([]);
  const [isOptionListEmpty, setIsOptionListEmpty] = useState(false);

  useEffect(() => {
    const tempOptions = [];
    setAvailableOptions(tempOptions);
    setIsOptionListEmpty(false);

    let isVehicleAssigned = false;
    let isVehicleReadyForSale = false;
    const vehicleStatusList = [];
    vehicle.assetLifecycle.forEach((entry) => {
      const statusCode = parseInt(entry.lifeCycle.lifecycleIndicatorId, 10);
      if (statusCode === LIFECYCLE_UPDATE_TYPE.GF_ACTIVE_ASSIGNED) {
        isVehicleAssigned = true;
      }
      if (statusCode === LIFECYCLE_UPDATE_TYPE.GF_ACTIVE_READY_FOR_SALE) {
        isVehicleReadyForSale = true;
      }
      vehicleStatusList.push(statusCode);
    });

    if (statusData.operator === 'add') {
      statusOptions.every((entry) => {
        // if the vehicle has Assigned status, check the options
        const optionValue = parseInt(entry.value, 10);
        if (
          (optionValue ===
            LIFECYCLE_UPDATE_TYPE.GF_ACTIVE_TEMPORARY_INTERNAL_USE ||
            optionValue === LIFECYCLE_UPDATE_TYPE.GF_ACTIVE_READY_FOR_SALE ||
            optionValue === LIFECYCLE_UPDATE_TYPE.GF_ACTIVE_SPECIAL_DISPOSAL) &&
          isVehicleAssigned
        ) {
          return true;
        }

        // only show the status that's NOT on the list
        if (vehicleStatusList.indexOf(optionValue) === -1) {
          if (optionValue === LIFECYCLE_UPDATE_TYPE.GF_ACTIVE_UNASSIGNED) {
            if (isVehicleReadyForSale) tempOptions.push(entry);
          } else tempOptions.push(entry);
        }
        return true;
      });
    } else if (statusData.operator === 'remove') {
      if (
        vehicleStatusList.length === 1 &&
        vehicleStatusList?.[0] ===
          LIFECYCLE_UPDATE_TYPE.GF_ACTIVE_TEMPORARY_INTERNAL_USE
      ) {
        tempOptions.push(
          statusOptions.find(
            (entry) =>
              parseInt(entry.value, 10) ===
              LIFECYCLE_UPDATE_TYPE.GF_ACTIVE_TEMPORARY_INTERNAL_USE,
          ),
        );
      } else if (vehicleStatusList.length > 1) {
        statusOptions.every((entry) => {
          if (
            isVehicleReadyForSale &&
            parseInt(entry.value, 10) ===
              LIFECYCLE_UPDATE_TYPE.GF_ACTIVE_READY_FOR_SALE
          ) {
            return true;
          }
          if (
            parseInt(entry.value, 10) ===
              LIFECYCLE_UPDATE_TYPE.GF_ACTIVE_UNASSIGNED ||
            parseInt(entry.value, 10) ===
              LIFECYCLE_UPDATE_TYPE.GF_MISSING_STOLEN_UNASSIGNED
          ) {
            return true;
          }
          if (vehicleStatusList.indexOf(parseInt(entry.value, 10)) !== -1) {
            // only show the status that's on the list
            tempOptions.push(entry);
          }
          return true;
        });
      }
    }
    if (tempOptions.length) {
      tempOptions.unshift({ value: '0', label: '-Select-' });
      setAvailableOptions(tempOptions);
    } else {
      setIsOptionListEmpty(true);
    }
    setStatusData({
      operator: statusData.operator,
      value: '0',
    });
  }, [statusData.operator]);

  const RenderForm = () => {
    const defaultFormData = useMemo(
      () => ({
        statusOprator: statusData.operator,
        newStatusValue: statusData.value || '0',
      }),
      [],
    );

    const sections = [
      {
        fieldLayout: 'vertical',
        fields: [
          {
            id: 'statusOprator',
            type: 'radio',
            label: 'Add or remove a status?',
            required: true,
            options: [
              { value: 'add', label: 'Add' },
              { value: 'remove', label: 'Remove' },
            ],
          },
        ],
      },
    ];

    if (statusData.operator && !isOptionListEmpty) {
      sections.push({
        fieldLayout: 'vertical',
        fields: [
          {
            id: 'newStatusValue',
            type: 'select',
            label: 'Vehicle status',
            required: true,
            options: availableOptions,
          },
        ],
      });
    }

    const formContent = {
      buttonControls: {
        submit: false,
        cancel: false,
      },
      sections,
    };
    return (
      <FormGenerator
        ref={updateVehicleStatusRef}
        id="update-vehicle-status-form"
        data-testid="update-vehicle-status-form"
        schema={updateVehicleStatusSchema}
        defaultValues={defaultFormData}
        content={formContent}
        onSubmit={onSubmit}
        useFormProps={{
          mode: 'onBlur',
          reValidateMode: 'onBlur',
        }}
      />
    );
  };

  return (
    <>
      <RenderForm />
      {statusData.operator === 'remove' &&
      statusData.value?.length > 1 &&
      !isOptionListEmpty ? (
        <Alert type="warning">
          Are you sure you want to {statusData.operator} this status?
        </Alert>
      ) : (
        <></>
      )}
      {statusData.operator && isOptionListEmpty ? (
        <Alert type="warning">
          There is no status to {statusData.operator}!
        </Alert>
      ) : (
        <></>
      )}
    </>
  );
};

export default UpdateVehicleStatusForm;
