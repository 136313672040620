import React, { useEffect, useState } from 'react';
import { useVehicle } from '../../vehicle-context-provider';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  GET_AL_CATEGORY,
  UPDATE_VEHICLE_FIELDS,
} from '../../../../services/data-layer';
import { Engine, Fuel, WheelsAndTires } from './components';
import { Spinner } from '@gsa/afp-component-library';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import {
  canViewAOVehicles,
  canViewGFVehicles,
  canUpdateGFVehicleAdmin,
} from 'utilities/authorization';
import WeightAndCapacity from './components/weight-and-capacity';
import Miscellaneous from './components/miscellaneous';
import {
  canUpdateAOVehicle,
  canUpdateGFVehicleFSR,
} from 'utilities/authorization';
import { isFeatureEnabled } from 'utilities/feature-toggle';

const VehicleSpecifications = () => {
  const { vehicle, refetchVehicle, setSectionMsg } = useVehicle();
  const [lookups, setLookups] = useState([]);
  const ability = useAppAbility();

  const canViewSpecifications =
    canViewAOVehicles(ability) || canViewGFVehicles(ability);

  const canEditAO = canUpdateAOVehicle(ability);

  const canEditGF =
    (isFeatureEnabled('edit-gf-vehicle') && canUpdateGFVehicleFSR(ability)) ||
    canUpdateGFVehicleAdmin(ability);

  const canEdit = vehicle.ownershipTypeCode === 'GF' ? canEditGF : canEditAO;

  const [refetchingData, setRefetchingData] = useState(false);

  const [updateVehicleField, { loading }] = useMutation(UPDATE_VEHICLE_FIELDS, {
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      refetchVehicle().finally(() => {
        setRefetchingData(false);
      });
      setSectionMsg({
        type: 'success',
        message: 'Vehicle was successfully updated.',
      });
    },
    onError: (err) => {
      setSectionMsg({
        type: 'error',
        message: `Error occured while updating vehicle details: ${err.message}`,
      });
    },
  });

  const saveVehicleChanges = (fields) => {
    if (fields?.length) {
      setRefetchingData(true);
      updateVehicleField({
        variables: {
          vin: vehicle.id,
          fields,
        },
      });
    }
  };

  const [getALByCategories, { data, loading: lookupsLoading }] = useLazyQuery(
    GET_AL_CATEGORY,
    {
      fetchPolicy: 'network-only',
    },
  );

  useEffect(() => {
    getALByCategories({
      variables: {
        lookupCategories: ['TRANSMISSION', 'NO_OF_CYLINDERS'],
      },
    });
  }, []);

  useEffect(() => {
    if (data?.getALByCategories) {
      const lookupMap = data?.getALByCategories?.reduce((result, item) => {
        result[item.name] = item.options;
        return result;
      }, {});
      setLookups(lookupMap);
    }
  }, [data]);

  return (
    <div>
      {canViewSpecifications && (
        <>
          <div className="grid-container padding-x-0">
            <div className="grid-row grid-gap margin-bottom-2">
              <div className="tablet:grid-col-6">
                <div className="grid-col-12 margin-bottom-4">
                  <Engine
                    data={vehicle}
                    lookups={lookups}
                    canEdit={canEdit}
                    onSave={saveVehicleChanges}
                  />
                </div>
                <div className="grid-col-12">
                  <WheelsAndTires
                    data={vehicle}
                    canEdit={canEdit}
                    onSave={saveVehicleChanges}
                  />
                </div>
                <div className="grid-col-12">
                  <WeightAndCapacity
                    data={vehicle}
                    canEdit={canEdit}
                    onSave={saveVehicleChanges}
                  />
                </div>
              </div>
              <div className="tablet:grid-col-6">
                <div className="grid-col-12">
                  <Fuel
                    data={vehicle}
                    canEdit={canEdit}
                    onSave={saveVehicleChanges}
                  />
                </div>
                <div className="grid-col-12">
                  <Miscellaneous
                    data={vehicle}
                    canEdit={canEdit}
                    onSave={saveVehicleChanges}
                  />
                </div>
              </div>
            </div>
          </div>
          {(lookupsLoading || loading || refetchingData) && (
            <Spinner
              aria-busy="true"
              className="loading_backdrop"
              size="large"
            />
          )}
        </>
      )}
    </div>
  );
};

export default VehicleSpecifications;
