import {
  Alert,
  Button,
  Modal,
  Spinner,
  RequiredFieldIndicator,
} from '@gsa/afp-component-library';
import React, { useEffect } from 'react';
import MotorPoolAddEditForm from './forms/motor-pool-add-edit-form';
import MotorPoolListingDelete from './forms/motor-pool-listing-delete';
import { useMotorPool } from './motor-pool-provider';

const ModalTitle = () => {
  const { motorPoolListingModalMode, dispatchAction } = useMotorPool();

  useEffect(() => {
    dispatchAction('SET_ALERT_MESSAGE', { type: '', message: '' });
  }, []);

  let title = '';
  switch (motorPoolListingModalMode) {
    case 'SELF_INVITE_MOTOR_POOL_LISTING':
      title = <h2>Request access to pool</h2>;
      break;
    case 'ADD_MOTOR_POOL_LISTING':
      title = <h2>Create motor pool</h2>;
      break;
    case 'UPDATE_MOTOR_POOL_LISTING':
      title = <h2>Edit motor pool</h2>;
      break;
    case 'DELETE_MOTOR_POOL_LISTING':
      title = <h2>Are you sure you want to delete the pool?</h2>;
      break;
    default:
      break;
  }

  return title;
};

const ModalContent = () => {
  const { motorPoolListingModalMode, motorPoolListingSelected } =
    useMotorPool();

  switch (motorPoolListingModalMode) {
    case 'UPDATE_MOTOR_POOL_LISTING':
    case 'ADD_MOTOR_POOL_LISTING':
      return (
        <>
          {motorPoolListingModalMode === 'ADD_MOTOR_POOL_LISTING' && (
            <p>
              Begin by naming your pool, adding a description, and selecting time
              zone.
            </p>
          )}
          <p>
            Required fields are marked with an asterisk (
            <RequiredFieldIndicator />
            ).
          </p>
          <div>
            <MotorPoolAddEditForm />
          </div>
        </>
      );
    case 'SELF_INVITE_MOTOR_POOL_LISTING':
      return (
        <p>
          You are requesting access to a public pool. Once your request is
          approved, you will be able to view and reserve vehicles from the{' '}
          <strong>{motorPoolListingSelected?.poolName}</strong>.
        </p>
      );
    case 'DELETE_MOTOR_POOL_LISTING':
      return <MotorPoolListingDelete />;
    default:
      return null;
  }
};

const ModalAction = () => {
  const {
    motorPoolListingSelected,
    resetModal,
    motorPoolListingModalMode,
    addMotorPoolLoading,
    updateMotorPoolLoading,
    requestPublicPoolAccess,
  } = useMotorPool();

  switch (motorPoolListingModalMode) {
    case 'UPDATE_MOTOR_POOL_LISTING':
    case 'ADD_MOTOR_POOL_LISTING':
      return (
        <>
          <Button
            variant="unstyled"
            className="margin-right-2"
            onClick={resetModal}
            data-testid="motor-pool-listing-add-cancel-button"
            label="Cancel"
          />
          <Button
            variant="primary"
            type="submit"
            form="motor-pool-listing-add-form"
            data-testid="motor-pool-listing-add-submit-button"
            disabled={addMotorPoolLoading || updateMotorPoolLoading}
            label="Submit"
          />
          {(addMotorPoolLoading || updateMotorPoolLoading) && (
            <Spinner
              size="small"
              className="display-inline-block margin-left-2"
            />
          )}
        </>
      );
    case 'DELETE_MOTOR_POOL_LISTING':
      return (
        <>
          <Button
            variant="unstyled"
            className="margin-right-2"
            onClick={resetModal}
            data-testid="motor-pool-listing-cancel-button"
            label="Cancel"
          />
          <Button
            variant="secondary"
            type="submit"
            data-testid="motor-pool-listing-delete-button"
            form="motor-pool-listing-delete-form"
            label="Delete pool"
          />
        </>
      );
    case 'SELF_INVITE_MOTOR_POOL_LISTING':
      return (
        <>
          <Button
            variant="unstyled"
            className="margin-right-2"
            onClick={resetModal}
            data-testid="self-invite-cancel-button"
            label="Cancel"
          />
          <Button
            variant="primary"
            type="submit"
            onClick={() => {
              requestPublicPoolAccess({
                variables: {
                  motorPoolId: motorPoolListingSelected?.id,
                },
              });
            }}
            data-testid="self-invite-submit-button"
            form="motor-pool-listing-self-invite-form"
            label="Request access"
          />
        </>
      );
    default:
      return null;
  }
};

const MotorPoolListingModal = () => {
  const { alertMessage, resetModal, motorPoolListingModalShow } =
    useMotorPool();
  const { header, message, type, context } = alertMessage;

  return motorPoolListingModalShow ? (
    <div className="afp-modal-wrapper">
      <div className="afp-modal-overlay">
        <Modal
          title={<ModalTitle />}
          actions={<ModalAction />}
          onClose={resetModal}
          variant="large"
        >
          {(context === 'addMotorPool' ||
            context === 'updateMotorPool' ||
            context === 'motorPoolTimezones') &&
            message && (
              <div
                className="margin-bottom-2"
                data-testid="reservation-modal-alert"
              >
                <Alert type={type} heading={header || null}>
                  {message}
                </Alert>
              </div>
            )}
          <ModalContent />
        </Modal>
      </div>
    </div>
  ) : null;
};

export default MotorPoolListingModal;
