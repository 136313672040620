/** Utility to add two times together.
 *  @param {string} time1 - The first time to add.
 *  @param {string} time2 - The second time to add.
 *  @returns {string} - The sum of the two times.
 * */
export const addTimes = (time1, time2) => {
  const [hours1, minutes1] = time1.split(':').map(Number);
  const [hours2, minutes2] = time2.split(':').map(Number);

  const totalMinutes = minutes1 + minutes2;
  const carry = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  const totalHours = hours1 + hours2 + carry;
  const hours = totalHours % 24;

  return `${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}`;
};
