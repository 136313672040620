import React from 'react';
import { PropTypes } from 'prop-types';
import { SummaryCard } from '../../widgets/summary-card';
import { utcDateStrToUS } from './helpers';

const RepairSummary = ({ data, loading }) => {
  const getFootnote = () => {
    if (!data?.getTotalCostByVehicle?.date) return ' ';
    return `As of ${utcDateStrToUS(data.getTotalCostByVehicle.date)}`;
  };

  return (
    <div
      className="grid-row flex-row margin-bottom-4"
      data-testid="mileage-summary"
    >
      <SummaryCard
        title="Total cost of repairs"
        count={data?.getTotalCostByVehicle?.cost}
        isDollar
        footnote={getFootnote()}
        loading={loading}
      />
    </div>
  );
};

RepairSummary.propTypes = {
  data: PropTypes.shape({
    getTotalCostByVehicle: PropTypes.shape({
      cost: PropTypes.number,
      date: PropTypes.string,
    }),
  }),
  loading: PropTypes.bool.isRequired,
};
RepairSummary.defaultProps = {
  data: undefined,
};

export default RepairSummary;
