/* eslint-disable filenames/match-regex */
import { gql } from '@apollo/client';

export const CREATE_FLEET_CARD_TRANSACTION = gql`
  mutation createFleetCardTransaction(
    $fcTransaction: FCTransactionInputCreate!
    $fcMerchant: FCMerchantInput!
  ) {
    createFleetCardTransaction(
      fcTransaction: $fcTransaction
      fcMerchant: $fcMerchant
    ) {
      id
      assetId
    }
  }
`;
