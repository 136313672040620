/* eslint-disable react/prop-types */
import React from 'react';
import { getCodeAndNameByOwnerShip } from 'utilities/format';
import { fieldGetter } from '../../../helpers/field-getter';

const POCCard = ({ title, fullName, email, agencyOrFMC, phoneInfo }) => {
  return (
    <div className="overview-card-lg">
      <h4 className="title-s-caps text-primary margin-top-0 margin-bottom-1">
        {title}
      </h4>
      <div className="padding-top-2">
        <b>{fullName}</b>
      </div>
      <div>{email}</div>
      <div>{agencyOrFMC}</div>
      <div>{phoneInfo}</div>
    </div>
  );
};

export default POCCard;
