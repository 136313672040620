import { z } from 'zod';

export const changePlateSchema = z.object({
  reason: z.string().min(1, { message: 'Reason is a required field.' }),
  tagNumber: z
    .string()
    .min(1, { message: 'License plate is a required field.' })
    .refine((data) => {
      return !data.includes('invalid');
    }, 'Enter a different plate number to proceed.'),
});
