export const MANGE_PM_FIELDS = {
  date: {
    name: 'date',
    id: 'date',
    label: 'PM completion date',
  },
  mileage: {
    name: 'mileage',
    id: 'mileage',
    label: 'PM completion mileage',
  },
  invoice: {
    name: 'invoice',
    id: 'invoice',
    label: 'Attach invoice',
  },
};
