/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Button, RadioButton } from '@gsa/afp-component-library';
import vinVerificationTypes from './vin-verification-enum';

export default function UnverifiedVinModal({
  registrationType,
  vin,
  nhtsaVerified,
  // eslint-disable-next-line func-names
  setOption = function () {
    // nothing to do
  },
  // eslint-disable-next-line func-names
  onGoBack = function () {
    // nothing to do
  },
}) {
  const [val, setVal] = useState(null);
  const handleOptionSelect = (e) => {
    setVal(e.target.value);
  };
  const verifyNonStandardVin = () => {
    setOption(val);
  };
  const chartMsg =
    vin.length < 17 ? `is only ${vin.length} characters and` : '';

  const nhtsaMessage = nhtsaVerified
    ? 'was partially validated with the National Highway Traffic Safety Administration (NHTSA). Some data elements were missing in the validation process. GSA is relaxing the registration constraint for you to proceed with the vehicle registration process.'
    : 'cannot be validated with the National Highway Traffic Safety Administration (NHTSA).';
  return (
    <div data-testid="validation-modal">
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
      <div tabIndex={0}>
        {vin.length < 17 && 'All vehicles should contain a 17-character VIN. '}{' '}
        The VIN number <span className="text-bold">{vin}</span> {chartMsg}{' '}
        {nhtsaMessage} Please select an option below to continue.
      </div>
      <br />
      <br />
      <br />
      <div className="grid-row">
        {registrationType === 'manual' && !nhtsaVerified && (
          <div className="grid-col-6 border-right-1px padding-right-4 border-gray-10">
            <h2 className="margin-top-0 text-primary-dark text-normal margin-bottom-1">
              Option 1
            </h2>
            <div>Go back and verify that the VIN was entered correctly</div>
            <Button
              type="button"
              onClick={onGoBack}
              className="margin-top-4"
              label="Go back"
            />
          </div>
        )}
        <div
          className={
            registrationType === 'manual'
              ? 'grid-col-6 padding-left-4'
              : 'grid-col-6'
          }
        >
          <h2 className="margin-top-0 text-primary-dark text-normal margin-bottom-1">
            {registrationType === 'manual' && !nhtsaVerified && 'Option 2'}
            {(registrationType !== 'manual' || nhtsaVerified) && 'Options'}
          </h2>
          <fieldset className="border-0 margin-0 padding-0">
            <legend>Choose non-standard VIN option and verify</legend>
            <RadioButton
              name="case"
              id={vinVerificationTypes.INTERNATIONAL}
              value={vinVerificationTypes.INTERNATIONAL}
              checked={val === vinVerificationTypes.INTERNATIONAL}
              label={
                <>
                  <span className="text-normal">
                    This vehicle was manufactured in Japan and uses a chassis
                    number instead of a VIN (example: JZA80-1004956)
                  </span>
                </>
              }
              onChange={handleOptionSelect}
            />
            <RadioButton
              name="case"
              id={vinVerificationTypes.PRE_1981}
              value={vinVerificationTypes.PRE_1981}
              checked={val === vinVerificationTypes.PRE_1981}
              label={
                <>
                  <span className="text-normal">
                    This VIN is on a vehicle built before 1981
                  </span>
                </>
              }
              onChange={handleOptionSelect}
            />
            <RadioButton
              name="case"
              id="other"
              value={vinVerificationTypes.UNVERIFIED}
              checked={val === vinVerificationTypes.UNVERIFIED}
              label={
                <>
                  <span className="text-normal">Other</span>
                </>
              }
              onChange={handleOptionSelect}
            />
          </fieldset>

          <Button
            type="button"
            className="margin-top-4"
            onClick={verifyNonStandardVin}
            label="Verify non-standard VIN"
          />
        </div>
      </div>
      <br />
    </div>
  );
}
