/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useCallback } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
// import PropTypes from 'prop-types';
import {
  AFPTable,
  EmptyState,
  Pagination,
  Spinner,
  Label,
  Link,
  Tooltip,
} from '@gsa/afp-component-library';
import { GET_ALL_REPAIR_ORDER_BASED_ON_ASSET } from '../../../../../services/data-layer/repair-order';
import { useVehicle } from '../../../vehicle-context-provider';
import usePagination from './use-pagination';
import './repairs-by-po.css';

const emDashUnicode = '\u2014';
const opts = { year: 'numeric', month: '2-digit', day: '2-digit' };

const ReadonlyField = ({
  label,
  value,
  defaultValue,
  render,
  className,
  valueClassName,
}) => {
  const description = render ? render(value) : value;
  const ariaLabel = `${label} ${description}`;
  return (
    <section aria-label={ariaLabel} className={className}>
      <Label className="text-bold">{label}</Label>
      <p className={valueClassName}>{description || defaultValue}</p>
    </section>
  );
};

const ReadonlyFieldLink = ({
  label,
  value,
  defaultValue,
  render,
  className,
  href,
  ariaLabel,
  valueClassName,
}) => {
  const description = render ? render(value) : value;
  const effectiveAriaLabel = ariaLabel || description;
  return (
    <section className={className}>
      <Label className="text-bold padding-bottom-1">{label}</Label>
      <Link
        href={href}
        data-testid="po-number-link"
        aria-label={effectiveAriaLabel}
        target="_blank"
        rel="noopener noreferrer"
        className={valueClassName}
      >
        {description || defaultValue}
      </Link>
    </section>
  );
};

const repairOrderColumns = [
  {
    Header: 'Cause code',
    accessor: (row) => row?.commonServiceReason?.value,
    Cell: ({ value }) => <p>{value}</p>,
    sortable: false,
    headerClassName: 'width-card-lg',
  },
  {
    Header: 'Service type',
    accessor: (row) =>
      [
        row?.serviceCode?.componentOne,
        row?.serviceCode?.componentTwo,
        row?.serviceCode?.componentThree,
        row?.serviceCode?.componentFour,
        row?.serviceCode?.componentFive,
      ]
        .filter(Boolean)
        .join(' > '),
    Cell: ({ value }) => <p>{value}</p>,
    sortable: false,
  },
  {
    Header: 'Qty',
    accessor: (row) =>
      row?.serviceLineCosts?.map((cost) => cost.partsQuantity).join(', '),
    Cell: ({ value }) => <p>{value}</p>,
    sortable: false,
  },
  {
    Header: 'Cost',
    accessor: (row) =>
      row?.serviceLineCosts?.map((cost) => cost.partsCost).join(', '),
    Cell: ({ value, row }) => (
      <p className="">
        {`${Number(value).toLocaleString('en-US', {
          style: 'currency',
          currency: row?.original?.isEuVehicle ? 'EUR' : 'USD',
        })}`}
      </p>
    ),
    sortable: false,
    headerClassName: 'width-card',
  },
  {
    Header: 'Total',
    accessor: (row) =>
      row?.serviceLineCosts?.map((cost) => cost.totalAmount).join(', '),
    Cell: ({ value, row }) => (
      <p className="text-bold padding-right-neg-10">
        {`${Number(value).toLocaleString('en-US', {
          style: 'currency',
          currency: row?.original?.isEuVehicle ? 'EUR' : 'USD',
        })}`}
      </p>
    ),
    sortable: false,
    cellClassName: '',
    headerClassName: 'width-card',
  },
];

const RepairsByPO = () => {
  const { vehicle } = useVehicle();
  const { vin } = useParams();
  const [
    getAllRepairsByPOQuery,
    { data: repairsData, loading: repairsLoading },
  ] = useLazyQuery(GET_ALL_REPAIR_ORDER_BASED_ON_ASSET);

  const getAllRepairsByPO = useCallback(
    (offset, itemsPerPage) => {
      const queryVariables = {
        order: [['authorizedOn', 'DESC']],
        offset,
        limit: itemsPerPage || 5,
        filters: {
          operator: 'AND',
          value: [
            {
              key: 'assetId',
              operator: 'EQ',
              value: vehicle.uuid,
            },
            {
              key: 'repairOrderNumber',
              operator: 'NOT',
              value: null,
            },
            {
              key: 'poNumber',
              operator: 'NOT',
              value: null,
            },
          ],
        },
      };
      getAllRepairsByPOQuery({
        variables: queryVariables,
        fetchPolicy: 'network-only',
      });
    },
    [getAllRepairsByPOQuery, vehicle.uuid],
  );

  const { currentPage, offset, itemsPerPage, onPageChange } = usePagination({
    currentPage: 1,
    itemsPerPage: 5,
  });

  useEffect(() => {
    getAllRepairsByPO(offset, itemsPerPage);
  }, [getAllRepairsByPO, offset, itemsPerPage]);

  const repairsTableData = useMemo(() => {
    if (repairsLoading) return [];
    return repairsData?.getAllRepairOrderBasedOnAsset?.rows || [];
  }, [repairsData, repairsLoading]);

  if (repairsLoading && !repairsTableData.length) return <Spinner />;
  return (
    <>
      {repairsTableData &&
        repairsTableData.length > 0 &&
        repairsTableData.map((repair, index) => (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            className="repair-by-po-card border border-base-light border-width-0.5"
          >
            <div className="grid-row flex-row">
              <div className="grid-col display-flex flex-row bg-blue-5 padding-bottom-1">
                <ReadonlyField
                  label="Authorization date"
                  value={
                    new Date(repair.authorizedOn).toLocaleDateString(
                      'en-us',
                      opts,
                    ) ?? ''
                  }
                  defaultValue=""
                  className="grid-col-6 flex-auto padding-x-2 padding-right-4"
                  valueClassName="margin-y-1"
                />
                <ReadonlyFieldLink
                  label="PO number"
                  value={repair.poNumber ?? ''}
                  defaultValue=""
                  className="grid-col-4 flex-auto padding-x-2 padding-right-4"
                  valueClassName="margin-y-1"
                  href={`${window.AFP_CONFIG.appURLs.mnr}/vehicles/${vin}/repairs/${repair.repairOrderNumber}`}
                />
                <ReadonlyField
                  label="AutoIntegrate"
                  value={repair.aiRoidNumber ?? emDashUnicode}
                  defaultValue="-"
                  className="grid-col-6 flex-auto padding-x-2 padding-right-4"
                  valueClassName="margin-y-1"
                />
                <section className="grid-col-4 flex-auto padding-x-2 padding-right-4">
                  <Label className="text-bold padding-bottom-1">Vendor</Label>
                  <Tooltip
                    className="usa-button--unstyled"
                    label={repair.vendor?.name || ''}
                    onClick={() => {
                      window.open(
                        `${window.AFP_CONFIG.appURLs.home}/vendor/details/${repair.vendorId}`,
                        '_blank',
                      );
                    }}
                  >
                    {' '}
                    {repair.fleetVendorId ?? ''}
                  </Tooltip>
                </section>
              </div>
              <div className="display-flex flex-row flex-justify-end bg-blue-5">
                <ReadonlyField
                  label="Mileage"
                  value={`${
                    repair.mileage?.toLocaleString() ?? emDashUnicode
                  } mi`}
                  defaultValue=""
                  className="grid-col flex-auto padding-x-2 text-right"
                  valueClassName="margin-y-1"
                />
                <ReadonlyField
                  label="Total"
                  value={
                    `${Number(repair.repairTotal).toLocaleString('en-US', {
                      style: 'currency',
                      currency: repair.isEuVehicle ? 'EUR' : 'USD',
                    })}` ?? ''
                  }
                  defaultValue=""
                  className="grid-col flex-auto padding-x-2 bg-primary-lighter text-right width-card-lg"
                  valueClassName="text-right text-bold text-total margin-y-0"
                />
              </div>
            </div>
            <AFPTable
              columns={repairOrderColumns}
              data={
                repair?.serviceLines?.map((detail) => ({
                  ...detail,
                  isEuVehicle: repair.isEuVehicle,
                  qty:
                    detail.serviceLineCosts
                      ?.map((cost) => cost.partsQuantity)
                      .join(', ') || '',
                })) || []
              }
              testId="afp-repair-orders-table"
              fullWidth
            />
          </div>
        ))}
      {repairsTableData.length === 0 && (
        <div className="bg-gray-3 padding-y-5">
          <div className="text-center padding-y-4">
            <EmptyState bottomText="There are no previous repair orders for this vehicle." />
          </div>
        </div>
      )}
      {repairsTableData.length > 0 && (
        <Pagination
          itemsPerPageOptions={[5, 10, 25]}
          fullWidth
          currentPage={currentPage}
          variant="advanced"
          itemsPerPage={itemsPerPage}
          onPageChange={onPageChange}
          itemsCount={repairsData?.getAllRepairOrderBasedOnAsset?.count ?? 0}
        />
      )}
    </>
  );
};

export default RepairsByPO;
