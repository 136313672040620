/* eslint-disable filenames/match-exported */
import * as yup from 'yup';
import { emdash } from 'components/common';
import { AddMemberTypeAHead } from '../utils/motor-pool-form-components';

export const ADD_DRIVER = {
  formId: 'ADD_DRIVER',
  formLayout: {
    usersTypeAhead: {
      label: 'Search user by email',
      name: 'usersTypeAhead',
      id: 'usersTypeAhead',
      dataId: 'addDriver',
      required: true,
      component: AddMemberTypeAHead,
    },
  },
  formValidationSchema: () =>
    yup.object().shape({
      usersTypeAhead: yup.string().required().label('This'),
    }),
  onSubmit:
    ({ addMotorPoolDriver, selectedMotorPoolById }) =>
    (values) => {
      const [, email] = values.usersTypeAhead.split(emdash);

      addMotorPoolDriver({
        variables: {
          motorPoolUserInput: {
            email: email.trim(),
            motorPoolId: selectedMotorPoolById?.id,
            poolName: selectedMotorPoolById?.poolName,
          },
        },
      });
    },
};

const formSchema = {
  ADD_DRIVER,
};

export default formSchema;
