/* eslint-disable react/prop-types */
/* eslint-disable filenames/match-exported */
import React from 'react';
import { useMutation } from '@apollo/client';
import exportMessages from 'utilities/export-message';
import { EXPORT_REPORT } from 'services/data-layer';
import { useLicensePlate } from './context';
import { useLicensePlateDestruction } from '../license-plate-destruction/context';

const useDataController = ({ alertUtils }) => {
  const { licensePlates, selectedRows, appliedFilters, filters, sortOrder } =
    useLicensePlate();

  const {
    toggleDestructionEditing,
    setEligibleForDestruction,
    getIneligiblePlateDistribution,
  } = useLicensePlateDestruction();

  const [exportLicensePlates] = useMutation(EXPORT_REPORT, {
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      alertUtils.showSuccessAlert(
        exportMessages.exportSuccessMessage.message,
        'Export Initiated:',
      );
    },
    onError: (error) => {
      alertUtils.showErrorAlert(<span>{error.message}</span>);
    },
  });

  const onExport = () => {
    if (!licensePlates?.count || licensePlates?.count === 0) {
      alertUtils.showErrorAlert(
        <span>{exportMessages.exportMsgNoData('license plate').message}</span>,
      );
      return;
    }

    if (licensePlates?.count > 950000) {
      alertUtils.showErrorAlert(
        <span>{exportMessages.exportMsgTooMany.message}</span>,
      );
    } else {
      exportLicensePlates({
        variables: {
          request: {
            reportType: 10,
            deliveryMethod: 1,
            reportCriteria: {
              order: [sortOrder],
              filters: {
                operator: filters[0]?.operator,
                value: filters[0]?.value,
              },
              appliedFilters,
            },
          },
        },
      });
    }
  };

  const onBulkSubmitForDestruction = () => {
    setEligibleForDestruction(licensePlates, selectedRows);
    getIneligiblePlateDistribution(licensePlates, selectedRows);
    toggleDestructionEditing();
  };

  return {
    onExport,
    onBulkSubmitForDestruction,
  };
};

export default useDataController;
