import _ from 'lodash';
import React from 'react';
import OrderDetail from '../../orders/widgets/table-widgets/detail-row';
import OrderDetailEmail from '../../orders/widgets/table-widgets/order-detail-row-email';
import Status from '../../orders/widgets/table-widgets/order-status-badge';
import { useOrder } from '../context';
import './order-status.css';

export default function OrderStatus() {
  const { details } = useOrder();

  return (
    <div className="details-rows margin-top-1 padding-2 detail-list bg-gray-3 radius-md">
      <div className="detail-row display-flex flex-justify padding-y-1 border-bottom border-width-2px border-base-lighter">
        <div className="detail-title">
          <strong>Order status</strong>
        </div>
        <div className="status-info">
          <Status orderStatus={_.get(details, 'orderStatus')} />
        </div>
      </div>
      <OrderDetailEmail
        label="Updated by"
        value={_.get(
          details,
          'updatedByUser.fullName',
          _.get(details, 'updatedAt', null) ? null : '—',
        )}
        email={_.get(details, 'updatedByUser.email', null)}
      />
      <OrderDetail
        label="Date updated"
        value={
          _.get(details, 'updatedAt')
            ? `${new Date(
                _.get(details, 'updatedAt'),
              ).toLocaleDateString()} • ${new Date(
                _.get(details, 'updatedAt'),
              ).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`
            : '—'
        }
      />
    </div>
  );
}
