import AgencyFilterItem from '../custom-filters/agency-filter-item';
import BureauFilterItem from '../custom-filters/bureau-filter-item';

const general = {
  agency: {
    title: 'Agency',
    key: 1,
    filters: [
      {
        key: '$customer.customer_agency_code$',
        title: 'Agency',
        component: AgencyFilterItem,
        permanent: false,
        operator: '$exact',
        value: '',
      },
      {
        key: '$customer.customer_bureau_code$',
        title: 'Bureau',
        component: BureauFilterItem,
        permanent: false,
        operator: '$exact',
        value: '',
      },
    ],
  },
  bureau: {
    title: 'Bureau',
    key: 'bureauCode',
    component: BureauFilterItem,
    permanent: false,
    operator: '$exact',
  },
};

export const generalNestedFilter = {
  agency: {
    title: 'Agency',
    key: 1,
    filters: [
      {
        key: '$customer.customer_agency_code$',
        title: 'Agency',
        component: AgencyFilterItem,
        permanent: false,
        operator: 'EQ',
        value: '',
      },
      {
        key: '$customer.customer_bureau_code$',
        title: 'Bureau',
        component: BureauFilterItem,
        permanent: false,
        operator: 'EQ',
        value: '',
      },
    ],
  },
  bureau: {
    title: 'Bureau',
    key: '$customer.customer_bureau_code$',
    component: BureauFilterItem,
    permanent: false,
    operator: 'EQ',
    value: '',
  },
};

export default general;
