/* eslint-disable no-shadow */
/* eslint-disable consistent-return */
/* eslint-disable no-console */
import { get, set, cloneDeep, isNil } from 'lodash';
import { atomFamily } from 'recoil';
import { safeParseFloat } from './format';

const runWatcher = ({
  atoms,
  currentId,
  currentVal,
  get,
  set,
  dependantFunctions = { watcher: { onChange: {} } },
  type = 'onChange',
}) => {
  const { watcher } = dependantFunctions;
  if (typeof watcher === 'object' && watcher[type]) {
    if (Object.keys(watcher[type]).includes(currentId)) {
      watcher[type][currentId]({
        atoms,
        currentId,
        get,
        set,
        dependantFunctions,
        currentVal,
      });
    }
  }
};

const getValueByPath = (data, path) => {
  if (path === '#' || !path) {
    return data || {};
  }
  if (typeof path === 'string') {
    return get(data, path);
  }

  console.error('path has to be a string');
};
// pathInfo -> {path,value} | [[path,value]]
const setValueByPath = ({ data, pathInfo = [], mutate = false }) => {
  const draft = mutate ? data : cloneDeep(data);

  if (Object.prototype.toString.call(pathInfo).slice(8, -1) === 'Object') {
    const { path, value } = pathInfo;
    if (typeof path === 'string') {
      set(draft, path, value);
    } else {
      console.error('path has to be a string');
    }
    return draft;
  }
  if (pathInfo.length === 0) {
    return draft;
  }
  // x[0] is path and x[1] is value
  pathInfo.map((x) => {
    const path = x[0];
    const value = x[1];
    if (typeof path === 'string') {
      set(draft, path, value);
    } else {
      console.error('path has to be a string');
    }
  });

  return draft;
};

export const formFieldAtom = atomFamily({
  key: 'form/formFieldState',
  default: { errorMessage: null, value: null, isDirty: false },
});

export const layoutSchemaAtom = atomFamily({
  key: 'expense/layoutFormState',
  default: {
    layout: null,
  },
});

export const isReadyToSubmit = ({
  get,
  set,
  pageId,
  atoms,
  atomStateSchema,
  currentStepIndex = 1,
}) => {
  const layoutAtom = get(atoms.layoutSchemaAtom(pageId));
  // validation the process could be more modular if we have more resource

  const stateIdsOfCurrentStep =
    layoutAtom.layout[currentStepIndex - 1].stateIds;

  const errors = stateIdsOfCurrentStep.reduce((acc, stateId) => {
    return {
      ...acc,
      [stateId]:
        atomStateSchema.formFields[stateId]?.validate &&
        atomStateSchema.formFields[stateId]?.validate({
          value: get(atoms.formFieldAtom(stateId)).value,
        }),
    };
  }, {});

  Object.keys(errors).map((key) => {
    if (errors[key]) {
      const fieldState = get(atoms.formFieldAtom(key));
      set(atoms.formFieldAtom(key), { ...fieldState, ...errors[key] });
    }
  });
  if (Object.values(errors).some((x) => x?.errorMessage)) return false;
  return true;
};

const validationRule = {
  required: ({ value }) => {
    if (!value) {
      return { errorMessage: 'This is a required field' };
    }

    return { errorMessage: null };
  },
  notDefault: ({ value }) => {
    if (value === 'DEFAULT') {
      return { errorMessage: 'This is a required field' };
    }
    return { errorMessage: null };
  },
  pass: () => {
    return { errorMessage: null };
  },
  phoneNumber: ({ value }) => {
    if (isNil(value) || value === '') {
      return { errorMessage: null };
    }
    if (value.toString().length < 10) {
      return { errorMessage: 'Invalid phone number' };
    }
    return { errorMessage: null };
  },
  maxValue:
    (valueMax) =>
    ({ value }) => {
      const numberValue = safeParseFloat(value);
      const numberMaxValue = safeParseFloat(valueMax);
      if (numberValue && numberValue > numberMaxValue) {
        return { errorMessage: `This cannot be more than ${numberMaxValue}` };
      }
      return { errorMessage: null };
    },
  isValidYear:
    (minValidYear) =>
    ({ value }) => {
      let year = 0;
      if (value.length === 10) {
        year = Number(value.split('/')[2]);
      }

      if (value.length === 20) {
        year = Number(value.split('-')[0]);
      }

      if (year < minValidYear) {
        return { errorMessage: 'Please enter a valid year.' };
      }
      return { errorMessage: null };
    },
};

export { runWatcher, getValueByPath, setValueByPath, validationRule };
