import React from 'react';
import { Button } from '@gsa/afp-component-library';
import {
  canUpdateGFVehicleAdmin,
  canUpdateGFVehicleFSR,
} from 'utilities/authorization';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import { usePm } from './pm-provider';

const EditPmSchedule = () => {
  const ability = useAppAbility();
  const canEditPmSchedules =
    canUpdateGFVehicleAdmin(ability) || canUpdateGFVehicleFSR(ability);
  const { openEditPmScheduleModal } = usePm();
  return (
    <>
      {canEditPmSchedules && (
        <Button
          label="Edit inspection schedule"
          leftIcon={{ name: 'edit', class: 'usa-icon--size-2' }}
          variant="outline"
          onClick={() => {
            openEditPmScheduleModal();
          }}
          data-testid="report-pm-button"
        />
      )}
    </>
  );
};

export default EditPmSchedule;
