/* eslint-disable react/prop-types */
import React from 'react';
import classnames from 'classnames';
import { emdash } from 'components/common';
import moment from 'moment';
export const TIMEZONE = 'America/New_York';

const formatDate = (date) => moment(date).utc().format('MM/DD/YYYY');

export const RowSubDetail = ({ detail }) => {
  const {
    reportType,
    frequency,
    nextRunDate,
    user,
    lastRunReport,
    agencyInfo,
    bureauInfo,
    scheduleStatus,
  } = detail;

  const borderClasses = classnames(
    'border-bottom border-base-lighter padding-y-1',
  );

  return (
    <div className="grid-row grid-gap flex-justify">
      <div className="tablet:grid-col-6">
        <div
          className={`${borderClasses} display-flex flex-justify`}
          data-testid="data-section"
        >
          <strong>Report Type</strong>
          {reportType?.name || emdash}
        </div>
        <div
          className={`${borderClasses} display-flex flex-justify`}
          data-testid="data-section"
        >
          <strong>Email Address</strong>
          {user?.email || emdash}
        </div>
        <div
          className={`${borderClasses} display-flex flex-justify`}
          data-testid="data-section"
        >
          <strong>Agency</strong>
          {agencyInfo?.name || emdash}
        </div>
        <div
          className={`${borderClasses} display-flex flex-justify`}
          data-testid="data-section"
        >
          <strong>Bureau</strong>
          {bureauInfo?.name || emdash}
        </div>
      </div>
      <div className="tablet:grid-col-5">
        <div
          className={`${borderClasses} display-flex flex-justify`}
          data-testid="data-section"
        >
          <strong>Frequency</strong>
          {frequency || emdash}
        </div>
        <div
          className={`${borderClasses} display-flex flex-justify`}
          data-testid="data-section"
        >
          <strong>Next cycle date</strong>
          {scheduleStatus === 'Active' ? formatDate(nextRunDate) : emdash}
        </div>
        <div
          className={`${borderClasses} display-flex flex-justify`}
          data-testid="data-section"
        >
          <strong>Last run date</strong>
          {lastRunReport?.updatedAt
            ? formatDate(lastRunReport?.updatedAt)
            : emdash}
        </div>
        <div
          className={`${borderClasses} display-flex flex-justify`}
          data-testid="data-section"
        >
          <strong>Last run status</strong>
          {lastRunReport?.reportStatusType?.name || emdash}
        </div>
      </div>
    </div>
  );
};
