import React from 'react';
import { Button } from '@gsa/afp-component-library';
import { SectionBlock } from '../../common';
import ContactInfoDisplay from './contact-info-display';

const ContactInfoCard = ({
  contact,
  displayContext,
  handleContactCardAction,
}) => {
  return (
    <SectionBlock
      key={'contact' + contact?.contactId}
      title=""
      className="margin-top-1"
      basic={false}
    >
      <div className="grid-row">
        <div className="grid-col-8">
          <div className="grid-row">
            <ContactInfoDisplay
              contact={contact}
              displayContext={displayContext}
            />
          </div>
          {displayContext === 'CustomerForm' && (
            <div className="grid-row">
              <Button
                data-testid={'remove-contact-button' + contact?.contactId}
                variant="unstyled"
                className="margin-right-2"
                label="Remove"
                onClick={() =>
                  handleContactCardAction('REMOVE_CONTACT', contact)
                }
              />
              <Button
                data-testid={
                  'edit-contact-button-customer' + contact?.contactId
                }
                variant="unstyled"
                className="margin-right-2"
                label="Edit"
                onClick={() => handleContactCardAction('EDIT_CONTACT', contact)}
              />
              {contact?.priority !== 1 && (
                <Button
                  data-testid={
                    'mark-contact-as-primary-button' + contact?.contactId
                  }
                  variant="unstyled"
                  className="margin-right-2"
                  label="Mark as primary"
                  onClick={() =>
                    handleContactCardAction('MARK_CONTACT_AS_PRIMARY', contact)
                  }
                />
              )}
            </div>
          )}
        </div>
        {displayContext === 'PoCModal' && (
          <div className="grid-col-4">
            <Button
              data-testid={'add-poc-to-cust-button' + contact?.contactId}
              leftIcon={{ name: 'add' }}
              variant="primary"
              className="margin-right-2"
              label="Add contact"
              onClick={() =>
                handleContactCardAction('ADD_CONTACT_TO_ACCOUNT', contact)
              }
            />
            <Button
              data-testid={'edit-contact-button-poc-modal' + contact?.contactId}
              leftIcon={{ name: 'edit' }}
              variant="unstyled"
              className="margin-right-2"
              label="Edit contact"
              onClick={() => handleContactCardAction('EDIT_CONTACT', contact)}
            />
          </div>
        )}
      </div>
    </SectionBlock>
  );
};

export default ContactInfoCard;
