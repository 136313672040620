/* eslint-disable react/prop-types */
import React, { useEffect, useState, useMemo } from 'react';
import {
  Pagination,
  AFPTable,
  EmptyState,
  AFPTableRowAction,
  Icon,
  Tooltip,
} from '@gsa/afp-component-library';
import useUser from 'utilities/use-user';
import { VEHICLE_OWNERSHIP } from 'utilities/format';
import { useRecallCampaignDetailPage } from '../providers';

const initialPaginationState = {
  limit: 10,
  offset: 0,
  currentPage: 1,
  isReset: false,
};

const VinRecallTables = ({
  vehicleRecallList,
  loadingGetRecallCampaignDetails,
  canManageRecallCampaigns,
  getRecallCampaignDetails,
  campaignId,
}) => {
  const { isRole } = useUser();
  // eslint-disable-next-line prefer-const
  const [order, setOrder] = useState('createdAt DESC');
  const { dispatchAction, currentModalName } = useRecallCampaignDetailPage();
  const columns = useMemo(() => {
    const columnList = [
      {
        Header: 'Ownership type',
        accessor: 'vehicle.ownershipTypeCode',
        headerClassName: 'width-card-lg',
        Cell: ({ value }) => VEHICLE_OWNERSHIP[value] ?? '-',
      },
      {
        Header: 'VIN',
        accessor: 'vehicle.vin',
        headerClassName: 'width-card-lg',
        // eslint-disable-next-line
        Cell: ({ value }) => (
          <a
            href={`${
              window.AFP_CONFIG.appURLs.vms
            }/vehicles/${encodeURIComponent(value)}/recalls`}
            aria-label={`show vehicle information for vin number ${value} in a new window`}
          >
            {value}
          </a>
        ),
      },
      {
        Header: () => 'License plate',
        accessor: 'vehicle.tagNumber',
        headerClassName: 'width-card-lg',
      },
      {
        Header: () => (
          <span>
            Remedy available{' '}
            <Tooltip
              className="usa-button--unstyled"
              label='"Yes" means a recall repair or parts are available.'
              position="left"
            >
              <Icon iconName="info" className="text-primary text-ink" />
            </Tooltip>
          </span>
        ),
        accessor: 'recallCampaign.isRemedyAvailable',
        Cell: ({ value }) => <span>{value ? 'Yes' : 'No'}</span>,
      },
      { Header: 'Recall status', accessor: 'status' },
    ];

    if (canManageRecallCampaigns) {
      columnList.push({
        Header: 'Actions',
        sortable: false,
        headerClassName: 'cell-center',
        cellClassName: 'cell-center',
        Cell: (props) => {
          const {
            row: { original },
          } = props;
          if (
            original?.recallCampaign.recallSource === 'Manual' &&
            (isRole('FMVRSAdminRole') || isRole('SiteAdmin'))
          ) {
            return (
              <AFPTableRowAction
                actions={[
                  {
                    icon: 'delete',
                    label: 'Remove VIN from Campaign',
                    action: 'deleteRecall',
                    operation: 'update',
                  },
                ]}
                // eslint-disable-next-line react/destructuring-assignment
                onSelectAction={() => {
                  dispatchAction('SET_MODAL', {
                    currentModalName,
                    currentMode: 'removeVinModalSchema',
                  });
                  dispatchAction('SET_CURRENT_VEHICLE', {
                    assetId: original?.assetId,
                    vin: original?.vehicle?.vin,
                  });
                }}
                {...props}
              />
            );
          }
          return [];
        },
      });
    }

    return columnList;
  }, []);
  const [paginationState, setPaginationState] = useState(
    initialPaginationState,
  );
  useEffect(() => {
    getRecallCampaignDetails({
      variables: {
        limit: paginationState.limit,
        offset: paginationState.offset,
        campaignId,
        order,
      },
    });
  }, [paginationState, order]);

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    const offset = (currentPage - 1) * itemsPerPage;
    setPaginationState({
      limit: itemsPerPage,
      offset,
      currentPage,
    });
  };
  return (
    <div className="padding-top-4">
      <h2>VINS associated with campaign</h2>
      <div className="padding-top-0">
        <AFPTable
          columns={columns}
          onSort={(sort) => {
            if (sort) {
              const segs = sort.split(' ');
              const col = segs[0].replace(/`/g, '');
              setOrder([[...col.split('.'), segs[1]]]);
            } else {
              setOrder(null);
            }
          }}
          data={vehicleRecallList?.rows}
          stacked
        />
      </div>

      {vehicleRecallList?.rows?.length > 0 && (
        <Pagination
          fullWidth
          variant="advanced"
          itemsPerPageOptions={[10, 25, 50]}
          itemsCount={vehicleRecallList.count}
          itemsPerPage={vehicleRecallList.limit}
          currentPage={vehicleRecallList.currentPage}
          onPageChange={handlePaginationChange}
        />
      )}

      {(!vehicleRecallList || vehicleRecallList?.rows?.length === 0) &&
        !loadingGetRecallCampaignDetails && (
          <div className="bg-gray-3 padding-y-5">
            <div className="text-center padding-y-4">
              <EmptyState alt="Image not available" hasBackground />
            </div>
            <div className="text-center text-bold">No vehicles available</div>
          </div>
        )}
    </div>
  );
};

export default VinRecallTables;
