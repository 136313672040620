import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { RadioButton } from '@gsa/afp-component-library';
import HeaderTagLookup from './header-tag-lookup';
import HeaderVINLookup from './header-vin-lookup';

/**
 * * This component is being used in
 * * src/components/forms/widgets/vehicle-picker-field.js
 */
const HeaderSearch = ({ onSelect, disabled, forExport }) => {
  const [selectedSearchOption, setSelectedSearchOption] = useState('tag');

  return (
    <div data-testid="header-search">
      <div
        className="display-flex flex-row grid-row flex-align-center"
        style={{ justifyContent: forExport ? 'left' : 'right' }}
      >
        <div className="display-flex flex-column tablet:grid-col-6">
          <RadioButton
            data-testid="search-license-plate-radio"
            className="text-normal"
            name="search by license plate"
            required={!forExport}
            value="yes"
            label="Search by license plate"
            onChange={() => setSelectedSearchOption('tag')}
            checked={selectedSearchOption === 'tag'}
            disabled={disabled}
          />
        </div>
        <div className="display-flex flex-column tablet:grid-col-4 flex-align-right">
          <RadioButton
            data-testid="search-vin-radio"
            className="text-normal"
            name="search by VIN"
            label="Search by VIN"
            value="no"
            required={!forExport}
            onChange={() => setSelectedSearchOption('vin')}
            checked={selectedSearchOption === 'vin'}
            disabled={disabled}
          />
        </div>
      </div>
      <div
        className={`flex-align-right tablet:grid-col-10 margin-top-neg-1 ${
          forExport ? '' : 'float-right'
        }`}
      >
        {selectedSearchOption === 'tag' ? (
          <HeaderTagLookup onSelect={onSelect} disabled={disabled} />
        ) : (
          <HeaderVINLookup onSelect={onSelect} disabled={disabled} />
        )}
      </div>
    </div>
  );
};

HeaderSearch.propTypes = {
  onSelect: PropTypes.func,
  disabled: PropTypes.bool,
  forExport: PropTypes.bool,
};
HeaderSearch.defaultProps = {
  onSelect: undefined,
  disabled: false,
  forExport: false,
};

export default HeaderSearch;
