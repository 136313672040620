import { gql } from '@apollo/client';
import {
  VehicleFieldsFull,
  VehicleCommentFields,
  VehicleRegistrationDetails,
  MotorPoolVehicleFields,
} from './fragments';

export const VEHICLE_DETAIL = gql`
  query GetVehicle($id: String!) {
    getVehicle(id: $id) {
      ...VehicleFieldsFull
    }
    # we might not need this query
    getTasks(
      filters: [
        {
          operator: "$and"
          conditions: [
            { operator: "$exact", key: "metaData.vin", value: [[$id]] }
          ]
        }
      ]
      limit: 5
      offset: 0
      order: []
    ) {
      rows {
        id
        taskID
        status
        title
        metaData
        updatedAt
      }
      count
      hasMore
    }
    # we might not need this query
    getAttachments(
      filter: { vehicles: [{ vin: $id }] }
      limit: 5
      offset: 0
      order: "updatedAt DESC"
    ) {
      rows {
        id
      }
      count
    }
  }
  ${VehicleFieldsFull}
`;

export const VEHICLE_DETAIL_MOTOR_POOL = gql`
  query GetVehicle($id: String!) {
    getVehicle(id: $id) {
      ...MotorPoolVehicleFields
      vin
      uuid: id
      tagNumber
      exemptPlate
      itemInventoryStatusCode
      ownershipTypeCode
      agencyCode
      customer {
        customerAgencyCode
      }
    }
  }
  ${MotorPoolVehicleFields}
`;

export const VEHICLE_REG_DETAIL = gql`
  query GetVehicle($id: String!) {
    getVehicle(id: $id) {
      ...VehicleRegistrationDetails
    }
    getTasks(
      filters: [
        {
          operator: "$and"
          conditions: [
            { operator: "$exact", key: "metaData.vin", value: [[$id]] }
          ]
        }
      ]
      limit: 5
      offset: 0
      order: []
    ) {
      rows {
        id
        taskID
        status
        title
        metaData
        updatedAt
      }
      count
      hasMore
    }
    getAttachments(
      filter: { vehicles: [{ vin: $id }] }
      limit: 5
      offset: 0
      order: "updatedAt DESC"
    ) {
      rows {
        id
      }
      count
    }
  }
  ${VehicleRegistrationDetails}
`;

export const GET_VEHICLE_COMMENTS = gql`
  query getVehicleComments($id: String!, $limit: Float, $offset: Float) {
    getVehicleComments(id: $id, limit: $limit, offset: $offset) {
      rows {
        ...VehicleCommentFields
      }
      count
      hasMore
    }
  }
  ${VehicleCommentFields}
`;

export const CREATE_VEHICLE_COMMENT = gql`
  mutation AddVehicleComment($id: String!, $comment: String!) {
    addVehicleComment(id: $id, comment: $comment) {
      ...VehicleCommentFields
    }
  }
  ${VehicleCommentFields}
`;

export const GET_VEHICLE_COMMENTS_PLUS = gql`
  query getVehicleCommentPlus($id: String!, $limit: Float, $offset: Float) {
    getVehicleCommentPlus(id: $id, limit: $limit, offset: $offset) {
      rows {
        createdBy
        deletedAt
        createdAt
        comment
        id
        author {
          fullName
        }
      }
      count
      hasMore
    }
  }
`;

export const GET_VEHICLE_RECALLS = gql`
  query GetVehicleRecalls(
    $vin: String!
    $status: String!
    $limit: Float
    $order: OrderBy!
  ) {
    getVehicleRecalls(id: $vin, status: $status, limit: $limit, order: $order) {
      rows {
        recallNhtsaID
        recallCode
        recallTypeCode
        recallStatusCode
        recallGsaNotifiedDate
        recallUpdatedDate
        recallClosedDate
      }
      count
    }
  }
`;

export const GET_VEHICLE_TASKS = gql`
  query GetVehicleTasks(
    $id: String!
    $limit: Int
    $offset: Int
    $order: OrderBy!
  ) {
    getTasks(
      filters: [
        {
          operator: "$and"
          conditions: [
            { operator: "$exact", key: "metaData.vin", value: [[$id]] }
          ]
        }
      ]
      limit: $limit
      offset: $offset
      order: $order
    ) {
      rows {
        id
        taskID
        status
        title
        metaData
        updatedAt
      }
      count
      hasMore
    }
  }
`;

export const GET_PURCHASE_ORDER_NUMBER = gql`
  query getPurchaseOrderNumber($vin: String!) {
    getPurchaseOrderNumber(vin: $vin)
  }
`;

export const VERIFY_PURCHASE_ORDER = gql`
  query verifyPurchaseOrder($vin: String!, $purchaseOrderNumber: String!) {
    verifyPurchaseOrder(vin: $vin, purchaseOrderNumber: $purchaseOrderNumber) {
      isVerified
      purchaseOrderNumber
    }
  }
`;

export const VERIFY_VIN = gql`
  query VerifyVin($id: String!) {
    verifyVin(id: $id) {
      ErrorCode
      RegisteredAt
      RegisteredBy
      Make
      MakeID
      Manufacturer
      Model
      ModelYear
      Fuel
      VehicleType
      Source
      vmsVehicle {
        modelYear
        makeColorName
        makeCode
        makeName
        modelCode
        modelName
        agencyCode
        ownershipTypeCode
        bureauCode
        subSectionCode
        fuelCode
        fuelName
        fastReportable
        nhtsaVerified
        internationalVin
        pre1981
        unverifiedVin
        exemptPlate
        itemType
        itemInventoryStatusCode
        tagNumber
        tagExpirationDate
        assetAcquisition {
          originalAcquisitionDate
        }
        assetContactAssociationPrimary {
          pointOfContactPrimary {
            contactId
            pocFirstName
            pocLastName
            pocFullName
            pocEmailAddress
            pocAddress1
            pocAddress2
            pocAddress3
            pocCity
            pocPostalCode
            pocStateCode
            pocPhone1CountryCode
            pocPhone1
            pocPhone1Ext
          }
        }
        assetContactAssociationSecondary {
          pointOfContactSecondary {
            contactId
            pocFirstName
            pocLastName
            pocFullName
            pocEmailAddress
            pocAddress1
            pocAddress2
            pocAddress3
            pocCity
            pocPostalCode
            pocStateCode
            pocPhone1CountryCode
            pocPhone1
            pocPhone1Ext
          }
        }
      }
    }
  }
`;

export const REGISTER_VEHICLE = gql`
  mutation RegisterVehicle($registration: RegistrationArgs!) {
    registerVehicle(registration: $registration) {
      id
      tagNumber
      updatedBy
      updatedAt
      exemptPlate
      primaryPoC {
        fullName
        email
      }
    }
  }
`;

export const UPDATE_VEHICLE_STATUS = gql`
  mutation UpdateAssetStatus(
    $id: String!
    $status: String!
    $statusOptionType: String
    $statusOptionalParams: AssetDisposalArg
  ) {
    updateAssetStatus(
      id: $id
      status: $status
      statusOptionType: $statusOptionType
      statusOptionalParams: $statusOptionalParams
    ) {
      id
      modelYear
      makeCode
      modelCode
      makeColorName
      vehicleTrim
      vehicleSeries
      fuelCode
      fastReportable
      itemInventoryStatusCode
      assetUsage {
        assetId
        vamExempt
        executiveFleet
        agencyUse1
        agencyUse2
        agencyUse3
        agencyUse4
      }
    }
  }
`;

export const CREATE_ASSET_CUSTODIAN = gql`
mutation CreateAssetCustodian($assetCustodianInput: AssetCustodianInput!) {
  createAssetCustodian(assetCustodianInput: $assetCustodianInput) {
    assetId
    entityType
    entityId
    vehicleLocation {
      locationName
      address1
      address2
      address3
      city
      stateCode
      postalCode
      countryCode
    }
  }
}
`;

export default {
  VEHICLE_DETAIL,
  GET_VEHICLE_COMMENTS,
  CREATE_VEHICLE_COMMENT,
  GET_VEHICLE_RECALLS,
  VERIFY_VIN,
  REGISTER_VEHICLE,
  UPDATE_VEHICLE_STATUS,
  CREATE_ASSET_CUSTODIAN,
};
