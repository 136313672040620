import vehicleFilters, {
  vehicleWithNestedFilters,
} from '../filter-structures/vehicle';

export default function ofm() {
  return [
    vehicleFilters.vehicleLicensePlateTypeahead,
    vehicleFilters.vehicleVINTypeahead,
    vehicleFilters.vehiclePoCTypeahead,
    vehicleFilters.vehicleMakeModel,
    vehicleFilters.vehicleOwnership,
    vehicleFilters.vehicleModelYear,
    vehicleFilters.registrationStaus,
  ];
}

export const ofmVehicleNestedFilters = () => {
  return [
    vehicleWithNestedFilters.vehicleLicensePlateTypeahead,
    vehicleWithNestedFilters.vehicleVINTypeahead,
    vehicleWithNestedFilters.vehiclePoCTypeahead,
    vehicleWithNestedFilters.vehicleMakeModel,
    vehicleWithNestedFilters.vehicleOwnership,
    vehicleWithNestedFilters.vehicleModelYear,
    vehicleWithNestedFilters.vehicleStaus,
    vehicleWithNestedFilters.registrationStaus,
  ];
};
