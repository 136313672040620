import React from 'react';
import { DetailsTable } from '@gsa/afp-component-library';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  VehicleRegistrationStatusTag,
  determineRegistrationStatus,
} from '../../../vehicle-registration-status-tag';
import { emdash } from 'components/common';

const RegistrationDetails = ({ vehicle }) => {
  const status = determineRegistrationStatus(vehicle);
  const getRegisteredBy = (vehicleDetail) => {
    let registeredBy = '—';
    if (status !== 'Unregistered') {
      if (vehicleDetail?.registeredByUser) {
        registeredBy = vehicleDetail.registeredByUser?.fullName;
      } else if (vehicleDetail?.tag?.activity?.performedByUser) {
        registeredBy = vehicleDetail?.tag?.activity?.performedByUser?.fullName;
      }
    }

    return registeredBy;
  };

  const regStatus = [
    'Registration status',
    <span data-testid="afp-registration__status">
      <VehicleRegistrationStatusTag
        data-testid="afp-registration__status"
        vehicle={vehicle}
      />
    </span>,
  ];

  const regBy = [
    /* TODO: unhappy scenario: registration user unknown. E.g.: etlservice or deleted user */
    'Registered by',
    <span data-testid="afp-registration__registeredBy">
      {getRegisteredBy(vehicle)}
    </span>,
  ];

  const getRegisteredOn = (vehicleDetail) => {
    let registeredOn = '';
    if (status !== 'Unregistered') {
      if (vehicleDetail?.registeredAt) {
        registeredOn = vehicleDetail.registeredAt;
      } else if (vehicleDetail?.tag?.activity?.createdAt) {
        registeredOn = vehicleDetail?.tag?.activity?.createdAt;
      }
    }

    return registeredOn ? (
      <span data-testid="afp-registration__registeredOn">
        {moment(registeredOn).format('L')} &bull;{' '}
        {moment(registeredOn).format('h:mm a')}
      </span>
    ) : (
      '—'
    );
  };

  const regOn = ['Registered on', <>{getRegisteredOn(vehicle)}</>];

  const receivedDate = [
    'Vehicle received date',
    moment(vehicle?.assetAcquisition?.originalAcquisitionDate).isValid()
      ? moment
          .utc(vehicle?.assetAcquisition?.originalAcquisitionDate)
          .format('MM/DD/YYYY')
      : emdash,
  ];

  let detailTableData = [regStatus, regBy, regOn];

  if (vehicle?.ownershipTypeCode === 'AO') detailTableData.push(receivedDate);

  return (
    <div className="bg-gray-3 radius-md padding-y-2 padding-x-4">
      <DetailsTable
        className="afp-registration__section_container"
        data={[...detailTableData]}
      />
    </div>
  );
};

RegistrationDetails.defaultProps = {
  vehicle: {},
};
RegistrationDetails.propTypes = {
  vehicle: PropTypes.shape({
    id: PropTypes.string,
    tagNumber: PropTypes.string,
    exemptPlate: PropTypes.bool,
    tag: PropTypes.shape({
      tagActivityTypeCode: PropTypes.string,
      expirationDate: PropTypes.string,
    }),
  }),
};
export default RegistrationDetails;
