import React from 'react';
import { Breadcrumbs } from '@gsa/afp-component-library';
import CustomerAccountHeader from '../widgets/customer-account-header';
import CustomerAccountListing from '../customer-account-listing';
import CustomerAccountFilterProvider from '../providers/filter-provider';
import { useCustomerAccounts } from '../providers/customer-account-provider';

const CustomerAccountHome = () => {
  const trail = [<a href={`${window.AFP_CONFIG.appURLs.home}/home`}>Home</a>];

  const title = 'Customer Accounts';
  const headingText =
    'This page provides a filterable list of all active GSA Leasing customer accounts. Use this page to view Account names, Agency POCs, FSR, and vehicle count for a given account.';

  const { optionsData } = useCustomerAccounts();

  return (
    <>
      <Breadcrumbs trail={trail} current={title} />
      <div className="margin-bottom-3">
        <CustomerAccountHeader
          title={title}
          headingText={headingText}
          showVehicleSearch
        />
      </div>
      {optionsData?.zones && (
        <CustomerAccountFilterProvider>
          <CustomerAccountListing />
        </CustomerAccountFilterProvider>
      )}
    </>
  );
};

export default CustomerAccountHome;
