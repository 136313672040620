export const teslaMapping = [
  ['ChargeStartDate', 'chargeStartDate', 'string'],
  ['ChargeStartTime', 'chargeStartTime', 'string'],
  ['ChargeStopDate', 'chargeStopDateInput', 'string'],
  ['ChargeStopTime', 'chargeStopTime', 'string'],
  ['Vin', 'vin', 'string'],
  ['Model', 'model', 'string'],
  ['Country', 'country', 'string'],
  ['SiteLocationName', 'siteLocationName', 'string'],
  ['CorrectedState', 'state', 'string'],
  ['Description', 'description', 'string'],
  ['QuantityBase kWh', 'quantityBaseKWh', 'string'],
  ['QuantityTier1', 'quantityTier1', 'string'],
  ['QuantityTier2', 'quantityTier2', 'string'],
  ['QuantityTier3', 'quantityTier3', 'string'],
  ['QuantityTier4', 'quantityTier4', 'string'],
  ['UnitCostBase', 'unitCostBase', 'string'],
  ['UnitCostTier1', 'unitCostTier1', 'string'],
  ['UnitCostTier2', 'unitCostTier2', 'string'],
  ['UnitCostTier3', 'unitCostTier3', 'string'],
  ['UnitCostTier4', 'unitCostTier4', 'string'],
  ['VAT', 'vat', 'string'],
  ['Total Exc VAT', 'totalExcVat', 'string'],
  ['Total Inc VAT', 'totalIncVat', 'string'],
  ['Cdr Id', 'cdrId', 'string'],
  ['Tax_S_Excise Tax', 'taxSExciseTax', 'string'],
  ['Tax_SA_Excise Tax', 'taxSAExciseTax', 'string'],
];
