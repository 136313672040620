/* eslint-disable import/prefer-default-export */
import { VMSOperations, VMSSubjects } from './consts';

export const canViewAOTagOrders = (ability) =>
  ability.can(VMSOperations.VIEW_ORDER_AO, VMSSubjects.ORDER);

export const canViewGFTagOrders = (ability) =>
  ability.can(VMSOperations.VIEW_ORDER_GF, VMSSubjects.ORDER);

export const canUpdateTagAOOrders = (ability) =>
  ability.can(VMSOperations.UPDATE_ORDER_AO, VMSSubjects.ORDER);

export const canUpdateTagOrders = (ability) =>
  ability.can(VMSOperations.UPDATE_ORDER, VMSSubjects.ORDER);

export const canUpdateTagAO = (ability) =>
  ability.can(VMSOperations.UPDATE_TAG_AO, VMSSubjects.TAG);

export const canUpdateTagGF = (ability) =>
  ability.can(VMSOperations.UPDATE_TAG_GF, VMSSubjects.TAG);

export const canUpdateGFVehicleAdmin = (ability) =>
  ability.can(VMSOperations.Update, VMSSubjects.GFVehicleAdmin);

export const canUpdateGFVehicleFSR = (ability) =>
  ability.can(VMSOperations.Update, VMSSubjects.GFVehicleFSR);

export const canUpdateGFVehicleCustomer = (ability) =>
  ability.can(VMSOperations.Update, VMSSubjects.GFVehicleCustomer);

export const canUpdateGFVehicleBM = (ability) =>
  ability.can(VMSOperations.Update, VMSSubjects.GFVehicleBusinessManagement);

export const canViewGFVehicleBM = (ability) =>
  ability.can(VMSOperations.View, VMSSubjects.GFVehicleBusinessManagement);

export const canRegisterVehicle = (ability) =>
  ability.can(VMSOperations.CREATE_VEHICLE, VMSSubjects.VEHICLE);

export const canViewGFVehicles = (ability) =>
  ability.can(VMSOperations.VIEW_VEHICLE_GF, VMSSubjects.VEHICLE);

export const canViewAOVehicles = (ability) =>
  ability.can(VMSOperations.View, VMSSubjects.VEHICLE);

export const canViewAOTags = (ability) =>
  ability.can(VMSOperations.VIEW_TAG_AO, VMSSubjects.TAG);

export const canViewGFTags = (ability) =>
  ability.can(VMSOperations.VIEW_TAG_GF, VMSSubjects.TAG);

export const canUpdateAOVehicle = (ability) =>
  ability.can(VMSOperations.UPDATE_VEHICLE_AO, VMSSubjects.VEHICLE);

export const canUpdateGFVehicle = (ability) =>
  ability.can(VMSOperations.UPDATE_VEHICLE_GF, VMSSubjects.VEHICLE);

export const canCreateVehicle = (ability) =>
  ability.can(VMSOperations.CREATE_VEHICLE, VMSSubjects.VEHICLE);

export const canManageFcReplacement = (ability) =>
  ability.can(VMSOperations.Manage, VMSSubjects.FC_REPLACEMENT);

export const canManageMotorPoolsVehicles = (ability) =>
  ability.can(VMSOperations.Manage, VMSSubjects.MOTOR_POOL);

export const canManageMotorPools = (ability) =>
  ability.can(VMSOperations.Manage, VMSSubjects.MOTOR_POOL);

export const canUpdateUNICORAdmin = (ability) =>
  ability.can(VMSOperations.Update, VMSSubjects.UNICOR_ADMIN);

export const isSiteAdmin = (ability) =>
  ability.can(VMSOperations.Manage, 'all');

export const canCreateGFMileage = (ability) =>
  ability.can(VMSOperations.Create, VMSSubjects.GFMileage);

// Vehicle Modification Abilities
export const canViewModification = (ability) =>
  ability.can(VMSOperations.View, VMSSubjects.MODIFICATION);
export const canCreateModification = (ability) =>
  ability.can(VMSOperations.Create, VMSSubjects.MODIFICATION);
export const canCancelModification = (ability) =>
  ability.can(VMSOperations.CANCEL, VMSSubjects.MODIFICATION);
export const canApproveModification = (ability) =>
  ability.can(VMSOperations.Approve, VMSSubjects.MODIFICATION);
export const canReviewModification = (ability) =>
  ability.can(VMSOperations.Review, VMSSubjects.MODIFICATION);

export const canCreateTagOrders = (ability) =>
  ability.can(VMSOperations.Create, VMSSubjects.ORDER);

export const canTransferVehicleOwnership = (ability) =>
  ability.can(VMSOperations.TRANSFER_OWNERSHIP, VMSSubjects.VEHICLE);

export const canUploadConsolidations = (ability) =>
  canViewAOVehicles(ability) &&
  canUpdateAOVehicle(ability) &&
  canTransferVehicleOwnership(ability);
