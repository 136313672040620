import { gql } from '@apollo/client';

export const GET_PAGINATED_RECALL_CAMPAIGNS = gql`
  query GetPaginatedRecallCampaigns(
    $order: OrderBy
    $limit: Float
    $offset: Float
    $filters: [Filter!]!
  ) {
    getPaginatedRecallCampaigns(
      order: $order
      limit: $limit
      offset: $offset
      filters: $filters
    ) {
      rows {
        id
        recallCode
        recallDescription
        isRemedyAvailable
        nhtsaCampaignId
        shouldParkOutside
        shouldStopDrive
        openRecallVehiclesCount {
          count
        }
      }
      count
      hasMore
    }
  }
`;
