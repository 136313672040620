/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Button, Spinner, useModal, Modal } from '@gsa/afp-component-library';
import _ from 'lodash';
import './search-result.css';
import { useVehicle } from 'components/vehicle-details/vehicle-context-provider';
import { AssignmentInfo } from './assignment-info';
import { SelectCustomer } from './select-customer';

const AssignVehicle = ({
  assignVehicleError,
  setModalError,
  currentFsr,
  vehicle,
  onSave,
  onClose,
  assignVehicleLoading,
}) => {
  const { showAlert } = useVehicle();
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [customer, setCustomer] = useState({});
  const [assignmentData, setAssignmentData] = useState({
    assetId: vehicle?.uuid,
  });
  const { openModal, closeModal } = useModal();

  useEffect(() => {
    openModal();
  }, []);

  useEffect(() => {
    if (!_.isEmpty(customer)) {
      setShowInfoModal(true);
      setAssignmentData({
        ...assignmentData,
        customerId: `${customer.customerId}`,
      });
    }
  }, [customer]);

  const close = () => {
    onClose && onClose();
    closeModal();
  };

  const save = () => {
    const toSave = {
      ...assignmentData,
      beginOdometer: +assignmentData.beginOdometer,
      endOdometer: +assignmentData.endOdometer,
      numberOfKeys: +assignmentData.numberOfKeys,
    };
    onSave && onSave(toSave);
  };

  const ModalHeader = () => {
    return (
      <>
        <div>
          <h1>Assign {vehicle?.tagNumber}</h1>
          <div>VIN {vehicle?.id}</div>
        </div>
      </>
    );
  };

  return (
    <div className="afp-modal-wrapper">
      <div className="afp-modal-overlay">
        <Modal
          variant="extra-large"
          actions={
            <>
              <Button
                variant="unstyled"
                id="cancelAssign"
                data-testId="cancelAssign"
                label="Cancel"
                onClick={close}
              />
              {showInfoModal && (
                <>
                  <Button
                    className="margin-left-2"
                    type="submit"
                    label="Submit"
                    form="assign-vehicle-form"
                    disabled={assignVehicleLoading}
                  />
                  {assignVehicleLoading && (
                    <Spinner
                      size="small"
                      aria-busy="true"
                      className="display-inline-block margin-left-2"
                    />
                  )}
                </>
              )}
            </>
          }
          title={<ModalHeader data-testid="header-vehicle-info" />}
          onClose={close}
        >
          {assignVehicleError?.context === 'assignVehicle' &&
            showAlert(assignVehicleError, setModalError)}
          {!showInfoModal && (
            <SelectCustomer setCustomer={setCustomer} currentFsr={currentFsr} />
          )}
          {showInfoModal && (
            <AssignmentInfo
              setModalError={setModalError}
              save={save}
              assignmentData={assignmentData}
              setAssignmentData={setAssignmentData}
              customer={customer}
            />
          )}
        </Modal>
      </div>
    </div>
  );
};

export default AssignVehicle;
