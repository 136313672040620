/* eslint-disable react/prop-types */
/* eslint-disable filenames/match-exported */
import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Breadcrumbs, PageTitle, Button } from '@gsa/afp-component-library';
import useCanPerformActions from 'hooks/use-can-perform-actions';

import BannerMessage from 'utilities/banner-message';
import { useRecallCampaignDetailPage } from './providers';

import DetailList from './widgets/detail-list';
import VinRecallTables from './widgets/vin-recall-tables';

const title = 'Campaign details';

const RecallCampaignDetail = ({ campaignId }) => {
  const location = useLocation();
  const history = useHistory();
  const {
    getRecallCampaignDetails,
    initialPaginationState,
    getRecallCampaign,
    vehicleRecallList,
    RecallCampaignDetailData,
    loadingGetRecallCampaignDetails,
    setBannerMsg,
    dispatchAction,
  } = useRecallCampaignDetailPage();
  
  useEffect(() => {
    getRecallCampaignDetails({
      variables: {
        limit: initialPaginationState.limit,
        offset: initialPaginationState.offset,
        campaignId,
        order: 'createdAt DESC',
      },
    });

    getRecallCampaign({
      variables: {
        campaignId,
      },
    });
    if (location?.state?.totalCount) {
      setBannerMsg({
        type: 'success',
        message:
          // eslint-disable-next-line no-multi-str
          location?.state?.totalCount === 'x'
            ? 'Campaign has been updated'
            : `Recall data for ${location?.state?.totalCount} VIN(s) has been saved.`,
      });
      window.history.replaceState({}, '');
    }
    dispatchAction('SET_CURRENT_CAMPAIGN_ID', campaignId);
  }, []);

  const canPerformActions = useCanPerformActions();
  const canManageRecallCampaigns = canPerformActions.canManageRecallCampaigns();

  return (
    <article>
      <header>
        <Breadcrumbs
          trail={[
            <a href={`${window.AFP_CONFIG.appURLs.home}/home`}>Home</a>,
            <Button
              variant="unstyled"
              onClick={history.goBack}
              label="Recall campaigns"
            />,
          ]}
          current={title}
        />
        <BannerMessage useProvider={useRecallCampaignDetailPage} />
        <PageTitle title={title} />
      </header>
      <DetailList
        RecallCampaignDetailData={RecallCampaignDetailData}
        canManageRecallCampaigns={canManageRecallCampaigns}
      />
      <VinRecallTables
        campaignId={campaignId}
        loadingGetRecallCampaignDetails={loadingGetRecallCampaignDetails}
        vehicleRecallList={vehicleRecallList}
        canManageRecallCampaigns={canManageRecallCampaigns}
        getRecallCampaignDetails={getRecallCampaignDetails}
      />
    </article>
  );
};

export default RecallCampaignDetail;
