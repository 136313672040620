import React, { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { AddressConfirmationModal, Spinner } from '@gsa/afp-component-library';
import { useVehicle } from '../../vehicle-context-provider';
import useCanPerformActions from '../../../../hooks/use-can-perform-actions';
import useUser from '../../../../utilities/use-user';
import PointOfContact from './point-of-contact';
import {
  checkOtherPoCEmail,
  formatAddressForSubmission,
  formatAddressForValidation,
  isEmailDifferent,
} from '../../../registration-details/helpers/utils';
import {
  UPDATE_VEHICLE_POC,
  VALIDATE_ADDRESS,
} from '../../../../services/data-layer';
import { validEmail } from '../../../../utilities/validation';
import PocEdit from './poc-edit';
import PocEmailChanger from './poc-email-change';

const ContactInformation = () => {
  const { vehicle, refetchVehicle, setAlertMessages } = useVehicle();
  const canPerformActions = useCanPerformActions();
  const { isRole } = useUser();
  const hasFMVRSrole = isRole('FMVRSAdminRole');
  const canEditPoc = canPerformActions.canEditPoc(vehicle, hasFMVRSrole);
  // used to show/hide different modals
  const [editingEmail, setEditingEmail] = useState(false);
  const [isEditingPoC, setIsEditingPoC] = useState(false); // toggles the editing modal
  const [isNewEmailSelected, setIsNewEmailSelected] = useState(false);
  const [validatedAddress, setValidatedAddress] = useState(null);
  const [editPoc, setEditPoc] = useState(null);
  const [refetchingData, setRefetchingData] = useState(false);

  const constructAddress = (addr) => {
    if (addr?.hasOwnProperty('address')) {
      const {
        address: primaryAddress,
        address2: secondaryAddress,
        city,
        countryCode,
        state: stateCode,
        zip: postalCode,
      } = addr;
      return {
        primaryAddress,
        secondaryAddress,
        city,
        stateCode,
        countryCode,
        postalCode,
      };
    }

    return addr;
  };

  const updatePoCAndReg = (addr) => {
    const pocWithValidAddr = {
      ...editPoc.poc,
      ...constructAddress(addr),
      ind: editPoc.ind,
    };
    updateVehPoC(pocWithValidAddr);
  };

  const checkAddress = (poc) => {
    const { ind, ...rest } = poc;
    const payload = formatAddressForValidation(rest);
    validateAddress({ variables: payload });
  };

  const [validateAddress, { error, loading }] = useLazyQuery(VALIDATE_ADDRESS, {
    fetchPolicy: 'no-cache',

    onCompleted: (data) => {
      if (data?.validateAddress) {
        const { address, address2, city, state, zip } = data.validateAddress;
        setValidatedAddress({
          ...editPoc,
          primaryAddress: address,
          secondaryAddress: address2,
          city,
          stateCode: state,
          postalCode: zip,
        });
      } else {
        setEditPoc(null);
      }
    },
  });

  const [
    updateVehiclePoC,
    { data: updatedVehicleWithPoc, loading: updatingVehiclePoc },
  ] = useMutation(UPDATE_VEHICLE_POC, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (data) {
        let messages = [];
        // legacy data can have two invalid e-mail addresses.  An alert displays if a user updates registration without correcting that.  This will leave the alert or dismiss it
        if (
          checkOtherPoCEmail(
            vehicle?.primaryPoC?.email,
            vehicle?.secondaryPoC?.email,
            editPoc,
          )
        ) {
          messages.push({
            type: 'warning',
            message:
              'The vehicle must have two unique points of contacts with valid e-mail addresses. Please update below.',
          });
        }
        messages.push({
          type: 'success',
          message: 'The vehicle point of contacts has been updated.',
        });
        setAlertMessages(messages);
      }
    },
    onError: (error) => {
      setAlertMessages([
        {
          type: 'internalError',
          message: error.message,
        },
      ]);
    },
  });

  const updatePoC = (poc) => {
    const { ind, ...rest } = poc;
    return updateVehiclePoC({
      variables: {
        vin: vehicle.id,
        pocNumber: ind,
        poc: _.omit(rest, [
          '__typename',
          'fullName',
          'validated',
          'tertiaryAddress',
        ]),
      },
    });
  };

  const updateVehPoCWithoutAddressCheck = (poc) => {
    updatePoC(poc).then(() => {
      // if updating the POC succeeds and the address will not be checked, the POC is no longer being edited
      setEditPoc(null);
    });
  };
  const updateVehPoCAndCheckAddress = (poc) => {
    updatePoC(poc).then(() => {
      return checkAddress(poc);
    });
  };

  useEffect(() => {
    if (updatedVehicleWithPoc?.updateVehiclePoC?.id) {
      setRefetchingData(true);
      refetchVehicle(updatedVehicleWithPoc?.updateVehiclePoC?.id).finally(
        () => {
          setRefetchingData(false);
        },
      );
    }
  }, [updatedVehicleWithPoc]);

  // If a user clicks "Edit" on a POC that is the same as the other POC, has an empty e-mail, or has an invalid e-mail, then the edit e-mail modal should open instead of the edit POC one
  const shouldOpenEmailEdit = (primEmail, secEmail, editPocEmail) => {
    return !isEmailDifferent(primEmail, secEmail) || !validEmail(editPocEmail);
  };

  const [uspsValidationError, setUspsValidationError] = useState(false);

  useEffect(() => {
    setUspsValidationError(!!error);
  }, [error]);

  return (
    <>
      <div className="grid-row grid-gap">
        <div className="tablet:grid-col-6">
          <h4 className="title-s-caps text-primary margin-bottom-2">
            POINT OF CONTACT #1
          </h4>
          <PointOfContact
            poc={{ ...vehicle?.primaryPoC, ind: 1 }}
            canEdit={canEditPoc}
            handlePoCEditOpen={() => {
              // set this POC as the one being edited
              setEditPoc({ ...vehicle?.primaryPoC, ind: 1 });
              // if POC 1 is the same as 2 or if the POC is empty, open the email editor instead of the edit modal
              if (
                shouldOpenEmailEdit(
                  vehicle?.primaryPoC?.email,
                  vehicle?.secondaryPoC?.email,
                  vehicle?.primaryPoC?.email,
                )
              ) {
                setEditingEmail(true);
              } else {
                // Open the edit modal
                setIsEditingPoC(true);
              }
            }}
          />
        </div>
        <div className="tablet:grid-col-6">
          <h4 className="title-s-caps text-primary margin-bottom-2">
            POINT OF CONTACT #2
          </h4>
          <PointOfContact
            poc={{ ...vehicle?.secondaryPoC, ind: 2 }}
            canEdit={canEditPoc}
            handlePoCEditOpen={() => {
              // set this POC as the one being edited
              setEditPoc({ ...vehicle?.secondaryPoC, ind: 2 });
              // if POC 1 is the same as 2 or if the POC is empty, open the email editor instead of the edit modal
              if (
                shouldOpenEmailEdit(
                  vehicle?.primaryPoC?.email,
                  vehicle?.secondaryPoC?.email,
                  vehicle?.secondaryPoC?.email,
                )
              ) {
                setEditingEmail(true);
              } else {
                setIsEditingPoC(true);
              }
            }}
          />
        </div>
      </div>
      <PocEdit
        poc={editPoc}
        isEditingPoC={isEditingPoC}
        handleEditClose={() => {
          // close the edit modal and clear the POC being edited
          setIsEditingPoC(false);
          setEditPoc(null);
          setIsNewEmailSelected(false);
        }}
        isNewEmailSelected={isNewEmailSelected}
        handleEditSave={(payload, hasAddressChanged) => {
          const updated = { ...editPoc, ...payload };
          setIsEditingPoC(false);
          setIsNewEmailSelected(false);
          // update the POC being edited in local state
          setEditPoc(updated);
          // Only validates with USPS when it's a USPS address and the address fields have changed
          if (updated?.countryCode === 'US' && hasAddressChanged) {
            updateVehPoCAndCheckAddress(updated);
          } else {
            updateVehPoCWithoutAddressCheck(updated);
          }
        }}
        handleOpenChangeEmail={() => {
          setEditingEmail(true);
          setIsEditingPoC(false);
        }}
        handleSaveEmailChange={updatePoCAndReg}
      />

      <AddressConfirmationModal
        validating={loading}
        hasValidationError={uspsValidationError}
        addressToValidate={formatAddressForValidation(editPoc)}
        validatedAddress={formatAddressForValidation(validatedAddress)}
        onSave={(d) => {
          setValidatedAddress(null);
          const payload = formatAddressForSubmission(d);
          // Don't need to validate with USPS from confirmation modal so just save selection
          if (!uspsValidationError) updatePoC({ ...editPoc, ...payload });
          setUspsValidationError(false);
        }}
        onCancel={() => {
          // closes the confirmation modal and opens the editing one when a user chooses "Edit address"
          setValidatedAddress(null);
          setUspsValidationError(false);
          setIsEditingPoC(true);
        }}
      />
      <PocEmailChanger
        handleCancel={() => {
          setEditingEmail(false);
          // Only re-direct to the editing modal if the POC is valid, not blank, and is not the same as the other one
          if (
            isEmailDifferent(
              vehicle?.secondaryPoC?.email,
              vehicle?.primaryPoC?.email,
            ) &&
            validEmail(editPoc?.email)
          ) {
            setIsEditingPoC(true);
          }
        }}
        handleSelect={(params) => {
          // set the changes as the POC being edited, close the email editor and open the POC edit modal
          setEditPoc(params);
          setIsNewEmailSelected(true);
          setEditingEmail(false);
          setIsEditingPoC(true);
        }}
        poc={editPoc}
        otherPocEmail={
          editPoc?.ind === 1
            ? vehicle?.secondaryPoC?.email
            : vehicle?.primaryPoC?.email
        }
        editingEmail={editingEmail}
        matchingEmail={
          !isEmailDifferent(
            vehicle?.secondaryPoC?.email,
            vehicle?.primaryPoC?.email,
          )
        }
        invalidPoc={!validEmail(editPoc?.email)}
      />

      {(updatingVehiclePoc || refetchingData) && (
        <Spinner aria-busy="true" className="loading_backdrop" size="large" />
      )}
    </>
  );
};

export default ContactInformation;
