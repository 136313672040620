import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Spinner, NotFound } from '@gsa/afp-component-library';
import {
  PageMode,
  RepairFormContext,
  RepairOrderProvider,
  ServiceLinesProvider,
  useRepairOrder,
} from './providers';
import { Header, RepairView, RepairEdit } from './widgets';

const EditPage = ({ mode, repairOrder, setPageError }) => (
  <RepairFormContext data={repairOrder}>
    <RepairEdit mode={mode} data={repairOrder} setPageError={setPageError} />
  </RepairFormContext>
);
const ViewPage = ({ repairOrder }) => <RepairView data={repairOrder} />;

const Page = ({ vin, orderNumber, mode }) => {
  const {
    transactionDetails,
    repairOrder,
    paymentInfo,
    vendorInfo,
    initPage,
    vehicle,
    agencyInfo,
    repairComment,
    savingRepairOrder,
    setError: setPageError,
  } = useRepairOrder();

  useEffect(() => {
    initPage({ vin, orderNumber, mode });
  }, []);

  const isEditing = () => mode !== PageMode.View;
  const isCreating = () => mode === PageMode.New;
  const isVehicleActive = () =>
    vehicle?.inventoryStatus?.id !== 'MS' &&
    vehicle?.inventoryStatus?.id !== 'SD';

  if (
    (!isCreating() && (!repairOrder || !vehicle)) ||
    (isCreating() && !vehicle)
  ) {
    return <Spinner />;
  }

  if (!isVehicleActive() && isEditing()) {
    return <NotFound />;
  }

  const data = {
    transactionDetails,
    repairOrder,
    paymentInfo,
    vendorInfo,
    vehicle,
    agencyInfo,
    repairComment,
  };

  return (
    <div className="grid-row grid-gap-2">
      <section className="grid-col-12">
        <Header />
      </section>
      <section className="grid-col-12">
        {!isEditing() && <ViewPage repairOrder={data} />}
        {isEditing() && (
          <EditPage
            mode={mode}
            repairOrder={data}
            setPageError={setPageError}
          />
        )}
      </section>
      {savingRepairOrder && (
        <Spinner className="loading_backdrop" size="large" />
      )}
    </div>
  );
};

Page.propTypes = {
  vin: PropTypes.string.isRequired,
  mode: PropTypes.string.isRequired,
  orderNumber: PropTypes.string,
};

const RepairOrderPage = () => {
  const params = useParams();
  const vin = decodeURIComponent(params.vin);
  const orderNumber = decodeURIComponent(params.order || '');
  const mode = params.mode;

  return (
    <RepairOrderProvider>
      <ServiceLinesProvider>
        <Page vin={vin} orderNumber={orderNumber} mode={mode} />
      </ServiceLinesProvider>
    </RepairOrderProvider>
  );
};

export default RepairOrderPage;
