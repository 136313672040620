import React, { useEffect, useState } from 'react';
import { Redirect, Link } from 'react-router-dom';
import classnames from 'classnames';
import {
  Breadcrumbs,
  PageTitle,
  LinkCard,
  LinkCardGroup,
  Spinner,
  Icon,
} from '@gsa/afp-component-library';
import { InternalError, useAppAbility, Can } from '@gsa/afp-shared-ui-utils';
import { useLazyQuery } from '@apollo/client';
import './index.css';
import { GET_REPORT_AGENCIES_BY_PERMISSION } from 'services/data-layer';
import { VMSOperations, VMSSubjects } from 'utilities/consts';
import { canUpdateGFVehicle } from 'utilities/authorization';

const title = 'Vehicle Reports Manager';
const trail = [<a href={`${window.AFP_CONFIG.appURLs.home}/home`}>Home</a>];

const sectionTitleClasses = classnames(
  'title-s-caps',
  'text-primary',
  'padding-bottom-2',
);
const cardIconWrapperClasses = classnames(
  'padding-1',
  'bg-white',
  'radius-lg',
  'display-flex',
  'flex-justify-center',
  'flex-align-center',
);
const cardIconClasses = classnames(
  'usa-icon--size-7',
  'text-primary-light',
  'margin-y-1',
);
const cardTitleClasses = classnames(
  'text-normal',
  'title-m',
  'text-no-uppercase',
);

const VehicleReports = () => {
  const [agencies, setAgencies] = useState(undefined);
  const [error, setError] = useState(undefined);
  const ability = useAppAbility();
  const hasGFUpdateAbility = canUpdateGFVehicle(ability);

  const [agenciesQuery, { loading }] = useLazyQuery(
    GET_REPORT_AGENCIES_BY_PERMISSION,
    {
      onError: (err) => {
        setError(err);
      },
      onCompleted: (data) => {
        const agenciesData = data?.getAgenciesByPermission || [];
        setAgencies(agenciesData);
        setError(undefined);
      },
    },
  );

  const getAgencies = () => {
    const operation = hasGFUpdateAbility
      ? VMSOperations.UPDATE_VEHICLE_GF
      : VMSOperations.Update;
    const subject = VMSSubjects.VEHICLE;
    agenciesQuery({
      variables: {
        operation,
        subject,
        // optin is not required for GF vehicles
        feature: hasGFUpdateAbility ? undefined : 'Basic',
      },
    });
  };

  useEffect(() => {
    getAgencies();
  }, []);

  if (loading) {
    return <Spinner size="small" data-testid="report-spinner" />;
  }

  if (agencies?.length === 0 && !error) {
    return <Redirect to="/not-opted" />;
  }

  return (
    <>
      <Breadcrumbs trail={trail} current={title} />
      {error && <InternalError />}
      <PageTitle title={title} />
      <div className="grid-row grid-gap">
        <div className="tablet:grid-col-5">
          <h2 className={sectionTitleClasses}>BULK UPDATES</h2>
          <p>This tool allows you to bulk update the vehicle data.</p>
          <ol>
            <li>
              First export the template provided, complete all the required
              steps
            </li>
            <li>Populate the template with vehicle data in your desktop</li>
            <li>Import the template back to the system</li>
          </ol>
        </div>
        <div className="tablet:grid-col-7">
          <LinkCardGroup>
            <LinkCard className="report_card-height" cardGridWidth={12}>
              <LinkCard.Media>
                <div className={cardIconWrapperClasses}>
                  <Icon
                    iconName="custom_download"
                    type="custom"
                    className={cardIconClasses}
                  />
                </div>
              </LinkCard.Media>
              <LinkCard.Body>
                <LinkCard.Header>
                  <Link
                    to="/reports/export?type=template"
                    className={cardTitleClasses}
                  >
                    Export template for bulk updates
                  </Link>
                </LinkCard.Header>
                <LinkCard.Text>
                  <span className="padding-right-2">
                    Download vehicle templates for Customer Driven Data (CDD),
                    mileage and expense.
                  </span>
                </LinkCard.Text>
              </LinkCard.Body>
            </LinkCard>
            <LinkCard className="report_card-height" cardGridWidth={12}>
              <div className={cardIconWrapperClasses}>
                <Icon
                  iconName="upload"
                  type="custom"
                  className={cardIconClasses}
                />
              </div>
              <LinkCard.Body>
                <LinkCard.Header>
                  <Link to="/reports/bulk-import" className={cardTitleClasses}>
                    Import template for bulk updates
                  </Link>
                </LinkCard.Header>
                <LinkCard.Text>
                  <span>
                    Use the exported template to import and bulk update vehicle
                    reports.
                  </span>
                </LinkCard.Text>
              </LinkCard.Body>
            </LinkCard>
          </LinkCardGroup>
        </div>
      </div>
      <div className="grid-row grid-gap border-top border-base-light padding-top-3">
        <div className="tablet:grid-col-5">
          <h2 className={sectionTitleClasses}>REPORTS</h2>
          <p>
            This tool allows you to download vehicle reports to your desktop via
            an email from the system.
          </p>
        </div>
        <div className="tablet:grid-col-7">
          <LinkCardGroup>
            <LinkCard className="report_card-height" cardGridWidth={12}>
              <LinkCard.Media>
                <div className={cardIconWrapperClasses}>
                  <Icon
                    iconName="excel_icon"
                    type="custom"
                    className={cardIconClasses}
                  />
                </div>
              </LinkCard.Media>
              <LinkCard.Body>
                <LinkCard.Header>
                  <Link to="/reports/export" className={cardTitleClasses}>
                    Run reports
                  </Link>
                </LinkCard.Header>
                <LinkCard.Text>
                  <span className="padding-right-2">
                    Download vehicle reports including FAST, vehicle inventory,
                    expenses, fuel usage and repairs.
                  </span>
                </LinkCard.Text>
              </LinkCard.Body>
            </LinkCard>
            <Can I={VMSOperations.UPDATE_VEHICLE_GF} a={VMSSubjects.VEHICLE}>
              <LinkCard className="report_card-height" cardGridWidth={12}>
                <LinkCard.Media>
                  <div className={cardIconWrapperClasses}>
                    <Icon
                      iconName="excel_icon"
                      type="custom"
                      className={cardIconClasses}
                    />
                  </div>
                </LinkCard.Media>
                <LinkCard.Body>
                  <LinkCard.Header>
                    <Link
                      to="/reports/scheduled-reports"
                      className={cardTitleClasses}
                    >
                      View and edit scheduled reports
                    </Link>
                  </LinkCard.Header>
                  <LinkCard.Text>
                    <span className="padding-right-2">
                      View and edit scheduled reports
                    </span>
                  </LinkCard.Text>
                </LinkCard.Body>
              </LinkCard>
            </Can>
          </LinkCardGroup>
        </div>
      </div>
    </>
  );
};

export default VehicleReports;
