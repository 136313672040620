import React, { useState, useEffect } from 'react';
import { useTitle } from '@gsa/afp-shared-ui-utils';
import { useHistory } from 'react-router-dom';
import ServerErrorMessage from 'components/error-handlers/server-error-message';
import { Alert, PageTitle } from '@gsa/afp-component-library';
import { useSchedulesContext } from './schedules-provider';
import { CREATE_SCHEDULE_MESSAGE } from './messages';

const SchedulesHeader = () => {
  const history = useHistory();
  useTitle('Scheduled Reports');
  const { alertMessage } = useSchedulesContext();
  const { header, message, type, context, error } = alertMessage;
  const [createdScheduleAlert, setCreatedScheduleAlert] = useState({});
  const [scheduleName, setScheduleName] = useState();

  useEffect(() => {
    const { createdSchedule, updated } = history.location?.state || {};
    setScheduleName(createdSchedule);
    if (createdSchedule) {
      setCreatedScheduleAlert({
        type: updated ? 'updated' : 'created',
        name: createdSchedule,
      });
      history.replace({
        pathname: 'scheduled-reports',
        state: { createdSchedule: '' },
      });
    }
  }, []);

  const showAlert = () => {
    if (context && context === 'scheduleListing') {
      return (
        <div className="margin-top-2" data-testid="scheduled-reports-alert">
          <Alert type={type} heading={header || null}>
            {message}
          </Alert>
        </div>
      );
    }
    if (createdScheduleAlert.name) {
      return (
        <div className="margin-top-2" data-testid="scheduled-reports-alert">
          <Alert type="success" heading={header || null}>
            {CREATE_SCHEDULE_MESSAGE.body(createdScheduleAlert)}
          </Alert>
        </div>
      );
    }
    if (type === 'error' && !context) {
      return <ServerErrorMessage graphQLErrors={error} />;
    }
    return null;
  };

  return (
    <>
      {showAlert()}
      <header>
        <PageTitle title="Scheduled Reports" />
        <div className="margin-bottom-4 tablet:grid-col-7">
          <p>
            For more information, please see the scheduled report&nbsp;
            <a
              href={`${process.env.PUBLIC_URL}/GSAFleet_gov-Reports-User-Guide.pdf`}
              className="mobile:display-block"
              data-testid="report-user-guide"
              target="_blank"
              rel="noopener noreferrer"
            >
              user guide
            </a>{' '}
            and&nbsp;
            <a
              href="https://vimeo.com/gsavisualcommunications/review/885699144/1c990efd14"
              className="mobile:display-block"
              data-testid="report-user-video"
              target="_blank"
              rel="noopener noreferrer"
            >
              How to
            </a>{' '}
            video.
          </p>
        </div>
      </header>
    </>
  );
};

export default SchedulesHeader;
