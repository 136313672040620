/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { InternalError } from '@gsa/afp-shared-ui-utils';
import { Alert } from '@gsa/afp-component-library';

const useAlertMessages = () => {
  const [alertMessages, setAlertMessages] = useState([]);

  const AlertsComponent = () => {
    return alertMessages.length === 0
      ? null
      : alertMessages.map(({ type, message, heading }) => {
          if (type === 'internalError') {
            return <InternalError />;
          }
          return (
            <Alert type={type} heading={heading}>
              {message}{' '}
            </Alert>
          );
        });
  };

  return {
    alertMessages,
    setAlertMessages,
    AlertsComponent,
  };
};

export { useAlertMessages };
