import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { isEmpty } from 'lodash';
import { GET_NESTED_LICENSE_PLATES } from '../../../services/data-layer';
import userFilterManager from '../../user-filters/index';

const expNestedFilter = () => {
  const currentDate = new Date()
    .toISOString()
    .split('T')[0]
    .replace(/-/g, '')
    .substring(0, 6);
  return [
    {
      key: 'expirationDate',
      operator: 'GTE',
      value: currentDate,
    },
    {
      // needed to allow legacy data with 0 exp date to be retrieved
      key: 'expirationDate',
      operator: 'EQ',
      value: 0,
    },
  ];
};

export const INIT_NESTED_FILTERS = {
  value: expNestedFilter(),
  operator: 'OR',
};

export const INIT_LIMIT = 10;
export const INIT_OFFSET = 0;
export const INIT_SORT_ORDER = [['updatedAt', 'ASC']];
export const INIT_SELECTED_ROWS = {};
export const INIT_DESTRUCTION_DATA = {
  shippingLabel: 'no',
  numBoxes: '',
  trackingNumber: '',
  agencyName: '',
  bureauName: '',
  officeName: '',
  trackingNumbers: [],
  reasonForReturning: '',
};

export default function useLicensePlateManager(alertUtils) {
  const [licensePlates, setLicensePlates] = useState(null);
  const [filters, setFilters] = useState([]);
  const [appliedFilters, setAppliedFilters] = useState([]);
  const [limit, setLimit] = useState(INIT_LIMIT);
  const [offset, setOffset] = useState(INIT_OFFSET);
  const [sortOrder, setSortOrder] = useState(INIT_SORT_ORDER);
  const [selectedRows, setSelectedRows] = useState(INIT_SELECTED_ROWS);
  const { userFilter } = userFilterManager('license-plates');

  const [
    getNestedTags,
    {
      data: nestedLicensePlate,
      refetch: refetchNestedLicensePlates,
      loading: loadingNestedlicensePlate,
      error: nestedLicensePlateError,
    },
  ] = useLazyQuery(GET_NESTED_LICENSE_PLATES, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (nestedLicensePlate && nestedLicensePlate.getNestedTags) {
      alertUtils.closeAlert();
      setLicensePlates(nestedLicensePlate.getNestedTags);
    }
  }, [nestedLicensePlate]);

  useEffect(() => {
    if (!isEmpty(filters)) {
      getNestedTags({
        variables: {
          limit,
          offset,
          order: sortOrder,
          filters: filters[0],
        },
      });
    }
  }, [limit, offset, sortOrder, filters]);

  return {
    appliedFilters,
    setAppliedFilters,
    getNestedTags,
    refetchNestedLicensePlates,
    loadingNestedlicensePlate,
    nestedLicensePlateError,
    nestedLicensePlate,
    licensePlates,
    setLicensePlates,
    filters,
    setFilters,
    limit,
    setLimit,
    offset,
    setOffset,
    sortOrder,
    setSortOrder,
    selectedRows,
    setSelectedRows,
    filterStructure: userFilter,
    loading: loadingNestedlicensePlate,
  };
}
