import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { Spinner, FilterPanel } from '@gsa/afp-component-library';
import { sortBy, uniqBy } from 'lodash';
import { GET_VEHICLE_FILTERABLE_MAKES } from '../../../services/data-layer';

const { FilterPanelFilterShape, FilterPanelField } = FilterPanel;

const placeholderOption = {
  value: '',
  label: '- Select make -',
  defaultValue: true,
};

const VehiclesMakeFilterItem = ({ filter }) => {
  const [options, setOptions] = useState([placeholderOption]);

  const [
    fetchVehicleFilterableMakes,
    { data: vehicleFilterableMakes, loading: vehicleFilterableMakesLoading },
  ] = useLazyQuery(GET_VEHICLE_FILTERABLE_MAKES, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    fetchVehicleFilterableMakes();
  }, []);

  useEffect(() => {
    if (vehicleFilterableMakes?.getVehicleFilterableMakes?.makes) {
      const makeOptions = sortBy(
        vehicleFilterableMakes.getVehicleFilterableMakes.makes,
        'id',
      ).map(({ makeCode, makeName }) => ({
        value: makeCode,
        label: makeName,
      }));
      const uniqueMakeOptions = uniqBy(makeOptions, (m) => m.value);
      setOptions(
        uniqueMakeOptions
          ? [placeholderOption, ...uniqueMakeOptions]
          : [placeholderOption],
      );
    }
  }, [vehicleFilterableMakes]);

  if (vehicleFilterableMakesLoading) {
    return <Spinner />;
  }

  return (
    <FilterPanelField
      key={[filter.key]}
      filter={{
        ...filter,
        type: 'select',
        options: options,
      }}
    />
  );
};

VehiclesMakeFilterItem.propTypes = {
  filter: PropTypes.shape(FilterPanelFilterShape).isRequired,
};

export default VehiclesMakeFilterItem;
