/* eslint-disable react/prop-types */
import React from 'react';
import moment from 'moment';
import classnames from 'classnames';
import { emdash } from 'components/common';

export const MotorPoolSubDetail = ({ detail }) => {
  const { fullName, createdAt, isPublic, timezone } = detail;
  const borderClasses = classnames(
    'border-bottom border-base-lighter padding-y-1',
  );
  return (
    <div className="grid-row grid-gap flex-justify">
      <div className="tablet:grid-col-6">
        <div
          className={`${borderClasses} display-flex flex-justify`}
          data-testid="data-section"
        >
          <strong>Dispatcher name</strong>
          {fullName || emdash}
        </div>
        <div
          className={`${borderClasses} display-flex flex-justify`}
          data-testid="data-section"
        >
          <strong>Pool is public</strong>
          {isPublic ? 'Yes' : 'No'}
        </div>
      </div>
      <div className="tablet:grid-col-6">
        <div
          className={`${borderClasses} display-flex flex-justify`}
          data-testid="data-section"
        >
          <strong>Pool created date</strong>
          {moment(createdAt).format('L')}
        </div>
        <div
          className={`${borderClasses} display-flex flex-justify`}
          data-testid="data-section"
        >
          <strong>Time zone</strong>
          {timezone}
        </div>
      </div>
    </div>
  );
};
