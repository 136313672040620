/* eslint-disable filenames/match-exported */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable filenames/match-regex */
import React from 'react';
import { Button, Alert } from '@gsa/afp-component-library';

const DeleteOfficeDetailErrorModalSchema = {
  title: () => '',
  content: () => {
    return (
      <>
        <Alert showClose type="error" heading="This office cannot be deleted">
          <ul>
            <li>if agency owned vehicle are assigned, or</li>
            <li>leasing customer accounts are assigned, or</li>
            <li>active users are associated to this account</li>
          </ul>
        </Alert>

        <p>Please resolve the issue(s) to delete the office.</p>
      </>
    );
  },
  action: ({ resetCurrentModal }) => (
    <>
      <Button
        className="margin-right-2"
        onClick={resetCurrentModal}
        data-testid="motor-pool-export-cancel-button"
        label="Ok"
      />
    </>
  ),
  onClose: ({ resetCurrentModal }) => {
    resetCurrentModal();
  },
};

export default DeleteOfficeDetailErrorModalSchema;
