/* eslint-disable react/prop-types */
/* eslint-disable filenames/match-exported */

import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  Breadcrumbs,
  RequiredFieldIndicator,
  PageTitle,
} from '@gsa/afp-component-library';
import { capitalize } from 'lodash';
import { alertMap } from 'components/vehicle-reports/pages/bulk-import/model/schema/variable-maps';
import { useImport } from 'components/vehicle-reports/pages/bulk-import/state/hook';
import { ProcessImport } from 'components/vehicle-reports/pages/bulk-import/components';
import RecallActionForm from './recall-action-form';
import { currentDownloadStrategyState } from './import-config';
import { createErrorFile } from './helper';

const getHistoryObjByAction = ({ action, totalCount, campaignId }) => {
  if (action === 'create') {
    return {
      pathname: `/recalls/campaigns`,
      state: {
        totalCount,
      },
    };
  }
  if (action === 'edit') {
    return {
      pathname: `/recalls/campaigns/${campaignId}`,
      state: {
        totalCount,
      },
    };
  }
};

const UpdateRecall = ({
  action,
  campaignId,
  recallCampaignData = null,
  Alert,
  alertUtils,
  setCampaignId,
  sendRecallNotificationEmail,
}) => {
  const {
    importedData,
    setMappedData,
    setProcessedResults,
    processedResponse,
    reset,
  } = useImport();
  const history = useHistory();
  const [fileState, setFileState] = useState();
  //   when  needToSendEmail change to true , after submit the edit recall, if
  //      valueSwitchToTrue   shouldParkOutside
  //       or
  //      valueSwitchToTrue shouldStopDrive
  //    will trigger sendRecallNotificationEmail function
  //     sendRecallNotificationEmail is located in two places depends on the fileState
  const [needToSendEmail, setNeedToSendEmail] = useState(false);
  const title = `${capitalize(action)} recall campaigns`;

  useEffect(() => {
    if (processedResponse?.length > 0) {
      setMappedData([]);
      setProcessedResults([]);
      if (processedResponse?.some((resp) => resp?.status === 'Error')) {
        const errorCount = processedResponse?.filter(
          (resp) => resp?.status === 'Error',
        )?.length;
        const totalCount = processedResponse?.length;
        const processedRecords =
          totalCount > 1 ? `${totalCount} records` : `${totalCount} record`;
        const errorRecords =
          errorCount > 1 ? `${errorCount} records` : `${errorCount} record`;
        alertUtils.showErrorAlert(
          alertMap.recallValidationError.getContent(
            createErrorFile(processedResponse, currentDownloadStrategyState),
            processedRecords,
            errorRecords,
          ),
          alertMap.recallValidationError.heading,
        );
      } else {
        history.push(
          getHistoryObjByAction({
            action,
            totalCount: processedResponse?.length,
            campaignId,
          }),
        );
        alertUtils.showSuccessAlert(
          alertMap.success.getContent(
            `${currentDownloadStrategyState?.label} (${currentDownloadStrategyState?.value})`,
          ),
        );
        if (needToSendEmail) {
          sendRecallNotificationEmail({ variables: { campaignId } });
          setNeedToSendEmail(false);
        }
      }
    }
    return () => reset();
  }, [processedResponse]);
  return (
    <article>
      <header>
        <Breadcrumbs
          trail={[
            <a href={`${window.AFP_CONFIG.appURLs.home}/home`}>Home</a>,
            <Link to="/recalls">Recalls</Link>,
            <Link to="/recalls/campaigns">Recall campaigns</Link>,
          ]}
          current={title}
        />

        <Alert />
        <PageTitle title={title} data-testid="expense-listing-header" />
        <div className="margin-bottom-4 tablet:grid-col-7">
          <span>
            Required fields are marked with an asterisk (
            <RequiredFieldIndicator />
            ).
          </span>
        </div>
      </header>

      <RecallActionForm
        fileState={fileState}
        setFileState={setFileState}
        alertUtils={alertUtils}
        recallCampaignData={recallCampaignData}
        action={action}
        campaignId={campaignId}
        getHistoryObjByAction={getHistoryObjByAction}
        setCampaignId={setCampaignId}
        sendRecallNotificationEmail={sendRecallNotificationEmail}
        needToSendEmail={needToSendEmail}
        setNeedToSendEmail={setNeedToSendEmail}
      />
      {importedData?.length > 0 && (
        <ProcessImport
          useDataProcessor={currentDownloadStrategyState.processorHook}
          importBatchSize={currentDownloadStrategyState.batchSize}
        />
      )}
    </article>
  );
};

export default UpdateRecall;
