import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import { Button, Menu } from '@gsa/afp-component-library';
import { useVehicle } from '../../vehicle-context-provider';
import { canUpdateAOVehicle } from '../../../../utilities/authorization';
import { ORDER_TAB_INDEX, isRepairEditable } from './helpers';

const getRepairUrl = (action, vin, serviceId) => {
  const url = `/vehicle/${encodeURIComponent(vin)}/repair-order`;
  if (action === 'new') return `${url}/new`;
  return `${url}/${encodeURIComponent(serviceId)}/${action}`;
};

export const HyperlinkedRepairOrder = ({ serviceId }) => {
  const {
    vehicle: { id: vin },
  } = useVehicle();
  return <a href={getRepairUrl('view', vin, serviceId)}>{serviceId}</a>;
};
HyperlinkedRepairOrder.propTypes = {
  serviceId: PropTypes.number.isRequired,
};

export const AddRepairOrderButton = () => {
  const history = useHistory();
  const ability = useAppAbility();
  const { vehicle } = useVehicle();

  if (canUpdateAOVehicle(ability) && isRepairEditable(vehicle))
    return (
      <Button
        onClick={() => history.push(getRepairUrl('new', vehicle.id))}
        label="Add new repair order"
      />
    );
  return null;
};

export const RepairRowActions = ({ tab, serviceId, onDelete }) => {
  const history = useHistory();
  const ability = useAppAbility();
  const { vehicle } = useVehicle();

  const onActionClick = (action) => {
    switch (action) {
      case 'view':
      case 'edit':
        history.push(getRepairUrl(action, vehicle.id, serviceId));
        break;
      case 'delete':
        if (onDelete) onDelete(serviceId);
        break;
      default:
    }
  };

  const menuItems = [
    {
      label: 'View repair order',
      action: 'view',
      icon: 'visibility',
    },
  ];
  if (canUpdateAOVehicle(ability) && isRepairEditable(vehicle)) {
    menuItems.push({
      label: 'Edit repair order',
      action: 'edit',
      icon: 'edit',
    });
    if (tab === ORDER_TAB_INDEX)
      menuItems.push({
        label: 'Delete repair order',
        action: 'delete',
        icon: 'delete',
      });
  }

  return (
    <div className="height-4 width-4">
      <Menu
        onActionClick={onActionClick}
        menuItems={menuItems}
        iconColor="text-primary"
      />
    </div>
  );
};
RepairRowActions.propTypes = {
  tab: PropTypes.string.isRequired,
  serviceId: PropTypes.number.isRequired,
  onDelete: PropTypes.func,
};
RepairRowActions.defaultProps = {
  onDelete: undefined,
};
