import { gql } from '@apollo/client';

export const GET_SALES_CODES = gql`
  query GetSalesCodes {
    getSalesCodes {
      code
      description
    }
  }
`;

export const GET_BILLING_HISTORY = gql`
  query GetBillingHistory(
    $limit: Float!
    $offset: Float!
    $order: OrderBy
    $filters: FilterType
  ) {
    getBillingHistory(
      limit: $limit
      offset: $offset
      order: $order
      filters: $filters
    ) {
      rows {
        created_at
        salesCode
        totalAmount
        milesDriven
        endMileage
        daysUsed
        actionCode
        customerBoac
        accessoryCharge
        salesCodeDescription
        customerAccountName
        legacyCustomerNumber
        customerAccountId
      }
      count
      hasMore
    }
  }
`;
