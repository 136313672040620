import { z } from 'zod';

export const getTerminateSchema = (assignmentMileage) => {
  return z.object({
    terminationDate: z
      .string()
      .nonempty({ message: 'Termination date is required' })
      .nullish(),
    endOdometer: z
      .string()
      .nonempty({ message: 'End mileage is required' })
      .pipe(
        z.coerce.number().gt(0, { message: 'Mileage must be greater than 0' }), // Checks for zero value
      )
      .pipe(
        z.coerce.number().gte(assignmentMileage, {
          message: `Mileage must be greater than or equal to assignment mileage of ${assignmentMileage}`,
        }),
      ),
    comment: z.string().nullish(),
  });
};
