import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from '@gsa/afp-component-library';
import './summary-card.css';

export const SummaryCard = ({
  title,
  count,
  isDollar,
  footnote,
  loading,
  id,
  className,
}) => {
  const cardId = id ? `summary-card-${id}` : undefined;
  const titleId = cardId ? `${cardId}-title` : undefined;
  const countId = cardId ? `${cardId}-count` : undefined;
  const footnoteId = cardId ? `${cardId}-footnote` : undefined;

  const getCountStr = () => {
    if (count || count === 0)
      return isDollar
        ? `$${count.toLocaleString(undefined, {
            minimumFractionDigits: 2,
          })}`
        : count.toLocaleString();
    return '—';
  };

  return (
    <div
      className={`vms-summary-card ${
        footnote ? 'vms-summary-card-w-footnote' : ''
      } ${className}`}
      id={cardId}
      data-testid={cardId}
    >
      <div className="title-s margin-top-0" id={titleId} data-testid={titleId}>
        {title}
      </div>
      {loading && <Spinner size="small" className="margin-top-3" />}
      {!loading && (
        <div
          className="font-sans-lg text-bold margin-top-2"
          id={countId}
          data-testid={countId}
        >
          {getCountStr()}
        </div>
      )}
      {!loading && footnote && (
        <div className="font-sans-xs" id={footnoteId} data-testid={footnoteId}>
          {footnote}
        </div>
      )}
    </div>
  );
};

SummaryCard.defaultProps = {
  count: undefined,
  footnote: undefined,
  isDollar: false,
  loading: false,
  id: undefined,
  className: '',
};

SummaryCard.propTypes = {
  title: PropTypes.string.isRequired,
  count: PropTypes.number,
  isDollar: PropTypes.bool,
  footnote: PropTypes.string,
  loading: PropTypes.bool,
  id: PropTypes.string,
  className: PropTypes.string,
};

export default SummaryCard;
