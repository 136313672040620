import React from 'react';
import DateRangeField from './date-range-field';
import { VehicleSelectionOptions } from './vehicle-selection-options';

// eslint-disable-next-line react/prop-types
export const RepairReportFilters = ({ control, register }) => {
  const fromProps = {
    required: false,
    id: 'repair_from_date',
  };
  const toProps = {
    required: false,
    id: 'repair_to_date',
  };

  const REPORT_TYPE = 'REPAIR';
  return (
    <>
      <VehicleSelectionOptions
        control={control}
        register={register}
        reportType={REPORT_TYPE}
      />
      <DateRangeField
        control={control}
        fromProps={fromProps}
        toProps={toProps}
      />
    </>
  );
};
