import {
  AFPTable,
  EmptyState,
  Menu,
  Pagination,
  Spinner,
} from '@gsa/afp-component-library';
import MotorPoolModal from 'utilities/modal-utils';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import { get } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { VMSOperations, VMSSubjects } from 'utilities/consts';
import { isArrayOfConstValShallowEqual } from 'utilities/common';
import { emdash } from 'components/common';
import Clamp from 'components/common/clamp/clamp';
import { useMotorPoolFilter } from './filters/filter-provider';
import MotorPoolListingModal from './motor-pool-listing-modal';
import { useMotorPool } from './motor-pool-provider';
import { MotorPoolSubDetail } from './pool-sub-detail';
import modalSchema from './schema/MOTOR_POOL_LISTING_MODAL';

const actionList = [
  {
    icon: 'edit',
    label: 'Edit',
    action: 'editMotorPool',
    operation: VMSOperations.Update,
    canShowIndicator: 'managesPool',
  },
  {
    icon: 'delete',
    label: 'Delete',
    action: 'deleteMotorPool',
    operation: VMSOperations.Delete,
    canShowIndicator: 'managesPool',
  },
  {
    icon: 'check',
    label: 'Request access',
    action: 'selfInvite',
    operation: VMSOperations.SELF_INVITE,
    canShowIndicator: 'canSelfInvite',
  },
];

const tableRef = React.createRef();

const initialPaginationState = {
  limit: 10,
  offset: 0,
  currentPage: 1,
  isReset: false,
};

const MotorPoolListingTable = () => {
  const [paginationState, setPaginationState] = useState(initialPaginationState);

  const [order, setOrder] = useState('poolName ASC');
  const ability = useAppAbility();
  const context = useMotorPool();

  const {
    getMotorPoolsList,
    motorPoolsList,
    motorPoolsListLoading,
    dispatchAction,
    motorPoolIdsSelected,
    resetModal,
    resetExportModal,
  } = context;

  const { filters } = useMotorPoolFilter();

  const getData = () => {
    getMotorPoolsList({
      variables: {
        limit: paginationState.limit,
        offset: paginationState.offset,
        order,
        filters: filters.motorPoolsListing,
      },
    });
  };

  useEffect(() => {
    dispatchAction('SET_ALERT_MESSAGE', { type: '', message: '' });
    dispatchAction('SET_SELECTED_VEHICLE_AVAILABLE_DATES', null);
    resetExportModal();
    resetModal();
    getData();
  }, []);

  useEffect(() => {
    getData();
  }, [paginationState, order, filters?.motorPoolsListing]);

  useEffect(() => {
    setPaginationState({
      ...paginationState,
      offset: 0,
      currentPage: 1,
      isReset: true,
    });
  }, [filters?.motorPoolsListing]);

  const handleSelectedAction = (action, row) => {
    dispatchAction('SET_SELECTED_MOTOR_POOL', row.original);
    switch (action) {
      case 'editMotorPool':
        dispatchAction(
          'SET_MOTOR_POOL_MODAL_MODE',
          'UPDATE_MOTOR_POOL_LISTING',
        );
        break;
      case 'deleteMotorPool':
        dispatchAction(
          'SET_MOTOR_POOL_MODAL_MODE',
          'DELETE_MOTOR_POOL_LISTING',
        );
        break;
      case 'selfInvite':
        dispatchAction(
          'SET_MOTOR_POOL_MODAL_MODE',
          'SELF_INVITE_MOTOR_POOL_LISTING',
        );
        break;
      default:
        break;
    }
    dispatchAction('SET_SHOW_MOTOR_POOL_MODAL', true);
  };

  const allowedActions = actionList.filter((action) => {
    return ability.can(action.operation, VMSSubjects.MOTOR_POOL);
  });

  const columns = useMemo(() => {
    const columnList = [
      {
        Header: 'Motor pool name',
        accessor: 'poolName',
        sortable: true,
        // eslint-disable-next-line
        Cell: ({ value, row: { original } }) => (
          // eslint-disable-next-line
          <Link to={`/motor-pools/${encodeURIComponent(original.id)}`}>
            {value}
          </Link>
        ),
      },
      {
        Header: 'Description',
        accessor: 'description',
        sortable: false,
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => {
          return <Clamp text={value} maxCharLength={25} />;
        },
      },
      {
        Header: 'No. of vehicles',
        accessor: 'vehiclesCount',
        sortable: false,
      },
      {
        Header: 'Location',
        accessor: 'state',
        sortable: true,
        Cell: ({ value, row: { original } }) => {
          const city = original.city ? `${original.city}` : '';
          const state = city && value ? `, ${value}` : value || '';
          const zip = original.zipCode ? ` ${original.zipCode}` : '';
          return city || state || zip ? `${city}${state}${zip}` : emdash;
        },
      },
      {
        Header: 'Actions',
        sortable: false,
        // eslint-disable-next-line react/prop-types
        Cell: ({ row }) => {
          const actions = allowedActions.filter((a) => {
            const canShow = get(a, 'canShowIndicator');
            // eslint-disable-next-line react/prop-types
            return !canShow || get(row.original, canShow, true);
          });
          if (actions?.length === 0) {
            return <>{emdash}</>;
          }
          return (
            <div className="height-4 width-4 margin-left-05em">
              <Menu
                actionIconSize="usa-icon--size-4"
                menuItems={actions}
                onActionClick={(action) => {
                  // eslint-disable-next-line
                  handleSelectedAction(action, row);
                }}
                iconColor="text-primary"
                menuDialogState="hover"
              />
            </div>
          );
        },
      },
    ];

    return columnList;
  }, []);

  const renderRowSubComponent = useCallback(({ row: { original } }) => {
    const { isPublic, createdAt, poolPrimaryDispatcher, timezone } = original;
    return (
      <div className="display-flex flex-justify-center">
        <div className="grid-col-11">
          <MotorPoolSubDetail
            detail={{
              isPublic,
              createdAt,
              fullName: poolPrimaryDispatcher?.member?.fullName,
              timezone,
            }}
          />
        </div>
      </div>
    );
  }, []);

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    // Calculate new offset.
    const offset = (currentPage - 1) * itemsPerPage;
    setPaginationState({
      limit: itemsPerPage,
      offset,
      currentPage,
    });
  };
  
  return (
    <>
      <AFPTable
        fullWidth
        ref={tableRef}
        testId="motor-pool-listing-table"
        columns={columns}
        data={!motorPoolsListLoading ? motorPoolsList.rows : []}
        defaultSort={order}
        onSort={setOrder}
        expandable
        renderRowSubComponent={renderRowSubComponent}
        selectable
        onRowSelect={({ selectedFlatRows }) => {
          const poolIds = selectedFlatRows.map(({ original }) => original.id);

          if (
            !isArrayOfConstValShallowEqual(
              selectedFlatRows.map(({ original }) => original.id),
              motorPoolIdsSelected,
            )
          ) {
            dispatchAction('SET_SELECTED_MOTOR_POOL_IDS', poolIds);
          }
        }}
      />
      {motorPoolsListLoading && <Spinner className="padding-y-9" />}
      {motorPoolsList?.rows?.length > 0 && (
        <Pagination
          fullWidth
          variant="advanced"
          itemsPerPageOptions={[10, 25, 50]}
          itemsCount={motorPoolsList.count}
          itemsPerPage={paginationState.limit}
          currentPage={paginationState.currentPage}
          onPageChange={handlePaginationChange}
          isReset={paginationState.isReset}
        />
      )}
      {(!motorPoolsList || motorPoolsList.rows.length === 0) &&
        !motorPoolsListLoading && (
          <div className="bg-gray-3 padding-y-5">
            <div className="text-center padding-y-4">
              <EmptyState alt="Image not available" hasBackground />
            </div>
            <div className="text-center text-bold">
              No Motor pools available
            </div>
          </div>
        )}
      <MotorPoolListingModal />
      <MotorPoolModal schema={modalSchema} context={context} />
    </>
  );
};

export default MotorPoolListingTable;
