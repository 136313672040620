import React, { useState, useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Link, useParams, useHistory } from 'react-router-dom';
import { Spinner, Alert } from '@gsa/afp-component-library';
import { groupBy, isNumber } from 'lodash';
import EditCustomerDrivenDataForm from './edit-customer-driven-data-form';
import ServerErrorMessage from '../../../error-handlers/server-error-message';
import {
  VEHICLE_DETAIL,
  GET_ALD,
  GET_ALD_CATEGORIES,
  UPDATE_ALD,
} from '../../../../services/data-layer';
import CddFormContext from './cdd-form-context';
import { formatBoolDropdown } from '../../helpers/utils';

const EditCustomerDrivenData = () => {
  let { vin } = useParams();

  if (vin) {
    vin = decodeURIComponent(vin);
  }
  const history = useHistory();
  const [vehicle, setVehicle] = useState(null);
  const [apiValidationErrors, setApiValidationErrors] = useState([]);
  const [ald, setAld] = useState(null);
  const [aldCategories, setAldCategories] = useState(null);

  const [getVehicle, { loading: loadingVehicle, error: vehicleError }] =
    useLazyQuery(VEHICLE_DETAIL, {
      fetchPolicy: 'no-cache',
      onCompleted: (vehicleData) => {
        if (vehicleData && vehicleData.getVehicle) {
          setVehicle({
            ...vehicleData.getVehicle,
            tasks: vehicleData.getTasks,
            attachments: vehicleData.getAttachments,
          });
        }
      },
    });

  // TODO: do we need to exclude the fields for GF since they contain acquisition cost?
  const [getAld, { loading: loadingAldData, error: aldDataError }] =
    useLazyQuery(GET_ALD, {
      onCompleted: (aldData) => {
        if (aldData?.getAld) {
          setAld(aldData.getAld);
        }
      },
      fetchPolicy: 'no-cache',
    });

  const [updateAld, { error: updateALDDataError }] = useMutation(UPDATE_ALD, {
    onCompleted: (data) => {
      const apiErrors = data?.updateAld[0]?.errors;
      if (!apiErrors) {
        history.push({
          pathname: `/vehicles/${vin}/cdd`,
          state: { success: true },
        });
      } else {
        setApiValidationErrors(apiErrors);
      }
    },
  });

  const formatAldCategories = (categories) => {
    return categories.map((category) => {
      return {
        name: category.name,
        description: category.description,
        options: category.options.map((option) => {
          return {
            label: option.description,
            value: option.code,
          };
        }),
      };
    });
  };

  const [
    getAldCategories,
    { loading: loadingAldCategories, error: aldCategoriesError },
  ] = useLazyQuery(GET_ALD_CATEGORIES, {
    onCompleted: (aldCategoriesData) => {
      if (aldCategoriesData?.getAldCategories) {
        const formattedCategories = formatAldCategories(
          aldCategoriesData.getAldCategories,
        );
        setAldCategories(groupBy(formattedCategories, 'name'));
      }
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (vin) {
      getVehicle({
        variables: {
          id: vin,
        },
      });
    }
  }, []);

  useEffect(() => {
    if (vehicle) {
      getAld({
        variables: {
          vin: vehicle.id,
        },
      });
      getAldCategories();
    }
  }, [vehicle]);

  const formatCurrency = (value) => {
    if (isNumber(value)) {
      return value;
    }
    return parseFloat(value.replace(/,/g, ''));
  };

  const handleSave = async (data) => {
    const acquisitionCostFormatted =
      data && data?.acquisitionCost
        ? formatCurrency(data.acquisitionCost)
        : null;
    const indirectCostFormatted =
      data && data?.indirectCost ? formatCurrency(data.indirectCost) : null;
    const leaseCostFormatted =
      data && data?.leaseCost ? formatCurrency(data.leaseCost) : null;
    const accidentCostFormatted =
      data && data?.accidentRepairCost
        ? formatCurrency(data.accidentRepairCost)
        : null;
    const isEmergencyResponseFormatted = formatBoolDropdown(
      data?.isEmergencyResponse,
    );
    const garageDomesticFormatted = formatBoolDropdown(data?.garageDomestic);
    const isLocationWithheldFormatted = formatBoolDropdown(
      data?.isLocationWithheld,
    );
    const isGasAfvFormatted = formatBoolDropdown(data?.isGasAfv);
    const armoredDesignationFormatted = formatBoolDropdown(
      data?.armoredDesignation,
    );
    const isMsaFormatted = formatBoolDropdown(data?.isMsa);
    const isHomeToWorkFormatted = formatBoolDropdown(data?.homeToWork);
    const cddInput = {
      vin: vehicle.id,
      assetId: ald.assetId,
      ...data,
      acquisitionCost: acquisitionCostFormatted,
      indirectCost: indirectCostFormatted,
      leaseCost: leaseCostFormatted,
      accidentRepairCost: accidentCostFormatted,
      isEmergencyResponse: isEmergencyResponseFormatted,
      garageDomestic: garageDomesticFormatted,
      isLocationWithheld: isLocationWithheldFormatted,
      isGasAfv: isGasAfvFormatted,
      armoredDesignation: armoredDesignationFormatted,
      isMsa: isMsaFormatted,
      homeToWork: isHomeToWorkFormatted,
      telematicsDataUsage:
        data?.telematicsDataUsage?.length > 0
          ? data.telematicsDataUsage.join('')
          : null,
    };
    await updateAld({
      variables: { updateAld: cddInput },
    });
  };

  if (loadingAldData || loadingAldCategories || loadingVehicle) {
    return <Spinner className="padding-top-5" />;
  }

  if (vehicleError) {
    return <ServerErrorMessage graphQLErrors={vehicleError} />;
  }

  if (aldDataError) {
    return <ServerErrorMessage graphQLErrors={aldDataError} />;
  }

  if (aldCategoriesError) {
    return <ServerErrorMessage graphQLErrors={aldCategoriesError} />;
  }

  if (updateALDDataError) {
    return <ServerErrorMessage graphQLErrors={updateALDDataError} />;
  }

  return (
    <>
      <p>
        Customer Driven Data (CDD) is also known as Asset Level Data (ALD). To
        comply with the Federal <br />
        Automotive Statistical Tool (FAST) you can manage all of this
        vehicle&apos;s Customer Driven Data
        <br /> (CDD) on this page.
      </p>
      <p>
        Vehicle data can be imported and updated in bulk from the{' '}
        <Link to="/reports">Vehicle Reports Manager page</Link>.
      </p>
      {apiValidationErrors.length > 0 && (
        <Alert type="error" heading="We found some issues with your data">
          {apiValidationErrors.map((error, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <p key={`api-error-${index}`}>{error?.message}</p>
          ))}
        </Alert>
      )}
      {ald && (
        <CddFormContext
          aldData={ald}
          aldCategories={aldCategories}
          apiValidationErrors={apiValidationErrors}
        >
          <EditCustomerDrivenDataForm
            handleSave={handleSave}
            vin={vehicle.id}
          />
        </CddFormContext>
      )}
    </>
  );
};

export default EditCustomerDrivenData;
