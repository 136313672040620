import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FilterPanel } from '@gsa/afp-component-library';

const placeholderOption = {
  value: '',
  label: '- Select FMC -',
  defaultValue: true,
};

const { FilterPanelField, FilterPanelFilterShape, useFilterPanel } =
  FilterPanel;

const FmcFilterItem = ({
  filter,
  fmcsWithZoneAndRegion,
  zoneFilterPath,
  fmcFilterPath,
}) => {
  const { getFilterFromState, mergedFilters } = useFilterPanel();
  const [fmcOptions, setFmcOptions] = useState([]);
  const [zoneRetainedValue, setZoneRetainedValue] = useState();

  const [zoneValue, setZoneValue] = useState();
  const [fmcValue, setFmcValue] = useState();

  useEffect(() => {
    const zoneFilter = getFilterFromState(zoneFilterPath);
    setZoneValue(zoneFilter.value);
    setZoneRetainedValue(zoneFilter.retained);
    const fmcFilter = getFilterFromState(fmcFilterPath);
    setFmcValue(fmcFilter.value);
  }, [mergedFilters]);

  useEffect(() => {
    if (!zoneRetainedValue) {
      setFmcOptions([placeholderOption]);
    }
    if (zoneValue) {
      const centers = fmcsWithZoneAndRegion?.filter(
        (fmcRegion) => fmcRegion.zoneId === zoneValue,
      );
      const centersOptions = centers?.map((center) => ({
        value: center.centerId,
        label: center.label,
      }));
      setFmcOptions([placeholderOption, ...centersOptions]);
    }
  }, [zoneValue, zoneRetainedValue]);

  return (
    <div className="margin-top-2">
      <FilterPanelField
        key={[filter.key]}
        filter={{
          ...filter,
          type: 'select',
          options: fmcOptions,
          value: fmcValue || [],
        }}
      />
    </div>
  );
};

FmcFilterItem.defaultProps = {
  zoneFilterPath: '',
  fmcFilterPath: '',
};

FmcFilterItem.propTypes = {
  filter: PropTypes.shape(FilterPanelFilterShape).isRequired,
  zoneFilterPath: PropTypes.string,
  fmcFilterPath: PropTypes.string,
};

export default FmcFilterItem;
