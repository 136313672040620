/* eslint-disable filenames/match-regex */
import { useSetRecoilState, atom } from 'recoil';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';

export const historyStateAtom = atom({
  key: 'history',
  default: null,
  dangerouslyAllowMutability: true,
});

export const RecoilHistoryListener = () => {
  const history = useHistory();
  const setHistoryState = useSetRecoilState(historyStateAtom);
  useEffect(() => setHistoryState(history), [history]);
  return null;
};
