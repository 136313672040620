/* eslint-disable react/prop-types */
import React from 'react';

import RecallCampaignModal from 'utilities/modal-utils';
import { useRecallCampaignDetailPage } from './providers';

import modalSchema from './schema/modal';

const RecallCampaignDetailModal = () => {
  const context = useRecallCampaignDetailPage();

  return (
    <>
      <RecallCampaignModal
        schema={modalSchema}
        context={context}
        isAlertMessage={false}
      />
    </>
  );
};

export default RecallCampaignDetailModal;
