import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useWatch } from 'react-hook-form';
// eslint-disable-next-line import/named
import { isDifferenceMoreThanAYear } from 'utilities/validation';
import DateRangeField from './date-range-field';
import { VehicleSelectionOptions } from './vehicle-selection-options';
import { useExport } from '../export-provider';
import { HiddenField } from '../components/hidden-field';

const fromProps = {
  required: true,
  id: 'aieReportFromDate',
  defaultValue: moment().subtract(1, 'months').format('YYYY-MM-DD'),
};
const toProps = {
  required: true,
  id: 'aieReportToDate',
  defaultValue: moment().format('YYYY-MM-DD'),
};

const REPORT_TYPE = 'AIE';
// eslint-disable-next-line react/prop-types
export const AieReportFilters = ({ control, register, setValue }) => {
  const [aieReportToDate, aieReportFromDate] = useWatch({
    name: ['aieReportToDate', 'aieReportFromDate', 'vehicle_selection_option'],
    control,
  });

  const [isValidRange, setIsValidRange] = useState(true);

  const { isScheduled } = useExport();

  useEffect(() => {
    if (toProps.defaultValue && fromProps.defaultValue)
      isDifferenceMoreThanAYear(fromProps.defaultValue, toProps.defaultValue)
        ? setIsValidRange(false)
        : setIsValidRange(true);
  }, []);

  useEffect(() => {
    if (aieReportToDate && aieReportFromDate)
      isDifferenceMoreThanAYear(aieReportFromDate, aieReportToDate)
        ? setIsValidRange(false)
        : setIsValidRange(true);
  }, [aieReportToDate, aieReportFromDate]);

  return (
    <div className="margin-bottom-6">
      <VehicleSelectionOptions
        control={control}
        register={register}
        setValue={setValue}
        reportType={REPORT_TYPE}
      />
      {isScheduled === 'no' ? (
        <DateRangeField
          isValidRange={isValidRange}
          control={control}
          fromProps={fromProps}
          toProps={toProps}
        />
      ) : (
        <>
          <HiddenField register={register} name={fromProps.id} />
          <HiddenField register={register} name={toProps.id} />
        </>
      )}
    </div>
  );
};
