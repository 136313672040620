// eslint-disable-next-line filenames/match-exported
import React from 'react';
import { CounterTag } from '@gsa/afp-component-library';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import RegistrationDetailHistory from './registration-detail-history';
import RegistrationDetailComments from './registration-detail-comments';
import { useRegistrationDetail } from './registration-detail-context-provider';
import { isFeatureEnabled } from '../../utilities/feature-toggle';

const VehicleRegistrationDetailTabs = () => {
  const { registrationDetailComments, registrationDetailVehicle } =
    useRegistrationDetail();
  return (
    <div className="margin-top-3">
      <Tabs>
        <TabList>
          {isFeatureEnabled('vehicle-reg-details-history') ? (
            <Tab>
              <span className="text-no-wrap" id="vehicle-history">
                History
              </span>
            </Tab>
          ) : null}

          <Tab>
            <span className="text-no-wrap">
              <CounterTag count={registrationDetailComments.count || 0}>
                Comments
              </CounterTag>
            </span>
          </Tab>
        </TabList>
        {isFeatureEnabled('vehicle-reg-details-history') ? (
          <TabPanel>
            <RegistrationDetailHistory vehicle={registrationDetailVehicle} />
          </TabPanel>
        ) : null}

        <TabPanel>
          <RegistrationDetailComments />
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default VehicleRegistrationDetailTabs;
