/* eslint react/prop-types: 0 */
import React from 'react';
import { Controller } from 'react-hook-form';
import {
  RadioButton,
  RequiredFieldIndicator,
  Label,
} from '@gsa/afp-component-library';
import { SCHEDULE_OPTIONS } from '../config/helpers';
import { useExport } from '../export-provider';

export const ScheduleSelection = ({ control }) => {
  const { setIsScheduled } = useExport();
  return (
    <>
      <div className="usa-form-group mobile-lg:grid-col-10">
        <Label className="text-bold">
          Would you like to save and schedule this report?{' '}
          <RequiredFieldIndicator />
        </Label>
        <Controller
          control={control}
          name="schedule_options"
          defaultValue="no"
          rules={{ required: 'This is a required field' }}
          render={({
            field: { onChange, name, value },
            formState: { errors },
          }) => (
            <>
              {SCHEDULE_OPTIONS.map(({ radioValue, label, ...restProps }) => {
                const key = `${label}-${radioValue}`;
                const isFieldError = errors?.[name];
                const fieldErrorMessage = isFieldError
                  ? errors[name].message
                  : null;
                return (
                  <div
                    className={`usa-form-group ${
                      isFieldError
                        ? 'usa-form-group--error margin-left-05'
                        : null
                    }`}
                  >
                    {isFieldError && (
                      <span className="usa-error-message">
                        {fieldErrorMessage}
                      </span>
                    )}
                    <RadioButton
                      name={name}
                      key={key}
                      value={radioValue}
                      checked={radioValue === value}
                      label={<p>{label}</p>}
                      {...restProps}
                      onChange={(e) => {
                        onChange(e.target.value);
                        setIsScheduled(e.target.value);
                      }}
                    />
                  </div>
                );
              })}
            </>
          )}
        />
      </div>
    </>
  );
};
