/* eslint-disable react/prop-types */
import React from 'react';
import { emdash } from 'components/common';
import { useOfficeManagement } from './office-management-provider';
import { getStateById, getCountryById } from './utils';

const ContactInfoDisplay = ({ contact, displayContext }) => {
  if (displayContext === 'FSR') {
    return (
      <div>
        <div className="text-bold">{contact?.fullName}</div>
        <div>{contact?.email}</div>
        {contact?.phoneNUmber && <div> {contact?.phoneNUmber} </div>}
      </div>
    );
  }

  return (
    <div>
      <div className="text-bold">
        {contact?.pocFirstName} {contact?.pocLastName}
      </div>
      <div>{contact?.pocEmailAddress}</div>
      <div>
        {contact?.pocPhone1CountryCode &&
          contact?.pocPhone1CountryCode?.split(' ')[0] !== '+1' && (
            <span>{contact?.pocPhone1CountryCode.split(' ')[0]} </span>
          )}
        {contact?.pocPhone1}
        {contact?.pocPhone1Ext && (
          <span>
            {' ext '} {contact?.pocPhone1Ext}
          </span>
        )}
      </div>
      {contact?.pocPhone2 && (
        <div>
          {contact?.pocPhone2CountryCode &&
            contact?.pocPhone2CountryCode?.split(' ')[0] !== '+1' && (
              <span>{contact?.pocPhone2CountryCode?.split(' ')[0]} </span>
            )}
          {contact?.pocPhone2}
        </div>
      )}
    </div>
  );
};

const OfficeManagementRowDetail = ({ customerAccount }) => {
  const { countriesOptions, statesOptions } = useOfficeManagement();

  const {
    address1,
    address2,
    city,
    postalCode,
    internationalStateName,
    usStateId,
    countryId,
    zoneId,
    managementCenter,
    boac,
    customerId,
    legacyCustomerNumber,
    accountName,
    primaryContactAssociation,
  } = customerAccount || {};

  const borderClasses = 'border-bottom border-base-lighter padding-y-1';

  return (
    <div className="grid-row grid-gap flex-justify">
      <div className="grid-col-12">
        <h2>{accountName}</h2>
      </div>
      <div className="tablet:grid-col-6">
        <div
          className={`${borderClasses} display-flex flex-justify`}
          data-testid="data-section"
        >
          <strong>Office location address</strong>
          <address>
            {address1}
            <br />
            {address2}
            <br />
            {city},{' '}
            {usStateId && statesOptions
              ? getStateById(usStateId, statesOptions)
              : ''}{' '}
            {postalCode}
            <br />
            {countryId && internationalStateName && countriesOptions
              ? getCountryById(
                  countryId,
                  internationalStateName,
                  countriesOptions,
                )
              : ''}
          </address>
        </div>
        <div
          className={`${borderClasses} display-flex flex-justify`}
          data-testid="data-section"
        >
          <strong>Agency POC</strong>
          {
            // display primary contact info if available}
            primaryContactAssociation && (
              <ContactInfoDisplay
                contact={primaryContactAssociation.pointOfContact}
              />
            )
          }
        </div>
      </div>
      <div className="tablet:grid-col-6">
        <div
          className={`${borderClasses} display-flex flex-justify`}
          data-testid="data-section"
        >
          <strong>Zone</strong>
          {zoneId || emdash}
        </div>
        <div
          className={`${borderClasses} display-flex flex-justify`}
          data-testid="data-section"
        >
          <strong>FMC</strong>
          <div>
            <div>{managementCenter?.name}</div>
            {managementCenter?.city && managementCenter?.state && (
              <div>{`${managementCenter?.city}, ${managementCenter?.state}`}</div>
            )}
          </div>
        </div>
        <div
          className={`${borderClasses} display-flex flex-justify`}
          data-testid="data-section"
        >
          <strong>BOAC</strong>
          {boac || emdash}
        </div>
        <div
          className={`${borderClasses} display-flex flex-justify`}
          data-testid="data-section"
        >
          <strong>Customer account number</strong>
          {customerId || emdash}
        </div>
        <div
          className={`${borderClasses} display-flex flex-justify`}
          data-testid="data-section"
        >
          <strong>Legacy customer number</strong>
          {legacyCustomerNumber || emdash}
        </div>
      </div>
    </div>
  );
};

export default OfficeManagementRowDetail;
