import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { FilterPanel } from '@gsa/afp-component-library';
import { GET_LIFECYCLE_INDICATORS } from 'services/data-layer/fed-hierarchy.gql';

const { FilterPanelFilterShape, FilterPanelField } = FilterPanel;

const VehiclesLifecycleFilterItem = ({ filter }) => {
  const [options, setOptions] = useState([]);

  const [getLifecycles] = useLazyQuery(GET_LIFECYCLE_INDICATORS, {
    fetchPolicy: 'network-only', // Used for first execution
    nextFetchPolicy: 'cache-first', // Used for subsequent executions
    onCompleted: (responseData) => {
      if (responseData?.getLifecycleIndicators) {
        const indicators = [];
        responseData.getLifecycleIndicators.forEach((lifecycle) => {
          if (
            indicators.findIndex(
              ({ value }) => value === lifecycle.lifecycleIndicator,
            ) === -1
          ) {
            indicators.push({
              value: lifecycle.lifecycleIndicator,
              label: lifecycle.lifecycleIndicator,
            });
          }
        });
        setOptions(indicators);
      }
    },
  });

  useEffect(() => {
    getLifecycles();
  }, []);

  return (
    <>
      <p className="text-bold">Vehicle lifecycle</p>
      <FilterPanelField
        key={[filter.key]}
        filter={{
          ...filter,
          type: 'radio',
          options,
        }}
      />
    </>
  );
};

VehiclesLifecycleFilterItem.propTypes = {
  filter: PropTypes.shape(FilterPanelFilterShape).isRequired,
};

export default VehiclesLifecycleFilterItem;
