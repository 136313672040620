import React, { useEffect, useState } from 'react';
import { Breadcrumbs, Spinner, Alert } from '@gsa/afp-component-library';
import { useParams } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import _ from 'lodash';
import { useTitle } from '@gsa/afp-shared-ui-utils';
import {
  VEHICLE_REG_DETAIL,
  REGISTER_VEHICLE,
  GET_VEHICLE_COMMENTS_PLUS,
  DISASSOCIATE_PDF,
  CREATE_SUPPORTING_DOC,
  SWAP_TAG,
} from '../../services/data-layer';
import { vehicleRegistrationIsIncomplete } from '../vehicle-registration-status-tag';
import { getRegistrationPDFPayload } from './helpers/payload-constructors';
import Header from './header';
import VehicleInfo from './vehicle-info';
import AgencyInfo from './agency-poc-info';
import './registration-details.css';
import VehicleRegistrationDetailTabs from './registration-detail-tab';
import RegistrationDetailContextProvider from './registration-detail-context-provider';
import useUpdateVehicleFields from './helpers/update-vehicle-fields';
import SwapLicensePlateAlerts from './widgets/swap-license-plate-alerts';
import {
  InvalidPoCAlert,
  NetworkErrorAlert,
} from './widgets/poc-status-alerts';
import {
  SoldConfirmation,
  MissingConfirmation,
  ExemptConfirmation,
  IncompleteWarning,
} from './widgets/vehicle-status-warnings';
import useUpdateStatePlate from '../vehicle-details/sidenav-widgets/registration/use-update-state-plate';

export default (props) => {
  let { vin } = useParams();
  vin = decodeURIComponent(vin);
  useTitle(`VIN ${vin}`);
  const [registrationDetailComments, setRegistrationDetailComments] = useState({
    rows: [],
    count: 0,
    hasMore: false,
  });

  const [swapData, setSwapData] = useState({});
  const [hasPocError, setHasPocError] = useState(false);
  const [wasUpdateSubmitted, setWasUpdateSubmitted] = useState(false);
  const [hasNetworkError, setHasNetworkError] = useState(false);
  const [fetchVehicle, { data, loading, refetch }] = useLazyQuery(
    VEHICLE_REG_DETAIL,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const [getVehicleCommentPlus, { data: comments }] = useLazyQuery(
    GET_VEHICLE_COMMENTS_PLUS,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const [swapTag, { loading: swappingTag }] = useMutation(SWAP_TAG, {
    fetchPolicy: 'no-cache',
    onError: (error) => {
      setSwapData({
        error: `An unexpected error has occurred: ${error.message}`,
      });
    },
    onCompleted: (swapResponse) => {
      setSwapData({
        tagNumber: _.get(vehicle, 'tagNumber'),
        oldExpDate: _.get(vehicle, 'tag.expirationDate'),
        newExpDate: _.get(swapResponse, 'swapTag.expirationDate'),
      });
      setTagSwapped(true);
      refetch(); // refresh vehicle data and registration card
    },
  });

  // network alerts should be cleared when PoC is updated but the invalid PoC error will only be dismissed if both PoCs are valid
  const handlePocUpdate = (hasError) => {
    setHasPocError(hasError);
    setHasNetworkError(false);
  };

  const [
    registerVehicle,
    { data: registrationResponse, loading: loadingRegisterVehicle },
  ] = useMutation(REGISTER_VEHICLE, {
    fetchPolicy: 'no-cache',
    onError: (error) => {
      setWasUpdateSubmitted(true);
      // Different errors are returned based on different invalid POC states
      if (error?.message === 'Invalid submission') {
        const isPocError = !!error?.graphQLErrors.find(
          (x) =>
            x.extensions?.code &&
            x.extensions?.code.includes('Point of contact emails'),
        );
        handlePocUpdate(isPocError);
      } else {
        setHasPocError(false);
        setHasNetworkError(true);
      }
    },
    onCompleted: () => {
      handlePocUpdate(false);
      setWasUpdateSubmitted(false);
    },
  });

  const [createSupportingDoc, { data: supportingDocUrl }] = useMutation(
    CREATE_SUPPORTING_DOC,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const [disassociateRegistrationPDF] = useMutation(DISASSOCIATE_PDF, {
    fetchPolicy: 'no-cache',
  });

  const [updateFields, updatedFields, updatingFields, updateFieldsError] =
    useUpdateVehicleFields(vin);

  const [updateStatePlate, updatedStatePlate, updatingStatePlate] =
    useUpdateStatePlate();

  const [vehicle, setVehicle] = useState(null);
  const [canRender, setCanRender] = useState(false);
  const [registrationUpdated, setRegistrationUpdated] = useState(false);
  const [tagSwapped, setTagSwapped] = useState(false);

  const [isRedirect, setIsRedirect] = useState(false);
  const [documentLink, setDocumentLink] = useState('');

  // Used to compare after registration completes to decide whether to display alerts for updating to missing/stolen or sold status.
  const [vehicleStatus, setVehicleStatus] = useState(null);
  const [displaySoldStatusConfirmation, setSoldStatusConfirmation] =
    useState(false);
  const [displayMissingStatusConfirmation, setMissingStatusConfirmation] =
    useState(false);
  // Tag will be removed from the vehicle after certain status updates but will need to be stored for display in confirmation modal
  const [displayTag, setTagForDisplay] = useState(null);
  const [displayExemptionConfirmation, setDisplayExemptionConfirmation] =
    useState(false);
  const [exemptStatus, setExemptStatus] = useState(null);

  const updateRegistration = (veh) => {
    setIsRedirect(false);
    setRegistrationUpdated(false); // In case the call fails.  It will be reset after the call succeeds and vehicle details are fetched
    registerVehicle({ variables: { registration: veh } });
  };

  useEffect(() => {
    setIsRedirect(!!props?.location?.state?.isRedirect);
    setDocumentLink(props?.location?.state?.documentLink || '');
    // Clears the state passed in as a redirect so it doesn't persist across refreshing
    window.history.replaceState({}, document.title);
  }, []);

  const clearConfirmationSettings = () => {
    setIsRedirect(false);
    setSoldStatusConfirmation(false);
    setMissingStatusConfirmation(false);
    setDisplayExemptionConfirmation(false);
  };

  useEffect(() => {
    if (updatedStatePlate) {
      clearConfirmationSettings();
      fetchVehicle({
        variables: {
          id: vin,
        },
      });
      setRegistrationUpdated(true);
    }
  }, [updatedStatePlate, vin]);

  useEffect(() => {
    if (registrationResponse) {
      clearConfirmationSettings();
      // Only display the confirmation when the exempt status updates from false to true
      if (
        displayTag?.tagNumber &&
        registrationResponse?.registerVehicle?.exemptPlate &&
        !exemptStatus
      ) {
        setDisplayExemptionConfirmation(true);
        // TODO: This can be orchestrated by the backend after MVP
        disassociateRegistrationPDF({
          variables: {
            model: 'Vehicle',
            modelPK: registrationResponse?.registerVehicle?.id,
            documentName: 'registration',
          },
        });
      }
      fetchVehicle({
        variables: {
          id: vin,
        },
      });
      setExemptStatus(registrationResponse?.registerVehicle?.exemptPlate);
      setRegistrationUpdated(true);
    }
  }, [registrationResponse, vin]);

  useEffect(() => {
    if (updatedFields) {
      clearConfirmationSettings();
      if (
        displayTag?.tagNumber &&
        displayTag?.tagExpirationDate &&
        updatedFields?.updateVehicleField?.itemInventoryStatusCode === 'SD' &&
        vehicleStatus !== 'SD'
      ) {
        // Status was updated to SD so show that alert
        setSoldStatusConfirmation(true);
        // TODO: This can be orchestrated by the backend after MVP
        disassociateRegistrationPDF({
          variables: {
            model: 'Vehicle',
            modelPK: updatedFields?.updateVehicleField?.id,
            documentName: 'registration',
          },
        });
      }
      if (
        displayTag?.tagNumber &&
        displayTag?.tagExpirationDate &&
        updatedFields?.updateVehicleField?.itemInventoryStatusCode === 'MS' &&
        vehicleStatus !== 'MS'
      ) {
        // Status was updated to SD so show that alert and delete the current registration if one is available
        setMissingStatusConfirmation(true);
        // TODO: This can be orchestrated by the backend after MVP
        disassociateRegistrationPDF({
          variables: {
            model: 'Vehicle',
            modelPK: updatedFields?.updateVehicleField?.id,
            documentName: 'registration',
          },
        });
      }
      fetchVehicle({
        variables: {
          id: vin,
        },
      });
      setVehicleStatus(updatedFields?.updateVehicleField?.status);
      setRegistrationUpdated(true);
    }
  }, [updatedFields, vin]);

  useEffect(() => {
    if (supportingDocUrl?.createSupportingDoc) {
      setVehicle((oldVeh) => {
        return {
          ...oldVeh,
          supportingDocument: {
            signedUrl: supportingDocUrl.createSupportingDoc,
          },
        };
      });
    }
  }, [supportingDocUrl]);

  useEffect(() => {
    // TODO: unhappy scenario: there's no vin in the URL
    if (vin) {
      fetchVehicle({
        variables: {
          id: vin,
        },
      });
      getVehicleCommentPlus({
        variables: {
          id: vin,
        },
      });
    }
  }, [vin]);

  useEffect(() => {
    if (comments) {
      setRegistrationDetailComments(comments.getVehicleCommentPlus);
    }
  }, [comments]);

  useEffect(() => {
    if (data) {
      // TODO: unhappy scenario: vehicle is not found
      if (!data.getVehicle) return;

      // TODO: unhappy scenario: vehicle is not registered
      // if (!data.getVehicle.tagNumber) return;

      // TODO: unhappy scenario: vehicle belongs to a diff agency

      // TODO: unhappy scenario: vehicle is missing mandatory fields like make, model, year

      // TODO after 4th condition (vehicle/license plate status?) for correctly setting up registered flag
      const vehicleRegistered =
        !!data.getVehicle?.tag || data.getVehicle?.exemptPlate;

      setVehicle({
        ...data.getVehicle,
        registered: vehicleRegistered,
      });
      setVehicleStatus(data.getVehicle?.inventoryStatus?.id);
      setExemptStatus(data.getVehicle?.exemptPlate);
      if (registrationUpdated || tagSwapped) {
        const pdfData = getRegistrationPDFPayload(data.getVehicle);
        if (pdfData) {
          createSupportingDoc({
            variables: {
              data: pdfData,
              model: 'Vehicle',
              modelPK: vehicle.id,
              documentName: 'registration',
            },
          });
        }
      }
    }
  }, [data]);

  useEffect(() => {
    if (vehicle && !loading) {
      setCanRender(true);
    }
  }, [vehicle]);

  const renderDetails = () => {
    return canRender && vehicle ? (
      <RegistrationDetailContextProvider
        registrationDetailVehicle={vehicle}
        setRegistrationDetailVehicle={setVehicle}
        setRegistrationDetailComments={setRegistrationDetailComments}
        refetchVehicle={refetch}
        registrationDetailComments={registrationDetailComments}
        updateFields={updateFields}
        updatedFields={updatedFields}
        updatingFields={updatingFields}
        updateFieldsError={updateFieldsError}
        handlePocUpdate={handlePocUpdate}
        setHasNetworkError={setHasNetworkError}
      >
        <div data-testid="afp-registration__details">
          <Header vehicle={vehicle} />
          <VehicleInfo
            vehicle={vehicle}
            updateRegistration={updateRegistration}
            updateStatePlate={(statePlate) => {
              updateStatePlate(vehicle, statePlate);
            }}
            setTagForDisplay={setTagForDisplay}
          />
          <AgencyInfo
            vehicle={vehicle}
            setRegistrationUpdated={setRegistrationUpdated}
          />
          <div className="padding-y-4" id="vehicle-regiistration-tabs">
            <VehicleRegistrationDetailTabs />
          </div>
        </div>
      </RegistrationDetailContextProvider>
    ) : (
      <Spinner className="padding-y-9" />
    );
  };

  if (loading) return <Spinner className="padding-y-9" />;
  return (
    <div className="afp-registration__container">
      <Breadcrumbs
        trail={[
          <a href={`${window.AFP_CONFIG.appURLs.home}/home`}>Home</a>,
          <a href="/vehicles">Vehicle Inventory</a>,
        ]}
        current={<span className="text-uppercase">VIN {vin}</span>}
      />
      <SwapLicensePlateAlerts
        registrationDetailVehicle={vehicle}
        swapTag={swapTag}
        swappingTag={swappingTag}
        swapData={swapData}
        documentLink={documentLink}
      />
      {registrationUpdated && !isRedirect && (
        <div className="padding-bottom-1">
          <Alert type="success" slim focused>
            Vehicle was successfully updated.
          </Alert>
        </div>
      )}
      {displayExemptionConfirmation && (
        <ExemptConfirmation
          licensePlate={displayTag?.tagNumber}
          expirationDate={displayTag?.tagExpirationDate}
          vin={vin}
        />
      )}
      {isRedirect && (
        <div className="padding-bottom-1">
          <Alert type="success" slim focused>
            Vehicle was successfully registered.
            {documentLink && documentLink.createSupportingDoc && (
              <>
                {' '}
                <a href={documentLink.createSupportingDoc} download>
                  Download vehicle registration card
                </a>
              </>
            )}
          </Alert>
        </div>
      )}
      {displayMissingStatusConfirmation && (
        <div className="padding-bottom-1">
          <MissingConfirmation
            licensePlate={displayTag?.tagNumber}
            expirationDate={displayTag?.tagExpirationDate}
          />
        </div>
      )}
      {displaySoldStatusConfirmation && (
        <div className="padding-bottom-1">
          <SoldConfirmation
            licensePlate={displayTag?.tagNumber}
            expirationDate={displayTag?.tagExpirationDate}
          />
        </div>
      )}
      {hasPocError && wasUpdateSubmitted && <InvalidPoCAlert />}
      {hasNetworkError && <NetworkErrorAlert />}
      {vehicle && vehicleRegistrationIsIncomplete(vehicle) && (
        <IncompleteWarning ownership={vehicle.ownershipTypeCode} />
      )}
      {renderDetails()}
      {(updatingFields || updatingStatePlate || loadingRegisterVehicle) && (
        <Spinner aria-busy="true" className="loading_backdrop" size="large" />
      )}
    </div>
  );
};
