import React, { useEffect } from 'react';
import {
  Alert,
  Button,
  Modal,
  useModal,
  connectModal,
  Spinner,
} from '@gsa/afp-component-library';
import { useLicensePlateDetails } from '../license-plate-details-provider';
import LpLocationEditForm from './lp-location-edit-form';

const LpLocationEditModal = () => {
  const {
    updatingLpLocation,
    setUpdatingLpLocation,
    updatingLpLocationLoading,
    updatingLpLocationError,
  } = useLicensePlateDetails();
  const { openModal, closeModal } = useModal();
  useEffect(() => {
    if (updatingLpLocation) openModal();
    else closeModal();
  }, [updatingLpLocation]);

  const onClose = () => {
    setUpdatingLpLocation(false);
    closeModal();
  };
  const Header = () => {
    return (
      <>
        <h1>Edit license plate location</h1>
        <div>Use the form below to edit the license plate location.</div>
      </>
    );
  };

  const LocationEditModal = connectModal(() => {
    return (
      <Modal
        id="lpLocationEditModal"
        title={<Header />}
        onClose={onClose}
        actions={
          <>
            <Button
              variant="unstyled"
              id="cancelLocationUpdate"
              data-testId="cancelLocationUpdate"
              label="Cancel"
              onClick={onClose}
            />
            <>
              <Button
                className="margin-left-2"
                type="submit"
                label="Save and close"
                form="lpLocationEditForm"
                disabled={updatingLpLocationLoading}
              />
              {updatingLpLocationLoading && (
                <Spinner
                  size="small"
                  aria-busy="true"
                  className="display-inline-block margin-left-2"
                />
              )}
            </>
          </>
        }
      >
        {updatingLpLocationError && (
          <Alert
            type="error"
            focused
            slim
            className="margin-bottom-2 padding-bottom-2"
          >
            An unexpected error has occurred.
          </Alert>
        )}
        <LpLocationEditForm />
      </Modal>
    );
  });

  return <LocationEditModal isOpen={updatingLpLocation} />;
};

export default LpLocationEditModal;
