import { z } from 'zod';

const validateDate = (dateString) => {
  if (dateString) {
    const dateInput = new Date(dateString);
    return dateInput <= new Date();
  }
  return true;
};

export const validateABChecked = (data, ctx) => {
  if (!(data.tagA || data.tagB)) {
    ctx.addIssue({
      code: z.ZodIssueCode.invalid_arguments,
      path: ['plateMissing'],
      message: 'Missing plate is required',
      fatal: true,
    });
  }
};

export const licensePlateSchema = z
  .object({
    stolenDate: z.string().nullish(),
    gsaReportedDate: z.string().nullish(),
    dhsReportedDate: z
      .string()
      .nullish()
      .refine(validateDate, { message: 'Please enter a valid date' }),
    dhsCaseNumber: z
      .string()
      .regex(
        new RegExp(/^[A-Za-z0-9]*$/),
        'DHS Case Number can only contain letters and numbers',
      )
      .nullish(),
    ncicNumber: z
      .string()
      .regex(
        new RegExp(/^[A-Za-z0-9]*$/),
        'NCIC Case Number can only contain letters and numbers',
      )
      .nullish(),
    comment: z
      .string()
      .trim()
      .nonempty({ message: 'Comments are required' })
      .nullish(),
    tagA: z.boolean(),
    tagB: z.boolean(),
  })
  .superRefine((data, ctx) => {
    const { stolenDate, gsaReportedDate } = data;

    if (!stolenDate) {
      ctx.addIssue({
        code: z.ZodIssueCode.invalid_date,
        path: ['stolenDate'],
        message: 'Date missing/stolen is required',
        fatal: true,
      });
    }
    if (!gsaReportedDate) {
      ctx.addIssue({
        code: z.ZodIssueCode.invalid_date,
        path: ['gsaReportedDate'],
        message: 'Date reported to GSA is required',
        fatal: true,
      });
    }
    if (!validateDate(stolenDate)) {
      ctx.addIssue({
        code: z.ZodIssueCode.invalid_date,
        path: ['stolenDate'],
        message: 'Please enter a valid date',
        fatal: true,
      });
    }
    if (!validateDate(gsaReportedDate)) {
      ctx.addIssue({
        code: z.ZodIssueCode.invalid_date,
        path: ['gsaReportedDate'],
        message: 'Please enter a valid date',
        fatal: true,
      });
    }

    validateABChecked(data, ctx);
  });

export const stolenSchema = z
  .object({
    stolenDate: z.string().nullish(),
    gsaReportedDate: z.string().nullish(),
    dhsReportedDate: z
      .string()
      .nullish()
      .refine(validateDate, { message: 'Please enter a valid date' }),
    dhsCaseNumber: z
      .string()
      .regex(
        new RegExp(/^[A-Za-z0-9]*$/),
        'DHS Case Number can only contain letters and numbers',
      )
      .nullish(),
    ncicNumber: z
      .string()
      .regex(
        new RegExp(/^[A-Za-z0-9]*$/),
        'NCIC Case Number can only contain letters and numbers',
      )
      .nullish(),
    comment: z
      .string()
      .trim()
      .nonempty({ message: 'Comments are required' })
      .nullish(),
  })
  .superRefine((data, ctx) => {
    const { stolenDate, gsaReportedDate } = data;

    if (!stolenDate) {
      ctx.addIssue({
        code: z.ZodIssueCode.invalid_date,
        path: ['stolenDate'],
        message: 'Date missing/stolen is required',
        fatal: true,
      });
    }
    if (!gsaReportedDate) {
      ctx.addIssue({
        code: z.ZodIssueCode.invalid_date,
        path: ['gsaReportedDate'],
        message: 'Date reported to GSA is required',
        fatal: true,
      });
    }
    if (!validateDate(stolenDate)) {
      ctx.addIssue({
        code: z.ZodIssueCode.invalid_date,
        path: ['stolenDate'],
        message: 'Please enter a valid date',
        fatal: true,
      });
    }
    if (!validateDate(gsaReportedDate)) {
      ctx.addIssue({
        code: z.ZodIssueCode.invalid_date,
        path: ['gsaReportedDate'],
        message: 'Please enter a valid date',
        fatal: true,
      });
    }
  });

export const recoveredSchema = z
  .object({
    stolenDate: z.string().nullish(),
    gsaReportedDate: z.string().nullish(),
    dhsReportedDate: z
      .string()
      .nullish()
      .refine(validateDate, { message: 'Please enter a valid date' }),
    dhsCaseNumber: z
      .string()
      .regex(
        new RegExp(/^[A-Za-z0-9]*$/),
        'DHS Case Number can only contain letters and numbers',
      )
      .nullish(),
    recoveredDate: z
      .string()
      .nonempty({ message: 'Date recovered is required' })
      .nullish()
      .refine(validateDate, { message: 'Please enter a valid date' }),
    ncicNumber: z
      .string()
      .regex(
        new RegExp(/^[A-Za-z0-9]*$/),
        'NCIC Case Number can only contain letters and numbers',
      )
      .nullish(),
    comment: z
      .string()
      .trim()
      .nonempty({ message: 'Comments are required' })
      .nullish(),
  })
  .superRefine((data, ctx) => {
    const { stolenDate, recoveredDate, gsaReportedDate } = data;

    if (!stolenDate) {
      ctx.addIssue({
        code: z.ZodIssueCode.invalid_date,
        path: ['stolenDate'],
        message: 'Date missing/stolen is required',
        fatal: true,
      });
    }
    if (!gsaReportedDate) {
      ctx.addIssue({
        code: z.ZodIssueCode.invalid_date,
        path: ['gsaReportedDate'],
        message: 'Date reported to GSA is required',
        fatal: true,
      });
    }
    if (!validateDate(stolenDate)) {
      ctx.addIssue({
        code: z.ZodIssueCode.invalid_date,
        path: ['stolenDate'],
        message: 'Please enter a valid date',
        fatal: true,
      });
    }
    if (!validateDate(gsaReportedDate)) {
      ctx.addIssue({
        code: z.ZodIssueCode.invalid_date,
        path: ['gsaReportedDate'],
        message: 'Please enter a valid date',
        fatal: true,
      });
    }

    if (!recoveredDate) {
      ctx.addIssue({
        code: z.ZodIssueCode.invalid_date,
        path: ['recoveredDate'],
        message: 'Date recovered is required',
        fatal: true,
      });
    }
    if (recoveredDate && new Date(recoveredDate) < new Date(stolenDate)) {
      ctx.addIssue({
        code: z.ZodIssueCode.invalid_date,
        path: ['recoveredDate'],
        message: 'Date recovered cannot be before date stolen',
        fatal: true,
      });
    }
  });
