import React from 'react';
import { Link } from 'react-router-dom';
import { useTitle } from '@gsa/afp-shared-ui-utils';
import ServerErrorMessage from 'components/error-handlers/server-error-message';
import { Alert, PageTitle } from '@gsa/afp-component-library';
import { useMileageExpress } from './mileage-express-provider';

const MileageExpressHeader = () => {
  useTitle('Mileage reporting');
  const { alertMessage, internalServerError } = useMileageExpress();

  const showAlert = () => {
    const { header, message, type, context, error } = alertMessage;
    if (!message) {
      return null;
    }
    if (type === 'error' && !context) {
      return <ServerErrorMessage graphQLErrors={error} />;
    }
    return (
      context !== 'endMileage' && (
        <div className="margin-top-2" data-testid="mileage-reporting-alert">
          <Alert type={type} heading={header || null}>
            {message}
          </Alert>
        </div>
      )
    );
  };

  const mileageExpressBulkUploadGuideLink = '/reports';
  const mileageExpressUserGuideLink = `${process.env.PUBLIC_URL}/GSAFleet.gov-Mileage-Reporting-User-Guide.pdf`;
  const howToVideoLink =
    'https://vimeo.com/gsavisualcommunications/review/1001308850/400c8d51a9';

  const headerText = () => {
    return (
      <p>
        Use this tool to report monthly mileage on your fleet-leased vehicle.
        Mileage is due the last day of each month and can only be reported once
        a month. Follow this link to{' '}
        <Link to={mileageExpressBulkUploadGuideLink}>Bulk Upload Mileage</Link>,
        or visit Vehicle Reports Manager tile on the Home page.
        <br />
        <br />
        For more information, please see the{' '}
        <a
          href={mileageExpressUserGuideLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          Mileage Reporting user guide
        </a>{' '}
        and &nbsp;
        <a href={howToVideoLink} target="_blank" rel="noopener noreferrer">
          How to
        </a>{' '}
        video.
      </p>
    );
  };

  return (
    <>
      {alertMessage !== null && showAlert()}
      {internalServerError}
      <header>
        <PageTitle title="Mileage Reporting" />
        <div className="margin-bottom-4 tablet:grid-col-7">{headerText()}</div>
      </header>
    </>
  );
};

export default MileageExpressHeader;
