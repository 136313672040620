import { useMutation } from '@apollo/client';
import moment from 'moment';
import { Button, Spinner } from '@gsa/afp-component-library';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import { startCase, toUpper, get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { emdash } from 'components/common';
import { UPDATE_ORDER_POC } from '../../../services/data-layer';
import { canUpdateTagOrders } from '../../../utilities/authorization';
import OrderDetail from '../../orders/widgets/table-widgets/detail-row';
import OrderDetailEmail from '../../orders/widgets/table-widgets/order-detail-row-email';
import { constructAddress } from '../../orders/widgets/table-widgets/order-details';
import { useOrder } from '../context';
import { constructOrderPoc } from '../helpers/payload-constructors';
import OrderAgencyEdit from './order-agency-edit';
import OrderDetailsEdit from './order-details-edit';
import './order-details-section.css';
import OrderPocEdit from './order-poc-edit';
import phoneNumberFormatter from '../../../utilities/phoneNumberFormatter';

// eslint-disable-next-line react/prop-types
export default function OrderDetailsSection({ ariaLabel, onEditAgency }) {
  const ability = useAppAbility();
  const canUpdateOrders = canUpdateTagOrders(ability);
  const { details, tags, getOrder, updateDetails } = useOrder();

  const {
    agencyAddress,
    orderNumber,
    pocEmail,
    pocName,
    pocPhone,
    agency,
    bureau,
    originalBureau,
    office,
  } = details;

  const { count, rows } = tags;

  const address = constructAddress(agencyAddress);
  const [editOrderPoc, setEditOrderPoc] = useState(false);
  const [editOrderDetails, setEditOrderDetails] = useState(false);

  const [isGtag, setIsGtag] = useState(false);

  useEffect(() => {
    if (rows && rows.length) {
      setIsGtag(rows.find((row) => row.tagNumber.substr(0, 1) === 'G'));
    }
  }, [rows]);

  const [updateOrderPoc, { loading: updatingPoc }] = useMutation(
    UPDATE_ORDER_POC,
    {
      onError: () => {
        getOrder({
          variables: {
            orderNumber,
          },
        });
      },
      onCompleted: (updatedOrderPoc) => {
        if (updatedOrderPoc && updatedOrderPoc.updateOrderPoc) {
          updateDetails(updatedOrderPoc.updateOrderPoc);
        }
      },
    },
  );

  const handlePocSave = (poc) => {
    updateOrderPoc({
      variables: {
        orderNumber,
        orderPoc: constructOrderPoc(poc),
      },
    });
  };

  const formattedDate = (date) => {
    return `${moment.utc(new Date(date)).format('L')} • ${moment
      .utc(new Date(date))
      .format('h:mm a')}`;
  };

  const getShippedDate = () => {
    const shippedDate = get(details, 'shippedDate');
    const processedAt = get(details, 'processedAt');
    if (shippedDate) return formattedDate(shippedDate);
    if (processedAt) return formattedDate(processedAt);
    return emdash;
  };

  return (
    <div className="padding-y-2 grid-row">
      <div className="details tablet:grid-col-6 padding-right-1">
        <h3 className="title-s-caps text-primary margin-bottom-1">
          Order Details
        </h3>
        <div className="details-rows margin-top-1 padding-2 detail-list bg-gray-3 radius-md">
          <OrderDetail label="UNICOR order number" value={orderNumber} />
          <OrderDetail
            label="Date ordered"
            value={
              get(details, 'orderedDate')
                ? formattedDate(get(details, 'orderedDate'))
                : emdash
            }
          />
          <OrderDetail
            label="Date reshipped"
            value={
              get(details, 'reshippedDate')
                ? formattedDate(get(details, 'reshippedDate'))
                : emdash
            }
          />
          <OrderDetail
            label="Quantity of plates"
            value={`${count}`}
            anchor="license-plates"
          />
          <OrderDetail label="Date shipped" value={getShippedDate()} />
          <OrderDetail
            label="Expiration date(s)"
            value={details?.tagExpirationDates}
          />
          <OrderDetailsEdit
            poc={{ ...details, ...agencyAddress }}
            onClose={() => {
              setEditOrderDetails(false);
            }}
            editing={editOrderDetails}
            setEditOrderDetails={setEditOrderDetails}
          />
        </div>
      </div>
      <div className="poc tablet:grid-col-6 padding-left-1">
        <div className="margin-bottom-1">
          <h3 className="title-s-caps text-primary margin-bottom-1">Agency</h3>
          <div className="details-rows margin-top-1 padding-2 detail-list bg-gray-3 radius-md">
            <OrderDetail
              label="Agency"
              value={`${agency?.id ? agency?.id : '- '} - ${
                agency?.name ? agency?.name : ' -'
              }`}
            />
            <OrderDetail
              label="Bureau"
              value={`${bureau?.id ? bureau?.id : '- '} - ${
                bureau?.name ? bureau?.name : ' -'
              }`}
            />
            <OrderDetail
              label="Office"
              value={
                office ? `${office?.officeCode} - ${office?.officeName}` : '-'
              }
            />
            {canUpdateOrders && !isGtag && (
              <OrderAgencyEdit
                onEditAgency={onEditAgency}
                originalBureau={originalBureau}
                agency={agency}
                bureau={bureau}
                office={office}
                orderNumber={orderNumber}
              />
            )}
          </div>
        </div>
        <h3 className="title-s-caps text-primary margin-bottom-1">
          Point of Contact
        </h3>
        <div className="details-rows margin-top-1 padding-2 detail-list bg-gray-3 radius-md">
          <OrderDetail label="Name" value={startCase(toUpper(pocName))} />
          <OrderDetailEmail label="Email" value={pocEmail} email={pocEmail} />
          <OrderDetail label="Phone" value={phoneNumberFormatter(pocPhone)} />
          <OrderDetail label="Address" value={startCase(toUpper(address))} />
          {canUpdateOrders && !isGtag && (
            <>
              <Button
                data-testid="order-poc-edit"
                onClick={() => {
                  setEditOrderPoc(true);
                }}
                variant="outline"
                className="bg-white margin-top-2"
                aria-label={ariaLabel}
                label="Edit"
              />
              <OrderPocEdit
                poc={{ ...details, ...agencyAddress }}
                onClose={() => {
                  setEditOrderPoc(false);
                }}
                onSave={(updatedPoc) => {
                  setEditOrderPoc(false);
                  handlePocSave(updatedPoc);
                }}
                editing={editOrderPoc}
                setEditOrderPoc={setEditOrderPoc}
                updateOrderPoc={updateOrderPoc}
              />
            </>
          )}
        </div>
        {updatingPoc && (
          <Spinner aria-busy="true" className="loading_backdrop" size="large" />
        )}
      </div>
    </div>
  );
}
