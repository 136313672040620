/* eslint-disable react/prop-types */
/* eslint-disable prefer-destructuring */
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { read, utils } from 'xlsx';
import * as yup from 'yup';
import moment from 'moment';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  TextInput,
  FileUpload,
  Button,
  Icon,
} from '@gsa/afp-component-library';
import { useImport } from 'components/vehicle-reports/pages/bulk-import/state/hook';
import { coercionString } from 'utilities/format';

import { CREATE_RECALL, UPDATE_RECALL_CAMPAIGN } from 'services/data-layer';
import { useMutation } from '@apollo/client';
import { LoadingQueryResult } from 'components/loading-query-result/loading-query-result';
import { useHistory } from 'react-router-dom';

import { currentDownloadStrategyState } from './import-config';
import RecallActionFormFields from './recall-action-fields';
import { getDisabledField } from './helper';

const fileTypes = ['.xlsx', '.xls'];

export const expenseMapping = [['VIN', 'vin', 'string']];

const valueSwitchToTrue = (originalVal, newVal) =>
  !originalVal && newVal === true;

const RecallActionForm = ({
  campaignId,
  action,
  fileState,
  setFileState,
  alertUtils,
  recallCampaignData,
  getHistoryObjByAction,
  setCampaignId,
  sendRecallNotificationEmail,
  needToSendEmail,
  setNeedToSendEmail,
}) => {
  const [processedDataState, setProcessedDataState] = useState();
  const [fileImportErrorState, setFileImportErrorState] = useState('');
  const [reportsErrorState] = useState();
  const { setData } = useImport();
  const history = useHistory();
  const [createRecall, { loading: createRecallLoading }] = useMutation(
    CREATE_RECALL,
    {
      fetchPolicy: 'network-only',

      onError: (error) => {
        alertUtils.showErrorAlert(<span>{error.message.split(':')[1]}</span>);
      },
      onCompleted: (data) => {
        setCampaignId(data?.createRecall?.id);
        setData(
          processedDataState.map((vin) => ({
            recallCampaignId: data?.createRecall?.id,
            ...vin,
          })),
        );
      },
    },
  );

  const [updateRecallCampaign] = useMutation(UPDATE_RECALL_CAMPAIGN, {
    fetchPolicy: 'network-only',

    onError: (error) => {
      alertUtils.showErrorAlert(<span>{error.message}</span>);
    },
    onCompleted: (data) => {
      if (action === 'edit' && !fileState) {
        history.push(
          getHistoryObjByAction({
            action,
            totalCount: 'x',
            campaignId,
          }),
        );
        if (needToSendEmail) {
          sendRecallNotificationEmail({ variables: { campaignId } });
          setNeedToSendEmail(false);
        }
        return;
      }
      setData(
        processedDataState.map((vin) => ({
          recallCampaignId: data?.updateRecallCampaign?.id,
          ...vin,
        })),
      );
      // setFileState(null);
    },
  });

  const formValidation = yup.object().shape({
    recallCode: yup.string().required().label('Manufacturer campaign ID'),
    recallDescription: yup.string().required().label('Campaign'),
    recallType: yup.string().required().label('Recall type'),
  });

  const methods = useForm({
    resolver: async (data, context, options) => {
      if (!fileState && action !== 'edit') {
        setFileImportErrorState('This is a required field');
      }
      return yupResolver(formValidation)(data, context, options);
    },
    // eslint-disable-next-line react/prop-types
    defaultValues: {},
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    if (recallCampaignData) {
      methods.setValue('recallCode', recallCampaignData?.recallCode);
      methods.setValue(
        'recallDescription',
        recallCampaignData?.recallDescription,
      );
      methods.setValue(
        'detailDescription',
        recallCampaignData?.detailDescription,
      );
      methods.setValue(
        'remedyDescription',
        recallCampaignData?.remedyDescription,
      );
      methods.setValue('safetyRisk', recallCampaignData?.safetyRisk);
      methods.setValue('nhtsaCampaignId', recallCampaignData?.nhtsaCampaignId);
      methods.setValue('recallType', recallCampaignData?.recallType);

      methods.setValue(
        'isRemedyAvailable',
        recallCampaignData?.isRemedyAvailable,
      );
      methods.setValue('shouldStopDrive', recallCampaignData?.shouldStopDrive);
      methods.setValue(
        'shouldParkOutside',
        recallCampaignData?.shouldParkOutside,
      );
    }
  }, [recallCampaignData]);

  const onSubmit = async (values) => {
    if (!fileState && action !== 'edit') {
      setFileImportErrorState('This is a required field');
      return;
    }

    if (fileImportErrorState || reportsErrorState) {
      return;
    }
    let processError = false;

    let processedData = null;
    if (fileState) {
      const mapping = currentDownloadStrategyState.mapping;

      const configHeaders = mapping.map((m) => m[0]);

      const data = await fileState.arrayBuffer();
      const wb = read(data, { cellDates: true, dateNF: 'mm/dd/yyyy' });
      const rawJson = utils.sheet_to_json(
        wb.Sheets[currentDownloadStrategyState?.dataSheet],
        { raw: false, header: configHeaders },
      );
      // parsing the excel
      if (rawJson.length === 0) {
        // to do set up
        // eslint-disable-next-line react/prop-types
        setFileImportErrorState('Does not match template');
        return;
      }
      const headerObject = rawJson?.[0];
      const mismatchedHeader =
        headerObject &&
        Object.keys(headerObject).find(
          (k) =>
            headerObject[k].trim().replace('*', '') !==
            k.trim().replace('*', ''),
        );

      if (mismatchedHeader || !headerObject) {
        setFileState('Does not match template');
        return;
      }

      // process the data from the file
      processedData = rawJson.slice(1).map((rowJson) => {
        const row = {};
        // map the data to the expected format
        try {
          mapping.forEach((value) => {
            const [colLabel, colProp, colType] = value;
            let rawVal = rowJson[colLabel];
            if (rawVal) {
              rawVal = rawVal.trim();
            } else {
              rawVal = '';
            }
            switch (colType) {
              case 'string | number':
                row[colProp] = coercionString(rawVal);
                break;
              default:
                row[colProp] = rawVal;
            }
          });
        } catch (err) {
          processError = true;
        }

        return row;
      });

      if (processedData?.length === 0) {
        setFileImportErrorState('this is an empty file');
        return;
      }
      if (processError) {
        // eslint-disable-next-line react/prop-types
        setFileImportErrorState('Does not match template');
        return;
      }
    }

    if (action === 'edit') {
      if (fileState && !processError) {
        updateRecallCampaign({
          variables: { recallInput: { ...values, id: campaignId } },
        });
        setProcessedDataState(processedData);
      }

      if (
        valueSwitchToTrue(
          recallCampaignData.shouldParkOutside,
          values.shouldParkOutside,
        ) ||
        valueSwitchToTrue(
          recallCampaignData.shouldStopDrive,
          values.shouldStopDrive,
        )
      ) {
        setNeedToSendEmail(true);
      }

      if (!fileState) {
        updateRecallCampaign({
          variables: { recallInput: { ...values, id: campaignId } },
        });
      }
    }

    if (action === 'create') {
      if (!processError) {
        createRecall({ variables: { recallInput: values } });
        setProcessedDataState(processedData);
      }
    }
  };

  return (
    <>
      <LoadingQueryResult onLoading={createRecallLoading}>
        <FormProvider {...methods}>
          <form
            data-testid="recall-add-edit-form"
            id="recall-add-edit-form"
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <TextInput
              label={<span className="text-bold">Created on</span>}
              name="createdOn"
              type="text"
              value={
                recallCampaignData?.createdAt
                  ? moment(recallCampaignData?.createdAt).format('MM/DD/YYYY')
                  : moment(new Date()).format('MM/DD/YYYY')
              }
              readOnly
            />
            <RecallActionFormFields
              action={action}
              getDisabledField={getDisabledField}
              recallCampaignData={recallCampaignData}
            />
            <h4 className="title-s-caps text-primary margin-bottom-2">
              VIN Bulk Upload
            </h4>
            <p>
              <span className="text-bold">
                What VINs are affected by this recall campaign?
              </span>{' '}
              Download the template below and add all relevant VINs. Then upload
              the document in the section below.
            </p>
            <a
              href={`${process.env.PUBLIC_URL}/recall-vin-upload-template.xlsx`}
              className="usa-button usa-button--unstyled usa-button--medium"
              data-testid="gsa-expense-template-link"
            >
              Download blank VIN upload template{' '}
              <span aria-hidden="true" className="margin-left-05">
                <Icon
                  type="custom"
                  iconName="custom_download"
                  className="text-base usa-icon--size-1"
                />
              </span>
            </a>{' '}
            <FileUpload
              required={action === 'create'}
              label="Supporting file"
              defaultValue={fileState}
              acceptableFiles={[
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'application/vnd.ms-excel',
              ]}
              acceptableFilesLabel={` Accept ${fileTypes[0]} and ${fileTypes[1]} file below
          ${currentDownloadStrategyState?.maxFileSize} MB`}
              fileSizeLimit={currentDownloadStrategyState?.maxFileSize} // MB
              onChange={(file) => {
                setFileState(file);
                // reset the fileImportErrorState if user upload a file
                setFileImportErrorState(
                  !file ? 'This is a required field' : '',
                );
              }}
              errorMessage={fileImportErrorState}
            />
            <div className="margin-top-2">
              <Button
                size="sm"
                data-testid="recall-add-edit-form-cancel"
                variant="unstyled"
                className="continue usa-button--unstyled padding-right-4"
                id="recall-add-edit-form"
                aria-label="Cancel form"
                hidden="hidden"
                onClick={() => {
                  if (action === 'create') {
                    history.push({
                      pathname: `/recalls/campaigns`,
                    });
                  }
                  if (action === 'edit') {
                    history.push({
                      pathname: `/recalls/campaigns/${campaignId}`,
                    });
                  }
                }}
                label="Cancel"
              />
              <Button
                size="sm"
                data-testid="recall-add-edit-form-submit"
                variant="primary"
                id="recall-add-edit-form"
                aria-label="Submit form"
                hidden="hidden"
                type="submit"
                label={
                  action === 'edit'
                    ? 'Update campaign details'
                    : `Submit new recall`
                }
              />
            </div>
          </form>
        </FormProvider>
      </LoadingQueryResult>
    </>
  );
};

export default RecallActionForm;
