/* eslint-disable react/prop-types */
/* eslint-disable filenames/match-exported */

import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { cloneDeep, capitalize } from 'lodash';
import {
  Breadcrumbs,
  RequiredFieldIndicator,
  AddressConfirmationModal,
} from '@gsa/afp-component-library';
import {
  createLayoutSelector,
  createPageSetupSelector,
  createFormSetupSelector,
} from 'utilities/recoil/store';
import { setValueByPath } from 'utilities/form';
import { getFieldsDataWithDisabled } from 'utilities/format';
import useAlertManager from 'utilities/alert-manager';
import { LoadingQueryResult } from 'components/loading-query-result/loading-query-result';
import FormFrame from 'components/forms/index';
import FormButtons from './components/form-buttons';
import Header from './components/Header';
import { watcher } from './model/schema/form-rule-map';
import {
  addressSelector,
  submitSelector,
  pageConfigUpdateSelector,
} from './model/store/selectors';
import atomStateSchema, { pageId } from './model/schema/atom-state-schema';
import { LayoutSchema } from './model/schema/form-contents-schema';
import * as atoms from './model/store/atoms';

const ExpenseUpdate = ({ action, vin, graphqlOperations, loading }) => {
  const title = `${capitalize(action)} Expense Record`;
  const location = useLocation();

  const setFormLayoutState = useSetRecoilState(
    createLayoutSelector({ atoms })(pageId),
  );
  const vehicle = useRecoilValue(pageConfigUpdateSelector('vehicle'));

  const setupFormState = useSetRecoilState(
    createFormSetupSelector({ atoms })(pageId),
  );
  const setupPageState = useSetRecoilState(createPageSetupSelector(pageId));
  const addressConfirmModalInfo = useRecoilValue(
    atoms.pageConfigAtom('addressConfirmModalInfo'),
  );
  const setAddressConfirmModalInfo = useSetRecoilState(addressSelector);

  const [Alert, alertUtils] = useAlertManager(true, true, true);

  useEffect(() => {
    const newFields = cloneDeep(atomStateSchema.formFields);
    const { editData, isWEX, editId } = location?.state?.data ?? {};

    setupPageState({
      atoms,
      pageConfigs: {
        ...atomStateSchema.pageConfigs,
        pageMetaInfo: {
          default: {
            isEditPage: action === 'edit',
            isWEX,
            editId,
          },
        },
        dependantFunctions: {
          default: {
            ...graphqlOperations,
            alertUtils,
            watcher,
          },
        },
      },
    });

    // loop the formFields to create the pathInfo
    // use isWEX to generate pathInfo with disabled value
    // the detail example of getFieldsDataWithDisabled please refer to unit test
    // eslint-disable-next-line array-callback-return
    Object.keys(atomStateSchema.formFields).map((fieldKey) => {
      const pathInfo = getFieldsDataWithDisabled({
        fieldKey,
        editData,
        formFields: atomStateSchema.formFields,
        disableCriteria: isWEX,
        disableExceptionKeys: [
          'productDescription',
          'unitOfMeasure',
          'comment',
        ],
      });
      // use the pathInfo to generate fields state with disabled
      // watchout! mutate the newFields with pathInfo
      setValueByPath({ data: newFields, pathInfo, mutate: true });
    });

    setupFormState({
      newFields,
      caches: { takeSnapshot: 'init' },
    });

    setFormLayoutState({ layoutSchema: LayoutSchema });

    graphqlOperations.query.getProductOptions({
      variables: {
        productDescription: editData?.productDescription ?? null,
      },
    });
  }, []);

  return (
    <article>
      <LoadingQueryResult loadingObject={loading}>
        {addressConfirmModalInfo.show && (
          <div>
            <AddressConfirmationModal
              {...addressConfirmModalInfo}
              onSave={(finalAddress) => {
                if (addressConfirmModalInfo.hasValidationError) {
                  setAddressConfirmModalInfo({
                    hasValidationError: true,
                    show: false,
                  });
                } else {
                  setAddressConfirmModalInfo({
                    hasValidationError: false,
                    addressToValidate: finalAddress,
                    show: false,
                  });
                }
              }}
            />
          </div>
        )}
        <header>
          <Breadcrumbs
            trail={[
              <a href={`${window.AFP_CONFIG.appURLs.home}/home`}>Home</a>,
              <Link to="/vehicles">Vehicle Inventory</Link>,
              <Link to={`/vehicles/${vin}`}>VIN {vin}</Link>,
            ]}
            current={title}
          />
          <Alert />
          <Header vin={vin} vehicle={vehicle} title={title} />
          <div className="margin-bottom-4 tablet:grid-col-7">
            <span>
              Required fields are marked with an asterisk (
              <RequiredFieldIndicator />
              ).
            </span>
          </div>
        </header>
        <div>
          <div className="margin-bottom-4 tablet:grid-col-7">
            <FormFrame
              pageId={pageId}
              atoms={atoms}
              atomStateSchema={atomStateSchema}
              submitSelector={submitSelector(pageId)}
              FormButtons={FormButtons}
            />
          </div>
        </div>
      </LoadingQueryResult>
    </article>
  );
};

export default ExpenseUpdate;
