import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { FilterPanel } from '@gsa/afp-component-library';
import { GET_ZONES_CENTERS } from 'services/data-layer/fed-hierarchy.gql';

const placeholderOption = {
  value: '',
  label: '- Select zone -',
  defaultValue: true,
};

const { FilterPanelFilterShape, FilterPanelField } = FilterPanel;

const VehiclesZoneFilterItem = ({ filter }) => {
  const [options, setOptions] = useState([placeholderOption]);

  const [getZones] = useLazyQuery(GET_ZONES_CENTERS, {
    fetchPolicy: 'network-only', // Used for first execution
    nextFetchPolicy: 'cache-first', // Used for subsequent executions
    onCompleted: (responseData) => {
      if (responseData?.getZones) {
        const zoneOptions = responseData?.getZones.map((zone) => ({
          value: zone.id,
          label: zone.name,
        }));
        setOptions([placeholderOption, ...zoneOptions]);
      }
    },
  });

  useEffect(() => {
    getZones();
  }, []);

  return (
    <FilterPanelField
      key={[filter.key]}
      filter={{
        ...filter,
        type: 'select',
        options,
      }}
    />
  );
};

VehiclesZoneFilterItem.propTypes = {
  filter: PropTypes.shape(FilterPanelFilterShape).isRequired,
};

export default VehiclesZoneFilterItem;
