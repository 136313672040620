import React, { useContext, useReducer, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation, useLazyQuery } from '@apollo/client';
import { usCountryCode } from 'utilities/consts';
import {
  GET_CUSTOMER_ACCOUNTS,
  CREATE_CUSTOMER_ACCOUNT,
  UPDATE_CUSTOMER_ACCOUNT,
  DELETE_CUSTOMER_ACCOUNT,
  GET_CUSTOMER_ACCOUNT,
  ASSIGN_FSR_EMAIL,
  UPDATE_CUSTOMER_TRANSPORTATION_FEES,
} from '../customer-account-gql';
import {
  GET_COUNTRIES,
  GET_PHONE_COUNTRY_CODES,
  GET_STATES,
} from '../../../services/data-layer/address';
import { GET_ZONES_CENTERS } from '../../../services/data-layer/fed-hierarchy.gql';

export const CustomerAccountContext = React.createContext();

const customerAccountFormDataTemplate = {
  boac: '',
  customerAgencyCode: '',
  customerBureauCode: '',
  accountName: '',
  address1: '',
  address2: '',
  city: '',
  usStateId: '',
  internationalStateName: '',
  fmcId: 'default',
  fsrUserEmail: '',
  customerType: '',
  agencySubGroup: '',
  accountNotes: '',
  countryId: usCountryCode,
  state: 'default',
  contactAssociations: [],
};

const initialState = {
  alertMessage: { message: '', type: '', header: null, location: 'header' },
  customerAccountsList: {
    rows: [],
  },
  customerAccountSelected: null,
  selectedCustomerAccounts: [],
  customerAccountModalMode: '',
  customerAccountModalShow: false,
  customerAccountPageMode: null, // CREATE, VIEW, EDIT
  optionsData: null,
  customerAccountToDelete: null,
  customerAccountForm: customerAccountFormDataTemplate,
};

const mapContactAssociations = (customerAccount) => {
  return customerAccount.allContactAssociations?.map((ca) => ({
    customerId: ca.customerId,
    contactId: ca.contactId,
    priority: ca.priority,
    ...ca.pointOfContact,
  }));
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_ALERT_MESSAGE':
      return { ...state, alertMessage: action.payload };
    case 'SET_CUSTOMER_ACCOUNT_SELECTED':
      if (action.payload) {
        return {
          ...state,
          customerAccountSelected: action.payload,
          customerAccountForm: {
            ...action.payload,
            state: `${action.payload?.usStateId}`,
            maintenanceReminder:
              action.payload?.maintenanceReminder === 1 ? 'Y' : 'N',
            zoneId: `${action.payload?.zoneId}`,
            fmcId: `${action.payload.zoneId}:${action.payload?.fmcId}:${action.payload?.regionId}`,
            contactAssociations: mapContactAssociations(action.payload),
            countryId: `${action.payload?.countryId}`,
          },
        };
      }
    // eslint-disable-next-line no-fallthrough
    case 'SET_CUSTOMER_ACCOUNT_MODAL_SHOW':
      return { ...state, customerAccountModalShow: action.payload };
    case 'SET_SELECTED_CUSTOMER_ACCOUNTS':
      return { ...state, selectedCustomerAccounts: action.payload };
    case 'SET_CUSTOMER_ACCOUNT_MODAL_MODE':
      return {
        ...state,
        customerAccountModalMode: action.payload,
        customerAccountModalShow: !!action?.payload,
      };
    case 'SET_CUSTOMER_ACCOUNTS':
      return { ...state, customerAccountsList: action.payload };
    case 'SET_OPTIONS_DATA':
      return { ...state, optionsData: action.payload };
    case 'SET_OPTIONS_DATA_COUNTRIES': {
      const optionDataWCountries = {
        ...state.optionsData,
        countries: action.payload,
      };
      return { ...state, optionsData: optionDataWCountries };
    }
    case 'SET_OPTIONS_DATA_STATES': {
      const optionDataWStates = {
        ...state.optionsData,
        usStates: action.payload,
      };
      return { ...state, optionsData: optionDataWStates };
    }
    case 'SET_OPTIONS_DATA_INT_DIAL': {
      const optionData = { ...state.optionsData, intCallCodes: action.payload };
      return { ...state, optionsData: optionData };
    }
    case 'SET_OPTIONS_DATA_ZONES': {
      const optionData = {
        ...state.optionsData,
        zones: action.payload.zones,
        fmcsWithZoneAndRegion: action.payload.fmcsWithZoneAndRegion,
      };
      return { ...state, optionsData: optionData };
    }
    case 'SET_CUSTOMER_ACCOUNT_TO_DELETE':
      return { ...state, customerAccountToDelete: action.payload };
    case 'SET_CUSTOMER_ACCOUNT_FORM':
      return { ...state, customerAccountForm: action.payload };
    default:
      return state;
  }
};

// eslint-disable-next-line react/prop-types
const CustomerAccountProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const history = useHistory();

  const resetModal = () => {
    dispatch({ type: 'SET_CUSTOMER_ACCOUNT_MODAL_SHOW', payload: false });
    dispatch({ type: 'SET_CUSTOMER_ACCOUNT_MODAL_MODE', payload: '' });
    dispatch({ type: 'SET_CUSTOMER_ACCOUNT_SELECTED', payload: null });
    dispatch({
      type: 'SET_CUSTOMER_ACCOUNT_FORM',
      payload: customerAccountFormDataTemplate,
    });
  };

  const dispatchAction = (type, payload) => {
    dispatch({
      type,
      payload,
    });
  };

  const setRequestError = (requestError, context) => {
    if (context === 'SET_VEHICLE_COUNT_ERROR') {
      dispatchAction('SET_ALERT_MESSAGE', {
        type: 'error',
        message: requestError.message,
        error: requestError,
        location: 'header',
        context,
      });
    } else {
      dispatchAction('SET_ALERT_MESSAGE', {
        type: 'error',
        message: requestError?.message,
        error: requestError,
        location: 'header',
        context,
      });
    }
  };

  // Get customer accounts list.
  const [
    getCustomerAccountsList,
    { loading: customerAccountsListLoading, refetch },
  ] = useLazyQuery(GET_CUSTOMER_ACCOUNTS, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onError: (error) => setRequestError(error),
    onCompleted: (responseData) => {
      if (responseData?.getCustomerAccounts) {
        dispatchAction(
          'SET_CUSTOMER_ACCOUNTS',
          responseData.getCustomerAccounts,
        );
      }
    },
  });

  const [getCustomerAccount, { loading: customerAccountLoading }] =
    useLazyQuery(GET_CUSTOMER_ACCOUNT, {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onError: (error) => setRequestError(error),
      onCompleted: (responseData) => {
        if (responseData?.getCustomerAccount) {
          dispatchAction(
            'SET_CUSTOMER_ACCOUNT_SELECTED',
            responseData.getCustomerAccount,
          );
        }
      },
    });

  // Create customer account.
  const [createCustomerAccount, { loading: createCustomerAccountLoading }] =
    useMutation(CREATE_CUSTOMER_ACCOUNT, {
      onError: (error) => {
        setRequestError(error);
      },
      onCompleted: (response) => {
        dispatchAction('SET_ALERT_MESSAGE', {
          type: 'success',
          message: `Successfully created customer account: ${response.createCustomerAccount.accountName}`,
          location: 'header',
        });

        history.push(
          `/customer-accounts/view/${response.createCustomerAccount.customerId}`,
        );
      },
    });

  // Update customer account.
  const [updateCustomerAccount, { loading: updateCustomerAccountLoading }] =
    useMutation(UPDATE_CUSTOMER_ACCOUNT, {
      onError: (error) => {
        setRequestError(error);
      },
      onCompleted: (response) => {
        dispatchAction('SET_ALERT_MESSAGE', {
          type: 'success',
          message: `Successfully updated customer account: ${response?.updateCustomerAccount?.customerId}`,
          location: 'header',
        });

        history.push(
          `/customer-accounts/view/${response.updateCustomerAccount.customerId}`,
        );
      },
    });

  // Delete customer account.
  const [deleteCustomerAccount] = useMutation(DELETE_CUSTOMER_ACCOUNT, {
    onError: (error) => {
      setRequestError(error, 'SET_VEHICLE_COUNT_ERROR');
      resetModal();
    },
    onCompleted: (response) => {
      resetModal();
      dispatchAction('SET_ALERT_MESSAGE', {
        type: 'success',
        message: `The account ${response?.deleteCustomerAccount?.accountName} has been deleted.`,
        location: 'header',
      });

      refetch({
        variables: {
          limit: 10,
          offset: 0,
          order: 'updatedAt DESC',
        },
      });
    },
  });

  const [assignFsrEmail] = useMutation(ASSIGN_FSR_EMAIL, {
    onError: (error) => {
      setRequestError(error);
      resetModal();
    },
    onCompleted: () => {
      refetch({
        variables: {
          limit: 10,
          offset: 0,
          order: 'updatedAt DESC',
        },
      });
    },
  });

  const [updateCustomerTransportationFees] = useMutation(
    UPDATE_CUSTOMER_TRANSPORTATION_FEES,
    {
      onError: (error) => {
        setRequestError(error);
        resetModal();
      },
    },
  );

  const [getCountriesList] = useLazyQuery(GET_COUNTRIES, {
    fetchPolicy: 'network-only', // Used for first execution
    nextFetchPolicy: 'cache-first', // Used for subsequent executions
    onError: (error) => setRequestError(error),
    onCompleted: (responseData) => {
      if (responseData?.getCountries) {
        const countryOptions = responseData.getCountries.map((country) => ({
          value: country.id.toString(),
          label: `${country.isoCountryCode2} - ${country.countryName}`,
        }));

        dispatchAction('SET_OPTIONS_DATA_COUNTRIES', countryOptions);
      }
    },
  });

  const [getUsStates] = useLazyQuery(GET_STATES, {
    fetchPolicy: 'network-only', // Used for first execution
    nextFetchPolicy: 'cache-first', // Used for subsequent executions
    onError: (error) => setRequestError(error),
    onCompleted: (responseData) => {
      if (responseData?.getStates) {
        let stateOptions = responseData.getStates.map((s) => ({
          value: s.id,
          label: s.stateName,
        }));

        stateOptions = [
          { value: 'default', label: '-Select-' },
          ...stateOptions,
        ];

        dispatchAction('SET_OPTIONS_DATA_STATES', stateOptions);
      }
    },
  });

  const [getInternationalPhoneCodes] = useLazyQuery(GET_PHONE_COUNTRY_CODES, {
    fetchPolicy: 'network-only', // Used for first execution
    nextFetchPolicy: 'cache-first', // Used for subsequent executions
    onError: (error) => setRequestError(error),
    onCompleted: (responseData) => {
      if (responseData?.getPhoneCountryCodes) {
        const callCodeOptions = responseData.getPhoneCountryCodes.map(
          (callCode) => ({
            value: callCode.description,
            label: callCode.description,
          }),
        );

        dispatchAction('SET_OPTIONS_DATA_INT_DIAL', callCodeOptions);
      }
    },
  });

  const [getZones] = useLazyQuery(GET_ZONES_CENTERS, {
    fetchPolicy: 'network-only', // Used for first execution
    nextFetchPolicy: 'cache-first', // Used for subsequent executions
    onError: (error) => setRequestError(error),
    onCompleted: (responseData) => {
      if (responseData?.getZones) {
        const zones = [];
        const fmcsWithZoneAndRegion = [];
        const zoneOptions = responseData?.getZones.map((zone) => ({
          value: zone.id,
          label: zone.name,
        }));
        zones.push(...zoneOptions);

        let fmcOptions = responseData?.getZones.flatMap((zone) =>
          zone.centers.map((center) => ({
            value: `${zone.id}:${center.id}:${center.regionId}`,
            label: center.name,
            zoneId: zone.id,
            regionId: center.regionId,
            centerId: center.id,
          })),
        );

        fmcOptions = [
          {
            value: 'default',
            label: '-Select-',
            zoneId: '',
            regionId: 0,
            centerId: '',
          },
          ...fmcOptions,
        ];

        fmcsWithZoneAndRegion.push(...fmcOptions);

        dispatchAction('SET_OPTIONS_DATA_ZONES', {
          zones,
          fmcsWithZoneAndRegion,
        });
      }
    },
  });

  useEffect(() => {
    getCountriesList();
    getUsStates();
    getZones();
    getInternationalPhoneCodes();
  }, []);

  // this useEffect might not be needed anymore
  useEffect(() => {
    const fetchLookupData = async () => {
      await getCountriesList();
      await getUsStates();
    };

    if (state?.optionsData?.zones && !state?.optionsData?.countries) {
      fetchLookupData().catch((e) => {
        // eslint-disable-next-line no-console
        console.error('Error loading lookup states and countries', e);
      });
    }
  }, [state?.optionsData?.zones]);

  return (
    <CustomerAccountContext.Provider
      value={{
        ...state,
        dispatchAction,
        resetModal,
        getCustomerAccountsList,
        getCustomerAccount,
        createCustomerAccount,
        updateCustomerAccount,
        deleteCustomerAccount,
        setRequestError,
        getInternationalPhoneCodes,
        assignFsrEmail,
        customerAccountsListLoading,
        customerAccountLoading,
        createCustomerAccountLoading,
        updateCustomerAccountLoading,
        updateCustomerTransportationFees,
        customerAccountFormDataTemplate,
      }}
    >
      {children}
    </CustomerAccountContext.Provider>
  );
};

export default CustomerAccountProvider;

export const useCustomerAccounts = () => useContext(CustomerAccountContext);
