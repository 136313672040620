import React from 'react';
import { StatusBadge } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';

const MILEAGE_EXPRESS_STATUS_COLORS = {
  Pending: 'Info-Gray',
  Telematics: 'Info-Alt-Gray',
  Reported: 'Ready-Gray',
  'Ready for update': 'Urgent-Gray',
};

export default function MileageExpressStatusBadge({ status }) {
  return (
    <StatusBadge variant={MILEAGE_EXPRESS_STATUS_COLORS[status]}>
      {status}
    </StatusBadge>
  );
}

MileageExpressStatusBadge.propTypes = {
  status: PropTypes.string.isRequired,
};
