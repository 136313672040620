import React from 'react';
import { useOfficeManagement } from './office-management-provider';
import { showAlert } from './utils';

const CustomerAccountHeader = () => {
  const { alertMessage } = useOfficeManagement();
  return <>{showAlert({ alertPosition: 'bottom', alertMessage })}</>;
};

export default CustomerAccountHeader;
