/* eslint-disable react/prop-types */
import moment from 'moment';
import {
  Button,
  RequiredFieldIndicator,
  Modal,
} from '@gsa/afp-component-library';
import React from 'react';
import { z } from 'zod';
import { FormGenerator } from '@gsa/afp-shared-form-utils';
import FormattedNumberControl from 'utilities/form-generator-controls/formatted-number-control';

import { FG_DEFAULT_OPTION_VAL, purchaseTypeOptions } from 'utilities/consts';

import { getOptionsWithDefault } from 'utilities/form-generator-controls/helper';
import { emdash } from 'components/common';

export const datePickerValidate = (vehicle) => (val, ctx) => {
  const isDisposalLessThanAcquisitionDate =
    moment(vehicle?.disposalDate).diff(moment(val)) <= 0;
  const isAcquisitionDateMoreThanYesterday =
    moment(val).diff(moment(new Date())) > 0;
  if (isDisposalLessThanAcquisitionDate || isAcquisitionDateMoreThanYesterday) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message:
        "Acquisition date must be on or before today's date and less than disposal date (when provided)",
    });
  }
};

export default function GFPurchaseInfoEdit({
  onClose,
  vehicle,
  onSave,
  isGFModalOpen,
}) {
  const purchaseTypeSelectOptions = purchaseTypeOptions.map((option) => ({
    value: option.code,
    label: option.description,
  }));

  const schema = z.object({
    purchaseType: z
      .string()
      .refine((data) => data !== FG_DEFAULT_OPTION_VAL, 'This is required'),
    deliveredMiles: z.string().refine((data) => data, 'This is required'),
    originalAcquisitionDate: z.coerce
      .date({
        invalid_type_error: 'This is required',
      })
      .superRefine(datePickerValidate(vehicle)),
    acquisitionCost: z.coerce
      .number()
      .refine((data) => data && data > 0, 'This is required'),
  });

  const data = {
    purchaseType: vehicle?.assetAcquisition?.purchaseType
      ? vehicle?.assetAcquisition?.purchaseType.toString()
      : FG_DEFAULT_OPTION_VAL,
    deliveredMiles: vehicle?.assetAcquisition?.deliveredMiles
      ? vehicle?.assetAcquisition?.deliveredMiles.toString()
      : '',
    originalAcquisitionDate:
      vehicle?.assetAcquisition?.originalAcquisitionDate || '',
    acquisitionCost: vehicle?.assetAcquisition?.acquisitionCost
      ? vehicle?.assetAcquisition?.acquisitionCost.toString()
      : '',
    requisitionNumber: vehicle?.assetAcquisition?.requisitionNumber || emdash,
    consolidationNumber:
      vehicle?.assetAcquisition?.consolidationNumber || emdash,
    consolidationDate: vehicle?.assetAcquisition?.consolidationDate
      ? moment
          .utc(vehicle?.assetAcquisition?.consolidationDate)
          .format('MM/DD/YYYY')
      : emdash,
  };

  const content = {
    buttonControls: {
      submit: false,
      cancel: false,
    },
    sections: [
      {
        fieldLayout: 'horizontal',
        gap: '32px',
        fields: [
          {
            id: 'purchaseType',
            label: 'Purchase type',
            type: 'select',
            options: getOptionsWithDefault(purchaseTypeSelectOptions),
            required: true,
          },
          {
            id: 'originalAcquisitionDate',
            label: 'Delivered date',
            type: 'datepicker',
            required: true,
          },
        ],
      },
      {
        fieldLayout: 'horizontal',
        gap: '32px',
        fields: [
          {
            id: 'acquisitionCost',
            label: 'Acquisition cost',
            type: 'currency',
            required: true,
          },
          {
            id: 'deliveredMiles',
            label: 'Delivered miles',
            type: 'format-number',
            element: {
              control: { attachments: { after: ['miles'] } },
            },
            required: true,
          },
        ],
      },
      {
        fieldLayout: 'horizontal',
        gap: '32px',
        fields: [
          {
            id: 'consolidationNumber',
            label: 'Consolidation Number',
            type: 'text',
            disabled: true,
          },
          {
            id: 'consolidationDate',
            label: 'Consolidation Date',
            type: 'text',
            disabled: true,
          },
        ],
      },
      {
        fieldLayout: 'horizontal',
        gap: '16px',
        fields: [
          {
            id: 'requisitionNumber',
            label: 'Requisition Number',
            type: 'text',
            disabled: true,
          },
          {
            type: 'spacer',
          }
        ],
      },
    ],
  };

  return (
    isGFModalOpen && (
      <div className="afp-modal-wrapper">
        <div className="afp-modal-overlay">
          <Modal
            variant="large"
            title={<h2>Edit purchase information</h2>}
            onClose={onClose}
            actions={
              <>
                <Button variant="unstyled" onClick={onClose} label="Close" />
                <Button
                  className="margin-left-2"
                  type="submit"
                  form="gf-purchase-edit-form"
                  label="Save and close"
                />
              </>
            }
          >
            <p>
              Edit purchase information for for VIN{' '}
              <span className="text-bold">{vehicle.id}</span> in the form below.
            </p>
            <p>
              Required fields are marked with an asterisk (
              <RequiredFieldIndicator />
              ).
            </p>
            <FormGenerator
              id="gf-purchase-edit-form"
              schema={schema}
              content={content}
              defaultValues={data}
              controls={{ 'format-number': FormattedNumberControl }}
              onSubmit={onSave}
            />
          </Modal>
        </div>
      </div>
    )
  );
}
