import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classnames from 'classnames';
import phoneNumberFormatter from 'utilities/phoneNumberFormatter';

const DetailRow = ({ data }) => {
  const {
    comment,
    canceledByUser,
    canceledAt,
    address,
    contact,
    archivedContact,
  } = data;

  const borderClasses = classnames(
    'border-bottom border-base-lighter padding-y-1',
  );
  const labelClasses = classnames('padding-bottom-1 padding-y-1');
  return (
    <div className="grid-row grid-gap flex-justify">
      <div className="grid-col-6">
        <div className="margin-bottom-2" data-testid="data-section">
          <div
            className={`${borderClasses} display-flex flex-justify`}
            data-testid="data-section"
          >
            <strong>Address</strong>
            {address && (
              <div className="display-block">
                {(archivedContact || address).agency && (
                  <div>{(archivedContact || address).agency}</div>
                )}
                {(archivedContact || address).address1 && (
                  <div>{(archivedContact || address).address1}</div>
                )}
                {(archivedContact || address)?.address2 && (
                  <div>{(archivedContact || address).address2}</div>
                )}
                <div>
                  {`${(archivedContact || address).city}, ${
                    (archivedContact || address).stateCode
                  } ${(archivedContact || address).postalCode}`}
                </div>
              </div>
            )}
          </div>
          <div className={`${borderClasses} ${labelClasses}`}>
            <strong>Comments</strong>
            <div className="margin-top-1">{comment}</div>
          </div>
        </div>
      </div>
      <div className="grid-col-6">
        <div
          className={`${borderClasses} display-flex flex-justify`}
          data-testid="phone-number"
        >
          <strong>Phone number</strong>
          {`${
            (archivedContact || contact)?.phoneNumber &&
            phoneNumberFormatter((archivedContact || contact).phoneNumber)
          } ${
            (archivedContact || contact)?.extension
              ? `ext. ${(archivedContact || contact)?.extension}`
              : ''
          }`}
        </div>
        {canceledAt && (
          <>
            <div
              className={`${borderClasses} display-flex flex-justify`}
              data-testid="data-section"
            >
              <strong>Canceled by</strong>
              {canceledByUser?.firstName} {canceledByUser?.lastName}
            </div>
            <div
              className={`${borderClasses} display-flex flex-justify`}
              data-testid="data-section"
            >
              <strong>Date and time canceled</strong>
              {moment(canceledAt).format('L hh:mm A')}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

DetailRow.defaultProps = {
  data: PropTypes.shape({
    address: PropTypes.shape({
      agency: '',
      address1: '',
      address2: '',
      city: '',
      postalCode: '',
      stateCode: '',
    }),
    comment: '',
    canceledAt: '',
    canceledByUser: PropTypes.shape({
      firstName: '',
      lastName: '',
    }),
    contact: PropTypes.shape({
      phoneNumber: '',
      extension: '',
    }),
  }),
};

DetailRow.propTypes = {
  data: PropTypes.shape({
    address: PropTypes.shape({
      address1: PropTypes.string,
      address2: PropTypes.string,
      agency: PropTypes.string,
      city: PropTypes.string,
      postalCode: PropTypes.string,
      stateCode: PropTypes.string,
    }),
    archivedContact: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      phoneNumber: PropTypes.string,
      extension: PropTypes.string,
      agency: PropTypes.string,
      address1: PropTypes.string,
      address2: PropTypes.string,
      city: PropTypes.string,
      stateCode: PropTypes.string,
      postalCode: PropTypes.string,
    }),
    canceledAt: PropTypes.string,
    canceledByUser: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
    comment: PropTypes.string,
    contact: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      phoneNumber: PropTypes.string,
      extension: PropTypes.string,
    }),
  }),
};

export default DetailRow;
