import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import { SelectDropdown, Button, useModal } from '@gsa/afp-component-library';
import GarageAddressForm from './garage-address-form';
import { CDD_FORM_FIELDS, YES_NO_OPTIONS } from './consts';
import CDDPopOverModal from './cdd-pop-over-modal';

const VehicleLocationForm = ({ isAOVehicle }) => {
  const {
    formState: { errors },
    control,
    setValue,
  } = useFormContext();

  const { isOpen, openModal, closeModal } = useModal();

  return (
    <div data-testid="cdd-vehicle-location-form">
      <div className="grid-col-6">
        <Controller
          name={CDD_FORM_FIELDS.garageDomestic.name}
          control={control}
          render={({ field, ref }) => {
            return (
              <SelectDropdown
                {...field}
                ref={ref}
                label={
                  <>
                    <span className="text-bold">
                      Is the vehicle in a domestic location?
                    </span>
                    <Button
                      position="right"
                      variant="unstyled"
                      className="margin-left-1"
                      rightIcon={{ name: 'info', className: 'text-black' }}
                      onClick={() => {
                        openModal();
                      }}
                      data-testid="domestic-location-info-button"
                      aria-label="info"
                    />
                  </>
                }
                name={CDD_FORM_FIELDS.garageDomestic.name}
                id={CDD_FORM_FIELDS.garageDomestic.id}
                options={YES_NO_OPTIONS}
                onChange={(e) => {
                  const garageDomesticValue = e.target.value;
                  if (
                    garageDomesticValue === 'true' ||
                    garageDomesticValue === true
                  ) {
                    setValue(CDD_FORM_FIELDS.garageCountryCode.name, 'US');
                  }
                  setValue(
                    CDD_FORM_FIELDS.garageDomestic.name,
                    garageDomesticValue,
                  );
                }}
                errorMessage={
                  errors?.[CDD_FORM_FIELDS.garageDomestic.name]
                    ? errors[CDD_FORM_FIELDS.garageDomestic.name].message
                    : null
                }
                aria-invalid={
                  errors?.[CDD_FORM_FIELDS.garageDomestic.name]
                    ? 'true'
                    : 'false'
                }
              />
            );
          }}
        />
        <Controller
          name={CDD_FORM_FIELDS.isLocationWithheld.name}
          control={control}
          render={({ field, ref }) => {
            return (
              <SelectDropdown
                {...field}
                ref={ref}
                label={
                  <span className="text-bold">
                    Withhold location of vehicle
                  </span>
                }
                name={CDD_FORM_FIELDS.isLocationWithheld.name}
                id={CDD_FORM_FIELDS.isLocationWithheld.id}
                options={YES_NO_OPTIONS}
                errorMessage={
                  errors?.[CDD_FORM_FIELDS.isLocationWithheld.name]
                    ? errors[CDD_FORM_FIELDS.isLocationWithheld.name].message
                    : null
                }
                aria-invalid={
                  errors?.[CDD_FORM_FIELDS.isLocationWithheld.name]
                    ? 'true'
                    : 'false'
                }
              />
            );
          }}
        />
        {isAOVehicle ? (
          <Controller
            name={CDD_FORM_FIELDS.isMsa.name}
            control={control}
            render={({ field, ref }) => {
              return (
                <SelectDropdown
                  {...field}
                  ref={ref}
                  label={
                    <span className="text-bold">
                      Is MSA (Metropolitan Statistical Area)
                    </span>
                  }
                  name={CDD_FORM_FIELDS.isMsa.name}
                  id={CDD_FORM_FIELDS.isMsa.id}
                  options={YES_NO_OPTIONS}
                  errorMessage={
                    errors?.[CDD_FORM_FIELDS.isMsa.name]
                      ? errors[CDD_FORM_FIELDS.isMsa.name].message
                      : null
                  }
                  aria-invalid={
                    errors?.[CDD_FORM_FIELDS.isMsa.name] ? 'true' : 'false'
                  }
                />
              );
            }}
          />
        ) : null}
      </div>
      <h4 className="margin-top-4">Vehicle garage address</h4>
      <GarageAddressForm />
      {!isAOVehicle ? (
        <div className="grid-col-6">
          <Controller
            name={CDD_FORM_FIELDS.homeToWork.name}
            control={control}
            render={({ field, ref }) => {
              return (
                <SelectDropdown
                  {...field}
                  ref={ref}
                  label={<span className="text-bold">Home to work</span>}
                  name={CDD_FORM_FIELDS.homeToWork.name}
                  id={CDD_FORM_FIELDS.homeToWork.id}
                  options={YES_NO_OPTIONS}
                  errorMessage={
                    errors?.[CDD_FORM_FIELDS.homeToWork.name]?.message ?? null
                  }
                  aria-invalid={
                    errors?.[CDD_FORM_FIELDS.homeToWork.name] ? 'true' : 'false'
                  }
                />
              );
            }}
          />
        </div>
      ) : null}
      {isOpen && (
        <CDDPopOverModal
          isOpen={isOpen}
          closeModal={closeModal}
          title='Vehicle location'
        />
      )}
    </div>
  );
};

VehicleLocationForm.propTypes = {
  isAOVehicle: PropTypes.bool.isRequired,
};

export default VehicleLocationForm;
