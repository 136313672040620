import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Typeahead } from '@gsa/afp-component-library';
import { GET_MAKES_BY_PARTIAL_NAME } from '../../../services/data-layer';
import { nullForForm } from '../../../utilities/common';

export default function MakeLookup({
  onSelect = () => {},
  onChange = () => {},
  value = '',
  errorMessage = '',
}) {
  const [getMakesByPartialName, { data }] = useLazyQuery(
    GET_MAKES_BY_PARTIAL_NAME,
    {
      fetchPolicy: 'no-cache',
      onError(err) {
        console.warn(err);
      },
    },
  );

  const [typeaheads, setTypeaheads] = useState([]);

  useEffect(() => {
    const makes = data?.getMakesByPartialName;
    if (makes) {
      setTypeaheads(makes.map((m) => m.makeName));
    }
  }, [data]);

  const fetchMakes = (_accessor, val) => {
    getMakesByPartialName({ variables: { makeName: val } });
  };

  const handleSelect = (val) => {
    const make = data?.getMakesByPartialName.find(
      (item) => item.makeName === val,
    );
    if (make) {
      onSelect(make);
    } else {
      onSelect({ makeName: null, makeCode: null });
    }
  };

  return (
    <Typeahead
      id="make"
      accessor="make"
      label="Make"
      labelClass="text-bold"
      filterValue={nullForForm(value)}
      onFilterChange={onChange}
      fetchTypeaheadValues={fetchMakes}
      typeaheadValues={typeaheads}
      placeholder="Search Makes..."
      onOptionEnter={handleSelect}
      inputCharNum={3}
      required
      ariaLabel="Make"
      debounceDelay={500}
      errorMessage={errorMessage}
      onClear={() => handleSelect(null)}
    />
  );
}
