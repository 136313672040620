/* eslint-disable react/prop-types */
import React from 'react';
import { AssignVehicleForm } from './assign-vehicle-form';
import './search-result.css';

export const AssignmentInfo = ({
  setModalError,
  save,
  assignmentData,
  setAssignmentData,
  customer,
}) => {
  const CustomerInfo = () => {
    const poc = customer?.primaryContactAssociation?.pointOfContact || {};
    return (
      <>
        <h2>Customer information</h2>
        <b>{customer.accountName}</b>
        <div>{poc.pocAddress1}</div>
        <div>
          {poc.pocCity}, {poc.pocStateCode} {poc.pocPostalCode}
        </div>
      </>
    );
  };

  return (
    <>
      <CustomerInfo customer={customer} />
      <h2>Vehicle garaged address</h2>
      <AssignVehicleForm
        setModalError={setModalError}
        onSubmit={save}
        assignmentData={assignmentData}
        setAssignmentData={setAssignmentData}
      />
    </>
  );
};
