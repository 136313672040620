import {
  Alert,
  Button,
  Modal,
  RequiredFieldIndicator,
  Spinner,
} from '@gsa/afp-component-library';
import React, { useEffect } from 'react';
import { carTitleConstructor } from 'utilities/car-title';
import { emdash } from 'components/common';
import ManageMotorPoolVehicle from './forms/manage-motor-pool-vehicle';
import ReserveVehicle from '../reservations/forms/reservation-add-edit-form';
import { useMotorPool } from '../../motor-pool-provider';

const ModalTitle = () => {
  const {
    motorPoolVehicleModalMode,
    motorPoolVehicleSelected,
    dispatchAction,
  } = useMotorPool();

  useEffect(() => {
    dispatchAction('SET_ALERT_MESSAGE', { type: '', message: '' });
  }, []);

  let title = '';
  switch (motorPoolVehicleModalMode) {
    case 'ADD_MOTOR_POOL_VEHICLE':
      title = <h2>Add vehicle</h2>;
      break;
    case 'UPDATE_MOTOR_POOL_VEHICLE':
      title = <h2>Edit vehicle</h2>;
      break;
    case 'DELETE_MOTOR_POOL_VEHICLE':
      title = <h2>Remove vehicle</h2>;
      break;
    case 'RESERVE_MOTOR_POOL_VEHICLE':
      {
        const { modelYear, vehicleMake, vehicleModel, modelCode } =
          motorPoolVehicleSelected?.vehicle;
        let vehicleTitle = carTitleConstructor(
          modelYear,
          vehicleMake,
          vehicleModel,
          modelCode,
        );
        vehicleTitle = vehicleTitle === emdash ? 'vehicle' : vehicleTitle;
        title = <h2>Reserve {vehicleTitle}</h2>;
      }
      break;
    default:
      break;
  }

  return title;
};

const ModalContent = () => {
  const { motorPoolVehicleModalMode } = useMotorPool();

  switch (motorPoolVehicleModalMode) {
    case 'ADD_MOTOR_POOL_VEHICLE':
    case 'UPDATE_MOTOR_POOL_VEHICLE':
      return (
        <>
          <p>
            Required fields are marked with an asterisk (
            <RequiredFieldIndicator />
            ).
          </p>
          <div>
            <ManageMotorPoolVehicle />
          </div>
        </>
      );
    case 'DELETE_MOTOR_POOL_VEHICLE':
      return (
        <div>
          Are you sure you want to remove the selected vehicle from the pool?
          This action cannot be undone and all reservations associated with the
          vehicle will be deleted.
        </div>
      );
    case 'RESERVE_MOTOR_POOL_VEHICLE':
      return (
        <>
          <p>
            Required fields are marked with an asterisk (
            <RequiredFieldIndicator />
            ).
          </p>
          <ReserveVehicle />
        </>
      );
    default:
      return null;
  }
};

const ModalAction = () => {
  const {
    resetMotorPoolVehicleModal,
    motorPoolVehicleModalMode,
    deleteMotorPoolVehicle,
    motorPoolVehicleSelected,
    updateMotorPoolVehicleLoading,
    createReservationLoading,
  } = useMotorPool();

  switch (motorPoolVehicleModalMode) {
    case 'ADD_MOTOR_POOL_VEHICLE':
    case 'UPDATE_MOTOR_POOL_VEHICLE':
      return (
        <>
          <Button
            variant="unstyled"
            className="margin-right-2"
            onClick={resetMotorPoolVehicleModal}
            data-testid="motor-pool-vehicles-listing-add-cancel-button"
            label="Cancel"
          />
          <Button
            variant="primary"
            type="submit"
            disabled={updateMotorPoolVehicleLoading}
            form="motor-pool-vehicle-listing-add-form"
            data-testid="motor-pool-vehicles-listing-add-submit-button"
            label="Submit"
          />
          {updateMotorPoolVehicleLoading && (
            <Spinner
              size="small"
              className="display-inline-block margin-left-2"
            />
          )}
        </>
      );
    case 'DELETE_MOTOR_POOL_VEHICLE':
      return (
        <>
          <Button
            variant="unstyled"
            className="margin-right-2"
            onClick={resetMotorPoolVehicleModal}
            data-testid="motor-pool-vehicles-listing-cancel-button"
            label="Cancel"
          />
          <Button
            variant="secondary"
            onClick={() => {
              deleteMotorPoolVehicle({
                variables: {
                  id: motorPoolVehicleSelected.id,
                },
              });
            }}
            data-testid="motor-pool-vehicle-delete-button"
            form="motor-pool-vehicle-delete-form"
            label="Remove vehicle"
          />
        </>
      );
    case 'RESERVE_MOTOR_POOL_VEHICLE':
      return (
        <>
          <Button
            variant="unstyled"
            className="margin-right-2"
            onClick={resetMotorPoolVehicleModal}
            data-testid="reserve-vehicle-cancel-button"
            label="Cancel"
          />
          <Button
            variant="primary"
            type="submit"
            form="reservation-add-edit-form"
            data-testid="reserve-vehicle-submit-button"
            disabled={createReservationLoading}
            label="Submit"
          />
          {createReservationLoading && (
            <Spinner
              size="small"
              className="display-inline-block margin-left-2"
            />
          )}
        </>
      );
    default:
      return null;
  }
};

const MotorPoolVehiclesModal = () => {
  const {
    resetMotorPoolVehicleModal,
    motorPoolVehicleModalShow,
    alertMessage,
  } = useMotorPool();
  const { header, message, type, context } = alertMessage;

  return motorPoolVehicleModalShow ? (
    <div className="afp-modal-wrapper">
      <div className="afp-modal-overlay">
        <Modal
          title={<ModalTitle />}
          actions={<ModalAction />}
          onClose={resetMotorPoolVehicleModal}
          variant="large"
        >
          {(context === 'updateMotorPoolVehicle' ||
            context === 'createReservation') &&
            message && (
              <div
                className="margin-bottom-2"
                data-testid="pool-vehicle-modal-alert"
              >
                <Alert type={type} heading={header || null}>
                  {message}
                </Alert>
              </div>
            )}
          <ModalContent />
        </Modal>
      </div>
    </div>
  ) : null;
};

export default MotorPoolVehiclesModal;
