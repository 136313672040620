import React from 'react';
import { useLocation } from 'react-router-dom';
import useAlertManager from 'utilities/alert-manager';
import { LoadingQueryResult } from 'components/loading-query-result/loading-query-result';
import './vehicleInventory.css';
import HeaderWidget from './widgets/header-widget';
import FilterGrid from './filter-grid';
import useDataController from './use-data-controller';
import VehicleInventoryFilterProvider from './vehicle-inventory-filter-provider';

const VehicleInventory = () => {
  const [Alert, alertUtils] = useAlertManager(true, true, true, 'margin-top-2');
  const { loading, mutation } = useDataController({
    alertUtils,
  });

  const location = useLocation();
  const legacyCustomerNumber = location?.state?.legacyCustomerNumber;

  return (
    <VehicleInventoryFilterProvider legacyCustomerNumber={legacyCustomerNumber}>
      <article className="vehicle-inventory">
        <LoadingQueryResult loadingObject={loading}>
          <HeaderWidget Alert={Alert} />
        </LoadingQueryResult>

        <FilterGrid mutation={mutation} alertUtils={alertUtils} />
      </article>
    </VehicleInventoryFilterProvider>
  );
};

export default VehicleInventory;
