import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { cloneDeep } from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import {
  DetailsTable,
  FilterPanel,
  AFPTable,
  Icon,
  Spinner,
  Pagination,
  EmptyState,
} from '@gsa/afp-component-library';
import { emdash } from 'components/common';

import { useVehicle } from '../../../vehicle-context-provider';
import { GET_SALES_CODES, GET_BILLING_HISTORY } from '../billing-history-gql';
import BillingHistoryTableDetail from './billing-history-table-detail';
import {
  getMoneyFormat,
  getThousandSeperatedValue,
} from '../../vehicle-overview/helpers/common';

const BillingHistoryTable = () => {
  const { vehicle } = useVehicle();

  const DEFAULT_OPTION = {
    value: '',
    label: '- Select -',
    defaultValue: true,
  };

  const DEFAULT_FILTER = {
    operator: '$exact',
    key: 'asset_id',
    value: vehicle.uuid,
  };

  const tableData = [
    ['Current leasing account name', vehicle?.customer?.accountName || emdash],
    ['Current leasing account number', vehicle?.customer?.customerId || emdash],
    ['Fund code', vehicle?.ald?.fundCode || emdash],
    ['Description 1', vehicle?.ald?.account1 || emdash],
    ['Description 2', vehicle?.ald?.account2 || emdash],
  ];

  const TABLE_HEADER = [
    {
      Header: 'Transaction Date',
      accessor: 'created_at',
      sortable: true,
    },
    {
      Header: 'Sales code',
      accessor: 'salesCode',
      sortable: false,
    },
    {
      Header: 'Description',
      accessor: 'salesCodeDescription',
      sortable: false,
    },
    {
      Header: 'Total amount',
      accessor: 'totalAmount',
      sortable: false,
    },
  ];

  const initaialFilterStructure = [
    {
      expanded: true,
      filters: [
        {
          columnWidth: '5',
          key: 'sales_code',
          id: 'sales-code',
          label: 'Sales code',
          operator: '$exact',
          options: [DEFAULT_OPTION],
          permanent: false,
          title: 'Sales code',
          type: 'select',
          value: '',
        },
        {
          columnWidth: '6',
          hideClear: true,
          id: 'date-range-time',
          key: 'date-range-time',
          type: 'daterange',
          operator: '$between',
          options: {
            className: 'display-flex',
            startDate: {
              key: 'beginning',
              label: <strong>Start date</strong>,
            },
            endDate: {
              key: 'end',
              label: <strong>End date</strong>,
            },
            wrapperClass: 'margin-top-0',
          },
          value: {
            beginning: '',
            end: '',
          },
        },
      ],
      key: 1,
      requiredAsterisk: true,
      title: '',
    },
  ];
  const initialPaginationState = {
    limit: 10,
    offset: 0,
    currentPage: 1,
    isReset: false,
  };

  const [paginationState, setPaginationState] = useState(
    initialPaginationState,
  );
  const [salesCodes, setSalesCodes] = useState(DEFAULT_OPTION);
  const [billingHistoryList, setBillingHistoryList] = useState();
  const [filterStructure, setfilterStructure] = useState(
    initaialFilterStructure,
  );
  const [filters, setFilters] = useState();
  const [order, setOrder] = useState('created_at DESC');

  const TABLE_ICON_COLLAPSED = (
    <Icon className="usa-icon--size-4" iconName="navigate_next" />
  );
  const TABLE_ICON_EXPANDED = (
    <Icon className="usa-icon--size-4" iconName="expand_more" />
  );

  const [getBillingHistoryList, { loading: billingHistoryLoading }] =
    useLazyQuery(GET_BILLING_HISTORY, {
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
      // onError: (error) => setRequestError(error),
      onCompleted: (responseData) => {
        if (responseData?.getBillingHistory) {
          const billingRecords = cloneDeep(responseData?.getBillingHistory);
          const records = billingRecords?.rows?.map((item) => {
            const billingRecord = item;
            billingRecord.created_at = moment(billingRecord.created_at).format(
              'MM/DD/YYYY',
            );
            const totalAmount = billingRecord.totalAmount
              ? parseFloat(billingRecord.totalAmount).toFixed(2)
              : '';
            const accessoryCharge = billingRecord.accessoryCharge
              ? parseFloat(billingRecord.accessoryCharge).toFixed(2)
              : '';
            billingRecord.totalAmount =
            getMoneyFormat(totalAmount) || emdash;
            billingRecord.accessoryCharge =
            getMoneyFormat(accessoryCharge) || emdash;
            billingRecord.endMileage = getThousandSeperatedValue(
              billingRecord.endMileage,
            );
            billingRecord.milesDriven = getThousandSeperatedValue(
              billingRecord.milesDriven,
            );
            return billingRecord;
          });
          billingRecords.rows = records;
          setBillingHistoryList(billingRecords);
        }
      },
    });

  const [getSalesCodes, { loading: salesCodeLoading }] = useLazyQuery(
    GET_SALES_CODES,
    {
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
      onCompleted: (responseData) => {
        if (responseData?.getSalesCodes) {
          const saleCodes = responseData?.getSalesCodes.map((salesCode) => ({
            value: salesCode.code,
            label: `${salesCode.code} - ${salesCode.description}`,
            key: salesCode.code,
          }));
          setSalesCodes([...saleCodes]);
        }
      },
    },
  );

  useEffect(() => {
    if (vehicle.uuid) {
      const filterToApply = {
        filters: {
          operator: 'AND',
          value: [DEFAULT_FILTER],
        },
      };
      setFilters(filterToApply.filters);
      getBillingHistoryList({
        variables: {
          limit: paginationState.limit,
          offset: paginationState.offset,
          order,
          filters: filterToApply.filters,
        },
      });
      getSalesCodes();
    }
  }, [vehicle]);

  useEffect(() => {
    if (salesCodes?.length > 0) {
      const filterStruct = cloneDeep(filterStructure);
      filterStruct[0].filters[0].options = [DEFAULT_OPTION, ...salesCodes];
      setfilterStructure(filterStruct);
    }
  }, [salesCodes]);

  const setBillingHistoryFilters = (filtersToApply) => {
    const filterToApply = {
      filters: {
        operator: 'AND',
        value: [DEFAULT_FILTER],
      },
    };
    if (filtersToApply?.length > 0) {
      Object.keys(filtersToApply).forEach((key) => {
        const valueObj = filtersToApply[key];
        if (valueObj) {
          filterToApply.filters.value.push(valueObj);
        }
      });
    }
    setFilters(filterToApply.filters);
    getBillingHistoryList({
      variables: {
        limit: paginationState.limit,
        offset: paginationState.offset,
        filters: filterToApply.filters,
        order,
      },
    });
  };

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    const offset = (currentPage - 1) * itemsPerPage;
    setPaginationState({
      limit: itemsPerPage,
      offset,
      currentPage,
      filters,
      order,
    });
    getBillingHistoryList({
      variables: {
        limit: itemsPerPage,
        offset,
        order,
        filters,
      },
    });
  };

  const sort = (orderBy) => {
    setOrder(orderBy);
    getBillingHistoryList({
      variables: {
        limit: paginationState.limit,
        offset: paginationState.offset,
        order: orderBy,
        filters,
      },
    });
  };

  return (
    <>
      <div className="grid-row grid-gap">
        <div className="tablet:grid-col-7">
          <h4 className="title-s-caps text-primary">CUSTOMER INFORMATION</h4>
          <div
            className="bg-gray-3 radius-md padding-y-2 padding-x-2"
            data-testid="afp-billing-details"
          >
            <DetailsTable
              className="afp-registration__section_container text-tabular"
              data={tableData}
            />
          </div>
        </div>
      </div>
      <div>
        <div className="grid-row">
          <div className="grid-col-12 margin-top-5 bg-primary-lightest padding-3">
            {salesCodeLoading && <Spinner className="padding-y-9" />}
            {salesCodes?.length > 0 && (
              <FilterPanel.FilterPanel
                filterStructure={filterStructure}
                mode="horizontal"
                model="Billing"
                setQueryFiltersState={(filters) =>
                  setBillingHistoryFilters(filters)
                }
                updateAppliedFilters={(filters) => {
                  setBillingHistoryFilters(filters);
                }}
              />
            )}
          </div>
        </div>
      </div>

      <div>
        <AFPTable
          columns={TABLE_HEADER}
          iconCollapsed={TABLE_ICON_COLLAPSED}
          iconExpanded={TABLE_ICON_EXPANDED}
          data={!billingHistoryLoading ? billingHistoryList?.rows || [] : []}
          testId="afp-billing-history"
          fullWidth
          bordered={false}
          defaultSort="trasactionDate asc"
          expandable
          scrollable={false}
          stacked
          selectAllHeader=""
          renderRowSubComponent={BillingHistoryTableDetail}
          onSort={(sortOrder) => {
            sort(sortOrder);
          }}
        />
        {billingHistoryLoading && <Spinner className="padding-y-9" />}
        {billingHistoryList?.rows?.length > 0 && (
          <Pagination
            fullWidth
            variant="advanced"
            itemsPerPageOptions={[10, 25, 50]}
            itemsCount={billingHistoryList?.count}
            itemsPerPage={paginationState.limit}
            currentPage={paginationState.currentPage}
            onPageChange={handlePaginationChange}
            isReset={paginationState.isReset}
          />
        )}
        {(billingHistoryList?.rows == null ||
          billingHistoryList?.rows?.length === 0) && (
          <div className="bg-gray-3 padding-y-5">
            <div className="text-center padding-y-4">
              <EmptyState alt="Image not available" hasBackground />
            </div>
            <div className="text-center text-bold">
              No billing history available
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default BillingHistoryTable;
BillingHistoryTable.propTypes = {
  vehicle: PropTypes.shape({
    customer: PropTypes.shape({
      accountName: PropTypes.string,
      customerId: PropTypes.string,
    }),
    ald: PropTypes.shape({
      fundCode: PropTypes.string,
      account1: PropTypes.string,
      account2: PropTypes.string,
    }),
  }).isRequired,
};
