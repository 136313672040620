import React from 'react';
import moment from 'moment';
import { Menu } from '@gsa/afp-component-library';
import { startCase } from 'lodash';
import { emdash } from 'components/common';

const getRowMenuItems = (status, ownershipTypeCode) => {
  const items = [
    {
      label: 'Edit',
      action: 'editMileageRecord',
      icon: 'edit',
    },
    ...(ownershipTypeCode === 'AO' ||
    (ownershipTypeCode === 'GF' && status === 'Pending')
      ? [
          {
            label: 'Delete',
            action: 'deleteMileageRecord',
            icon: 'cancel',
          },
        ]
      : []),
  ];

  return items;
};

// eslint-disable-next-line react/prop-types
const MileageRowActionButton = ({ onClick, menuItems, rowItem }) => {
  return (
    <>
      <div className="height-4 width-4">
        <Menu
          onActionClick={(action) => {
            onClick(action, rowItem);
          }}
          menuItems={menuItems}
          iconColor="text-primary"
        />
      </div>
    </>
  );
};

const formatMonthYear = (date) => {
  let formattedDate;
  if (date === null) {
    formattedDate = '-';
  } else {
    formattedDate = moment.utc(date).format('MM/YYYY');
  }
  return formattedDate;
};

const formatDate = (date) => {
  let formattedDate;
  if (date === null) {
    formattedDate = '-';
  } else {
    formattedDate = moment.utc(date).format('MM/DD/YYYY');
  }
  return formattedDate;
};

const formatMileageHistoryTableRow = (
  rowItem,
  buttonAction,
  ownershipTypeCode,
) => {
  const {
    mileageDate,
    odometer,
    daysInUse,
    createdAt,
    sourceType,
    enteredByUserInfo,
    mileageDriven,
    status,
    customer,
  } = rowItem;

  const canViewGFMileageActions = () =>
    ownershipTypeCode === 'GF' &&
    (status === 'Pending' || status === 'Reported' || status === 'Telematics');

  return {
    monthYear: formatMonthYear(mileageDate),
    mileageDate: formatDate(mileageDate),
    mileageDriven,
    odometer,
    daysInUse,
    dateOfEntry: formatDate(createdAt),
    user: enteredByUserInfo?.fullName,
    email: enteredByUserInfo?.email,
    customer,
    methodOfEntry: sourceType ? startCase(sourceType) : null,
    status,
    actions:
      ownershipTypeCode === 'AO' || canViewGFMileageActions() ? (
        <MileageRowActionButton
          onClick={buttonAction}
          menuItems={getRowMenuItems(status, ownershipTypeCode)}
          rowItem={rowItem}
        />
      ) : (
        emdash
      ),
  };
};

export const formatDataForMileageHistoryTable = (
  queryData,
  buttonAction,
  ownershipTypeCode,
) => {
  const rows =
    queryData?.getMileageByVehicle?.rows ||
    queryData?.getMileageByGFVehicle?.rows;
  if (rows) {
    return rows.map((row) => {
      return formatMileageHistoryTableRow(row, buttonAction, ownershipTypeCode);
    });
  }
  return [];
};

export default {
  formatDataForMileageHistoryTable,
};
