export const vehicleFilterKeys = {
  AGENCY_AND_BUREAU: 1,
  AGENCY_AND_BUREAU_AND_OFFICE: 2,
  CUSTOMER_ACCOUNT: 4,
  VIN_TYPEAHEAD: 5,
  MAKE_AND_MODEL: 'make-and-model',
  TAG_NUMBER: 'tagNumber',
  POC_EMAIL:
    '$assetContactAssociationPrimary.pointOfContactPrimary.email_address$',
  MAKE_CODE: 'makeCode',
  MODEL_CODE: 'modelCode',
  MODEL_YEAR: 'modelYear',
  VIN_FILTER: 'vin',
  LIFECYCLE: '$assetLifecycle.lifeCycle.lifecycle_indicator$',
  VEHICLE_STATUS: '$assetLifecycle.lifeCycle.lifecycle_indicator_status$',
  REGISTRATION_STATUS: 'vehicleRegistrationStatus',
  OWNERSHIP_TYPE_CODE: 'ownershipTypeCode',
  AGENCY_FILTER: '$customer.customer_agency_code$',
  BUREAU_FILTER: '$customer.customer_bureau_code$',
  FMC: 'fmcId',
  ZONE: '$vmsManagementCenter.zone_id$',
  FSR_USER_EMAIL: 'fsrUserEmail',
  LEGACY_CUSTOMER_FILTER: '$customer.legacy_customer_number$',
  OFFICE_FILTER: '$office_code$',
  VEHICLE_LOCATION: '$assetCustodian.entity_id$',
  VEHICLE_VENDOR_TYPE: '$assetCustodian.entity_type$',
  FMC_ZONE_FSR: 'fmc-zone-fsr',
  VEHICLE_LIFECYCLE_IND_AND_STATUS: 'vehicleLifecycleAndStatus',
};
