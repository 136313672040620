import moment from 'moment-timezone';
import React from 'react';

export const formatReservationScheduledDates = (values, motorPoolTimezone) => {
  const dateTimeFormat = 'YYYY-MM-DD HH:mm:ss';
  const dateTimeZoneFormat = 'YYYY-MM-DDTHH:mm:ss.SSSZ';
  let {
    scheduledPickupDate,
    scheduledReturnDate,
    scheduledStartTime,
    scheduledReturnTime,
  } = values;
  scheduledStartTime = moment(scheduledStartTime, 'HH:mm');
  scheduledPickupDate = moment(scheduledPickupDate).set({
    hour: scheduledStartTime.get('hour'),
    minute: scheduledStartTime.get('minute'),
  });
  const formatScheduledDate =
    moment(scheduledPickupDate).format(dateTimeFormat);
  const formattedScheduledPickupDate = moment
    .tz(formatScheduledDate, motorPoolTimezone)
    .utc()
    .format(dateTimeZoneFormat);
  scheduledReturnTime = moment(scheduledReturnTime, 'HH:mm');
  scheduledReturnDate = moment(scheduledReturnDate).set({
    hour: scheduledReturnTime.get('hour'),
    minute: scheduledReturnTime.get('minute'),
  });
  const formatReturnDate = moment(scheduledReturnDate).format(dateTimeFormat);
  const formattedScheduledReturnDate = moment
    .tz(formatReturnDate, motorPoolTimezone)
    .utc()
    .format(dateTimeZoneFormat);
  return {
    formattedScheduledPickupDate,
    formattedScheduledReturnDate,
  };
};

export const formatReservationDate = (value, timezone) => {
  return moment.tz(value, timezone).format('L h:mm A z');
};

export const getReservationReceiptPDFPayload = (motorPool, reservation) => {
  const { agencyCode, poolName, timezone } = motorPool;
  const {
    reservationNumber,
    scheduledPickupDate,
    scheduledReturnDate,
    actualPickupDate,
    actualReturnDate,
    reservationPurpose,
    comment,
    motorPoolMember,
    motorPoolVehicle,
    driverName,
    isDriverNeeded,
    isOnBehalf,
    odometerAtPickup,
    odometerAtReturn,
    vehicleCondition,
    totalKeysIssued,
    totalKeysReturned,
  } = reservation;

  return {
    motorPoolName: poolName,
    reservationNumber,
    scheduledPickupDate: scheduledPickupDate
      ? formatReservationDate(scheduledPickupDate, timezone)
      : null,
    scheduledReturnDate: scheduledReturnDate
      ? formatReservationDate(scheduledReturnDate, timezone)
      : null,
    requester: motorPoolMember?.member ? motorPoolMember.member.fullName : null,
    organization: agencyCode,
    reservationPurpose: reservationPurpose?.purpose,
    remarks: comment,
    tagNumber: motorPoolVehicle?.vehicle
      ? motorPoolVehicle.vehicle.tagNumber
      : null,
    actualPickupDate: actualPickupDate
      ? formatReservationDate(actualPickupDate, timezone)
      : null,
    actualReturnDate: actualReturnDate
      ? formatReservationDate(actualReturnDate, timezone)
      : null,
    driverName,
    motorPoolDriver: isDriverNeeded ? 'Yes' : 'No',
    isOnBehalf: isOnBehalf ? 'Yes' : 'No',
    pickupMileage: odometerAtPickup,
    returnMileage: odometerAtReturn,
    totalKeysIssued,
    totalKeysReturned,
    vehicleCondition,
    vehicle: {
      year: motorPoolVehicle?.vehicle
        ? motorPoolVehicle?.vehicle.modelYear
        : null,
      make: motorPoolVehicle?.vehicle?.vehicleMake
        ? motorPoolVehicle.vehicle.vehicleMake.makeName
        : null,
      model: motorPoolVehicle?.vehicle?.vehicleModel
        ? motorPoolVehicle.vehicle.vehicleModel.modelName
        : null,
    },
    vehicleComment: motorPoolVehicle?.comment ? motorPoolVehicle.comment : null,
    vehicleLocation: motorPoolVehicle?.location
      ? motorPoolVehicle.location
      : null,
  };
};
