import { gql } from '@apollo/client';

export const GET_VEHICLE_TELEMATICS = gql`
  query GetVehicleTelematics($vin: String!) {
    getVehicleTelematics(vin: $vin) {
      vin
      isTelematicsInstalled
      isTelematicsExempt
      assetTelematics {
        odometer
        odometerDate
      }
      assetTelematicsMetadata {
        deviceSerialNumber
        deviceId
      }
      assetTelematicsSubscriptions {
        subscriptionActivationDate
        subscriptionDeactivationDate
        isSubscriptionActive
      }
    }
  }
`;
