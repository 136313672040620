/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from 'react';
import { PropTypes } from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import {
  AFPTable,
  StatusBadge,
  DetailsTable,
  useModal,
  ActivityTracker,
} from '@gsa/afp-component-library';
import { GET_REPAIR_ORDERS_BY_VEHICLE } from 'services/data-layer/repair-order';
import usePagination from '../../../../utilities/use-pagination';
import TableNoData from '../../../../utilities/table-no-data';
import { useVehicle } from '../../vehicle-context-provider';
import DeleteOrderModal from './delete-order-modal';
import {
  HyperlinkedRepairOrder,
  AddRepairOrderButton,
  RepairRowActions,
} from './repair-actions';
import {
  REPAIR_STATUS,
  STATUS_BADGE_VAR,
  ORDER_TAB_INDEX,
  dollarToStr,
  utcDateStrToUS,
  getLineDesc,
  getLineCost,
  sortToOrder,
} from './helpers';
import './styles.css';
import useDateRangeFilter from '../hooks/use-date-range-filter';

const DEFAULT_ORDER = [
  ['transactionDate', 'DESC'],
  ['comments', 'createdAt', 'DESC'],
];

const RepairOrdersTable = ({ refetchTotal }) => {
  const [tableData, setTableData] = useState([]);
  const [order, setOrder] = useState(DEFAULT_ORDER);
  const [selectedServiceId, setSelectedServiceId] = useState(null);
  const [dateFiltersState, setDateFiltersState] = useState({
    filters: undefined,
  });

  const { vehicle, setSectionMsg, setPageMsg } = useVehicle();
  const {
    isOpen: isDeleteModalOpen,
    openModal: openDeleteModal,
    closeModal: closeDeleteModal,
  } = useModal();
  const [Pagination, { offset, limit, setItemsCount, resetPage }] =
    usePagination();

  const [fetchData, { data, loading }] = useLazyQuery(
    GET_REPAIR_ORDERS_BY_VEHICLE,
    {
      fetchPolicy: 'network-only',
      onError: () =>
        setSectionMsg({
          type: 'error',
          message: 'Unable to fetch repair orders',
        }),
    },
  );

  const refetchData = () => {
    fetchData({
      variables: {
        filters: dateFiltersState.filters,
        assetId: vehicle.uuid,
        offset,
        limit,
        order,
      },
    });
  };

  const onDelete = (servicdId) => {
    setSelectedServiceId(servicdId);
    openDeleteModal();
  };
  const onDeleteCompleted = (isSuccess = undefined) => {
    closeDeleteModal();
    if (isSuccess === true) {
      setPageMsg({
        type: 'success',
        message: `Repair order was successfully deleted.`,
      });
      // refresh total cost card
      refetchTotal();
      if (offset !== 0 && tableData.length === 1) {
        // deleted last item in page other than page 1;
        // reset to page 1; retch will be triggered by useEffect()
        resetPage();
      } else {
        // force to refresh current page
        refetchData();
      }
    } else if (isSuccess === false) {
      setPageMsg({
        type: 'error',
        message: `Unable to delete repair order ${selectedServiceId}`,
      });
    }
  };

  const onSort = (sortBy) => {
    if (sortBy) {
      const tableOrder = sortToOrder(sortBy);
      if (tableOrder[0] === 'isTransactionComplete') {
        tableOrder[1] = tableOrder[1] === 'ASC' ? 'DESC' : 'ASC';
      }
      setOrder([tableOrder]);
      resetPage(); // reset to page 1
    }
  };

  useEffect(() => {
    if (!data?.getRepairOrdersByVehicle) return;
    const { count, rows } = data.getRepairOrdersByVehicle;
    setItemsCount(count);
    setTableData(rows);
    refetchTotal();
  }, [data]);

  useEffect(() => {
    refetchData();
  }, [offset, limit, order, dateFiltersState]);

  const tableColumns = [
    {
      Header: 'Date of order',
      accessor: 'transactionDate',
      Cell: ({ value }) => utcDateStrToUS(value),
    },
    {
      Header: <>Repair&nbsp;order</>,
      accessor: 'serviceNumber',
      Cell: ({ row: { original } }) => (
        <HyperlinkedRepairOrder
          serviceId={original.id}
          serviceNumber={original.serviceNumber}
          invoiceNumber={original.invoiceNumber}
        />
      ),
      sortable: false,
    },
    {
      Header: 'Status',
      accessor: 'isTransactionComplete',
      Cell: ({ value }) => {
        const val = value === REPAIR_STATUS.DONE ? value : REPAIR_STATUS.PROG;
        return (
          <StatusBadge variant={STATUS_BADGE_VAR[val].variant}>
            {STATUS_BADGE_VAR[val].label}
          </StatusBadge>
        );
      },
    },
    {
      Header: 'Vendor name',
      accessor: 'vendor.name',
      sortable: false,
    },
    {
      Header: 'Total',
      accessor: 'payment.serviceTotal',
      headerClassName: 'cell-right',
      cellClassName: 'cell-right',
      Cell: ({ value }) => dollarToStr(value),
    },
    {
      Header: 'Actions',
      Cell: ({ row: { original } }) => (
        <RepairRowActions
          tab={ORDER_TAB_INDEX}
          serviceId={original.id}
          onDelete={() => onDelete(original.id)}
        />
      ),
      sortable: false,
    },
  ];

  const getComments = (comments) =>
    [...comments].map((cmt) => {
      const user = cmt.updatedByUserInfo || cmt.createdByUserInfo || {};
      return {
        id: cmt.id,
        type: 'Comment added',
        date: cmt.updatedAt || cmt.createdAt,
        userName: user.fullName,
        email: user.email,
        comment: cmt.comment,
      };
    });

  const renderRowSubComponent = useCallback(({ row: { original } }) => {
    const { payment, serviceLines } = original;
    const getOrderDetails = () => {
      return serviceLines.map((line) => [
        getLineDesc(line.serviceCode),
        getLineCost(line.serviceLineCosts),
      ]);
    };
    const getTotals = () => {
      return [
        ['Total labor cost', dollarToStr(payment?.totalLabor)],
        ['Total miscellaneous', dollarToStr(payment?.miscCost)],
        ['Total tax', dollarToStr(payment?.taxCost)],
        [
          'Repair order total amount',
          <b>{dollarToStr(payment?.serviceTotal)}</b>,
        ],
      ];
    };
    return (
      <div className="margin-left-10">
        <div className="grid-row grid-gap margin-bottom-2">
          <div className="tablet:grid-col-6">
            <h4 className="title-s-caps text-primary margin-top-0 margin-bottom-1">
              Order details
            </h4>
            <DetailsTable
              className="repairs-subrow-details-table"
              data={getOrderDetails()}
            />
          </div>

          <div className="tablet:grid-col-6">
            <h4 className="title-s-caps text-primary margin-top-0 margin-bottom-1">
              Totals
            </h4>
            <DetailsTable
              className="repairs-subrow-details-table"
              data={getTotals()}
            />
          </div>
        </div>
        {original.comments?.length > 0 && (
          <div>
            <h4 className="margin-top-4 margin-bottom-1">Comments</h4>
            <ActivityTracker
              className="margin-left-neg-1 margin-y-0"
              activities={getComments(original.comments)}
              variant="highlight"
            />
          </div>
        )}
      </div>
    );
  }, []);

  const { getDateRangeComponent, fromDate, toDate, touched } =
    useDateRangeFilter({
      classes: 'grid-row grid-gap flex-1',
      dateFormat: 'YYYY-MM-DD',
    });

  useEffect(() => {
    if (!fromDate || !toDate) {
      setDateFiltersState({ filters: undefined });
    } else if (touched) {
      setDateFiltersState({
        filters: {
          operator: '$and',
          conditions: [
            { operator: '$exact', key: 'asset_id', value: vehicle.uuid },
            {
              operator: '$between',
              key: 'transaction_date',
              value: [fromDate, toDate],
            },
          ],
        },
      });
    }
  }, [fromDate, toDate]);

  return (
    <div>
      <div className="grid-row grid-gap flex-justify">
        <div>
          <p className="text-bold">Transaction date</p>
          {getDateRangeComponent()}
        </div>
        <div className="tablet:grid-col flex-1 flex-align-self-end margin-top-2 tablet:margin-top-0">
          <div className="float-right">
            <AddRepairOrderButton />
          </div>
        </div>
      </div>
      <AFPTable
        columns={tableColumns}
        data={loading ? [] : tableData || []}
        testId="afp-repair-orders-table"
        defaultSort={DEFAULT_ORDER}
        fullWidth
        expandable
        scrollable={false}
        renderRowSubComponent={renderRowSubComponent}
        onSort={onSort}
      />
      <TableNoData
        loading={loading}
        hasNoData={!tableData?.length}
        bottomText="No repair data"
      />
      <Pagination loading={loading} />
      {isDeleteModalOpen && selectedServiceId && (
        <DeleteOrderModal
          onClose={onDeleteCompleted}
          serviceId={selectedServiceId}
        />
      )}
    </div>
  );
};

RepairOrdersTable.propTypes = {
  refetchTotal: PropTypes.func,
};
RepairOrdersTable.defaultProps = {
  refetchTotal: undefined,
};

export default RepairOrdersTable;
