import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

export const LicensePlateContext = createContext(null);

export default function Context({ children, ...props }) {
  return (
    <LicensePlateContext.Provider value={{ ...props }}>
      {children}
    </LicensePlateContext.Provider>
  );
}

Context.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useLicensePlate = () => useContext(LicensePlateContext);
