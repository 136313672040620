import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { FilterPanel } from '@gsa/afp-component-library';
import userFilterManager from '../../user-filters';

import { FETCH_TYPEAHEAD } from '../../../services/data-layer';

const ordersSidebar = ({ onChange, order, updateAppliedFilters }) => {
  const [fetchTypeaheads, { data }] = useLazyQuery(FETCH_TYPEAHEAD, {
    fetchPolicy: 'no-cache',
  });
  const { userFilter } = userFilterManager('orders');
  const [typeaheadData, setTypeaheadData] = useState();

  useEffect(() => {
    if (data) {
      setTypeaheadData(data.getTypeaheads.typeaheads);
    }
  }, [data]);

  return (
    <>
      <FilterPanel.FilterPanel
        filterStructure={userFilter}
        setQueryFiltersState={onChange}
        clearButtonLabel="Reset all"
        showClearIcon
        model="UnicorTag"
        order={order || [['id', 'ASC']]}
        fetchTypeaheads={fetchTypeaheads}
        typeaheadData={typeaheadData}
        updateAppliedFilters={updateAppliedFilters}
      />
    </>
  );
};

ordersSidebar.propTypes = {
  onChange: PropTypes.func.isRequired,
  order: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
  updateAppliedFilters: PropTypes.func.isRequired,
};

export default ordersSidebar;
