import { emdash } from 'components/common';

export const AddressHelper = ({
  addressObject,
  locationType='',
  className='text-right',
}) =>  {

  return (
    <div className={className}>
      {
        locationType
          ? <div id="tag-locationType"><label>Location type:</label> {locationType}</div>
          : <></>
      }
      {
        addressObject?.locationName
          ? <div id="tag-locationName">{addressObject?.locationName}</div>
          : <></>
      }
      {
        addressObject?.address1 ? (
          <>
            <div id="tag-address1">{addressObject?.address1}</div>
            <div id="tag-address2">{addressObject?.address2}</div>
            <div id="tag-address3">{addressObject?.address3}</div>
            <div id="tag-city-state-zip">
              {addressObject?.city}, {
                addressObject?.stateCode ? `${addressObject?.stateCode}` : ''
              }{
                addressObject?.postalCode
                  ? `${addressObject?.postalCode.substring(0, 5)}-${addressObject?.postalCode.substring(5)}`
                  : ''
              }{
                addressObject?.countryCode ? `, ${addressObject?.countryCode}` : ''
              }
            </div>
          </>
        ) : emdash
      }
    </div>
  );
};
