import React from 'react';
import _ from 'lodash';
import { Alert } from '@gsa/afp-component-library';
import { shippedWithoutUnicor } from '../../../utilities/common';
import { useLicensePlateDetails } from '../license-plate-details-provider';

const LpNoOrderAlert = () => {
  const { tagDetails } = useLicensePlateDetails();

  if (
    !shippedWithoutUnicor(
      _.get(tagDetails, 'tagActivityTypeCode'),
      _.get(tagDetails, 'unicorTag.orderNumber'),
    )
  ) {
    return null;
  }

  return (
    <Alert type="warning" slim data-testid="no-order-alert">
      You physically received a license plate order that is missing a sales
      order number in the system. The sales order file contains additional
      information necessary for processing federal license plates and is
      required before your agency can attach license plates to vehicles.
    </Alert>
  );
};

export default LpNoOrderAlert;
