import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  DetailsTable,
  Spinner,
  AddressConfirmationModal,
} from '@gsa/afp-component-library';
import { useMutation, useLazyQuery } from '@apollo/client';
import { fieldGetter } from '../vehicle-details/helpers/field-getter';
import PocEdit from '../vehicle-details/sidenav-widgets/contact-information/poc-edit';
import AgencyEdit from './widgets/agency-edit';
import {
  formatAddressForValidation,
  formatAddressForSubmission,
  isEmailDifferent,
  checkOtherPoCEmail,
} from './helpers/utils';
import { constructPayload } from './helpers/payload-constructors';
import {
  UPDATE_VEHICLE_AGENCY_DETAILS,
  UPDATE_VEHICLE_POC,
  VALIDATE_ADDRESS,
} from '../../services/data-layer';
import PointOfContact from './widgets/point-of-contact';
import { useRegistrationDetail } from './registration-detail-context-provider';
import AddressNotFoundModal from './widgets/address-not-found-modal';
import PocEmailChanger from '../vehicle-details/sidenav-widgets/contact-information/poc-email-change';
import useCanPerformActions from '../../hooks/use-can-perform-actions';
import { validEmail } from '../../utilities/validation';
import useUser from '../../utilities/use-user';

export default function AgencyPocInfo({ vehicle, setRegistrationUpdated }) {
  const { isRole } = useUser();
  const hasFMVRSrole = isRole('FMVRSAdminRole');
  const [editingAgency, setEditingAgency] = useState(false);
  // used to show/hide different modals
  const [editingEmail, setEditingEmail] = useState(false);
  const [isAddressNotFound, setIsAddressNotFound] = useState(false); // toggles the "Address not found" modal
  const [isEditingPoC, setIsEditingPoC] = useState(false); // toggles the editing modal
  const [isNewEmailSelected, setIsNewEmailSelected] = useState(false);
  const [validatedAddress, setValidatedAddress] = useState(null); // holds the address recommendation from USPS when the address from user input is validated
  const [editPoc, setEditPoc] = useState(null); // holds the POC object currently being edited
  const [refetchingData, setRefetchingData] = useState(false);

  const canPerformActions = useCanPerformActions();
  const canEditPoc = canPerformActions.canEditPoc(vehicle, hasFMVRSrole);

  const { refetchVehicle, handlePocUpdate, setHasNetworkError } =
    useRegistrationDetail();

  const constructAddress = (addr) => {
    if (addr?.hasOwnProperty('address')) {
      const {
        address: primaryAddress,
        address2: secondaryAddress,
        city,
        countryCode,
        state: stateCode,
        zip: postalCode,
      } = addr;
      return {
        primaryAddress,
        secondaryAddress,
        city,
        stateCode,
        countryCode,
        postalCode,
      };
    }

    return addr;
  };

  const updatePoCAndReg = (addr) => {
    const pocWithValidAddr = {
      ...editPoc.poc,
      ...constructAddress(addr),
      ind: editPoc.ind,
    };
    updateVehPoC(pocWithValidAddr);
  };

  const checkAddress = (poc) => {
    const { ind, ...rest } = poc;
    const payload = formatAddressForValidation(rest);
    validateAddress({ variables: payload });
  };

  const [validateAddress] = useLazyQuery(VALIDATE_ADDRESS, {
    fetchPolicy: 'no-cache',
    onError: (error) => {
      setValidatedAddress(null);
      if (error?.message && error?.message.includes('UserInputError')) {
        setIsAddressNotFound(true);
        setValidatedAddress(null);
      }
    },
    onCompleted: (data) => {
      if (data?.validateAddress) {
        const { address, address2, city, state, zip } = data.validateAddress;
        setValidatedAddress({
          ...editPoc,
          primaryAddress: address,
          secondaryAddress: address2,
          city,
          stateCode: state,
          postalCode: zip,
        });
      } else {
        setEditPoc(null);
      }
      setIsAddressNotFound(false);
    },
  });

  const [
    updateVehiclePoC,
    { data: updatedVehicleWithPoc, loading: updatingVehiclePoc },
  ] = useMutation(UPDATE_VEHICLE_POC, {
    fetchPolicy: 'no-cache',
  });

  const [
    updateVehicleAgencyDetails,
    { data: updatedVehicleAgencyDetails, loading: updatingVehicleAgency },
  ] = useMutation(UPDATE_VEHICLE_AGENCY_DETAILS, {
    fetchPolicy: 'no-cache',
  });

  const updatePoC = (poc) => {
    const { ind, ...rest } = poc;
    return updateVehiclePoC({
      variables: {
        vin: vehicle.id,
        pocNumber: ind,
        poc: _.omit(rest, ['__typename', 'fullName', 'validated']),
      },
    });
  };

  const updateVehPoCWithoutAddressCheck = (poc) => {
    updatePoC(poc).then(() => {
      // if updating the POC succeeds and the address will not be checked, the POC is no longer being edited
      setEditPoc(null);
    });
  };
  const updateVehPoCAndCheckAddress = (poc) => {
    updatePoC(poc).then(() => {
      return checkAddress(poc);
    });
  };

  useEffect(() => {
    if (updatedVehicleWithPoc?.updateVehiclePoC?.id) {
      setRefetchingData(true);
      refetchVehicle(updatedVehicleWithPoc?.updateVehiclePoC?.id).finally(
        () => {
          setRefetchingData(false);
        },
      );
    }
  }, [updatedVehicleWithPoc]);

  useEffect(() => {
    if (updatedVehicleAgencyDetails) {
      refetchVehicle(
        updatedVehicleAgencyDetails?.updateVehicleAgencyDetails?.id,
      );
      setRegistrationUpdated(true);
    }
  }, [updatedVehicleAgencyDetails]);

  const handleAgencySave = (agencyCode, bureauCode, subSectionCode) => {
    // dismisses previous error if the registration update failed
    setHasNetworkError(false);
    setEditingAgency();

    updateVehicleAgencyDetails({
      variables: {
        vin: vehicle.id,
        agencyDetails: {
          agencyCode,
          bureauCode,
          subSectionCode,
        },
      },
    });
  };

  const agencyBureauGetter = (ownershipCode, entityToReturn) => {
    const code = `${entityToReturn}Code`;
    let field = fieldGetter([
      {
        field: `${_.get(vehicle, code)} - ${
          _.get(vehicle, entityToReturn + '.name')
            ? _.get(vehicle, entityToReturn + '.name')
            : ' -'
        }`,
      },
    ]);
    if (ownershipCode === 'GF') {
      field = fieldGetter([
        { field: vehicle?.customer?.[entityToReturn]?.name },
      ]);
    }
    return field;
  };
  // If a user clicks "Edit" on a POC that is the same as the other POC, has an empty e-mail, or has an invalid e-mail, then the edit e-mail modal should open instead of the edit POC one
  const shouldOpenEmailEdit = (primEmail, secEmail, editPocEmail) => {
    return !isEmailDifferent(primEmail, secEmail) || !validEmail(editPocEmail);
  };

  return (
    <>
      <div className="margin-top-4">
        <div className="grid-row grid-gap">
          <div className="grid-col-8">
            <h3 className="title-m-bold">
              Agency and point of contact information
            </h3>
          </div>
        </div>
        <div className="grid-row grid-gap">
          <div className="grid-col-6">
            <h4 className="title-s-caps text-primary margin-bottom-1">
              POINT OF CONTACT #1
            </h4>
            <PointOfContact
              poc={{ ...vehicle?.primaryPoC, ind: 1 }}
              canEdit={canEditPoc}
              handlePoCEditOpen={() => {
                // set this POC as the one being edited
                setEditPoc({ ...vehicle?.primaryPoC, ind: 1 });
                // if POC 1 is the same as 2 or if the POC is empty, open the email editor instead of the edit modal
                if (
                  shouldOpenEmailEdit(
                    vehicle?.primaryPoC?.email,
                    vehicle?.secondaryPoC?.email,
                    vehicle?.primaryPoC?.email,
                  )
                ) {
                  setEditingEmail(true);
                } else {
                  // Open the edit modal
                  setIsEditingPoC(true);
                }
              }}
            />
          </div>
          <div className="grid-col-6">
            <h4 className="title-s-caps text-primary margin-bottom-1">
              POINT OF CONTACT #2
            </h4>
            <PointOfContact
              poc={{ ...vehicle?.secondaryPoC, ind: 2 }}
              canEdit={canEditPoc}
              handlePoCEditOpen={() => {
                // set this POC as the one being edited
                setEditPoc({ ...vehicle?.secondaryPoC, ind: 2 });
                // if POC 1 is the same as 2 or if the POC is empty, open the email editor instead of the edit modal
                if (
                  shouldOpenEmailEdit(
                    vehicle?.primaryPoC?.email,
                    vehicle?.secondaryPoC?.email,
                    vehicle?.secondaryPoC?.email,
                  )
                ) {
                  setEditingEmail(true);
                } else {
                  setIsEditingPoC(true);
                }
              }}
            />
          </div>
        </div>
        <PocEdit
          poc={editPoc}
          isEditingPoC={isEditingPoC}
          handleEditClose={() => {
            // close the edit modal and clear the POC being edited
            setIsEditingPoC(false);
            setEditPoc(null);
            setIsAddressNotFound(false);
            setIsNewEmailSelected(false);
          }}
          isNewEmailSelected={isNewEmailSelected}
          handleEditSave={(payload, hasAddressChanged) => {
            const updated = { ...editPoc, ...payload };
            setIsEditingPoC(false);
            setIsNewEmailSelected(false);
            // update the POC being edited in local state
            setEditPoc(updated);
            // Only validates with USPS when it's a USPS address and the address fields have changed
            if (updated?.countryCode === 'US' && hasAddressChanged) {
              updateVehPoCAndCheckAddress(updated);
            } else {
              updateVehPoCWithoutAddressCheck(updated);
            }
            // legacy data can have two invalid e-mail addresses.  An alert displays if a user updates registration without correcting that.  This will leave the alert or dismiss it
            const shouldShowAlert = checkOtherPoCEmail(
              vehicle?.primaryPoC?.email,
              vehicle?.secondaryPoC?.email,
              updated,
            );
            handlePocUpdate(shouldShowAlert);
          }}
          handleOpenChangeEmail={() => {
            setEditingEmail(true);
            setIsEditingPoC(false);
          }}
          handleSaveEmailChange={updatePoCAndReg}
        />
        <AddressNotFoundModal
          address={editPoc}
          showError={isAddressNotFound}
          handleAccept={() => {
            // clear address not found and the POC being edited when a user accepts the address recommendation from USPS
            setIsAddressNotFound(false);
            setEditPoc(null);
          }}
          handleClose={() => {
            // closes the "Not found" modal and opens the editing one when a user chooses "Edit address"
            setIsAddressNotFound(false);
            setIsEditingPoC(true);
          }}
        />
        <AddressConfirmationModal
          addressToValidate={formatAddressForValidation(editPoc)}
          validatedAddress={formatAddressForValidation(validatedAddress)}
          saveButtonText={'Save and close'}
          onSave={(d) => {
            setValidatedAddress(null);
            const payload = formatAddressForSubmission(d);
            // Don't need to validate with USPS from confirmation modal so just save selection
            updatePoC({ ...editPoc, ...payload });
          }}
          onCancel={() => {
            // closes the confirmation modal and opens the editing one when a user chooses "Edit address"
            setValidatedAddress(null);
            setIsEditingPoC(true);
          }}
        />
        <PocEmailChanger
          handleCancel={() => {
            setEditingEmail(false);
            // Only re-direct to the editing modal if the POC is valid, not blank, and is not the same as the other one
            if (
              isEmailDifferent(
                vehicle?.secondaryPoC?.email,
                vehicle?.primaryPoC?.email,
              ) &&
              validEmail(editPoc?.email)
            ) {
              setIsEditingPoC(true);
            }
          }}
          handleSelect={(params) => {
            // set the changes as the POC being edited, close the email editor and open the POC edit modal
            setEditPoc(params);
            setIsNewEmailSelected(true);
            setEditingEmail(false);
            setIsEditingPoC(true);
          }}
          poc={editPoc}
          otherPocEmail={
            editPoc?.ind === 1
              ? vehicle?.secondaryPoC?.email
              : vehicle?.primaryPoC?.email
          }
          editingEmail={editingEmail}
          matchingEmail={
            !isEmailDifferent(
              vehicle?.secondaryPoC?.email,
              vehicle?.primaryPoC?.email,
            )
          }
          invalidPoc={!validEmail(editPoc?.email)}
        />
        <div className="grid-row grid-gap margin-bottom-8">
          <div className="grid-col-6">
            <h4 className="title-s-caps text-primary margin-bottom-1 margin-top-4">
              AGENCY DETAILS
            </h4>
            <div
              className="bg-gray-3 radius-md padding-y-2 padding-x-4"
              data-testid="afp-registration__agency_info"
            >
              <DetailsTable
                className="afp-registration__section_container"
                data={[
                  [
                    'Agency',
                    agencyBureauGetter(vehicle?.ownershipTypeCode, 'agency'),
                  ],
                  [
                    'Bureau',
                    agencyBureauGetter(vehicle?.ownershipTypeCode, 'bureau'),
                  ],
                  [
                    'Office',
                    fieldGetter([
                      {
                        field: (() => {
                          if (vehicle?.office && vehicle?.office?.officeName) {
                            return `${_.get(
                              vehicle,
                              'office.officeCode',
                            )} - ${_.get(vehicle, 'office.officeName')}`;
                          }
                          return '—';
                        })(),
                      },
                    ]),
                  ],
                ]}
              />
              {canEditPoc && (
                <Button
                  data-testid="agency-edit"
                  onClick={() => {
                    setEditingAgency(true);
                  }}
                  variant="outline"
                  className="bg-white margin-top-2"
                  aria-label="edit agency"
                  label="Edit"
                />
              )}
              <AgencyEdit
                agencyCode={vehicle.agencyCode}
                bureauCode={vehicle.bureauCode}
                subSectionCode={vehicle.subSectionCode}
                editing={editingAgency}
                onClose={() => {
                  setEditingAgency(false);
                }}
                onSave={handleAgencySave}
              />
            </div>
          </div>
        </div>
      </div>
      {(updatingVehicleAgency || updatingVehiclePoc || refetchingData) && (
        <Spinner aria-busy="true" className="loading_backdrop" size="large" />
      )}
    </>
  );
}

AgencyPocInfo.propTypes = {
  vehicle: PropTypes.object,
};

AgencyPocInfo.defaultProps = {
  vehicle: {},
};
