import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { GET_TAGS_BY_EXPIRATION_DATE } from 'services/data-layer';
import { canSubmitDestruction } from '../../../../utilities/common';
import { INIT_DESTRUCTION_DATA } from '../../helpers/use-license-plate-manager';

export default function LicensePlateDestructionManager() {
  const history = useHistory();
  const [destructionEditing, setDestructionEditing] = useState(false);
  const [forDestructionList, setForDestructionList] = useState([]);
  const [ineligibleDestructionList, setIneligibleDestructionList] = useState(
    {},
  );
  const [destructionInitData, setDestructionInitData] = useState(
    INIT_DESTRUCTION_DATA,
  );

  const [
    getTagsByExpirationDate,
    { loading: onGetTagsByExpirationDateLoading },
  ] = useLazyQuery(GET_TAGS_BY_EXPIRATION_DATE, {
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      const filteredTags = data?.getTagsByExpirationDate?.filter((tag) => {
        return canSubmitDestruction(tag.tagActivityTypeCode);
      });
      setForDestructionList(filteredTags);
    },
  });

  const toggleDestructionEditing = () => {
    setDestructionEditing((edit) => {
      return !edit;
    });
  };

  const setEligibleForDestruction = (licensePlates, selectedRows) => {
    const list = Object.keys(selectedRows)
      .filter((rowKey) => {
        return (
          licensePlates?.rows[rowKey] &&
          canSubmitDestruction(licensePlates.rows[rowKey].tagActivityTypeCode)
        );
      })
      .filter((rowKey) => {
        return licensePlates.rows[rowKey]?.id[0] !== 'G';
      })
      .map((rowKey) => {
        return licensePlates.rows[rowKey];
      });
    const input = [];
    list.map((tag) => {
      input.push({
        id: tag.id,
        expirationDate: tag.expirationDate,
      });
    });
    getTagsByExpirationDate({
      variables: {
        input,
      },
    });
  };

  const getIneligiblePlateDistribution = (licensePlates, selectedRows) => {
    function getStatusCount(status) {
      return Object.keys(selectedRows).filter(
        (rowKey) =>
          licensePlates?.rows[rowKey] &&
          licensePlates.rows[rowKey].tagActivityTypeCode === status,
      ).length;
    }

    function getGTagCount() {
      return Object.keys(selectedRows).filter(
        (rowKey) =>
          licensePlates?.rows[rowKey] &&
          licensePlates.rows[rowKey].id[0] === 'G',
      )?.length;
    }
    setIneligibleDestructionList({
      shipped: getStatusCount('SHIP'),
      pendingDestruction: getStatusCount('RETN'),
      destroyed: getStatusCount('DESV'),
      GTag: getGTagCount(),
    });
  };

  const saveDestruction = (forwardTo) => {
    toggleDestructionEditing();
    // this line is needed when going from modal to link to bring scroll back
    document.documentElement.style.overflow = 'auto';
    forwardTo && history.push(forwardTo);
  };

  useEffect(() => {
    if (destructionInitData.trackingNumber) {
      setDestructionInitData({
        ...destructionInitData,
        trackingNumbers: [destructionInitData.trackingNumber],
      });
    }
  }, [destructionInitData.trackingNumber]);

  return {
    forDestructionList,
    setForDestructionList,
    destructionEditing,
    toggleDestructionEditing,
    ineligibleDestructionList,
    setEligibleForDestruction,
    getIneligiblePlateDistribution,
    destructionInitData,
    setDestructionInitData,
    saveDestruction,
    onGetTagsByExpirationDateLoading,
  };
}
