/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Button, DetailsTable, Spinner } from '@gsa/afp-component-library';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { omit } from 'lodash';
import useCanPerformActions from 'hooks/use-can-perform-actions';
import useUser from 'utilities/use-user';
import {
  GET_AL_CATEGORY,
  GET_ASSET_DISPOSAL,
  UPDATE_OR_CREATE_ASSET_DISPOSAL,
} from 'services/data-layer';
import { isFeatureEnabled } from 'utilities/feature-toggle';
import {
  canUpdateGFVehicleAdmin,
  canUpdateGFVehicleFSR,
} from 'utilities/authorization';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import DisposalEdit from './disposal-edit';
import { useVehicle } from '../../../vehicle-context-provider';

const DisposalInfo = ({ data }) => {
  const [editingDisposal, setDisposalEditing] = useState(false);
  const { setTabMsg, setSectionMsg } = useVehicle();
  const [valuationData, setValuationData] = useState({});
  const [options, setOptions] = useState([]);
  const ability = useAppAbility();

  const canPerformActions = useCanPerformActions();
  const { isRole } = useUser();
  const hasFSRrole = isRole('FSR');
  const hasCFMrole = isRole('CustFltMan');
  const hasFMVRSrole = isRole('FMVRSAdminRole');
  const hasSiteAdmin = isRole('SiteAdmin');
  const hasCustFltManRole = isRole('CustFltMan');

  const canEditValuationAO = canPerformActions.canEditEquipment(
    data,
    hasFSRrole,
    hasCFMrole,
    hasFMVRSrole,
    hasSiteAdmin,
    hasCustFltManRole,
  );

  const canUpdateGF =
    isFeatureEnabled('edit-gf-vehicle') &&
    (canUpdateGFVehicleAdmin(ability) || canUpdateGFVehicleFSR(ability));

  const canEdit =
    data.ownershipTypeCode === 'GF' ? canUpdateGF : canEditValuationAO;

  const getOptions = (disposalOptions) => {
    return disposalOptions?.map((option) => {
      const { code, description } = option;
      return {
        value: code,
        label: description,
      };
    });
  };

  const [
    updateOrCreateValuation,
    { data: vehicleValuationData, loading: vehicleValuationDataLoading },
  ] = useMutation(UPDATE_OR_CREATE_ASSET_DISPOSAL, {
    fetchPolicy: 'no-cache',
    onError: (error) => {
      setTabMsg({
        type: 'error',
        message: error?.message,
      });
    },
  });

  const { data: disposalReasons } = useQuery(GET_AL_CATEGORY, {
    variables: { lookupCategories: ['DISPOSAL_REASON'] },
    fetchPolicy: 'no-cache',
  });

  const [
    getAssetDisposal,
    { data: getAssetDisposalResponse, loading: getAssetDisposalLoading },
  ] = useLazyQuery(GET_ASSET_DISPOSAL, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (disposalReasons?.getALByCategories[0]?.options)
      setOptions([
        { value: '', label: '- Select -' },
        ...getOptions(disposalReasons?.getALByCategories[0]?.options),
      ]);
  }, [disposalReasons]);

  useEffect(() => {
    // eslint-disable-next-line react/prop-types
    if (data.uuid) {
      // eslint-disable-next-line react/prop-types
      getAssetDisposal({ variables: { assetId: data.uuid } });
    }
  }, [data]);

  useEffect(() => {
    if (vehicleValuationData?.updateOrCreateDisposal) {
      setValuationData(vehicleValuationData.updateOrCreateDisposal);
      setTabMsg({
        type: 'success',
        message: 'Valuation was successfully saved',
      });
    }
  }, [vehicleValuationData]);

  useEffect(() => {
    if (getAssetDisposalResponse)
      setValuationData(getAssetDisposalResponse.getDisposalByAssetId);
  }, [getAssetDisposalResponse]);

  const emdash = '\u2014';

  const handleCloseDisposalEdit = () => {
    setDisposalEditing(false);
  };

  const handleSaveDisposal = (editedDisposalData) => {
    const {
      actualDisposalDate,
      disposalCost,
      disposalProceeds,
      actualDisposalReason,
      turnInCode,
      eligibleReplacementDate,
      turnInLocation,
      disposalCondition,
    } = editedDisposalData;

    const updatedDisposalData = {
      ...omit(valuationData, ['id', '__typename']),
      // this has to explicitly be set to null to avoid Invalid Date error (0000-00-00 00:00:00)
      actualDisposalDate: actualDisposalDate || null,
      disposalCost: Number(disposalCost),
      disposalProceeds: Number(disposalProceeds),
      turnInCode,
      actualDisposalReason,
      eligibleReplacementDate,
      turnInLocation,
      disposalCondition,
    };

    updateOrCreateValuation({
      variables: {
        assetDisposalArg: updatedDisposalData,
      },
    });
  };

  const aoData = [
    [
      'Projected replacement eligibility',
      valuationData?.eligibleReplacementDate
        ? moment.utc(valuationData.eligibleReplacementDate).format('MM/DD/YYYY')
        : emdash,
    ],
    [
      'Sale turn in reason',
      valuationData?.turnInCode ? valuationData?.turnInCode : emdash,
    ],
    [
      'Disposal date',
      valuationData?.actualDisposalDate
        ? moment.utc(valuationData.actualDisposalDate).format('MM/DD/YYYY')
        : emdash,
    ],
    ['Disposal reason', valuationData?.actualDisposalReason ?? emdash],
    [
      'Disposal cost',
      valuationData?.disposalCost
        ? `$${valuationData?.disposalCost
            ?.toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
        : emdash,
    ],
    [
      'Disposal proceeds',
      valuationData?.disposalProceeds
        ? `$${valuationData?.disposalProceeds
            ?.toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
        : emdash,
    ],
  ];

  const gfData = [
    [
      'Projected replacement eligibility',
      valuationData?.eligibleReplacementDate
        ? moment.utc(valuationData.eligibleReplacementDate).format('MM/DD/YYYY')
        : emdash,
    ],
    ['Sale turn in reason', valuationData?.turnInCode || emdash],
    ['Sale turn in location', valuationData?.turnInLocation || emdash],
    ['Current condition', valuationData?.disposalCondition || emdash],
    [
      'Disposal date',
      valuationData?.actualDisposalDate
        ? moment.utc(valuationData.actualDisposalDate).format('MM/DD/YYYY')
        : emdash,
    ],
    ['Disposal reason', valuationData?.actualDisposalReason ?? emdash],
    [
      'Disposal cost',
      valuationData?.disposalCost
        ? `$${valuationData?.disposalCost
            ?.toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
        : emdash,
    ],
    [
      'Disposal proceeds',
      valuationData?.disposalProceeds
        ? `$${valuationData?.disposalProceeds
            ?.toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
        : emdash,
    ],
    [
      'Fair market value',
      valuationData?.fairMarketValue
        ? `$${valuationData?.fairMarketValue
            ?.toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
        : emdash,
    ],
  ];

  const tableData = data.ownershipTypeCode === 'GF' ? gfData : aoData;

  return (
    <>
      <h4 className="title-s-caps text-primary margin-bottom-1">
        Disposal information
      </h4>
      <div
        className="bg-gray-3 radius-md padding-y-2 padding-x-4"
        data-testid={`afp-disposal${valuationData?.id}`}
      >
        <DetailsTable
          className="afp-registration__section_container text-tabular"
          data={tableData}
        />
        {canEdit && (
          <Button
            data-testid={`'disposal-edit-button'`}
            onClick={() => setDisposalEditing(true)}
            variant="outline"
            className="bg-white margin-top-2"
            aria-label={`edit point of contact ${valuationData?.id}`}
            label="Edit"
          />
        )}

        {editingDisposal && (
          <DisposalEdit
            vehicle={data}
            disposalReasons={disposalReasons}
            valuationData={valuationData}
            editingDisposal={editingDisposal}
            onClose={handleCloseDisposalEdit}
            onSave={handleSaveDisposal}
            setSectionMsg={setSectionMsg}
            options={options}
          />
        )}

        {(getAssetDisposalLoading || vehicleValuationDataLoading) && (
          <Spinner aria-busy="true" className="loading_backdrop" size="large" />
        )}
      </div>
    </>
  );
};

export default DisposalInfo;
