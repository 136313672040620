import { gql } from '@apollo/client';

export const GET_MILEAGE_BY_VEHICLE = gql`
  query getMileageByVehicle(
    $assetId: String!
    $order: OrderBy
    $offset: Float
    $limit: Float
    $filters: [Filter!]
  ) {
    getMileageByVehicle(
      assetId: $assetId
      order: $order
      offset: $offset
      limit: $limit
      filters: $filters
    ) {
      rows {
        id
        mileageDate
        daysInUse
        odometer
        sourceType
        enteredByUserInfo {
          fullName
          email
        }
        mileageDriven
        createdAt
      }
      count
      hasMore
    }
  }
`;

export const GET_MILEAGE_BY_GF_VEHICLE = gql`
  query GetMileageByGFVehicle(
    $assetId: String!
    $order: OrderBy
    $offset: Float
    $limit: Float
    $filters: [Filter!]
  ) {
    getMileageByGFVehicle(
      assetId: $assetId
      order: $order
      offset: $offset
      limit: $limit
      filters: $filters
    ) {
      rows {
        assetMileageId
        assetMileageLogId
        mileageDate
        daysInUse
        odometer
        status
        mileageDriven
        sourceType
        assetMileageSourceTypeId
        customer {
          legacyCustomerNumber
          accountName
        }
        enteredByUserInfo {
          fullName
          email
        }
        createdAt
        updatedAt
      }
      count
      hasMore
    }
  }
`;

export const ADD_MILEAGE_FOR_VEHICLE = gql`
  mutation addMileageForVehicle($mileageInput: MileageInput!) {
    addMileageForVehicle(mileageInput: $mileageInput) {
      assetId
      mileageDate
      daysInUse
      odometer
      sourceType
      createdBy
      createdAt
      updatedBy
      updatedAt
      deletedAt
    }
  }
`;

export const EDIT_MILEAGE_FOR_VEHICLE = gql`
  mutation editMileageForVehicle($mileageInput: UpdateMileageInput!) {
    editMileageForVehicle(mileageInput: $mileageInput) {
      assetId
      mileageDate
      daysInUse
      odometer
      sourceType
      createdBy
      createdAt
      updatedBy
      updatedAt
      deletedAt
    }
  }
`;

export const DELETE_MILEAGE_FOR_VEHICLE = gql`
  mutation deleteMileageForVehicle($mileageInput: UpdateMileageInput!) {
    deleteMileageForVehicle(mileageInput: $mileageInput)
  }
`;

export const GET_NEXT_LAST_ODOMETER_BY_DATE_VEHICLE = gql`
  query getLastAndNextOdometerByVehicleAndDate($mileageInput: MileageInput!) {
    getLastAndNextOdometerByVehicleAndDate(mileageInput: $mileageInput)
  }
`;

export const IS_TELEMATICS_ACTIVE = gql`
  query IsTelematicsActive($vin: String!) {
    isTelematicsActive(vin: $vin)
  }
`;

export const ADD_MILEAGE_LOG_FOR_GF_VEHICLES = gql`
  mutation AddMileageLogForGFVehicles($mileageGFFields: [MileageGFInput!]!) {
    addMileageLogForGFVehicles(mileageGFFields: $mileageGFFields)
  }
`;

export const EDIT_MILEAGE_LOG_FOR_GF_VEHICLE = gql`
  mutation EditMileageForGFVehicle(
    $updateMileageGFFields: UpdateMileageHistoryGFInput!
  ) {
    editMileageForGFVehicle(updateMileageGFFields: $updateMileageGFFields)
  }
`;

export const DELETE_MILEAGE_LOG = gql`
  mutation DeleteMileageLog($id: Float!, $assetId: String!) {
    deleteMileageLog(id: $id, assetId: $assetId)
  }
`;
