import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import { SelectDropdown, Button, useModal } from '@gsa/afp-component-library';
import { CDD_FORM_FIELDS, YES_NO_OPTIONS } from './consts';
import { generationOptions } from '../../helpers/utils';
import CDDPopOverModal from './cdd-pop-over-modal';

const VehicleDesignationsForm = ({ isAOVehicle }) => {
  const {
    formState: { errors },
    control,
    aldCategories,
  } = useFormContext();

  const { isOpen, openModal, closeModal } = useModal();

  return (
    <div data-testid="cdd-vehicle-designations-form">
      <Controller
        name={CDD_FORM_FIELDS.lawEnforcement.name}
        control={control}
        render={({ field, ref }) => {
          return (
            <SelectDropdown
              {...field}
              ref={ref}
              label={
                <>
                  <span className="text-bold">
                    Law enforcement <br />
                    designation
                  </span>
                  <Button
                    position="right"
                    variant="unstyled"
                    className="margin-left-1"
                    rightIcon={{ name: 'info', className: 'text-black' }}
                    onClick={() => {
                      openModal();
                    }}
                    data-testid="law-enforcement-info-button"
                    aria-label="info"
                  />
                </>
              }
              name={CDD_FORM_FIELDS.lawEnforcement.name}
              id={CDD_FORM_FIELDS.lawEnforcement.id}
              options={generationOptions(
                'law_enforcement_designation',
                aldCategories,
              )}
              errorMessage={
                errors && errors[CDD_FORM_FIELDS.lawEnforcement.name]
                  ? errors[CDD_FORM_FIELDS.lawEnforcement.name].message
                  : null
              }
              aria-invalid={
                errors && errors[CDD_FORM_FIELDS.lawEnforcement.name]
                  ? 'true'
                  : 'false'
              }
            />
          );
        }}
      />
      <Controller
        name={CDD_FORM_FIELDS.isEmergencyResponse.name}
        control={control}
        render={({ field, ref }) => {
          return (
            <SelectDropdown
              {...field}
              ref={ref}
              label={
                <span className="text-bold">
                  Emergency response designation
                </span>
              }
              name={CDD_FORM_FIELDS.isEmergencyResponse.name}
              id={CDD_FORM_FIELDS.isEmergencyResponse.id}
              options={YES_NO_OPTIONS}
              errorMessage={
                errors && errors[CDD_FORM_FIELDS.isEmergencyResponse.name]
                  ? errors[CDD_FORM_FIELDS.isEmergencyResponse.name].message
                  : null
              }
              aria-invalid={
                errors && errors[CDD_FORM_FIELDS.isEmergencyResponse.name]
                  ? 'true'
                  : 'false'
              }
            />
          );
        }}
      />
      {isAOVehicle ? (
        <Controller
          name={CDD_FORM_FIELDS.armoredDesignation.name}
          control={control}
          render={({ field, ref }) => {
            return (
              <SelectDropdown
                {...field}
                ref={ref}
                label={<span className="text-bold">Armored designation</span>}
                name={CDD_FORM_FIELDS.armoredDesignation.name}
                id={CDD_FORM_FIELDS.armoredDesignation.id}
                options={YES_NO_OPTIONS}
                errorMessage={
                  errors && errors[CDD_FORM_FIELDS.armoredDesignation.name]
                    ? errors[CDD_FORM_FIELDS.armoredDesignation.name].message
                    : null
                }
                aria-invalid={
                  errors && errors[CDD_FORM_FIELDS.armoredDesignation.name]
                    ? 'true'
                    : 'false'
                }
              />
            );
          }}
        />
      ) : null}
      {isOpen && <CDDPopOverModal isOpen={isOpen} closeModal={closeModal} title='Law enforcement designation' />}
    </div>
  );
};

VehicleDesignationsForm.propTypes = {
  isAOVehicle: PropTypes.bool.isRequired,
};

export default VehicleDesignationsForm;
