/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { Button, Modal, Spinner } from '@gsa/afp-component-library';
import { usePmExpress } from './pm-express-provider';
import AttachPmInvoiceForm from './attach-pm-invoice-form';
import { useUploadInvoiceContext } from './upload-invoice-context';

const AttachInvoiceModal = () => {
  const { savingPmInvoice, handleDocChange } = useUploadInvoiceContext();
  const { isPmInvoiceModalOpen, closePmInvoiceModal, dispatchAction } =
    usePmExpress();

  const onCloseModal = () => {
    dispatchAction('SET_SELECTED_PM_FOR_UPLOAD', undefined);
    closePmInvoiceModal();
  };

  useEffect(() => {
    if (!isPmInvoiceModalOpen) {
      closePmInvoiceModal();
    }
  }, [isPmInvoiceModalOpen]);

  if (!isPmInvoiceModalOpen) {
    return null;
  }

  return (
    <div className="afp-modal-wrapper">
      <div className="afp-modal-overlay">
        <Modal
          title={<h2> Attach invoice</h2>}
          actions={
            <>
              <Button
                variant="unstyled"
                className="margin-right-2"
                onClick={onCloseModal}
                data-testid="attach-invoice-cancel-button"
                label="Cancel"
              />
              <Button
                variant="primary"
                onClick={() => handleDocChange()}
                form="attach-invoice-form"
                data-testid="attach-invoice-submit-button"
                label={
                  savingPmInvoice ? <Spinner /> : <span>Save and close</span>
                }
              />
            </>
          }
          onClose={onCloseModal}
          variant="large"
        >
          <AttachPmInvoiceForm />
        </Modal>
      </div>
    </div>
  );
};

export default AttachInvoiceModal;
