import React from 'react';
import { PropTypes } from 'prop-types';

export const HiddenField = ({ register, name }) =>
  name && typeof name === 'string' ? (
    <input type="hidden" {...register(name, { validate: {} })} />
  ) : null;
HiddenField.propTypes = {
  register: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

export const HiddenFields = ({ register, names }) => {
  const list = [...new Set(names || [])];
  return list.map((item) => (
    <HiddenField register={register} name={item} key={item} />
  ));
};
HiddenFields.propTypes = {
  register: PropTypes.func.isRequired,
  names: PropTypes.arrayOf(PropTypes.string).isRequired,
};
