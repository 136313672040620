// takes 2 versions of filters
// and clears the bureau, if the agency has changed
export default function resetBureauOnAgencyChange(newFilters, oldFilters) {
  if (!newFilters[0].conditions || !oldFilters[0].conditions) return newFilters;

  const newAgencyCode = newFilters[0].conditions.find(({ key }) =>
    key?.includes('customerAgencyCode'),
  )?.value;
  const oldAgencyCode = oldFilters[0].conditions.find(({ key }) =>
    key?.includes('customerAgencyCode'),
  )?.value;
  if (newAgencyCode !== oldAgencyCode) {
    const newConditions = newFilters[0].conditions.filter(
      ({ key }) => !key?.includes('customerBureauCode'),
    );
    return [{ ...newFilters[0], conditions: newConditions }];
  }
  return newFilters;
}
