import React, { createContext, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { VehiclePropType } from '../../utilities/types';

export const registrationDetailContext = createContext(null);

const RegistrationDetailContextProvider = ({
  children,
  registrationDetailVehicle,
  setRegistrationDetailVehicle,
  refetchVehicle,
  registrationDetailComments,
  setRegistrationDetailComments,
  ...rest
}) => {
  return (
    <registrationDetailContext.Provider
      value={{
        registrationDetailVehicle,
        setRegistrationDetailVehicle,
        refetchVehicle,
        registrationDetailComments,
        setRegistrationDetailComments,
        ...rest,
      }}
    >
      {children}
    </registrationDetailContext.Provider>
  );
};

RegistrationDetailContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  registrationDetailVehicle: PropTypes.shape(VehiclePropType).isRequired,
  setRegistrationDetailVehicle: PropTypes.func.isRequired,
  refetchVehicle: PropTypes.func.isRequired,
};

export default RegistrationDetailContextProvider;

// Hook to exposes context value.
export const useRegistrationDetail = () =>
  useContext(registrationDetailContext);
