import { gql } from '@apollo/client';

export const GET_SCHEDULES = gql`
  query GetReportSchedules(
    $order: OrderBy!
    $limit: Int!
    $offset: Int!
    $filters: [Filter!]
  ) {
    getReportSchedules(
      order: $order
      limit: $limit
      offset: $offset
      filters: $filters
    ) {
      rows {
        id
        reportTypeId
        reportName
        scheduleStartDate
        scheduleEndDate
        scheduleStatus
        frequency
        recipientsList
        agencyCode
        bureauCode
        nextRunDate
        reportCriteria
        reportType {
          name
        }
        agencyInfo {
          name
        }
        bureauInfo {
          name
        }
        user {
          email
        }
        lastRunReport {
          id
          updatedAt
          reportStatusType {
            name
          }
          reportScheduleId
        }
      }
      count
      hasMore
    }
  }
`;

export const GET_REPORT_SCHEDULE_BY_ID = gql`
  query GetReportScheduleById($id: String!) {
    getReportScheduleById(id: $id) {
      id
      reportTypeId
      reportName
      scheduleStartDate
      scheduleEndDate
      scheduleStatus
      frequency
      recipientsList
      agencyCode
      bureauCode
      nextRunDate
      reportCriteria
    }
  }
`;

export const UPDATE_REPORT_SCHEDULES = gql`
  mutation UpdateReportSchedule($updateScheduleInput: UpdateScheduleInput!) {
    updateReportSchedule(updateScheduleInput: $updateScheduleInput) {
      reportTypeId
      reportName
    }
  }
`;

export const SET_SCHEDULE_STATUS = gql`
  mutation setScheduleStatus($id: String!, $scheduleStatus: String!) {
    setScheduleStatus(id: $id, scheduleStatus: $scheduleStatus) {
      reportTypeId
      reportName
      scheduleStatus
    }
  }
`;

export const DELETE_REPORT_SCHEDULES = gql`
  mutation DeleteReportSchedule($id: String!) {
    deleteReportSchedule(id: $id)
  }
`;
