import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { FilterPanel } from '@gsa/afp-component-library';
import { FETCH_TYPEAHEAD } from '../../../services/data-layer';
import filterStructure from './license-plate-filter-structure';
import { useOrder } from '../context';
import usePaginationManager from '../helpers/pagination-manager';

const LicensePlateFilters = () => {
  const { filters, setFilter, details: order, sortOrder, limit } = useOrder();
  const [handlePaginationChange] = usePaginationManager();
  const [fetchTypeaheads, { data }] = useLazyQuery(FETCH_TYPEAHEAD, {
    fetchPolicy: 'network-only',
  });

  const [typeaheadData, setTypeaheadData] = useState();

  useEffect(() => {
    if (data) {
      setTypeaheadData(data.getTypeaheads.typeaheads);
    }
  }, [data]);

  const onChange = (conditions) => {
    handlePaginationChange(1, limit);
    setFilter([{ ...filters[0], conditions }]);
  };

  return (
    <>
      {order && (
        <FilterPanel.FilterPanel
          filterStructure={filterStructure(order.orderNumber)}
          setQueryFiltersState={onChange}
          clearButtonLabel="Reset all"
          showClearIcon
          model="UnicorTag"
          order={sortOrder}
          fetchTypeaheads={fetchTypeaheads}
          typeaheadData={typeaheadData}
        />
      )}
    </>
  );
};

LicensePlateFilters.propTypes = {};

export default LicensePlateFilters;
