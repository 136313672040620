import React, { useMemo, useState } from 'react';
import { SectionBlock } from '../../vehicle-overview/components';
import { Button, DetailsTable } from '@gsa/afp-component-library';
import { PropTypes } from 'prop-types';
import { EditEngine } from './index';
import { emdash } from 'components/common';

const transmissionMap = {
  T2: 'Automatic',
  'T-2': 'Automatic',
  AT: 'Automatic',
  A: 'Automatic',
  T8: 'Manual',
  T5: 'Manual',
  T10: 'Manual',
  T12: 'Manual',
  T13: 'Manual',
  T6: 'Manual',
  T16: 'Manual',
  T7: 'Manual',
  T4: 'Manual',
  T18: 'Manual',
  T9: 'Manual',
  T3: 'Manual',
};

const Engine = ({ data, lookups, canEdit, onSave }) => {
  const [editEngine, setEditEngine] = useState(false);
  const isOwnershipGF = data?.ownershipTypeCode === 'GF';

  const getTransmission = (transimssionType) => {
    if (transimssionType && transmissionMap[transimssionType])
      return transmissionMap[transimssionType];
    return emdash;
  };

  const getPTO = (pto) => {
    if (pto) {
      return pto === 'Y' ? 'Yes' : 'No';
    } else {
      return emdash;
    }
  };

  const tableData = useMemo(() => {
    let rows = [];
    if (isOwnershipGF) {
      rows = [
        ['Fuel type', data?.fuel?.description],
        [
          'Engine size',
          data?.assetTechnical?.assetTechnicalIce?.engineDisplacement,
        ],
        ['Cylinders', data?.assetTechnical?.assetTechnicalIce?.cylinders],
        ['Max horsepower', data?.assetTechnical?.assetTechnicalIce?.horsePower],
        ['PTO', getPTO(data?.assetTechnical?.pto)],
        [
          'Transmission',
          getTransmission(
            data?.assetTechnical?.assetTechnicalIce?.transmission,
          ),
        ],
      ];
    } else {
      rows = [
        ['Cylinders', data?.assetTechnical?.assetTechnicalIce?.cylinders],
        [
          'Transmission',
          getTransmission(
            data?.assetTechnical?.assetTechnicalIce?.transmission,
          ),
        ],
      ];
    }
    return rows;
  }, [data, lookups]);

  const saveEngineChanges = (editState) => {
    const fieldsToUpdate = Object.keys(editState).map((key) => ({
      field: key,
      value: editState[key],
    }));
    onSave(fieldsToUpdate);
    setEditEngine(false);
  };

  return (
    <>
      <SectionBlock title="Engine">
        <DetailsTable
          className="vehicle-overview-details-table"
          data={tableData}
        />
        {canEdit && (
          <Button
            onClick={() => setEditEngine(true)}
            variant="outline"
            className="bg-white margin-top-2"
            aria-label={`edit Engine ${data?.id}`}
            label="Edit"
            data-testid="edit-engine"
          />
        )}
        {editEngine && (
          <EditEngine
            onSave={saveEngineChanges}
            onClose={() => setEditEngine(false)}
            vehicle={data}
            lookups={lookups}
          />
        )}
      </SectionBlock>
    </>
  );
};

export default Engine;

Engine.propTypes = {
  data: PropTypes.shape({
    assetTechnical: PropTypes.shape({
      assetTechnicalIce: PropTypes.shape({
        cylinders: PropTypes.string,
        transmission: PropTypes.string,
        tankSize: PropTypes.number,
        mpgCity: PropTypes.number,
        mpgHighway: PropTypes.number,
        mpgCombined: PropTypes.number,
        flexFuelMpgCity: PropTypes.number,
        flexFuelMpgHighway: PropTypes.number,
        flexFuelMpgCombined: PropTypes.number,
        gramPerMile: PropTypes.number,
        flexFuelGramPerMile: PropTypes.number,
      }),
    }),
  }),
  lookups: PropTypes.shape({
    no_of_cylinders: PropTypes.array,
    transmission: PropTypes.array,
  }),
  onSave: PropTypes.func,
};
