/* eslint-disable react/prop-types */
/* eslint-disable filenames/match-exported */
import React from 'react';

import { EmptyState } from '@gsa/afp-component-library';

const NoDataComponent = ({ title, text, ExtraNoDataContent }) => {
  return (
    <div className="bg-gray-3 padding-y-5" data-testid="no-data-component">
      <EmptyState
        hasBackground
        topText={title}
        topTextStyles="text-bold"
        bottomText={text}
      />
      <div className="text-center">
        {ExtraNoDataContent && <ExtraNoDataContent />}
      </div>
    </div>
  );
};

export default NoDataComponent;
