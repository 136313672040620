/* eslint-disable react/prop-types */
import React from 'react';
import { Button, Modal, Alert, Spinner } from '@gsa/afp-component-library';
import { useVehicle } from 'components/vehicle-details/vehicle-context-provider';
import { usePm } from './pm-provider';

const DeletePmModal = () => {
  const {
    isDeletePmModalOpen,
    closeDeletePmModal,
    dispatch,
    alertMessage,
    deletePm,
    deletePmLoading,
    selectedPm,
  } = usePm();

  const { vehicle } = useVehicle();

  const onCloseModal = () => {
    dispatch({
      type: 'SET_SELECTED_PM',
      payload: undefined,
    });
    dispatch({
      type: 'SET_ALERT_MESSAGE',
      payload: { type: '', message: '', context: 'deletePm' },
    });
    closeDeletePmModal();
  };

  if (!isDeletePmModalOpen) {
    return null;
  }

  const deletePM = () => {
    const payload = {
      pmId: selectedPm?.assetPmId,
      assetId: vehicle?.uuid,
      pmScheduleId: vehicle?.assetTechnical?.pmSchedule?.pmScheduleId,
      fmcId: vehicle?.fmcId,
      lastOdometerMileageDate: vehicle?.newOdometerReading?.mileageDate
        ? new Date(vehicle?.newOdometerReading?.mileageDate).toISOString()
        : vehicle?.assetAcquisition?.originalAcquisitionDate ||
          new Date().toISOString(),
      lastOdometer:
        vehicle?.newOdometerReading?.odometer ||
        vehicle?.assetAcquisition?.deliveredMiles ||
        0,
      acquisitionDeliveredMiles: vehicle?.assetAcquisition?.deliveredMiles,
      originalAcquisitionDate:
        vehicle?.assetAcquisition?.originalAcquisitionDate,
    };
    deletePm({
      variables: {
        deletePmFields: payload,
      },
    });
  };

  return (
    <div className="afp-modal-wrapper">
      <div className="afp-modal-overlay">
        <Modal
          title={<h2>Delete PM</h2>}
          actions={
            <>
              <Button
                variant="unstyled"
                className="margin-right-2"
                onClick={onCloseModal}
                data-testid="motor-pool-listing-add-cancel-button"
                label="Cancel"
              />
              <Button
                variant="secondary"
                type="submit"
                onClick={() => {
                  deletePM();
                }}
                data-testid="delete-pm-submit-button"
                disabled={
                  deletePmLoading || !vehicle?.assetTechnical?.pmSchedule
                }
                label="Delete PM"
              />
              {deletePmLoading && (
                <Spinner
                  size="small"
                  className="display-inline-block margin-left-2"
                />
              )}
            </>
          }
          onClose={onCloseModal}
          variant="large"
        >
          {alertMessage?.context === 'deletePm' && alertMessage?.message && (
            <Alert type={alertMessage.type} slim>
              {alertMessage.message}
            </Alert>
          )}
          {!vehicle?.assetTechnical?.pmSchedule && (
            <Alert type="error" slim>
              Vehicle does not have a PM schedule.
            </Alert>
          )}
          <p>
            Are you sure you want to delete this PM? this action cannot be
            undone.
          </p>
        </Modal>
      </div>
    </div>
  );
};

export default DeletePmModal;
