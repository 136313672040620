import React from 'react';
import _ from 'lodash';
import { DetailsTable } from '@gsa/afp-component-library';
import { useLicensePlateDetails } from '../license-plate-details-provider';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import { canViewAOVehicles, canViewGFVehicles } from 'utilities/authorization';

const LpAgencyInfo = () => {
  const ability = useAppAbility();
  const canViewAOTag = canViewAOVehicles(ability);
  const canViewGFTag = canViewGFVehicles(ability);

  const { tagDetails } = useLicensePlateDetails();
  const isGFTag = () => {
    return _.get(tagDetails, 'id')?.substr(0, 1) === 'G';
  };
  const getOffice = () => {
    if (canViewAOTag || canViewGFTag) {
      if (!isGFTag()) {
        return _.get(tagDetails, 'office.officeName')
          ? `${_.get(tagDetails, 'office.officeCode')} - ${_.get(
              tagDetails,
              'office.officeName',
            )}`
          : '—';
      }
      if (isGFTag()) {
        return _.get(
          tagDetails,
          'vehicle.customer.customerPhysicalOffice.officeName',
        )
          ? `${_.get(
              tagDetails,
              'vehicle.customer.customerPhysicalOffice.officeCode',
            )} - ${_.get(
              tagDetails,
              'vehicle.customer.customerPhysicalOffice.officeName',
            )}`
          : '—';
      }
    }
    return '—';
  };
  return (
    <>
      <h2 className="title-s-caps text-primary margin-bottom-1">AGENCY</h2>
      <div className="bg-gray-3 padding-3">
        <DetailsTable
          bordered
          data={[
            [
              'Agency',
              <span className="float-right align-right">
                {_.get(tagDetails, 'agency.name')
                  ? `${_.get(tagDetails, 'agency.id')} - ${_.get(
                      tagDetails,
                      'agency.name',
                    )}`
                  : '—'}
              </span>,
            ],
            [
              'Bureau',
              <span className="float-right align-right">
                {_.get(tagDetails, 'bureau.name')
                  ? `${_.get(tagDetails, 'bureau.id')} - ${_.get(
                      tagDetails,
                      'bureau.name',
                    )}`
                  : '—'}
              </span>,
            ],
            ['Office', <span className="float-right align-right">{getOffice()}</span>],
          ]}
        />
      </div>
    </>
  );
};

export default LpAgencyInfo;
