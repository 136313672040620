import React, { useMemo, useState } from 'react';
import { Button, DetailsTable } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';
import { emdash } from 'components/common';
import { SectionBlock } from '../../vehicle-overview/components';
import { EditFuel } from './index';
import { getAverageMonthlyMiles } from './helpers';

const Fuel = ({ data, canEdit, onSave }) => {
  const [editFuel, setEditFuel] = useState(false);
  // eslint-disable-next-line react/prop-types
  const { assetAcquisition, newOdometerReading } = data;

  const getData = (field, measurement) => {
    return field ? `${field} ${measurement}` : emdash;
  };
  const MPG = 'mpg';
  const GAL = 'gal/l';
  const GPM = 'gpm';
  const CITY_HWY = 'city/hwy';

  const isOwnershipGF = data?.ownershipTypeCode === 'GF';

  const tableData = useMemo(() => {
    const rows = [
      [
        'Fuel tank capacity',
        getData(data?.assetTechnical?.assetTechnicalIce?.tankSize, GAL),
      ],
      ['City', getData(data?.assetTechnical?.assetTechnicalIce?.mpgCity, MPG)],
      [
        'Highway',
        getData(data?.assetTechnical?.assetTechnicalIce?.mpgHighway, MPG),
      ],
      [
        'Combined',
        getData(data?.assetTechnical?.assetTechnicalIce?.mpgCombined, MPG),
      ],
      [
        'Flex fuel city',
        getData(data?.assetTechnical?.assetTechnicalIce?.flexFuelMpgCity, MPG),
      ],
      [
        'Flex fuel highway',
        getData(
          data?.assetTechnical?.assetTechnicalIce?.flexFuelMpgHighway,
          MPG,
        ),
      ],
      [
        'Flex fuel combined',
        getData(
          data?.assetTechnical?.assetTechnicalIce?.flexFuelMpgCombined,
          MPG,
        ),
      ],
      [
        'Gram per mile',
        getData(data?.assetTechnical?.assetTechnicalIce?.gramPerMile, GPM),
      ],
      [
        'Flex gram per mile',
        getData(
          data?.assetTechnical?.assetTechnicalIce?.flexFuelGramPerMile,
          GPM,
        ),
      ],
      [
        'Range in miles',
        getData(
          data?.assetTechnical?.assetTechnicalIce?.rangeInMiles,
          CITY_HWY,
        ),
      ],
      [
        'Average monthly miles',
        getAverageMonthlyMiles(assetAcquisition, newOdometerReading),
      ],
    ];
    return rows;
  }, [data]);

  const saveFuelChanges = (editState) => {
    const fieldsToUpdate = Object.keys(editState).map((key) => ({
      field: key,
      value: editState[key],
    }));
    onSave(fieldsToUpdate);
    setEditFuel(false);
  };

  return (
    <>
      <SectionBlock title="Fuel">
        <DetailsTable
          className="vehicle-overview-details-table"
          data={tableData}
        />
        {canEdit && !isOwnershipGF && (
          <Button
            onClick={() => setEditFuel(true)}
            variant="outline"
            className="bg-white margin-top-2"
            // eslint-disable-next-line react/prop-types
            aria-label={`edit Fuel ${data?.id}`}
            label="Edit"
            data-testid="edit-fuel"
          />
        )}
        {editFuel && (
          <EditFuel
            onSave={saveFuelChanges}
            onClose={() => setEditFuel(false)}
            vehicle={data}
          />
        )}
      </SectionBlock>
    </>
  );
};

export default Fuel;

Fuel.propTypes = {
  data: PropTypes.shape({
    assetTechnical: PropTypes.shape({
      assetTechnicalIce: PropTypes.shape({
        cylinders: PropTypes.string,
        transmission: PropTypes.string,
        tankSize: PropTypes.number,
        mpgCity: PropTypes.number,
        mpgHighway: PropTypes.number,
        mpgCombined: PropTypes.number,
        flexFuelMpgCity: PropTypes.number,
        flexFuelMpgHighway: PropTypes.number,
        flexFuelMpgCombined: PropTypes.number,
        gramPerMile: PropTypes.number,
        flexFuelGramPerMile: PropTypes.number,
      }),
    }),
  }),
  onSave: PropTypes.func,
};
