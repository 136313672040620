import React, { useEffect } from 'react';
import { FilterPanel } from '@gsa/afp-component-library';
import { useFCReplacementFilter } from './fc-filter-provider';
import { generateFcReplacementFilterStructure } from './fc-replacement-filter-structure';

const FcReplacementSidebar = () => {
  const {
    filterStructure,
    setFilters,
    setStructure,
    typeaheadData,
    getFcOrdersByPartialTagNumber,
  } = useFCReplacementFilter();

  const setFcReplacementFilters = (filters) => {
    setFilters({
      context: 'fcReplacement',
      filters,
    });
  };

  useEffect(() => {
    setStructure({
      context: 'fcReplacement',
      filterStructure: generateFcReplacementFilterStructure(),
    });
  }, []);

  const handleTypeaheadSearch = ({ variables }) => {
    const { conditions } = variables.filters[0];
    const query = conditions.filter((c) => c.key === variables.field)[0]?.value;
    if (variables.field === '$tag_number$') {
      getFcOrdersByPartialTagNumber(query);
    }
  };

  if (filterStructure?.fcReplacement?.length > 0) {
    return (
      <FilterPanel.FilterPanel
        filterStructure={filterStructure.fcReplacement}
        setQueryFiltersState={(filters) => setFcReplacementFilters(filters)}
        clearButtonLabel="Reset all"
        showClearIcon
        order={[['updateAt', 'DESC']]}
        fetchTypeaheads={handleTypeaheadSearch}
        typeaheadData={typeaheadData}
        showSearchIcon
      />
    );
  }
  return null;
};

export default FcReplacementSidebar;
