/* eslint-disable no-shadow */
/* eslint-disable array-callback-return */
/* eslint-disable import/prefer-default-export */
import { selectorFamily, selector } from 'recoil';
import { isNil, omitBy, pick, omit } from 'lodash';
import { strsToOptions } from 'utilities/format';
import { setValueByPath, isReadyToSubmit } from 'utilities/form';
import { textToFloat } from 'utilities/format';
import * as atoms from './atoms';
import atomStateSchema, {
  pageId as selectorPageId,
} from '../schema/atom-state-schema';

// the selector for set the field
// code function for update the field, include validation and change state value and after value change

export const pageConfigUpdateSelector = selectorFamily({
  key: `${selectorPageId}/pageConfigUpdate`,
  get:
    (id) =>
    ({ get }) => {
      return get(atoms.pageConfigAtom(id));
    },
  set:
    (id) =>
    ({ get, set }, newObj) => {
      const pageConfigAtom = atoms.pageConfigAtom(id);
      set(pageConfigAtom, { ...get(pageConfigAtom), ...newObj });
    },
});

export const addressSelector = selector({
  key: `${selectorPageId}/addressSelector`,
  get: ({ get }) => {
    const addressKeys = ['address', 'address2', 'city', 'state', 'zip'];
    const addressObj = addressKeys.reduce(
      (acc, key) => {
        acc[key] = get(atoms.formFieldAtom(key))?.value ?? '';
        return acc;
      },
      {
        addressValidateError: false,
      },
    );
    addressObj.addressValidateError = get(
      atoms.pageConfigAtom('addressValidateError'),
    ).value;
    return addressObj;
  },
  set: ({ get, set }, addressValidationArgs) => {
    const dependantFunctions = get(atoms.pageConfigAtom('dependantFunctions'));
    const pageMetaInfoState = get(atoms.pageConfigAtom('pageMetaInfo'));

    const pathInfo = Object.keys(addressValidationArgs)
      .map((key) => {
        if (!isNil(addressValidationArgs[key])) {
          return [key, addressValidationArgs[key]];
        }
      })
      .filter((x) => x);

    const data = get(atoms.pageConfigAtom('addressConfirmModalInfo'));
    const vehicleData = get(pageConfigUpdateSelector('vehicle'));

    const getFloatStateValue = (id) => {
      const filedStateValue = get(atoms.formFieldAtom(id)).value;
      return textToFloat(filedStateValue);
    };

    const getStringStateValue = (id, value) => {
      const filedStateValue = value ?? get(atoms.formFieldAtom(id)).value;
      return isNil(filedStateValue) ? null : filedStateValue;
    };

    const newState = setValueByPath({
      data,
      pathInfo,
    });
    if (addressValidationArgs.show === false) {
      const { address, address2, address3, city, zip, state } =
        newState.addressToValidate;

      const fcTransaction = omitBy(
        {
          assetId: vehicleData.uuid,
          acctn4: getStringStateValue('lastFourAccountNum'),
          ccn4: getStringStateValue('lastFourCardNum'),
          odometerReading: getFloatStateValue('transactionOdometer'),
          unitCost: getFloatStateValue('unitCost'),
          transactionDate: getStringStateValue('transactionDate'),
          fleetCardProductIdOnline: getStringStateValue('productDescription'),
          unitOfMeasureOnline: getStringStateValue('unitOfMeasure'),
          unitsBought: getFloatStateValue('quantity'),
          netCost: getFloatStateValue('netDollar'),
          taxAmount: getFloatStateValue('taxAmount'),
          grossCost: getFloatStateValue('totalCost'),
          comment: getStringStateValue('comment'),
        },
        isNil,
      );

      const fcMerchant = omitBy(
        {
          merchantName: getStringStateValue('merchantName'),
          merchantPhone: getStringStateValue('phone'),
          merchantPhoneExt: getStringStateValue('extension'),
          merchantAddress: getStringStateValue(address, address),
          merchantAddress2: getStringStateValue(address2, address2),
          merchantAddress3: getStringStateValue(address3, address3),
          merchantCity: getStringStateValue(city, city),
          merchantPostalCode: getStringStateValue(zip, zip),
          merchantStateCode: getStringStateValue(state, state),
        },
        isNil,
      );
      if (pageMetaInfoState.isEditPage) {
        fcTransaction.id = pageMetaInfoState.editId;
        if (pageMetaInfoState.isWEX) {
          dependantFunctions.mutation.wexUpdateExpense({
            variables: {
              fcTransaction: pick(fcTransaction, [
                'id',
                'fleetCardProductIdOnline',
                'unitOfMeasureOnline',
                'comment',
              ]),
            },
          });
        } else {
          dependantFunctions.mutation.updateExpense({
            variables: {
              fcTransaction: omit(fcTransaction, 'assetId'),
              fcMerchant,
            },
          });
        }
      } else {
        dependantFunctions.mutation.createExpense({
          variables: {
            fcTransaction,
            fcMerchant,
          },
        });
      }
    }

    set(atoms.pageConfigAtom('addressConfirmModalInfo'), newState);
    if (
      !addressValidationArgs?.hasValidationError &&
      addressValidationArgs.addressToValidate
    ) {
      Object.keys(addressValidationArgs.addressToValidate).map((key) => {
        set(atoms.formFieldAtom(key), {
          ...get(atoms.formFieldAtom(key)),
          value: addressValidationArgs.addressToValidate[key],
        });
      });
    }
  },
});

// this is for submit the form
export const submitSelector = selectorFamily({
  key: `${selectorPageId}/submitStep1Selector`,
  get: () => {
    return null;
  },
  set:
    (pageId) =>
    ({ get, set }) => {
      if (!isReadyToSubmit({ get, set, pageId, atoms, atomStateSchema }))
        return;
      // address Validation
      const addressFieldsIds = [
        'city',
        'zip',
        'state',
        'address',
        'address2',
        'address3',
      ];
      const addressToValidate = addressFieldsIds.reduce(
        (acc, key) => {
          acc[key] = get(atoms.formFieldAtom(key)).value ?? '';
          return acc;
        },
        {
          countryCode: 'US',
        },
      );
      const addressConfirmModalInfo = atoms.pageConfigAtom(
        'addressConfirmModalInfo',
      );
      const dependantFunctions = get(
        atoms.pageConfigAtom('dependantFunctions'),
      );
      set(addressConfirmModalInfo, { addressToValidate });

      dependantFunctions.query.validateAddress({
        variables: addressToValidate,
      });
    },
});

export const updateOptionsSelector = selector({
  key: `${selectorPageId}/updateOptionsSelector`,
  get: ({ get }) => {
    return get(atoms.formFieldAtom('productDescription').options);
  },
  set: (
    { get, set },
    { productOptions, unitOfMeasureStrs, setUnitOfMeasureToDefault },
  ) => {
    if (productOptions) {
      const productionStateAtom = atoms.formFieldAtom('productDescription');
      set(productionStateAtom, {
        ...get(productionStateAtom),
        options: [{ value: 'DEFAULT', label: '-Select-' }, ...productOptions],
      });
    }

    if (atoms.pageConfigAtom && unitOfMeasureStrs) {
      const unitOfMeasure = atoms.formFieldAtom('unitOfMeasure');
      const unitOfMeasureOptions = strsToOptions({ strs: unitOfMeasureStrs });
      // when change the product description trigger setUnitOfMeasureToDefault and it's value is true
      // when init  set to the unit of measure value
      if (setUnitOfMeasureToDefault) {
        set(unitOfMeasure, {
          ...get(unitOfMeasure),
          value: 'DEFAULT',
          options: unitOfMeasureOptions,
        });
      } else {
        set(unitOfMeasure, {
          ...get(unitOfMeasure),
          options: unitOfMeasureOptions,
        });
      }
    }
  },
});
