export { default as SectionBlock } from './section-block';
export const emdash = '\u2014';

export const formatShortDate = (date) => {
  if (!date) {
    return null;
  }

  const aDate = new Date(date);
  if (!(aDate instanceof Date) || Number.isNaN(aDate)) {
    return null;
  }

  const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
  return aDate.toLocaleDateString('en-us', options);
};

export const displayModel = (displayVehicle) => {
  if (displayVehicle) {
    if (displayVehicle.vehicleModel) {
      return displayVehicle.vehicleModel?.modelDescription?.toUpperCase();
    }
    if (
      displayVehicle.modelCode &&
      displayVehicle.modelCode.toUpperCase() !== 'UNKNOWN' &&
      displayVehicle.modelCode !== '-1'
    ) {
      return displayVehicle.modelCode.toUpperCase();
    }
  }
  return null;
};

export const displayVehicleGVWR = (vehicle) => {
  if (vehicle?.assetTechnical?.gvwr) {
    return `${vehicle.assetTechnical.gvwr
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')} lb`;
  }
  return '';
};
