import _, { get } from 'lodash';
import moment from 'moment';

export const validate = ({ data, rules, setErrors, validateCallback }) => {
  const validatedFields = {};
  rules.forEach((field) => {
    if (field.rules.includes('required')) {
      const fieldValue = get(data, field.name); // get deeply nested properties
      if (
        typeof fieldValue !== 'boolean' &&
        (!fieldValue ||
          fieldValue === '' ||
          (typeof fieldValue !== 'number' && _.isEmpty(fieldValue)))
      ) {
        validatedFields[field.name] = field.msg.required;
      } else {
        delete validatedFields[field.name];
      }
    } else if (field.rules.includes('requiredIfNotExempt')) {
      if (!data.exemptPlate) {
        const fieldValue = get(data, field.name);
        if (
          typeof fieldValue !== 'boolean' &&
          (!fieldValue ||
            fieldValue === '' ||
            (typeof fieldValue !== 'number' && _.isEmpty(fieldValue)))
        ) {
          validatedFields[field.name] = field.msg.required;
        } else {
          delete validatedFields[field.name];
        }
      }
    }
  });
  setErrors(validatedFields);
  const valid = Object.keys(validatedFields).length === 0;
  if (valid && validateCallback) validateCallback();
  return valid;
};

// https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
export const validEmail = (email) => {
  // const re = /\S+@\S+\.\S+/;
  const re =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const getValue = (value, fallbackValue) => value || fallbackValue || '—';

export const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

export function isDifferenceMoreThanAYear(startDate, endDate) {
  const startMoment = moment(startDate);
  const endMoment = moment(endDate);

  const yearDifference = endMoment.diff(startMoment, 'years');
  const remainingMonths = endMoment.diff(startMoment, 'months') % 12;
  const remainingDays = endMoment.diff(startMoment, 'days');

  return (
    yearDifference > 1 ||
    (yearDifference === 1 && (remainingMonths > 0 || remainingDays > 0))
  );
}

export function isDifferenceMoreThan35Days(startDate, endDate) {
  const startMoment = moment(startDate);
  const endMoment = moment(endDate);

  const remainingDays = endMoment.diff(startMoment, 'days');

  return remainingDays > 35;
}
