import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { startCase, toUpper } from 'lodash';
import moment from 'moment';
import OrderDetail from './detail-row';
import OrderDetailLink from './order-detail-row-link';
import OrderDetailEmail from './order-detail-row-email';

export const constructAddress = (agencyAddress) => {
  let addressAvailable = false;
  for (const key of Object.keys(agencyAddress)) {
    if (agencyAddress[key]) {
      addressAvailable = true;
    }
  }
  if (agencyAddress && addressAvailable) {
    const address = ['address1', 'address2', 'address3', 'address4'].reduce(
      (adr, item) => {
        if (agencyAddress[item]) {
          adr.push(agencyAddress[item]);
        }
        return adr;
      },
      [],
    );

    const cityStateZip = ['city', 'state', 'zip']
      .reduce((adr, item, i) => {
        if (i === 1) {
          if (agencyAddress[item]) {
            adr.push(agencyAddress[item] + ' ' + agencyAddress['zip']);
          }
          return adr;
        }
        if (i === 2) return adr;
        if (agencyAddress[item]) {
          adr.push(agencyAddress[item]);
        }
        return adr;
      }, [])
      .join(', ');

    address.push(cityStateZip);
    return address;
  }
  return '—';
};

export default function OrderDetails({ row: { original } }) {
  const {
    processedAt,
    pocName,
    pocEmail,
    pocPhone,
    agencyAddress,
    plateCount,
    staticOrderNumber,
    createdAt,
  } = original;

  const address = constructAddress(agencyAddress);
  return (
    <div className="display-flex flex-justify-center padding-x-8">
      <div className="vehicle-row-details margin-right-5 flex-1">
        <div className="display-flex flex-align-center">
          <h2 className="text-uppercase font-sans-xs margin-right-105">
            Order Details
          </h2>
          <Link to={`/order/${encodeURIComponent(staticOrderNumber)}`}>
            More
          </Link>
        </div>
        <div className="row-details">
          <OrderDetailLink
            label="Order Number"
            value={staticOrderNumber}
            orderNumber={staticOrderNumber}
          />
          <OrderDetail
            label="Date Ordered"
            value={createdAt ? moment(createdAt).format('MM/DD/YYYY') : '-'}
          />
          <OrderDetail
            label="Quantity of Plate"
            value={plateCount.toString()}
            orderNumber={staticOrderNumber}
          />
          <OrderDetail
            label="Date Shipped"
            value={new Date(processedAt).toLocaleDateString()}
          />
        </div>
      </div>
      <div className="vehicle-row-details flex-1">
        <div className="display-flex flex-align-center">
          <h2 className="text-uppercase font-sans-xs margin-right-105">
            Point of Contact
          </h2>
        </div>
        <div className="row-details">
          <OrderDetail label="Name" value={startCase(toUpper(pocName))} />
          <OrderDetailEmail label="Email" email={pocEmail} />
          <OrderDetail label="Phone" value={pocPhone} />
          <OrderDetail label="Zone" value="—" />
          <OrderDetail label="FMC" value="—" />
          <OrderDetail label="Address" value={startCase(toUpper(address))} />
        </div>
      </div>
    </div>
  );
}

OrderDetails.propTypes = {
  order: PropTypes.object.isRequired,
};
