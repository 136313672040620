export const VEHICLE_OWNERSHIP = {
  GF: 'GSA Leased',
  AO: 'Agency Owned',
};

export default function vehicleOwnership(ownership) {
  if (!ownership) {
    return '—';
  }
  return VEHICLE_OWNERSHIP[ownership] || '—';
}
