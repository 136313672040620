import { atomFamily } from 'recoil';
import { pageId } from '../schema/atom-state-schema';

export const layoutSchemaAtom = atomFamily({
  key: `${pageId}/layoutFormState`,
  default: {
    currentStep: 1,
    layout: null,
  },
});

export const formFieldAtom = atomFamily({
  key: `${pageId}/formFieldState`,
  default: { errorMessage: null, value: null, isDirty: false, validate: null },
  dangerouslyAllowMutability: true,
});

export const pageConfigAtom = atomFamily({
  key: `${pageId}/pageConfigState`,
  default: { value: null },
  dangerouslyAllowMutability: true,
});
