import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from '@gsa/afp-component-library';
import { InternalError } from '@gsa/afp-shared-ui-utils';
import errorMessages from './server-error-message-list';

const ServerErrorMessage = ({ graphQLErrors, error }) => {
  let errorMessage;
  let handledError;
  if (error) {
    errorMessage = error.message;
    handledError = true;
  } else {
    handledError = graphQLErrors?.graphQLErrors.find(
      ({ extensions }) => extensions?.exception?.handled, // to be changed once we have handled types
    );

    if (handledError) errorMessage = errorMessages(handledError);
  }
  return (
    <>
      {handledError && errorMessage ? (
        <Alert
          type="error"
          data-testid="server-error"
          className="margin-bottom-2"
          focused
          slim
        >
          {errorMessage}
        </Alert>
      ) : (
        <InternalError />
      )}
    </>
  );
};

ServerErrorMessage.propTypes = {
  graphQLErrors: PropTypes.shape(Object),
  error: PropTypes.shape(Object),
};

export default ServerErrorMessage;
