import React from 'react';
import { OptIn } from '@gsa/afp-shared-ui-utils';
import moment from 'moment';
import PropTypes from 'prop-types';

import { VehiclePropType } from 'utilities/types';
import { useFeature } from 'utilities/features';
import {
  isMileageEnabled,
  isRecallEnabled,
} from '../../../helpers/feature-helper';
import './styles.css';
import POC from './poc';

const Overview = ({ data }) => {
  const {
    agencyCode,
    bureauCode,
    ownershipTypeCode,
    itemInventoryStatusCode,
    agency,
    customer,
  } = data;
  const features = useFeature();

  const formatDate = (date) =>
    `${date.substr(5, 2)}/${date.substr(8, 2)}/${date.substr(0, 4)}`;

  const formatEligibleReplacementDate = (date) =>
    date ? moment.utc(date).format('MMMM DD, YYYY') : '—';

  return (
    <div className="vehicle-overview-container">
      <POC
        data={data}
        ownershipTypeCode={ownershipTypeCode}
        agencyCode={agencyCode}
        agency={agency}
        customer={customer}
      />
      <div className="vehicle-overview-row">
        <OptIn
          agencyCode={agencyCode}
          bureauCode={bureauCode}
          isHidden={
            !features.fedfms || !isMileageEnabled(agencyCode, ownershipTypeCode)
          }
        >
          <div className="overview-card-sm">
            <div className="title-s margin-top-0">Vehicle odometer</div>
            <div className="font-sans-lg text-bold margin-top-2">
              {data.newOdometerReading?.odometer?.toLocaleString() || '—'}
            </div>
            <div className="font-sans-xs">
              {data.newOdometerReading?.mileageDate
                ? `miles as of ${formatDate(
                    data.newOdometerReading?.mileageDate,
                  )}`
                : 'miles'}
            </div>
          </div>
        </OptIn>
        <div className="overview-card-sm">
          <div className="title-s margin-top-0">
            Projected replacement eligibility
          </div>
          <div className="font-sans-lg text-bold margin-top-2">
            {formatEligibleReplacementDate(
              data.assetReplacement?.eligibleReplacementDate,
            )}
          </div>
        </div>
        <OptIn
          agencyCode={agencyCode}
          bureauCode={bureauCode}
          isHidden={
            !features.fedfms ||
            !isRecallEnabled(
              agencyCode,
              ownershipTypeCode,
              itemInventoryStatusCode,
            )
          }
        >
          <div className="overview-card-sm">
            <div className="title-s margin-top-0">Open recalls</div>
            <div className="font-sans-lg text-bold margin-top-2">
              {data.recallCounts && data.recallCounts.openCount >= 0
                ? data.recallCounts.openCount
                : '—'}
            </div>
          </div>
        </OptIn>
      </div>
    </div>
  );
};

Overview.propTypes = {
  data: PropTypes.shape(VehiclePropType).isRequired,
};

export default Overview;
