import React from 'react';
import { NotFound } from '@gsa/afp-component-library';
import * as consts from './consts';

const FEATURES = consts.getFeatures();
const FEATURE_LIST = Object.keys(FEATURES);
const isFeature = (feature) => feature && FEATURE_LIST.includes(feature);
const isFeatureON = (feature) => FEATURES[feature];

const EmptyCmp = () => null;

export const PlaceHolderEmptyPage = () => {
  return NotFound;
};

export const isFeatureEnabled = (feature) => {
  if (isFeature(feature) && isFeatureON(feature)) {
    return true;
  }
  return false;
};

export const useFeatureToggle = (returnNull) => {
  const feature = (cmp, feat) => {
    if (isFeature(feat) && isFeatureON(feat)) {
      return cmp;
    }
    return NotFound;
  };
  return { feature };
};

export default function FeatureToggle({ children, feature, returnNull }) {
  if (isFeature(feature) && isFeatureON(feature)) {
    return children;
  }
  return returnNull ? <EmptyCmp /> : <PlaceHolderEmptyPage />;
}
