/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Alert,
  Spinner,
  Modal,
  useModal,
} from '@gsa/afp-component-library';
import { useVehicle } from 'components/vehicle-details/vehicle-context-provider';
import { ReportStolenForm } from './report-stolen-form';

const ReportStolen = ({
  reportStolenVehicleError,
  setModalError,
  mode,
  getStolenData,
  submitStolenReport,
  vehicle,
  onClose,
  getDataLoading,
  reportData,
  setReportData,
  reportStolenVehicleLoading,
}) => {
  const { openModal, closeModal } = useModal();
  const { showAlert } = useVehicle();
  const [lifecycles, setLifecycles] = useState([]);

  const StolenVehicleTitle = () => {
    const reportText = lifecycles['Missing/Stolen'] ? 'Update' : 'Report';
    let headerText;
    let headerSubText;
    if (mode === 'recover') {
      headerText = 'Report recovered vehicle';
      headerSubText = `Report recovered vehicle`;
    } else {
      headerText = `${reportText} stolen vehicle`;
      headerSubText = `${reportText} stolen vehicle`;
    }

    return (
      <>
        <div>
          <h1>{headerText}</h1>
          <div className="margin-bottom-3">
            {headerSubText} for VIN <b> {vehicle?.id}</b> in the form below.
          </div>
        </div>
      </>
    );
  };
  useEffect(() => {
    if (vehicle && (mode === 'update' || mode === 'recover') && getStolenData) {
      getStolenData({
        variables: {
          assetId: reportData.assetId,
        },
      });
    }
    openModal();
  }, []);

  useEffect(() => {
    const vehicleLifecycleData = _.get(vehicle, 'assetLifecycle', []);
    if (vehicleLifecycleData.length > 0) {
      setLifecycles(
        _.reduce(
          vehicleLifecycleData,
          (result, { lifeCycle }) => {
            if (result[lifeCycle.lifecycleIndicator])
              return {
                [lifeCycle.lifecycleIndicator]: [
                  ...result[lifeCycle.lifecycleIndicator],
                  lifeCycle.lifecycleIndicatorId,
                ],
              };
            return {
              ...result,
              [lifeCycle.lifecycleIndicator]: [lifeCycle.lifecycleIndicatorId],
            };
          },
          {
            [vehicleLifecycleData[0]?.lifeCycle.lifecycleIndicator]: [],
          },
        ),
      );
    }
  }, [vehicle]);

  const close = () => {
    onClose && onClose();
    closeModal();
  };

  return (
    <>
      <div className="afp-modal-wrapper">
        <div className="afp-modal-overlay">
          <Modal
            variant="large"
            actions={
              <>
                <Button
                  variant="unstyled"
                  id="cancelReportStolen"
                  data-testId="cancelReportStolen"
                  label="Cancel"
                  onClick={close}
                />
                <Button
                  className="margin-left-2"
                  type="submit"
                  label="Save and close"
                  form="report-stolen-vehicle-form"
                  disabled={reportStolenVehicleLoading}
                />
                {reportStolenVehicleLoading && (
                  <Spinner
                    size="small"
                    aria-busy="true"
                    className="display-inline-block margin-left-2"
                  />
                )}
              </>
            }
            title={<StolenVehicleTitle />}
            onClose={close}
          >
            {reportStolenVehicleError?.context === 'stolenVehicle' &&
              showAlert(reportStolenVehicleError, setModalError)}
            {getDataLoading && (
              <Spinner
                aria-busy="true"
                className="loading_backdrop"
                size="large"
              />
            )}

            {
              // otherwise form values are not prepopulated
              (mode === 'create' || reportData?.gsaReportedDate) && (
                <ReportStolenForm
                  mode={mode}
                  reportData={reportData}
                  setReportData={setReportData}
                  onSubmit={submitStolenReport}
                />
              )
            }
          </Modal>
        </div>
      </div>
    </>
  );
};

export default ReportStolen;
