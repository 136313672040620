/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { AFPTable, Alert } from '@gsa/afp-component-library';
import usePagination from 'utilities/use-pagination';
import TableNoData from 'utilities/table-no-data';
import { useVehicle } from '../../vehicle-context-provider';
import { AddRepairOrderButton, RepairRowActions } from './repair-actions';
import {
  SERVICE_TAB_INDEX,
  utcDateStrToUS,
  getLineDesc,
  getLineCost,
  sortToOrder,
} from './helpers';
import { GET_REPAIR_SERVICE_LINES_BY_VEHICLE } from 'services/data-layer/repair-order';
import useDateRangeFilter from '../hooks/use-date-range-filter';

const DEFAULT_ORDER = [];

const RepairsByServiceTable = () => {
  const [tableData, setTableData] = useState([]);
  const [order, setOrder] = useState(DEFAULT_ORDER);
  // use local error message toaster
  // setSectionMsg in useVehicle() will cause tabset to switch to 1st tab
  const [showLoadingError, setShowLoadingError] = useState(false);
  const [dateFiltersState, setDateFiltersState] = useState({
    filters: undefined,
  });
  const { vehicle } = useVehicle();

  const [Pagination, { offset, limit, setItemsCount, resetPage }] =
    usePagination();

  const [fetchData, { data, loading }] = useLazyQuery(
    GET_REPAIR_SERVICE_LINES_BY_VEHICLE,
    {
      fetchPolicy: 'network-only',
      onError: () => setShowLoadingError(true),
    },
  );

  const onSort = (sortBy) => {
    if (sortBy) {
      const tableOrder = sortToOrder(sortBy);
      let queryOrder;
      switch (tableOrder[0]) {
        case 'serviceCode':
          queryOrder = [
            ['serviceCode', 'componentOne', tableOrder[1]],
            ['serviceCode', 'componentTwo', tableOrder[1]],
            ['serviceCode', 'componentThree', tableOrder[1]],
            ['serviceCode', 'componentFour', tableOrder[1]],
            ['serviceCode', 'componentFive', tableOrder[1]],
          ];
          break;
        case 'serviceClass':
          queryOrder = [['serviceClassCode', tableOrder[1]]];
          break;
        case 'serviceReason':
          queryOrder = [['serviceReasonCode', tableOrder[1]]];
          break;
        case 'serviceLineCosts':
          queryOrder = [['serviceLineCosts', 'partsTotal', tableOrder[1]]];
          break;
        default:
          queryOrder = [[...tableOrder]];
      }
      setOrder(queryOrder);
      resetPage(); // reset to page 1
    }
  };

  // replace this: set total item count after data is fetched
  useEffect(() => {
    if (!data?.getRepairServiceLinesByVehicle) return;
    const { count, rows } = data.getRepairServiceLinesByVehicle;
    setItemsCount(count);
    setTableData(rows);
    setShowLoadingError(false);
  }, [data]);

  useEffect(() => {
    fetchData({
      variables: {
        filters: dateFiltersState.filters,
        assetId: vehicle.uuid,
        offset,
        limit,
        order,
      },
    });
  }, [offset, limit, order, dateFiltersState]);

  const tableColumns = [
    {
      Header: 'Date of repair',
      accessor: 'service.transactionDate',
      Cell: ({ value }) => utcDateStrToUS(value),
    },
    {
      Header: 'Service type',
      accessor: 'serviceCode',
      Cell: ({ value }) => getLineDesc(value),
    },
    {
      Header: 'Class',
      accessor: 'serviceClass',
    },
    {
      Header: 'Reason',
      accessor: 'serviceReason',
    },
    {
      Header: 'Total',
      accessor: 'serviceLineCosts',
      headerClassName: 'cell-right',
      cellClassName: 'cell-right',
      Cell: ({ value }) => getLineCost(value),
    },
    {
      Header: 'Actions',
      accessor: 'serviceId',
      Cell: ({ value }) => (
        <RepairRowActions tab={SERVICE_TAB_INDEX} serviceId={value} />
      ),
      sortable: false,
    },
  ];

  const { getDateRangeComponent, fromDate, toDate, touched } =
    useDateRangeFilter({
      classes: 'grid-row grid-gap flex-1',
      dateFormat: 'YYYY-MM-DD',
    });

  useEffect(() => {
    if (!fromDate || !toDate) {
      setDateFiltersState({ filters: undefined });
    } else if (touched) {
      setDateFiltersState({
        filters: {
          operator: '$and',
          conditions: [
            { operator: '$exact', key: 'asset_id', value: vehicle.uuid },
            {
              operator: '$between',
              key: 'transaction_date',
              value: [fromDate, toDate],
            },
          ],
        },
      });
    }
  }, [fromDate, toDate]);

  return (
    <div>
      <div className="grid-row grid-gap flex-justify">
        {getDateRangeComponent()}
        <div className="grid-col flex-1 flex-align-self-end">
          <div className="float-right">
            <AddRepairOrderButton />
          </div>
        </div>
      </div>
      {showLoadingError && (
        <Alert
          type="error"
          focused
          slim
          className="margin-bottom-2 padding-bottom-2"
        >
          Unable to fetch repair services
        </Alert>
      )}
      <AFPTable
        columns={tableColumns}
        data={loading ? [] : tableData || []}
        testId="afp-repairs-by-service-table"
        defaultSort={DEFAULT_ORDER}
        fullWidth
        scrollable={false}
        onSort={onSort}
      />
      <TableNoData
        loading={loading}
        hasNoData={!tableData?.length}
        bottomText="No repair data"
      />
      <Pagination loading={loading} />
    </div>
  );
};

export default RepairsByServiceTable;
