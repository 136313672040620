export const generateFcReplacementFilterStructure = () => {
  return [
    {
      title: 'License plate',
      ariaLabel: 'Search by license plate',
      key: '$tag_number$',
      placeholder: 'Search by license plate',
      id: 'filter-license-plate',
      type: 'typeahead',
      operator: '$exact',
      value: [],
      customFieldProps: {
        inputCharNum: 3,
        debounceDelay: 500,
        promptText: 'Search requires 3 characters',
        showNoResults: false,
        clearPanelFilterOnEmpty: true,
      },
      hideClear: true,
    },
    {
      id: 'filter-ordered-date',
      title: 'Ordered date',
      key: 'createdAt',
      type: 'daterange',
      operator: '$between',
      options: {
        startDate: {
          key: 'beginning',
          label: 'Start date',
          hint: 'mm/dd/yyyy',
          maxDate: new Date().toISOString(),
        },
        endDate: {
          key: 'end',
          label: 'End date',
          hint: 'mm/dd/yyyy',
          maxDate: new Date().toISOString(),
        },
      },
      value: {
        beginning: '',
        end: '',
      },
      hideClear: true,
    },
  ];
};
