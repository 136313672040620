import { Button, Icon } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import _ from 'lodash';
import AttachmentAddForm from './attachment-add-form';
import AttachmentModal from './attachment-modal';
import { useAttachmentComponent } from './component-context';

const AttachmentAdd = ({ title }) => {
  const {
    setAttachmentErrors,
    addState,
    setAddState,
    rows: attachments,
  } = useAttachmentComponent();

  const clearErrors = () =>
    setAttachmentErrors((prev) => ({ ...prev, save: {} }));

  // Close modal on success.
  useEffect(() => {
    if (addState?.added) {
      clearErrors();
      setAddState({ show: false, adding: false, added: false });
    }
  }, [addState]);

  return (
    <div data-testid="attachment-add-wrapper">
      <AttachmentModal
        id="attachment-add-modal"
        className="attachment-add-modal"
        title={title}
        show={addState?.show}
        onClose={() => {
          clearErrors();
          setAddState({ show: false, adding: false, added: false });
        }}
        actions={
          <>
            <Button
              data-testid="attachment-add-cancel-button"
              disabled={addState?.adding}
              type="button"
              variant="outline"
              onClick={() => {
                clearErrors();
                setAddState({ show: false, adding: false, added: false });
              }}
              label="Cancel"
            />
            <Button
              data-testid="attachment-add-upload-button"
              disabled={addState?.adding}
              type="submit"
              form="attachment-upload-form"
              className="add-document-button action-button"
              label={addState?.adding ? 'Uploading...' : 'Add Document'}
            />
          </>
        }
      >
        <AttachmentAddForm />
      </AttachmentModal>

      <div
        className={`display-flex flex-justify-${
          _.size(attachments) > 0 ? 'start' : 'center'
        }`}
      >
        <Button
          data-testid="attachment-add-button"
          type="button"
          onClick={() => {
            clearErrors();
            setAddState({ show: true, adding: false, added: false });
          }}
          leftIcon={{ name: 'add', class: 'usa-icon--size-2' }}
          label="Add Document"
        />
      </div>
    </div>
  );
};

AttachmentAdd.defaultProps = {
  title: <h2>Add Document</h2>,
};

AttachmentAdd.propTypes = {
  title: PropTypes.node,
};

export default AttachmentAdd;
