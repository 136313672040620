import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Comments } from '@gsa/afp-component-library';
import { useCurrentUser } from '@gsa/afp-shared-ui-utils';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  CREATE_VEHICLE_COMMENT,
  GET_VEHICLE_COMMENTS,
} from '../../../../services/data-layer/vehicle';

const VehicleComments = () => {
  let { vin } = useParams();
  if (vin) {
    vin = decodeURIComponent(vin);
  }

  const { currentUser } = useCurrentUser();
  const [comments, setComments] = useState({
    count: 0,
    hasMore: false,
    rows: [],
  });
  const [showAllComments, setShowAllComments] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState({});

  const [getVehicleCommentsQuery, { loading: loadingComments }] = useLazyQuery(
    GET_VEHICLE_COMMENTS,
    {
      onError: (error) => {
        setSubmissionStatus({
          action: 'get',
          status: 'fail',
          message: `An unexpected error has occurred: ${error.message}`,
        });
      },
      onCompleted: (data) => {
        setComments(data.getVehicleComments);
      },
      fetchPolicy: 'network-only',
    },
  );
  const getVehicleComments = () => {
    getVehicleCommentsQuery({
      variables: {
        id: vin,
        limit: showAllComments ? Infinity : 5,
        offset: 0,
        order: [['createdAt', 'DESC']],
      },
    });
  };

  const [addVehicleCommentQuery, { loading: submittingComment }] = useMutation(
    CREATE_VEHICLE_COMMENT,
    {
      onError: (error) => {
        setSubmissionStatus({
          action: 'add',
          status: 'fail',
          message: `An unexpected error has occurred: ${error.message}`,
        });
      },
      onCompleted: () => {
        getVehicleComments();
        setSubmissionStatus({
          action: 'add',
          status: 'success',
          message: '',
        });
      },
    },
  );
  const addVehicleComment = (comment) => {
    addVehicleCommentQuery({
      variables: {
        id: vin,
        comment,
      },
    });
  };

  useEffect(() => {
    getVehicleComments();
  }, [showAllComments]);

  useEffect(() => {
    getVehicleComments();
  }, []);

  return (
    <Comments
      comments={comments}
      showAllComments={setShowAllComments}
      loadingComments={loadingComments}
      submitComment={addVehicleComment}
      submittingComment={submittingComment}
      submissionStatus={submissionStatus}
      currentUser={currentUser?.id}
      allowChanges={false}
    />
  );
};

export default VehicleComments;
