import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { TextInput } from '@gsa/afp-component-library';
import { CDD_FORM_FIELDS } from './consts';

const CommentsForm = () => {
  const {
    formState: { errors },
    control,
  } = useFormContext();

  return (
    <div data-testid="cdd-comments-form">
      <Controller
        name={CDD_FORM_FIELDS.aldComment.name}
        control={control}
        render={({ field, ref }) => {
          return (
            <TextInput
              {...field}
              ref={ref}
              type="textarea"
              label={<span className="text-bold">Comments</span>}
              name={CDD_FORM_FIELDS.aldComment.name}
              id={CDD_FORM_FIELDS.aldComment.name}
              errorMessage={
                errors && errors[CDD_FORM_FIELDS.aldComment.name]
                  ? errors[CDD_FORM_FIELDS.aldComment.name].message
                  : null
              }
              maxLength={65}
              aria-invalid={
                errors && errors[CDD_FORM_FIELDS.aldComment.name]
                  ? 'true'
                  : 'false'
              }
            />
          );
        }}
      />
    </div>
  );
};

export default CommentsForm;
