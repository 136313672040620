import { useLazyQuery } from '@apollo/client';
import { Button, TextInput } from '@gsa/afp-component-library';
import { usePrevious } from '@gsa/afp-shared-ui-utils';
import React, { useEffect, useState } from 'react';
import { GET_TAG_ACTIVITY } from '../../../services/data-layer';
import FeatureToggle from '../../../utilities/feature-toggle';
import usePortalModal from '../../../utilities/portal-modal';
import { isTest } from '../../../utilities/consts';
import ExemptCheckbox from '../../exempt-checkbox';
import { fieldGetter } from '../../vehicle-details/helpers/field-getter';
import './license-plate-edit.css';
import { LicensePlateUpdateWarning } from './vehicle-status-warnings';

export default function LicensePlateEdit({
  editing,
  onClose,
  onSave,
  licensePlate,
  lpExpDate,
  stateLicPlate,
  exemptPlate,
}) {
  const [LPModal, openModal, closeModal] = usePortalModal();

  const [, { data: tagDetails, loading: loadingTagDetails }] = useLazyQuery(
    GET_TAG_ACTIVITY,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const [lp, setLp] = useState(licensePlate);
  const [licExpDate, setExpDate] = useState(lpExpDate);
  const [lpstateLicPlate, setStateLicPlate] = useState(stateLicPlate);
  const [isExemptPlate, setIsExemptPlate] = useState(!!exemptPlate);
  const [showExemptAlert, setShowExemptAlert] = useState(false);

  let prevStateLicPlate;
  if (!isTest) {
    prevStateLicPlate = usePrevious(stateLicPlate);
  }

  useEffect(() => {
    if (stateLicPlate !== prevStateLicPlate) {
      setStateLicPlate(stateLicPlate);
    }
  }, [stateLicPlate]);

  useEffect(() => {
    if (tagDetails) {
      setExpDate(tagDetails?.getTag?.expirationDate);
    }
  }, [tagDetails]);

  useEffect(() => {
    if (editing) {
      openModal();
      setLp(licensePlate);
      setExpDate(lpExpDate);
      setShowExemptAlert(false); // don't show the alert on page load if it's already checked
      setIsExemptPlate(exemptPlate);
    } else {
      closeModal();
    }
  }, [editing, setLp, setExpDate]);

  const close = () => {
    // eslint-disable-next-line no-unused-expressions
    onClose && onClose();
  };

  const save = () => {
    // eslint-disable-next-line no-unused-expressions
    onSave &&
      onSave({
        tagNumber: isExemptPlate ? null : lp,
        tagExpirationDate: isExemptPlate ? null : licExpDate,
        statePlate: lpstateLicPlate,
        exemptPlate: isExemptPlate,
      });
  };

  return (
    <LPModal
      onClose={close}
      title={<h2>License plate</h2>}
      actions={
        <>
          <Button variant="unstyled" onClick={close} label="Cancel" />
          <Button
            className="margin-left-2"
            onClick={save}
            disabled={loadingTagDetails}
            label="Save and close"
          />
        </>
      }
    >
      <FeatureToggle feature="vehicle-reg-details-tag-edit" returnNull>
        <ExemptCheckbox
          exemptPlate={isExemptPlate}
          handleChange={(e) => {
            setShowExemptAlert(true);
            const { checked } = e.currentTarget;
            setIsExemptPlate(checked);
          }}
          licensePlate={licensePlate}
          showAlert={showExemptAlert}
        />
        {!isExemptPlate && !licensePlate && <LicensePlateUpdateWarning />}
        {!isExemptPlate && (
          <div className="grid-col-7">
            <TextInput
              value={lp || '—'}
              label="License Plate"
              ariaLabel="License Plate"
              name="tagNumber"
              id="licensePlate"
              type="text"
              disabled
              data-testid="license-plate"
            />
            <TextInput
              value={fieldGetter([{ field: licExpDate, formatter: 'exp' }])}
              label="License Plate Expiration Date"
              name="tagExpDate"
              type="text"
              disabled
              data-testid="license-plate-expiration-date"
            />
          </div>
        )}
      </FeatureToggle>
      <div className="grid-col-7">
        <TextInput
          label="State License Plate"
          name="lpstateLicPlate"
          value={lpstateLicPlate}
          onChange={(e) => {
            setStateLicPlate(e.target.value);
          }}
          data-testid="state-license-plate"
        />
      </div>
    </LPModal>
  );
}
