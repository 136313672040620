import {
  YesNoSelector,
  WrappedStateSelector,
  BackupDispatcherSelector,
  AgencySelector,
  PrimaryDispatcherSelector,
  TimezoneSelector,
} from './motor-pool-form-components';

export const MOTOR_POOL_FORM_FIELDS = {
  mpName: {
    label: 'Name of motor pool',
    required: true,
    name: 'poolName',
    id: 'poolName',
    dataId: 'poolName',
    editable: true,
    additionalAttributes: {
      maxLength: 51,
      characterLimit: 50,
    },
  },
  description: {
    label: 'Description',
    required: true,
    name: 'description',
    id: 'description',
    dataId: 'description',
    editable: true,
    additionalAttributes: {
      type: 'textarea',
      maxLength: 201,
      characterLimit: 200,
    },
  },
  city: {
    label: 'City',
    name: 'city',
    id: 'city',
    dataId: 'city',
    editable: true,
    wrapperClass:
      'tablet:display-inline-block tablet:grid-col-6 tablet:margin-right-105em',
  },
  state: {
    label: 'State',
    name: 'state',
    id: 'state',
    dataId: 'state',
    editable: true,
    wrapperClass: 'tablet:display-inline-block',
    component: WrappedStateSelector,
  },
  zip: {
    label: 'ZIP code',
    name: 'zipCode',
    id: 'zipCode',
    dataId: 'zipCode',
    editable: true,
    wrapperClass: 'tablet:grid-col-3',
    additionalAttributes: {
      maxLength: 5,
    },
  },
  timezone: {
    label: 'Time zone',
    name: 'timezone',
    required: true,
    id: 'timezone',
    dataId: 'timezone',
    editable: true,
    mode: 'motorPool',
    wrapperClass: 'tablet:grid-col-6',
    component: TimezoneSelector,
  },
  isPublic: {
    label: 'Pool is public',
    name: 'isPublic',
    id: 'isPublic',
    dataId: 'isPublic',
    editable: true,
    component: YesNoSelector,
  },
  mpAgency: {
    label: 'Agency',
    required: true,
    name: 'agencyCode',
    id: 'agencyCode',
    dataId: 'agencyCode',
    editable: false,
    component: AgencySelector,
  },
  primaryDispatcher: {
    label: 'Primary dispatcher',
    required: true,
    name: 'primaryDispatcher',
    id: 'primaryDispatcher',
    dataId: 'primaryDispatcher',
    editable: true,
    component: PrimaryDispatcherSelector,
  },
  backupDispatchers: {
    label: 'Backup dispatcher(s)',
    name: 'backupDispatchers',
    id: 'backupDispatchers',
    dataId: 'backupDispatchers',
    editable: true,
    component: BackupDispatcherSelector,
  },
};
