/* eslint-disable filenames/match-exported */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable filenames/match-regex */
import React from 'react';
import { Button, Spinner } from '@gsa/afp-component-library';

const RemoveVinSchema = {
  title: () => <h2>Remove Vin</h2>,
  content: ({ currentVehicle }) => {
    return (
      <>
        <p>
          {' '}
          Are you sure you want to remove Vin{' '}
          <span className="text-bold">{currentVehicle?.vin}</span> from campaign{' '}
        </p>
      </>
    );
  },
  action: ({
    resetCurrentModal,
    resetCurrentVehicle,
    removeVinFromCampaign,
    currentVehicle,
    currentCampaignId,
  }) => (
    <>
      <Button
        variant="unstyled"
        className="margin-right-2"
        onClick={() => {
          resetCurrentVehicle();
          resetCurrentModal();
        }}
        data-testid="remove-vin-cancel-button"
        label="Cancel"
      />
      <Button
        variant="secondary"
        type="submit"
        form="remove-vin-form"
        data-testid="utilization-report-form-submit-button"
        label="Remove Vin"
        onClick={() => {
          removeVinFromCampaign({
            variables: {
              campaignId: currentCampaignId,
              assetId: currentVehicle?.assetId,
            },
          });
        }}
      />
    </>
  ),
  onClose: ({ resetCurrentModal, resetCurrentVehicle }) => {
    resetCurrentVehicle();
    resetCurrentModal();
  },
};

export default RemoveVinSchema;
