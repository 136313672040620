/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable filenames/match-exported */

import React from 'react';
import Row from './widgets/Row';
import { createFieldByComponentType } from './schema/fieldMaps';

const SectionGenerator = ({
  pageId,
  selectors,
  fields = null,
  name = null,
  desc = null,
  lineBreak,
  customizedWidgets,
}) => {
  //    when original design I did not consider the json schema standard
  //    The type keyword is fundamental to JSON Schema. It specifies the data type for a schema.
  //     At its core, JSON Schema defines the following basic types:
  //          string number integer object array boolean null
  //     https://json-schema.org/understanding-json-schema/reference/type.html
  //     we are using both componentType and type as the key for the componentType now
  //     need to  come back to the sectionGenerator after mvp, to clean up all the schema

  const FieldGenerator = ({
    type,
    columns = [],
    index,
    componentType,
    ...fieldProps
  }) => {
    if (type === 'row') {
      return <Row key={`${index}-row`}>{columns.map(FieldGenerator)}</Row>;
    }
    return createFieldByComponentType({
      props: { pageId, selectors, ...fieldProps },
      componentType: componentType || type,
      customizedWidgets,
    });
  };
  return (
    <section className="margin-bottom-6">
      {name && <h2 className="text-primary margin-bottom-1">{name}</h2>}
      {desc && (typeof desc === 'string' ? <p>{desc}</p> : desc)}
      {lineBreak && <hr />}
      {fields &&
        fields.map(({ type, columns = [], ...fieldProps }, index) => {
          return FieldGenerator({
            type,
            columns,
            index,
            ...fieldProps,
          });
        })}
    </section>
  );
};

export default SectionGenerator;
