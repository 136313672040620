import React from 'react';
import VehiclesAgencyFilterItem from '../custom-filters/vehicles-agency-filter-item';
import VehiclesBureauFilterItem from '../custom-filters/vehicles-bureau-filter-item';
import VehiclesOfficeFilterItem from '../custom-filters/vehicles-office-filter-item';
import VehiclesMakeFilterItem from '../custom-filters/vehicle-make-filter-item';
import VehiclesModelFilterItem from '../custom-filters/vehicle-model-filter-item';
import { vehicleFilterKeys } from './constants';
import CustomerAccountFilterItem from '../custom-filters/customer-account-filter-item';
import VehiclesZoneFilterItem from '../custom-filters/vehicles-zone-filter-item';
import VehiclesFmcFilterItem from '../custom-filters/vehicles-fmc-filter-item';
import VehiclesLifecycleFilterItem from '../custom-filters/vehicles-lifecycle-filter-item';
import VehiclesStatusFilterItem from '../custom-filters/vehicles-status-filter-item';
import VehiclesModelYearFilterItem from '../custom-filters/vehicle-model-year-filter-item';

const {
  FSR_USER_EMAIL,
  TAG_NUMBER,
  POC_EMAIL,
  MAKE_AND_MODEL,
  MAKE_CODE,
  MODEL_CODE,
  MODEL_YEAR,
  VIN_TYPEAHEAD,
  VIN_FILTER,
  VEHICLE_STATUS,
  LIFECYCLE,
  FMC,
  VEHICLE_LOCATION,
  ZONE,
  REGISTRATION_STATUS,
  OWNERSHIP_TYPE_CODE,
  AGENCY_FILTER,
  BUREAU_FILTER,
  OFFICE_FILTER,
  FMC_ZONE_FSR,
  VEHICLE_LIFECYCLE_IND_AND_STATUS,
  AGENCY_AND_BUREAU_AND_OFFICE,
  LEGACY_CUSTOMER_FILTER,
  CUSTOMER_ACCOUNT,
} = vehicleFilterKeys;

const typeaheadOpts = {
  type: 'typeahead',
  placeholder: 'All',
  value: '',
  permanent: false,
  operator: '$like',
};

const typeaheadOpts2 = {
  type: 'typeahead',
  placeholder: 'All',
  value: '',
  permanent: false,
  operator: 'LIKE',
};

const vehicle = {
  vehicleFSR: {
    title: 'FSR',
    key: FSR_USER_EMAIL,
    ariaLabel: 'Search FSR email',
    ...typeaheadOpts,
    placeholder: 'sample@gsa.gov',
  },
  vehicleLicensePlateTypeahead: {
    title: 'License Plate',
    key: TAG_NUMBER,
    ariaLabel: 'Search License Plate',
    ...typeaheadOpts,
  },
  vehiclePoCTypeahead: {
    title: 'Point of Contact',
    key: POC_EMAIL,
    ariaLabel: 'Search Point of Contact',
    ...typeaheadOpts,
    placeholder: 'sample@email.gov',
  },
  customerAccount: {
    title: 'Customer account',
    key: CUSTOMER_ACCOUNT,
    position: 'top',
    filters: [
      {
        title: 'VIN',
        key: LEGACY_CUSTOMER_FILTER,
        type: 'text',
        permanent: false,
        operator: 'STARTWITH',
        hideClear: false,
        position: 'top',
        label: 'Legacy customer number',
        showSearchButton: true,
      },
    ],
  },
  vehicleMakeModel: {
    title: 'Vehicle',
    key: MAKE_AND_MODEL,
    filters: [
      {
        key: MAKE_CODE,
        title: 'Make',
        component: VehiclesMakeFilterItem,
        options: [{ value: '', label: '-- Select make --' }],
        permanent: false,
        operator: 'EQ',
        value: '',
      },
      {
        key: MODEL_CODE,
        title: 'Model',
        component: VehiclesModelFilterItem,
        options: [{ value: '', label: '-- Select model --' }],
        permanent: false,
        operator: 'EQ',
        value: '',
      },
    ],
  },
  vehicleModelYear: {
    title: 'Year',
    key: MODEL_YEAR,
    type: 'select',
    hideClear: true,
    permanent: false,
    operator: '$exact',
    options: [{ value: '', label: 'Year' }],
    value: '',
  },
  vehicleVINTypeahead: {
    title: 'VIN',
    key: VIN_TYPEAHEAD,
    position: 'top',
    expanded: true,
    filters: [
      {
        title: 'VIN',
        key: VIN_FILTER,
        type: 'text',
        permanent: false,
        operator: '$startsWith',
        hideClear: false,
        position: 'top',
        label: 'Search by VIN',
      },
    ],
  },
  registrationStaus: {
    title: 'Registration status',
    key: REGISTRATION_STATUS,
    type: 'multiselect',
    value: [],
    options: [
      { value: '$isNotBlank', label: 'Registered' },
      { value: '$isBlank', label: 'Unregistered' },
    ],
    permanent: false,
    operator: ({ value }) => {
      // both values checked is the same query as no values checked
      return value && value.length === 1 ? value[0] : undefined;
    },
    hideClear: true,
  },
  vehicleOwnership: {
    title: 'Ownership',
    key: OWNERSHIP_TYPE_CODE,
    type: 'multiselect',
    operator: '$exact',
    value: [],
    hideClear: true,
    options: [
      { value: 'GF', label: 'GSA Leased' },
      { value: 'AO', label: 'Agency Owned' },
    ],
  },
  agency: {
    title: 'Agency',
    key: AGENCY_AND_BUREAU_AND_OFFICE,
    filters: [
      {
        key: AGENCY_FILTER,
        title: 'Agency',
        component: VehiclesAgencyFilterItem,
        permanent: false,
        operator: '$exact',
        value: '',
      },
      {
        key: BUREAU_FILTER,
        title: 'Bureau',
        component: VehiclesBureauFilterItem,
        permanent: false,
        operator: 'IN',
      },
      {
        key: OFFICE_FILTER,
        title: 'Office',
        component: VehiclesOfficeFilterItem,
        permanent: false,
        operator: '$exact',
        value: '',
      },
    ],
  },
  bureau: {
    title: 'Bureau',
    key: BUREAU_FILTER,
    component: VehiclesBureauFilterItem,
    permanent: false,
    operator: 'IN',
  },
  fmc: {
    title: 'FMC',
    key: FMC_ZONE_FSR,
    filters: [
      {
        key: ZONE,
        title: 'Zone',
        component: () => <>Zone Component</>,
        placeholder: '- Select zone -',
        permanent: false,
        operator: 'EQ',
        value: '',
      },
      {
        key: FMC,
        title: 'FMC',
        component: () => <>FMC Component</>,
        placeholder: '- Select FMC -',
        noResults: 'No bureaus found',
        permanent: false,
        operator: 'EQ',
        value: '',
      },
      {
        title: 'FSR',
        key: FSR_USER_EMAIL,
        ariaLabel: 'Search FSR email',
        ...typeaheadOpts2,
        placeholder: 'sample@gsa.gov',
      },
    ],
  },
  vehicleLicenseLocation: {
    title: 'Vehicle Location',
    key: VEHICLE_LOCATION,
    expanded: true,
    ariaLabel: 'Search vehicle location',
    ...typeaheadOpts2,
  },
  vehicleLifecycleStatus: {
    title: 'Vehicle lifecycle',
    key: LIFECYCLE,
    type: 'radio',
    value: [],
    options: [
      { value: 'Delivered', label: 'delivered' },
      { value: 'Active', label: 'active' },
      { value: 'Missing/Stolen', label: 'Missing/Stolen' },
      { value: 'Disposed', label: 'Disposed' },
    ],
    permanent: false,
    operator: 'LIFECYCLE',
    hideClear: true,
  },
  vehicleLifecycleAndStatus: {
    key: 'vehicleLifecycleAndStatus',
    title: 'Vehicle status',
    filters: [
      {
        title: 'Vehicle lifecycle',
        key: LIFECYCLE,
        type: 'radio',
        value: [],
        options: [
          { value: 'Delivered', label: 'delivered' },
          { value: 'Active', label: 'active' },
          { value: 'Missing/Stolen', label: 'Missing/Stolen' },
          { value: 'Disposed', label: 'Disposed' },
        ],
        permanent: false,
        operator: 'LIFECYCLE',
        hideClear: true,
      },
      {
        title: 'Vehicle status',
        key: VEHICLE_STATUS,
        component: () => <>Vehicle status component</>,
        placeholder: '- Select vehicle status -',
        noResults: 'No vehicle status found',
        permanent: false,
        operator: 'IN',
      },
    ],
  },
};

export const vehicleWithNestedFilters = {
  vehicleLicensePlateTypeahead: {
    title: 'License Plate',
    key: TAG_NUMBER,
    expanded: true,
    ariaLabel: 'Search License Plate',
    ...typeaheadOpts2,
  },
  vehiclePoCTypeahead: {
    title: 'Point of Contact',
    key: POC_EMAIL,
    ariaLabel: 'Search Point of Contact',
    ...typeaheadOpts2,
    placeholder: 'sample@email.gov',
  },
  vehicleMakeModel: {
    title: 'Vehicle',
    key: MAKE_AND_MODEL,
    filters: [
      {
        title: 'Year',
        key: MODEL_YEAR,
        component: VehiclesModelYearFilterItem,
        options: [{ value: '', label: '-- Select year --' }],
        permanent: false,
        operator: 'EQ',
        value: '',
      },
      {
        key: MAKE_CODE,
        title: 'Make',
        component: VehiclesMakeFilterItem,
        options: [{ value: '', label: '-- Select make --' }],
        permanent: false,
        operator: 'EQ',
        value: '',
      },
      {
        key: MODEL_CODE,
        title: 'Model',
        component: VehiclesModelFilterItem,
        options: [{ value: '', label: '-- Select model --' }],
        permanent: false,
        operator: 'EQ',
        value: '',
      },
    ],
  },

  customerAccount: {
    title: 'Customer account',
    key: CUSTOMER_ACCOUNT,
    position: 'top',
    filters: [
      {
        title: 'VIN',
        key: LEGACY_CUSTOMER_FILTER,
        component: CustomerAccountFilterItem,
        permanent: false,
        operator: 'STARTWITH',
        hideClear: false,
        position: 'top',
        label: 'Legacy customer number',
        showSearchButton: true,
      },
    ],
  },
  vehicleVINTypeahead: {
    title: 'VIN',
    key: VIN_TYPEAHEAD,
    position: 'top',
    expanded: true,
    filters: [
      {
        title: 'VIN',
        key: VIN_FILTER,
        type: 'text',
        permanent: false,
        operator: 'STARTWITH',
        hideClear: false,
        position: 'top',
        label: 'Search by VIN',
        showSearchButton: true,
        minSearchLength: 6,
      },
    ],
  },
  registrationStaus: {
    title: 'Registration status',
    key: REGISTRATION_STATUS,
    type: 'radio',
    value: [],
    options: [
      { value: 'Registered', label: 'Registered' },
      { value: 'Incomplete', label: 'Registered - Incomplete' },
      { value: 'Exempt', label: 'Exempt' },
      { value: 'Unregistered', label: 'Unregistered' },
    ],
    permanent: false,
    operator: 'REGISTRATIONSTATUS',
    hideClear: true,
  },
  vehicleOwnership: {
    title: 'Ownership',
    key: OWNERSHIP_TYPE_CODE,
    type: 'multiselect',
    operator: 'IN',
    value: [],
    hideClear: true,
    options: [
      { value: 'GF', label: 'GSA Leased' },
      { value: 'AO', label: 'Agency Owned' },
    ],
  },
  agency: {
    title: 'Agency',
    key: AGENCY_AND_BUREAU_AND_OFFICE,
    filters: [
      {
        key: AGENCY_FILTER,
        title: 'Agency',
        component: VehiclesAgencyFilterItem,
        permanent: false,
        operator: 'EQ',
        value: '',
      },
      {
        key: BUREAU_FILTER,
        title: 'Bureau',
        component: VehiclesBureauFilterItem,
        placeholder: '- Select bureau -',
        noResults: 'No bureaus found',
        permanent: false,
        operator: 'IN',
      },
      {
        key: OFFICE_FILTER,
        title: 'Office',
        component: VehiclesOfficeFilterItem,
        placeholder: '- Select office -',
        noResults: 'No offices found',
        permanent: false,
        operator: 'IN',
      },
    ],
  },
  fsr: {
    title: 'FSR',
    key: FSR_USER_EMAIL,
    ariaLabel: 'Search FSR email',
    ...typeaheadOpts2,
    placeholder: 'Search FSR email',
  },
  fmc: {
    title: 'FMC',
    key: FMC_ZONE_FSR,
    filters: [
      {
        key: ZONE,
        title: 'Zone',
        component: VehiclesZoneFilterItem,
        placeholder: '- Select zone -',
        permanent: false,
        operator: 'EQ',
        value: '',
      },
      {
        key: FMC,
        title: 'FMC',
        component: VehiclesFmcFilterItem,
        placeholder: '- Select FMC -',
        noResults: 'No bureaus found',
        permanent: false,
        operator: 'EQ',
        value: '',
      },
      {
        title: 'FSR',
        key: FSR_USER_EMAIL,
        ariaLabel: 'Search FSR email',
        ...typeaheadOpts2,
        placeholder: 'Search FSR email',
      },
    ],
  },
  vehicleLicenseLocation: {
    title: 'Vehicle Location',
    key: VEHICLE_LOCATION,
    expanded: false,
    ariaLabel: 'Search vendor name',
    ...typeaheadOpts2,
    placeholder: 'Search vendor name',
  },
  vehicleLifecycleStatus: {
    title: 'Vehicle status',
    key: LIFECYCLE,
    component: VehiclesLifecycleFilterItem,
    permanent: false,
    operator: 'EQ',
    hideClear: true,
    value: '',
  },
  vehicleLifecycleAndStatus: {
    key: VEHICLE_LIFECYCLE_IND_AND_STATUS,
    title: 'Vehicle status',
    filters: [
      {
        title: 'Vehicle lifecycle',
        key: LIFECYCLE,
        component: VehiclesLifecycleFilterItem,
        permanent: false,
        operator: 'EQ',
        hideClear: true,
        value: '',
      },
      {
        title: 'Vehicle status',
        key: VEHICLE_STATUS,
        component: VehiclesStatusFilterItem,
        placeholder: '- Select vehicle status -',
        noResults: 'No vehicle status found',
        permanent: false,
        operator: 'IN',
      },
    ],
  },
};

export default vehicle;
