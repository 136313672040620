import moment from 'moment';

export const getAverageMonthlyMiles = (
  assetAcquisition,
  newOdometerReading,
) => {
  try {
    const { deliveredMiles, originalAcquisitionDate } = assetAcquisition;
    const { odometer, mileageDate } = newOdometerReading;

    const totalMiles = odometer - deliveredMiles;
    const totalMonth =
      moment(mileageDate.substr(0, 7), 'YYYY-MM').diff(
        moment(originalAcquisitionDate.substr(0, 7), 'YYYY-MM'),
        'months',
      ) + 1;

    if (totalMiles <= 0 || totalMonth <= 0 || isNaN(totalMiles) || isNaN(totalMonth)) {
      return undefined;
    }
    return Math.round(totalMiles / totalMonth);
  } catch {
    return undefined;
  }
};
