// eslint-disable-next-line filenames/match-exported, import/prefer-default-export
export const expenseMapping = [
  ['VIN', 'vin', 'string'],
  ['License Plate', 'tagNumber', 'string'],
  ['Last 4 of Card', 'ccn4', 'string'],
  ['Last 4 of Account Number', 'acctn4', 'string'],
  ['Transaction Date', 'transactionDate', 'string'],
  ['Transaction Odometer', 'odometerReading', 'string'],
  ['Product Description', 'productName', 'string'],
  ['Unit of Measure', 'unitOfMeasure', 'string'],
  ['Unit Cost', 'unitCost', 'string'],
  ['Quantity Purchased', 'unitsBought', 'string'],
  ['Net Dollars', 'netCost', 'string'],
  ['Tax Amount', 'taxAmount', 'string'],
  ['Total Cost', 'grossCost', 'string'],
  ['Merchant Name', 'merchantName', 'string'],
  ['Merchant Address Line 1', 'merchantAddress', 'string'],
  ['Merchant Address Line 2', 'merchantAddress2', 'string'],
  ['Merchant Address Line 3', 'merchantAddress3', 'string'],
  ['Merchant City', 'merchantCity', 'string'],
  ['Merchant State', 'merchantStateCode', 'string'],
  ['Merchant Zip Code', 'merchantPostalCode', 'string'],
  ['Merchant Phone', 'merchantPhone', 'string'],
  ['Merchant Extension', 'merchantPhoneExt', 'string'],
  ['Comments', 'comment', 'string'],
];
