/* eslint-disable react/prop-types */
import React from 'react';
import {
  DatePicker,
  RequiredFieldIndicator,
  ErrorMessage,
} from '@gsa/afp-component-library';
import classnames from 'classnames';
import { useRecoilState } from 'recoil';
import { cloneDeep } from 'lodash';
import { USDateStrToUTCStr } from '../../../utilities/format';
import moment from 'moment';

const DateRangeField = ({
  selectors,
  pageId = 'DEFAULT',
  id,
  label,
  required,
  fromProps,
  toProps,
}) => {
  const { fieldSelector } = selectors;
  const [fieldValueState, setFieldValueState] = useRecoilState(
    fieldSelector({ pageId, id }),
  );

  return (
    <div
      className={classnames(fieldValueState.errorMessage && 'margin-left-205')}
    >
      <div
        className={`grid-col-8 minh-15 ${classnames(
          fieldValueState.errorMessage &&
            'border-left-05 error-border-color padding-left-2',
        )}`}
      >
        {label && (
          <label>
            {label} {required && <RequiredFieldIndicator />}
          </label>
        )}
        {fieldValueState.errorMessage && (
          <ErrorMessage>{fieldValueState.errorMessage}</ErrorMessage>
        )}
        <div className="grid-row grid-gap">
          <div className="grid-col-6">
            <DatePicker
              id={fromProps?.id}
              name={fromProps?.id}
              data-testid={fromProps?.id}
              label={
                <span>
                  {fromProps?.label || 'From date'}{' '}
                  {fromProps?.required && <RequiredFieldIndicator />}
                </span>
              }
              defaultValue={fieldValueState.value?.fromDate}
              maxDate={
                fieldValueState.value?.toDate || moment().format('YYYY-MM-DD')
              }
              hint="mm/dd/yyyy"
              onChange={(val) => {
                const newState = cloneDeep(fieldValueState);
                newState.value.fromDate = USDateStrToUTCStr(val);
                setFieldValueState(newState);
              }}
              aria-invalid={fieldValueState.errorMessage ? 'true' : 'false'}
            />
          </div>
          <div className="grid-col-6">
            <DatePicker
              id={toProps?.id}
              name={toProps?.id}
              data-testid={toProps?.id}
              label={
                <span>
                  {toProps?.label || 'To date'}{' '}
                  {toProps?.required && <RequiredFieldIndicator />}
                </span>
              }
              defaultValue={fieldValueState.value?.toDate}
              minDate={fieldValueState.value?.fromDate}
              maxDate={moment().format('YYYY-MM-DD')}
              hint="mm/dd/yyyy"
              onChange={(val) => {
                const newState = cloneDeep(fieldValueState);
                newState.value.toDate = USDateStrToUTCStr(val);
                setFieldValueState(newState);
              }}
              aria-invalid={fieldValueState.errorMessage ? 'true' : 'false'}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DateRangeField;
