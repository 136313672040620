import React from 'react';
import VehicleActivitiesProvider from './vehicle-activities-provider';
import VehicleActivitiesFilterProvider from './filters/filter-provider';
import VehicleActivitiesListing from './vehicle-activities-listing';
import VehicleActivitiesFilterBar from './vehicle-activities-filter-bar';
import { useVehicle } from '../../vehicle-context-provider';

const VehicleActivities = () => {
  const { vehicle } = useVehicle();
  if (!vehicle) return null;

  return (
    <VehicleActivitiesProvider>
      <VehicleActivitiesFilterProvider>
        <VehicleActivitiesFilterBar />
        <VehicleActivitiesListing />
      </VehicleActivitiesFilterProvider>
    </VehicleActivitiesProvider>
  );
};

export default VehicleActivities;
