import React from 'react';

import { Button } from '@gsa/afp-component-library';
import { useCreateOrders } from './create-orders-provider';

const currentModalName = 'CREATE_ORDERS_MODAL';

const CreateOrdersButtons = () => {
  const { ordersList1, ordersList2, ordersList3, ordersList4, dispatchAction } =
    useCreateOrders();

  const validateOrdersList = () => {
    const totalOrdersQuantity = [
      ...ordersList1,
      ...ordersList2,
      ...ordersList3,
      ...ordersList4,
    ].reduce((acc, order) => {
      return acc + Number(order.quantity);
    }, 0);

    if (totalOrdersQuantity === 0) {
      dispatchAction('SET_ALERT_MESSAGE', {
        type: 'error',
        message: `Quantity cannot be 0.`,
        location: 'header',
      });
    } else {
      dispatchAction('SET_MODAL', {
        currentModalName,
        currentMode: 'ConfirmSubmitOrders',
      });
    }
  };
  return (
    <>
      <Button
        variant="unstyled"
        className="margin-right-2"
        onClick={validateOrdersList}
        data-testid="create-orders-cancel-button"
        label="Cancel"
      />
      <Button
        variant="primary"
        type="submit"
        onClick={validateOrdersList}
        data-testid="create-orders-submit-button"
        label="Submit"
      />
    </>
  );
};

export default CreateOrdersButtons;
