import React from 'react';
import { Controller } from 'react-hook-form';
import {
  RadioButton,
  RequiredFieldIndicator,
} from '@gsa/afp-component-library';
import { FOREIGN_DOMESTIC_OPTIONS } from '../config/helpers';

const VehicleForeignDomestic = ({ control }) => {
  return (
    <>
      <div className="usa-form-group mobile-lg:grid-col-10 margin-top-6">
        <span>
          Choose the garage foreign/domestic option that you would like to
          include in your report.
        </span>

        <div className="margin-top-3">
          <b>Vehicle garage foreign/domestic</b> <RequiredFieldIndicator />
        </div>
        <Controller
          control={control}
          name="foreign_domestic"
          defaultValue={FOREIGN_DOMESTIC_OPTIONS[0].radioValue}
          rules={{ required: 'This is a required field' }}
          render={({ field: { onChange, name, value } }) => {
            return (
              <>
                {FOREIGN_DOMESTIC_OPTIONS.map(({ label, radioValue }) => (
                  <RadioButton
                    name={name}
                    key={label}
                    value={radioValue}
                    checked={radioValue === value}
                    label={<p>{label}</p>}
                    onChange={(e) => {
                      onChange(e.target.value);
                    }}
                  />
                ))}
              </>
            );
          }}
        />
      </div>
    </>
  );
};

export default VehicleForeignDomestic;
