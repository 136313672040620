import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { AFPTable } from '@gsa/afp-component-library';
import ReactHtmlParser from 'react-html-parser';
import { GET_MODIFICATION_REQUESTS_QUERY } from 'services/data-layer/modifications';
import TableNoData from '../../../../utilities/table-no-data';
import { useVehicle } from '../../vehicle-context-provider';
import useQueryParams from '../hooks/use-query-params';
import { getModStatusBadge, utcDateStrToUS } from './helpers';
import {
  AddModificationButton,
  HyperlinkedRequestNumber,
  ModificationRowActions,
} from './modification-actions';

const VehicleModification = () => {
  const [sortOrder, setSortOrder] = useState([['created_at', 'DESC']]);
  const params = useQueryParams();

  const dataRef = params.get('ref');

  const { setPageMsg, vehicle } = useVehicle();

  const [getModificationRequestsQuery, { data, loading }] = useLazyQuery(
    GET_MODIFICATION_REQUESTS_QUERY,
  );

  const getModificationRequests = useCallback(
    (order) => {
      const queryVariables = {
        order,
        filters: [
          {
            operator: '$and',
            conditions: [
              { operator: '$exact', key: 'assetId', value: vehicle.uuid },
            ],
          },
        ],
      };
      getModificationRequestsQuery({ variables: queryVariables });
    },
    [getModificationRequestsQuery],
  );

  useEffect(() => {
    getModificationRequests(sortOrder);
  }, [sortOrder]);

  const tableData = useMemo(() => {
    if (loading) return [];
    const modificationRequestsRows = data?.getModificationRequests?.rows;
    let newTableData = [];
    if (modificationRequestsRows) {
      newTableData = modificationRequestsRows?.map((row) => ({
        id: row.id,
        requestNumber: row.modificationNumber,
        title: row.modificationTitle,
        requestDate: row.modificationDate,
        isCapitalized: row.isCapitalized,
        status: row.modificationStatus,
        attachments: row.attachments,
      }));
    }
    return newTableData;
  }, [loading, data]);

  // Display success banner after page redirect
  useEffect(() => {
    if (!dataRef) return;
    try {
      const messageObject = JSON.parse(window.atob(dataRef));
      if (messageObject.type && messageObject.message)
        setPageMsg({
          ...messageObject,
          message: ReactHtmlParser(messageObject.message),
        });
    } catch (error) {
      setPageMsg({
        type: 'error',
        message: <>Error parsing success message</>,
      });
    }
  }, [dataRef]);

  const tableColumns = [
    {
      Header: <>Request number</>,
      accessor: 'requestNumber',
      Cell: ({ row: { original } }) => HyperlinkedRequestNumber(original),
    },
    {
      Header: 'Title',
      accessor: 'title',
      Cell: ({ value }) => value,
    },
    {
      Header: 'Date',
      accessor: 'requestDate',
      Cell: ({ value }) => utcDateStrToUS(value),
    },
    {
      Header: 'Capitalized',
      accessor: 'isCapitalized',
      Cell: ({ value }) => (value ? 'Yes' : 'No'),
    },
    {
      Header: 'Status',
      accessor: 'status',
      sortable: false,
      Cell: ({ value }) => getModStatusBadge(value),
    },
    {
      Header: 'Actions',
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original } }) => (
        <ModificationRowActions request={original} />
      ),
      sortable: false,
    },
  ];

  const handleTableSort = (orderPreference) => {
    const parts = orderPreference.split(' ');
    const sortColumnName = parts[0].trim().replace(/`/g, '');
    const sortIndicator = parts[1];

    switch (sortColumnName) {
      case 'dateCreated':
        return setSortOrder([['created_at', sortIndicator]]);

      case 'isCapitalized':
        return setSortOrder([['is_capitalized', sortIndicator]]);

      case 'title':
        return setSortOrder([['modification_title', sortIndicator]]);

      case 'requestNumber':
        return setSortOrder([['modification_number', sortIndicator]]);

      case 'requestDate':
        return setSortOrder([['created_at', sortIndicator]]);

      default:
        return setSortOrder([['created_at', 'DESC']]);
    }
  };

  return (
    <div>
      <div className="float-right">
        <AddModificationButton />
      </div>
      <AFPTable
        columns={tableColumns}
        data={loading ? [] : tableData}
        testId="vehicle-modification-table"
        defaultSort={sortOrder}
        fullWidth
        scrollable={false}
        onSort={handleTableSort}
      />
      <TableNoData
        loading={loading}
        hasNoData={!tableData?.length}
        bottomText="No modification data"
      />
    </div>
  );
};

export default VehicleModification;
