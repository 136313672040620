/* eslint-disable react/prop-types */
import React from 'react';
import { Button } from '@gsa/afp-component-library';
import { useHistory } from 'react-router-dom';

const RecallCampaignsActions = ({ canManageRecallCampaigns }) => {
  const history = useHistory();
  return (
    canManageRecallCampaigns && (
      <div className="display-flex flex-justify-end">
        <Button
          data-testid="create-recall-campaign-button"
          variant="primary"
          onClick={() => {
            history.replace('/recalls/campaign/create');
          }}
          leftIcon={{ name: 'add' }}
          label="Create recall campaign"
        />
      </div>
    )
  );
};

export default RecallCampaignsActions;
