export const FLAG_TYPE = {
  add: 'add',
  change: 'change',
  delete: 'delete',
  noChange: 'noChange',
};

export const getInvoiceModificationFlag = (originalName, currentFileState) => {
  if (!originalName) {
    if (currentFileState) {
      return FLAG_TYPE.add;
    }
  } else {
    // edit
    if (!currentFileState) {
      return FLAG_TYPE.delete;
    }
    if (currentFileState?.size) {
      return FLAG_TYPE.change;
    }
  }

  return FLAG_TYPE.noChange;
};

export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = () => {
      reject(reader.error);
    };

    reader.readAsDataURL(file);
  });
}

export const joinStringArrayExceptLast = (arr, separator = ', ') => {
  if (!Array.isArray(arr)) {
    throw new Error('Input must be an array.');
  }

  if (arr.length === 0) {
    return '';
  }

  // Join all elements except the last one
  const joinedString = arr.slice(0, -1).join(separator);

  // Concatenate the last element without the separator
  return joinedString + (arr.length > 1 ? separator : '') + arr[arr.length - 1];
};

const EUROPE_VEHICLE_FMC_ID = '24';

export const isEuVehicle = (fmcId) => fmcId === EUROPE_VEHICLE_FMC_ID;

export const getPmDistanceByLocation = (
  fmcId,
  pmMilesInterval,
  pmKilometersInterval,
) => {
  if (!pmMilesInterval && !pmKilometersInterval) {
    return '';
  }
  if (isEuVehicle(fmcId)) {
    return pmMilesInterval
      ? `${pmMilesInterval?.toLocaleString()} mi`
      : `${pmKilometersInterval?.toLocaleString()} km`;
  }
  return `${pmMilesInterval?.toLocaleString()} mi`;
};
