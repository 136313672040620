/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  AFPTable,
  EmptyState,
  Pagination,
  Spinner,
} from '@gsa/afp-component-library';
import {
  filterOptionMap,
  getData,
  getSelectedRows,
  getDisabledRows,
} from '../utils';

const columns = [
  {
    Header: 'Account',
    accessor: 'accountName',
    sortable: true,
    Cell: ({ value }) => value,
  },
  {
    Header: 'POC',
    sortable: true,
    accessor: 'primaryContactAssociation.pointOfContact.pocEmailAddress',
    Cell: ({ value, row }) => {
      return (
        <>
          {row?.original?.primaryContactAssociation?.pointOfContact
            ? value
            : null}
        </>
      );
    },
  },
  {
    Header: 'FSR',
    accessor: 'fsrUserEmail',
    sortable: true,
    Cell: ({ value }) => <>{value}</>,
  },
];

const args = {
  testId: 'add-customer-account-table',
  fullWidth: true,
  bordered: false,
  expandable: false,
  selectable: true,
  selectAllHeader: '',
  columns,
  boldLastRow: false,
};

const ResultTable = ({
  providerContext: {
    selectedOrganization,
    getModalFilterCustomerAccounts,
    modalFilterCustomerAccountsList,
    modalFilterCustomerAccountsLoading,
  },
  needToReviewRowsState,
  setNeedToReviewRowsState,
  currentFiltersStatus,
  paginationState,
  setPaginationState,
  order,
  setShowFinalAlertBox,
}) => {
  //   create to object for o(1) checking time
  // !row 0 index  is 'x', for if check
  const indexRowsCurrentPageObj = modalFilterCustomerAccountsList?.rows.reduce(
    (acc, cur, ind) => {
      if (cur?.customerId && !acc[cur.customerId])
        acc[cur.customerId] = ind === 0 ? 'x' : ind;
      return acc;
    },
    {},
  );

  // for rerender issue
  const [onSelectSelected, setOnselectSelected] = useState();

  useEffect(() => {
    getData({
      getModalFilterCustomerAccounts,
      paginationState,
      currentFiltersStatus,
      filterOptionMap,
      selectedOrganization,
      order,
    });
    setOnselectSelected(false);
    setShowFinalAlertBox(false);
  }, [paginationState, order, currentFiltersStatus]);

  return (
    <>
      <div className="display-flex flex-justify margin-top-2">
        <div className="text-primary">Results</div>
        <div className="text-bold">
          {needToReviewRowsState?.length} account(s) selected
        </div>
      </div>
      <AFPTable
        data={
          !modalFilterCustomerAccountsLoading
            ? modalFilterCustomerAccountsList?.rows || []
            : []
        }
        selectedRows={getSelectedRows(
          needToReviewRowsState,
          indexRowsCurrentPageObj,
        )}
        selectDisabledRows={getDisabledRows(modalFilterCustomerAccountsList)}
        onRowSelect={({ selectedFlatRows }) => {
          if (!selectedFlatRows?.length && !onSelectSelected) return;
          const otherRows = needToReviewRowsState.filter(
            (row) => !indexRowsCurrentPageObj[row.customerId],
          );
          const updatedRows = [
            ...selectedFlatRows.map(({ original }) => original),
            ...otherRows,
          ];
          setNeedToReviewRowsState(updatedRows);
          // for rerender issue also need to turn it to false  on pagination useEffect
          setOnselectSelected(true);
          setShowFinalAlertBox(false);
        }}
        {...args}
      />

      {modalFilterCustomerAccountsLoading && (
        <Spinner className="padding-y-9" />
      )}
      {modalFilterCustomerAccountsList?.rows?.length > 0 && (
        <Pagination
          fullWidth
          variant="advanced"
          itemsCount={modalFilterCustomerAccountsList.count}
          itemsPerPage={paginationState.limit}
          currentPage={paginationState.currentPage}
          onPageChange={(currentPage, itemsPerPage) => {
            const offset = (currentPage - 1) * itemsPerPage;
            setPaginationState({
              limit: itemsPerPage,
              offset,
              currentPage,
            });
          }}
          isReset={paginationState.isReset}
        />
      )}
      {(!modalFilterCustomerAccountsList ||
        modalFilterCustomerAccountsList.rows.length === 0) &&
        !modalFilterCustomerAccountsLoading && (
          <div className="bg-gray-3 padding-y-5">
            <div className="text-center padding-y-4">
              <EmptyState alt="Image not available" hasBackground />
            </div>
            <div className="text-center text-bold">
              No Customer Accounts available
            </div>
          </div>
        )}
    </>
  );
};

export default ResultTable;
