import React, { useState, createContext, useContext } from 'react';
import { useMutation } from '@apollo/client';
import { SAP_UPLOAD } from '../../services/data-layer';

const SELECT_FILE_MESSAGE = 'Please select a file';
const INVALID_FILE_MESSAGE = 'Please remove invalid files to upload';

export const UploadComponentContext = createContext();

export const handleUploadFileFn =
  ({
    clearErrors,
    file,
    setUploadErrors,
    setAddUploadState,
    handleDocUpload,
    onClose,
  }) =>
  () => {
    clearErrors();
    if (file && file.length === 0) {
      setUploadErrors({
        save: {
          message: SELECT_FILE_MESSAGE,
        },
      });
      return;
    }
    setAddUploadState((prev) => ({ ...prev, adding: true, show: true }));
    handleDocUpload(file, file?.name, onClose);
  };

export default function UploadContext({
  children,
  title,
  uploadPath,
  fileTypes,
  maxFileSize,
  setUploadMessage,
}) {
  const [isUpload, setIsUpload] = useState(false);
  const [uploadError, setUploadError] = useState([]); // file format errors
  const [isUploading, setIsUploading] = useState(false);
  const [file, setFile] = useState([]);
  const [submitError, setSubmitError] = useState(); // upload errors
  const [sapUpload] = useMutation(SAP_UPLOAD, {
    onError: () => {},
    onCompleted: (sapUploadData) => {
      if (sapUploadData) {
        setIsUploading(false);
      }
    },
  });

  function getBase64(files, cb) {
    let result = [];
    for (const file of files) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        result.push(reader.result);
        if (files.length === result.length) {
          cb(result);
        }
      };
    }
  }

  const noInvalidError = () => {
    let valid = false;
    Object.values(uploadError).forEach((it) => {
      it && (valid = true);
    });
    return valid;
  };

  const handleDocUpload = async (files, onClose) => {
    try {
      getBase64(files, async (result) => {
        let fileNames = files.map((file) => {
          return file.name;
        });
        const response = await sapUpload({
          variables: {
            file: result,
            filename: fileNames,
          },
        });

        if (response.errors) {
          setSubmitError(response.errors.message);
          setIsUploading(false);
          setFile([]);
        } else {
          setIsUploading(false);
          setSubmitError();
          setFile([]);
          onClose();
          setUploadMessage(
            'The files have been uploaded and an email will be generated once the files has been processed.',
          );
        }
      });
    } catch (error) {
      setIsUploading(false);
      setSubmitError('Problem uploading attachment');
    }
  };

  const handleUploadFile = (onClose) => {
    setSubmitError();
    if (file.length === 0) {
      setSubmitError(SELECT_FILE_MESSAGE);
      return;
    }
    if (noInvalidError()) {
      setSubmitError(INVALID_FILE_MESSAGE);
      return;
    }
    setIsUploading(true);

    void handleDocUpload(file, onClose);
  };

  return (
    <UploadComponentContext.Provider
      value={{
        title,
        uploadPath,
        fileTypes,
        maxFileSize,
        uploadError,
        submitError,
        setSubmitError,
        setUploadError,
        file,
        setFile,
        handleUploadFile,
        isUploading,
        setIsUploading,
        isUpload,
        setIsUpload,
        setUploadMessage,
      }}
    >
      {children}
    </UploadComponentContext.Provider>
  );
}

export const useUploadContext = () => useContext(UploadComponentContext);
