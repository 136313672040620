import React from 'react';
import { OptIn } from '@gsa/afp-shared-ui-utils';
import { useVehicle } from '../../vehicle-context-provider';
import { isMileageEnabled } from '../../helpers/feature-helper';
import ExpenseDisplayPage from './pages/display';

const VehicleExpenses = () => {
  const { vehicle } = useVehicle();
  if (!vehicle) return null;
  const { agencyCode, bureauCode, ownershipTypeCode } = vehicle;

  return (
    <OptIn
      agencyCode={agencyCode}
      bureauCode={bureauCode}
      isHidden={!isMileageEnabled(agencyCode, ownershipTypeCode)}
    >
      <ExpenseDisplayPage />
    </OptIn>
  );
};

export default VehicleExpenses;
