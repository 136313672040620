import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useHistory, Link } from 'react-router-dom';
import {
  DetailsTable,
  Spinner,
  Button,
  Alert,
  useModal,
} from '@gsa/afp-component-library';
import moment from 'moment';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import { VMSOperations, VMSSubjects } from 'utilities/consts';
import { formatCurrency } from 'utilities/common';
import { GET_ALD, GET_AL_CATEGORY } from 'services/data-layer';
import ServerErrorMessage from '../../../error-handlers/server-error-message';
import { useVehicle } from '../../vehicle-context-provider';
import {
  populateBoolReadonly,
  populateValueByAldCode,
} from '../../helpers/utils';
import CDDPopOverModal from './cdd-pop-over-modal';

const CustomerDrivenData = () => {
  const history = useHistory();
  const { vehicle } = useVehicle();
  const [ald, setAld] = useState(null);
  const [aldOptions, setAldOptions] = useState(null);
  const { isOpen, openModal, closeModal } = useModal();
  const [modalType, setModalType] = useState(null);

  const ability = useAppAbility();
  const canEdit =
    vehicle.ownershipTypeCode === 'GF'
      ? ability.can(VMSOperations.UPDATE_VEHICLE_GF, VMSSubjects.VEHICLE)
      : ability.can(VMSOperations.Update, VMSSubjects.VEHICLE);

  // TODO: do we need to exclude the fields for GF since they contain acquisition cost?
  const [getAld, { loading: loadingAldData, error: aldDataError }] =
    useLazyQuery(GET_ALD, {
      onCompleted: (aldData) => {
        if (aldData?.getAld) {
          setAld(aldData.getAld);
        }
      },
      fetchPolicy: 'no-cache',
    });

  const [
    getALByCategories,
    { loading: loadingEPACTLookup, error: epactLookupError },
  ] = useLazyQuery(GET_AL_CATEGORY, {
    onCompleted: (epactLookupData) => {
      if (epactLookupData?.getALByCategories?.[0]?.options) {
        setAldOptions(epactLookupData.getALByCategories[0].options);
      }
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (vehicle) {
      getALByCategories({
        variables: {
          lookupCategories: ['EPACT_2005_SECTION_701_DESIGNATION'],
        },
      });
      getAld({
        variables: {
          vin: vehicle.id,
        },
      });
    }
  }, [vehicle]);

  if (loadingAldData || loadingEPACTLookup) {
    return <Spinner className="padding-top-5" />;
  }

  if (aldDataError || epactLookupError) {
    return <ServerErrorMessage graphQLErrors={aldDataError} />;
  }

  function formatGarageAddress() {
    const {
      garageAddress1,
      garageAddress2,
      garageAddress3,
      garageCity,
      garageStateCode,
      garagePostalCode,
    } = ald || {};
    const isAnyAddressPresent =
      garageAddress1 ||
      garageAddress2 ||
      garageAddress3 ||
      garageCity ||
      garageStateCode ||
      garagePostalCode;
    return (
      <address className="text-uppercase">
        {garageAddress1} {garageAddress1 && <br />}
        {garageAddress2} {garageAddress2 && <br />}
        {garageAddress3} {garageAddress3 && <br />}
        {garageCity}
        {garageCity && garageStateCode && `, `}
        {garageStateCode} {garagePostalCode}
        {isAnyAddressPresent ? null : '\u2014'}
      </address>
    );
  }

  const handleOpenModal = (type) => {
    setModalType(type);
    openModal();
  };

  const handleCloseModal = () => {
    setModalType(null);
    closeModal();
  };

  const renderOwnershipColumns = () => {
    const columns = [
      ['FAST vehicle type', ald?.fastDescription],
      [
        'Vehicle acquisition cost',
        ald?.assetAcquisition?.acquisitionCost
          ? formatCurrency(ald.assetAcquisition.acquisitionCost)
          : '\u2014',
      ],
      [
        'Vehicle acquisition date',
        ald?.assetAcquisition?.originalAcquisitionDate
          ? moment
              .utc(ald?.assetAcquisition?.originalAcquisitionDate)
              .format('MM/DD/YYYY')
          : '\u2014',
      ],
      ['Vehicle assignment type', ald?.assignmentType],
      ['Reporting organization', ald?.reportOrganization],
      ['Agency budget element code', ald?.agencyBudgetElementCode],
      ['Agency sort code', ald?.agencySort],
      ['Lease Type', ald?.leaseType],
    ];

    if (vehicle?.ownershipTypeCode === 'GF') {
      // remove acquisition cost and date
      columns.splice(1, 2);
      // remove lease type
      columns.pop();
    }
    return columns;
  };

  const renderLocationColumns = () => {
    const columns = [
      [
        <>
          <span className="text-bold">
            Is the vehicle in a domestic location?
          </span>
          <Button
            position="right"
            variant="unstyled"
            className="margin-left-1"
            rightIcon={{ name: 'info', className: 'text-black' }}
            onClick={() => {
              handleOpenModal('garageDomestic');
            }}
            data-testid="domestic-location-info-button"
            aria-label="info"
          />
        </>,
        populateBoolReadonly(ald?.garageDomestic),
      ],
      [
        'Withhold location of vehicle',
        populateBoolReadonly(ald?.isLocationWithheld),
      ],
      [
        'Is MSA (Metropolitan Statistical Area)',
        populateBoolReadonly(ald?.isMsa),
      ],
      ['Garage address', formatGarageAddress()],
    ];

    if (vehicle?.ownershipTypeCode === 'GF') {
      // remove is MSA
      columns.splice(2, 1);
      columns.push(['Home to work', populateBoolReadonly(ald?.homeToWork)]);
    }
    return columns;
  };

  const renderDesignationColumns = () => {
    const columns = [
      [
        <>
          <span className="text-bold">Law enforcement designation</span>
          <Button
            position="right"
            variant="unstyled"
            className="margin-left-1"
            rightIcon={{ name: 'info', className: 'text-black' }}
            onClick={() => {
              handleOpenModal('lawEnforcement');
            }}
            data-testid="law-enforcement-info-button"
            aria-label="info"
          />
        </>,
        ald?.lawEnforcement,
      ],
      [
        'Emergency response designation',
        populateBoolReadonly(ald?.isEmergencyResponse),
      ],
      ['Armored designation', populateBoolReadonly(ald?.armoredDesignation)],
    ];
    if (vehicle?.ownershipTypeCode === 'GF') {
      // remove armored designation
      columns.pop();
    }
    return columns;
  };

  return (
    <>
      {history?.location?.state?.success && (
        <Alert type="success" slim>
          Customer driven data was successfully updated.
        </Alert>
      )}
      <p>
        Customer Driven Data (CDD) is also known as Asset Level Data (ALD). To
        comply with the Federal <br />
        Automotive Statistical Tool (FAST) you can manage all of this
        vehicle&apos;s Customer Driven Data
        <br /> (CDD) on this page.
      </p>
      <p>
        Vehicle data can be imported and updated in bulk from the{' '}
        <Link to="/reports">Vehicle Reports Manager page</Link>.
      </p>
      {canEdit ? (
        <div className="padding-y-2">
          <Button
            data-testid="edit-cdd-button"
            type="button"
            onClick={() => {
              history.push(`/vehicles/${vehicle.id}/cddEdit`);
            }}
            leftIcon={{ name: 'edit', class: 'usa-icon--size-2' }}
            label="Edit customer driven data"
          />
        </div>
      ) : null}
      <div className="grid-row grid-gap margin-bottom-8">
        <div className="tablet:grid-col-6">
          <h4 className="title-s-caps text-primary margin-bottom-2 margin-top-4">
            VEHICLE DESIGNATIONS
          </h4>
          <div
            className="bg-gray-3 radius-md padding-y-2 padding-x-4"
            data-testid="cdd-vehicle-designations"
          >
            <DetailsTable
              className="text-right"
              bordered
              data={renderDesignationColumns()}
            />
            {isOpen && modalType === 'lawEnforcement' && (
              <CDDPopOverModal
                isOpen={isOpen}
                closeModal={handleCloseModal}
                title="Law enforcement designation"
              />
            )}
          </div>
          <h4 className="title-s-caps text-primary margin-bottom-2 margin-top-4">
            VEHICLE LOCATION
          </h4>
          <div
            className="bg-gray-3 radius-md padding-y-2 padding-x-4"
            data-testid="cdd-vehicle-location"
          >
            <DetailsTable
              className="text-right"
              bordered
              data={renderLocationColumns()}
            />
            {isOpen && modalType === 'garageDomestic' && (
              <CDDPopOverModal
                isOpen={isOpen}
                closeModal={handleCloseModal}
                title="Vehicle location"
              />
            )}
          </div>
          {vehicle?.ownershipTypeCode === 'AO' ? (
            <>
              <h4 className="title-s-caps text-primary margin-bottom-2 margin-top-4">
                VEHICLE COSTS
              </h4>
              <div
                className="bg-gray-3 radius-md padding-y-2 padding-x-4"
                data-testid="cdd-vehicle-costs"
              >
                <DetailsTable
                  className="text-right"
                  bordered
                  data={[
                    [
                      'Indirect cost',
                      ald?.indirectCost?.toString()
                        ? formatCurrency(ald.indirectCost)
                        : '\u2014',
                    ],
                    [
                      'Lease cost',
                      ald?.leaseCost?.toString()
                        ? formatCurrency(ald.leaseCost)
                        : '\u2014',
                    ],
                    [
                      'Accident repair cost',
                      ald?.accidentRepairCost?.toString()
                        ? formatCurrency(ald.accidentRepairCost)
                        : '\u2014',
                    ],
                  ]}
                />
              </div>
            </>
          ) : null}
          <h4 className="title-s-caps text-primary margin-bottom-2 margin-top-4">
            COMMENTS
          </h4>
          <div
            className="bg-gray-3 radius-md padding-y-2 padding-x-4"
            data-testid="cdd-comments"
          >
            <DetailsTable
              bordered
              wrapText
              data={[
                [
                  'Comments',
                  <span className="margin-left-3 float-right align-right">
                    {ald?.aldComment || '\u2014'}
                  </span>,
                ],
              ]}
            />
          </div>
        </div>
        <div className="tablet:grid-col-6">
          <h4 className="title-s-caps text-primary margin-bottom-2 margin-top-4">
            VEHICLE OWNERSHIP
          </h4>
          <div
            className="bg-gray-3 radius-md padding-y-2 padding-x-4"
            data-testid="cdd-vehicle-ownership"
          >
            <DetailsTable
              className="text-right text-wrap"
              bordered
              wrapText
              data={renderOwnershipColumns()}
            />
          </div>
          <h4 className="title-s-caps text-primary margin-bottom-2 margin-top-4">
            VEHICLE EXEMPTIONS
          </h4>
          <div
            className="bg-gray-3 radius-md padding-y-2 padding-x-4"
            data-testid="cdd-vehicle-exemptions"
          >
            <DetailsTable
              className="text-right"
              bordered
              data={[
                [
                  'Petroleum-dedicated LGHG vehicle qualifying as AFV',
                  populateBoolReadonly(ald?.isGasAfv),
                ],
                ['EPAct coverage designation', ald?.aldCoverageDesignation],
                [
                  'EPAct 2005 section 701 designation',
                  populateValueByAldCode(ald?.ald2005701, aldOptions),
                ],
                ['EISA section 141 designation', ald?.ald141Designation],
              ]}
            />
          </div>
          <h4 className="title-s-caps text-primary margin-bottom-2 margin-top-4">
            VEHICLE TELEMATICS
          </h4>
          <div
            className="bg-gray-3 radius-md padding-y-2 padding-x-4"
            data-testid="cdd-vehicle-telematics"
          >
            <DetailsTable
              className="text-right"
              bordered
              data={[
                [
                  'Telematics equipped',
                  ald?.vehicle?.cddTelematicsInstalled,
                ],
                [
                  <div className="margin-top-3">
                    <>
                      <span className="text-bold">
                        Vehicle telematics data usage
                      </span>
                      <Button
                        position="right"
                        variant="unstyled"
                        className="margin-left-1"
                        rightIcon={{ name: 'info', className: 'text-black' }}
                        onClick={() => {
                        handleOpenModal('telematics');
                      }}
                        data-testid="telematics-info-button"
                        aria-label="info"
                      />
                    </>
                  </div>,
                  ald?.assetTelematicsMetadata?.telematicsDataUsage,
                ],
              ]}
            />
            {isOpen && modalType === 'telematics' && (
              <CDDPopOverModal
                isOpen={isOpen}
                closeModal={handleCloseModal}
                title="Vehicle telematics data usage"
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerDrivenData;
