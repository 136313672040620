import { isTest } from 'utilities/consts';
import { getValueByPath } from '../form';

export const getFieldsKeys = ({ createLayoutSelector, atoms, pageId, get }) => {
  const layoutSelector = createLayoutSelector({ atoms })(pageId);
  const currentStepIndex = get(layoutSelector).currentStep - 1;
  return getValueByPath(
    get(layoutSelector),
    `layout[${currentStepIndex}.stateIds]`,
  );
};

export const navigateByHistoryState = ({
  historyState,
  pathname,
  state = {},
}) => {
  if (!isTest) {
    historyState.push({ pathname, state });
  }
};
