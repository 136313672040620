import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import AgencyFilterItem from '../../user-filters/custom-filters/agency-filter-item';
import BureauFilterItem from '../../user-filters/custom-filters/bureau-filter-item';
import OfficeFilterItem from '../../user-filters/custom-filters/office-filter-item';
import { VMSOperations, VMSSubjects } from '../../../utilities/consts';
// eslint-disable-next-line import/no-cycle
import BoacFilterItem from '../widgets/boac-filter-item';

// TODO: fix this circular dependency
const CustomerAccountFilterContext = createContext({});
const useCustomerAccountFilter = () => useContext(CustomerAccountFilterContext);

// eslint-disable-next-line react/prop-types
const CAAgencyFilterItem = ({ filter }) => {
  return (
    <AgencyFilterItem
      filter={filter}
      operation={VMSOperations.View}
      subject={VMSSubjects.CUSTOMER_ACCOUNT}
    />
  );
};

// eslint-disable-next-line react/prop-types
const CAOfficeFilterItem = ({ filter }) => {
  return (
    <OfficeFilterItem
      filter={filter}
      agencyFilterPath="$CustomerAccount.customer_agency_code$"
      bureauFilterPath="$CustomerAccount.customer_bureau_code$"
      officeFilterPath="$CustomerAccount.customer_physical_office_code$"
      operation={VMSOperations.View}
      subject={VMSSubjects.CUSTOMER_ACCOUNT}
      filterType="select"
    />
  );
};

// eslint-disable-next-line react/prop-types
const CABureauFilterItem = ({ filter }) => {
  return (
    <BureauFilterItem
      filter={filter}
      agencyFilterPath="$CustomerAccount.customer_agency_code$"
      bureauFilterPath="$CustomerAccount.customer_bureau_code$"
      operation={VMSOperations.View}
      subject={VMSSubjects.CUSTOMER_ACCOUNT}
    />
  );
};

const initialState = {
  error: {},
  filters: [],
  filterStructure: [],
};

const actions = {
  setFilters: 'SET_FILTERS',
  setError: 'SET_ERROR',
  setStructure: 'SET_STRUCTURE',
};

const extractErrorName = (err) => err.name || 'Unknown Error';

const customerAccountFilterReducer = (state, { action, payload }) => {
  const modifiedPayload = payload || [];
  const vehicleCountFilterIndex = payload?.findIndex(
    (item) => item.key === 'vehicleCount',
  );

  // adding this custom logic to handle the vehicle count filter
  // since filter component does not support operator change based on value
  if (vehicleCountFilterIndex !== -1) {
    const vehicleCountFilter = { ...payload[vehicleCountFilterIndex] };
    vehicleCountFilter.operator =
      vehicleCountFilter.value === '0' ? '$lt' : '$gte';
    vehicleCountFilter.value = '1';
    modifiedPayload[vehicleCountFilterIndex] = vehicleCountFilter;
  }

  const mergeState = (value, field) => {
    if (!field) {
      return { ...state, error: initialState.error, ...value };
    }
    const merged = { ...state, error: initialState.error };
    merged[field] = value || initialState[field];
    return merged;
  };

  switch (action) {
    case actions.setFilters: {
      return mergeState(
        {
          operator: '$and',
          conditions: modifiedPayload || [],
        },
        'filters',
      );
    }
    case actions.setStructure: {
      return mergeState(modifiedPayload, 'filterStructure');
    }
    case actions.setError: {
      return mergeState(extractErrorName(modifiedPayload), 'error');
    }
    default:
      throw new Error('Invalid user filter action');
  }
};

const CustomerAccountFilterProvider = ({ children }) => {
  const [state, setDispatch] = useReducer(
    customerAccountFilterReducer,
    initialState,
    () => initialState,
  );
  const dispatch = (action, payload) => setDispatch({ action, payload });
  const dispatchFilters = (conditions) =>
    dispatch(actions.setFilters, conditions);

  const dispatchFilterStructure = (structure) =>
    dispatch(actions.setStructure, structure);

  const getCustomerAccountsFilterStruct = ({
    fmcsWithZoneAndRegion,
    zones,
    initialFilters,
  }) => [
    {
      title: 'Organization',
      key: 'filter-abb',
      filters: [
        {
          key: '$CustomerAccount.customer_agency_code$',
          permanent: false,
          operator: '$exact',
          value: '',
          component: CAAgencyFilterItem,
        },
        {
          key: '$CustomerAccount.customer_bureau_code$',
          permanent: false,
          operator: '$in',
          value: '',
          component: CABureauFilterItem,
          placeholder: '- Select bureau -',
        },
        {
          key: '$CustomerAccount.customer_physical_office_code$',
          permanent: false,
          operator: '$in',
          value: '',
          component: CAOfficeFilterItem,
          placeholder: '- Select office -',
        },
      ],
    },
    {
      title: 'BOAC',
      key: 'filter-boac',
      filters: [
        {
          key: '$CustomerAccount.boac$',
          permanent: false,
          operator: '$exact',
          value: '',
          component: BoacFilterItem,
          placeholder: 'enter boac',
        },
      ],
    },
    {
      title: 'Point of Contact',
      key: 'filter-poc',
      filters: [
        {
          key: '$primaryContactAssociation->pointOfContact.email_address$',
          permanent: false,
          operator: '$exact',
          placeholder: 'enter Agency POC email',
          value: [],
          inSearchLength: 4,
          type: 'typeahead',
          id: 'placeholder_agency',
          ariaLabel: 'Agency POC',
          customFieldProps: {
            debounceDelay: 600,
            showNoResults: false,
            clearPanelFilterOnEmpty: true,
          },
        },
      ],
    },
    {
      title: 'Fleet Management Center',
      key: 'filter-fmc',
      filters: [
        {
          key: '$CustomerAccount.zone_id$',
          permanent: false,
          operator: '$exact',
          value: initialFilters?.zone ? initialFilters.zone : '',
          type: 'select',
          options:
            zones?.length > 0
              ? [{ value: '', label: '- Select zone - ' }, ...zones]
              : [{ value: '', label: '- Select zone - ' }],
        },
        {
          key: '$CustomerAccount.fmc_id$',
          permanent: false,
          operator: '$exact',
          value: initialFilters?.fmc ? initialFilters.fmc : '',
          type: 'select',
          options:
            fmcsWithZoneAndRegion?.length > 0
              ? [
                  { value: '', label: '- Select FMC - ' },
                  ...fmcsWithZoneAndRegion.map((fmc) => ({
                    value: fmc.centerId,
                    label: fmc.label,
                  })),
                ]
              : [{ value: '', label: '- Select FMC - ' }],
        },
        {
          key: '$CustomerAccount.fsr_user_email_address$',
          permanent: false,
          operator: '$exact',
          value: initialFilters?.fsrEmail ? initialFilters.fsrEmail : [],
          placeholder: 'enter FSR email',
          prefixIcon: '',
          type: 'typeahead',
          id: 'placeholder_FSR',
          customFieldProps: {
            inputCharNum: 3,
            debounceDelay: 500,
            promptText: 'Search requires 3 characters',
            showNoResults: false,
            clearPanelFilterOnEmpty: true,
          },
        },
      ],
    },
    {
      title: 'Customer account',
      key: 'filter-customer-account',
      filters: [
        {
          key: '$CustomerAccount.account_name$',
          permanent: false,
          operator: '$exact',
          value: '',
          placeholder: 'enter account name',
          prefixIcon: '',
          type: 'typeahead',
          id: 'placeholder_customer_account_name',
          customFieldProps: {
            inputCharNum: 3,
            debounceDelay: 500,
            promptText: 'Search requires 3 characters',
            showNoResults: false,
            clearPanelFilterOnEmpty: true,
          },
        },
        {
          key: '$CustomerAccount.legacy_customer_number$',
          permanent: false,
          operator: '$exact',
          value: '',
          placeholder: 'enter legacy customer number',
          prefixIcon: '',
          type: 'typeahead',
          id: 'placeholder_customer_number',
          customFieldProps: {
            inputCharNum: 3,
            debounceDelay: 500,
            promptText: 'Search requires 3 characters',
            showNoResults: false,
            clearPanelFilterOnEmpty: true,
          },
        },
      ],
    },
    {
      title: 'Account status',
      key: 'vehicleCount',
      type: 'radio',
      operator: '$gte',
      value: 'Active',
      options: [
        { value: '1', label: 'Active' },
        { value: '0', label: 'Inactive' },
      ],
    },
  ];

  return (
    <CustomerAccountFilterContext.Provider
      value={{
        ...state,
        dispatch,
        setFilters: dispatchFilters,
        setStructure: dispatchFilterStructure,
        getCustomerAccountsFilterStruct,
      }}
    >
      {children}
    </CustomerAccountFilterContext.Provider>
  );
};

CustomerAccountFilterProvider.defaultProps = {
  children: undefined,
};

CustomerAccountFilterProvider.propTypes = {
  children: PropTypes.node,
};

export { CustomerAccountFilterProvider as default, useCustomerAccountFilter };
