import React from 'react';
import { formatDate } from 'utilities/format';
import { RecallRowActionButton, RecallStatus } from './recall-table-widgets';

export const getRowMenuItems = (ownerShipType, isRole, recallStatus) => {
  let menuItems = [];
  if (
    ownerShipType === 'AO' &&
    (isRole('FMVRSAdminRole') || isRole('SiteAdmin') || isRole('CustFltMan'))
  ) {
    menuItems = [
      {
        label: 'Add new comment',
        action: 'addComment',
        icon: 'comments',
        iconType: 'custom',
      },
    ];
    if (recallStatus === 'Open') {
      menuItems = [
        ...menuItems,
        {
          label: 'Add or update remedy date',
          action: 'addRemedyDate',
          icon: 'edit',
        },
      ];
    }
  }

  if (
    ownerShipType === 'GF' &&
    (isRole('FMVRSAdminRole') ||
      isRole('SiteAdmin') ||
      isRole('FleetSvcRep') ||
      isRole('FMCManager')) &&
    recallStatus === 'Open'
  ) {
    menuItems = [
      {
        label: 'Add or update remedy date',
        action: 'addRemedyDate',
        icon: 'edit',
      },
    ];
  }
  return menuItems;
};

const formatComments = (comment) => {
  return {
    comment: comment?.comment,
    date: comment?.createdAt,
    id: comment?.id,
    type: 'Comment added',
    userName: comment?.createdByUser?.fullName,
    email: comment?.createdByUser?.email,
  };
};

const formatRecallTableRow = (rowItem, buttonAction, ownerShipType, isRole) => {
  const { recallCampaign, assetRecallComments } = rowItem;

  let formattedComments = [];
  if (assetRecallComments) {
    formattedComments = assetRecallComments
      .map((comment) => formatComments(comment))
      .sort((a, b) => (a.date > b.date ? -1 : 1));
  }
  const status =
    rowItem?.status === 'Open' && rowItem?.userClosedDate
      ? 'Closed by Customer'
      : rowItem?.status;
  return {
    ...rowItem,
    recallCode: recallCampaign?.recallCode,
    status: <RecallStatus status={status} />,
    recallStatus: rowItem?.status,
    remedyDate: formatDate(rowItem?.userClosedDate),
    launchDate: formatDate(recallCampaign?.recallReleaseDate),
    actions: (
      <RecallRowActionButton
        onClick={buttonAction}
        menuItems={getRowMenuItems(ownerShipType, isRole, rowItem?.status)}
        rowItem={rowItem}
      />
    ),
    description: recallCampaign?.recallDescription,
    comments: formattedComments,
  };
};

export const formatDataForTable = (
  queryData,
  buttonAction,
  ownerShipType,
  isRole,
) => {
  const rows = queryData?.getRecallsByVehicle?.rows;
  if (rows) {
    return rows.map((row) => {
      return formatRecallTableRow(row, buttonAction, ownerShipType, isRole);
    });
  }
  return [];
};

export default {
  formatDataForTable,
};
