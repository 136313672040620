import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import { generateOfficeManagementFilterStructure } from './office-management-filter-structure';

const OfficeManagementFilterContext = createContext({});
const useOfficeManagementFilter = () =>
  useContext(OfficeManagementFilterContext);

const initialState = {
  error: {},
  filters: [],
  filterStructure: [],
};

const officeManagementFilterReducer = (state, { action, payload }) => {
  const mergeState = (value, field) => {
    if (!field) {
      return { ...state, error: initialState.error, ...value };
    }
    const merged = { ...state, error: initialState.error };
    merged[field] = value || initialState[field];
    return merged;
  };

  switch (action) {
    case 'SET_FILTERS': {
      return mergeState(
        {
          operator: '$and',
          conditions: payload || [],
        },
        'filters',
      );
    }
    case 'SET_STRUCTURE': {
      return mergeState(payload, 'filterStructure');
    }
    default:
      throw new Error('Invalid user filter action');
  }
};

const OfficeManagementFilterProvider = ({ children }) => {
  const [state, setDispatch] = useReducer(
    officeManagementFilterReducer,
    initialState,
    () => initialState,
  );

  const dispatch = (action, payload) => setDispatch({ action, payload });

  const dispatchFilters = (conditions) => dispatch('SET_FILTERS', conditions);

  const dispatchFilterStructure = (structure) =>
    dispatch('SET_STRUCTURE', structure);

  const generateStructure = (statesOptions) => {
    const statesOptionsFormatted = statesOptions.map((item) => {
      return {
        label: item.stateName,
        value: item.id,
      };
    });
    const filterStructure = generateOfficeManagementFilterStructure(
      statesOptionsFormatted,
    );
    dispatchFilterStructure(filterStructure);
  };

  return (
    <OfficeManagementFilterContext.Provider
      value={{
        ...state,
        dispatch,
        generateStructure,
        setFilters: dispatchFilters,
        setStructure: dispatchFilterStructure,
      }}
    >
      {children}
    </OfficeManagementFilterContext.Provider>
  );
};

OfficeManagementFilterProvider.defaultProps = {
  children: undefined,
};

OfficeManagementFilterProvider.propTypes = {
  children: PropTypes.node,
};

export { OfficeManagementFilterProvider as default, useOfficeManagementFilter };
