import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMotorPool } from '../../../motor-pool-provider';
import MotorPoolVehicleAddEditFields from './motor-pool-vehicle-edit-fields';
import { MOTOR_POOL_VEHICLE_FORM_FIELDS } from './vehicle-constants';

const MotorPoolVehicleEditForm = () => {
  const generateMotorPoolVehicleFormSchema = () =>
    yup.object().shape({
      [MOTOR_POOL_VEHICLE_FORM_FIELDS.location.name]: yup
        .string()
        .max(100, 'Vehicle location cannot be longer than 100 characters')
        .label('Vehicle location')
        .nullable(),
      [MOTOR_POOL_VEHICLE_FORM_FIELDS.comment.name]: yup
        .string()
        .max(200, 'Comment cannot be longer than 200 characters.')
        .label('Comment')
        .nullable(),
    });

  const { updateMotorPoolVehicle, motorPoolVehicleSelected } = useMotorPool();

  const methods = useForm({
    resolver: yupResolver(generateMotorPoolVehicleFormSchema()),
    defaultValues: {},
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const onSubmit = (values) => {
    const reformatedValues = {};
    Object.keys(values).forEach((key) => {
      reformatedValues[key] = values[key];
    });
    updateMotorPoolVehicle({
      variables: {
        updatePoolVehicle: {
          id: motorPoolVehicleSelected.id,
          ...reformatedValues,
        },
      },
    });
  };

  return (
    <FormProvider {...methods}>
      <form
        data-testid="motor-pool-vehicle-listing-add-form"
        id="motor-pool-vehicle-listing-add-form"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <MotorPoolVehicleAddEditFields />
      </form>
    </FormProvider>
  );
};

export default MotorPoolVehicleEditForm;
