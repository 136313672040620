/* eslint-disable react/prop-types */
import React, { createContext, useContext, useReducer } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  GET_RECALL_DETAILS,
  GET_RECALL_CAMPAIGN,
  REMOVE_VIN_FROM_CAMPAIGN,
} from 'services/data-layer';
import { useHistory } from 'react-router-dom';

const RecallCampaignDetailPageContext = createContext({});
const useRecallCampaignDetailPage = () =>
  useContext(RecallCampaignDetailPageContext);

const initialState = {
  vehicleRecallList: {
    rows: [],
    hasMore: false,
    count: 0,
  },
  stopResetAlertMsgInitModal: true,
  currentModalName: 'RecallCampaignDetail',
  modalState: {
    currentModalName: null,
    currentMode: null,
    currentModalAlert: { type: null, message: null, header: null },
  },
  RecallCampaignDetailData: null,
  bannerMsg: null,
  currentVehicle: null,
  currentCampaignId: null,
};

export const RecallCampaignDetailPageReducer = (state, action) => {
  switch (action.type) {
    case 'SET_BANNER_MESSAGE':
      return { ...state, bannerMsg: action.payload };
    case 'SET_RECALL_DETAIL_DATA':
      return { ...state, RecallCampaignDetailData: action.payload };
    case 'SET_VEHICLE_RECALL_LIST':
      return { ...state, vehicleRecallList: action.payload };
    case 'SET_MODAL': {
      return {
        ...state,
        modalState: { ...state.modalState, ...action.payload },
      };
    }
    case 'SET_CURRENT_VEHICLE':
      return { ...state, currentVehicle: action.payload };
    case 'SET_CURRENT_CAMPAIGN_ID':
      return { ...state, currentCampaignId: action.payload };

    default:
      return {
        ...state,
        bannerMsg: { type: 'error', message: 'Unknown page action' },
      };
  }
};

export const onRemoveVinError = (
  error,
  setRequestError,
  resetCurrentModal,
  resetCurrentVehicle,
) => {
  setRequestError(error);
  resetCurrentModal();
  resetCurrentVehicle();
};

export const onRemoveVinCompleted = ({
  state,
  dispatchAction,
  resetCurrentModal,
  resetCurrentVehicle,
  getRecallCampaignDetails,
  initialPaginationState,
}) => {
  dispatchAction('SET_BANNER_MESSAGE', {
    type: 'success',
    message: (
      <>
        You have successfully removed VIN{' '}
        <span className="text-bold">{state.currentVehicle.vin}</span> from this
        campaign.
      </>
    ),
  });
  resetCurrentModal();
  resetCurrentVehicle();
  getRecallCampaignDetails({
    variables: {
      limit: initialPaginationState.limit,
      offset: initialPaginationState.offset,
      campaignId: state.currentCampaignId,
      order: 'createdAt DESC',
    },
  });
};

const RecallCampaignDetailPageProvider = ({ children, ...props }) => {
  const history = useHistory();
  const [state, dispatch] = useReducer(
    RecallCampaignDetailPageReducer,
    initialState,
  );

  const initialPaginationState = {
    limit: 10,
    offset: 0,
    currentPage: 1,
    isReset: false,
  };
  const dispatchAction = (type, payload) => {
    dispatch({
      type,
      payload,
    });
  };

  const setRequestError = (requestError) => {
    dispatchAction('SET_BANNER_MESSAGE', {
      type: 'error',
      message: requestError?.message,
      error: requestError,
    });
  };

  const [getRecallCampaign, { loading: loadingGetRecallCampaign }] =
    useLazyQuery(GET_RECALL_CAMPAIGN, {
      fetchPolicy: 'network-only',

      onError: (error) => {
        setRequestError(error);
      },
      onCompleted: (data) => {
        if (!data?.getRecallCampaign) {
          history.replace('/NotFound');
        }
        if (data?.getRecallCampaign) {
          dispatchAction('SET_RECALL_DETAIL_DATA', data.getRecallCampaign);
        }
      },
    });

  const [
    getRecallCampaignDetails,
    { loading: loadingGetRecallCampaignDetails },
  ] = useLazyQuery(GET_RECALL_DETAILS, {
    fetchPolicy: 'network-only',

    onError: (error) => {
      setRequestError(error);
    },
    onCompleted: (data) => {
      if (data?.getRecallCampaignDetails) {
        dispatchAction(
          'SET_VEHICLE_RECALL_LIST',
          data.getRecallCampaignDetails,
        );
      }
    },
  });
  const resetCurrentModal = () => {
    dispatchAction('SET_MODAL', initialState.modalState);
  };
  const resetCurrentVehicle = () => {
    dispatchAction('SET_CURRENT_VEHICLE', null);
  };
  // delete motor pool vehicle.
  const [removeVinFromCampaign] = useMutation(REMOVE_VIN_FROM_CAMPAIGN, {
    onError: (error) =>
      onRemoveVinError(
        error,
        setRequestError,
        resetCurrentModal,
        resetCurrentVehicle,
      ),
    onCompleted: () => {
      onRemoveVinCompleted({
        state,
        dispatchAction,
        resetCurrentModal,
        resetCurrentVehicle,
        getRecallCampaignDetails,
        initialPaginationState,
      });
    },
  });

  const setBannerMsg = (payload) =>
    dispatchAction('SET_BANNER_MESSAGE', payload);

  return (
    <RecallCampaignDetailPageContext.Provider
      value={{
        ...state,
        getRecallCampaignDetails,
        getRecallCampaign,
        setBannerMsg,
        resetCurrentModal,
        resetCurrentVehicle,
        loadingGetRecallCampaignDetails,
        loadingGetRecallCampaign,
        dispatchAction,
        removeVinFromCampaign,
        initialPaginationState,
        ...props,
      }}
    >
      {children}
    </RecallCampaignDetailPageContext.Provider>
  );
};

export {
  RecallCampaignDetailPageProvider as default,
  useRecallCampaignDetailPage,
};
