import React from 'react';
import { DetailsTable } from '@gsa/afp-component-library';
import { get, startCase, toLower } from 'lodash';
import PropTypes from 'prop-types';
import { fieldGetter } from '../../helpers/field-getter';

const LicensePlatePoc = ({ tagDetails }) => {
  const agencyAddress = {
    address1: tagDetails?.customerPointOfContactAddress1,
    address2: tagDetails?.customerPointOfContactAddress2,
    address3: tagDetails?.customerPointOfContactAddress3,
    address4: tagDetails?.customerPointOfContactAddress4,
    city: tagDetails?.customerPointOfContactCity,
    state: tagDetails?.customerPointOfContactState,
    zip: tagDetails?.customerPointOfContactZip,
  };

  const formatText = (text) => {
    if (Array.isArray(text)) {
      return text.map((item) => (
        <div className="text-right" key={item}>
          {item}
        </div>
      ));
    }
    return text || '—';
  };

  // eslint-disable-next-line no-shadow
  const constructAddress = (agencyAddress) => {
    let addressAvailable = false;
    // eslint-disable-next-line no-restricted-syntax
    for (const key of Object.keys(agencyAddress)) {
      if (agencyAddress[key]) {
        addressAvailable = true;
      }
    }
    if (agencyAddress && addressAvailable) {
      const address = ['address1', 'address2', 'address3', 'address4'].reduce(
        (adr, item) => {
          if (agencyAddress[item]) {
            adr.push(agencyAddress[item]);
          }
          return adr;
        },
        [],
      );

      const cityStateZip = ['city', 'state', 'zip']
        .reduce((adr, item, i) => {
          if (i === 1) {
            if (agencyAddress[item]) {
              adr.push(`${agencyAddress[item]} ${agencyAddress.zip}`);
            }
            return adr;
          }
          if (i === 2) return adr;
          if (agencyAddress[item]) {
            adr.push(agencyAddress[item]);
          }
          return adr;
        }, [])
        .join(', ');

      address.push(cityStateZip);
      return address;
    }
    return '—';
  };

  return (
    <div className="bg-gray-3 radius-md padding-y-2 padding-x-4">
      <DetailsTable
        bordered
        data={[
          [
            'Name',
            <span className="float-right align-right text-uppercase">
              {get(tagDetails, 'customerPointOfContactName')
                ? startCase(
                    toLower(get(tagDetails, 'customerPointOfContactName')),
                  )
                : '—'}
            </span>,
          ],
          [
            'Email',
            <span className="float-right align-right">
              {get(tagDetails, 'customerPointOfContactEmailAddress') ? (
                <a
                  aria-label="mailto"
                  href={`mailto:${get(
                    tagDetails,
                    'customerPointOfContactEmailAddress',
                  )}`}
                >
                  {get(tagDetails, 'customerPointOfContactEmailAddress')}
                </a>
              ) : (
                '—'
              )}
            </span>,
          ],
          [
            'Phone',
            <span className="float-right align-right">
              {get(tagDetails, 'customerPointOfContactPhone')
                ? fieldGetter([
                    {
                      field: get(tagDetails, 'customerPointOfContactPhone'),
                      formatter: 'phone',
                    },
                  ])
                : '—'}
            </span>,
          ],
          [
            'Address',
            <span className="float-right align-right text-uppercase">
              {formatText(constructAddress(agencyAddress))}
            </span>,
          ],
        ]}
      />
    </div>
  );
};

LicensePlatePoc.defaultProps = {
  tagDetails: {},
};

LicensePlatePoc.propTypes = {
  tagDetails: PropTypes.shape({
    customerPointOfContactAddress1: PropTypes.string,
    customerPointOfContactAddress2: PropTypes.string,
    customerPointOfContactAddress3: PropTypes.string,
    customerPointOfContactAddress4: PropTypes.string,
    customerPointOfContactCity: PropTypes.string,
    customerPointOfContactState: PropTypes.string,
    customerPointOfContactZip: PropTypes.string,
    customerPointOfContactName: PropTypes.string,
    customerPointOfContactEmailAddress: PropTypes.string,
    customerPointOfContactPhone: PropTypes.string,
  }),
};

export default LicensePlatePoc;
