import { useLazyQuery } from '@apollo/client';
import { Button, SelectDropdown, Spinner } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  GET_AGENCIES_BY_PERMISSION,
  GET_BUREAUS_BY_PERMISSION,
  GET_OFFICES_BY_PERMISSION,
} from '../../../../services/data-layer';
import { VMSOperations, VMSSubjects } from '../../../../utilities/consts';
import usePortalModal from '../../../../utilities/portal-modal';
import useUser from '../../../../utilities/use-user';

const DEFAULT_AGENCIES = [{ label: '- Select Agency -', value: '' }];
const DEFAULT_BUREAUS = [{ label: '- Select Bureau -', value: '' }];
const DEFAULT_OFFICES = [{ label: '- Select Office -', value: '' }];

export default function AgencyEdit({
  agencyCode,
  bureauCode,
  subSectionCode,
  editing,
  onClose,
  onSave,
}) {
  const [AgencyDetailsModal, openModal, closeModal] = usePortalModal();
  const [agencies, setAgencies] = useState(DEFAULT_AGENCIES);
  const [bureaus, setBureaus] = useState(DEFAULT_BUREAUS);
  const [offices, setOffices] = useState(DEFAULT_OFFICES);

  const { isRole } = useUser();

  const [newAgency, setNewAgency] = useState('');
  const [newBureau, setNewBureau] = useState('');
  const [newOffice, setNewOffice] = useState('');

  const [errors, setErrors] = useState({});

  const [getAgencies, { data: allAgencies, loading: agencyLoading }] =
    useLazyQuery(GET_AGENCIES_BY_PERMISSION, {
      fetchPolicy: 'network-only',
    });

  const [
    getBureausByPermission,
    { data: allBureaus, loading: bureausLoading },
  ] = useLazyQuery(GET_BUREAUS_BY_PERMISSION, {
    fetchPolicy: 'network-only',
  });

  const [getOfficesByPermission, { data: allOffices, loading: officeLoading }] =
    useLazyQuery(GET_OFFICES_BY_PERMISSION, {
      fetchPolicy: 'network-only',
    });

  const resetModal = () => {
    getAgencies({
      variables: {
        operation: VMSOperations.UPDATE_VEHICLE_AO,
        subject: VMSSubjects.VEHICLE,
        order: 'id ASC',
      },
    });

    if (agencyCode) {
      getBureausByPermission({
        variables: {
          agencyCode,
          operation: VMSOperations.UPDATE_VEHICLE_AO,
          subject: VMSSubjects.VEHICLE,
          order: 'id ASC',
        },
      });
    }

    if (agencyCode && bureauCode) {
      getOfficesByPermission({
        variables: {
          agencyCode,
          bureauCode,
          operation: VMSOperations.UPDATE_VEHICLE_AO,
          subject: VMSSubjects.VEHICLE,
          order: 'officeCode ASC',
        },
      });
    }

    setNewAgency(agencyCode);
    setNewBureau(bureauCode);
    setNewOffice(subSectionCode);

    setErrors({});
  };

  useEffect(() => {
    resetModal();
  }, []);

  useEffect(() => {
    if (editing) {
      resetModal();
      openModal();
    } else {
      onClose && onClose();
      closeModal();
    }
  }, [editing]);

  useEffect(() => {
    if (allAgencies) {
      const possibleAgencies = allAgencies.getAgenciesByPermission.map(
        (agency) => ({
          label: `${agency.id} - ${agency.name}`,
          value: agency.id,
        }),
      );
      setAgencies([...DEFAULT_AGENCIES, ...possibleAgencies]);
    }
  }, [allAgencies]);

  useEffect(() => {
    if (allBureaus) {
      const possibleBureaus = allBureaus.getBureausByPermission.map(
        (bureau) => ({
          label: `${bureau.id} - ${bureau.name}`,
          value: bureau.id,
        }),
      );
      setBureaus([...DEFAULT_BUREAUS, ...possibleBureaus]);
    }
  }, [allBureaus]);

  useEffect(() => {
    if (allOffices) {
      const possibleOffices = allOffices.getOfficesByPermission.map((off) => ({
        label: `${off.officeCode} - ${off.officeName}`,
        value: off.officeCode,
      }));
      setOffices([...DEFAULT_OFFICES, ...possibleOffices]);
    }
  }, [allOffices]);

  const handleAgencyChange = (e) => {
    setNewAgency(e.target.value);
    setNewBureau('');
    setNewOffice('');
    getBureausByPermission({
      variables: {
        agencyCode: e.target.value,
        operation: VMSOperations.UPDATE_VEHICLE_AO,
        subject: VMSSubjects.VEHICLE,
        order: 'id ASC',
      },
    });
  };

  const handleBureauChange = (e) => {
    setNewBureau(e.target.value);
    setNewOffice('');
    getOfficesByPermission({
      variables: {
        agencyCode: newAgency,
        bureauCode: e.target.value,
        operation: VMSOperations.UPDATE_VEHICLE_AO,
        subject: VMSSubjects.VEHICLE,
        order: 'officeCode ASC',
      },
    });
  };

  const handleOfficeChange = (e) => {
    setNewOffice(e.target.value);
  };

  const handleClose = () => {
    onClose && onClose();
    closeModal();
  };

  const handleSave = () => {
    if (!newAgency || !newBureau) {
      setErrors({
        agencyError: !newAgency ? 'Agency is a required field' : undefined,
        bureauError: !newBureau ? 'Bureau is a required field' : undefined,
      });
    } else if (
      agencyCode !== newAgency ||
      bureauCode !== newBureau ||
      subSectionCode !== newOffice
    ) {
      onSave && onSave(newAgency, newBureau, newOffice);
    } else {
      handleClose();
    }
  };

  return (
    <AgencyDetailsModal
      title={<h2>Update agency details</h2>}
      onClose={handleClose}
      actions={
        <>
          <Button variant="unstyled" onClick={handleClose} label="Close" />
          <Button
            className="margin-left-2"
            onClick={handleSave}
            label="Save and close"
          />
        </>
      }
    >
      <div>
        <div className="grid-row">
          <div className="grid-col-8">
            <SelectDropdown
              data-testid="agency-edit"
              name="agency"
              label="Agency"
              options={agencies}
              onChange={handleAgencyChange}
              value={newAgency}
              disabled={!(isRole('FMVRSAdminRole') || isRole('SiteAdmin'))}
              required
              errorMessage={errors.agencyError}
              default
            />
          </div>
          {agencyLoading && <Spinner className="grid-col-1 padding-top-7" />}
        </div>
        <div className="grid-row">
          <div className="grid-col-8">
            <SelectDropdown
              data-testid="bureau-edit"
              name="bureau"
              label="Bureau"
              options={bureaus}
              onChange={handleBureauChange}
              value={newBureau}
              disabled={
                !(
                  isRole('FMVRSAdminRole') ||
                  isRole('SiteAdmin') ||
                  isRole('CustFltMan')
                )
              }
              required
              errorMessage={errors.bureauError}
              default
            />
          </div>
          {bureausLoading && <Spinner className="grid-col-1 padding-top-7" />}
        </div>
        <div className="grid-row">
          <div className="grid-col-8">
            <SelectDropdown
              data-testid="office-edit"
              className="office-select"
              name="office"
              label="Office"
              options={offices}
              default
              onChange={handleOfficeChange}
              disabled={
                !(
                  isRole('FMVRSAdminRole') ||
                  isRole('SiteAdmin') ||
                  isRole('CustFltMan')
                )
              }
              value={newOffice}
            />
          </div>
          {officeLoading && <Spinner className="grid-col-1 padding-top-7" />}
        </div>
      </div>
    </AgencyDetailsModal>
  );
}

AgencyEdit.propTypes = {
  agencyCode: PropTypes.string,
  bureauCode: PropTypes.string,
  subSectionCode: PropTypes.string,
  editing: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};
AgencyEdit.defaultProps = {
  agencyCode: '000',
  bureauCode: '00',
  subSectionCode: '000',
  editing: false,
  onClose: () => {
    // intentional just an FYI SQ
  },
  onSave: () => {
    // intentional just an FYI SQ
  },
};
