import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Icon, Popover } from '@gsa/afp-component-library';
import alertManager from '../../utilities/alert-manager';

import './exempt-checkbox.css';

// eslint-disable-next-line react/prop-types
export const ExemptAlertText = ({ licensePlate }) => (
  <>
    You are recording an exemption from the requirements to display official
    U.S. Government license plates for this vehicle under sections{' '}
    <a
      href="https://www.ecfr.gov/current/title-41/section-102-34.160"
      data-testid="ecfr-160"
      target="_blank"
      rel="noopener noreferrer"
    >
      § 102-34.160
    </a>
    ,{' '}
    <a
      href="https://www.ecfr.gov/current/title-41/section-102-34.175"
      data-testid="ecfr-175"
      target="_blank"
      rel="noopener noreferrer"
    >
      § 102-34.175
    </a>{' '}
    or{' '}
    <a
      href="https://www.ecfr.gov/current/title-41/section-102-34.180"
      data-testid="ecfr-180"
      target="_blank"
      rel="noopener noreferrer"
    >
      § 102-34.180
    </a>{' '}
    and vehicle must be registered and inspected in accordance with the laws of
    the jurisdiction where the motor vehicle is regularly operated.
    {licensePlate && (
      <>
        <br />
        <p>When the license plate information is saved:</p>
        <ul>
          <li>
            The attached license plate{' '}
            <span className="text-bold">{licensePlate}</span> will be removed
            from the vehicle registration
          </li>
          <li>
            The vehicle will remain{' '}
            <span className="text-bold">registered</span>
          </li>
          <li>
            You will need to submit license plate{' '}
            <span className="text-bold">{licensePlate}</span> for destruction
          </li>
        </ul>
      </>
    )}
  </>
);

const ExemptCheckbox = ({
  exemptPlate,
  handleChange,
  licensePlate,
  showAlert,
}) => {
  const [ExemptPlateAlert, { showWarningAlert, closeAlert }] =
    alertManager(false);

  useEffect(() => {
    if (exemptPlate && showAlert) {
      showWarningAlert(<ExemptAlertText licensePlate={licensePlate} />);
    } else {
      closeAlert();
    }
  }, [exemptPlate]);

  return (
    <>
      <div className="grid-col-7 exempt-checkbox exempt-label-font margin-top-4 position-relative display-flex">
        <div className="usa-checkbox">
          <input
            id="checkbox"
            className="usa-checkbox__input"
            type="checkbox"
            aria-label="The Agency has an exemption from the requirement to display official U.S. Government license plates"
            checked={exemptPlate}
            onChange={handleChange}
          />
          <label className="usa-checkbox__label" htmlFor="checkbox">
            The Agency has an exemption from the requirement to display official
            U.S. Government license plates
            <span className="exempt-popover-wrapper">
              <div className="exempt-pop">
                <span className="position-relative popover-info-icon">
                  <Icon className="text-primary" iconName="info" />
                </span>
                <Popover
                  className="exempt-checkbox-popover"
                  arrowPosition="arrow-top-center"
                >
                  <h4 className="exempt-popover-heading margin-y-1">
                    License Plate Exemption
                  </h4>
                  <p className="margin-y-0">
                    The agency has an exemption from the requirement to display
                    official U.S. Government license plates for this vehicle
                    under sections{' '}
                    <a
                      href="https://www.ecfr.gov/current/title-41/section-102-34.160"
                      data-testid="ecfr-160"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      § 102-34.160
                    </a>
                    ,{' '}
                    <a
                      href="https://www.ecfr.gov/current/title-41/section-102-34.175"
                      data-testid="ecfr-175"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      § 102-34.175
                    </a>{' '}
                    or{' '}
                    <a
                      href="https://www.ecfr.gov/current/title-41/section-102-34.180"
                      data-testid="ecfr-180"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      § 102-34.180
                    </a>{' '}
                    and it must be registered and inspected in accordance with
                    the laws of the jurisdiction where the motor vehicle is
                    regularly operated.
                  </p>
                </Popover>
              </div>
            </span>
          </label>
        </div>
      </div>
      <ExemptPlateAlert />
    </>
  );
};

ExemptCheckbox.propTypes = {
  exemptPlate: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  showAlert: PropTypes.bool,
  licensePlate: PropTypes.string,
};

ExemptCheckbox.defaultProps = {
  exemptPlate: false,
  showAlert: true, // alert won't always show if the value is set on load and not the result of a change
  licensePlate: '',
};

export default ExemptCheckbox;
