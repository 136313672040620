import { useLazyQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useReducer } from 'react';
import { useVehicle } from '../../vehicle-context-provider';
import {
  GET_TRANSACTION_HISTORY_RECORDS,
  GET_HISTORY_ACTIVITY_TYPES,
} from './vehicle-activities.gql';

export const VehicleActivitiesContext = createContext();

const initialState = {
  activitiesList: {
    rows: [],
    hasMore: false,
    count: 0,
  },
  activityTypeOptions: [],
  alertMessage: { context: '', message: '', type: '', header: null },
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_ACTIVITIES_LIST':
      return { ...state, activitiesList: action.payload };
    case 'SET_ACTIVITIES_TYPE_OPTIONS':
      return { ...state, activityTypeOptions: action.payload };
    case 'SET_ALERT_MESSAGE':
      return { ...state, alertMessage: action.payload };
    default:
      throw new Error('Invalid action');
  }
};

function VehicleActivitiesProvider({ children, ...props }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { setAlertMessages } = useVehicle();
  const dispatchAction = (type, payload) => {
    dispatch({
      type,
      payload,
    });
  };

  // Get vehicle activities list.
  const [
    getTransactionHistoryRecords,
    { loading: transactionHistoryRecordsLoading },
  ] = useLazyQuery(GET_TRANSACTION_HISTORY_RECORDS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: (error) => {
      setAlertMessages([
        {
          type: 'internalError',
          message: error.message,
        },
      ]);
    },
    onCompleted: (responseData) => {
      if (responseData) {
        const temporarilyDisabledActivityIds = [4, 7, 8];
        const historyRecords = responseData.getTransactionHistoryRecords;
        const filteredHistoryRecords = historyRecords?.rows.filter(
          (record) =>
            !temporarilyDisabledActivityIds.includes(
              record.transactionHistoryCategoryTypeId,
            ),
        );
        dispatchAction('SET_ACTIVITIES_LIST', {
          ...historyRecords,
          rows: filteredHistoryRecords,
          count: filteredHistoryRecords.length,
        });
        setAlertMessages([]);
      }
    },
  });

  // Get vehicle history activity types.
  const [getHistoryActivityTypes, { loading: historyActivityTypesLoading }] =
    useLazyQuery(GET_HISTORY_ACTIVITY_TYPES, {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onError: (error) => {
        setAlertMessages([
          {
            type: 'internalError',
            message: error.message,
          },
        ]);
      },
      onCompleted: (responseData) => {
        const temporarilyDisabledActivities = [
          'Replacement',
          'WEX Card Replacement',
          'Modifications',
        ];
        if (responseData && responseData.getHistoryActivityTypes.length > 0) {
          const formattedActivityTypes =
            responseData.getHistoryActivityTypes.map((activityType) => ({
              value: activityType.transactionHistoryCategoryTypeId,
              label: activityType.description,
            }));
          // temporarily disable some activity types
          const filteredActivityTypes = formattedActivityTypes.filter(
            (activityType) =>
              !temporarilyDisabledActivities.includes(activityType.label),
          );
          dispatchAction('SET_ACTIVITIES_TYPE_OPTIONS', [
            { value: '', label: '- Select - ' },
            ...filteredActivityTypes,
          ]);
        }
      },
    });

  return (
    <VehicleActivitiesContext.Provider
      value={{
        ...state,
        getTransactionHistoryRecords,
        transactionHistoryRecordsLoading,
        getHistoryActivityTypes,
        historyActivityTypesLoading,
        dispatchAction,
        ...props,
      }}
    >
      {children}
    </VehicleActivitiesContext.Provider>
  );
}

export default VehicleActivitiesProvider;

VehicleActivitiesProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export const useVehicleActivities = () => useContext(VehicleActivitiesContext);
