// eslint-disable-next-line filenames/match-exported
import PropTypes from 'prop-types';
import { RadioButton } from '@gsa/afp-component-library';
import React from 'react';

const FleetCardAddressOption = ({
  address,
  label,
  addressOption,
  selectedAddressOption,
  onAddressOptionChange,
}) => {
  const { id, address1, address2, city, stateCode, postalCode, address3 } =
    address;

  return (
    <div
      data-testid={`customer-address-${addressOption}`}
      className={`usa-card__container padding-x-1 margin-x-0 margin-y-2 cursor-pointer grid-col-8 ${
        addressOption === selectedAddressOption &&
        'bg-primary-lighter border-accent-cool-darker'
      }`}
    >
      <RadioButton
        name="address"
        data-testid={`${addressOption}-customer-address-option`}
        value={addressOption}
        checked={addressOption === selectedAddressOption}
        onChange={() => onAddressOptionChange(addressOption, id)}
        label={label}
        className={`${
          addressOption === selectedAddressOption && 'bg-primary-lighter'
        }`}
      />
      {addressOption !== 'new' ? (
        <div className="margin-x-4 padding-y-1">
          <address>
            {address1}
            <br />
            {address2}
            <br />
            {address3}
            {address3 && <br />}
            {city}, {stateCode}, {postalCode}
          </address>
        </div>
      ) : (
        <div className="padding-bottom-2" />
      )}
    </div>
  );
};

FleetCardAddressOption.propTypes = {
  address: PropTypes.shape({
    id: PropTypes.string,
    address1: PropTypes.string,
    address2: PropTypes.string,
    address3: PropTypes.string,
    city: PropTypes.string,
    postalCode: PropTypes.string,
    stateCode: PropTypes.string,
  }).isRequired,
  addressOption: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onAddressOptionChange: PropTypes.func.isRequired,
  selectedAddressOption: PropTypes.string.isRequired,
};

export default FleetCardAddressOption;
