import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { FilterPanel, Spinner } from '@gsa/afp-component-library';
import { GET_AGENCY_INDICATORS_BY_AGENCY_BUREAU } from 'components/vehicle-reports/helpers/vehicle-reports.gql';

const { FilterPanelField, FilterPanelFilterShape, useFilterPanel } =
  FilterPanel;

const placeholderOption = {
  value: '',
  label: '- Select agency indicator -',
  defaultValue: true,
};

const AgencyIndicatorFilterItem = ({
  filter,
  agencyFilterPath,
  bureauFilterPath,
  agencyIndicatorFilterPath,
}) => {
  const [agencyCode, setAgencyCode] = useState();
  const [bureauCode, setBureauCode] = useState();

  const [selectedAgencyIndicator, setSelectedAgencyIndicator] = useState();
  const [options, setOptions] = useState([]);

  const { getFilterFromState, mergedFilters, clearOneFilter } =
    useFilterPanel();

  const [
    agencyIndicatorByAgencyAndBureauQuery,
    { data: agencyIndicatorOptions, loading },
  ] = useLazyQuery(GET_AGENCY_INDICATORS_BY_AGENCY_BUREAU, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    const agencyFilter =
      getFilterFromState(agencyFilterPath) ||
      getFilterFromState('$customer.customer_agency_code$') ||
      getFilterFromState('customerAgencyCode') ||
      {};

    setAgencyCode(agencyFilter.value);

    const bureauFilter =
      getFilterFromState(bureauFilterPath) ||
      getFilterFromState('$customer.customer_bureau_code$') ||
      getFilterFromState('customerBureauCode') ||
      {};

    setBureauCode(bureauFilter.value);

    const agencyIndicatorFilter =
      getFilterFromState(agencyIndicatorFilterPath) ||
      getFilterFromState('customerSubSectionCode') ||
      {};

    setSelectedAgencyIndicator(agencyIndicatorFilter.value);
  }, [mergedFilters]);

  useEffect(() => {
    clearOneFilter(filter);
    setOptions([placeholderOption]);
    if (agencyCode && bureauCode.length === 1) {
      agencyIndicatorByAgencyAndBureauQuery({
        variables: {
          agencyCode,
          bureauCode: bureauCode[0],
        },
      });
    }
  }, [bureauCode]);

  useEffect(() => {
    if (agencyIndicatorOptions?.getAgencyIndicatorsByAgencyBureau) {
      setOptions([
        placeholderOption,
        ...agencyIndicatorOptions?.getAgencyIndicatorsByAgencyBureau.map(
          ({ agencySubGroup }) => ({
            value: agencySubGroup,
            label: agencySubGroup,
          }),
        ),
      ]);
    }
  }, [agencyIndicatorOptions]);

  if (loading) {
    return <Spinner />;
  }

  if (bureauCode?.length > 1) {
    return (
      <p>
        Cannot filter by agency indicator when multiple bureaus are selected
      </p>
    );
  }

  return (
    <div className="margin-top-3">
      <FilterPanelField
        key={[filter.key]}
        filter={{
          ...filter,
          type: 'select',
          options,
          value: selectedAgencyIndicator || [],
        }}
      />
    </div>
  );
};

AgencyIndicatorFilterItem.propTypes = {
  filter: PropTypes.shape(FilterPanelFilterShape).isRequired,
  agencyFilterPath: PropTypes.string,
  bureauFilterPath: PropTypes.string,
  agencyIndicatorFilterPath: PropTypes.string,
};

export default AgencyIndicatorFilterItem;
