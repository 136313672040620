/* eslint-disable import/prefer-default-export */
/* eslint-disable filenames/match-regex */
import { gql } from '@apollo/client';

export const GET_FLEET_CARD_TRANSACTIONS_BY_VEHICLE = gql`
  query getFleetCardTransactionsByVehicle(
    $id: String!
    $order: OrderBy
    $offset: Float
    $limit: Float
    $filters: [Filter!]
  ) {
    getFleetCardTransactionsByVehicle(
      id: $id
      order: $order
      offset: $offset
      limit: $limit
      filters: $filters
    ) {
      rows {
        id
        assetId
        fleetCardMerchantId
        fleetCardMerchant {
          id
          merchantPhone
          merchantPhoneExt
          merchantName
          merchantAddress
          merchantAddress2
          merchantAddress3
          merchantCity
          merchantStateCode
          merchantPostalCode
          merchantPostalCodePlus4
        }
        fleetCardId
        recordType
        acctn4
        ccn4
        transactionDate
        odometerReading
        fleetCardProductId
        fleetCardProduct {
          id
          productName
          productDescription
        }
        fleetCardProductIdOnline
        fleetCardProductOnline {
          id
          productName
          productDescription
        }
        unitOfMeasure
        unitOfMeasureOnline
        unitCost
        unitsBought
        netCost
        tax {
          id
          fleetCardTransactionId
          fuelTax
          createdAt
        }
        grossCost
        comment
        enteredByUserInfo {
          fullName
          email
        }

        createdAt
        updatedBy
        updatedAt
        deletedAt
      }
      count
      hasMore
      totalExpense
    }
  }
`;
