/* eslint-disable react/require-default-props */
/* eslint-disable react/prop-types */
import React, { useEffect, useReducer } from 'react';
import usePortalModal from 'utilities/portal-modal';
import {
  Label,
  Button,
  RequiredFieldIndicator,
} from '@gsa/afp-component-library';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { emdash } from 'components/common';

const EditFuel = ({ vehicle, onSave, onClose }) => {
  const formReducer = (state, newState) => {
    return {
      ...state,
      ...newState,
    };
  };

  const isOwnershipAO = vehicle?.ownershipTypeCode === 'AO';
  const isOwnershipGF = vehicle?.ownershipTypeCode === 'GF';

  const MPG = 'mpg';
  const CITY_HWY = 'city/hwy';
  const MI_KM = 'mi/km';
  const GAL = 'gal';

  const [state, dispatch] = useReducer(formReducer, {
    tankSize: vehicle?.assetTechnical?.assetTechnicalIce?.tankSize?.toString(),
    mpgCity: vehicle?.assetTechnical?.assetTechnicalIce?.mpgCity?.toString(),
    mpgHighway:
      vehicle?.assetTechnical?.assetTechnicalIce?.mpgHighway?.toString(),
    mpgCombined:
      vehicle?.assetTechnical?.assetTechnicalIce?.mpgCombined?.toString(),
    flexFuelMpgCity:
      vehicle?.assetTechnical?.assetTechnicalIce?.flexFuelMpgCity?.toString(),
    flexFuelMpgHighway:
      vehicle?.assetTechnical?.assetTechnicalIce?.flexFuelMpgHighway?.toString(),
    flexFuelMpgCombined:
      vehicle?.assetTechnical?.assetTechnicalIce?.flexFuelMpgCombined?.toString(),
    gramPerMile:
      vehicle?.assetTechnical?.assetTechnicalIce?.gramPerMile?.toString(),
    flexFuelGramPerMile:
      vehicle?.assetTechnical?.assetTechnicalIce?.flexFuelGramPerMile?.toString(),
    rangeInMiles:
      vehicle?.assetTechnical?.assetTechnicalIce?.rangeInMiles?.toString(),
  });

  const [FuelEditModal, openModal, closeModal] = usePortalModal();
  useEffect(() => {
    openModal();
  }, []);

  const close = () => {
    // eslint-disable-next-line no-unused-expressions
    onClose && onClose();
    closeModal();
  };

  const getData = (field) => {
    return field || emdash;
  };

  const handleTextInputChange = (e, field) => {
    dispatch({
      [field]: e.target.value
        ? e.target.value.replace(/,/g, '').toString()
        : null,
    });
  };

  const getNumberFormat = ({
    handleChange,
    value,
    label,
    id,
    maxValue,
    suffix,
  }) => {
    return (
      <>
        <Label htmlFor={`${id}-id`}>
          <span className="text-bold">{label}</span>
        </Label>
        <div className="miles-input">
          <span className="prefix-icon-miles">{suffix || ''}</span>
          <NumberFormat
            className="usa-input"
            id={`${id}-id`}
            data-testid={id}
            thousandsGroupStyle="thousand"
            fixedDecimalScale
            decimalScale={0}
            decimalSeparator
            displayType="input"
            type="text"
            allowNegative={false}
            thousandSeparator
            onChange={handleChange}
            value={value}
            isAllowed={(values) => {
              const { floatValue, value } = values;
              return floatValue <= maxValue || value === '';
            }}
          />
        </div>
      </>
    );
  };

  const saveFuelDetails = (e) => {
    if (isOwnershipGF) {
      close();
      return;
    }
    closeModal();
    onSave(e);
  };

  return (
    <FuelEditModal
      actions={
        <>
          <Button
            variant="unstyled"
            label="Cancel"
            onClick={close}
            data-testid="fuel-cancel"
          />
          <Button
            className="margin-left-2"
            onClick={() => saveFuelDetails(state)}
            label="Save and close"
            data-testid="fuel-save-and-close"
          />
        </>
      }
      title={<h2>Edit fuel information</h2>}
      onClose={close}
    >
      <div className="grid-row grid-col-12">
        <div className="grid-col-12 grid-gap">
          <p>
            Edit fuel information for VIN <strong>{vehicle?.id}</strong> in the
            form below.
          </p>
          <p>
            Required fields are marked with an asterisk (
            <RequiredFieldIndicator />
            ).
          </p>
        </div>

        {isOwnershipGF && (
          <>
            <div className="grid-row grid-gap">
              <div className="grid-col-6">
                <Label htmlFor="fuel-tank-size-field-id">
                  <span className="text-bold">Fuel tank capacity</span>
                </Label>
                <div className="miles-input">
                  <div>{getData(state.tankSize)}</div>
                  <span className="prefix-icon-miles">
                    {state.tankSize ? GAL : ''}
                  </span>
                </div>
              </div>
              <div className="grid-col-6">
                <Label htmlFor="city-mpg-field-id">
                  <span className="text-bold">City</span>
                </Label>
                <div className="miles-input">
                  <div>{getData(state.mpgCity)}</div>
                  <span className="prefix-icon-miles">
                    {state.mpgCity ? MPG : ''}
                  </span>
                </div>
              </div>
              <div className="grid-col-6">
                <Label htmlFor="chighway-mpg-field-id">
                  <span className="text-bold">Highway</span>
                </Label>
                <div className="miles-input">
                  <div>{getData(state.mpgHighway)}</div>
                  <span className="prefix-icon-miles">
                    {state.mpgHighway ? MPG : ''}
                  </span>
                </div>
              </div>
              <div className="grid-col-6">
                <Label htmlFor="combined-mpg-field-id">
                  <span className="text-bold">Combined</span>
                </Label>
                <div className="miles-input">
                  <div>{getData(state.mpgCombined)}</div>
                  <span className="prefix-icon-miles">
                    {state.mpgCombined ? MPG : ''}
                  </span>
                </div>
              </div>
              <div className="grid-col-6">
                <Label htmlFor="flex-fuel-city-mpg-field-id">
                  <span className="text-bold">Flex fuel city</span>
                </Label>
                <div className="miles-input">
                  <div>{getData(state.flexFuelMpgCity)}</div>
                  <span className="prefix-icon-miles">
                    {state.flexFuelMpgCity ? MPG : ''}
                  </span>
                </div>
              </div>
              <div className="grid-col-6">
                <Label htmlFor="flex-fuel-highway-mpg-field-id">
                  <span className="text-bold">Flex fuel highway</span>
                </Label>
                <div className="miles-input">
                  <div>{getData(state.flexFuelMpgHighway)}</div>
                  <span className="prefix-icon-miles">
                    {state.flexFuelMpgHighway ? MPG : ''}
                  </span>
                </div>
              </div>
              <div className="grid-col-6">
                <Label htmlFor="flex-fuel-combined-mpg-field-id">
                  <span className="text-bold">Flex fuel combined</span>
                </Label>
                <div className="miles-input">
                  <div>{getData(state.flexFuelMpgCombined)}</div>
                  <span className="prefix-icon-miles">
                    {state.flexFuelMpgCombined ? MPG : ''}
                  </span>
                </div>
              </div>
              <div className="grid-col-6">
                <Label htmlFor="gram-per-mile-field-id">
                  <span className="text-bold">Gram per mile</span>
                </Label>
                <div className="miles-input">
                  <div>{getData(state.gramPerMile)}</div>
                  <span className="prefix-icon-miles">
                    {state.gramPerMile ? MPG : ''}
                  </span>
                </div>
              </div>
              <div className="grid-col-6">
                <Label htmlFor="flex-gram-per-mile-field-id">
                  <span className="text-bold">Flex gram per mile</span>
                </Label>
                <div className="miles-input">
                  <div>{getData(state.flexFuelGramPerMile)}</div>
                  <span className="prefix-icon-miles">
                    {state.flexFuelGramPerMile ? MPG : ''}
                  </span>
                </div>
              </div>
              <div className="grid-col-6">
                <Label htmlFor="flex-range-in-miles-id">
                  <span className="text-bold">Ranage in miles</span>
                </Label>
                <div className="miles-input">
                  <div>{getData(state.rangeInMiles)}</div>
                  <span className="prefix-icon-miles">
                    {state.rangeInMiles ? CITY_HWY : ''}
                  </span>
                </div>
              </div>
              <div className="grid-col-6">
                <Label htmlFor="average-monthly-miles-field-id">
                  <span className="text-bold">Average monthly miles</span>
                </Label>
                <div className="miles-input">
                  <div>{getData(state.averageMonthlyMiles)}</div>
                  <span className="prefix-icon-miles">
                    {state.averageMonthlyMiles ? MI_KM : ''}
                  </span>
                </div>
              </div>
            </div>
          </>
        )}
        {isOwnershipAO && (
          <>
            <div className="grid-row grid-gap">
              <div className="grid-col-6">
                {getNumberFormat({
                  handleChange: (e) => {
                    handleTextInputChange(e, 'tankSize');
                  },
                  value: state.tankSize,
                  label: 'Fuel tank capacity',
                  id: 'fuel-tank-size-field',
                  maxValue: 999,
                  suffix: 'gal',
                })}
              </div>
              <div className="grid-col-6">
                {getNumberFormat({
                  handleChange: (e) => {
                    handleTextInputChange(e, 'mpgCity');
                  },
                  value: state.mpgCity,
                  label: 'City',
                  id: 'city-mpg-field',
                  maxValue: 500,
                  suffix: 'mpg',
                })}
              </div>
              <div className="grid-col-6">
                {getNumberFormat({
                  handleChange: (e) => {
                    handleTextInputChange(e, 'mpgHighway');
                  },
                  value: state.mpgHighway,
                  label: 'Highway',
                  id: 'highway-mpg-field',
                  maxValue: 500,
                  suffix: 'mpg',
                })}
              </div>
              <div className="grid-col-6">
                {getNumberFormat({
                  handleChange: (e) => {
                    handleTextInputChange(e, 'mpgCombined');
                  },
                  value: state.mpgCombined,
                  label: 'Combined',
                  id: 'combined-mpg-field',
                  maxValue: 500,
                  suffix: 'mpg',
                })}
              </div>
              <div className="grid-col-6">
                {getNumberFormat({
                  handleChange: (e) => {
                    handleTextInputChange(e, 'flexFuelMpgCity');
                  },
                  value: state.flexFuelMpgCity,
                  label: 'Flex fuel city',
                  id: 'flex-fuel-city-mpg-field',
                  maxValue: 500,
                  suffix: 'mpg',
                })}
              </div>
              <div className="grid-col-6">
                {getNumberFormat({
                  handleChange: (e) => {
                    handleTextInputChange(e, 'flexFuelMpgHighway');
                  },
                  value: state.flexFuelMpgHighway,
                  label: 'Flex fuel highway',
                  id: 'flex-fuel-highway-mpg-field',
                  maxValue: 500,
                  suffix: 'mpg',
                })}
              </div>
              <div className="grid-col-6">
                {getNumberFormat({
                  handleChange: (e) => {
                    handleTextInputChange(e, 'flexFuelMpgCombined');
                  },
                  value: state.flexFuelMpgCombined,
                  label: 'Flex fuel combined',
                  id: 'flex-fuel-combined-mpg-field',
                  maxValue: 500,
                  suffix: 'mpg',
                })}
              </div>
              <div className="grid-col-6">
                {getNumberFormat({
                  handleChange: (e) => {
                    handleTextInputChange(e, 'gramPerMile');
                  },
                  value: state.gramPerMile,
                  label: 'Gram per mile',
                  id: 'gram-per-mile-field',
                  maxValue: 1000,
                  suffix: 'gpm',
                })}
              </div>

              <div className="grid-col-6">
                {getNumberFormat({
                  handleChange: (e) => {
                    handleTextInputChange(e, 'flexFuelGramPerMile');
                  },
                  value: state.flexFuelGramPerMile,
                  label: 'Flex gram per mile',
                  id: 'flex-gram-per-mile-field',
                  maxValue: 1000,
                  suffix: 'gpm',
                })}
              </div>
              <div className="grid-col-6">
                {getNumberFormat({
                  handleChange: (e) => {
                    handleTextInputChange(e, 'rangeInMiles');
                  },
                  value: state.rangeInMiles,
                  label: 'Range in miles',
                  id: 'range-in-miles-field',
                  maxValue: 1000,
                  suffix: 'city/hwy',
                })}
              </div>
            </div>
          </>
        )}
      </div>
    </FuelEditModal>
  );
};

export default EditFuel;

EditFuel.propTypes = {
  vehicle: PropTypes.shape({
    id: PropTypes.string,
    assetTechnical: PropTypes.shape({
      assetTechnicalIce: PropTypes.shape({
        cylinders: PropTypes.string,
        transmission: PropTypes.string,
        tankSize: PropTypes.number,
        mpgCity: PropTypes.number,
        mpgHighway: PropTypes.number,
        mpgCombined: PropTypes.number,
        flexFuelMpgCity: PropTypes.number,
        flexFuelMpgHighway: PropTypes.number,
        flexFuelMpgCombined: PropTypes.number,
        gramPerMile: PropTypes.number,
        flexFuelGramPerMile: PropTypes.number,
      }),
    }),
    tagNumber: PropTypes.string,
    fuel: PropTypes.shape({
      description: PropTypes.string,
    }),
    modelYear: PropTypes.number,
    fuelCode: PropTypes.string,
    fastReportable: PropTypes.bool,
    makeColor: PropTypes.shape({
      name: PropTypes.string,
    }),
    vehicleModel: PropTypes.shape({
      modelDescription: PropTypes.string,
    }),
    vehicleMake: PropTypes.shape({
      makeName: PropTypes.string,
    }),
    tag: PropTypes.shape({
      expirationDate: PropTypes.number,
    }),
    inventoryStatus: PropTypes.shape({
      id: PropTypes.string,
      description: PropTypes.string,
    }),
  }),
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};
