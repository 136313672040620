import { useLazyQuery } from '@apollo/client';
import { GET_REPORT_AGENCIES_BY_PERMISSION } from '../../../../../services/data-layer';

const useDataController = ({ setInternalError, setAgencies }) => {
  const [agenciesQuery, { loading: agenciesQueryLoading }] = useLazyQuery(
    GET_REPORT_AGENCIES_BY_PERMISSION,
    {
      onError: (err) => {
        setInternalError(err);
      },
      onCompleted: (data) => {
        setAgencies(data?.getAgenciesByPermission || []);
        setInternalError(undefined);
      },
    },
  );

  return { query: { agenciesQuery }, loading: { agenciesQueryLoading } };
};

export default useDataController;
