import React from 'react';
import { Breadcrumbs } from '@gsa/afp-component-library';
import { useParams } from 'react-router-dom';
import MileageExpressProvider from './mileage-express-provider';
import MileageExpressFilterProvider from './filters/mileage-express-filter-provider';
import MileageExpressHeader from './mileage-express-header';
import MileageExpressListing from './mileage-express-listing';

const MileageExpress = () => {
  const params = useParams();
  const { id } = params;
  const trail = [<a href={`${window.AFP_CONFIG.appURLs.home}/home`}>Home</a>];

  return (
    <>
      <MileageExpressProvider>
        <MileageExpressFilterProvider>
          <Breadcrumbs trail={trail} current="Mileage reporting" />
          <div className="margin-bottom-3">
            <MileageExpressHeader />
          </div>
          <MileageExpressListing />
        </MileageExpressFilterProvider>
      </MileageExpressProvider>
    </>
  );
};

export default MileageExpress;
