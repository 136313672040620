import React from 'react';
import { useTitle } from '@gsa/afp-shared-ui-utils';
import ServerErrorMessage from 'components/error-handlers/server-error-message';
import { Alert, PageTitle } from '@gsa/afp-component-library';
import { usePmExpress } from './pm-express-provider';

const PmExpressHeader = () => {
  useTitle('Preventative maintenance reporting');
  const { alertMessage } = usePmExpress();
  const { header, message, type, context, error } = alertMessage;

  const showAlert = () => {
    if (!message) {
      return null;
    }
    if (type === 'error' && !context) {
      return <ServerErrorMessage graphQLErrors={error} />;
    }
    return (
      <div className="margin-top-2" data-testid="pm-express-alert">
        <Alert type={type} heading={header || null}>
          {message}
        </Alert>
      </div>
    );
  };

  const cfcMaintenanceLink =
    'https://www.ecfr.gov/current/title-41/subtitle-C/chapter-101/subchapter-G/part-101-39/subpart-101-39.3/section-101-39.303';
  const cfcMaintenanceProgramLink =
    'https://www.ecfr.gov/current/title-41/subtitle-C/chapter-102/subchapter-B/part-102-34/subpart-F/section-102-34.275';

  const headerText = () => {
    return (
      <p>
        Use this tool to view and report completed Preventative Maintenance (PM)
        on your fleet-leased vehicle. Please refer to the CFR{' '}
        <a target="_blank" rel="noopener noreferrer" href={cfcMaintenanceLink}>
          {' '}
          § 101-39.303{' '}
        </a>{' '}
        and{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={cfcMaintenanceProgramLink}
        >
          § 102-34.275
        </a>{' '}
        on how to properly maintain your fleet-leased vehicle. You can also view
        and manage a vehicle’s PM history from the Vehicle Details page,
        Preventative Maintenance Tab. Please contact your local Fleet Service
        Representative (FSR) to edit an existing reported PM.
        <br />
        <br />
        <span className="text-bold text-underline">Note:</span> By reporting the
        PM completion date and mileage you are certifying that the information
        you are entering is accurate.
        <br />
        <br />
        For more information, please see the Preventative Maintenance Reporting{' '}
        <a
          href={`${process.env.PUBLIC_URL}/GSAFleet.gov-Preventative-Maintenance-User-Guide.pdf`}
          target="_blank"
          rel="noopener noreferrer"
        >
          user guide
        </a>{' '}
        and &nbsp;
        <a
          href="https://vimeo.com/gsavisualcommunications/review/939372241/ac44cdcf06"
          target="_blank"
          rel="noopener noreferrer"
        >
          How to
        </a>{' '}
        video.
        <br />
        Please note: PM Express was renamed to PM Reporting after video
        production.
      </p>
    );
  };

  return (
    <>
      {showAlert()}
      <header>
        <PageTitle title="Preventative Maintenance Reporting" />
        <div className="margin-bottom-4 tablet:grid-col-7">{headerText()}</div>
      </header>
    </>
  );
};

export default PmExpressHeader;
