import React from 'react';
import { get, startCase, toLower } from 'lodash';
import { DetailsTable } from '@gsa/afp-component-library';
import { useLicensePlateDetails } from '../license-plate-details-provider';
import { constructAddress } from '../../orders/widgets/table-widgets/order-details';
import { formatText } from '../../orders/widgets/table-widgets/detail-row';
import { fieldGetter } from '../../vehicle-details/helpers/field-getter';

export function LicensePlatePoc(tagDetails) {
  const agencyAddress = {
    address1: tagDetails?.customerPointOfContactAddress1,
    address2: tagDetails?.customerPointOfContactAddress2,
    address3: tagDetails?.customerPointOfContactAddress3,
    address4: tagDetails?.customerPointOfContactAddress4,
    city: tagDetails?.customerPointOfContactCity,
    state: tagDetails?.customerPointOfContactState,
    zip: tagDetails?.customerPointOfContactZip,
  };

  return (
    <DetailsTable
      bordered
      data={[
        [
          'Name',
          <span className="float-right align-right text-uppercase">
            {get(tagDetails, 'customerPointOfContactName')
              ? startCase(
                  toLower(get(tagDetails, 'customerPointOfContactName')),
                )
              : '—'}
          </span>,
        ],
        [
          'Email',
          <span className="float-right align-right">
            {get(tagDetails, 'customerPointOfContactEmailAddress') ? (
              <a
                aria-label="mailto"
                href={`mailto:${get(
                  tagDetails,
                  'customerPointOfContactEmailAddress',
                )}`}
              >
                {get(tagDetails, 'customerPointOfContactEmailAddress')}
              </a>
            ) : (
              '—'
            )}
          </span>,
        ],
        [
          'Phone',
          <span className="float-right align-right">
            {get(tagDetails, 'customerPointOfContactPhone')
              ? fieldGetter([
                  {
                    field: get(tagDetails, 'customerPointOfContactPhone'),
                    formatter: 'phone',
                  },
                ])
              : '—'}
          </span>,
        ],
        [
          'Address',
          <span className="float-right align-right text-uppercase">
            {formatText(constructAddress(agencyAddress))}
          </span>,
        ],
      ]}
    />
  );
}

const LpPocInfo = () => {
  const { tagDetails } = useLicensePlateDetails();

  return (
    <>
      <h2 className="title-s-caps text-primary margin-bottom-1">
        POINT OF CONTACT
      </h2>
      <div className="bg-gray-3 padding-3">{LicensePlatePoc(tagDetails)}</div>
    </>
  );
};

export default LpPocInfo;
