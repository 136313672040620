/* eslint-disable filenames/match-exported */
/* eslint-disable filenames/match-regex */
/* eslint-disable react/prop-types */

import React from 'react';
import { Spinner } from '@gsa/afp-component-library';

const LoadingComponent = ({
  size = 'large',
  className = 'loading_backdrop',
}) => <Spinner aria-busy="true" className={className} size={size} />;

export default LoadingComponent;
