/* eslint-disable filenames/match-exported */
export const defaultIncludeables = [
  'makeColor',
  'assetContactAssociationPrimary',
  'fuel',
  'customer',
  'assetContactAssociationSecondary',
  'assetLifecycle',
  'assetCustodian',
  'vmsManagementCenter'
];

// eslint-disable-next-line consistent-return
export default function getVehicleIncludeables(vehicleStatus) {
  // eslint-disable-next-line default-case
  switch (vehicleStatus) {
    case 'Registered':
    case 'Incomplete':
      // it looks we are switching tagRequire to tab in BackEnd and we do not have tagRequire field any more in the database
      // we may need to revisit this code and only use the tag in both FE and BE when we have time.
      // the reference back end fix is https://github.com/GSA/afp-vms-api/pull/1493
      return [...defaultIncludeables, 'tagRequire'];
    case 'Other':
    case 'Exempt':
    case 'Unregistered':
      return defaultIncludeables;
    case 'All':
      return [...defaultIncludeables, 'tag'];
  }
}
