import React, { useMemo } from 'react';
import { FilterTableFrame } from '@gsa/afp-component-library';
import PmListingTable from './pm-listing-table';
import PmExpressSidebar from './pm-express-sidebar';
import PMExpressActions from './pm-express-actions';

const PmExpressListing = () => {
  const FTF = useMemo(
    () =>
      FilterTableFrame(
        null,
        PMExpressActions,
        PmExpressSidebar,
        PmListingTable,
      ),
    [],
  );

  return <FTF filterToggle />;
};

export default PmExpressListing;
