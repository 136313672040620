import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumbs, PageTitle, Alert } from '@gsa/afp-component-library';
import { useCreateOrders } from './create-orders-provider';

const trail = [
  <a href={`${window.AFP_CONFIG.appURLs.home}/home`}>Home</a>,
  <Link to="/orders">Orders</Link>,
];

const showAlert = (alertMessage = {}) => {
  const { message, type, location } = alertMessage;

  return (
    message &&
    location === 'header' && (
      <div className="margin-top-2" data-testid="customer-account-alert">
        <Alert type={type} heading="Error">
          {message}
        </Alert>
      </div>
    )
  );
};

export default function Header() {
  const { alertMessage } = useCreateOrders();
  return (
    <header>
      <Breadcrumbs trail={trail} current="License Plate Orders" />
      {showAlert(alertMessage)}
      <PageTitle title="Create license plate order " />
      <p>Create orders for additional license plates</p>
    </header>
  );
}
