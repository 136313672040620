import { StatusBadge } from '@gsa/afp-component-library';

export const utcDateStrToUS = (str) => {
  if (!str) return '—';
  return `${str.substr(5, 2)}/${str.substr(8, 2)}/${str.substr(0, 4)}`;
};

export const MOD_STATUS = {
  Approved: 'APPROVED',
  Rejected: 'REJECTED',
  Cancelled: 'CANCELLED',
  InReview: 'IN-REVIEW',
  Returned: 'RETURNED',
};

export const getModStatusBadge = (status) => {
  let badge;
  switch (status) {
    case MOD_STATUS.Approved:
      badge = { variant: 'Ready-Gray', label: 'Approved' };
      break;
    case MOD_STATUS.Rejected:
      badge = { variant: 'Urgent-Gray', label: 'Rejected' };
      break;
    case MOD_STATUS.Cancelled:
      badge = { variant: 'Inactive-Gray', label: 'Cancelled' };
      break;
    case MOD_STATUS.Returned:
      badge = { variant: 'Warning-Gray', label: 'Returned to customer' };
      break;
    default:
      badge = { variant: 'Info-Gray', label: <>In&nbsp;review</> };
  }
  return <StatusBadge variant={badge.variant}>{badge.label}</StatusBadge>;
};
