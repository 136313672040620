/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { DetailsTable, StatusBadge, Tag } from '@gsa/afp-component-library';
import moment from 'moment';
import { Link } from 'react-router-dom';
import VehicleData from './vehicle-data';
import RegistrationActions from './registration-actions';
import { getValue } from '../../utilities/validation';
import vehicleOwnership from './helpers/vehicle-ownership';
import {
  VehicleRegistrationStatusTag,
  determineRegistrationStatus,
} from '../vehicle-registration-status-tag';
import VinLookup from './vin-number-lookup';
import './header.css';

export default ({ vehicle }) => {
  const { unverifiedVin, internationalVin, nhtsaVerified, pre1981 } = vehicle;

  const [vinVerificationStatus, setVinVerificationStatus] = useState('Unknown');

  useEffect(() => {
    if (unverifiedVin || pre1981 || internationalVin) {
      setVinVerificationStatus('User Verified VIN');
    } else if (nhtsaVerified) {
      setVinVerificationStatus('NHTSA Verified VIN');
    }
  }, [unverifiedVin, pre1981, internationalVin, nhtsaVerified]);

  const showRegistrationDetailsTile = () => {
    const status = determineRegistrationStatus(vehicle);
    if (status === 'Registered') return true;
    return false;
  };

  const getRegisteredBy = (vehicleDetail) => {
    let registeredBy = '—';

    if (vehicleDetail?.registeredByUser) {
      registeredBy = vehicleDetail.registeredByUser?.fullName;
    } else if (vehicleDetail?.tag?.activity?.performedByUser) {
      registeredBy = vehicleDetail?.tag?.activity?.performedByUser?.fullName;
    } else if (vehicleDetail?.createdByUser) {
      registeredBy = vehicleDetail?.createdByUser.fullName;
    }

    return registeredBy;
  };

  const getRegisteredOn = (vehicleDetail) => {
    let registeredOn = '';

    if (vehicleDetail?.registeredAt) {
      registeredOn = vehicleDetail.registeredAt;
    } else if (vehicleDetail?.tag?.activity?.createdAt) {
      registeredOn = vehicleDetail?.tag?.activity?.createdAt;
    } else if (vehicleDetail?.createdAt) {
      registeredOn = vehicleDetail?.createdAt;
    }

    return registeredOn ? (
      <span data-testid="afp-registration__registeredOn">
        {moment(registeredOn).format('L')} &bull;{' '}
        {moment(registeredOn).format('h:mm a')}
      </span>
    ) : (
      '—'
    );
  };

  return (
    <div
      data-testid="afp-registration__header_container"
      className="margin-top-2"
    >
      <div className="grid-row grid-gap">
        <div className="grid-col-9">
          <div className="display-flex flex-align-center">
            <h1 className="title-xl margin-0">
              <span className="afp-unselectable ">VIN </span>
              {getValue(vehicle?.id).toUpperCase()}
            </h1>
            <Tag className="text-middle bg-primary-lighter margin-x-3 padding-left-1 padding-right-1 afp-unselectable">
              <span className="text-base-darker font-sans-xs text-normal text-uppercase">
                {vehicleOwnership(vehicle?.ownershipTypeCode)}
              </span>
            </Tag>
          </div>
          <h2 className="title-m margin-top-0 margin-bottom-1 text-uppercase">
            {getValue(vehicle?.modelYear <= 0 ? 'UNKNOWN' : vehicle?.modelYear)}{' '}
            {getValue(vehicle?.vehicleMake?.makeName)}{' '}
            {getValue(
              vehicle?.vehicleModel?.modelDescription || vehicle?.modelCode,
            )}
          </h2>
          {vehicle?.tag && vehicle.tagNumber && (
            <h3 className="title-m margin-top-0">
              <Link to={`/license-plate/${vehicle?.tagNumber}`}>
                {vehicle?.tagNumber}
              </Link>
            </h3>
          )}
        </div>
        <div className="grid-col-3">
          <VinLookup
            ariaLabel="Search vehicles by VIN number"
            vin={getValue(vehicle?.id).toUpperCase()}
          />
          <div data-testid="registration-info">
            <VehicleData />
          </div>
        </div>
      </div>
      <div className="grid-row grid-gap">
        <div className="grid-col-9">
          <span data-testid="afp-registration__status">
            <VehicleRegistrationStatusTag
              data-testid="afp-registration__status"
              vehicle={vehicle}
              page="registration-details"
            />
          </span>
        </div>
      </div>
      <div className="margin-top-2">
        <div className="grid-row grid-gap margin-top-0">
          <div className="grid-col-6">
            {showRegistrationDetailsTile() && (
              <div className="bg-gray-3 radius-md padding-y-2 padding-x-4">
                <DetailsTable
                  className="afp-registration__section_container"
                  data={[
                    [
                      'Registration status',
                      <span data-testid="afp-registration__status">
                        <StatusBadge
                          data-testid="afp-registration__status"
                          variant="Ready-Gray"
                        >
                          Registered
                        </StatusBadge>
                      </span>,
                    ],
                    [
                      /* TODO: unhappy scenario: registration user unknown. E.g.: etlservice or deleted user */
                      'Registered by',
                      <span data-testid="afp-registration__registeredBy">
                        {getRegisteredBy(vehicle)}
                      </span>,
                    ],
                    ['Registered on', <>{getRegisteredOn(vehicle)}</>],
                    ['VIN verified by', vinVerificationStatus],
                  ]}
                />
              </div>
            )}
          </div>
          <div className=" grid-col-3 grid-offset-3">
            <div data-testid="registration-actions">
              <RegistrationActions vehicle={vehicle} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
