import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { Modal, Spinner, Button } from '@gsa/afp-component-library';
import { DELETE_REPAIR_ORDER } from 'services/data-layer/repair-order';

const DeleteOrderModal = ({ serviceId, onClose }) => {
  const [deleteOrder, { loading }] = useMutation(DELETE_REPAIR_ORDER, {
    onCompleted: () => onClose(true),
    onError: () => onClose(false),
  });

  return (
    <div className="afp-modal-wrapper">
      <div className="afp-modal-overlay">
        <Modal
          id="delete-repair-order-modal"
          variant="medium"
          title={<h2>Delete confirmation</h2>}
          onClose={onClose}
          actions={
            <div>
              <Button variant="unstyled" onClick={onClose} label="Cancel" />
              <Button
                variant="secondary"
                onClick={() => deleteOrder({ variables: { id: serviceId } })}
                label="Delete"
              />
            </div>
          }
        >
          {loading && (
            <div
              role="none"
              className="afp-modal-overlay"
              data-testid="delete-repair-loading-spinner"
            >
              <Spinner
                style={{
                  position: 'absolute',
                  top: '50%',
                  transform: 'translate(0, -50%)',
                }}
              />
            </div>
          )}
          <div className="margin-top-2 margin-bottom-8">
            Are you sure you want to delete the repair order?
          </div>
        </Modal>
      </div>
    </div>
  );
};

DeleteOrderModal.propTypes = {
  serviceId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DeleteOrderModal;
