import React from 'react';
import { Popover, Icon } from '@gsa/afp-component-library';

export default function ExemptPopover() {
  return (
    <div className="exempt-popover-container position-relative width-205">
      <Icon className="text-primary" iconName="info" />
      <div className="position-absolute top-neg-1 right-3">
        <Popover
          arrowPosition="arrow-right-top"
          className="exempt-popover width-mobile z-100"
        >
          <h4 className="exempt-popover-heading margin-y-1">
            License Plate Exemption
          </h4>
          <p className="margin-y-0">
            The agency has an exemption from the requirement to display official
            U.S. Government license plates for this vehicle under sections{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.ecfr.gov/current/title-41/section-102-34.160"
            >
              § 102-34.160
            </a>
            ,{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.ecfr.gov/current/title-41/section-102-34.175"
            >
              § 102-34.175
            </a>
              or{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.ecfr.gov/current/title-41/section-102-34.180"
            >
               § 102-34.180
            </a>{' '}
            and it must be registered and inspected in accordance with the laws
            of the jurisdiction where the motor vehicle is regularly operated.
          </p>
        </Popover>
      </div>
    </div>
  );
}
