import fsr, { fsrVehicleNestedFilters } from './fsr';
import afm, { afmVehicleNestedFilters } from './afm';
import bfm, { bfmVehicleNestedFilters } from './bfm';
import ofm, { ofmVehicleNestedFilters } from './ofm';
import defaultFilters, { defaultVehicleNestedFilters } from './default-filters';

const vehicleInventoryFilters = {
  fsr,
  fsrVehicleNestedFilters,
  afm,
  afmVehicleNestedFilters,
  bfm,
  bfmVehicleNestedFilters,
  ofm,
  ofmVehicleNestedFilters,
  defaultFilters,
  defaultVehicleNestedFilters,
};
export default vehicleInventoryFilters;
