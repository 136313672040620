import PropTypes from 'prop-types';
import React from 'react';

const Icon = ({ type }) => {
  switch (type) {
    case 'download':
      return (
        <svg
          data-testid="download-icon"
          width="14"
          height="16"
          viewBox="0 0 14 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.50859 12.3977C6.77663 12.6669 7.22337 12.6669 7.49141 12.3977L13.299 6.59503C13.567 6.29592 13.567 5.84726 13.299 5.57807L12.614 4.89012C12.3459 4.62093 11.8992 4.62093 11.6014 4.89012L8.21477 8.26938V1.37024C8.21477 0.920707 7.86307 0.599609 7.44744 0.599609H6.42434C5.97673 0.599609 5.65701 0.920707 5.65701 1.37024V8.16255L2.39863 4.89012C2.1008 4.62093 1.65407 4.62093 1.38603 4.89012L0.701031 5.57807C0.43299 5.84726 0.43299 6.29592 0.701031 6.59503L6.50859 12.3977Z"
            fill="#71767A"
          />
          <path
            d="M1.32857 13.3997L12.6714 13.3997C13.1647 13.3997 13.5 13.6986 13.5 14.0366V14.7627C13.5 15.1206 13.1647 15.3997 12.6714 15.3997L1.32857 15.3997C0.80625 15.3997 0.5 15.1206 0.5 14.7627V14.0366C0.5 13.6986 0.80625 13.3997 1.32857 13.3997Z"
            fill="#71767A"
          />
        </svg>
      );

    case 'edit':
      return (
        <svg
          data-testid="edit-icon"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.34439 3.39242L1.37235 11.3645L0.791007 14.3964C0.733654 14.8265 1.10645 15.1993 1.53659 15.1419L4.56847 14.5606L12.5405 6.58854L9.34439 3.39242ZM14.8051 2.3723L13.5606 1.12779C13.0445 0.582942 12.1547 0.582132 11.6098 1.12698L10.0039 2.73286L13.2001 5.92899L14.8059 4.32311C15.3508 3.77826 15.35 2.88848 14.8051 2.3723Z"
            fill="#71767A"
          />
        </svg>
      );

    case 'delete':
      return (
        <svg
          data-testid="delete-icon"
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7 0C3.13306 0 0 3.13306 0 7C0 10.8669 3.13306 14 7 14C10.8669 14 14 10.8669 14 7C14 3.13306 10.8669 0 7 0ZM10.4153 8.8629C10.5565 8.97581 10.5565 9.20161 10.4153 9.34274L9.31452 10.4435C9.17339 10.5847 8.94758 10.5847 8.83468 10.4435L7 8.58064L5.1371 10.4435C5.02419 10.5847 4.79839 10.5847 4.65726 10.4435L3.55645 9.31452C3.41532 9.20161 3.41532 8.97581 3.55645 8.83468L5.41935 7L3.55645 5.16532C3.41532 5.05242 3.41532 4.82661 3.55645 4.68548L4.68548 3.58468C4.79839 3.44355 5.02419 3.44355 5.16532 3.58468L7 5.41935L8.83468 3.58468C8.94758 3.44355 9.17339 3.44355 9.31452 3.58468L10.4153 4.68548C10.5565 4.82661 10.5565 5.05242 10.4153 5.16532L8.58064 7L10.4153 8.8629Z"
            fill="#B51D09"
          />
        </svg>
      );

    case 'up-arrow':
      return (
        <svg
          data-testid="up-arrow-icon"
          width="10"
          height="12"
          viewBox="0 0 10 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.62915 0.653564C4.8313 0.451416 5.16821 0.451416 5.37036 0.653564L9.75024 5.01099C9.95239 5.2356 9.95239 5.57251 9.75024 5.77466L9.23364 6.29126C9.03149 6.49341 8.69458 6.49341 8.46997 6.29126L6.0126 3.83389L6.0126 10.9233C6.0126 11.2608 5.77148 11.502 5.43391 11.502H4.66231C4.34885 11.502 4.08362 11.2608 4.08362 10.9233L4.08362 3.75366L1.52954 6.29126C1.30493 6.49341 0.968018 6.49341 0.765869 6.29126L0.249268 5.77466C0.0471191 5.57251 0.0471191 5.2356 0.249268 5.01099L4.62915 0.653564Z"
            fill="white"
          />
        </svg>
      );

    case 'sort-ascending':
      return (
        <svg
          data-testid="sort-ascending-icon"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="#005EA2"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.50586 0.871582C7.77539 0.602051 8.22461 0.602051 8.49414 0.871582L14.334 6.68148C14.6035 6.98096 14.6035 7.43018 14.334 7.69971L13.6452 8.38851C13.3757 8.65804 12.9264 8.65804 12.627 8.38851L9.35046 5.11201L9.35046 14.5645C9.35046 15.0146 9.02896 15.3361 8.57887 15.3361H7.55008C7.13213 15.3361 6.77848 15.0146 6.77848 14.5645V5.00504L3.37305 8.38851C3.07357 8.65804 2.62435 8.65804 2.35482 8.38851L1.66602 7.69971C1.39648 7.43018 1.39648 6.98096 1.66602 6.68148L7.50586 0.871582Z"
            fill="#005EA2"
          />
        </svg>
      );

    case 'sort-descending':
      return (
        <svg
          data-testid="sort-descending-icon"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="#005EA2"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.50586 15.134C7.77539 15.4035 8.22461 15.4035 8.49414 15.134L14.334 9.3241C14.6035 9.02462 14.6035 8.5754 14.334 8.30587L13.6452 7.61707C13.3757 7.34754 12.9264 7.34754 12.627 7.61707L9.35046 10.8936V1.44107C9.35046 0.990976 9.02896 0.669479 8.57887 0.669479L7.55008 0.669479C7.13213 0.669479 6.77848 0.990976 6.77848 1.44107V11.0005L3.37305 7.61707C3.07357 7.34754 2.62435 7.34754 2.35482 7.61707L1.66602 8.30587C1.39648 8.5754 1.39648 9.02462 1.66602 9.3241L7.50586 15.134Z"
            fill="#005EA2"
          />
        </svg>
      );

    case 'down-chevron':
      return (
        <svg
          data-testid="down-chevron-icon"
          width="15"
          height="10"
          viewBox="0 0 15 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.258 0.5C12.5202 0.5 12.7827 0.593022 13.0007 0.79778L13.7269 1.564C13.9289 1.7974 14.03 2.09096 14.03 2.37866C14.03 2.6496 13.9407 2.91393 13.7682 3.1221L7.81826 9.46093C7.58924 9.6834 7.30036 9.79157 7.01879 9.79157C6.75227 9.79157 6.49097 9.6954 6.28566 9.5038L0.309161 3.17566C0.105525 2.95993 0 2.67324 0 2.37866C0 2.11508 0.0848815 1.84657 0.254389 1.62392L0.983844 0.842747C1.2123 0.620885 1.5002 0.512234 1.78698 0.512234C2.0519 0.512234 2.31768 0.604997 2.53677 0.793917L7.02971 5.68498L11.4375 0.845029C11.6701 0.618173 11.9661 0.5 12.258 0.5Z"
            fill="#71767A"
          />
        </svg>
      );

    default:
      return null;
  }
};

Icon.propTypes = {
  type: PropTypes.string.isRequired,
};

export default Icon;
