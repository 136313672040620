// eslint-disable-next-line filenames/match-exported
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { useMotorPool } from '../motor-pool-provider';

import ReservationExportFields, {
  startDate,
  endDate,
} from './motor-pool-export-date-fields';

const ExportForm = ({ reportType }) => {
  const generateExportFormSchema = () =>
    yup.object().shape({
      [startDate.name]: yup.date().required().label(startDate.label),
      [endDate.name]: yup.date().required().label(endDate.label),
    });
  // need find out the variable
  const { motorPoolIdsSelected, exportReport } = useMotorPool();

  const methods = useForm({
    resolver: yupResolver(generateExportFormSchema()),
    defaultValues: {},
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const createCriteria = (values) => {
    const dateFormat = 'YYYY-MM-DD';
    const { pickupDate, returnDate, dateCriteria } = values;
    const formatPickupDate = moment.utc(pickupDate).format(dateFormat);
    const formatReturnDate = moment.utc(returnDate).format(dateFormat);

    return {
      pickUpDate: formatPickupDate,
      returnDate: formatReturnDate,
      motorPoolIds: motorPoolIdsSelected,
      dateCriteria,
    };
  };

  const onSubmit = (values) => {
    exportReport({
      variables: {
        request: {
          reportType,
          deliveryMethod: 1,
          reportCriteria: createCriteria(values),
        },
      },
    });
  };

  return (
    <FormProvider {...methods}>
      <form
        data-testid="export-form"
        id="export-form"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <ReservationExportFields reportType={reportType} />
      </form>
    </FormProvider>
  );
};

export default ExportForm;
