import React from 'react';
import { SelectDropdown } from '@gsa/afp-component-library';
import VerifyVin from './verify-vin';
import { useRegistration } from '../reg-context-provider';
import LabelOrInput from './label-or-input';
import MakeLookup from './make-lookup';
import ModelLookup from './model-lookup';
import MakeColor from './make-color';
import SelectFuelType from './select-fuel-type';
import SelectFastReportable from './select-fast-reportable';
import LicensePlateInformation from './license-plate-information';
import { nullForForm, UNKNOWN } from '../../../utilities/common';
import ReceivedDate from './received-date';


const FROM_YEAR = 1970;
const CURRENT_YEAR = new Date().getFullYear();
const VEHICLE_YEAR_OPTIONS = [
  {
    value: '',
    label: '- Select -',
  },
];

for (let index = CURRENT_YEAR + 2; index >= FROM_YEAR; index -= 1) {
  VEHICLE_YEAR_OPTIONS.push({
    value: index,
    label: index,
  });
}

export default function VehicleInformationManual() {
  const {
    setFormState,
    formState,
    fuelTypes,
    getFieldValidation,
    registrationType,
  } = useRegistration();

  const disableSelectionForTrailer = () => {
    return formState.itemType === 'Trailer';
  };

  const getFastReportable = () => {
    if (disableSelectionForTrailer()) {
      return false;
    }
    return formState.fastReportable;
  };

  const checkIfNonFueled = () => {
    if (disableSelectionForTrailer()) {
      return '99';
    }
    return nullForForm(formState.fuelCode);
  };

  const disableModelSelectionUnknownMake = () =>
    formState.makeCode === -1 || formState.makeCode === '-1';

  const checkIfUnknownMake = () => {
    if (disableModelSelectionUnknownMake()) {
      return UNKNOWN;
    }
    return formState.modelName;
  };

  return (
    <div className="afp-registration__vin-validation_form">
      <div className="afp-vin-verification-container">
        <VerifyVin />
        <div className="grid-row grid-gap">
          <div className="tablet:grid-col-2">
            <LabelOrInput label="Year" value={nullForForm(formState.modelYear)}>
              <SelectDropdown
                id="year"
                data-testid="year"
                label="Year"
                value={nullForForm(formState.modelYear)}
                name="year"
                options={VEHICLE_YEAR_OPTIONS}
                onChange={(e) => {
                  const { value } = e.target;
                  const cleanedVal = +value.replace(/[^0-9]/g, '') || '';
                  setFormState({ modelYear: cleanedVal });
                }}
                errorMessage={getFieldValidation('modelYear')}
                required
              />
            </LabelOrInput>
          </div>
          <div className="tablet:grid-col-4">
            <LabelOrInput label="Make" value={nullForForm(formState.makeName)}>
              <MakeLookup
                onSelect={(make) => {
                  if (make && make.makeCode) {
                    setFormState({
                      makeName: make.makeName,
                      makeCode: +make.makeCode,
                    });
                  } else {
                    setFormState({
                      makeName: null,
                      makeCode: null,
                      modelCode: null,
                      modelName: null,
                    });
                  }
                }}
                value={formState.makeName}
                onChange={(e) => {
                  setFormState({
                    makeName: e.target.value,
                  });
                }}
                errorMessage={getFieldValidation('makeCode')}
              />
            </LabelOrInput>
          </div>
          <div className="tablet:grid-col-4">
            <LabelOrInput
              label="Model"
              value={nullForForm(formState.modelName)}
            >
              <ModelLookup
                onSelect={(model) => {
                  setFormState({
                    modelName: model ? model.modelDescription : null,
                    modelCode: model ? model.modelCode : null,
                  });
                }}
                makeCode={formState.makeCode}
                value={checkIfUnknownMake()}
                onChange={() => {
                  setFormState({
                    modelName: null,
                  });
                }}
                errorMessage={getFieldValidation('modelCode')}
                disabled={disableModelSelectionUnknownMake()}
              />
            </LabelOrInput>
          </div>
          <div className="tablet:grid-col-2">
            <MakeColor
              makeCode={formState.makeCode}
              value={nullForForm(formState.makeColorName)}
              onChange={(color) => {
                setFormState({
                  makeColorName: color.value,
                  makeColorLabel: color.label,
                });
              }}
              errorMessage={getFieldValidation('makeColorName')}
            />
          </div>
        </div>
        <div className="grid-row grid-gap">
          <div className="tablet:grid-col-6">
            <SelectFuelType
              fuelTypes={fuelTypes}
              value={checkIfNonFueled()}
              onChange={(fuel) => {
                setFormState({
                  fuelCode: fuel.value,
                  fuelDescription: fuel.label,
                });
              }}
              errorMessage={getFieldValidation('fuelCode')}
              disabled={disableSelectionForTrailer()}
            />
          </div>
          <div className="tablet:grid-col-3">
            <SelectFastReportable
              value={getFastReportable()}
              onChange={(value) => {
                setFormState({
                  fastReportable: value,
                });
              }}
              errorMessage={getFieldValidation('fastReportable')}
              disabled={disableSelectionForTrailer()}
            />
          </div>
        </div>
        <div className="grid-row grid-gap">
          <ReceivedDate
            value={
              registrationType === 'edit'
                ? formState?.assetAcquisition?.originalAcquisitionDate
                : ''
            }
          />
        </div>
        <LicensePlateInformation />
      </div>
    </div>
  );
}
