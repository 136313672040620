// eslint-disable-next-line filenames/match-exported
import React from 'react';
import { Accordion } from '@gsa/afp-component-library';
import { useVehicle } from 'components/vehicle-details/vehicle-context-provider';
import { emdash } from 'components/common';
import ReactHtmlParser from 'react-html-parser';
import PmScheduleTypeTag from './pm-schedule-type-tag';
import { getPmDistanceByLocation } from './helper';

export default function PmInspectionSchduleDetails() {
  const { vehicle } = useVehicle();
  const {
    pmScheduleNumber,
    pmTitle,
    pmType,
    pmMilesInterval,
    pmKilometersInterval,
    pmMonthsInterval,
    pmAdditionalNotes,
    pmRequiredService,
  } = vehicle?.assetTechnical?.pmSchedule || {};

  const inpsectionSchduleData = [
    { label: 'Month Interval', value: pmMonthsInterval || emdash },
    {
      label: 'Mileage Interval',
      value:
        getPmDistanceByLocation(
          vehicle?.fmcId,
          pmMilesInterval,
          pmKilometersInterval,
        ) || emdash,
    },
  ];

  const requiredService = {
    title: 'Required Service',
    contents: pmRequiredService || emdash,
  };

  const additionalNotes = {
    title: 'Additional Notes',
    contents: pmAdditionalNotes || emdash,
  };

  const accordionContents = [
    {
      title: 'Inspection schedule details',
      content: (
        <>
          <span className="title-m">
            {pmScheduleNumber === pmTitle
              ? pmScheduleNumber
              : `${pmScheduleNumber} - ${pmTitle}`}
          </span>
          {pmType && <PmScheduleTypeTag pmScheduleType={pmType} />}

          <div className="grid-row grid-gap margin-top-3">
            {inpsectionSchduleData.map(({ label, value }) => (
              <div className="grid-col-4 margin-bottom-6">
                <div className="display-flex flex-justify border-bottom border-base-lighter padding-bottom-1">
                  <span className="text-bold"> {label} </span>
                  <span>{value} </span>
                </div>
              </div>
            ))}
          </div>

          <div>
            <div className="text-bold"> {requiredService.title} </div>
            {ReactHtmlParser(requiredService.contents)}
          </div>
          <div className="margin-top-5">
            <div className="text-bold"> {additionalNotes.title} </div>
            <div>{ReactHtmlParser(additionalNotes.contents)}</div>
          </div>
        </>
      ),
      expanded: false,
      id: '123',
      className: 'grid-col-12',
    },
  ];

  return (
    <div className="grid-col-12" aria-label="inspection-schedule-details">
      <Accordion items={accordionContents} />
    </div>
  );
}
