import React from 'react';
import { MILEAGE_CAPTURE_TYPE_OPTIONS } from 'utilities/consts';
import FsrField from './fsr-field';

export const fleetMgmtInfoSection = (
  mode,
  fmcs,
  internalUserViewOnly,
  fmcReadOnlyMode,
) => {
  const sections = [
    {
      sectionLayout: 'horizontal',
      fields: [
        {
          id: 'fmcId',
          label: 'Fleet Management Center (FMC)',
          type: 'select',
          options: [...fmcs],
          required: true,
          disabled: fmcReadOnlyMode,
        },
        {
          id: 'fsrUserEmail',
          label: 'Fleet Service Representative (FSR)',
          required: true,
          readOnly: internalUserViewOnly,

          component: (config, useFormContext) => {
            const { setValue, clearErrors, setError, errors } =
              useFormContext();
            return (
              <FsrField
                id={config?.id}
                setValue={setValue}
                defaultValue={config?.defaultValue}
                readOnly={config?.readOnly}
                clearErrors={clearErrors}
                setError={setError}
                errors={errors}
              />
            );
          },
        },
      ],
    },
    {
      sectionLayout: 'horizontal',
      sections: [
        {
          fields: [
            {
              id: 'customerType',
              label: 'Mileage capture type',
              type: 'select',
              options: MILEAGE_CAPTURE_TYPE_OPTIONS,
              required: false,
              tooltip: 'Method by which mileage is primarily captured',
              readOnly: internalUserViewOnly,
            },
          ],
        },
        {
          fields: [
            {
              id: 'maintenanceReminder',
              label: 'Receive PM reminders',
              type: 'select',
              required: true,
              disabled: internalUserViewOnly,
              options: [
                { value: 'Y', label: 'Yes' },
                { value: 'N', label: 'No' },
              ],
              tooltip: 'Yes - indicates monthly email notification is enabled',
            },
          ],
        },
        {
          fields: [
            {
              id: 'agencySubGroup',
              label: 'Customer agency indicator',
              type: 'text',
              required: false,
              disabled: internalUserViewOnly,
              tooltip:
                '2 or 3 position code to indicate which part of an agency the customer account belongs',
            },
          ],
        },
      ],
    },
  ];
  if (internalUserViewOnly) {
    sections.push({
      sectionLayout: 'horizontal',
      fields: [
        {
          id: 'accountNotes',
          label: 'Internal account notes',
          type: 'textarea',
          disabled: internalUserViewOnly,
          tooltip:
            'Internal account notes are visible only to internal GSA users',
          element: {
            control: {
              maxLength: 100,
              rows: 3,
              autoGrow: true,
              countdown: true,
            },
          },
        },
      ],
    });
  }
  return {
    sections,
  };
};
