// eslint-disable-next-line filenames/match-exported
import React from 'react';
import PropTypes from 'prop-types';
import { emdash } from 'components/common';

export const formatText = (text) => {
  if (Array.isArray(text)) {
    return text.map((item) => (
      <div className="text-right" key={item}>
        {item}
      </div>
    ));
  }
  return text || emdash;
};

export default function OrderDetail({ label, value, anchor }) {
  return (
    <div className="detail-row display-flex flex-justify padding-y-1 border-bottom border-width-2px border-base-lighter">
      <div className="detail-title">
        <strong>{label}</strong>
      </div>
      <div className="detail-value">
        {anchor ? (
          <a href={`#${anchor}`}>{formatText(value)}</a>
        ) : (
          formatText(value)
        )}
      </div>
    </div>
  );
}

OrderDetail.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  anchor: PropTypes.string,
};

OrderDetail.defaultProps = {
  value: '',
  anchor: undefined,
};
