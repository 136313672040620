import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import {
  DatePicker,
  TextInput,
  SelectDropdown,
  RequiredFieldIndicator,
} from '@gsa/afp-component-library';

const TransactionDetailsFields = {
  date: { name: 'transactionDate' },
  invoiceNumber: { name: 'invoiceNumber' },
  transactionCompleted: { name: 'transactionCompleted' },
  mileageAtRepair: { name: 'mileageAtRepair' },
};

const TransactionDetailsForm = () => {
  const {
    formState: { errors },
    control,
  } = useFormContext();

  const { date, invoiceNumber, transactionCompleted, mileageAtRepair } =
    TransactionDetailsFields;

  const maxDate = new Date();

  return (
    <div data-testid="repair-transaction-details-form">
      <Controller
        name={date.name}
        control={control}
        render={({ field, ref }) => {
          return (
            <DatePicker
              {...field}
              ref={ref}
              required
              readOnly
              name={date.name}
              id={date.name}
              maxDate={maxDate.toISOString()}
              label={
                <span className="text-bold">
                  Transaction date <RequiredFieldIndicator />
                </span>
              }
              defaultValue={field.value}
              onChange={(val) => {
                let date = null;
                if (val) {
                  date = new Date(val);
                  date.setHours(0, 0, 0, 0);
                }
                field.onChange(date);
              }}
              errorMessage={
                errors && errors[date.name] ? errors[date.name].message : null
              }
              aria-invalid={errors && errors[date.name] ? 'true' : 'false'}
            />
          );
        }}
      />
      <Controller
        name={invoiceNumber.name}
        control={control}
        render={({ field, ref }) => {
          return (
            <TextInput
              {...field}
              ref={ref}
              type="text"
              label={<span className="text-bold">Invoice number</span>}
              name={invoiceNumber.name}
              id={invoiceNumber.name}
              characterLimit={15}
              errorMessage={
                errors && errors[invoiceNumber.name]
                  ? errors[invoiceNumber.name].message
                  : null
              }
              aria-invalid={
                errors && errors[invoiceNumber.name] ? 'true' : 'false'
              }
            />
          );
        }}
      />
      <Controller
        name={transactionCompleted.name}
        control={control}
        render={({ field, ref }) => {
          return (
            <SelectDropdown
              {...field}
              ref={ref}
              label={<span className="text-bold">Transaction complete</span>}
              name={transactionCompleted.name}
              id={transactionCompleted.name}
              options={[
                { value: 'No', label: 'No (In progress)' },
                { value: 'Yes', label: 'Yes (Done)' },
              ]}
              errorMessage={
                errors && errors[transactionCompleted.name]
                  ? errors[transactionCompleted.name].message
                  : null
              }
              aria-invalid={
                errors && errors[transactionCompleted.name] ? 'true' : 'false'
              }
            />
          );
        }}
      />
      <Controller
        name={mileageAtRepair.name}
        control={control}
        render={({ field, ref }) => {
          return (
            <TextInput
              {...field}
              ref={ref}
              type="number"
              label={
                <span className="text-bold">Vehicle mileage at repair</span>
              }
              name={mileageAtRepair.name}
              id={mileageAtRepair.name}
              errorMessage={
                errors && errors[mileageAtRepair.name]
                  ? errors[mileageAtRepair.name].message
                  : null
              }
              aria-invalid={
                errors && errors[mileageAtRepair.name] ? 'true' : 'false'
              }
              onKeyDown={(e) => {
                if ([69, 187, 189, 190].includes(e.keyCode)) e.preventDefault();
              }}
            />
          );
        }}
      />
    </div>
  );
};

export default TransactionDetailsForm;
