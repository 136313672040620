import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FilterPanel, Button, Label } from '@gsa/afp-component-library';
import DateMonthYear from '../../forms/widgets/DateMonthYear';
import useDateRange from '../hooks/use-date-range';

const DateMonthYearRange = ({
  filter,
  errorMsg,
  fromLabel,
  toLabel,
  buttonLabel,
}) => {
  const {
    dateTo,
    dateFrom,
    error,
    setDateFrom,
    setDateTo,
    clear,
    applyFilter,
  } = useDateRange({ filter, errorMsg });

  const [isInitialFilterApplied, setInitialFilterApplied] = useState(false);

  // clear FilterPanel state when dates become empty locally
  useEffect(() => {
    if (!dateFrom && !dateTo) {
      clear();
      return;
    }
    if (!isInitialFilterApplied) {
      applyFilter();
      setInitialFilterApplied(true);
    }
  }, [dateFrom, dateTo, isInitialFilterApplied]);

  const defaultFrom = filter?.defaultValue?.from;
  const defaultTo = filter?.defaultValue?.to;
  useEffect(() => {
    if (filter?.defaultValue) {
      setDateFrom(moment(defaultFrom).toDate());
      setDateTo(moment(dateTo).toDate());
    }
  }, []);

  return (
    <div className="grid-row">
      <div className="grid-col-10">
        {error && (
          <b className="grid-col-12 padding-top-1 text-error">{error}</b>
        )}
        <div className="grid-col-12">
          <DateMonthYear
            label={<Label className="text-bold">{fromLabel}</Label>}
            error={!!error}
            name={`${filter.key}-from`}
            value={dateFrom}
            defaultMonth={moment(defaultFrom).month() + 1}
            defaultYear={moment(defaultFrom).year()}
            onChange={({ month, year }) => {
              let newDateFrom = '';
              if (month && year) {
                newDateFrom = new Date(`${year}/${month}`);
                newDateFrom.setHours(0, 0, 0, 0);
              }
              setDateFrom(newDateFrom);
            }}
          />
        </div>
        <div className="grid-col-12">
          <DateMonthYear
            label={<Label className="text-bold">{toLabel}</Label>}
            error={!!error}
            name={`${filter.key}-to`}
            value={dateTo}
            defaultMonth={moment(defaultTo).month() + 1}
            defaultYear={moment(defaultTo).year()}
            onChange={({ month, year }) => {
              let newDateTo = '';
              if (month && year) {
                newDateTo = new Date(
                  parseInt(year, 10),
                  parseInt(month, 10),
                  0,
                );
              }
              setDateTo(newDateTo);
            }}
          />
        </div>
        <div className="grid-col-12 padding-top-3">
          <Button
            data-testid={`${filter.key}-range-button`}
            type="button"
            variant="unstyled"
            onClick={applyFilter}
            label={buttonLabel}
          />
        </div>
      </div>
    </div>
  );
};

export const displayValue = (dates) => {
  if (!Array.isArray(dates) || dates.length < 2) {
    return undefined;
  }
  if (!(dates[0] instanceof Date && dates[1] instanceof Date)) {
    return undefined;
  }
  const opts = { year: 'numeric', month: '2-digit' };
  const startDate = dates[0].toLocaleDateString('en-us', opts);
  const endDate = dates[1].toLocaleDateString('en-us', opts);
  return `Between ${startDate} and ${endDate}`;
};

DateMonthYearRange.propTypes = {
  filter: PropTypes.shape(FilterPanel.FilterPanelFilterShape).isRequired,
  errorMsg: PropTypes.string,
  fromLabel: PropTypes.string,
  toLabel: PropTypes.string,
  buttonLabel: PropTypes.string,
};

DateMonthYearRange.defaultProps = {
  errorMsg: 'Please select valid date range',
  fromLabel: 'From date',
  toLabel: 'To date',
  buttonLabel: 'Update results',
};

export default DateMonthYearRange;
