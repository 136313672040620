import React, { useMemo, useState } from 'react';
import { SectionBlock } from '../../vehicle-overview/components';
import { Button, DetailsTable } from '@gsa/afp-component-library';
import { PropTypes } from 'prop-types';
import { EditWheelsAndTires } from './index';

const WheelsAndTires = ({ data, canEdit, onSave }) => {
  const [editWheelsAndTires, setEditWheelsAndTires] = useState(false);

  const tableData = useMemo(() => {
    const rows = [
      ['Tire size', data?.assetTechnical?.tireSize],
      ['Tire load index', data?.assetTechnical?.tireLoadRange],
      ['Tire speed rating', data?.assetTechnical?.tireSpeedRating],
      ['Tires in use', data?.assetTechnical?.tireQuantity],
    ];
    return rows;
  }, [data]);

  const saveTireChanges = (editState) => {
    const fieldsToUpdate = Object.keys(editState).map((key) => ({
      field: key,
      value: editState[key],
    }));
    onSave(fieldsToUpdate);
    setEditWheelsAndTires(false);
  };

  return (
    <>
      <SectionBlock title="Wheels & Tires">
        <DetailsTable
          className="vehicle-overview-details-table"
          data={tableData}
        />
        {canEdit && (
          <Button
            data-testid={`wheels-and-tires-edit`}
            onClick={() => setEditWheelsAndTires(true)}
            variant="outline"
            className="bg-white margin-top-2"
            aria-label={`edit wheels and tires ${data?.id}`}
            label="Edit"
          />
        )}
      </SectionBlock>
      {editWheelsAndTires && (
        <EditWheelsAndTires
          vehicle={data}
          onClose={() => setEditWheelsAndTires(false)}
          onSave={saveTireChanges}
        />
      )}
    </>
  );
};

export default WheelsAndTires;

WheelsAndTires.propTypes = {
  data: PropTypes.shape({
    assetTechnical: PropTypes.shape({
      tireSize: PropTypes.string,
      wheelBase: PropTypes.string,
      cabToAxle: PropTypes.string,
      bumperToBack: PropTypes.string,
      payload: PropTypes.string,
    }),
  }),
  onSave: PropTypes.func,
};
