import React, { useEffect, useState } from 'react';
import { SelectDropdown, TextInput } from '@gsa/afp-component-library';
import { STATES_OCONUS_TERRITORIES } from 'utilities/consts';

const formatUsStates = () => {
  return STATES_OCONUS_TERRITORIES.map((s) => ({ value: s, label: s }));
};

export default function SelectState({ label, countryCode, ...rest }) {
  const [usState, setUsStates] = useState([]);
  useEffect(() => {
    if (countryCode === 'US' || '') {
      setUsStates(formatUsStates());
    }
  }, [countryCode]);
  if (countryCode === 'US') {
    return (
      <SelectDropdown
        label={label || 'State / Province / Region'}
        options={usState}
        {...rest}
      />
    );
  }
  return <TextInput label={label || 'State / Province / Region'} {...rest} />;
}
