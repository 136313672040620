import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Alert } from '@gsa/afp-component-library';
import { useAttachmentComponent } from './component-context';
import DescriptionField from './description-field';

const AttachmentEditForm = () => {
  const {
    onUpdateDescription,
    attachmentErrors,
    editState: { data = {} },
  } = useAttachmentComponent();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { description: data?.description },
  });

  const onSubmit = (formData) => {
    if (!data.modelId) return;
    onUpdateDescription({
      variables: {
        input: {
          id: data.metadataId,
          description: formData.description,
        },
      },
    });
  };

  return (
    <form
      data-testid="attachment-edit-form"
      id="attachment-edit-form"
      onSubmit={handleSubmit(onSubmit)}
    >
      {attachmentErrors?.update?.message && (
        <Alert type="error">{attachmentErrors?.update?.message}</Alert>
      )}
      <Controller
        control={control}
        name="description"
        render={({ field, ref }) => {
          return (
            <DescriptionField
              {...field}
              ref={ref}
              showErrorMessage={errors?.description?.type === 'required'}
            />
          );
        }}
      />
    </form>
  );
};

export default AttachmentEditForm;
