import React from 'react';
import PropTypes from 'prop-types';
import { FilterPanel } from '@gsa/afp-component-library';
import { VMSOperations, VMSSubjects } from '../../../utilities/consts';
import BureauFilterItem from './bureau-filter-item';

const { FilterPanelFilterShape } = FilterPanel;

const LicensePlateBureauFilterItem = ({ filter }) => {
  return (
    <BureauFilterItem
      filter={filter}
      operation={VMSOperations.View}
      subject={VMSSubjects.TAG}
    />
  );
};

LicensePlateBureauFilterItem.propTypes = {
  filter: PropTypes.shape(FilterPanelFilterShape).isRequired,
};

export default LicensePlateBureauFilterItem;
