import React from 'react';
import {
  Button,
  Modal,
  RequiredFieldIndicator,
  Alert,
  Spinner,
} from '@gsa/afp-component-library';
import ReplacementCardFormContext from './replacement-card-form-context';
import ReplacementCardForm from './replacement-card-form';
import { useFcReplacement } from './fc-replacement-context-provider';
import { MAX_ORDERS_LIMIT_MODAL_MESSAGE } from './consts';

const AddReplacementCardModal = () => {
  const {
    alertMessage,
    clearData,
    isAddReplacementCardModalOpen,
    closeAddReplacementCardModal,
    ordersMaxLimitReached,
    editMode,
    submittingReplacementOrder,
    updatingReplacementOrder
  } = useFcReplacement();

  const { message, type, context } = alertMessage;

  const onCloseModal = () => {
    clearData();
    closeAddReplacementCardModal();
  };

  if (!isAddReplacementCardModalOpen) {
    return null;
  }

  if (ordersMaxLimitReached) {
    return (
      <div className="afp-modal-wrapper">
        <div className="afp-modal-overlay">
          <Modal
            id="replacement-card-max-order-modal"
            variant="large"
            title={<h2>{MAX_ORDERS_LIMIT_MODAL_MESSAGE.header}</h2>}
            onClose={onCloseModal}
            actions={
              <>
                <Button
                  variant="unstyled"
                  onClick={onCloseModal}
                  label="Cancel"
                  data-testid="cancel-replacement-card-button"
                />
              </>
            }
          >
            <p>{MAX_ORDERS_LIMIT_MODAL_MESSAGE.body}</p>
          </Modal>
        </div>
      </div>
    );
  }

  return (
    <div className="afp-modal-wrapper">
      <div className="afp-modal-overlay">
        <Modal
          id="replacement-card-order-modal"
          variant="large"
          title={<h2>{editMode ? 'Edit' : 'Add'} replacement card</h2>}
          onClose={onCloseModal}
          actions={
            <>
              <Button
                variant="unstyled"
                onClick={onCloseModal}
                label="Cancel"
                data-testid="cancel-replacement-card-button"
              />
              <Button
                variant="primary"
                type="submit"
                form="replacement-card-form"
                label="Submit"
                disabled={submittingReplacementOrder || updatingReplacementOrder}
                data-testid="submit-replacement-card-button"
              />
              {(submittingReplacementOrder || updatingReplacementOrder) && (
                <Spinner
                  size="small"
                  data-testid="replacement-card-spinner"
                  className="display-inline-block margin-left-2"
                />
              )}
            </>
          }
        >
          {context === 'addReplacementCard' && message && (
            <Alert type={type}>{message}</Alert>
          )}
          <p>Begin by entering a plate number</p>
          <p>
            Required fields are marked with an asterisk (
            <RequiredFieldIndicator />
            ).
          </p>
          <ReplacementCardFormContext>
            <ReplacementCardForm />
          </ReplacementCardFormContext>
        </Modal>
      </div>
    </div>
  );
};

export default AddReplacementCardModal;
