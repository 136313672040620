import React, { useMemo } from 'react';
import { FilterTableFrame } from '@gsa/afp-component-library';
import { Link } from 'react-router-dom';
import OfficeManagementListingTable from './office-management-listing-table';
import OfficeManagementSidebar from './office-management-sidebar';
import OfficeManagementActions from './office-management-actions';
import CustomerAccountHeader from './customer-account-header';

const OfficeManagementListing = () => {
  const FTF = useMemo(
    () =>
      FilterTableFrame(
        null,
        OfficeManagementActions,
        OfficeManagementSidebar,
        OfficeManagementListingTable,
      ),
    [],
  );
  return (
    <>
      <div className="margin-bottom-2" data-testid="customer-accounts">
        <div className="grid-row">
          <div className="grid-col-7">
            <h2>Customer accounts</h2>
            <p>
              Associate GSA leasing customer accounts to this office in the
              table below. You can add multiple accounts to this office, but
              cannot split an account between offices, or add an account to more
              than one office. To add agency owned vehicles to an office, use
              the{' '}
              <Link to="/vehicle-registration">vehicle registration tool</Link>.
              To make changes to an account, contact your Fleet Service
              Representative.
            </p>
          </div>
        </div>
      </div>
      <div className="margin-bottom-2">
        <CustomerAccountHeader />
      </div>
      <FTF filterToggle />
    </>
  );
};

export default OfficeManagementListing;
