import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { FilterPanel, Spinner } from '@gsa/afp-component-library';
import { GET_BUREAUS_BY_PERMISSION } from '../../../services/data-layer';

const { FilterPanelField, FilterPanelFilterShape, useFilterPanel } =
  FilterPanel;

const BureauFilterItem = ({
  filter,
  subject,
  operation,
  agencyFilterPath,
  bureauFilterPath,
}) => {
  const { getFilterFromState, mergedFilters, clearOneFilter } =
    useFilterPanel();
  const [options, setOptions] = useState([]);
  const [agencyCode, setAgencyCode] = useState();
  const [retainedAgencyCode, setRetainedAgencyCode] = useState(true);
  const [burueauCode, setBurueauCode] = useState();
  const [getBureaus, { data: bureauOptions, loading }] = useLazyQuery(
    GET_BUREAUS_BY_PERMISSION,
    {
      fetchPolicy: 'network-only',
    },
  );

  useEffect(() => {
    const agencyFilter =
      getFilterFromState(agencyFilterPath) ||
      getFilterFromState('$customer.customer_agency_code$') ||
      getFilterFromState('customerAgencyCode') ||
      {};
    setAgencyCode(agencyFilter.value);
    setRetainedAgencyCode(agencyFilter.retained);
    const bureauFilter =
      getFilterFromState(bureauFilterPath) ||
      getFilterFromState('$customer.customer_bureau_code$') ||
      getFilterFromState('customerBureauCode') ||
      {};
    setBurueauCode(bureauFilter.value);
  }, [mergedFilters]);

  useEffect(() => {
    // clear bureau when agency is changed
    if (!retainedAgencyCode) {
      clearOneFilter(filter);
      setOptions([]);
    }
    if (agencyCode) {
      getBureaus({
        variables: {
          agencyCode,
          operation,
          subject,
          order: 'id ASC',
        },
      });
    }
  }, [agencyCode, retainedAgencyCode]);

  useEffect(() => {
    if (bureauOptions?.getBureausByPermission) {
      const formattedBureauOptions = bureauOptions.getBureausByPermission.map(
        (bureauDetails) => ({
          value: bureauDetails.id,
          label: bureauDetails.prefixedName,
        }),
      );
      setOptions(formattedBureauOptions);
    }
  }, [bureauOptions]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="margin-top-3">
      <FilterPanelField
        key={[filter.key]}
        filter={{
          ...filter,
          type: 'multiselectdropdown',
          options,
          value: burueauCode || [],
        }}
      />
    </div>
  );
};

BureauFilterItem.defaultProps = {
  agencyFilterPath: '',
  bureauFilterPath: '',
  subject: '',
  operation: '',
};

BureauFilterItem.propTypes = {
  filter: PropTypes.shape(FilterPanelFilterShape).isRequired,
  subject: PropTypes.string,
  operation: PropTypes.string,
  agencyFilterPath: PropTypes.string,
  bureauFilterPath: PropTypes.string,
};

export default BureauFilterItem;
