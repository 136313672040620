import React, { useState } from 'react';
import { FilterPanel } from '@gsa/afp-component-library';
import { useLazyQuery } from '@apollo/client';
import { GET_CUSTOMER_FSR_EMAILS_BY_PARTIAL_EMAIL } from 'components/customer-accounts/customer-account-gql';
import { GET_RECALL_CAMPAIGN_OPTIONS } from 'services/data-layer';
import _, { uniq } from 'lodash';
import { useRecallFilter } from '../providers/filter-provider';

const campaignIdFilterPath = '$recallCampaign.recall_code$';
const nhtsaCampaignIdFilterPath = '$recallCampaign.nhtsa_campaign_id$';
const fsrEmailFilterPath = '$vehicle.fsr_user_email_address$';

const Sidebar = () => {
  const { filterStructure, setFilters } = useRecallFilter();
  const handleFilterChange = (conditions) => {
    let filters = conditions;
    if (conditions.length > 0) {
      if (
        conditions.some(
          (filterVal) =>
            filterVal.key === '$vehicle.customer.legacy_customer_number$',
        )
      ) {
        const customerNumberIndex = _.findIndex(conditions, {
          key: '$vehicle.customer.legacy_customer_number$',
        });
        const updatedConditions = conditions.filter(
          (condition) =>
            condition.key !== '$vehicle.customer.legacy_customer_number$',
        );

        filters = {
          legacyCustomerNumber: [
            {
              operator: '$and',
              conditions: updatedConditions,
            },
            {
              operator: '$or',
              conditions: [
                {
                  operator: '$exact',
                  key: '$vehicle.customer.legacy_customer_number$',
                  value: conditions[customerNumberIndex].value,
                },
                {
                  operator: '$exact',
                  key: '$vehicle.customer.customer_account_id$',
                  value: conditions[customerNumberIndex].value,
                },
              ],
            },
          ],
        };
      }
      setFilters(filters);
    }
  };
  const [typeaheadData, setTypeaheadData] = useState([]);

  const [searchCampaignId] = useLazyQuery(GET_RECALL_CAMPAIGN_OPTIONS, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setTypeaheadData({
        field: campaignIdFilterPath,
        values: data?.getRecallCampaigns?.recallCodes,
      });
    },
  });

  const [searchNhtsaCampaignId] = useLazyQuery(GET_RECALL_CAMPAIGN_OPTIONS, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setTypeaheadData({
        field: nhtsaCampaignIdFilterPath,
        values: data?.getRecallCampaigns?.nhtsaCampaignIds,
      });
    },
  });

  const [getCustomerFSREmailsByPartialEmail] = useLazyQuery(
    GET_CUSTOMER_FSR_EMAILS_BY_PARTIAL_EMAIL,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        const fsrEmails = data.getCustomerFSREmailsByPartialEmail.map(
          (account) => account.fsrUserEmail,
        );
        setTypeaheadData({
          field: fsrEmailFilterPath,
          values: uniq(fsrEmails),
        });
      },
    },
  );

  const handleTypeaheadSearch = ({ variables }) => {
    const { conditions } = variables.filters[0];
    const query = conditions.filter((c) => c.key === variables.field)[0]?.value;
    if (variables.field === campaignIdFilterPath) {
      searchCampaignId({
        variables: {
          distinctField: 'recall_code',
          filters: [
            {
              operator: '$and',
              conditions: [
                {
                  operator: '$startsWith',
                  key: 'recall_code',
                  value: query,
                },
              ],
            },
          ],
        },
      });
    } else if (variables.field === nhtsaCampaignIdFilterPath) {
      searchNhtsaCampaignId({
        variables: {
          distinctField: 'nhtsa_campaign_id',
          filters: [
            {
              operator: '$and',
              conditions: [
                {
                  operator: '$startsWith',
                  key: 'nhtsa_campaign_id',
                  value: query,
                },
              ],
            },
          ],
        },
      });
    } else if (variables.field === fsrEmailFilterPath) {
      getCustomerFSREmailsByPartialEmail({
        variables: {
          limit: 25,
          fsrUserEmail: query,
        },
      });
    }
  };

  return (
    <FilterPanel.FilterPanel
      filterStructure={filterStructure}
      setQueryFiltersState={handleFilterChange}
      fetchTypeaheads={handleTypeaheadSearch}
      typeaheadData={typeaheadData}
      clearButtonLabel="Reset all"
      showClearIcon
      order={[['id', 'ASC']]}
      showSearchIcon
    />
  );
};

export default Sidebar;
