import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@gsa/afp-component-library';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { PageMode, useRepairOrder } from '../providers';

const Actions = ({ mode, onSave }) => {
  const { vehicle, savedSuccefully, repairOrder } = useRepairOrder();
  const vin = vehicle?.vin;
  const repairOrderNumber = repairOrder?.repairOrderNumber;
  const isNew = mode === PageMode.New;
  const history = useHistory();

  const redirectToView = () => {
    const hrefs = window.location.href.split('/');
    if (isNew) {
      hrefs[hrefs.length - 1] = `${encodeURIComponent(repairOrderNumber)}/${
        PageMode.View
      }`;
    } else {
      hrefs[hrefs.length - 1] = PageMode.View;
    }
    window.location.assign(hrefs.join('/'));
  };
  const redirectToEdit = () => {
    const hrefs = window.location.href.split('/');
    hrefs[hrefs.length - 1] = PageMode.Edit;
    window.location.assign(hrefs.join('/'));
  };
  const redirectToVehicle = () => {
    const host = window.location.href.split('/vehicle/')[0];
    window.location.replace(
      `${host}/vehicles/${encodeURIComponent(vin)}/repair`,
    );
  };

  const handleCancel = () => {
    if (mode === PageMode.New) {
      redirectToVehicle();
    } else {
      redirectToView();
    }
  };
  const handleBack = () => {
    redirectToVehicle();
  };
  const handleEdit = () => {
    redirectToEdit();
  };

  const isEditing = () => mode !== PageMode.View;
  const isVehicleActive = () =>
    vehicle?.inventoryStatus?.id !== 'MS' &&
    vehicle?.inventoryStatus?.id !== 'SD';

  useEffect(() => {
    const text = isNew
      ? 'Repair order successfully created.'
      : 'Repair order successfully modified.';
    if (savedSuccefully) {
      history.push({
        pathname: `/vehicles/${vin}/repair`,
        state: {
          message: {
            type: 'success',
            text,
          },
        },
      });
    }
  }, [savedSuccefully]);

  const EditButtons = () => {
    return (
      <div>
        <Button
          data-testid="repair-order-cancel-button"
          type="button"
          variant="outline"
          onClick={handleCancel}
          label="Cancel"
        />
        <Button
          data-testid="repair-order-submit-button"
          type="button"
          variant="primary"
          form="repair-order-form"
          label="Save repair order"
          leftIcon={{ name: 'check' }}
          onMouseDown={(evt) => {
            // Order onClick event before onBlur event
            // Solution for prevent click twice on the save button.
            evt.preventDefault();
          }}
          onClick={() => {
            onSave();
          }}
        />
      </div>
    );
  };
  const ViewButton = () => {
    return (
      <div>
        <Button
          data-testid="repair-order-cancel-button"
          type="button"
          variant="outline"
          onClick={handleBack}
          label="Go back"
        />
        <Button
          data-testid="repair-order-edit-button"
          type="button"
          variant="primary"
          onClick={handleEdit}
          label="Edit repair order"
          leftIcon={{ name: 'check' }}
        />
      </div>
    );
  };

  if (!isVehicleActive()) {
    return null;
  }

  if (isEditing()) {
    return <EditButtons />;
  }

  return <ViewButton />;
};

Actions.propTypes = {
  mode: PropTypes.string.isRequired,
};

export default Actions;
