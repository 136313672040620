import { orderBy } from 'lodash';
import moment from 'moment';
import { MILEAGE_HISTORY_STATUS } from './consts';

export const getLastReportedMileage = (
  mileageHistory,
  excludeEstimated = false,
) => {
  let mileageHistoryList =
    mileageHistory?.getMileageByVehicle?.rows ||
    mileageHistory?.getMileageByGFVehicle?.rows;
  mileageHistoryList = mileageHistoryList?.filter(
    (mileage) => mileage?.status !== 'Pending',
  ); // exclude pending ones
  if (excludeEstimated) {
    mileageHistoryList = mileageHistoryList?.filter(
      (mileage) => mileage?.sourceType !== 'Estimated',
    ); // exclude Estimated ones
  }
  return orderBy(mileageHistoryList, 'mileageDate', 'desc')?.[0];
};

export const isReportedOrPendingMileage = (mileageReportingStatus) => {
  return (
    mileageReportingStatus === MILEAGE_HISTORY_STATUS.Reported ||
    mileageReportingStatus === MILEAGE_HISTORY_STATUS.Pending ||
    mileageReportingStatus === MILEAGE_HISTORY_STATUS.Telematics
  );
};

export const isMileageBetweenAllowedThreshold = (
  allowedThreshold,
  mileageHistory,
  enteredMileage,
) => {
  const lastMileageHistoryRecord = getLastReportedMileage(mileageHistory);
  const lastOdometer = lastMileageHistoryRecord?.odometer || 0;
  const mileage = enteredMileage ? Number(enteredMileage) : 0;
  return !enteredMileage || Math.abs(mileage - lastOdometer) < allowedThreshold;
};

export const isMileageReportedForThisMonth = (
  lastAssetMileageReportingStatusRecord,
) => {
  if (
    !lastAssetMileageReportingStatusRecord ||
    !lastAssetMileageReportingStatusRecord?.length
  ) {
    return false;
  }
  const record = lastAssetMileageReportingStatusRecord?.[0];
  const lastAssetMileageReportingStatusRecordDate = moment(
    `${record?.mileageReportingMonth}-01-${record?.mileageReportingYear}`,
  );
  return (
    moment(lastAssetMileageReportingStatusRecordDate).isSame(
      moment(),
      'month',
    ) && isReportedOrPendingMileage(record?.mileageReportingStatus)
  );
};
