import { useState } from 'react';

const DEFAULT_STEP = 1;
const STEP_MACHINE = {
  1: {
    PREV: 1,
    NEXT: 2,
  },
  2: {
    PREV: 1,
    NEXT: 3,
  },
  3: {
    NEXT: 3,
    PREV: 2,
  },
};

export default function stepsFsm() {
  const [step, setStep] = useState(DEFAULT_STEP);

  const next = () => {
    const nextStep = STEP_MACHINE[step].NEXT;
    setStep(nextStep);
    window.scrollTo(0, 0);
  };
  const prev = () => {
    const prevStep = STEP_MACHINE[step].PREV;
    setStep(prevStep);
    window.scrollTo(0, 0);
  };
  const goToStep = (stepNum) => {
    if (STEP_MACHINE[stepNum]) {
      setStep(stepNum);
      window.scrollTo(0, 0);
    }
  };
  return [step, prev, next, goToStep];
}
