/* eslint-disable react/prop-types */
import React from 'react';
import { Button } from '@gsa/afp-component-library';
import { useExport } from '../../export-provider';

export const FormButtons = ({
  isFirstStep,
  isLastStep,
  decrementStep,
  submitLabel,
  scrollToStepper,
  stepIndex,
}) => {
  const { selectedReport } = useExport();

  const renderPreviousButton = () => {
    const prevButton = (
      <Button
        size="sm"
        data-testid="form-btn-prev"
        type="button"
        variant="outline"
        aria-label="Previous form"
        leftIcon={{ name: 'arrow_back' }}
        label="Previous"
        onClick={() => {
          decrementStep();
          scrollToStepper();
        }}
      />
    );
    if (selectedReport) {
      if (!isFirstStep && stepIndex !== 1) {
        return prevButton;
      }
    } else if (!isFirstStep) {
      return prevButton;
    }
    return null;
  };

  return (
    <div>
      {renderPreviousButton()}
      {!isLastStep && (
        <Button
          size="sm"
          data-testid="orm-btn-next"
          variant="primary"
          rightIcon={{ name: 'arrow_forward' }}
          id="form-btn-save"
          aria-label="Next form"
          hidden="hidden"
          type="submit"
          label="Next"
        />
      )}
      {isLastStep && (
        <Button
          size="sm"
          data-testid="form-btn-save"
          variant="primary"
          leftIcon={{ name: 'check' }}
          id="form-btn-save"
          aria-label="Submit form"
          hidden="hidden"
          type="submit"
          label={submitLabel || 'Submit form'}
        />
      )}
    </div>
  );
};
