import React from 'react';

import { useParams } from 'react-router-dom';
import OfficeManagementProvider from './office-management-provider';
import OfficeManagementHeader from './office-management-header';

import OfficeDetails from './office-details';
import OfficeManagementListing from './office-management-listing';
import OfficeManagementFilterProvider from './filters/filter-provider';
import PageBreadCrumbs from './page-bread-crumbs';
import OfficeManagementPageTitle from './office-management-page-title';
import OfficeModal from './office-modal';

const OfficeManagement = () => {
  const { organization } = useParams();
  const organizationData = JSON.parse(decodeURIComponent(organization));
  return (
    <>
      <OfficeManagementProvider organizationData={organizationData}>
        <OfficeManagementFilterProvider>
          <PageBreadCrumbs />
          <OfficeManagementPageTitle />
          <div data-testid="office-management-header">
            <OfficeManagementHeader />
          </div>
          <div className="margin-bottom-3" data-testid="office-details">
            <OfficeDetails />
          </div>

          <div className="margin-top-8" data-testid="customer-accounts-listing">
            <OfficeManagementListing />
          </div>
        </OfficeManagementFilterProvider>

        <OfficeModal />
      </OfficeManagementProvider>
    </>
  );
};

export default OfficeManagement;
