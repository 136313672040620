import React from 'react';
import { TextInput } from '@gsa/afp-component-library';

export default function LabelOrInput({
  label,
  value,
  children,
  readOnly = false,
}) {
  if (readOnly) {
    return (
      <div tabIndex={0}>
        <TextInput
          label={label}
          required
          value={value}
          name={label}
          type="text"
          disabled={readOnly}
          data-testid={label}
        />
      </div>
    );
  }
  return children;
}
