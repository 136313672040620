import React, { useMemo } from 'react';
import { TabSet } from '@gsa/afp-component-library';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import { Link } from 'react-router-dom';
import { canManageMotorPools } from 'utilities/authorization';
import MotorPoolVehiclesListing from './vehicles/motor-pool-vehicles-listing';
import MembersListing from './members/members-listing';
import ReservationsListing from './reservations/reservations-listing';

const PoolDetailsTabs = () => {
  const ability = useAppAbility();
  const isAdmin = canManageMotorPools(ability);

  const membersTabHelperText = () => {
    return (
      <>
        <p>
          Use this feature to add or remove members from this motor pool.
          Existing users on GSAFleet.gov can be added to the motor pool by
          clicking on the Add member button below. If you want to invite a new
          user to the motor pool who is not part of GSAFleet.gov, please use the{' '}
          <a
            href={`${window.AFP_CONFIG.appURLs.home}/admin/user-invitations`}
            target="_blank"
            rel="noopener noreferrer"
            className="body-bold"
          >
            invitation management
          </a>{' '}
          to invite the user. Once the user is successfully registered, they can
          be added to this motor pool.
        </p>
      </>
    );
  };

  const vehiclesTabHelperText = () => {
    if (isAdmin) {
      return (
        <p>
          Use this feature to create new reservations. You can use the
          availability dates filter to reserve vehicles. You can also add or
          update vehicle location/comments, and remove existing vehicles from
          this motor pool. If you would like to add new vehicles to this pool,
          you can do so from the{' '}
          <Link to="/vehicles" className="body-bold">
            Vehicle Inventory
          </Link>{' '}
          page by using the <b>Add vehicle to pool</b> action.
        </p>
      );
    }
    return (
      <p>
        Use this feature to create new reservations. If you would like to
        reserve a vehicle, use availability dates filter.
      </p>
    );
  };

  const reservationsTabHelperText = () => {
    return (
      <p>
        Use this feature to view your current reservations, and start or
        close/complete a reservation. Existing reservations can be edited or
        canceled. If you would like to create a new reservation, you can do so
        from the <b>Vehicles tab</b> by using the <b>Reserve vehicle</b> action.
      </p>
    );
  };

  const tabList = useMemo(() => {
    const list = [
      {
        heading: 'Current Reservations',
        content: (
          <div className="grid-row">
            <div className="grid-col-12">
              <div className="grid-col-9">{reservationsTabHelperText()}</div>
              <ReservationsListing />
            </div>
          </div>
        ),
        tabSelectedWhenOpen: false,
      },
      {
        heading: 'Members',
        content: (
          <div className="grid-row">
            <div className="grid-col-12">
              <div className="grid-col-9">{membersTabHelperText()}</div>
              <MembersListing />
            </div>
          </div>
        ),
        tabSelectedWhenOpen: false,
      },
      {
        heading: 'Vehicles',
        content: (
          <div className="grid-row">
            <div className="grid-col-12">
              <div className="grid-col-9">{vehiclesTabHelperText()}</div>
              <MotorPoolVehiclesListing />
            </div>
          </div>
        ),
        tabSelectedWhenOpen: false,
      },
    ];

    if (!isAdmin) list.splice(1, 1);

    return list;
  }, []);

  return (
    <div id="motor-pool-tabs">
      <TabSet tabs={tabList} />
    </div>
  );
};

export default PoolDetailsTabs;
