import { Button } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';
import React from 'react';
import AttachmentModal from './attachment-modal';
import { useAttachmentComponent } from './component-context';

const AttachmentDelete = ({ title }) => {
  const { deleting, onDelete, deleteState, resetDeleteState } =
    useAttachmentComponent();

  return (
    <AttachmentModal
      id="attachment-delete-confirm"
      className="attachment-delete-modal"
      title={title}
      show={deleteState?.show}
      onClose={resetDeleteState}
      actions={
        <>
          <Button
            type="button"
            variant="outline"
            label="Cancel"
            onClick={resetDeleteState}
          />
          <Button
            disabled={deleting}
            variant="secondary"
            className="delete-document-button action-button"
            onClick={() => {
              resetDeleteState();
              if (deleteState?.data?.modelId) onDelete(deleteState.data);
            }}
            label="Delete Document"
          />
        </>
      }
    >
      <p>
        Are you sure you want to delete
        <strong>{` ${deleteState?.data?.name}`}</strong>
        <span>? This action cannot be undone.</span>
      </p>
    </AttachmentModal>
  );
};

AttachmentDelete.defaultProps = {
  title: <h2>Delete Document</h2>,
};

AttachmentDelete.propTypes = {
  title: PropTypes.node,
};

export default AttachmentDelete;
