import React, { useRef } from 'react';
import { useRegistration } from '../../reg-context-provider';
import Poc from './poc/poc';

export default function PocInformation() {
  const { formState, updatePoc, setFormState, validations } = useRegistration();

  const poc2Ref = useRef(null);

  const getPocSpecificValidations = (pocNum) => {
    const poc = `pointOfContact${pocNum}`;
    return Object.keys(validations).reduce((vals, field) => {
      if (field.includes(poc)) {
        const genericField = field.replace(`${poc}.`, '');
        vals[genericField] = validations[field];
      }
      return vals;
    }, {});
  };
  const handlePocValidChange = (poc, pocNum) => {
    updatePoc(pocNum, poc, pocNum === '1' ? poc2Ref : null);
  };
  return (
    <>
      <Poc
        pocNumber={1}
        label="Point of Contact #1"
        pointOfContact={formState.pointOfContact1}
        otherEmail={formState?.pointOfContact2?.email}
        onEmailChange={(poc) => {
          setFormState({ pointOfContact1: { ...poc } });
        }}
        onValidPocChange={(poc) => {
          handlePocValidChange(poc, '1');
        }}
        validations={getPocSpecificValidations('1')}
      />
      <Poc
        pocNumber={2}
        label="Point of Contact #2"
        pointOfContact={formState.pointOfContact2}
        otherEmail={formState?.pointOfContact1?.email}
        onEmailChange={(poc) => {
          setFormState({ pointOfContact2: { ...poc } });
        }}
        onValidPocChange={(poc) => {
          handlePocValidChange(poc, '2');
        }}
        validations={getPocSpecificValidations('2')}
        inputRef={poc2Ref}
      />
    </>
  );
}
