// eslint-disable-next-line filenames/match-exported
import React from 'react';
import { DatePicker, Label, RadioButton } from '@gsa/afp-component-library';
import { REPORT_TYPE } from 'utilities/consts';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import moment from 'moment';

export const startDate = {
  label: 'Start date',
  name: 'pickupDate',
  id: 'pickupDate',
  dataId: 'pickupDate',
  required: true,
};
export const endDate = {
  label: 'End date',
  name: 'returnDate',
  id: 'returnDate',
  dataId: 'returnDate',
  required: true,
};
const ReservationExportFields = ({ reportType }) => {
  const {
    clearErrors,
    formState: { errors },
    control,
  } = useFormContext();

  const startDateValue = useWatch({
    name: startDate.name,
  });

  const endDateValue = useWatch({
    name: endDate.name,
  });
  // type = RESERVATION_EXPORT(default) or MOTOR_POOL_UTILIZATION_REPORT
  // in the future GSA has plan to show both Scheduled and Actual for MOTOR_POOL_UTILIZATION_REPORT after MVP
  const options =
    reportType === REPORT_TYPE.MOTOR_POOL_UTILIZATION_REPORT
      ? [{ label: 'Actual', radioValue: 'actual' }]
      : [
          { label: 'Scheduled', radioValue: 'scheduled' },
          { label: 'Actual', radioValue: 'actual' },
        ];

  return (
    <>
      <div>
        <div className="margin-top-6">
          <b>Dates</b>
        </div>
        <Controller
          control={control}
          name="dateCriteria"
          defaultValue={options[0].radioValue}
          render={({ field: { onChange, name, value } }) => (
            <>
              {options.map(({ radioValue, label, ...restProps }) => {
                return (
                  <RadioButton
                    name={name}
                    key={radioValue}
                    value={radioValue}
                    checked={radioValue === value}
                    label={<p>{label}</p>}
                    {...restProps}
                    onChange={(e) => {
                      onChange(e.target.value);
                    }}
                  />
                );
              })}
            </>
          )}
        />
      </div>
      <div className="display-flex desktop:flex-row margin-top-neg-2">
        <div className="grid-col-5">
          <Controller
            name={startDate.name}
            control={control}
            rules={{ required: `${startDate.label} is required.` }}
            render={({ field, ref }) => {
              return (
                <DatePicker
                  {...field}
                  ref={ref}
                  name={startDate.name}
                  id={startDate.id}
                  label={
                    <Label required className="text-bold">
                      {startDate.label}
                    </Label>
                  }
                  hint="mm/dd/yyyy"
                  maxDate={endDateValue}
                  onChange={(val) => {
                    field.onChange(
                      val ? moment(val).format('YYYY-MM-DD') : null,
                    );
                    clearErrors(startDate.name);
                  }}
                  errorMessage={
                    errors && errors[startDate.name]
                      ? errors[startDate.name].message
                      : null
                  }
                  aria-invalid={
                    errors && errors[startDate.name] ? 'true' : 'false'
                  }
                />
              );
            }}
          />
        </div>
      </div>
      <div className="display-flex flex-row margin-top-neg-2">
        <div className="grid-col-5">
          <Controller
            name={endDate.name}
            control={control}
            rules={{ required: `${endDate.label} is required.` }}
            render={({ field, ref }) => {
              return (
                <DatePicker
                  {...field}
                  ref={ref}
                  name={endDate.name}
                  id={endDate.id}
                  label={
                    <Label required className="text-bold">
                      {endDate.label}
                    </Label>
                  }
                  hint="mm/dd/yyyy"
                  minDate={startDateValue}
                  onChange={(val) => {
                    field.onChange(
                      val ? moment(val).format('YYYY-MM-DD') : null,
                    );
                    clearErrors(endDate.name);
                  }}
                  errorMessage={
                    errors && errors[endDate.name]
                      ? errors[endDate.name].message
                      : null
                  }
                  aria-invalid={
                    errors && errors[endDate.name] ? 'true' : 'false'
                  }
                />
              );
            }}
          />
        </div>
      </div>
    </>
  );
};

export default ReservationExportFields;
