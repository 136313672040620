/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';

import { FilterTableFrame } from '@gsa/afp-component-library';
import LicensePlateFilters from './license-plate-filters';
import OrderTagTable from './order-tag-table';

const OrderTagFilterGrid = ({ onReconcile }) => {
  const FilterGrid = useMemo(
    () => FilterTableFrame(null, null, LicensePlateFilters, OrderTagTable),
    [],
  );

  return <FilterGrid onReconcile={onReconcile} />;
};

export default OrderTagFilterGrid;
