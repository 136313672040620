import React, { useEffect, useState } from 'react';
import { Button, TextInput, DatePicker } from '@gsa/afp-component-library';
import usePortalModal from '../../../utilities/portal-modal';
import { useOrder } from '../context';

export default function DetailsEditModal({ editing, setEditOrderDetails }) {
  const { details, updateOrderDetails } = useOrder();

  const [dateOrdered, setDateOrdered] = useState(details.orderedDate);
  const [dateShipped, setDateShipped] = useState(details.shippedDate);
  const [dateReshipped, setDateReshipped] = useState(details.reshippedDate);
  const [expirationDate, setExpirationDate] = useState(
    details.tagExpirationDates[0],
  );
  const [errors, setErrors] = useState({});

  const [Modal, openModal, closeModal] = usePortalModal();

  useEffect(() => {
    if (editing) openModal();
    else closeModal();
  }, [editing]);

  const validateInputOnBlur = (input) => {
    const regex = /^(0[1-9]|1[0-2])\/20[0-9]{2}$/;
    return input === '' || regex.test(input);
  };

  const validateInputOnChange = (input) => {
    const regex = /^(0[1-9]?|1[0-2]?)?\/?[0-9]{0,4}$/;
    return regex.test(input);
  };

  const getFormattedExpirationDate = () => {
    const dateParts = expirationDate.split('/');
    return +(dateParts[1] + dateParts[0]);
  };

  const handleSave = () => {
    const updateInput = {
      orderNumber: details.orderNumber,
      orderedDate: dateOrdered,
      shippedDate: dateShipped,
      reshippedDate: dateReshipped,
    };

    if (expirationDate && !errors.expirationDate)
      updateInput.expirationDate = getFormattedExpirationDate();

    updateOrderDetails({
      variables: {
        updateInput,
      },
    });
    setEditOrderDetails(false);
  };

  const handleClose = () => {
    setEditOrderDetails(false);
  };

  return (
    <Modal
      title={<h2>Update details</h2>}
      onClose={handleClose}
      variant="large"
      actions={
        <>
          <Button
            type="button"
            variant="unstyled"
            onClick={handleClose}
            label="Cancel"
          />
          <Button
            type="button"
            variant="primary"
            data-testid="agency-edit-button"
            onClick={handleSave}
            label="Save and close"
          />
        </>
      }
    >
      <div className="edit-poc grid-row grid-gap">
        <div className="grid-col-7">
          <DatePicker
            type="text"
            label="Date Ordered"
            name="dateOrdered"
            hint="mm/dd/yyyy"
            defaultValue={dateOrdered}
            onChange={(val) => {
              setDateOrdered(val);
            }}
          />
        </div>
        <div className="grid-col-7">
          <DatePicker
            type="text"
            label="Date Shipped"
            name="dateShipped"
            hint="mm/dd/yyyy"
            defaultValue={dateShipped}
            onChange={(val) => {
              setDateShipped(val);
            }}
          />
        </div>
        <div className="grid-col-7">
          <DatePicker
            type="text"
            label="Date Reshipped"
            name="dateReshipped"
            hint="mm/dd/yyyy"
            defaultValue={dateReshipped}
            onChange={(val) => {
              setDateReshipped(val);
            }}
          />
        </div>
        <div className="grid-col-7">
          <TextInput
            type="text"
            label="Expiration Date"
            name="expirationDate"
            value={expirationDate}
            onChange={(e) => {
              if (validateInputOnChange(e.target.value))
                setExpirationDate(e.target.value);
            }}
            onBlur={(e) => {
              if (!validateInputOnBlur(e.target.value))
                setErrors({
                  expirationDate: 'Please enter in the format mm/yyyy',
                });
              else setErrors({ ...errors, expirationDate: '' });
            }}
            errorMessage={errors.expirationDate}
          />
        </div>
      </div>
    </Modal>
  );
}
