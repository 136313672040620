import React from 'react';
import {
  AFPTable,
  Pagination,
  Icon,
  EmptyState,
  Spinner,
} from '@gsa/afp-component-library';
import { Link } from 'react-router-dom';
import { startCase, toLower } from 'lodash';
import moment from 'moment';
import { useOrders } from '../context';
import tableHeaderStructure from './table-widgets/table-header';
import OrderStatus from './table-widgets/order-status-badge';
import OrderActions from './table-widgets/order-action';
import usePaginationManager from '../helpers/pagination-manager';
import OrderDetails from './table-widgets/order-details';
import useSortManager from '../helpers/sort-manager';

const TABLE_ICON_COLLAPSED = (
  <Icon className="usa-icon--size-4" iconName="navigate_next" />
);
const TABLE_ICON_EXPANDED = (
  <Icon className="usa-icon--size-4" iconName="expand_more" />
);

export default function OrdersTable({ filters = [] }) {
  let tableData = [];
  const { orders, limit, loading } = useOrders();
  const [sort] = useSortManager();
  const [handlePaginationChange] = usePaginationManager();

  if (orders) {
    tableData = orders.rows.map((row) => ({
      ...row,
      createdAt: row.createdAt
        ? moment(row.createdAt).format('MM/DD/yyyy')
        : '-',
      orderNumber: (
        <Link to={`/order/${encodeURIComponent(row.orderNumber)}`}>
          {row.orderNumber}
        </Link>
      ),
      pocName: startCase(toLower(row.pocName)),
      staticOrderNumber: row.orderNumber,
      orderStatusCode: <OrderStatus orderStatus={row.orderStatusCode} />,
      orderedAt: '-',
      processedAt: row.processedAt
        ? new Date(row.processedAt).toLocaleDateString()
        : '-',
      action: <OrderActions order={row} />,
      subRowStructure: [<OrderDetails order={row} />],
    }));
  }

  return (
    <div className="orders-content flex-10">
      <AFPTable
        columns={tableHeaderStructure}
        iconCollapsed={TABLE_ICON_COLLAPSED}
        iconExpanded={TABLE_ICON_EXPANDED}
        data={!loading ? tableData : []}
        testId="afp-responsive-table-test-id"
        fullWidth
        bordered={false}
        expandable
        scrollable={false}
        stacked
        renderRowSubComponent={OrderDetails}
        onSort={(order) => sort(order.split(' '))}
      />
      {loading && <Spinner className="padding-y-9" />}
      {tableData && tableData.length != 0 && (
        <Pagination
          variant="advanced"
          itemsPerPage={limit}
          itemsCount={orders.count}
          onPageChange={handlePaginationChange}
        />
      )}

      {(tableData == null || tableData.length == 0) &&
        !loading &&
        filters[0]?.conditions?.length == 0 && (
          <div className="bg-gray-3 padding-y-5">
            <div className="text-center padding-y-4">
              <EmptyState alt="Image not available" hasBackground />
            </div>
            <div className="text-center text-bold">
              No license plate orders showing
            </div>
            <div className="text-center margin-top-2">
              Add or remove filters to search license plate orders
            </div>
          </div>
        )}
      {(tableData == null || tableData.length == 0) &&
        !loading &&
        filters[0]?.conditions?.length != 0 && (
          <div className="bg-gray-3 padding-y-5">
            <div className="text-center padding-y-4">
              <EmptyState alt="Image not available" hasBackground />
            </div>
            <div className="text-center text-bold">No Results Found</div>
          </div>
        )}
    </div>
  );
}
