/* eslint-disable import/prefer-default-export */
// eslint-disable-next-line import/prefer-default-export
import { allowAlphaNumeric, onlyAllowNumber } from 'utilities/format';
import atomStateSchema from './atom-state-schema';

export const LayoutSchema = [
  {
    stateIds: [...Object.keys(atomStateSchema.formFields)],
    sections: [
      {
        name: null,
        isVisible: true,
        fields: [
          {
            type: 'row',
            columns: [
              {
                gridStyle: 'desktop:grid-col-6',
                type: 'TextInputField',
                characterLimit: 4,
                id: 'lastFourAccountNum',
                keyPressFilter: allowAlphaNumeric,
                label: 'Last 4 digit of account number',
              },
              {
                gridStyle: 'desktop:grid-col-6',
                type: 'TextInputField',
                characterLimit: 4,
                keyPressFilter: onlyAllowNumber,
                id: 'lastFourCardNum',
                label: 'Last 4 digit of card number',
              },
            ],
          },
          {
            type: 'row',
            columns: [
              {
                gridStyle: 'desktop:grid-col-6',
                type: 'DatePickerField',
                maxDate: new Date().toISOString(),
                id: 'transactionDate',
                label: 'Transaction date',
                required: true,
              },
              {
                gridStyle: 'desktop:grid-col-6',
                type: 'MilesInputField',
                id: 'transactionOdometer',
                label: 'Transaction odometer',
              },
            ],
          },
          {
            type: 'row',
            columns: [
              {
                gridStyle: 'desktop:grid-col-6',
                type: 'SelectDropDownField',
                id: 'productDescription',
                label: 'Product description',
                required: true,
              },

              {
                gridStyle: 'desktop:grid-col-6',
                type: 'SelectDropDownField',
                id: 'unitOfMeasure',
                label: 'Unit of measure',
                required: true,
              },
            ],
          },
          {
            type: 'row',
            columns: [
              {
                gridStyle: 'desktop:grid-col-6',
                isVisible: true,
                type: 'NumberInputField',
                id: 'unitCost',
                label: 'Unit cost',
                decimalScale: 3,
                required: true,
              },
              {
                gridStyle: 'desktop:grid-col-6',
                isVisible: true,
                type: 'NumberInputField',
                hasIcon: false,
                id: 'quantity',
                label: 'Quantity',
                decimalScale: 3,
                required: true,
              },
            ],
          },
          {
            type: 'row',
            columns: [
              {
                gridStyle: 'desktop:grid-col-6',
                isVisible: true,
                type: 'NumberInputField',
                id: 'netDollar',
                label: 'Net dollar',
                disabled: true,
              },
              {
                gridStyle: 'desktop:grid-col-6',
                isVisible: true,
                type: 'NumberInputField',
                id: 'taxAmount',
                label: 'Tax amount',
              },
            ],
          },
          {
            type: 'row',
            columns: [
              {
                isVisible: true,
                gridStyle: 'desktop:grid-col-6',
                type: 'NumberInputField',
                id: 'totalCost',
                label: 'Total cost',
                disabled: true,
              },
            ],
          },
        ],
      },
      {
        name: 'MERCHANT',
        lineBreak: true,
        isVisible: true,
        fields: [
          {
            type: 'row',
            columns: [
              {
                id: 'merchantName',
                label: 'Name',
                type: 'TextInputField',
              },
            ],
          },
          {
            type: 'row',
            columns: [
              {
                gridStyle: 'desktop:grid-col-6',
                id: 'phone',
                label: 'Phone',
                type: 'NumberInputField',
                hasIcon: false,
                decimalScale: 0,
                format: '### ### ####',
              },
              {
                gridStyle: 'desktop:grid-col-3',
                id: 'extension',
                label: 'Extension',
                type: 'NumberInputField',
                hasIcon: false,
                decimalScale: 0,
                format: '####',
              },
            ],
          },
          {
            type: 'row',
            columns: [
              {
                id: 'address',
                label: 'Address line 1',
                type: 'TextInputField',
              },
            ],
          },
          {
            type: 'row',
            columns: [
              {
                id: 'address2',
                label: 'Address line 2',
                type: 'TextInputField',
              },
            ],
          },

          {
            type: 'row',
            columns: [
              {
                id: 'address3',
                label: 'Address line 3',
                type: 'TextInputField',
              },
            ],
          },

          {
            type: 'row',
            columns: [
              {
                gridStyle: 'desktop:grid-col-8',
                id: 'city',
                label: 'City',
                type: 'TextInputField',
              },
              {
                gridStyle: 'desktop:grid-col-4',
                type: 'SelectDropDownField',
                id: 'state',
                label: 'State',
                required: true,
              },
            ],
          },
          {
            type: 'row',
            columns: [
              {
                gridStyle: 'desktop:grid-col-4',
                id: 'zip',
                label: 'ZIP code',
                type: 'NumberInputField',
                hasIcon: false,
                decimalScale: 0,
                format: '#####',
              },
            ],
          },
          {
            type: 'row',
            columns: [
              {
                id: 'comment',
                help: 'Help prevent a privacy incident by ensuring that any comments here does not contain personally identifiable information (PII).',
                label: 'Comment',
                type: 'TextInputField',
                inputType: 'textarea',
              },
            ],
          },
        ],
      },
    ],
  },
];
