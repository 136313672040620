import { FilterTableFrame, Button } from '@gsa/afp-component-library';
import React, { useMemo } from 'react';
import { Can } from '@gsa/afp-shared-ui-utils';
import {
  VMSOperations,
  VMSSubjects,
  REPORT_DELIVERY_METHOD,
  REPORT_TYPE,
} from 'utilities/consts';
import ReplacementOrderTable from './replacement-order-table';
import FcReplacementSidebar from './fc-replacement-sidebar';
import { useFcReplacement } from './fc-replacement-context-provider';
import { useFCReplacementFilter } from './fc-filter-provider';

const FcReplacementFilterGrid = () => {
  const { openAddReplacementCardModal, exportData } = useFcReplacement();
  const { filters } = useFCReplacementFilter();

  const onExport = () => {
    exportData({
      variables: {
        request: {
          reportType: REPORT_TYPE.FLEETCARD_REPLACEMENT_EXPORT,
          deliveryMethod: REPORT_DELIVERY_METHOD.EMAIL,
          reportCriteria: {
            filters,
          },
        },
      },
    });
  };

  const FilterUpperRightAction = ({ onExport }) => (
    <Can I={VMSOperations.Create} a={VMSSubjects.FC_REPLACEMENT}>
      <div className="grid-row">
        <div className="grid-col-12">
          <div className="float-right">
            <Button
              label="Export"
              leftIcon={{ name: 'file_download', class: 'usa-icon--size-2' }}
              variant="primary"
              onClick={onExport}
              data-testid="export-fc-orders-button"
            />
            <Button
              label="Order replacement card"
              leftIcon={{ name: 'add', class: 'usa-icon--size-2' }}
              variant="outline"
              onClick={openAddReplacementCardModal}
              data-testid="order-fc-card-button"
            />
          </div>
        </div>
      </div>
    </Can>
  );

  const FilterGrid = useMemo(
    () =>
      FilterTableFrame(
        null,
        FilterUpperRightAction,
        FcReplacementSidebar,
        ReplacementOrderTable,
      ),
    [],
  );

  return <FilterGrid upRightProps={{ onExport }} filterToggle />;
};

export default FcReplacementFilterGrid;
