import React, { useState, useEffect, useRef } from 'react';
import { TextInput } from '@gsa/afp-component-library';
const PhoneInputMask = (inputProps) => {
  useEffect(() => {
    handleChange();
  }, [inputProps.value]);

  const [val, setVal] = useState(inputProps.value);
  const inputRef = useRef();

  const handleChange = () => {
    if (inputProps?.value) {
      const inputValue = inputProps.value
        .replace(/\D/g, '')
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);

      const maskValue = !inputValue[2]
        ? inputValue[1]
        : `${inputValue[1]}-${inputValue[2]}${`${
            inputValue[3] ? `-${inputValue[3]}` : ''
          }`}`;
      setVal(maskValue);
    } else {
      setVal('');
    }
  };

  return (
    <TextInput {...inputProps} inputRef={inputRef} value={val} maxLength={12} />
  );
};
export { PhoneInputMask };
export default PhoneInputMask;
