import React, { useEffect, useState } from 'react';
import QueryString from 'query-string';
import { Link, Route, useParams, useHistory } from 'react-router-dom';
import { Breadcrumbs, EmptyState, Spinner } from '@gsa/afp-component-library';
import { useQuery } from '@apollo/client';
import LpRelatedData from './widgets/lp-related-data';
import LpActions from './widgets/lp-actions';
import LpInfo from './widgets/lp-info';
import LpVehicleInfo from './widgets/lp-vehicle-info';
import LpAgencyInfo from './widgets/lp-agency-info';
import LicensePlateDetailsProvider from './license-plate-details-provider';
import LpPocInfo from './widgets/lp-poc-info';
import LpTabs from './widgets/lp-tabs';
import LpDisassociationSection from './widgets/lp-disassociation-section';
import LpReconciliationSection from './widgets/lp-reconciliation-section';
import LpDestructionSection from '../license-plate-destruction/widgets/lp-destruction-section';
import LicensePlateDestruction from '../license-plate-destruction';
import LicensePlateLookup from './widgets/lp-lookup';
import LpUnattachedAlert from './widgets/lp-unattached-alert';
import LpNoOrderAlert from './widgets/lp-no-order-alert';
import { GET_NESTED_LICENSE_PLATES } from '../../services/data-layer';
import { useTitle } from '@gsa/afp-shared-ui-utils';
import LpGeneralErrorAlert from './widgets/lp-general-error-alert';

const LicensePlateDetails = ({ match }) => {
  const history = useHistory();
  const { tagExpirationDate } = QueryString.parse(history.location.search);

  let { id } = useParams();
  if (id) {
    id = decodeURIComponent(id);
  }
  useTitle(`License Plate ${id}`);

  const [isValidTag, setIsValidTag] = useState(false);

  const { data, loading } = useQuery(GET_NESTED_LICENSE_PLATES, {
    variables: {
      limit: 10,
      offset: 0,
      filters: { operator: 'EQ', key: 'tagNumber', value: id },
      order: [['updatedAt', 'ASC']],
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (data?.getNestedTags?.rows?.length > 0) {
      setIsValidTag(true);
    }
  }, [data]);

  if (loading && !data?.getNestedTags?.rows) {
    return <Spinner className="padding-y-9" />;
  }
  if (!isValidTag) {
    return (
      <div className="bg-gray-3 padding-y-5">
        <div className="text-center padding-y-4">
          <EmptyState alt="Tag not available" hasBackground />
        </div>
        <div className="text-center text-bold">
          License plate does not exist or you
        </div>
        <div className="text-center text-bold margin-top-2">
          do not have permission to access it.
        </div>
      </div>
    );
  }
  return (
    <div
      className="license-plate-details margin-bottom-5"
      data-testid="license-plate-details"
    >
      <LicensePlateDetailsProvider>
        <Route
          exact
          path={`${match.url}/destruction`}
          render={() => {
            return (
              <LicensePlateDestruction
                returnRoute={`${match.url}${
                  tagExpirationDate
                    ? `?tagExpirationDate=${tagExpirationDate}`
                    : ''
                }`}
              />
            );
          }}
        />
        <Route
          exact
          path={`${match.url}`}
          render={() => {
            return (
              <>
                <div className="grid-row">
                  <div className="grid-col-fill" data-testid="breadcrumbs">
                    <Breadcrumbs
                      trail={[
                        <a href={`${window.AFP_CONFIG.appURLs.home}/home`}>
                          Home
                        </a>,
                        <Link to="/license-plates">
                          License plate Inventory
                        </Link>,
                      ]}
                      current={`License Plate ${id}`}
                    />
                  </div>
                </div>
                <div className="grid-row" data-testid="general-alert">
                  <div className="grid-col-fill" data-testid="general-alert">
                    <LpGeneralErrorAlert />
                  </div>
                </div>
                <LpReconciliationSection />
                <LpDisassociationSection />
                <LpDestructionSection />
                <div className="grid-row" data-testid="unattached-tag-alert">
                  <LpUnattachedAlert />
                </div>

                <div
                  className="grid-row margin-bottom-1"
                  data-testid="no-order-alert"
                >
                  <LpNoOrderAlert />
                </div>
                <div className="grid-row grid-gap">
                  <div className="tablet:grid-col-9">
                    <LpInfo id={id} />
                  </div>
                  <div className="tablet:grid-col-3">
                    <LicensePlateLookup
                      ariaLabel="Search by License Plate"
                      licensePlate={id}
                    />
                    <div data-testid="tag-related-info">
                      <LpRelatedData />
                    </div>
                    <div
                      data-testid="tag-related-actions"
                      className="display-flex flex-justify-end"
                    >
                      <LpActions />
                    </div>
                  </div>
                </div>
                <div className="grid-row grid-gap">
                  <LpVehicleInfo />
                  <div className="tablet:grid-col-6" data-testid="agency-info">
                    <LpAgencyInfo />
                  </div>
                </div>
                <div className="grid-row grid-gap">
                  <div className="tablet:grid-col-6" data-testid="poc-info">
                    <LpPocInfo />
                  </div>
                </div>
                <div className="grid-row margin-y-4">
                  <div className="grid-col-fill" data-testid="lp-tabs">
                    <LpTabs />
                  </div>
                </div>
              </>
            );
          }}
        />
      </LicensePlateDetailsProvider>
    </div>
  );
};

export default LicensePlateDetails;
