/* eslint-disable filenames/match-exported */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable filenames/match-regex */
import React from 'react';
import { Button } from '@gsa/afp-component-library';
import AddCustomerAccounts from '../../../add-customer-accounts';

const addCustomerAccountModalSchema = {
  title: () => <h2 className="title-m-bold">Add customer accounts</h2>,
  content: (providerContext) => {
    return <AddCustomerAccounts providerContext={providerContext} />;
  },

  action: ({ resetCurrentModal }) => (
    <>
      <Button
        className="margin-right-2"
        variant="unstyled"
        onClick={resetCurrentModal}
        data-testid="motor-pool-export-cancel-button"
        label="Cancel"
      />
    </>
  ),
  onClose: ({ resetCurrentModal }) => {
    resetCurrentModal();
  },
};

export default addCustomerAccountModalSchema;
