/* eslint-disable react/prop-types */
// eslint-disable-next-line filenames/match-exported
import React, { useEffect } from 'react';
import { Button, Modal } from '@gsa/afp-component-library';
import { useMileageExpress } from './mileage-express-provider';

const InterceptionModal = () => {
  const {
    interceptSave,
    dispatchAction,
    saveAllMileageExpresss,
    significantMileageEntries,
  } = useMileageExpress();

  const onDiscardAndCloseModal = () => {
    dispatchAction('RESET_MILEAGES', true);
    dispatchAction('SET_INTERCEPT_SAVE', false);
    dispatchAction('SET_ALERT_MESSAGE', {});
  };

  const onSaveAndClose = () => {
    if (significantMileageEntries?.length > 0) {
      dispatchAction('SET_CONFIRM_SIGNIFICANT_MILEAGE_DATA', true);
    } else {
      saveAllMileageExpresss();
    }
    dispatchAction('SET_INTERCEPT_SAVE', false);
  };

  useEffect(() => {
    dispatchAction('RESET_MILEAGES', false);
  }, []);

  if (!interceptSave) {
    return null;
  }

  return (
    <div className="afp-modal-wrapper">
      <div className="afp-modal-overlay">
        <Modal
          title={<h2>You have unsaved data</h2>}
          actions={
            <>
              <Button
                variant="unstyled"
                className="margin-right-2"
                onClick={onDiscardAndCloseModal}
                data-testid="discard-save-cancel-button"
                label="Discard and close"
              />
              <Button
                variant="primary"
                type="submit"
                data-testid="confirm-save-all-mileageExpresss"
                label="Save and close"
                onClick={onSaveAndClose}
              />
            </>
          }
          onClose={onDiscardAndCloseModal}
          variant="large"
        >
          You must save or you will lose the data you have entered.
        </Modal>
      </div>
    </div>
  );
};

export default InterceptionModal;
