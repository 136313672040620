/* eslint-disable react/prop-types */
/* eslint-disable filenames/match-exported */
import React from 'react';
import { Button } from '@gsa/afp-component-library';
import { formatTagExpDate } from 'utilities/common';
import usePortalModal from '../../../utilities/portal-modal';

export default function exportLicensePlateModal() {
  const [Modal, openModal, closeModal] = usePortalModal();

  const ValidateLicensePlateModal = ({ licenseInfo, clearLicense }) => {
    let content = '';
    const licenseExpDate = formatTagExpDate(licenseInfo?.expirationDate);
    const licenseAltExpDate = formatTagExpDate(licenseInfo?.altExpirationDate);

    if (licenseInfo?.type === 1) {
      content = `The License Plate you have selected (${licenseInfo?.licensePlate}) exists in the system with a
      different expiration date and is in "Attached" status. Please select a
      different license plate`;
    } else if (licenseInfo?.type === 2) {
      content = `The License Plate you have selected (${licenseInfo?.licensePlate}) exists in the system with a 
      different expiration date(${licenseExpDate})  and is in "Received" status. The License Plate with the latest expiration 
      date (${licenseAltExpDate})  is available to you to select`;
    } else if (licenseInfo?.type === 3) {
      content = `The License Plate you have selected (${licenseInfo?.licensePlate}) exists 
      in the system with a expiration date (${licenseExpDate}) 
      and is in "${licenseInfo?.tagStatus}" status. Are you sure you want to continue?`;
    }

    // eslint-disable-next-line consistent-return
    const actionsBtn = () => {
      if (licenseInfo?.type === 1) {
        return (
          <Button
            type="button"
            variant="outline"
            onClick={() => {
              clearLicense();
              closeModal();
            }}
            label="Select a different plate"
          />
        );
      }

      if (licenseInfo?.type === 2) {
        return (
          <Button
            type="button"
            variant="outline"
            onClick={() => {
              closeModal();
            }}
            label="Save and close"
          />
        );
      }

      if (licenseInfo?.type === 3) {
        return (
          <Button
            type="button"
            variant="outline"
            onClick={() => {
              closeModal();
            }}
            label="Continue"
          />
        );
      }
    };

    const handleClose = () => {
      if (licenseInfo?.type === 1) {
        clearLicense();
      }
    };

    return (
      <Modal
        onClose={handleClose}
        variant="large"
        title={
          <>
            <h2>License Plate Modal</h2>
          </>
        }
        actions={actionsBtn()}
      >
        <div className="margin-y-2 cursor-pointer">{content}</div>
      </Modal>
    );
  };

  return [ValidateLicensePlateModal, openModal];
}
