/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import {
  Button,
  Modal,
  Alert,
  Spinner,
  SelectDropdown,
} from '@gsa/afp-component-library';
import { useVehicle } from 'components/vehicle-details/vehicle-context-provider';
import { isEuVehicle } from 'components/vehicle-details/sidenav-widgets/preventative-maintenance/helper';
import { usePm } from './pm-provider';

const defaultOption = [{ value: '', label: '-Select-' }];

const formatPmSchedules = (schedules) => {
  const formattedSchedules = schedules.map((schedule) => {
    return {
      label:
        schedule?.pmScheduleNumber === schedule?.pmTitle
          ? schedule?.pmScheduleNumber
          : `${schedule?.pmScheduleNumber} - ${schedule?.pmTitle}`,
      value: schedule.pmScheduleId,
    };
  });
  return [...defaultOption, ...formattedSchedules];
};

const EditPmScheduleModal = () => {
  const {
    isEditPmScheduleModalOpen,
    closeEditPmScheduleModal,
    dispatch,
    getPmSchedules,
    pmSchedules,
    pmSchedulesLoading,
    alertMessage,
    updateAssetPmSchedule,
    updateAssetPmScheduleLoading,
    pmHistoryList,
  } = usePm();

  const { vehicle } = useVehicle();

  const [selectedSchedule, setSelectedSchedule] = useState();
  const [scheduleOptions, setScheduleOptions] = useState(defaultOption);

  useEffect(() => {
    if (
      isEditPmScheduleModalOpen &&
      vehicle?.standardItem?.standardItemNumber &&
      vehicle?.fuelCode
    ) {
      dispatch({
        type: 'SET_ALERT_MESSAGE',
        payload: { type: '', message: '', context: 'pmSchedules' },
      });
      const vehicleLocation = isEuVehicle(vehicle.fmcId) ? 'EU' : 'US';
      getPmSchedules({
        variables: {
          pmScheduleId: vehicle?.assetTechnical?.pmSchedule?.pmScheduleId,
          vehicleLocation,
          filters: {
            operator: '$and',
            conditions: [
              {
                operator: '$exact',
                key: 'pmLocation',
                value: vehicleLocation,
              },
            ],
          },
        },
      });
    }
  }, [isEditPmScheduleModalOpen]);

  useEffect(() => {
    if (pmSchedules && pmSchedules?.length) {
      setScheduleOptions(formatPmSchedules(pmSchedules));
    }
  }, [pmSchedules]);

  useEffect(() => {
    if (vehicle?.assetTechnical?.pmSchedule?.pmScheduleId) {
      setSelectedSchedule(vehicle?.assetTechnical?.pmSchedule?.pmScheduleId);
    }
  }, [vehicle?.assetTechnical?.pmSchedule?.pmScheduleId]);

  const onCloseModal = () => {
    dispatch({
      type: 'SET_SELECTED_PM',
      payload: undefined,
    });
    dispatch({
      type: 'SET_ALERT_MESSAGE',
      payload: { type: '', message: '', context: 'pmSchedules' },
    });
    closeEditPmScheduleModal();
  };

  if (!isEditPmScheduleModalOpen) {
    return null;
  }

  const updateSchedule = () => {
    const payload = {
      assetId: vehicle?.uuid,
      newPmScheduleId: selectedSchedule,
      fmcId: vehicle?.fmcId,
      lastOdometerMileageDate: vehicle?.newOdometerReading?.mileageDate
        ? new Date(vehicle?.newOdometerReading?.mileageDate).toISOString()
        : vehicle?.assetAcquisition?.originalAcquisitionDate ||
          new Date().toISOString(),
      lastOdometer:
        vehicle?.newOdometerReading?.odometer ||
        vehicle?.assetAcquisition?.deliveredMiles ||
        0,
      acquisitionDeliveredMiles: vehicle?.assetAcquisition?.deliveredMiles,
      originalAcquisitionDate:
        vehicle?.assetAcquisition?.originalAcquisitionDate,
      currentPmDate: pmHistoryList?.rows?.[0]?.currentPmDate,
      currentPmMileage: pmHistoryList?.rows?.[0]?.currentPmMileage,
    };
    updateAssetPmSchedule({
      variables: {
        updatePmScheduleFields: payload,
      },
    });
  };

  return (
    <div className="afp-modal-wrapper">
      <div className="afp-modal-overlay">
        <Modal
          title={<h2>Edit inspection schedule</h2>}
          actions={
            <>
              <Button
                variant="unstyled"
                className="margin-right-2"
                onClick={onCloseModal}
                data-testid="motor-pool-listing-add-cancel-button"
                label="Cancel"
              />
              <Button
                variant="primary"
                type="submit"
                onClick={() => {
                  updateSchedule();
                }}
                data-testid="select-pm-schedule-submit-button"
                disabled={!selectedSchedule || updateAssetPmScheduleLoading}
                label="Save and close"
              />
              {updateAssetPmScheduleLoading && (
                <Spinner
                  size="small"
                  className="display-inline-block margin-left-2"
                />
              )}
            </>
          }
          onClose={onCloseModal}
          variant="large"
        >
          {alertMessage?.context === 'pmSchedules' && alertMessage?.message && (
            <Alert type={alertMessage.type} slim>
              {alertMessage.message}
            </Alert>
          )}
          {pmSchedules?.length === 0 && (
            <Alert type="warning" slim>
              No other schedules available for this vehicle.
            </Alert>
          )}
          <p>
            Edit the preventative maintenance inspection schedule for VIN{' '}
            <strong>{vehicle.id}</strong> in the form below.
          </p>
          <SelectDropdown
            label={<span className="text-bold">Inspection schedule</span>}
            required
            disabled={pmSchedulesLoading || pmSchedules?.length === 0}
            value={selectedSchedule}
            options={scheduleOptions}
            onChange={(e) => {
              setSelectedSchedule(e.target.value);
            }}
          />
        </Modal>
      </div>
    </div>
  );
};

export default EditPmScheduleModal;
