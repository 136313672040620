/* eslint-disable filenames/match-exported */

import React, { useEffect } from 'react';

import { useHistory } from 'react-router-dom';
import useDataController from './model/use-data-controller';
import ExpenseDisplay from './expense-display';
import { useVehicle } from '../../../../vehicle-context-provider';
import atomStateSchema from './model/schema/atom-state-schema';

const ExpenseDisplayPage = () => {
  const { vehicle, showSystemError, setSectionMsg } = useVehicle();
  const { query, mutation, loading } = useDataController({ vehicle });
  const history = useHistory();
  // eslint-disable-next-line prefer-const
  useEffect(() => {
    query.getExpenses({
      variables: {
        id: vehicle.uuid,
        offset: 0,
        limit: 10,
        order: atomStateSchema?.pageConfigs?.tableOrder?.default?.order,
      },
    });
    return () => {
      history.replace();
    };
  }, []);

  return (
    <>
      <ExpenseDisplay
        dependencies={{ query, mutation, showSystemError, setSectionMsg }}
        loading={loading}
      />
    </>
  );
};

export default ExpenseDisplayPage;
