/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'react-router-dom';
import { Alert } from '@gsa/afp-component-library';
import { formatTagExpDate } from '../../../../utilities/common';

export const ExemptConfirmation = ({ licensePlate, expirationDate, vin }) => {
  return (
    <Alert
      type="warning"
      heading="Previous license plate has been removed from the vehicle"
    >
      <p>
        License plate{' '}
        <Link
          style={{ textDecoration: 'underline' }}
          to={{
            pathname: `/license-plate/${encodeURIComponent(licensePlate)}`,
            state: { vin },
          }}
        >
          {licensePlate}
        </Link>{' '}
        with expiration date{' '}
        <span className="text-bold">{formatTagExpDate(expirationDate)}</span>{' '}
        has been removed from the vehicle. The status of unattached has been
        applied to both A and B plates (if a 2-plate set).
      </p>
      <p className="text-bold">What to do next:</p>
      <p>
        Please submit license plate{' '}
        <Link
          style={{ textDecoration: 'underline' }}
          to={{
            pathname: `/license-plate/${encodeURIComponent(licensePlate)}`,
            state: { vin },
          }}
        >
          {licensePlate}
        </Link>{' '}
        with expiration date of{' '}
        <span className="text-bold">{formatTagExpDate(expirationDate)}</span>{' '}
        for destruction.
      </p>
    </Alert>
  );
};

export const MissingConfirmation = ({ licensePlate, expirationDate }) => {
  return (
    <Alert type="warning" heading="License plate reported lost or stolen">
      <p>
        License plate{' '}
        <Link
          style={{ textDecoration: 'underline' }}
          to={`/license-plate/${encodeURIComponent(licensePlate)}`}
        >
          {licensePlate}
        </Link>{' '}
        with expiration date {formatTagExpDate(expirationDate)} has been removed
        from the vehicle. The status of missing has been applied to both A and B
        plates (if a 2-plate set).
      </p>
      <p className="text-bold">What to do next:</p>
      <ol>
        <li>
          Report the missing license plate to your local security office and
          your local police department (or equivalent).
        </li>
        <li>
          Contact UNICOR immediately to obtain a replacement license plate.
        </li>
      </ol>
    </Alert>
  );
};

export const LicensePlateUpdateWarning = () => {
  return (
    <Alert type="warning" slim>
      When the license plate information is updated:
      <ul>
        <li>
          The vehicle will become{' '}
          <span className="text-bold">unregistered</span>
        </li>
        <li>
          You will need to add a federal license plate to re-register the
          vehicle
        </li>
      </ul>
    </Alert>
  );
};
