import React, { useState } from 'react';
import moment from 'moment';
import {
  Icon,
  TextInput,
  Button,
  EmptyState,
} from '@gsa/afp-component-library';
import { SectionBlock } from '../../common';
import { PageMode, useRepairOrder } from '../providers';
import { useCurrentUser } from '@gsa/afp-shared-ui-utils';
import ConfirmDeleteCommentModal from './confirm-delete-comment-modal';

const MAX_COUNT = 1000;

const Comment = ({ comment = {}, onDelete }) => {
  const {
    id,
    comment: text,
    createdBy,
    createdAt,
    createdByUserInfo,
  } = comment;
  const { currentUser } = useCurrentUser();

  const allowDelete = () => onDelete && createdBy === currentUser?.id;
  const handleDelete = () => {
    if (allowDelete && onDelete) {
      onDelete(comment);
    }
  };

  const author = () => createdByUserInfo?.fullName || 'Unknown';
  const date = () => createdAt;
  return (
    <li key={id} className="margin-bottom-4">
      <div className="display-flex flex-align-center">
        <Icon
          iconName="account_circle"
          className="usa-icon--size-3 margin-right-1"
        />
        <span className="text-bold">{author()}</span>
        <span className="margin-x-4">
          {moment(date()).format('l')} &bull; {moment(date()).format('h:mm a')}
        </span>
        {allowDelete() && (
          <Button
            type="button"
            variant="unstyled"
            className="cursor-pointer display-flex flex-align-center"
            onClick={handleDelete}
            leftIcon={{
              name: 'cancel',
              className: 'usa-icon--size-3 text-base',
            }}
            label="Delete Comment"
            data-testid={`delete-comment-button-${id}`}
          />
        )}
      </div>
      <p style={{ wordBreak: 'break-word' }}>{text}</p>
    </li>
  );
};

const CommentsEmptyState = ({ mode }) => {
  if (mode === PageMode.View) {
    return (
      <EmptyState
        bottomText={<p className="margin-top-neg-1">No comments</p>}
        className="margin-top-5"
        hasBackground
        data-testid="repair-comments-empty-state"
      />
    );
  }
  return null;
};

const Comments = ({ comments = [], onDelete, mode }) => {
  if (!comments.length) {
    return <CommentsEmptyState mode={mode} />;
  }
  const items = comments.map((c) => {
    return <Comment comment={c} onDelete={onDelete} />;
  });

  return (
    <ul className="add-list-reset" data-testid="repair-comments-list">
      {items}
    </ul>
  );
};
const CommentForm = ({ comments = [], onChange, onDelete }) => {
  const [value, setValue] = useState('');
  const [wordCount, setWordCount] = useState(MAX_COUNT - value.length);

  const handleChange = (evt) => {
    const v = evt.target.value;
    setValue(v);
    setWordCount(MAX_COUNT - v.length);
    onChange(evt.target.value);
  };
  return (
    <>
      <TextInput
        inputClass="maxw-full"
        type="textarea"
        label=""
        title="repair comment"
        value={value}
        onChange={handleChange}
        maxLength={MAX_COUNT}
        hint={`${wordCount} characters allowed`}
      />
      <div className="padding-top-4">
        <Comments comments={comments} onDelete={onDelete} />
      </div>
    </>
  );
};

const RepairComments = ({ mode }) => {
  const {
    repairComments = [],
    setCommentText,
    deleteComment,
  } = useRepairOrder();
  const [deletingComment, setDeletingComment] = useState(undefined);
  const isEditing = mode !== PageMode.View;
  const comments = repairComments.filter((c) => !c.deleted);

  const handleDelete = (c) => {
    setDeletingComment(c);
  };
  const handleCancelDelete = () => {
    setDeletingComment(undefined);
  };
  const handleAcceptDelete = () => {
    deleteComment(deletingComment);
    setDeletingComment(undefined);
  };

  return (
    <SectionBlock title="Comments" basic border={false}>
      {deletingComment && (
        <ConfirmDeleteCommentModal
          onAccept={handleAcceptDelete}
          onCancel={handleCancelDelete}
        />
      )}
      {isEditing ? (
        <CommentForm
          comments={comments}
          onDelete={handleDelete}
          onChange={setCommentText}
        />
      ) : (
        <Comments comments={comments} mode={mode} />
      )}
    </SectionBlock>
  );
};

export default RepairComments;
