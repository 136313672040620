import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Button,
  connectModal,
  Modal,
  useModal,
  Alert,
} from '@gsa/afp-component-library';

const DisassociateModal = ({
  closeModal,
  onSubmit,
  disassociationPayload,
  setDisassociationPayload,
}) => {
  const { tagNumber, type } = disassociationPayload;
  return (
    <Modal
      id="delete-service-line-modal"
      className="width-tablet"
      variant="large"
      title={
        <h2>
          Report license plate as{' '}
          {type === 'lostStolen' ? ' lost or stolen' : ' damaged'}
        </h2>
      }
      onClose={() => {
        closeModal();
        setDisassociationPayload({});
      }}
      actions={
        <>
          <Button
            variant="unstyled"
            onClick={() => {
              setDisassociationPayload({});
              closeModal();
            }}
            label="Cancel"
          />
          <Button
            className="margin-left-2"
            onClick={onSubmit}
            disabled={disassociationPayload?.loading}
            label={`${
              disassociationPayload?.loading ? 'Changing' : 'Change'
            } status to "missing"`}
          />
        </>
      }
    >
      <div
        data-testid="disassociate-tag-modal-mody"
        className="padding-bottom-3 padding-top-1"
      >
        You&apos;re about to report license plate <strong>{tagNumber}</strong>{' '}
        as {type === 'lostStolen' ? ' lost or stolen' : ' damaged'} and the
        status of <strong>&ldquo;missing&rdquo;</strong> will be applied to both
        A and B plates (if a 2-plate set).
      </div>
    </Modal>
  );
};

DisassociateModal.defaultProps = {
  disassociationPayload: {},
};

DisassociateModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  disassociationPayload: PropTypes.shape({
    expirationDate: PropTypes.number,
    tagNumber: PropTypes.string,
    type: PropTypes.string,
    vinToDisassociateFrom: PropTypes.string,
    loading: PropTypes.bool,
    disassociatedTag: PropTypes.shape(Object),
  }),
  setDisassociationPayload: PropTypes.func.isRequired,
};

export default function LpDisassociation({
  disassociationPayload,
  setDisassociationPayload,
  disassociateTag,
  disassociationAlert,
}) {
  const { isOpen, openModal, closeModal } = useModal();

  useEffect(() => {
    if (_.get(disassociationPayload, 'tagNumber')) {
      openModal();
    }
    if (
      _.get(disassociationPayload, 'disassociatedTag') ||
      _.get(disassociationPayload, 'error')
    ) {
      closeModal();
    }
  }, [disassociationPayload]);

  useEffect(() => {
    if (!_.isEmpty(disassociationAlert)) {
      const lpAlert = document.getElementById('lpDisassociteAlert');
      if (lpAlert) {
        lpAlert.scrollIntoView({
          behavior: 'smooth',
        });
        lpAlert.focus();
      }
    }
  }, [disassociationAlert]);

  const disassociateLp = async () => {
    setDisassociationPayload({
      ...disassociationPayload,
      loading: true,
    });
    disassociateTag({
      variables: {
        tag: {
          tagNumber: _.get(disassociationPayload, 'tagNumber'),
          expirationDate: _.toString(
            _.get(disassociationPayload, 'expirationDate'),
          ),
        },
      },
    });
  };

  const DisassociateTagModal = connectModal(() => (
    <DisassociateModal
      closeModal={closeModal}
      onSubmit={disassociateLp}
      disassociationPayload={disassociationPayload}
      setDisassociationPayload={setDisassociationPayload}
    />
  ));

  return (
    <>
      {!_.isEmpty(disassociationAlert) && (
        <Alert
          heading={_.get(disassociationAlert, 'heading')}
          type={_.get(disassociationAlert, 'type')}
          slim={_.get(disassociationAlert, 'type') === 'error'}
          id="lpDisassociteAlert"
          tabIndex="0"
        >
          {_.get(disassociationAlert, 'message')}
        </Alert>
      )}
      <DisassociateTagModal isOpen={isOpen} closeModal={closeModal} />
    </>
  );
}

LpDisassociation.defaultProps = {
  disassociationPayload: {},
  disassociationAlert: {},
};

LpDisassociation.propTypes = {
  disassociationPayload: PropTypes.shape({
    expirationDate: PropTypes.number,
    tagNumber: PropTypes.string,
    type: PropTypes.string,
    vinToDisassociateFrom: PropTypes.string,
    loading: PropTypes.bool,
    disassociatedTag: PropTypes.shape(Object),
  }),
  setDisassociationPayload: PropTypes.func.isRequired,
  disassociateTag: PropTypes.func.isRequired,
  disassociationAlert: PropTypes.shape({
    heading: PropTypes.string,
    message: PropTypes.shape(Object),
    type: PropTypes.string,
  }),
};
