import React, { useEffect, useState } from 'react';
import { useOrder } from '../context';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  GET_AGENCIES_BY_PERMISSION,
  GET_BUREAUS_BY_PERMISSION,
  GET_OFFICES_BY_PERMISSION,
  UPDATE_ORDER_AGENCY,
} from '../../../services/data-layer';
import { VMSOperations, VMSSubjects } from '../../../utilities/consts';
import _ from 'lodash';

const DEFAULT_AGENCIES = [{ value: '', label: '- Select Agency -' }];
const DEFAULT_OFFICES = [{ value: '', label: '- Select Office -' }];
const DEFAULT_BUREAUS = [{ value: '', label: '- Select Bureau -' }];

export default function OrderAgencyManager({
  orderNumber,
  agency,
  bureau,
  office,
  onEditAgency,
}) {
  const { getOrder } = useOrder();
  const [agencies, setAgencies] = useState(DEFAULT_AGENCIES);
  const [bureaus, setBureaus] = useState(DEFAULT_BUREAUS);
  const [offices, setOffices] = useState(DEFAULT_OFFICES);

  const [currentAgencyId] = useState(agency?.id);
  const [currentOfficeId] = useState(office?.id);
  const [currentBureauId] = useState(bureau?.id);

  const [getOffices, { loading: officeLoading }] = useLazyQuery(
    GET_OFFICES_BY_PERMISSION,
    {
      fetchPolicy: 'network-only',
      onCompleted: (allOffices) => {
        if (allOffices) {
          const possibleOffices = _.sortBy(
            allOffices.getOfficesByPermission,
            'officeCode',
          ).map(({ officeCode, officeName }) => ({
            label: `${officeCode} - ${officeName}`,
            value: officeCode,
          }));
          setOffices([...DEFAULT_OFFICES, ...possibleOffices]);
        }
      },
    },
  );

  const [getBureaus, { loading: bureausLoading }] = useLazyQuery(
    GET_BUREAUS_BY_PERMISSION,
    {
      fetchPolicy: 'network-only',
      onCompleted: (allBureaus) => {
        if (allBureaus) {
          setBureaus([
            ...DEFAULT_BUREAUS,
            ..._.sortBy(allBureaus.getBureausByPermission, 'id').map(
              ({ id, name }) => ({
                value: id,
                label: `${id} - ${name}`,
              }),
            ),
          ]);
        }
      },
    },
  );

  const [agenciesQuery, { loading: agencyLoading }] = useLazyQuery(
    GET_AGENCIES_BY_PERMISSION,
    {
      fetchPolicy: 'network-only',
      onCompleted: (allAgencies) => {
        if (allAgencies) {
          setAgencies([
            ...DEFAULT_AGENCIES,
            ..._.sortBy(allAgencies.getAgenciesByPermission, 'id').map(
              ({ id, name }) => ({
                value: id,
                label: `${id} - ${name}`,
              }),
            ),
          ]);
        }
      },
    },
  );

  const getAgencies = () => {
    const operation = VMSOperations.Update;
    const subject = VMSSubjects.ORDER;
    agenciesQuery({
      variables: {
        operation,
        subject,
      },
    });
  };

  useEffect(() => {
    getAgencies();
  }, [orderNumber]);

  useEffect(() => {
    if (currentAgencyId) {
      getBureaus({
        variables: {
          agencyCode: currentAgencyId,
          subject: VMSSubjects.ORDER,
          operation: VMSOperations.UPDATE_ORDER,
        },
      });
    }
  }, [currentAgencyId]);

  useEffect(() => {
    if (currentAgencyId && currentBureauId) {
      getOffices({
        variables: {
          agencyCode: currentAgencyId,
          bureauCode: currentBureauId,
          subject: VMSSubjects.ORDER,
          operation: VMSOperations.UPDATE_ORDER,
        },
      });
    }
  }, [currentAgencyId, currentBureauId]);

  const [updateOrderAgency, { loading: updatingAgencyLoading }] = useMutation(
    UPDATE_ORDER_AGENCY,
    {
      onError: () => {
        onEditAgency('error');
      },
      onCompleted: (responseAgencyData) => {
        if (responseAgencyData && responseAgencyData.updateOrderAgencyDetails) {
          getOrder({
            variables: {
              orderNumber:
                responseAgencyData.updateOrderAgencyDetails.orderNumber,
            },
          });
          onEditAgency('success');
        }
        // set success message here
      },
    },
  );

  const changeAgency = async (
    newAgencyCode,
    newBureauCode,
    newOfficeCode = undefined,
    orderNumber,
  ) => {
    if (
      (newAgencyCode && newAgencyCode !== currentAgencyId) ||
      newBureauCode !== currentBureauId ||
      newOfficeCode !== currentOfficeId
    ) {
      const agencyDetailsData = {
        agencyCode: newAgencyCode,
        bureauCode: newBureauCode,
        officeCode: newOfficeCode,
      };

      await updateOrderAgency({
        variables: {
          orderNumber: orderNumber.orderNumber,
          agencyDetailsData,
        },
      });
    }
  };

  return {
    getAgencies,
    offices,
    getOffices,
    bureaus,
    getBureaus,
    agencies,
    currentAgencyId,
    currentOfficeId,
    changeAgency,
    bureausLoading,
    officeLoading,
    agencyLoading,
    updatingAgencyLoading,
  };
}
