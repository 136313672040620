import React, { useEffect, useState } from 'react';
import { useTitle } from '@gsa/afp-shared-ui-utils';
import { PageTitle } from '@gsa/afp-component-library';
import { startCase } from 'lodash';
import { useOfficeManagement } from './office-management-provider';

export default function OfficeManagementPageTitle() {
  const [title, setTitle] = useState('');
  const { selectedOrganization } = useOfficeManagement();
  const { officeCode, officeName } = selectedOrganization;

  useEffect(() => {
    setTitle(`${officeCode} - ${startCase(officeName.toLocaleLowerCase())}`);
  }, [officeName]);

  useTitle(title);

  return (
    <>
      <PageTitle title={title} />
    </>
  );
}
