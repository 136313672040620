import vehicleFilters, {
  vehicleWithNestedFilters,
} from '../filter-structures/vehicle';

const fsr = () => {
  return [
    vehicleFilters.vehicleVINTypeahead,
    vehicleFilters.vehicleLicensePlateTypeahead,
    vehicleFilters.vehiclePoCTypeahead,
    vehicleFilters.customerAccount,
    vehicleFilters.vehicleMakeModel,
    vehicleFilters.agency,
    vehicleFilters.registrationStaus,
    vehicleFilters.fmc,
    vehicleFilters.vehicleLifecycleAndStatus,
    vehicleFilters.vehicleLicenseLocation,
  ];
};

export const fsrVehicleNestedFilters = () => {
  return [
    vehicleWithNestedFilters.vehicleLicensePlateTypeahead,
    vehicleWithNestedFilters.vehicleVINTypeahead,
    vehicleWithNestedFilters.fmc,
    vehicleWithNestedFilters.vehiclePoCTypeahead,
    vehicleWithNestedFilters.customerAccount,
    vehicleWithNestedFilters.vehicleMakeModel,
    vehicleWithNestedFilters.vehicleOwnership,
    vehicleWithNestedFilters.agency,
    vehicleWithNestedFilters.vehicleLifecycleAndStatus,
    vehicleWithNestedFilters.registrationStaus,
    vehicleWithNestedFilters.vehicleLicenseLocation,
  ];
};

export default fsr;
