/* eslint-disable react/prop-types */
import { useLazyQuery } from '@apollo/client';
import { Typeahead } from '@gsa/afp-component-library';
import React, { useEffect, useRef, useState } from 'react';
import { GET_MODELS_BY_PARTIAL_NAME } from '../../../services/data-layer';
import { nullForForm, UNKNOWN } from '../../../utilities/common';

export default function ModelLookup({
  onSelect = () => {},
  onChange = () => {},
  value = '',
  makeCode,
  errorMessage = '',
  disabled,
}) {
  const [getModelsByPartialModelName, { data }] = useLazyQuery(
    GET_MODELS_BY_PARTIAL_NAME,
    {
      fetchPolicy: 'no-cache',
      onError(err) {
        // eslint-disable-next-line no-console
        console.warn(err);
      },
    },
  );

  const [typeaheads, setTypeaheads] = useState([]);
  const makeCodeRef = useRef(makeCode);

  useEffect(() => {
    makeCodeRef.current = makeCode;
  }, [makeCode]);

  useEffect(() => {
    if (data) {
      setTypeaheads(
        data?.getModelsByPartialModelName.map((m) => m.modelDescription),
      );
    }
  }, [data]);

  const fetchModels = (_accessor, val) => {
    if (makeCodeRef.current && val) {
      getModelsByPartialModelName({
        variables: { makeCode: makeCodeRef.current, modelDescription: val },
      });
    }
  };

  const handleSelect = (val) => {
    const model = data?.getModelsByPartialModelName.find(
      (item) => item.modelDescription === val,
    );
    onSelect(model);
  };

  useEffect(() => {
    if (value && value === UNKNOWN) {
      onSelect({ modelCode: '-1', modelDescription: UNKNOWN });
    }
  }, [value]);

  return (
    <Typeahead
      id="model"
      accessor="model"
      label="Model"
      labelClass="text-bold"
      filterValue={nullForForm(value)}
      onFilterChange={onChange}
      fetchTypeaheadValues={fetchModels}
      typeaheadValues={typeaheads}
      placeholder="Search Models..."
      onOptionEnter={handleSelect}
      inputCharNum={2}
      required
      ariaLabel="Model"
      debounceDelay={500}
      disabled={!makeCode || disabled}
      errorMessage={errorMessage}
      onClear={() => handleSelect(null)}
    />
  );
}
