/* eslint-disable react/prop-types */
/* eslint-disable dot-notation */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import moment from 'moment';
import { CDD_FORM_FIELDS } from './consts';
import {
  populateBoolDropdown,
  populateCountryDropdown,
} from '../../helpers/utils';

const alphanumericOrDashStartWithLetterRegExp = /^$|^[a-zA-Z]+[a-zA-Z0-9-]*$/;
const noQuotesOrApostrophesRegExp = /^$|^[^'"]+$/;
const telematicsDataUsage = 'FILMSTUX';

const formatTelematicsDataUsage = (data) => {
  return data &&
    telematicsDataUsage.split('').some((char) => data.includes(char))
    ? data.split('')
    : '';
};

const cddFormSchema = yup.object().shape({
  [CDD_FORM_FIELDS['lawEnforcement'].name]: yup.string().label(' ').nullable(),
  [CDD_FORM_FIELDS['isEmergencyResponse'].name]: yup
    .string()
    .label(' ')
    .nullable(),
  [CDD_FORM_FIELDS['garageDomestic'].name]: yup.string().label(' ').nullable(),
  [CDD_FORM_FIELDS['isLocationWithheld'].name]: yup
    .string()
    .label(' ')
    .nullable(),
  [CDD_FORM_FIELDS['acquisitionCost'].name]: yup
    .string()
    .test(
      'Vehicle Acquisition Cost range',
      'Should be between $0 and $3M.',
      (items) => {
        const formattedCost = items.replace(/,/g, '');
        return (
          (formattedCost > 0 && formattedCost <= 3000000) ||
          formattedCost === ''
        );
      },
    )
    .nullable()
    .label(' '),
  [CDD_FORM_FIELDS['fastDescription'].name]: yup.string().nullable().label(' '),
  [CDD_FORM_FIELDS['assignmentType'].name]: yup.string().nullable().label(' '),
  [CDD_FORM_FIELDS['reportOrganization'].name]: yup
    .string()
    .label(' ')
    .matches(
      noQuotesOrApostrophesRegExp,
      'Quotes or apostrophes are not allowed.',
    )
    .max(32, 'Must be 32 characters or less.')
    .nullable(),
  [CDD_FORM_FIELDS['agencyBudgetElementCode'].name]: yup
    .string()
    .label(' ')
    .trim()
    .matches(
      alphanumericOrDashStartWithLetterRegExp,
      'Must be alpha-numeric and begin with an alpha character.',
    )
    .max(32, 'Must be 32 characters or less.')
    .nullable(),
  [CDD_FORM_FIELDS['agencySort'].name]: yup
    .string()
    .nullable()
    .label(' ')
    .matches(
      noQuotesOrApostrophesRegExp,
      'Quotes or apostrophes are not allowed.',
    )
    .max(64, 'Must be 64 characters or less.'),
  [CDD_FORM_FIELDS['isGasAfv'].name]: yup.string().label(' ').nullable(),
  [CDD_FORM_FIELDS['aldCoverageDesignation'].name]: yup
    .string()
    .label(' ')
    .nullable(),
  [CDD_FORM_FIELDS['ald2005701'].name]: yup.string().label(' ').nullable(),
  [CDD_FORM_FIELDS['ald141Designation'].name]: yup
    .string()
    .label(' ')
    .nullable(),
  [CDD_FORM_FIELDS['garageCountryCode'].name]: yup
    .string()
    .nullable()
    .label(' '),
  [CDD_FORM_FIELDS['garageAddress1'].name]: yup.string().nullable().label(' '),
  [CDD_FORM_FIELDS['garageAddress2'].name]: yup.string().nullable().label(' '),
  [CDD_FORM_FIELDS['garageAddress3'].name]: yup.string().nullable().label(' '),
  [CDD_FORM_FIELDS['garageCity'].name]: yup.string().nullable().label(' '),
  [CDD_FORM_FIELDS['garageStateCode'].name]: yup.string().nullable().label(' '),
  [CDD_FORM_FIELDS['garagePostalCode'].name]: yup
    .string()
    .nullable()
    .when('garageDomestic', (garageDomestic, schema) => {
      return garageDomestic === 'true' || garageDomestic === true
        ? schema.max(10, 'Must be 10 characters or less.')
        : schema;
    })
    .label(' '),
  [CDD_FORM_FIELDS['leaseType'].name]: yup.string().label(' ').nullable(),
  [CDD_FORM_FIELDS['indirectCost'].name]: yup
    .string()
    .test(
      'Indirect cost range',
      'Should be between $0 and $4,000.',
      (items) => {
        const formattedCost = items.replace(/,/g, '');
        return (
          (formattedCost >= 0 && formattedCost <= 4000) || formattedCost === ''
        );
      },
    )
    .nullable()
    .label(' '),
  [CDD_FORM_FIELDS['leaseCost'].name]: yup
    .string()
    .test('Lease cost range', 'Should be between $0 and $125,000.', (items) => {
      const formattedCost = items.replace(/,/g, '');
      return (
        (formattedCost >= 0 && formattedCost <= 125000) || formattedCost === ''
      );
    })
    .nullable()
    .label(' '),
  [CDD_FORM_FIELDS['accidentCost'].name]: yup
    .string()
    .test(
      'Accident cost range',
      'Should be between $0 and $250,000.',
      (items) => {
        const formattedCost = items.replace(/,/g, '');
        return (
          (formattedCost >= 0 && formattedCost <= 250000) ||
          formattedCost === ''
        );
      },
    )
    .nullable()
    .label(' '),
  [CDD_FORM_FIELDS['armoredDesignation'].name]: yup
    .string()
    .nullable()
    .label(' '),
  [CDD_FORM_FIELDS['isMsa'].name]: yup.string().nullable().label(' '),
  [CDD_FORM_FIELDS['aldComment'].name]: yup
    .string()
    .nullable()
    .max(64, 'Must be 64 characters or less.')
    .label(' '),
  [CDD_FORM_FIELDS['homeToWork'].name]: yup.string().nullable().label(' '),
  // [CDD_FORM_FIELDS['telematicsInstalled'].name]: yup
  //   .string()
  //   .nullable()
  //   .label(' '),
  // [CDD_FORM_FIELDS['telematicsDataUsage'].name]: yup
  //   .string()
  //   .nullable()
  //   .label(' '),
});

const CddFormContext = ({
  children,
  aldData,
  aldCategories,
  apiValidationErrors,
}) => {
  const inputData = {
    [CDD_FORM_FIELDS['lawEnforcement'].name]: aldData?.lawEnforcement || '',
    [CDD_FORM_FIELDS['isEmergencyResponse'].name]: populateBoolDropdown(
      aldData?.isEmergencyResponse,
    ),
    [CDD_FORM_FIELDS['acquisitionCost'].name]:
      aldData?.assetAcquisition?.acquisitionCost || '',
    [CDD_FORM_FIELDS['acquisitionDate'].name]: aldData?.assetAcquisition
      ?.originalAcquisitionDate
      ? moment
          .utc(aldData.assetAcquisition.originalAcquisitionDate)
          .format('YYYY-MM-DD')
      : null,
    [CDD_FORM_FIELDS['fastDescription'].name]: aldData?.fastDescription || '',
    [CDD_FORM_FIELDS['assignmentType'].name]: aldData?.assignmentType || '',
    [CDD_FORM_FIELDS['reportOrganization'].name]:
      aldData?.reportOrganization || '',
    [CDD_FORM_FIELDS['agencyBudgetElementCode'].name]:
      aldData?.agencyBudgetElementCode || '',
    [CDD_FORM_FIELDS['agencySort'].name]: aldData?.agencySort || '',
    [CDD_FORM_FIELDS['isGasAfv'].name]: populateBoolDropdown(aldData?.isGasAfv),
    [CDD_FORM_FIELDS['aldCoverageDesignation'].name]:
      aldData?.aldCoverageDesignation || '',
    [CDD_FORM_FIELDS['ald2005701'].name]: aldData?.ald2005701 || '',
    [CDD_FORM_FIELDS['ald141Designation'].name]:
      aldData?.ald141Designation || '',
    [CDD_FORM_FIELDS['garageDomestic'].name]: populateBoolDropdown(
      aldData?.garageDomestic,
    ),
    [CDD_FORM_FIELDS['isLocationWithheld'].name]: populateBoolDropdown(
      aldData?.isLocationWithheld,
    ),
    [CDD_FORM_FIELDS['garageCountryCode'].name]: populateCountryDropdown(
      aldData?.garageDomestic,
      aldData?.garageCountryCode,
    ),
    [CDD_FORM_FIELDS['garageAddress1'].name]: aldData?.garageAddress1 || '',
    [CDD_FORM_FIELDS['garageAddress2'].name]: aldData?.garageAddress2 || '',
    [CDD_FORM_FIELDS['garageAddress3'].name]: aldData?.garageAddress3 || '',
    [CDD_FORM_FIELDS['garageCity'].name]: aldData?.garageCity || '',
    [CDD_FORM_FIELDS['garageStateCode'].name]: aldData?.garageStateCode || '',
    [CDD_FORM_FIELDS['garagePostalCode'].name]: aldData?.garagePostalCode || '',
    [CDD_FORM_FIELDS['leaseType'].name]: aldData?.leaseType || '',
    [CDD_FORM_FIELDS['indirectCost'].name]:
      aldData?.indirectCost?.toString() || '',
    [CDD_FORM_FIELDS['leaseCost'].name]: aldData?.leaseCost?.toString() || '',
    [CDD_FORM_FIELDS['accidentCost'].name]:
      aldData?.accidentRepairCost?.toString() || '',
    [CDD_FORM_FIELDS['armoredDesignation'].name]: populateBoolDropdown(
      aldData?.armoredDesignation,
    ),
    [CDD_FORM_FIELDS['isMsa'].name]: populateBoolDropdown(aldData?.isMsa),
    [CDD_FORM_FIELDS['aldComment'].name]: aldData?.aldComment || '',
    [CDD_FORM_FIELDS['homeToWork'].name]: populateBoolDropdown(
      aldData?.homeToWork,
    ),
    [CDD_FORM_FIELDS['cddTelematicsInstalled'].name]:
      aldData?.vehicle?.cddTelematicsInstalled,
    [CDD_FORM_FIELDS['telematicsDataUsage'].name]: formatTelematicsDataUsage(
      aldData?.assetTelematicsMetadata?.telematicsDataUsage,
    ),
  };

  const methods = useForm({
    resolver: yupResolver(cddFormSchema),
    defaultValues: inputData,
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    if (apiValidationErrors.length > 0) {
      const validationErrors = [];
      // eslint-disable-next-line array-callback-return
      apiValidationErrors.map((validationError) => {
        validationError?.fields.map((field) => {
          if (validationErrors.find((error) => error.name === field)) {
            validationErrors[
              validationErrors.findIndex((el) => el.name === field)
            ].message += `/n ${validationError.message}`;
          } else {
            validationErrors.push({
              name: field,
              message: validationError.message,
            });
          }
          return validationErrors;
        });
      });

      validationErrors.map((error) =>
        methods.setError(CDD_FORM_FIELDS[error.name].name, {
          type: 'custom',
          message: (
            <div className="text-normal">
              {error.message.split('/n').map((errorMessage, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <p key={`${error.name}-${index}`}>{errorMessage}</p>
              ))}
            </div>
          ),
        }),
      );
    }
  }, [apiValidationErrors]);

  return (
    <FormProvider
      {...methods}
      ald={aldData}
      aldCategories={aldCategories}
      apiValidationErrors={apiValidationErrors}
    >
      {children}
    </FormProvider>
  );
};

CddFormContext.defaultProps = {
  ald: {},
  aldCategories: [],
};

CddFormContext.propTypes = {
  children: PropTypes.node.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  ald: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  aldCategories: PropTypes.object,
};

export default CddFormContext;
