/* eslint-disable react/prop-types */
import React, { useMemo, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  AFPTable,
  Menu,
  EmptyState,
  Pagination,
  Spinner,
} from '@gsa/afp-component-library';
import { useCurrentUser } from '@gsa/afp-shared-ui-utils';
import { emdash } from 'components/common';
import { VMSOperations } from 'utilities/consts';
import phoneNumberFormatter from 'utilities/phoneNumberFormatter';
import useUser from 'utilities/use-user';
import { useMotorPool } from '../../motor-pool-provider';
import { useMotorPoolFilter } from '../../filters/filter-provider';
import MotorPoolMembersModal from './utils/motor-pool-members-modal';

const actionList = [
  {
    icon: 'delete',
    label: 'Remove member',
    action: 'deleteMotorPoolMember',
    operation: VMSOperations.Update,
  },
];

const initialPaginationState = {
  limit: 10,
  offset: 0,
  currentPage: 1,
};

const tableRef = React.createRef();

const MotorPoolMembersTable = () => {
  const params = useParams();
  const { id } = params;
  const { currentUser } = useCurrentUser();
  const { isRole } = useUser();
  const [paginationState, setPaginationState] = useState(
    initialPaginationState,
  );
  const [order, setOrder] = useState('`member.firstName` ASC');

  const { filters } = useMotorPoolFilter();

  const {
    getMotorPoolMembers,
    motorPoolMembersList,
    motorPoolMembersLoading,
    dispatchAction,
    motorPoolMemberModalMode,
    resetMotorPoolMemberModal,
    selectedMotorPoolById,
  } = useMotorPool();

  const getData = () => {
    getMotorPoolMembers({
      variables: {
        motorPoolId: id,
        limit: paginationState.limit,
        offset: paginationState.offset,
        order,
        filters: filters.membersListing,
      },
    });
  };

  useEffect(() => {
    dispatchAction('SET_ALERT_MESSAGE', { type: '', message: '' });
    dispatchAction('SET_SELECTED_VEHICLE_AVAILABLE_DATES', null);
    resetMotorPoolMemberModal();
    getData();
  }, []);

  useEffect(() => {
    getData();
  }, [filters?.membersListing, paginationState, order]);

  useEffect(() => {
    setPaginationState({
      ...paginationState,
      offset: 0,
      currentPage: 1,
      isReset: true,
    });
  }, [filters?.membersListing]);

  const handleSelectedAction = (action, original) => {
    if (original) {
      dispatchAction('SET_SELECTED_MOTOR_POOL_MEMBER', original);
    }
    if (action === 'deleteMotorPoolMember') {
      dispatchAction(
        'SET_MOTOR_POOL_MEMBER_MODAL_MODE',
        'DELETE_MOTOR_POOL_MEMBER',
      );
    }
    dispatchAction('SET_SHOW_MOTOR_POOL_MEMBER_MODAL', true);
  };

  const columns = useMemo(() => {
    const columnList = [
      {
        Header: 'Member name',
        accessor: 'member.firstName',
        sortable: true,
        Cell: ({ row: { original } }) => {
          return original?.member?.fullName || emdash;
        },
      },
      {
        Header: 'Contact info',
        accessor: 'member.email',
        sortable: false,
        // eslint-disable-next-line react/prop-types
        Cell: ({ row: { original } }) => {
          return (
            <>
              <a
                aria-label={`mailto: ${original?.member?.email}`}
                href={`mailto:${original?.member?.email}`}
              >
                {original?.member?.email}
              </a>
              <br />
              {original?.member?.phoneNumber && (
                <div
                  aria-label={`phone number: ${original.member.phoneNumber} ${
                    original?.member?.phoneNumberExt
                      ? `ext. ${original?.member?.phoneNumberExt}`
                      : ''
                  }`}
                >
                  {phoneNumberFormatter(original.member.phoneNumber)}
                  {original?.member?.phoneNumberExt
                    ? ` ext. ${original.member.phoneNumberExt}`
                    : ''}
                </div>
              )}
            </>
          );
        },
      },
      {
        Header: 'Member type',
        accessor: 'isDispatcher',
        sortable: true,
        Cell: ({ row: { original } }) => {
          if (original?.isPrimaryDispatcher) {
            return 'Primary Dispatcher';
          }
          if (original?.isDispatcher) {
            return 'Backup Dispatcher';
          }
          return 'Driver';
        },
      },
      {
        Header: 'Invitation type',
        accessor: 'isSelfInvite',
        sortable: true,
        Cell: ({ value }) => {
          return value ? 'Self Invited' : 'Dispatcher Invited';
        },
      },
      {
        Header: 'Actions',
        sortable: false,
        Cell: ({ row: { original } }) => {
          if (!selectedMotorPoolById?.managesPool) return emdash;
          // don't show actions for current user to self remove
          if (original?.memberUserId === currentUser?.id) return emdash;

          // don't allow deleting primary dispatcher
          if (original?.isPrimaryDispatcher) return emdash;

          // don't show actions for non primary dispatchers, but display for 
          // FMVRSAdminRole, SiteAdmin, CustomerAdmin
          if (
            selectedMotorPoolById?.managesPool &&
            selectedMotorPoolById?.primaryDispatcher?.memberUserId !==
              currentUser?.id &&
            isRole('Dispatcher') &&
            !isRole('FMVRSAdminRole') &&
            !isRole('SiteAdmin') &&
            !isRole('CustomerAdmin')
          )
            return emdash;

          return (
            <div className="height-4 width-4 margin-left-05em">
              <Menu
                actionIconSize="usa-icon--size-4"
                menuItems={actionList}
                onActionClick={(action) => {
                  // eslint-disable-next-line react/prop-types
                  handleSelectedAction(action, original);
                }}
                iconColor="text-primary"
                menuDialogState="hover"
              />
            </div>
          );
        },
      },
    ];

    return columnList;
  }, []);

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    // Calculate new offset.
    const offset = (currentPage - 1) * itemsPerPage;
    setPaginationState({
      limit: itemsPerPage,
      offset,
      currentPage,
    });
  };

  return (
    <>
      <AFPTable
        fullWidth
        ref={tableRef}
        testId="motor-pool-members-listing-table"
        columns={columns}
        data={!motorPoolMembersLoading ? motorPoolMembersList.rows : []}
        defaultSort={order}
        onSort={setOrder}
      />
      {motorPoolMembersLoading && <Spinner className="padding-y-9" />}
      {motorPoolMembersList?.rows?.length > 0 && (
        <Pagination
          fullWidth
          variant="advanced"
          itemsPerPageOptions={[10, 25, 50]}
          itemsCount={motorPoolMembersList.count}
          itemsPerPage={paginationState.limit}
          currentPage={paginationState.currentPage}
          onPageChange={handlePaginationChange}
        />
      )}
      {(!motorPoolMembersList || motorPoolMembersList.rows.length === 0) &&
        !motorPoolMembersLoading && (
          <div className="bg-gray-3 padding-y-5">
            <div className="text-center padding-y-4">
              <EmptyState alt="Image not available" hasBackground />
            </div>
            <div
              className="text-center text-bold"
              data-testid="motor-pools-members-no-data"
            >
              No motor pool members available
            </div>
            <div className="text-center margin-top-2">
              Add or remove filters to search motor pool members
            </div>
          </div>
        )}

      <MotorPoolMembersModal mode={motorPoolMemberModalMode} />
    </>
  );
};

export default MotorPoolMembersTable;
