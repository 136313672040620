import React from 'react';
import LicensePlateDestructionModal from '../../license-plate/widgets/license-plate-destruction-modal';
import { useLicensePlateDestruction } from '../context';

const LpDestructionSection = () => {
  const {
    destructionEditing,
    destructionInitData,
    setDestructionInitData,
    saveDestruction,
    toggleDestructionEditing,
    forDestructionList,
  } = useLicensePlateDestruction();

  const title = (
    <div className="margin-top-2 margin-right-8">
      <h1>
        {forDestructionList.length} license plate
        {forDestructionList.length > 1 && 's'} will be added for destruction
      </h1>
    </div>
  );

  return (
    <LicensePlateDestructionModal
      editing={destructionEditing}
      onClose={toggleDestructionEditing}
      onSave={saveDestruction}
      title={title}
      destructionInitData={destructionInitData}
      setDestructionInitData={setDestructionInitData}
      forDestructionList={forDestructionList}
    />
  );
};

export default LpDestructionSection;
