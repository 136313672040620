import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { Menu } from '@gsa/afp-component-library';
import { useHistory } from 'react-router-dom';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import { useLicensePlateDestruction } from 'components/license-plate-destruction/context';
import { GET_TAGS_BY_EXPIRATION_DATE } from 'services/data-layer';
import { useLicensePlate } from '../context';

import {
  canDisassociate,
  canReconcile,
  canSubmitDestruction,
} from '../../../utilities/common';

import {
  canUpdateTagAO,
  canUpdateTagGF,
} from '../../../utilities/authorization';

const viewHistory = () => {
  return {
    action: 'viewPlate',
    icon: 'visibility',
    label: 'View license plate details',
  };
};

const reconcileOrderTag = (id, status, createdBy, orderNumber) => {
  // only tags in status 'SHIP'/'SH' and system MISS are eligible for reconcile
  if (canReconcile(id, status, createdBy, orderNumber)) {
    return {
      action: 'reconcileOrderTag',
      icon: 'check',
      label: 'Reconcile license plate',
    };
  }
};

const reportLostStolen = (status, orderNumber) => {
  if (canDisassociate(status, orderNumber)) {
    return {
      action: 'reportLostStolen',
      icon: 'warning',
      label: 'Report license plate lost or stolen',
    };
  }
  return null;
};

const submitForDestruction = (status) => {
  if (canSubmitDestruction(status)) {
    return {
      action: 'submitForDestruction',
      icon: 'delete',
      label: 'Submit for destruction',
    };
  }
  return null;
};

const reportDamaged = (status, orderNumber) => {
  if (canDisassociate(status, orderNumber)) {
    return {
      action: 'reportDamaged',
      icon: 'warning',
      label: 'Report license plate damaged',
    };
  }
};

const constructTagMenu = (tag, canUpdateTags) => {
  const { tagActivityTypeCode, createdBy, id, unicorTag } = tag;
  const orderNumber = unicorTag?.orderNumber;
  if (canUpdateTags && id?.substr(0, 1) !== 'G') {
    return [
      viewHistory(),
      reconcileOrderTag(id, tagActivityTypeCode, createdBy, orderNumber),
      reportLostStolen(tagActivityTypeCode, orderNumber),
      reportDamaged(tagActivityTypeCode, orderNumber),
      submitForDestruction(tagActivityTypeCode),
    ].filter((action) => !!action);
  }
  return [viewHistory()].filter((action) => !!action);
};

export default function LicensePlateAction({
  tag,
  disassociateTag,
  reconcileTag,
  submitTagForDestruction,
}) {
  const history = useHistory();
  const ability = useAppAbility();
  const canUpdateTags = canUpdateTagAO(ability) || canUpdateTagGF(ability);
  const [menuItems, setMenuItems] = useState(
    constructTagMenu(tag, canUpdateTags),
  );

  const { licensePlates, selectedRows } = useLicensePlate();
  const {
    toggleDestructionEditing,
    setEligibleForDestruction,
    getIneligiblePlateDistribution,
  } = useLicensePlateDestruction();

  const [getTagsByExpirationDate] = useLazyQuery(GET_TAGS_BY_EXPIRATION_DATE, {
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      const filteredTags = data?.getTagsByExpirationDate?.filter((t) => {
        return canSubmitDestruction(t.tagActivityTypeCode);
      });
      submitTagForDestruction(filteredTags);
    },
  });

  useEffect(() => {
    if (tag) {
      setMenuItems(constructTagMenu(tag, canUpdateTags));
    }
  }, [tag]);

  const hanlderActions = (action) => {
    switch (action) {
      case 'viewPlate':
        history.push(`/license-plate/${tag.id}`);
        break;
      case 'reconcileOrderTag':
        reconcileTag(tag);
        break;
      case 'reportLostStolen':
        disassociateTag(tag, 'lostStolen');
        break;
      case 'reportDamaged':
        disassociateTag(tag, 'damaged');
        break;
      case 'submitForDestruction':
        if (Object.keys(selectedRows).length === 0) {
          getTagsByExpirationDate({
            variables: {
              input: [{ id: tag.id, expirationDate: tag.expirationDate }],
            },
          });
        } else {
          setEligibleForDestruction(licensePlates, selectedRows);
          getIneligiblePlateDistribution(licensePlates, selectedRows);
          toggleDestructionEditing();
        }
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="height-4 width-4">
        <Menu
          actionIconSize="usa-icon--size-4"
          menuItems={menuItems}
          onActionClick={hanlderActions}
          iconColor="text-primary"
        />
      </div>
    </>
  );
}

LicensePlateAction.propTypes = {
  tag: PropTypes.object.isRequired,
  disassociateTag: PropTypes.func.isRequired,
  reconcileTag: PropTypes.func.isRequired,
  submitTagForDestruction: PropTypes.func.isRequired,
};
