import React from 'react';

const useCharCounter = (maxLen = 255) => {
  const [count, setCount] = React.useState(false);

  const formatNum = (num) =>
    num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

  const counterContent = React.useMemo(() => {
    let text;
    if (!count || count <= 0) text = `${formatNum(maxLen)} characters allowed`;
    else if (count < maxLen - 1)
      text = `${formatNum(maxLen - count)} characters left`;
    else if (count === maxLen - 1) text = '1 character left';
    else text = '0 character left';
    return text;
  }, [count]);

  const CharCounter = (
    <div className="usa-hint usa-character-count__message">
      {counterContent}
    </div>
  );

  return [CharCounter, setCount];
};

export default useCharCounter;
