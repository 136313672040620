/* eslint-disable react/prop-types */
import React, { useState, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Alert,
  SelectDropdown,
  DatePicker,
  RequiredFieldIndicator,
} from '@gsa/afp-component-library';
import { useLazyQuery, useQuery } from '@apollo/client';
import { VehiclePropType } from 'utilities/types';
import usePortalModal from 'utilities/portal-modal';
import { GET_AL_CATEGORY, GET_ASSET_DISPOSAL } from 'services/data-layer';
import {
  itemInventoryStatuses,
  DEFAULT_SELECT_OPTION_LABEL,
} from 'utilities/consts';
import './vehicle-edit.css';
import NumberFormat from 'react-number-format';
import moment from 'moment/moment';
import { omit } from 'lodash';
import { compareDisposalAcquisitionAndTodayDates } from '../helpers/common';
import {
  SoldWarning,
  MissingWarning,
  StatusWarningNoPlate,
} from '../../../widgets/vehicle-alerts';

const formReducer = (state, newState) => {
  return {
    ...state,
    ...newState,
  };
};

const DISPOSED_STATUS_CODE = 'SD';
const MISSING_STATUS_CODE = 'MS';
const ACTIVE_STATUS_CODE = 'AC';
const DELIVERED_STATUS_CODE = 'DD';
const ACKNOWLEDGE_STATUS_CODE = 'ACKO';
const ORDERED_STATUS_CODE = 'OR';
const NOT_AVAILABLE_STATUS_CODE = 'NA';
const DAMAGED_STATUS_CODE = 'DE';

const getDisplayItemInventoryStatusCode = (statusId) => {
  if (!statusId) {
    return '';
  }
  // Some statuses in the constant are integers but will be strings in the payload
  const converted = parseInt(statusId, 10);
  const selected = converted
    ? itemInventoryStatuses[converted]
    : itemInventoryStatuses[statusId];
  if (!selected?.displayStatus) {
    return '';
  }
  if (selected.displayStatus.toLowerCase() === 'active') {
    return ACTIVE_STATUS_CODE;
  }
  if (selected.displayStatus.toLowerCase() === 'missing/stolen') {
    return MISSING_STATUS_CODE;
  }
  if (
    selected.displayStatus.toLowerCase() === 'sold' ||
    selected.displayStatus.toLowerCase() === 'disposed'
  ) {
    return DISPOSED_STATUS_CODE;
  }
  if (selected.displayStatus.toLowerCase() === 'n/a') {
    return NOT_AVAILABLE_STATUS_CODE;
  }
  if (selected.displayStatus.toLowerCase() === 'delivered') {
    return DELIVERED_STATUS_CODE;
  }
  if (selected.displayStatus.toLowerCase() === 'ordered') {
    return ORDERED_STATUS_CODE;
  }
  if (selected.displayStatus.toLowerCase() === 'acknowledged') {
    return ACKNOWLEDGE_STATUS_CODE;
  }
  if (selected.displayStatus.toLowerCase() === 'destroyed') {
    return DAMAGED_STATUS_CODE;
  }
  return '';
};

const getWarningComponent = (status, tagNumber) => {
  switch (status) {
    case DISPOSED_STATUS_CODE:
      return tagNumber ? (
        <SoldWarning licensePlate={tagNumber} />
      ) : (
        <StatusWarningNoPlate statusName="disposed" />
      );
    case MISSING_STATUS_CODE:
      return tagNumber ? (
        <MissingWarning licensePlate={tagNumber} />
      ) : (
        <StatusWarningNoPlate statusName="missing/stolen" />
      );
    case ACTIVE_STATUS_CODE:
      return <StatusWarningNoPlate statusName="active" />;
    default:
      return null;
  }
};

const VehicleStatusEdit = ({ vehicle, onSave, onClose }) => {
  const [showFieldErrors, setShowFieldErrors] = useState(false);
  const [errorsCount, setErrorsCount] = useState(0);
  const [showWarning, setShowWarning] = useState(false);
  const [showSoldRequiredWarning, setShowSoldRequiredWarning] = useState(false);
  const [VehicleStatusModal, openModal, closeModal] = usePortalModal();
  const initItemInventoryStatusCode = getDisplayItemInventoryStatusCode(
    vehicle?.inventoryStatus?.id,
  );
  const [state, dispatch] = useReducer(formReducer, {
    displayItemInventoryStatusCode: initItemInventoryStatusCode,
    itemInventoryStatusCode: vehicle?.inventoryStatus?.id || '',
    acquisitionDate:
      vehicle?.assetAcquisition?.originalAcquisitionDate || undefined,
    actualDisposalDate: undefined,
    statusOpts: [
      {
        label: 'Active',
        value: ACTIVE_STATUS_CODE,
      },
      {
        label: 'Missing/Stolen',
        value: MISSING_STATUS_CODE,
      },
      {
        label: 'Disposed',
        value: DISPOSED_STATUS_CODE,
      },
    ],

    actualDisposalReason: undefined,
    disposalCost: undefined,
    disposalProceeds: undefined,
    disposalReasonOptions: [],
    disposalDateError: '',
    acquisitionDateError: '',
    statusOptionType: '',
  });

  const [getAssetDisposal, { data: getAssetDisposalResponse }] = useLazyQuery(
    GET_ASSET_DISPOSAL,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const { data: disposalReasons } = useQuery(GET_AL_CATEGORY, {
    variables: { lookupCategories: ['DISPOSAL_REASON'] },
    fetchPolicy: 'no-cache',
  });

  const getOptions = (options) => {
    return options?.map((option) => {
      const { code, description } = option;
      return {
        value: code,
        label: description,
      };
    });
  };

  useEffect(() => {
    openModal();
    if (vehicle.uuid) {
      getAssetDisposal({ variables: { assetId: vehicle.uuid } });
    }
  }, []);

  useEffect(() => {
    if (disposalReasons?.getALByCategories[0]?.options)
      dispatch({
        disposalReasonOptions: [
          { value: '', label: '- Select -' },
          ...getOptions(disposalReasons?.getALByCategories[0]?.options),
        ],
      });
  }, [disposalReasons]);

  useEffect(() => {
    if (getAssetDisposalResponse?.getDisposalByAssetId) {
      dispatch({
        actualDisposalDate:
          getAssetDisposalResponse.getDisposalByAssetId.actualDisposalDate,
        actualDisposalReason:
          getAssetDisposalResponse.getDisposalByAssetId.actualDisposalReason,
        disposalCost:
          getAssetDisposalResponse.getDisposalByAssetId.disposalCost,
        disposalProceeds:
          getAssetDisposalResponse.getDisposalByAssetId.disposalProceeds,
      });
    }
  }, [getAssetDisposalResponse]);

  const clearAllFieldError = () => {
    dispatch({ disposalDateError: '' });
    dispatch({ acquisitionDateError: '' });
    dispatch({ actualDisposalReasonError: '' });
  };

  const checkSoldStatusRequiredField = () => {
    dispatch(
      !state.actualDisposalDate
        ? { disposalDateError: 'Disposal date is required.' }
        : '',
    );
    dispatch(
      !state.actualDisposalReason
        ? { actualDisposalReasonError: 'Disposal reason is required.' }
        : '',
    );
    return !state.actualDisposalDate || !state.actualDisposalReason;
  };

  const checkDisposalDate = (e) => {
    if (
      (moment(e).isValid() && moment(e, 'MM/DD/YYYY', true).isValid()) ||
      e === ''
    ) {
      dispatch({
        disposalDateError: compareDisposalAcquisitionAndTodayDates(
          e,
          state.acquisitionDate,
          'disposalDate',
        ),
      });
      dispatch({ actualDisposalDate: e });
    } else {
      dispatch({ disposalDateError: 'Please enter correct date format.' });
    }
  };

  const checkAcquisitionDate = (e) => {
    if (
      (moment(e).isValid() && moment(e, 'MM/DD/YYYY', true).isValid()) ||
      e === ''
    ) {
      dispatch({
        acquisitionDateError: compareDisposalAcquisitionAndTodayDates(
          state.disposalDate,
          e,
          'acquisitionDate',
        ),
      });
      dispatch({ acquisitionDate: e });
    } else {
      dispatch({ acquisitionDateError: 'Please enter correct date format.' });
    }
  };

  useEffect(() => {
    setShowFieldErrors(false);
    clearAllFieldError();

    if (state.actualDisposalDate) {
      checkDisposalDate(state.actualDisposalDate);
    }
    if (state.acquisitionDate) {
      checkAcquisitionDate(state.acquisitionDate);
    }
    if (state.itemInventoryStatusCode === 'SD') {
      dispatch({ statusOptionType: 'AssetDisposalArg' });
      if (checkSoldStatusRequiredField()) {
        setShowSoldRequiredWarning(true);
      } else {
        setShowSoldRequiredWarning(false);
      }
    } else {
      setShowSoldRequiredWarning(false);
      clearAllFieldError();
    }
  }, [
    state.acquisitionDate,
    state.itemInventoryStatusCode,
    state.actualDisposalDate,
    state.actualDisposalReason,
  ]);

  const close = () => {
    // eslint-disable-next-line no-unused-expressions
    onClose && onClose();
    closeModal();
  };

  const getSoldRequiredFieldWarning = () => (
    <Alert type="warning" slim className="margin-top-4 margin-bottom-2">
      <span data-testid="sold-status-required-field-alert">
        Disposal date and disposal reason must be completed.
      </span>
    </Alert>
  );

  const countErrors = () => {
    const reasonErrorCount = state?.actualDisposalReasonError ? 1 : 0;
    const disposalErrorCount = state?.disposalDateError ? 1 : 0;
    const acquisitionDateErrorCount = state?.acquisitionDateError ? 1 : 0;
    return reasonErrorCount + disposalErrorCount + acquisitionDateErrorCount;
  };

  const save = () => {
    if (countErrors() > 0) {
      setErrorsCount(countErrors);
      setShowFieldErrors(true);
      return;
    }

    setShowFieldErrors(false);
    const {
      itemInventoryStatusCode,
      actualDisposalDate,
      actualDisposalReason,
      disposalCost,
      disposalProceeds,
      statusOptionType,
    } = state;
    const assetDisposalArg = {
      ...omit(getAssetDisposalResponse?.getDisposalByAssetId, [
        'id',
        '__typename',
      ]),
      assetId: vehicle.uuid,
      actualDisposalDate,
      disposalProceeds: Number(parseFloat(disposalProceeds).toFixed(2)),
      actualDisposalReason,
      disposalCost: Number(parseFloat(disposalCost).toFixed(2)),
      originalAcquisitionDate:
        state.acquisitionDate !==
        vehicle?.assetAcquisition?.originalAcquisitionDate
          ? state.acquisitionDate
          : undefined,
    };
    onSave &&
      onSave(
        itemInventoryStatusCode,
        vehicle.id,
        statusOptionType,
        assetDisposalArg,
      );
  };

  const setStatus = (e) => {
    dispatch({
      itemInventoryStatusCode: e.target.value,
      displayItemInventoryStatusCode: e.target.value,
    });
    setShowWarning(e.target.value !== state.initItemInventoryStatusCode);
  };

  const getErrorComponent = () => {
    return (
      <Alert
        className="error-alert margin-bottom-2"
        onClose={false}
        slim
        type="error"
      >
        This form has{' '}
        <span className="text-bold">
          {errorsCount === 1
            ? `${errorsCount} error. `
            : `${errorsCount} errors. `}
        </span>
        Please correct all fields outlined in red before saving.
      </Alert>
    );
  };

  const ModalHeader = ({ vin }) => {
    return (
      <div>
        <Alert type="warning" slim className="margin-top-4 margin-bottom-2">
          <span data-testid="motor-pool-warning-alert">
            Changing the status of the vehicle may result in canceling any
            future motor pool reservations made on this vehicle.
          </span>
        </Alert>
        {showWarning &&
          getWarningComponent(state.itemInventoryStatusCode, state.tag)}
        {showSoldRequiredWarning && getSoldRequiredFieldWarning()}
        {showFieldErrors && errorsCount > 0 && getErrorComponent()}

        <h2>Edit vehicle status</h2>
        <p className="font-body-xs">
          Edit vehicle status for VIN <span className="text-bold">{vin}</span>{' '}
          in the form below.
        </p>
        <p className="font-body-2xs text-italic">
          Disposal date and disposal reason must be completed before you can
          mark the vehicle as disposed.
        </p>
      </div>
    );
  };

  const statusLookup = (props) => {
    return (
      <SelectDropdown
        data-testid="vehicle-status-lookup"
        label="Vehicle status"
        name="itemInventoryStatusCode"
        id="itemInventoryStatusCode"
        options={[
          { value: '', label: DEFAULT_SELECT_OPTION_LABEL },
          ...(props.statusOpts || []),
        ]}
        value={props.value}
        onChange={props.handleChange}
        required
      />
    );
  };

  const handleEditedData = (field, ev) => {
    setShowFieldErrors(false);
    const updatedData = {};
    if (field === 'actualDisposalReason' && ev !== '') {
      updatedData[field] = state.disposalReasonOptions.find(
        (option) => option.value === ev,
      ).label;
    } else if (
      (field === 'actualDisposalDate' || field === 'acquisitionDate') &&
      ev === ''
    ) {
      updatedData[field] = null;
    } else {
      updatedData[field] = ev;
    }
    dispatch({ ...updatedData });
  };

  const getDisposalReasonValue = (reason) => {
    const optionValue =
      state.disposalReasonOptions.find((option) => option.label === reason)
        ?.value ?? '10';
    return reason ? optionValue : '0';
  };
  return (
    <VehicleStatusModal
      actions={
        <>
          <Button variant="unstyled" label="Cancel" onClick={close} />
          <Button
            className="margin-left-2"
            onClick={save}
            label="Save and close"
          />
        </>
      }
      title={<ModalHeader vin={vehicle?.id} />}
      onClose={close}
    >
      <div className="grid-row grid-gap grid-col-12">
        <div className="grid-col-4">
          {statusLookup({
            statusOpts: state.statusOpts,
            value: state.displayItemInventoryStatusCode,
            handleChange: setStatus,
          })}
        </div>
      </div>
      {state.itemInventoryStatusCode === 'SD' && (
        <div className="grid-row grid-gap grid-col-12">
          <div className="grid-col-6 margin-top-1">
            <DatePicker
              data-testid="disposal-date"
              defaultValue={state?.actualDisposalDate}
              id="disposal-date"
              name="disposal-date"
              hint="mm/dd/yyyy"
              format="MM/DD/YYYY"
              label={
                <span className="text-bold">
                  Disposal date <RequiredFieldIndicator />
                </span>
              }
              onChange={(val) => handleEditedData('actualDisposalDate', val)}
              errorMessage={showFieldErrors && state?.disposalDateError}
            />
          </div>
          <div className="grid-col-6 margin-top-1">
            <DatePicker
              data-testid="acquisition-date"
              defaultValue={state?.acquisitionDate}
              id="acquisition-date"
              name="acquisition-date"
              hint="mm/dd/yyyy"
              format="MM/DD/YYYY"
              label="Acquisition date"
              onChange={(val) => handleEditedData('acquisitionDate', val)}
              errorMessage={showFieldErrors && state?.acquisitionDateError}
            />
          </div>
          <div className="grid-col-12 margin-top-1">
            <SelectDropdown
              label="Disposal reason"
              name="disposalReason"
              onChange={(e) =>
                handleEditedData('actualDisposalReason', e.target.value)}
              value={getDisposalReasonValue(state?.actualDisposalReason)}
              options={state.disposalReasonOptions}
              required
              errorMessage={showFieldErrors && state?.actualDisposalReasonError}
            />
          </div>
          <div className="grid-col-6">
            <label htmlFor="disposal-cost">
              <span className="text-bold">Disposal cost</span>
            </label>
            <div className="usa-input-group usa-input-group--sm">
              <div className="usa-input-prefix" aria-hidden="true">
                $
              </div>
              <NumberFormat
                className="usa-input"
                value={state?.disposalCost}
                isAllowed={({ value }) => value <= 999999.99}
                thousandSeparator
                id="disposal-cost"
                name="disposal-cost"
                onValueChange={({ value }) =>
                  handleEditedData('disposalCost', parseFloat(value).toFixed(2))}
              />
            </div>
          </div>
          <div className="grid-col-6">
            <label htmlFor="disposal-proceeds">
              <span className="text-bold">Disposal proceeds</span>
            </label>
            <div className="usa-input-group usa-input-group--sm">
              <div className="usa-input-prefix" aria-hidden="true">
                $
              </div>
              <NumberFormat
                value={state?.disposalProceeds}
                isAllowed={({ value }) => value <= 999999.99}
                thousandSeparator
                className="usa-input"
                id="disposal-proceeds"
                name="disposal-proceeds"
                onValueChange={({ value }) =>
                  handleEditedData(
                    'disposalProceeds',
                    parseFloat(value).toFixed(2),
                  )}
              />
            </div>
          </div>
        </div>
      )}
    </VehicleStatusModal>
  );
};

VehicleStatusEdit.defaultProps = {
  onClose: () => undefined,
  onSave: () => undefined,
};

VehicleStatusEdit.propTypes = {
  vehicle: PropTypes.shape(VehiclePropType).isRequired,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

export default VehicleStatusEdit;
