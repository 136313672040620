import { z } from 'zod';

export const reassignVehicleSchema = z.object({
  zone: z.string().nonempty({ message: 'Zone is required' }).nullish(),
  fmc: z.string().nonempty({ message: 'FMC is required' }).nullish(),
  fsrEmailByZone: z
    .string()
    .nonempty({ message: 'FSR email is required' })
    .nullish(),
});
