import React, { useState, useEffect } from 'react';
import { Alert, Button } from '@gsa/afp-component-library';
import FilterForm from './filter-form';
import ReviewList from './review-list';

import ResultTable from './result-table';
const initialPaginationState = {
  limit: 10,
  offset: 0,
  currentPage: 1,
  isReset: false,
};

const AddCustomerAccounts = ({ providerContext }) => {
  const [needToReviewRowsState, setNeedToReviewRowsState] = useState([]);
  const [currentFiltersStatus, setCurrentFiltersState] = useState({});
  const [order, setOrder] = useState('accountName ASC');

  const [paginationState, setPaginationState] = useState(
    initialPaginationState,
  );

  const [showFinalAlertBox, setShowFinalAlertBox] = useState(false);

  useEffect(() => {
    if (Object.values(currentFiltersStatus).filter((x) => x).length > 0) {
      setNeedToReviewRowsState([]);
    }
  }, [currentFiltersStatus]);

  return (
    <>
      <h4>1.Find and select accounts to add </h4>
      <p>
        Use the filters below to find your customer accounts. Add or remove
        filters as needed to update the table results and find what you need.{' '}
      </p>
      <FilterForm
        currentFiltersStatus={currentFiltersStatus}
        setCurrentFiltersState={setCurrentFiltersState}
        setShowFinalAlertBox={setShowFinalAlertBox}
      />
      {Object.values(currentFiltersStatus).filter((x) => x).length > 0 && (
        <ResultTable
          providerContext={providerContext}
          needToReviewRowsState={needToReviewRowsState}
          setNeedToReviewRowsState={setNeedToReviewRowsState}
          currentFiltersStatus={currentFiltersStatus}
          paginationState={paginationState}
          setPaginationState={setPaginationState}
          order={order}
          setShowFinalAlertBox={setShowFinalAlertBox}
        />
      )}
      {Object.values(currentFiltersStatus).filter((x) => x).length > 0 &&
        needToReviewRowsState.length > 0 && (
          <ReviewList
            needToReviewRowsState={needToReviewRowsState}
            setShowFinalAlertBox={setShowFinalAlertBox}
          />
        )}
      {showFinalAlertBox && (
        <Alert showClose type="warning">
          Are you sure you want to add selected accounts to this office?
          <Button
            className="margin-top-2"
            variant="outline"
            label="Yes, add selected accounts"
            onClick={() => {
              const customerAccountIds = needToReviewRowsState.map(
                (needToReviewRow) => needToReviewRow.customerId,
              );
              providerContext.associateCustomerAccountsToOffice({
                variables: {
                  customerAccountIds,
                  officeCode: providerContext?.organizationData?.officeCode,
                },
              });
            }}
          />
        </Alert>
      )}
    </>
  );
};

export default AddCustomerAccounts;
