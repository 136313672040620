import React, { useEffect, useState } from 'react';
import {
  Button,
  Spinner,
  Alert,
} from '@gsa/afp-component-library';
import usePortalModal from 'utilities/portal-modal';
import VehicleLocationEditForm from './vehicle-location-edit-form';
import { AddressHelper } from 'utilities/address';

const VehicleLocationEditModal = ({
  vehicle,
  onClose,
  onSave,
  updateVehicleLocationLoading,
  updateVehicleLocationError,
}) => {
  
  const [
    VLEditModal,
    openModal,
    closeModal,
  ] = usePortalModal();

  useEffect(() => {
    openModal();
  }, []);

  const handleEditClose = () => {
    closeModal();
    onClose();
  };

  const Header = () => {
    return (
      <>
        <h1>Edit vehicle location</h1>
        <div>Use the form below to edit the vehicle location.</div>
      </>
    );
  };

  return (
    <VLEditModal
      title={<Header />}
      onClose={handleEditClose}
      actions={
        <>
          <Button
            variant="unstyled"
            onClick={handleEditClose}
            label="Cancel"
            id="close-modal-button"
          />
          <Button
            className="margin-left-2"
            type="submit"
            label="Save and close"
            form="vehicleLocationEditForm"
            disabled={updateVehicleLocationLoading}
          />
          {updateVehicleLocationLoading && (
            <Spinner
              size="small"
              aria-busy="true"
              className="display-inline-block margin-left-2"
            />
          )}
        </>
      } // eo actions
    >
      {updateVehicleLocationError && (
        <Alert
          type="error"
          focused
          slim
          className="margin-bottom-2 padding-bottom-2"
        >
          An unexpected error has occurred whicle updating {vehicle.id}'s locaiton
        </Alert>
      )}
      <VehicleLocationEditForm
        onSave={onSave}
        vehicle={vehicle}
      />
    </VLEditModal>
  );
};

export default VehicleLocationEditModal;
