/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useWatch } from 'react-hook-form';
import useUser from 'utilities/use-user';
// eslint-disable-next-line import/named
import { isDifferenceMoreThan35Days } from 'utilities/validation';
import { VMSOperations, VMSSubjects } from 'utilities/consts';
import { FuelReportLevelSelection } from './fuel-report-level-selection';
import DateRangeField from './date-range-field';
import OtherFuelFilters from './other-report-filters';
import { VehicleSelectionOptions } from './vehicle-selection-options';
import { useExport } from '../export-provider';
import { HiddenField } from '../components/hidden-field';

const initialReportSelectionOptions = [
  {
    label: 'Transactional by vehicle',
    description:
      'All individual transactions for every vehicle within the selected date range',
    radioValue: 'transByVehicle',
  },
  {
    label: 'Summary by vehicle',
    description:
      'Transactions grouped by VIN, then summarized within the selected date range',
    radioValue: 'summaryByVehicle',
  },
  {
    label: 'Detailed by reporting level',
    description: 'Transactions grouped by product type only, then summarized',
    radioValue: 'detailedByReporting',
  },
  {
    label: 'Summary by reporting level',
    description: 'Transactions grouped by product type only, then summarized',
    radioValue: 'summaryByReportingLevel',
  },
];

const REPORT_TYPE = 'FUEL';
const fromProps = {
  required: true,
  id: 'fuelReportFromDate',
  defaultValue: moment().subtract(1, 'months').format('YYYY-MM-DD'),
};
const toProps = {
  required: true,
  id: 'fuelReportToDate',
  defaultValue: moment().format('YYYY-MM-DD'),
};

// eslint-disable-next-line react/prop-types
export const FuelReportFilters = ({
  control,
  setValue,
  register,
  formState,
}) => {
  const [reportSelectionOptions, setReportSelectionOptions] = useState(
    initialReportSelectionOptions,
  );
  const [fuelReportFromDate, fuelReportToDate, agency] = useWatch({
    name: ['fuelReportFromDate', 'fuelReportToDate', 'agency'],
    control,
  });

  const { isScheduled } = useExport();

  const [isValidRange, setIsValidRange] = useState(true);
  useEffect(() => {
    if (toProps.defaultValue && fromProps.defaultValue)
      isDifferenceMoreThan35Days(fromProps.defaultValue, toProps.defaultValue)
        ? setIsValidRange(false)
        : setIsValidRange(true);
  }, []);

  useEffect(() => {
    if (fuelReportFromDate && fuelReportToDate)
      isDifferenceMoreThan35Days(fuelReportFromDate, fuelReportToDate)
        ? setIsValidRange(false)
        : setIsValidRange(true);
  }, [fuelReportFromDate, fuelReportToDate]);

  const { isRole, isScopeLevel, isGsaEmployee } = useUser();

  const isValidScope =
    isRole('CustomerAdmin') &&
    (isScopeLevel(
      'scopeLevel1',
      18,
      VMSOperations.Update,
      VMSSubjects.VEHICLE,
    ) ||
      isScopeLevel(
        'scopeLevel1',
        61,
        VMSOperations.Update,
        VMSSubjects.VEHICLE,
      ));

  useEffect(() => {
    if (
      agency &&
      (agency === '015' || agency === '070') &&
      !(isValidScope || isGsaEmployee())
    ) {
      const newReportSelectionOptions = reportSelectionOptions.slice(1);
      setReportSelectionOptions(newReportSelectionOptions);
      setValue(
        'fuel_report_selection',
        newReportSelectionOptions[0].radioValue,
      );
    }
  }, [agency]);

  return (
    <>
      {isScheduled === 'no' ? (
        <DateRangeField
          isValidRange={isValidRange}
          control={control}
          fromProps={fromProps}
          toProps={toProps}
          customErrorMessage='The start date should be less than or equal to 1 month from the end date'
        />
      ) : (
        <>
          <HiddenField register={register} name={fromProps.id} />
          <HiddenField register={register} name={toProps.id} />
        </>
      )}
      <FuelReportLevelSelection
        control={control}
        reportSelectionOptions={reportSelectionOptions}
      />

      <VehicleSelectionOptions
        control={control}
        register={register}
        reportType={REPORT_TYPE}
        setValue={setValue}
      />
      <OtherFuelFilters
        control={control}
        setValue={setValue}
        register={register}
        errors={formState?.errors}
      />
    </>
  );
};
