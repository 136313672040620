import React, { useEffect } from 'react';
import { RequiredFieldIndicator, TextInput } from '@gsa/afp-component-library';
import { Controller, useFormContext } from 'react-hook-form';
import { useMotorPool } from '../../../motor-pool-provider';
import { MOTOR_POOL_VEHICLE_FORM_FIELDS } from './vehicle-constants';

const MotorPoolVehicleEditFields = () => {
  const { motorPoolVehicleSelected } = useMotorPool();

  const {
    setValue,
    formState: { errors },
    control,
  } = useFormContext();

  const extractValue = (value, isField = false) => {
    if (typeof value !== 'boolean') {
      return value;
    }
    if (isField) return value ? 'Yes' : 'No';
    return value ? 'yes' : 'no';
  };

  useEffect(() => {
    // To pre-populate the form with selected row values
    Object.values(MOTOR_POOL_VEHICLE_FORM_FIELDS).forEach(({ name }) => {
      setValue(name, extractValue(motorPoolVehicleSelected[name]));
    });
  }, []);

  return (
    <div>
      {Object.values(MOTOR_POOL_VEHICLE_FORM_FIELDS).map((location) => {
        return (
          <div key={location.name} className={location.wrapperClass || ''}>
            {!location.editable ? (
              <>
                <div className="text-bold margin-top-2">
                  {location.label}{' '}
                  {location.required && <RequiredFieldIndicator />}
                </div>
                {extractValue(motorPoolVehicleSelected[location.name], true)}
              </>
            ) : (
              <Controller
                name={location.name}
                control={control}
                render={({ field, ref }) => (
                  <TextInput
                    {...field}
                    ref={ref}
                    name={location.name}
                    id={location.id}
                    type="text"
                    {...(location.additionalAttributes || {})}
                    label={
                      <span className="text-bold">
                        {location.label}{' '}
                        {location.required && <RequiredFieldIndicator />}
                      </span>
                    }
                    errorMessage={
                      errors && errors[location.name]
                        ? errors[location.name].message
                        : null
                    }
                    aria-invalid={
                      errors && errors[location.name] ? 'true' : 'false'
                    }
                  />
                )}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default MotorPoolVehicleEditFields;
