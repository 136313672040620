/* eslint-disable filenames/match-exported */
import React, { useState, useEffect, useCallback } from 'react';
import {
  AFPTable,
  Icon,
  EmptyState,
  Spinner,
  Pagination,
  Tooltip,
  Tag,
} from '@gsa/afp-component-library';
import { useLazyQuery } from '@apollo/client';
import useUser from 'utilities/use-user';
import { GET_RECALLS_BY_VEHICLE } from '../../../../services/data-layer';
import { useVehicle } from '../../vehicle-context-provider';
import { VehicleDescription } from '../../widgets/recall-table-widgets';
import { formatDataForTable } from '../../widgets/table-data-widgets';
import AddCommentModal from '../../modals/add-comment-modal';
import AddRemedyDate from '../../modals/add-remedy-date';
import './styles.css';

const initialPaginationState = {
  limit: 10,
  offset: 0,
  currentPage: 1,
  isReset: false,
};
const tableRef = React.createRef();

const VehicleRecallTable = ({ ownerShipType }) => {
  const { vehicle, showSystemError } = useVehicle();
  const [paginationState, setPaginationState] = useState(
    initialPaginationState,
  );

  const [tableData, setTableData] = useState(null);
  const [dataCount, setDataCount] = useState(0);
  const [addComment, setAddComment] = useState(false);
  const [addRemedyDate, setAddRemedyDate] = useState(false);
  const [actionData, setActionData] = useState({});
  const { isRole } = useUser();
  const [order, setOrder] = useState('status ASC');

  const [getRecallsData, { data, loading, error }] = useLazyQuery(
    GET_RECALLS_BY_VEHICLE,
    {
      fetchPolicy: 'network-only',
    },
  );

  const getData = () => {
    getRecallsData({
      variables: {
        id: vehicle?.uuid,
        limit: paginationState.limit,
        offset: paginationState.offset,
        order,
      },
    });
  };

  const renderRowSubComponent = useCallback(({ row: { original } }) => {
    return <VehicleDescription original={original} />;
  }, []);

  // Handler for Actions
  const actionClick = (action, queryData) => {
    if (action === 'addComment') {
      setAddComment(true);
      setActionData(queryData);
    }
    if (action === 'addRemedyDate') {
      setAddRemedyDate(true);
      setActionData(queryData);
    }
  };

  useEffect(() => {
    getData();
  }, [paginationState]);

  useEffect(() => {
    if (data) {
      const newData = formatDataForTable(
        data,
        actionClick,
        ownerShipType,
        isRole,
      );

      setTableData(newData);
      setDataCount(data?.getRecallsByVehicle?.count || 0);
    }
  }, [data]);

  const onSort = (sort) => {
    setOrder(sort);
    getRecallsData({
      variables: {
        id: vehicle?.uuid,
        limit: paginationState.limit,
        offset: paginationState.offset,
        order: sort,
      },
    });
  };

  useEffect(() => {
    getData();
  }, [addComment, addRemedyDate]);

  const tableHeaderData = [
    {
      Header: 'Campaign',
      accessor: 'recallCode',
      sortable: false,
      headerClassName: 'width-card-lg',
      Cell: ({ value, row: { original } }) => {
        return (
          <>
            <div>{value}</div>

            {original?.recallCampaign?.shouldStopDrive && (
              <div>
                <Tag key="Urgent-Dark" variant="Urgent-Dark">
                  STOP DRIVE
                </Tag>
              </div>
            )}

            {original?.recallCampaign?.shouldParkOutside && (
              <div>
                <Tag
                  key="Important-Dark"
                  variant="Important-Dark"
                  className="text-no-wrap"
                >
                  PARK OUTSIDE
                </Tag>
              </div>
            )}
          </>
        );
      },
    },
    {
      Header: 'Status',
      accessor: 'status',
      headerClassName: 'width-card-lg',
      sortable: false,
      disableSortBy: true,
    },
    {
      Header: () => (
        <span
          className="display-flex flex-align-center"
          title="Remedy available"
        >
          Remedy available
          <Tooltip
            className="usa-button--unstyled"
            label='Remedy Available: "Yes" means a recall repair or parts are available.'
            position="top"
          >
            <Icon iconName="info" className="margin-left-1 text-primary" />
          </Tooltip>
        </span>
      ),
      accessor: 'recallCampaign.isRemedyAvailable',
      sortable: true,
      headerClassName: 'width-card-lg',
      Cell: ({ value }) => (value ? 'Yes' : 'No'),
    },
    {
      Header: () => (
        <span
          className="display-flex remedy-tooltip text-no-wrap"
          title="Remedy date"
        >
          Remedy date
          <Tooltip
            className="usa-button--unstyled"
            label="Remedy date is the date that you had the recall addressed."
            position="top"
          >
            <Icon iconName="info" className="margin-left-1 text-primary" />
          </Tooltip>
        </span>
      ),
      accessor: 'remedyDate',
      headerClassName: 'width-card-lg',
      sortable: false,
      disableSortBy: true,
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      sortable: false,
    },
  ];

  const TableWrapper = ({ tableData, loading, dataCount }) => {
    return (
      <>
        <AFPTable
          ref={tableRef}
          columns={tableHeaderData}
          data={loading ? [] : tableData || []}
          testId="afp-responsive-table-test-id"
          defaultSort={order}
          fullWidth
          bordered={false}
          expandable
          scrollable={false}
          stacked
          renderRowSubComponent={renderRowSubComponent}
          onSort={onSort}
        />
        {loading && <Spinner className="padding-y-9" />}
        {tableData?.length > 0 ? (
          <Pagination
            variant="advanced"
            itemsCount={dataCount}
            itemsPerPage={paginationState.limit}
            currentPage={paginationState.currentPage}
            onPageChange={(currentPage, itemsPerPage) => {
              const offset = (currentPage - 1) * itemsPerPage;
              setPaginationState({
                limit: itemsPerPage,
                offset,
                currentPage,
              });
            }}
            isReset={paginationState.isReset}
            itemsPerPageOptions={[10, 25, 50]}
          />
        ) : null}

        {(tableData == null || tableData.length === 0) && !loading && (
          <div className="bg-gray-3 padding-y-5">
            <div className="text-center padding-y-4">
              <EmptyState alt="Image not available" hasBackground />
            </div>

            <div className="text-center text-bold" data-testid="no-recalls">
              This vehicle has no open or closed recalls.
            </div>
          </div>
        )}
      </>
    );
  };

  if (error) showSystemError(true);

  return (
    <>
      {addComment ? (
        <AddCommentModal
          onClose={() => setAddComment(false)}
          actionData={actionData}
        />
      ) : null}
      {addRemedyDate ? (
        <AddRemedyDate
          onClose={() => setAddRemedyDate(false)}
          actionData={actionData}
        />
      ) : null}

      <TableWrapper
        tableData={tableData}
        loading={loading}
        pageCount={initialPaginationState.limit}
        dataCount={dataCount}
      />
    </>
  );
};

export default VehicleRecallTable;
