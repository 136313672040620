/* eslint-disable react/no-array-index-key */
/* eslint-disable filenames/match-exported */
import React from 'react';
import { Button } from '@gsa/afp-component-library';

const alertMap = {
  success: {
    getContent: (reportName) => (
      <span>
        Your <span className="text-bold">{reportName}</span> has successfully
        been processed.
      </span>
    ),
  },
  validationError: {
    heading: 'We found some issues with your data',
    getContent: (downloadErrorFile, totalCount, errorText, errorCount, warningText) => (
      <>
        {errorCount === 0 ? (
          `File upload processed successfully. ${warningText}Download the error
            log to view the details. Once you address all the errors, please
            re-upload your report to validate the data.`
        ) : (
          <>
            Out of {totalCount}, we were not able to process{' '}
            <span className="text-bold">{errorText}</span>. {warningText}
            Download the error log to view the details. Once you address all the
            errors, please re-upload your report to validate the data.
          </>
        )}
        {/* <ul>
          {validationErrorList.map((error, ind) => (
            <li key={ind}>{error}</li>
          ))}
        </ul> */}
        <br />
        <div className="padding-top-4">
          <Button
            data-testid="download-error-log-button"
            type="button"
            variant="outline"
            onClick={() => downloadErrorFile()}
            leftIcon={{ name: 'file_download', type: '', className: '' }}
            label="Download error log"
          />
        </div>
      </>
    ),
  },
  recallValidationError: {
    heading: 'We found some issues with your data',
    getContent: (downloadErrorFile, totalCount, errorCount) => (
      <>
        <span>
          Out of {totalCount} VINs, we were not able to process{' '}
          <span className="text-bold">{errorCount} VINS.</span> Download the
          error log to view which VINs have which errors. Once you finish making
          corrections or removing problematic VINs, please re-upload your file
          to re-validate the data.
        </span>
        <br />
        <div className="padding-top-4">
          <Button
            data-testid="download-error-log-button"
            type="button"
            variant="outline"
            onClick={() => downloadErrorFile()}
            leftIcon={{ name: 'file_download', type: '', className: '' }}
            label="Download error log"
          />
        </div>
      </>
    ),
  },
  templateError: {
    getContent: (reportName) => (
      <span>
        The file you attempted to upload is not a{' '}
        <span className="text-bold">{reportName}</span> template or the file
        layout has been altered. Please upload a{' '}
        <span className="text-bold">{reportName}</span> template generated from
        GSAFleet.gov.
      </span>
    ),
  },
  noDataError:
    'Template is missing the data. Please update the template and upload again.',
};

// eslint-disable-next-line import/prefer-default-export
export { alertMap };
