import React, { useState, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import {
  TextInput,
  SelectDropdown,
  Label,
  Button,
} from '@gsa/afp-component-library';
import usePortalModal from '../../../utilities/portal-modal';
import { STATES_OCONUS_TERRITORIES } from 'utilities/consts';
import '../../vehicle-details/sidenav-widgets/contact-information/poc-edit.css';
import useAddressValidator from '../../registration-details/widgets/address-validator';
import { DEFAULT_SELECT_OPTION_LABEL } from '../../../utilities/consts';
import PhoneInputMask from '../../vehicle-registration/widgets/agency-and-poc/poc/phone-input-mask';

const formReducer = (state, newState) => {
  return { ...state, ...newState };
};

const fieldToLabel = {
  pocName: 'Name',
  address1: 'Street Address',
  address2: 'Street Address 2',
  address3: 'Street Address 3',
  address4: 'Street Address 4',
  city: 'City',
  stateCode: 'State',
  postalCode: 'Zip',
  pocPhone: 'Phone number',
};

const requiredFields = [
  'pocName',
  'address1',
  'city',
  'state',
  'zip',
  'pocPhone',
];

const validateFields = (reqFields, state) => {
  if (reqFields && state) {
    return reqFields.reduce((validations, field) => {
      if (!state[field] || state[field] === DEFAULT_SELECT_OPTION_LABEL) {
        validations[field] = `${fieldToLabel[field]} is required`;
      } else if (
        field === 'pocPhone' &&
        state.countryCode === 'US' &&
        state[field].replace(/\D/g, '').length < 10
      ) {
        validations[field] = 'Phone number must be 10 digits';
      }
      return validations;
    }, {});
  }
};

export default function OrderPocEdit({
  poc,
  editing,
  onClose,
  onSave,
  updateOrderPoc,
  disableChange,
  setEditOrderPoc,
}) {
  const [PocModal, openModal, closeModal] = usePortalModal();

  const [state, dispatch] = useReducer(formReducer, {
    countryCode: 'US',
    ...poc,
  });
  const [statesOptions] = useState(
    STATES_OCONUS_TERRITORIES.map((_state) => ({
      value: _state,
      label: _state,
    })),
  );
  const [errors, setErrors] = useState({});

  const [AddressConfirm, validateAddress] = useAddressValidator({
    onSavenClose: updateOrderPoc,
    openEditAddress: setEditOrderPoc,
  });

  const handleClose = () => {
    closeModal();
    onClose && onClose();
  };

  const handleSave = () => {
    let pocChanged = false;
    Object.keys(state).forEach((key) => {
      if (poc[key] !== state[key]) {
        pocChanged = true;
      }
    });

    if (pocChanged) {
      const validations = validateFields(requiredFields, state);
      const noError = Object.keys(validations).length === 0;
      if (noError) {
        setErrors({});
        validateAddress({
          primaryAddress: state.address1,
          secondaryAddress: state.address2,
          city: state.city,
          stateCode: state.state,
          postalCode: state.zip,
          countryCode: state.countryCode,
        });
        onSave(state);
      } else {
        setErrors(validations);
      }
    } else {
      handleClose();
    }
  };

  useEffect(() => {
    if (editing === true) {
      openModal();
      dispatch(poc);
      setErrors({});
    } else {
      handleClose();
    }
  }, [editing]);

  return (
    <>
      <PocModal
        title={<h1>Update point of contact</h1>}
        onClose={handleClose}
        actions={
          <>
            <Button variant="unstyled" onClick={handleClose} label="Close" />
            <Button
              className="margin-left-2"
              onClick={handleSave}
              label="Save and close"
            />
          </>
        }
      >
        <div className="edit-poc grid-row grid-gap">
          {!disableChange && (
            <>
              <div className="grid-col-12">
                Update information for this point of contact or change to a
                different point of contact.
              </div>
              <div className="grid-col-6">
                <TextInput
                  type="text"
                  label="Email"
                  disabled
                  value={state.pocEmail}
                />
              </div>
            </>
          )}
          <div className="grid-col-6">
            <TextInput
              label="Name"
              name="pocName"
              required
              value={state.pocName}
              onChange={(e) => {
                dispatch({ pocName: e.target.value });
              }}
              errorMessage={errors.pocName}
            />
          </div>
          <div className="grid-col-12">
            <Label className="text-primary text-heavy text-uppercase">
              Mailing Address
            </Label>
          </div>
          <div className="grid-col-12">
            <TextInput
              label="Street address"
              name="address1"
              required
              value={state.address1}
              onChange={(e) => {
                dispatch({ address1: e.target.value });
              }}
              errorMessage={errors.address1}
            />
          </div>
          <div className="grid-col-12">
            <TextInput
              label="Street address 2"
              name="address2"
              value={state.address2}
              onChange={(e) => {
                dispatch({ address2: e.target.value });
              }}
              errorMessage={errors.address2}
            />
          </div>
          <div className="grid-col-12">
            <TextInput
              label="Street address 3"
              name="address3"
              value={state.address3}
              onChange={(e) => {
                dispatch({ address3: e.target.value });
              }}
              errorMessage={errors.address3}
            />
          </div>
          <div className="grid-col-12">
            <TextInput
              label="Street address 4"
              name="address4"
              value={state.address4}
              onChange={(e) => {
                dispatch({ address4: e.target.value });
              }}
              errorMessage={errors.address4}
            />
          </div>
          <div className="grid-col-6">
            <TextInput
              label="City"
              name="city"
              required
              value={state.city}
              onChange={(e) => {
                dispatch({ city: e.target.value });
              }}
              errorMessage={errors.city}
            />
          </div>
          <div className="grid-col-6">
            <SelectDropdown
              label="State"
              name="state"
              required
              options={statesOptions}
              value={state.state}
              onChange={(e) => dispatch({ state: e.target.value })}
              errorMessage={errors.state}
            />
          </div>
          <div className="grid-col-6">
            <TextInput
              label="Zip"
              name="zip"
              required
              value={state.zip}
              maxLength={10}
              onChange={(e) => {
                dispatch({ zip: e.target.value });
              }}
              errorMessage={errors.zip}
            />
          </div>
          <div className="grid-col-12">
            <Label className="text-primary text-heavy text-uppercase">
              Phone Number
            </Label>
          </div>
          <div className="grid-col-6">
            {state.countryCode === 'US' ? (
              <PhoneInputMask
                label="Phone number"
                name="phone"
                required
                value={state.pocPhone}
                onChange={(e) => {
                  dispatch({ pocPhone: e.target.value });
                }}
                errorMessage={errors.pocPhone}
              />
            ) : (
              <TextInput
                label="Phone number"
                name="phone"
                required
                value={state.pocPhone}
                onChange={(e) => {
                  dispatch({ pocPhone: e.target.value });
                }}
                errorMessage={errors.pocPhone}
              />
            )}
          </div>
        </div>
      </PocModal>
      <AddressConfirm
        setAddress={(add) => {
          onSave({
            address1: add.primaryAddress,
            address2: add.secondaryAddress,
            address3: '',
            address4: '',
            city: add.city,
            state: add.stateCode,
            zip: add.postalCode,
            pocEmail: state.pocEmail,
            pocName: state.pocName,
            pocPhone: state.pocPhone,
          });
        }}
      />
    </>
  );
}

OrderPocEdit.propTypes = {
  poc: PropTypes.object.isRequired,
  editing: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};
OrderPocEdit.defaultProps = {
  editing: false,
  onClose: () => {
    //
  },
  onSave: () => {
    //
  },
};
