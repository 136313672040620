import React, { useState, useEffect } from 'react';
import { Typeahead, Button } from '@gsa/afp-component-library';
import './service-type-search.css';
import ConfirmDuplicateServiceLineModal from './confirm-duplicate-service-line-modal';
const ServiceTypeSearch = ({
  searchServiceTypes,
  typeaheadData,
  addServiceItem,
}) => {
  const [selectedValue, setSelectedValue] = useState('');
  const [confirmDuplicate, setConfirmDuplicate] = useState(false);
  const [values, setValues] = useState(typeaheadData?.values || []);
  const handleAddItem = () => {
    const { duplicated } = addServiceItem(selectedValue);
    if (!duplicated) {
      setSelectedValue('');
    }
    setConfirmDuplicate(duplicated);
  };
  const handleConfirm = () => {
    const force = true;
    addServiceItem(selectedValue, force);
    setConfirmDuplicate(false);
    setSelectedValue('');
  };
  const handleCancel = () => {
    setConfirmDuplicate(false);
  };

  useEffect(() => {
    if (typeaheadData.field === 'serviceTypes') {
      setValues(typeaheadData?.values || []);
    }
  }, [typeaheadData]);

  return (
    <div className="service-type-search-box bg-primary-lightest grid-row padding-left-5 padding-y-2">
      {confirmDuplicate && (
        <ConfirmDuplicateServiceLineModal
          onAccept={handleConfirm}
          onCancel={handleCancel}
        />
      )}
      <div
        className="grid-col-4 margin-top-neg-4"
        data-testid="service-types-typeahead"
      >
        <Typeahead
          filterValue=""
          placeholder="Search for a repair service type"
          onOptionEnter={(value) => {
            setSelectedValue(value);
          }}
          typeaheadValues={values}
          fetchTypeaheadValues={(_accessor, value) => {
            searchServiceTypes(value);
          }}
          onClear={() => setSelectedValue('')}
          inputCharNum={3}
          debounceDelay={500}
          promptText="Search requires 3 characters"
          readOnly={selectedValue}
          reset={!selectedValue}
        />
      </div>
      <div className="grid-col-4 margin-left-3">
        <Button
          type="button"
          variant="primary"
          onClick={handleAddItem}
          leftIcon={{ name: 'add' }}
          label="Add line item to repair order"
          data-testid="service-types-add-service-type"
        />
      </div>
    </div>
  );
};

export default ServiceTypeSearch;
