import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { z } from 'zod';
import { FormGenerator } from '@gsa/afp-shared-form-utils';
import { phoneRegExp } from 'utilities/validation';
import { Alert, Button } from '@gsa/afp-component-library';
import {
  CREATE_CONTACT,
  UPDATE_CONTACT,
} from '../../../services/data-layer/point-of-contact';
import { useCustomerAccounts } from '../providers/customer-account-provider';

export const contactFormZObject = z.object({
  pocEmailAddress: z
    .string()
    .email({ message: 'Enter a valid email.' })
    .nullish(),
  pocFirstName: z
    .string()
    .nonempty({ message: 'First Name is required' })
    .nullish(),
  pocLastName: z
    .string()
    .nonempty({ message: 'Last Name is required' })
    .nullish(),
  pocPhone1: z
    .string()
    .nonempty({ message: 'Phone Number is required' })
    .regex(phoneRegExp, 'Contact number is not valid.')
    .nullish(),
  pocPhone1Ext: z.string().nullish(),
  pocPhone1CountryCode: z
    .string()
    .nonempty({ message: 'Country Code is required' })
    .nullish(),
  pocPhone2: z.string().nullish(),
  pocPhone2CountryCode: z.string().nullish(),
});

const PocForm = ({
  internationDialingCodes,
  mode,
  contact,
  saveAndClose,
  cancelForm,
}) => {
  const [formContext, setFormContext] = useState(null);
  const [formData, setFormData] = useState({ ...contact });
  const { dispatchAction, setRequestError } = useCustomerAccounts();
  const [showEditAlert, setShowEditAlert] = useState(false);
  const [editData, setEditData] = useState(null);

  const contactFormSection = {
    buttonControls: {
      submit: {
        label: 'Save and add POC',
        leftIcon: { name: 'check' },
      },
      cancel: {
        label: 'Cancel',
        leftIcon: { name: 'arrow_back' },
        onClick: () => cancelForm(),
      },
    },
    sections: [
      {
        sectionLayout: 'vertical',
        gap: '32px',
        sections: [
          {
            fields: [
              {
                id: 'pocEmailAddress',
                label: 'Email',
                type: 'text',
                required: true,
              },
            ],
          },
          {
            sectionLayout: 'horizontal',
            gap: '32px',
            sections: [
              {
                fields: [
                  {
                    id: 'pocFirstName',
                    label: 'First Name',
                    type: 'text',
                    required: true,
                  },
                ],
              },
              {
                fields: [
                  {
                    id: 'pocLastName',
                    label: 'Last Name',
                    type: 'text',
                    required: true,
                  },
                ],
              },
            ],
          },
          {
            sectionLayout: 'horizontal',
            gap: '32px',
            sections: [
              {
                columnWidth: 'col-3-12',
                fields: [
                  {
                    id: 'pocPhone1CountryCode',
                    label: 'Country Code',
                    type: 'select',
                    required: true,
                    options: [
                      { label: '', value: '' },
                      ...internationDialingCodes,
                    ],
                  },
                ],
              },
              {
                columnWidth: 'col-6-12',
                fields: [
                  {
                    id: 'pocPhone1',
                    label: 'Phone Number',
                    type: 'text',
                    required: true,
                  },
                ],
              },
              {
                columnWidth: 'col-3-12',
                fields: [
                  {
                    id: 'pocPhone1Ext',
                    label: 'Extension',
                    type: 'text',
                  },
                ],
              },
            ],
          },
          {
            sectionLayout: 'horizontal',
            gap: '32px',
            sections: [
              {
                columnWidth: 'col-3-12',
                fields: [
                  {
                    id: 'pocPhone2CountryCode',
                    label: 'Cell Country Code',
                    type: 'select',
                    options: [
                      { label: '', value: '' },
                      ...internationDialingCodes,
                    ],
                  },
                ],
              },
              {
                columnWidth: 'col-5-12',
                fields: [
                  {
                    id: 'pocPhone2',
                    label: 'Cell Phone Number',
                    type: 'text',
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  };

  // Create contact.
  const [createContact, { loading: createContactLoading }] = useMutation(
    CREATE_CONTACT,
    {
      onError: (error) => setRequestError(error, 'createContact'),
      onCompleted: (response) => {
        dispatchAction('SET_ALERT_MESSAGE', {
          type: 'success',
          message: `You have successfully added ${response.createContact.pocFirstName} ${response.createContact.pocLastName} as a point of contact. Contact will be associated to the customer account after you submit the form.`,
          location: 'poc_section',
        });
        saveAndClose(response?.createContact);
      },
    },
  );

  // Update contact.
  const [updateContact, { loading: updateContactLoading }] = useMutation(
    UPDATE_CONTACT,
    {
      onError: (error) => setRequestError(error, 'updateContact'),
      onCompleted: (response) => {
        dispatchAction('SET_ALERT_MESSAGE', {
          type: 'success',
          message: `You have successfully updated ${response.updateContact.pocFirstName} ${response.updateContact.pocLastName} as a point of contact.`,
          location: 'poc_section',
        });
        saveAndClose(response?.updateContact);
      },
    },
  );

  const onSubmit = async (data) => {
    if (mode === 'edit') {
      setShowEditAlert(true);
      setEditData({ ...data, contactId: contact.contactId });
    } else {
      await createContact({
        variables: { pointOfContact: data },
      });
    }
  };

  const continueEdit = async () => {
    await updateContact({
      variables: { pointOfContact: editData },
    });
  };

  return (
    <>
      <FormGenerator
        id="customer-account-poc-form"
        schema={contactFormZObject}
        onSetup={setFormContext}
        defaultValues={formData}
        content={contactFormSection}
        onSubmit={onSubmit}
        onError={(error) => {
          console.log('error', error);
        }}
      />
      {mode === 'edit' && showEditAlert && (
        <Alert type="warning" heading={null}>
          Are you sure? Updating this record will apply to all accounts for this contact.
          <div>
            <Button
              leftIcon={{ name: 'check' }}
              variant="outline"
              className="margin-right-2"
              data-testid="ask-poc-edit-confirm"
              label="Confirm and add/update contact"
              onClick={() => continueEdit()}
            />
          </div>
        </Alert>
      )}
    </>
  );
};

export default PocForm;
