import React, { useState, useEffect } from 'react';
import { DetailsTable, TextInput } from '@gsa/afp-component-library';
import { SectionBlock } from '../../common';
import { formatCurrency, formatUSDecimal } from '../../../utilities/common';
import { PageMode, useRepairOrder, useServiceLine } from '../providers';
import './total.css';

const Totals = ({ mode }) => {
  const { totals = {} } = useServiceLine();
  const { taxCost = 0, setTaxCost } = useRepairOrder();

  const TaxInput = ({ value }) => {
    const [taxAmount, setTaxAmount] = useState(formatUSDecimal(value));

    useEffect(() => {
      if (taxAmount !== value) {
        setTaxAmount(formatUSDecimal(value));
      }
    }, [value]);

    const handleChange = (evt) => {
      setTaxAmount(evt.target.value);
    };
    const handleBlur = () => {
      const val = formatUSDecimal(taxAmount);
      setTaxAmount(val);
      const p = parseFloat(val);
      setTaxCost(!Number.isNaN(p) ? p : 0);
    };

    return (
      <TextInput
        data-testid="repair-tax-cost"
        name="taxCost"
        type="text"
        inputMode="numeric"
        pattern="\d*"
        title="tax cost"
        value={taxAmount}
        onChange={handleChange}
        onBlur={handleBlur}
        inputClass="text-right"
      />
    );
  };

  const TaxCost = ({ value }) => {
    if (mode === PageMode.View) {
      return formatCurrency(value);
    }
    return <TaxInput value={value} />;
  };

  const totalInfo = () => {
    const { partsCost = 0, miscCost = 0, laborCost = 0 } = totals;
    const totalAmount = () => {
      return laborCost + partsCost + miscCost + taxCost;
    };
    return [
      ['Total labor cost', formatCurrency(laborCost)],
      ['Total parts cost', formatCurrency(partsCost)],
      ['Total miscellaneous cost', formatCurrency(miscCost)],
      ['Total tax', <TaxCost value={taxCost} />],
      ['Repair order total amount', formatCurrency(totalAmount())],
    ];
  };

  return (
    <SectionBlock title="Totals" basic border={false}>
      <DetailsTable
        bordered
        data={totalInfo()}
        className="repair-order-total-table"
      />
    </SectionBlock>
  );
};

export default Totals;
