import React, { useEffect, useState } from 'react';
import { Breadcrumbs } from '@gsa/afp-component-library';
import { useTitle } from '@gsa/afp-shared-ui-utils';
import { startCase } from 'lodash';
import { useOfficeManagement } from './office-management-provider';

export default function PageBreadCrumbs() {
  const [title, setTitle] = useState('');
  const { selectedOrganization } = useOfficeManagement();
  const { officeCode, officeName } = selectedOrganization;

  useEffect(() => {
    setTitle(`${officeCode} - ${startCase(officeName.toLocaleLowerCase())}`);
  }, [officeName]);

  useTitle(title);

  const trail = [
    <a href={`${window.AFP_CONFIG.appURLs.home}/home`}>Home</a>,
    <a href={`${window.AFP_CONFIG.appURLs.home}/admin/organization-profile`}>
      Organization Profile
    </a>,
    <a href={`${window.AFP_CONFIG.appURLs.home}/office-management`}>
      Office Management
    </a>,
  ];

  return <Breadcrumbs trail={trail} current={title} />;
}
