import React, { useEffect, useState } from 'react';
import { useParams, useRouteMatch, Link } from 'react-router-dom';
import { Breadcrumbs, Alert, Spinner } from '@gsa/afp-component-library';
import { useCustomerAccounts } from '../providers/customer-account-provider';
import CustomerAccountHeader from '../widgets/customer-account-header';
import CustomerAccountForm from '../widgets/customer-account-form';

const CustomerAccountAddEdit = () => {
  const { id } = useParams();
  const {
    customerAccountSelected,
    getCustomerAccount,
    optionsData,
    getInternationalPhoneCodes,
    createCustomerAccountLoading,
    updateCustomerAccountLoading,
    customerAccountLoading,
  } = useCustomerAccounts();
  const { path } = useRouteMatch();

  useEffect(() => {
    const customerIdToUpdate = id ? Number.parseInt(id, 10) : null;
    if (customerIdToUpdate) {
      getCustomerAccount({
        variables: { customerId: customerIdToUpdate },
      });
    }
  }, [id]);

  const isUpdate = path.includes('update');

  const trail = [
    <a href={`${window.AFP_CONFIG.appURLs.home}/home`}>Home</a>,
    <Link to="/customer-accounts">Customer Accounts</Link>,
  ];

  const title = isUpdate ? 'Edit Account' : 'Create an Account';
  const headingText = isUpdate
    ? ''
    : "Use this tool to create a new GSA leasing customer account. This 'Create an account' page is only available for internal GSA users.";

  const [message, setMessage] = useState(null);
  const [lookupData, setLookupData] = useState({
    countries: null,
    usStates: null,
    intCallCodes: null,
    fmcsWithZoneAndRegion: null,
  });
  const [isReadyToRenderForm, setIsReadyToRenderForm] = useState(false);

  useEffect(() => {
    const fetchLookupData = async () => {
      await getInternationalPhoneCodes();
    };

    fetchLookupData().catch((e) => {
      // eslint-disable-next-line no-console
      console.error('Error loading lookup International Phone Codes', e);
    });
  }, []);

  useEffect(() => {
    if (optionsData) {
      setLookupData(optionsData);
      if (
        optionsData?.countries &&
        optionsData?.usStates &&
        optionsData?.intCallCodes &&
        optionsData?.fmcsWithZoneAndRegion
      ) {
        setIsReadyToRenderForm(true);
      }
    }
  }, [optionsData]);

  return (
    <>
      <Breadcrumbs trail={trail} current={title} />
      {message && (
        <div className="margin-y-2">
          <Alert
            type={message.type}
            focused
            heading={message.heading}
            className="margin-top-2"
            noIcon
            onClose={() => {
              setMessage(null);
            }}
          >
            {message.message}
          </Alert>
        </div>
      )}
      <div className="margin-bottom-3">
        <CustomerAccountHeader title={title} headingText={headingText} />
        {customerAccountLoading ||
        createCustomerAccountLoading ||
        updateCustomerAccountLoading ||
        !isReadyToRenderForm ? (
          <Spinner className="padding-y-9" />
        ) : (
          <></>
        )}
        {isReadyToRenderForm && !isUpdate && (
          <CustomerAccountForm {...lookupData} />
        )}
        {!customerAccountLoading &&
          isReadyToRenderForm &&
          isUpdate &&
          customerAccountSelected &&
          customerAccountSelected?.allContactAssociations && (
            <CustomerAccountForm
              {...lookupData}
              customerAccount={customerAccountSelected}
            />
          )}
      </div>
    </>
  );
};

export default CustomerAccountAddEdit;
