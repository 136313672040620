/* eslint-disable react/prop-types */
/* eslint-disable filenames/match-exported */
import React from 'react';
import { useMutation } from '@apollo/client';
import exportMessages from 'utilities/export-message';
import { EXPORT_REPORT } from 'services/data-layer';

const useDataController = ({ alertUtils }) => {
  const [exportVehicles, { loading: exportVehicleLoading }] = useMutation(
    EXPORT_REPORT,
    {
      fetchPolicy: 'no-cache',
      onCompleted: () => {
        alertUtils.showSuccessAlert(
          exportMessages.exportSuccessMessage.message,
          'Export Initiated:',
        );
      },
      onError: (error) => {
        alertUtils.showErrorAlert(<span>{error.message}</span>);
      },
    },
  );

  return {
    loading: { exportVehicleLoading },
    mutation: { exportVehicles },
  };
};

export default useDataController;
