import React, { useState, useEffect } from 'react';
import { read, utils } from 'xlsx';
import { Link } from 'react-router-dom';
import {
  SelectDropdown,
  Button,
  FileUpload,
  RadioButton,
  Fieldset,
} from '@gsa/afp-component-library';
import classnames from 'classnames';
import { extractMonthYear } from 'utilities/common';
import {
  canUpdateGFVehicleAdmin,
  canUpdateGFVehicleBM,
  canUploadConsolidations,
} from 'utilities/authorization';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import { reportsConfig, isVehicleConsolidationEnabled } from '../config';
import { alertMap } from '../model/schema/variable-maps';
import { useImport } from '../state/hook';

const fileTypesMapping = {
  xlsx: {
    extensions: ['xlsx', 'xls'],
    MIMETypes: [
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-excel',
    ],
  },
  txt: {
    extensions: ['txt'],
    MIMETypes: ['text/plain'],
  },
};

const getTxtFromFile = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = () => {
      reject(reader.error);
    };
    reader.readAsText(file);
  });
};

const getAcceptableFilesLabel = (fileState) => {
  if (!fileState) return '';
  const isLeasingMileageType = fileState.value === 'MILEAGE_LEASE';
  const extensions = fileState?.fileTypes?.flatMap(
    (type) => fileTypesMapping[type]?.extensions,
  );

  if (isLeasingMileageType)
    return `Accept .xslx, .xls and FTP .txt file below ${fileState?.maxFileSize} MB`;

  return `Accept ${extensions
    ?.map((extension, index) => {
      if (index === extensions.length - 1) {
        return `and .${extension}`;
      }
      return `.${extension}`;
    })
    ?.join(', ')} file below ${fileState?.maxFileSize} MB`;
};

const getAcceptableTypes = (fileState) => {
  if (!fileState) return '';
  return fileState?.fileTypes?.flatMap(
    (type) => fileTypesMapping[type]?.MIMETypes,
  );
};

// TODO: move to ui-utils
export const coercionBoolean = (value) => {
  if (value.toLowerCase() === 'yes') {
    return true;
  }
  return typeof value === 'string' ? !!+value : !!value;
};

export const coercionString = (value) => {
  if (typeof value === 'string') {
    return value.trim();
  }
  return value.toString();
};

// eslint-disable-next-line react/prop-types
const ImportForm = ({ alertUtils }) => {
  const [fileState, setFileState] = useState();
  const [fileImportErrorState, setFileImportErrorState] = useState();
  const [reportsErrorState, setReportsErrorState] = useState();
  const [currentDownloadStrategyState, setCurrentDownloadStrategyState] =
    useState(reportsConfig.DEFAULT);
  const [ownershipType, setOwnershipType] = useState('AO');
  const [reportOptions, setReportOptions] = useState([]);
  const { setData, setImportType, reset } = useImport();
  const ability = useAppAbility();

  useEffect(() => {
    const options = Object.values(reportsConfig).filter(
      (config) =>
        (config.value === 'DEFAULT' ||
          config.ownershipType.includes(ownershipType)) &&
        !config.hidden, // hidden is used to hide same report type but different file type configs
    );
    const canUploadDLA =
      canUpdateGFVehicleBM(ability) || canUpdateGFVehicleAdmin(ability);

    if (!canUploadDLA) {
      const optionsHolder = [...options].filter(({ value }) => {
        return value !== 'DLA' && value !== 'TESLA';
      });
      setReportOptions(optionsHolder);
    } else {
      setReportOptions(options);
    }
    if (!isVehicleConsolidationEnabled || !canUploadConsolidations(ability)) {
      const optionsHolder = [...options].filter(({ value }) => {
        return value !== 'CONSOLIDATION';
      });
      setReportOptions(optionsHolder);
    } else {
      setReportOptions(options);
    }
  }, [ownershipType]);

  const onSubmit = async (e) => {
    e?.preventDefault();
    // form validation
    //     1.check bulkImportReport
    if (currentDownloadStrategyState.value === 'DEFAULT') {
      setReportsErrorState('This is a required field');
    } else {
      setReportsErrorState();
    }

    // 2.validate file upload

    if (!fileState) {
      setFileImportErrorState('This is a required field');
      return;
    }
    if (fileImportErrorState || reportsErrorState) {
      return;
    }

    // CDD has two different mappings based on ownership type
    const mapping =
      currentDownloadStrategyState.value === 'CDD' && ownershipType === 'GF'
        ? currentDownloadStrategyState.gfMapping
        : currentDownloadStrategyState.mapping;

    const configHeaders = mapping?.map((m) => m[0]);
    // Read the meta
    let rawJson;
    if (fileState?.type === 'text/plain') {
      const getTxtFileValuesPosition =
        currentDownloadStrategyState?.valuesPosition;
      const txtFileContent = await getTxtFromFile(fileState);
      const formattedTxtFileContent = txtFileContent
        .trim()
        .split('\n')
        .map((line) => line.trim())
        .filter((line) => line);
      const values = getTxtFileValuesPosition(formattedTxtFileContent);
      const headerRow = {};
      mapping.forEach(([key, value]) => {
        headerRow[key] = value;
      });
      rawJson = [headerRow, ...values]; // we're matching the format of the json generated from the xlsx file
    } else {
      const data = await fileState.arrayBuffer();
      const wb = read(data, { cellDates: true, dateNF: 'mm/dd/yyyy' });
      rawJson = utils.sheet_to_json(
        wb.Sheets[currentDownloadStrategyState?.dataSheet],
        { raw: false, header: configHeaders },
      );
    }

    // validate headers
    const headerObject = rawJson?.[0];
    const mismatchedHeader =
      headerObject &&
      Object.keys(headerObject).find(
        (k) =>
          headerObject[k].trim().replace('*', '') !== k.trim().replace('*', ''),
      );

    if (mismatchedHeader || !headerObject) {
      // eslint-disable-next-line react/prop-types
      alertUtils.showErrorAlert(
        alertMap.templateError.getContent(currentDownloadStrategyState?.label),
      );
      setFileState(null);
      return;
    }

    let processError = false;
    // process the data from the file
    const processedData = rawJson.slice(1).map((rowJson) => {
      const row = {};
      // map the data to the expected format
      try {
        mapping.forEach((value) => {
          const [colLabel, colProp, colType] = value;
          let rawVal = rowJson[colLabel];
          if (rawVal) {
            rawVal = rawVal.trim();
          } else {
            rawVal = '';
          }
          switch (colType) {
            case 'boolean':
              row[colProp] = coercionBoolean(rawVal);
              break;
            // input can be a string or number, convert to string
            case 'string | number':
              row[colProp] = coercionString(rawVal);
              break;
            case 'number':
              if (rawVal.length > 0)
                row[value[1] || colProp] = parseInt(rawVal, 10);
              else row[value[1] || colProp] = undefined;
              break;
            case 'tagFormat':
              if (rawVal && rawVal.length > 0)
                row[colProp] = extractMonthYear(rawVal);
              else row[colProp] = null;
              break;
            case 'float':
              if (rawVal.length > 0)
                row[value[1] || colProp] = +parseFloat(
                  Number(rawVal.replace(/[^0-9.-]+/g, '')),
                ).toFixed(2);
              else row[value[1] || colProp] = 0.0;
              break;
            case 'date':
              if (rawVal.length > 0) row[colProp] = new Date(rawVal);
              else row[colProp] = undefined;
              break;
            // readonly means that column will be ignored from the sheet
            case 'readonly':
              break;
            default:
              row[colProp] = rawVal;
          }
        });
      } catch (err) {
        processError = true;
      }
      return row;
    });

    if (processError) {
      // eslint-disable-next-line react/prop-types
      alertUtils.showErrorAlert(
        alertMap.templateError.getContent(currentDownloadStrategyState?.label),
      );
    }

    if (!processedData?.length) {
      // eslint-disable-next-line react/prop-types
      alertUtils.showErrorAlert(alertMap.noDataError);
    }
    // set the processed data to the state
    if (!processError) {
      setData(processedData);
      setFileState(null);
      setCurrentDownloadStrategyState(reportsConfig.DEFAULT);
    }
  };

  return (
    <>
      <form
        id="bulk-import-form"
        data-testid="bulk-import-form"
        onSubmit={onSubmit}
      >
        <div
          className={classnames(
            'text-bold',
            reportsErrorState && 'margin-left-205',
          )}
        >
          <div className="margin-top-6">
            <Fieldset legend="Vehicle ownership options">
              <RadioButton
                name="agency-owned"
                id="agency-owned"
                value="AO"
                checked={ownershipType === 'AO'}
                label={
                  <>
                    <span className="text-normal">Agency owned</span>
                  </>
                }
                onChange={() => setOwnershipType('AO')}
              />
              <RadioButton
                name="leased-vehicle"
                id="leased-vehicle"
                value="GF"
                checked={ownershipType === 'GF'}
                label={
                  <>
                    <span className="text-normal">GSA leased</span>
                  </>
                }
                onChange={() => setOwnershipType('GF')}
              />
            </Fieldset>
          </div>
          <SelectDropdown
            label="Choose from available templates"
            name="bulkImportReport"
            id="bulkImportReport"
            options={reportOptions}
            value={currentDownloadStrategyState.value}
            onChange={(e) => {
              reset();

              setFileState(null);
              // eslint-disable-next-line react/prop-types
              alertUtils.closeAlert();
              setCurrentDownloadStrategyState(reportsConfig[e.target.value]);
              if (e.target.value === 'DEFAULT') {
                setReportsErrorState('This is a required field');
              } else {
                setReportsErrorState();
                setImportType({ type: e.target.value });
              }
            }}
            errorMessage={reportsErrorState}
            required
          />
        </div>
        <FileUpload
          required
          label="Supporting file"
          defaultValue={fileState}
          acceptableFiles={getAcceptableTypes(currentDownloadStrategyState)}
          acceptableFilesLabel={getAcceptableFilesLabel(
            currentDownloadStrategyState,
          )}
          fileSizeLimit={currentDownloadStrategyState?.maxFileSize} // MB
          onChange={(file) => {
            setFileState(file);
            setFileImportErrorState(!file && 'This is a required field');
            // override the current download strategy with file type based config
            if (
              file?.type === 'text/plain' &&
              currentDownloadStrategyState.fileTypes.includes('txt')
            ) {
              setCurrentDownloadStrategyState(
                reportsConfig[`${currentDownloadStrategyState?.value}_TXT`],
              );
              setImportType({
                type: `${currentDownloadStrategyState?.value}_TXT`,
              }); // needed for the processor
            }
          }}
          errorMessage={fileImportErrorState}
        />
        <div className="margin-top-2">
          <Button
            size="sm"
            data-testid="bulk-import-form-btn-upload"
            variant="primary"
            id="bulk-import-form-btn-upload"
            aria-label="Submit form"
            hidden="hidden"
            type="submit"
            label="Upload"
          />
        </div>
      </form>
      <div className="margin-top-3">
        <Link to="/reports">Go back to Vehicle Report Manager</Link>
      </div>
    </>
  );
};

export default ImportForm;
