/* eslint-disable react/prop-types */
import { Alert, Button, TextInput, Modal } from '@gsa/afp-component-library';
import React, { useEffect, useState } from 'react';
import { useOrder } from '../context';

export default function OrderReconcileModal({ lpCount, onSave }) {
  const [comment, setComment] = useState('');

  const {
    isOrderReconcileModalOpen,
    closeOrderReconcileModal,
    setTag,
    orderNumber,
  } = useOrder();

  useEffect(() => {
    if (!isOrderReconcileModalOpen) {
      closeOrderReconcileModal();
      setTag(null);
    }
  }, [isOrderReconcileModalOpen]);

  if (!isOrderReconcileModalOpen) {
    return null;
  }

  return (
    <div className="afp-modal-wrapper">
      <div className="afp-modal-overlay">
        <Modal
          variant="large"
          actions={
            <>
              <Button
                variant="unstyled"
                onClick={() => {
                  closeOrderReconcileModal();
                }}
                label="Cancel"
              />
              <Button
                className="margin-left-2"
                onClick={() => {
                  onSave(comment);
                  closeOrderReconcileModal();
                }}
                label={`Reconcile license plate${lpCount > 1 && 's'}`}
              />
            </>
          }
          onClose={closeOrderReconcileModal}
          title={<h2>Reconcile license plates - Order {orderNumber}</h2>}
        >
          <Alert className="margin-right-3 margin-top-4" type="warning">
            Only license plates in <b>shipped</b> status will be updated to
            received.
          </Alert>
          <p>
            By changing the status of license plates to <b>received</b> you are
            recording physical receipt of these license plates.
          </p>
          <p>
            If any license plate on the order was <b>damaged</b> or is{' '}
            <b>missing</b> please update the status of each license plate{' '}
            <b>before</b> completing this step.
          </p>
          <div>
            <TextInput
              className="maxw-full"
              type="textarea"
              label="Additional comments"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          </div>
        </Modal>
      </div>
    </div>
  );
}
