import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TextInput } from '@gsa/afp-component-library';

export default function PhoneInputMask({ onChange, ...props }) {
  const [value, setValue] = useState(props?.value || '');
  const [maskedValue, setMaskedValue] = useState('');

  useEffect(() => {
    if (props?.value) setValue(props?.value);
  }, [props?.value]);

  useEffect(() => {
    const inputValue = value
      .replace(/\D/g, '')
      .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);

    const maskValue = !inputValue[2]
      ? inputValue[1]
      : `${inputValue[1]}-${inputValue[2]}${`${
          inputValue[3] ? `-${inputValue[3]}` : ''
        }`}`;
    setMaskedValue(maskValue);
  }, [value]);

  useEffect(() => {
    const unmasked = maskedValue.split('-').join('');
    onChange({ target: { value: unmasked, name: props?.name } });
  }, [maskedValue]);

  return (
    <TextInput
      {...props}
      value={maskedValue}
      onChange={(e) => {
        setValue(e.target.value);
      }}
      maxLength={12}
    />
  );
}

PhoneInputMask.defaultProps = {
  onChange: () => {
    // noop
  },
};

PhoneInputMask.propTypes = {
  onChange: PropTypes.func,
};
