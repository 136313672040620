import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Typeahead } from '@gsa/afp-component-library';
import { useCurrentUser } from '@gsa/afp-shared-ui-utils';
import { GET_USERS_BY_ROLE } from '../../../services/data-layer/users.gql';
import { emdash, SectionBlock } from '../../common';
import useUser from '../../../utilities/use-user';

// eslint-disable-next-line react/prop-types
const FsrField = ({ id, defaultValue, readOnly, setValue, clearErrors }) => {
  const { isRole } = useUser();
  const { currentUser } = useCurrentUser();

  const [typeaheads, setTypeaheads] = useState([]);
  const [typeaheadValues, setTypeaheadValues] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedUserEmail, setSelectedUserEmail] = useState(defaultValue);

  useEffect(() => {
    if (!defaultValue && !selectedUserEmail) {
      const e = isRole('FleetSvcRep') ? currentUser.email : null;
      setValue(id, e);
      setSelectedUserEmail(e);
    }
  }, [defaultValue, selectedUserEmail]);

  const [queryUsersForSearch] = useLazyQuery(GET_USERS_BY_ROLE, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setTypeaheads(data.getUsersByRole?.rows);
    },
    // eslint-disable-next-line no-console
    onError: (err) => console.warn(err),
  });

  const [querySpecificUser] = useLazyQuery(GET_USERS_BY_ROLE, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setSelectedUser(data.getUsersByRole?.rows[0]);
    },
    // eslint-disable-next-line no-console
    onError: (err) => console.warn(err),
  });

  const fetchUsers = (_, val) => {
    if (val) {
      queryUsersForSearch({
        variables: {
          roleId: [1, 2009, 2001, 2008],
          limit: 10,
          offset: 0,
          filters: [
            {
              operator: '$and',
              conditions: [{ operator: '$like', key: 'email', value: val }],
            },
          ],
        },
      });
    }
  };

  useEffect(() => {
    const values = [];
    if (typeaheads && typeaheads?.length > 0) {
      typeaheads.forEach((item) => {
        values.push(item.email);
      });
    }
    setTypeaheadValues(values);
  }, [typeaheads]);

  useEffect(() => {
    if (selectedUserEmail && !selectedUser) {
      querySpecificUser({
        variables: {
          roleId: [1, 2009, 2001, 2008],
          limit: 10,
          offset: 0,
          filters: [
            {
              operator: '$and',
              conditions: [
                { operator: '$like', key: 'email', value: selectedUserEmail },
              ],
            },
          ],
        },
      });
    }
  }, [selectedUserEmail, selectedUser]);

  return (
    <>
      <Typeahead
        id={id}
        date-testid={`${id}-typeahead`}
        name={id}
        filterValue={selectedUserEmail || ''}
        fetchTypeaheadValues={fetchUsers}
        typeaheadValues={typeaheadValues}
        placeholder="Enter Email"
        inputCharNum={3}
        ariaLabel="Enter Email"
        debounceDelay={500}
        promptText="Search requires 3 characters"
        noResultsText="No user found for the given search."
        onOptionEnter={(v) => {
          clearErrors('fsrUserEmail');
          setSelectedUser(typeaheads.find((item) => item.email === v));
          setValue(id, v);
        }}
        onClear={() => {
          setValue(id, '');
          setSelectedUser(null);
        }}
        required={false} // to bypass browser required field check
        disabled={readOnly || false}
      />
      {selectedUser && (
        <SectionBlock title="" basic border={false}>
          <div className="grid-row">
            <div className="grid-col-6 bg-primary-lighter padding-2">
              <span className="text-bold">{`${selectedUser?.firstName} ${selectedUser?.lastName}`}</span>{' '}
              <br />
              {selectedUser?.email || emdash} <br />
              {selectedUser?.phoneNumber} <br />
            </div>
          </div>
        </SectionBlock>
      )}
    </>
  );
};

export default FsrField;
