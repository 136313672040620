import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { FilterPanel } from '@gsa/afp-component-library';
import { useCurrentUser } from '@gsa/afp-shared-ui-utils';
import { GET_RECALL_CAMPAIGN_OPTIONS } from 'services/data-layer';
import { GET_CUSTOMER_FSR_EMAILS_BY_PARTIAL_EMAIL } from 'components/customer-accounts/customer-account-gql';
import { SEARCH_POINTS_OF_CONTACT } from 'services/data-layer/point-of-contact';
import { uniq } from 'lodash';
import { useRecallCampaigns } from './recall-campaigns-provider';
import { useRecallCampaignsFilter } from './filters/filter-provider';
import { generateRecallCampaignsFilterStructure } from './filters/recall-campaigns-filter-structure';

const fsrEmailFilterPath = '$assetRecalls.vehicle.fsr_user_email_address$';
const customerEmailFilterPath =
  '$assetRecalls.vehicle.assetContactAssociationPrimary.pointOfContactPrimary.email_address$';
const campaignIdFilterPath = '$recall_code$';
const nhtsaCampaignIdFilterPath = '$nhtsa_campaign_id$';

const RecallCampaignsSidebar = () => {
  const {
    filterStructure,
    setFilters,
    setStructure,
    filters: appliedFilters,
  } = useRecallCampaignsFilter();

  const setCampaignsFilters = (filters) => {
    setFilters(filters);
  };

  const [typeaheadData, setTypeaheadData] = useState([]);

  const { currentUser } = useCurrentUser();
  const { optionsData, campaignOptions } = useRecallCampaigns();

  const [getCustomerFSREmailsByPartialEmail] = useLazyQuery(
    GET_CUSTOMER_FSR_EMAILS_BY_PARTIAL_EMAIL,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        const fsrEmails = data.getCustomerFSREmailsByPartialEmail.map(
          (account) => account.fsrUserEmail,
        );
        setTypeaheadData({
          field: fsrEmailFilterPath,
          values: uniq(fsrEmails),
        });
      },
    },
  );

  const [searchPointsOfContact] = useLazyQuery(SEARCH_POINTS_OF_CONTACT, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const pocEmails = data.searchPointsOfContact.map(
        (poc) => poc.pocEmailAddress,
      );
      setTypeaheadData({
        field: customerEmailFilterPath,
        values: pocEmails,
      });
    },
  });

  const [searchCampaignId] = useLazyQuery(GET_RECALL_CAMPAIGN_OPTIONS, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setTypeaheadData({
        field: campaignIdFilterPath,
        values: data?.getRecallCampaigns?.recallCodes,
      });
    },
  });

  const [searchNhtsaCampaignId] = useLazyQuery(GET_RECALL_CAMPAIGN_OPTIONS, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setTypeaheadData({
        field: nhtsaCampaignIdFilterPath,
        values: data?.getRecallCampaigns?.nhtsaCampaignIds,
      });
    },
  });

  useEffect(() => {
    if (optionsData?.fmcsWithZoneAndRegion && currentUser) {
      const initialFilters = {
        zone: null,
        fmc: null,
        fsrEmail: null,
        nhtsaCampaignIdsOptions: null,
      };

      setStructure(
        generateRecallCampaignsFilterStructure({
          fmcsWithZoneAndRegion: optionsData.fmcsWithZoneAndRegion,
          zones: optionsData.zones,
          initialFilters,
        }),
      );
    }
  }, [currentUser, optionsData, campaignOptions, appliedFilters]);

  const handleTypeaheadSearch = ({ variables }) => {
    const { conditions } = variables.filters[0];
    const query = conditions.filter((c) => c.key === variables.field)[0]?.value;
    if (variables.field === fsrEmailFilterPath) {
      getCustomerFSREmailsByPartialEmail({
        variables: {
          limit: 25,
          fsrUserEmail: query,
        },
      });
    } else if (variables.field === customerEmailFilterPath) {
      searchPointsOfContact({
        variables: {
          limit: 10,
          offset: 0,
          filters: [
            {
              operator: '$and',
              conditions: [
                {
                  operator: '$like',
                  key: 'email_address',
                  value: query,
                },
              ],
            },
          ],
        },
      });
    } else if (variables.field === campaignIdFilterPath) {
      searchCampaignId({
        variables: {
          distinctField: 'recall_code',
          filters: [
            {
              operator: '$and',
              conditions: [
                {
                  operator: '$startsWith',
                  key: 'recall_code',
                  value: query,
                },
              ],
            },
          ],
        },
      });
    } else if (variables.field === nhtsaCampaignIdFilterPath) {
      searchNhtsaCampaignId({
        variables: {
          distinctField: 'nhtsa_campaign_id',
          filters: [
            {
              operator: '$and',
              conditions: [
                {
                  operator: '$startsWith',
                  key: 'nhtsa_campaign_id',
                  value: query,
                },
              ],
            },
          ],
        },
      });
    }
  };

  const clearTypeaheadData = () => {
    setTypeaheadData({
      field: '',
      values: [],
    });
  };

  if (filterStructure?.length > 0) {
    return (
      <FilterPanel.FilterPanel
        filterStructure={filterStructure}
        setQueryFiltersState={(filters) => setCampaignsFilters(filters)}
        clearButtonLabel="Reset all"
        showClearIcon
        order={[['updateAt', 'DESC']]}
        showSearchIcon
        fetchTypeaheads={handleTypeaheadSearch}
        typeaheadData={typeaheadData}
        handleClearAll={clearTypeaheadData}
        retainFilters
      />
    );
  }
  return null;
};

export default RecallCampaignsSidebar;
