/* eslint-disable react/prop-types */
// eslint-disable-next-line filenames/match-exported
import React, { useState, useEffect } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { useLazyQuery } from '@apollo/client';
import { sortBy } from 'lodash';
import {
  SelectDropdown,
  TextInput,
  Label,
  RadioButton,
  MultiSelect,
} from '@gsa/afp-component-library';
import { GET_STATES } from 'services/data-layer';
import {
  DEFAULT_OPTION,
  FUEL_UNIT_TYPE_OPTIONS,
  PURCHASE_FUEL_TYPES_OPTIONS,
  ADDITIONAL_FIELDS_OPTIONS,
} from '../config/helpers';
import { HiddenField } from '../components/hidden-field';

const zipRegExp = /^\d{5}(?:[-\s]\d{4})?$/;

// eslint-disable-next-line react/prop-types
const OtherFuelFilters = ({ control, setValue, register, errors }) => {
  const [additionalFilters, setAdditionalFilters] = useState([]);

  const [reportType, ownership] = useWatch({
    name: ['fuel_report_selection', 'vehicle_options'],
    control,
  });

  const [getStates, { data: merchantStates }] = useLazyQuery(GET_STATES, {
    fetchPolicy: 'no-cache',
    onError(err) {
      // eslint-disable-next-line no-console
      console.warn(err);
    },
  });

  useEffect(() => getStates(), []);

  const multiSelectChange = (checked, checkboxValue, prevVal) => {
    const previousVal = prevVal || [];
    let value = [...additionalFilters, ...previousVal];
    if (checked) {
      value.push(checkboxValue);
    } else {
      value = value.filter((el) => el !== checkboxValue);
    }
    setAdditionalFilters(value);
    setValue('additional_fields', value);
  };

  return (
    <>
      <section className="margin-bottom-6">
        <h2 className="text-primary margin-bottom-1">Other options</h2>
        <Controller
          name="purchase_fuel_type"
          control={control}
          render={({ field: { ref, ...rest } }) => {
            return (
              <SelectDropdown
                {...rest}
                label={<span className="text-bold">Purchase fuel type</span>}
                name="purchase_fuel_type"
                id="purchase_fuel_type"
                options={PURCHASE_FUEL_TYPES_OPTIONS}
              />
            );
          }}
        />
        <Controller
          control={control}
          name="merchant_state"
          render={({ field: { ref, ...rest } }) => {
            return (
              <SelectDropdown
                {...rest}
                id="merchant_state"
                data-testid="merchant-state-dropdown"
                name="merchant_state"
                label={<b>Merchant state</b>}
                options={[
                  DEFAULT_OPTION,
                  ...sortBy(merchantStates?.states, 'stateCode')?.map(
                    (item) => ({
                      value: item.stateCode,
                      label: item.stateCode,
                    }),
                  ),
                ]}
              />
            );
          }}
        />
        <Controller
          control={control}
          name="merchant_zip"
          rules={{
            validate: (value) => {
              if (value && !zipRegExp.test(value)) {
                return 'ZIP code is not valid.';
              }
              return true;
            },
          }}
          render={({ field: { ref, ...rest } }) => {
            return (
              <TextInput
                {...rest}
                id="merchant_zip"
                data-testid="merchant_zip"
                name="merchant_zip"
                label={<b>Merchant zip</b>}
                errorMessage={
                  errors && errors?.merchant_zip
                    ? errors?.merchant_zip?.message
                    : null
                }
                aria-invalid={errors && errors?.merchant_zip ? 'true' : 'false'}
              />
            );
          }}
        />
        <Controller
          control={control}
          name="fuel_unit_type"
          defaultValue={FUEL_UNIT_TYPE_OPTIONS[0].radioValue}
          rules={{ required: 'This is a required field' }}
          render={({ field: { onChange, name, value } }) => (
            <>
              <Label className="text-bold" required>
                Fuel unit type
              </Label>
              {FUEL_UNIT_TYPE_OPTIONS.map(
                ({ radioValue, label, ...restProps }) => {
                  return (
                    <RadioButton
                      name={name}
                      key={radioValue}
                      value={radioValue}
                      checked={radioValue === value}
                      label={<p>{label}</p>}
                      {...restProps}
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                    />
                  );
                },
              )}
            </>
          )}
        />
        {ownership === 'GF' && reportType !== 'summaryByReportingLevel' ? (
          <>
            <h2 className="text-primary margin-bottom-1 margin-top-6">
              Additional options
            </h2>
            <Controller
              control={control}
              name="additional_fields"
              render={({ field: { ref, value, ...rest } }) => {
                return (
                  <MultiSelect
                    {...rest}
                    id="additional_fields"
                    key="additional_fields"
                    options={ADDITIONAL_FIELDS_OPTIONS}
                    onChange={(val, checked) => {
                      multiSelectChange(checked, val, value);
                    }}
                    selectedValues={value || []}
                  />
                );
              }}
            />
          </>
        ) : (
          <HiddenField register={register} name="additional_fields" />
        )}
      </section>
    </>
  );
};

export default OtherFuelFilters;
