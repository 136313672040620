import { useLazyQuery, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import React, {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';
import { GET_ZONES_CENTERS } from 'services/data-layer/fed-hierarchy.gql';
import { ADD_MILEAGE_LOG_FOR_GF_VEHICLES } from 'components/vehicle-details/sidenav-widgets/mileage-history/mileage-history.gql';
import exportMessage from 'utilities/export-message';
import { EXPORT_REPORT } from 'services/data-layer';
import { REPORT_DELIVERY_METHOD, REPORT_TYPE } from 'utilities/consts';
import { InternalError } from '@gsa/afp-shared-ui-utils';
import {
  formatModifiedMileageExpress,
  scrollInto,
} from './helpers/mileage-express-utils';
import {
  CREATE_MILEAGE_SUCCESS_MESSAGE,
  CREATE_MILEAGE_FAIL_MESSAGE,
  NO_MODIFIED_MILEAGE_EXPRESS,
} from './mileage-express-messages';
import { GET_MILEAGE_EXPRESS_LIST } from './mileage-express.gql';

export const MileageExpressContext = createContext();

export const MILEAGE_LISTING_MODAL_MODES = {
  ADD_MILEAGE_LISTING: 'ADD_MOTOR_POOL_LISTING',
};

const initialState = {
  mileageExpressList: {
    rows: [],
    hasMore: false,
    count: 0,
  },
  alertMessage: { context: '', message: '', type: '', header: null }, // type => error || success || info e.g.: => {type: 'error', 'message': 'Something went wrong', context: 'motorPoolListing'}
  modalState: {
    currentModalName: null,
    currentMode: null,
    currentModalAlert: { type: null, message: null, header: null },
  },
  modifiedMileageExpress: [],
  interceptSave: false,
  resetMileageExpresss: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_MILEAGE_LIST':
      return { ...state, mileageExpressList: action.payload };
    case 'SET_SELECTED_MILEAGE_BY_ID':
      return { ...state, selectedMileageExpressById: action.payload };
    case 'SET_SELECTED_MILEAGE':
      return { ...state, mileageExpressListingSelected: action.payload };
    case 'SET_SELECTED_MILEAGE_IDS':
      return {
        ...state,
        mileageExpressIdsSelected: action.payload,
      };
    case 'SET_MODIFIED_MILEAGES':
      return {
        ...state,
        modifiedMileageExpress: action.payload,
      };
    case 'SET_INTERCEPT_SAVE':
      return {
        ...state,
        interceptSave: action.payload,
      };
    case 'SET_CONFIRM_SIGNIFICANT_MILEAGE_DATA':
      return {
        ...state,
        confirmSignificanMileageData: action.payload,
      };
    case 'RESET_MILEAGES':
      return {
        ...state,
        resetMileageExpresss: action.payload,
      };
    case 'SET_ALERT_MESSAGE':
      return { ...state, alertMessage: action.payload };
    case 'SET_INTERNAL_SERVER_ERROR':
      return { ...state, internalServerError: action.payload };
    case 'SET_ENTRIES_WITH_SIGNIFICANT_MILEAGE_DIFFERENCE':
      return { ...state, significantMileageEntries: action.payload };

    case 'SET_OPTIONS_DATA_ZONES': {
      const optionData = {
        ...state.optionsData,
        zones: action.payload.zones,
        fmcsWithZoneAndRegion: action.payload.fmcsWithZoneAndRegion,
      };
      return { ...state, optionsData: optionData };
    }
    default:
      throw new Error('Invalid action');
  }
};

function MileageExpressProvider({ children, ...props }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [localMergedFilter, setLocalMergedFilter] = useState();
  const dispatchAction = (type, payload) => {
    dispatch({
      type,
      payload,
    });
  };

  const resetModal = () => {
    // console.log('reset modal');
  };

  const setInternalServerError = (showError) => {
    dispatchAction('SET_ALERT_MESSAGE', null);
    dispatchAction(
      'SET_INTERNAL_SERVER_ERROR',
      showError ? <InternalError /> : '',
    );
  };

  const setRequestError = (message, context) => {
    dispatchAction('SET_INTERNAL_SERVER_ERROR', null);
    dispatchAction('SET_ALERT_MESSAGE', {
      type: 'error',
      message: message?.message,
      context,
    });
  };

  const setRequestMessage = (message, context) => {
    dispatchAction('SET_INTERNAL_SERVER_ERROR', null);
    dispatchAction('SET_ALERT_MESSAGE', {
      type: 'success',
      message: message?.message,
      header: 'Export Initiated:',
      context,
    });
  };

  const [
    getMileageExpressList,
    { refetch: refetchMileageExpressList, loading: mileageExpressListLoading },
  ] = useLazyQuery(GET_MILEAGE_EXPRESS_LIST, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onError: () => setInternalServerError(true),
    onCompleted: (responseData) => {
      if (responseData?.getMileageExpressList) {
        dispatchAction('SET_MILEAGE_LIST', responseData.getMileageExpressList);
      }
    },
  });

  const [addMileageLogForGFVehicles, { loading: addingMileageForGFVehicle }] =
    useMutation(ADD_MILEAGE_LOG_FOR_GF_VEHICLES, {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onError: () => setInternalServerError(true),
      onCompleted: (response) => {
        if (response?.addMileageLogForGFVehicles) {
          refetchMileageExpressList();
          dispatchAction('SET_ALERT_MESSAGE', {
            type: 'success',
            message: CREATE_MILEAGE_SUCCESS_MESSAGE.body(),
          });
        }
      },
    });

  const saveAllMileageExpresss = () => {
    const formattedMileageExpress = formatModifiedMileageExpress(
      state.modifiedMileageExpress,
      state.mileageExpressList,
    );

    if (formattedMileageExpress?.payload?.length === 0) {
      dispatchAction('SET_ALERT_MESSAGE', {
        type: 'error',
        message: NO_MODIFIED_MILEAGE_EXPRESS.body(),
        context: 'mileageExpress-listing',
      });
      scrollInto('main-content');
    } else if (formattedMileageExpress?.isValid) {
      dispatchAction('SET_ALERT_MESSAGE', { type: '', message: '' });
      addMileageLogForGFVehicles({
        variables: {
          mileageGFFields: formattedMileageExpress?.payload,
        },
      });
    } else {
      dispatchAction('SET_ALERT_MESSAGE', {
        type: 'error',
        message: CREATE_MILEAGE_FAIL_MESSAGE.body(),
        context: 'mileageExpress-listing',
      });
      scrollInto('main-content');
    }
  };

  const [getZones] = useLazyQuery(GET_ZONES_CENTERS, {
    fetchPolicy: 'network-only', // Used for first execution
    nextFetchPolicy: 'cache-first', // Used for subsequent executions
    onError: () => setInternalServerError(true),
    onCompleted: (responseData) => {
      if (responseData?.getZones) {
        const zones = [];
        const fmcsWithZoneAndRegion = [];
        const zoneOptions = responseData?.getZones.map((zone) => ({
          value: zone.id,
          label: zone.name,
        }));
        zones.push(...zoneOptions);

        const fmcOptions = responseData?.getZones.flatMap((zone) =>
          zone.centers.map((center) => ({
            value: `${zone.id}:${center.id}:${center.regionId}`,
            label: center.name,
            zoneId: zone.id,
            regionId: center.regionId,
            centerId: center.id,
          })),
        );
        fmcsWithZoneAndRegion.push(...fmcOptions);

        dispatchAction('SET_OPTIONS_DATA_ZONES', {
          zones,
          fmcsWithZoneAndRegion,
        });
      }
    },
  });

  useEffect(() => {
    getZones();
  }, []);

  const mileageContext = 'mileageExpressList';

  const [exportData] = useMutation(EXPORT_REPORT, {
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      setRequestMessage(exportMessage.exportSuccessMessage, mileageContext);
    },
    onError: () => setInternalServerError(true),
  });

  const onExport = (filters) => {
    const emptyFilterResult = 0;
    const maxExportingCount = 950000;
    const { count } = state.mileageExpressList;

    setRequestError(null, mileageContext);
    if (count === emptyFilterResult) {
      setRequestError(
        exportMessage.exportMsgNoData('mileage report'),
        mileageContext,
      );
      return;
    }
    if (count > maxExportingCount) {
      setRequestError(exportMessage.exportMsgTooMany, mileageContext);
    } else {
      exportData({
        variables: {
          request: {
            reportType: REPORT_TYPE.MILEAGE_EXPRESS,
            deliveryMethod: REPORT_DELIVERY_METHOD.EMAIL,
            reportCriteria: {
              filters,
            },
          },
        },
      });
    }
  };

  return (
    <MileageExpressContext.Provider
      value={{
        ...state,
        onExport,
        getMileageExpressList,
        mileageExpressListLoading,
        dispatchAction,
        resetModal,
        setRequestError,
        setRequestMessage,
        addMileageLogForGFVehicles,
        addingMileageForGFVehicle,
        saveAllMileageExpresss,
        localMergedFilter,
        setLocalMergedFilter,
        ...props,
      }}
    >
      {children}
    </MileageExpressContext.Provider>
  );
}

export default MileageExpressProvider;

MileageExpressProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export const useMileageExpress = () => useContext(MileageExpressContext);
