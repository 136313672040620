import { SelectDropdown, TextInput } from '@gsa/afp-component-library';
import React, { useState } from 'react';
import { usCountryCode } from 'utilities/consts';

export const officeLocationSection = (
  countryOptions,
  usStateOptions,
  bothReadOnlyMode,
) => {
  const section = {
    sections: [
      {
        sectionLayout: 'horizontal',
        fields: [
          {
            id: 'countryId',
            label: 'Country',
            type: 'select',
            required: true,
            readOnly: bothReadOnlyMode,
            component: (config, useFormContext) => {
              const { watch, setValue, clearErrors } = useFormContext();
              const [selectedCountryId] = watch(['countryId']);

              const handleStateReset = (val) => {
                if (val === usCountryCode) {
                  setValue('state', 'default');
                } else {
                  setValue('state', '');
                  clearErrors('state');
                }
              };

              return (
                <>
                  <SelectDropdown
                    name={config.id}
                    required
                    value={selectedCountryId}
                    id={config.id}
                    options={countryOptions}
                    onChange={(e) => {
                      clearErrors('countryId');
                      handleStateReset(e.target.value);
                      setValue(config.id, e.target.value);
                    }}
                    disabled={config.readOnly}
                  />
                </>
              );
            },
          },
          {
            id: 'address1', // address2 is mapped to address_line 1 and names goes as address1
            label: 'Address line 1',
            type: 'text',
            required: true,
            readOnly: bothReadOnlyMode,
          },
          {
            id: 'address2',
            label: 'Address line 2',
            type: 'text',
            element: {
              control: {
                maxLength: 40,
              },
            },
            readOnly: bothReadOnlyMode,
          },
          {
            id: 'city',
            label: 'City',
            type: 'text',
            required: true,
            readOnly: bothReadOnlyMode,
          },
          {
            id: 'state',
            readOnly: bothReadOnlyMode,
            component: (config, useFormContext) => {
              const {
                watch,
                formState: { errors },
                setValue,
                clearErrors,
              } = useFormContext();
              const [
                selectedCountryId,
                selectedState,
                selectedinternationalStateName,
              ] = watch(['countryId', 'usStateId', 'internationalStateName']);
              const [selectedIntState, setSelectedIntState] = useState(
                selectedinternationalStateName,
              );

              return (
                <>
                  {selectedCountryId === usCountryCode ? (
                    <SelectDropdown
                      name={config.id}
                      required={selectedCountryId === usCountryCode}
                      label="State"
                      value={selectedState}
                      id={config.id}
                      options={usStateOptions}
                      onChange={(e) => {
                        clearErrors('state');
                        setValue(config.id, e.target.value);
                      }}
                      disabled={config.readOnly}
                    />
                  ) : (
                    <TextInput
                      name={config.id}
                      value={selectedIntState}
                      id={config.id}
                      label="State"
                      type="text"
                      aria-invalid={
                        errors && errors[config.id] ? 'true' : 'false'
                      }
                      onChange={(e) => {
                        clearErrors('state');
                        setSelectedIntState(e.target.value);
                        setValue(config.id, e.target.value);
                      }}
                      disabled={config.readOnly}
                    />
                  )}
                </>
              );
            },
          },
          {
            id: 'postalCode',
            label: 'Zip code',
            type: 'text',
            required: true,
            element: {
              control: {
                maxLength: 15,
              },
            },
            readOnly: bothReadOnlyMode,
          },
        ],
      },
    ],
  };

  return section;
};
