import React, { useEffect } from 'react';
import { FilterPanel } from '@gsa/afp-component-library';
import { useCurrentUser } from '@gsa/afp-shared-ui-utils';
import { useMotorPoolFilter } from '../../filters/filter-provider';
import { generateMPReservationsFilterStructure } from '../../filters/motor-pool-filter-structure';

const ReservationsSidebar = () => {
  const { filterStructure, setFilters, setStructure } = useMotorPoolFilter();
  const { currentUser } = useCurrentUser();

  const setReservationsListingFilters = (filters) => {
    setFilters({
      context: 'reservationsListing',
      filters,
    });
  };

  useEffect(() => {
    setStructure({
      context: 'reservationsListing',
      filterStructure: generateMPReservationsFilterStructure(currentUser.id),
    });
  }, []);

  if (filterStructure?.reservationsListing?.length > 0) {
    return (
      <FilterPanel.FilterPanel
        filterStructure={filterStructure.reservationsListing}
        setQueryFiltersState={(filters) =>
          setReservationsListingFilters(filters)
        // eslint-disable-next-line react/jsx-curly-newline
        }
        clearButtonLabel="Reset all"
        showClearIcon
        order={[['updateAt', 'DESC']]}
        showSearchIcon
      />
    );
  }
  return null;
};

export default ReservationsSidebar;
