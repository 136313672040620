import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Button, DetailsTable, EmptyState } from '@gsa/afp-component-library';
import { fieldGetter } from '../vehicle-details/helpers/field-getter';
import InventoryStatusTag from '../inventory-status-tag/index';
import VehicleEdit from './widgets/vehicle-edit';
import LicensePlateEdit from './widgets/license-plate-edit';
import { constructPayload } from './helpers/payload-constructors';
import { LicensePlatePoc } from '../license-plate-details/widgets/lp-poc-info';
import { useRegistrationDetail } from './registration-detail-context-provider';
import { ExemptLabeledMessage } from '../exempt-message';
import useCanPerformActions from '../../hooks/use-can-perform-actions';
import { unknownField } from '../../utilities/unknown-field';
import useUser from '../../utilities/use-user';

const fastReportable = (value) => {
  if (value === undefined || value === null) {
    return '—';
  }
  return value ? 'Yes' : 'No';
};

const VehicleInfo = ({
  vehicle,
  updateRegistration,
  setTagForDisplay,
  updateStatePlate,
}) => {
  const [editingVehicle, setEditingVehicle] = useState(false);
  const toggleVehicleEdit = () => {
    setEditingVehicle((currentEdit) => !currentEdit);
  };
  const [editingLP, setEditingLP] = useState(false);
  const toggleLPEdit = () => {
    setEditingLP((currentEdit) => !currentEdit);
  };

  const { updateFields, setHasNetworkError } = useRegistrationDetail();
  const { isRole } = useUser();
  const hasFMVRSrole = isRole('FMVRSAdminRole');
  const canPerformActions = useCanPerformActions();
  const canRegisterVehicle = canPerformActions.canRegister(vehicle);
  const canEditVehicle = canPerformActions.canEditVehicle(vehicle);
  const canEditLicensePlate = canPerformActions.canEditLicensePlate(
    vehicle,
    hasFMVRSrole,
  );

  const updateRegDetails = (details) => {
    const payloadStructure = constructPayload(vehicle);
    const payload = { ...payloadStructure, ...details, mode: 'edit' };
    updateRegistration(payload);
  };

  const handleSettingTagForDisplay = () => {
    if (vehicle?.tagNumber) {
      setTagForDisplay({
        tagNumber: vehicle?.tagNumber,
        tagExpirationDate: vehicle?.tag?.expirationDate,
      });
    } else {
      setTagForDisplay(null);
    }
  };

  const getStatePlate = vehicle?.ald?.stateTag;

  const saveLPChanges = (lp) => {
    toggleLPEdit();
    handleSettingTagForDisplay();
    if (
      lp.tagNumber !== vehicle.tagNumber ||
      lp.exemptPlate !== vehicle.exemptPlate
    ) {
      updateRegDetails(lp);
    } else if (lp.statePlate !== getStatePlate) {
      updateStatePlate(lp.statePlate);
    }
  };

  const saveVehicleChanges = (fields) => {
    // Dismiss previous registration update error if one exists so it doesn't confuse the user
    setHasNetworkError(false);
    // Confirmation modals need to know what the plates were before the status updates in case plates are disassociated
    handleSettingTagForDisplay();
    if (fields?.length) {
      updateFields(fields);
      toggleVehicleEdit();
    }
  };

  const displayModel = (displayVehicle) => {
    if (displayVehicle) {
      if (displayVehicle.vehicleModel) {
        return displayVehicle.vehicleModel?.modelDescription.toUpperCase();
      } else if (
        displayVehicle.modelCode &&
        displayVehicle.modelCode.toUpperCase() !== unknownField &&
        displayVehicle.modelCode !== '-1'
      ) {
        return displayVehicle.modelCode.toUpperCase();
      }
    }
    return null;
  };

  return (
    <div className="margin-top-2">
      <div className="grid-row grid-gap margin-top-0">
        <div className="grid-col-6">
          <h4 className="title-s-caps text-primary margin-bottom-1">VEHICLE</h4>
          <div className="bg-gray-3 radius-md padding-y-2 padding-x-4">
            <DetailsTable
              className="afp-registration__section_container"
              data={[
                ['VIN', fieldGetter([{ field: vehicle?.id }]).toUpperCase()],
                ['VIN type', fieldGetter([{ field: vehicle?.itemType }])],
                [
                  'Vehicle Status',
                  vehicle?.inventoryStatus?.id ? (
                    <InventoryStatusTag
                      statusCode={vehicle?.inventoryStatus?.id}
                    />
                  ) : (
                    '—'
                  ),
                ],
                [
                  'Year',
                  fieldGetter([
                    {
                      field:
                        vehicle?.modelYear <= 0
                          ? 'UNKNOWN'
                          : vehicle?.modelYear,
                    },
                  ]),
                ],
                [
                  'Make',
                  fieldGetter([
                    { field: vehicle?.vehicleMake?.makeName.toUpperCase() },
                  ]),
                ],
                [
                  'Model',
                  fieldGetter([
                    {
                      field: displayModel(vehicle),
                    },
                  ]),
                ],
                ['Color', fieldGetter([{ field: vehicle?.makeColor?.name }])],
                [
                  'Fuel Type',
                  fieldGetter([{ field: vehicle?.fuel?.description }]),
                ],
                ['FAST reportable', fastReportable(vehicle?.fastReportable)],
              ]}
            />
            {/* Button should still show for missing/stolen vehicles but not for sold ones */}
            {canEditVehicle && (
              <Button
                onClick={toggleVehicleEdit}
                variant="outline"
                className="vehicle-edit bg-white margin-top-2"
                aria-label="edit vehicle"
                label="Edit"
              />
            )}
          </div>
          {editingVehicle && (
            <VehicleEdit
              currentFuel={vehicle?.fuelCode}
              currentFast={vehicle?.fastReportable}
              currentYear={vehicle?.modelYear}
              currentMakeName={vehicle?.vehicleMake?.makeName}
              currentMakeCode={vehicle?.makeCode}
              currentModelName={vehicle?.vehicleModel?.modelDescription}
              currentModelCode={vehicle?.modelCode}
              currentColorCode={vehicle?.makeColor?.makeColorCode}
              currentColorName={vehicle?.makeColor?.name}
              nhtsaVerified={vehicle?.nhtsaVerified}
              onClose={toggleVehicleEdit}
              onSave={saveVehicleChanges}
              vin={vehicle?.id}
              inventoryStatus={vehicle?.inventoryStatus}
              tag={{
                tagNumber: vehicle?.tagNumber,
                expirationDate: vehicle?.tag?.expirationDate,
              }}
            />
          )}
        </div>
        <div className="grid-col-6">
          <h4 className="title-s-caps text-primary margin-bottom-1">
            LICENSE PLATE
          </h4>
          {vehicle?.registered ? (
            <>
              <div className="bg-gray-3 radius-md padding-y-2 padding-x-4">
                {!vehicle?.exemptPlate && (
                  <DetailsTable
                    data-testid="afp-registered_vehicle_License_plate"
                    className="afp-registration__section_container"
                    data={[
                      [
                        'License plate',
                        <Link
                          to={`/license-plate/${encodeURIComponent(
                            vehicle?.tagNumber,
                          )}?tagExpirationDate=${vehicle?.tag?.expirationDate}`}
                        >
                          {fieldGetter([{ field: vehicle?.tagNumber }])}
                        </Link>,
                      ],
                      [
                        'License plate expiration date',
                        fieldGetter([
                          {
                            field: vehicle?.tag?.expirationDate,
                            formatter: 'exp',
                          },
                        ]),
                      ],
                      [
                        'State license plate',
                        fieldGetter([
                          {
                            field: getStatePlate,
                          },
                        ]),
                      ],
                    ]}
                  />
                )}
                {vehicle?.exemptPlate && (
                  <DetailsTable
                    data-testid="afp-registered_vehicle_License_plate"
                    className="afp-registration__section_container"
                    data={[
                      ['License plate', <ExemptLabeledMessage />],
                      [
                        'State license plate',
                        fieldGetter([
                          {
                            field: getStatePlate,
                          },
                        ]),
                      ],
                    ]}
                  />
                )}
                {canEditLicensePlate && (
                  <Button
                    onClick={toggleLPEdit}
                    variant="outline"
                    className="lp-edit bg-white margin-top-2"
                    aria-label="edit license plate"
                    label="Edit"
                  />
                )}
              </div>
              <LicensePlateEdit
                licensePlate={vehicle?.tagNumber || ''}
                lpExpDate={vehicle?.tag?.expirationDate}
                stateLicPlate={getStatePlate}
                editing={editingLP}
                onClose={toggleLPEdit}
                onSave={saveLPChanges}
                exemptPlate={vehicle?.exemptPlate}
              />
              {!vehicle?.exemptPlate && (
                <>
                  <h4 className="title-s-caps text-primary margin-bottom-1">
                    LICENSE PLATE POC
                  </h4>
                  <div className="bg-gray-3 radius-md padding-y-2 padding-x-4">
                    {LicensePlatePoc(vehicle?.tag)}
                  </div>
                </>
              )}
            </>
          ) : (
            <div
              className="bg-gray-3 radius-md padding-y-2 padding-x-4 afp-registration__section_lp_register"
              data-testid="afp-unregistered_vehicle_default_info"
            >
              <div className="afp-vehicle-details__no_image padding-y-4">
                <EmptyState
                  alt="Image not available"
                  bottomText={
                    !canRegisterVehicle ? 'No attached license plate' : ''
                  }
                  hasBackground
                />
              </div>
              {canRegisterVehicle && (
                <>
                  <div data-testid="register-vehicle-placeholder">
                    Add a federal license plate
                    <br />
                    and two points of contact to register
                    <br />
                    this vehicle{' '}
                  </div>
                  <div className="margin-top-2 margin-bottom-2">
                    <Link
                      to={`/vehicle-registration/${encodeURIComponent(
                        _.get(vehicle, 'id'),
                      )}`}
                    >
                      <Button
                        aria-label="register vehicle"
                        label="Register Vehicle"
                      />
                    </Link>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

VehicleInfo.defaultProps = {
  vehicle: {},
  updateRegistration: (vehicle) => vehicle,
};

VehicleInfo.propTypes = {
  vehicle: PropTypes.shape({
    id: PropTypes.string,
    tagNumber: PropTypes.string,
    fuel: PropTypes.shape({
      description: PropTypes.string,
    }),
    modelYear: PropTypes.number,
    fuelCode: PropTypes.string,
    fastReportable: PropTypes.bool,
    makeColor: PropTypes.shape({
      name: PropTypes.string,
    }),
    vehicleModel: PropTypes.shape({
      modelDescription: PropTypes.string,
    }),
    vehicleMake: PropTypes.shape({
      makeName: PropTypes.string,
    }),
    tag: PropTypes.shape({
      expirationDate: PropTypes.number,
    }),
    inventoryStatus: PropTypes.shape({
      id: PropTypes.string,
      description: PropTypes.string,
    }),
  }),
  updateRegistration: PropTypes.func,
};

export default VehicleInfo;
