import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { SelectDropdown } from '@gsa/afp-component-library';
import { sortBy } from 'lodash';
import { GET_AGENCIES_BY_PERMISSION } from '../../../../services/data-layer';
import {
  VMSOperations,
  VMSSubjects,
  DEFAULT_SELECT_OPTION_LABEL,
} from '../../../../utilities/consts';

export default function SelectAgency({
  agencyCode,
  setIsMultiAgencyScoped,
  onChange = () => {},
  onUpdate = () => {},
  ...props
}) {
  const { data } = useQuery(GET_AGENCIES_BY_PERMISSION, {
    variables: {
      subject: VMSSubjects.VEHICLE,
      operation: VMSOperations.CREATE_VEHICLE,
    },
    onError(err) {
      console.warn(err);
    },
  });
  const [agencies, setAgencies] = useState([]);

  useEffect(() => {
    if (data?.getAgenciesByPermission) {
      setAgencies([
        { value: '', label: DEFAULT_SELECT_OPTION_LABEL },
        ...sortBy(data.getAgenciesByPermission, 'id').map((a) => ({
          value: a.id,
          label: `${a.id} - ${a.name}`,
        })),
      ]);
      if (data?.getAgenciesByPermission.length === 1) {
        const valueToPrepoluate = data?.getAgenciesByPermission[0];
        onChange({
          value: valueToPrepoluate.id,
          label: `${valueToPrepoluate.id} - ${valueToPrepoluate.name}`,
        });
      } else if (data?.getAgenciesByPermission.length > 1) {
        setIsMultiAgencyScoped(true);
      }
    }
  }, [data]);

  useEffect(() => {
    if (agencyCode && agencies.length) {
      const selectedAgency = agencies.find(
        (agency) => agency.value === agencyCode,
      );
      if (selectedAgency) {
        onUpdate(selectedAgency);
      }
    }
  }, [agencyCode, agencies]);

  const handleChange = (e) => {
    const code = e.target.value;
    const selectedAgency = agencies.find((agency) => agency.value === code);
    onChange(selectedAgency);
  };

  return (
    <SelectDropdown
      id="agency"
      data-testid="agency"
      name="agency"
      label="Agency"
      required
      options={agencies}
      value={agencyCode}
      onChange={handleChange}
      {...props}
    />
  );
}
