/* eslint-disable react/prop-types */
// eslint-disable-next-line filenames/match-exported
import React, { useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import useStepper from 'hooks/use-stepper';
import { StepIndicator } from '@gsa/afp-component-library';
import { FormButtons } from './form-buttons';
import exportsConfig from '../../config/exports-config';
import { useExport } from '../../export-provider';
import {
  OWNERSHIP_TYPES,
  handleSetAgencySelectionValues,
  handleSetReportFiltersValues,
  handleSetReportScheduleValues,
} from '../../config/helpers';
import { REPORT_TYPE_BY_ID } from '../../../../helpers/report-types';

const WizardForm = ({
  title,
  stepConfig,
  centered,
  noLabel,
  counterSize,
  submitLabel,
  defaultValues,
  onSubmit,
  watchConfig,
  hideActionButtons,
  mode,
  setReportType,
}) => {
  const { ownership, selectedReport } = useExport();

  const stepper = useStepper(stepConfig);
  const {
    steps,
    stepIndex,
    stepLabel,
    stepStateIds,
    StepComponent,
    incrementStep,
    decrementStep,
    jumpToStep,
    isLastStep,
    scrollToStepper,
  } = stepper;

  const formMethods = useForm({ defaultValues });

  const watchedField = useWatch({
    control: formMethods.control,
    name: watchConfig?.id,
  });

  useEffect(() => {
    if (watchConfig?.notifier) {
      watchConfig.notifier(watchedField);
    }
  }, [watchedField]);

  // Handle edit mode form values
  useEffect(() => {
    if (selectedReport) {
      // set step 1 values
      setReportType(REPORT_TYPE_BY_ID[selectedReport?.reportTypeId]);
      formMethods.setValue(
        'vehicle_options',
        selectedReport?.reportCriteria?.vehicleOptions,
      );
      formMethods.setValue('schedule_options', 'yes');
      jumpToStep('Agency selection');
      // set step 2 (Agency selection) values
      handleSetAgencySelectionValues(formMethods.setValue, selectedReport);
      // set step 3 (Report filters) values
      handleSetReportFiltersValues(
        formMethods.setValue,
        selectedReport,
        REPORT_TYPE_BY_ID[selectedReport?.reportTypeId],
      );
      // set step 4 (Report schedule) values
      handleSetReportScheduleValues(formMethods.setValue, selectedReport);
    }
  }, [selectedReport]);

  const stepStatus = steps.map(({ label }, index) => {
    let isCompleted = index < stepIndex ? 'completed' : 'not completed';
    // disable step 1 for edit mode
    if (selectedReport && index === 0) {
      isCompleted = 'not completed';
    }
    const status = index === stepIndex ? 'current' : isCompleted;
    return { label, status };
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isLastStep) {
      formMethods.handleSubmit((data) => {
        onSubmit(data);
      })();
    } else {
      formMethods.trigger(stepStateIds).then(() => {
        const { errors } = formMethods.formState;
        if (Object.keys(errors).length === 0) {
          incrementStep(formMethods.getValues());
          scrollToStepper();
        }
      });
    }
  };

  const resetErrors = () => {
    formMethods.reset({}, { keepValues: true });
  };

  return (
    <div>
      {stepIndex > 0 && watchedField !== 'DEFAULT' && (
        <div className="grid-row flex-column border-left border-base-lighter margin-y-3 padding-left-2">
          <div className="grid-col-3 text-bold border-bottom border-base-lighter padding-bottom-05">
            {mode.charAt(0).toUpperCase() + mode.slice(1)}
          </div>
          <div className="grid-col-6 margin-top-2 display-flex">
            <div
              // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
              tabIndex="0"
              className="afp-chip margin-right-1"
              aria-label="Selected report ownership"
            >
              <label className="afp-chip text-primary">
                {selectedReport
                  ? OWNERSHIP_TYPES[
                      selectedReport?.reportCriteria?.vehicleOptions
                    ].label
                  : OWNERSHIP_TYPES[ownership?.defaultValue].label}
              </label>
            </div>
            <div
              // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
              tabIndex="0"
              className="afp-chip margin-right-1"
              aria-label="Selected report type"
            >
              <label className="afp-chip text-primary">
                {exportsConfig[watchedField].label}
              </label>
            </div>
          </div>
        </div>
      )}
      <div
        className="margin-top-3 margin-bottom-4 tablet:grid-col-7"
        id="stepper-wrapper"
      >
        <StepIndicator
          title={title}
          steps={stepStatus}
          heading={{ current: stepIndex + 1, text: stepLabel }}
          centered={centered}
          noLabel={noLabel}
          counterSize={counterSize}
          onClick={({ label }) => {
            resetErrors();
            jumpToStep(label);
          }}
        />
      </div>
      <form
        id="bulk-export-form"
        data-testid="bulk-export-form"
        className="margin-bottom-4 tablet:grid-col-7"
        onSubmit={handleSubmit}
      >
        <StepComponent {...formMethods} mode={mode} />
        {!hideActionButtons && (
          <FormButtons
            {...stepper}
            decrementStep={() => {
              resetErrors();
              decrementStep();
            }}
            submitLabel={submitLabel}
          />
        )}
      </form>
    </div>
  );
};

export default WizardForm;
