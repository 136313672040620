import React from 'react';
import CreateOrdersTable from './create-orders-table';
import { useCreateOrders } from './create-orders-provider';

const updateOrdersList = (rowIndex, columnId, cellValue, ordersList) => {
  return ordersList.map((order, index) => {
    if (index === rowIndex) {
      return {
        ...order,
        [columnId]: cellValue?.value ?? '',
      };
    }
    return order;
  });
};

const CreateOrdersTables = () => {
  const { ordersList1, ordersList2, ordersList3, ordersList4, dispatchAction } =
    useCreateOrders();
  // if value is dirty , change the orderList state value and resetAlertMessage
  const validateCellData =
    (dispatchActionType) => (rowIndex, columnId, cellValue, original, data) => {
      const newOrderList = updateOrdersList(
        rowIndex,
        columnId,
        cellValue,
        data,
      );
      dispatchAction(dispatchActionType, newOrderList);
      if (cellValue.isDirty) {
        dispatchAction('SET_ALERT_MESSAGE', { type: '', message: '' });
      }
      return {
        columnId,
        rowIndex,
        value: cellValue.value,
        isValidCell: true,
        errorMessage: '',
        inputClass: '',
      };
    };

  return (
    <>
      <div className="grid-row grid-gap grid-col-12">
        <div className="grid-col-3">
          <CreateOrdersTable
            tableIndex="1"
            ordersList={ordersList1}
            updateCellData={validateCellData('SET_ORDERS_LIST1')}
          />
        </div>
        <div className="grid-col-3">
          <CreateOrdersTable
            tableIndex="2"
            ordersList={ordersList2}
            updateCellData={validateCellData('SET_ORDERS_LIST2')}
          />
        </div>
        <div className="grid-col-3">
          <CreateOrdersTable
            tableIndex="3"
            ordersList={ordersList3}
            updateCellData={validateCellData('SET_ORDERS_LIST3')}
          />
        </div>
        <div className="grid-col-3">
          <CreateOrdersTable
            tableIndex="4"
            ordersList={ordersList4}
            updateCellData={validateCellData('SET_ORDERS_LIST4')}
          />
        </div>
      </div>
    </>
  );
};

export default CreateOrdersTables;
