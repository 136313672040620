import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { VehiclePropType } from '../../utilities/types';

export const vehicleContext = createContext(null);

const VehicleContextProvider = ({
  children,
  vehicle,
  setVehicle,
  refetchVehicle,
  showSystemError,
  setPageMsg,
  setTabMsg,
  setSectionMsg,
  alertMessages,
  setAlertMessages,
  ...rest
}) => {
  const [registrationUpdated, setRegistrationUpdated] = useState(false);

  return (
    <vehicleContext.Provider
      value={{
        vehicle,
        setVehicle,
        refetchVehicle,
        showSystemError,
        setPageMsg,
        setTabMsg,
        setSectionMsg,
        registrationUpdated,
        setRegistrationUpdated,
        alertMessages,
        setAlertMessages,
        ...rest,
      }}
    >
      {children}
    </vehicleContext.Provider>
  );
};

VehicleContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  vehicle: PropTypes.shape(VehiclePropType).isRequired,
  setVehicle: PropTypes.func.isRequired,
  refetchVehicle: PropTypes.func.isRequired,
  showSystemError: PropTypes.func.isRequired,
  setPageMsg: PropTypes.func.isRequired,
  setTabMsg: PropTypes.func.isRequired,
  setAlertMessages: PropTypes.func.isRequired,
};

export default VehicleContextProvider;

// Hook to exposes context value.
export const useVehicle = () => useContext(vehicleContext);
