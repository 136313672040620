import React from 'react';
import { StepIndicator } from '@gsa/afp-component-library';

const getStepText = (step) => {
  const lookup = {
    1: 'Vehicle and License Plate Information',
    2: 'Agency and Point of Contact Information',
    3: 'Review and Register',
  };
  return lookup[step];
};

const getStepStatus = (step, currentStep) => {
  if (step === currentStep) {
    return 'current';
  }
  if (step < currentStep) {
    return 'completed';
  }
  if (step > currentStep) {
    return 'not completed';
  }
  return null;
};

// eslint-disable-next-line react/prop-types
export default function RegistrationSteps({ step }) {
  return (
    <StepIndicator
      counterSize="large"
      heading={{
        current: step,
        total: 3,
        text: getStepText(step),
      }}
      steps={[
        {
          label: 'Vehicle and License Plate',
          status: getStepStatus(1, step),
        },
        {
          label: 'Agency and Point of Contact',
          status: getStepStatus(2, step),
        },
        {
          label: 'Review and Register',
          status: getStepStatus(3, step),
        },
      ]}
    />
  );
}
