import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from '@gsa/afp-component-library';

const domesticLocationInfo = () => (
  <p>
    Domestic locations include all 50 states, District of Columbia, <br />
    the US Virgin Islands, Guam, American Samoa, the Commonwealth <br />
    of the Northern Marianas Islands, and the Commonwealth of Puerto Rico.
    <br /> All other locations are considered to be foreign.
  </p>
);

const telematicsInfo = () => (
  <>
    <p>
      If Vehicle telematics installed is marked as Yes, then the data usage
      column can be populated with one or more attributes(below) or can be left
      blank if data is not used from telematics device. The following
      single-letter codes identifying categories of data gathered from the
      vehicle&apos;s on-board telematics system(s) and used in the agency&apos;s
      fleet management and reporting processes and/or systems:
      <br />
    </p>
    <p>
      <b>Telematics Attributes:</b> <br />
      F - Vehicle fuel/energy consumption (e.g., types and quantities of fuel
      consumed, including charging and electricity consumption for electric and
      plug-in hybrid electric vehicles)
      <br />
      I - Vehicle idling
      <br />
      L - Vehicle location <br />
      M - Vehicle mileage <br />
      S - Safety-related information (e.g., speed, accident information, unsafe
      driving behaviors) <br />
      T - Trip-level information <br />
      U - Vehicle utilization (e.g., hours of use, # of trips) <br />
      X - Vehicle maintenance information <br />
    </p>
    <p>
      <b>Examples:</b>
    </p>
    <ul>
      <li>
        If Agency is using Idling, trip-level information, fuel/energy
        consumption, mileage data from Telematics device, this column needs to
        be populated with IFTM
      </li>
      <li>
        If Agency is using Fuel consumption, utilization, idling information
        data from Telematics device, this column needs to be populated with FUI
      </li>
    </ul>
  </>
);

const lawEnforcementInfo = () => (
  <>
    <p>
      LE 1: Vehicle configured for apprehensions, arrests, law enforcement,
      police activities or dignitary protection, and is assigned to pursuit,
      protection or off-road duties. Must be equipped with heavy duty components
      to handle the stress of extreme maneuvers and have the horsepower required
      to achieve the speeds necessary to perform these functions.
    </p>
    <p>
      LE 2: Vehicle configured to perform intelligence, investigations,
      security, and surveillance activities. May be unmarked or marked. Not
      expected to perform pursuit or protection operations either on- or
      off-road and does not require the heavy duty components found on an LE 1
      vehicle.
    </p>
    <p>
      LE 3: Standard vehicle of any make or model that may be used for
      associated LE operations, including administrative functions such as
      courier, mail delivery, employee shuttle or other functions not performed
      by LE 1 and LE 2 tiered vehicles. Not expected to perform pursuit or
      protection operations either on or off-road.
    </p>
    <p>
      Agencies should consider the tier to which they have classified their LE
      vehicles when deciding whether or not to exempt them from AFV
      requirements. Agencies should not automatically exempt them from AFV
      requirements solely because it is operated by LE or because of the need
      for special equipment. Rather, the heads of agencies should exempt from
      AFV requirements only those LE vehicles for which no mission-suitable AFV
      exists. Agencies should implement policies that ensure that their LE and
      emergency vehicles are the smallest, most fuel efficient, and least
      greenhouse gas emitting vehicles necessary to execute mission
      requirements.
    </p>
  </>
);

const CDDPopOverModal = ({ isOpen, closeModal, title }) => {
  const onClose = () => {
    closeModal();
  };

  if (!isOpen) {
    return null;
  }

  const vehicleTelematics = title === 'Vehicle telematics data usage';
  const isGarageDomestic = title === 'Vehicle location';
  const isLawEnforcement = title === 'Law enforcement designation';
  let modalContent = '';
  if (vehicleTelematics) {
    modalContent = telematicsInfo();
  } else if (isGarageDomestic) {
    modalContent = domesticLocationInfo();
  } else if (isLawEnforcement) {
    modalContent = lawEnforcementInfo();
  }

  return (
    <div className="afp-modal-wrapper" data-test-id="pop-over-modal">
      <div className="afp-modal-overlay">
        <Modal
          title={<h2>{title}</h2>}
          variant="large"
          actions={
            <Button
              variant="unstyled"
              className="margin-right-2"
              onClick={onClose}
              data-testid="pop-over-close-button"
              label="Close"
            />
          }
          onClose={onClose}
        >
          {modalContent}
        </Modal>
      </div>
    </div>
  );
};

export default CDDPopOverModal;

CDDPopOverModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};
