import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Controller, useWatch } from 'react-hook-form';
import { useLazyQuery } from '@apollo/client';
import { Typeahead, RequiredFieldIndicator } from '@gsa/afp-component-library';
import {
  MATCHING_VEHICLES_BY_PARTIAL_VIN,
  MATCHING_VEHICLES_BY_PARTIAL_TAG,
} from 'services/data-layer';
import { DEFAULT_VALUE } from '../config/helpers';
import { useExport } from '../export-provider';

const renderSubComponent = (vehicles, id, type) => {
  const item = vehicles?.find(
    (veh) => (type === 'vin' ? veh.id : veh.tagNumber) === id,
  );
  return (
    item && (
      <div className="display-flex flex-column">
        <span className="text-ink">
          {item?.tagNumber}
          {item?.tagNumber && item.id && <>&nbsp;&nbsp;&ndash;&nbsp;&nbsp;</>}
          {item.id}
        </span>
        <span className="text-base">
          {item?.modelYear} {item?.vehicleMake?.makeName}&nbsp;
          {item?.vehicleModel?.modelDescription}&nbsp;
          {item?.makeColor?.name && `(${item.makeColor.name})`}
        </span>
      </div>
    )
  );
};

export const VehicleSelection = ({
  control,
  setValue,
  type,
  name: nameProp,
  required,
  ...restProps
}) => {
  const [typeaheads, setTypeaheads] = useState([]);
  const [typeaheadValues, setTypeaheadValues] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);

  const [agency, bureau, office, vehicleOptions] = useWatch({
    name: ['agency', 'bureau', 'office', 'vehicle_options'],
    control,
  });

  const { selectedReport } = useExport();

  const [fetchByVin] = useLazyQuery(MATCHING_VEHICLES_BY_PARTIAL_VIN, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setTypeaheads(data.getMatchingVinsByPartialVin);
    },
    // eslint-disable-next-line no-console
    onError: (err) => console.warn(err),
  });
  const [fetchByTag] = useLazyQuery(MATCHING_VEHICLES_BY_PARTIAL_TAG, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (data) setTypeaheads(data.getMatchingVinsByPartialTag);
    },
    // eslint-disable-next-line no-console
    onError: (err) => console.warn(err),
  });

  const fetchVehicles = (_, val) => {
    if (val) {
      type === 'vin'
        ? fetchByVin({
            variables: {
              partialVin: val,
              agency: agency === DEFAULT_VALUE ? null : agency,
              bureau: bureau === DEFAULT_VALUE ? null : bureau,
              office: office === DEFAULT_VALUE ? null : office,
              vehicleOption: vehicleOptions,
            },
          })
        : fetchByTag({
            variables: {
              partialTag: val,
              agency: agency === DEFAULT_VALUE ? null : agency,
              bureau: bureau === DEFAULT_VALUE ? null : bureau,
              office: office === DEFAULT_VALUE ? null : office,
              vehicleOption: vehicleOptions,
            },
          });
    }
  };

  useEffect(() => {
    const ids = [];
    if (typeaheads && typeaheads.length > 0) {
      typeaheads.forEach((item) => {
        ids.push(type === 'vin' ? item.id : item.tagNumber);
      });
    }
    setTypeaheadValues(ids);
  }, [typeaheads]);

  useEffect(() => {
    if (selectedReport?.reportCriteria?.vin) {
      setValue('vehicle_selection_vin', selectedReport?.reportCriteria?.vin);
      setSelectedValue(selectedReport?.reportCriteria?.vin);
    } else if (selectedReport?.reportCriteria?.tagNumber) {
      setValue(
        'vehicle_selection_tag',
        selectedReport?.reportCriteria?.tagNumber,
      );
      setSelectedValue(selectedReport?.reportCriteria?.tagNumber);
    }
  }, [
    selectedReport?.reportCriteria?.vin,
    selectedReport?.reportCriteria?.tagNumber,
  ]);

  return (
    <section className="margin-bottom-6 text-bold">
      <Controller
        control={control}
        name={nameProp}
        rules={
          required
            ? {
                validate: (value) => !!value || 'This is a required field',
              }
            : {}
        }
        render={({
          field: { onChange, name, value: formValue },
          formState: { errors },
        }) => {
          const placeholder = `Search vehicle by ${
            type === 'vin' ? 'VIN' : 'license plate'
          }`;
          const charNum = type === 'vin' ? 6 : 4;
          return (
            <>
              <Typeahead
                id={name}
                date-testid={name}
                name={name}
                label={
                  <>
                    Select a single vehicle for report{' '}
                    {required ? <RequiredFieldIndicator /> : null}
                  </>
                }
                filterValue={selectedValue || formValue || ''}
                fetchTypeaheadValues={fetchVehicles}
                typeaheadValues={typeaheadValues}
                placeholder={placeholder}
                inputCharNum={charNum}
                ariaLabel={placeholder}
                debounceDelay={500}
                promptText={`Search requires ${charNum} characters`}
                noResultsText={`No results found. The ${
                  type === 'vin' ? 'VIN' : 'license plate'
                } does not exist or you do not have permission to access the vehicle.`}
                generateCustomOption={(id) =>
                  renderSubComponent(typeaheads, id, type)
                }
                onOptionEnter={(value) => onChange({ target: { value } })}
                onClear={() => onChange({ target: { value: '' } })}
                errorMessage={errors?.[name]?.message}
                {...restProps}
                required={false} // to bypass browser required field check
              />
            </>
          );
        }}
      />
    </section>
  );
};

VehicleSelection.propTypes = {
  control: PropTypes.shape({}).isRequired,
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  setValue: PropTypes.func.isRequired,
};
VehicleSelection.defaultProps = {
  type: 'tag',
  required: false,
};
