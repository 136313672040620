import React from 'react';
import { useRegistration } from '../reg-context-provider';
import LicensePlate from './license-plate';

export default function LicensePlateInformation() {
  const { formState, setFormState, getFieldValidation } = useRegistration();
  return (
    <>
      <h2 className="margin-bottom-0 margin-top-8 title-m-bold">
        License Plate Information
      </h2>
      <div className="grid-row grid-gap">
        <div className="tablet:grid-col-6">
          <p className="afp-vehicle-registration__section_text">
            In order to register your vehicle you must enter a valid license
            plate number or have an exemption from requirement to display
            official U.S. Government license plates for this vehicle under
            sections{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.ecfr.gov/current/title-41/section-102-34.160"
            >
              § 102-34.160
            </a>
            ,{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.ecfr.gov/current/title-41/section-102-34.175"
            >
              § 102-34.175
            </a>{' '}
            or{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.ecfr.gov/current/title-41/section-102-34.180"
            >
              {' '}
              § 102-34.180
            </a>{' '}
          </p>
        </div>
      </div>
      <LicensePlate
        vin={formState.vin}
        setFormState={setFormState}
        tagNumber={formState.tagNumber}
        tagExpirationDate={formState.tagExpirationDate}
        exemptPlate={formState.exemptPlate}
        statePlate={formState.statePlate}
        itemType={formState.itemType}
        tagAgencyCode={formState.tagAgencyCode}
        tagBureauCode={formState.tagBureauCode}
        getFieldValidation={getFieldValidation}
      />
    </>
  );
}
