import React from 'react';
import _ from 'lodash';
import { CounterTag } from '@gsa/afp-component-library';
import { useLicensePlateDetails } from '../license-plate-details-provider';

const LpRelatedData = () => {
  const { tagDetails, tagActivityList } = useLicensePlateDetails();
  return (
    <div className="margin-top-3 display-flex flex-justify-end">
      <span className="padding-x-2">
        <CounterTag count={_.get(tagDetails, 'attachments.count', 0)}>
          Documents
        </CounterTag>
      </span>
      <span className="padding-x-2">
        <CounterTag count={_.get(tagDetails, 'comments.count', 0)}>
          Comments
        </CounterTag>
      </span>
      <span className="padding-left-2">
        <CounterTag count={tagActivityList?.length || 0}>History</CounterTag>
      </span>
    </div>
  );
};

export default LpRelatedData;
