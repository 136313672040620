import { useMutation } from '@apollo/client';
import { UPDATE_STATE_PLATE } from '../../../../services/data-layer';

export default function useUpdateStatePlate() {
  const [updateStatePlate, { data, loading }] = useMutation(
    UPDATE_STATE_PLATE,
    {
      fetchPolicy: 'no-cache',
      onError: (err) => {
        // eslint-disable-next-line no-console
        console.warn(err);
      },
    },
  );

  const updateItemStatePlate = (vehicle, statePlate, statePlateExp) => {
    const statePlateInput = {
      assetId: vehicle.uuid,
      vin: vehicle.id,
      stateTag: statePlate,
      stateTagExpirationDate: statePlateExp,
    };
    updateStatePlate({ variables: { updateStatePlate: statePlateInput } });
  };

  return [updateItemStatePlate, data, loading];
}
