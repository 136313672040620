/* eslint-disable react/prop-types */
/* eslint-disable filenames/match-exported */

import React from 'react';
import {
  Icon,
  Label,
  RequiredFieldIndicator,
} from '@gsa/afp-component-library';
import NumberFormat from 'react-number-format';
import classnames from 'classnames';

import { useRecoilState, useSetRecoilState } from 'recoil';

const NumberInputField = ({
  pageId = 'DEFAULT',
  selectors,
  id,
  dependency = null,
  required,
  label,
  disabled,
  hasIcon = true,
  decimalScale = 2,
  gridStyle,
  isVisible,
  defaultValue,
  ...restProps
}) => {
  const { fieldSelector, fieldAfterSelector = null } = selectors;
  const [fieldValueState, setFieldValueState] = useRecoilState(
    fieldSelector({ pageId, id }),
  );

  let afterFieldUpdated = ({ value }) => value;
  if (fieldAfterSelector) {
    afterFieldUpdated = useSetRecoilState(fieldAfterSelector({ pageId, id }));
  }

  const hasError = fieldValueState?.errorMessage;
  return (
    <div
      className={classnames(fieldValueState.errorMessage && 'margin-left-205')}
    >
      <div
        className={`usa-form-group ${
          hasError ? 'usa-form-group--error' : null
        }`}
      >
        <Label
          htmlFor={`${id}-id`}
          className={`${hasError ? 'usa-label--error' : null}`}
        >
          <span>
            {label} {required && <RequiredFieldIndicator />}
          </span>
        </Label>
        {hasError && (
          <span className="usa-error-message">
            {fieldValueState.errorMessage}
          </span>
        )}
        <div className="dollar-input">
          {hasIcon && (
            <Icon iconName="attach_money" className="prefix-icon-dollar" />
          )}
          <NumberFormat
            className={`usa-input ${hasError ? 'usa-input--error' : null}`}
            id={`${id}-id`}
            data-testid={id}
            onValueChange={(val) => {
              setFieldValueState({ value: val.value, dependency });
            }}
            thousandsGroupStyle="thousand"
            fixedDecimalScale
            decimalScale={decimalScale}
            decimalSeparator="."
            type="input"
            disabled={fieldValueState?.disabled || disabled}
            thousandSeparator
            allowNegative
            aria-invalid={hasError ? 'true' : 'false'}
            value={fieldValueState.value}
            onBlur={() => {
              afterFieldUpdated({ value: fieldValueState.value });
            }}
            {...restProps}
          />
        </div>
      </div>
    </div>
  );
};

export default NumberInputField;
