/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable filenames/match-exported */

import React from 'react';
import classnames from 'classnames';
import SelectDropDownField from '../widgets/SelectDropDownField';
import TextInputField from '../widgets/TextInputField';
import RadioButtonFields from '../widgets/RadioButtonFields';
import NumberInputField from '../widgets/NumberInputField';
import DatePickerField from '../widgets/DatePickerField';
import MilesInputField from '../widgets/MilesInputField';
import DateRangeField from '../widgets/date-range-field';
import VehiclePickerField from '../widgets/vehicle-picker-field';

/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable filenames/match-exported */

const GridStyleWrapper = ({ children, ...props }) => {
  return (
    <div
      key={props?.id}
      className={classnames(
        'text-bold flex-align-self-end',
        props?.gridStyle ? props?.gridStyle : 'desktop:grid-col-12',
      )}
    >
      {children}
    </div>
  );
};

const fieldTypeMap = {
  SelectDropDownField: (props) => <SelectDropDownField {...props} />,
  TextInputField: (props) => <TextInputField {...props} />,
  RadioButtonFields: (props) => (
    <RadioButtonFields key={props?.id} {...props} />
  ),
  NumberInputField: (props) => <NumberInputField {...props} />,
  DatePickerField: (props) => <DatePickerField {...props} />,
  MilesInputField: (props) => <MilesInputField {...props} />,
  DateRangeField: (props) => <DateRangeField {...props} />,
  VehiclePickerField: (props) => <VehiclePickerField {...props} />,
};

const getFieldComponent = ({ customizedWidgets, componentType }) => {
  if (typeof componentType === 'string') {
    if (
      componentType.includes('_customized') &&
      customizedWidgets[componentType]
    ) {
      return customizedWidgets[componentType];
    }
    if (fieldTypeMap[componentType]) {
      return fieldTypeMap[componentType];
    }
  }

  // eslint-disable-next-line no-console
  console.warn(`can not find ${componentType}`);

  return null;
};

const createFieldByComponentType = ({
  props,
  customizedWidgets,
  componentType,
}) => {
  const FieldComponent = getFieldComponent({
    customizedWidgets,
    componentType,
  });

  return (
    <GridStyleWrapper key={props?.id} {...props}>
      <FieldComponent {...props} />
    </GridStyleWrapper>
  );
};

// eslint-disable-next-line import/prefer-default-export
export { fieldTypeMap, createFieldByComponentType };
