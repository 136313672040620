import React, { createContext, useContext, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import useUser from 'utilities/use-user';
import { isArray } from 'lodash';
import {
  GET_AGENCIES_BY_PERMISSION,
  GET_BUREAUS_BY_PERMISSION,
  GET_OFFICES_BY_PERMISSION,
  GET_RECALL_CAMPAIGN_OPTIONS,
} from 'services/data-layer';
import { VMSOperations, VMSSubjects } from 'utilities/consts';
import { BureauFilterItem, OfficeFilterItem } from '../widgets';

const RecallFilterContext = createContext({});
const useRecallFilter = () => useContext(RecallFilterContext);
const filterStructure = (agencyCode, options, isGSAEmployee = true) => {
  return [
    {
      title: 'FSR email',
      key: 'filter-poc-fsr',
      filters: [
        {
          key: '$vehicle.fsr_user_email_address$',
          permanent: false,
          operator: '$like',
          value: [],
          ariaLabel: 'Search by FSR email',
          placeholder: 'Search by FSR email',
          prefixIcon: '',
          type: 'typeahead',
          id: 'placeholder_FSR',
          customFieldProps: {
            inputCharNum: 6,
            debounceDelay: 500,
            promptText: 'Search requires 6 characters',
            showNoResults: false,
            clearPanelFilterOnEmpty: true,
          },
        },
      ],
    },
    {
      title: 'Agency',
      requiredAsterisk: !isGSAEmployee,
      key: 'organization',
      filters: [
        {
          title: 'Agency',
          key: '$vehicle.customer.customer_agency_code$',
          id: 'filter-vehicle-agency-code',
          type: 'select',
          hideClear: true,
          operator: '$exact',
          options: [{ value: '', label: '-Select agency-' }, ...options],
          value: agencyCode,
        },
        {
          title: 'Bureau',
          key: '$vehicle.customer.customer_bureau_code$',
          id: 'filter-vehicle-bureau-code',
          component: BureauFilterItem,
          permanent: false,
          operator: '$exact',
          hideClear: true,
        },
        {
          title: 'Office',
          key: '$vehicle.customer.customer_office_code$',
          id: 'filter-vehicle-office-code',
          component: OfficeFilterItem,
          permanent: false,
          operator: '$exact',
          hideClear: true,
        },
      ],
    },
    {
      title: 'Legacy customer number',
      key: 'filter-legacy-customer-cumber',
      filters: [
        {
          label: 'Search by legacy customer number',
          key: '$vehicle.customer.legacy_customer_number$',
          type: 'text',
          permanent: false,
          operator: '$exact',
          hideClear: false,
          position: 'top',
          showSearchButton: true,
        },
      ],
    },
    {
      title: 'Ownership type',
      key: '$vehicle.ownership_type_code$',
      type: 'multiselect',
      operator: '$exact',
      value: [],
      hideClear: true,
      options: [
        { value: 'GF', label: 'GSA Leased' },
        { value: 'AO', label: 'Agency Owned' },
      ],
    },
    {
      title: 'Recall ID',
      key: 'filter-manufacturer-campaign',
      filters: [
        {
          key: '$recallCampaign.recall_code$',
          id: 'filter-campaign',
          permanent: false,
          hideClear: true,
          operator: '$like',
          value: [],
          ariaLabel: 'Search by campaign ID',
          placeholder: 'Search by campaign ID',
          prefixIcon: '',
          type: 'typeahead',
          customFieldProps: {
            inputCharNum: 3,
            debounceDelay: 500,
            promptText: 'Search requires 3 characters',
            showNoResults: false,
            clearPanelFilterOnEmpty: true,
          },
        },
        {
          key: '$recallCampaign.nhtsa_campaign_id$',
          permanent: false,
          operator: '$like',
          value: [],
          ariaLabel: 'Search by NHTSA recall ID',
          placeholder: 'Search by NHTSA recall ID',
          prefixIcon: '',
          type: 'typeahead',
          customFieldProps: {
            inputCharNum: 3,
            debounceDelay: 500,
            promptText: 'Search requires 3 characters',
            showNoResults: false,
            clearPanelFilterOnEmpty: true,
          },
        },
      ],
    },
    {
      title: 'Recall status',
      key: 'filter-recall-status',
      filters: [
        {
          key: 'status',
          type: 'radio',
          value: '',
          options: [
            { value: 'Open', label: 'Open' },
            { value: 'Closed', label: 'Closed' },
          ],
          hideClear: true,
          operator: '$in',
        },
      ],
    },
    {
      title: 'Stop drive',
      key: 'filter-should_stop_drive',
      filters: [
        {
          key: '$recallCampaign.should_stop_drive$',
          type: 'radio',
          operator: '$exact',
          id: 'should_stop_drive',
          hideClear: true,
          options: [
            { value: '1', label: 'Yes' },
            { value: '0', label: 'No' },
          ],
        },
      ],
    },
    {
      title: 'Park outside',
      key: 'filter-should_park_outside',
      filters: [
        {
          key: '$recallCampaign.should_park_outside$',
          type: 'radio',
          id: 'should_park_outside',
          operator: '$exact',
          hideClear: true,
          options: [
            { value: '1', label: 'Yes' },
            { value: '0', label: 'No' },
          ],
        },
      ],
    },
    {
      title: 'User remediated',
      key: 'user_closed_date',
      type: 'radio',
      value: '',
      options: [
        { value: '$isNotNull', label: 'Yes' },
        { value: '$isNull', label: 'No' },
      ],
      permanent: false,
      hideClear: true,
      operator: (filter) => filter.value,
    },
  ];
};

const initialState = {
  error: {},
  campaigns: [],
  nhtsaRecallOptions: [],
  agencies: undefined,
  bureaus: [],
  offices: [],
  filters: [],
  filterStructure: undefined,
};

const actions = {
  setAgencies: 'SET_AGENCIES',
  setCampaigns: 'SET_CAMPAIGNS',
  setBureaus: 'SET_BUREAUS',
  setOffices: 'SET_OFFICES',
  setScope: 'SET_SCOPE',
  setFilters: 'SET_FILTERS',
  setError: 'SET_ERROR',
  setStructure: 'SET_STRUCTURE',
  setNhtsaRecallOptions: 'SET_NHTSA_RECALL_OPTIONS',
};

const extractErrorName = (err) => err.name || 'Unknown Error';

const recallFilterReducer = (state, { action, payload }) => {
  const modifiedPayload = isArray(payload) ? payload || [] : [payload] || []; // in some cases the payload is not an array so need to check first if its an array or not

  const recallStatusFilterIndex = modifiedPayload?.findIndex(
    (item) => item.key === 'status',
  );

  // adding this custom logic to handle the recall status filter
  // since filter component does not support operator change based on value
  if (recallStatusFilterIndex !== -1) {
    const recallStatusFilter = { ...payload[recallStatusFilterIndex] };
    recallStatusFilter.operator =
      recallStatusFilter.value === 'Open' ? '$exact' : '$in';
    recallStatusFilter.value =
      recallStatusFilter.value === 'Closed'
        ? ['Closed', 'Closed by Mfg']
        : 'Open';
    modifiedPayload[recallStatusFilterIndex] = recallStatusFilter;
  }

  const mergeState = (value, field) => {
    if (!field) {
      return { ...state, error: initialState.error, ...value };
    }
    const merged = { ...state, error: initialState.error };
    merged[field] = value || initialState[field];
    return merged;
  };
  switch (action) {
    case actions.setAgencies: {
      return mergeState(modifiedPayload, 'agencies');
    }
    case actions.setCampaigns: {
      return mergeState(modifiedPayload, 'campaigns');
    }
    case actions.setBureaus: {
      return mergeState(modifiedPayload, 'bureaus');
    }
    case actions.setOffices: {
      return mergeState(modifiedPayload, 'offices');
    }
    case actions.setScope: {
      return mergeState(modifiedPayload);
    }
    case actions.setFilters: {
      return mergeState(
        {
          operator: '$and',
          conditions: modifiedPayload || [],
        },
        'filters',
      );
    }
    case actions.setNhtsaRecallOptions: {
      return mergeState(modifiedPayload, 'nhtsaRecallOptions');
    }
    case actions.setStructure: {
      return mergeState(modifiedPayload, 'filterStructure');
    }
    case actions.setError: {
      return mergeState(extractErrorName(modifiedPayload), 'error');
    }
    default:
      throw new Error('Invalid user filter action');
  }
};

const mapOptions = (data, map, sort) => {
  if (!data) {
    return [];
  }
  const fn = map
    ? map
    : (c) => ({
        value: c.id,
        label: c.name,
      });
  const options = data.map(fn);
  if (sort) {
    options.sort(sort);
  }
  return options;
};

const RecallFilterProvider = ({ children, structure }) => {
  const [agencyData, setAgencyData] = useState();
  const [state, setDispatch] = useReducer(
    recallFilterReducer,
    initialState,
    () => initialState,
  );
  const dispatch = (action, payload) => setDispatch({ action, payload });
  const gsaEmployee = useUser().isGsaEmployee();
  const dispatchError = (error) => dispatch(actions.setError, error);
  const dispatchFilters = (conditions) =>
    dispatch(actions.setFilters, conditions);

  const getFilterStructure = (agency, options) => {
    if (structure) {
      return structure(agency, options);
    }
    return filterStructure(
      agency,
      options,
      state.nhtsaRecallOptions,
      gsaEmployee,
    );
  };

  // Queries

  // TODO implement API query
  const [campaignsQuery] = useLazyQuery(GET_RECALL_CAMPAIGN_OPTIONS, {
    fetchPolicy: 'no-cache',
    onError: dispatchError,
    onCompleted: (data) => {
      const recallCodes = data?.getRecallCampaigns?.recallCodes || [];
      const campaigns = mapOptions(recallCodes, (recallCode) => ({
        label: recallCode,
        value: recallCode,
      }));
      dispatch(actions.setCampaigns, campaigns);
      const nhtsaRecallCodes = data?.getRecallCampaigns?.nhtsaCampaignIds || [];
      const nhtsaRecallOptions = mapOptions(
        nhtsaRecallCodes,
        (nhtsaRecallCode) => ({
          label: nhtsaRecallCode,
          value: nhtsaRecallCode,
        }),
      );
      dispatch(
        actions.setStructure,
        filterStructure(
          agencyData?.agencyCode,
          agencyData?.options,
          nhtsaRecallOptions,
          gsaEmployee,
        ),
      );
    },
  });
  const [agenciesQuery] = useLazyQuery(GET_AGENCIES_BY_PERMISSION, {
    fetchPolicy: 'cache-and-network',
    onError: dispatchError,
    onCompleted: (data) => {
      const agencies = data?.getAgenciesByPermission || [];
      const options = mapOptions(agencies, (c) => ({
        value: c.id,
        label: `${c.id} - ${c.name}`,
      }));
      const agencyCode = agencies.length === 1 ? agencies[0].id : '';
      setAgencyData({
        agencyCode,
        options,
      });
      dispatch(actions.setStructure, getFilterStructure(agencyCode, options));
      dispatch(actions.setAgencies, agencies);
    },
  });
  const [bureausQuery] = useLazyQuery(GET_BUREAUS_BY_PERMISSION, {
    fetchPolicy: 'cache-and-network',
    onError: dispatchError,
    onCompleted: (data) => {
      const bureaus = mapOptions(data?.getBureausByPermission || [], (c) => ({
        value: c.id,
        label: `${c.id} - ${c.name}`,
      }));
      dispatch(actions.setBureaus, bureaus);
    },
  });
  const [officesQuery] = useLazyQuery(GET_OFFICES_BY_PERMISSION, {
    fetchPolicy: 'cache-and-network',
    onError: dispatchError,
    onCompleted: (data) => {
      const offices = mapOptions(data?.getOfficesByPermission || [], (c) => ({
        value: c.officeCode,
        label: `${c.officeCode} - ${c.officeName}`,
      }));
      dispatch(actions.setOffices, offices);
    },
  });

  const getCampaigns = ({ agencyCode, bureauCode, officeCode }) => {
    campaignsQuery({
      variables: {
        filters: [
          {
            conditions: [
              {
                key: '$assetRecalls.vehicle.customer.customer_agency_code$',
                operator: '$in',
                value: [agencyCode],
              },
              {
                key: '$assetRecalls.vehicle.customer.customer_bureau_code$',
                operator: '$in',
                value: [...bureauCode],
              },
              {
                key: '$assetRecalls.vehicle.customer.customer_office_code$',
                operator: '$in',
                value: [...officeCode],
              },
            ],
            operator: '$and',
          },
        ],
      },
    });
  };

  const getAgencies = () => {
    const operation = VMSOperations.View;
    const subject = VMSSubjects.VEHICLE;
    agenciesQuery({
      variables: {
        operation,
        subject,
        order: [['agencycode', 'ASC']],
      },
    });
  };
  const getBureaus = (agencyCode) => {
    const operation = VMSOperations.View;
    const subject = VMSSubjects.VEHICLE;
    bureausQuery({
      variables: {
        agencyCode,
        operation,
        subject,
        order: [['bureaucode', 'ASC']],
      },
    });
  };
  const getOffices = ({ agencyCode, bureauCode }) => {
    const operation = VMSOperations.View;
    const subject = VMSSubjects.VEHICLE;
    officesQuery({
      variables: {
        agencyCode,
        bureauCode,
        operation,
        subject,
        order: [['officecode', 'ASC']],
      },
    });
  };

  return (
    <RecallFilterContext.Provider
      value={{
        ...state,
        setFilters: dispatchFilters,
        getCampaigns,
        getAgencies,
        getBureaus,
        getOffices,
      }}
    >
      {children}
    </RecallFilterContext.Provider>
  );
};

RecallFilterProvider.defaultProps = {
  structure: undefined,
};

RecallFilterProvider.propTypes = {
  structure: PropTypes.func,
};

export { RecallFilterProvider as default, useRecallFilter };
