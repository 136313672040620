import React from 'react';
import { Breadcrumbs, PageTitle } from '@gsa/afp-component-library';

const trail = [<a href={`${window.AFP_CONFIG.appURLs.home}/home`}>Home</a>];

export default function OrdersHeader() {
  return (
    <header>
      <Breadcrumbs trail={trail} current="License Plate Orders" />
      <PageTitle title="License Plate Orders" />
      <div className="usa-prose margin-bottom-4">
        <p>
          If you need help registering a vehicle or managing license plates,
          please reference the 
          <a
            href={`${process.env.PUBLIC_URL}/Vehicle-Registration-Service-User-Guide-Jan2022-v1.pdf`}
            className="mobile:display-block"
            data-testid="gsa-login-anchor"
            target="_blank"
            rel="noopener noreferrer"
          >
            user guide
          </a>
           for step-by-step instructions.
        </p>
      </div>
    </header>
  );
}
