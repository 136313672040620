import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { FilterPanel } from '@gsa/afp-component-library';
import { GET_AGENCIES_BY_PERMISSION } from '../../../services/data-layer';

const { FilterPanelField, FilterPanelFilterShape } = FilterPanel;

const placeholderOption = {
  value: '',
  label: '- Select agency -',
  defaultValue: true,
};

const AgencyFilterItem = ({ filter, operation, subject }) => {
  const [options, setOptions] = useState([placeholderOption]);
  const { data } = useQuery(GET_AGENCIES_BY_PERMISSION, {
    variables: { operation, subject, order: [['agencyCode', 'ASC']] },
  });

  useEffect(() => {
    if (data) {
      setOptions([
        placeholderOption,
        ...data.getAgenciesByPermission.map((agency) => ({
          value: agency.id,
          label: `${agency.id} - ${agency.name}`,
        })),
      ]);
    }
  }, [data]);

  return (
    <FilterPanelField
      key={[filter.key]}
      filter={{
        ...filter,
        type: 'select',
        options,
      }}
    />
  );
};

AgencyFilterItem.propTypes = {
  filter: PropTypes.shape(FilterPanelFilterShape).isRequired,
  operation: PropTypes.string,
  subject: PropTypes.string,
};

export default AgencyFilterItem;
