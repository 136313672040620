import React from 'react';
import PropTypes from 'prop-types';
import { StepIndicator } from '@gsa/afp-component-library';

const DestructionSteps = ({ currentStep }) => {
  const getStepStatus = (step) => {
    if (step === currentStep) {
      return 'current';
    }
    if (step < currentStep) {
      return 'completed';
    }
    if (step > currentStep) {
      return 'not completed';
    }
    return null;
  };

  const getStepText = (step) => {
    const lookup = {
      1: 'License plates for destruction',
      2: 'Agency and point of contact',
      3: 'Destruction information',
    };
    return lookup[step];
  };

  return (
    <StepIndicator
      counterSize="large"
      heading={{
        current: currentStep,
        total: 3,
        text: getStepText(currentStep),
      }}
      steps={[
        {
          label: 'License plates for destruction',
          status: getStepStatus(1),
        },
        {
          label: 'Agency and point of contact',
          status: getStepStatus(2),
        },
        {
          label: 'Destruction information',
          status: getStepStatus(3),
        },
      ]}
    />
  );
};

DestructionSteps.propTypes = {
  currentStep: PropTypes.number.isRequired,
};

export default DestructionSteps;
