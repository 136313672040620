import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { FilterTableFrame } from '@gsa/afp-component-library';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import { VMSOperations, VMSSubjects } from 'utilities/consts';
import FilterUpRightActions from 'utilities/filter-up-right-actions';
import { isFeatureEnabled } from 'utilities/feature-toggle';
import { canUpdateGFVehicleAdmin } from 'utilities/authorization';
import CustomerAccountListingTable from './pages/customer-account-listing-table';
import CustomerAccountSidebar from './widgets/customer-account-sidebar';
import { useCustomerAccounts } from './providers/customer-account-provider';
import CustomerAccountModal from './widgets/customer-account-modal';

const CustomerAccountListing = () => {
  const ability = useAppAbility();
  const {
    dispatchAction,
    selectedCustomerAccounts,
    customerAccountsList,
    resetModal,
    updateCustomerTransportationFees,
  } = useCustomerAccounts();

  const history = useHistory();
  const isEditEnabled = isFeatureEnabled('customer-accounts-change');

  const canCreateAccount =
    isEditEnabled &&
    ability.can(VMSOperations.Create, VMSSubjects.CUSTOMER_ACCOUNT);

  const canBulkReassign =
    isEditEnabled &&
    ability.can(VMSOperations.Manage, VMSSubjects.CUSTOMER_ACCOUNT);

  const canUseTransportationFeat = canUpdateGFVehicleAdmin(ability);

  const FTF = useMemo(
    () =>
      FilterTableFrame(
        null,
        FilterUpRightActions,
        CustomerAccountSidebar,
        CustomerAccountListingTable,
      ),
    [],
  );

  // AFP-121592 AC-1
  const hasSelectedAccounts = () => {
    if (!selectedCustomerAccounts.length) {
      dispatchAction(
        'SET_CUSTOMER_ACCOUNT_MODAL_MODE',
        'NO_SELECTED_ACCOUNTS_ERROR',
      );
      dispatchAction('SET_CUSTOMER_ACCOUNT_MODAL_SHOW', true); // Show the modal
      return false;
    }
    return true;
  };

  const transportationFeeAction = async (type) => {
    // going thorough the selected accounts,
    // selectedCustomerAccounts only contains customer account ID
    // the rows are from customerAccountsList

    const selectedAgencyCodes = new Set();
    customerAccountsList.rows.forEach((custAccount) => {
      if (selectedCustomerAccounts.indexOf(custAccount.customerId) !== -1) {
        selectedAgencyCodes.add(custAccount.customerAgencyCode);
      }
    });

    if (
      // AFP-121592 AC-3, AC-4
      selectedCustomerAccounts.length > 1 &&
      selectedAgencyCodes.size > 1
    ) {
      dispatchAction(
        'SET_CUSTOMER_ACCOUNT_MODAL_MODE',
        `SELECTED_ACCOUNTS_HAVE_MULTIPLE_AGNECIES_${type}`,
      );
      dispatchAction('SET_CUSTOMER_ACCOUNT_MODAL_SHOW', true); // Show the modal
    } else {
      const verb = type === 'ADD' ? 'added' : 'removed';
      const preposition = type === 'ADD' ? 'to' : 'from';
      resetModal();

      const response = await updateCustomerTransportationFees({
        variables: {
          customerAccountIds: selectedCustomerAccounts,
          actionType: type,
        },
      });

      if (response?.data?.updateCustomerTransportationFees) {
        dispatchAction('SET_ALERT_MESSAGE', {
          type: 'success',
          message: (
            <>
              You have successfully {verb} transportation fee {preposition}{' '}
              <strong>{selectedCustomerAccounts.length}</strong> accounts.
            </>
          ),
          location: 'header',
        });
      }
    }
  };

  return (
    <>
      <FTF
        upRightProps={{
          pageName: 'customer-account',
          DropDownItems: [
            {
              label: 'Create new account',
              iconName: 'add',
              onClick: () => {
                history.push('/customer-accounts/create');
              },
              hiddenItem: !canCreateAccount,
            },
            {
              label: 'Reassign selected accounts',
              iconName: 'account_circle',
              onClick: () => {
                if (selectedCustomerAccounts?.length === 0) {
                  dispatchAction(
                    'SET_CUSTOMER_ACCOUNT_MODAL_MODE',
                    'NO_SELECTED_ACCOUNTS',
                  );
                } else {
                  dispatchAction(
                    'SET_CUSTOMER_ACCOUNT_MODAL_MODE',
                    'REASSIGN_CUSTOMER_ACCOUNT',
                  );
                }
                dispatchAction('SET_CUSTOMER_ACCOUNT_MODAL_SHOW', true); // Show the modal
              },
              hiddenItem: !canBulkReassign,
            },
            {
              label: 'Add transportation fee',
              iconName: 'check',
              onClick: () => {
                hasSelectedAccounts() && transportationFeeAction('ADD');
              },
              hiddenItem: !canBulkReassign || !canUseTransportationFeat,
            },
            {
              label: 'Remove transportation fee',
              iconName: 'cancel',
              onClick: () => {
                hasSelectedAccounts() && transportationFeeAction('REMOVE');
              },
              hiddenItem: !canBulkReassign || !canUseTransportationFeat,
            },
          ],
        }}
        filterToggle
      />
      <CustomerAccountModal />
    </>
  );
};

export default CustomerAccountListing;
