import {
  Alert,
  Button,
  Modal,
  TextInput,
  RequiredFieldIndicator,
} from '@gsa/afp-component-library';
import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useEffect } from 'react';
import { useCustomerAccounts } from '../providers/customer-account-provider';
import { GET_USERS_BY_ROLE } from '../../../services/data-layer/users.gql';
import { ASSIGN_FSR_EMAIL } from '../customer-account-gql';
import ContactInfoCard from './contact-info-card';
import '../customer-account.css';

const ModalTitle = () => {
  const { customerAccountModalMode, dispatchAction } = useCustomerAccounts();

  useEffect(() => {
    dispatchAction('SET_ALERT_MESSAGE', { type: '', message: '' });
  }, []);

  let title = '';
  switch (customerAccountModalMode) {
    case 'REASSIGN_CUSTOMER_ACCOUNT':
      title = <h2>Reassign selected accounts</h2>;
      break;
    case 'NO_SELECTED_ACCOUNTS':
      title = <h2>Please select accounts first</h2>;
      break;
    case 'DELETE_CUSTOMER_ACCOUNT':
      title = <h2>Delete Account</h2>;
      break;
    default:
      break;
  }

  return title;
};

const ModalContent = () => {
  const {
    customerAccountModalMode,
    dispatchAction,
    resetModal,
    selectedCustomerAccounts,
    assignFsrEmail,
  } = useCustomerAccounts();
  const [lookupEmail, setLookupEmail] = React.useState('');
  const [fsr, setFsr] = React.useState(null);
  const [getUsersByRole, { data }] = useLazyQuery(GET_USERS_BY_ROLE);
  const [searched, setSearched] = React.useState(false);
  // const [assignFsrEmail] = useMutation(ASSIGN_FSR_EMAIL);
  // const { customerAccountIdsSelected } = useCustomerAccounts();
  const [showAlert, setShowAlert] = React.useState(false);

  const handleFindFsrClick = async () => {
    if (!lookupEmail) {
      // Show an error message if lookupEmail is empty
      return;
    }

    // Trigger the query
    setSearched(true);
    getUsersByRole({
      variables: {
        roleId: [1, 2009, 2001, 2008],
        limit: 1,
        offset: 0,
        filters: [
          {
            operator: '$and',
            conditions: [
              { operator: '$like', key: 'email', value: lookupEmail },
              { operator: '$exact', key: 'userStatusId', value: '3' },
            ],
          },
        ],
      },
    });
  };

  useEffect(() => {
    if (data?.getUsersByRole?.rows?.length > 0) {
      setFsr(data?.getUsersByRole?.rows[0]);
    } else {
      setFsr(null);
    }
  }, [data]);

  useEffect(() => {
    setSearched(false);
  }, [lookupEmail]);

  const handleReassignAccount = () => {
    setShowAlert(true);
  };

  const handleConfirmReassign = async () => {
    // Extract only the ids from the selectedCustomerAccounts and convert them to strings
    const customerAccountIds = selectedCustomerAccounts.map(
      (selectedCustomerAccount) => String(selectedCustomerAccount),
    );

    const response = await assignFsrEmail({
      variables: { customerAccountIds, fsrEmail: fsr.email },
    });

    resetModal();

    if (response.data) {
      dispatchAction('SET_ALERT_MESSAGE', {
        type: 'success',
        message: `You have successfully reassigned ${
          customerAccountIds.length
        } customer accounts to FSR, ${fsr.firstName + ' ' + fsr.lastName}.`,
        location: 'header',
      });
    }
  };

  switch (customerAccountModalMode) {
    case 'REASSIGN_CUSTOMER_ACCOUNT':
      return (
        <>
          <p className="text-bold margin-top-4 margin-bottom-neg-1">
            {' '}
            Enter the email address for the FSR you wish to designate selected
            <br /> customer accounts <RequiredFieldIndicator />
          </p>
          <div className="grid-row">
            <div className="grid-col-8 padding-right-1">
              <TextInput
                placeholder="Enter FSR's email address"
                name="lookupemail"
                required
                value={lookupEmail}
                onChange={(e) => {
                  setLookupEmail(e.target.value);
                }}
              />
            </div>
            <div className="grid-col-4 margin-top-3">
              <Button
                variant="outline"
                className="margin-left-05"
                style={{ height: '2.4rem' }}
                data-testid="fsr-button"
                label="Find FSR"
                onClick={handleFindFsrClick}
              />
            </div>

            {fsr && searched && (
              <>
                <div
                  className="grid-col-10 margin-top-2 padding-left-2 contact-card"
                  data-testid="contact-card"
                >
                  <ContactInfoCard contact={fsr} displayContext="FSR" />
                </div>
                <div className="grid-col-12 margin-top-2">
                  <Button
                    variant="primary"
                    className="margin-right-2"
                    data-testid="fsr-reassign-button"
                    label="Reassign accounts to new FSR"
                    onClick={handleReassignAccount}
                  />
                  {showAlert && (
                    <Alert type="warning">
                      Are you sure you want to reassign selected accounts to
                      this FSR?
                      <Button
                        variant="outline"
                        className="margin-right-2"
                        data-testid="confirm-reassign-button"
                        label="Confirm reassign"
                        onClick={handleConfirmReassign}
                      />
                    </Alert>
                  )}
                </div>
              </>
            )}

            {!fsr && searched && (
              <div className="grid-col-12">
                <p>No FSR exists for that email address</p>
              </div>
            )}
          </div>
        </>
      );
    case 'NO_SELECTED_ACCOUNTS':
      return (
        <p>
          Please select customer accounts from the listings first to perform
          this action.
        </p>
      );
    case 'DELETE_CUSTOMER_ACCOUNT':
      return (
        <p>Are you sure you want to delete this account from GSAFleet.gov?</p>
      );
    case 'NO_SELECTED_ACCOUNTS_ERROR':
      return (
        <Alert heading="Select accounts first" type="error">
          Select accounts before performing this action.
        </Alert>
      );
    case 'SELECTED_ACCOUNTS_HAVE_MULTIPLE_AGNECIES_ADD':
      return (
        <Alert heading="Select accounts in same agency" type="error">
          Transportation fee can only be added to one agency at a time.
        </Alert>
      );
    case 'SELECTED_ACCOUNTS_HAVE_MULTIPLE_AGNECIES_REMOVE':
      return (
        <Alert heading="Select accounts in same agency" type="error">
          Transportation fee can only be removed from one agency at a time.
        </Alert>
      );
    default:
      return null;
  }
};

const ModalAction = () => {
  const {
    resetModal,
    customerAccountModalMode,
    deleteCustomerAccount,
    customerAccountToDelete,
  } = useCustomerAccounts();

  const customerAccountIdToDelete = parseFloat(customerAccountToDelete);

  switch (customerAccountModalMode) {
    case 'REASSIGN_CUSTOMER_ACCOUNT':
      return (
        <>
          <Button
            variant="unstyled"
            className="margin-right-2"
            onClick={resetModal}
            data-testid="customer-account-reassign-cancel-button"
            label="Cancel"
          />
        </>
      );
    case 'NO_SELECTED_ACCOUNTS':
    case 'NO_SELECTED_ACCOUNTS_ERROR':
    case 'SELECTED_ACCOUNTS_HAVE_MULTIPLE_AGNECIES_ADD':
    case 'SELECTED_ACCOUNTS_HAVE_MULTIPLE_AGNECIES_REMOVE':
      return (
        <>
          <Button
            variant="primary"
            className="margin-right-2"
            onClick={resetModal}
            data-testid="customer-account-ok-button"
            label="OK"
          />
        </>
      );
    case 'DELETE_CUSTOMER_ACCOUNT':
      return (
        <>
          <Button
            variant="unstyled"
            className="margin-right-2"
            onClick={resetModal}
            data-testid="customer-account-delete-cancel-button"
            label="Cancel"
          />
          <Button
            variant="primary"
            className="margin-right-2"
            onClick={() =>
              deleteCustomerAccount({
                variables: { id: customerAccountIdToDelete },
              })
            }
            data-testid="customer-account-delete-button"
            label="Remove Account"
          />
        </>
      );
    default:
      return null;
  }
};

const CustomerAccountModal = () => {
  const { alertMessage, resetModal, customerAccountModalShow } =
    useCustomerAccounts();
  const { header, message, type, context } = alertMessage;

  return customerAccountModalShow ? (
    <div className="afp-modal-wrapper" data-testid="customer-account-modal">
      <div className="afp-modal-overlay">
        <Modal
          title={<ModalTitle />}
          actions={<ModalAction />}
          onClose={resetModal}
          variant="large"
        >
          {context === 'reassignAccount' && message && (
            <div
              className="margin-bottom-2"
              data-testid="customer-account-modal-alert"
            >
              <Alert type={type} heading={header || null}>
                {message}
              </Alert>
            </div>
          )}
          <ModalContent />
        </Modal>
      </div>
    </div>
  ) : null;
};

export default CustomerAccountModal;
