import { flatten } from 'lodash';

const licensePlateFilterStructure = (orderNumber) => [
  {
    title: 'Plate number',
    key: 'tagNumber',
    type: 'typeahead',
    ariaLabel: 'Search Plate number',
    placeholder: 'All',
    value: '',
    permanent: false,
    operator: '$like',
  },
  {
    title: 'Plate details',
    key: 'plate-details',
    filters: [
      // hidden orderNumber filter for typeahead
      {
        component: '',
        key: 'orderNumber',
        operator: '$exact',
        value: orderNumber,
        permanent: true,
        hideClear: true,
        hideChip: true,
      },
      {
        type: 'radio',
        key: 'trailer-motorcycle-field',
        value: '',
        options: [
          { value: 'all', label: 'All plates' },
          { value: 'B', label: 'A and B' },
          { value: 'A', label: 'A plates only' },
          { value: 'M', label: 'Motorcycle' },
          { value: 'T', label: 'Trailer' },
        ],
        operator: (filter) => {
          return {
            all: undefined,
            B: '$isNotNull',
            A: '$isNull',
            M: '$endsWith',
            T: '$endsWith',
          }[filter.value];
        },
        field: (filter) =>
          ['B', 'A'].includes(filter.value)
            ? '$bUnicorTag.tagNumber$'
            : 'tagNumber',
      },
    ],
  },
  {
    title: 'Plate status',
    type: 'multiselect',
    key: 'tagstatus',
    field: 'tagstatus',
    operator: '$in',
    value: [],
    options: [
      { value: 'ATTD', label: 'Attached' },
      { value: 'SHIP,SH', label: 'Shipped' },
      { value: 'RECV', label: 'Received' },
      { value: 'MISS', label: 'Missing' },
      { value: 'RETN', label: 'Pending destruction' },
      { value: 'DESV', label: 'Destroyed' }, // DESA is not used
    ],
    getValue: (filter) => flatten(filter.value.map((v) => v.split(','))),
    hideClear: true,
  },
];

export default licensePlateFilterStructure;
