import { useFeatureContext } from '@gsa/afp-shared-ui-utils';
import { useEffect, useState } from 'react';

export const useFeature = () => {
  const [features, setFeatures] = useState({});
  const { featureError, featureData, isFeatureLoading } = useFeatureContext();

  useEffect(() => {
    if (isFeatureLoading || featureError) {
      setFeatures({});
    } else {
      setFeatures(
        featureData.getVMSEnabledFeatures.reduce(
          (result, feat) => ({ ...result, [feat]: true }),
          {},
        ),
      );
    }
  }, [isFeatureLoading, featureError]);

  return features;
};
