import { vehicleFilterKeys } from '../../user-filters/filter-structures/constants';

const {
  FSR_USER_EMAIL,
  TAG_NUMBER,
  POC_EMAIL,
  MAKE_AND_MODEL,
  MAKE_CODE,
  MODEL_CODE,
  MODEL_YEAR,
  VIN_TYPEAHEAD,
  VIN_FILTER,
  AGENCY_AND_BUREAU,
  AGENCY_FILTER,
  BUREAU_FILTER,
  VEHICLE_STATUS,
  REGISTRATION_STATUS,
  OWNERSHIP_TYPE_CODE,
  LIFECYCLE,
  CUSTOMER_ACCOUNT,
  VEHICLE_LOCATION,
  VEHICLE_LIFECYCLE_IND_AND_STATUS,
} = vehicleFilterKeys;

const addSelectOptionsToFilters = (key, options, filters) =>
  filters.map((f) => {
    const filter = { ...f };
    // recursively do nested filters
    if (filter.filters) {
      filter.filters = addSelectOptionsToFilters(key, options, filter.filters);
    }

    if (filter.key === key) {
      filter.options = [filter.options[0], ...options];
    }
    return filter;
  });

export const amendFiltersWithVehicleYearData = (baseFilters, years) => {
  const yearOptions = years
    .map((year) => ({ value: year, label: year }))
    .filter((year) => year.value > 0)
    .sort((a, b) => b.value - a.value);
  return addSelectOptionsToFilters(MODEL_YEAR, yearOptions, baseFilters);
};

export const getFilterWithCustomerNumber = (
  baseFilters,
  customerNumber,
  mode,
) => {
  return baseFilters.map((f) => {
    if (f.key === CUSTOMER_ACCOUNT) {
      // eslint-disable-next-line no-param-reassign
      f.filters[0].value = customerNumber;
      if (mode === 'delete') delete f.filters[0].value;
    }
    return f;
  });
};

export const getUpdatedMakeModels = ({ filter, allUpdates }) => {
  const makeUpdate = allUpdates.find((x) => x.key === MAKE_CODE)?.value || '';
  const modelUpdate = allUpdates.find((x) => x.key === MODEL_CODE)?.value || '';
  const yearUpdate = allUpdates.find((x) => x.key === MODEL_YEAR)?.value || '';
  const oldFilters = filter.filters || [];
  const oldMake = oldFilters.find((x) => x.key === MAKE_CODE);
  const oldModel = oldFilters.find((x) => x.key === MODEL_CODE);
  const oldYear = oldFilters.find((x) => x.key === MODEL_YEAR);
  return {
    ...filter,
    filters: [
      { ...oldYear, value: yearUpdate },
      { ...oldMake, value: makeUpdate },
      { ...oldModel, value: modelUpdate },
    ],
  };
};

export const getUpdatedFieldValue = ({
  filter,
  allUpdates,
  fieldName,
  defaultValue = '',
}) => {
  const updated =
    allUpdates.find((x) => x.key === fieldName)?.value || defaultValue;
  return { ...filter, value: updated };
};

export const getUpdatedVin = ({ filter, allUpdates }) => {
  const vinField = allUpdates.find((x) => x.key === VIN_FILTER)?.value || '';
  const oldFilters = filter.filters || [];
  const oldVinEntry = oldFilters.find((x) => x.key === VIN_FILTER);
  return { ...filter, filters: [{ ...oldVinEntry, value: vinField }] };
};

export const getUpdatedAgencyAndBureau = ({ filter, allUpdates }) => {
  const updatedAgencyValue =
    allUpdates.find((x) => x.key === AGENCY_FILTER)?.value || '';
  const updatedBureauValue =
    allUpdates.find((x) => x.key === BUREAU_FILTER)?.value || '';
  const oldFilters = filter.filters || [];
  const oldAgency = oldFilters.find((x) => x.key === AGENCY_FILTER);
  const oldBureau = oldFilters.find((x) => x.key === BUREAU_FILTER);
  let updatedFilters = [];
  if (oldAgency) {
    updatedFilters = [
      ...updatedFilters,
      { ...oldAgency, value: updatedAgencyValue },
    ];
  }
  if (oldBureau) {
    updatedFilters = [
      ...updatedFilters,
      { ...oldBureau, value: updatedBureauValue },
    ];
  }
  return { ...filter, filters: updatedFilters };
};

export const getUpdatedLifecycleAndStatus = ({ filter, allUpdates }) => {
  const updatedLifecycle =
    allUpdates.find((x) => x.key === LIFECYCLE)?.value || '';
  const updatedStatusValue =
    allUpdates.find((x) => x.key === VEHICLE_STATUS)?.value || '';
  const oldFilters = filter.filters || [];
  const oldLifecycle = oldFilters.find((x) => x.key === LIFECYCLE);
  const oldStatus = oldFilters.find((x) => x.key === VEHICLE_STATUS);
  let updatedFilters = [];
  if (oldLifecycle) {
    updatedFilters = [
      ...updatedFilters,
      { ...oldLifecycle, value: updatedLifecycle },
    ];
  }
  if (oldStatus) {
    updatedFilters = [
      ...updatedFilters,
      { ...oldStatus, value: updatedStatusValue },
    ];
  }
  return { ...filter, filters: updatedFilters };
};

export const getClearedBureauSelection = ({ filter }) => {
  const filters = filter?.filters || [];
  const agency = filters.find((x) => x.key === AGENCY_FILTER);
  const bureau = filters.find((x) => x.key === BUREAU_FILTER);
  return { ...filter, filters: [agency, { ...bureau, value: '' }] };
};

export const clearBureauSelection = ({
  assembledFilters,
  setAssembledFilters,
}) => {
  const updatedFilters = assembledFilters.reduce((accum, filter) => {
    if (filter.key === AGENCY_AND_BUREAU) {
      const updated = getClearedBureauSelection({ filter });
      return [...accum, updated];
    }
    return [...accum, filter];
  }, []);
  setAssembledFilters(updatedFilters);
};

const getClearModelFilters = ({ filter }) => {
  const filters = filter?.filters || [];
  const makeOption = filters.find((x) => x.key === MAKE_CODE);
  const modelOption = filters.find((x) => x.key === MODEL_CODE);
  return { ...filter, filters: [makeOption, { ...modelOption, value: '' }] };
};

export const clearModelSelection = ({
  assembledFilters,
  setAssembledFilters,
}) => {
  const updatedFilters = assembledFilters.reduce((accum, filter) => {
    if (filter.key === MAKE_AND_MODEL) {
      const updated = getClearModelFilters({ filter });
      return [...accum, updated];
    }
    return [...accum, filter];
  }, []);
  setAssembledFilters(updatedFilters);
};

export const processUpdate = ({
  vendorOptions,
  allUpdates,
  filterStructure,
  setAssembledFilters,
}) => {
  const updatedFilters = filterStructure.reduce((accum, filter) => {
    if (!filter.key) {
      return [...accum, filter];
    }
    if (filter.key === MAKE_AND_MODEL) {
      const updated = getUpdatedMakeModels({ filter, allUpdates });
      return [...accum, updated];
    }
    if (filter.key === VIN_TYPEAHEAD) {
      const updated = getUpdatedVin({ filter, allUpdates });
      return [...accum, updated];
    }
    if (filter.key === MODEL_YEAR) {
      const updated = getUpdatedFieldValue({
        filter,
        allUpdates,
        fieldName: MODEL_YEAR,
      });
      return [...accum, updated];
    }
    if (filter.key === TAG_NUMBER) {
      const updated = getUpdatedFieldValue({
        filter,
        allUpdates,
        fieldName: TAG_NUMBER,
      });
      return [...accum, updated];
    }
    if (filter.key === POC_EMAIL) {
      const updated = getUpdatedFieldValue({
        filter,
        allUpdates,
        fieldName: POC_EMAIL,
      });
      return [...accum, updated];
    }
    if (filter.key === FSR_USER_EMAIL) {
      const updated = getUpdatedFieldValue({
        filter,
        allUpdates,
        fieldName: FSR_USER_EMAIL,
      });
      return [...accum, updated];
    }
    if (filter.key === AGENCY_AND_BUREAU) {
      const updated = getUpdatedAgencyAndBureau({ filter, allUpdates });
      return [...accum, updated];
    }

    if (filter.key === VEHICLE_LIFECYCLE_IND_AND_STATUS) {
      const updated = getUpdatedLifecycleAndStatus({ filter, allUpdates });
      return [...accum, updated];
    }

    if (filter.key === LIFECYCLE) {
      const updated = getUpdatedFieldValue({
        filter,
        allUpdates,
        fieldName: LIFECYCLE,
      });
      return [...accum, updated];
    }

    if (filter.key === BUREAU_FILTER) {
      const updated = getUpdatedFieldValue({
        filter,
        allUpdates,
        fieldName: BUREAU_FILTER,
      });
      return [...accum, updated];
    }
    if (filter.key === VEHICLE_STATUS) {
      const updated = getUpdatedFieldValue({
        filter,
        allUpdates,
        fieldName: VEHICLE_STATUS,
      });
      return [...accum, updated];
    }

    if (filter.key === VEHICLE_LOCATION) {
      const updated = getUpdatedFieldValue({
        filter,
        allUpdates,
        fieldName: VEHICLE_LOCATION,
      });
      return [...accum, updated];
    }

    if (filter.key === REGISTRATION_STATUS) {
      const updated = getUpdatedFieldValue({
        filter,
        allUpdates,
        fieldName: REGISTRATION_STATUS,
        defaultValue: [],
      });
      return [...accum, updated];
    }
    if (filter.key === OWNERSHIP_TYPE_CODE) {
      const updated = getUpdatedFieldValue({
        filter,
        allUpdates,
        fieldName: OWNERSHIP_TYPE_CODE,
        defaultValue: [],
      });
      return [...accum, updated];
    }
    return [...accum, filter];
  }, []);
  setAssembledFilters(updatedFilters);
};
