import React from 'react';
import { Alert } from '@gsa/afp-component-library';
import { useRecallPage } from '../providers';

const BannerMessage = () => {
  const { bannerMsg, setBannerMsg } = useRecallPage();

  if (!bannerMsg) return null;

  if (bannerMsg.alert) return bannerMsg.alert;

  return (
    <Alert
      type={bannerMsg.type}
      focused={bannerMsg.focused || true}
      heading={bannerMsg.heading}
      onClose={() => setBannerMsg(null)}
    >
      {bannerMsg.message}
    </Alert>
  );
};

export default BannerMessage;
