/* eslint-disable react/prop-types */
import React from 'react';
import { SelectDropdown } from '@gsa/afp-component-library';
import { nullForForm } from 'utilities/common';
import { DEFAULT_SELECT_OPTION_LABEL, VEHICLE_TYPE } from 'utilities/consts';
import { strsToOptions } from 'utilities/format';

export const VEHICLE_TYPE_OPTIONS = [
  {
    value: '',
    label: DEFAULT_SELECT_OPTION_LABEL,
    disabled: true,
    defaultValue: true,
  },
  ...strsToOptions({ strs: VEHICLE_TYPE, defaultVal: 'NO-DEFAULT' }),
];

export default function SelectVehicleType({
  itemType,
  setFormState,
  errorMessage = '',
}) {
  const isDisabled = () => false;

  return (
    <SelectDropdown
      id="vehicleType"
      label="Vehicle group"
      value={nullForForm(itemType)}
      required
      name="vehicleType"
      options={VEHICLE_TYPE_OPTIONS}
      onChange={(e) => {
        setFormState({ itemType: e.target.value });
      }}
      disabled={isDisabled()}
      errorMessage={errorMessage}
    />
  );
}
