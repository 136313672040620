/* eslint-disable react/prop-types */
import { Button, RequiredFieldIndicator } from '@gsa/afp-component-library';
import React from 'react';
import { z } from 'zod';
import { FormGenerator } from '@gsa/afp-shared-form-utils';
import { emdash } from 'components/common';

export default function CustomerEdit({
  agencyCodeName,
  bureauCodeName,
  officeCodeName,
  onClose,
  vehicle,
  onSave,
  isInternal,
  CustomerInformationModal,
  hideCustomerInfo,
}) {
  const schema = z.object({
    fundCode: z.string().max(2, 'Should be two characters').nullish(),
    account1: z.string(),
    account2: z.string(),
  });

  const data = {
    ownerShip: 'GSA leased vehicle',
    agency: agencyCodeName,
    bureau: bureauCodeName,
    office: officeCodeName,
    legacyCustomerNumber: !hideCustomerInfo
      ? vehicle?.customer?.legacyCustomerNumber
      : emdash,
    accountName: !hideCustomerInfo ? vehicle?.customer?.accountName : emdash,
    accountNumber: !hideCustomerInfo ? vehicle?.customer?.customerId : emdash,
    boac: !hideCustomerInfo ? vehicle?.customer?.boac : emdash,
    zone: vehicle?.fleetManagementCenter?.zoneId ?? '',
    fmc: vehicle?.fleetManagementCenter?.name ?? '',
    fundCode: vehicle?.ald?.fundCode ?? '',
    account1: vehicle?.ald?.account1 ?? '',
    account2: vehicle?.ald?.account2 ?? '',
  };

  const sectionParts = isInternal
    ? [
        {
          fieldLayout: 'horizontal',
          gap: '32px',
          fields: [
            {
              id: 'zone',
              label: 'Zone',
              type: 'text',
              disabled: true,
            },
            {
              id: 'fmc',
              label: 'FMC',
              type: 'text',
              disabled: true,
            },
          ],
        },
        {
          fieldLayout: 'horizontal',
          gap: '32px',
          fields: [
            {
              id: 'fundCode',
              label: 'Fund Code',
              type: 'text',
              element: { control: { maxLength: 2, countdown: true } },
            },
            {
              id: 'account1',
              label: 'Description 1',
              type: 'text',
              element: { control: { maxLength: 8, countdown: true } },
            },
          ],
        },
        {
          fieldLayout: 'horizontal',
          gap: '32px',
          fields: [
            {
              id: 'account2',
              label: 'Description 2',
              type: 'text',
              element: { control: { maxLength: 8, countdown: true } },
            },
            {
              type: 'spacer',
            },
          ],
        },
      ]
    : [
        {
          fieldLayout: 'horizontal',
          gap: '32px',
          fields: [
            {
              id: 'fundCode',
              label: 'Fund Code',
              type: 'text',
              element: { control: { maxLength: 2, countdown: true } },
            },
            {
              id: 'account1',
              label: 'Description 1',
              type: 'text',
              element: { control: { maxLength: 8, countdown: true } },
            },
          ],
        },
        {
          fieldLayout: 'horizontal',
          gap: '32px',
          fields: [
            {
              id: 'account2',
              label: 'Description 2',
              type: 'text',
              element: { control: { maxLength: 8, countdown: true } },
            },
            {
              type: 'spacer',
            },
          ],
        },
      ];

  const content = {
    buttonControls: {
      submit: false,
      cancel: false,
    },
    sections: [
      {
        fieldLayout: 'horizontal',
        gap: '32px',
        fields: [
          {
            id: 'ownerShip',
            label: 'Ownership',
            type: 'text',
            disabled: true,
          },
          {
            id: 'agency',
            label: 'Agency',
            type: 'text',
            element: { control: { rows: 2, style: { height: 'auto' } } },
            disabled: true,
          },
        ],
      },
      {
        fieldLayout: 'horizontal',
        gap: '32px',
        fields: [
          {
            id: 'bureau',
            label: 'Bureau',
            type: 'text',
            element: { control: { rows: 2, style: { height: 'auto' } } },
            disabled: true,
          },
          {
            id: 'office',
            label: 'Office',
            type: 'text',
            element: { control: { rows: 2, style: { height: 'auto' } } },
            disabled: true,
          },
        ],
      },
      {
        fieldLayout: 'horizontal',
        gap: '32px',
        fields: [
          {
            id: 'legacyCustomerNumber',
            label: 'Legacy customer number',
            type: 'text',
            disabled: true,
          },
          {
            id: 'accountName',
            label: 'Account name',
            type: 'text',
            disabled: true,
          },
        ],
      },
      {
        fieldLayout: 'horizontal',
        gap: '32px',
        fields: [
          {
            id: 'accountNumber',
            label: 'Account number',
            type: 'text',
            disabled: true,
          },
          {
            fields: [
              {
                id: 'BOAC',
                label: 'BOAC',
                type: 'text',
                disabled: true,
              },
            ],
          },
        ],
      },

      ...sectionParts,
    ],
  };

  const handleSubmit = (values) => {
    onSave(values.fundCode, values.account1, values.account2);
  };

  return (
    <CustomerInformationModal
      title={<h2>Edit customer information</h2>}
      onClose={onClose}
      actions={
        <>
          <Button variant="unstyled" onClick={onClose} label="Close" />
          <Button
            className="margin-left-2"
            type="submit"
            form="customer-edit-form"
            label="Save and close"
          />
        </>
      }
    >
      <p>
        Edit customer information for VIN{' '}
        <span className="text-bold">{vehicle.id}</span> in the form below.
      </p>
      <p>
        Required fields are marked with an asterisk (
        <RequiredFieldIndicator />
        ).
      </p>
      <FormGenerator
        id="customer-edit-form"
        schema={schema}
        content={content}
        defaultValues={data}
        onSubmit={handleSubmit}
      />
    </CustomerInformationModal>
  );
}
