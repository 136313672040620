import { unknownField } from './unknown-field';

export const newCarTitleConstructor = (
  modelYear,
  makeName,
  modelName,
  modelCode,
  modelDescription,
) => {
  let title = '';
  if (modelYear && modelYear !== -1) {
    title += `${modelYear} `;
  }
  if (makeName && makeName !== unknownField) {
    title += `${makeName} `;
  }
  if (modelDescription && modelDescription !== unknownField) {
    title += modelDescription;
  } else if (modelName && modelName !== unknownField) {
    title += modelName;
  } else if (
    modelCode &&
    modelCode.toUpperCase() !== unknownField &&
    modelCode !== '-1'
  ) {
    title += modelCode;
  }
  if (!title) return '-';
  return title;
};


export const carTitleConstructor = (
  modelYear,
  vehicleMake,
  vehicleModel,
  modelCode,
) => {
  let title = '';
  if (modelYear && modelYear !== -1) {
    title += `${modelYear} `;
  }
  if (vehicleMake?.makeName && vehicleMake?.makeName !== unknownField) {
    title += `${vehicleMake?.makeName} `;
  }
  if (
    vehicleModel?.modelDescription &&
    vehicleModel?.modelDescription !== unknownField
  ) {
    title += vehicleModel?.modelDescription;
  } else if (
    vehicleModel?.modelName &&
    vehicleModel?.modelName !== unknownField
  ) {
    title += vehicleModel?.modelName;
  } else if (
    modelCode &&
    modelCode.toUpperCase() !== unknownField &&
    modelCode !== '-1'
  ) {
    title += modelCode;
  }
  if (!title) return '-';
  return title;
};