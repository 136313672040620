/* eslint-disable react/prop-types */
// eslint-disable-next-line filenames/match-exported
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import EditFormDetailFields from './edit-office-detail-fields';

const OfficeEditDetailForm = ({ providerContext }) => {
  const {
    selectedOrganization,
    editOfficeDetail,
    backEndFieldValidationError,
  } = providerContext;
  const { officeName, agencyCode, bureauCode, officeCode } =
    selectedOrganization;

  const getValidateSchema = () =>
    yup.object().shape({
      officeName: yup.string().required().label('This'),
    });

  const methods = useForm({
    resolver: yupResolver(getValidateSchema()),
    defaultValues: { officeName },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });
  //  set up backend validation here
  const { setError } = methods;

  // this is a necessary side effect because the error message is depend on the query result and setError is not a global method
  useEffect(() => {
    const { fieldName, message } = backEndFieldValidationError;
    if (fieldName && message) {
      setError(fieldName, {
        type: 'custom',
        message,
      });
    }
  }, [backEndFieldValidationError]);

  const onSubmit = (values) => {
    editOfficeDetail({
      variables: {
        officeName: values.officeName,
        agencyCode,
        bureauCode,
        officeCode,
      },
    });
  };

  return (
    <FormProvider {...methods}>
      <form
        data-testid="office-edit-detail-form"
        id="office-edit-detail-form"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <EditFormDetailFields selectedOrganization={selectedOrganization} />
      </form>
    </FormProvider>
  );
};

export default OfficeEditDetailForm;
