import NumberFormat from 'react-number-format';

import React from 'react';

const getClassName = (resolvedProps) => {
  if (resolvedProps?.attachments?.after) {
    return 'miles-input';
  }
  return ' ';
};
const FormattedNumberControl = (_, config, useFormContext) => {
  const {
    setValue,
    getValues,
    register,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  const resolvedProps = {
    isDollarFormat: false,
    isMileFormat: false,
    thousandsGroupStyle: 'thousand',
    isFixedDecimalScale: true,
    decimalScale: 2,
    allowNegative: false,
    attachments: {},
    ...(config?.element?.control ?? {}),
  };

  config.id && register(config.id);

  const hasError = !!(errors && errors[config.id]);
  return (
    <div className={getClassName(resolvedProps)}>
      {resolvedProps?.attachments.after && (
        <span className="prefix-icon-miles">
          {resolvedProps.attachments.after}
        </span>
      )}

      <NumberFormat
        className={`usa-input ${hasError ? 'usa-input--error' : null}`}
        id={config.id}
        decimalSeparator="."
        displayType="input"
        type="text"
        thousandSeparator
        aria-invalid={hasError ? 'true' : 'false'}
        value={getValues(config.id)}
        onValueChange={(val) => {
          hasError && clearErrors(config.id);
          setValue(config.id, val.value);
        }}
        {...resolvedProps}
      />
    </div>
  );
};

export default FormattedNumberControl;
