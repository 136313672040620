import { FG_DEFAULT_OPTION_VAL } from 'utilities/consts';
import { getOrDefaultVal } from 'utilities/format';

export const getOptionsWithDefault = (options = []) => {
  return options.length === 0
    ? [
        {
          value: FG_DEFAULT_OPTION_VAL,
          label: '- Select - ',
        },
      ]
    : [
        {
          value: FG_DEFAULT_OPTION_VAL,
          label: '- Select - ',
        },
        ...options,
      ];
};

export const yesOrNoOptions = getOptionsWithDefault([
  {
    value: 'true',
    label: 'Yes',
  },
  {
    value: 'false',
    label: 'No',
  },
]);

export const boolFieldToYesOrNo = ({ data, field }) =>
  getOrDefaultVal({
    dataObject: data,
    dataKey: field,
    defaultVal: FG_DEFAULT_OPTION_VAL,
  }).toString();

export const filterSubmitObj = ({ formData, fields }) => {
  return fields.reduce((acc, cur) => {
    if (formData[cur] && formData[cur] !== FG_DEFAULT_OPTION_VAL) {
      acc[cur] = formData[cur].toString();
    }
    return acc;
  }, {});
};

export const nullifiedSchemaObject = (objectSchema) => {
  const schemaObjectWithNullish = {};
  for (const key in objectSchema) {
    schemaObjectWithNullish[key] = objectSchema[key].nullish();
  }
  return schemaObjectWithNullish;
};
