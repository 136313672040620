import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Button, DetailsTable, Spinner } from '@gsa/afp-component-library';
import SectionBlock from './section-block';
import { useVehicle } from '../../../vehicle-context-provider';
import { UPDATE_VEHICLE_STATUS } from '../../../../../services/data-layer';
import { LifeCycleIndicatorTag } from 'components/vehicle-details/widgets/life-cycle';
import useUser from '../../../../../utilities/use-user';
import useCanPerformActions from '../../../../../hooks/use-can-perform-actions';
import VehicleStatusEdit from './vehicle-status-edit';

const VehicleStatus = ({ data }) => {
  const [editVehicleStatus, setEditVehicleStatus] = useState(false);
  const { setSectionMsg, refetchVehicle } = useVehicle();

  const { isRole } = useUser();
  const hasFMVRSrole = isRole('FMVRSAdminRole');
  const hasCustFltManRole = isRole('CustFltMan');
  const canPerformActions = useCanPerformActions();
  const isAoVehicle = canPerformActions.isAoAndAbleToRegister(data);
  const isSold = data?.itemInventoryStatusCode === 'SD';
  const isVehicleEditable = !isSold && isAoVehicle;
  const canEditSoldVehicle = (hasFMVRSrole || hasCustFltManRole) && isSold;

  const [updateVehicleStatus, { loading }] = useMutation(
    UPDATE_VEHICLE_STATUS,
    {
      fetchPolicy: 'no-cache',
      onCompleted: () => {
        refetchVehicle();
        setSectionMsg({
          type: 'success',
          message: 'Vehicle was successfully updated.',
        });
      },
      onError: ({ graphQLErrors }) => {
        const gqErrors = graphQLErrors.map((error) => {
          if (error?.extensions?.code.includes('Petroleum-Dedicated')) {
            return `${error?.message}: ${error?.extensions?.code}`;
          } else if (error?.extensions?.code.includes('vehicle Acquisition')) {
            return `${error?.extensions?.code}`;
          } else {
            return `${error?.message}`;
          }
        });
        setSectionMsg({
          type: 'error',
          message: `Error occurred while updating vehicle status: ${gqErrors.join(
            ' ',
          )}`,
        });
      },
    },
  );

  const saveVehicleChanges = (
    status,
    id,
    statusOptionType,
    statusOptionalParams,
  ) => {
    if (statusOptionalParams && statusOptionType) {
      updateVehicleStatus({
        variables: {
          id,
          status,
          statusOptionType,
          statusOptionalParams,
        },
      });
    } else {
      updateVehicleStatus({
        variables: {
          id,
          status,
        },
      });
    }

    setEditVehicleStatus(false);
  };

  return (
    <>
      <SectionBlock title="Vehicle status">
        <DetailsTable
          className="vehicle-overview-details-table"
          data={[
            [
              'Vehicle status',
              data?.assetLifecycle?.[0]?.lifeCycle?.lifecycleIndicator ? (
                <LifeCycleIndicatorTag
                  value={
                    data?.assetLifecycle?.[0]?.lifeCycle?.lifecycleIndicator
                  }
                />
              ) : (
                ''
              ),
            ],
          ]}
        />
        <div className="margin-top-2">
          {isVehicleEditable && (
            <Button
              data-testid="vehicle-status-edit-button-1"
              variant="outline"
              label="Edit"
              onClick={() => setEditVehicleStatus(true)}
            />
          )}
          {isAoVehicle && canEditSoldVehicle && (
            <Button
              data-testid="vehicle-status-edit-button-2"
              variant="outline"
              label="Edit"
              onClick={() => setEditVehicleStatus(true)}
            />
          )}
          {editVehicleStatus && (
            <VehicleStatusEdit
              onSave={saveVehicleChanges}
              onClose={() => setEditVehicleStatus(false)}
              vehicle={data}
            />
          )}
        </div>
      </SectionBlock>
      {loading && (
        <Spinner aria-busy="true" className="loading_backdrop" size="large" />
      )}
    </>
  );
};

export default VehicleStatus;
