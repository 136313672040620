import {
  FilterTableFrame,
} from '@gsa/afp-component-library';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import React, { useMemo } from 'react';
import { VMSOperations, VMSSubjects } from 'utilities/consts';
import FilterUpRightActions from 'utilities/filter-up-right-actions';
import MotorPoolListingTable from './motor-pool-listing-table';
import MotorPoolSidebar from './motor-pool-sidebar';
import { useMotorPool } from './motor-pool-provider';

const MotorPoolListing = () => {
  const ability = useAppAbility();
  const { dispatchAction, motorPoolIdsSelected } = useMotorPool();

  const canCreatePool = ability.can(
    VMSOperations.Create,
    VMSSubjects.MOTOR_POOL,
  );

  const currentModalName = 'MOTOR_POOL_LISTING_MODAL';

  const FTF = useMemo(
    () =>
      FilterTableFrame(
        null,
        FilterUpRightActions,
        MotorPoolSidebar,
        MotorPoolListingTable,
      ),
    [],
  );

  return (
    <>
      <FTF
        upRightProps={{
          pageName: 'motor-pool',
          DropDownItems: [
            {
              label: 'Create motor pool',
              iconName: 'add',
              variant: motorPoolIdsSelected.length === 0 ? 'outline' : undefined,
              onClick: () => {
                dispatchAction('SET_SHOW_MOTOR_POOL_MODAL', true);
                dispatchAction(
                  'SET_MOTOR_POOL_MODAL_MODE',
                  'ADD_MOTOR_POOL_LISTING',
                );
              },
              hiddenItem: !canCreatePool,
            },
            {
              label: 'Export reservation history report data',
              iconName: 'file_download',
              onClick: () => {
                dispatchAction('SET_MODAL', {
                  currentModalName,
                  currentMode: 'RESERVATION_HISTORY_REPORT',
                });
              },
              hiddenItem: !canCreatePool || motorPoolIdsSelected.length === 0,
            },
            {
              label: 'Export motor pool utilization report data',
              iconName: 'file_download',
              onClick: () => {
                dispatchAction('SET_MODAL', {
                  currentModalName,
                  currentMode: 'MOTOR_POOL_UTILIZATION_REPORT',
                });
              },
              'data-testid': 'export-utilization-button',
              hiddenItem: !canCreatePool || motorPoolIdsSelected.length === 0,
            },
          ],
        }}
        filterToggle
      />
    </>
  );

};

export default MotorPoolListing;
