export default function licensePlateQueryFilters(value) {
  /*
   * for vehicle registration do not bring in plates starting with G followed by number
   * only leased tags starts with G and a number which needs to be excluded
   * some agencies have plates starting with GP, GAO, GSA, etc.
   * */
  return {
    operator: 'AND',
    value: [
      { operator: 'STARTWITH', key: 'id', value },
      {
        operator: 'REGEXP',
        key: 'id',
        value: '^(?!G\\d)',
      },
    ],
  };
}
