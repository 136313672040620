import React, { useEffect, useState, useRef } from 'react';
import { Spinner } from '@gsa/afp-component-library';
import PurchaseOrderNumberModal from 'utilities/modal-utils';
import modalSchema from './schema/modal';
import Header from './header';
import RegistrationContextProvider from './reg-context-provider';
import useRegistrationManager from './helpers/registration-manager';
import RegistrationForms from './registration-forms';
import FormStepButtons from './widgets/form-step-buttons';
import Alert from './alert';
import DetailsRedirect from './widgets/details-redirect';
import CancelRegistration from './widgets/cancel-registration';

import './veh-reg-styles.css';
import './vin-validation.css';

export default function VehicleRegistration() {
  const [regSubmissionSpinner, setRegSubmissionSpinner] = useState(false);
  const registrationContext = useRegistrationManager();
  const {
    fetchedVehicle,
    verifyVin,
    isCurrentlyRegistered,
    verifyingVin,
    UnavailableModal,
    showUnavailable,
    step,
    prevStep,
    nextStep,
    setStep,
    validateStep,
    validations,
    getFieldValidation,
    registrationType,
    formState,
    setFormState,
    errors,
    addError,
    clearError,
    fuelTypes,
    vinSource,
    UserVerifyModal,
    openUserVerifyModal,
    setBodyTextType,
    setVinVerification,
    validatePocAddress,
    AddressConfirmModal,
    updatePoc,
    registerVehicle,
    submittingRegistration,
    submitRegistrationError,
    complete,
    pdfComplete,
    pdfUrl,
    setRegistrationError,
    registrationError,
    accessError,
    setAccessError,
    isActiveStatus,
    isRegistered,
    getPurchaseOrderNumber,
    purchaseOrderNumber,
    setPurchaseOrderNumber,
    modalState,
    setModalState,
    isRequirePurchaseOrderNumber,
    setIsRequirePurchaseOrderNumber,
    resetCurrentModal,
    verifyPurchaseOrderNumber,
    isValidOrderNumber,
    setIsValidOrderNumber,
    resetFirstStepState,
    resetForm,
  } = registrationContext;

  useEffect(() => {
    if (submitRegistrationError) setRegSubmissionSpinner(false);
    else if (submittingRegistration) setRegSubmissionSpinner(true);
  }, [submittingRegistration, submitRegistrationError]);

  const breadcrumbRef = useRef(null);

  const onPrev = () => {
    breadcrumbRef?.current?.focus();
    prevStep();
  };

  const onNext = () => {
    breadcrumbRef?.current?.focus();
    nextStep();
  };

  return (
    <div data-testid="vehicle-registartion">
      {regSubmissionSpinner && (
        <Spinner aria-busy="true" className="loading_backdrop" size="large" />
      )}
      <DetailsRedirect
        complete={complete}
        pdfComplete={pdfComplete}
        vin={formState.vin}
        pdfUrl={pdfUrl}
        isCurrentlyRegistered={isCurrentlyRegistered}
        registrationType={registrationType}
      />
      <UnavailableModal />
      <UserVerifyModal
        registrationType={registrationType}
        getPurchaseOrderNumber={getPurchaseOrderNumber}
      />
      <AddressConfirmModal />

      <RegistrationContextProvider
        fetchedVehicle={fetchedVehicle}
        verifyVin={verifyVin}
        isCurrentlyRegistered={isCurrentlyRegistered}
        verifyingVin={verifyingVin}
        registrationType={registrationType}
        step={step}
        prevStep={onPrev}
        nextStep={onNext}
        setStep={setStep}
        validations={validations}
        formState={formState}
        setFormState={setFormState}
        errors={errors}
        addError={addError}
        clearError={clearError}
        fuelTypes={fuelTypes}
        vinSource={vinSource}
        validateStep={validateStep}
        updatePoc={updatePoc}
        getFieldValidation={getFieldValidation}
        validatePocAddress={validatePocAddress}
        registerVehicle={registerVehicle}
        setRegistrationError={setRegistrationError}
        registrationError={registrationError}
        setAccessError={setAccessError}
        isActiveStatus={isActiveStatus}
        isRegistered={isRegistered}
        openUserVerifyModal={openUserVerifyModal}
        setBodyTextType={setBodyTextType}
        setVinVerification={setVinVerification}
        showUnavailable={showUnavailable}
        getPurchaseOrderNumber={getPurchaseOrderNumber}
        purchaseOrderNumber={purchaseOrderNumber}
        setPurchaseOrderNumber={setPurchaseOrderNumber}
        modalState={modalState}
        setModalState={setModalState}
        isRequirePurchaseOrderNumber={isRequirePurchaseOrderNumber}
        setIsRequirePurchaseOrderNumber={setIsRequirePurchaseOrderNumber}
        resetCurrentModal={resetCurrentModal}
        verifyPurchaseOrderNumber={verifyPurchaseOrderNumber}
        isValidOrderNumber={isValidOrderNumber}
        setIsValidOrderNumber={setIsValidOrderNumber}
        resetFirstStepState={resetFirstStepState}
        resetForm={resetForm}
      >
        <Header breadcrumbRef={breadcrumbRef} />
        <Alert />
        <RegistrationForms />
        <FormStepButtons accessError={accessError} />
        <CancelRegistration />
        <PurchaseOrderNumberModal
          schema={modalSchema}
          context={registrationContext}
        />
      </RegistrationContextProvider>
    </div>
  );
}
