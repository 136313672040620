// eslint-disable-next-line filenames/match-exported
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export default function OrderDetailLink({ label, value, orderNumber }) {
  return (
    <div className="detail-row display-flex flex-justify padding-y-1 border-bottom border-width-2px border-base-lighter">
      <div className="detail-title">
        <strong>{label}</strong>
      </div>
      <div className="detail-value">
        <Link to={`/order/${encodeURIComponent(orderNumber)}`}>{value}</Link>
      </div>
    </div>
  );
}

OrderDetailLink.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.number,
  ]),
  orderNumber: PropTypes.string,
};

OrderDetailLink.defaultProps = {
  value: '',
  orderNumber: '',
};
