import React from 'react';
import RepairOrderStatus from './order-status';
import { PageMode, useRepairOrder } from '../providers';
import { displayModel, emdash } from 'components/common';

const RepairOrderTitle = () => {
  const {
    repairOrder,
    transactionDetails,
    vehicle = {},
    mode,
  } = useRepairOrder();

  const isEditing = () => mode !== PageMode.View;
  const isCreating = () => mode === PageMode.New;

  if ((isEditing && !isCreating && !repairOrder) || (isCreating && !vehicle)) {
    return null;
  }
  const orderNumber = repairOrder?.repairOrderNumber;

  const title = () => {
    return orderNumber ? `Repair Order - ${orderNumber}` : 'New Repair Order';
  };
  const modelYear = () => {
    return !vehicle?.modelYear ? 'UNKNOWN' : vehicle?.modelYear;
  };
  const vehicleMake = () => {
    return vehicle?.vehicleMake?.makeName || emdash;
  };
  const vehicleModel = () => {
    return displayModel(vehicle) || emdash;
  };
  const vehicleMakeModel = () => {
    return `${modelYear()} ${vehicleMake()} ${vehicleModel()}`;
  };
  const tagNumber = () => {
    return vehicle?.tagNumber || '';
  };

  return (
    <>
      <div className="display-flex flex-align-center">
        <h1 data-testid="repair-header-title" className="margin-right-3">
          {title()}
        </h1>
        <RepairOrderStatus status={transactionDetails?.transactionCompleted} />
      </div>
      <h2
        data-testid="repair-header-vehicle-model"
        className="title-m margin-top-neg-3"
      >
        {vehicleMakeModel()}
      </h2>
      {vehicle?.tagNumber && (
        <h3
          data-testid="repair-header-vehicle-tag"
          className="title-m margin-top-neg-2"
        >
          {tagNumber()}
        </h3>
      )}
    </>
  );
};

export default RepairOrderTitle;
