// eslint-disable-next-line filenames/match-exported
import { itemInventoryStatuses } from '../../../utilities/consts';

export default function getVehicleRegistrationFilter(vehicleStatus) {
  const getKeys = (status) => {
    return Object.keys(itemInventoryStatuses).reduce((acc, key) => {
      if (
        itemInventoryStatuses[key].altStatus === status ||
        (!itemInventoryStatuses[key].altStatus &&
          itemInventoryStatuses[key].displayStatus === status)
      ) {
        acc.push(key);
      }

      return acc;
    }, []);
  };

  const filterVals = getKeys(vehicleStatus);

  if (vehicleStatus && filterVals?.length)
    return [
      {
        operator: 'AND',
        value: [
          {
            operator: 'IN',
            key: 'itemInventoryStatusCode',
            value: filterVals,
          },
        ],
      },
    ];

  return null;
}
