import React from 'react';
import { DatePicker, RequiredFieldIndicator } from '@gsa/afp-component-library';
import moment from 'moment';
import { useRegistration } from '../reg-context-provider';

// eslint-disable-next-line react/prop-types
export default function ReceivedDate({ value }) {
  const { setFormState, getFieldValidation } = useRegistration();
  return (
    <DatePicker
      key={`received-date-${value}`}
      label={
        <span>
          Vehicle received date <RequiredFieldIndicator />
        </span>
      }
      data-testid="originalAcquisitionDate"
      defaultValue={value ? moment.utc(value).format('YYYY-MM-DD') : ''}
      required
      maxDate={moment(new Date()).format('YYYY-MM-DD')}
      onChange={(newValue) => {
        setFormState({
          assetAcquisition: {
            originalAcquisitionDate: moment(newValue).format('YYYY-MM-DD'),
          },
          originalAcquisitionDate: moment(newValue).format('YYYY-MM-DD'),
        });
      }}
      errorMessage={getFieldValidation('originalAcquisitionDate')}
    />
  );
}
