// eslint-disable-next-line filenames/match-exported
import React, { useState, useEffect } from 'react';
import { Typeahead } from '@gsa/afp-component-library';
import { useLazyQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { FETCH_TYPEAHEAD } from '../../services/data-layer';

const getTypeaheadQueryVariables = (value) => ({
  field: 'id',
  order: 'id ASC',
  model: 'Vehicle',
  filters: [
    {
      operator: '$and',
      conditions: [{ operator: '$startsWith', key: 'id', value }],
    },
  ],
});

export default function VinLookup({ vin = '', ariaLabel }) {
  const [getTypeaheads, { data: typeaheadData }] = useLazyQuery(
    FETCH_TYPEAHEAD,
    {
      fetchPolicy: 'no-cache',
    },
  );
  const [vinList, setvinList] = useState([]);
  const history = useHistory();
  useEffect(() => {
    if (
      typeaheadData &&
      typeaheadData.getTypeaheads &&
      typeaheadData.getTypeaheads.typeaheads
    ) {
      setvinList(typeaheadData.getTypeaheads.typeaheads.values || []);
    }
  }, [typeaheadData]);

  return (
    <div className="afp-vehicle-registration-wrapper">
      <Typeahead
        ariaLabel={ariaLabel}
        label="Search vehicles by VIN number"
        labelClass="text-bold"
        accessor="vin"
        placeholder="Search VIN Numbers..."
        filterValue={vin || ''}
        onOptionEnter={(value) => {
          if (value) history.push(`/vehicles/${encodeURIComponent(value)}`);
        }}
        typeaheadValues={vinList}
        fetchTypeaheadValues={(_accessor, value) => {
          getTypeaheads({ variables: getTypeaheadQueryVariables(value) });
        }}
        inputCharNum={3}
        debounceDelay={500}
        inputClass="text-uppercase"
      />
    </div>
  );
}
