import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useCurrentUser } from '@gsa/afp-shared-ui-utils';
import { Comments, Spinner } from '@gsa/afp-component-library';
import {
  CREATE_VEHICLE_COMMENT,
  GET_VEHICLE_COMMENTS_PLUS,
} from '../../services/data-layer/vehicle';
import { DELETE_VEHICLE_COMMENT } from '../../services/data-layer';
import { useRegistrationDetail } from './registration-detail-context-provider';

const RegistrationDetailComments = () => {
  const {
    registrationDetailVehicle,
    refetchVehicle,
    registrationDetailComments,
    setRegistrationDetailComments,
  } = useRegistrationDetail();

  const { currentUser } = useCurrentUser();

  const [showAllComments, setShowAllComments] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState({});

  const {
    loading: loadingComments,
    data: commentsData,
    refetch: refetchComments,
  } = useQuery(GET_VEHICLE_COMMENTS_PLUS, {
    variables: {
      id: registrationDetailVehicle.id,
      order: [['createdAt', 'DESC']],
      offset: 0,
      limit: showAllComments ? registrationDetailComments.count : 5,
    },
    fetchPolicy: 'no-cache',
  });

  const [createComment, { loading: submittingComment }] = useMutation(
    CREATE_VEHICLE_COMMENT,
    {
      onError: (error) => {
        setSubmissionStatus({
          action: 'add',
          status: 'fail',
          message: `An unexpected error has occurred: ${error.message}`,
        });
      },
      onCompleted: () => {
        refetchComments();
        refetchVehicle();
        setSubmissionStatus({
          action: 'add',
          status: 'success',
          message: '',
        });
      },
    },
  );

  const [deleteComment, { loading: deletingComment }] = useMutation(
    DELETE_VEHICLE_COMMENT,
    {
      onError: (error) => {
        setSubmissionStatus({
          action: 'delete',
          status: 'fail',
          message: `${error.message}`,
        });
      },
      onCompleted: (deleteRespose) => {
        if (deleteRespose && deleteRespose.deleteVehicleComment) {
          refetchComments();
          refetchVehicle();
          setSubmissionStatus({
            action: 'delete',
            status: 'success',
            message: '',
          });
        }
      },
    },
  );

  useEffect(() => {
    if (commentsData) {
      setRegistrationDetailComments(commentsData.getVehicleCommentPlus);
    }
  }, [commentsData]);

  const submitComment = (comment) => {
    const contextId = registrationDetailVehicle.id;
    createComment({
      variables: {
        id: contextId,
        comment,
      },
    });
  };

  const removeComment = (id) => {
    deleteComment({
      variables: { id, vin: registrationDetailVehicle?.id },
    });
  };

  return (
    <>
      <Comments
        comments={registrationDetailComments}
        showAllComments={(value) => setShowAllComments(value)}
        loadingComments={loadingComments}
        submitComment={(comment) => submitComment(comment)}
        submittingComment={submittingComment}
        deleteComment={(commentId) => removeComment(commentId)}
        deletingComment={deletingComment}
        submissionStatus={submissionStatus}
        currentUser={currentUser.id}
      />
      {submittingComment && (
        <Spinner aria-busy="true" className="loading_backdrop" size="large" />
      )}
    </>
  );
};

export default RegistrationDetailComments;
