import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import {
  RadioButton,
  RequiredFieldIndicator,
  SelectDropdown,
} from '@gsa/afp-component-library';
import { fiscalYear } from 'utilities/common';
import { useExport } from '../../export-provider';

const currentYear = fiscalYear();

export const yearOption = [
  { value: currentYear - 1, label: `${currentYear - 1}` },
  { value: currentYear - 2, label: `${currentYear - 2}` },
  { value: currentYear - 3, label: `${currentYear - 3}` },
];

// eslint-disable-next-line react/prop-types
export const CustomInventoryDateFilters = ({ control, setValue }) => {
  const { isScheduled } = useExport();
  const options = [
    {
      label: 'Current year',
      radioValue: 'current',
    },
    ...(isScheduled === 'no'
      ? [
          {
            label: 'Previous year',
            description: 'Please select the year below',
            radioValue: 'prev',
          },
        ]
      : []),
  ];
  const [dateRangeOption, setDateRangeOption] = useState(options[0].radioValue);

  return (
    <section className="margin-bottom-6">
      <h2 className="text-primary margin-bottom-1">Date selection</h2>
      <div className="usa-form-group mobile-lg:grid-col-10">
        <span>
          {<b>Choose a date range for the reports</b>}{' '}
          <RequiredFieldIndicator />
        </span>
        <Controller
          control={control}
          name="date_range"
          defaultValue={options[0].radioValue}
          rules={{ required: 'This is a required field' }}
          render={({ field: { onChange, name, value } }) => (
            <>
              {options.map(({ radioValue, label, ...restProps }) => {
                return (
                  <RadioButton
                    name={name}
                    key={radioValue}
                    value={radioValue}
                    checked={radioValue === value}
                    label={<b>{label}</b>}
                    variant="tile"
                    {...restProps}
                    onChange={(e) => {
                      onChange(e.target.value);
                      setDateRangeOption(e.target.value);
                    }}
                  />
                );
              })}
            </>
          )}
        />

        {dateRangeOption === 'prev' && (
          <Controller
            control={control}
            name="yearOption"
            defaultValue={yearOption[0].value}
            render={({ field: { ref, onChange, ...rest } }) => (
              <SelectDropdown
                id="yearOption"
                data-testid="yearOption"
                name="yearOption"
                label={<b>Please select year</b>}
                options={yearOption}
                onChange={(e) => {
                  onChange(e);
                  setValue(e.target.value);
                }}
                {...rest}
              />
            )}
          />
        )}
      </div>
    </section>
  );
};
