import React from 'react';
import { Breadcrumbs } from '@gsa/afp-component-library';
import PmExpressProvider from './pm-express-provider';
import PMExpressFilterProvider from './filters/filter-provider';
import PmExpressHeader from './pm-express-header';
import PmExpressListing from './pm-express-listing';

const PmExpress = () => {
  const trail = [<a href={`${window.AFP_CONFIG.appURLs.home}/home`}>Home</a>];

  return (
    <PmExpressProvider>
      <PMExpressFilterProvider>
        <Breadcrumbs
          trail={trail}
          current="Preventative maintenance reporting"
        />
        <div className="margin-bottom-3">
          <PmExpressHeader />
        </div>
        <PmExpressListing />
      </PMExpressFilterProvider>
    </PmExpressProvider>
  );
};

export default PmExpress;
