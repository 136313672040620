/* eslint-disable filenames/match-exported */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable filenames/match-regex */
import React from 'react';
import { Button, RequiredFieldIndicator } from '@gsa/afp-component-library';
import PurchaseOrderNumberForm from 'components/vehicle-registration/widgets/purchase-order-number-form';

const PURCHASE_ORDER_NUMBER = {
  title: () => <h2>Vehicle and license plate information </h2>,
  content: (contextProvider) => {
    return (
      <>
        <p>
          This vehicle does not exist in GSAFleet.gov. Provide more information
          to continue.{' '}
        </p>
        <p>
          Required fields are marked with an asterisk (
          <RequiredFieldIndicator />
          ).
        </p>
        <PurchaseOrderNumberForm {...contextProvider} />
      </>
    );
  },
  action: ({ resetCurrentModal, resetFirstStepState }) => (
    <>
      <Button
        variant="unstyled"
        className="margin-right-2"
        onClick={() => {
          resetCurrentModal();
          resetFirstStepState();
        }}
        data-testid="purchase-order-number-form-cancel-button"
        label="Cancel"
      />
      <Button
        variant="primary"
        type="submit"
        form="purchase-order-number-form"
        data-testid="purchase-order-number-form-submit-button"
        label="Save and close"
      />
    </>
  ),
  onClose: ({ resetCurrentModal, resetFirstStepState }) => {
    resetCurrentModal();
    resetFirstStepState();
  },
};

export default PURCHASE_ORDER_NUMBER;
