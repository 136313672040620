import { useOrders } from '../context';

export default function usePaginationManager() {
  const { limit, setLimit, offset, setOffset } = useOrders();
  const handlePaginationChange = (page, newLimit) => {
    if (limit !== newLimit) {
      setLimit(newLimit);
      setOffset(0);
    }

    const newOffset = (page - 1) * newLimit;
    if (newOffset !== offset) {
      setOffset(newOffset);
    }
  };
  return [handlePaginationChange];
}
