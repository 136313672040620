import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SelectDropdown, Button } from '@gsa/afp-component-library';
import { useLazyQuery } from '@apollo/client';

import useUser from '../../../utilities/use-user';
import {
  GET_AGENCIES,
  GET_BUREAUS_BY_PERMISSION,
  GET_OFFICES_BY_PERMISSION,
} from '../../../services/data-layer';
import { VMSSubjects, VMSOperations } from '../../../utilities/consts';
import usePortalModal from '../../../utilities/portal-modal';
import { sortBy } from 'lodash';

const DEFAULT_AGENCIES = [{ label: '- Select Agency -', value: '' }];
const DEFAULT_BUREAUS = [{ label: '- Select Bureau -', value: '' }];
const DEFAULT_OFFICES = [{ label: '- Select Office -', value: '' }];

export default function AgencyEdit({
  agencyCode,
  bureauCode,
  subSectionCode,
  editing,
  onClose,
  onSave,
}) {
  const [AgencyDetailsModal, openModal, closeModal] = usePortalModal();
  const [agencies, setAgencies] = useState(DEFAULT_AGENCIES);
  const [bureaus, setBureaus] = useState(DEFAULT_BUREAUS);
  const [offices, setOffices] = useState(DEFAULT_OFFICES);

  const { isRole } = useUser();

  const [newAgency, setNewAgency] = useState('');
  const [newBureau, setNewBureau] = useState('');
  const [newOffice, setNewOffice] = useState('');

  const [errors, setErrors] = useState({});

  const [getAgencies, { data: allAgencies }] = useLazyQuery(GET_AGENCIES, {
    fetchPolicy: 'network-only',
  });

  const [getBureausByPermission, { data: allBureaus }] = useLazyQuery(
    GET_BUREAUS_BY_PERMISSION,
    {
      fetchPolicy: 'network-only',
    },
  );

  const [getOfficesByPermission, { data: allOffices }] = useLazyQuery(
    GET_OFFICES_BY_PERMISSION,
    {
      fetchPolicy: 'network-only',
    },
  );

  const resetModal = () => {
    getAgencies();

    getBureausByPermission({
      variables: {
        agencyCode,
        operation: VMSOperations.UPDATE_VEHICLE_AO,
        subject: VMSSubjects.VEHICLE,
      },
    });

    getOfficesByPermission({
      variables: {
        agencyCode,
        bureauCode,
        operation: VMSOperations.UPDATE_VEHICLE_AO,
        subject: VMSSubjects.VEHICLE,
      },
    });

    setNewAgency(agencyCode);
    setNewBureau(bureauCode);
    setNewOffice(subSectionCode);

    setErrors({});
  };

  useEffect(() => {
    resetModal();
  }, []);

  useEffect(() => {
    if (editing) {
      resetModal();
      openModal();
    } else {
      onClose && onClose();
      closeModal();
    }
  }, [editing]);

  useEffect(() => {
    if (allAgencies) {
      const possibleAgencies = sortBy(allAgencies.getAgencies, 'id').map(
        (agency) => ({
          label: `${agency.id} - ${agency.name}`,
          value: agency.id,
        }),
      );
      setAgencies([...DEFAULT_AGENCIES, ...possibleAgencies]);
    }
  }, [allAgencies]);

  useEffect(() => {
    if (allBureaus) {
      const possibleBureaus = sortBy(
        allBureaus.getBureausByPermission,
        'id',
      ).map((bureau) => ({
        label: `${bureau.id} - ${bureau.name}`,
        value: bureau.id,
      }));
      setBureaus([...DEFAULT_BUREAUS, ...possibleBureaus]);
    }
  }, [allBureaus]);

  useEffect(() => {
    if (allOffices) {
      const possibleOffices = sortBy(
        allOffices.getOfficesByPermission,
        'officeCode',
      ).map((off) => ({
        label: `${off.officeCode} - ${off.officeName}`,
        value: off.officeCode,
      }));
      setOffices([...DEFAULT_OFFICES, ...possibleOffices]);
    }
  }, [allOffices]);

  const handleAgencyChange = (e) => {
    setNewAgency(e.target.value);
    setNewBureau('');
    setNewOffice('');
    getBureausByPermission({
      variables: {
        agencyCode: e.target.value,
        operation: VMSOperations.UPDATE_VEHICLE_AO,
        subject: VMSSubjects.VEHICLE,
      },
    });
  };

  const handleBureauChange = (e) => {
    setNewBureau(e.target.value);
    setNewOffice('');
    getOfficesByPermission({
      variables: {
        agencyCode: newAgency,
        bureauCode: e.target.value,
        operation: VMSOperations.UPDATE_VEHICLE_AO,
        subject: VMSSubjects.VEHICLE,
      },
    });
  };

  const handleOfficeChange = (e) => {
    setNewOffice(e.target.value);
  };

  const handleClose = () => {
    onClose && onClose();
    closeModal();
  };

  const handleSave = () => {
    if (!newAgency || !newBureau) {
      setErrors({
        agencyError: !newAgency ? 'Agency is a required field' : undefined,
        bureauError: !newBureau ? 'Bureau is a required field' : undefined,
      });
    } else if (
      agencyCode !== newAgency ||
      bureauCode !== newBureau ||
      subSectionCode !== newOffice
    ) {
      onSave && onSave(newAgency, newBureau, newOffice);
    } else {
      handleClose();
    }
  };

  return (
    <AgencyDetailsModal
      title={<h2>Update agency details</h2>}
      onClose={handleClose}
      actions={
        <>
          <Button variant="unstyled" onClick={handleClose} label="Close" />
          <Button
            className="margin-left-2"
            onClick={handleSave}
            label="Save and close"
          />
        </>
      }
    >
      <div>
        <SelectDropdown
          data-testid="agency-edit"
          name="agency"
          label="Agency"
          options={agencies}
          onChange={handleAgencyChange}
          value={newAgency}
          disabled={!(isRole('FMVRSAdminRole') || isRole('SiteAdmin'))}
          required
          errorMessage={errors.agencyError}
          default
        />
        <SelectDropdown
          data-testid="bureau-edit"
          name="bureau"
          label="Bureau"
          options={bureaus}
          onChange={handleBureauChange}
          value={newBureau}
          disabled={
            !(
              isRole('FMVRSAdminRole') ||
              isRole('SiteAdmin') ||
              isRole('CustFltMan')
            )
          }
          required
          errorMessage={errors.bureauError}
          default
        />
        <SelectDropdown
          data-testid="office-edit"
          className="office-select"
          name="office"
          label="Office"
          options={offices}
          default
          onChange={handleOfficeChange}
          disabled={
            !(
              isRole('FMVRSAdminRole') ||
              isRole('SiteAdmin') ||
              isRole('CustFltMan')
            )
          }
          value={newOffice}
        />
      </div>
    </AgencyDetailsModal>
  );
}

AgencyEdit.propTypes = {
  agencyCode: PropTypes.string,
  bureauCode: PropTypes.string,
  subSectionCode: PropTypes.string,
  editing: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};
AgencyEdit.defaultProps = {
  agencyCode: '000',
  bureauCode: '00',
  subSectionCode: '000',
  editing: false,
  onClose: () => {
    // intentional just an FYI SQ
  },
  onSave: () => {
    // intentional just an FYI SQ
  },
};
