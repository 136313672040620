export const CDD_FORM_FIELDS = {
  lawEnforcement: {
    name: 'lawEnforcement',
    id: 'lawEnforcement',
  },
  isEmergencyResponse: {
    name: 'isEmergencyResponse',
    id: 'isEmergencyResponse',
  },
  fastDescription: {
    name: 'fastDescription',
    id: 'fastDescription',
  },
  acquisitionCost: {
    name: 'acquisitionCost',
    id: 'acquisitionCost',
  },
  acquisitionDate: {
    name: 'originalAcquisitionDate',
    id: 'acquisitionDate',
  },
  assignmentType: {
    name: 'assignmentType',
    id: 'assignmentType',
  },
  reportOrganization: {
    name: 'reportOrganization',
    id: 'reportOrganization',
  },
  agencyBudgetElementCode: {
    name: 'agencyBudgetElementCode',
    id: 'agencyBudgetElementCode',
  },
  agencySort: {
    name: 'agencySort',
    id: 'agencySort',
  },
  isGasAfv: {
    name: 'isGasAfv',
    id: 'petroleumDedicatedLGHGVehicleQualifyingAsAFV',
  },
  aldCoverageDesignation: {
    name: 'aldCoverageDesignation',
    id: 'EPActCoverageDesignation',
  },
  ald2005701: {
    name: 'ald2005701',
    id: 'EPAct2005Section701Designation',
  },
  aldComment: {
    name: 'aldComment',
    id: 'aldComment',
  },
  ald141Designation: {
    name: 'ald141Designation',
    id: 'EISASection141Designation',
  },
  garageDomestic: {
    name: 'garageDomestic',
    id: 'isTheVehicleInADomesticLocation',
  },
  isLocationWithheld: {
    name: 'isLocationWithheld',
    id: 'withholdLocationOfVehicle',
  },
  garageCountryCode: {
    name: 'garageCountryCode',
    id: 'garageCountryCode',
  },
  garageAddress1: {
    name: 'garageAddress1',
    id: 'garageAddressLine1',
  },
  garageAddress2: {
    name: 'garageAddress2',
    id: 'garageAddressLine2',
  },
  garageAddress3: {
    name: 'garageAddress3',
    id: 'garageAddressLine3',
  },
  garageCity: {
    name: 'garageCity',
    id: 'garageCity',
  },
  garageStateCode: {
    name: 'garageStateCode',
    id: 'garageStateProvinceRegion',
  },
  garagePostalCode: {
    name: 'garagePostalCode',
    id: 'garagePostalCode',
  },
  leaseType: {
    name: 'leaseType',
    id: 'leaseType',
  },
  indirectCost: {
    name: 'indirectCost',
    id: 'indirectCost',
  },
  leaseCost: {
    name: 'leaseCost',
    id: 'leaseCost',
  },
  accidentCost: {
    name: 'accidentRepairCost',
    id: 'accidentRepairCost',
  },
  armoredDesignation: {
    name: 'armoredDesignation',
    id: 'armoredDesignation',
  },
  isMsa: {
    name: 'isMsa',
    id: 'isMSA(MetropolitanStatisticalArea)',
  },
  homeToWork: {
    name: 'homeToWork',
    id: 'homeToWork',
  },
  stateTag: {
    name: 'stateTag',
    id: 'stateTag',
  },
  stateTagExpirationDate: {
    name: 'stateTagExpirationDate',
    id: 'stateTagExpirationDate',
  },
  cddTelematicsInstalled: {
    name: 'cddTelematicsInstalled',
    id: 'cddTelematicsInstalled',
  },
  telematicsDataUsage: {
    name: 'telematicsDataUsage',
    id: 'telematicsDataUsage',
  },
};

export const YES_NO_OPTIONS = [
  { value: '', label: 'Select' },
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];
