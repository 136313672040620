import React from 'react';
import {
  ButtonDropdown,
  ButtonDropdownItem,
  Button,
} from '@gsa/afp-component-library';

// DropDownItems is an array of  DropDownItem { label: 'Export data',   iconName, onClick, hiddenItem}
// hiddenItem is controlled show or hidden the items. optional attribute default is show the items.

// eslint-disable-next-line react/prop-types
const FilterUpRightActions = ({ DropDownItems = [], pageName = '' }) => {
  const filteredList = DropDownItems.filter((x) => !x.hiddenItem);
  if (filteredList.length === 0) {
    return null;
  }

  return (
    <div className="display-flex flex-justify-end margin-top-neg-05">
      {filteredList.length === 1 ? (
        <Button
          className="display-flex flex-align-right"
          data-testid={`export-${pageName}-btn`}
          onClick={DropDownItems[0].onClick}
          leftIcon={{ name: DropDownItems[0].iconName }}
          label={DropDownItems[0].label}
          variant={DropDownItems[0]?.variant || undefined}
        />
      ) : (
        <ButtonDropdown
          label="Actions"
          variant="primary"
          side="right"
          className="display-flex flex-align-right"
          data-testid={`actions-${pageName}-btn`}
        >
          {filteredList
            .filter((x) => !x.hiddenItem)
            .map((props) => (
              // eslint-disable-next-line react/prop-types
              <ButtonDropdownItem key={props.label} {...props} />
            ))}
        </ButtonDropdown>
      )}
    </div>
  );
};

export default FilterUpRightActions;
