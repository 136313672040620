import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const DescriptionField = ({ showErrorMessage, ...restProps }) => {
  const descriptionTextAreaGroupStyles = classnames('usa-form-group', {
    [`usa-form-group--error`]: showErrorMessage,
  });

  return (
    <div className="usa-character-count">
      <div className={descriptionTextAreaGroupStyles}>
        <label className="usa-label" htmlFor="attachment-textarea">
          Description (optional)
        </label>
        <span id="attachment-textarea-hint" className="usa-hint">
          A few words to help identify this document
        </span>
        <textarea
          {...restProps}
          data-testid="attachment-file-description"
          className="usa-textarea usa-character-count__field attachment-description"
          id="attachment-textarea"
          maxLength="100"
          rows="5"
          aria-describedby="attachment-textarea-info attachment-textarea-hint"
        />
        <span
          id="attachment-textarea-info"
          className="usa-hint usa-character-count__message"
          aria-live="polite"
        >
          100 characters allowed
        </span>
        {showErrorMessage && (
          <span className="usa-error-message" id="file-input-error-alert">
            Please add document description
          </span>
        )}
      </div>
    </div>
  );
};

DescriptionField.defaultProps = {
  showErrorMessage: false,
};

DescriptionField.propTypes = {
  showErrorMessage: PropTypes.bool,
};

export default DescriptionField;
