import { gql } from '@apollo/client';

export const GET_PM_EXPANDED_ROW = gql`
  query getExpandedRowPmData($assetPmId: String!) {
    getExpandedRowPmData(assetPmId: $assetPmId) {
      invoiceData {
        docStoreUri
        fileType
        name
        docMetaId
      }
      createdByUser {
        fullName
      }
    }
  }
`;

export const GET_PM_LIST = gql`
  query GetPmExpressList(
    $order: OrderBy
    $limit: Float
    $offset: Float
    $filters: [Filter!]!
  ) {
    getPmExpressList(
      order: $order
      limit: $limit
      offset: $offset
      filters: $filters
    ) {
      count
      hasMore
      rows {
        pmSchedule {
          pmMilesInterval
          pmKilometersInterval
          pmMonthsInterval
          pmScheduleType
        }
        assetId
        assetPmId
        recall {
          status
        }
        vehicle {
          id
          customer {
            boac
            legacyCustomerNumber
            accountName
          }
          tagNumber
          agencyCode
          bureauCode
          vin
          fmcId
        }
        milesSinceLastPm
        timeSincePm
        currentPmDate
        currentPmMileage
        nextPmDate
        nextPmMileage
        pmScheduleType
        pmStatus
        methodOfEntry
        odometer
        createdAt
        updatedBy
        updatedAt
      }
    }
  }
`;

export const ADD_PM = gql`
  mutation AddPm($addPmField: AddPmFieldInput!) {
    addPm(addPmField: $addPmField)
  }
`;

export const REPORT_PMS = gql`
  mutation ReportPms($reportPmsFields: [ReportPmFieldInput!]!) {
    reportPms(reportPmsFields: $reportPmsFields) {
      assetPmId
      currentPmDate
      currentPmMileage
      assetId
    }
  }
`;

export const UPDATE_PM = gql`
  mutation UpdatePm($updatePmFields: UpdatePmFieldInput!) {
    updatePm(updatePmFields: $updatePmFields) {
      assetPmId
      currentPmDate
      currentPmMileage
      assetId
    }
  }
`;

export const GET_BOACS = gql`
  query GetBoacs($getBoacsArgs: GetBoacsInput!) {
    getBoacs(getBoacsArgs: $getBoacsArgs) {
      boacId
      boac
    }
  }
`;
