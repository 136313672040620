import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { OptIn } from '@gsa/afp-shared-ui-utils';
import { TabSet } from '@gsa/afp-component-library';
import { useLocation } from 'react-router-dom';
import { GET_TOTAL_COST_BY_VEHICLE } from 'services/data-layer/repair-order';
import { useVehicle } from '../../vehicle-context-provider';
import { isMileageEnabled } from '../../helpers/feature-helper';
import RepairSummary from './repair-summary';
import RepairOrdersTable from './repair-orders-table';
import RepairsByServiceTable from './repairs-by-service-table';
import { isRepairEditable } from './helpers';
import RepairsByPO from './repairs-by-po/repairs-by-po';
import useUser from '../../../../utilities/use-user';

const RepairOrders = () => {
  const { vehicle, setTabMsg, setPageMsg } = useVehicle();
  const { isGsaEmployee } = useUser();
  const location = useLocation();
  if (!vehicle) return null;
  const { agencyCode, bureauCode, ownershipTypeCode } = vehicle;

  const { data, loading, refetch } = useQuery(GET_TOTAL_COST_BY_VEHICLE, {
    variables: { assetId: vehicle?.uuid },
  });

  useEffect(() => {
    if (isRepairEditable(vehicle) || vehicle.ownershipTypeCode === 'GF')
      setTabMsg(null);
    else
      setTabMsg({
        type: 'info',
        message:
          'Repair orders can only be created or modified for active vehicles.',
        hideClose: true,
      });
  }, [vehicle]);

  useEffect(() => {
    const { message } = location?.state ?? {};
    if (message) setPageMsg({ type: message.type, message: message.text });
  }, []);
  return (
    <>
      {vehicle?.ownershipTypeCode === 'GF' && isGsaEmployee() ? (
        <RepairsByPO />
      ) : (
        <OptIn
          agencyCode={agencyCode}
          bureauCode={bureauCode}
          isHidden={!isMileageEnabled(agencyCode, ownershipTypeCode)}
        >
          <RepairSummary data={data} loading={loading} />
          <TabSet
            tabs={[
              {
                heading: 'Repair orders',
                content: <RepairOrdersTable refetchTotal={refetch} />,
                tabSelectedWhenOpen: true,
              },
              {
                heading: 'Repairs by service',
                content: <RepairsByServiceTable />,
                tabSelectedWhenOpen: false,
              },
            ]}
          />
        </OptIn>
      )}
    </>
  );
};

export default RepairOrders;
