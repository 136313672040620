/* eslint-disable import/prefer-default-export */
/* eslint-disable filenames/match-regex */
import { gql } from '@apollo/client';

export const GET_FLEET_CARD_PRODUCTS_AND_UNIT_OF_MEASURES = gql`
  query getFleetCardProductsAndUnitOfMeasures($productDescription: String) {
    getFleetCardProductsAndUnitOfMeasures(
      productDescription: $productDescription
    ) {
      productLists {
        id
        fullProductDescription
        productDescription
      }
      unitOfMeasureLookup
    }
  }
`;
