import React, { createContext, useContext, useReducer } from 'react';

const RecallPageContext = createContext({});
const useRecallPage = () => useContext(RecallPageContext);

const initialState = {
  bannerMsg: null,
  recallCount: 0,
};

const actions = {
  setBannerMsg: 'SET_BANNER_MSG',
  setRecallCount: 'SET_RECALL_COUNT',
};

const recallPageReducer = (state, { action, payload }) => {
  switch (action) {
    case actions.setBannerMsg:
      return { ...state, bannerMsg: payload };
    case actions.setRecallCount:
      return { ...state, recallCount: payload };
    default:
      return {
        ...state,
        bannerMsg: { type: 'error', message: 'Unknown page action' },
      };
  }
};

const RecallPageProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    recallPageReducer,
    initialState,
    () => initialState,
  );

  const setBannerMsg = (payload) =>
    dispatch({ action: actions.setBannerMsg, payload });

  const setRecallCount = (payload) =>
    dispatch({ action: actions.setRecallCount, payload });

  return (
    <RecallPageContext.Provider
      value={{
        ...state,
        setBannerMsg,
        setRecallCount,
      }}
    >
      {children}
    </RecallPageContext.Provider>
  );
};

export { RecallPageProvider as default, useRecallPage };
