// eslint-disable-next-line filenames/match-exported
import React from 'react';
import { StatusBadge } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';

export const ORDER_STATUS_LABELS = {
  SHIP: 'Shipped',
  RECV: 'Received',
  MISS: 'Missing',
  PRECV: 'Partially received',
};

const ORDER_STATUS_COLORS = {
  SHIP: 'Info-Gray',
  RECV: 'Ready-Gray',
  MISS: 'Urgent-Gray',
  PRECV: 'Warning-Gray',
};

export default function OrderStatus({ orderStatus }) {
  return (
    <StatusBadge variant={ORDER_STATUS_COLORS[orderStatus]}>
      {ORDER_STATUS_LABELS[orderStatus] || 'Unknown'}
    </StatusBadge>
  );
}

OrderStatus.propTypes = {
  orderStatus: PropTypes.string.isRequired,
};
