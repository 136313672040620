import { useState, useCallback } from 'react';

const usePagination = (initialPagination) => {
  const [currentPage, setCurrentPage] = useState(
    initialPagination?.currentPage || 1,
  );
  const [itemsPerPage, setItemsPerPage] = useState(
    initialPagination?.itemsPerPage || 20,
  );

  const onPageChange = useCallback((newCurrentPage, newItemsPerPage) => {
    setCurrentPage(newCurrentPage);
    setItemsPerPage(newItemsPerPage);
  }, []);

  const offset = (currentPage - 1) * itemsPerPage;

  return {
    currentPage,
    offset,
    itemsPerPage,
    onPageChange,
  };
};

export default usePagination;
