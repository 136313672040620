import React, { useEffect, useReducer, useState } from 'react';
import usePortalModal from 'utilities/portal-modal';
import {
  Button,
  TextInput,
  RequiredFieldIndicator,
} from '@gsa/afp-component-library';
import PropTypes from 'prop-types';

const alphanumericRegExp = /^$|^[a-z0-9 ]+$/i;

const EditMiscellaneous = ({ vehicle, onSave, onClose }) => {
  const formReducer = (state, newState) => {
    return {
      ...state,
      ...newState,
    };
  };

  const [errors, setErrors] = useState({});

  const [state, dispatch] = useReducer(formReducer, {
    keyCodeIgnition: vehicle?.assetAcquisition?.keyCodeIgnition,
    keyCodeOther: vehicle?.assetAcquisition?.keyCodeOther,
  });

  const [MiscellaneousEditModal, openModal, closeModal] = usePortalModal();
  useEffect(() => {
    openModal();
  }, []);

  const close = () => {
    // eslint-disable-next-line no-unused-expressions
    onClose && onClose();
    closeModal();
  };

  const handleTextInputChange = (e, field) => {
    dispatch({
      [field]: e.target.value,
    });
  };

  const validate = (e, field) => {
    if (!alphanumericRegExp.test(e.target.value)) {
      setErrors({
        [field]: 'Letters & numbers only allowed',
      });
      return;
    } else {
      setErrors({ ...errors, [field]: '' });
    }
  };

  const keyCodeIgnition = ({ handleChange, value, validate }) => {
    return (
      <>
        <TextInput
          maxLength={15}
          label="Ignition key"
          name="keyCodeIgnition"
          type="input"
          value={value}
          onChange={(e) => handleChange(e, 'keyCodeIgnition')}
          onBlur={(e) => validate(e, 'keyCodeIgnition')}
          data-testid="miscellaneous-key-code-ignition"
          errorMessage={errors.keyCodeIgnition}
        />
      </>
    );
  };

  const keyCodeOther = ({ handleChange, value, validate }) => {
    return (
      <>
        <TextInput
          maxLength={15}
          label="Other key"
          name="keyCodeOther"
          type="input"
          value={value}
          onChange={(e) => handleChange(e, 'keyCodeOther')}
          onBlur={(e) => validate(e, 'keyCodeOther')}
          data-testid="miscellaneous-key-code-other"
          errorMessage={errors.keyCodeOther}
        />
      </>
    );
  };

  const saveMiscellaneous = (e) => {
    if (
      !alphanumericRegExp.test(state.keyCodeIgnition) ||
      !alphanumericRegExp.test(state.keyCodeOther)
    ) {
      return;
    }
    closeModal();
    onSave(e);
  };

  return (
    <MiscellaneousEditModal
      actions={
        <>
          <Button
            data-testid="miscellaneous-close"
            variant="unstyled"
            label="Cancel"
            onClick={close}
          />
          <Button
            className="margin-left-2"
            onClick={() => saveMiscellaneous(state)}
            label="Save and close"
            data-testid="miscellaneous-save-and-close"
          />
        </>
      }
      title={<h2>Edit miscellaneous information</h2>}
      onClose={close}
    >
      <div className="grid-col-12 grid-gap">
        <p>
          Edit miscellaneous information for VIN <strong>{vehicle?.id}</strong>{' '}
          in the form below.
        </p>
        <p>
          Required fields are marked with an asterisk (
          <RequiredFieldIndicator />
          ).
        </p>
      </div>
      <div className="grid-row grid-gap">
        <div className="grid-col-6">
          {keyCodeIgnition({
            value: state.keyCodeIgnition,
            handleChange: handleTextInputChange,
            validate: validate,
          })}
        </div>
        <div className="grid-col-6">
          {keyCodeOther({
            value: state.keyCodeOther,
            handleChange: handleTextInputChange,
            validate: validate,
          })}
        </div>
      </div>
    </MiscellaneousEditModal>
  );
};

export default EditMiscellaneous;

EditMiscellaneous.propTypes = {
  vehicle: PropTypes.shape({
    id: PropTypes.string,
    assetTechnical: PropTypes.shape({
      assetTechnicalIce: PropTypes.shape({
        cylinders: PropTypes.string,
        transmission: PropTypes.string,
        tankSize: PropTypes.number,
        mpgCity: PropTypes.number,
        mpgHighway: PropTypes.number,
        mpgCombined: PropTypes.number,
        flexFuelMpgCity: PropTypes.number,
        flexFuelMpgHighway: PropTypes.number,
        flexFuelMpgCombined: PropTypes.number,
        gramPerMile: PropTypes.number,
        flexFuelGramPerMile: PropTypes.number,
      }),
    }),
    tagNumber: PropTypes.string,
    fuel: PropTypes.shape({
      description: PropTypes.string,
    }),
    modelYear: PropTypes.number,
    fuelCode: PropTypes.string,
    fastReportable: PropTypes.bool,
    makeColor: PropTypes.shape({
      name: PropTypes.string,
    }),
    vehicleModel: PropTypes.shape({
      modelDescription: PropTypes.string,
    }),
    vehicleMake: PropTypes.shape({
      makeName: PropTypes.string,
    }),
    tag: PropTypes.shape({
      expirationDate: PropTypes.number,
    }),
    inventoryStatus: PropTypes.shape({
      id: PropTypes.string,
      description: PropTypes.string,
    }),
  }),
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};
