import { get, isEmpty } from 'lodash';
import guards from './guards';

export default function registrationFormValidation(
  state,
  validations,
  context = null,
) {
  if (state && validations) {
    const fields = Object.keys(validations);
    const fieldsWithErrors = {};
    if (context) {
      if (
        context?.isRequirePurchaseOrderNumber &&
        isEmpty(context?.purchaseOrderNumber)
      ) {
        fieldsWithErrors.orderNumber = 'Order number is required';
      } else {
        fieldsWithErrors.orderNumber = null;
      }
    }
    fields.forEach((field) => {
      const fieldValidations = validations[field].reduce(
        (result, validation) => {
          const { guard } = validation;
          const fieldValue = get(state, field);
          const isValid = guards[guard](fieldValue, state);
          if (!isValid) {
            result.push(validation.msg);
          }
          return result;
        },
        [],
      );
      if (fieldValidations.length) {
        fieldsWithErrors[field] = fieldValidations[0];
      } else {
        fieldsWithErrors[field] = null;
      }
    });
    return fieldsWithErrors;
  }
  return null;
}
