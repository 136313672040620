import React from 'react';
import PropTypes from 'prop-types';
import { FilterPanel } from '@gsa/afp-component-library';
import DateRange, { displayValue } from '../../filters/widgets/date-range';

const ExpenseDateRangeFilter = ({ filter }) => (
  <FilterPanel.FilterPanelField
    key={[filter.key]}
    filter={{
      ...filter,
      isSingleValueFilter: true,
      displayValue,
    }}
    component={DateRange}
  />
);

ExpenseDateRangeFilter.propTypes = {
  filter: PropTypes.shape(FilterPanel.FilterPanelFilterShape).isRequired,
};

export default ExpenseDateRangeFilter;
