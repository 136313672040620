import React, { useState, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import {
  TextInput,
  SelectDropdown,
  CountrySelect,
  Label,
  Button,
} from '@gsa/afp-component-library';
import {
  STATES_OCONUS_TERRITORIES,
  DEFAULT_SELECT_OPTION_LABEL,
} from 'utilities/consts';
import usePortalModal from 'utilities/portal-modal';
import './poc-edit.css';
import PhoneInputMask from 'components/vehicle-registration/poc-lookup/phone-input-mask';
import { fieldLengthValidator } from 'components/vehicle-registration/widgets/agency-and-poc/poc/create-poc-modal';

const formReducer = (state, newState) => {
  return { ...state, ...newState };
};

const fieldToLabel = {
  firstName: 'First name',
  lastName: 'Last name',
  primaryAddress: 'Street address',
  city: 'City',
  stateCode: 'State / Province / Region',
  postalCode: 'Zip',
  primaryPhone: 'Phone number',
};

const requiredFields = [
  'firstName',
  'lastName',
  'primaryAddress',
  'city',
  'stateCode',
  'postalCode',
  'primaryPhone',
];

const addressFields = ['primaryAddress', 'city', 'stateCode', 'postalCode'];

const validateFields = (reqFields, state) => {
  if (reqFields && state) {
    return reqFields.reduce((validations, field) => {
      if (!state[field] || state[field] === DEFAULT_SELECT_OPTION_LABEL) {
        validations[field] = `${fieldToLabel[field]} is required`;
      } else if (
        field === 'primaryPhone' &&
        state.countryCode === 'US' &&
        state[field].replace(/\D/g, '').length < 10
      ) {
        validations[field] = 'Phone number must be 10 digits';
      }
      return validations;
    }, {});
  }
};

export default function PocEdit({
  poc,
  handleEditClose,
  handleEditSave,
  handleOpenChangeEmail,
  isEditingPoC,
  isNewEmailSelected,
}) {
  const [PocModal, openModal, closeModal] = usePortalModal();
  const [state, dispatch] = useReducer(formReducer, {
    email: '',
    firstName: '',
    lastName: '',
    countryCode: '',
    primaryAddress: '',
    secondaryAddress: '',
    city: '',
    stateCode: '',
    postalCode: '',
    primaryPhone: '',
    primaryPhoneExt: '',
    ...poc,
  });

  useEffect(() => {
    if (isEditingPoC) {
      openModal();
      setErrors({});
    } else {
      closeModal();
    }
  }, [isEditingPoC]);

  const [statesOptions] = useState(
    STATES_OCONUS_TERRITORIES.map((stateOption) => ({
      value: stateOption,
      label: stateOption,
    })),
  );
  const [errors, setErrors] = useState({});

  const handleSave = () => {
    let pocChanged = false;
    let hasAddressChanged = false;

    if (poc) {
      Object.keys(state).forEach((key) => {
        if (poc[key] !== state[key]) {
          pocChanged = true;

          if (addressFields.includes(key)) {
            hasAddressChanged = true;
          }
        }
      });
    }
    if (!poc || pocChanged || isNewEmailSelected) {
      const validations = validateFields(requiredFields, state);
      const noError = Object.keys(validations).length === 0;
      if (noError) {
        setErrors({});
        handleEditSave(state, hasAddressChanged);
      } else {
        setErrors(validations);
      }
    } else {
      handleEditClose();
    }
  };

  useEffect(() => {
    if (poc) {
      dispatch({ ...poc });
    }
  }, [poc]);

  const handleOnlyDigits = (e) => {
    const { name, value } = e.target;
    const sanitizedValue = value.replace(/\D/g, '');
    if (fieldLengthValidator({ target: { name, value: sanitizedValue } })) {
      dispatch({ [name]: sanitizedValue });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (fieldLengthValidator(e)) {
      dispatch({ [name]: value });
    }
  };

  return (
    <div>
      <PocModal
        title={<h1>Update point of contact #{poc?.ind}</h1>}
        onClose={handleEditClose}
        actions={
          <>
            <Button
              variant="unstyled"
              onClick={handleEditClose}
              label="Close"
              id="close-modal-button"
            />
            <Button
              className="margin-left-2"
              onClick={handleSave}
              label="Save and close"
            />
          </>
        }
      >
        <div className="edit-poc grid-row grid-gap">
          <div className="grid-col-12">
            Update information for this point of contact or change to a
            different point of contact.
          </div>
          <div className="grid-col-6">
            <TextInput type="text" label="Email" disabled value={state.email} />
          </div>
          <div className="grid-col-6 display-flex flex-align-end">
            <Button
              variant="unstyled"
              onClick={() => {
                handleOpenChangeEmail();
              }}
              label="Change to a different point of contact"
            />
          </div>
          <div className="grid-col-6">
            <TextInput
              label="First name"
              name="firstName"
              required
              value={state.firstName}
              onChange={handleChange}
              errorMessage={errors.firstName}
            />
          </div>
          <div className="grid-col-6">
            <TextInput
              label="Last name"
              name="lastName"
              required
              value={state.lastName}
              onChange={handleChange}
              errorMessage={errors.lastName}
            />
          </div>
          <div className="grid-col-12">
            <Label className="text-primary text-heavy text-uppercase">
              Mailing Address
            </Label>
          </div>
          <div className="grid-col-6">
            <CountrySelect
              label="Country"
              name="countryCode"
              required
              defaultUSA
              withUsTerritories={false}
              value={state.countryCode}
              onChange={handleChange}
            />
          </div>
          <div className="grid-col-12">
            <TextInput
              label="Street address"
              name="primaryAddress"
              required
              value={state.primaryAddress}
              onChange={handleChange}
              errorMessage={errors.primaryAddress}
            />
          </div>
          <div className="grid-col-12">
            <TextInput
              label="Street address 2 (Optional)"
              name="secondaryAddress"
              value={state.secondaryAddress}
              onChange={handleChange}
            />
          </div>
          <div className="grid-col-5">
            <TextInput
              label="City"
              name="city"
              required
              value={state.city}
              onChange={handleChange}
              errorMessage={errors.city}
            />
          </div>
          <div className="grid-col-7">
            {state.countryCode === 'US' ? (
              <SelectDropdown
                label="State"
                name="stateCode"
                required
                options={statesOptions}
                value={state.stateCode}
                onChange={handleChange}
                errorMessage={errors.stateCode}
              />
            ) : (
              <TextInput
                label="State / Province / Region"
                name="stateCode"
                required
                value={state.stateCode}
                onChange={handleChange}
                errorMessage={errors.stateCode}
              />
            )}
          </div>
          <div className="grid-col-4">
            <TextInput
              label="Zip"
              name="postalCode"
              required
              value={state.postalCode}
              onChange={handleOnlyDigits}
              errorMessage={errors.postalCode}
            />
          </div>
          <div className="grid-col-12">
            <Label className="text-primary text-heavy text-uppercase">
              Phone Number
            </Label>
          </div>
          <div className="grid-col-6">
            {state.countryCode === 'US' ? (
              <PhoneInputMask
                label="Phone number"
                name="primaryPhone"
                required
                value={state.primaryPhone}
                onChange={handleChange}
                errorMessage={errors.primaryPhone}
              />
            ) : (
              <TextInput
                label="Phone number"
                name="primaryPhone"
                required
                value={state.primaryPhone}
                onChange={handleOnlyDigits}
                errorMessage={errors.primaryPhone}
              />
            )}
          </div>
          <div className="grid-col-3">
            <TextInput
              label="Extension"
              name="primaryPhoneExt"
              value={state.primaryPhoneExt || ''}
              onChange={handleChange}
            />
          </div>
        </div>
      </PocModal>
    </div>
  );
}

PocEdit.propTypes = {
  poc: PropTypes.object,
  handleEditClose: PropTypes.func.isRequired,
  handleEditSave: PropTypes.func.isRequired,
  handleOpenChangeEmail: PropTypes.func.isRequired,
  isEditingPoC: PropTypes.bool,
  isNewEmailSelected: PropTypes.bool,
};
PocEdit.defaultProps = {
  poc: {},
  isEditingPoc: false,
  isNewEmailSelected: false,
};
