import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FilterPanel, DateRangePicker } from '@gsa/afp-component-library';
import useDateRange from '../hooks/use-date-range';

const DateRange = ({ filter, errorMsg, fromLabel, toLabel }) => {
  const {
    dateFrom,
    setDateFrom,
    dateTo,
    setDateTo,
    error,
    clear,
    applyFilter,
  } = useDateRange({ filter, errorMsg });

  /**
   * We are using randomKey to overcome an issue with Clearing filter when
   * the filter state resets. The DateRangePicker component appears to ignore
   * the prop "value" and it is not passing it down. Therefore, we are not able to
   * clear it up when useDateRange state becomes empty.
   *
   * As a workaround to clear the filter we are using a random key to force re-rendering
   * when dateFrom and dateTo values are both empty
   */
  const [randomKey, setRandomKey] = useState(Math.random());
  const [touched, setTouched] = useState(false);

  useEffect(() => {
    if (!(dateFrom instanceof Date && dateTo instanceof Date)) {
      if (dateFrom === '' && dateTo === '') {
        setRandomKey(Math.random());
      }
    }
  }, [dateFrom, dateTo]);

  useEffect(() => {
    if (dateFrom === '' && dateTo === '') {
      // clear FilterPanel state when dates become empty locally
      clear();
      return;
    }
    // apply filter when user inputs a valid date range
    applyFilter();

    if (filter.defaultValue) {
      setTouched(true);
    }
  }, [dateFrom, dateTo]);

  return (
    <div className="grid-row">
      <div className="grid-col-10">
        {error && (
          <b className="grid-col-12 padding-top-1 text-error">{error}</b>
        )}
        <div className="grid-col-12">
          <DateRangePicker
            key={randomKey}
            id={`${filter.key}-date-range`}
            name={`${filter.key}-date-range`}
            startDateLabel={fromLabel}
            startDateHint="mm/dd/yyyy"
            startDatePickerProps={{
              onChange: (val) => {
                if (val === '') {
                  setDateFrom('');
                  return;
                }
                const newDate = new Date(val);
                newDate.setHours(0, 0, 0, 0);
                setDateFrom(newDate);
              },
              defaultValue: touched
                ? undefined
                : filter.defaultValue?.from.format('YYYY-MM-DD'),
            }}
            endDateLabel={toLabel}
            endDateHint="mm/dd/yyyy"
            endDatePickerProps={{
              onChange: (val) => {
                if (val === '') {
                  setDateTo('');
                  return;
                }
                const newDate = new Date(val);
                newDate.setHours(23, 59, 59, 0);
                setDateTo(newDate);
              },
              defaultValue: touched
                ? undefined
                : filter.defaultValue?.to.format('YYYY-MM-DD'),
            }}
          />
        </div>
      </div>
    </div>
  );
};

export const displayValue = (dates) => {
  if (!Array.isArray(dates) || dates.length < 2) {
    return undefined;
  }
  if (!(dates[0] instanceof Date && dates[1] instanceof Date)) {
    return undefined;
  }
  const opts = { year: 'numeric', month: '2-digit', day: '2-digit' };
  const startDate = dates[0].toLocaleDateString('en-us', opts);
  const endDate = dates[1].toLocaleDateString('en-us', opts);
  return `Between ${startDate} and ${endDate}`;
};

DateRange.propTypes = {
  filter: PropTypes.shape(FilterPanel.FilterPanelFilterShape).isRequired,
  errorMsg: PropTypes.string,
  fromLabel: PropTypes.string,
  toLabel: PropTypes.string,
};

DateRange.defaultProps = {
  errorMsg: 'Date from must be before Date To',
  fromLabel: 'From',
  toLabel: 'To',
};

export default DateRange;
