import { useState } from 'react';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import useUser, { ROLES } from '../../utilities/use-user';
import vehicleFilters from './vehicle-inventory-filters';
import licensePlateFilters from './license-plate-filters';
import ordersFilters from './orders-filters';
import {
  canViewAOVehicles,
  canViewGFVehicles,
  canViewAOTags,
  canViewGFTags,
} from '../../utilities/authorization';

const roleAbbrToFilters = {
  'vehicle-inventory': {
    FMVRS: (user) => vehicleFilters.fsrVehicleNestedFilters(user),
    FSR: (user) => vehicleFilters.fsrVehicleNestedFilters(user),
    CFM: (user) => vehicleFilters.afmVehicleNestedFilters(user),
    default: (user) => vehicleFilters.defaultVehicleNestedFilters(user),
  },
  'license-plates': {
    FSR: (user) => licensePlateFilters.fsrNestedFilter(user),
    default: (user) => licensePlateFilters.defaultNestedFilters(user),
  },
  orders: {
    default: (user) => ordersFilters.defaultFilters(user),
  },
};

const initUserFilters = (entity, roleAbbr, user) => {
  if (entity && user) {
    if (roleAbbrToFilters[entity]) {
      if (roleAbbrToFilters[entity][roleAbbr]) {
        return roleAbbrToFilters[entity][roleAbbr](user);
      }
      return roleAbbrToFilters[entity].default();
    }
    return [];
  }
  return [];
};

export default function userFilters(entity) {
  const { userContext, isCustomer } = useUser();
  const ability = useAppAbility();
  let roleAbbr;
  if (entity === 'vehicle-inventory') {
    const accessAOFleet = canViewAOVehicles(ability);
    const accessGFFleet = canViewGFVehicles(ability);
    if (accessAOFleet && isCustomer()) {
      roleAbbr = ROLES.CFM;
    } else if (accessGFFleet && !accessAOFleet) {
      roleAbbr = ROLES.FSR;
    } else if (accessGFFleet && accessAOFleet && !isCustomer()) {
      roleAbbr = ROLES.FMVRS;
    }
  } else if (entity === 'license-plates') {
    const accessAOTags = canViewAOTags(ability);
    const accessGFTags = canViewGFTags(ability);
    if (accessAOTags && !accessGFTags) {
      roleAbbr = ROLES.CFM;
    } else if (accessGFTags) {
      roleAbbr = ROLES.FSR;
    }
  }
  const [userFilter] = useState(
    initUserFilters(entity, roleAbbr, userContext?.currentUser),
  );
  return { userFilter };
}
