/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  Spinner,
  EmptyState,
  AFPTable,
  SideNav,
  Button,
} from '@gsa/afp-component-library';
import { useLazyQuery } from '@apollo/client';
import { GET_ZONAL_REPORTS } from 'services/data-layer';
import fileDownload from 'js-file-download';
import { GET_AWS_SIGNED_READ_URL } from 'components/attachment/helpers';
import ZonalReportsHeader from './zonal-reports-header';
import './styles.css';

const ZONES = [
  {
    name: 'Zone 1',
    value: 'Zone_1',
  },
  {
    name: 'Zone 2',
    value: 'Zone_2',
  },
  {
    name: 'Zone 3',
    value: 'Zone_3',
  },
  {
    name: 'Zone 4',
    value: 'Zone_4',
  },
  {
    name: 'Zone Other',
    value: 'Zone_Other',
  },
];

const excelMimeType =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
const csvMimeType = 'text/csv';
const pdfMimeType = 'application/pdf';

const iconMapping = {
  [excelMimeType]: 'excel_icon',
  [csvMimeType]: 'csv_icon',
  [pdfMimeType]: 'pdf',
};

const INITIAL_ZONE = ZONES[0];

const ZonalReports = () => {
  const [reports, setReports] = useState([]);
  const [selectedZone, setSelectedZone] = useState(INITIAL_ZONE);
  const [selectedReport, setSelectedReport] = useState();
  const [downloadingFile, setDownloadingFile] = useState(false);
  const [error, setError] = useState();

  const tableRef = React.createRef();

  const [downloadSignedReadURL] = useLazyQuery(GET_AWS_SIGNED_READ_URL, {
    fetchPolicy: 'no-cache',
    onError: (err) => {
      setError(err);
      setDownloadingFile(false);
    },
    onCompleted: async (readURLData) => {
      try {
        const fileURL = readURLData?.generateReadSignedURL;
        if (fileURL) {
          const response = await fetch(readURLData.generateReadSignedURL, {
            method: 'GET',
            headers: {
              'Content-Type': selectedReport?.fileType,
            },
          });
          const blob = await response.blob();
          fileDownload(blob, selectedReport?.fileName);
          setDownloadingFile(false);
        } else {
          setDownloadingFile(false);
        }
      } catch (err) {
        setError(err);
        setDownloadingFile(false);
      }
    },
  });

  const [getReportsByZone, { loading }] = useLazyQuery(GET_ZONAL_REPORTS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: (err) => {
      setError(err);
    },
    onCompleted: (data) => {
      const zonalReports = data?.getZonalReports || [];
      setReports(zonalReports);
      setError(undefined);
    },
  });

  useEffect(() => {
    if (selectedZone) {
      getReportsByZone({ variables: { zone: selectedZone.value } });
    }
  }, [selectedZone]);

  const handleDownloadReport = (fileKey, fileType) => {
    if (fileKey && fileType) {
      downloadSignedReadURL({
        variables: {
          bucket: `${window.AFP_CONFIG.jasper_report}`,
          fileKey,
          fileType,
        },
      });
    } else {
      setDownloadingFile(false);
    }
  };

  const generateSideNav = () =>
    ZONES.map((zone, index) => ({
      id: `zone${index}`,
      body: (
        <Button
          onClick={(e) => {
            e.preventDefault();
            setSelectedZone(zone);
          }}
          className="usa-button--unstyled width-full margin-0 padding-0 zonal-sidenav-button"
          label={zone.name}
          leftIcon={{ name: 'folder', className: 'usa-icon--size-3' }}
        />
      ),
      active: zone === selectedZone,
    }));

  const columns = [
    {
      Header: 'Name',
      accessor: 'fileName',
      sortable: true,
      Cell: ({ value, row: { original } }) => {
        const { fileKey, fileType } = original;
        return (
          <>
            <Button
              onClick={() => {
                setDownloadingFile(true);
                setSelectedReport(original);
                handleDownloadReport(fileKey, fileType);
              }}
              className="usa-button--unstyled padding-0"
              label={value}
              leftIcon={{
                name: iconMapping[fileType] || 'document_file',
                className: 'usa-icon--size-3',
              }}
            />
          </>
        );
      },
    },
  ];

  if (downloadingFile) {
    return (
      <Spinner aria-busy="true" className="loading_backdrop" size="large" />
    );
  }

  return (
    <>
      <ZonalReportsHeader error={error} />
      <div className="grid-row grid-gap">
        <div className="grid-col-2 margin-top-2 zonal-side-nav">
          <SideNav items={generateSideNav()} />
        </div>
        <div className="grid-col-10">
          <>
            <AFPTable
              fullWidth
              ref={tableRef}
              testId="zonal-reports-table"
              columns={columns}
              data={!loading ? reports || [] : []}
            />
            {loading && <Spinner className="padding-y-9" />}
            {reports?.length === 0 && !loading && (
              <div className="bg-gray-3 padding-y-5">
                <div className="text-center padding-y-4">
                  <EmptyState alt="Image not available" hasBackground />
                </div>
                <div className="text-center text-bold">
                  No Zonal Reports available
                </div>
              </div>
            )}
          </>
        </div>
      </div>
    </>
  );
};

export default ZonalReports;
