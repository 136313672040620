import {
  Button,
  connectModal,
  Modal,
  useModal,
} from '@gsa/afp-component-library';
import classnames from 'classnames';
import FocusTrap from 'focus-trap-react';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import UploadForm from './upload-form';
import { useUploadContext } from './upload-context';

const UploadModal = ({ id, title, className }) => {
  const {
    addUploadState,
    handleUploadFile,
    setFile,
    isUploading,
    setIsUploading,
    isUpload,
    setIsUpload,
  } = useUploadContext();

  const [activeTrap, setActiveTrap] = useState(false);

  const { isOpen, openModal, closeModal } = useModal();

  const classes = classnames('upload-modal', className);

  const unmountTrap = () => {
    setActiveTrap(false);
  };

  useEffect(() => {
    if (isUpload) {
      setActiveTrap(isUpload);
      openModal();
    } else {
      closeModal();
    }
  }, [isUpload]);

  const onClose = () => {
    closeModal();
    setFile([]);
    setIsUploading(false);
    setIsUpload(false);
    setActiveTrap(false);
  };

  const actions = (
    <>
      <Button
        data-testid="sap-upload-cancel-button"
        disabled={isUploading}
        type="button"
        variant="outline"
        onClick={onClose}
        label="Cancel"
      />
      <Button
        data-testid="sap-upload-upload-button"
        disabled={isUploading}
        form="sap-upload-upload-form"
        className="add-document-button action-button"
        onClick={() => handleUploadFile(onClose)}
        label={isUploading ? 'Uploading...' : 'Upload'}
      />
    </>
  );

  const AddModal = useMemo(
    () => () =>
      (
        <Modal
          id={id}
          className={classes}
          variant="large"
          title={<h2>{title}</h2>}
          show={addUploadState?.show}
          onClose={() => {
            closeModal();
            onClose();
          }}
          actions={actions}
        >
          <UploadForm />
        </Modal>
      ),
    [addUploadState, actions],
  );

  const ConnectedModal = connectModal(AddModal);

  const trap = activeTrap ? (
    <FocusTrap
      focusTrapOptions={{
        onDeactivate: unmountTrap,
        escapeDeactivates: false,
      }}
    >
      <div className="trap">
        <ConnectedModal isOpen={isOpen} onClose={closeModal} />
      </div>
    </FocusTrap>
  ) : (
    <ConnectedModal isOpen={isOpen} onClose={closeModal} />
  );

  return trap;
};

UploadModal.defaultProps = {
  id: 'attachment-modal',
  className: 'modal',
  title: null,
  children: null,
  actions: null,
  show: false,
  onClose: () => {
    // noop
  },
};
UploadModal.propTypes = {
  id: PropTypes.string,
  title: PropTypes.node,
  show: PropTypes.bool,
  actions: PropTypes.node,
  className: PropTypes.string,
  children: PropTypes.node,
  onClose: PropTypes.func,
};

export default UploadModal;
