import { useLazyQuery, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useReducer } from 'react';
import { EXPORT_REPORT } from 'services/data-layer';
import exportMessages from 'utilities/export-message';
import useCanPerformActions from 'hooks/use-can-perform-actions';
import {
  GET_MOTOR_POOLS_LIST,
  GET_MOTOR_POOL,
  CREATE_MOTOR_POOL,
  UPDATE_MOTOR_POOL,
  DELETE_MOTOR_POOL,
  GET_AGENCIES_BY_PERMISSION,
  GET_BUREAUS_BY_PERMISSION,
  GET_POOL_RESERVATIONS_LIST,
  CREATE_POOL_RESERVATION,
  UPDATE_POOL_RESERVATION,
  START_POOL_RESERVATION,
  CLOSE_AND_COMPLETE_RESERVATION,
  CANCEL_POOL_RESERVATION,
  GET_MOTOR_POOL_VEHICLES_LIST,
  DELETE_MOTOR_POOL_VEHICLE,
  UPDATE_MOTOR_POOL_VEHICLE,
  GET_MOTOR_POOL_MEMBERS,
  DELETE_MOTOR_POOL_MEMBER,
  GET_DISPATCHERS,
  GET_DRIVERS_AND_DISPATCHERS_BY_EMAIL,
  ADD_DRIVER,
  REQUEST_PUBLIC_POOL_ACCESS,
  GET_TIME_ZONES,
} from './motor-pool.gql';
import {
  CREATE_POOL_SUCCESS_MESSAGE,
  UPDATE_POOL_SUCCESS_MESSAGE,
  DELETE_POOL_SUCCESS_MESSAGE,
  DELETE_VEHICLE_SUCCESS_MESSAGE,
  CREATE_RESERVATION_SUCCESS_MESSAGE,
  UPDATE_RESERVATION_SUCCESS_MESSAGE,
  START_RESERVATION_SUCCESS_MESSAGE,
  CLOSE_RESERVATION_SUCCESS_MESSAGE,
  CANCEL_RESERVATION_SUCCESS_MESSAGE,
  UPDATE_POOL_VEHICLE_SUCCESS_MESSAGE,
  MEMBER_SUCCESS_MESSAGE,
  SELF_INVITE_SUCCESS_MESSAGE,
} from './messages';

export const MotorPoolContext = createContext();

export const MOTOR_POOL_LISTING_MODAL_MODES = {
  ADD_MOTOR_POOL_LISTING: 'ADD_MOTOR_POOL_LISTING',
  UPDATE_MOTOR_POOL_LISTING: 'UPDATE_MOTOR_POOL_LISTING',
  DELETE_MOTOR_POOL_LISTING: 'DELETE_MOTOR_POOL_LISTING',
  SELF_INVITE_MOTOR_POOL_LISTING: 'SELF_INVITE_MOTOR_POOL_LISTING',
};

export const MOTOR_POOL_VEHICLE_MODAL_MODES = {
  ADD_MOTOR_POOL_VEHICLE: 'ADD_MOTOR_POOL_VEHICLE',
  UPDATE_MOTOR_POOL_VEHICLE: 'UPDATE_MOTOR_POOL_VEHICLE',
  DELETE_MOTOR_POOL_VEHICLE: 'DELETE_MOTOR_POOL_VEHICLE',
  RESERVE_MOTOR_POOL_VEHICLE: 'RESERVE_MOTOR_POOL_VEHICLE',
};

export const RESERVATION_MODAL_MODES = {
  UPDATE_RESERVATION: 'UPDATE_RESERVATION',
  START_RESERVATION: 'START_RESERVATION',
  CANCEL_RESERVATION: 'CANCEL_RESERVATION',
  CLOSE_AND_COMPLETE_RESERVATION: 'CLOSE_AND_COMPLETE_RESERVATION',
};

export const MOTOR_POOL_MEMBER_MODAL_MODES = {
  ADD_MOTOR_POOL_MEMBER: 'ADD_MOTOR_POOL_MEMBER',
  DELETE_MOTOR_POOL_MEMBER: 'DELETE_MOTOR_POOL_MEMBER',
  ADD_DRIVER: 'ADD_DRIVER',
};

const initialState = {
  motorPoolsList: {
    rows: [],
    hasMore: false,
    count: 0,
  },
  reservationsList: {
    rows: [],
    hasMore: false,
    count: 0,
  },
  motorPoolMembersList: {
    rows: [],
    hasMore: false,
    count: 0,
  },
  motorPoolListingSelected: null,
  motorPoolVehicleSelected: null,
  vehiclesAvailableDatesSelected: null,
  reservationListingSelected: null,
  motorPoolIdsSelected: [],
  motorPoolListingModalMode: null,
  motorPoolVehicleModalMode: null,
  motorPoolMemberModalMode: null,
  reservationModalMode: null,
  motorPoolListingModalShow: false,
  motorPoolVehicleModalShow: false,
  motorPoolMemberModalShow: false,
  motorPoolExportModalShow: false,
  reservationModalShow: false,
  alertMessage: { context: '', message: '', type: '', header: null }, // type => error || success || info e.g.: => {type: 'error', 'message': 'Something went wrong', context: 'motorPoolListing'}
  motorPoolListingError: null,
  MOTOR_POOL_LISTING_MODAL_MODES,
  motorPoolAgency: null,
  motorPoolBureaus: [],
  agencies: [],
  primaryDispatchers: [],
  MOTOR_POOL_VEHICLE_MODAL_MODES,
  RESERVATION_MODAL_MODES,
  modalState: {
    currentModalName: null,
    currentMode: null,
    currentModalAlert: { type: null, message: null, header: null },
  },
  typeaheadDrivers: {
    values: [],
    filterValue: '',
  },
  motorPoolVehiclesList: {
    rows: [],
    hasMore: false,
    count: 0,
  },
  dispatchers: [],
  motorPoolTimezones: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_MOTOR_POOLS_LIST':
      return { ...state, motorPoolsList: action.payload };

    case 'SET_SELECTED_MOTOR_POOL_BY_ID':
      return { ...state, selectedMotorPoolById: action.payload };

    case 'SET_SELECTED_MOTOR_POOL':
      return { ...state, motorPoolListingSelected: action.payload };

    case 'SET_SELECTED_MOTOR_POOL_VEHICLE':
      return { ...state, motorPoolVehicleSelected: action.payload };

    case 'SET_SELECTED_VEHICLE_AVAILABLE_DATES':
      return { ...state, vehiclesAvailableDatesSelected: action.payload };

    case 'SET_SELECTED_MOTOR_POOL_MEMBER':
      return { ...state, motorPoolMemberSelected: action.payload };

    case 'SET_MOTOR_POOL_MODAL_MODE': {
      return { ...state, motorPoolListingModalMode: action.payload };
    }

    case 'SET_MOTOR_POOL_VEHICLE_MODAL_MODE': {
      return { ...state, motorPoolVehicleModalMode: action.payload };
    }

    case 'SET_SELECTED_RESERVATION':
      return { ...state, reservationListingSelected: action.payload };

    case 'SET_SELECTED_MOTOR_POOL_IDS':
      return {
        ...state,
        motorPoolIdsSelected: action.payload,
      };

    case 'SET_RESERVATION_MODAL_MODE': {
      return { ...state, reservationModalMode: action.payload };
    }

    case 'SET_SHOW_MOTOR_POOL_MODAL': {
      return { ...state, motorPoolListingModalShow: action.payload };
    }

    case 'SET_SHOW_MOTOR_POOL_VEHICLE_MODAL': {
      return { ...state, motorPoolVehicleModalShow: action.payload };
    }

    case 'SET_SHOW_MOTOR_POOL_MEMBER_MODAL': {
      return { ...state, motorPoolMemberModalShow: action.payload };
    }

    case 'SET_SHOW_RESERVATION_MODAL': {
      return { ...state, reservationModalShow: action.payload };
    }

    case 'SET_MODAL':
      return {
        ...state,
        modalState: { ...state.modalState, ...action.payload },
      };

    case 'SET_ALERT_MESSAGE':
      return { ...state, alertMessage: action.payload };

    case 'SET_MOTOR_POOL_AGENCY':
      return { ...state, motorPoolAgency: action.payload };

    case 'SET_AGENCIES':
      return { ...state, agencies: action.payload };

    case 'SET_MOTOR_POOL_BUREAUS':
      return { ...state, motorPoolBureaus: action.payload };

    case 'SET_MOTOR_POOL_VEHICLES_LIST':
      return { ...state, motorPoolVehiclesList: action.payload };

    case 'SET_MOTOR_POOL_MEMBERS':
      return { ...state, motorPoolMembersList: action.payload };
    case 'SET_DRIVERS_TYPEAHEAD':
      return action.payload?.values
        ? {
            ...state,
            typeaheadDrivers: {
              ...state.typeaheadDrivers,
              values: action.payload?.values,
            },
          }
        : {
            ...state,
            typeaheadDrivers: {
              ...state.typeaheadDrivers,
              filterValue: action.payload?.filterValue,
            },
          };

    case 'SET_MOTOR_POOL_MEMBER_MODAL_MODE':
      return { ...state, motorPoolMemberModalMode: action.payload };

    case 'SET_RESERVATIONS_LIST':
      return { ...state, reservationsList: action.payload };

    case 'RESET_MOTOR_POOL_VEHICLES_LIST':
      return {
        ...state,
        motorPoolVehiclesList: initialState.motorPoolVehiclesList,
      };

    case 'SET_DISPATCHERS':
      return { ...state, dispatchers: action.payload };

    case 'SET_PRIMARY_DISPATCHERS':
      return { ...state, primaryDispatchers: action.payload };

    case 'SET_TIMEZONES':
      return { ...state, motorPoolTimezones: action.payload };

    default:
      throw new Error('Invalid action');
  }
};

function MotorPoolProvider({ children, ...props }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const dispatchAction = (type, payload) => {
    dispatch({
      type,
      payload,
    });
  };

  const resetModal = () => {
    dispatchAction('SET_SHOW_MOTOR_POOL_MODAL', false);
    dispatchAction('SET_MOTOR_POOL_MODAL_MODE', null);
    dispatchAction('SET_SELECTED_MOTOR_POOL', null);
  };

  const resetMotorPoolVehicleModal = () => {
    dispatchAction('SET_SHOW_MOTOR_POOL_VEHICLE_MODAL', false);
    dispatchAction('SET_MOTOR_POOL_VEHICLE_MODAL_MODE', null);
    dispatchAction('SET_SELECTED_MOTOR_POOL_VEHICLE', null);
    // dispatchAction('SET_SELECTED_VEHICLE_AVAILABLE_DATES', null);
  };

  const resetMotorPoolMemberModal = () => {
    dispatchAction('SET_SHOW_MOTOR_POOL_MEMBER_MODAL', false);
    dispatchAction('SET_MOTOR_POOL_MEMBER_MODAL_MODE', null);
    dispatchAction('SET_SELECTED_MOTOR_POOL_MEMBER', null);
  };

  const resetReservationModal = () => {
    dispatchAction('SET_SHOW_RESERVATION_MODAL', false);
    dispatchAction('SET_RESERVATION_MODAL_MODE', null);
    dispatchAction('SET_SELECTED_RESERVATION', null);
  };

  const resetExportModal = () => {
    dispatchAction('SET_MODAL', initialState.modalState);
  };

  const resetCurrentModal = () => {
    dispatchAction('SET_MODAL', initialState.modalState);
  };

  const setRequestError = (requestError, context) => {
    dispatchAction('SET_ALERT_MESSAGE', {
      type: 'error',
      message: requestError?.message,
      error: requestError,
      context,
    });
  };

  // Get motor pools list.
  const [getMotorPoolsList, { refetch, loading: motorPoolsListLoading }] =
    useLazyQuery(GET_MOTOR_POOLS_LIST, {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onError: (error) => setRequestError(error, 'motorPoolListing'),
      onCompleted: (responseData) => {
        if (responseData?.getMotorPoolsList) {
          dispatchAction(
            'SET_MOTOR_POOLS_LIST',
            responseData.getMotorPoolsList,
          );
        }
      },
    });

  // Get motor pools list.
  const [getMotorPoolById, { loading: getMotorPoolByIdLoading }] = useLazyQuery(
    GET_MOTOR_POOL,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onError: (error) => setRequestError(error, 'reservationListing'),
      onCompleted: (responseData) => {
        if (responseData?.getMotorPool) {
          dispatchAction(
            'SET_SELECTED_MOTOR_POOL_BY_ID',
            responseData.getMotorPool,
          );
        }
      },
    },
  );

  // Get motor pool.
  const [
    getMotorPoolMembers,
    { refetch: refetchMotorPoolMembers, loading: motorPoolMembersLoading },
  ] = useLazyQuery(GET_MOTOR_POOL_MEMBERS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: (error) => setRequestError(error, 'motorPoolMembersListing'),
    onCompleted: (responseData) => {
      if (responseData?.getMotorPoolMembers) {
        dispatchAction(
          'SET_MOTOR_POOL_MEMBERS',
          responseData.getMotorPoolMembers,
        );
      }
    },
  });

  // create motor pool.
  const [addMotorPool, { loading: addMotorPoolLoading }] = useMutation(
    CREATE_MOTOR_POOL,
    {
      onError: (error) => setRequestError(error, 'addMotorPool'),
      onCompleted: (response) => {
        resetModal();
        const successMessage = CREATE_POOL_SUCCESS_MESSAGE.body(
          response?.createMotorPool?.poolName,
        );
        dispatchAction('SET_ALERT_MESSAGE', {
          context: 'motorPoolListing',
          type: 'success',
          message: successMessage,
        });

        refetch({
          variables: {
            limit: 10,
            offset: 0,
            order: 'updatedAt DESC',
          },
        });
      },
    },
  );

  // update motor pool.
  const [updateMotorPool, { loading: updateMotorPoolLoading }] = useMutation(
    UPDATE_MOTOR_POOL,
    {
      onError: (error) => setRequestError(error, 'updateMotorPool'),
      onCompleted: (response) => {
        resetModal();
        const successMessage = UPDATE_POOL_SUCCESS_MESSAGE.body(
          response?.updateMotorPool?.poolName,
        );
        dispatchAction('SET_ALERT_MESSAGE', {
          context: 'motorPoolListing',
          type: 'success',
          message: successMessage,
        });

        refetch({
          variables: {
            limit: 10,
            offset: 0,
            order: 'updatedAt DESC',
          },
        });
      },
    },
  );

  // delete motor pool.
  const [deleteMotorPool] = useMutation(DELETE_MOTOR_POOL, {
    onError: (error) => {
      setRequestError(error, 'deleteMotorPool');
      resetModal();
    },
    onCompleted: () => {
      resetModal();
      const successMessage = DELETE_POOL_SUCCESS_MESSAGE.body();
      dispatchAction('SET_ALERT_MESSAGE', {
        context: 'motorPoolListing',
        type: 'success',
        message: successMessage,
      });

      refetch({
        variables: {
          limit: 10,
          offset: 0,
          order: 'updatedAt DESC',
        },
      });
    },
  });

  // request access to public pool(selfInvite).
  const [requestPublicPoolAccess] = useMutation(REQUEST_PUBLIC_POOL_ACCESS, {
    onError: (error) => {
      setRequestError(error, 'motorPoolListing');
      resetModal();
    },
    onCompleted: (response) => {
      resetModal();
      const successMessage = SELF_INVITE_SUCCESS_MESSAGE.body(
        response?.requestPublicPoolAccess?.poolName,
      );
      dispatchAction('SET_ALERT_MESSAGE', {
        context: 'motorPoolListing',
        type: 'success',
        message: successMessage,
      });

      refetch({
        variables: {
          limit: 10,
          offset: 0,
          order: 'updatedAt DESC',
        },
      });
    },
  });

  const canPerformActions = useCanPerformActions();

  const [getAgenciesByPermission] = useLazyQuery(GET_AGENCIES_BY_PERMISSION, {
    fetchPolicy: 'network-only',
    onCompleted: ({ getAgenciesByPermission: data }) => {
      canPerformActions.canEditMotorPoolAgency()
        ? dispatchAction('SET_AGENCIES', data)
        : dispatchAction('SET_MOTOR_POOL_AGENCY', data[0]); // Only one agency should exist for dispatchers per GSA
    },
  });

  const [getBureausByPermission] = useLazyQuery(GET_BUREAUS_BY_PERMISSION, {
    fetchPolicy: 'network-only',
    onCompleted: ({ getBureausByPermission: data }) => {
      dispatchAction(
        'SET_MOTOR_POOL_BUREAUS',
        data.map((bureau) => ({
          value: bureau.id,
          label: `${bureau.id}-${bureau.name}`,
        })),
      );
    },
  });

  const [getDispatchers] = useLazyQuery(GET_DISPATCHERS, {
    fetchPolicy: 'network-only',
    onCompleted: ({ getScopedDispatchers: data }) => {
      if (data) {
        dispatchAction(
          'SET_DISPATCHERS',
          data.map((dispatcher) => ({
            value: dispatcher.id,
            label: dispatcher.fullName,
          })),
        );
      }
    },
  });

  const [getAgencyPrimaryDispatchers] = useLazyQuery(GET_DISPATCHERS, {
    fetchPolicy: 'network-only',
    onCompleted: ({ getScopedDispatchers: data }) => {
      if (data) {
        dispatchAction(
          'SET_PRIMARY_DISPATCHERS',
          data.map((dispatcher) => ({
            value: dispatcher.id,
            label: dispatcher.fullName,
          })),
        );
      }
    },
  });

  const [getDriversAndDispatchersByEmail] = useLazyQuery(
    GET_DRIVERS_AND_DISPATCHERS_BY_EMAIL,
    {
      fetchPolicy: 'network-only',
      onCompleted: ({ getScopedDriversAndDispatchersByEmail: data }) => {
        if (data) {
          dispatchAction('SET_DRIVERS_TYPEAHEAD', {
            values: data.map((member) => ({
              id: member.id,
              fullName: member.fullName,
              email: member.email,
            })),
          });
        }
      },
    },
  );

  // Get motor pools reservations.
  const [
    getPoolReservationsList,
    { refetch: refetchReservationsList, loading: reservationsListLoading },
  ] = useLazyQuery(GET_POOL_RESERVATIONS_LIST, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: (error) => setRequestError(error, 'reservationListing'),
    onCompleted: (responseData) => {
      if (responseData?.getPoolReservationsList) {
        dispatchAction(
          'SET_RESERVATIONS_LIST',
          responseData.getPoolReservationsList,
        );
      }
    },
  });

  // Get motor pools vehicles list.
  const [
    getMotorPoolVehiclesList,
    {
      refetch: refetchMotorPoolVehicles,
      loading: motorPoolVehiclesListLoading,
    },
  ] = useLazyQuery(GET_MOTOR_POOL_VEHICLES_LIST, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: (error) => setRequestError(error, 'motorPoolVehicleListing'),
    onCompleted: (responseData) => {
      if (responseData?.getMotorPoolVehiclesList) {
        dispatchAction(
          'SET_MOTOR_POOL_VEHICLES_LIST',
          responseData.getMotorPoolVehiclesList,
        );
      }
    },
  });

  // Get motor pools reservations.
  const [getMotorPoolTimezones, { loading: motorPoolTimezonesLoading }] =
    useLazyQuery(GET_TIME_ZONES, {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onError: (error) => setRequestError(error, 'motorPoolTimezones'),
      onCompleted: (responseData) => {
        if (responseData?.getTimeZones) {
          dispatchAction('SET_TIMEZONES', responseData.getTimeZones);
        }
      },
    });

  // create pool reservation.
  const [createPoolReservation, { loading: createReservationLoading }] =
    useMutation(CREATE_POOL_RESERVATION, {
      onError: (error) => setRequestError(error, 'createReservation'),
      onCompleted: () => {
        resetMotorPoolVehicleModal();
        const successMessage = CREATE_RESERVATION_SUCCESS_MESSAGE.body();
        dispatchAction('SET_ALERT_MESSAGE', {
          context: 'motorPoolVehicleListing',
          type: 'success',
          message: successMessage,
        });

        refetchMotorPoolVehicles({
          variables: {
            limit: 10,
            offset: 0,
            order: 'updatedAt DESC',
          },
        });
      },
    });

  // update pool reservation.
  const [updatePoolReservation, { loading: updateReservationLoading }] =
    useMutation(UPDATE_POOL_RESERVATION, {
      onError: (error) => setRequestError(error, 'updateReservation'),
      onCompleted: () => {
        resetReservationModal();
        const successMessage = UPDATE_RESERVATION_SUCCESS_MESSAGE.body();
        dispatchAction('SET_ALERT_MESSAGE', {
          context: 'reservationListing',
          type: 'success',
          message: successMessage,
        });

        refetchReservationsList({
          variables: {
            limit: 10,
            offset: 0,
            order: 'updatedAt DESC',
          },
        });
      },
    });

  // start reservation.
  const [startPoolReservation, { loading: startReservationLoading }] =
    useMutation(START_POOL_RESERVATION, {
      onError: (error) => setRequestError(error, 'startReservation'),
      onCompleted: () => {
        resetReservationModal();
        const successMessage = START_RESERVATION_SUCCESS_MESSAGE.body();
        dispatchAction('SET_ALERT_MESSAGE', {
          context: 'reservationListing',
          type: 'success',
          message: successMessage,
        });
        refetchReservationsList({
          variables: {
            limit: 10,
            offset: 0,
            order: 'updatedAt DESC',
          },
        });
      },
    });

  // close and complete reservation.
  const [closeAndCompleteReservation, { loading: completeReservationLoading }] =
    useMutation(CLOSE_AND_COMPLETE_RESERVATION, {
      onError: (error) => setRequestError(error, 'closeReservation'),
      onCompleted: () => {
        resetReservationModal();
        const successMessage = CLOSE_RESERVATION_SUCCESS_MESSAGE.body();
        dispatchAction('SET_ALERT_MESSAGE', {
          context: 'reservationListing',
          type: 'success',
          message: successMessage,
        });
        refetchReservationsList({
          variables: {
            limit: 10,
            offset: 0,
            order: 'updatedAt DESC',
          },
        });
      },
    });

  // cancel reservation.
  const [cancelPoolReservation] = useMutation(CANCEL_POOL_RESERVATION, {
    onError: (error) => {
      setRequestError(error, 'cancelReservation');
      resetReservationModal();
    },
    onCompleted: () => {
      resetReservationModal();
      const successMessage = CANCEL_RESERVATION_SUCCESS_MESSAGE.body();
      dispatchAction('SET_ALERT_MESSAGE', {
        context: 'reservationListing',
        type: 'success',
        message: successMessage,
      });

      refetchReservationsList({
        variables: {
          limit: 10,
          offset: 0,
          order: 'updatedAt DESC',
        },
      });
    },
  });

  // delete motor pool vehicle.
  const [deleteMotorPoolVehicle] = useMutation(DELETE_MOTOR_POOL_VEHICLE, {
    onError: (error) => {
      setRequestError(error, 'deleteMotorPoolVehicle');
      resetMotorPoolVehicleModal();
    },
    onCompleted: () => {
      resetMotorPoolVehicleModal();
      const successMessage = DELETE_VEHICLE_SUCCESS_MESSAGE.body();
      dispatchAction('SET_ALERT_MESSAGE', {
        type: 'success',
        message: successMessage,
        context: 'motorPoolVehicleListing',
      });

      refetchMotorPoolVehicles({
        variables: {
          limit: 10,
          offset: 0,
          order: 'updatedAt DESC',
        },
      });
    },
  });

  // update motor pool vehicle.
  const [updateMotorPoolVehicle, { loading: updateMotorPoolVehicleLoading }] =
    useMutation(UPDATE_MOTOR_POOL_VEHICLE, {
      onError: (error) => setRequestError(error, 'updateMotorPoolVehicle'),
      onCompleted: () => {
        resetMotorPoolVehicleModal();
        const successMessage = UPDATE_POOL_VEHICLE_SUCCESS_MESSAGE.body();
        dispatchAction('SET_ALERT_MESSAGE', {
          context: 'motorPoolVehicleListing',
          type: 'success',
          message: successMessage,
        });
        refetchMotorPoolVehicles({
          variables: {
            limit: 10,
            offset: 0,
            order: 'updatedAt DESC',
          },
        });
      },
    });

  // delete motor pool member.
  const [deleteMotorPoolMember] = useMutation(DELETE_MOTOR_POOL_MEMBER, {
    onError: (error) => {
      setRequestError(error, 'motorPoolMembersListing');
      resetMotorPoolMemberModal();
    },
    onCompleted: () => {
      const { selectedMotorPoolById, motorPoolMemberSelected } = state;
      const successMessage = MEMBER_SUCCESS_MESSAGE(
        selectedMotorPoolById?.poolName,
        motorPoolMemberSelected?.member?.fullName,
        'removed from',
      ).body();
      resetMotorPoolMemberModal();
      dispatchAction('SET_ALERT_MESSAGE', {
        type: 'success',
        message: successMessage,
        context: 'motorPoolMembersListing',
      });

      refetchMotorPoolMembers({
        variables: {
          limit: 10,
          offset: 0,
          order: 'updatedAt DESC',
        },
      });
    },
  });

  // export

  const [exportReport, { loading: exportReportLoading }] = useMutation(
    EXPORT_REPORT,
    {
      fetchPolicy: 'no-cache',
      onCompleted: () => {
        resetExportModal();
        dispatchAction('SET_ALERT_MESSAGE', {
          context: 'motorPoolListing',
          type: 'success',
          header: 'Export Initiated:',
          message: exportMessages.exportSuccessMessage.message,
        });
      },

      onError: (error) => setRequestError(error, 'motorPoolListing'),
    },
  );

  //  addMotorPoolDriver
  const [addMotorPoolDriver] = useMutation(ADD_DRIVER, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (data) {
        const { selectedMotorPoolById } = state;
        const fullName = data?.addMotorPoolDriver?.fullName;
        const successMessage = MEMBER_SUCCESS_MESSAGE(
          selectedMotorPoolById?.poolName,
          fullName.trim(),
          'added to',
        ).body();
        resetMotorPoolMemberModal();
        dispatchAction('SET_ALERT_MESSAGE', {
          type: 'success',
          message: successMessage,
          context: 'motorPoolMembersListing',
        });

        refetchMotorPoolMembers({
          variables: {
            limit: 10,
            offset: 0,
            order: 'updatedAt DESC',
          },
        });
      }
    },

    onError: (error) => {
      setRequestError(error, 'motorPoolVehicleListing');
      resetMotorPoolMemberModal();
    },
  });

  return (
    <MotorPoolContext.Provider
      value={{
        ...state,
        getMotorPoolMembers,
        motorPoolMembersLoading,
        refetchMotorPoolMembers,
        addMotorPoolLoading,
        updateMotorPoolLoading,
        getMotorPoolsList,
        getMotorPoolById,
        getMotorPoolByIdLoading,
        dispatchAction,
        motorPoolsListLoading,
        addMotorPool,
        updateMotorPool,
        deleteMotorPool,
        requestPublicPoolAccess,
        resetModal,
        getAgenciesByPermission,
        getBureausByPermission,
        resetReservationModal,
        resetExportModal,
        getPoolReservationsList,
        reservationsListLoading,
        createPoolReservation,
        updatePoolReservation,
        startPoolReservation,
        closeAndCompleteReservation,
        createReservationLoading,
        updateReservationLoading,
        startReservationLoading,
        completeReservationLoading,
        cancelPoolReservation,
        resetMotorPoolVehicleModal,
        getMotorPoolVehiclesList,
        motorPoolVehiclesListLoading,
        deleteMotorPoolVehicle,
        updateMotorPoolVehicle,
        updateMotorPoolVehicleLoading,
        setRequestError,
        deleteMotorPoolMember,
        resetMotorPoolMemberModal,
        getDispatchers,
        getAgencyPrimaryDispatchers,
        getDriversAndDispatchersByEmail,
        exportReport,
        exportReportLoading,
        addMotorPoolDriver,
        getMotorPoolTimezones,
        motorPoolTimezonesLoading,
        resetCurrentModal,
        ...props,
      }}
    >
      {children}
    </MotorPoolContext.Provider>
  );
}

export default MotorPoolProvider;

MotorPoolProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export const useMotorPool = () => useContext(MotorPoolContext);
