/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { MultiSelectDropdown, Spinner } from '@gsa/afp-component-library';
import { Controller, useWatch } from 'react-hook-form';
import useDataController from '../use-data-controller';
import { useExport } from '../export-provider';

const getBoacsOptions = (items = []) => {
  if (items?.length === 0) return [];
  return items?.map((item) => ({
    value: item.boac,
    label: item.boac,
  }));
};

// eslint-disable-next-line react/prop-types
export const BoacsSelection = ({ control, setValue }) => {
  const [boacs, setBoacs] = useState();
  const [internalError, setInternalError] = useState();
  const { query, loading } = useDataController({
    setInternalError,
    setBoacs,
  });

  const { selectedReport } = useExport();

  const [agency, bureau] = useWatch({
    name: ['agency', 'bureau', 'boac'],
    control,
  });

  useEffect(() => {
    setBoacs([]);
    setValue('boac', []);
    if (agency !== 'DEFAULT' && bureau && bureau !== 'DEFAULT') {
      query.boacsQuery({
        variables: {
          getBoacsArgs: {
            agencyCode: agency !== 'DEFAULT' ? agency : null,
            bureauCode: bureau !== 'DEFAULT' ? bureau : null,
          },
        },
      });
    }
  }, [agency, bureau]);

  useEffect(() => {
    if (boacs?.length > 0 && selectedReport?.reportCriteria?.boac) {
      setValue('boac', selectedReport?.reportCriteria?.boac);
    }
  }, [boacs, selectedReport?.reportCriteria?.boac]);

  if (internalError && internalError[0]?.message.includes('Not Authorized!')) {
    return <Redirect to="/unauthorized" />;
  }

  const validateBoac = () => {
    return {
      validate: (value) => {
        if (value.length > 10) return 'Selections should be less than 10';
        return undefined;
      },
    };
  };

  return (
    <div className="margin-bottom-3">
      <label className="usa-label margin-bottom-1">
        <span className="text-bold">BOAC</span>
      </label>

      <Controller
        control={control}
        name="boac"
        rules={validateBoac()}
        render={({
          field: { ref, onChange, value, ...rest },
          formState: { errors },
        }) => (
          <>
            {errors.boac?.message && (
              <span className="usa-error-message margin-bottom-2">
                {errors.boac?.message}
              </span>
            )}
            {/* // No BOACs found for selected Agency and Bureau. // Please make */}
            {/*  Please make sure to select an Agency and Bureau first. */}
            {loading.boacsQueryLoading ? (
              <Spinner size="small" data-testid="boacs-query-spinner" />
            ) : (
              <>
                <MultiSelectDropdown
                  label={<b>BOAC</b>}
                  name="boac"
                  id="boac"
                  selectedValues={value}
                  key={bureau}
                  required
                  disabled={
                    agency === 'DEFAULT' || !bureau || bureau === 'DEFAULT'
                  }
                  placeholder={
                    agency === 'DEFAULT' || bureau === 'DEFAULT'
                      ? 'Select an Agency and Bureau first'
                      : 'Select BOAC'
                  }
                  onChange={(items) => {
                    onChange(items);
                  }}
                  options={[...getBoacsOptions(boacs)]}
                  noResults={
                    agency === 'DEFAULT' && bureau === 'DEFAULT'
                      ? ' Please make sure to select an Agency and Bureau first.'
                      : 'No BOACs found for selected Agency and Bureau.'
                  }
                  {...rest}
                />
                <span className="usa-sr-only">
                  This field is disabled, please select an Agency and Bureau
                  first
                </span>
              </>
            )}
          </>
        )}
      />
    </div>
  );
};
