import { gql } from '@apollo/client';

export const VALIDATE_ADDRESS = gql`
  query ValidateAddress(
    $address: String!
    $address2: String
    $city: String!
    $state: String!
    $zip: String!
  ) {
    validateAddress(
      address: $address
      address2: $address2
      city: $city
      state: $state
      zip: $zip
    ) {
      address
      address2
      city
      state
      zip
    }
  }
`;

export const GET_COUNTRIES = gql`
  query GetCountries {
    getCountries {
      id
      isoCountryCode2
      isoCountryCode3
      countryName
    }
  }
`;

export const GET_STATES = gql`
  query GetStates {
    getStates {
      id
      stateCode
      stateName
      isoCountryCode2
    }
  }
`;

export const GET_PHONE_COUNTRY_CODES = gql`
  query GetPhoneCountryCodes {
    getPhoneCountryCodes {
      code
      displayText
      description
    }
  }
`;

