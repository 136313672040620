/* eslint-disable react/prop-types */
import React, { useRef, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Typeahead } from '@gsa/afp-component-library';
import { FormGenerator } from '@gsa/afp-shared-form-utils';
import { GET_TAGS_BY_FOR_VEHICLE_PLATE_CHANGE } from 'services/data-layer';
import { camelCase } from 'lodash';
import { changePlateSchema } from './update-plate-schema';

const REASONS = [
  'Plate damaged',
  'Plate Missing',
  'Mistakenly attached',
  'Reassignment',
  'Consolidation',
];

const formatReasonOptions = () => {
  return REASONS.map((reason) => {
    return {
      label: reason,
      value: camelCase(reason),
    };
  });
};

const UpdatePlateForm = ({ onSubmit }) => {
  const changePlateRef = useRef(null);
  const [tags, setTags] = useState([]);

  const [getTagsByPartialTagNumber] = useLazyQuery(
    GET_TAGS_BY_FOR_VEHICLE_PLATE_CHANGE,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        setTags(
          data?.getTagsForVehiclePlateChange?.length > 0
            ? data.getTagsForVehiclePlateChange
            : [],
        );
      },
      onError(err) {
        // eslint-disable-next-line no-console
        console.warn(err);
      },
    },
  );

  const sections = [
    {
      fieldLayout: 'vertical',
      fields: [
        {
          id: 'tagNumber',
          columnWidth: 'col-5-12',
          component: (config, useFormContext) => {
            const { setValue, getValues, clearErrors } = useFormContext();
            return (
              <>
                <Typeahead
                  id="license-plate"
                  label="Search for new plate"
                  name="licensePlate"
                  date-testid="licensePlate"
                  filterValue={getValues('tagNumber') || ''}
                  typeaheadValues={tags.map((tag) => tag.id)}
                  fetchTypeaheadValues={(accessor, value) => {
                    getTagsByPartialTagNumber({
                      variables: { partialTagNumber: value },
                    });
                  }}
                  promptText="Minimum of 4 characters required."
                  placeholder="Enter license plate"
                  inputCharNum={4}
                  ariaLabel="Enter license plate"
                  debounceDelay={500}
                  noResultsText="No license plates found for the given search."
                  onOptionEnter={(value) => {
                    setValue('tagNumber', value);
                    clearErrors('tagNumber');
                  }}
                  onClear={() => {
                    setValue('tagNumber', '');
                  }}
                />
                <div className="usa-hint">
                  Minimum of 4 characters required.
                </div>
              </>
            );
          },
        },
        {
          id: 'reason',
          columnWidth: 'col-5-12',
          type: 'select',
          label: 'Reason',
          required: true,
          options: [{ label: '', value: '' }, ...formatReasonOptions()],
        },
      ],
    },
  ];

  const formContent = {
    buttonControls: {
      submit: false,
      cancel: false,
    },
    sections,
  };

  return (
    <FormGenerator
      ref={changePlateRef}
      id="change-plate-form"
      data-testid="change-plate-form"
      schema={changePlateSchema}
      content={formContent}
      onSubmit={onSubmit}
      useFormProps={{
        mode: 'onBlur',
        reValidateMode: 'onBlur',
      }}
    />
  );
};

export default UpdatePlateForm;
