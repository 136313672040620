import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Pagination as GSAPagination } from '@gsa/afp-component-library';

/**
 * usePagination provides a standard implementation of Pagination used in
 * conjunction with a Table
 * Args:
 *     initState (optional) - object to set the initial state with available
 *         fields: itemsPerPageOptions, currentPage, and itemsPerPage
 * Return values in an array:
 *     Pagination - the UI component
 *     Object of  {
 *         offset, // state variable for data fetching
 *         limit, // state variable for data fetching
 *         setItemsCount, // funtion to set the total number of items
 *         resetPage, // function to reset page number to 1
 *     }
 */
const usePagination = (initState) => {
  const itemsPerPageOptions = initState?.itemsPerPageOptions;
  const defaultOption = itemsPerPageOptions?.length
    ? itemsPerPageOptions[0]
    : 10;
  const page = initState?.currentPage || 1;
  const items = initState?.itemsPerPage || defaultOption;
  const [currentPage, setCurrentPage] = useState(page);
  const [limit, setLimit] = useState(items);
  const [offset, setOffset] = useState((page - 1) * items);
  const [itemsCount, setItemsCount] = useState(undefined);
  const [isReset, setIsReset] = useState(false);

  const resetPage = () => {
    setCurrentPage(1);
    setOffset(0);
    setIsReset(true);
    setTimeout(() => setIsReset(false), 100);
  };

  const onPageChange = (pageNum, itemsPP) => {
    if (pageNum !== currentPage) {
      setCurrentPage(pageNum);
      setOffset((pageNum - 1) * limit);
    }
    if (itemsPP !== limit) {
      setLimit(itemsPP);
      resetPage();
    }
  };

  const Pagination = ({ loading }) => {
    if (loading) return null;
    return (
      <GSAPagination
        variant="advanced"
        currentPage={currentPage}
        itemsPerPage={limit}
        itemsCount={itemsCount}
        onPageChange={onPageChange}
        isReset={isReset}
        itemsPerPageOptions={itemsPerPageOptions}
      />
    );
  };
  Pagination.propTypes = {
    loading: PropTypes.bool,
  };
  Pagination.defaultProps = {
    loading: false,
  };

  return [
    Pagination,
    { offset, limit, currentPage, itemsCount, setItemsCount, resetPage },
  ];
};

export default usePagination;
