import React, { useState, useReducer, useEffect } from 'react';
import moment from 'moment';
import { Table } from '@gsa/afp-component-library';
import { useLicensePlateDetails } from '../license-plate-details-provider';

// SORTING
const sortingReducer = (state, action) => {
  return {
    ...state,
    sortBy: action?.sortBy,
    sortOrder: action?.sortOrder,
  };
};

const initialSortingState = {
  sortBy: 'recordedAt',
  sortOrder: 'DESC',
};

// SORT END

const tableHeaderData = [
  {
    name: 'Expiration Date',
    accessor: 'expirationDate',
    sortable: true,
    className: '',
  },
  {
    name: 'Date updated',
    accessor: 'recordedAt',
    sortable: true,
    className: '',
  },
  {
    name: 'Activity',
    accessor: 'activityType.description',
    sortable: true,
    className: '',
  },
  {
    name: 'A or B',
    accessor: 'aOrB',
    sortable: true,
    className: '',
  },
  {
    name: 'Reference',
    accessor: 'serialNumberVin',
    sortable: true,
    className: '',
  },
  {
    name: 'Performed By',
    accessor: 'performedBy',
    sortable: true,
    className: '',
  },
];

export const tagActivityRows = (rows) =>
  rows &&
  rows.map((row) => {
    const {
      performedAt,
      expirationDate,
      aOrB,
      activityType,
      performedByUser,
      serialNumberVin,
    } = row;
    return {
      expirationDate: {
        value: expirationDate
          ? moment(expirationDate, 'YYYYMM').format('MM/YYYY')
          : null,
        className: '',
      },
      performedAt: {
        value: performedAt
          ? moment(performedAt).format('MM/DD/YYYY . h:mm a')
          : null,
        className: '',
      },
      typeCode: {
        value: `${activityType?.description}`,
        className: '',
      },
      aOrB: {
        value: aOrB,
        className: '',
      },
      serialNumberVin: {
        value: serialNumberVin ? (
          <a href={`/vehicles/${encodeURIComponent(serialNumberVin)}`}>
            {serialNumberVin}
          </a>
        ) : (
          ''
        ),
        className: '',
      },
      performedBy: {
        value: performedByUser?.fullName,
        className: '',
      },
    };
  });

const LpTableList = () => {
  const { tagActivityList, setFilters } = useLicensePlateDetails();
  const [expirationDate, setExpirationDate] = useState('');

  const [sortingState, setSortingState] = useReducer(
    sortingReducer,
    initialSortingState,
  );

  const handleSort = (accessor) => {
    const oldSort = sortingState;
    let sortOrder;
    let sortBy;
    if (accessor?.payload === 'ASC') {
      sortOrder = 'ASC';
      sortBy = oldSort?.sortBy;
      setSortingState({
        sortOrder,
        sortBy,
      });
    } else if (accessor?.payload === 'DESC') {
      sortOrder = 'DESC';
      sortBy = oldSort?.sortBy;
      setSortingState({
        sortOrder: 'DESC',
        sortBy,
      });
    } else {
      sortOrder = 'ASC';
      sortBy = oldSort?.sortBy;
      setSortingState({
        sortOrder: 'ASC',
        sortBy: accessor?.payload,
      });
    }
    callSetFilters(expirationDate, sortBy, sortOrder);
  };

  const callSetFilters = (expirationDate, sortBy, sortOrder) => {
    let filters;
    let order;
    if (expirationDate) {
      filters = [
        {
          conditions: [
            {
              key: 'expirationDate',
              operator: '$exact',
              value: expirationDate,
            },
          ],
          operator: '$and',
        },
      ];
    }
    if (sortBy && sortOrder) {
      order = [[sortBy, sortOrder]];
    }
    setFilters({ filters, order });
  };

  useEffect(() => {
    if (window.location.hash === '#license-plate-history') {
      document.querySelector('#react-tabs-0')?.focus();
    }
  }, [window.location.hash]);

  // TODO replace with AFPTable
  return (
    <div className="afp-table-container--scrollable">
      <Table
        className="afp-responsive-table usa-table usa-table--borderless usa-table--full-width"
        bordered={false}
        caption=""
        fullWidth
        fixed
        header={tableHeaderData}
        sortingState={sortingState}
        setSortingState={handleSort}
        rows={tagActivityRows(tagActivityList)}
      />
    </div>
  );
};

export default LpTableList;
