/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  AFPTable,
  Menu,
  EmptyState,
  Pagination,
  Spinner,
} from '@gsa/afp-component-library';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import { VMSOperations, VMSSubjects } from 'utilities/consts';
import classnames from 'classnames';
import Clamp from 'components/common/clamp/clamp';
import { emdash } from 'components/common';
import { useMotorPool } from '../../motor-pool-provider';
import { useMotorPoolFilter } from '../../filters/filter-provider';
import MotorPoolVehiclesModal from './motor-pool-vehicles-modal';
import { VehicleTableCell } from '../vehicle-table-cell';

const renderRowSubComponent = ({ row: { original } }) => {
  const { location, comment } = original;
  const borderClasses = classnames(
    'border-bottom border-base-lighter padding-y-1 display-flex flex-justify',
  );
  return (
    <div className="grid-row">
      <div className="tablet:grid-col-6">
        <div className={`${borderClasses}`}>
          <strong>Vehicle location</strong>
          {location ? (
            <span className="margin-left-3 float-right align-right">
              <Clamp text={location} />
            </span>
          ) : (
            emdash
          )}
        </div>
        <div className={`${borderClasses}`}>
          <strong>Comment</strong>
          {comment ? (
            <span className="margin-left-3 float-right align-right">
              <Clamp text={comment} />
            </span>
          ) : (
            emdash
          )}
        </div>
      </div>
    </div>
  );
};

const actionList = [
  {
    icon: 'event',
    label: 'Reserve vehicle',
    action: 'reserveMotorPoolVehicle',
    operation: VMSOperations.Create,
    subject: VMSSubjects.MOTOR_POOL_RESERVATION,
  },
  {
    icon: 'edit',
    label: 'Edit vehicle details',
    action: 'editMotorPoolVehicle',
    subject: VMSSubjects.MOTOR_POOL,
    operation: VMSOperations.Update,
  },
  {
    icon: 'delete',
    label: 'Remove vehicle',
    action: 'deleteMotorPoolVehicle',
    subject: VMSSubjects.MOTOR_POOL,
    operation: VMSOperations.Delete,
  },
];

const initialPaginationState = {
  limit: 10,
  offset: 0,
  currentPage: 1,
  isReset: false,
};

const tableRef = React.createRef();

const MotorPoolVehiclesTable = () => {
  const params = useParams();
  const { id } = params;
  const ability = useAppAbility();
  const [paginationState, setPaginationState] = useState(
    initialPaginationState,
  );
  const [order, setOrder] = useState('`vehicle.vin` ASC');

  const {
    getMotorPoolVehiclesList,
    motorPoolVehiclesList,
    motorPoolVehiclesListLoading,
    dispatchAction,
    resetMotorPoolVehicleModal,
    selectedMotorPoolById,
  } = useMotorPool();

  const { filters } = useMotorPoolFilter();

  const getData = () => {
    getMotorPoolVehiclesList({
      variables: {
        motorPoolId: id,
        limit: paginationState.limit,
        offset: paginationState.offset,
        order,
        filters: filters.vehiclesListing,
      },
    });
  };

  const resetPagination = () => {
    setPaginationState({
      ...paginationState,
      offset: 0,
      currentPage: 1,
      isReset: true,
    });
  };

  useEffect(() => {
    dispatchAction('SET_ALERT_MESSAGE', { type: '', message: '' });
    resetMotorPoolVehicleModal();
    getData();
  }, []);

  useEffect(() => {
    if (motorPoolVehiclesList.rows.length > 0) {
      getData();
    }
  }, [paginationState, order]);

  useEffect(() => {
    resetPagination();
    getData();
  }, [filters?.vehiclesListing]);

  const handleSelectedAction = (action, row) => {
    if (row) {
      dispatchAction('SET_SELECTED_MOTOR_POOL_VEHICLE', row);
    }
    if (action === 'reserveMotorPoolVehicle')
      dispatchAction(
        'SET_MOTOR_POOL_VEHICLE_MODAL_MODE',
        'RESERVE_MOTOR_POOL_VEHICLE',
      );
    if (action === 'editMotorPoolVehicle')
      dispatchAction(
        'SET_MOTOR_POOL_VEHICLE_MODAL_MODE',
        'UPDATE_MOTOR_POOL_VEHICLE',
      );
    if (action === 'deleteMotorPoolVehicle')
      dispatchAction(
        'SET_MOTOR_POOL_VEHICLE_MODAL_MODE',
        'DELETE_MOTOR_POOL_VEHICLE',
      );
    dispatchAction('SET_SHOW_MOTOR_POOL_VEHICLE_MODAL', true);
  };

  const columns = [
    {
      Header: 'Vehicle',
      accessor: 'vehicle.vin',
      sortable: true,
      headerClassName: 'width-card-lg',
      // eslint-disable-next-line
      Cell: ({ value, row: { original } }) => (
        <VehicleTableCell
          key={value}
          // eslint-disable-next-line react/prop-types
          value={original.vehicle}
          // eslint-disable-next-line react/prop-types
          showLink={selectedMotorPoolById?.managesPool}
        />
      ),
    },
    {
      Header: 'Plate number',
      accessor: 'vehicle.tagNumber',
      sortable: true,
      headerClassName: 'width-card-lg',
      Cell: ({ value }) => {
        return value || emdash;
      },
    },
    {
      Header: 'Ownership',
      accessor: 'vehicle.ownershipTypeCode',
      sortable: true,
      Cell: ({ value }) => {
        return value === 'AO' ? 'Agency Owned' : 'GSA Leased';
      },
    },
    {
      Header: 'Actions',
      sortable: false,
      Cell: ({ row: { original } }) => {
        let allowedActions = [...actionList];

        if (!selectedMotorPoolById?.managesPool)
          allowedActions = allowedActions.splice(0, 1);

        const hasTimeZone = selectedMotorPoolById?.timezone;
        const isMotorPoolMember = selectedMotorPoolById?.isMotorPoolMember;

        allowedActions = allowedActions.filter((action) => {
          const hasAbility = ability.can(action.operation, action.subject);
          const canShow = hasTimeZone && isMotorPoolMember;
          if (action.action === 'reserveMotorPoolVehicle') {
            return hasAbility && canShow;
          }
          return hasAbility;
        });

        if (allowedActions?.length === 0) {
          return <>{emdash}</>;
        }

        return (
          <div className="height-4 width-4 margin-left-05em">
            <Menu
              actionIconSize="usa-icon--size-4"
              menuItems={allowedActions}
              onActionClick={(action) => {
                // eslint-disable-next-line react/prop-types
                handleSelectedAction(action, original);
              }}
              iconColor="text-primary"
              menuDialogState="hover"
            />
          </div>
        );
      },
    },
  ];

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    // Calculate new offset.
    const offset = (currentPage - 1) * itemsPerPage;
    setPaginationState({
      limit: itemsPerPage,
      offset,
      currentPage,
    });
  };

  return (
    <>
      <AFPTable
        fullWidth
        ref={tableRef}
        testId="motor-pool-vehicles-listing-table"
        columns={columns}
        defaultSort={order}
        onSort={setOrder}
        data={!motorPoolVehiclesListLoading ? motorPoolVehiclesList.rows : []}
        expandable
        renderRowSubComponent={renderRowSubComponent}
      />
      {motorPoolVehiclesListLoading && <Spinner className="padding-y-9" />}
      {motorPoolVehiclesList?.rows?.length > 0 && (
        <Pagination
          fullWidth
          variant="advanced"
          itemsPerPageOptions={[10, 25, 50]}
          itemsCount={motorPoolVehiclesList.count}
          itemsPerPage={paginationState.limit}
          currentPage={paginationState.currentPage}
          onPageChange={handlePaginationChange}
          isReset={paginationState.isReset}
        />
      )}
      {(!motorPoolVehiclesList || motorPoolVehiclesList.rows.length === 0) &&
        !motorPoolVehiclesListLoading && (
          <div className="bg-gray-3 padding-y-5">
            <div className="text-center padding-y-4">
              <EmptyState alt="Image not available" hasBackground />
            </div>
            <div
              className="text-center text-bold"
              data-testid="motor-pools-vehicles-no-data"
            >
              No motor pools vehicles available
            </div>
            <div className="text-center margin-top-2">
              Add or remove filters to search motor pools vehicles
            </div>
          </div>
        )}
      <MotorPoolVehiclesModal />
    </>
  );
};

export default MotorPoolVehiclesTable;
