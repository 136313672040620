/* eslint-disable filenames/match-exported */
import { STATES_LIST } from 'utilities/consts';
import { validation } from './form-rule-map';

export const pageId = 'expenseUpdate';

export const transactionStateSchema = {
  lastFourAccountNum: {
    default: {
      value: '',
    },
  },
  lastFourCardNum: {
    default: {
      value: '',
    },
  },
  transactionDate: {
    default: {
      value: '',
    },
    validate: ({ value }) => {
      const requiredErrorInfo = validation.required({ value });
      if (requiredErrorInfo.errorMessage) {
        return requiredErrorInfo;
      }
      const validYearError = validation.isValidYear(1900)({
        value,
      });

      if (validYearError.errorMessage) {
        return validYearError;
      }
      return validYearError;
    },
  },
  productDescription: {
    default: {
      value: 'DEFAULT',
      options: [{ value: 'DEFAULT', label: '-Select-' }],
    },
    validate: validation.notDefault,
  },
  unitOfMeasure: {
    default: {
      value: 'DEFAULT',
      options: [{ value: 'DEFAULT', label: '-Select-' }],
    },
    validate: validation.notDefault,
  },
  transactionOdometer: {
    default: {
      value: '',
    },
    validate: validation.maxValue(4294967295),
  },
  unitCost: {
    default: {
      value: '',
    },
    validate: ({ value }) => {
      const requiredErrorInfo = validation.required({ value });
      if (requiredErrorInfo.errorMessage) {
        return requiredErrorInfo;
      }
      const maxValueErrorInfo = validation.maxValue(999999999999.9999)({
        value,
      });

      if (maxValueErrorInfo.errorMessage) {
        return maxValueErrorInfo;
      }
      return maxValueErrorInfo;
    },
  },
  quantity: {
    default: {
      value: '',
    },
    validate: ({ value }) => {
      const requiredErrorInfo = validation.required({ value });
      if (requiredErrorInfo.errorMessage) {
        return requiredErrorInfo;
      }
      const maxValueErrorInfo = validation.maxValue(999999999999.9999)({
        value,
      });
      if (maxValueErrorInfo.errorMessage) {
        return maxValueErrorInfo;
      }
      return maxValueErrorInfo;
    },
  },
  netDollar: {
    default: {
      value: '',
    },
    validate: validation.maxValue(999999999999.9999),
  },
  taxAmount: {
    default: {
      value: '',
    },
    validate: validation.maxValue(999999999999.9999),
  },
  totalCost: {
    default: {
      value: '',
    },
    validate: validation.maxValue(999999999999.9999),
  },
};

const merchantStateSchema = {
  merchantName: {
    default: {
      value: '',
    },
  },
  phone: {
    default: {
      value: '',
    },
    validate: validation.phoneNumber,
  },
  extension: {
    default: {
      value: '',
    },
  },
  address: {
    default: {
      value: '',
    },
  },
  address2: {
    default: {
      value: '',
    },
  },
  address3: {
    default: {
      value: '',
    },
  },
  city: {
    default: {
      value: '',
    },
  },
  state: {
    default: {
      value: 'DEFAULT',
      options: [
        { value: 'DEFAULT', label: '-Select-' },
        ...STATES_LIST.map((x) => ({
          value: x.stateCode,
          label: `${x.stateCode}-${x.stateName}`,
        })),
      ],
    },
    validate: validation.notDefault,
  },
  zip: {
    default: {
      value: '',
    },
    validate: ({ value }) => {
      if (value && value.length !== 5) {
        return { errorMessage: 'Please use the correct format' };
      }
      return { errorMessage: null };
    },
  },
  comment: {
    default: {
      value: '',
      characterLimit: 100,
    },
  },
};

const pageConfigs = {
  pageMetaInfo: {
    default: {
      isEditPage: false,
      isWex: false,
      editId: null,
    },
  },
  addressConfirmModalInfo: {
    default: {
      hasValidationError: false,
      addressToValidate: {},
      validatedAddress: {},
      show: false,
    },
  },

  dependantFunctions: {
    default: {
      value: {},
    },
  },
  vehicle: {
    default: {
      value: {},
    },
  },
  productOptionsMap: {
    default: {
      value: {},
    },
  },
};
const caches = {
  snapshot: {
    default: {
      register: null,
      recent: null,
    },
  },
};

const atomStateSchema = {
  formFields: { ...transactionStateSchema, ...merchantStateSchema },
  pageConfigs,
  caches,
};

export default atomStateSchema;
