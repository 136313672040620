import React, { useState, useEffect } from 'react';
import { SelectDropdown, TextInput, Button } from '@gsa/afp-component-library';
import { PageMode, useRepairOrder } from '../providers';
import { formatUSDecimal } from '../../../utilities/common';
import ConfirmDeleteServiceLineModal from './confirm-delete-service-line-modal';

const Index = ({ readOnly, id, index, onRemove }) => {
  const [confirmRemove, setConfirmRemove] = useState(false);
  const handleRemove = () => {
    onRemove({ id });
    setConfirmRemove(false);
  };

  return (
    <div className="grid-row">
      {confirmRemove && (
        <ConfirmDeleteServiceLineModal
          onCancel={() => setConfirmRemove(false)}
          onAccept={handleRemove}
        />
      )}
      <div
        className="grid-col-12"
        data-testid={`service-line-item-index-${id}`}
      >
        <span className="text-gray-30 font-heading-xl font-family-sans">
          {index}
        </span>
      </div>
      {!readOnly && (
        <div className="grid-col-12">
          <Button
            leftIcon={{ name: 'highlight_off', className: 'text-primary' }}
            size="large"
            title="remove service line"
            variant="unstyled"
            onClick={() => setConfirmRemove(true)}
            data-testid={`service-line-item-remove-${id}`}
          />
        </div>
      )}
    </div>
  );
};

const TypeHeader = ({ serviceCode }) => {
  const { combinedComponent: name } = serviceCode;

  return (
    <div className="text-top">
      <div className="text-bold">Service type</div>
      <span className="text-primary font-body-lg">{name}</span>
    </div>
  );
};

const ItemForm = ({ readOnly, data, onChange, dropdownData }) => {
  const { vehicle } = useRepairOrder();
  const { classes, reasons, failureCauses, worksAccomplished } = dropdownData;
  const { id } = data;
  const ownershipTypeCode = vehicle?.ownershipTypeCode;

  const [totalAmount, _setTotalAmount] = useState(
    formatUSDecimal(data.totalAmount || 0.0),
  );
  const setTotalAmount = (amount) => {
    _setTotalAmount(formatUSDecimal(amount));
  };

  const [partsTotal, _setPartsTotal] = useState(
    formatUSDecimal(data.partsTotal || 0.0),
  );
  const setPartsTotal = (value) => {
    const cleanLaborTotal =
      typeof laborTotal === 'string' ? cleanNumStr(laborTotal) : laborTotal;
    const cleanValue = typeof value === 'string' ? cleanNumStr(value) : value;
    _setPartsTotal(formatUSDecimal(cleanValue || '0.00'));
    setTotalAmount(parseFloat(cleanValue) + parseFloat(cleanLaborTotal));
  };

  const [laborTotal, _setLaborTotal] = useState(
    formatUSDecimal(data.laborTotal || 0.0),
  );
  const setLaborTotal = (value) => {
    const cleanPartsTotal =
      typeof partsTotal === 'string' ? cleanNumStr(partsTotal) : partsTotal;
    const cleanValue = typeof value === 'string' ? cleanNumStr(value) : value;
    _setLaborTotal(formatUSDecimal(cleanValue || '0.00'));
    setTotalAmount(parseFloat(cleanValue) + parseFloat(cleanPartsTotal));
  };

  const [partsCost, _setPartsCost] = useState(
    formatUSDecimal(data.partsCost || 0.0),
  );
  const setPartsCost = (value) => {
    if (value === partsCost) {
      return;
    }
    const cleanPartsQuantity =
      typeof partsQuantity === 'string'
        ? cleanNumStr(partsQuantity)
        : partsQuantity;
    const cleanValue = typeof value === 'string' ? cleanNumStr(value) : value;
    _setPartsCost(cleanValue);
    setPartsTotal(parseFloat(cleanValue) * parseFloat(cleanPartsQuantity));
  };

  const [partsQuantity, _setPartsQuantity] = useState(data.partsQuantity || 0);
  const setPartsQuantity = (value) => {
    if (value == partsQuantity) {
      return;
    }
    const cleanPartsCost =
      typeof partsCost === 'string' ? cleanNumStr(partsCost) : partsCost;
    const cleanValue = typeof value === 'string' ? cleanNumStr(value) : value;
    _setPartsQuantity(cleanValue);
    setPartsTotal(parseFloat(cleanPartsCost) * parseFloat(cleanValue));
  };

  const [laborRate, _setLaborRate] = useState(
    formatUSDecimal(data.laborRate || 0.0),
  );
  const setLaborRate = (value) => {
    if (value === laborRate) {
      return;
    }
    const cleanLaborHours =
      typeof laborHours === 'string' ? cleanNumStr(laborHours) : laborHours;
    const cleanValue = typeof value === 'string' ? cleanNumStr(value) : value;
    _setLaborRate(cleanValue);
    setLaborTotal(parseFloat(cleanLaborHours) * parseFloat(cleanValue));
  };

  const [laborHours, _setLaborHours] = useState(
    formatUSDecimal(data.laborHours || 0),
  );
  const setLaborHours = (value) => {
    if (value === laborHours) {
      return;
    }
    const cleanLaborRate =
      typeof laborRate === 'string' ? cleanNumStr(laborRate) : laborRate;
    const cleanValue = typeof value === 'string' ? cleanNumStr(value) : value;
    _setLaborHours(cleanValue);
    setLaborTotal(parseFloat(cleanLaborRate) * parseFloat(cleanValue));
  };

  const [serviceClass, setServiceClass] = useState(data.serviceClassCode);
  const [filteredClasses, setFilteredClasses] = useState([]);
  const [reason, setReason] = useState(data.serviceReasonCode);
  const [failureCause, setFailureCause] = useState(data.failureCauseCode);
  const [workAccomplished, setWorkAccomplished] = useState(
    data.workAccomplishedCode,
  );

  useEffect(() => {
    if (ownershipTypeCode === 'AO') {
      const filtered = classes.filter(
        (c) =>
          c.label !== 'FSR Assisted Repairs' &&
          c.label !== 'Questionable Repair or Billback',
      );
      setFilteredClasses(filtered);
    } else {
      setFilteredClasses(classes);
    }
  }, [dropdownData]);

  const sets = {
    partsCost: setPartsCost,
    partsQuantity: setPartsQuantity,
    laborRate: setLaborRate,
    laborHours: setLaborHours,
    partsTotal: setPartsTotal,
    laborTotal: setLaborTotal,
    totalAmount: setTotalAmount,
    serviceClassCode: setServiceClass,
    serviceReasonCode: setReason,
    failureCauseCode: setFailureCause,
    workAccomplishedCode: setWorkAccomplished,
  };

  const cleanNumStr = (numStr) => numStr?.replace(/,/g, '') || '';

  const handleSelectChange = (evt) => {
    const { name: field, value } = evt.target;
    sets[field](value);
    onChange({ id: data.id, field, value });
  };

  const formatIntegerCap = (value, limit) => {
    if (!value) return 0;
    const val = parseInt(value);
    return val > limit ? limit : val;
  };

  const handleNumberChange = (evt) => {
    const { name: field, value } = evt.target;
    const cleanValue = cleanNumStr(value);

    if (
      value === '' ||
      (!isNaN(cleanValue) &&
        !cleanValue.match(/[eE]/) &&
        parseFloat(cleanValue) >= 0)
    ) {
      sets[field](cleanValue);
    }

    let val;
    switch (field) {
      case 'partsQuantity':
        val = formatIntegerCap(cleanValue, 50);
        sets[field](`${val}`);
        break;
      case 'partsCost':
      case 'laborRate':
      case 'laborHours':
        val = parseFloat(cleanValue || '0.00');
        break;
      default:
    }
    onChange({ id: data.id, field, value: val });
  };

  const handleBlur = (evt) => {
    const { name, value } = evt.target;
    const cleanValue = cleanNumStr(value);

    switch (name) {
      case 'partsQuantity':
        sets[name](`${formatIntegerCap(cleanValue, 50)}`);
        break;
      case 'partsCost':
      case 'laborRate':
      case 'laborHours ':
        sets[name](formatUSDecimal(cleanValue || '0.00'));
        break;
    }
  };

  const classesLabel = () => {
    const filtered = (filteredClasses || []).filter(
      (c) => c.value == serviceClass,
    );
    if (filtered.length) {
      return filtered[0].label;
    }
    return '-Select-';
  };
  const serviceReasonLabel = () => {
    const filtered = (reasons || []).filter((c) => c.value == reason);
    if (filtered.length) {
      return filtered[0].label;
    }
    return '-Select-';
  };
  const failureCauseLabel = () => {
    const filtered = (failureCauses || []).filter(
      (c) => c.value == failureCause,
    );
    if (filtered.length) {
      return filtered[0].label;
    }
    return '-Select-';
  };
  const workAccomplishedLabel = () => {
    const filtered = (worksAccomplished || []).filter(
      (c) => c.value == workAccomplished,
    );
    if (filtered.length) {
      return filtered[0].label;
    }
    return '-Select-';
  };

  return (
    <div className="grid-row">
      <div className="grid-cold-12 margin-top-neg-3">
        <div className="grid-row grid-gap-2">
          <div className="grid-col-3">
            {readOnly ? (
              <TextInput
                label={<span className="text-bold">Class</span>}
                name="serviceClassCode"
                type="text"
                value={classesLabel()}
                readOnly={true}
              />
            ) : (
              <SelectDropdown
                name="serviceClassCode"
                label={<span className="text-bold">Class</span>}
                options={filteredClasses}
                value={serviceClass}
                onChange={handleSelectChange}
                onBlur={handleBlur}
              />
            )}
          </div>

          <div className="grid-col-3">
            {readOnly ? (
              <TextInput
                label={<span className="text-bold">Reason</span>}
                name="serviceReasonCode"
                type="text"
                value={serviceReasonLabel()}
                readOnly={true}
              />
            ) : (
              <SelectDropdown
                name="serviceReasonCode"
                label={<span className="text-bold">Reason</span>}
                options={reasons}
                value={reason}
                onChange={handleSelectChange}
                onBlur={handleBlur}
              />
            )}
          </div>

          <div className="grid-col-3">
            {readOnly ? (
              <TextInput
                label={<span className="text-bold">Failure cause</span>}
                name="failureCauseCode"
                type="text"
                value={failureCauseLabel()}
                readOnly={true}
              />
            ) : (
              <SelectDropdown
                name="failureCauseCode"
                label={<span className="text-bold">Failure cause</span>}
                options={failureCauses}
                value={failureCause}
                onChange={handleSelectChange}
                onBlur={handleBlur}
              />
            )}
          </div>
          <div className="grid-col-3">
            {readOnly ? (
              <TextInput
                label={<span className="text-bold">Work accomplished</span>}
                name="workAccomplishedCode"
                type="text"
                value={workAccomplishedLabel()}
                readOnly={true}
              />
            ) : (
              <SelectDropdown
                name="workAccomplishedCode"
                label={<span className="text-bold">Work accomplished</span>}
                options={worksAccomplished}
                value={workAccomplished}
                onChange={handleSelectChange}
                onBlur={handleBlur}
              />
            )}
          </div>
        </div>
      </div>
      <div className="grid-cold-12  margin-top-neg-2">
        <div className="grid-row grid-gap-2">
          <div className="grid-col-2">
            <TextInput
              label={<span className="text-bold">Parts cost ($)</span>}
              name="partsCost"
              type="text"
              inputMode="numeric"
              pattern="\d*"
              value={partsCost}
              onChange={handleNumberChange}
              onBlur={handleBlur}
              inputClass="text-right"
              readOnly={readOnly}
              data-testid={`partsCost-${id}`}
            />
          </div>
          <div className="grid-col-1">
            <TextInput
              label={<span className="text-bold">Qty</span>}
              name="partsQuantity"
              type="text"
              inputMode="numeric"
              pattern="\d*"
              value={partsQuantity}
              onChange={handleNumberChange}
              onBlur={handleBlur}
              readOnly={readOnly}
              data-testid={`partsQuantity-${id}`}
            />
          </div>
          <div className="grid-col-2">
            <TextInput
              label={<span className="text-bold">Parts total ($)</span>}
              name="partsTotal"
              type="text"
              value={partsTotal}
              inputClass="text-right"
              readOnly={true}
              data-testid={`partsTotal-${id}`}
            />
          </div>
          <div className="grid-col-2">
            <TextInput
              label={<span className="text-bold">Labor rate ($)</span>}
              name="laborRate"
              type="text"
              inputMode="numeric"
              pattern="\d*"
              value={laborRate}
              inputClass="text-right"
              onChange={handleNumberChange}
              onBlur={handleBlur}
              readOnly={readOnly}
              data-testid={`laborRate-${id}`}
            />
          </div>
          <div className="grid-col-1">
            <TextInput
              label={<span className="text-bold">Hours</span>}
              name="laborHours"
              type="text"
              inputMode="numeric"
              pattern="\d*"
              value={laborHours}
              onChange={handleNumberChange}
              onBlur={handleBlur}
              readOnly={readOnly}
              data-testid={`laborHours-${id}`}
            />
          </div>
          <div className="grid-col-2">
            <TextInput
              label={<span className="text-bold">Labor total ($)</span>}
              name="laborTotal"
              type="text"
              value={laborTotal}
              inputClass="text-right"
              readOnly={true}
              data-testid={`laborTotal-${id}`}
            />
          </div>
          <div className="grid-col-2">
            <TextInput
              label={<span className="text-bold">Total ($)</span>}
              name="total"
              type="text"
              value={totalAmount}
              inputClass="text-right"
              readOnly={true}
              data-testid={`lineTotal-${id}`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const ServiceLineItem = ({
  data,
  index,
  mode,
  onChange,
  onRemove,
  dropdownData,
}) => {
  const { id, serviceCode = {} } = data || {};

  const readOnly = mode === PageMode.View;
  const border = readOnly ? 'border-bottom border-top' : 'border-bottom';

  return (
    <div className={`grid-row padding-y-2 border-gray-20 ${border}`}>
      <div className="grid-col-1 border-right border-gray-20 text-center">
        <Index readOnly={readOnly} id={id} index={index} onRemove={onRemove} />
      </div>
      <div className="grid-col-3 padding-left-2 border-gray-20">
        <TypeHeader serviceCode={serviceCode} />
      </div>
      <div className="grid-col-8">
        <ItemForm
          readOnly={readOnly}
          data={data}
          onChange={onChange}
          dropdownData={dropdownData}
        />
      </div>
    </div>
  );
};

export default ServiceLineItem;
