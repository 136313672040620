import React, { useEffect } from 'react';
import {
  Alert,
  Button,
  Modal,
  Spinner,
  RequiredFieldIndicator,
} from '@gsa/afp-component-library';
import { carTitleConstructor } from 'utilities/car-title';
import { emdash } from 'components/common';
import ReservationStartForm from './forms/reservation-start-form';
import { useMotorPool } from '../../motor-pool-provider';
import ReservationAddEditForm from './forms/reservation-add-edit-form';
import ReservationCloseCompleteForm from './forms/reservation-close-complete-form';
import { NO_MOTOR_POOL_RESERVATION_TIME_ZONE } from '../../messages';

const ModalTitle = () => {
  const { reservationModalMode, dispatchAction, reservationListingSelected } =
    useMotorPool();

  useEffect(() => {
    dispatchAction('SET_ALERT_MESSAGE', { type: '', message: '' });
  }, []);

  let title = '';
  switch (reservationModalMode) {
    case 'UPDATE_RESERVATION':
    case 'START_RESERVATION':
    case 'CLOSE_AND_COMPLETE_RESERVATION': {
      const { modelYear, vehicleMake, vehicleModel, modelCode } =
        reservationListingSelected?.motorPoolVehicle?.vehicle;
      const vehicleTitle = carTitleConstructor(
        modelYear,
        vehicleMake,
        vehicleModel,
        modelCode,
      );
      let modalTitle = '';
      if (reservationModalMode === 'START_RESERVATION') {
        modalTitle = 'Start';
      } else if (reservationModalMode === 'CLOSE_AND_COMPLETE_RESERVATION') {
        modalTitle = 'Close and complete';
      } else {
        modalTitle = 'Update';
      }
      title = (
        <h2>
          {modalTitle} reservation for{' '}
          {vehicleTitle === emdash ? ' the vehicle' : vehicleTitle}
        </h2>
      );
      break;
    }
    case 'CANCEL_RESERVATION':
      title = <h2>Cancel reservation</h2>;
      break;
    default:
      break;
  }

  return title;
};

const ModalContent = () => {
  const {
    reservationModalMode,
    reservationListingSelected,
    selectedMotorPoolById,
  } = useMotorPool();

  switch (reservationModalMode) {
    case 'UPDATE_RESERVATION':
      return (
        <>
          <p>
            Required fields are marked with an asterisk (
            <RequiredFieldIndicator />
            ).
          </p>
          <ReservationAddEditForm />
        </>
      );
    case 'CLOSE_AND_COMPLETE_RESERVATION':
      return (
        <>
          <p>
            Required fields are marked with an asterisk (
            <RequiredFieldIndicator />
            ).
          </p>
          <ReservationCloseCompleteForm />
        </>
      );
    case 'START_RESERVATION': {
      return (
        <div>
          {!selectedMotorPoolById?.timezone && (
            <Alert type="warning">
              {NO_MOTOR_POOL_RESERVATION_TIME_ZONE.body()}
            </Alert>
          )}
          <p>
            Required fields are marked with an asterisk (
            <RequiredFieldIndicator />
            ).
          </p>
          <ReservationStartForm />
        </div>
      );
    }
    case 'CANCEL_RESERVATION': {
      const { modelYear, vehicleMake, vehicleModel, modelCode } =
        reservationListingSelected?.motorPoolVehicle?.vehicle;
      let vehicleTitle = carTitleConstructor(
        modelYear,
        vehicleMake,
        vehicleModel,
        modelCode,
      );
      vehicleTitle = vehicleTitle === emdash ? ' the vehicle' : vehicleTitle;
      return (
        <div>
          Are you sure you want to cancel this reservation for{' '}
          <strong>{vehicleTitle}</strong>?
        </div>
      );
    }
    default:
      return null;
  }
};

const ModalAction = () => {
  const {
    resetReservationModal,
    reservationModalMode,
    cancelPoolReservation,
    reservationListingSelected,
    updateReservationLoading,
    startReservationLoading,
    completeReservationLoading,
  } = useMotorPool();

  switch (reservationModalMode) {
    case 'UPDATE_RESERVATION':
      return (
        <>
          <Button
            variant="unstyled"
            className="margin-right-2"
            onClick={resetReservationModal}
            data-testid="reservation-add-edit-cancel-button"
            label="Cancel"
          />
          <Button
            variant="primary"
            type="submit"
            form="reservation-add-edit-form"
            data-testid="reservation-add-edit-submit-button"
            disabled={updateReservationLoading}
            label="Submit"
          />
          {updateReservationLoading && (
            <Spinner
              size="small"
              className="display-inline-block margin-left-2"
              data-testid="update-reservation-submit-spinner"
            />
          )}
        </>
      );
    case 'START_RESERVATION':
      return (
        <>
          <Button
            variant="unstyled"
            className="margin-right-2"
            onClick={resetReservationModal}
            data-testid="motor-pool-reservation-cancel-start-button"
            label="Cancel"
          />
          <Button
            variant="primary"
            type="submit"
            form="motor-pool-reservation-start-form"
            data-testid="motor-pool-reservation-start-reservation-button"
            label="Start reservation"
            disabled={startReservationLoading}
          />
          {startReservationLoading && (
            <Spinner
              size="small"
              className="display-inline-block margin-left-2"
              data-testid="start-reservation-submit-spinner"
            />
          )}
        </>
      );
    case 'CLOSE_AND_COMPLETE_RESERVATION':
      return (
        <>
          <Button
            variant="unstyled"
            className="margin-right-2"
            onClick={resetReservationModal}
            data-testid="reservation-close-complete-cancel-button"
            label="Cancel"
          />
          <Button
            variant="primary"
            type="submit"
            form="reservation-close-complete-form"
            data-testid="reservation-close-complete-button"
            label="Complete reservation"
            disabled={completeReservationLoading}
          />
          {completeReservationLoading && (
            <Spinner
              size="small"
              className="display-inline-block margin-left-2"
              data-testid="complete-reservation-submit-spinner"
            />
          )}
        </>
      );
    case 'CANCEL_RESERVATION':
      return (
        <>
          <Button
            variant="unstyled"
            className="margin-right-2"
            onClick={resetReservationModal}
            data-testid="reservation-cancel-button"
            label="Cancel"
          />
          <Button
            variant="secondary"
            onClick={() => {
              cancelPoolReservation({
                variables: {
                  id: reservationListingSelected.id,
                },
              });
            }}
            data-testid="cancel-reservation-button"
            form="cancel-reservation-form"
            label="Cancel reservation"
          />
        </>
      );
    default:
      return null;
  }
};

const ReservationsModal = () => {
  const { alertMessage, resetReservationModal, reservationModalShow } =
    useMotorPool();
  const { header, message, type, context } = alertMessage;
  const alertContext =
    context === 'updateReservation' ||
    context === 'startReservation' ||
    context === 'closeReservation' ||
    context === 'motorPoolTimezones';

  return reservationModalShow ? (
    <div className="afp-modal-wrapper">
      <div className="afp-modal-overlay">
        <Modal
          title={<ModalTitle />}
          actions={<ModalAction />}
          onClose={resetReservationModal}
          variant="large"
        >
          {alertContext && message && (
            <div
              className="margin-bottom-2"
              data-testid="reservation-modal-alert"
            >
              <Alert type={type} heading={header || null}>
                {message}
              </Alert>
            </div>
          )}
          <ModalContent />
        </Modal>
      </div>
    </div>
  ) : null;
};

export default ReservationsModal;
