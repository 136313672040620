import React from 'react';
import { Breadcrumbs, PageTitle } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';
import RegistrationSteps from './widgets/registration-steps';
import { useRegistration } from './reg-context-provider';
import FormHeading from './widgets/form-heading';
import ServerErrorMessage from '../error-handlers/server-error-message';

const Header = ({ breadcrumbRef }) => {
  const { step, registrationError } = useRegistration();

  return (
    <header>
      <Breadcrumbs
        trail={[
          <a
            ref={breadcrumbRef}
            href={`${window.AFP_CONFIG.appURLs.home}/home`}
          >
            Home
          </a>,
        ]}
        current="Vehicle Registration"
      />
      {registrationError && (
        <ServerErrorMessage graphQLErrors={registrationError} />
      )}
      <div className="border-bottom border-base-light padding-bottom-1 margin-bottom-2">
        <PageTitle title="Vehicle Registration" />
        <p className="tablet:width-tablet-lg">
          Use this form to register a federally owned and operated vehicle with
          GSA. If you need help registering a vehicle or managing license
          plates, please reference the &nbsp;
          <a
            href={`${process.env.PUBLIC_URL}/Vehicle-Registration-User-Guide-Sept2022-v2.pdf`}
            className="mobile:display-block"
            data-testid="gsa-login-anchor"
            target="_blank"
            rel="noopener noreferrer"
          >
            user guide
          </a>
           for step-by-step instructions.
        </p>
        <p>
          By registering your vehicle, you are formally accepting receipt of the
          vehicle.
        </p>
      </div>
      <RegistrationSteps step={step} />
      <FormHeading step={step} />
    </header>
  );
};

Header.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  breadcrumbRef: PropTypes.any.isRequired,
};

export default Header;
