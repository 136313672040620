import React, { useEffect, useReducer, useState } from 'react';
import moment from 'moment';
import { DateRangePicker, Label } from '@gsa/afp-component-library';
import DateMonthYear from '../../../forms/widgets/DateMonthYear';

const dateRangeReducer = (state, newState) => {
  return {
    ...state,
    ...newState,
  };
};

const useDateRangeFilter = ({ classes, dateFormat }) => {
  const [state, dispatch] = useReducer(dateRangeReducer, {
    toDate: undefined,
    fromDate: undefined,
    toDateError: '',
    fromDateError: '',
    monthYearError: '',
    showFilterDateError: false,
    default: { to: new Date(), from: new Date() },
  });
  const [touched, setTouched] = useState(false);
  const [randomKey, setRandomKey] = useState(Math.random());

  const handleEditFilterDate = (field, e) => {
    const updatedData = {};
    if (e === '') {
      updatedData[field] = null;
    } else {
      updatedData[field] = e;
    }

    dispatch({ ...updatedData });
  };

  useEffect(() => {
    if (!(state.fromDate instanceof Date && state.toDate instanceof Date)) {
      if (state.fromDate === '' && state.toDate === '') {
        setRandomKey(Math.random());
      }
    }
  }, [state.fromDate, state.toDate]);

  useEffect(() => {
    setTouched(true);
  }, [state.fromDate, state.toDate]);

  const dateRange = () => (
    <DateRangePicker
      className={classes}
      key={randomKey}
      startDateLabel={<span className="text-bold">From</span>}
      startDateHint="mm/dd/yyyy"
      startDatePickerProps={{
        onChange: (val) => {
          if (val === '') {
            handleEditFilterDate('fromDate', '');
            return;
          }
          const newDate = new Date(val);
          newDate.setHours(0, 0, 0, 0);
          handleEditFilterDate('fromDate', newDate);
        },
        defaultValue: !touched
          ? undefined
          : moment(state.fromDate).format('YYYY-MM-DD'),
      }}
      endDateLabel={<span className="text-bold">To</span>}
      endDateHint="mm/dd/yyyy"
      endDatePickerProps={{
        onChange: (val) => {
          if (val === '') {
            handleEditFilterDate('toDate', '');
            return;
          }
          const newDate = new Date(val);
          newDate.setHours(0, 59, 59, 0);
          handleEditFilterDate('toDate', newDate);
        },
        defaultValue: !touched
          ? undefined
          : moment(state.toDate).format('YYYY-MM-DD'),
      }}
    />
  );

  const checkError = (field, val) => {
    if (field === 'from' && moment(state.toDate).isValid()) {
      if (!moment(val).isValid) {
        return 'Enter valid start date format';
      } else if (moment(val).diff(moment(state.toDate)) > 0) {
        return 'Enter valid start date';
      } else {
        return undefined;
      }
    } else if (field === 'to' && moment(state.fromDate).isValid()) {
      if (!moment(val).isValid) {
        return 'Enter valid end date format';
      } else if (moment(val).diff(moment(state.fromDate)) < 0) {
        return 'Enter valid end date';
      } else {
        return undefined;
      }
    }
  };

  const dateRangeTypeTwo = () => (
    <>
      <span className="usa-error-message margin-top-3">
        {' '}
        {state.monthYearError.from || state.monthYearError.to}{' '}
      </span>
      <div className={classes}>
        <DateMonthYear
          showSelectOption
          label={<span className="text-bold">From</span>}
          fieldError={state.monthYearError.from}
          fieldFormat="mm/yyyy"
          name="from-date-picker"
          value={state.fromDate}
          defaultMonth={undefined}
          defaultYear={undefined}
          onChange={({ month, year }) => {
            if (month > -1 && year > -1) {
              let newDateFrom = '';
              newDateFrom = new Date(`${year}/${month}`);
              newDateFrom.setHours(0, 0, 0, 0);
              const error = checkError('from', newDateFrom);
              dispatch({ ...{ monthYearError: { from: error } } });
              if (!error) handleEditFilterDate('fromDate', newDateFrom);
            } else {
              dispatch({ ...{ monthYearError: { from: undefined } } });
              handleEditFilterDate('fromDate', null);
            }
          }}
        />
        <DateMonthYear
          showSelectOption
          label={<span className="text-bold">To</span>}
          fieldError={state.monthYearError.to}
          fieldFormat="mm/yyyy"
          name="from-date-picker"
          value={state.toDate}
          defaultMonth={undefined}
          defaultYear={undefined}
          onChange={({ month, year }) => {
            let newDateTo = '';
            if (month > -1 && year > -1) {
              newDateTo = new Date(year, month, 0);
              newDateTo.setDate(newDateTo.getDate());
              const error = checkError('to', newDateTo);
              dispatch({ ...{ monthYearError: { to: error } } });
              handleEditFilterDate('toDate', newDateTo);
            } else {
              dispatch({ ...{ monthYearError: { to: undefined } } });
              handleEditFilterDate('toDate', null);
            }
          }}
        />
      </div>
    </>
  );

  return {
    getDateRangeComponent:
      dateFormat === 'YYYY-MM-DD' ? dateRange : dateRangeTypeTwo,
    fromDate: state.fromDate,
    toDate: state.toDate,
    touched,
  };
};

export default useDateRangeFilter;
