import React from 'react';
import { Button } from '@gsa/afp-component-library';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import { VMSOperations, VMSSubjects } from '../../utilities/consts'
import { useOfficeManagement } from './office-management-provider';

const OfficeManagementActions = () => {
  const { dispatchAction, currentModalName } = useOfficeManagement();

  const ability = useAppAbility();
  const canManageOffice = ability.can(
    VMSOperations.Manage,
    VMSSubjects.ORGANIZATION_PROFILE,
  );
  return (
    <div className="float-right">
      {canManageOffice && (
        <Button
          data-testid="export-pm-express-btn"
          variant="primary"
          leftIcon={{ name: 'add' }}
          label="Add accounts"
          onClick={() => {
            dispatchAction('SET_MODAL', {
              currentModalName,
              currentMode: 'addCustomerAccountModalSchema',
            });
          }}
        />
      )}
    </div>
  );
};

export default OfficeManagementActions;
