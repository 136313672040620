import React from 'react';
import { useAppAbility, useTitle } from '@gsa/afp-shared-ui-utils';
import ServerErrorMessage from 'components/error-handlers/server-error-message';
import {
  Alert,
  PageTitle,
} from '@gsa/afp-component-library';
import { canManageMotorPools } from 'utilities/authorization';
import { useMotorPool } from './motor-pool-provider';

const MotorPoolHeader = () => {
  useTitle('Dispatch and Reservation');
  const { alertMessage } = useMotorPool();
  const { header, message, type, context, error } = alertMessage;
  
  const ability = useAppAbility();
  const canManagePool = canManageMotorPools(ability);

  const showAlert = () => {
    if (
      (context !== 'deleteMotorPool' && context !== 'motorPoolListing') ||
      !message
    ) {
      return null;
    }

    if (type === 'error' && context === 'deleteMotorPool') {
      return (
        <div className="margin-bottom-2" data-testid="motor-pool-alert">
          <Alert type={type} heading={header || null}>
            {message}
          </Alert>
        </div>
      );
    }

    if (type === 'error') {
      return <ServerErrorMessage graphQLErrors={error} />;
    }

    return (
      <div className="margin-top-2" data-testid="motor-pool-alert">
        <Alert type={type} heading={header || null}>
          {message}
        </Alert>
      </div>
    );
  };

  const headerText = () => {
    if (canManagePool) {
      return (
        <>
          <p>
            Use this feature to create and manage public and private motor
            pools, add GSA Fleet leased and/or agency-owned vehicles to motor
            pools, invite and manage members, create and manage vehicle
            reservations, and produce reports based on reservation data.
          </p>
          <p>
            For more information, please see the dispatch and
            reservation&nbsp;
            <a
              href={`${process.env.PUBLIC_URL}/Dispatch-and-Reservation-Dispatcher-User-Guide.pdf`}
              className="mobile:display-block"
              data-testid="dispatcher-user-guide"
              target="_blank"
              rel="noopener noreferrer"
            >
              user guide
            </a>{' '}
            and&nbsp;
            <a
              href="https://vimeo.com/gsavisualcommunications/review/812855949/2cdfad648a"
              className="mobile:display-block"
              data-testid="dispatcher-user-video"
              target="_blank"
              rel="noopener noreferrer"
            >
              How to
            </a>{' '}
            video.
          </p>
        </>
      );
    }

    return (
      <>
        <p>
          Use this feature to view motor pools, request access to public motor
          pools, create and manage vehicle reservations.
        </p>
        <p>
          For more information, please see the dispatch and reservation&nbsp;
          <a
            href={`${process.env.PUBLIC_URL}/Dispatch-and-Reservation-Driver-User-Guide.pdf`}
            className="mobile:display-block"
            data-testid="member-user-guide"
            target="_blank"
            rel="noopener noreferrer"
          >
            user guide
          </a>{' '}
          and&nbsp;
          <a
            href="https://vimeo.com/gsavisualcommunications/review/812875850/b62a7714e9"
            className="mobile:display-block"
            data-testid="member-user-video"
            target="_blank"
            rel="noopener noreferrer"
          >
            How to
          </a>{' '}
          video.
        </p>
      </>
    );
  };

  return (
    <>
      {showAlert()}
      <header>
        <PageTitle title="Dispatch and Reservation" />
        <div className="margin-bottom-4 tablet:grid-col-7">{headerText()}</div>
      </header>
    </>
  );
};

export default MotorPoolHeader;
