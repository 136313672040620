import React from 'react';
import { Icon, Popover } from '@gsa/afp-component-library';
import './exempt-message.css';

// eslint-disable-next-line react/prop-types
export const ExemptPopover = ({ style }) => (
  <div className="exempt-pop" style={style}>
    <span className="position-relative popover-info-icon">
      <Icon className="" iconName="info" />
    </span>
    <Popover className="exempt-message-popover" arrowPosition="arrow-top-right">
      <h4 className="exempt-popover-heading margin-y-1">
        License Plate Exemption
      </h4>
      <p className="margin-y-0">
        The agency has an exemption from the requirement to display official
        U.S. Government license plates for this vehicle under sections{' '}
        <a
          href="https://www.ecfr.gov/current/title-41/section-102-34.160"
          data-testid="ecfr-160"
          target="_blank"
          rel="noopener noreferrer"
        >
          § 102-34.160
        </a>
        ,{' '}
        <a
          href="https://www.ecfr.gov/current/title-41/section-102-34.175"
          data-testid="ecfr-175"
          target="_blank"
          rel="noopener noreferrer"
        >
          § 102-34.175
        </a>{' '}
        or{' '}
        <a
          href="https://www.ecfr.gov/current/title-41/section-102-34.180"
          data-testid="ecfr-180"
          target="_blank"
          rel="noopener noreferrer"
        >
          § 102-34.180
        </a>{' '}
        and it must be registered and inspected in accordance with the laws of
        the jurisdiction where the motor vehicle is regularly operated.
      </p>
    </Popover>
  </div>
);

const ExemptMessage = () => (
  <div className="exempt-checkbox exempt-label-font margin-top-4 position-relative">
    <div>
      The Agency has an exemption from the requirement to display official U.S.
      Government license plates
      <span className="exempt-popover-wrapper">
        <ExemptPopover />
      </span>
    </div>
  </div>
);

export const ExemptLabeledMessage = () => (
  <div className="exempt-checkbox exempt-label-font position-relative">
    <div>
      <p className="margin-0">The Agency has an exemption from</p>
      <p className="margin-0">the requirement to display official</p>
      <p className="margin-0">
        U.S. Government license plates
        <span className="exempt-popover-wrapper">
          <ExemptPopover style={{ transform: 'translateY(3px)' }} />
        </span>
      </p>
    </div>
  </div>
);

export default ExemptMessage;
