/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import moment from 'moment-timezone';
import { DatePicker, Modal, Label, Spinner } from '@gsa/afp-component-library';
import { UPDATE_REMEDY_DATE_FOR_RECALL } from '../components/graphql-queries';
import { useVehicle } from '../vehicle-context-provider';
// This needs to be here to match Figma. The BM purchase rate Date Picker has css too
import './modal.css';

const AddRemedyDate = ({ onClose, actionData }) => {
  document.querySelectorAll('[aria-hidden="true"]').forEach((item) => {
    // or remove it
    item.removeAttribute('aria-hidden');
  });
  const [remedyDate, setRemedyDate] = useState(
    actionData?.userClosedDate || null,
  );

  const [errorMessage, setErrorMessage] = useState();
  const [errorDate, setErrorDate] = useState(false);

  const { setSectionMsg,refetchVehicle } = useVehicle();

  const [updateRemedyDate, { loading }] = useMutation(
    UPDATE_REMEDY_DATE_FOR_RECALL,
    {
      onCompleted: () => {
        onClose();
        setSectionMsg({
          type: 'success',
          message: (
            <>
              The remedy date for recall{' '}
              <strong>{actionData.recallCampaign.recallCode}</strong> is updated
              successfully.
            </>
          ),
          
        });
        refetchVehicle();
      },
      onError: (err) => setErrorMessage(`Error on Submit: ${err.message}`),
    },
  );

  const submitForm = () => {
    if (errorDate) return;
    if (
      remedyDate === null ||
      (moment(remedyDate).isValid() &&
        moment().diff(remedyDate, 'YYYY-MM-DD HH:mm:ss') > 0)
    ) {
      const date = remedyDate
        ? moment(remedyDate).format('YYYY-MM-DD').concat('T00:00:00.000Z')
        : null;
      updateRemedyDate({
        variables: {
          assetRecallId: parseFloat(actionData.id),
          date,
        },
      });
    }
  };

  const onDateChange = (val) => {
    if (val) {
      setErrorMessage('');
      if (moment(val).isValid() && moment(val, 'MM/DD/YYYY', true).isValid()) {
        if (moment().diff(val, 'MM/DD/YYYY') > 0) {
          setRemedyDate(val);
          setErrorMessage('');
          setErrorDate(false);
        } else {
          setErrorMessage('Remedy date cannot be a future date');
          setErrorDate(true);
        }
      } else {
        // datepicker has invalid date format message
        // no need to add the error message.
        setErrorDate(true);
      }
    } else {
      setErrorMessage(null);
      setRemedyDate(null);
      setErrorDate(false);
    }
  };

  return (
    <div className="afp-modal-wrapper">
      <div className="afp-modal-overlay">
        <Modal
          isOpen
          id="cancel-registration-modal"
          variant="medium"
          title={<h2>Add or update remedy date</h2>}
          onClose={onClose}
          actions={
            <div>
              <button
                type="button"
                className="continue usa-button--unstyled padding-right-2"
                onClick={onClose}
              >
                Cancel
              </button>
              <button
                type="button"
                className="cancel usa-button"
                onClick={submitForm}
              >
                Submit
              </button>
            </div>
          }
        >
          {loading && (
            <Spinner
              style={{
                position: 'absolute',
                top: '50%',
                transform: 'translate(0, -50%)',
              }}
            />
          )}

          <form id="remedyDateForm" data-testid="remedyDateForm">
            <p>
              If the recall has been addressed by the agency but has not been
              closed by the manufacturer, you can add the remedy date that the
              recall was completed for the campaign{' '}
              <b>{actionData.recallCampaign.recallCode}</b>.
            </p>
            <p>
              This will NOT close the recall, but will remove notifications
              regarding the recall until it is closed by the manufacturer.
            </p>
            <p>
              If you remove or delete remedy date, the recall will become open
              again.
            </p>
            <div data-testid="RemedyDatePicker">
              <Label className="changeFontWeight">Remedy date</Label>
              <DatePicker
                id="remedy-date"
                name="remedy-date"
                className="datePicker"
                defaultValue={remedyDate}
                maxDate={moment.utc(new Date()).format('YYYY-MM-DD')}
                minDate={moment.utc(actionData?.createdAt).format('YYYY-MM-DD')}
                format="MM/DD/YYYY"
                errorMessage={errorMessage}
                onChange={(val) => {
                  onDateChange(val);
                }}
                data-testid="remedy-date-picker-test"
              />
            </div>
          </form>
        </Modal>
      </div>
    </div>
  );
};

export default AddRemedyDate;
