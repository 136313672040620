import React, { useState, useEffect } from 'react';
import {
  ActivityTracker,
  Spinner,
  Pagination,
  EmptyState,
} from '@gsa/afp-component-library';
import { useVehicle } from '../../vehicle-context-provider';
import { useVehicleActivities } from './vehicle-activities-provider';
import { useVehicleActivitiesFilter } from './filters/filter-provider';
import { formatActivities } from './utils';

const initialPaginationState = {
  limit: 20,
  offset: 0,
  currentPage: 1,
  isReset: false,
};

const VehicleActivitiesListing = () => {
  const { vehicle } = useVehicle();
  const { uuid } = vehicle;
  const {
    getTransactionHistoryRecords,
    activitiesList,
    transactionHistoryRecordsLoading,
    getHistoryActivityTypes,
  } = useVehicleActivities();
  const { filters } = useVehicleActivitiesFilter();

  const [paginationState, setPaginationState] = useState(
    initialPaginationState,
  );

  const getActivities = () => {
    getTransactionHistoryRecords({
      variables: {
        assetId: uuid,
        limit: paginationState.limit,
        offset: paginationState.offset,
        filters,
      },
    });
  };

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    // Calculate new offset.
    const offset = (currentPage - 1) * itemsPerPage;
    setPaginationState({
      limit: itemsPerPage,
      offset,
      currentPage,
    });
  };

  useEffect(() => {
    getActivities();
    getHistoryActivityTypes();
  }, [uuid]);

  useEffect(() => {
    setPaginationState({
      ...paginationState,
      offset: 0,
      currentPage: 1,
      isReset: true,
    });
  }, [filters]);

  useEffect(() => {
    getActivities();
    const activityWrapper = document.getElementById('filter-bar-wrapper');
    if (activityWrapper) {
      activityWrapper.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [paginationState, filters]);

  return (
    <div className="margin-top-5">
      {transactionHistoryRecordsLoading ? (
        <Spinner className="padding-y-9" />
      ) : (
        <>
          {activitiesList.count > 0 ? (
            <div className="title-m">
              <strong>{activitiesList.count}</strong> activities
            </div>
          ) : (
            ''
          )}
          <ActivityTracker
            activities={formatActivities(activitiesList?.rows)}
            variant="page"
          />
        </>
      )}
      {activitiesList?.rows?.length > 0 && (
        <div className="margin-top-2">
          <Pagination
            fullWidth
            variant="advanced"
            itemsPerPageOptions={[20, 25, 50]}
            itemsCount={activitiesList.count}
            itemsPerPage={paginationState.limit}
            currentPage={paginationState.currentPage}
            onPageChange={handlePaginationChange}
            isReset={paginationState.isReset}
          />
        </div>
      )}
      {(!activitiesList || activitiesList.rows.length === 0) &&
        !transactionHistoryRecordsLoading && (
          <div className="bg-gray-3 padding-y-5">
            <div className="text-center padding-y-4">
              <EmptyState alt="Image not available" hasBackground />
            </div>
            <div className="text-center text-bold">No data available</div>
          </div>
        )}
    </div>
  );
};

export default VehicleActivitiesListing;
