import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import { canUpdateTagOrders } from 'utilities/authorization';
import FilterUpRightActions from 'utilities/filter-up-right-actions';
import { FilterTableFrame } from '@gsa/afp-component-library';
import LicensePlateSidebar from './sidebar-widget';
import useDataController from '../use-data-controller';

import LicensePlateTable from './license-plate-table';
import { useLicensePlate } from '../context';
import LicensePlateInitDestruction from './license-plate-init-destruction';
import { useLicensePlateDestruction } from '../../license-plate-destruction/context';

export default function FilterAndGrid({ alertUtils }) {
  const { onExport, onBulkSubmitForDestruction } = useDataController({
    alertUtils,
  });
  const { setAppliedFilters, selectedRows } = useLicensePlate();
  const { toggleDestructionEditing, destructionEditing, saveDestruction } =
    useLicensePlateDestruction();

  const FTF = useMemo(
    () =>
      FilterTableFrame(
        null,
        FilterUpRightActions,
        LicensePlateSidebar,
        LicensePlateTable,
      ),
    [],
  );

  const ability = useAppAbility();
  const canUpdateTags = canUpdateTagOrders(ability);

  return (
    <>
      <FTF
        upRightProps={{
          pageName: 'plate',
          DropDownItems: [
            {
              label: 'Export data',
              iconName: 'file_download',
              onClick: onExport,
            },
            {
              label: 'Submit for destruction',
              iconName: 'delete',
              onClick: onBulkSubmitForDestruction,
              hiddenItem:
                !canUpdateTags || Object.keys(selectedRows).length === 0,
            },
          ],
        }}
        lowLeftProps={{
          updateAppliedFilters: (filters) => {
            const filterLabels = filters.map((filter) => filter.label);
            setAppliedFilters(filterLabels);
          },
        }}
        filterToggle
      />
      <LicensePlateInitDestruction
        editing={destructionEditing}
        onClose={toggleDestructionEditing}
        onSave={saveDestruction}
      />
    </>
  );
}

FilterAndGrid.propTypes = {
  mutation: PropTypes.shape({
    exportLicensePlates: PropTypes.func,
  }).isRequired,
};
