const statePlaceholder = [{ value: '', label: '- Select -' }];

export const generateOfficeManagementFilterStructure = (states) => {
  return [
    {
      title: 'Point of contact',
      key: '$primaryContactAssociation.pointOfContact.email_address$',
      type: 'text',
      permanent: false,
      operator: '$startsWith',
      hideClear: false,
      position: 'top',
      label: 'Search by point of contact',
      showSearchButton: true,
      minSearchLength: 3,
    },
    {
      title: 'Legacy customer number',
      key: '$legacy_customer_number$',
      type: 'text',
      permanent: false,
      operator: '$startsWith',
      hideClear: false,
      position: 'top',
      label: 'Search by legacy customer number',
      showSearchButton: true,
      minSearchLength: 3,
    },
    {
      title: 'State',
      key: '$CustomerAccount.us_state_id$',
      permanent: false,
      operator: '$exact',
      value: '',
      type: 'select',
      options: [...statePlaceholder, ...(states.length > 0 ? states : [])],
    },
  ];
};
