/* eslint-disable filenames/match-exported */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable filenames/match-regex */
import React from 'react';
import { Button, RequiredFieldIndicator } from '@gsa/afp-component-library';
import { REPORT_TYPE } from 'utilities/consts';
import ExportForm from '../../forms/motor-pool-export-form';

const RESERVATION_HISTORY_REPORT = {
  title: () => <h2>Export reservation history report data </h2>,
  content: () => {
    return (
      <>
        <p>Request data for your motor pools.</p>
        <p>
          Required fields are marked with an asterisk (
          <RequiredFieldIndicator />
          ).
        </p>
        <ExportForm reportType={REPORT_TYPE.RESERVATION_HISTORY_REPORT} />
      </>
    );
  },
  action: ({ resetCurrentModal }) => (
    <>
      <Button
        variant="unstyled"
        className="margin-right-2"
        onClick={resetCurrentModal}
        data-testid="motor-pool-export-cancel-button"
        label="Cancel"
      />
      <Button
        variant="primary"
        type="submit"
        form="export-form"
        data-testid="reservation-history-report-form-submit-button"
        label="Submit"
      />
    </>
  ),
  onClose: ({ resetCurrentModal }) => {
    resetCurrentModal();
  }
};

export default RESERVATION_HISTORY_REPORT;
