// eslint-disable-next-line filenames/match-exported
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { Spinner, FilterPanel } from '@gsa/afp-component-library';
import { GET_VEHICLE_FILTERABLE_YEARS } from '../../../services/data-layer';

const { FilterPanelFilterShape, FilterPanelField } = FilterPanel;

const placeholderOption = {
  value: '',
  label: '- Select year -',
  defaultValue: true,
};

const VehiclesModelYearFilterItem = ({ filter }) => {
  const [options, setOptions] = useState([placeholderOption]);

  const [
    fetchVehicleFilterableYears,
    { data: vehicleFilterableYears, loading: vehicleFilterableYearsLoading },
  ] = useLazyQuery(GET_VEHICLE_FILTERABLE_YEARS, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    fetchVehicleFilterableYears();
  }, []);

  useEffect(() => {
    if (vehicleFilterableYears?.getVehicleFilterableYears) {
      const yearOptions =
        vehicleFilterableYears?.getVehicleFilterableYears?.years?.map(
          (value) => ({
            value,
            label: value,
          }),
        );
      setOptions([placeholderOption, ...yearOptions]);
    }
  }, [vehicleFilterableYears]);

  if (vehicleFilterableYearsLoading) {
    return <Spinner />;
  }

  return (
    <FilterPanelField
      key={[filter.key]}
      filter={{
        ...filter,
        type: 'select',
        options: options,
      }}
    />
  );
};

VehiclesModelYearFilterItem.propTypes = {
  filter: PropTypes.shape(FilterPanelFilterShape).isRequired,
};

export default VehiclesModelYearFilterItem;
