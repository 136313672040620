import React from 'react';
import { Button } from '@gsa/afp-component-library';

function NextButton({ ...props }) {
  return (
    <Button
      type="button"
      className="afp-vehicle-next-btn"
      rightIcon={{ name: 'arrow_forward' }}
      label="Next"
      {...props}
    />
  );
}

export default NextButton;
