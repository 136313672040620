/* eslint-disable react/prop-types */
// eslint-disable-next-line filenames/match-exported
import React from 'react';
import { RequiredFieldIndicator, TextInput } from '@gsa/afp-component-library';

import { startCase } from 'lodash';
import { Controller, useFormContext } from 'react-hook-form';
import { useOfficeManagement } from '../office-management-provider';

const OfficeEditDetailFields = ({ selectedOrganization }) => {
  const { dispatchAction } = useOfficeManagement();
  const {
    formState: { errors },
    control,
  } = useFormContext();
  const { agencyCode, bureauCode, officeCode, agencyName, bureauName } =
    selectedOrganization;

  return (
    <>
      <div>
        <p>
          Use this tool to edit and name offices within your agency/bureau to be
          displayed throughout GSAFleet.gov &apos;s Organization filters.
        </p>
        <p className="text-bold">
          Agency <RequiredFieldIndicator />
        </p>
        <p>
          {agencyCode} - {startCase(agencyName?.toLocaleLowerCase())}
        </p>
        <p className="text-bold">
          Bureau <RequiredFieldIndicator />
        </p>
        <p>
          {bureauCode} - {startCase(bureauName.toLocaleLowerCase())}
        </p>
        <p className="text-bold">
          Office code <RequiredFieldIndicator />
        </p>
        <p>{officeCode}</p>
        <Controller
          name="officeName"
          control={control}
          render={({ field, ref }) => {
            return (
              <TextInput
                {...field}
                ref={ref}
                maxLength="100"
                characterLimit={100}
                label="Office name"
                required
                name="officeName"
                id="officeName"
                onKeyDown={() => {
                  dispatchAction('SET_FIELD_VALIDATION_ERRORS', {
                    fieldName: '',
                    message: '',
                  });
                }}
                errorMessage={
                  errors && errors?.officeName
                    ? errors?.officeName?.message
                    : null
                }
                aria-invalid={errors && errors?.officeName ? 'true' : 'false'}
              />
            );
          }}
        />
      </div>
    </>
  );
};

export default OfficeEditDetailFields;
