// eslint-disable-next-line filenames/match-exported
import PropTypes from 'prop-types';
import { RadioButton } from '@gsa/afp-component-library';
import React from 'react';

const FleetCardContactOption = ({
  contact,
  label,
  contactOption,
  selectedContactOption,
  onContactOptionChange,
}) => {
  const { id, firstName, lastName, phoneNumber, email, extension } = contact;

  const formatPhone = (fPhone, fExtension) => {
    if (!fPhone) return '';
    const phoneNo =
      fPhone.length === 10
        ? `${fPhone.substring(0, 3)}-${fPhone.substring(
            3,
            6,
          )}-${fPhone.substring(6)}`
        : fPhone;
    return fExtension ? `${phoneNo} ext ${fExtension}` : phoneNo;
  };

  return (
    <div
      data-testid={`"customer-contact-${contactOption}`}
      className={`usa-card__container padding-x-1 margin-x-0 margin-y-2 cursor-pointer grid-col-8 ${
        contactOption === selectedContactOption &&
        'bg-primary-lighter border-accent-cool-darker'
      }`}
    >
      <RadioButton
        name="contact"
        data-testid={`${contactOption}-customer-contact-option`}
        value={selectedContactOption}
        checked={contactOption === selectedContactOption}
        onChange={() => onContactOptionChange(contactOption, id)}
        label={label}
        className={`${
          contactOption === selectedContactOption && 'bg-primary-lighter'
        }`}
      />
      {contactOption !== 'new' ? (
        <div className="margin-x-4 padding-y-1">
          <address>
            {firstName && lastName && `${firstName} ${lastName}`}
            <br />
            {formatPhone(phoneNumber, extension)}
            <br />
            {email}
          </address>
        </div>
      ) : (
        <div className="padding-bottom-2" />
      )}
    </div>
  );
};

FleetCardContactOption.propTypes = {
  contact: PropTypes.shape({
    id: PropTypes.string,
    email: PropTypes.string,
    extension: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    phoneNumber: PropTypes.string,
  }).isRequired,
  contactOption: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onContactOptionChange: PropTypes.func.isRequired,
  selectedContactOption: PropTypes.string.isRequired,
};

export default FleetCardContactOption;
