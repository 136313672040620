// eslint-disable-next-line filenames/match-exported, import/prefer-default-export
export const dlaExpenseMapping = [
  ['Product', 'productCode', 'string'],
  ['Quantity', 'unitsBought', 'string'],
  ['Unit Cost', 'unitCost', 'string'],
  ['Extended Cost', 'grossCost', 'string'],
  ['Customer ID', 'dodaac', 'string'],
  ['Vehicle ID', 'tagNumber', 'string'],
  ['Seller DoDAAC', 'sellerDodaac', 'string'],
  ['Bill Num', 'merchantInvoice', 'string'],
  ['Milstrip Document Number', 'milStrip', 'string'],
  ['Transaction Date', 'transactionDate', 'string'],
  ['Odometer', 'odometerReading', 'string'],
  ['Tail', 'tail', 'string'],
  ['Taxed Total', 'taxedTotal', 'string'],
];
