import { gql } from '@apollo/client';

export const GET_BOACS_BY_AGENCY = gql`
  query GetBoacsByAgency($getBoacsArgs: GetBoacsInput!) {
    getBoacsByAgency(getBoacsArgs: $getBoacsArgs) {
      boacId
      boac
    }
  }
`;

export const GET_LEGACY_CUSTOMERS_BY_AGENCY = gql`
  query GetLegacyCustomersByAgency($agencyCode: String!) {
    getLegacyCustomersByAgency(agencyCode: $agencyCode) {
      legacyCustomerNumber
    }
  }
`;

export const GET_AGENCY_INDICATORS_BY_AGENCY_BUREAU = gql`
  query GetAgencyIndicatorsByAgencyBureau(
    $agencyCode: String!
    $bureauCode: String
  ) {
    getAgencyIndicatorsByAgencyBureau(
      agencyCode: $agencyCode
      bureauCode: $bureauCode
    ) {
      agencySubGroup
    }
  }
`;
