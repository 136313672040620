import React from 'react';
import { Link } from 'react-router-dom';

const omitKey = (obj, key) => {
  const { [key]: omitted, ...rest } = obj;
  return rest;
};

export const omitKeys = (items, keys) =>
  keys.reduce((acc, currentKey) => {
    return omitKey(acc, currentKey);
  }, items);

export const generateSideNavItems = ({ items, omitItemsFunction = () => [] }) =>
  Object.keys(omitKeys(items, omitItemsFunction())).reduce((result, id) => {
    if (!items[id] || items[id].hidden) {
      return result;
    }
    const { label, count, active, icon } = items[id];
    result.push({
      id,
      body: (
        <Link to={`${id}`} key={id} aria-current={active ? 'page' : null}>
          {label}{' '}
          {count && (
            <span className="usa-sr-only">
              this link has {count} notification{count > 1 && 's'}
            </span>
          )}
        </Link>
      ),
      count,
      icon,
      active,
    });
    return result;
  }, []);

export const generationOptions = (aldCategory, categories) => {
  const defaultOption = { value: '', label: 'Select' };
  const categoryOptions =
    categories && categories[aldCategory]
      ? categories[aldCategory][0].options.sort((a, b) =>
          a.label.localeCompare(b.label),
        )
      : [];
  return [defaultOption, ...categoryOptions];
};

export const populateTelematicsOptions = (categories) => {
  const telematicsOptions = categories?.telematics_data_usage?.[0]?.options || [];
  return telematicsOptions.map((option) => ({
    value: option.value,
    label: `${option.value} - ${option.label}`,
  }));
}

export const populateBoolDropdown = (value) => {
  if (value === null) return '';
  // eslint-disable-next-line
  return value === true ? true : false;
};

export const populateCountryDropdown = (garageDomestic, countryCode) => {
  if (countryCode) return countryCode;
  return garageDomestic ? 'US' : '';
};

export const formatBoolDropdown = (value) => {
  if (value === '') return null;
  // eslint-disable-next-line
  return value === 'true' || value === true ? true : false;
};

export const populateBoolReadonly = (value) => {
  if (value === null) return '';
  return value === true ? 'Yes' : 'No';
};

export const populateValueByAldCode = (value, aldOptions) => {
  if (value === null) return '';
  return aldOptions?.find((option) => option?.code === value)?.description;
};
