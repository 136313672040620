import React from 'react';
import { Route, useParams, Link } from 'react-router-dom';
import { Spinner, Breadcrumbs } from '@gsa/afp-component-library';
import { useTitle } from '@gsa/afp-shared-ui-utils';
import OrderDetailsHeader from './widgets/order-details-header';
import OrderStatus from './widgets/order-status';
import OrderDetailsSection from './widgets/order-details-section';
import Context from './context';
import useOrderDetailsManager from './helpers/order-details-manager';
import useLicensePlateDestructionManager from '../license-plate/widgets/helpers/license-plate-destruction-manager';
import LicensePlateDestructionContextProvider from '../license-plate-destruction/context';
import LicensePlateDestruction from '../license-plate-destruction';
import LicensePlateInitDestruction from '../license-plate/widgets/license-plate-init-destruction';
import { isTest } from '../../utilities/consts';
import useAlertManager from '../../utilities/alert-manager';
import OrderTagTableListing from './widgets/order-tag-table-listing';

const trail = [
  <a href={`${window.AFP_CONFIG.appURLs.home}/home`}>Home</a>,
  <Link to="/orders">License plate orders</Link>,
];

export default function OrderDetails({ match }) {
  let { orderNumber } = useParams();
  if (orderNumber) {
    orderNumber = decodeURIComponent(orderNumber);
  }
  useTitle(`UNICOR Order #${orderNumber}`);

  const [RcAlert, alert] = useAlertManager();

  const handleReconcile = (msgType) => {
    if (msgType === 'success') {
      alert.showSuccessAlert(
        <span>
          The status of the license plates have been updated to{' '}
          <span className="text-bold">received.</span>
        </span>,
      );
    }

    if (msgType === 'error') {
      alert.showErrorAlert(<span>Failed to Reconcile</span>);
    }
  };

  const {
    details,
    setDetails,
    tags,
    setTags,
    loading,
    updateDetails,
    getOrder,
    updateOrderDetails,
    refetchOrder,
    filters,
    limit,
    offset,
    sortOrder,
    setSortOrder,
    setFilter,
    setLimit,
    setOffset,
    updateTagsDetails,
    refetchOrderTags,
    getOrderTags,
    loadingOrderTags,
    isReconcileModalOpen,
    openReconcileModal,
    closeReconcileModal,
    isDamagedModalOpen,
    openDamagedModal,
    closeDamagedModal,
    isLostStolenTagModalOpen,
    openLostStolenTagModal,
    closeLostStolenTagModal,
    tag,
    setTag,
    reconTags,
    reconciledTags,
    reconciledTagsLoading,
    reconciledTagsError,
    openReconcileModalWithTag,
    reportTag,
    reportedTag,
    loadingOrderTagLostStolen,
    errorOrderTagLostStolen,
    openLostStolenModalWithTag,
    openDamagedModalWithTag,
    reportDamageTag,
    reportedDamageTag,
    errorDamagedTag,
    loadingDamagedTag,
    getLpCount,
    getExceptionCount,
    saveReconcile,
    toggleReconcileEdit,
    reconcileEditing,
    isOrderReconcileModalOpen,
    openOrderReconcileModal,
    closeOrderReconcileModal,
    tagUpdateStatus,
    reconcileOrderLoading,
  } = useOrderDetailsManager(orderNumber, handleReconcile);

  const {
    forDestructionList,
    setForDestructionList,
    setEligibleForDestruction,
    getIneligiblePlateDistribution,
    ineligibleDestructionList,
    destructionInitData,
    setDestructionInitData,
    toggleDestructionEditing,
    destructionEditing,
    saveDestruction,
  } = useLicensePlateDestructionManager();

  const handleEditAgencyAlert = (msgType) => {
    if (msgType === 'success') {
      alert.showSuccessAlert(
        <span>
          Agency information of order and associated tag(s) has been
          successfully updated
        </span>,
      );
    }

    if (msgType === 'error') {
      alert.showErrorAlert(<span>Failed to edit agency information </span>);
    }
  };

  if (orderNumber) {
    return (
      <article className="orders">
        <section className="orders-container">
          <Context
            details={details}
            setDetails={setDetails}
            tags={tags}
            getOrderTags={getOrderTags}
            orderNumber={orderNumber}
            loadingOrderTags={loadingOrderTags}
            setTags={setTags}
            loading={loading}
            updateDetails={updateDetails}
            getOrder={getOrder}
            updateOrderDetails={updateOrderDetails}
            refetchOrder={refetchOrder}
            filters={filters}
            limit={limit}
            offset={offset}
            sortOrder={sortOrder}
            setSortOrder={setSortOrder}
            setFilter={setFilter}
            setLimit={setLimit}
            setOffset={setOffset}
            updateTagsDetails={updateTagsDetails}
            refetchOrderTags={refetchOrderTags}
            isReconcileModalOpen={isReconcileModalOpen}
            openReconcileModal={openReconcileModal}
            closeReconcileModal={closeReconcileModal}
            isDamagedModalOpen={isDamagedModalOpen}
            openDamagedModal={openDamagedModal}
            closeDamagedModal={closeDamagedModal}
            isLostStolenTagModalOpen={isLostStolenTagModalOpen}
            openLostStolenTagModal={openLostStolenTagModal}
            closeLostStolenTagModal={closeLostStolenTagModal}
            tag={tag}
            setTag={setTag}
            reconTags={reconTags}
            reconciledTags={reconciledTags}
            reconciledTagsLoading={reconciledTagsLoading}
            reconciledTagsError={reconciledTagsError}
            openReconcileModalWithTag={openReconcileModalWithTag}
            reportTag={reportTag}
            reportedTag={reportedTag}
            loadingOrderTagLostStolen={loadingOrderTagLostStolen}
            errorOrderTagLostStolen={errorOrderTagLostStolen}
            openLostStolenModalWithTag={openLostStolenModalWithTag}
            openDamagedModalWithTag={openDamagedModalWithTag}
            reportDamageTag={reportDamageTag}
            reportedDamageTag={reportedDamageTag}
            errorDamagedTag={errorDamagedTag}
            loadingDamagedTag={loadingDamagedTag}
            getLpCount={getLpCount}
            getExceptionCount={getExceptionCount}
            saveReconcile={saveReconcile}
            toggleReconcileEdit={toggleReconcileEdit}
            reconcileEditing={reconcileEditing}
            isOrderReconcileModalOpen={isOrderReconcileModalOpen}
            openOrderReconcileModal={openOrderReconcileModal}
            closeOrderReconcileModal={closeOrderReconcileModal}
            tagUpdateStatus={tagUpdateStatus}
            reconcileOrderLoading={reconcileOrderLoading}
          >
            <LicensePlateDestructionContextProvider
              forDestructionList={forDestructionList}
              setForDestructionList={setForDestructionList}
              setEligibleForDestruction={setEligibleForDestruction}
              getIneligiblePlateDistribution={getIneligiblePlateDistribution}
              ineligibleDestructionList={ineligibleDestructionList}
              setDestructionInitData={setDestructionInitData}
              destructionInitData={destructionInitData}
              destructionEditing={destructionEditing}
              toggleDestructionEditing={toggleDestructionEditing}
              saveDestruction={saveDestruction}
            >
              <Route
                exact
                path={`${match.url}/destruction`}
                render={() => {
                  return (
                    <LicensePlateDestruction returnRoute={`${match.url}`} />
                  );
                }}
              />

              <Route
                exact
                path={`${match.url}`}
                render={() => {
                  if (loading && !isTest)
                    return <Spinner className="padding-y-9" />;
                  return (
                    <>
                      <Breadcrumbs
                        trail={trail}
                        current={`UNICOR Order ${orderNumber}`}
                      />
                      <RcAlert />

                      <OrderDetailsHeader orderNumber={orderNumber} />

                      {details ? (
                        <>
                          <div className="grid-row grid-gap">
                            <div className="tablet:grid-col-6">
                              <OrderStatus />
                            </div>
                          </div>
                          <OrderDetailsSection
                            ariaLabel="edit point of contact"
                            onEditAgency={handleEditAgencyAlert}
                          />
                          <OrderTagTableListing onReconcile={handleReconcile} />

                          <LicensePlateInitDestruction
                            destructionInitData={destructionInitData}
                            setDestructionInitData={setDestructionInitData}
                            editing={destructionEditing}
                            onClose={toggleDestructionEditing}
                            onSave={saveDestruction}
                          />
                        </>
                      ) : null}
                    </>
                  );
                }}
              />
            </LicensePlateDestructionContextProvider>
          </Context>
        </section>
      </article>
    );
  }
  return null;
}
