import React from 'react';
import _ from 'lodash';
import { CounterTag } from '@gsa/afp-component-library';
import { useRegistrationDetail } from './registration-detail-context-provider';

const VehicleData = () => {
  const { registrationDetailComments } = useRegistrationDetail();
  return (
    <div className="margin-top-3 display-flex flex-justify-end">
      <span className="padding-x-2">
        {/* TODO: post-MVP */}
        {/* <CounterTag count={0}>History</CounterTag> */}
      </span>
      <span className="padding-x-2">
        <CounterTag count={registrationDetailComments.count || 0}>
          Comments
        </CounterTag>
      </span>
    </div>
  );
};

export default VehicleData;
