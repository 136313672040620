// eslint-disable-next-line filenames/match-exported
import React from 'react';
import PropTypes from 'prop-types';

export default function OrderDetailEmail({ label, email }) {
  return (
    <div className="detail-row display-flex flex-justify padding-y-1 border-bottom border-width-2px border-base-lighter">
      <div className="detail-title">
        <strong>{label}</strong>
      </div>
      <div className="detail-value">
        {email ? <a href={`mailto:${email}`}>{email}</a> : 'System'}
      </div>
    </div>
  );
}

OrderDetailEmail.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  email: PropTypes.string,
};

OrderDetailEmail.defaultProps = {
  value: null,
  email: null,
};
