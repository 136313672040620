/* eslint-disable react/prop-types */
import React from 'react';
import { Typeahead, RequiredFieldIndicator } from '@gsa/afp-component-library';
import { emdash } from 'components/common';

export const AddMemberTypeAHead = ({
  fieldAttrs,
  setValue,
  field,
  errors,
  clearErrors,
  providerContext,
  setError,
}) => {
  return (
    <>
      <Typeahead
        label={
          <>
            <div className="text-bold">
              {fieldAttrs.label} <RequiredFieldIndicator />
            </div>
          </>
        }
        filterValue={field?.value ?? ''}
        promptText="Please input user's email and select the user"
        placeholder="sample@gsa.gov"
        onOptionEnter={(option) => {
          providerContext.dispatchAction('SET_DRIVERS_TYPEAHEAD', {
            filterValue: option,
          });
          setValue(fieldAttrs.name, option);
          clearErrors(fieldAttrs.name);
        }}
        clearErrors
        inputCharNum={3}
        debounceDelay={500}
        typeaheadValues={providerContext.typeaheadDrivers.values.map(
          (val) => `${val?.fullName} ${emdash} ${val?.email}`,
        )}
        fetchTypeaheadValues={(_accessor, value) => {
          providerContext.getDriversAndDispatchersByEmail({
            variables: {
              email: value,
              poolId: providerContext?.selectedMotorPoolById?.id,
            },
          });
        }}
        onClear={() => {
          setValue(fieldAttrs.name, '');
          setError(fieldAttrs.name, {
            type: 'custom',
            message: 'This is a required field',
          });
        }}
        errorMessage={
          errors && errors[fieldAttrs.name]
            ? errors[fieldAttrs.name].message
            : null
        }
        aria-invalid={errors && errors[fieldAttrs.name] ? 'true' : 'false'}
      />
    </>
  );
};
