/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  Button,
  Spinner,
  Modal,
  Alert,
  useModal,
  RequiredFieldIndicator,
} from '@gsa/afp-component-library';
import { useVehicle } from 'components/vehicle-details/vehicle-context-provider';
import { emdash } from 'components/common';
import UpdateVehicleStatusForm from './update-plate-form';
import { UPDATE_VEHICLE_LICENSE_PLATE } from './update-plate.gql';

const UpdatePlate = ({ onClose }) => {
  const { vehicle, setAlertMessages, refetchVehicle } = useVehicle();
  const { openModal, closeModal } = useModal();
  const [error, setError] = useState();

  const [
    updateVehicleLicensePlate,
    { loading: updateVehicleLicensePlateLoading },
  ] = useMutation(UPDATE_VEHICLE_LICENSE_PLATE, {
    fetchPolicy: 'no-cache',
    onError: () => {
      setError('Error while changing the vehicle license plate.');
    },
    onCompleted: (response) => {
      setAlertMessages([
        {
          type: 'success',
          message: `Plate was successfully changed to ${response?.updateVehicleLicensePlate?.id}.`,
        },
      ]);
      closeModal();
      refetchVehicle();
    },
  });

  useEffect(() => {
    openModal();
  }, []);

  const changeTag = (data) => {
    updateVehicleLicensePlate({
      variables: {
        tagInput: {
          vin: vehicle.id,
          oldTagNumber: vehicle.tagNumber,
          newTagNumber: data.tagNumber,
          reasonForChange: data.reason,
        },
      },
    });
  };

  const close = () => {
    onClose && onClose();
    closeModal();
  };

  return (
    <div className="afp-modal-wrapper">
      <div className="afp-modal-overlay">
        <Modal
          title={<h1>Change Plate</h1>}
          variant="large"
          actions={
            <>
              <Button
                variant="unstyled"
                id="cancelUpdateVehicleStatus"
                data-testid="cancelUpdateVehicleStatus"
                label="Cancel"
                onClick={close}
              />

              <Button
                className="margin-left-2"
                type="submit"
                label="Save and close"
                form="change-plate-form"
                disabled={updateVehicleLicensePlateLoading}
              />
              {updateVehicleLicensePlateLoading && (
                <Spinner
                  size="small"
                  aria-busy="true"
                  className="display-inline-block margin-left-2"
                />
              )}
            </>
          }
          onClose={close}
        >
          {error && (
            <Alert type="error" slim>
              {error}
            </Alert>
          )}
          <p>
            Edit plate information for VIN <strong>{vehicle?.id}</strong> in the
            form below.
          </p>
          <p>
            Required fields are marked with an asterisk (
            <RequiredFieldIndicator />
            ).
          </p>
          <p>
            <div>
              <strong>Current associated plate</strong>
            </div>
            <span>{vehicle?.tagNumber || emdash}</span>
          </p>
          <UpdateVehicleStatusForm onSubmit={changeTag} />
        </Modal>
      </div>
    </div>
  );
};

export default UpdatePlate;
