/* eslint-disable react/prop-types */
/* eslint-disable filenames/match-exported */

import React from 'react';

import { Button } from '@gsa/afp-component-library';

const DeleteModal = ({
  title = 'Delete confirmation',
  subtitle,
  saveFn,
  closeModal,
  Modal,
}) => {
  const onSave = () => {
    saveFn();
  };
  const onClose = () => {
    closeModal();
  };

  const ModalHeader = () => {
    return (
      <>
        <h2>{title}</h2>
        <p className="font-body-xs margin-bottom-10">{subtitle}</p>
      </>
    );
  };
  return (
    <Modal
      actions={
        <>
          <Button variant="unstyled" label="Cancel" onClick={onClose} />
          <Button
            variant="secondary"
            className="margin-left-2"
            onClick={onSave}
            label="Delete"
          />
        </>
      }
      title={<ModalHeader />}
      onClose={onClose}
    />
  );
};

export default DeleteModal;
