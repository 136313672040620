import React from 'react';
import LpReconciliation from './lp-reconciliation';
import { useLicensePlateDetails } from '../license-plate-details-provider';

const LpReconciliationSection = () => {
  const {
    reconciliationAlert,
    reconciliationPayload,
    setReconciliationPayload,
    reconcileTag,
  } = useLicensePlateDetails();

  return (
    <div className="grid-row margin-bottom-1" data-testid="lp-reconciliation">
      <div className="grid-col-fill">
        <LpReconciliation
          reconciliationPayload={reconciliationPayload}
          setReconciliationPayload={setReconciliationPayload}
          reconcileTag={reconcileTag}
          reconciliationAlert={reconciliationAlert}
        />
      </div>
    </div>
  );
};

export default LpReconciliationSection;
