/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { Button } from '@gsa/afp-component-library';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import { canUpdateTagOrders } from 'utilities/authorization';
import {
  canBulkReconcile,
  generateDisassociationMessage,
} from 'utilities/common';
import useAlertManager from '../../../utilities/alert-manager';
import { useOrder } from '../context';
import './order-tag-table.css';
import OrderTagFilterGrid from './order-tag-filter-grid';
import OrderReconcileModal from './order-reconcile-modal';
import OrderTagReconModal from './order-tag-recon-modal';
import OrderTagLostStolenModal from './order-tag-lost-stolen-modal';
import OrderTagDamagedModal from './order-tag-damaged-modal';

export default function OrderTagTableListing({ onReconcile }) {
  const ability = useAppAbility();
  const {
    filters,
    tags,
    updateTagsDetails,
    closeReconcileModal,
    closeLostStolenTagModal,
    closeDamagedModal,
    reconciledTags: reconedTags,
    reconciledTagsError,
    reportedLostStolen,
    errorOrderTagLostStolen: lostStolenError,
    reportedDamageTag: damagedReported,
    errorDamagedTag: damagedError,
    getLpCount,
    saveReconcile,
    openOrderReconcileModal,
  } = useOrder();

  const [Alert, alert] = useAlertManager();

  useEffect(() => {
    if (lostStolenError || damagedError || reconciledTagsError) {
      const alertMsg =
        (lostStolenError || damagedError || reconciledTagsError)?.message ||
        'Unsuccessful action.';
      alert.showErrorAlert(alertMsg);
    }
  }, [lostStolenError, damagedError, reconciledTagsError]);

  useEffect(() => {
    if (reconedTags?.reconcileTags) {
      if (reconedTags?.reconcileTags?.reconciledUnicorTags.length) {
        updateTagsDetails(reconedTags?.reconcileTags?.reconciledUnicorTags);
        closeReconcileModal();
        alert.showSuccessAlert(
          <span>
            The status of license plate{' '}
            <span className="text-bold">
              {reconedTags?.reconcileTags?.reconciledUnicorTags[0].tagNumber}
            </span>{' '}
            has been updated to <span className="text-bold">received.</span>
          </span>,
        );
      } else {
        alert.showWarningAlert(
          'Selected license plate is not eligible to reconcile.',
        );
      }
    }
  }, [reconedTags]);

  useEffect(() => {
    if (damagedReported?.reportLostStolenTags) {
      if (damagedReported?.reportLostStolenTags?.reportedUnicorTags.length) {
        updateTagsDetails(
          damagedReported?.reportLostStolenTags?.reportedUnicorTags,
        );
        closeDamagedModal();
        if (damagedReported?.reportLostStolenTags?.reportedTags[0]?.vehicle) {
          const { tagNumber, vehicle, expirationDate } =
            damagedReported?.reportLostStolenTags?.reportedTags[0];
          alert.showWarningAlert(
            generateDisassociationMessage({
              tagNumber,
              expirationDate,
              vinToDisassociateFrom: vehicle.id,
              type: 'damaged',
            }),
            'License plate reported lost or stolen',
          );
        } else {
          const { tagNumber, tagExpirationDate } =
            damagedReported?.reportLostStolenTags?.reportedUnicorTags[0];
          alert.showWarningAlert(
            generateDisassociationMessage({
              tagNumber,
              expirationDate: tagExpirationDate,
              vinToDisassociateFrom: null,
              type: 'damaged',
            }),
            'License plate reported lost or stolen',
          );
        }
      } else {
        alert.showWarningAlert(
          'Selected license plate is not eligible to be reported damaged.',
        );
      }
    }
  }, [damagedReported]);

  useEffect(() => {
    if (reportedLostStolen?.reportLostStolenTags) {
      if (reportedLostStolen?.reportLostStolenTags?.reportedUnicorTags.length) {
        updateTagsDetails(
          reportedLostStolen?.reportLostStolenTags?.reportedUnicorTags,
        );
        closeLostStolenTagModal();
        if (
          reportedLostStolen?.reportLostStolenTags?.reportedTags[0]?.vehicle
        ) {
          const { tagNumber, vehicle, expirationDate } =
            reportedLostStolen?.reportLostStolenTags?.reportedTags[0];
          alert.showWarningAlert(
            generateDisassociationMessage({
              tagNumber,
              expirationDate,
              vinToDisassociateFrom: vehicle.id,
              type: 'lostStolen',
            }),
            'License plate reported lost or stolen',
          );
        } else {
          const { tagNumber, tagExpirationDate } =
            reportedLostStolen?.reportLostStolenTags?.reportedUnicorTags[0];
          alert.showWarningAlert(
            generateDisassociationMessage({
              tagNumber,
              expirationDate: tagExpirationDate,
              vinToDisassociateFrom: null,
              type: 'lostStolen',
            }),
            'License plate reported lost or stolen',
          );
        }
      } else {
        alert.showWarningAlert(
          'Selected license plate is not eligible to be reported lost or stolen.',
        );
      }
    }
  }, [reportedLostStolen]);

  const shouldAllowToReconcile = (order) => {
    // allow reconciliation of the FULL order only if no filters were applied
    if (filters[0]?.conditions.length > 1) return false;
    return canBulkReconcile(
      order.tagNumber,
      order.tagStatus,
      order.activity?.createdBy || order.updatedBy,
    );
  };

  const isReconcileAllowedForAllPlates = !!tags.rows.find(
    shouldAllowToReconcile,
  );
  const canUpdateOrders = canUpdateTagOrders(ability);

  if (tags) {
    return (
      <div id="license-plates">
        <h3>License Plates</h3>

        <Alert />

        <div className="order-details-table-header tablet:display-flex flex-justify padding-2 bg-gray-3 border-base border-bottom-2px radius-sm">
          <div className="tag-count display-flex flex-align-center">
            <span className="text-bold margin-right-05">Total plates:</span>
            {tags?.count}
          </div>
          {isReconcileAllowedForAllPlates && canUpdateOrders && (
            <Button
              className="display-flex"
              onClick={openOrderReconcileModal}
              leftIcon={{ name: 'check_circle' }}
              label="Reconcile full license plate order"
            />
          )}
        </div>

        <OrderTagFilterGrid onReconcile={onReconcile} />

        <OrderReconcileModal lpCount={getLpCount()} onSave={saveReconcile} />

        <OrderTagLostStolenModal />
        <OrderTagReconModal />
        <OrderTagDamagedModal />
      </div>
    );
  }
  return null;
}
