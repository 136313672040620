import React, { useMemo, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  AFPTable,
  Menu,
  EmptyState,
  Pagination,
  Spinner,
} from '@gsa/afp-component-library';
import { useCurrentUser } from '@gsa/afp-shared-ui-utils';
import { VMSOperations } from 'utilities/consts';
import { get } from 'lodash';
import moment from 'moment-timezone';
import { emdash } from 'components/common';
import { useMotorPool } from '../../motor-pool-provider';
import { useMotorPoolFilter } from '../../filters/filter-provider';
import ReservationsModal from './reservations-modal';
import { VehicleTableCell } from '../vehicle-table-cell';
import ReservationStatusBadge from './reservation-status-badge';
import ReservationDetailsRow from './reservation-details-row';
import { useMutation } from '@apollo/client';
import { CREATE_SUPPORTING_DOC } from '../../../../services/data-layer';
import { getReservationReceiptPDFPayload } from '../../helpers/utils';

const actionList = [
  {
    icon: 'check',
    label: 'Start reservation',
    action: 'startReservation',
    operation: VMSOperations.Update,
    canShowIndicator: 'canStartOrCancel',
  },
  {
    icon: 'edit',
    label: 'Update reservation',
    action: 'editReservation',
    operation: VMSOperations.Update,
    canShowIndicator: 'canUpdate',
  },
  {
    icon: 'delete',
    label: 'Cancel reservation',
    action: 'cancelReservation',
    operation: VMSOperations.CANCEL,
    canShowIndicator: 'canStartOrCancel',
  },
  {
    icon: 'pdf',
    iconType: 'custom',
    label: 'Generate Reservation Receipt',
    action: 'generateReservationReceipt',
    operation: VMSOperations.View,
  },
  {
    icon: 'check',
    label: 'Complete reservation',
    action: 'closeReservation',
    operation: VMSOperations.Update,
    canShowIndicator: 'canCloseComplete',
  },
];

const initialPaginationState = {
  limit: 10,
  offset: 0,
  currentPage: 1,
  isReset: false,
};

const tableRef = React.createRef();

const ReservationsListingTable = () => {
  const params = useParams();
  const { id } = params;
  const [paginationState, setPaginationState] = useState(
    initialPaginationState,
  );
  const [order, setOrder] = useState('scheduledPickupDate DESC');
  const { currentUser } = useCurrentUser();

  const {
    getPoolReservationsList,
    reservationsList,
    reservationsListLoading,
    dispatchAction,
    resetReservationModal,
    selectedMotorPoolById,
  } = useMotorPool();

  const { filters } = useMotorPoolFilter();

  // this is to make sure that the table data renders when the selectedMotorPoolById loads
  const uniqueId = useMemo(() => {
    if (!reservationsList?.rows?.length) return '';
    return `${reservationsList?.rows?.length}${selectedMotorPoolById?.id}`;
  }, [reservationsList?.rows, selectedMotorPoolById?.id]);

  const [createSupportingDoc, { loading: creatingSupportingDoc }] = useMutation(
    CREATE_SUPPORTING_DOC,
    {
      fetchPolicy: 'no-cache',
      onError: () =>
        dispatchAction('SET_ALERT_MESSAGE', {
          context: 'reservationListing',
          type: 'error',
          message:
            'An unexpected error has occurred when generating reservation receipt.',
        }),
    },
  );

  const getData = () => {
    getPoolReservationsList({
      variables: {
        motorPoolId: id,
        limit: paginationState.limit,
        offset: paginationState.offset,
        order,
        filters: filters.reservationsListing,
      },
    });
  };

  useEffect(() => {
    dispatchAction('SET_ALERT_MESSAGE', { type: '', message: '' });
    dispatchAction('SET_SELECTED_VEHICLE_AVAILABLE_DATES', null);
    resetReservationModal();
    getData();
  }, []);

  useEffect(() => {
    getData();
  }, [filters?.reservationsListing, paginationState, order]);

  useEffect(() => {
    setPaginationState({
      ...paginationState,
      offset: 0,
      currentPage: 1,
      isReset: true,
    });
  }, [filters?.reservationsListing]);

  const handleSelectedAction = async (action, row) => {
    if (row) {
      dispatchAction('SET_SELECTED_RESERVATION', row.original);
    }
    switch (action) {
      case 'startReservation':
        dispatchAction('SET_RESERVATION_MODAL_MODE', 'START_RESERVATION');
        break;
      case 'closeReservation':
        dispatchAction(
          'SET_RESERVATION_MODAL_MODE',
          'CLOSE_AND_COMPLETE_RESERVATION',
        );
        break;
      case 'editReservation':
        dispatchAction('SET_RESERVATION_MODAL_MODE', 'UPDATE_RESERVATION');
        break;
      case 'cancelReservation':
        dispatchAction('SET_RESERVATION_MODAL_MODE', 'CANCEL_RESERVATION');
        break;
      default:
        break;
    }

    if (action === 'generateReservationReceipt') {
      const payload = getReservationReceiptPDFPayload(
        selectedMotorPoolById,
        row.original,
      );
      const createResponse = await createSupportingDoc({
        variables: {
          data: payload,
          model: 'Reservation',
          modelPK: row.original.id,
          documentName: 'receipt',
        },
      });
      window.open(createResponse?.data?.createSupportingDoc);
    } else {
      dispatchAction('SET_SHOW_RESERVATION_MODAL', true);
    }
  };

  const formatReservationDate = (value, timezone) => {
    return <>{moment.tz(value, timezone).format('L h:mm A z')}</>;
  };

  const columns = [
    {
      Header: 'Driver name',
      accessor: 'driverName',
      sortable: true,
      Cell: ({ value }) => {
        return value || emdash;
      },
    },
    {
      Header: 'Vehicle',
      accessor: 'motorPoolVehicle.vehicle.vin',
      sortable: true,
      headerClassName: 'width-card-lg',
      // eslint-disable-next-line
      Cell: ({ value, row: { original } }) => (
        <VehicleTableCell
          key={value}
          // eslint-disable-next-line
          value={original.motorPoolVehicle.vehicle}
          showLink={selectedMotorPoolById?.managesPool}
        />
      ),
    },
    {
      Header: 'Scheduled start date',
      accessor: 'scheduledPickupDate',
      sortable: true,
      Cell: ({ value }) =>
        formatReservationDate(value, selectedMotorPoolById?.timezone),
    },
    {
      Header: 'Scheduled end date',
      accessor: 'scheduledReturnDate',
      sortable: true,
      Cell: ({ value }) =>
        formatReservationDate(value, selectedMotorPoolById?.timezone),
    },
    {
      Header: 'Status',
      accessor: 'reservationStatus.status',
      sortable: true,
      // eslint-disable-next-line react/prop-types
      Cell: ({ value }) => {
        return value ? <ReservationStatusBadge status={value} /> : emdash;
      },
    },
    {
      Header: 'Actions',
      sortable: false,
      // eslint-disable-next-line react/prop-types
      Cell: ({ row }) => {
        // eslint-disable-next-line react/prop-types
        const { original } = row;

        if (
          // eslint-disable-next-line react/prop-types
          currentUser.id !== original?.motorPoolMember?.member?.id &&
          // eslint-disable-next-line react/prop-types
          !selectedMotorPoolById?.managesPool
        )
          return emdash;

        const allowedActions = actionList.filter((a) => {
          const canShow = get(a, 'canShowIndicator');
          // eslint-disable-next-line react/prop-types
          return !canShow || get(row.original, canShow, true);
        });

        if (allowedActions?.length === 0) return emdash;

        return (
          <div className="height-4 width-4 margin-left-05em">
            <Menu
              actionIconSize="usa-icon--size-4"
              // eslint-disable-next-line react/prop-types
              menuItems={allowedActions}
              onActionClick={(action) => {
                // eslint-disable-next-line react/prop-types
                handleSelectedAction(action, row);
              }}
              iconColor="text-primary"
              menuDialogState="hover"
            />
          </div>
        );
      },
    },
  ];

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    // Calculate new offset.
    const offset = (currentPage - 1) * itemsPerPage;
    setPaginationState({
      limit: itemsPerPage,
      offset,
      currentPage,
    });
  };

  if (creatingSupportingDoc) {
    return (
      <Spinner aria-busy="true" className="loading_backdrop" size="large" />
    );
  }

  return (
    <>
      <AFPTable
        key={uniqueId}
        fullWidth
        ref={tableRef}
        defaultSort={order}
        onSort={setOrder}
        testId="reservations-listing-table"
        columns={columns}
        data={!reservationsListLoading ? reservationsList.rows : []}
        expandable
        renderRowSubComponent={(r) => {
          return (
            <ReservationDetailsRow
              reservation={r.row.original}
              timezone={selectedMotorPoolById.timezone}
            />
          );
        }}
      />
      {reservationsListLoading && <Spinner className="padding-y-9" />}
      {reservationsList?.rows?.length > 0 && (
        <Pagination
          fullWidth
          variant="advanced"
          itemsPerPageOptions={[10, 25, 50]}
          itemsCount={reservationsList.count}
          itemsPerPage={paginationState.limit}
          currentPage={paginationState.currentPage}
          onPageChange={handlePaginationChange}
          isReset={paginationState.isReset}
        />
      )}
      {(!reservationsList || reservationsList.rows.length === 0) &&
        !reservationsListLoading && (
          <div className="bg-gray-3 padding-y-5">
            <div className="text-center padding-y-4">
              <EmptyState alt="Image not available" hasBackground />
            </div>
            <div
              className="text-center text-bold"
              data-testid="reservations-no-data"
            >
              No motor pool reservations available
            </div>
            <div className="text-center margin-top-2">
              Add or remove filters to search motor pool reservations
            </div>
          </div>
        )}
      <ReservationsModal />
    </>
  );
};

export default ReservationsListingTable;
