const pocValidationRules = {
  email: [
    {
      guard: 'validEmail',
      msg: 'Email address must be a valid email address.',
    },
  ],
  firstName: [{ guard: 'notEmpty', msg: 'First Name is required' }],
  lastName: [{ guard: 'notEmpty', msg: 'Last Name is required' }],
  countryCode: [{ guard: 'notEmpty', msg: 'Country is required' }],
  primaryAddress: [{ guard: 'notEmpty', msg: 'Address is required' }],
  city: [{ guard: 'notEmpty', msg: 'City is required' }],
  stateCode: [{ guard: 'notEmpty', msg: 'State is required' }],
  postalCode: [{ guard: 'notEmpty', msg: 'Zip is required' }],
  primaryPhone: [
    { guard: 'notEmpty', msg: 'Phone is required' },
    { guard: 'minLength10', msg: 'Phone length must be 10 characters' },
  ],
};
export default pocValidationRules;
