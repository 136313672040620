import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery, useQuery, useMutation } from '@apollo/client';
import { Link } from 'react-router-dom';
import {
  Modal,
  Button,
  DetailsTable,
  Alert,
  TextInput,
  SelectDropdown,
  RequiredFieldIndicator,
  Spinner,
} from '@gsa/afp-component-library';
import { VEHICLE_DETAIL_MOTOR_POOL } from 'services/data-layer';
import {
  GET_MOTOR_POOL_NAMES,
  CREATE_MOTOR_POOL_VEHICLE,
} from 'components/motor-pool/motor-pool.gql';
import { emdash } from 'components/common';

const VEHICLE_OWNERSHIP = {
  GF: 'GSA Leased',
  AO: 'Agency Owned',
};
const DEFAULT_POOLS_OPTION = [{ value: '', label: '- Select -' }];
const isActiveVehicle = (code) => code === 'AC' || code === '20';

const AddToMotorPoolModal = ({ isOpen, closeModal, vin, showAlert }) => {
  const [vehicle, setVehicle] = useState();
  const [vehicleValidationErrors, setVehicleValidationErrors] = useState([]);
  const [motorPoolsList, setMotorPoolsList] = useState([]);
  const [selectedPool, setSelectedPool] = useState({});
  const [submissionError, setSubmissionError] = useState({});
  const [requestError, setRequestError] = useState('');

  const getPoolNameById = (id) => {
    const { label } = motorPoolsList.find((pool) => pool.value === id);
    return label || '';
  };

  const { data: vehicleData, loading: loadingVehicleData } = useQuery(
    VEHICLE_DETAIL_MOTOR_POOL,
    {
      variables: {
        id: vin,
      },
      fetchPolicy: 'no-cache',
      onError: (vehicleDataError) => {
        setRequestError(vehicleDataError.message);
      },
    },
  );

  const [getMotorPoolsList, { loading: motorPoolsListLoading }] = useLazyQuery(
    GET_MOTOR_POOL_NAMES,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onError: (getMotorPoolsListError) => {
        setRequestError(getMotorPoolsListError.message);
      },
      onCompleted: (responseData) => {
        if (responseData?.getMotorPoolsList?.rows?.length > 0) {
          const formattedPoolOptions = responseData.getMotorPoolsList?.rows
            .filter((pool) => pool.managesPool === true)
            .map((pool) => {
              return {
                label: pool.poolName,
                value: pool.id,
              };
            });
          setMotorPoolsList([...DEFAULT_POOLS_OPTION, ...formattedPoolOptions]);
        }
      },
    },
  );

  const [createMotorPoolVehicle, { loading: creatingMotorPoolVehicle }] =
    useMutation(CREATE_MOTOR_POOL_VEHICLE, {
      fetchPolicy: 'no-cache',
      onError: (createMotorPoolVehicleError) => {
        setRequestError(createMotorPoolVehicleError.message);
      },
      onCompleted: (response) => {
        if (response?.createMotorPoolVehicle) {
          closeModal();
          showAlert(
            <span>
              Vehicle has been successfully added to the motor pool &quot;
              {getPoolNameById(response.createMotorPoolVehicle.motorPoolId)}
              &quot;. Please click{' '}
              <Link
                to={`/motor-pools/${encodeURIComponent(
                  response.createMotorPoolVehicle.motorPoolId,
                )}`}
              >
                here
              </Link>{' '}
              to navigate to the motor pool,
            </span>,
          );
        }
      },
    });

  const validateVehicle = (item) => {
    const errors = [];
    if (item?.motorPoolVehicle) {
      errors.push(
        `Vehicle is already in pool - ${item?.motorPoolVehicle?.motorPool?.poolName}`,
      );
    }
    if (!item?.tagNumber && !item?.exemptPlate) {
      errors.push('Vehicle does not have a license plate.');
    }
    if (!isActiveVehicle(item?.itemInventoryStatusCode)) {
      errors.push('Vehicle is not active.');
    }
    if (errors.length > 0) {
      setVehicleValidationErrors(errors);
    } else {
      getMotorPoolsList({
        variables: {
          //  we need to only see pools with agency code this vehicle belongs to
          filters: {
            operator: '$and',
            conditions: [
              {
                key: '$agency_code$',
                operator: '$exact',
                value:
                  item?.ownershipTypeCode === 'GF'
                    ? item.customer.customerAgencyCode
                    : item.agencyCode,
              },
            ],
          },
          order: 'poolName ASC'
        },
      });
    }
  };

  useEffect(() => {
    if (vehicleData) {
      validateVehicle(vehicleData?.getVehicle);
      setVehicle(vehicleData?.getVehicle);
    }
  }, [vehicleData]);

  const onClose = () => {
    closeModal();
  };

  if (!isOpen) {
    return null;
  }

  const addVehicleToPool = () => {
    if (!selectedPool.poolId) {
      setSubmissionError({
        selectedPool: 'Select pool is a required field',
      });
    } else {
      createMotorPoolVehicle({
        variables: {
          createPoolVehicle: {
            assetId: vehicle.uuid,
            motorPoolId: selectedPool.poolId,
            location: selectedPool.vehicleLocation,
            comment: selectedPool.comment,
          },
        },
      });
    }
  };

  const renderPoolSelectionForm = () => {
    if (motorPoolsList.length > 0) {
      return (
        <>
          <SelectDropdown
            label={
              <span className="text-bold">
                Select pool <RequiredFieldIndicator />
              </span>
            }
            onChange={(e) => {
              setSubmissionError({
                ...submissionError,
                selectedPool: '',
              });
              setSelectedPool({
                ...selectedPool,
                poolId: e.target.value,
              });
            }}
            name="select-pools"
            id="select-pools"
            data-testid="select-pools-dropdown"
            options={motorPoolsList}
            errorMessage={submissionError.selectedPool}
            aria-invalid={submissionError.selectedPool}
          />
          <TextInput
            type="text"
            label={<span className="text-bold">Vehicle location</span>}
            name="vehicle-location"
            id="vehicle-location"
            onChange={(e) => {
              setSelectedPool({
                ...selectedPool,
                vehicleLocation: e.target.value,
              });
            }}
            maxLength="100"
            characterLimit={100}
            errorMessage={submissionError.vehicleLocation}
            aria-invalid={submissionError.vehicleLocation}
          />
          <TextInput
            type="textarea"
            label={<span className="text-bold">Comment</span>}
            name="vehicle-location"
            id="vehicle-location"
            onChange={(e) => {
              setSelectedPool({
                ...selectedPool,
                comment: e.target.value,
              });
            }}
            maxLength="200"
            characterLimit={200}
            errorMessage={submissionError.comment}
            aria-invalid={submissionError.comment}
          />
        </>
      );
    }
    if (motorPoolsList.length === 0 && vehicleValidationErrors.length === 0) {
      return (
        <Alert type="warning">
          No motor pools found, click <Link to="/motor-pools">here</Link> to add
          one.
        </Alert>
      );
    }
    return null;
  };

  return (
    <div className="afp-modal-wrapper">
      <div className="afp-modal-overlay">
        <Modal
          variant="large"
          actions={
            <>
              <Button
                variant="unstyled"
                onClick={closeModal}
                label="Cancel"
                data-testid="cancel-add-to-pool-button"
              />
              {vehicleValidationErrors.length === 0 &&
                motorPoolsList.length > 0 && (
                  <Button
                    variant="primary"
                    label="Add vehicle"
                    disabled={creatingMotorPoolVehicle}
                    onClick={addVehicleToPool}
                    data-testid="submit-add-to-pool-button"
                  />
                )}
              {creatingMotorPoolVehicle && <Spinner size="small" />}
            </>
          }
          title={<h2>Add vehicle to pool</h2>}
          onClose={onClose}
        >
          {requestError && <Alert type="error">{requestError}</Alert>}
          {loadingVehicleData ? (
            <Spinner className="padding-y-9" />
          ) : (
            <div className="margin-top-3">
              {vehicleValidationErrors.length > 0 && (
                <Alert type="error" data-testid="validation-error-alert">
                  This vehicle cannot be added to a pool for the following
                  reason
                  {vehicleValidationErrors.length > 1 ? 's' : null}:
                  {vehicleValidationErrors.map((error) => (
                    <p>{error}</p>
                  ))}
                </Alert>
              )}
              <div className="bg-gray-3 padding-3 margin-top-3">
                <DetailsTable
                  bordered
                  data={[
                    [
                      'Vehicle',
                      <span className="float-right align-right">
                        <Link
                          to={`/vehicles/${encodeURIComponent(vehicle?.id)}`}
                        >
                          {vehicle?.modelYear <= 0
                            ? 'UNKNOWN'
                            : vehicle?.modelYear}{' '}
                          {vehicle?.vehicleMake?.makeName}{' '}
                          {vehicle?.vehicleModel?.modelDescription ||
                            vehicle?.modelCode}
                        </Link>
                      </span>,
                    ],
                    [
                      'VIN',
                      <span className="float-right align-right">
                        {vehicle?.id}
                      </span>,
                    ],
                    [
                      'License plate',
                      <span className="float-right align-right">
                        {vehicle?.exemptPlate
                          ? 'Exempt'
                          : vehicle?.tagNumber || emdash}
                      </span>,
                    ],
                    [
                      'Ownership',
                      <span className="float-right align-right">
                        {VEHICLE_OWNERSHIP[vehicle?.ownershipTypeCode]}
                      </span>,
                    ],
                  ]}
                />
              </div>
              {motorPoolsListLoading ? (
                <Spinner className="padding-y-9" />
              ) : (
                renderPoolSelectionForm()
              )}
            </div>
          )}
        </Modal>
      </div>
    </div>
  );
};

export default AddToMotorPoolModal;

AddToMotorPoolModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  vin: PropTypes.string.isRequired,
  showAlert: PropTypes.func.isRequired,
};
