import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FilterPanel } from '@gsa/afp-component-library';
import { useMileageFilter } from '../providers';

const { FilterPanelField, FilterPanelFilterShape, useFilterPanel } =
  FilterPanel;

const placeholderOption = {
  value: '',
  label: '-Select bureau-',
  defaultValue: true,
};

const BureauFilterItem = ({ filter }) => {
  const { getFilterFromState, mergedFilters, clearOneFilter } =
    useFilterPanel();

  const { getBureaus, bureaus } = useMileageFilter();

  const [options, setOptions] = useState([placeholderOption]);
  const [agencyCode, setAgencyCode] = useState('');
  const [retainedAgencyCode, setRetainedAgencyCode] = useState(true);

  useEffect(() => {
    const agencyFilter = getFilterFromState('$vehicle.agency_code$');
    setAgencyCode(agencyFilter.value || '');
    setRetainedAgencyCode(agencyFilter.retained);
  }, [mergedFilters]);

  useEffect(() => {
    // clear bureau when agency is changed
    if (!retainedAgencyCode) {
      clearOneFilter(filter);
    }

    // eslint-disable-next-line no-unused-expressions
    agencyCode ? getBureaus(agencyCode) : setOptions([placeholderOption]);
  }, [agencyCode, retainedAgencyCode]);

  useEffect(() => {
    setOptions([placeholderOption, ...bureaus]);
  }, [bureaus]);

  return (
    <>
      <FilterPanelField
        key={[filter.key]}
        filter={{
          ...filter,
          type: 'select',
          options,
        }}
      />
    </>
  );
};

BureauFilterItem.propTypes = {
  filter: PropTypes.shape(FilterPanelFilterShape).isRequired,
};

export default BureauFilterItem;
