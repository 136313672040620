import React, { useState } from 'react';
import { TextInput } from '@gsa/afp-component-library';
import BoacField from './boac-field';

export const formSectionAccountDetails = (mode, intenalGsaReadOnlyMode) => {
  return {
    sections: [
      {
        fields: [
          {
            id: 'boac',
            label: 'BOAC',
            tooltip: 'Provided by the customer agency for billing purposes',
            readOnly: mode === 'update',
            required: true,
            component: (config, useFormContext) => {
              const { setValue, clearErrors } = useFormContext();
              return (
                <BoacField
                  config={config}
                  setValue={setValue}
                  clearErrors={clearErrors}
                />
              );
            },
          },
        ],
      },
      {
        fields: [
          {
            readOnly: intenalGsaReadOnlyMode,
            id: 'accountName',
            required: true,
            label: 'Account name',
            component: (config, useFormContext) => {
              const {
                setValue,
                formState: { errors },
                clearErrors,
              } = useFormContext();

              const [val, setVal] = useState(config.defaultValue);

              return (
                <div>
                  <span className="usa-hint">
                    Enter the name of the account here. It will be displayed on
                    the Customer Account page as the Account name field.
                  </span>
                  <TextInput
                    name={config.id}
                    id={config.id}
                    type="text"
                    value={val}
                    onChange={(e) => {
                      if (e.target.value) clearErrors('accountName');
                      setValue(config.id, e.target.value);
                      setVal(e.target.value);
                    }}
                    disabled={config.readOnly}
                  />
                </div>
              );
            },
          },
        ],
      },
    ],
  };
};
