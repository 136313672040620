/* eslint-disable react/prop-types */
import React, { useEffect, useState, useReducer } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { Button, SelectDropdown, Typeahead } from '@gsa/afp-component-library';
import {
  GET_MAKES_BY_PARTIAL_NAME,
  GET_MODELS_BY_PARTIAL_NAME,
  GET_VEHICLE_EDIT_OPTIONS,
} from '../../../services/data-layer';
import usePortalModal from '../../../utilities/portal-modal';
import {
  SoldWarning,
  MissingWarning,
  StatusWarningNoPlate,
} from './vehicle-status-warnings';
import {
  itemInventoryStatuses,
  DEFAULT_SELECT_OPTION_LABEL,
  isTest,
} from '../../../utilities/consts';
import { unknownField } from '../../../utilities/unknown-field';

const FROM_YEAR = 1970;
const CURRENT_YEAR = new Date().getFullYear();
const VEHICLE_YEAR_OPTIONS = [
  {
    value: '',
    label: '- Select -',
  },
];

for (let index = CURRENT_YEAR + 2; index >= FROM_YEAR; index--) {
  VEHICLE_YEAR_OPTIONS.push({
    value: index,
    label: index,
  });
}

const fastReportable = (value) => {
  if (value === undefined || value === null) {
    return true;
  }
  return value;
};

const SOLD_STATUS_CODE = 'SD';
const MISSING_STATUS_CODE = 'MS';
const ACTIVE_STATUS_CODE = 'AC';

const getDisplayItemInventoryStatusCode = (statusId) => {
  if (!statusId) {
    return '';
  }
  // Some statuses in the constant are integers but will be strings in the payload
  const converted = parseInt(statusId, 10);
  const selected = converted
    ? itemInventoryStatuses[converted]
    : itemInventoryStatuses[statusId];
  if (!selected?.displayStatus) {
    return '';
  }
  if (selected.displayStatus.toLowerCase() === 'active') {
    return ACTIVE_STATUS_CODE;
  }
  if (selected.displayStatus.toLowerCase() === 'missing/stolen') {
    return MISSING_STATUS_CODE;
  }
  if (selected.displayStatus.toLowerCase() === 'sold') {
    return SOLD_STATUS_CODE;
  }
  return '';
};

const formReducer = (state, newState) => {
  return {
    ...state,
    ...newState,
  };
};

const getWarningComponent = (status, tagNumber) => {
  if (status === SOLD_STATUS_CODE && tagNumber) {
    return <SoldWarning licensePlate={tagNumber} />;
  }
  if (status === MISSING_STATUS_CODE && tagNumber) {
    return <MissingWarning licensePlate={tagNumber} />;
  }
  if (status === MISSING_STATUS_CODE || status === SOLD_STATUS_CODE) {
    const statusName =
      status === MISSING_STATUS_CODE ? 'missing/stolen' : 'sold';
    return <StatusWarningNoPlate statusName={statusName} />;
  }
  return null;
};

const statusLookup = (props) => {
  return (
    <SelectDropdown
      label="Vehicle status"
      name="itemInventoryStatusCode"
      id="itemInventoryStatusCode"
      options={[
        { value: '', label: DEFAULT_SELECT_OPTION_LABEL },
        ...(props.statusOpts || []),
      ]}
      value={props.value}
      onChange={props.handleChange}
      errorMessage={props.errorMessage}
      required
    />
  );
};

const fieldToLabel = {
  makeName: 'Make',
  modelName: 'Model',
  year: 'Year',
  color: 'Color',
  fuel: 'Fuel Type',
  fastReportable: 'Fast Reportable',
  itemInventoryStatusCode: 'Vehicle status',
};

const requiredFields = {
  makeName: (state) => state.makeName,
  modelName: (state) =>
    state.modelName ||
    (state.modelCode &&
      state.modelCode !== '-1' &&
      state.modelCode !== unknownField)
      ? state.modelCode
      : '',
  year: (state) => state.year,
  color: (state) => state.colorCode,
  fuel: (state) => state.fuel,
  fastReportable: (state) => state.fastReportable,
  itemInventoryStatusCode: (state) => state.itemInventoryStatusCode,
};

const requiredFieldsNhtsaVerified = {
  fastReportable: (state) => state.fastReportable,
};

const validateFields = (reqFields, state) => {
  if (reqFields && state) {
    return Object.keys(reqFields).reduce((validations, field) => {
      const selector = reqFields[field];
      const fieldValue = selector(state);
      // 0 is valid
      if (
        fieldValue === undefined ||
        fieldValue === null ||
        fieldValue === ''
      ) {
        validations[field] = `${fieldToLabel[field]} is required`;
      }
      return validations;
    }, {});
  }
};

const VehicleEdit = ({
  currentFuel,
  currentFast,
  currentYear,
  currentMakeCode,
  currentMakeName,
  currentModelCode,
  currentModelName,
  currentColorCode,
  currentColorName,
  onClose,
  onSave,
  vin,
  inventoryStatus,
  tag,
  nhtsaVerified,
}) => {
  const [VehicleModal, openModal, closeModal] = usePortalModal();
  const [state, dispatch] = useReducer(formReducer, {
    year: currentYear?.toString(),
    makeName: currentMakeName,
    makeCode: currentMakeCode,
    modelName: currentModelName,
    modelCode: currentModelCode,
    fuel: currentFuel,
    colorCode: currentColorCode,
    colorName: currentColorName,
    // Multiple status codes will have the same display (status 20 "Assigned Vehicle" and status AC "Active" will both appear "Active").  The original should not be overwritten if it's not changed, but if the dropdown is changed/saved, the status will be updated to AC, MS, or SD.
    displayItemInventoryStatusCode: getDisplayItemInventoryStatusCode(
      inventoryStatus?.id,
    ),
    itemInventoryStatusCode: inventoryStatus?.id || '',
    fastReportable: fastReportable(currentFast),
    allModels: [],
    fuelOpts: [],
    colorOpts: [],
    statusOpts: [
      {
        label: itemInventoryStatuses?.AC?.displayStatus,
        value: ACTIVE_STATUS_CODE,
      },
      {
        label: itemInventoryStatuses?.MS?.displayStatus,
        value: MISSING_STATUS_CODE,
      },
      {
        label: itemInventoryStatuses?.SD?.displayStatus,
        value: SOLD_STATUS_CODE,
      },
    ],
    tag,
  });
  const [errors, setErrors] = useState({});
  const [makeNames, setMakeNames] = useState([]);
  const [modelNames, setModelNames] = useState([]);
  const [selectedMake, setSelectedMake] = useState(null);
  const [modelPartial, setPartialModel] = useState('');
  const [isValueChanged, setIsValueChanged] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [getVehicleEditOptions, { data: editOptionsData }] = useLazyQuery(
    GET_VEHICLE_EDIT_OPTIONS,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const [getMakesByPartialName, { data: makesData }] = useLazyQuery(
    GET_MAKES_BY_PARTIAL_NAME,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const [getModelsByPartialModelName, { data: modelData }] = useLazyQuery(
    GET_MODELS_BY_PARTIAL_NAME,
    {
      fetchPolicy: 'no-cache',
    },
  );

  // If makeName is provided, fetch all the edit options (colors, fuelTypes and modelNames
  useEffect(() => {
    openModal();
    dispatch({
      displayItemInventoryStatusCode: getDisplayItemInventoryStatusCode(
        inventoryStatus?.id,
      ),
    });
    if (currentMakeName) {
      getVehicleEditOptions({ variables: { makeName: currentMakeName } });
    }
  }, []);

  // Updating state
  useEffect(() => {
    if (editOptionsData) {
      const allModels = editOptionsData.getVehicleMakeModels?.models.map(
        (model) => ({
          label: model.modelDescription,
          value: model.modelCode,
        }),
      );
      const makeCode = editOptionsData.getVehicleMakeModels?.make?.makeCode;

      const colorOpts = [
        ...(makeCode === currentMakeCode.toString() &&
        state.colorCode &&
        !editOptionsData?.getVehicleMakeModels.colors.find(
          (vColor) => vColor.makeColorCode === state.colorCode,
        )
          ? [
              {
                label: state.colorName,
                value: state.colorCode,
              },
            ]
          : []),
        ...editOptionsData?.getVehicleMakeModels.colors.map((option) => ({
          label: option.name,
          value: option.makeColorCode,
        })),
      ];

      const fuelOpts = editOptionsData?.getFuelNames.map((option) => ({
        label: option.description,
        value: option.id,
      }));

      let updatedState = { allModels, makeCode, colorOpts, fuelOpts };

      const isValidModel =
        !!allModels.find((model) => model.label === state.modelName) ||
        (!state.modelName && state.modelCode);
      // If previous model is not valid based on the new make, clear the value in the state
      if (!isValidModel) {
        updatedState = { ...updatedState, modelName: '', modelCode: '' };
      }
      dispatch(updatedState);
    }
  }, [editOptionsData]);

  useEffect(() => {
    if (makesData) {
      setMakeNames(makesData?.getMakesByPartialName?.map((d) => d.makeName));
    }
  }, [makesData]);

  useEffect(() => {
    if (modelData) {
      setModelNames(
        modelData?.getModelsByPartialModelName?.map((d) => d.modelDescription),
      );
    }
  }, [modelData]);

  const handleMakeSelected = (value) => {
    setIsValueChanged(true);
    setModelNames([]);
    if (!state.modelName && state.modelCode) {
      dispatch({ modelCode: '' });
    }
    dispatch({ makeName: value });
    getVehicleEditOptions({
      variables: {
        makeName: value,
      },
    });
    const getSelectedMake = makesData?.getMakesByPartialName.find(
      (d) => d.makeName === value,
    );

    if (getSelectedMake) {
      const { makeCode, makeName } = getSelectedMake;
      setSelectedMake({ makeCode, makeName });
      dispatch({ makeCode, makeName });
    } else {
      setSelectedMake(null);
    }
  };

  useEffect(() => {
    if (modelPartial) {
      getModelsByPartialModelName({
        variables: {
          makeCode: Number.parseInt(
            selectedMake?.makeCode ?? currentMakeCode,
            10,
          ),
          modelDescription: modelPartial,
        },
      });
    }
  }, [modelPartial]);

  const resetOptions = () => {
    dispatch({
      color: '',
      fuel: '',
      model: '',
      allModels: [],
      fuelOpts: [],
      colorOpts: [],
    });
    setSelectedMake(null);
  };

  const handleMakeChange = (e) => {
    if (!e.target.value || !e.target.value.length) {
      resetOptions();
    }
  };

  const close = () => {
    onClose && onClose();
    closeModal();
  };

  const save = () => {
    if (nhtsaVerified) {
      const reqFieldsForNhtsaVerified = Object.keys(state).reduce(
        (fields, currKey) => {
          fields[currKey] = state[currKey];
          return fields;
        },
        {},
      );
      const validations = validateFields(
        requiredFieldsNhtsaVerified,
        reqFieldsForNhtsaVerified,
      );
      const noError = Object.keys(validations).length === 0;
      if (noError) {
        // eslint-disable-next-line no-unused-expressions
        onSave &&
          onSave([
            { field: 'fuelCode', value: state.fuel },
            {
              field: 'fastReportable',
              value: state.fastReportable?.toString(),
            },
            { field: 'makeColorName', value: state.colorCode },
            {
              field: 'itemInventoryStatusCode',
              value: state.itemInventoryStatusCode,
            },
          ]);
      } else {
        setErrors(validations);
      }
    } else {
      const validations = validateFields(requiredFields, state);
      const noError = Object.keys(validations).length === 0;
      if (noError) {
        setErrors({});
        // eslint-disable-next-line no-unused-expressions
        onSave &&
          onSave([
            { field: 'fuelCode', value: state.fuel },
            {
              field: 'fastReportable',
              value: state.fastReportable?.toString(),
            },
            { field: 'modelYear', value: state.year },
            { field: 'makeColorName', value: state.colorCode },
            {
              field: 'itemInventoryStatusCode',
              value: state.itemInventoryStatusCode,
            },
            { field: 'makeCode', value: state.makeCode.toString() },
            { field: 'modelCode', value: state.modelCode?.toString() },
          ]);
      } else {
        setErrors(validations);
      }
    }
  };

  const setModel = (value) => {
    setIsValueChanged(true);
    const selectedModel = modelData?.getModelsByPartialModelName.find(
      (d) => d.modelDescription === value,
    );
    dispatch({ modelName: value, modelCode: selectedModel.modelCode });
  };

  const getOptionValue = (selectedOption, options) => {
    return options?.find((op) => op.value === selectedOption)?.value;
  };

  const [color, setColor] = useState('');

  useEffect(() => {
    if (state.colorCode && state.colorOpts) {
      setColor(getOptionValue(state.colorCode, state.colorOpts));
    } else {
      setColor('');
    }
  }, [state.colorCode, state.colorOpts]);

  const handleSetColor = (e) => {
    setIsValueChanged(true);
    dispatch({ colorCode: e.target.value });
  };

  const [fuelType, setFuelType] = useState('');

  useEffect(() => {
    if (state.fuel && state.fuelOpts) {
      setFuelType(getOptionValue(state.fuel, state.fuelOpts));
    } else {
      setFuelType('');
    }
  }, [state.fuel, state.fuelOpts]);

  const handleSetFuelType = (e) => {
    setIsValueChanged(true);
    dispatch({ fuel: e.target.value });
  };

  const updateFastReportable = (e) => {
    setIsValueChanged(true);
    dispatch({ fastReportable: e.target.value === 'true' });
  };

  const setStatus = (e) => {
    setIsValueChanged(true);
    // The itemInventoryStatusCode should be preserved and saved if it isn't changed regardless of how it displays, but it should be updated if a new value is selected from the dropdown
    dispatch({
      itemInventoryStatusCode: e.target.value,
      displayItemInventoryStatusCode: e.target.value,
    });
    if (
      e.target.value === SOLD_STATUS_CODE ||
      e.target.value === MISSING_STATUS_CODE
    ) {
      setShowWarning(true);
    } else {
      setShowWarning(false);
    }
  };

  const displayModel = (modelCode) => {
    return modelCode &&
      modelCode.toUpperCase() !== unknownField &&
      modelCode !== '-1'
      ? modelCode
      : '';
  };

  return (
    <VehicleModal
      actions={
        <>
          <Button variant="unstyled" onClick={close} label="Close" />
          <Button
            className="margin-left-2"
            disabled={!isValueChanged && !isTest}
            onClick={save}
            label="Save and close"
          />
        </>
      }
      title={<h2 className="text-uppercase">VIN {vin}</h2>}
      onClose={close}
      showAlert={showWarning}
      alert={getWarningComponent(
        state.itemInventoryStatusCode,
        state.tag?.tagNumber,
      )}
    >
      <div className="grid-row grid-gap grid-col-12">
        <div className="grid-col-4">
          {statusLookup({
            statusOpts: state.statusOpts,
            value: state.displayItemInventoryStatusCode,
            handleChange: setStatus,
            errorMessage: errors.itemInventoryStatusCode,
          })}
        </div>
      </div>

      <div className="grid-row grid-gap grid-col-12">
        <div className="grid-col-4">
          <SelectDropdown
            id="year"
            data-testid="year"
            label="Year"
            required
            name="year"
            options={VEHICLE_YEAR_OPTIONS}
            value={state.year}
            onChange={(e) => {
              setIsValueChanged(true);
              dispatch({ year: e.target.value });
            }}
            errorMessage={errors.year}
          />
        </div>
        <div className="grid-col-4">
          <Typeahead
            id="makeName"
            name="makeName"
            label="Make"
            labelClass="text-bold"
            filterValue={state.makeName}
            placeholder="Search Makes..."
            typeaheadValues={makeNames}
            fetchTypeaheadValues={(_accessor, value) => {
              getMakesByPartialName({ variables: { makeName: value } });
            }}
            onClear={() => {
              dispatch({
                makeName: '',
                modelName: '',
                makeCode: '',
                modelCode: '',
              });
            }}
            onOptionEnter={handleMakeSelected}
            onFilterChange={handleMakeChange}
            inputCharNum={1}
            required
            debounceDelay={500}
            errorMessage={errors.makeName}
          />
        </div>
        <div className="grid-col-4">
          <Typeahead
            id="model"
            data-testid="model-typeahead"
            name="model"
            label="Model"
            labelClass="text-bold"
            accessor="modelName"
            filterValue={state.modelName || displayModel(state.modelCode)}
            placeholder="Search Model..."
            typeaheadValues={modelNames}
            fetchTypeaheadValues={(_accessor, value) => {
              () => value;
            }}
            onOptionEnter={setModel}
            onFilterChange={(e) => {
              setPartialModel(e.target.value);
            }}
            inputCharNum={1}
            debounceDelay={500}
            required
            errorMessage={errors.modelName}
          />
        </div>
      </div>
      <div className="grid-row grid-gap grid-col-12">
        <div className="grid-col-4">
          <SelectDropdown
            label="Color"
            name="color"
            id="color"
            options={[
              { value: '', label: DEFAULT_SELECT_OPTION_LABEL },
              ...(state.colorOpts || []),
            ]}
            value={color}
            onChange={handleSetColor}
            errorMessage={errors.color}
            required
          />
        </div>
        <div className="grid-col-4">
          <SelectDropdown
            name="fuelType"
            required
            label="Fuel type"
            value={fuelType}
            onChange={handleSetFuelType}
            options={[
              { value: '', label: DEFAULT_SELECT_OPTION_LABEL },
              ...(state.fuelOpts || []),
            ]}
            errorMessage={errors.fuel}
          />
        </div>
        <div className="grid-col-4">
          <SelectDropdown
            id="fastReportable"
            required
            label="FAST reportable"
            name="fastReportable"
            value={state.fastReportable}
            onChange={updateFastReportable}
            options={[
              {
                value: true,
                label: 'Yes',
              },
              {
                value: false,
                label: 'No',
              },
            ]}
          />
        </div>
      </div>
    </VehicleModal>
  );
};

VehicleEdit.defaultProps = {
  vin: '',
  currentFuel: '',
  currentFast: false,
  currentYear: null,
  currentMakeCode: null,
  currentMakeName: '',
  currentModelCode: '',
  currentModelName: '',
  currentColor: '',
  onClose: () => {
    // default fx prop
  },
  onSave: () => {
    // default fx prop
  },
};

VehicleEdit.propTypes = {
  vin: PropTypes.string,
  currentFuel: PropTypes.string,
  currentFast: PropTypes.bool,
  currentYear: PropTypes.number,
  currentMakeCode: PropTypes.number,
  currentMakeName: PropTypes.string,
  currentModelCode: PropTypes.string,
  currentModelName: PropTypes.string,
  // eslint-disable-next-line react/no-unused-prop-types
  currentColor: PropTypes.string,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

export default VehicleEdit;
