import React, { useEffect } from 'react';
import { Spinner, TextInput } from '@gsa/afp-component-library';
import { usePrevious } from '@gsa/afp-shared-ui-utils';
import { useRegistration } from '../reg-context-provider';
import { nullForForm } from '../../../utilities/common';
import { cleanVin } from '../helpers/use-verify-vin';
import VerifyButton from './verify-button';
import VerificationTag from './verification-tag';
import SelectVehicleType from './select-vehicle-type';
import LabelOrInput from './label-or-input';

// eslint-disable-next-line react/prop-types
const ValidatingSpinner = ({ loading }) => {
  if (loading) {
    return (
      <div className="display-inline">
        <div className="afp-vehicle-registration__input_validation">
          <Spinner size="small" />
          Validating VIN
        </div>
      </div>
    );
  }
  return null;
};

export default function VerifyVin() {
  const {
    formState,
    setFormState,
    verifyingVin,
    verifyVin,
    vinSource,
    registrationType,
    getFieldValidation,
    openUserVerifyModal,
    purchaseOrderNumber,
    isRequirePurchaseOrderNumber,
    resetFirstStepState,
  } = useRegistration();

  const prevFormState = usePrevious(formState);

  function nhtsaVerifiedFieldChanged() {
    return (
      prevFormState &&
      formState.itemType &&
      formState.nhtsaVerified &&
      ((prevFormState.itemType &&
        formState.itemType !== prevFormState.itemType) ||
        (prevFormState.modelYear &&
          formState.modelYear !== prevFormState.modelYear) ||
        (prevFormState.makeName &&
          formState.makeName !== prevFormState.makeName) ||
        (prevFormState.modelName &&
          formState.modelName !== prevFormState.modelName))
    );
  }

  useEffect(() => {
    if (nhtsaVerifiedFieldChanged() && !formState.userVerifiedVin) {
      openUserVerifyModal(true);
    }
  }, [formState]);

  const handleOnChange = (e) => {
    const inputVin = e.target.value.trim();
    const vin = cleanVin(inputVin);
    resetFirstStepState(vin);
  };

  const isReadOnly = () => {
    return registrationType === 'regular';
  };

  return (
    <div className="grid-row grid-gap">
      <div className="tablet:grid-col-6">
        <div className="display-flex flex-align-end">
          <LabelOrInput
            label="VIN"
            value={nullForForm(formState.vin)}
            readOnly={isReadOnly()}
          >
            <TextInput
              containerClassName="afp-vehicle-registration__vin_input"
              id="vin"
              data-testid="vin"
              value={nullForForm(formState.vin)}
              onChange={handleOnChange}
              name="vin"
              type="text"
              label="VIN"
              maxLength={17}
              disabled={verifyingVin}
              required
              errorMessage={getFieldValidation('vin')}
              inputClass="text-uppercase"
            />
          </LabelOrInput>
          <VerifyButton
            vin={formState.vin}
            nhtsaVerified={formState.nhtsaVerified}
            vinSource={vinSource}
            verifyVin={verifyVin}
            {...formState}
          />
          <VerificationTag
            nhtsaVerified={formState.nhtsaVerified}
            vinSource={vinSource}
            {...formState}
          />
        </div>
      </div>

      <div className="tablet:grid-col-6">
        {verifyingVin ? (
          <ValidatingSpinner loading={verifyingVin} />
        ) : (
          isRequirePurchaseOrderNumber && (
            <TextInput
              containerClassName="afp-vehicle-registration_case_number_input"
              id="order_number"
              data-testid="order_number"
              value={purchaseOrderNumber}
              onChange={handleOnChange}
              name="orderNumber"
              type="text"
              label="Order number"
              maxLength={17}
              disabled
              required={isRequirePurchaseOrderNumber}
              inputClass="text-uppercase"
              errorMessage={getFieldValidation('orderNumber')}
            />
          )
        )}
      </div>
      <div className="tablet:grid-col-6">
        <LabelOrInput
          label="Vehicle group"
          value={nullForForm(formState.itemType)}
        >
          <SelectVehicleType
            itemType={formState.itemType}
            vinSource={vinSource}
            setFormState={setFormState}
            errorMessage={getFieldValidation('itemType')}
          />
        </LabelOrInput>
      </div>
    </div>
  );
}
