/* eslint-disable react/prop-types */
/* eslint-disable filenames/match-exported */

import React from 'react';
import {
  RequiredFieldIndicator,
  RadioButton,
} from '@gsa/afp-component-library';
import classnames from 'classnames';

import { useRecoilState, useSetRecoilState } from 'recoil';

const RadioButtonFields = ({
  pageId = 'DEFAULT',
  selectors,
  id,
  dependency = null,
  radios = [],
  label = '',
  required = false,
}) => {
  const { fieldSelector, fieldAfterSelector = null } = selectors;
  const [fieldValueState, setFieldValueState] = useRecoilState(
    fieldSelector({ pageId, id }),
  );
  let afterFieldUpdated = ({ value }) => value;
  if (fieldAfterSelector) {
    afterFieldUpdated = useSetRecoilState(fieldAfterSelector({ pageId, id }));
  }
  return (
    <div
      className={classnames(
        'usa-form-group mobile-lg:grid-col-10',
        fieldValueState.errorMessage && 'margin-left-205',
      )}
    >
      <span>
        {<b>{label}</b>} {required && <RequiredFieldIndicator />}
      </span>

      {radios.map(
        ({
          radioValue,
          label: radioButtonLabel,
          variant = undefined,
          ...restProps
        }) => {
          return (
            <RadioButton
              key={radioValue}
              checked={radioValue === fieldValueState.value}
              value={fieldValueState.value}
              label={variant ? <b>{radioButtonLabel}</b> : radioButtonLabel}
              onChange={() => {
                setFieldValueState({ value: radioValue, dependency });
              }}
              onBlur={(e) => {
                afterFieldUpdated({ value: e.target.value, dependency });
              }}
              variant={variant}
              {...restProps}
            />
          );
        },
      )}
    </div>
  );
};

export default RadioButtonFields;
