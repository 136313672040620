import React from 'react';
import { Button, ButtonDropdownItem } from '@gsa/afp-component-library';
import { useUploadContext } from './upload-context';

// eslint-disable-next-line react/prop-types
const UploadButton = ({ title, isButtonDropdownItem }) => {
  const { setUploadError, setIsUpload, setUploadMessage } = useUploadContext();
  return !isButtonDropdownItem ? (
    <Button
      data-testid="attachment-add-button"
      type="button"
      onClick={() => {
        // it contains redundant side-effect we may want to clean them later
        setUploadError({});
        setIsUpload(true);
        setUploadMessage();
      }}
      label={title}
    />
  ) : (
    <ButtonDropdownItem
      data-testid="attachment-add-button"
      type="button"
      iconName="file_upload"
      onClick={() => {
        setUploadError({});
        setIsUpload(true);
        setUploadMessage();
      }}
      label={title}
    />
  );
};

export default UploadButton;
