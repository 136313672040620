import React from 'react';

export default function ExemptAlert() {
  return (
    <>
      You are recording an exemption from the requirement to display official
      U.S. Government license plates for this vehicle under sections{' '}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.ecfr.gov/current/title-41/section-102-34.160"
      >
        § 102-34.160
      </a>
      ,{' '}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.ecfr.gov/current/title-41/section-102-34.175"
      >
        § 102-34.175
      </a>
        or{' '}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.ecfr.gov/current/title-41/section-102-34.180"
      >
         § 102-34.180
      </a>{' '}
      and vehicle must be registered and inspected in accordance with the laws
      of the jurisdiction where the motor vehicle is regularly operated.
    </>
  );
}
