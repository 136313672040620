import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { carTitleConstructor } from 'utilities/car-title';

export const VehicleTableCell = ({ value, showLink }) => {
  const { modelYear, vehicleMake, vehicleModel, modelCode, vin } = value;
  const title = carTitleConstructor(
    modelYear,
    vehicleMake,
    vehicleModel,
    modelCode,
  );
  return (
    <>
      <strong className="text-uppercase">{title}</strong>
      <br />
      {showLink ? (
        <Link
          className="text-uppercase"
          to={`/vehicles/${encodeURIComponent(vin)}`}
          aria-label={`show vehicle information for vin number ${vin} in a new window`}
        >
          {vin}
        </Link>
      ) : (
        <strong className="text-uppercase">{vin}</strong>
      )}
    </>
  );
};

VehicleTableCell.propTypes = {
  value: PropTypes.shape({
    modelYear: PropTypes.number,
    vehicleMake: PropTypes.shape({
      makeName: PropTypes.string,
    }),
    vehicleModel: PropTypes.shape({
      modelDescription: PropTypes.string,
    }),
    modelCode: PropTypes.string,
    vin: PropTypes.string,
  }),
  showLink: PropTypes.bool,
};

VehicleTableCell.defaultProps = {
  value: {},
  showLink: false,
};
