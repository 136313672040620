import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumbs, Alert } from '@gsa/afp-component-library';
import RepairOrderTitle from './order-title';
import { PageMode, useRepairOrder } from '../providers';

const trail = (vin) => [
  <a href={`${window.AFP_CONFIG.appURLs.home}/home`}>Home</a>,
  <Link to="/vehicles">Vehicle Inventory</Link>,
  <Link to={`/vehicles/${encodeURIComponent(vin)}/overview`}>
    {`VIN ${vin}`}
  </Link>,
  <Link to={`/vehicles/${encodeURIComponent(vin)}/repair`}>Repair Orders</Link>,
];

const Header = () => {
  const { repairOrder, vehicle, mode, error } = useRepairOrder();

  const isEditing = () => mode !== PageMode.View;
  const isCreating = () => mode === PageMode.New;

  if ((isEditing && !isCreating && !repairOrder) || (isCreating && !vehicle)) {
    return null;
  }

  const getCurrent = () => {
    const orderNumber = repairOrder?.repairOrderNumber;
    return orderNumber ? `Repair Order - ${orderNumber}` : 'New Repair Order';
  };

  return (
    <header>
      <Breadcrumbs trail={trail(vehicle?.vin)} current={getCurrent()} />
      {error?.heading && (
        <Alert type="error" heading={error.heading}>
          {error?.message || ''}
        </Alert>
      )}
      <RepairOrderTitle />
    </header>
  );
};

export default Header;
