import React from 'react';
import { useVehicle } from '../../vehicle-context-provider';
import SummaryCard from '../../widgets/summary-card';

const VehicleRecallSummary = () => {
  const {
    vehicle: { recallCounts },
  } = useVehicle();
  const getCount = (count) => (count >= 0 ? count : undefined);

  return (
    <div className="grid-row flex-row" data-testid="recall-summary">
      <SummaryCard
        title="Open recalls"
        count={getCount(recallCounts?.openCount)}
      />
      <SummaryCard
        title="Closed recalls"
        count={getCount(recallCounts?.closedCount)}
      />
    </div>
  );
};

export default VehicleRecallSummary;
