/* eslint-disable react/prop-types */
import React, { useState, useEffect, useReducer } from 'react';
import {
  Button,
  DatePicker,
  Alert,
  Label,
  Icon,
  Fieldset,
  RadioButton,
} from '@gsa/afp-component-library';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import usePortalModal from '../../../../../utilities/portal-modal';
import { compareDisposalAcquisitionAndTodayDates } from '../helpers/common';

const MAX_VAL = 3000000;

const formReducer = (state, newState) => {
  return {
    ...state,
    ...newState,
  };
};

const validateAcquisitionCost = (state) => {
  if (state.acquisitionCost === '') {
    return {};
  }

  if (parseFloat(state.acquisitionCost, 10) > MAX_VAL) {
    return {
      acquisitionCostError: 'Acquisition Cost should be between $0 and $3M',
    };
  }

  return {};
};

const PurchaseInfoEdit = ({ vehicle, onSave, onClose }) => {
  const [PurchaseInfoModal, openModal, closeModal] = usePortalModal();
  const [state, dispatch] = useReducer(formReducer, {
    acquisitionDate: vehicle?.assetAcquisition?.originalAcquisitionDate || '',
    disposalDate: vehicle?.assetDisposal?.actualDisposalDate || '',
    acquisitionCost: vehicle?.assetAcquisition?.acquisitionCost || '',
    deliveredMiles: vehicle?.assetAcquisition?.deliveredMiles || '',
    projectedReplacementDate:
      vehicle?.assetReplacement?.eligibleReplacementDate || '',
    acquisitionDateError: {},
    disposalDateError: {},
    acquisitionDateErrorMessage: {},
    disposalDateErrorMessage: {},
    projectedReplacementDateError: {},
  });

  const [selectedRadioInput, setSelectedRadioInput] = useState(
    vehicle?.assetReplacement?.isReplacementEligible || false,
  );
  const [errors, setErrors] = useState({});
  const [errorsCount, setErrorsCount] = useState(0);
  const [showStateError, setShowStateError] = useState(false);
  const [showAcquisitionCostError, setShowAcquisitionCostError] =
    useState(false);

  const save = () => {
    const acquisitionCostValidation = validateAcquisitionCost(state);

    const noError =
      Object.keys(acquisitionCostValidation).length +
        Object.keys(state.acquisitionDateError).length +
        Object.keys(state.acquisitionDateErrorMessage).length +
        Object.keys(state.projectedReplacementDateError).length ===
      0;

    if (noError) {
      setShowStateError(false);
      setErrors({});
      setErrorsCount(0);
      const purchaseInformationArgs = {
        assetReplacementInput: {
          assetId: vehicle?.uuid,
          isReplacementEligible: selectedRadioInput,
          eligibleReplacementDate: state.projectedReplacementDate
            ? moment(state.projectedReplacementDate)
                .format('YYYY-MM-DD')
                .concat('T00:00:00.000Z')
            : null,
        },
        assetAcquisitionInput: {
          acquisitionCost: parseInt(state.acquisitionCost, 10) || null,
          deliveredMiles: parseInt(state.deliveredMiles, 10) || null,
          originalAcquisitionDate: state.acquisitionDate
            ? moment(state.acquisitionDate)
                .format('YYYY-MM-DD')
                .concat('T00:00:00.000Z')
            : null,
        },
      };

      const updatedDisposalDate = state.disposalDate
        ? moment(state.disposalDate)
            .format('YYYY-MM-DD')
            .concat('T00:00:00.000Z')
        : null;

      onSave &&
        onSave(
          [
            {
              field: 'purchaseInformation',
              values: purchaseInformationArgs,
            },
          ],
          updatedDisposalDate,
        );
      closeModal();
    } else {
      setErrors(acquisitionCostValidation);
      setErrorsCount(
        Object.keys(acquisitionCostValidation).length +
          Object.keys(state.acquisitionDateError).length +
          Object.keys(state.acquisitionDateErrorMessage).length +
          Object.keys(state.projectedReplacementDateError).length,
      );
      setShowStateError(true);
      setShowAcquisitionCostError(true);
    }
  };

  const close = () => {
    // eslint-disable-next-line no-unused-expressions
    onClose && onClose();
    closeModal();
  };

  useEffect(() => {
    openModal();
  }, []);

  const getErrorComponent = () => {
    return (
      <Alert
        className="error-alert margin-bottom-2"
        onClose={false}
        slim
        type="error"
      >
        This form has{' '}
        <span className="text-bold">
          {errorsCount === 1
            ? `${errorsCount} error. `
            : `${errorsCount} errors. `}
        </span>
        Please correct all fields outlined in red before saving.
      </Alert>
    );
  };

  const ModalHeader = ({ vin }) => {
    return (
      <div>
        {errorsCount > 0 && getErrorComponent()}
        <h2>Edit purchase information</h2>
        <p className="font-body-xs">
          Edit purchase information for VIN{' '}
          <span className="text-bold">{vin}</span> in the form below.
        </p>
      </div>
    );
  };

  const handleAcquisitionDate = (e) => {
    setShowStateError(false);
    dispatch({ acquisitionDateErrorMessage: {} });
    if (e === '') {
      dispatch({ acquisitionDate: e });
    } else if (moment(e).isValid() && moment(e, 'MM/DD/YYYY', true).isValid()) {
      const disposalAcquisitionDateCompare =
        compareDisposalAcquisitionAndTodayDates(
          state.disposalDate,
          e,
          'acquisitionDate',
        );
      if (disposalAcquisitionDateCompare)
        dispatch({
          acquisitionDateErrorMessage: {
            errorMessage: disposalAcquisitionDateCompare,
          },
        });
      dispatch({ acquisitionDate: e });
    } else {
      dispatch({ acquisitionDateError: { errorMessage: 'Invalid date' } });
    }
  };

  const handleProjectedReplacement = (e) => {
    if (
      (moment(e).isValid() && moment(e, 'MM/DD/YYYY', true).isValid()) ||
      e === ''
    ) {
      dispatch({
        projectedReplacementDateError: {},
      });
      dispatch({ projectedReplacementDate: e });
    } else {
      dispatch({
        projectedReplacementDateError: { errorMessage: 'Invalid date' },
      });
    }
  };

  return (
    <PurchaseInfoModal
      actions={
        <>
          <Button variant="unstyled" label="Cancel" onClick={close} />
          <Button
            className="margin-left-2"
            onClick={save}
            label="Save and close"
          />
        </>
      }
      title={<ModalHeader vin={vehicle?.id} />}
      onClose={close}
    >
      <div className="grid-row grid-gap grid-col-12">
        <div className="grid-col-6">
          <div className="usa-form-group">
            <Label htmlFor="acquisition-cost">
              <span className="text-bold">Acquisition cost</span>
            </Label>
            {showAcquisitionCostError && (
              <span className="usa-error-message">
                {errors.acquisitionCostError}
              </span>
            )}
            <div className="dollar-input">
              <Icon iconName="attach_money" className="prefix-icon-dollar" />
              <NumberFormat
                allowNegative={false}
                value={state.acquisitionCost}
                className="usa-input"
                thousandSeparator
                onValueChange={(values) => {
                  const { value } = values;
                  if (value > MAX_VAL) {
                    setShowAcquisitionCostError(true);
                  }
                  dispatch({ acquisitionCost: value });
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="grid-row grid-gap grid-col-12">
        <div className="grid-col-6">
          <div className="usa-form-group">
            <Label htmlFor="delivered-miles">
              <span className="text-bold">Delivered miles</span>
            </Label>
            <span className="prefix-icon-odometer">miles</span>
            <NumberFormat
              value={state.deliveredMiles}
              className="usa-input"
              thousandSeparator
              onValueChange={(values) => {
                const { value } = values;
                dispatch({ deliveredMiles: value });
              }}
            />
          </div>
        </div>
      </div>
      <div className="grid-row grid-gap grid-col-12">
        <div className="grid-col-6">
          <div>
            <Label htmlFor="acquisition-date-picker">Delivered date</Label>
            {showStateError && (
              <span className="usa-error-message">
                {state?.acquisitionDateErrorMessage.errorMessage}
              </span>
            )}
            <div className="acquisition-date-picker">
              <DatePicker
                id="acquisition-date-picker"
                name="acquisition-date-picker"
                defaultValue={state.acquisitionDate}
                hint="mm/dd/yyyy"
                onChange={(val) => {
                  handleAcquisitionDate(val);
                }}
                format="MM/DD/YYYY"
                data-testid="acquisition-date-picker"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="grid-row grid-gap grid-col-12">
        <div className="grid-col-6">
          <div>
            <Label htmlFor="projected-date-picker">
              Projected replacement eligibility
            </Label>
            <div className="projected-date-picker">
              <DatePicker
                id="projected-date-picker"
                name="projected-date-picker"
                defaultValue={state.projectedReplacementDate}
                hint="mm/dd/yyyy"
                onChange={(val) => {
                  handleProjectedReplacement(val);
                }}
                format="MM/DD/YYYY"
                data-testid="projected-date-picker"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="grid-row grid-gap grid-col-12">
        <div className="grid-col-8 margin-top-2">
          <Fieldset legend="Replacement ordered">
            <RadioButton
              name="executive-fleet-option-yes"
              id="executive-fleet-option-yes"
              value
              checked={selectedRadioInput === true}
              label={
                <>
                  <span className="text-normal">Yes</span>
                </>
              }
              onChange={() => setSelectedRadioInput(true)}
            />
            <RadioButton
              name="executive-fleet-option-no"
              id="executive-fleet-option-no"
              value={false}
              checked={selectedRadioInput === false}
              label={
                <>
                  <span className="text-normal">No</span>
                </>
              }
              onChange={() => setSelectedRadioInput(false)}
            />
          </Fieldset>
        </div>
      </div>
    </PurchaseInfoModal>
  );
};

export default PurchaseInfoEdit;
