import React, { useEffect } from 'react';
import { FilterPanel } from '@gsa/afp-component-library';
import { useSchedulesFilter } from './filters/filter-provider';
import { generateSchedulesFilterStructure } from './filters/schedules-filter-structure';

const SchedulesSidebar = () => {
  const { filterStructure, setFilters, setStructure } = useSchedulesFilter();

  const setScheduleFilters = (filters) => {
    setFilters(filters);
  };

  useEffect(() => {
    setStructure(generateSchedulesFilterStructure());
  }, []);

  if (filterStructure?.length > 0) {
    return (
      <FilterPanel.FilterPanel
        filterStructure={filterStructure}
        setQueryFiltersState={(filters) => setScheduleFilters(filters)}
        clearButtonLabel="Reset all"
        showClearIcon
        order={[['updateAt', 'DESC']]}
        showSearchIcon
      />
    );
  }
  return null;
};

export default SchedulesSidebar;
