export default (phoneNumberString) => {
  const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `${match[1]}-${match[2]}-${match[3]}`;
  }
  const getSegment = (from, to) => {
    if (cleaned.slice(from, to)) {
      let string = '';
      if (from) {
        string += '-';
      }
      string += cleaned.slice(from, to);
      return string;
    }
    return '';
  };

  return `${getSegment(0, 3)}${getSegment(3, 6)}${getSegment(6)}`;
};
