import React, { useMemo, useState } from 'react';
import { SectionBlock } from '../../vehicle-overview/components';
import { Button, DetailsTable } from '@gsa/afp-component-library';
import { PropTypes } from 'prop-types';
import { EditMiscellaneous } from './index';

const Miscellaneous = ({ data, canEdit, onSave }) => {
  const [editMiscellaneous, setEditMiscellaneous] = useState(false);

  const tableData = useMemo(() => {
    const rows = [
      ['Ignition key', data?.assetAcquisition?.keyCodeIgnition],
      ['Other key', data?.assetAcquisition?.keyCodeOther],
    ];
    return rows;
  }, [data]);

  const saveMiscellaneous = (editState) => {
    const fieldsToUpdate = Object.keys(editState).map((key) => ({
      field: key,
      value: editState[key],
    }));
    onSave(fieldsToUpdate);
    setEditMiscellaneous(false);
  };

  return (
    <>
      <SectionBlock title="miscellaneous">
        <DetailsTable
          className="vehicle-overview-details-table"
          data={tableData}
        />
        {canEdit && (
          <Button
            data-testid={`miscellaneous-edit`}
            onClick={() => setEditMiscellaneous(true)}
            variant="outline"
            className="bg-white margin-top-2"
            aria-label={`edit miscellaneous ${data?.id}`}
            label="Edit"
          />
        )}
      </SectionBlock>
      {editMiscellaneous && (
        <EditMiscellaneous
          vehicle={data}
          onClose={() => setEditMiscellaneous(false)}
          onSave={saveMiscellaneous}
        />
      )}
    </>
  );
};

export default Miscellaneous;

Miscellaneous.propTypes = {
  data: PropTypes.shape({
    assetAcquisition: PropTypes.shape({
      keyCodeIgnition: PropTypes.string,
      keyCodeOther: PropTypes.string,
    }),
  }),
  onSave: PropTypes.func,
};
