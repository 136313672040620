import moment from 'moment';
import { utils, writeFile } from 'xlsx';

const fieldNames = [
  'recallCode', // used for Manufacturer campaign ID on the UI
  'recallDescription',// used for Campaign on the UI
  'detailDescription',// used for Description on the UI
  'remedyDescription',
  'safetyRisk',
];
const fieldNamesManual = [];

export const createErrorFile =
  (processedResponse, currentDownloadStrategyState) => async () => {
    const fileName = `${currentDownloadStrategyState.errorFileName}_${moment()
      .utcOffset('-06:00')
      .format('MMDDYYYY_HHMMSS')}.xlsx`;
    const worksheet = utils.json_to_sheet(processedResponse);
    worksheet['!cols'] = currentDownloadStrategyState.errorSheetColumnWidths;
    const workbook = utils.book_new();
    utils.book_append_sheet(
      workbook,
      worksheet,
      currentDownloadStrategyState.errorSheetName,
    );
    utils.sheet_add_aoa(worksheet, [
      currentDownloadStrategyState.errorSheetHeaders,
    ]);
    writeFile(workbook, fileName);
  };

export const getDisabledField = (recallCampaignData, action, fieldName) => {
  if (action === 'edit') {
    if (recallCampaignData?.recallSource === 'Manual'){
      if (fieldNamesManual.includes(fieldName)) {
        return true;
      }
    }
    

    if (recallCampaignData?.recallSource !== 'Manual') {
      if (fieldNames.includes(fieldName)) {
        return true;
      }
      if (
        fieldName === 'shouldParkOutside' ||
        fieldName === 'shouldStopDrive'
      ) {
        return false;
      } else {
        return true;
      }
    }
  }
  return false;
};
