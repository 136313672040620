// eslint-disable-next-line filenames/match-exported
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import { licensePlateWithVehicleType } from '../filter-structures/license-plate';
import { canViewAOTags, canViewGFTags } from '../../../utilities/authorization';
import { isTest } from '../../../utilities/consts';

export const defaultNestedFilters = () => {
  const ability = useAppAbility();
  const aoTags = canViewAOTags(ability);
  const gfTags = canViewGFTags(ability);
  const filters = [
    licensePlateWithVehicleType.topLevelSearch,
    licensePlateWithVehicleType.agency,
    licensePlateWithVehicleType.licensePlateStatus,
    licensePlateWithVehicleType.licensePlateOrder,
  ];
  if (isTest || (aoTags && gfTags)) {
    filters.splice(3, 0, licensePlateWithVehicleType.licensePlateOwnership);
  }
  return filters;
};

export default defaultNestedFilters;
