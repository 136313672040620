import { useState } from 'react';
import { useMutation } from '@apollo/client';
import _ from 'lodash';
import { RECONCILE_TAGS } from '../../../services/data-layer';
import { generateReconciliationMessage } from '../../../utilities/common';

export default function LpReconciliationManager() {
  const [reconciliationPayload, setReconciliationPayload] = useState({});
  const [reconciliationAlert, setReconciliationAlert] = useState({});

  const [reconcileTag] = useMutation(RECONCILE_TAGS, {
    fetchPolicy: 'no-cache',
    onError: (error) => {
      setReconciliationAlert({
        message: _.get(error, 'message', 'Unknown Error.'),
        type: 'error',
      });
      setReconciliationPayload({
        error: {
          message: _.get(error, 'message', 'Unknown Error.'),
        },
      });
    },
    onCompleted: (responseData) => {
      const reconciledTags = _.get(
        responseData,
        'reconcileTags.reconciledTags',
        [],
      );
      const reconciledUnicorTags = _.get(
        responseData,
        'reconcileTags.reconciledUnicorTags',
      );
      const combinedReconciledTags =
        _.size(reconciledUnicorTags) > 0
          ? reconciledUnicorTags
          : reconciledTags;

      if (_.size(combinedReconciledTags) > 0) {
        setReconciliationPayload({
          ...reconciliationPayload,
          reconciledTags: combinedReconciledTags,
          loading: false,
        });
        setReconciliationAlert({
          message: generateReconciliationMessage(combinedReconciledTags),
          type: 'success',
        });
      }
    },
  });

  return {
    reconcileTag,
    reconciliationAlert,
    reconciliationPayload,
    setReconciliationPayload,
  };
}
