import React, { useEffect, useMemo, useRef, useState } from 'react';
import { SelectDropdown } from '@gsa/afp-component-library';
import { useLazyQuery } from '@apollo/client';
import { FormGenerator } from '@gsa/afp-shared-form-utils';
import { GET_ZONES_CENTERS } from 'services/data-layer/fed-hierarchy.gql';
import { reassignVehicleSchema } from './reassign-vehicle-schema';
import { GET_FSRS_BY_FMC } from '../../../../services/data-layer/users.gql';

export const ReassignVehicleForm = ({
  onSubmit,
  reportData,
  setReportData,
}) => {
  const reassignVehicleRef = useRef(null);

  const DEFAULT_OPTION = {
    value: '',
    label: '- Select -',
    defaultValue: true,
  };

  const defaultFormData = useMemo(
    () => ({
      fsrEmailByZone: '',
      zone: '',
      fmc: '',
    }),
    [],
  );

  const [zoneWithCenters, setZoneWithCenters] = useState(null);
  const [zones, setZones] = useState([DEFAULT_OPTION]);
  const [fmcs, setFmcs] = useState([DEFAULT_OPTION]);
  const [fsrEmailsByZoneOptions, setFSREmailsByZone] = useState([
    DEFAULT_OPTION,
  ]);

  const [getFsrsByFmc] = useLazyQuery(GET_FSRS_BY_FMC, {
    fetchPolicy: 'network-only',
    onCompleted: (responseData) => {
      if (responseData?.getFsrsByFmc) {
        const rows = responseData.getFsrsByFmc;
        const emails = [];
        if (rows) {
          emails.push(DEFAULT_OPTION);
          for (let i = 0; i < rows.length; i += 1) {
            emails.push({
              value: rows[i].email,
              label: rows[i].email,
            });
          }
          setFSREmailsByZone(emails);
        }
      }
    },
    onError: () => {},
  });

  const getFSREmailList = (zone, fmc) => {
    getFsrsByFmc({
      variables: {
        fmc,
      },
    });
  };

  const [getZones] = useLazyQuery(GET_ZONES_CENTERS, {
    fetchPolicy: 'network-only', // Used for first execution
    // nextFetchPolicy: 'cache-first', // Used for subsequent executions
    onCompleted: (responseData) => {
      if (responseData?.getZones) {
        const zoneOptions = responseData?.getZones.map((zone) => ({
          value: zone.id,
          label: zone.name,
        }));
        setZones([DEFAULT_OPTION, ...zoneOptions]);
        setZoneWithCenters(responseData?.getZones);
      }
    },
  });

  useEffect(() => {
    getZones();
  }, []);

  useEffect(() => {
    if (!reassignVehicleRef.current) return () => {};
    const subscription = reassignVehicleRef.current.watch((updatedData) => {
      setReportData({
        ...reportData,
        ...updatedData,
      });
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [reassignVehicleRef.current]);

  const formContent = {
    buttonControls: { submit: false, cancel: false },
    sections: [
      {
        sectionLayout: 'horizontal',
        sections: [
          {
            fields: [
              {
                id: 'zone',
                required: true,
                label: 'Zone',
                component: (config, useFormContext) => {
                  const { setValue, getValues } = useFormContext();

                  const handleZonesChange = (e) => {
                    e.preventDefault();
                    const { value } = e.target;
                    setValue('zone', value);
                    setValue('fmc', '');
                    setValue('fsrEmailByZone', '');
                    let fmcOptions = [];
                    if (value.length > 0) {
                      fmcOptions = zoneWithCenters[value - 1].centers.map(
                        (center) => ({
                          value: center.id,
                          label: center.name,
                        }),
                      );
                    }
                    setFmcs([DEFAULT_OPTION, ...fmcOptions]);
                  };
                  return (
                    <SelectDropdown
                      data-testid="zones"
                      name="zone"
                      value={getValues('zone')}
                      onChange={(e) => {
                        handleZonesChange(e);
                      }}
                      options={zones}
                    />
                  );
                },
              },
              {
                id: 'fmc',
                label: 'FMC',
                required: true,
                component: (config, useFormContext) => {
                  const { setValue, getValues } = useFormContext();
                  const handleFMCChange = (e) => {
                    e.preventDefault();
                    const { value } = e.target;
                    setValue('fmc', value);
                    setValue('fsrEmailByZone', '');
                    getFSREmailList(getValues('zone'), value);
                  };
                  return (
                    <SelectDropdown
                      data-testid="fmcs"
                      name="fmc"
                      value={getValues('fmc')}
                      options={fmcs}
                      onChange={(e) => {
                        handleFMCChange(e);
                      }}
                    />
                  );
                },
              },
              {
                id: 'fsrEmailByZone',
                label: 'FSR email',
                required: true,
                component: (config, useFormContext) => {
                  const { setValue, getValues } = useFormContext();
                  const handleFSREmailChange = (e) => {
                    e.preventDefault();
                    const { value } = e.target;
                    setValue('fsrEmailByZone', value);
                  };
                  return (
                    <SelectDropdown
                      data-testid="fsrEmailByZone"
                      name="fsrEmailByZone"
                      value={getValues('fsrEmailByZone')}
                      options={fsrEmailsByZoneOptions}
                      onChange={(e) => {
                        handleFSREmailChange(e);
                      }}
                    />
                  );
                },
              },
            ],
          },
        ],
      },
    ],
  };
  return (
    <FormGenerator
      ref={reassignVehicleRef}
      id="reassign-vehicle-form"
      data-testid="reassign-vehicle-form"
      schema={reassignVehicleSchema}
      defaultValues={defaultFormData}
      content={formContent}
      onSubmit={onSubmit}
      useFormProps={{
        mode: 'onBlur',
        reValidateMode: 'onBlur',
      }}
    />
  );
};
