/* eslint-disable filenames/match-exported */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable filenames/match-regex */
import React from 'react';
import { Button, RequiredFieldIndicator } from '@gsa/afp-component-library';
import { yupResolver } from '@hookform/resolvers/yup';
import formSchema from '../form';
import MotorPoolForm from '../../utils/motor-pool-form';

const ADD_DRIVER = {
  title: () => <h2>Add member</h2>,
  content: (providerContext) => {
    return (
      <>
        <p>
          Required fields are marked with an asterisk (
          <RequiredFieldIndicator />
          ).
        </p>
        <MotorPoolForm
          formSchema={formSchema}
          mode={providerContext.motorPoolMemberModalMode}
          providerContext={providerContext}
          formValidationMethods={{
            //   change the schema formSchema.ADD_DRIVER need some future work
            resolver: yupResolver(formSchema.ADD_DRIVER.formValidationSchema()),
            defaultValues: {},
            mode: 'onBlur',
            reValidateMode: 'onChange',
          }}
        />
      </>
    );
  },
  action: ({ resetMotorPoolMemberModal }) => (
    <>
      <>
        <Button
          variant="unstyled"
          className="margin-right-2"
          onClick={resetMotorPoolMemberModal}
          data-testid="motor-pool-members-cancel-button"
          label="Cancel"
        />
        <Button
          variant="primary"
          type="submit"
          //   change the schema formSchema.ADD_DRIVER
          form={formSchema.ADD_DRIVER.formId}
          data-testid="motor-pool-members-submit-button"
          label="Submit"
        />
      </>
    </>
  ),
};

export default ADD_DRIVER;
