import { useState } from 'react';
import { useMutation } from '@apollo/client';
import _ from 'lodash';
import { DISACCOCIATE_TAG } from '../../../services/data-layer';
import { generateDisassociationMessage } from '../../../utilities/common';

export default function LpDisassociationManager() {
  const [disassociationPayload, setDisassociationPayload] = useState({});
  const [disassociationAlert, setDisassociationAlert] = useState({});

  const [disassociateTag] = useMutation(DISACCOCIATE_TAG, {
    fetchPolicy: 'no-cache',
    onError: (error) => {
      setDisassociationAlert({
        message: _.get(error, 'message', 'Unknown Error.'),
        type: 'error',
      });
      setDisassociationPayload({
        error: _.get(error, 'message', 'Unknown Error.'),
      });
    },
    onCompleted: (responseData) => {
      if (responseData?.disassociateTag) {
        setDisassociationPayload({
          ...disassociationPayload,
          disassociatedTag: responseData?.disassociateTag,
          loading: false,
        });
        setDisassociationAlert({
          heading: `License plate reported ${
            _.get(disassociationPayload, 'type') === 'lostStolen'
              ? 'lost or stolen'
              : 'damaged'
          }`,
          message: generateDisassociationMessage(disassociationPayload),
          type: 'warning',
        });
      }
    },
  });

  return {
    disassociateTag,
    disassociationAlert,
    disassociationPayload,
    setDisassociationPayload,
  };
}
