// eslint-disable-next-line filenames/match-exported, import/prefer-default-export
// column A-AF
export const cddMapping = [
  ['Agency', '', 'readonly'],
  ['Bureau', '', 'readonly'],
  ['Office', '', 'readonly'],
  ['VIN', 'vin', 'string | number'],
  ['License Plate attached to Vehicle', '', 'readonly'],
  ['FAST Reportable', '', 'readonly'],
  ['ASF', 'agencySort', 'string'],
  ['Dom', 'garageDomestic', 'boolean'],
  ['Garage Address 1', 'garageAddress1', 'string'],
  ['Garage Address 2', 'garageAddress2', 'string'],
  ['Garage Address 3', 'garageAddress3', 'string'],
  ['Garage City', 'garageCity', 'string'],
  ['Garage State', 'garageStateCode', 'string'],
  ['ZIP', 'garagePostalCode', 'string | number'],
  ['MSA', 'isMsa', 'boolean'],
  ['State Tag', 'stateTag', 'string'], // AO
  ['State Tag Exp. Date (MM/YYYY)', 'stateTagExpirationDate', 'tagFormat'], // AO
  ['Loc_Wheld', 'isLocationWithheld', 'boolean'],
  ['LGHG_AFV', 'isGasAfv', 'boolean'],
  ['LE', 'lawEnforcement', 'string'],
  ['ER', 'isEmergencyResponse', 'boolean'],
  ['ARM', 'armoredDesignation', 'boolean'],
  ['EPAct', 'aldCoverageDesignation', 'string'],
  ['S701', 'ald2005701', 'string'],
  ['EISA141', 'ald141Designation', 'string'],
  ['Assign_Type', 'assignmentType', 'string'],
  ['Reporting Org', 'reportOrganization', 'string'],
  ['BEC', 'agencyBudgetElementCode', 'string'],
  ['FAST Vehicle Type', 'fastDescription', 'string'],
  ['Comments', 'aldComment', 'string'],
  ['Vehicle Telematics', 'cddTelematicsInstalled', 'string'],
  ['Vehicle Telematics Data Usage', 'telematicsDataUsage', 'string'],
];

// columns A-AE
export const cddMappingGF = [
  ['Agency', '', 'readonly'],
  ['Bureau', '', 'readonly'],
  ['Office', '', 'readonly'],
  ['VIN', 'vin', 'string | number'],
  ['License Plate attached to Vehicle', '', 'readonly'],
  ['FAST Reportable', '', 'readonly'],
  ['ASF', 'agencySort', 'string'],
  ['Dom', 'garageDomestic', 'boolean'],
  ['Garage Address 1', 'garageAddress1', 'string'],
  ['Garage Address 2', 'garageAddress2', 'string'],
  ['Garage Address 3', 'garageAddress3', 'string'],
  ['Garage City', 'garageCity', 'string'],
  ['Garage State', 'garageStateCode', 'string'],
  ['ZIP', 'garagePostalCode', 'string | number'],
  ['Home To Work', 'homeToWork', 'boolean'],
  ['Loc_Wheld', 'isLocationWithheld', 'boolean'],
  ['LGHG_AFV', 'isGasAfv', 'boolean'],
  ['LE', 'lawEnforcement', 'string'],
  ['ER', 'isEmergencyResponse', 'boolean'],
  ['EPAct', 'aldCoverageDesignation', 'string'],
  ['S701', 'ald2005701', 'string'],
  ['EISA141', 'ald141Designation', 'string'],
  ['Assign_Type', 'assignmentType', 'string'],
  ['Reporting Org', 'reportOrganization', 'string'],
  ['BEC', 'agencyBudgetElementCode', 'string'],
  ['Comments', 'aldComment', 'string'],
  ['Fund Code', 'fundCode', 'string'],
  ['Description 1', 'account1', 'string'],
  ['Description 2', 'account2', 'string'],
  ['Vehicle Telematics', '', 'readonly'],
  ['Vehicle Telematics Data Usage', '', 'readonly'],
];
