import React, { useEffect, useState, useReducer } from 'react';
import usePortalModal from 'utilities/portal-modal';
import { useLazyQuery } from '@apollo/client';
import {
  Label,
  TextInput,
  RadioButton,
  Button,
  SelectDropdown,
  RequiredFieldIndicator,
} from '@gsa/afp-component-library';
import { DEFAULT_SELECT_OPTION_LABEL } from 'utilities/consts';
import PropTypes from 'prop-types';
import './edit-engine.css';
import { GET_FUEL_TYPES } from '../../../../../services/data-layer';
import { getOptionsWithDefault } from '../../../../../utilities/form-generator-controls/helper';
import NumberFormat from 'react-number-format';

const EditEngine = ({ vehicle, onSave, onClose, lookups }) => {
  const isOwnershipAO = vehicle?.ownershipTypeCode === 'AO';
  const isOwnershipGF = vehicle?.ownershipTypeCode === 'GF';

  const PTO = [
    {
      value: 'Y',
      description: 'Yes',
    },
    {
      value: 'N',
      description: 'No',
    },
  ];

  const [getFuelTypes] = useLazyQuery(GET_FUEL_TYPES, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const fuelOpts = data?.getFuelNames.map((option) => ({
        label: option.description,
        value: option.id,
      }));
      setFuelOpts(fuelOpts);
    },
  });

  const formReducer = (state, newState) => {
    return {
      ...state,
      ...newState,
    };
  };
  const [fuelType, setFuelType] = useState('');
  const [fuelOpts, setFuelOpts] = useState([]);

  let data = {};

  if (isOwnershipAO) {
    data = {
      cylinders: vehicle?.assetTechnical?.assetTechnicalIce?.cylinders,
      transmission: vehicle?.assetTechnical?.assetTechnicalIce?.transmission,
    };
  } else {
    data = {
      fuelCode: vehicle?.fuel?.description,
      engineDisplacement:
        vehicle?.assetTechnical?.assetTechnicalIce?.engineDisplacement,
      cylinders: vehicle?.assetTechnical?.assetTechnicalIce?.cylinders,
      horsePower:
        vehicle?.assetTechnical?.assetTechnicalIce?.horsePower?.toString(),
      pto: vehicle?.assetTechnical?.pto,
      transmission: vehicle?.assetTechnical?.assetTechnicalIce?.transmission,
    };
  }
  const [state, dispatch] = useReducer(formReducer, data);
  const handleTextInputChange = (e, field) => {
    dispatch({
      [field]: e.target.value?.toString(),
    });
  };

  const getOptionValue = (selectedOption, options) => {
    return options?.find((op) => op.label === selectedOption)?.value;
  };

  const handleSetFuelType = (e, field) => {
    const fuelCode = e.target.value;
    const selectedOption = fuelOpts.find((fuel) => fuel.value === fuelCode);
    dispatch({
      [field]: selectedOption?.value,
    });
  };

  const getNumberFormat = ({
    handleChange,
    validate,
    value,
    label,
    id,
    maxValue,
    suffix,
  }) => {
    return (
      <>
        <Label>
          <span className="text-bold">{label}</span>
        </Label>
        <div className="miles-input">
          <span className="prefix-icon-miles">{suffix ? suffix : ''}</span>
          <NumberFormat
            className={`usa-input`}
            id={`${id}`}
            data-testid={id}
            displayType="input"
            type="text"
            allowNegative={false}
            onChange={handleChange}
            value={value}
            onBlur={(e) => {
              if (validate) {
                validate(e, id);
              }
            }}
            isAllowed={(values) => {
              const { floatValue, value } = values;
              return floatValue <= maxValue || value === '';
            }}
          />
        </div>
      </>
    );
  };

  const [EngineEditModal, openModal, closeModal] = usePortalModal();

  //   Cylinders Dropdown
  const cylindersLookup = (props) => {
    return (
      <SelectDropdown
        id="itemCylinders"
        data-testid="itemCylinders"
        label="Cylinders"
        name="itemCylinders"
        options={[
          { value: '', label: DEFAULT_SELECT_OPTION_LABEL },
          ...(props.options || []),
        ]}
        value={props.value}
        onChange={props.handleChange}
      />
    );
  };

  const setCylinders = (e) => {
    dispatch({
      cylinders: e.target.value,
    });
  };

  const setPTO = (e) => {
    dispatch({
      pto: e.target.value,
    });
  };

  const setTransmission = (e) => {
    dispatch({
      transmission: e.target.value,
    });
  };

  const ptoDropDown = (props) => {
    return (
      <SelectDropdown
        id="pto"
        data-testid="pto"
        label="PTO"
        name="pto"
        options={[
          { value: '', label: DEFAULT_SELECT_OPTION_LABEL },
          ...(props.options || []),
        ]}
        value={props.value}
        onChange={props.handleChange}
      />
    );
  };

  const pto = ({ handleChange, value }) => {
    return (
      <>
        {ptoDropDown({
          options: PTO?.map((opt) => ({
            label: opt.description,
            value: opt.value,
          })),
          value: value,
          handleChange: setPTO,
        })}
      </>
    );
  };

  //   Transmission Dropdown
  const transmissionLookup = (props) => {
    return (
      <>
        <Label htmlFor="engine-transmission-group">Transmission</Label>
        <div id="engine-transmission-group">
          {props.options.map((opt) => (
            <RadioButton
              key={opt.code}
              label={`${opt.code} - ${opt.description}`}
              checked={props.value === opt.code}
              value={opt.code}
              id={`engine-transmission-${opt.code}`}
              data-testid={`engine-transmission-${opt.code}`}
              onChange={props.handleChange}
            />
          ))}
        </div>
      </>
    );
  };

  useEffect(() => {
    getFuelTypes();
    openModal();
  }, []);

  useEffect(() => {
    if (state.fuelCode && fuelOpts) {
      setFuelType(getOptionValue(state.fuelCode, fuelOpts));
    } else {
      setFuelType('');
    }
  }, [state.fuelCode, fuelOpts]);

  const close = () => {
    // eslint-disable-next-line no-unused-expressions
    onClose && onClose();
    closeModal();
  };

  const fuelCode = ({ value }) => {
    return (
      <>
        <SelectDropdown
          name="fuelType"
          label="Fuel type"
          value={fuelType}
          onChange={(e) => handleSetFuelType(e, 'fuelCode')}
          options={getOptionsWithDefault(fuelOpts)}
          data-testid="engine-fuelCode"
        />
      </>
    );
  };

  const cylinders = ({ handleChange, value }) => {
    return (
      <>
        {cylindersLookup({
          options: lookups?.no_of_cylinders?.map((opt) => ({
            label: opt.description,
            value: opt.code,
          })),
          value: value,
          handleChange: setCylinders,
        })}
      </>
    );
  };

  const transmission = ({ handleChange, value }) => {
    return (
      <>
        {transmissionLookup({
          options: lookups.transmission,
          value: state.transmission,
          handleChange: setTransmission,
        })}
      </>
    );
  };

  const saveEngineDetails = (e) => {
    const value = getOptionValue(state.fuelCode, fuelOpts);
    if (value) {
      state.fuelCode = value;
    }
    closeModal();
    onSave(e);
  };

  return (
    <EngineEditModal
      actions={
        <>
          <Button
            variant="unstyled"
            label="Cancel"
            onClick={close}
            data-testid="close-engine"
          />
          <Button
            className="margin-left-2"
            onClick={() => saveEngineDetails(state)}
            label="Save and close"
            data-testid="save-and-close-engine"
          />
        </>
      }
      title={<h2>Edit engine information</h2>}
      onClose={close}
    >
      <div className="grid-col-12 grid-gap">
        <p>
          Edit engine information for VIN <strong>{vehicle?.id}</strong> in the
          form below.
        </p>
        <p>
          Required fields are marked with an asterisk (
          <RequiredFieldIndicator />
          ).
        </p>
      </div>

      <div>
        {isOwnershipAO && (
          <>
            <div className="grid-row grid-gap">
              <div className="grid-col-6">
                {cylinders({
                  value: state.cylinders,
                  handleChange: handleTextInputChange,
                })}
              </div>
              <div className="grid-col-6">
                {transmission({
                  value: state.transmission,
                  handleChange: handleTextInputChange,
                })}
              </div>
            </div>
          </>
        )}
        {isOwnershipGF && (
          <>
            <div className="grid-row grid-gap">
              <div className="grid-col-6">
                {fuelCode({
                  value: state.fuelCode,
                  handleChange: handleTextInputChange,
                })}
              </div>
              <div className="grid-col-6">
                {getNumberFormat({
                  handleChange: (e) => {
                    handleTextInputChange(e, 'engineDisplacement');
                  },
                  value: state.engineDisplacement,
                  label: 'Engine size',
                  id: 'engine-engineDisplacement',
                  maxValue: 999,
                  suffix: 'L',
                })}
              </div>
              <div className="grid-col-6">
                {cylinders({
                  value: state.cylinders,
                  handleChange: handleTextInputChange,
                })}
              </div>
              <div className="grid-col-6">
                {getNumberFormat({
                  handleChange: (e) => {
                    handleTextInputChange(e, 'horsePower');
                  },
                  value: state.horsePower,
                  label: 'Max horsepower',
                  id: 'engine-horsePower',
                  maxValue: 99999999,
                })}
              </div>
              <div className="grid-col-6">
                {pto({
                  value: state.pto,
                  handleChange: handleTextInputChange,
                })}
              </div>
              <div className="grid-col-6">
                {transmission({
                  value: state.transmission,
                  handleChange: handleTextInputChange,
                })}
              </div>
            </div>
          </>
        )}
      </div>
    </EngineEditModal>
  );
};

export default EditEngine;

EditEngine.propTypes = {
  vehicle: PropTypes.shape({
    id: PropTypes.string,
    assetTechnical: PropTypes.shape({
      assetTechnicalIce: PropTypes.shape({
        cylinders: PropTypes.string,
        transmission: PropTypes.string,
      }),
    }),
    tagNumber: PropTypes.string,
    fuel: PropTypes.shape({
      description: PropTypes.string,
    }),
    modelYear: PropTypes.number,
    fuelCode: PropTypes.string,
    fastReportable: PropTypes.bool,
    makeColor: PropTypes.shape({
      name: PropTypes.string,
    }),
    vehicleModel: PropTypes.shape({
      modelDescription: PropTypes.string,
    }),
    vehicleMake: PropTypes.shape({
      makeName: PropTypes.string,
    }),
    tag: PropTypes.shape({
      expirationDate: PropTypes.number,
    }),
    inventoryStatus: PropTypes.shape({
      id: PropTypes.string,
      description: PropTypes.string,
    }),
  }),
  onSave: PropTypes.func,
  onClose: PropTypes.func,
  lookups: PropTypes.shape({
    no_of_cylinders: PropTypes.array,
    transmission: PropTypes.array,
  }),
};
