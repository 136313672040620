import React from 'react';
import PropTypes from 'prop-types';
import { RequiredFieldIndicator } from '@gsa/afp-component-library';
import { PageMode } from '../providers';

const RequiredFieldsText = ({ mode, className }) => {
  if (mode === PageMode.View) {
    return null;
  }
  return (
    <div className={className}>
      Required fields are marked with an asterisk &nbsp;(
      <RequiredFieldIndicator />
      ).
    </div>
  );
};

RequiredFieldsText.propTypes = {
  mode: PropTypes.string.isRequired,
};

export default RequiredFieldsText;
