import { NetworkStatus, useMutation } from '@apollo/client';
import {
  AFPTable,
  Button,
  EmptyState,
  Pagination,
  Spinner,
} from '@gsa/afp-component-library';
import React, { useCallback, useEffect, useState } from 'react';
import {
  canCreateGFMileage,
  canUpdateGFVehicleFSR,
  canUpdateGFVehicleCustomer,
} from 'utilities/authorization';
import moment from 'moment/moment';
import { emdash } from 'components/common';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import MileageExpressStatusBadge from 'components/mileage-express/mileage-express-status-badge';
import { useVehicle } from '../../vehicle-context-provider';
import {
  ADD_MILEAGE_FOR_VEHICLE,
  DELETE_MILEAGE_FOR_VEHICLE,
  EDIT_MILEAGE_FOR_VEHICLE,
} from './mileage-history.gql';
import DeleteMileageModal from './modals/delete-mileage-modal';
import UpdateMileageModal from './modals/update-mileage-modal';
import { MileageHistoryRow } from './widgets/mileage-history-row';
import { formatDataForMileageHistoryTable } from './widgets/mileage-history-widgets';
import useDateRangeFilter from '../hooks/use-date-range-filter';
import { useMileageHistoryGF } from './mileage-history-gf-provider';
import ManageGFMileageModal from './modals/manage-gf-mileage-modal';
import DeleteGFMileageModal from './modals/delete-gf-mileage-modal';

const tableRef = React.createRef();

const TELEMATICS_JOB_RUN_DAY = 20;

const MileageHistoryTable = () => {
  const { vehicle, showSystemError, setSectionMsg, refetchVehicle } =
    useVehicle();

  const {
    openManageGFMileageModal,
    isManageGFMileageModalOpen,
    getMileageHistoryForVehicle,
    getMileageHistoryForGFVehicle,
    mileageHistory: data,
    mileageHistoryLoading: loading,
    mileageHistoryError: error,
    mileageHistoryNetworkStatus: networkStatus,
    mileageHistoryGFLoading,
    mileageHistoryGFError,
    mileageHistoryGFNetworkStatus,
    dispatchAction,
    openDeleteGFMileageModal,
    isDeleteGFMileageModalOpen,
    checkTelematicsDevice,
    isTelematicsActive,
  } = useMileageHistoryGF();

  const ability = useAppAbility();
  const canCreateGFMileageRecords = canCreateGFMileage(ability);
  const canUpdateGFVehicleFSRUser = canUpdateGFVehicleFSR(ability);
  const canUpdateGFVehicleCustomerUser = canUpdateGFVehicleCustomer(ability);

  const [addMileage, setAddMileage] = useState(false);
  const [editMileage, setEditMileage] = useState(false);
  const [deleteMileage, setDeleteMileage] = useState(false);
  const [pageCount, setPageCount] = useState(5);
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [tableData, setTableData] = useState(null);
  const [isReset, resetPage] = useState(false);
  const [dataCount, setDataCount] = useState(0);
  const [actionData, setActionData] = useState({});
  const [order, setOrder] = useState('`mileageDate` DESC');
  const [dateFilters, setDateFilters] = useState(undefined);

  const toggleMileageEdit = () => {
    setEditMileage(!editMileage);
  };
  const toggleAddMileage = () => {
    if (vehicle?.ownershipTypeCode === 'GF') {
      openManageGFMileageModal();
    } else {
      setAddMileage(!addMileage);
    }
  };
  const toggleDeleteMileage = () => {
    setDeleteMileage(!deleteMileage);
  };

  const canManageMileage = () => {
    if (
      vehicle?.ownershipTypeCode === 'AO' &&
      vehicle?.itemInventoryStatusCode === 'AC'
    ) {
      return true;
    }
    const isActiveLifecycle = vehicle?.assetLifecycle?.some((item) => {
      return item.lifeCycle.lifecycleIndicator === 'Active';
    });
    if (
      vehicle?.ownershipTypeCode === 'GF' &&
      canCreateGFMileageRecords &&
      isActiveLifecycle
    ) {
      return true;
    }
    return false;
  };

  const { getDateRangeComponent, fromDate, toDate, touched } =
    useDateRangeFilter({
      classes: 'grid-row grid-gap flex-1',
      dateFormat: 'YYYY-MM',
    });

  const getMileageHistoryData = () => {
    if (vehicle?.ownershipTypeCode === 'GF') {
      getMileageHistoryForGFVehicle({
        variables: {
          assetId: vehicle?.uuid,
          offset,
          limit: pageCount,
          order,
        },
      });
      checkTelematicsDevice({
        variables: {
          vin: vehicle?.id,
        },
      });
    } else {
      getMileageHistoryForVehicle({
        variables: {
          filters: dateFilters,
          assetId: vehicle?.uuid,
          offset,
          limit: pageCount,
          order,
        },
      });
    }
  };

  useEffect(() => {
    getMileageHistoryData();
  }, []);

  const [addMileageRecord, { loading: addMileageLoading }] = useMutation(
    ADD_MILEAGE_FOR_VEHICLE,
    {
      onError: (err) =>
        setSectionMsg({
          type: 'error',
          message: err?.message || 'Error adding mileage record',
        }),
      onCompleted: () => {
        setSectionMsg({
          type: 'success',
          message: <>Successfully added a new mileage record</>,
        });
      },
      fetchPolicy: 'network-only',
    },
  );

  const [editMileageRecord, { loading: editMileageLoading }] = useMutation(
    EDIT_MILEAGE_FOR_VEHICLE,
    {
      onError: (err) =>
        setSectionMsg({
          type: 'error',
          message: err?.message || 'Error updating mileage record',
        }),
      onCompleted: () => {
        setSectionMsg({
          type: 'success',
          message: <>Successfully updated mileage record</>,
        });
      },
      fetchPolicy: 'network-only',
    },
  );

  const [deleteMileageRecord, { loading: deleteMileageLoading }] = useMutation(
    DELETE_MILEAGE_FOR_VEHICLE,
    {
      onError: (err) =>
        setSectionMsg({
          type: 'error',
          message: err?.message || 'Error deleting mileage record',
        }),
      onCompleted: () => {
        setSectionMsg({
          type: 'success',
          message: <>Successfully deleted mileage record</>,
        });
      },
      fetchPolicy: 'network-only',
    },
  );

  const saveMileageChanges = async (
    assetId,
    mileageDate,
    daysUsed,
    odometer,
    type,
  ) => {
    let dataUpdated;
    const MILEAGE_INPUT_ARGS = {
      mileageInput: {
        assetId,
        daysInUse: daysUsed,
        mileageDate,
        odometer,
      },
    };

    const MILEAGE_UPDATE_ARGS = {
      mileageInput: {
        assetId,
        daysInUse: daysUsed,
        mileageDate,
        odometer,
        id: parseInt(actionData.id, 10),
      },
    };

    switch (type) {
      case 'add':
        dataUpdated = await addMileageRecord({
          variables: MILEAGE_INPUT_ARGS,
        });
        toggleAddMileage();
        break;
      case 'edit':
        dataUpdated = await editMileageRecord({
          variables: MILEAGE_UPDATE_ARGS,
        });
        toggleMileageEdit();
        break;
      case 'delete':
        dataUpdated = await deleteMileageRecord({
          variables: MILEAGE_UPDATE_ARGS,
        });
        toggleDeleteMileage();
        break;
      default:
        break;
    }

    if (dataUpdated) {
      getMileageHistoryData();
      refetchVehicle();
    }
  };

  const renderRowSubComponent = useCallback(({ row: { original } }) => {
    const { methodOfEntry, user, dateOfEntry, email, customer } = original;
    return (
      <MileageHistoryRow
        methodOfEntry={methodOfEntry}
        user={user}
        dateOfEntry={dateOfEntry}
        email={email}
        customer={customer}
      />
    );
  }, []);

  // Handler for Actions
  const actionClick = (action, queryData) => {
    // keeping the existing logic for AO vehicle and adding new logic for GF vehicle based on context provider
    if (action === 'editMileageRecord') {
      if (vehicle?.ownershipTypeCode === 'GF') {
        dispatchAction('SET_SELECTED_MILEAGE_HISTORY_RECORD', queryData);
        openManageGFMileageModal();
      } else {
        toggleMileageEdit();
        setActionData(queryData);
      }
    }
    if (action === 'deleteMileageRecord') {
      if (vehicle?.ownershipTypeCode === 'GF') {
        dispatchAction('SET_SELECTED_MILEAGE_HISTORY_RECORD', queryData);
        openDeleteGFMileageModal();
      } else {
        toggleDeleteMileage();
        setActionData(queryData);
      }
    }
  };

  useEffect(() => {
    if (touched && moment(fromDate).isValid() && moment(toDate).isValid()) {
      const filters = {
        operator: '$and',
        conditions: [
          { operator: '$exact', key: 'asset_id', value: vehicle.uuid },
          {
            operator: '$between',
            key: 'mileage_date',
            value: [fromDate, toDate],
          },
        ],
      };
      setDateFilters(filters);
    } else {
      setDateFilters(undefined);
    }
  }, [fromDate, toDate]);

  useEffect(() => {
    getMileageHistoryData();
  }, [pageCount, offset, order, dateFilters]);

  useEffect(() => {
    if (touched && moment(fromDate).isValid() && moment(toDate).isValid()) {
      const filters = {
        operator: '$and',
        conditions: [
          { operator: '$exact', key: 'asset_id', value: vehicle.uuid },
          {
            operator: '$between',
            key: 'mileage_date',
            value: [fromDate, toDate],
          },
        ],
      };
      setDateFilters(filters);
    } else {
      setDateFilters(undefined);
    }
  }, [fromDate, toDate]);

  useEffect(() => {
    if (data) {
      const newData = formatDataForMileageHistoryTable(
        data,
        actionClick,
        vehicle?.ownershipTypeCode,
      );
      setTableData(newData);
      setDataCount(
        data?.getMileageByVehicle?.count ||
          data?.getMileageByGFVehicle?.count ||
          0,
      );
    }
  }, [data]);

  const tableHeaderData = [
    ...(vehicle?.ownershipTypeCode === 'AO'
      ? [
          {
            Header: 'Month/year',
            accessor: 'monthYear',
            headerClassName: 'width-card-lg',
            sortable: false,
          },
        ]
      : []),
    {
      Header: 'Mileage date',
      accessor: 'mileageDate',
      headerClassName: 'width-card-lg',
      Cell: ({ value }) => { 
        return value ? moment.utc(value).format('MM/DD/YYYY') : emdash;
      }
    },
    {
      Header: 'Odometer',
      accessor: 'odometer',
      headerClassName: 'width-card-lg',
      sortable: vehicle?.ownershipTypeCode === 'AO',
      Cell: ({ value }) => {
        return value ? value?.toLocaleString() : emdash;
      },
    },
    {
      Header: 'Days used',
      accessor: 'daysInUse',
      headerClassName: 'width-mobile',
      sortable: vehicle?.ownershipTypeCode === 'AO',
    },
    {
      Header: 'Miles driven',
      accessor: 'mileageDriven',
      headerClassName: 'width-mobile',
      sortable: false,
      Cell: ({ value }) => {
        return value ? value?.toLocaleString() : emdash;
      },
    },
    ...(vehicle?.ownershipTypeCode === 'GF'
      ? [
          {
            Header: 'Mileage status',
            accessor: 'status',
            sortable: true,
            // eslint-disable-next-line react/prop-types
            Cell: ({ value }) => {
              return value ? (
                <MileageExpressStatusBadge status={value} />
              ) : (
                emdash
              );
            },
          },
        ]
      : []),
    ...(vehicle?.ownershipTypeCode === 'AO' ||
    (vehicle?.ownershipTypeCode === 'GF' &&
      (canUpdateGFVehicleFSRUser || canUpdateGFVehicleCustomerUser))
      ? [
          {
            Header: 'Actions',
            accessor: canManageMileage ? 'actions' : '',
            sortable: false,
          },
        ]
      : []),
  ];

  const handlePaginationChange = (pageNum, itemsPer) => {
    if (itemsPer !== pageCount) {
      setPageCount(itemsPer);
      resetPage(true);
      setOffset(0);
      setCurrentPage(pageNum);
    }
    const newOffset = (pageNum - 1) * itemsPer;

    if (newOffset !== offset) {
      setCurrentPage(pageNum);
      setOffset(newOffset);
    }
  };

  if (error || mileageHistoryGFError) showSystemError(true);

  return (
    <>
      {canManageMileage() && (
        <div className="grid-row grid-gap flex-justify">
          {vehicle?.itemInventoryStatusCode === 'AC' && (
            <div className="tablet:grid-col tablet:flex-2 tablet:flex-align-self-end">
              <p className="text-bold margin-bottom-neg-2">Transaction date</p>
              {getDateRangeComponent()}
            </div>
          )}
          <div className="tablet:grid-col flex-1 flex-align-self-end margin-top-3 tablet:margin-top-0">
            <div className="float-right">
              <Button
                label="Add new mileage record"
                leftIcon={{
                  name: 'add',
                }}
                // For Leased vehicles, disable the action if Telematics is active, and we haven't ran the job yet.
                disabled={
                  vehicle?.ownershipTypeCode === 'GF' &&
                  isTelematicsActive &&
                  new Date().getDate() < TELEMATICS_JOB_RUN_DAY
                }
                aria-disabled={
                  vehicle?.ownershipTypeCode === 'GF' &&
                  isTelematicsActive &&
                  new Date().getDate() < TELEMATICS_JOB_RUN_DAY
                }
                onClick={() => {
                  if (vehicle?.ownershipTypeCode === 'AO') {
                    toggleAddMileage();
                  } else if (vehicle?.ownershipTypeCode === 'GF') {
                    openManageGFMileageModal();
                  }
                }}
                data-testId="add-mileage-record-button"
              />
            </div>
          </div>
        </div>
      )}
      <>
        <AFPTable
          fullWidth
          ref={tableRef}
          testId="afp-responsive-table-test-id"
          columns={tableHeaderData}
          data={loading || mileageHistoryGFLoading ? [] : tableData || []}
          defaultSort={order}
          onSort={setOrder}
          expandable
          renderRowSubComponent={renderRowSubComponent}
        />
        {(loading || mileageHistoryGFLoading) && (
          <Spinner className="padding-y-9" />
        )}
        {(networkStatus === NetworkStatus.refetch ||
          mileageHistoryGFNetworkStatus === NetworkStatus.refetch) &&
          !loading &&
          !mileageHistoryGFLoading && <Spinner className="padding-y-9" />}
        {tableData?.length > 0 ? (
          <Pagination
            variant="advanced"
            currentPage={currentPage}
            itemsPerPage={pageCount}
            itemsCount={dataCount}
            onPageChange={handlePaginationChange}
            isReset={isReset}
            itemsPerPageOptions={[5, 10, 15, 20]}
          />
        ) : null}

        {(tableData === null || tableData.length === 0) &&
          !loading &&
          !mileageHistoryGFLoading && (
            <div className="bg-gray-3 padding-y-5">
              <div className="text-center padding-y-4">
                <EmptyState alt="Image not available" hasBackground />
              </div>

              <div
                className="text-center text-bold"
                data-testid="no-mileage-history"
              >
                No mileage records at this time
              </div>
              <div className="text-center" data-testid="no-mileage-history">
                Add a new mileage record to this vehicle or import multiple
                mileage records using{' '}
                <span className="usa-link">bulk import</span>
              </div>
            </div>
          )}
      </>
      {addMileage && (
        <UpdateMileageModal
          onSave={saveMileageChanges}
          onClose={toggleAddMileage}
          vehicle={vehicle}
          type="add"
        />
      )}
      {editMileage && (
        <UpdateMileageModal
          onSave={saveMileageChanges}
          onClose={toggleMileageEdit}
          vehicle={vehicle}
          actionData={actionData}
          type="edit"
        />
      )}
      {deleteMileage && (
        <DeleteMileageModal
          onClose={toggleDeleteMileage}
          onSave={saveMileageChanges}
          actionData={actionData}
          vehicle={vehicle}
          type="delete"
        />
      )}
      {isManageGFMileageModalOpen && <ManageGFMileageModal />}
      {isDeleteGFMileageModalOpen && <DeleteGFMileageModal />}
    </>
  );
};

export default MileageHistoryTable;
