import React from 'react';

export const generateVehicleActivityFilterStructure = (activityTypeOptions) => {
  const filterStructure = [
    {
      title: '',
      expanded: true,
      filters: [
        {
          columnWidth: '5',
          key: 'transactionHistoryCategoryTypeId',
          id: 'activity-type',
          label: 'Activity type',
          operator: '$exact',
          options: activityTypeOptions,
          permanent: false,
          title: 'Activity type',
          type: 'select',
          value: '',
        },
        {
          columnWidth: '6',
          hideClear: true,
          id: 'date-range-time',
          key: 'transactionDate',
          type: 'daterange',
          operator: '$between',
          options: {
            className: 'display-flex',
            startDate: {
              key: 'beginning',
              label: <strong>Start date</strong>,
            },
            endDate: {
              key: 'end',
              label: <strong>End date</strong>,
            },
            wrapperClass: 'margin-top-0',
          },
          value: {
            beginning: '',
            end: '',
          },
        },
      ],
    },
  ];

  return filterStructure;
};
