import React from 'react';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import { useVehicle } from '../../vehicle-context-provider';
import PmProvider from './pm-provider';
import PmCards from './pm-cards';
import PmHistory from './pm-history';
import ManagePmModal from './manage-pm-modal';
import EditPmScheduleModal from './edit-pm-schedule-modal';
import DeletePmModal from './delete-pm-modal';
import { isPmEnabled } from '../../helpers/feature-helper';
import PmInspectionSchduleDetails from './pm-inspection-schdule-detail';

const PreventativeMaintenance = () => {
  const { vehicle } = useVehicle();
  const { ownershipTypeCode } = vehicle;
  const ability = useAppAbility();
  if (!vehicle || !isPmEnabled(ability, ownershipTypeCode)) return null;

  return (
    <PmProvider>
      <div className="grid-row" data-testid="pm-cards">
        <div className="grid-col-12">
          <PmCards />
        </div>
        <PmInspectionSchduleDetails />
      </div>
      <div className="grid-row" data-testid="pm-history">
        <div className="grid-col-12">
          <PmHistory />
        </div>
      </div>
      <ManagePmModal />
      <DeletePmModal />
      <EditPmScheduleModal />
    </PmProvider>
  );
};

export default PreventativeMaintenance;
