// takes 2 versions of filters
// and clears the modelCode, if the makeCode has changed
export default function resetModelOnMakeChange(newFilters, oldFilters) {
  if (!newFilters[0].conditions || !oldFilters[0].conditions) return newFilters;

  const newMakeCode = newFilters[0].conditions.find(
    ({ key }) => key === 'makeCode',
  )?.value;
  const oldMakeCode = oldFilters[0].conditions.find(
    ({ key }) => key === 'makeCode',
  )?.value;
  if (newMakeCode !== oldMakeCode) {
    const newConditions = newFilters[0].conditions.filter(
      ({ key }) => key !== 'modelCode',
    );
    return [{ ...newFilters[0], conditions: newConditions }];
  }
  return newFilters;
}
