import React, { useEffect, useRef, useState } from 'react';
import { Breadcrumbs, Alert, PageTitle } from '@gsa/afp-component-library';
import { Link, useHistory } from 'react-router-dom';
import DestructionSteps from './helpers/destruction-steps';
import StepDestructionLicensePlates from './step-destruction-license-plates';
import StepDestructionAgencyPoc from './step-destruction-agency-poc';
import StepDestructionReturnInformation from './step-destruction-return-information';
import StepDestructionEnd from './step-destruction-end';
import { useLicensePlateDestruction } from './context';
import { INIT_DESTRUCTION_DATA } from '../license-plate/helpers/use-license-plate-manager';

const LicensePlateDestruction = ({ setSelectedRows, returnRoute }) => {
  const history = useHistory();
  const { setForDestructionList, setDestructionInitData } =
    useLicensePlateDestruction();
  const [currentStep, setCurrentStep] = useState(1);
  const [showAlert, setShowAlert] = useState({});

  const breadcrumbRef = useRef(null);

  const lpDestructionTrail = [
    <a href={`${window.AFP_CONFIG.appURLs.home}/home`}>Home</a>,
    <Link ref={breadcrumbRef} to="/license-plates">
      License Plate Inventory
    </Link>,
  ];

  const onStepChange = (nextStep) => {
    setCurrentStep(nextStep);
    breadcrumbRef?.current?.focus();
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    breadcrumbRef?.current?.focus();
  }, []);

  return (
    <div data-testid="license-plate-destruction">
      <Breadcrumbs
        trail={lpDestructionTrail}
        current="License Plate Destruction Process"
      />
      {currentStep !== 4 ? (
        <>
          <div className="display-flex flex-align-center ">
            <PageTitle title="License Plate Destruction Process" />
          </div>
          <DestructionSteps currentStep={currentStep} />
        </>
      ) : null}

      {!_.isEmpty(showAlert) ? (
        <Alert
          type={showAlert.status}
          slim
          className="display-flex flex-justify flex-align-center "
          onClose={() => setShowAlert({})}
        >
          <div className="grid-row ">
            <span>
              {' '}
              {showAlert.message} {showAlert.key}.
            </span>
          </div>
        </Alert>
      ) : null}

      <div className="border-top border-width-2px border-base-lighter">
        {currentStep === 1 && (
          <StepDestructionLicensePlates
            setCurrentStep={onStepChange}
            setShowAlert={setShowAlert}
          />
        )}
        {currentStep === 2 && (
          <StepDestructionAgencyPoc
            setCurrentStep={onStepChange}
            setShowAlert={setShowAlert}
          />
        )}
        {currentStep === 3 && (
          <StepDestructionReturnInformation
            setShowAlert={setShowAlert}
            setCurrentStep={onStepChange}
            returnRoute={returnRoute}
          />
        )}
        {currentStep === 4 && <StepDestructionEnd />}
      </div>

      <button
        type="button"
        className="cancel-destruction usa-button margin-bottom-2 display-flex flex-align-center usa-button--unstyled"
        onClick={() => {
          setForDestructionList([]);
          setDestructionInitData(INIT_DESTRUCTION_DATA);
          setSelectedRows && setSelectedRows({});
          history.push(returnRoute);
        }}
      >
        {currentStep !== 4
          ? 'Cancel and clear all license plates'
          : 'Exit the license plate destruction process'}
      </button>
    </div>
  );
};

export default LicensePlateDestruction;
