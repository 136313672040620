import React, { useEffect, useState } from 'react';
import { SelectDropdown } from '@gsa/afp-component-library';
import { useLazyQuery } from '@apollo/client';
import { GET_COLORS_BY_MAKE } from '../../../services/data-layer';
import { DEFAULT_SELECT_OPTION_LABEL } from '../../../utilities/consts';

export default function MakeColor({
  makeCode,
  onChange = () => {},
  value = '',
  errorMessage = '',
}) {
  const [fetchMakeColor, { data }] = useLazyQuery(GET_COLORS_BY_MAKE, {
    fetchPolicy: 'no-cache',
    onError(err) {
      console.warn(err);
    },
  });
  const [colors, setColors] = useState([
    { value: '', label: DEFAULT_SELECT_OPTION_LABEL },
  ]);

  useEffect(() => {
    if (makeCode) {
      fetchMakeColor({
        variables: {
          makeCode,
        },
      });
    }
  }, [makeCode]);

  useEffect(() => {
    if (data) {
      setColors((oldCols) => {
        const fetchedColors = data?.getColorsByMakeCode.map((c) => ({
          value: c.makeColorCode,
          label: c.name,
        }));

        if (value) {
          const selectedOption = fetchedColors.find(
            (color) => color.value === value,
          );
          if (selectedOption) {
            onChange(selectedOption);
          } else onChange({ value: '', label: DEFAULT_SELECT_OPTION_LABEL });
        }

        return [...oldCols, ...fetchedColors];
      });
    }
  }, [data]);

  const handleColorChange = (e) => {
    const colorValue = e.target.value;
    const selectedOption = colors.find((color) => color.value === colorValue);

    onChange(selectedOption);
  };

  return (
    <SelectDropdown
      data-testid="select-color"
      label="Color"
      name="makeColorName"
      id="makeColorName"
      required
      options={colors}
      value={value}
      disabled={!makeCode}
      onChange={handleColorChange}
      errorMessage={errorMessage}
    />
  );
}
