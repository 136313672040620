import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import {
  SelectDropdown,
  TextInput,
  Icon,
  Label,
  DatePicker,
} from '@gsa/afp-component-library';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import PropTypes from 'prop-types';
import { emdash } from 'components/common';
import { CDD_FORM_FIELDS } from './consts';
import { generationOptions } from '../../helpers/utils';

const VehicleOwnershipForm = ({ isAOVehicle }) => {
  const {
    formState: { errors },
    control,
    aldCategories,
    getValues,
  } = useFormContext();

  return (
    <div data-testid="cdd-vehicle-designations-form">
      <div className="grid-col-6">
        {isAOVehicle ? (
          <>
            <Controller
              name={CDD_FORM_FIELDS.fastDescription.name}
              control={control}
              render={({ field, ref }) => {
                return (
                  <SelectDropdown
                    {...field}
                    ref={ref}
                    label={<span className="text-bold">FAST vehicle type</span>}
                    name={CDD_FORM_FIELDS.fastDescription.name}
                    id={CDD_FORM_FIELDS.fastDescription.id}
                    options={generationOptions(
                      'fast_vehicle_type',
                      aldCategories,
                    )}
                    errorMessage={
                      errors && errors[CDD_FORM_FIELDS.fastDescription.name]
                        ? errors[CDD_FORM_FIELDS.fastDescription.name].message
                        : null
                    }
                    aria-invalid={
                      errors && errors[CDD_FORM_FIELDS.fastDescription.name]
                        ? 'true'
                        : 'false'
                    }
                  />
                );
              }}
            />
            <Controller
              name={CDD_FORM_FIELDS.acquisitionCost.name}
              control={control}
              render={({ field, ref }) => {
                const isFieldError =
                  errors && errors[CDD_FORM_FIELDS.acquisitionCost.name];
                const fieldErrorMessage = isFieldError
                  ? errors[CDD_FORM_FIELDS.acquisitionCost.name].message
                  : null;
                return (
                  <div
                    className={`usa-form-group ${
                      isFieldError ? 'usa-form-group--error' : null
                    }`}
                  >
                    <Label
                      className={`${isFieldError ? 'usa-label--error' : null}`}
                      htmlFor="vehicle-acquisition-cost"
                    >
                      <span className="text-bold">
                        Vehicle acquisition cost
                      </span>
                    </Label>
                    {isFieldError && (
                      <span className="usa-error-message">
                        {fieldErrorMessage}
                      </span>
                    )}
                    <div className="dollar-input">
                      <Icon
                        iconName="attach_money"
                        className="prefix-icon-dollar"
                      />
                      <NumberFormat
                        {...field}
                        ref={ref}
                        className={`usa-input ${
                          isFieldError ? 'usa-input--error' : null
                        }`}
                        id={CDD_FORM_FIELDS.acquisitionCost.id}
                        thousandsGroupStyle="thousand"
                        fixedDecimalScale
                        decimalScale={2}
                        decimalSeparator="."
                        displayType="input"
                        type="text"
                        thousandSeparator
                        allowNegative={false}
                        aria-invalid={isFieldError ? 'true' : 'false'}
                      />
                    </div>
                  </div>
                );
              }}
            />
          </>
        ) : (
          <>
            <div className="text-bold margin-top-2">FAST vehicle type</div>
            <div className="margin-bottom-3">
              <span>
                {getValues(CDD_FORM_FIELDS.fastDescription.name) || emdash}
              </span>
            </div>
          </>
        )}
      </div>
      {isAOVehicle ? (
        <div className="grid-col-8">
          <Controller
            name={CDD_FORM_FIELDS.acquisitionDate.name}
            control={control}
            render={({ field, ref }) => {
              const isFieldError =
                errors && errors[CDD_FORM_FIELDS.acquisitionDate.name];
              const fieldErrorMessage = isFieldError
                ? errors[CDD_FORM_FIELDS.acquisitionDate.name].message
                : null;
              return (
                <div
                  className={`usa-form-group ${
                    isFieldError ? 'usa-form-group--error' : null
                  }`}
                >
                  {isFieldError && (
                    <span className="usa-error-message">
                      {fieldErrorMessage}
                    </span>
                  )}
                  <DatePicker
                    {...field}
                    ref={ref}
                    id={CDD_FORM_FIELDS.acquisitionDate.id}
                    name={CDD_FORM_FIELDS.acquisitionDate.name}
                    data-testid="acquisition-date"
                    maxDate={moment().format('YYYY-MM-DD')}
                    label={
                      <span className="text-bold">
                        Vehicle acquisition date
                      </span>
                    }
                    defaultValue={field.value}
                    hint="mm/dd/yyyy"
                    onChange={(val) => {
                      field.onChange(
                        val
                          ? moment(val)
                              .format('YYYY-MM-DD')
                              .concat('T00:00:00.000Z')
                          : null,
                      );
                    }}
                    aria-invalid={
                      errors && errors[CDD_FORM_FIELDS.acquisitionDate.name]
                        ? 'true'
                        : 'false'
                    }
                  />
                </div>
              );
            }}
          />
        </div>
      ) : null}
      <Controller
        name={CDD_FORM_FIELDS.assignmentType.name}
        control={control}
        render={({ field, ref }) => {
          return (
            <SelectDropdown
              {...field}
              ref={ref}
              label={<span className="text-bold">Vehicle assignment type</span>}
              name={CDD_FORM_FIELDS.assignmentType.name}
              id={CDD_FORM_FIELDS.assignmentType.id}
              options={generationOptions(
                'vehicle_assignment_type',
                aldCategories,
              )}
              errorMessage={
                errors && errors[CDD_FORM_FIELDS.assignmentType.name]
                  ? errors[CDD_FORM_FIELDS.assignmentType.name].message
                  : null
              }
              aria-invalid={
                errors && errors[CDD_FORM_FIELDS.assignmentType.name]
                  ? 'true'
                  : 'false'
              }
            />
          );
        }}
      />
      <Controller
        name={CDD_FORM_FIELDS.reportOrganization.name}
        control={control}
        render={({ field, ref }) => {
          return (
            <TextInput
              {...field}
              ref={ref}
              type="text"
              label={<span className="text-bold">Reporting Organization</span>}
              name={CDD_FORM_FIELDS.reportOrganization.name}
              id={CDD_FORM_FIELDS.reportOrganization.id}
              errorMessage={
                errors && errors[CDD_FORM_FIELDS.reportOrganization.name]
                  ? errors[CDD_FORM_FIELDS.reportOrganization.name].message
                  : null
              }
              maxLength={33}
              aria-invalid={
                errors && errors[CDD_FORM_FIELDS.reportOrganization.name]
                  ? 'true'
                  : 'false'
              }
            />
          );
        }}
      />
      <Controller
        name={CDD_FORM_FIELDS.agencyBudgetElementCode.name}
        control={control}
        render={({ field, ref }) => {
          return (
            <TextInput
              {...field}
              ref={ref}
              type="text"
              label={
                <span className="text-bold">Agency budget element code</span>
              }
              help="Code must begin with an alphabetical character"
              maxLength={33}
              name={CDD_FORM_FIELDS.agencyBudgetElementCode.name}
              id={CDD_FORM_FIELDS.agencyBudgetElementCode.id}
              errorMessage={
                errors && errors[CDD_FORM_FIELDS.agencyBudgetElementCode.name]
                  ? errors[CDD_FORM_FIELDS.agencyBudgetElementCode.name].message
                  : null
              }
              aria-invalid={
                errors && errors[CDD_FORM_FIELDS.agencyBudgetElementCode.name]
                  ? 'true'
                  : 'false'
              }
            />
          );
        }}
      />
      <Controller
        name={CDD_FORM_FIELDS.agencySort.name}
        control={control}
        render={({ field, ref }) => {
          return (
            <TextInput
              {...field}
              ref={ref}
              type="text"
              label={<span className="text-bold">Agency sort code</span>}
              maxLength="65"
              name={CDD_FORM_FIELDS.agencySort.name}
              id={CDD_FORM_FIELDS.agencySort.id}
              errorMessage={
                errors && errors[CDD_FORM_FIELDS.agencySort.name]
                  ? errors[CDD_FORM_FIELDS.agencySort.name].message
                  : null
              }
              aria-invalid={
                errors && errors[CDD_FORM_FIELDS.agencySort.name]
                  ? 'true'
                  : 'false'
              }
            />
          );
        }}
      />
      {isAOVehicle ? (
        <div className="grid-col-7">
          <Controller
            name={CDD_FORM_FIELDS.leaseType.name}
            control={control}
            render={({ field, ref }) => {
              return (
                <SelectDropdown
                  {...field}
                  ref={ref}
                  label={<span className="text-bold">Lease type</span>}
                  name={CDD_FORM_FIELDS.leaseType.name}
                  id={CDD_FORM_FIELDS.leaseType.id}
                  options={generationOptions('lease_type', aldCategories)}
                  errorMessage={
                    errors && errors[CDD_FORM_FIELDS.leaseType.name]
                      ? errors[CDD_FORM_FIELDS.leaseType.name].message
                      : null
                  }
                  aria-invalid={
                    errors && errors[CDD_FORM_FIELDS.leaseType.name]
                      ? 'true'
                      : 'false'
                  }
                />
              );
            }}
          />
        </div>
      ) : null}
    </div>
  );
};

VehicleOwnershipForm.propTypes = {
  isAOVehicle: PropTypes.bool.isRequired,
};

export default VehicleOwnershipForm;
