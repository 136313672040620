/* eslint-disable react/prop-types */
import React from 'react';
import { Checkbox } from '@gsa/afp-component-library';

export const headersToState = (headers) =>
  headers.map((header) => ({
    value: header,
    label: header,
  }));

// eslint-disable-next-line react/prop-types
export const CheckBoxComponents = ({
  checkBoxOptions,
  state,
  checkStateChange,
  setState,
  clearErrors,
  section,
}) => {
  return (
    <>
      {checkBoxOptions.map((opt, i) => {
        return (
          <div key={opt.label} className="grid-col-4">
            <Checkbox
              id={opt.value}
              name={opt.value}
              key={opt.label}
              label={opt.label}
              checked={state[section].checkedList.find((x) => x === opt.value)}
              onChange={(ev) => {
                clearErrors();

                const newCheckedList = checkStateChange({
                  optValue: opt.value,
                  checked: ev.target.checked,
                  section,
                });
                setState({
                  ...state,
                  [section]: {
                    isOpen: state[section].isOpen,
                    checkedList: newCheckedList,
                  },
                });
              }}
              aria-label="Custom Inventory field list"
            />
          </div>
        );
      })}
    </>
  );
};
export const CheckboxesComponent = ({
  section,
  headersList,
  setState,
  state,
  checkStateChange,
  clearErrors,
}) => {
  return (
    <>
      <div className="grid-row">
        <Checkbox
          name={section}
          key={section}
          label="Select all"
          onChange={() => {
            // if check box checked
            if (state[section].isCheckedAll) {
              setState({
                ...state,
                [section]: {
                  ...state[section],
                  isCheckedAll: false,
                  checkedList: [],
                },
              });
            } else {
              // if check box unchecked
              // ---select all fields of that session
              setState({
                ...state,
                [section]: {
                  ...state[section],
                  isCheckedAll: true,
                  checkedList: headersList,
                },
              });
            }
          }}
          checked={state[section].isCheckedAll}
        />
      </div>
      <br />
      <div className="grid-row">
        <CheckBoxComponents
          checkBoxOptions={headersToState(headersList)}
          state={state}
          checkStateChange={checkStateChange}
          setState={setState}
          clearErrors={clearErrors}
          section={section}
        />
      </div>
    </>
  );
};
