/* eslint-disable react/prop-types */
/* eslint-disable filenames/match-exported */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable filenames/match-regex */
import React from 'react';
import { Button } from '@gsa/afp-component-library';

const RemoveCurrentAccount = {
  title: () => <h2>Remove this account from office?</h2>,
  content: ({ currentSelectedAccountRow }) => {
    return (
      <>
        <p>
          {' '}
          Are you sure you want to remove{' '}
          <span className="text-bold">
            {currentSelectedAccountRow.accountName}
          </span>{' '}
          from this Office?{' '}
        </p>
      </>
    );
  },
  action: ({
    resetCurrentModal,
    currentSelectedAccountRow,
    disassociateCustomerAccountFromOffice,
  }) => (
    <>
      <Button
        variant="unstyled"
        className="margin-right-2"
        onClick={resetCurrentModal}
        data-testid="remove-contact-cancel-button"
        label="Cancel"
      />
      <Button
        variant="secondary"
        data-testid="remove-contact-submit-button"
        label="Remove contact"
        onClick={() => {
          disassociateCustomerAccountFromOffice({
            variables: {
              customerId: currentSelectedAccountRow?.customerId,
            },
          });
        }}
      />
    </>
  ),

  onClose: ({ resetCurrentModal }) => {
    resetCurrentModal();
  },
};

export default RemoveCurrentAccount;
