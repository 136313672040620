import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import {
  Pagination,
  Icon,
  AFPTable,
  EmptyState,
  Spinner,
} from '@gsa/afp-component-library';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import tableHeaderStructure from '../table-widgets/table-header';
import TagStatus from '../table-widgets/tag-status-badge';
import LicensePlateAction from '../table-widgets/license-plate-action';
import LicenseDetails from '../table-widgets/license-details';
import usePaginationManager from '../helpers/pagination-manager';
import { useLicensePlate } from '../context';
import useSortManager from '../helpers/sort-manager';
import { useLicensePlateDestruction } from '../../license-plate-destruction/context';
import { formatTagExpDate } from '../../../utilities/common';
import { canViewAOVehicles } from '../../../utilities/authorization';

export const LicenseAorB = ({ value }) => {
  return (
    <div className="aorb display-inline-block height-3 width-3 text-center border-radius-sm bg-gray-5">
      {value}
    </div>
  );
};

const TABLE_ICON_COLLAPSED = (
  <Icon className="usa-icon--size-4" iconName="navigate_next" />
);
const TABLE_ICON_EXPANDED = (
  <Icon className="usa-icon--size-4" iconName="expand_more" />
);

export default function licensePlateTable() {
  const {
    licensePlates,
    setLicensePlates,
    limit,
    filters,
    setDisassociationPayload,
    setReconciliationPayload,
    setSelectedRows,
    selectedRows,
    loading,
  } = useLicensePlate();

  const {
    toggleDestructionEditing,
    setEligibleForDestruction,
    setForDestructionList,
  } = useLicensePlateDestruction();

  const [handlePaginationChange] = usePaginationManager();
  const [sort] = useSortManager();
  const [tableData, setTableData] = useState([]);
  const [filterApplied, setFilterApplied] = useState(false);
  const [preventRowHandle, setPreventRowHandle] = useState(false);

  const ability = useAppAbility();
  const canViewAO = canViewAOVehicles(ability);

  const formatAgency = (agencyCode, agencyName) => {
    if (!agencyCode) {
      return '—';
    }
    return `${agencyCode !== 0 ? agencyCode : ''} - ${agencyName || ''}`;
  };

  useEffect(() => {
    if (_.isEmpty(filters) || _.isEmpty(licensePlates)) {
      setLicensePlates(null);
      setFilterApplied(false);
      setTableData([]);
    }

    if (!_.isEmpty(filters) && licensePlates) {
      const data = licensePlates.rows.map((row) => {
        return {
          id: (
            <Link
              to={`/license-plate/${encodeURIComponent(
                row.id,
              )}?tagExpirationDate=${row.expirationDate}`}
            >
              {row.id}
            </Link>
          ),
          tagActivityTypeCode: (
            <TagStatus tagStatus={row?.tagActivityTypeCode} />
          ),
          aOrB: <LicenseAorB value={row.aOrB} />,
          expirationDate: formatTagExpDate(row.expirationDate),
          customerAgencyCode: formatAgency(
            _.get(row, 'customerAgencyCode'),
            _.get(row, 'agency.name'),
          ),
          action: (
            <LicensePlateAction
              tag={row}
              disassociateTag={disassociateTag}
              reconcileTag={reconcileTag}
              submitTagForDestruction={handleLicensePlateDestruction}
            />
          ),
          name: row.customerPointOfContactName,
          email: row.customerPointOfContactEmailAddress,
          phone: row.customerPointOfContactPhone,
          customerBureauCode: row.customerBureauCode,
          agencyAddress: {
            address1: row.customerPointOfContactAddress1,
            address2: row.customerPointOfContactAddress2,
            address3: row.customerPointOfContactAddress3,
            address4: row.customerPointOfContactAddress4,
            city: row.customerPointOfContactCity,
            state: row.customerPointOfContactState,
            zip: row.customerPointOfContactZip,
          },
          bureau: row.bureau,
          office: row.office,
          vehicle: row.vehicle,
          orderNumber: row.unicorTag?.orderNumber,
          orderStatus: row.unicorTag?.orderStatus,
          subRowStructure: [<LicenseDetails order={row} />],
        };
      });
      setFilterApplied(true);
      setPreventRowHandle(true);
      setTableData(data);
    }
  }, [filters, licensePlates]);

  const disassociateTag = (tag, type) => {
    setDisassociationPayload({
      type,
      vinToDisassociateFrom: _.get(tag, 'VIN'), // prevseve it for the alert message
      tagNumber: _.get(tag, 'id'),
      expirationDate: _.get(tag, 'expirationDate'),
    });
  };

  const reconcileTag = (tag) => {
    setReconciliationPayload({
      tagNumber: _.get(tag, 'id'),
      expirationDate: _.get(tag, 'expirationDate'),
    });
  };

  const handleOnRowSelect = ({ selectedRowIds }) => {
    if (!preventRowHandle && !_.isEqual(selectedRows, selectedRowIds)) {
      setSelectedRows(selectedRowIds);
    } else if (preventRowHandle) {
      setPreventRowHandle(false);
      setSelectedRows([]);
    }
  };

  const handleLicensePlateDestruction = (tag) => {
    if (tag) {
      setForDestructionList(tag);
    } else {
      setEligibleForDestruction(licensePlates, selectedRows);
    }

    toggleDestructionEditing();
  };

  const getFilteredColumns = (tableColumns) => {
    let columnsToReturn = [...tableColumns];
    if (!canViewAO) {
      columnsToReturn = columnsToReturn.filter(
        (col) => col.accessor !== 'customerAgencyCode',
      );
    }
    return columnsToReturn;
  };

  return (
    <div className="plate-content flex-10">
      <AFPTable
        columns={getFilteredColumns(tableHeaderStructure)}
        iconCollapsed={TABLE_ICON_COLLAPSED}
        iconExpanded={TABLE_ICON_EXPANDED}
        data={loading ? [] : tableData || []}
        testId="afp-responsive-table-test-id"
        fullWidth
        bordered={false}
        defaultSort="standardItemNumber asc"
        expandable
        selectable
        scrollable={false}
        stacked
        selectAllHeader=""
        hiddenColumns={['gender']}
        selectedRows={selectedRows}
        onRowSelect={handleOnRowSelect}
        renderRowSubComponent={LicenseDetails}
        onSort={(order) => sort(order.split(' '))}
      />
      {loading && <Spinner className="padding-y-9" />}
      {tableData && tableData.length != 0 && (
        <Pagination
          variant="advanced"
          itemsPerPage={limit}
          itemsCount={licensePlates?.count || 0}
          onPageChange={handlePaginationChange}
          isReset={filters}
        />
      )}
      {(tableData == null || tableData.length == 0) &&
        !loading &&
        !filterApplied && (
          <div className="bg-gray-3 padding-y-5">
            <div className="text-center padding-y-4">
              <EmptyState alt="Image not available" hasBackground />
            </div>
            <div className="text-center text-bold">
              No License Plates Showing
            </div>
            <div className="text-center margin-top-2">
              Add or remove filters to search license plates
            </div>
          </div>
        )}
      {(tableData == null || tableData.length == 0) &&
        !loading &&
        filterApplied && (
          <div className="bg-gray-3  padding-y-5">
            <div className="text-center padding-y-4">
              <EmptyState alt="Image not available" hasBackground />
            </div>
            <div className="text-center text-bold">No Results Found</div>
          </div>
        )}
    </div>
  );
}
