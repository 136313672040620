import PropTypes from 'prop-types';
import React from 'react';
import { AddressConfirmationModal } from '@gsa/afp-component-library';
import { useFcReplacement } from './fc-replacement-context-provider';

const FcAddressConfirmation = ({ onSave }) => {
  const {
    addressToValidate,
    validatedAddress,
    loadingValidateAddress,
    validateAddressError,
    clearAddress,
  } = useFcReplacement();

  return (
    <AddressConfirmationModal
      validating={loadingValidateAddress}
      hasValidationError={validateAddressError}
      addressToValidate={addressToValidate}
      validatedAddress={validatedAddress}
      onSave={onSave}
      onCancel={clearAddress}
    />
  );
};

FcAddressConfirmation.propTypes = {
  onSave: PropTypes.func.isRequired,
};

export default FcAddressConfirmation;
