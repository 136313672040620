import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import { startCase, toLower } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {
  canViewAOVehicles,
  canViewGFVehicles,
} from '../../../utilities/authorization';
import phoneNumberFormatter from '../../../utilities/phoneNumberFormatter';
import { constructAddress } from '../../orders/widgets/table-widgets/order-details';
import { ORDER_STATUS_LABELS } from '../../orders/widgets/table-widgets/order-status-badge';
import LicenseDetail from './detail-row';
import LicenseDetailEmail from './license-detail-row-email';
import LicenseDetailLink from './license-detail-row-link';

export default function LicenseDetails({ row: { original } }) {
  const ability = useAppAbility();
  const shouldSeeVehicleLink =
    canViewAOVehicles(ability) || canViewGFVehicles(ability);

  const officeCode = `${
    original.office?.officeCode ? original.office.officeCode : '- '
  }`;

  const officeName = `${
    original.office?.officeName ? original.office.officeName : '- '
  }`;

  return (
    <div className="display-flex flex-justify-center padding-x-8">
      <div className="vehicle-row-details margin-right-5 flex-1">
        <div className="display-flex flex-align-center">
          <h2 className="text-uppercase font-sans-xs margin-right-105">
            Plate Details
          </h2>
        </div>
        <div className="row-details x">
          <LicenseDetailLink
            href={`/order/${original.orderNumber}`}
            label="UNICOR order"
            value={original.orderNumber}
          />
          <LicenseDetail
            href={`/order/${original.orderNumber}`}
            label="Order Status"
            value={ORDER_STATUS_LABELS[original.orderStatus]}
          />
          <LicenseDetailLink
            href={`/vehicles/${encodeURIComponent(original.vehicle?.id)}`}
            label="Attached VIN"
            value={original.vehicle?.id}
            shouldSeeLink={shouldSeeVehicleLink}
            className="text-uppercase"
          />
          <LicenseDetailLink
            href={`/vehicles/${encodeURIComponent(original.vehicle?.id)}`}
            label="Attached Vehicle"
            value={
              original.vehicle
                ? `${original.vehicle?.modelYear} ${original.vehicle?.vehicleMake?.makeName} ${original.vehicle?.modelCode}`
                : ''
            }
            shouldSeeLink={shouldSeeVehicleLink}
          />
        </div>
      </div>
      <div className="vehicle-row-details flex-1">
        <div className="display-flex flex-align-center">
          <h2 className="text-uppercase font-sans-xs margin-right-105">
            Point of Contact
          </h2>
        </div>
        <div className="row-details">
          <LicenseDetail
            label="Name"
            value={`${startCase(toLower(original.name))}`}
            className="text-uppercase"
          />
          <LicenseDetailEmail label="Email" value={original.email} />
          <LicenseDetail
            label="Phone"
            value={phoneNumberFormatter(original.phone)}
          />
          <LicenseDetail
            label="Bureau"
            value={
              original.bureau
                ? `${original.customerBureauCode} - ${original.bureau?.name}`
                : ''
            }
          />
          <LicenseDetail
            label="Office"
            value={original.office ? `${officeCode} - ${officeName}` : ''}
          />
          <LicenseDetail
            label="Address"
            value={constructAddress(original.agencyAddress)}
            className="text-uppercase"
          />
        </div>
      </div>
    </div>
  );
}

LicenseDetails.defaultProps = {
  row: undefined,
};

LicenseDetails.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      orderNumber: PropTypes.string,
      orderStatus: PropTypes.string,
      vehicle: {
        id: PropTypes.string,
      },
    }),
  }),
};
