import React from 'react';
import Modal from 'utilities/modal-utils';
import { useCreateOrders } from './create-orders-provider';
import modalSchema from './schema/modal';

const CreateOrdersModal = () => {
  const context = useCreateOrders();

  return (
    <>
      <Modal schema={modalSchema} context={context} />
    </>
  );
};

export default CreateOrdersModal;
