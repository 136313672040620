import React from 'react';
import PropTypes from 'prop-types';
import DestructionTable from './widgets/destruction-table';
import NextButton from '../common/next-button';

// eslint-disable-next-line react/prop-types
function StepDestructionLicensePlates({ setCurrentStep, setShowAlert }) {
  return (
    <div data-testid="destruction-step1">
      <div className="grid-row grid-gap">
        <div className="grid-col-8">
          <p>
            The table below should include all license plates that need to be
            returned to UNICOR for destruction.
          </p>
        </div>
      </div>
      <DestructionTable />
      <div className="grid-row margin-bottom-6 margin-top-6">
        <NextButton
          data-testid="btn-next-destruction"
          onClick={() => {
            setShowAlert({});
            setCurrentStep(2);
          }}
        />
      </div>
    </div>
  );
}

StepDestructionLicensePlates.defaultProps = {
  setCurrentStep: undefined,
};

StepDestructionLicensePlates.propTypes = {
  setCurrentStep: PropTypes.func,
};

export default StepDestructionLicensePlates;
