/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-array-index-key */
/* eslint-disable filenames/match-exported */
import { isNil } from 'lodash';
import { toNumValue } from 'utilities/format';
import { createFieldAfterSelector } from 'utilities/recoil/store';
import { validationRule } from 'utilities/form';
// eslint-disable-next-line import/no-cycle
import atomStateSchema from './atom-state-schema';

const removeSelect = ({ atoms, get, currentVal, currentId, set }) => {
  const self = get(atoms.formFieldAtom(currentId));
  if (self.options.findIndex((x) => x?.value === 'DEFAULT') !== -1) {
    set(atoms.formFieldAtom(currentId), {
      ...self,
      value: currentVal,
      options: [...self.options].splice(1),
    });
  }
};

const watcher = {
  onBlur: {
    taxAmount: ({ atoms, get, currentVal, set }) => {
      const netDollar = get(atoms.formFieldAtom('netDollar'));
      const taxAmountVal =
        currentVal === '' || isNil(currentVal) ? 0 : currentVal;
      if (currentVal === '' || isNil(currentVal)) {
        set(
          createFieldAfterSelector({ atoms, atomStateSchema })({
            id: 'taxAmount',
          }),
          {
            value: taxAmountVal,
            onWatcher: true,
          },
        );
      }
      if (netDollar.value && taxAmountVal) {
        set(
          createFieldAfterSelector({ atoms, atomStateSchema })({
            id: 'totalCost',
          }),
          {
            value: toNumValue(netDollar.value) + toNumValue(taxAmountVal),
            onWatcher: true,
          },
        );
      }
    },
  },
  onChange: {
    productDescription: ({ currentVal, dependantFunctions }) => {
      dependantFunctions.query.getUnitOfMeasureOptions({
        variables: {
          productId: currentVal,
        },
      });
    },
    quantity: ({ atoms, get, currentVal, set }) => {
      const unitCost = get(atoms.formFieldAtom('unitCost'));
      const taxAmount = get(atoms.formFieldAtom('taxAmount'));
      if (unitCost.value && currentVal) {
        const netDollarValue =
          toNumValue(unitCost.value) * toNumValue(currentVal);

        set(
          createFieldAfterSelector({ atoms, atomStateSchema })({
            id: 'netDollar',
          }),
          {
            value: netDollarValue,
            onWatcher: true,
          },
        );

        const calculatedTaxAmount = taxAmount?.value ?? 0;
        if (!taxAmount?.value) {
          set(atoms.formFieldAtom('taxAmount'), {
            ...get(atoms.formFieldAtom('taxAmount')),
            value: '0',
          });
        }

        set(
          createFieldAfterSelector({ atoms, atomStateSchema })({
            id: 'totalCost',
          }),
          {
            value: netDollarValue + toNumValue(calculatedTaxAmount),
            onWatcher: true,
          },
        );
      }
    },
    unitCost: ({ atoms, get, currentVal, set }) => {
      const quantity = get(atoms.formFieldAtom('quantity'));
      const taxAmount = get(atoms.formFieldAtom('taxAmount'));
      if (quantity.value && currentVal) {
        const netDollarValue =
          toNumValue(quantity.value) * toNumValue(currentVal);
        set(
          createFieldAfterSelector({ atoms, atomStateSchema })({
            id: 'netDollar',
          }),
          {
            value: netDollarValue,
            onWatcher: true,
          },
        );

        const calculatedTaxAmount = taxAmount?.value ?? 0;
        if (!taxAmount?.value) {
          set(atoms.formFieldAtom('taxAmount'), {
            ...get(atoms.formFieldAtom('taxAmount')),
            value: '0',
          });
        }
        set(
          createFieldAfterSelector({ atoms, atomStateSchema })({
            id: 'totalCost',
          }),
          {
            value: netDollarValue + toNumValue(calculatedTaxAmount),
            onWatcher: true,
          },
        );
      }
    },
    taxAmount: ({ atoms, get, currentVal, set }) => {
      const netDollar = get(atoms.formFieldAtom('netDollar'));

      if (netDollar.value && currentVal) {
        set(
          createFieldAfterSelector({ atoms, atomStateSchema })({
            id: 'totalCost',
          }),
          {
            value: toNumValue(netDollar.value) + toNumValue(currentVal),
            onWatcher: true,
          },
        );
      }
    },
    netDollar: ({ atoms, get, currentVal, set }) => {
      const taxAmount = get(atoms.formFieldAtom('taxAmount'));
      if (taxAmount.value && currentVal) {
        set(
          createFieldAfterSelector({ atoms, atomStateSchema })({
            id: 'totalCost',
          }),
          {
            value: toNumValue(taxAmount.value) + toNumValue(currentVal),
            onWatcher: true,
          },
        );
      }
    },
    state: removeSelect,
  },
};

const validation = {
  ...validationRule,
};

export { watcher, validation };
