import { gql } from '@apollo/client';

export const GET_MOTOR_POOLS_LIST = gql`
  query getMotorPoolsList(
    $order: OrderBy
    $limit: Float
    $offset: Float
    $filters: [Filter!]!
  ) {
    getMotorPoolsList(
      order: $order
      limit: $limit
      offset: $offset
      filters: $filters
    ) {
      rows {
        id
        poolName
        description
        state
        agencyCode
        vehiclesCount
        city
        zipCode
        timezone
        isPublic
        createdAt
        poolPrimaryDispatcher: primaryDispatcher {
          memberUserId
          member {
            fullName
            id
          }
        }
        members: mpMembers {
          member {
            id
            fullName
          }
          isPrimaryDispatcher
          isDispatcher
        }
        managesPool
        canSelfInvite
      }
      count
      hasMore
    }
  }
`;

export const GET_MOTOR_POOL_NAMES = gql`
  query getMotorPoolsList(
    $order: OrderBy
    $limit: Float
    $offset: Float
    $filters: [Filter!]!
  ) {
    getMotorPoolsList(
      order: $order
      limit: $limit
      offset: $offset
      filters: $filters
    ) {
      rows {
        id
        poolName
        description
        managesPool
      }
      count
      hasMore
    }
  }
`;

export const GET_MOTOR_POOL = gql`
  query getMotorPool($id: String!) {
    getMotorPool(id: $id) {
      id
      poolName
      description
      timezone
      managesPool
      agencyCode
      isMotorPoolMember
      primaryDispatcher {
        memberUserId
        member {
          fullName
          id
        }
      }
    }
  }
`;

export const GET_RESERVATIONS_BY_CONFIRMATION_NUMBER = gql`
  query GetReservationsByConfirmationNumber($confirmationNumber: String!) {
    getReservationsByConfirmationNumber(
      confirmationNumber: $confirmationNumber
    ) {
      reservationNumber
    }
  }
`;

export const GET_POOLS_BY_EMAIL = gql`
  query GetPoolsByEmail($email: String!) {
    getPoolsByEmail(email: $email) {
      email
      memberUserId
    }
  }
`;

export const GET_MEMBERS_BY_EMAIL = gql`
  query GetMembersByEmail(
    $motorPoolId: String!
    $order: OrderBy
    $limit: Float
    $filters: [Filter!]
  ) {
    getMotorPoolMembers(
      motorPoolId: $motorPoolId
      order: $order
      limit: $limit
      filters: $filters
    ) {
      rows {
        member {
          email
        }
      }
    }
  }
`;

export const GET_MOTOR_POOL_MEMBERS = gql`
  query GetMotorPoolMembers(
    $motorPoolId: String!
    $offset: Float
    $order: OrderBy
    $limit: Float
    $filters: [Filter!]
  ) {
    getMotorPoolMembers(
      motorPoolId: $motorPoolId
      offset: $offset
      order: $order
      limit: $limit
      filters: $filters
    ) {
      rows {
        id
        isSelfInvite
        memberUserId
        motorPoolId
        isPrimaryDispatcher
        isDispatcher
        member {
          fullName
          email
          phoneNumber
          phoneNumberExt
        }
      }
      hasMore
      count
    }
  }
`;

export const DELETE_MOTOR_POOL_MEMBER = gql`
  mutation DeleteMotorPoolMember($id: String!, $motorPoolId: String!) {
    deleteMotorPoolMember(id: $id, motorPoolId: $motorPoolId)
  }
`;

export const DELETE_MOTOR_POOL = gql`
  mutation deleteMotorPool($id: String!) {
    deleteMotorPool(id: $id)
  }
`;

export const REQUEST_PUBLIC_POOL_ACCESS = gql`
  mutation requestPublicPoolAccess($motorPoolId: String!) {
    requestPublicPoolAccess(motorPoolId: $motorPoolId) {
      poolName
    }
  }
`;

export const CREATE_MOTOR_POOL = gql`
  mutation createMotorPool($createPool: MotorPoolInput!) {
    createMotorPool(createPool: $createPool) {
      poolName
      description
    }
  }
`;

export const UPDATE_MOTOR_POOL = gql`
  mutation updateMotorPool($updatePool: UpdateMotorPoolInput!) {
    updateMotorPool(updatePool: $updatePool) {
      poolName
      description
    }
  }
`;

export const GET_AGENCIES_BY_PERMISSION = gql`
  query GetAgenciesByPermission(
    $operation: String!
    $subject: String!
    $order: OrderBy
    $feature: String
  ) {
    getAgenciesByPermission(
      operation: $operation
      subject: $subject
      order: $order
      feature: $feature
    ) {
      id
      name
    }
  }
`;

export const GET_BUREAUS_BY_PERMISSION = gql`
  query GetBureausByPermission(
    $agencyCode: String!
    $operation: String!
    $subject: String!
    $order: OrderBy
    $feature: String
  ) {
    getBureausByPermission(
      agencyCode: $agencyCode
      operation: $operation
      subject: $subject
      order: $order
      feature: $feature
    ) {
      id
      name
    }
  }
`;

export const GET_POOL_RESERVATIONS_LIST = gql`
  query GetPoolReservationsList(
    $motorPoolId: String!
    $order: OrderBy!
    $limit: Int!
    $offset: Int!
    $filters: [Filter!]
  ) {
    getPoolReservationsList(
      motorPoolId: $motorPoolId
      order: $order
      limit: $limit
      offset: $offset
      filters: $filters
    ) {
      rows {
        id
        reservationNumber
        motorPoolId
        motorPoolVehicleId
        odometerAtPickup
        odometerAtReturn
        totalKeysIssued
        totalKeysReturned
        vehicleCondition
        contactInfo
        motorPoolMember {
          member {
            id
            fullName
          }
        }
        motorPoolVehicle {
          vehicle {
            id
            vin
            tagNumber
            modelYear
            vehicleMake {
              makeName
            }
            vehicleModel {
              modelName
            }
            modelCode
          }
          comment
          location
        }
        newOdometerReading {
          assetId
          mileageDate
          odometer
          createdAt
        }
        scheduledPickupDate
        scheduledReturnDate
        actualPickupDate
        actualReturnDate
        isOnBehalf
        isDriverNeeded
        driverName
        comment
        canStartOrCancel
        canUpdate
        canCloseComplete
        reservationStatus {
          status
        }
        reservationPurposeId
        reservationPurpose {
          purpose
        }
      }
      count
      hasMore
    }
  }
`;

export const CREATE_POOL_RESERVATION = gql`
  mutation createPoolReservation(
    $createPoolReservation: MotorPoolReservationInput!
  ) {
    createPoolReservation(createPoolReservation: $createPoolReservation) {
      scheduledPickupDate
      scheduledReturnDate
    }
  }
`;

export const UPDATE_POOL_RESERVATION = gql`
  mutation updatePoolReservation(
    $updatePoolReservation: UpdatePoolReservationInput!
  ) {
    updatePoolReservation(updatePoolReservation: $updatePoolReservation)
  }
`;

export const START_POOL_RESERVATION = gql`
  mutation startPoolReservation($startReservation: StartReservationInput!) {
    startPoolReservation(startReservation: $startReservation)
  }
`;

export const CLOSE_AND_COMPLETE_RESERVATION = gql`
  mutation closeAndCompleteReservation(
    $closeReservation: CloseReservationInput!
  ) {
    closeAndCompleteReservation(closeReservation: $closeReservation)
  }
`;

export const CANCEL_POOL_RESERVATION = gql`
  mutation cancelPoolReservation($id: String!) {
    cancelPoolReservation(id: $id)
  }
`;

export const GET_MOTOR_POOL_VEHICLES_LIST = gql`
  query GetMotorPoolVehiclesList(
    $motorPoolId: String!
    $order: OrderBy!
    $limit: Float!
    $offset: Float!
    $filters: [Filter!]
  ) {
    getMotorPoolVehiclesList(
      motorPoolId: $motorPoolId
      order: $order
      limit: $limit
      offset: $offset
      filters: $filters
    ) {
      rows {
        id
        motorPoolId
        location
        comment
        vehicle {
          vin
          tagNumber
          modelYear
          vehicleMake {
            makeName
          }
          vehicleModel {
            modelName
          }
          modelCode
          ownershipTypeCode
        }
      }
      count
      hasMore
    }
  }
`;

export const CREATE_MOTOR_POOL_VEHICLE = gql`
  mutation CreateMotorPoolVehicle($createPoolVehicle: MotorPoolVehicleInput!) {
    createMotorPoolVehicle(createPoolVehicle: $createPoolVehicle) {
      id
      motorPoolId
      motorPool {
        poolName
      }
    }
  }
`;

export const DELETE_MOTOR_POOL_VEHICLE = gql`
  mutation DeleteMotorPoolVehicle($id: String!) {
    deleteMotorPoolVehicle(id: $id)
  }
`;

export const UPDATE_MOTOR_POOL_VEHICLE = gql`
  mutation UpdateMotorPoolVehicle(
    $updatePoolVehicle: UpdateMotorPoolVehicleInput!
  ) {
    updateMotorPoolVehicle(updatePoolVehicle: $updatePoolVehicle) {
      id
    }
  }
`;

export const GET_DISPATCHERS = gql`
  query GetScopedDispatchers($agencyCode: String) {
    getScopedDispatchers(agencyCode: $agencyCode) {
      id
      fullName
    }
  }
`;

export const GET_DRIVERS_AND_DISPATCHERS_BY_EMAIL = gql`
  query getScopedDriversAndDispatchersByEmail(
    $email: String
    $poolId: String!
  ) {
    getScopedDriversAndDispatchersByEmail(email: $email, poolId: $poolId) {
      id
      fullName
      email
    }
  }
`;

export const ADD_DRIVER = gql`
  mutation addMotorPoolDriver($motorPoolUserInput: MotorPoolUserInput!) {
    addMotorPoolDriver(motorPoolUserInput: $motorPoolUserInput) {
      id
      fullName
    }
  }
`;

export const GET_TIME_ZONES = gql`
  query GetTimeZones {
    getTimeZones {
      name
      standardCode
    }
  }
`;
