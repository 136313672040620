export const SPEED_RATINGS = [
  {
    value: 'A1',
    description: 'A1',
  },
  {
    value: 'A2',
    description: 'A2',
  },
  {
    value: 'A3',
    description: 'A3',
  },
  {
    value: 'A4',
    description: 'A4',
  },
  {
    value: 'A5',
    description: 'A5',
  },
  {
    value: 'A6',
    description: 'A6',
  },
  {
    value: 'A7',
    description: 'A7',
  },
  {
    value: 'A8',
    description: 'A8',
  },
  {
    value: 'B',
    description: 'B',
  },
  {
    value: 'C',
    description: 'C',
  },
  {
    value: 'D',
    description: 'D',
  },
  {
    value: 'E',
    description: 'E',
  },
  {
    value: 'F',
    description: 'F',
  },
  {
    value: 'G',
    description: 'G',
  },
  {
    value: 'J',
    description: 'J',
  },
  {
    value: 'K',
    description: 'K',
  },
  {
    value: 'L',
    description: 'L',
  },
  {
    value: 'M',
    description: 'M',
  },
  {
    value: 'N',
    description: 'N',
  },
  {
    value: 'P',
    description: 'P',
  },
  {
    value: 'Q',
    description: 'Q',
  },
  {
    value: 'R',
    description: 'R',
  },
  {
    value: 'S',
    description: 'S',
  },
  {
    value: 'T',
    description: 'T',
  },
  {
    value: 'U',
    description: 'U',
  },
  {
    value: 'H',
    description: 'H',
  },
  {
    value: 'V',
    description: 'V',
  },
  {
    value: 'W',
    description: 'W',
  },
  {
    value: 'Y',
    description: 'Y',
  },
  {
    value: '(Y)',
    description: '(Y)',
  },
];
