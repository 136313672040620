/* eslint-disable no-param-reassign */
/* eslint-disable filenames/match-exported */
import React from 'react';
import PropTypes from 'prop-types';

import { AFPTable } from '@gsa/afp-component-library';
import { isEmpty } from 'lodash/fp';
import NoDataComponent from './no-data-component';

// when do we do not need to use the LoadingComponent set isLoading = false
const AFPTableWrapper = ({
  onError,
  data,
  NoDataMessages,
  CustomizeNoDataFn,
  noDataIndicator,
  ...rest
}) => {
  if (onError) {
    NoDataMessages.title = 'Cannot Display the Data';
    NoDataMessages.text = 'Due to server error, data cannot be displayed.';
  }

  return (
    <>
      <AFPTable data={data || []} {...rest} />

      {noDataIndicator ||
        (isEmpty(data) && (
          <NoDataComponent
            title={NoDataMessages?.title || undefined}
            text={NoDataMessages?.text || undefined}
            ExtraNoDataContent={NoDataMessages?.ExtraNoDataContent}
          />
        ))}
    </>
  );
};

AFPTableWrapper.defaultProps = {
  onError: false,
  NoDataMessages: { title: null, text: null, ExtraNoDataContent: () => <></> },
  data: [],
  isLoading: false,
  CustomizeNoDataFn: null,
  noDataIndicator: false,
};

export const NoDataMessagesShape = {
  title: PropTypes.string,
  text: PropTypes.string,
  ExtraNoDataContent: PropTypes.oneOfType([
    PropTypes.instanceOf(Element),
    PropTypes.func,
  ]),
};

AFPTableWrapper.propTypes = {
  isLoading: PropTypes.bool,
  onError: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.object),
  NoDataMessages: PropTypes.shape(NoDataMessagesShape),
  CustomizeNoDataFn: PropTypes.func,
  noDataIndicator: PropTypes.bool,
};

export default AFPTableWrapper;
