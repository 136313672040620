import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { CREATE_SUPPORTING_DOC } from '../../../services/data-layer';
import phoneNumberFormatter from '../../../utilities/phoneNumberFormatter';
import { formatTagExpDate } from '../../../utilities/common';

const formatRegDataForPDF = (formState) => {
  const {
    vin,
    agencyName,
    bureauName,
    subSectionName,
    makeName,
    modelName,
    modelYear,
    fuelDescription,
    makeColorLabel,
    tagNumber,
    tagExpirationDate,
    exemptPlate,
    pointOfContact1: poc1,
    pointOfContact2: poc2,
  } = formState;

  return {
    id: vin,
    agency: { name: agencyName },
    bureau: { name: bureauName },
    office: { name: subSectionName },
    tag: tagNumber,
    exemptPlate,
    expDate: formatTagExpDate(tagExpirationDate),
    vehicleMake: { makeName },
    vehicleModel: { modelDescription: modelName },
    modelYear,
    makeColorName: makeColorLabel,
    fuel: { description: fuelDescription },
    primaryPoC: {
      fullName: `${poc1.firstName} ${poc1.lastName}`,
      primaryAddress: poc1.primaryAddress,
      secondaryAddress: poc1.secondaryAddress,
      city: poc1.city,
      stateCode: poc1.stateCode,
      postalCode: poc1.postalCode,
      primaryPhone: phoneNumberFormatter(poc1.primaryPhone),
    },
    secondaryPoC: {
      fullName: `${poc2.firstName} ${poc2.lastName}`,
      primaryAddress: poc2.primaryAddress,
      secondaryAddress: poc2.secondaryAddress,
      city: poc2.city,
      stateCode: poc2.stateCode,
      postalCode: poc2.postalCode,
      primaryPhone: phoneNumberFormatter(poc2.primaryPhone),
    },
  };
};

export default function useCreatePdf(formState) {
  const [complete, setComplete] = useState(null);
  const [createSupportingDoc, { data, loading, error }] = useMutation(
    CREATE_SUPPORTING_DOC,
    {
      fetchPolicy: 'no-cache',
      onError(err) {
        console.warn(err);
      },
    },
  );

  const createRegPdf = () => {
    const data = formatRegDataForPDF(formState);
    createSupportingDoc({
      variables: {
        data,
        model: 'Vehicle',
        modelPK: formState.vin,
        documentName: 'registration',
      },
    }).finally(() => {
      setComplete(true);
    });
  };

  return [createRegPdf, { data, loading, error, complete }];
}
