/* eslint-disable react/prop-types */
/* eslint-disable filenames/match-exported */
import { useMutation } from '@apollo/client';
import { EXPORT_REPORT } from 'services/data-layer';
import exportMessage from 'utilities/export-message';
import { REPORT_DELIVERY_METHOD, REPORT_TYPE } from 'utilities/consts';
import { usePmExpressFilter } from './filters/filter-provider';
import { usePmExpress } from './pm-express-provider';

const useDataController = () => {
  const pmContext = 'pmListing',
    emptyFilterResult = 0,
    maxExportingCount = 950000;
  const pmExpressContext = usePmExpress();

  if (!pmExpressContext) {
    return {
      onExport: () => {},
    };
  }

  const { setRequestError, setRequestMessage, pmList } = pmExpressContext;
  const { count } = pmList;
  const { filters } = usePmExpressFilter();
  const [exportData] = useMutation(EXPORT_REPORT, {
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      setRequestMessage(exportMessage.exportSuccessMessage, pmContext);
    },
    onError: () => {
      setRequestError(exportMessage.exportMsgError);
    },
  });

  const onExport = () => {
    setRequestError(null, pmContext);
    if (count === emptyFilterResult) {
      setRequestError(
        exportMessage.exportMsgNoData('preventative maintenance express'),
        pmContext,
      );
      return;
    }
    if (count > maxExportingCount) {
      setRequestError(exportMessage.exportMsgTooMany, pmContext);
    } else {
      exportData({
        variables: {
          request: {
            reportType: REPORT_TYPE.PREVENTATIVE_MAINTENANCE_EXPRESS,
            deliveryMethod: REPORT_DELIVERY_METHOD.EMAIL,
            reportCriteria: {
              filters,
            },
          },
        },
      });
    }
  };

  return {
    onExport,
  };
};

export default useDataController;
