/* eslint react/prop-types: 0 */
import React from 'react';
import { Spinner } from '@gsa/afp-component-library';
import { VehicleOwnership } from './vehicle-ownership';
import { ReportSelection } from './report-selection';
import { ScheduleSelection } from './schedule-selection';
import { TYPE } from '../config/helpers';
import { useExport } from '../export-provider';

const DEFAULT_VEHICLE_OPTION = 'AO';
export const ReportOwnershipSelection = ({
  control,
  mode,
  watch,
  setValue,
}) => {
  const { loading, canBeScheduled } = useExport();

  const selectedReportType = watch('reportType');
  const selectedVehicleOption =
    watch('vehicle_options') || DEFAULT_VEHICLE_OPTION;
  if (loading?.agenciesQueryLoading) {
    return <Spinner size="small" data-testid="agencies-query-spinner" />;
  }

  return (
    <section className="grid-row margin-bottom-6">
      <div className="grid-col-12" data-testid="vehicle-ownership-section">
        <VehicleOwnership control={control} setValue={setValue} />
      </div>
      <div className="grid-col-12" data-testid="vehicle-report-section">
        <ReportSelection control={control} watch={watch} mode={mode} />
      </div>
      {mode === TYPE.REPORT &&
        selectedReportType !== 'DEFAULT' &&
        canBeScheduled &&
        selectedVehicleOption !== 'AO' && (
          <div
            className="grid-col-12 margin-top-3"
            data-testid="schedule-selection-section"
          >
            <ScheduleSelection control={control} watch={watch} />
          </div>
        )}
    </section>
  );
};
