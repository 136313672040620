import PropTypes from 'prop-types';
import React, { useEffect, createContext, useContext, useReducer } from 'react';
import { useLazyQuery } from '@apollo/client';
import { GET_ZONES_CENTERS } from 'services/data-layer/fed-hierarchy.gql';
import { GET_PAGINATED_RECALL_CAMPAIGNS } from './recall-campaigns.gql';
import { GET_RECALL_CAMPAIGN_OPTIONS } from 'services/data-layer';

export const RecallCampaignsContext = createContext();

const initialState = {
  campaignList: {
    rows: [],
    hasMore: false,
    count: 0,
  },
  alertMessage: { context: '', message: '', type: '', header: null },
  campaignOptions: null,
  optionsData: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_CAMPAIGN_LIST':
      return { ...state, campaignList: action.payload };
    case 'SET_ALERT_MESSAGE':
      return { ...state, alertMessage: action.payload };
    case 'SET_OPTIONS_DATA_ZONES': {
      const optionData = {
        ...state.optionsData,
        zones: action.payload.zones,
        fmcsWithZoneAndRegion: action.payload.fmcsWithZoneAndRegion,
      };
      return { ...state, optionsData: optionData };
    }
    case 'SET_CAMPAIGNS_OPTIONS': {
      return { ...state, campaignOptions: action.payload };
    }
    default:
      throw new Error('Invalid action');
  }
};

function RecallCampaignsProvider({ children, ...props }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const dispatchAction = (type, payload) => {
    dispatch({
      type,
      payload,
    });
  };

  const setRequestError = (requestError, context) => {
    dispatchAction('SET_ALERT_MESSAGE', {
      type: 'error',
      message: requestError?.message,
      error: requestError,
      context,
    });
  };

  const setRequestMessage = (message, context) => {
    dispatchAction('SET_ALERT_MESSAGE', {
      type: 'success',
      message: message?.message,
      header: 'Export Initiated:',
      context,
    });
  };

  const [getRecallCampaigns, { loading: recallCampaignsLoading }] =
    useLazyQuery(GET_PAGINATED_RECALL_CAMPAIGNS, {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onError: (error) => setRequestError(error, 'campaignListing'),
      onCompleted: (responseData) => {
        if (responseData?.getPaginatedRecallCampaigns) {
          dispatchAction(
            'SET_CAMPAIGN_LIST',
            responseData.getPaginatedRecallCampaigns,
          );
        }
      },
    });

  const [getZones] = useLazyQuery(GET_ZONES_CENTERS, {
    fetchPolicy: 'network-only', // Used for first execution
    nextFetchPolicy: 'cache-first', // Used for subsequent executions
    onError: (error) => setRequestError(error),
    onCompleted: (responseData) => {
      if (responseData?.getZones) {
        const zones = [];
        const fmcsWithZoneAndRegion = [];
        const zoneOptions = responseData?.getZones.map((zone) => ({
          value: zone.id,
          label: zone.name,
        }));
        zones.push(...zoneOptions);

        const fmcOptions = responseData?.getZones.flatMap((zone) =>
          zone.centers.map((center) => ({
            value: `${zone.id}:${center.id}:${center.regionId}`,
            label: center.name,
            zoneId: zone.id,
            regionId: center.regionId,
            centerId: center.id,
          })),
        );
        fmcsWithZoneAndRegion.push(...fmcOptions);

        dispatchAction('SET_OPTIONS_DATA_ZONES', {
          zones,
          fmcsWithZoneAndRegion,
        });
      }
    },
  });

  const [getCampaignsOptions] = useLazyQuery(GET_RECALL_CAMPAIGN_OPTIONS, {
    onError: (error) => setRequestError(error),
    onCompleted: (data) => {
      const recallCodes = data?.getRecallCampaigns.recallCodes || [];
      const nhtsaCampaignIds = data?.getRecallCampaigns.nhtsaCampaignIds || [];
      const recallCodesOptions = recallCodes.map((r) => ({
        label: r,
        value: r,
      }));
      const nhtsaCampaignIdsOptions = nhtsaCampaignIds.map((r) => ({
        label: r,
        value: r,
      }));
      dispatchAction('SET_CAMPAIGNS_OPTIONS', {
        recallCodesOptions,
        nhtsaCampaignIdsOptions,
      });
    },
  });

  useEffect(() => {
    getZones();
  }, []);

  return (
    <RecallCampaignsContext.Provider
      value={{
        ...state,
        getRecallCampaigns,
        recallCampaignsLoading,
        getCampaignsOptions,
        dispatchAction,
        setRequestError,
        setRequestMessage,
        ...props,
      }}
    >
      {children}
    </RecallCampaignsContext.Provider>
  );
}

export default RecallCampaignsProvider;

RecallCampaignsProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export const useRecallCampaigns = () => useContext(RecallCampaignsContext);
