/* eslint-disable react/prop-types */
import React from 'react';
import { Button } from '@gsa/afp-component-library';
import { useHistory } from 'react-router-dom';
import { getDataOrDash, getNhtsaLink } from '../helper';

const DetailList = ({ RecallCampaignDetailData, canManageRecallCampaigns }) => {
  const history = useHistory();
  return (
    <>
      {' '}
      <div className="grid-row padding-top-1 border-bottom border-base-lighter">
        <div className="tablet:grid-col-2 text-bold ">Campaign ID</div>
        <div className="tablet:grid-col-8 ">
          {getDataOrDash(RecallCampaignDetailData, 'recallCode')}
        </div>

        <div className="tablet:grid-col-2">
          {canManageRecallCampaigns && (
            <Button
              className="margin-bottom-1"
              onClick={() => {
                history.replace(
                  `/recalls/campaign/edit/${RecallCampaignDetailData?.id}`,
                );
              }}
              label="Edit recall"
            />
          )}
        </div>
      </div>
      <div className="grid-row padding-y-1 border-bottom border-base-lighter">
        <div className="tablet:grid-col-2  text-bold ">Campaign</div>
        <div className="tablet:grid-col-10 ">
          {getDataOrDash(RecallCampaignDetailData, 'recallDescription')}
        </div>
      </div>
      <div className="grid-row padding-y-1 border-bottom border-base-lighter">
        <div className="tablet:grid-col-2  text-bold ">Description</div>
        <div className="tablet:grid-col-10 ">
          {getDataOrDash(RecallCampaignDetailData, 'detailDescription')}
        </div>
      </div>
      <div className="grid-row padding-y-1 border-bottom border-base-lighter">
        <div className="tablet:grid-col-2  text-bold ">Safety risk</div>
        <div className="tablet:grid-col-10 ">
          {getDataOrDash(RecallCampaignDetailData, 'safetyRisk')}
        </div>
      </div>
      <div className="grid-row padding-y-1 border-bottom border-base-lighter">
        <div className="tablet:grid-col-2  text-bold ">Remedy</div>
        <div className="tablet:grid-col-10 ">
          {getDataOrDash(RecallCampaignDetailData, 'remedyDescription')}
        </div>
      </div>
      <div className="grid-row">
        <div className="tablet:grid-col-2  padding-y-1 border-bottom border-base-lighter text-bold">
          NHTSA ID
        </div>
        {RecallCampaignDetailData && (
          <div className="tablet:grid-col-3  padding-y-1 border-bottom border-base-lighter">
            {getNhtsaLink(RecallCampaignDetailData)}
          </div>
        )}
        <div className="tablet:grid-col-2 grid-offset-2 padding-y-1 border-bottom border-base-lighter text-bold">
          Recall type
        </div>
        <div className="tablet:grid-col-3 padding-y-1 border-bottom border-base-lighter">
          {getDataOrDash(RecallCampaignDetailData, 'recallType')}
        </div>
      </div>
      <div className="grid-row">
        <div className="tablet:grid-col-2 padding-y-1 text-bold border-bottom border-base-lighter ">
          Remedy available
        </div>
        <div className="tablet:grid-col-3 padding-y-1 border-bottom border-base-lighter">
          {getDataOrDash(RecallCampaignDetailData, 'isRemedyAvailable')}
        </div>
        <div className="tablet:grid-col-2 padding-y-1 text-bold grid-offset-2  border-bottom border-base-lighter">
          Customer should stop driving the vehicle?
        </div>
        <div className="tablet:grid-col-3 padding-y-1  border-bottom border-base-lighter">
          {getDataOrDash(RecallCampaignDetailData, 'shouldStopDrive')}
        </div>
      </div>
      <div className="grid-row ">
        <div className="tablet:grid-col-2 padding-y-1 text-bold  border-bottom border-base-lighter ">
          Does the vehicle need to be parked outside?
        </div>
        <div className="tablet:grid-col-3 padding-y-1  border-bottom border-base-lighter">
          {getDataOrDash(RecallCampaignDetailData, 'shouldParkOutside')}
        </div>
        <div className="tablet:grid-col-2 padding-y-1 text-bold grid-offset-2  border-bottom border-base-lighter">
          Source Name
        </div>
        <div className="tablet:grid-col-3 padding-y-1 border-bottom border-base-lighter">
          {getDataOrDash(RecallCampaignDetailData, 'recallSource')}
        </div>
      </div>
      <div className="grid-row padding-y-1">
        <div className="tablet:grid-col-2 text-bold  border-bottom border-base-lighter ">
          Manufacturer
        </div>
        <div className="tablet:grid-col-3 padding-y-1 border-bottom border-base-lighter">
          {getDataOrDash(RecallCampaignDetailData, 'make')}
        </div>
        <div className="tablet:grid-col-2 padding-y-1 text-bold grid-offset-2  border-bottom border-base-lighter">
          Recall Release Date
        </div>
        <div className="tablet:grid-col-3 padding-y-1  border-bottom border-base-lighter">
          {getDataOrDash(RecallCampaignDetailData, 'recallReleaseDate')}
        </div>
      </div>
    </>
  );
};

export default DetailList;
