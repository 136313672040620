import React from 'react';
import { Button, Menu } from '@gsa/afp-component-library';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import PropTypes from 'prop-types';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  canReviewModification,
  canCreateModification,
} from '../../../../utilities/authorization';
import { useVehicle } from '../../vehicle-context-provider';
import { MOD_STATUS } from './helpers';
import {
  GET_ATTACHMENT_SIGNED_URL_QUERY,
  GET_EDMS_DOWNLOAD_URL,
} from '../../../../services/data-layer/modifications';

const PATH = {
  viewRequest: '',
  viewCase: 'case',
};

const getMNRUrl = (vin, path, id) => {
  const show = (val) => (val ? `/${val}` : '');
  return `${window.AFP_CONFIG.appURLs.mnr}/vehicles/${encodeURIComponent(
    vin,
  )}/modifications${show(path)}${show(id)}`;
};

export const HyperlinkedRequestNumber = ({ requestNumber }) => {
  const {
    vehicle: { id: vin },
  } = useVehicle();
  return (
    <a href={getMNRUrl(vin, PATH.viewRequest, requestNumber)}>
      {requestNumber}
    </a>
  );
};
HyperlinkedRequestNumber.propTypes = {
  requestNumber: PropTypes.string.isRequired,
};

export const AddModificationButton = () => {
  const ability = useAppAbility();
  const {
    vehicle: { id: vin },
  } = useVehicle();

  return canCreateModification(ability) ? (
    <Button
      onClick={() => {
        window.location.assign(getMNRUrl(vin, 'create'));
      }}
      label="Request modification"
      leftIcon={{ name: 'add', class: 'usa-icon--size-3' }}
    />
  ) : null;
};

function downloadUrl(url) {
  try {
    const link = document.createElement('a');
    link.href = url;

    link.setAttribute('download', 'file');
    document.body.appendChild(link);

    link.click();
    link.remove();
  } catch (error) {
    if (error instanceof Error) throw new Error(error.message);
    throw new Error('Error occurred while downloading attachment');
  }
}

export const ModificationRowActions = ({ request }) => {
  const { requestNumber, status, isCapitalized } = request;
  const ability = useAppAbility();
  const {
    vehicle: { id: vin },
  } = useVehicle();
  const isApproved = status === MOD_STATUS.Approved;

  const [getAttachmentSignedUrl] = useLazyQuery(
    GET_ATTACHMENT_SIGNED_URL_QUERY,
    {
      onCompleted: (data) => {
        const { getServiceAttachmentDownloadURL } = data;
        if (getServiceAttachmentDownloadURL) {
          downloadUrl(getServiceAttachmentDownloadURL);
        }
      },
      fetchPolicy: 'no-cache',
    },
  );

  const [getAttachmentFromEDMS] = useMutation(GET_EDMS_DOWNLOAD_URL);

  const attachments = [...(request.attachments || [])];

  attachments.sort((a, b) => {
    const requestNumberA = parseInt(a.attachmentFileLocation.split('-')[3]);
    const requestNumberB = parseInt(b.attachmentFileLocation.split('-')[3]);

    if (requestNumberA < requestNumberB) return 1;
    if (requestNumberA > requestNumberB) return -1;
    return 0;
  });

  const attachment2553A = attachments.find((attachment) =>
    attachment.attachmentFileLocation.endsWith(`2553-A.pdf`),
  );
  const attachment2553 = attachments.find((attachment) =>
    attachment.attachmentFileLocation.endsWith(`2553.pdf`),
  );

  const downloadAttachment = async (attachment) => {
    if (attachment) {
      const { data } = await getAttachmentFromEDMS({
        variables: { attachmentId: attachment.attachmentId },
      });
      if (!data?.getEdmsDownloadLink) {
        await getAttachmentSignedUrl({
          variables: {
            fileKey: attachment.attachmentFileLocation,
            fileMimeType: attachment.attachmentFileType,
          },
        });
      } else {
        downloadUrl(data.getEdmsDownloadLink);
      }
    }
  };

  const onActionClick = async (action) => {
    switch (action) {
      case 'viewRequest':
        window.location.assign(getMNRUrl(vin, PATH[action], requestNumber));
        break;
      case 'view2553APDF':
        await downloadAttachment(attachment2553A);
        break;
      case 'view2553CapitalizationPDF':
        await downloadAttachment(attachment2553);
        break;
      default:
        break;
    }
  };

  const menuItems = [
    {
      label: 'View request',
      action: 'viewRequest',
      icon: 'visibility',
    },
  ];
  if (isApproved && canReviewModification(ability))
    menuItems.push({
      label: 'View case file',
      action: 'viewCase',
      icon: 'folder_open',
    });
  if (attachment2553A)
    menuItems.push({
      label: 'View PDF Form 2553-A Modification Request',
      action: 'view2553APDF',
      icon: 'file_download',
    });
  if (attachment2553 && canReviewModification(ability) && isCapitalized)
    menuItems.push({
      label: 'View PDF Form 2553 Capitalization',
      action: 'view2553CapitalizationPDF',
      icon: 'file_download',
    });

  return (
    <div className="height-4 width-4" data-testid="modification-actions-button">
      <Menu
        onActionClick={onActionClick}
        menuItems={menuItems.filter(Boolean)}
        iconColor="text-primary"
      />
    </div>
  );
};

ModificationRowActions.propTypes = {
  request: PropTypes.shape({
    requestNumber: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    attachments: PropTypes.arrayOf(
      PropTypes.shape({
        attachmentId: PropTypes.string.isRequired,
        attachmentFileLocation: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
};
