/* eslint-disable filenames/match-exported */

import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  GET_RECALL_CAMPAIGN,
  SEND_RECALL_NOTIFICATION_EMAIL,
} from 'services/data-layer';
import useAlertManager from 'utilities/alert-manager';
import { useLazyQuery, useMutation } from '@apollo/client';
import RecallUpdate from './recall-update';

const RecallAction = () => {
  // eslint-disable-next-line prefer-const
  let { action, id } = useParams();
  const [campaignId, setCampaignId] = useState(id);
  const [recallCampaignData, setRecallCampaignData] = useState();
  const [Alert, alertUtils] = useAlertManager(true, true, true);

  const [sendRecallNotificationEmail] = useMutation(
    SEND_RECALL_NOTIFICATION_EMAIL,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const [getRecallCampaign, { loading: loadingGetRecallCampaign }] =
    useLazyQuery(GET_RECALL_CAMPAIGN, {
      fetchPolicy: 'network-only',

      onError: (error) => {
        alertUtils.showErrorAlert(<span>{error.message}</span>);
      },
      onCompleted: (data) => {
        if (data?.getRecallCampaign) {
          setRecallCampaignData(data.getRecallCampaign);
        }
      },
    });
  const history = useHistory();

  useEffect(() => {
    if (!['create', 'edit'].includes(action)) {
      history.replace('/NotFound');
    }

    if (action === 'edit' && !id) {
      history.replace('/NotFound');
    }

    if (action === 'edit') {
      getRecallCampaign({
        variables: {
          campaignId: id,
        },
      });
    }
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <RecallUpdate
        action={action}
        campaignId={campaignId}
        setCampaignId={setCampaignId}
        sendRecallNotificationEmail={sendRecallNotificationEmail}
        recallCampaignData={recallCampaignData}
        Alert={Alert}
        alertUtils={alertUtils}
      />
    </>
  );
};

export default RecallAction;
