import React, { useState, useEffect, useRef } from 'react';
import { useLazyQuery } from '@apollo/client';
import { TextInput, Button, Icon, Spinner } from '@gsa/afp-component-library';
import { useRegistration } from '../../../reg-context-provider';
import { GET_POC } from '../../../../../services/data-layer';
import registrationFormValidation from '../../../helpers/registration-form-validation';
import { nullForForm } from '../../../../../utilities/common';

const validateEmail = (email, otherEmail) => {
  return registrationFormValidation(
    { email, otherEmail },
    {
      email: [
        {
          guard: 'validEmail',
          msg: 'Email address must be a valid email address.',
        },
        {
          guard: 'isUniquePoCEmail',
          msg: 'POC 1 and 2 email addresses must be different.',
        },
      ],
    },
  );
};

const EmailAction = ({ loading, pocNumber, validated, ...props }) => {
  if (loading) {
    return <Spinner />;
  }
  if (validated) {
    return (
      <Icon
        iconName="check_circle"
        className="usa-icon--size-6 margin-x-4"
        style={{ fill: '#00A91C' }}
      />
    );
  }
  return (
    <Button
      type="submit"
      className="margin-top-2 tablet:margin-top-0"
      data-testid="btn-getpoc"
      variant="primary"
      aria-label={`Add Contact #${pocNumber.pocNumber}`}
      label="+ Add Contact"
      {...props}
    />
  );
};

export default function PocSearch({
  pocNumber,
  value,
  otherEmail,
  validated,
  onChange = () => {},
  onPocChange = () => {},
  errorMessage,
  inputRef,
  ...props
}) {
  const [email, setEmail] = useState(value || '');
  const [emailValidation, setEmailValidation] = useState(null);
  const [pocChanged, setPocChanged] = useState(false);
  const pocRef = useRef(null);

  const { setRegistrationError } = useRegistration();

  const focusInput = () => {
    if (pocRef?.current) {
      const input = pocRef.current.querySelector('input');
      if (input) {
        input.focus();
      }
    }
  };

  useEffect(() => {
    setEmail((em) => {
      if (em !== value) {
        return nullForForm(value);
      }
      return em;
    });
  }, [value]);

  useEffect(() => {
    if (errorMessage) {
      setEmailValidation(errorMessage);
    }
  }, [errorMessage]);

  const [getPoC, { data, loading }] = useLazyQuery(GET_POC, {
    fetchPolicy: 'no-cache',
    onError(err) {
      setRegistrationError(err);
    },
  });

  const getEmailStatusClass = () => {
    return validated ? 'input_success' : '';
  };

  const handlePocLookup = (e) => {
    e.preventDefault();
    const validatedEmail = validateEmail(email, otherEmail);
    setEmailValidation(validatedEmail.email);
    if (!validatedEmail.email) {
      const id = email;
      if (id) {
        getPoC({
          variables: {
            id,
          },
        });
      }
    } else {
      focusInput();
    }
  };

  useEffect(() => {
    if (data) {
      onPocChange(data?.getPoC);
      setRegistrationError(null);
    }
  }, [data]);

  const handleEmailChange = (e) => {
    setPocChanged(true);
    setEmail(e.target.value);
    onChange(e);
  };

  const [ariaLabel, setAriaLabel] = useState(`P O C ${pocNumber.pocNumber}`);

  useEffect(() => {
    if (emailValidation) {
      setAriaLabel(emailValidation);
    } else {
      setAriaLabel(`P O C ${pocNumber.pocNumber}`);
    }
  }, [emailValidation]);
  return (
    <form onSubmit={handlePocLookup} ref={pocRef}>
      <div className="grid-row">
        <div className="afp-vehicle-registration__poc_lookup_input">
          <TextInput
            {...props}
            aria-label={ariaLabel}
            value={email}
            onChange={handleEmailChange}
            onBlur={(e) => {
              if (pocChanged) handlePocLookup(e);
              setPocChanged(false);
            }}
            className={getEmailStatusClass()}
            errorMessage={nullForForm(emailValidation)}
            inputRef={inputRef}
          />
        </div>
        <div className="padding-left-1 afp-vehicle-registration__poc_lookup">
          <EmailAction
            loading={loading}
            validated={validated}
            pocNumber={pocNumber}
          />
        </div>
      </div>
    </form>
  );
}
