import React, { useEffect, useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import {
  Breadcrumbs,
  PageTitle,
  Spinner,
  FilterTableFrame,
} from '@gsa/afp-component-library';
import FilterUpRightActions from 'utilities/filter-up-right-actions';

import { InventoryMgmtGuideText } from 'utilities/user-guide';
import {
  ExpenseFilterProvider,
  useExpenseFilter,
  ExpensePageProvider,
} from './providers';
import useDataController from './use-data-controller';
import { Sidebar, ExpenseTable, BannerMessage } from './widgets';

const title = 'Vehicle Expenses';
const trail = [<a href={`${window.AFP_CONFIG.appURLs.home}/home`}>Home</a>];
const Page = () => {
  const { agencies, getAgencies, filterStructure } = useExpenseFilter();

  const FilterGrid = useMemo(
    () => FilterTableFrame(null, FilterUpRightActions, Sidebar, ExpenseTable),
    [],
  );

  const { onExport } = useDataController();

  useEffect(() => {
    getAgencies();
  }, []);
  /**
   * Below we are using agencies variable to check on failure and loading states.
   * It might good for improvement to have error and loading states coming back from the useExpenseFilter
   */
  if (agencies === undefined) {
    return <Spinner size="small" data-testid="expenses-spinner" />;
  }
  if (agencies.length === 0) {
    return <Redirect to="/not-opted" />;
  }
  return (
    <article>
      <header>
        <Breadcrumbs trail={trail} current={title} />
        <BannerMessage />
        <PageTitle title={title} data-testid="expense-listing-header" />

        <div className="margin-bottom-4 tablet:grid-col-7">
          <p>Use the search and filters to locate vehicle expense records.</p>
          <p>
            {' '}
            If you would like to perform a bulk upload of expense data, please
            go to the <a href="/reports/">Vehicle Reports Manager</a>. From
            there you can download a template and upload your completed
            template.
          </p>
          <InventoryMgmtGuideText />
        </div>
      </header>
      {filterStructure ? (
        <FilterGrid
          filterToggle
          upRightProps={{
            DropDownItems: [
              {
                label: 'Export data',
                iconName: 'file_download',
                onClick: onExport,
              },
            ],
          }}
        />
      ) : (
        <Spinner size="medium" data-testid="expense-spinner" />
      )}
    </article>
  );
};

const VehicleExpenses = () => {
  return (
    <ExpenseFilterProvider>
      <ExpensePageProvider>
        <Page />
      </ExpensePageProvider>
    </ExpenseFilterProvider>
  );
};

export default VehicleExpenses;
