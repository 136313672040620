/* eslint-disable react/prop-types */
import React from 'react';
import { Redirect } from 'react-router-dom';

export default function DetailsRedirect({
  complete,
  pdfComplete,
  vin,
  pdfUrl,
  isCurrentlyRegistered,
  registrationType,
}) {
  if (complete && pdfComplete) {
    return (
      <Redirect
        to={{
          pathname: `/vehicles/${encodeURIComponent(vin)}/overview`,
          state: {
            isRedirect: true,
            documentLink: pdfUrl,
          },
        }}
      />
    );
  }
  if (isCurrentlyRegistered && registrationType === 'regular') {
    return (
      <Redirect
        to={{
          pathname: `/vehicles/${encodeURIComponent(vin)}/overview`,
        }}
      />
    );
  }
  return null;
}
