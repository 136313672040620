import React from 'react';
import { StatusBadge } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';

const SCHEDULE_STATUS_COLORS = {
  Active: 'Ready-Gray',
  Suspended: 'Inactive-Gray',
};

export default function SchedulesStatusBadge({ schedlueStatus }) {
  return (
    <StatusBadge variant={SCHEDULE_STATUS_COLORS[schedlueStatus]}>
      {schedlueStatus}
    </StatusBadge>
  );
}

SchedulesStatusBadge.propTypes = {
  schedlueStatus: PropTypes.string.isRequired,
};
