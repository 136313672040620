import React, { useState } from 'react';
import { useRegistration } from '../../reg-context-provider';
import { nullForForm } from '../../../../utilities/common';
import SelectAgency from './select-agency';
import SelectBureau from './select-bureau';
import SelectOffice from './select-office';
import PocInformation from './poc-information';
import useRegistrationManager from '../../helpers/registration-manager';
import {
  getAgencyCode,
  getBureauCode,
  getSubsectionCode,
} from 'components/vehicle-registration/helpers/use-register-vehicle';

export default function AgencyAndPoc() {
  const { formState, setFormState, getFieldValidation } = useRegistration();
  const { registrationType } = useRegistrationManager();

  const [isMultiAgencyScoped, setIsMultiAgencyScoped] = useState(false);
  const [isMultiBureauScoped, setIsMultiBureauScoped] = useState(false);

  const isDisabledAgency = () => {
    const { tagAgencyCode, agencyCode } = formState;

    if (!formState?.exemptPlate) {
      return !!tagAgencyCode && !!agencyCode;
    } else {
      return (
        !isMultiAgencyScoped &&
        registrationType !== 'manual' &&
        (!!tagAgencyCode || !!agencyCode)
      );
    }
  };

  const isDisabledBureau = () => {
    const { tagBureauCode, bureauCode } = formState;
    if (!formState?.exemptPlate) {
      return !!bureauCode || !!tagBureauCode;
    } else {
      return (
        registrationType !== 'manual' &&
        !isMultiBureauScoped &&
        (!!tagBureauCode || !!bureauCode)
      );
    }
  };

  return (
    <main
      data-testid="step2"
      className="afp-vehicle-registration__step afp-vehicle-registration-wrapper"
    >
      <div className="grid-row grid-gap-6 margin-bottom-3">
        <div className="tablet:grid-col-4">
          <SelectAgency
            setIsMultiAgencyScoped={setIsMultiAgencyScoped}
            agencyCode={nullForForm(getAgencyCode(formState))}
            onChange={(agency) => {
              if (agency.value !== nullForForm(getAgencyCode(formState))) {
                setFormState({
                  selectedAgencyCode: agency.value,
                  agencyName: agency.label,
                  selectedBureauCode: null,
                  selectedOfficeCode: null,
                  bureauCode: null,
                });
              }
            }}
            onUpdate={(agency) => {
              setFormState({
                agencyCode: agency.value,
                agencyName: agency.label,
              });
            }}
            errorMessage={getFieldValidation('agencyCode')}
            readOnly={isDisabledAgency()}
            disabled={isDisabledAgency()}
          />
        </div>
        <div className="tablet:grid-col-4">
          <SelectBureau
            setIsMultiBureauScoped={setIsMultiBureauScoped}
            agencyCode={nullForForm(getAgencyCode(formState))}
            bureauCode={nullForForm(getBureauCode(formState))}
            onUpdate={(bureau) => {
              setFormState({
                bureauCode: bureau.value,
                bureauName: bureau.label,
              });
            }}
            onChange={(bureau) => {
              setFormState({
                selectedBureauCode: bureau.value,
                bureauName: bureau.label,
                selectedOfficeCode: null,
              });
            }}
            errorMessage={getFieldValidation('bureauCode')}
            readOnly={isDisabledBureau()}
            disabled={isDisabledBureau()}
          />
        </div>
        <div className="grid-col-4">
          <SelectOffice
            agencyCode={nullForForm(getAgencyCode(formState))}
            bureauCode={nullForForm(getBureauCode(formState))}
            subSectionCode={nullForForm(getSubsectionCode(formState))}
            onUpdate={(office) => {
              setFormState({
                subSectionCode: office.value,
                subSectionName: office.label,
              });
            }}
            onChange={(office) => {
              setFormState({
                selectedOfficeCode: office.value,
                subSectionName: office.label,
              });
            }}
          />
        </div>
      </div>
      <h2 className="margin-bottom-0 title-m-bold">
        Point of Contact Information
      </h2>
      <div className="grid-row grid-gap">
        <div className="tablet:grid-col-8">
          <p className="afp-vehicle-registration__section_text">
            Enter the email address of the Point of Contact (POC) to look up the
            POC information. If there is not an existing record for the email
            address entered, you will be prompted to enter the details for the
            new POC. At least 1 POC for the vehicle must be identified in order
            to complete the registration. You can return to this page in the
            future to change the POC information if needed.
          </p>
        </div>
      </div>

      <div data-testid="step3" className="afp-registration__poc_form">
        <div>
          <div className="grid-row grid-gap margin-bottom-4">
            <div className="grid-col-12">
              <div className="grid-row grid-gap">
                <PocInformation />
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
