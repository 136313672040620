/* eslint-disable react/prop-types */
/* eslint-disable filenames/match-exported */

import React from 'react';
import { Pagination } from '@gsa/afp-component-library';
import AFPTable from 'utilities/afp-table-wrapper';
import SubRowComponent from './sub-row-component';

import { columns } from '../model/schema/expense-records-schema';

const ExpenseDisplayTable = ({
  paginationState,
  setPaginationState,
  tableOrderState,
  expenseRecordsState,
  setTableOrderState,
  NoDataMessages,
}) => {
  const renderRowSubComponent = React.useCallback(({ row }) => {
    return <SubRowComponent row={row} />;
  }, []);

  return (
    <>
      <AFPTable
        columns={columns()}
        data={expenseRecordsState.rows}
        expandable
        NoDataMessages={NoDataMessages}
        defaultSort={tableOrderState.order}
        renderRowSubComponent={renderRowSubComponent}
        onSort={(orderStr) => {
          if (orderStr) {
            setTableOrderState({ orderStr });
          }
        }}
      />

      <Pagination
        variant="advanced"
        currentPage={paginationState?.currentPage}
        itemsPerPage={paginationState?.itemsPerPage}
        onPageChange={(currentPage, itemsPerPage) => {
          setPaginationState({ currentPage, itemsPerPage });
        }}
        itemsCount={expenseRecordsState?.count ?? 0}
      />
    </>
  );
};

export default ExpenseDisplayTable;
