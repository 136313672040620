import React, { useMemo, useState } from 'react';
import { SectionBlock } from '../../vehicle-overview/components';
import { Button, DetailsTable } from '@gsa/afp-component-library';
import { PropTypes } from 'prop-types';
import EditWeightAndCapacity from './edit-weight-and-capacity';
import { emdash } from 'components/common';

const WeightAndCapacity = ({ data, canEdit, onSave }) => {
  const [editWeightAndCapacity, setEditWeightAndCapacity] = useState(false);

  const LBS_KG = 'lbs';
  const IN_CM = 'in';

  const getData = (field, measurement) => {
    return field ? `${field} ${measurement}` : emdash;
  };

  const tableData = useMemo(() => {
    const rows = [
      ['GVWR', getData(data?.assetTechnical?.gvwr, LBS_KG)],
      ['Payload', getData(data?.assetTechnical?.payload, LBS_KG)],
      ['Wheelbase', getData(data?.assetTechnical?.wheelBase, IN_CM)],
      ['Cab to axle', getData(data?.assetTechnical?.cabToAxle, IN_CM)],
      [
        'Bumper to back of cab',
        getData(data?.assetTechnical?.bumperToBack, IN_CM),
      ],
    ];
    return rows;
  }, [data]);

  const saveWeightAndCapacity = (editState) => {
    const fieldsToUpdate = Object.keys(editState).map((key) => ({
      field: key,
      value: editState[key],
    }));
    onSave(fieldsToUpdate);
    setEditWeightAndCapacity(false);
  };

  return (
    <>
      <SectionBlock title="Weight & Capacity">
        <DetailsTable
          className="vehicle-overview-details-table"
          data={tableData}
        />
        {canEdit && (
          <Button
            data-testid={`weight-and-capacity-edit`}
            onClick={() => setEditWeightAndCapacity(true)}
            variant="outline"
            className="bg-white margin-top-2"
            aria-label={`edit weight and capacity ${data?.id}`}
            label="Edit"
          />
        )}
      </SectionBlock>
      {editWeightAndCapacity && (
        <EditWeightAndCapacity
          vehicle={data}
          onClose={() => setEditWeightAndCapacity(false)}
          onSave={saveWeightAndCapacity}
        />
      )}
    </>
  );
};

export default WeightAndCapacity;

WeightAndCapacity.propTypes = {
  data: PropTypes.shape({
    assetTechnical: PropTypes.shape({
      gvwr: PropTypes.string,
      payload: PropTypes.string,
      wheelbase: PropTypes.string,
      cabToAxle: PropTypes.string,
      bumperToBack: PropTypes.string,
    }),
  }),
  onSave: PropTypes.func,
};
