import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import { isFeatureEnabled } from 'utilities/feature-toggle';
import {
  DetailsTable,
  Button,
  Spinner,
  useModal,
} from '@gsa/afp-component-library';
import PropTypes from 'prop-types';
import moment from 'moment';
import { VehiclePropType } from 'utilities/types';
import {
  canUpdateGFVehicleAdmin,
  canViewGFVehicleBM,
} from 'utilities/authorization';
import { emdash } from 'components/common';
import { UPDATE_VEHICLE_FIELDS } from 'services/data-layer';
import useUser from 'utilities/use-user';
import useCanPerformActions from 'hooks/use-can-perform-actions';
import { purchaseTypeOptions } from 'utilities/consts';
import SectionBlock from './section-block';
import PurchaseInfoEdit from './purchase-info-edit';
import { useVehicle } from '../../../vehicle-context-provider';
import { formatUSCurrency, durationInMonths } from '../helpers/common';
import GFPurchaseInfoEdit from './gf-purchase-info-edit';

export const ageInMonths = (assetAcquisition) => {
  if (!assetAcquisition?.originalAcquisitionDate) {
    return null;
  }
  const duration = durationInMonths(assetAcquisition?.originalAcquisitionDate);
  const suffix = duration < 2 ? 'month' : 'months';
  return `${duration} ${suffix}`;
};

export const getDataByAbility = (ability, data = {}) => {
  const { assetAcquisition, assetReplacement } = data;
  const AOdata = [
    ['Order number', assetAcquisition?.purchaseOrderNumber || emdash],
    ['Standard item number', data?.standardItem?.standardItemNumber || emdash],
    [
      'Delivered date',
      assetAcquisition?.originalAcquisitionDate
        ? moment
            .utc(assetAcquisition?.originalAcquisitionDate)
            .format('MM/DD/YYYY')
        : emdash,
    ],
    [
      'Acquisition cost',
      formatUSCurrency(assetAcquisition?.acquisitionCost) || emdash,
    ],
    [
      'Projected replacement eligibility',
      assetReplacement?.eligibleReplacementDate
        ? moment
            .utc(assetReplacement.eligibleReplacementDate)
            .format('MM/DD/YYYY')
        : emdash,
    ],
    ['Vehicle age in months', ageInMonths(assetAcquisition) || emdash],
    [
      'Delivered miles',
      assetAcquisition?.deliveredMiles
        ?.toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',') || emdash,
    ],
    [
      'Replacement ordered',
      assetReplacement?.wasReplacementOrdered === true ? 'Yes' : 'No' || emdash,
    ],
  ];

  const GFInternalData = [
    [
      'Purchase type',
      purchaseTypeOptions.find(
        (option) => option.code === assetAcquisition?.purchaseType,
      )?.description || emdash,
    ],
    ['Standard item number', data?.standardItem?.standardItemNumber || emdash],
    ['Order number', assetAcquisition?.purchaseOrderNumber || emdash],
    ['Requisition number', assetAcquisition?.requisitionNumber || emdash],
    [
      'Delivered date',
      assetAcquisition?.originalAcquisitionDate
        ? moment
            .utc(assetAcquisition?.originalAcquisitionDate)
            .format('MM/DD/YYYY')
        : emdash,
    ],
    [
      'Acquisition cost',
      formatUSCurrency(assetAcquisition?.acquisitionCost) || emdash,
    ],
    [
      'Delivered miles',
      assetAcquisition?.deliveredMiles
        ?.toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',') || emdash,
    ],
    ['Consolidation number', assetAcquisition?.consolidationNumber || emdash],
    [
      'Consolidation date',
      assetAcquisition?.consolidationDate
        ? moment.utc(assetAcquisition?.consolidationDate).format('MM/DD/YYYY')
        : emdash,
    ],
    [
      'Projected minimum replacement date',
      assetReplacement?.eligibleReplacementDate
        ? moment
            .utc(assetReplacement.eligibleReplacementDate)
            .format('MM/DD/YYYY')
        : emdash,
    ],
    [
      'Replacement ordered',
      assetReplacement?.wasReplacementOrdered === true ? 'Yes' : 'No' || emdash,
    ],
  ];

  const GFExternalData = [
    ['Standard item number', data?.standardItem?.standardItemNumber || emdash],
    [
      'Replacement ordered',
      assetReplacement?.wasReplacementOrdered === true ? 'Yes' : 'No' || emdash,
    ],
  ];

  if (data?.ownershipTypeCode === 'AO') {
    return AOdata;
  }

  if (canViewGFVehicleBM(ability)) {
    return GFInternalData;
  }

  return GFExternalData;
};

const PurchaseInfo = ({ data: asset }) => {
  const [editPurchaseInfo, setEditPurchaseInfo] = useState(false);
  const { setSectionMsg, refetchVehicle } = useVehicle();

  const {
    isOpen: isGFModalOpen,
    openModal: openGFModal,
    closeModal: closeGFModal,
  } = useModal();

  const { isRole } = useUser();
  const hasFMVRSrole = isRole('FMVRSAdminRole');
  const hasCustFltManRole = isRole('CustFltMan');
  const ability = useAppAbility();
  const canPerformActions = useCanPerformActions();
  const isAoVehicle = canPerformActions.isAoAndAbleToRegister(asset);
  const isSold = asset?.itemInventoryStatusCode === 'SD';
  const isVehicleEditable = !isSold && isAoVehicle;
  const canEditSoldVehicle = (hasFMVRSrole || hasCustFltManRole) && isSold;

  const canEditGFVehicle =
    isFeatureEnabled('edit-gf-vehicle') &&
    asset?.ownershipTypeCode === 'GF' &&
    canUpdateGFVehicleAdmin(ability);

  const [updateVehicleField, { loading }] = useMutation(UPDATE_VEHICLE_FIELDS, {
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      refetchVehicle();
      setSectionMsg({
        type: 'success',
        message: 'Vehicle was successfully updated.',
      });
    },
    onError: ({ graphQLErrors }) => {
      const gqErrors = graphQLErrors.map((error) => {
        if (error?.extensions?.code.includes('Petroleum-Dedicated')) {
          return `${error?.message}: ${error?.extensions?.code}`;
        }
        if (error?.extensions?.code.includes('vehicle Acquisition')) {
          return `${error?.extensions?.code}`;
        }
        return `${error?.message}`;
      });
      setSectionMsg({
        type: 'error',
        message: `Error occurred while updating purchase information: ${gqErrors.join(
          ' ',
        )}`,
      });
    },
  });

  const savePurchaseInformation = (fields) => {
    if (fields?.length) {
      updateVehicleField({
        variables: {
          vin: asset?.id,
          fields,
        },
      });
      setEditPurchaseInfo(false);
    }
  };
  const handleGFPurchaseInformation = (formFields) => {
    const fields = [
      {
        field: 'purchaseInformation',
        values: {
          assetAcquisitionInput: {
            purchaseType: formFields.purchaseType,
            acquisitionCost: formFields.acquisitionCost,
            deliveredMiles: formFields.deliveredMiles,
            originalAcquisitionDate: moment(formFields.originalAcquisitionDate)
              .format('YYYY-MM-DD')
              .concat('T00:00:00.000Z'),
          },
        },
      },
    ];

    updateVehicleField({
      variables: {
        vin: asset?.id,
        fields,
      },
    });

    closeGFModal();
  };
  return (
    <>
      <SectionBlock title="Purchase information">
        <DetailsTable
          className="vehicle-overview-details-table"
          data={getDataByAbility(ability, asset, canViewGFVehicleBM)}
        />
        <div className="margin-top-2">
          {isVehicleEditable && (
            <Button
              variant="outline"
              label="Edit"
              onClick={() => setEditPurchaseInfo(true)}
            />
          )}
          {isAoVehicle && canEditSoldVehicle && (
            <Button
              variant="outline"
              label="Edit"
              onClick={() => setEditPurchaseInfo(true)}
            />
          )}

          {canEditGFVehicle && (
            <Button
              variant="outline"
              data-testid="gf-edit-btn"
              label="Edit"
              onClick={() => openGFModal()}
            />
          )}

          <GFPurchaseInfoEdit
            vehicle={asset}
            onClose={() => {
              closeGFModal();
            }}
            onSave={handleGFPurchaseInformation}
            isGFModalOpen={isGFModalOpen}
          />
          {editPurchaseInfo && (
            <PurchaseInfoEdit
              onSave={savePurchaseInformation}
              onClose={() => setEditPurchaseInfo(false)}
              vehicle={asset}
            />
          )}
        </div>
      </SectionBlock>
      {loading && (
        <Spinner aria-busy="true" className="loading_backdrop" size="large" />
      )}
    </>
  );
};

PurchaseInfo.propTypes = {
  data: PropTypes.shape(VehiclePropType).isRequired,
};

export default PurchaseInfo;
