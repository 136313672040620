import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Alert, Button } from '@gsa/afp-component-library';
import { tagExpStrToDate, formatTagExpDate } from '../../../utilities/common';
import { VehiclePropType } from '../../../utilities/types';
import VehicleActions from './vehicle-actions';

export const SoldWarning = ({ licensePlate }) => {
  return (
    <Alert type="warning" className="margin-top-4 margin-bottom-2">
      <span data-testid="sold-status-alert">
        When this vehicle is updated to{' '}
        <span className="text-bold">disposed</span> status:
        <ul>
          <li>
            The attached license plate{' '}
            <span className="text-bold">{licensePlate}</span> will be removed
            from the vehicle registration
          </li>
          <li>
            The vehicle will become{' '}
            <span className="text-bold">unregistered</span>
          </li>
          <li>
            You will need to submit license plate{' '}
            <span className="text-bold">{licensePlate}</span> for destruction
          </li>
          <li>
            Any status updates will not be reflected on the FAST reports until
            the next day
          </li>
        </ul>
      </span>
    </Alert>
  );
};
SoldWarning.propTypes = {
  licensePlate: PropTypes.string.isRequired,
};

export const MissingWarning = ({ licensePlate }) => {
  return (
    <Alert type="warning" className="margin-top-4 margin-bottom-2">
      <span data-testid="missing-status-alert">
        When this vehicle is updated to{' '}
        <span className="text-bold">missing/stolen</span> status:
        <ul>
          <li>
            The attached license plate{' '}
            <span className="text-bold">{licensePlate}</span> will be removed
            from the vehicle registration
          </li>
          <li>
            The vehicle will become{' '}
            <span className="text-bold">unregistered</span>
          </li>
          <li>
            License plate <span className="text-bold">{licensePlate}</span> will
            be updated to the status of missing
          </li>
          <li>
            Any status updates will not be reflected on the FAST reports until
            the next day
          </li>
        </ul>
      </span>
    </Alert>
  );
};
MissingWarning.propTypes = {
  licensePlate: PropTypes.string.isRequired,
};

export const StatusWarningNoPlate = ({ statusName }) => (
  <Alert
    type="warning"
    slim
    className="margin-top-4 margin-bottom-2"
    data-testid={`status-warning-${statusName}`}
  >
    <span>
      The vehicle will be updated to{' '}
      <span className="text-bold">{statusName}</span> status on clicking save
      and close. Any status updates will not be reflected on the FAST reports
      until the next day
    </span>
  </Alert>
);
StatusWarningNoPlate.propTypes = {
  statusName: PropTypes.string.isRequired,
};

const incompleteEditSuggestion = (
  <p>Please edit vehicle details in the overview to update the empty fields.</p>
);

export const IncompleteWarning = ({ ownership }) => {
  if (ownership !== 'AO' && ownership !== 'GF') return null;
  return (
    <Alert
      type="error"
      heading="Critical information is missing on this vehicle registration"
      data-testid="incomplete-registration-alert"
      className="margin-bottom-1"
    >
      Certain vehicle fields (VIN, Make, Model, Year, Color, Fuel type, FAST
      reportable, Primary & Secondary POC) are mandatory data elements used to
      produce a Vehicle Identification Card (VIC). These are needed to ensure
      that the vehicle is properly registered in the Federal Motor Vehicle
      Registration System (FMVRS) when queried through the National Law
      Enforcement Telecommunication System (NLETS).
      {ownership === 'AO' && incompleteEditSuggestion}
    </Alert>
  );
};
IncompleteWarning.propTypes = {
  ownership: PropTypes.string,
};
IncompleteWarning.defaultProps = {
  ownership: '',
};

export const NewLicensePlateAlert = ({ vehicle, swapTag, swapping }) => {
  if (vehicle?.ownershipTypeCode !== 'AO') return null;
  const { id: vin, tag, tagNew, tagNumber } = vehicle;
  const expDate = tag?.expirationDate;
  const newExpDate = tagNew?.expirationDate;

  const tagExpDate = tagExpStrToDate(expDate);
  const newTagExpDate = tagExpStrToDate(newExpDate);

  if (
    !newTagExpDate ||
    (!tagExpDate && moment(newTagExpDate).diff(new Date()) <= 0) ||
    (tagExpDate && moment(newTagExpDate).diff(tagExpDate) <= 0)
  )
    return null;

  return (
    <Alert
      type="info"
      className="margin-top-2 margin-bottom-2"
      heading="New license plate available"
    >
      License plate <strong>{tagNumber}</strong> with the newer expiration date{' '}
      <strong>{formatTagExpDate(newExpDate)}</strong> is ready to be attached to
      this vehicle.
      <div className="margin-top-1">
        <Button
          data-testid="swap-tag-button"
          disabled={swapping}
          type="button"
          variant="primary"
          onClick={() => {
            swapTag({
              variables: {
                tagNumber,
                vin,
                tagExpirationDate: Number(expDate || '0'),
                newTagExpirationDate: Number(newExpDate),
              },
            });
          }}
          label="Update vehicle registration with new license plate"
        />
      </div>
    </Alert>
  );
};
NewLicensePlateAlert.propTypes = {
  vehicle: PropTypes.shape(VehiclePropType),
  swapTag: PropTypes.func.isRequired,
  swapping: PropTypes.bool,
};
NewLicensePlateAlert.defaultProps = {
  vehicle: null,
  swapping: false,
};

export const LicensePlateRemovedAlert = ({ ownershipTypeCode, tagRemoved }) => {
  if (ownershipTypeCode !== 'AO' || !tagRemoved) return null;

  const getTag = () => (
    <>
      <Link
        to={`/license-plate/${tagRemoved.tagNumber}?tagExpirationDate=${
          tagRemoved.expirationDate || ''
        }`}
      >
        <strong>{tagRemoved.tagNumber}</strong>
      </Link>{' '}
      with expiration date of{' '}
      <strong>{formatTagExpDate(tagRemoved.expirationDate)}</strong>
    </>
  );

  return (
    <Alert
      type="warning"
      className="margin-top-2 margin-bottom-2"
      heading="Previous license plate has been removed from the vehicle"
    >
      License plate {getTag()} has been removed from the vehicle. The status of{' '}
      <strong>unattached</strong> has been applied to both A and B plates (if a
      2-plate set).
      <div className="padding-y-2">
        <strong>What to do next:</strong>
      </div>
      Please submit license plate {getTag()} for destruction.
    </Alert>
  );
};
LicensePlateRemovedAlert.propTypes = {
  ownershipTypeCode: PropTypes.string.isRequired,
  tagRemoved: PropTypes.shape({
    tagNumber: PropTypes.string,
    expirationDate: PropTypes.number,
  }),
};
LicensePlateRemovedAlert.defaultProps = {
  tagRemoved: null,
};

export const getNewLicensePlateMessage = () => (
  <>
    Vehicle registration and license plate information were successfully
    updated.{' '}
    <VehicleActions
      downloadRegLinkOnly
      linkText="Download updated vehicle registration card"
    />
  </>
);
