import React, { useEffect } from 'react';
import { Button, Modal } from '@gsa/afp-component-library';
import { useOrder } from '../context';

export default function OrderTagLostStolenModal() {
  const {
    isLostStolenTagModalOpen,
    closeLostStolenTagModal,
    reportTag,
    tag,
    setTag,
    loadingOrderTagLostStolen,
  } = useOrder();

  useEffect(() => {
    if (!isLostStolenTagModalOpen) {
      closeLostStolenTagModal();
      setTag(null);
    }
  }, [isLostStolenTagModalOpen]);

  if (!isLostStolenTagModalOpen) {
    return null;
  }

  const reportTagLostStolen = () => {
    if (tag) {
      const variables = {
        tagNumber: tag.tagNumber,
        tagExpirationDate: tag.tagExpirationDate,
      };
      reportTag({
        variables,
      });
    }
  };

  return (
    <div className="afp-modal-wrapper">
      <div className="afp-modal-overlay">
        <Modal
          variant="large"
          title={<h2>Report license plate lost or stolen</h2>}
          onClose={closeLostStolenTagModal}
          actions={
            <>
              <Button
                data-testid="cancel-lost-stolen"
                variant="unstyled"
                className="margin-right-2"
                onClick={closeLostStolenTagModal}
                label="Cancel"
              />
              <Button
                data-testid="report-lost-stolen-plate"
                variant="primary"
                onClick={reportTagLostStolen}
                disabled={loadingOrderTagLostStolen}
                label={`Change status to "missing"`}
              />
            </>
          }
        >
          <p>
            You are about to report license plate{' '}
            <span className="text-bold" data-testid="lost-stolen-tag">
              {tag?.tagNumber}
            </span>{' '}
            as lost or stolen and the status of{' '}
            <span className="text-bold">missing</span> will be applied to both A
            and B plates.
          </p>
        </Modal>
      </div>
    </div>
  );
}
