import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import { GET_FC_ORDERS_BY_PARTIAL_TAG_NUMBER } from 'services/data-layer';
import { useLazyQuery } from '@apollo/client';

const FCReplacementFilterContext = createContext({});
const useFCReplacementFilter = () => useContext(FCReplacementFilterContext);

const initialState = {
  error: {},
  filters: [{ operator: '$and', conditions: [] }],
  filterStructure: {},
};

const actions = {
  setFilters: 'SET_FILTERS',
  setError: 'SET_ERROR',
  setStructure: 'SET_STRUCTURE',
};

const extractErrorName = (err) => err.name || 'Unknown Error';

const mapTypeaheadData = (field, values) => {
  return {
    field,
    values,
  };
};

const fcReplacementFilterReducer = (state, { action, payload }) => {
  const mergeState = (value, field) => {
    if (!field) {
      return { ...state, error: initialState.error, ...value };
    }
    const merged = { ...state, error: initialState.error };
    merged[field] = value || initialState[field];
    return merged;
  };

  const isDateRangeSelected = (filterConditions) =>
    filterConditions?.some((c) => c.key === 'createdAt');

  switch (action) {
    case actions.setFilters: {
      let filterConditions = payload.filters || [];
      const isDateSelected = isDateRangeSelected(filterConditions);
      if (isDateSelected) {
        filterConditions = filterConditions.map((condition) => {
          if (condition.key === 'createdAt') {
            const { beginning, end } = condition.value;
            const startDate = new Date(beginning);
            const endDate = end ? new Date(end) : new Date();
            startDate.setHours(0, 0, 0, 0);
            endDate.setHours(23, 59, 59, 0);
            return {
              ...condition,
              value: [startDate.toISOString(), endDate.toISOString()],
            };
          }
          return condition;
        });
      }

      return mergeState(
        {
          operator: '$and',
          conditions: filterConditions,
        },
        'filters',
      );
    }
    case actions.setTagsByPartialTagNumber: {
      return mergeState({
        tagsByPartialTagNumber: payload,
        typeaheadData: mapTypeaheadData('$tag_number$', [
          ...new Set(payload.map((c) => c?.tagNumber)),
        ]),
      });
    }
    case actions.setStructure: {
      return mergeState(
        {
          [payload.context]: payload.filterStructure,
        },
        'filterStructure',
      );
    }
    case actions.setError: {
      return mergeState(extractErrorName(payload), 'error');
    }
    default:
      throw new Error('Invalid user filter action');
  }
};

const FCReplacementFilterProvider = ({ children }) => {
  const [state, setDispatch] = useReducer(
    fcReplacementFilterReducer,
    initialState,
    () => initialState,
  );

  const dispatch = (action, payload) => setDispatch({ action, payload });

  const dispatchError = (error) => dispatch(actions.setError, error);

  const dispatchFilters = (conditions) =>
    dispatch(actions.setFilters, conditions);

  const dispatchFilterStructure = (structure) =>
    dispatch(actions.setStructure, structure);

  const [getFcReplacementOrdersByPartialTag] = useLazyQuery(
    GET_FC_ORDERS_BY_PARTIAL_TAG_NUMBER,
    {
      onError: dispatchError,
      onCompleted: (data) => {
        dispatch(
          actions.setTagsByPartialTagNumber,
          data?.getFcReplacementOrdersByPartialTag || [],
        );
      },
    },
  );

  const getFcOrdersByPartialTagNumber = (partialTagNumber) => {
    getFcReplacementOrdersByPartialTag({
      variables: { partialTagNumber },
    });
  };

  return (
    <FCReplacementFilterContext.Provider
      value={{
        ...state,
        getFcOrdersByPartialTagNumber,
        dispatch,
        setFilters: dispatchFilters,
        setStructure: dispatchFilterStructure,
      }}
    >
      {children}
    </FCReplacementFilterContext.Provider>
  );
};

FCReplacementFilterProvider.defaultProps = {
  children: undefined,
};

FCReplacementFilterProvider.propTypes = {
  children: PropTypes.node,
};

export { FCReplacementFilterProvider as default, useFCReplacementFilter };
