import React, { useState, useCallback, useEffect } from 'react';
import moment from 'moment';
import { AFPTable, EmptyState, Spinner } from '@gsa/afp-component-library';
import { useLazyQuery } from '@apollo/client';
import usePagination from '../../../utilities/use-pagination';
import { formatCurrency } from '../../../utilities/common';
import { GET_FLEET_CARD_TRANSACTIONS } from '../../../services/data-layer';
import { useExpenseFilter, useExpensePage } from '../providers';

const ExpenseTable = () => {
  const [tableData, setTableData] = useState([]);
  const [order, setOrder] = useState(null);
  const [Pagination, { offset, limit, setItemsCount, resetPage }] =
    usePagination();

  const { filters } = useExpenseFilter();
  const { setExpenseCount, setBannerMsg } = useExpensePage();

  // Query to fetch fleet card transactions
  const [getExpenseData, { loading: expenseLoading }] = useLazyQuery(
    GET_FLEET_CARD_TRANSACTIONS,
    {
      onError: () =>
        setBannerMsg({
          type: 'error',
          message: 'Error occurred while retrieving expense data.',
        }),
      onCompleted: ({ getFleetCardTransactions }) => {
        setBannerMsg(null);
        setTableData(getFleetCardTransactions.rows);
        setItemsCount(getFleetCardTransactions.count);
        setExpenseCount(getFleetCardTransactions.count);
      },
      fetchPolicy: 'network-only',
    },
  );

  const fetchExpenseData = () => {
    const variables = {
      limit,
      offset,
      order,
      filters,
    };
    getExpenseData({ variables });
  };

  const isAgencySelected = () =>
    filters?.conditions?.some((c) => c.key === '$vehicle.agency_code$');

  const isDateRangeSelected = () =>
    filters?.conditions?.some((c) => c.key === 'transaction_date');

  useEffect(() => {
    if (isAgencySelected() && isDateRangeSelected()) {
      fetchExpenseData();
    } else {
      setTableData([]);
      setItemsCount({ itemsCount: 0 });
    }
  }, [filters, order, offset, limit]);

  const onSort = (sort) => {
    if (sort) {
      const segs = sort.split(' ');
      const col = segs[0].replace(/`/g, '');
      resetPage();
      setOrder([[...col.split('.'), segs[1]]]);
    }
  };

  const getUSDateStr = (isoDateStr) =>
    isoDateStr ? moment(isoDateStr).utc().format('MM/DD/YYYY') : '–';

  const getCell = ({ value }) => value || '–';

  const tableHeaderData = [
    {
      Header: 'Transaction date',
      accessor: 'transactionDate',
      headerClassName: 'width-card-lg',
      sortable: true,
      Cell: ({ value }) => getUSDateStr(value),
    },
    {
      Header: 'VIN',
      accessor: 'vehicle.vin',
      headerClassName: 'width-card-lg',
      sortable: true,
      // eslint-disable-next-line
      Cell: ({ value }) => (
        <a
          href={`${window.AFP_CONFIG.appURLs.vms}/vehicles/${encodeURIComponent(
            value,
          )}/overview`}
          aria-label={`show vehicle information for vin number ${value} in a new window`}
        >
          {value}
        </a>
      ),
    },
    {
      Header: 'License plate',
      accessor: 'vehicle.tagNumber',
      headerClassName: 'width-card-lg',
      sortable: true,
      // eslint-disable-next-line
      Cell: ({ value }) => (
        <a
          href={`${
            window.AFP_CONFIG.appURLs.vms
          }/license-plate/${encodeURIComponent(value)}`}
          aria-label={`show vehicle information for vin number ${value} in a new window`}
        >
          {value}
        </a>
      ),
    },
    {
      Header: 'Product description',
      accessor: 'fleetCardProduct.productDescription',
      headerClassName: 'width-card-lg',
      sortable: true,
      Cell: ({ row }) =>
        row?.original?.fleetCardProductOnline?.productDescription ||
        row?.original?.fleetCardProduct?.productDescription,
    },
    {
      Header: 'Total cost',
      accessor: 'grossCost',
      headerClassName: 'width-card-lg',
      sortable: true,
      Cell: ({ value }) => formatCurrency(parseFloat(value)),
    },
  ];

  const DetailRow = ({ label, value, className }) => (
    <div className="expanded-row display-flex flex-justify padding-y-1 border-bottom border-width-2px border-primary-lighter">
      <div className="expanded-title">
        <strong>{label}</strong>
      </div>

      <div className={`expanded-value ${className}`}>{value}</div>
    </div>
  );

  const renderRowSubComponent = useCallback(({ row: { original = {} } }) => {
    return (
      <div className="grid-row grid-gap grid-col-12 padding-left-2 ">
        <div className="grid-col-6">
          <DetailRow
            label="Last 4 digits of card number"
            value={original?.ccn4}
          />
        </div>
        <div className="grid-col-6">
          <DetailRow label="Year" value={original?.vehicle?.modelYear} />
          <DetailRow
            label="Make"
            value={original?.vehicle?.vehicleMake?.makeName || '-'}
          />
          <DetailRow label="Model" value={original?.vehicle?.modelCode} />
        </div>
      </div>
    );
  }, []);

  return (
    <>
      <AFPTable
        columns={tableHeaderData}
        renderRowSubComponent={renderRowSubComponent}
        data={expenseLoading ? [] : tableData || []}
        testId="afp-recall-table-test-id"
        fullWidth
        bordered={false}
        defaultSort="campaign asc"
        expandable
        scrollable={false}
        stacked
        onSort={onSort}
      />
      {expenseLoading && <Spinner className="padding-y-9" />}
      {!expenseLoading &&
        (tableData.length ? (
          <Pagination loading={expenseLoading} />
        ) : (
          <div className="bg-gray-3 padding-y-5">
            <div className="text-center padding-y-4">
              <EmptyState
                alt="Image not available"
                hasBackground
                bottomText={
                  <>
                    <p className="text-bold">No expense records</p>
                    <p>
                      No expense records found. Agency and transaction dates
                      selection are required.
                    </p>
                  </>
                }
              />
            </div>
          </div>
        ))}
    </>
  );
};

export default ExpenseTable;
