import React, { useState, useEffect } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import {
  TextInput,
  SelectDropdown,
  CountrySelect,
} from '@gsa/afp-component-library';
import { STATES_OCONUS_TERRITORIES } from 'utilities/consts';
import { CDD_FORM_FIELDS } from './consts';

const generateStatesOptions = () => {
  // Stopgap solutions for States list with value as -Select-
  // needs to be removed after a site-wide cleanup for States options
  const [, ...optionsNoPlaceholder] = STATES_OCONUS_TERRITORIES;
  const options = optionsNoPlaceholder.map((stateOption) => ({
    value: stateOption,
    label: stateOption,
  }));
  return [{ label: 'Select', value: '' }, ...options];
};

const GarageAddressForm = () => {
  const {
    formState: { errors },
    control,
    watch,
  } = useFormContext();

  const watchGarageDomestic = watch(CDD_FORM_FIELDS.garageDomestic.name);
  const watchCountry = watch(CDD_FORM_FIELDS.garageCountryCode.name);

  const [isGarageDomestic, setIsGarageDomestic] = useState();
  const [statesOptions] = useState(generateStatesOptions());

  useEffect(() => {
    setIsGarageDomestic(
      watchGarageDomestic === 'true' ||
        watchGarageDomestic === true ||
        watchCountry === 'US',
    );
  }, [watchGarageDomestic, watchCountry]);

  return (
    <div data-testid="cdd-garage-address-form">
      <Controller
        name={CDD_FORM_FIELDS.garageCountryCode.name}
        control={control}
        render={({ field, ref }) => {
          return (
            <>
              <CountrySelect
                {...field}
                ref={ref}
                name={CDD_FORM_FIELDS.garageCountryCode.name}
                id={CDD_FORM_FIELDS.garageCountryCode.id}
                label={<span className="text-bold">Country</span>}
                placeholder="Select"
                withUsTerritories={false}
                disabled={
                  watchGarageDomestic === 'true' || watchGarageDomestic === true
                }
                errorMessage={
                  errors && errors[CDD_FORM_FIELDS.garageCountryCode.name]
                    ? errors[CDD_FORM_FIELDS.garageCountryCode.name].message
                    : null
                }
                aria-invalid={
                  errors && errors[CDD_FORM_FIELDS.garageCountryCode.name]
                    ? 'true'
                    : 'false'
                }
              />
            </>
          );
        }}
      />
      <Controller
        name={CDD_FORM_FIELDS.garageAddress1.name}
        control={control}
        render={({ field, ref }) => {
          return (
            <TextInput
              {...field}
              ref={ref}
              type="text"
              label={<span className="text-bold">Address line 1</span>}
              name={CDD_FORM_FIELDS.garageAddress1.name}
              id={CDD_FORM_FIELDS.garageAddress1.id}
              errorMessage={
                errors && errors[CDD_FORM_FIELDS.garageAddress1.name]
                  ? errors[CDD_FORM_FIELDS.garageAddress1.name].message
                  : null
              }
              aria-invalid={
                errors && errors[CDD_FORM_FIELDS.garageAddress1.name]
                  ? 'true'
                  : 'false'
              }
            />
          );
        }}
      />
      <Controller
        name={CDD_FORM_FIELDS.garageAddress2.name}
        control={control}
        render={({ field, ref }) => {
          return (
            <TextInput
              {...field}
              ref={ref}
              type="text"
              label={<span className="text-bold">Address line 2</span>}
              name={CDD_FORM_FIELDS.garageAddress2.name}
              id={CDD_FORM_FIELDS.garageAddress2.id}
              errorMessage={
                errors && errors[CDD_FORM_FIELDS.garageAddress2.name]
                  ? errors[CDD_FORM_FIELDS.garageAddress2.name].message
                  : null
              }
              aria-invalid={
                errors && errors[CDD_FORM_FIELDS.garageAddress2.name]
                  ? 'true'
                  : 'false'
              }
            />
          );
        }}
      />
      <Controller
        name={CDD_FORM_FIELDS.garageAddress3.name}
        control={control}
        render={({ field, ref }) => {
          return (
            <TextInput
              {...field}
              ref={ref}
              type="text"
              label={<span className="text-bold">Address line 3</span>}
              name={CDD_FORM_FIELDS.garageAddress3.name}
              id={CDD_FORM_FIELDS.garageAddress3.id}
              errorMessage={
                errors && errors[CDD_FORM_FIELDS.garageAddress3.name]
                  ? errors[CDD_FORM_FIELDS.garageAddress3.name].message
                  : null
              }
              aria-invalid={
                errors && errors[CDD_FORM_FIELDS.garageAddress3.name]
                  ? 'true'
                  : 'false'
              }
            />
          );
        }}
      />
      <div className="grid-row grid-gap">
        <div className="grid-col-6">
          <Controller
            name={CDD_FORM_FIELDS.garageCity.name}
            control={control}
            render={({ field, ref }) => {
              return (
                <TextInput
                  {...field}
                  ref={ref}
                  type="text"
                  label={
                    <span className="text-bold">
                      {isGarageDomestic ? 'City' : 'City/Town'}
                    </span>
                  }
                  name={CDD_FORM_FIELDS.garageCity.name}
                  id={isGarageDomestic ? 'city' : 'cityTown'}
                  errorMessage={
                    errors && errors[CDD_FORM_FIELDS.garageCity.name]
                      ? errors[CDD_FORM_FIELDS.garageCity.name].message
                      : null
                  }
                  aria-invalid={
                    errors && errors[CDD_FORM_FIELDS.garageCity.name]
                      ? 'true'
                      : 'false'
                  }
                />
              );
            }}
          />
        </div>
        <div className="grid-col-6">
          <Controller
            name={CDD_FORM_FIELDS.garageStateCode.name}
            control={control}
            render={({ field, ref }) => {
              const errorMessage =
                errors && errors[CDD_FORM_FIELDS.garageStateCode.name]
                  ? errors[CDD_FORM_FIELDS.garageStateCode.name].message
                  : null;
              const ariaInvalid =
                errors && errors[CDD_FORM_FIELDS.garageStateCode.name]
                  ? 'true'
                  : 'false';
              return (
                <>
                  {isGarageDomestic ? (
                    <SelectDropdown
                      {...field}
                      ref={ref}
                      label={<span className="text-bold">State</span>}
                      name={CDD_FORM_FIELDS.garageStateCode.name}
                      id="state"
                      options={statesOptions}
                      errorMessage={errorMessage}
                      aria-invalid={ariaInvalid}
                    />
                  ) : (
                    <TextInput
                      {...field}
                      ref={ref}
                      type="text"
                      label={
                        <span className="text-bold">State/Province/Region</span>
                      }
                      name={CDD_FORM_FIELDS.garageStateCode.name}
                      id="stateProvinceRegion"
                      errorMessage={errorMessage}
                      aria-invalid={ariaInvalid}
                    />
                  )}
                </>
              );
            }}
          />
        </div>
      </div>
      <div className="grid-row grid-gap">
        <div className="grid-col-6">
          <Controller
            name={CDD_FORM_FIELDS.garagePostalCode.name}
            control={control}
            render={({ field, ref }) => {
              return (
                <TextInput
                  {...field}
                  ref={ref}
                  type="text"
                  label={
                    <span className="text-bold">
                      {isGarageDomestic ? 'Zip code' : 'Postal code'}
                    </span>
                  }
                  maxLength={isGarageDomestic ? '11' : '15'}
                  name={CDD_FORM_FIELDS.garagePostalCode.name}
                  id={isGarageDomestic ? 'zipCode' : 'postalCode'}
                  onChange={(val) => {
                    field.onChange(val.target.value.replace(/[^0-9]/g, ''));
                  }}
                  errorMessage={
                    errors && errors[CDD_FORM_FIELDS.garagePostalCode.name]
                      ? errors[CDD_FORM_FIELDS.garagePostalCode.name].message
                      : null
                  }
                  aria-invalid={
                    errors && errors[CDD_FORM_FIELDS.garagePostalCode.name]
                      ? 'true'
                      : 'false'
                  }
                />
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default GarageAddressForm;
