import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import {
  GET_POOLS_BY_EMAIL,
  GET_RESERVATIONS_BY_CONFIRMATION_NUMBER,
  GET_MEMBERS_BY_EMAIL,
} from '../motor-pool.gql';

const MotorPoolFilterContext = createContext({});
const useMotorPoolFilter = () => useContext(MotorPoolFilterContext);

const initialState = {
  error: {},
  filters: [],
  motorPoolConfirmationNumbers: [],
  motorPoolEmails: [],
  memberEmails: [],
  typeaheadData: { field: '', values: [] },
  filterStructure: {
    vehiclesListing: [],
    reservationsListing: [],
    membersListing: [],
  },
};

const actions = {
  setTypeaheadData: 'SET_TYPEAHEAD_DATA',
  setMotorPoolConfirmationNumbers: 'SET_MP_CONFIRMATION_NUMBERS',
  setMotorPoolEmails: 'SET_MOTOR_POOL_EMAILS',
  setMemberEmails: 'SET_MEMBER_EMAILS',
  setFilters: 'SET_FILTERS',
  setError: 'SET_ERROR',
  setStructure: 'SET_STRUCTURE',
};

const extractErrorName = (err) => err.name || 'Unknown Error';

const mapTypeaheadData = (field, values) => {
  return {
    field,
    values,
  };
};

const motorPoolFilterReducer = (state, { action, payload }) => {
  const mergeState = (value, field) => {
    if (!field) {
      return { ...state, error: initialState.error, ...value };
    }
    const merged = { ...state, error: initialState.error };
    merged[field] = value || initialState[field];
    return merged;
  };

  switch (action) {
    case actions.setMotorPoolConfirmationNumbers: {
      return mergeState({
        motorPoolConfirmationNumbers: payload,
        typeaheadData: mapTypeaheadData(
          'confirmationNumber',
          payload.map((c) => c.reservationNumber),
        ),
      });
    }
    case actions.setMotorPoolEmails: {
      return mergeState({
        motorPoolEmails: payload,
        typeaheadData: mapTypeaheadData(
          'email',
          payload.map((c) => c.email),
        ),
      });
    }
    case actions.setMemberEmails: {
      return mergeState({
        memberEmails: payload,
        typeaheadData: mapTypeaheadData(
          '$member.email$',
          payload.map((c) => c.email),
        ),
      });
    }
    case actions.setFilters: {
      return mergeState(
        {
          [payload.context]: {
            operator: '$and',
            conditions: payload.filters || [],
          },
        },
        'filters',
      );
    }
    case actions.setStructure: {
      return mergeState(
        {
          [payload.context]: payload.filterStructure,
        },
        'filterStructure',
      );
    }
    case actions.setError: {
      return mergeState(extractErrorName(payload), 'error');
    }
    default:
      throw new Error('Invalid user filter action');
  }
};

const MotorPoolFilterProvider = ({ children }) => {
  const [state, setDispatch] = useReducer(
    motorPoolFilterReducer,
    initialState,
    () => initialState,
  );

  const dispatch = (action, payload) => setDispatch({ action, payload });
  const dispatchError = (error) => dispatch(actions.setError, error);

  const dispatchFilters = (conditions) =>
    dispatch(actions.setFilters, conditions);

  const dispatchFilterStructure = (structure) =>
    dispatch(actions.setStructure, structure);

  const [getReservationsByConfirmationNumber] = useLazyQuery(
    GET_RESERVATIONS_BY_CONFIRMATION_NUMBER,
    {
      onError: dispatchError,
      onCompleted: (data) => {
        dispatch(
          actions.setMotorPoolConfirmationNumbers,
          data?.getReservationsByConfirmationNumber || [],
        );
      },
    },
  );

  const [getPoolsByEmail] = useLazyQuery(GET_POOLS_BY_EMAIL, {
    onError: dispatchError,
    onCompleted: (data) => {
      dispatch(actions.setMotorPoolEmails, data?.getPoolsByEmail || []);
    },
  });

  const [getMemberEmails] = useLazyQuery(GET_MEMBERS_BY_EMAIL, {
    onError: dispatchError,
    onCompleted: ({ getMotorPoolMembers }) => {
      if (getMotorPoolMembers && getMotorPoolMembers?.rows?.length > 0) {
        const { rows } = getMotorPoolMembers;

        const emails = rows.map((r) => {
          return {
            email: r.member.email,
          };
        });
        dispatch(actions.setMemberEmails, emails);
      } else {
        dispatch(actions.setMemberEmails, []);
      }
    },
  });

  return (
    <MotorPoolFilterContext.Provider
      value={{
        ...state,
        dispatch,
        getReservationsByConfirmationNumber,
        getPoolsByEmail,
        getMemberEmails,
        setFilters: dispatchFilters,
        setStructure: dispatchFilterStructure,
      }}
    >
      {children}
    </MotorPoolFilterContext.Provider>
  );
};

MotorPoolFilterProvider.defaultProps = {
  children: undefined,
};

MotorPoolFilterProvider.propTypes = {
  children: PropTypes.node,
};

export { MotorPoolFilterProvider as default, useMotorPoolFilter };
