// eslint-disable-next-line react/prop-types
import React, { useEffect, useMemo, useState } from 'react';
import {
  AFPTable,
  EmptyState,
  Pagination,
  Spinner,
  Tooltip,
  Icon,
  Tag,
} from '@gsa/afp-component-library';
import { Link } from 'react-router-dom';
import { emdash } from 'components/common';
import { isEqual } from 'lodash';
import useUser from 'utilities/use-user';
import { useRecallCampaignsFilter } from './filters/filter-provider';
import { useRecallCampaigns } from './recall-campaigns-provider';

const tableRef = React.createRef();
const initialPaginationState = {
  limit: 10,
  offset: 0,
  currentPage: 1,
  isReset: false,
};
const initialOrderState = 'recallCode ASC';

const RecallCampaignsTable = () => {
  const [paginationState, setPaginationState] = useState(
    initialPaginationState,
  );
  const [order, setOrder] = useState(initialOrderState);
  const [requiredFilterPerRole, setRequiredFilterPerRole] = useState('');
  const { isRole } = useUser();

  useEffect(() => {
    if (isRole('SiteAdmin')) {
      setRequiredFilterPerRole('Ownership Type');
    } else if (isRole('CustFltMan')) {
      setRequiredFilterPerRole('Agency');
    } else if (isRole('FSR')) {
      setRequiredFilterPerRole('Email');
    } else if (isRole('FMCManager')) {
      setRequiredFilterPerRole('Zone');
    }
  }, []);

  const {
    campaignList,
    getRecallCampaigns,
    recallCampaignsLoading,
    dispatchAction,
  } = useRecallCampaigns();

  const { filters } = useRecallCampaignsFilter();

  const getModifiedFilters = (filters) => {
    let updatedFilters = filters;
    const conditions = filters.conditions;
    if (conditions.length > 0) {
      if (
        conditions.some(
          (filterVal) =>
            filterVal.key ===
            '$assetRecalls.vehicle.customer.legacy_customer_number$',
        )
      ) {
        const customerNumberIndex = _.findIndex(conditions, {
          key: '$assetRecalls.vehicle.customer.legacy_customer_number$',
        });
        const updatedConditions = conditions.filter(
          (condition) =>
            condition.key !==
            '$assetRecalls.vehicle.customer.legacy_customer_number$',
        );

        updatedFilters = [
          {
            operator: '$and',
            conditions: updatedConditions,
          },
          {
            operator: '$or',
            conditions: [
              {
                operator: '$exact',
                key: '$assetRecalls.vehicle.customer.legacy_customer_number$',
                value: conditions[customerNumberIndex].value,
              },
              {
                operator: '$exact',
                key: '$assetRecalls.vehicle.customer.customer_account_id$',
                value: conditions[customerNumberIndex].value,
              },
            ],
          },
        ];
      }
    }
    return updatedFilters;
  };

  const getData = () => {
    const modifiedFilters = getModifiedFilters(filters);
    getRecallCampaigns({
      variables: {
        limit: paginationState.limit,
        offset: paginationState.offset,
        order,
        filters: modifiedFilters,
      },
    });
  };

  const resetPage = () => {
    dispatchAction('SET_CAMPAIGN_LIST', {
      count: 0,
      hasMore: false,
      rows: [],
    });
    if (!isEqual(paginationState, initialPaginationState)) {
      setPaginationState({
        ...paginationState,
        offset: 0,
        currentPage: 1,
        isReset: true,
      });
    }
    if (order !== initialOrderState) {
      setOrder(initialOrderState);
    }
  };

  useEffect(() => {
    if (filters?.conditions?.length > 0) {
      getData();
    } else if (campaignList.rows.length !== 0) {
      resetPage();
    }
  }, [paginationState, order, filters]);

  useEffect(() => {
    setPaginationState({
      ...paginationState,
      offset: 0,
      currentPage: 1,
      isReset: true,
    });
  }, [filters]);

  // eslint-disable-next-line react/prop-types
  const columns = useMemo(() => {
    const columnList = [
      {
        Header: 'Campaign ID',
        accessor: 'recallCode',
        sortable: true,
        // eslint-disable-next-line
        Cell: ({ row: { original }, value }) =>
          value ? (
            <>
              <Link
                // eslint-disable-next-line react/prop-types
                to={`/recalls/campaigns/${encodeURIComponent(original.id)}`}
              >
                {value}
              </Link>
              {/* eslint-disable-next-line react/prop-types */}
              {value && original?.shouldStopDrive && (
                <div>
                  <Tag key="Urgent-Dark" variant="Urgent-Dark">
                    STOP DRIVE
                  </Tag>
                </div>
              )}
              {/* eslint-disable-next-line react/prop-types */}
              {value && original?.shouldParkOutside && (
                <div>
                  <Tag
                    key="Important-Dark"
                    variant="Important-Dark"
                    className="text-no-wrap"
                  >
                    PARK OUTSIDE
                  </Tag>
                </div>
              )}
            </>
          ) : (
            emdash
          ),
      },
      {
        Header: 'Remaining',
        accessor: 'openRecallVehiclesCount',
        sortable: false,
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => {
          return value?.count || 0;
        },
      },
      {
        Header: (
          <div className="display-flex flex-align-center">
            Remedy available
            <Tooltip
              className="usa-button--unstyled"
              label={
                'Remedy Available: "Yes" means \n a recall  repair or parts are available. \n Contact your local dealership to verify \n and schedule an appointment.'
              }
              position="left"
            >
              <Icon
                iconName="info"
                className="text-primary margin-left-1 text-ink"
              />
            </Tooltip>
          </div>
        ),
        accessor: 'isRemedyAvailable',
        sortable: true,
        Cell: ({ value }) => {
          return value ? 'Yes' : 'No';
        },
      },
      {
        Header: 'Description',
        accessor: 'recallDescription',
        sortable: false,
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => {
          return value ?? emdash;
        },
      },
    ];
    return columnList;
  }, []);

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    // Calculate new offset.
    const offset = (currentPage - 1) * itemsPerPage;
    setPaginationState({
      limit: itemsPerPage,
      offset,
      currentPage,
    });
  };

  return (
    <>
      <AFPTable
        fullWidth
        ref={tableRef}
        testId="campaigns-listing-table"
        columns={columns}
        data={!recallCampaignsLoading ? campaignList?.rows || [] : []}
        defaultSort={order}
        onSort={setOrder}
      />
      {recallCampaignsLoading && <Spinner className="padding-y-9" />}
      {campaignList?.rows?.length > 0 && (
        <Pagination
          fullWidth
          variant="advanced"
          itemsPerPageOptions={[10, 25, 50]}
          itemsCount={campaignList.count}
          itemsPerPage={paginationState.limit}
          currentPage={paginationState.currentPage}
          onPageChange={handlePaginationChange}
          isReset={paginationState.isReset}
        />
      )}
      {(!campaignList || campaignList.rows.length === 0) &&
        !recallCampaignsLoading && (
          <div className="bg-gray-3 padding-y-5">
            <div className="text-center padding-y-4">
              <EmptyState
                alt="Image not available"
                hasBackground
                bottomText={
                  <>
                    <p className="text-bold">No recall campaigns showing</p>
                    <p>
                      Add or remove filters to search recall campaigns.{' '}
                      {requiredFilterPerRole} selection is required.
                    </p>
                  </>
                }
              />
            </div>
          </div>
        )}
    </>
  );
};

export default RecallCampaignsTable;
