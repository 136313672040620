import React from 'react';
import { Button, DetailsTable } from '@gsa/afp-component-library';
import phoneNumberFormatter from '../../../../../utilities/phoneNumberFormatter';

const formatPhone = (phone, countryCode) => {
  if (countryCode === 'US' && phone.indexOf('-') > -1) {
    return phone;
  }
  return phoneNumberFormatter(phone);
};

export default function ValidPocCard({
  pointOfContact,
  validated,
  onEdit = () => {},
  onRemove = () => {},
  ...props
}) {
  if (validated) {
    return (
      <div className="afp-vehicle-registration__poc_info_read" {...props}>
        <DetailsTable
          data={[
            ['Name', `${pointOfContact.firstName} ${pointOfContact.lastName}`],
            [
              'Address',
              `${
                pointOfContact.primaryAddress
                  ? pointOfContact.primaryAddress + ','
                  : ''
              } ${
                pointOfContact.secondaryAddress
                  ? pointOfContact.secondaryAddress + ','
                  : ''
              } ${pointOfContact.city ? pointOfContact.city + ',' : ''} ${
                pointOfContact.stateCode ? pointOfContact.stateCode : ''
              } ${pointOfContact.postalCode}`,
            ],
            [
              'Phone',
              `${
                pointOfContact.primaryPhone
                  ? formatPhone(
                      pointOfContact.primaryPhone,
                      pointOfContact.countryCode,
                    )
                  : ''
              } ${
                pointOfContact.primaryPhoneExt
                  ? 'ext. ' + pointOfContact.primaryPhoneExt
                  : ''
              }`,
            ],
            [
              <div className="w-225">
                <Button
                  variant="outline"
                  onClick={onRemove}
                  className="bg-white"
                  label="Remove"
                />
                <Button
                  data-testid={`edit ${props['data-testid']}`}
                  className="bg-white"
                  onClick={onEdit}
                  variant="outline"
                  label="Edit"
                />
              </div>,
              ' ',
            ],
          ]}
        />
      </div>
    );
  }
  return null;
}
