import { cloneDeep } from 'lodash';
import { formatTagExpDate } from '../../../utilities/common';
import phoneNumberFormatter from '../../../utilities/phoneNumberFormatter';

export const constructPoc = (poc) => {
  const {
    email,
    firstName,
    lastName,
    primaryAddress,
    secondaryAddress,
    city,
    stateCode,
    postalCode,
    countryCode,
    primaryPhone,
    primaryPhoneExt,
  } = poc || {};

  return {
    email,
    firstName,
    lastName,
    primaryAddress,
    secondaryAddress,
    city,
    stateCode,
    postalCode,
    countryCode,
    primaryPhone,
    primaryPhoneExt,
  };
};
export const constructPayload = (veh) => {
  const {
    id,
    makeCode,
    vehicleMake,
    modelCode,
    modelYear,
    makeColorName,
    fuelCode,
    tagNumber,
    tag,
    agencyCode,
    bureauCode,
    subSectionCode,
    primaryPoC,
    secondaryPoC,
    status,
    exemptPlate,
    vehicleModel,
  } = veh || {};
  return {
    vin: id,
    makeCode,
    makeName: vehicleMake.makeName,
    modelYear,
    modelCode,
    makeColorName,
    fuelCode,
    tagNumber,
    tagExpirationDate: tag?.expirationDate,
    agencyCode,
    bureauCode,
    subSectionCode,
    subSectionTypeCode: 'COF',
    pointOfContact1: constructPoc(primaryPoC),
    pointOfContact2: constructPoc(secondaryPoC),
    status,
    exemptPlate,
    modelTypeCode: vehicleModel?.modelTypeCode
      ? vehicleModel.modelTypeCode
      : 'VEH',
  };
};

export const getRegistrationPDFPayload = (veh) => {
  try {
    // shallow copy will not copy the nested attribute veh.vehicleModel.modelDescription
    // and vehicleCopy.vehicleModel.modelDescription = vehicleCopy.modelCode will have the error of not able to set value on read only property and trigger the try catch
    // modelDescription refer to the modelName , the problem happens when the modelName = '' => !modelName === true
    // there are two options we could use for the deep clone JSON.parse(JSON.stringify) or clone deep, discussed with tech lead we chose the clone deep for the performance
    const vehicleCopy = cloneDeep(veh);
    if (!vehicleCopy.office) {
      vehicleCopy.office = { officeName: '' };
    }
    if (!vehicleCopy.makeColor) {
      vehicleCopy.makeColor = { makeColorName: '' };
    }
    if (!vehicleCopy.vehicleModel) {
      vehicleCopy.vehicleModel = {
        modelDescription: vehicleCopy.modelCode,
      };
    } else if (!vehicleCopy.vehicleModel.modelDescription) {
      vehicleCopy.vehicleModel.modelDescription = vehicleCopy.modelCode;
    }

    const {
      id,
      agency: { name: agencyName },
      bureau: { name: bureauName },
      agencyCode,
      bureauCode,
      subSectionCode,
      office: { officeName },
      exemptPlate,
      tagNumber,
      vehicleMake,
      vehicleModel: { modelDescription },
      modelYear,
      makeColor: { name: makeColorName },
      fuel: { description: fuelDescription },
      primaryPoC: {
        fullName: firstFullName,
        firstName: firstFirstName,
        lastName: firstLastName,
        primaryAddress: firstPrimaryAddress,
        secondaryAddress: firstSecondaryAddress,
        city: firstCity,
        postalCode: firstPostalCode,
        primaryPhone: firstPrimaryPhone,
        stateCode: firstStateCode,
      },
      secondaryPoC: {
        fullName: secondFullName,
        firstName: secondFirstName,
        lastName: secondLastName,
        primaryAddress: secondPrimaryAddress,
        secondaryAddress: secondSecondaryAddress,
        city: secondCity,
        postalCode: secondPostalCode,
        primaryPhone: secondPrimaryPhone,
        stateCode: secondStateCode,
      },
    } = vehicleCopy;
    const makeName = vehicleMake?.makeName;

    const expirationDate = !vehicleCopy.exemptPlate
      ? vehicleCopy.tag?.expirationDate
      : null;

    const officeCode =
      officeName && subSectionCode ? `${subSectionCode} - ` : '';

    return {
      id,
      agency: { name: `${agencyCode} - ${agencyName}` },
      bureau: { name: `${bureauCode} - ${bureauName}` },
      office: { name: `${officeCode}${officeName}` },
      tag: tagNumber,
      exemptPlate,
      expDate: expirationDate ? formatTagExpDate(expirationDate) : null,
      vehicleMake: { makeName },
      vehicleModel: { modelDescription },
      modelYear,
      makeColorName,
      fuel: { description: fuelDescription },
      primaryPoC: {
        fullName: firstFullName
          ? firstFullName
          : `${firstFirstName} ${firstLastName}`,
        primaryAddress: firstPrimaryAddress,
        secondaryAddress: firstSecondaryAddress,
        city: firstCity,
        postalCode: firstPostalCode,
        primaryPhone: phoneNumberFormatter(firstPrimaryPhone),
        stateCode: firstStateCode,
      },
      secondaryPoC: {
        fullName: secondFullName
          ? secondFullName
          : `${secondFirstName} ${secondLastName}`,
        primaryAddress: secondPrimaryAddress,
        secondaryAddress: secondSecondaryAddress,
        city: secondCity,
        postalCode: secondPostalCode,
        primaryPhone: phoneNumberFormatter(secondPrimaryPhone),
        stateCode: secondStateCode,
      },
    };
  } catch (err) {
    console.log(err);
    return null;
  }
};
