import moment from 'moment';
import React from 'react';

const RecallTypeMap = {
  Safety: 'Safety recall campaign',
  Warranty: 'Warranty',
  'Non-Safety': 'Non-safety campaign',
  'Non-Actionable': '-',
};

const RecallSourceMap = {
  CarFax: 'Carfax',
  Ford: 'Ford',
  GM: 'GM',
  Chrysler: 'Chrysler',
  Manual: 'Manual Entry',
};

const getNhtsaLink = (recallDetailData) => {
  const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
  const nhtsaLink = `https://www.nhtsa.gov/recalls?nhtsaId=${recallDetailData?.nhtsaCampaignId}`;
  const isValidUrl = urlRegex.test(recallDetailData?.recallUrl);

  if (isValidUrl) {
    return (
      <a href={recallDetailData?.recallUrl} target="_blank" rel="noreferrer">
        {recallDetailData?.nhtsaCampaignId}
      </a>
    );
  }
  if (recallDetailData?.nhtsaCampaignId) {
    return (
      <a href={nhtsaLink} target="_blank" rel="noreferrer">
        {recallDetailData?.nhtsaCampaignId}
      </a>
    );
  }
  return '-';
};

const getDataOrDash = (recallDetailData, field) => {
  if (recallDetailData) {
    if (field === 'make') {
      return recallDetailData?.make?.makeName ?? '-';
    }
    if (field === 'recallType') {
      if (!recallDetailData.recallType) return '-';
      return RecallTypeMap[recallDetailData.recallType] ?? '-';
    }

    if (
      field === 'isRemedyAvailable' ||
      field === 'shouldStopDrive' ||
      field === 'shouldParkOutside'
    ) {
      return recallDetailData[field] ? 'Yes' : 'No';
    }

    if (field === 'recallSource') {
      return RecallSourceMap[recallDetailData.recallSource];
    }

    if (field === 'gsaNotifiedDate' || field === 'recallReleaseDate') {
      return recallDetailData[field]
        ? moment(recallDetailData[field]).format('MM/DD/YYYY')
        : '-';
    }

    if (field) return recallDetailData[field] ? recallDetailData[field] : '-';
  }
  return '-';
};

export { getDataOrDash, getNhtsaLink };
