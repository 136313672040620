import React from 'react';
import { Button } from '@gsa/afp-component-library';
import { useHistory } from 'react-router-dom';
import usePortalModal from '../../../utilities/portal-modal';

export default function CancelRegistration() {
  const [Modal, openModal, closeModal] = usePortalModal();
  const history = useHistory();
  const handleCancel = () => {
    history.push('/vehicles');
    closeModal();
  };
  const CancelModal = () => {
    return (
      <Modal
        id="cancel-registration-modal"
        variant="large"
        title={<h2>Cancel registration</h2>}
        onClose={closeModal}
        actions={
          <>
            <Button
              onClick={closeModal}
              variant="outline"
              label="Continue Registration"
            />
            <Button onClick={handleCancel} label="Cancel Registration" />
          </>
        }
      >
        <div className="cancel-registration-content margin-right-10 margin-y-7">
          <p>Are you sure you want to cancel your registration?</p>
          <p>
            By canceling the registration, you will lose all entered information
            and will be directed to the vehicle inventory page. Please confirm
            that you wish to cancel your registration.
          </p>
        </div>
      </Modal>
    );
  };
  return (
    <>
      <Button
        variant="unstyled"
        onClick={() => {
          openModal();
        }}
        className="margin-bottom-2"
        leftIcon={{ name: 'arrow_back', class: 'usa-icon--size-3' }}
        label="Cancel registration"
      />
      <CancelModal />
    </>
  );
}
