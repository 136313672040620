/* eslint-disable react/prop-types */
import { useMutation } from '@apollo/client';
import {
  Button,
  FilterTableFrame,
  Alert,
  ButtonDropdown,
  ButtonDropdownItem,
} from '@gsa/afp-component-library';
import exportMessages from 'utilities/export-message';
import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { useHistory } from 'react-router-dom';
// UploadContextProvider has been moved from the uploadSapButton to this page. there are clean up job
// the UploadContextProvider should stay with the order page
// we may want to clean up the structure when we have time
import UploadContextProvider from 'components/UploadFile/upload-context';
import UploadModal from 'components/UploadFile/upload-modal';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import { canCreateTagOrders } from 'utilities/authorization';
import {
  EXPORT_LICENCE_PLATE_ORDERS,
  UPLOAD_SUPPORTING_DOC,
} from '../../services/data-layer';
import ServerErrorMessage from '../error-handlers/server-error-message';
import UploadSapFile from '../UploadFile';
import OrderContextProvider from './context';
import useOrderManager from './helpers/orders-manager';
import OrdersHeader from './widgets/header';
import OrdersSidebar from './widgets/orders-sidebar';
import OrdersTable from './widgets/orders-table';
import { canUpdateSAPFile } from './authorization';

// SORTING
export const sortingReducer = (state, action) => {
  return { ...action };
};
const initialSortingState = {
  sortBy: 'registered',
  sortOrder: 'ASC',
};

export const showAlert = (msg, setMsg) => {
  if (!msg?.type || !msg?.message) return null;
  return (
    <Alert
      type={msg.type}
      focused={msg.focused || true}
      heading={msg.heading}
      slim={!!msg.heading}
      onClose={!msg.hideClose && setMsg ? () => setMsg(null) : undefined}
      className="margin-bottom-2 padding-bottom-2"
    >
      {msg.message}
    </Alert>
  );
};

const ButtonWrapper = ({
  ordersList,
  sortOrder,
  filters,
  appliedFilters,
  exportVehiclesByNestedFilters,
  setExportMessage,
  setUploadMessage,
  title,
  fileTypes,
}) => {
  const ability = useAppAbility();

  const history = useHistory();
  // we assume the user will either can updateSapFile or canUpdateTagOrders. and there is no user could process both ability.

  if (canCreateTagOrders(ability) && canUpdateSAPFile(ability)) {
    return (
      <div className="display-flex flex-justify-end">
        <ButtonDropdown
          label="Actions"
          variant="primary"
          side="right"
          className="display-flex flex-align-right"
          data-testid="actions-order-btn"
        >
          <ButtonDropdownItem
            className="display-flex flex-align-center float-left"
            data-testid="create-orders-btn"
            iconName="add"
            label="Create license plate order"
            onClick={() => {
              history.push({
                pathname: '/orders/create-license-plate-orders',
              });
            }}
          />
          <UploadSapFile
            title={title}
            fileTypes={fileTypes}
            uploadPath="orders/"
            model="Orders"
            modelId="orders"
            graphqlSchema={UPLOAD_SUPPORTING_DOC}
            setUploadMessage={setUploadMessage}
            isButtonDropdownItem
          />
          {ordersList?.rows?.length > 0 ? (
            <ButtonDropdownItem
              className="display-flex flex-align-center float-left"
              data-testid="download-csv-btn"
              iconName="file_download"
              label="Export data"
              onClick={() => {
                const variables = {
                  order: sortOrder,
                  filters,
                  appliedFilters,
                };
                exportVehiclesByNestedFilters({
                  variables,
                });
                // this is quick fix , the correct fix is create an manager function to clean up the side effect for all the alert message function.
                // handle all the alert in one place.
                // we already know we will show multiple alert box in order page, which is aggreed an approved by team lead due to scope of this ticket.
                // we will combine multiple alerts into one in the HIP sprint(which will require structure change)
                setExportMessage(exportMessages.exportSuccessMessage);
              }}
            />
          ) : null}
        </ButtonDropdown>
      </div>
    );
  }
  if (canCreateTagOrders(ability)) {
    return (
      <div className="display-flex flex-justify-end">
        {ordersList?.rows?.length > 0 ? (
          <ButtonDropdown
            label="Actions"
            variant="primary"
            side="right"
            className="display-flex flex-align-right"
            data-testid="actions-order-btn"
          >
            <ButtonDropdownItem
              className="display-flex flex-align-center float-left"
              data-testid="download-csv-btn"
              iconName="file_download"
              label="Export data"
              onClick={() => {
                const variables = {
                  order: sortOrder,
                  filters,
                  appliedFilters,
                };
                exportVehiclesByNestedFilters({
                  variables,
                });
                // this is quick fix , the correct fix is create an manager function to clean up the side effect for all the alert message function.
                // handle all the alert in one place.
                // we already know we will show multiple alert box in order page, which is aggreed an approved by team lead due to scope of this ticket.
                // we will combine multiple alerts into one in the HIP sprint(which will require structure change)
                setExportMessage(exportMessages.exportSuccessMessage);
              }}
            />

            <ButtonDropdownItem
              className="display-flex flex-align-center float-left"
              data-testid="create-orders-btn"
              iconName="add"
              label="Create license plate order"
              onClick={() => {
                history.push({
                  pathname: '/orders/create-license-plate-orders',
                });
              }}
            />
          </ButtonDropdown>
        ) : (
          <Button
            className="display-flex flex-align-center float-left"
            data-testid="create-orders-btn"
            rightIcon={{ name: 'add' }}
            label="Create license plate order"
            onClick={() => {
              history.push({
                pathname: '/orders/create-license-plate-orders',
              });
            }}
          />
        )}
      </div>
    );
  }

  if (canUpdateSAPFile(ability)) {
    return (
      <div className="display-flex flex-justify-end">
        {ordersList?.rows?.length > 0 ? (
          <ButtonDropdown
            label="Actions"
            variant="primary"
            side="right"
            className="display-flex flex-align-right"
            data-testid="actions-order-btn"
          >
            <ButtonDropdownItem
              className="display-flex flex-align-center float-left"
              data-testid="download-csv-btn"
              iconName="file_download"
              label="Export data"
              onClick={() => {
                const variables = {
                  order: sortOrder,
                  filters,
                  appliedFilters,
                };
                exportVehiclesByNestedFilters({
                  variables,
                });
                // this is quick fix , the correct fix is create an manager function to clean up the side effect for all the alert message function.
                // handle all the alert in one place.
                // we already know we will show multiple alert box in order page, which is aggreed an approved by team lead due to scope of this ticket.
                // we will combine multiple alerts into one in the HIP sprint(which will require structure change)
                setExportMessage(exportMessages.exportSuccessMessage);
              }}
            />
            <UploadSapFile
              title={title}
              fileTypes={fileTypes}
              uploadPath="orders/"
              model="Orders"
              modelId="orders"
              graphqlSchema={UPLOAD_SUPPORTING_DOC}
              setUploadMessage={setUploadMessage}
              isButtonDropdownItem
            />
          </ButtonDropdown>
        ) : (
          <UploadSapFile
            title={title}
            fileTypes={fileTypes}
            uploadPath="orders/"
            model="Orders"
            modelId="orders"
            graphqlSchema={UPLOAD_SUPPORTING_DOC}
            setUploadMessage={setUploadMessage}
            isButtonDropdownItem={false}
          />
        )}
      </div>
    );
  }

  return (
    <div className="display-flex flex-justify-end">
      {ordersList?.rows?.length > 0 ? (
        <Button
          className="display-flex flex-align-center float-left"
          data-testid="download-csv-btn"
          rightIcon={{ name: 'file_download' }}
          label="Export data"
          onClick={() => {
            const variables = {
              order: sortOrder,
              filters,
              appliedFilters,
            };
            exportVehiclesByNestedFilters({
              variables,
            });
            // this is quick fix , the correct fix is create an manager function to clean up the side effect for all the alert message function.
            // handle all the alert in one place.
            // we already know we will show multiple alert box in order page, which is aggreed an approved by team lead due to scope of this ticket.
            // we will combine multiple alerts into one in the HIP sprint(which will require structure change)
            setExportMessage(exportMessages.exportSuccessMessage);
          }}
        />
      ) : null}
    </div>
  );
};
// this page should split to  multiple files

function OrderFilterGrid() {
  const [sortingState] = useReducer(sortingReducer, initialSortingState);
  const {
    orders,
    setOrders,
    filters,
    setFilters,
    limit,
    setLimit,
    offset,
    setOffset,
    sortOrder,
    setSortOrder,
    loading,
    getOrdersError,
  } = useOrderManager();

  const [exportVehiclesByNestedFilters] = useMutation(
    EXPORT_LICENCE_PLATE_ORDERS,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const [appliedFilters, setAppliedFilters] = useState([]);

  const fileTypes = ['.txt', '.dat'];
  const title = 'Import USCAN / SAP files';

  const OrdersSidebarWrapper = () => (
    <OrdersSidebar
      onChange={(conditions) => {
        const tagstatus = conditions.find((i) => i.key === 'tagStatus');
        if (tagstatus) {
          tagstatus.value = tagstatus.value.flat();
        }
        setFilters([{ ...filters[0], conditions }]);
      }}
      order={[[sortingState.sortBy, sortingState.sortOrder]]}
      updateAppliedFilters={(f) => updateFilters(f)}
    />
  );
  const [exportMessage, setExportMessage] = useState(null);
  const [uploadMessage, setUploadMessage] = useState();

  // when we have time we want to change the  buttonWrapper to the standard structure as other page
  const FTF = useMemo(
    () =>
      FilterTableFrame(null, ButtonWrapper, OrdersSidebarWrapper, OrdersTable),
    [],
  );
  const updateFilters = (f) => {
    const filterLabels = f.map((filter) => filter.label);
    setAppliedFilters(filterLabels);
  };

  const [serverError, setServerError] = useState({});

  useEffect(() => {
    if (
      getOrdersError &&
      getOrdersError.message.split(':')[0] === 'ORDER_NOT_FOUND'
    ) {
      setServerError({
        message: (
          <>
            GSAFLeet.gov does not have any sales order information related to
            your order number. Contact UNICOR at{' '}
            <a href="mailto:CUSGDLP@usdoj.gov">CUSGDLP@usdoj.gov</a> and request
            to have your order number loaded into GSAFLeet.gov
          </>
        ),
      });
    } else {
      setServerError({});
    }
  }, [getOrdersError]);

  return (
    <>
      {showAlert(exportMessage, setExportMessage)}

      {getOrdersError && (
        <ServerErrorMessage
          graphQLErrors={getOrdersError}
          error={serverError}
        />
      )}
      {uploadMessage && (
        <Alert
          type="info"
          data-testid="upload-message"
          className="margin-bottom-2"
          focused
          slim
        >
          {uploadMessage}
        </Alert>
      )}

      <OrderContextProvider
        orders={orders}
        setOrders={setOrders}
        filters={filters}
        setFilters={setFilters}
        limit={limit}
        setLimit={setLimit}
        offset={offset}
        setOffset={setOffset}
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
        loading={loading}
        getOrdersError={getOrdersError}
      >
        <UploadContextProvider
          title={title}
          fileTypes={fileTypes}
          uploadPath="orders/"
          model="Orders"
          modelId="orders"
          graphqlSchema={UPLOAD_SUPPORTING_DOC}
          setUploadMessage={setUploadMessage}
        >
          <UploadModal
            id="sap-upload-modal"
            className="sap-upload-modal"
            title={title}
          />
          <FTF
            upRightProps={{
              ordersList: orders,
              sortOrder,
              filters,
              appliedFilters,
              exportVehiclesByNestedFilters,
              setExportMessage,
              setUploadMessage,
              title,
              fileTypes,
            }}
            lowRightProps={{
              filters,
            }}
            filterToggle
          />
        </UploadContextProvider>
      </OrderContextProvider>
    </>
  );
}

export default function Orders() {
  return (
    <article className="orders">
      <OrdersHeader />
      <OrderFilterGrid />
    </article>
  );
}
