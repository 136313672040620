import React from 'react';
import { Alert } from '@gsa/afp-component-library';
import { useLicensePlateDetails } from '../license-plate-details-provider';

const LpGeneralErrorAlert = () => {
  const { alertMessage } = useLicensePlateDetails();

  if (!alertMessage?.message) return null;

  return (
    <Alert type={alertMessage?.type} slim>
      {alertMessage?.message}
    </Alert>
  );
};

export default LpGeneralErrorAlert;
