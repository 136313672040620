/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import { useLazyQuery, useMutation } from '@apollo/client';
import exportMessages from 'utilities/export-message';
import {
  AFPTable,
  FilterTableFrame,
  Icon,
  EmptyState,
  Spinner,
  Pagination,
  useModal,
} from '@gsa/afp-component-library';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import _ from 'lodash';
import React, {
  useEffect,
  useReducer,
  useState,
  useMemo,
  useCallback,
} from 'react';
import { useHistory } from 'react-router-dom';
import FilterUpRightActions from 'utilities/filter-up-right-actions';
import { vehToPDFpayload } from 'utilities/format';
import { vehicleFilterKeys } from 'components/user-filters/filter-structures/constants';
import useCanPerformActions from '../../hooks/use-can-perform-actions';
import {
  CREATE_SUPPORTING_DOC,
  GET_VEHICLE_LISTING,
} from '../../services/data-layer';
import {
  canViewGFVehicles,
  canManageMotorPoolsVehicles,
} from '../../utilities/authorization';
import ServerErrorMessage from '../error-handlers/server-error-message';
import { getRegistrationPDFPayload } from '../registration-details/helpers/payload-constructors';
import getVehicleIncludeables, {
  defaultIncludeables,
} from '../user-filters/vehicle-inventory-filters/vehicle-includeable-builder';
import getVehicleRegistrationFilter from '../user-filters/vehicle-inventory-filters/vehicle-registration-filter-builder';
import getVehicleStatusFilter from '../user-filters/vehicle-inventory-filters/vehicle-status-filter-builder';
import resetModelOnMakeChange from './helpers/reset-model-on-make-change';
import resetBureauOnAgencyChange from './helpers/reset-bureau-on-agency-change';
import './vehicleInventory.css';
import useBulkRegDownload from './widgets/bulk-reg-download';
import SidebarWidget from './widgets/sidebar-widget';
import { formatDataForTable } from './widgets/table-data-widgets';
import { VehicleDetailsRow } from './widgets/table-widgets';
import AddToMotorPoolModal from './widgets/add-to-motor-pool-modal';
import { useVehicleInventoryFilter } from './vehicle-inventory-filter-provider';

const initFilters = [
  {
    conditions: [],
    operator: 'AND',
  },
];
const initVariables = {
  limit: 10,
  offset: 0,
  filters: initFilters,
};

// SORTING
const sortingReducer = (state, action) => {
  return { ...action };
};

// SORT END

const TABLE_ICON_COLLAPSED = (
  <Icon className="usa-icon--size-4" iconName="navigate_next" />
);
const TABLE_ICON_EXPANDED = (
  <Icon className="usa-icon--size-4" iconName="expand_more" />
);

// the actual filter build is located at src/components/user-filters/vehicle-inventory-filters/vehicle-registration-filter-builder.js
// need to change the user status id to 3 to see the fmvrs filter
const FilterGrid = ({ mutation, alertUtils }) => {
  const ability = useAppAbility();
  const viewGovFleet = canViewGFVehicles(ability);
  const renderRowSubComponent = useCallback(({ row: { original } }) => {
    return <VehicleDetailsRow vehicle={original} />;
  }, []);

  const TableWrapper = ({
    tableData,
    loading,
    selectedRows,
    pageCount,
    dataCount,
  }) => {
    return (
      <>
        <AFPTable
          columns={tableHeaderData}
          iconCollapsed={TABLE_ICON_COLLAPSED}
          iconExpanded={TABLE_ICON_EXPANDED}
          data={loading ? [] : tableData || []}
          testId="afp-responsive-table-test-id"
          fullWidth
          bordered={false}
          defaultSort="registered asc"
          expandable
          selectable
          scrollable={false}
          stacked
          selectAllHeader=""
          selectedRows={selectedRows}
          onRowSelect={({ selectedFlatRows }) => {
            if (
              !preventRowHandle &&
              !_.isEqual(selectedRows, selectedFlatRows)
            ) {
              const selectedRowIds = selectedFlatRows.map(
                (item) => item?.values?.id?.props?.vin,
              );
              setSelectedRows(selectedRowIds);
            } else if (preventRowHandle) {
              setPreventRowHandle(false);
              setSelectedRows([]);
            }
          }}
          renderRowSubComponent={renderRowSubComponent}
          onSort={(order) => {
            setFetchVehicles(true);
            if (order) {
              const [sortBy, sortOrder] = order.split(' ');
              setSortingState({
                sortOrder,
                sortBy: sortBy.replace(/`/g, ''),
              });
            }
          }}
        />
        {loading && <Spinner className="padding-y-9" />}
        {tableData?.length ? (
          <Pagination
            variant="advanced"
            itemsPerPage={pageCount}
            itemsCount={dataCount}
            onPageChange={handlePaginationChange}
            isReset={isReset}
          />
        ) : null}
        {(tableData == null || tableData.length === 0) &&
          !loading &&
          !filterApplied && (
            <div className="bg-gray-3 padding-y-5">
              <div className="text-center padding-y-4">
                <EmptyState alt="Image not available" hasBackground />
              </div>
              {isSearchByVin ? (
                <>
                  <div className="text-center text-bold">
                    Vehicle does not exist or you
                  </div>
                  <div className="text-center text-bold margin-top-2">
                    do not have permission to access it.
                  </div>
                </>
              ) : (
                <>
                  <div className="text-center text-bold">
                    No Vehicle Showing
                  </div>
                  <div className="text-center margin-top-2">
                    Add or remove filters to search vehicles
                  </div>
                </>
              )}
            </div>
          )}

        {(tableData == null || tableData.length === 0) &&
          !loading &&
          filterApplied && (
            <div className="bg-gray-3 padding-y-5">
              <div className="text-center padding-y-4">
                <EmptyState alt="Image not available" hasBackground />
              </div>
              {isSearchByVin ? (
                <>
                  <div className="text-center text-bold">
                    Vehicle does not exist or you
                  </div>
                  <div className="text-center text-bold margin-top-2">
                    do not have permission to access it.
                  </div>
                </>
              ) : (
                <>
                  <div className="text-center text-bold">No Results Found</div>
                </>
              )}
            </div>
          )}
      </>
    );
  };

  const FTF = useMemo(
    () =>
      FilterTableFrame(null, FilterUpRightActions, SidebarWidget, TableWrapper),
    [],
  );

  const [
    getVehicleInventory,
    { data, loading, error: getVehicleInventoryError },
  ] = useLazyQuery(GET_VEHICLE_LISTING, {
    fetchPolicy: 'network-only',
  });

  const [createSupportingDoc] = useMutation(CREATE_SUPPORTING_DOC, {
    fetchPolicy: 'no-cache',
  });

  const history = useHistory();

  const [sortingState, setSortingState] = useReducer(sortingReducer, {});
  const [dataCount, setDataCount] = useState(0);
  const [pageCount, setPageCount] = useState(10);
  const [offset, setOffset] = useState(0);
  const [tableData, setTableData] = useState(null);

  const [filters, setVehicleFilters] = useState(initVariables.filters);
  const [includeables, setIncludeables] = useState(defaultIncludeables);
  const [isReset, resetPage] = useState(false);

  const [selectedRows, setSelectedRows] = useState([]);
  const [BulkRegModal, startBulkDownload] = useBulkRegDownload(alertUtils);
  const [filterApplied, setFilterApplied] = useState(false);
  const [preventRowHandle, setPreventRowHandle] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState({});
  const [isFilterUpdatRequired, setIsFilterUpdatRequired] = useState(true);

  const {
    isOpen: isAddToMotorPoolModalOpen,
    openModal: openAddToMotorPoolModal,
    closeModal: closeAddToMotorPoolModal,
  } = useModal();

  const { typeaheadData, isGetVehicles, setFetchVehicles, getFilteredVendors } =
    useVehicleInventoryFilter();

  const sortMap = {
    agency: 'agencyCode',
  };

  const setFilters = (conditions) => {
    let vehicleStatusIndex;
    let registrationStatusIndex;
    let usedIncludeables = [];

    for (let i = 0; i < conditions.length; i++) {
      if (conditions[i]?.operator === 'REGISTRATIONSTATUS') {
        registrationStatusIndex = i;
      }
      if (conditions[i]?.operator === 'VEHICLESTATUS') {
        vehicleStatusIndex = i;
      }
    }

    if (vehicleStatusIndex !== undefined) {
      const vehicleStatusFilters = getVehicleStatusFilter(
        conditions[vehicleStatusIndex]?.value,
      );
      conditions.splice(vehicleStatusIndex, 1, {
        operator: 'OR',
        value: vehicleStatusFilters,
      });
    }

    if (registrationStatusIndex !== undefined) {
      const vehicleRegistrationFilters = getVehicleRegistrationFilter(
        conditions[registrationStatusIndex]?.value,
      );
      usedIncludeables = getVehicleIncludeables(
        conditions[registrationStatusIndex]?.value,
      );
      conditions.splice(registrationStatusIndex, 1, {
        operator: 'OR',
        value: vehicleRegistrationFilters,
      });
    } else {
      usedIncludeables = getVehicleIncludeables('All');
    }

    const resetBureauAgencyFilters = resetBureauOnAgencyChange(
      [{ ...filters[0], conditions }],
      filters,
    );

    const updatedFilters = resetModelOnMakeChange(
      [{ ...resetBureauAgencyFilters[0] }],
      filters,
    );

    setIncludeables(usedIncludeables);

    if (registrationStatusIndex !== undefined) {
      setVehicleFilters(updatedFilters);
      resetPage(true);
      setOffset(0);
      setFetchVehicles(true);
    }

    if (!_.isEqual(updatedFilters[0].conditions, initFilters[0].conditions)) {
      setVehicleFilters(updatedFilters);
      resetPage(true);
      setOffset(0);
      setFetchVehicles(true);
      if (
        updatedFilters[0].conditions.find(
          ({ key }) => key === vehicleFilterKeys.VEHICLE_LOCATION,
        )
      ) {
        setIsFilterUpdatRequired(true);
      } else {
        setIsFilterUpdatRequired(false);
      }
    }

    setTableData(null);
  };

  const actionClick = async (vin, action, vehicle) => {
    if (action === 'vehicleDetails') {
      history.push(`/vehicles/${encodeURIComponent(vin)}`);
    } else if (action === 'editRegistration') {
      history.push(`/vehicles/${encodeURIComponent(vin)}`);
    } else if (action === 'register') {
      history.push(`/vehicle-registration/${encodeURIComponent(vin)}`);
    } else if (action === 'downloadRegistration') {
      const payload = getRegistrationPDFPayload(vehToPDFpayload(vehicle));
      const createResponse = await createSupportingDoc({
        variables: {
          data: payload,
          model: 'Vehicle',
          modelPK: vehicle.id,
          documentName: 'registration',
        },
      });
      window.open(createResponse?.data?.createSupportingDoc);
    } else if (action === 'addVehicleToMotorPool') {
      setSelectedVehicle(vehicle);
      openAddToMotorPoolModal();
    }
  };

  const [isSearchByVin, setIsSearchedByVin] = useState(false);

  const canPerformActions = useCanPerformActions();
  const { canRegister } = canPerformActions;
  const canAddVehicleToPool = canManageMotorPoolsVehicles(ability);

  useEffect(() => {
    if (
      _.findIndex(filters[0]?.conditions, { operator: '$startsWith' }) > -1 &&
      _.isEmpty(data?.getVehiclesListing?.rows)
    ) {
      setIsSearchedByVin(true);
    } else {
      setIsSearchedByVin(false);
    }
    if (data) {
      alertUtils.closeAlert();
      const newData = formatDataForTable(
        data,
        actionClick,
        canRegister,
        canAddVehicleToPool,
      );
      setTableData(newData);
      setDataCount(data?.getVehiclesListing?.count || 0);
      setFetchVehicles(false);
    }
  }, [data]);

  useEffect(() => {
    if (!filters) {
      setFetchVehicles(true);
    }
  }, [filters]);

  useEffect(() => {
    const updatedFilters = [...filters];
    let vendorEntity;

    for (let i = 0; i < updatedFilters[0].conditions.length; i++) {
      if (
        updatedFilters[0].conditions[i].key ===
        vehicleFilterKeys.VEHICLE_LOCATION
      ) {
        setIsFilterUpdatRequired(true);
        vendorEntity = typeaheadData?.options?.find(({ vendorName }) => {
          return vendorName.includes(updatedFilters[0].conditions[i].value);
        });

        if (vendorEntity) {
          setIsFilterUpdatRequired(false);
          setFetchVehicles(true);
          setVehicleFilters(updatedFilters);
          updatedFilters[0].conditions[i].value = vendorEntity?.id;
        }
      }
    }
  }, [filters, typeaheadData]);

  useEffect(() => {
    if (isGetVehicles && !isFilterUpdatRequired) {
      const sortBy = sortMap[sortingState.sortBy] || sortingState.sortBy;

      const formatedFilter = {
        operator: filters[0].operator,
        value: filters[0].conditions,
      };

      const unformatedVariable = {
        ...initVariables,
        limit: pageCount,
        offset,
        filters,
      };

      if (sortBy) {
        unformatedVariable.order = [[sortBy, sortingState.sortOrder]];
      }

      const paginationInput = {
        limit: unformatedVariable.limit,
        offset: unformatedVariable.offset,
        order: unformatedVariable.order,
      };

      const variables = {
        paginationInput,
        filters: formatedFilter,
        includeables,
        appliedFilters,
      };

      setSelectedRows([]);

      getVehicleInventory({
        variables,
      });
    }
  }, [
    initVariables,
    pageCount,
    offset,
    sortingState,
    JSON.stringify(filters),
    isGetVehicles,
  ]);

  const handlePaginationChange = (pageNum, itemsPer) => {
    setFetchVehicles(true);

    if (itemsPer !== pageCount) {
      setPageCount(itemsPer);
      resetPage(true);
      setOffset(0);
    }
    const newOffset = (pageNum - 1) * itemsPer;
    setOffset(newOffset);
  };

  const DropDownItems = [
    {
      label: 'Export data',
      iconName: 'file_download',
      onClick: () => {
        if (!tableData?.length || tableData?.length === 0) {
          alertUtils.showErrorAlert(
            <span>{exportMessages.exportMsgNoData('vehicle').message}</span>,
          );
          return;
        }
        if (tableData?.length > 95000) {
          alertUtils.showErrorAlert(
            <span>{exportMessages.exportMsgTooMany.message}</span>,
          );
        } else {
          mutation.exportVehicles({
            variables: {
              request: {
                reportType: 9,
                deliveryMethod: 1,
                reportCriteria: {
                  order: [],
                  filters: {
                    operator: filters[0].operator,
                    value: filters[0].conditions,
                  },
                  appliedFilters,
                  includeables: [...includeables, 'ald'],
                },
              },
            },
          });
        }
      },
    },
    {
      label: 'Download registration cards',
      iconName: 'topic',
      onClick: () => {
        const selectedVehs = data.getVehiclesListing.rows.filter((v) =>
          selectedRows.includes(v.vin),
        );
        startBulkDownload(selectedVehs);
      },
      hiddenItem: selectedRows.length === 0,
    },
  ];

  const tableHeaderData = [
    {
      Header: 'Vehicle',
      accessor: 'id',
      headerClassName: 'width-card-lg',
    },
    {
      Header: 'Plate Number',
      accessor: 'tagNumber',
      headerClassName: 'width-card-lg',
      sortable: false,
    },
    {
      Header: 'Vehicle Lifecycle',
      accessor: 'lifecycleIndicator',
      headerClassName: 'width-card-lg',
    },
    { Header: 'Actions', accessor: 'actions', sortable: false },
  ];

  if (viewGovFleet) {
    tableHeaderData.splice(3, 0, {
      Header: 'Agency',
      accessor: 'agency',
      sortable: false,
      headerClassName: 'width-card-lg',
    });
  }
  return (
    <>
      {getVehicleInventoryError && (
        <ServerErrorMessage graphQLErrors={getVehicleInventoryError} />
      )}

      <BulkRegModal />
      {isAddToMotorPoolModalOpen && (
        <AddToMotorPoolModal
          isOpen={isAddToMotorPoolModalOpen}
          closeModal={closeAddToMotorPoolModal}
          vin={selectedVehicle.id}
          showAlert={(message) => {
            alertUtils.showSuccessAlert(<span>{message}</span>);
          }}
        />
      )}
      <FTF
        upRightProps={{
          DropDownItems,
        }}
        lowLeftProps={{
          onChange: async (conditions) => {
            const vendorFilterIndex = conditions.findIndex(
              ({ key }) => key === vehicleFilterKeys.VEHICLE_LOCATION,
            );
            if (vendorFilterIndex > -1) {
              getFilteredVendors({
                variables: {
                  key: 'id',
                  search: conditions[vendorFilterIndex]?.value,
                },
              });
            }
            setFilterApplied(conditions.length > 0);
            setFilters(conditions);
          },
          order: [[sortingState.sortBy, sortingState.sortOrder]],
          updateAppliedFilters: (f) => {
            const filterLabels = f.map((filter) => filter.label);
            setAppliedFilters(filterLabels);
          },
        }}
        lowRightProps={{
          tableData,
          loading,
          selectedRows,
          pageCount,
          dataCount,
        }}
        filterToggle
      />
    </>
  );
};

export default FilterGrid;
