import { Button, Spinner, Table } from '@gsa/afp-component-library';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useReducer } from 'react';

import { AttachmentPropType } from '../../utilities/types';
import './attachment.css';
import AttachmentDelete from './attachment-delete';
import AttachmentEdit from './attachment-edit';
import AttachmentRowAction from './attachment-row-action';
import { useAttachmentComponent } from './component-context';

export const NoData = ({ title, message }) => {
  return (
    <div data-testid="attachment-no-data" className="attachment-no-data">
      <div className="attachment-no-data-title">{title}</div>
      <div className="attachment-no-data-message">{message}</div>
    </div>
  );
};

NoData.defaultProps = {
  title: <h3>No Supporting Documents Available</h3>,
  message: (
    <p>
      You don’t have any supporting documents at this time. Please check back
      again or start uploading documents. Input accepts a single file. Supported
      file formats are .jpg, .jpeg, .png, .bmp, .pdf, .doc, .docx, .rtf, .xls,
      .xlsx, .ppt, .pptx, .txt, .csv and file size should be under 5 MB.
    </p>
  ),
};

NoData.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.string,
  ]),
  message: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.string,
  ]),
};

const AttachmentDownloadLink = ({ attachment }) => {
  const { onDownload } = useAttachmentComponent();

  return (
    <Button
      data-testid="file-name-download-link"
      variant="unstyled"
      onClick={() => {
        if (onDownload) onDownload(attachment);
      }}
      label={attachment.name}
    />
  );
};
AttachmentDownloadLink.propTypes = {
  attachment: PropTypes.shape(AttachmentPropType).isRequired,
};

AttachmentDownloadLink.propTypes = {
  attachment: PropTypes.shape({ name: PropTypes.string }).isRequired,
};

const NameCell = ({ row }) => (
  <AttachmentDownloadLink attachment={row.original} />
);
NameCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({ name: PropTypes.string }).isRequired,
  }).isRequired,
};

export const attachmentData = (rows, allowChanges) =>
  rows.map((row) => {
    const { createdByUser, updatedAt, user, description } = row;
    return {
      name: {
        value: <NameCell row={{ original: row }} />,
      },
      updatedAt: {
        value: moment(updatedAt).format('L'),
      },
      createdByUser: {
        value: (user || {}).fullName || createdByUser?.fullName,
      },
      description: {
        value: description,
      },
      action: {
        value: (
          <AttachmentRowAction
            row={{ original: row }}
            allowChanges={allowChanges}
          />
        ),
      },
    };
  });

const AttachmentTable = ({ noData: NoDataComponent, allowChanges }) => {
  const { rows: attachments, loading, onSort } = useAttachmentComponent();

  const columns = [
    {
      name: 'Document Name',
      accessor: 'name',
      sortable: true,
      className: 'task-manager-name',
    },
    {
      name: 'Date Uploaded',
      accessor: 'updatedAt',
      sortable: true,
      className: 'task-manager-updatedat',
    },
    {
      name: 'Uploaded By',
      accessor: 'createdByUser',
      sortable: false,
      className: 'task-manager-createdByUser',
    },
    {
      name: 'Description',
      accessor: 'description',
      sortable: true,
      className: 'task-manager-description',
    },
    {
      name: 'Actions',
      accessor: 'action',
      sortable: false,
      className: 'task-manager-Action',
    },
  ];
  const data = React.useMemo(
    () =>
      attachments.map(({ __typename, ...attachment }) => {
        return attachment;
      }),
    [attachments],
  );

  const sortingReducer = (state, action) => {
    switch (action.type) {
      case 'sortBy':
        return { sortBy: action.payload, sortOrder: 'ASC' };
      case 'sortOrder':
      default:
        return { ...state, sortOrder: action.payload };
    }
  };

  const initSortingState = {
    sortBy: 'updatedAt',
    sortOrder: 'DESC',
  };

  const [sortingState, setSortingState] = useReducer(
    sortingReducer,
    initSortingState,
  );

  // Fire sort.
  useEffect(() => {
    const order = `${sortingState.sortBy} ${sortingState.sortOrder}`;
    if (onSort) onSort(order);
  }, [sortingState]);

  // Loading message.
  if (loading) return <Spinner size="small" />;

  // No data message.
  if (!data.length) return <NoDataComponent />;

  return (
    <div className="attachment-table" data-testid="attachment-table">
      {/* Delete Confirmation Modal */}
      <AttachmentDelete />
      {/* Edit Modal */}
      <AttachmentEdit />
      <Table
        header={columns}
        bordered={false}
        caption=""
        style={{ width: '100%' }}
        fixed
        sortingState={sortingState}
        setSortingState={setSortingState}
        rows={attachmentData(data, allowChanges)}
      />
    </div>
  );
};

AttachmentTable.defaultProps = {
  noData: NoData,
  allowChanges: true,
};

AttachmentTable.propTypes = {
  noData: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.string,
  ]),
  allowChanges: PropTypes.bool,
};

export default AttachmentTable;
