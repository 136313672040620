import React, { useMemo } from 'react';
import { FilterTableFrame } from '@gsa/afp-component-library';
import ScheduleListingTable from './schedules-listing-table';
import SchedulesSidebar from './schedules-sidebar';
import ScheduleActions from './schedule-actions';

const SchedulesListing = () => {
  const FTF = useMemo(
    () =>
      FilterTableFrame(
        null,
        ScheduleActions,
        SchedulesSidebar,
        ScheduleListingTable,
      ),
    [],
  );

  return <FTF filterToggle />;
};

export default SchedulesListing;
