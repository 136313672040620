import React from 'react';
import { getCodeAndNameByOwnerShip } from 'utilities/format';
import { isFeatureEnabled } from 'utilities/feature-toggle';
import {
  VehicleTableCell,
  VehicleDetailsRow,
  VehicleRowActionButton,
} from './table-widgets';
import { newCarTitleConstructor } from '../../../utilities/car-title';
import {
  determineRegistrationStatus,
  VehicleRegistrationStatusTag,
} from '../../vehicle-registration-status-tag';
import { LifeCycleIndicatorTag } from 'components/vehicle-details/widgets/life-cycle';
import { emdash } from 'components/common';

const getRowMenuItems = (
  reg,
  registrationAction,
  canRegister,
  exemptPlate,
  registerStatus,
  canAddVehicleToPool,
) => {
  const registrationItem =
    reg || exemptPlate
      ? {
          label: 'Edit vehicle registration',
          action: 'editRegistration',
          icon: 'edit',
        }
      : {
          label: 'Register vehicle',
          action: 'register',
          icon: 'edit',
        };

  let items = [];

  if (registerStatus === 'Unregistered') {
    items.push({
      label: 'View vehicle details',
      action: 'vehicleDetails',
      icon: 'visibility',
    });
  } else if (registerStatus === 'Incomplete' && (!reg || !exemptPlate)) {
    items.push({
      label: 'Edit vehicle registration',
      action: 'editRegistration',
      icon: 'edit',
    });
  }

  if (registrationAction && canRegister) {
    items = items.filter((item) => item.label !== registrationItem.label);
    items.push({
      ...registrationItem,
    });
  }

  if (registerStatus === 'Registered') {
    items.push({
      label: 'Download vehicle registration card',
      action: 'downloadRegistration',
      icon: 'pdf',
      iconType: 'custom',
    });
  }

  if (canAddVehicleToPool && isFeatureEnabled('motor-pools')) {
    items.push({
      icon: 'add',
      label: 'Add vehicle to pool',
      action: 'addVehicleToMotorPool',
    });
  }

  return items;
};

const formatVehicleTableRow = (
  rowItem,
  buttonAction,
  canRegister,
  i,
  canAddVehicleToPool,
) => {
  // To be removed later
  const { tag, primaryPoC, secondaryPoC } = rowItem;

  const {
    id,
    vin,
    agencyName,
    customerAgencyCode,
    lifecycleIndicator,
    modelCode,
    modelYear,
    tagNumber,
    modelDescription,
    makeName,
    modelName,
    exemptPlate,
  } = rowItem;

  const showRegistrationAction = () => {
    return id && (!tag?.id || (primaryPoC && secondaryPoC));
  };

  return {
    id: (
      <VehicleTableCell
        key={`${id}-${i}`}
        title={newCarTitleConstructor(
          modelYear,
          makeName,
          modelName,
          modelCode,
          modelDescription,
        )}
        vin={vin}
      />
    ),
    tagNumber: tagNumber || emdash,
    lifecycleIndicator: lifecycleIndicator ? (
      <LifeCycleIndicatorTag value={lifecycleIndicator} />
    ) : (
      emdash
    ),
    agency: `${customerAgencyCode} - ${agencyName}`,
    actions: (
      <VehicleRowActionButton
        vin={vin}
        onClick={buttonAction}
        menuItems={getRowMenuItems(
          tagNumber,
          showRegistrationAction(),
          canRegister,
          exemptPlate,
          determineRegistrationStatus(rowItem),
          canAddVehicleToPool,
        )}
        vehicle={rowItem}
      />
    ),
  };
};

export const formatDataForTable = (
  queryData,
  buttonAction,
  canRegister,
  canAddVehicleToPool,
) => {
  const rows = queryData?.getVehiclesListing?.rows;

  if (rows) {
    return rows.map((row, i) => {
      const canRegisterVehicle = canRegister(row);
      return formatVehicleTableRow(
        row,
        buttonAction,
        canRegisterVehicle,
        i,
        canAddVehicleToPool,
      );
    });
  }
  return [];
};

export default {
  formatDataForTable,
};
