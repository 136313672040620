/* eslint-disable no-alert */
/* eslint-disable react/prop-types */
// eslint-disable-next-line react/prop-types
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  AFPTable,
  EmptyState,
  Pagination,
  Spinner,
  Tooltip,
  Icon,
} from '@gsa/afp-component-library';
import { useHistory } from 'react-router-dom';
import { emdash } from 'components/common';
import moment from 'moment';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import { useMileageExpressFilter } from './filters/mileage-express-filter-provider';
import { useMileageExpress } from './mileage-express-provider';
import { RowSubDetail } from './mileage-express-row-details';
import InterceptionModal from './mileage-express-interception-modal';
import MileageExpressStatusBadge from './mileage-express-status-badge';
import EditableCell from '../../utilities/editable-cell';
import {
  canUpdateGFVehicleAdmin,
  canUpdateGFVehicleFSR,
} from '../../utilities/authorization';
import MileageWithSignificantDataModal from './mileage-with-significant-data-modal';

const alphanumericRegExp = /^[0-9]+$/;

const ErrorMapping = {
  isLessThanOne: 'DayInUse is less than one',
  mileageRequired: 'Odometer is a required field.',
  mileagePositive: 'Odometer must be greater than 0',
  moreThanCurrentMonthDayCount: 'More than current month days',
  nonNumericMileage: 'Only numeric values are allowed.',
  mileageLessThanReportedPM:
    'Mileage must be equal to or \ngreater than previous mileage.',
  mileageGraterThan9999:
    "Mileage must not exceed +/- 9,999 \nmiles from the vehicle's current mileage.",
  contactFSR:
    "Contact your FSR to report mileage greater \nthan +/-9,999 from the vehicle's current \nmileage.",
};

const tableRef = React.createRef();
const initialPaginationState = {
  limit: 10,
  offset: 0,
  currentPage: 1,
  isReset: false,
};
const initialOrderState = 'mileageReportingStatus ASC';
const initBeforeUnLoad = () => {
  window.onbeforeunload = (e) => {
    e.preventDefault();
    if (e) {
      e.returnValue = '';
    }
    return '';
  };
};

const MileageExpressListingTable = () => {
  const [modifiedRecords, setModifiedRecords] = useState([]);
  const [paginationState, setPaginationState] = useState(
    initialPaginationState,
  );
  const [order, setOrder] = useState(initialOrderState);
  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [skipPageReset, setSkipPageReset] = useState(false);

  const history = useHistory();

  const {
    getMileageExpressList,
    mileageExpressList,
    mileageExpressListLoading,
    dispatchAction,
    resetModal,
    modifiedMileageExpress,
    resetMileageExpresss,
  } = useMileageExpress();

  const { filters } = useMileageExpressFilter();

  const getData = () => {
    getMileageExpressList({
      variables: {
        limit: paginationState.limit,
        offset: paginationState.offset,
        order,
        filters,
      },
    });
  };

  window.onload = () => {
    if (modifiedMileageExpress?.length > 0) {
      initBeforeUnLoad(modifiedMileageExpress);
    }
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (modifiedMileageExpress?.length > 0) {
      initBeforeUnLoad(modifiedMileageExpress);
      const currentLocation = history?.location;
      const unListen = history.listen((location) => {
        const newLocation = location?.pathname;
        if (currentLocation?.pathname !== newLocation) {
          if (
            window.confirm(
              'Changes you made may not be saved. Are you sure you want to leave?',
            )
          ) {
            // continue to navigate
          } else {
            window.close();
            history.push({
              pathname: currentLocation?.pathname,
              search: currentLocation?.search,
            });
          }
        }
      });
      return unListen;
      // eslint-disable-next-line no-else-return
    } else {
      window.onbeforeunload = () => {
        // remove window.onbeforeunload functionality when no modified Mileages exist
      };
    }
  }, [modifiedMileageExpress]);

  useEffect(() => {
    resetModal();
  }, []);

  useEffect(() => {
    if (resetMileageExpresss) {
      setData(originalData);
      setModifiedRecords(originalData);
      setPaginationState(initialPaginationState);
      setOrder(initialOrderState);
      const [field, direction] = initialOrderState.split(' ');
      tableRef.current.toggleSortBy(field, direction.toLowerCase() === 'desc');
      dispatchAction('SET_MODIFIED_MILEAGES', []);
      dispatchAction('RESET_MILEAGES', false);
    }
  }, [resetMileageExpresss]);

  useEffect(() => {
    if (modifiedMileageExpress?.length > 0) {
      dispatchAction('SET_INTERCEPT_SAVE', true);
    } else if (filters?.conditions?.length) {
      getData();
    } else {
      dispatchAction('SET_MILEAGE_LIST', {
        count: 0,
        hasMore: false,
        rows: [],
      });
    }
  }, [paginationState, order, filters]);

  useEffect(() => {
    setPaginationState({
      ...paginationState,
      offset: 0,
      currentPage: 1,
      isReset: true,
    });
  }, [filters]);

  useEffect(() => {
    setData(mileageExpressList.rows);
    setModifiedRecords(mileageExpressList.rows);
    setOriginalData(mileageExpressList.rows);
  }, [mileageExpressList]);

  useEffect(() => {
    setSkipPageReset(false);
    const modifiedRows = data.filter((item) => item.modified);
    setModifiedRecords(data);
    dispatchAction('SET_MODIFIED_MILEAGES', modifiedRows);
  }, [data]);

  useEffect(() => {
    setSkipPageReset(false);
    const modifiedRows = modifiedRecords.filter(
      (item) => item.modified && item?.currentMileage !== '',
    );
    dispatchAction('SET_MODIFIED_MILEAGES', modifiedRows);
  }, [modifiedRecords]);

  const columns = useMemo(() => {
    const columnList = [
      {
        Header: 'License plate',
        accessor: 'vehicle.tagNumber',
        sortable: true,
      },
      {
        Header: 'Previous mileage',
        accessor: 'previousMileage',
        sortable: false,
        Cell: ({ value, row }) => {
          const date = new Date();
          const currentMileage = row?.original?.currentMileage;
          const mileageReportingMonth = row?.original?.mileageReportingMonth;
          const mileageReportingYear = row?.original?.mileageReportingYear;
          const mileageReportingStatus = row?.original?.mileageReportingStatus;
          const isCurrentMonthReported =
            mileageReportingMonth === date.getMonth() + 1 &&
            date.getFullYear() === mileageReportingYear;
          // for telematics if it is not reported then we need to show the current mileage
          if (
            mileageReportingStatus === 'Telematics' &&
            !isCurrentMonthReported
          ) {
            return currentMileage;
          }

          return value;
        },
      },
      {
        Header: (
          <div className="display-flex flex-align-center">
            Ending mileage
            <Tooltip
              className="usa-button--unstyled"
              label={
                <ul className="usa-list margin-0">
                  <li className="text-thin">
                    {ErrorMapping.nonNumericMileage}
                  </li>
                  <li className="text-thin">
                    {ErrorMapping.mileageLessThanReportedPM}
                  </li>
                  <li className="text-thin">
                    {ErrorMapping.mileageGraterThan9999}
                  </li>
                  <li className="text-thin">{ErrorMapping.contactFSR}</li>
                </ul>
              }
              position="bottom"
            >
              <Icon
                iconName="info"
                className="text-primary margin-left-1 text-ink"
              />
            </Tooltip>
          </div>
        ),
        accessor: 'currentMileage',
        sortable: false,
        Cell: ({ value, row, column, updateCellData }) => {
          // we need to enable the edit for telematics only after 20th of the month if not reported
          // and editable cell should be blank
          const now = new Date();
          const telematicsEditEnableDate = 20;
          const mileageReportingMonth = row?.original?.mileageReportingMonth;
          const mileageReportingYear = row?.original?.mileageReportingYear;
          const mileageReportingStatus = row?.original?.mileageReportingStatus;
          const isCurrentMonthReported =
            mileageReportingMonth === now.getMonth() + 1 &&
            now.getFullYear() === mileageReportingYear;
          const enableTelematicsEdit =
            now.getDate() > telematicsEditEnableDate && !isCurrentMonthReported;

          if (mileageReportingStatus === 'Reported') {
            return value || emdash;
          }

          if (
            mileageReportingStatus === 'Telematics' &&
            !enableTelematicsEdit
          ) {
            if (!isCurrentMonthReported) {
              return emdash;
            }
            return value || emdash;
          }

          const enableEditingForTelematics =
            mileageReportingStatus === 'Telematics' && enableTelematicsEdit;
          const isReadyForUpdate =
            mileageReportingStatus === 'Ready for update';

          return (
            <>
              <EditableCell
                key={`${value}-current-mileage`}
                inputType="number"
                row={row}
                column={column}
                defaultValue={
                  enableEditingForTelematics || isReadyForUpdate ? '' : value
                }
                updateCellData={updateCellData}
                aria-label={`${column?.id} editable field`}
              />
            </>
          );
        },
      },
      {
        Header: 'Days used',
        accessor: 'daysInUse',
        sortable: false,
        // eslint-disable-next-line react/prop-types
        Cell: ({ value, row, column, updateCellData }) => {
          const now = new Date();
          const mileageReportingMonth = row?.original?.mileageReportingMonth;
          const mileageReportingYear = row?.original?.mileageReportingYear;
          const mileageReportingStatus = row?.original?.mileageReportingStatus;
          const telematicsEditEnableDate = 20;
          const isCurrentMonthReported =
            mileageReportingMonth === now.getMonth() + 1 &&
            now.getFullYear() === mileageReportingYear;

          const enableTelematicsEdit = now.getDate() > telematicsEditEnableDate;

          if (
            mileageReportingStatus === 'Telematics' &&
            !isCurrentMonthReported &&
            !enableTelematicsEdit
          ) {
            return emdash;
          }

          return (
            <>
              <EditableCell
                key={`${value}-day-in-use`}
                inputType="number"
                row={row}
                column={column}
                defaultValue={
                  row?.original?.mileageReportingStatus !== 'Ready for update'
                    ? value
                    : ''
                }
                updateCellData={updateCellData}
                aria-label={`${column?.id} editable field`}
              />
            </>
          );
        },
      },
      {
        Header: 'Mileage status',
        accessor: 'mileageReportingStatus',
        sortable: true,
        Cell: ({ value }) => {
          return value ? <MileageExpressStatusBadge status={value} /> : emdash;
        },
      },
    ];

    return columnList;
  }, []);

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    // Calculate new offset.
    const offset = (currentPage - 1) * itemsPerPage;
    setPaginationState({
      limit: itemsPerPage,
      offset,
      currentPage,
    });
  };

  const ability = useAppAbility();
  const canReportMoreThanThreshold =
    canUpdateGFVehicleAdmin(ability) || canUpdateGFVehicleFSR(ability);

  const handleValidationError = (errorMessage) => {
    return [false, errorMessage];
  };

  const validateCurrentMileage = (rowIndex, value, mileageReportingStatus) => {
    const isValidCell = true;
    const errorMessage = '';
    let inputClass = '';
    const thresholdMileage = 9999;
    const mileage = Number(value);
    const previousMileage = Number(
      mileageExpressList?.rows[rowIndex]?.previousMileage,
    );
    if (
      !value &&
      (mileageReportingStatus === 'Ready for update' ||
        mileageReportingStatus === 'Telematics')
    ) {
      // user can leave the field empty if the status is 'Ready for update'
      return [true, '', ''];
    }
    if (!value) {
      return handleValidationError(ErrorMapping.mileageRequired);
    }
    if (mileage <= 0) {
      return handleValidationError(ErrorMapping.mileagePositive);
    }
    if (!alphanumericRegExp.test(value)) {
      return handleValidationError(ErrorMapping.nonNumericMileage);
    }
    if (
      (!canReportMoreThanThreshold &&
        Math.abs(mileage - previousMileage) > thresholdMileage) ||
      (!canReportMoreThanThreshold &&
        mileage - previousMileage < -thresholdMileage)
    ) {
      return handleValidationError(ErrorMapping.mileageGraterThan9999);
    }

    if (
      canReportMoreThanThreshold &&
      Math.abs(mileage - previousMileage) > 10000
    ) {
      dispatchAction('SET_ALERT_MESSAGE', {
        type: 'warning',
        message:
          'One or more mileage entries exceeds +/- 9,999 miles. Verify mileage in highlighted areas before saving.',
        context: 'endMileage',
      });
      inputClass = 'border-accent-warm border-05';
    } else {
      dispatchAction('SET_ALERT_MESSAGE', {
        type: '',
        message: '',
        context: '',
      });
    }
    return [isValidCell, errorMessage, inputClass];
  };

  const valideDayInUse = (rowIndex, value) => {
    const isValidCell = true;
    const errorMessage = '';
    const monthDayCount = moment(
      modifiedRecords?.[rowIndex]?.mileageDate || new Date(),
    ).daysInMonth();
    const daysInUse = Number(value);
    if (daysInUse > monthDayCount) {
      return handleValidationError(ErrorMapping.moreThanCurrentMonthDayCount);
    }

    if (value && daysInUse < 1) {
      return handleValidationError(ErrorMapping.isLessThanOne);
    }

    return [isValidCell, errorMessage];
  };

  const getOriginalValue = (row, column) => {
    const status = row?.mileageReportingStatus;
    if (
      (column === 'daysInUse' || column === 'currentMileage') &&
      status === 'Ready for update'
    ) {
      return '';
    }
    return row[column];
  };

  const MILEAGE_THRESHOLD = 9999;
  const ALERT_MESSAGE = {
    type: 'warning',
    message:
      'One or more mileage entries exceeds +/- 9,999 miles. Verify mileage in highlighted areas before saving.',
    context: 'endMileage',
  };
  const CLEAR_MESSAGE = {
    type: '',
    message: '',
    context: '',
  };

  // Helper to calculate if a mileage difference is significant
  function hasSignificantMileageDifference(modifiedMileage, originalMileage) {
    return Math.abs(modifiedMileage - originalMileage) > MILEAGE_THRESHOLD;
  }

  // Main function
  function checkForMileageDiscrepancy(modifiedData) {
    // Filter entries with significant mileage differences
    const significantMileageEntries = modifiedData
      .filter((modified, index) => {
        return hasSignificantMileageDifference(
          modified.currentMileage,
          originalData[index].currentMileage,
        );
      })
      .map((modified, index) => ({
        modified,
        original: originalData[index],
      }));

    // Determine the message based on discrepancies
    const message =
      significantMileageEntries?.length > 0 ? ALERT_MESSAGE : CLEAR_MESSAGE;

    // Dispatching actions
    dispatchAction('SET_ALERT_MESSAGE', message);
    dispatchAction(
      'SET_ENTRIES_WITH_SIGNIFICANT_MILEAGE_DIFFERENCE',
      significantMileageEntries,
    );
  }

  const handleCellData = (columnId, rowIndex, value, isValueValid) => {
    const modifiedOnes = modifiedRecords.map((row, index) => {
      if (index === rowIndex) {
        const modifiedRecordFields = {
          ...modifiedRecords[rowIndex]?.modifiedRecordFields,
          [columnId]:
            value !== getOriginalValue(originalData[rowIndex], columnId),
        };

        return {
          ...modifiedRecords[rowIndex],
          [columnId]: isValueValid ? value : '',
          modifiedRecordFields,
          modified: Object.values(modifiedRecordFields).some(
            (modf) => modf === true,
          ),
          errors: {
            ...modifiedRecords[rowIndex]?.errors,
            [columnId]: isValueValid,
          },
        };
      }
      return row;
    });

    if (canReportMoreThanThreshold) {
      checkForMileageDiscrepancy(modifiedOnes);
    }

    setModifiedRecords(modifiedOnes);
  };

  const validateCellData = (rowIndex, columnId, cellValue, original) => {
    let isValidCell = true;
    let errorMessage = '';
    let inputClass = '';

    if (cellValue.isDirty) {
      if (columnId === 'currentMileage') {
        setSkipPageReset(true); // turn on the flag to not reset the page
        [isValidCell, errorMessage, inputClass] = validateCurrentMileage(
          rowIndex,
          cellValue.value,
          original?.mileageReportingStatus,
        );
      }
      if (columnId === 'daysInUse') {
        setSkipPageReset(true); // turn on the flag to not reset the page
        [isValidCell, errorMessage] = valideDayInUse(rowIndex, cellValue.value);
      }
    }
    handleCellData(columnId, rowIndex, cellValue.value, isValidCell);

    return {
      columnId,
      rowIndex,
      value: cellValue.value,
      isValidCell,
      errorMessage,
      inputClass,
    };
  };

  const renderRowSubComponent = useCallback(
    ({ row }) => {
      return (
        <div className="display-flex flex-justify-center">
          <div className="grid-col-11">
            <RowSubDetail updateCellData={validateCellData} row={row} />
          </div>
        </div>
      );
    },
    [modifiedRecords],
  );

  return (
    <>
      <AFPTable
        fullWidth
        ref={tableRef}
        testId="mileageExpress-listing-table"
        columns={columns}
        data={!mileageExpressListLoading ? data : []}
        defaultSort={order}
        onSort={setOrder}
        expandable
        renderRowSubComponent={renderRowSubComponent}
        updateCellData={validateCellData}
        skipPageReset={skipPageReset}
      />
      {mileageExpressListLoading && <Spinner className="padding-y-9" />}
      {mileageExpressList?.rows?.length > 0 && (
        <Pagination
          fullWidth
          variant="advanced"
          itemsPerPageOptions={[10, 25, 50]}
          itemsCount={mileageExpressList.count}
          itemsPerPage={paginationState.limit}
          currentPage={paginationState.currentPage}
          onPageChange={handlePaginationChange}
          isReset={paginationState.isReset}
        />
      )}
      {(!mileageExpressList || mileageExpressList.rows.length === 0) &&
        !mileageExpressListLoading && (
          <div className="bg-gray-3 padding-y-5">
            <div className="text-center padding-y-4">
              <EmptyState alt="Image not available" hasBackground />
            </div>
            <div className="text-center text-bold">No data available</div>
          </div>
        )}

      <MileageWithSignificantDataModal />

      <InterceptionModal />
    </>
  );
};

export default MileageExpressListingTable;
