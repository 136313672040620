/* eslint-disable react/prop-types */
import React, { useState, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Alert,
  SelectDropdown,
  Typeahead,
  TextInput,
  Label,
  Fieldset,
  RadioButton,
} from '@gsa/afp-component-library';
import NumberFormat from 'react-number-format';
import { useLazyQuery } from '@apollo/client';
import { VehiclePropType } from 'utilities/types';
import usePortalModal from 'utilities/portal-modal';
import {
  GET_MAKES_BY_PARTIAL_NAME,
  GET_MODELS_BY_PARTIAL_NAME,
  GET_VEHICLE_EDIT_OPTIONS,
} from 'services/data-layer';
import {
  assetLookupOptions,
  DEFAULT_SELECT_OPTION_LABEL,
} from 'utilities/consts';
import { getOrDefaultVal } from 'utilities/format';
import { unknownField } from 'utilities/unknown-field';
import { VEHICLE_TYPE_OPTIONS } from 'components/vehicle-registration/widgets/select-vehicle-type';

import './vehicle-edit.css';

// Character limits
const MAX_LEN_SERIES = 30;
const MAX_LEN_TRIM = 50;

// Generate years from current year
const FROM_YEAR = 1970;
const CURRENT_YEAR = new Date().getFullYear();
const VEHICLE_YEAR_OPTIONS = [
  {
    value: '',
    label: '- Select -',
  },
];

for (let index = CURRENT_YEAR + 2; index >= FROM_YEAR; index -= 1) {
  VEHICLE_YEAR_OPTIONS.push({
    value: index,
    label: index,
  });
}

const formReducer = (state, newState) => {
  return {
    ...state,
    ...newState,
  };
};

const LAW_ENFORCEMENT_CODE = 110;
const EMERGENCY_RESPONSE_CODE = 111;
const OVERSEAS_CODE = 112;
const COVERED_CODE = 113;
const OTHER_CODE = 114;

const executiveFleetOptions = {
  Y: 'Yes',
  N: 'No',
};

const getKeyByValue = (object, value) => {
  return Object.keys(object).find((key) => object[key] === value);
};

const requiredFields = {
  makeName: (state) => state.makeName,
  modelName: (state) =>
    state.modelName ||
    (state.modelCode &&
      state.modelCode !== '-1' &&
      state.modelCode !== unknownField)
      ? state.modelCode
      : '',
  year: (state) => state.modelYear,
  color: (state) => state.colorCode,
  fuel: (state) => state.fuel,
  fastReportable: (state) =>
    getOrDefaultVal({
      dataObject: state,
      dataKey: 'fastReportable',
    }),
  itemInventoryStatusCode: (state) => state.itemInventoryStatusCode,
};

const validateFields = (reqFields, state) => {
  if (reqFields && state) {
    return Object.keys(reqFields).reduce((validations, field) => {
      const selector = reqFields[field];
      const fieldValue = selector(state);
      // 0 is valid
      if (
        fieldValue === undefined ||
        fieldValue === null ||
        fieldValue === ''
      ) {
        return { ...validations, [field]: 'This is a required field' };
      }
      return validations;
    }, {});
  }
  return {};
};

const formatOdometerReading = (reading) => {
  return reading !== ''
    ? parseFloat(reading.toString().replace(/,/g, '')).toLocaleString()
    : 0;
};

const VehicleEdit = ({
  vehicle,
  onSave,
  onClose,
  fastReportableOptions,
  hasFMVRSAdminRole,
}) => {
  const [VehicleModal, openModal, closeModal] = usePortalModal();
  const [state, dispatch] = useReducer(formReducer, {
    color: vehicle?.makeColorName,
    displayVamExempt: vehicle?.assetUsage?.vamExempt || '',
    fastReportable: getOrDefaultVal({
      dataObject: vehicle,
      dataKey: 'fastReportable',
    }),
    fuel: vehicle?.fuelCode,
    itemInventoryStatusCode: vehicle?.inventoryStatus?.id || '',
    makeName: vehicle?.vehicleMake?.makeName || '',
    makeCode: vehicle?.vehicleMake?.makeCode || '',
    modelName: vehicle?.vehicleModel?.modelDescription || '',
    modelYear: vehicle?.modelYear || '',
    modelCode: vehicle?.modelCode || '',
    colorCode: vehicle?.makeColor?.makeColorCode || '',
    colorName: vehicle?.makeColor?.name || '',
    odometer: vehicle?.newOdometerReading?.odometer || '',
    additionalTag: vehicle?.ald?.stateTag || '',
    itemType: vehicle?.itemType || '',
    tankSize: vehicle?.assetTechnical?.assetTechnicalIce?.tankSize
      ? vehicle?.assetTechnical?.assetTechnicalIce?.tankSize.toString()
      : '',
    vamExemptOpts: [
      {
        label: assetLookupOptions[110],
        value: LAW_ENFORCEMENT_CODE,
      },
      {
        label: assetLookupOptions[111],
        value: EMERGENCY_RESPONSE_CODE,
      },
      {
        label: assetLookupOptions[112],
        value: OVERSEAS_CODE,
      },
      {
        label: assetLookupOptions[113],
        value: COVERED_CODE,
      },
      {
        label: assetLookupOptions[114],
        value: OTHER_CODE,
      },
    ],
    tag: vehicle?.tagNumber,
    trim: vehicle?.vehicleTrim,
    series: vehicle?.vehicleSeries,
  });
  const [errors, setErrors] = useState({});
  const [errorsCount, setErrorsCount] = useState(0);
  const [makeNames, setMakeNames] = useState([]);
  const [seriesText, setSeriesText] = useState(vehicle?.vehicleSeries || '');
  const [gvwrText, setGvwrText] = useState(vehicle?.assetTechnical?.gvwr || '');
  const [trimText, setTrimText] = useState(vehicle?.vehicleTrim || '');
  const [tankSize, setTankSize] = useState(
    vehicle?.assetTechnical?.assetTechnicalIce?.tankSize
      ? vehicle?.assetTechnical?.assetTechnicalIce?.tankSize.toString()
      : '',
  );
  const [agencyUseOneText, setAgencyUseOneText] = useState(
    vehicle?.assetUsage?.agencyUse1,
  );
  const [agencyUseTwoText, setAgencyUseTwoText] = useState(
    vehicle?.assetUsage?.agencyUse2,
  );
  const [agencyUseThreeText, setAgencyUseThreeText] = useState(
    vehicle?.assetUsage?.agencyUse3,
  );
  const [agencyUseFourText, setAgencyUseFourText] = useState(
    vehicle?.assetUsage?.agencyUse4,
  );

  const [modelNames, setModelNames] = useState([]);
  const [selectedMake, setSelectedMake] = useState(null);
  const [modelPartial, setPartialModel] = useState('');
  const [, setIsValueChanged] = useState(false);
  const [getVehicleEditOptions, { data: editOptionsData }] = useLazyQuery(
    GET_VEHICLE_EDIT_OPTIONS,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const [getMakesByPartialName, { data: makesData }] = useLazyQuery(
    GET_MAKES_BY_PARTIAL_NAME,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const [getModelsByPartialModelName, { data: modelData }] = useLazyQuery(
    GET_MODELS_BY_PARTIAL_NAME,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const [fuelType, setFuelType] = useState('');

  const [selectedRadioInput, setSelectedRadioInput] = useState(
    executiveFleetOptions[vehicle?.assetUsage?.executiveFleet] || '',
  );

  useEffect(() => {
    openModal();
    if (vehicle?.vehicleMake?.makeName) {
      getVehicleEditOptions({
        variables: { makeName: vehicle?.vehicleMake?.makeName },
      });
    }
  }, []);

  // Updating state
  useEffect(() => {
    if (editOptionsData) {
      const allModels = editOptionsData.getVehicleMakeModels?.models.map(
        (model) => ({
          label: model.modelDescription,
          value: model.modelCode,
        }),
      );
      const makeCode = editOptionsData.getVehicleMakeModels?.make?.makeCode;

      const colorOpts = [
        ...(makeCode === vehicle?.vehicleMake?.makeCode.toString() &&
        state.colorCode &&
        !editOptionsData?.getVehicleMakeModels.colors.find(
          (vColor) => vColor.makeColorCode === state.colorCode,
        )
          ? [
              {
                label: state.colorName,
                value: state.colorCode,
              },
            ]
          : []),
        ...editOptionsData?.getVehicleMakeModels.colors.map((option) => ({
          label: option.name,
          value: option.makeColorCode,
        })),
      ];

      const fuelOpts = editOptionsData?.getFuelNames.map((option) => ({
        label: option.description,
        value: option.id,
      }));

      let updatedState = { allModels, makeCode, colorOpts, fuelOpts };

      const isValidModel =
        !!allModels.find((model) => model.label === state.modelName) ||
        (!state.modelName && state.modelCode);
      // If previous model is not valid based on the new make, clear the value in the state
      if (!isValidModel) {
        updatedState = { ...updatedState, modelName: '', modelCode: '' };
      }
      dispatch(updatedState);
    }
  }, [editOptionsData]);

  useEffect(() => {
    if (makesData) {
      setMakeNames(makesData?.getMakesByPartialName?.map((d) => d.makeName));
    }
  }, [makesData]);

  useEffect(() => {
    if (modelData) {
      setModelNames(
        modelData?.getModelsByPartialModelName?.map((d) => d.modelDescription),
      );
    }
  }, [modelData]);

  const handleMakeSelected = (value) => {
    setIsValueChanged(true);
    setModelNames([]);
    if (!state.modelName && state.modelCode) {
      dispatch({ modelCode: '' });
    }
    dispatch({ makeName: value });
    getVehicleEditOptions({
      variables: {
        makeName: value,
      },
    });
    const getSelectedMake = makesData?.getMakesByPartialName.find(
      (d) => d.makeName === value,
    );

    if (getSelectedMake) {
      const { makeCode, makeName } = getSelectedMake;
      setSelectedMake({ makeCode, makeName });
      dispatch({ makeCode, makeName });
    } else {
      setSelectedMake(null);
    }
  };

  useEffect(() => {
    if (modelPartial) {
      getModelsByPartialModelName({
        variables: {
          makeCode: Number.parseInt(
            selectedMake?.makeCode ?? vehicle?.vehicleMake?.makeCode,
            10,
          ),
          modelDescription: modelPartial,
        },
      });
    }
  }, [modelPartial]);

  const resetOptions = () => {
    dispatch({
      color: '',
      fuel: '',
      model: '',
      allModels: [],
      fuelOpts: [],
      colorOpts: [],
    });
    setSelectedMake(null);
  };

  const handleMakeChange = (e) => {
    if (!e.target.value || !e.target.value.length) {
      resetOptions();
    }
  };

  const close = () => {
    // eslint-disable-next-line no-unused-expressions
    onClose && onClose();
    closeModal();
  };

  const save = () => {
    const validations = validateFields(requiredFields, state);
    const noError = Object.keys(validations).length === 0;
    if (noError) {
      setErrors({});
      setErrorsCount(0);
      const assetUsage = {
        assetId: vehicle?.uuid,
        vamExempt: parseInt(state.displayVamExempt, 10) || null,
        executiveFleet:
          getKeyByValue(executiveFleetOptions, selectedRadioInput) || '',
        agencyUse1: agencyUseOneText || '',
        agencyUse2: agencyUseTwoText || '',
        agencyUse3: agencyUseThreeText || '',
        agencyUse4: agencyUseFourText || '',
      };
      // eslint-disable-next-line no-unused-expressions
      onSave &&
        onSave([
          { field: 'fuelCode', value: state.fuel },
          {
            field: 'fastReportable',
            value: state.fastReportable.toString(),
          },
          { field: 'modelYear', value: state.modelYear.toString() },
          { field: 'makeColorName', value: state.colorCode },
          { field: 'makeCode', value: state.makeCode.toString() },
          { field: 'modelCode', value: state.modelCode?.toString() },
          {
            field: 'vehicleTrim',
            value: trimText.length > 0 ? trimText : null,
          },
          {
            field: 'vehicleSeries',
            value: seriesText.length > 0 ? seriesText : null,
          },
          {
            field: 'gvwr',
            value: gvwrText ? gvwrText.toString() : null,
          },
          {
            field: 'assetUsage',
            values: assetUsage,
          },
          { field: 'tankSize', value: tankSize },
          {
            field: 'itemType',
            value: state.itemType === null ? '' : state.itemType,
          },
        ]);
    } else {
      setErrors(validations);
      setErrorsCount(Object.keys(validations).length);
    }
  };

  const setModel = (value) => {
    setIsValueChanged(true);
    const selectedModel = modelData?.getModelsByPartialModelName.find(
      (d) => d.modelDescription === value,
    );
    dispatch({ modelName: value, modelCode: selectedModel.modelCode || '' });
  };

  const getOptionValue = (selectedOption, options) => {
    return options?.find((op) => op.value === selectedOption)?.value;
  };

  const [color, setColor] = useState('');

  useEffect(() => {
    if (state.colorCode && state.colorOpts) {
      setColor(getOptionValue(state.colorCode, state.colorOpts));
    } else {
      setColor('');
    }
  }, [state.colorCode, state.colorOpts]);

  const handleSetColor = (e) => {
    setIsValueChanged(true);
    dispatch({ colorCode: e.target.value });
  };

  useEffect(() => {
    if (state.fuel && state.fuelOpts) {
      setFuelType(getOptionValue(state.fuel, state.fuelOpts));
    } else {
      setFuelType('');
    }
  }, [state.fuel, state.fuelOpts]);

  const handleSetFuelType = (e) => {
    setIsValueChanged(true);
    dispatch({ fuel: e.target.value });
  };

  const updateFastReportable = (e) => {
    setIsValueChanged(true);
    dispatch({ fastReportable: e.target.value });
  };

  const displayModel = (modelCode) => {
    return modelCode &&
      modelCode.toUpperCase() !== unknownField &&
      modelCode !== '-1'
      ? modelCode
      : '';
  };

  const getErrorComponent = () => {
    return (
      <Alert
        className="error-alert margin-bottom-2"
        onClose={false}
        slim
        type="error"
      >
        This form has{' '}
        <span className="text-bold">
          {errorsCount === 1
            ? `${errorsCount} error. `
            : `${errorsCount} errors. `}
        </span>
        Please correct all fields outlined in red before saving.
      </Alert>
    );
  };

  const ModalHeader = ({ vin, errorsCnt }) => {
    return (
      <div>
        {errorsCnt > 0 && getErrorComponent()}
        <h2>Edit vehicle details</h2>
        <p className="font-body-xs">
          Edit vehicle details for VIN <span className="text-bold">{vin}</span>{' '}
          in the form below.
        </p>
        <p className="font-body-2xs text-italic">
          Required fields are marked with an asterisk (
          <span className="text-secondary-dark">*</span>).
        </p>
      </div>
    );
  };

  const handleSeriesInput = (e) => {
    setIsValueChanged(true);
    setSeriesText(e.target.value);
  };

  const handleTrimInput = (e) => {
    setIsValueChanged(true);
    setTrimText(e.target.value);
  };

  const handleTankSizeInput = (val) => {
    setIsValueChanged(true);
    setTankSize(val.value);
  };
  const handleGvwrInput = (e) => {
    setIsValueChanged(true);
    const gvwrValue = e.target.value;
    setGvwrText(gvwrValue.replace(/,/g, ''));
  };

  const handleVamExemptChange = (e) => {
    setIsValueChanged(true);
    dispatch({
      displayVamExempt: e.target.value,
    });
  };

  const handleAgencyUseOneInput = (e) => {
    setIsValueChanged(true);
    setAgencyUseOneText(e.target.value);
  };

  const handleAgencyUseTwoInput = (e) => {
    setIsValueChanged(true);
    setAgencyUseTwoText(e.target.value);
  };

  const handleAgencyUseThreeInput = (e) => {
    setIsValueChanged(true);
    setAgencyUseThreeText(e.target.value);
  };

  const handleAgencyUseFourInput = (e) => {
    setIsValueChanged(true);
    setAgencyUseFourText(e.target.value);
  };

  const vamExemptLookup = (props) => {
    return (
      <SelectDropdown
        label="VAM exempt"
        name="vamExemptCode"
        id="vamExemptCode"
        options={[
          { value: '', label: DEFAULT_SELECT_OPTION_LABEL },
          ...(props.vamExemptOpts || []),
        ]}
        value={props.value}
        onChange={props.handleChange}
        errorMessage={props.errorMessage}
      />
    );
  };

  // Year Dropdown
  const yearLookup = () => {
    return (
      <SelectDropdown
        id="year"
        data-testid="year"
        label="Year"
        required
        name="year"
        options={VEHICLE_YEAR_OPTIONS}
        value={state.modelYear}
        onChange={(e) => {
          setIsValueChanged(true);
          dispatch({ modelYear: e.target.value });
        }}
        errorMessage={errors.year}
      />
    );
  };

  //   Make Dropdown
  const makeLookup = () => {
    return (
      <Typeahead
        name="vehicleMakeName"
        data-testid="make-typeahead"
        label="Make"
        labelClass="text-bold"
        filterValue={state.makeName}
        placeholder="Search Makes..."
        typeaheadValues={makeNames}
        fetchTypeaheadValues={(_accessor, value) => {
          getMakesByPartialName({ variables: { makeName: value } });
        }}
        onClear={() => {
          dispatch({
            makeName: '',
            modelName: '',
            makeCode: '',
            modelCode: '',
          });
        }}
        onOptionEnter={handleMakeSelected}
        onFilterChange={handleMakeChange}
        inputCharNum={1}
        required
        debounceDelay={500}
        errorMessage={errors.makeName}
      />
    );
  };

  const modelLookup = () => {
    return (
      <Typeahead
        id="vehicleModel"
        data-testid="model-typeahead"
        name="vehicleModel"
        label="Model"
        labelClass="text-bold"
        accessor="modelName"
        filterValue={state.modelName || displayModel(state.modelCode)}
        placeholder="Search Model..."
        typeaheadValues={modelNames}
        fetchTypeaheadValues={(_accessor, value) => value}
        onOptionEnter={setModel}
        onFilterChange={(e) => {
          setPartialModel(e.target.value);
        }}
        inputCharNum={1}
        debounceDelay={500}
        required
        errorMessage={errors.modelName}
      />
    );
  };

  const trimInput = () => {
    return (
      <TextInput
        characterLimit={MAX_LEN_TRIM}
        label="Trim"
        type="input"
        value={trimText}
        onChange={handleTrimInput}
      />
    );
  };

  const gvwrInput = () => {
    return (
      <>
        <Label htmlFor="gvwr-field">
          <span className="text-bold">GVWR</span>
        </Label>
        <NumberFormat
          className="usa-input"
          id="gvwr-field"
          displayType="input"
          type="text"
          allowNegative={false}
          onChange={handleGvwrInput}
          value={gvwrText}
          thousandsGroupStyle="thousand"
          thousandSeparator
          isAllowed={(values) => {
            const { floatValue, value } = values;
            return floatValue <= 200000 || value === '';
          }}
        />
      </>
    );
  };

  const vehicleSeriesInput = () => {
    return (
      <TextInput
        characterLimit={MAX_LEN_SERIES}
        label="Vehicle series"
        type="input"
        value={seriesText}
        onChange={handleSeriesInput}
      />
    );
  };

  const fuelLookup = () => {
    return (
      <SelectDropdown
        name="fuelType"
        required
        label="Fuel type"
        value={fuelType}
        onChange={handleSetFuelType}
        options={[
          { value: '', label: DEFAULT_SELECT_OPTION_LABEL },
          ...(state.fuelOpts || []),
        ]}
        errorMessage={errors.fuel}
      />
    );
  };

  const colorLookup = () => {
    return (
      <SelectDropdown
        label="Color"
        name="color"
        id="color"
        data-testid="color"
        options={[
          { value: '', label: DEFAULT_SELECT_OPTION_LABEL },
          ...(state.colorOpts || []),
        ]}
        value={color}
        onChange={handleSetColor}
        errorMessage={errors.color}
        required
      />
    );
  };

  const fastReportableLookup = () => {
    return (
      <SelectDropdown
        id="fastReportable"
        required
        label="FAST reportable"
        name="fastReportable"
        value={state.fastReportable}
        onChange={updateFastReportable}
        errorMessage={errors.fastReportable}
        options={[
          { value: '', label: DEFAULT_SELECT_OPTION_LABEL },
          ...fastReportableOptions,
        ]}
      />
    );
  };

  const additionalTagInput = () => {
    return (
      <div className="additional-tag-input">
        <TextInput
          id="additional-tag"
          aria-label="additional-tag"
          label="State plate"
          type="input"
          className="usa-input"
          showLabelError={false}
          value={state.additionalTag}
          data-testid="ald-tag-input"
          disabled
        />
      </div>
    );
  };

  const odometerInput = () => {
    return (
      <div className="odometer-input">
        <span className="prefix-icon-odometer">miles</span>
        <TextInput
          id="odometer"
          aria-label="odometer"
          label="Vehicle odometer"
          type="input"
          className="usa-input"
          name={state.odometer}
          showLabelError={false}
          value={formatOdometerReading(state.odometer)}
          data-testid="current-odometer-input"
          disabled
        />
      </div>
    );
  };

  const agencyUseOneInput = () => {
    return (
      <>
        <TextInput
          id="agency-use-one"
          aria-label="Agency use one"
          characterLimit="50"
          label="Agency use 1"
          type="input"
          className="usa-input"
          showLabelError={false}
          data-testid="agency-use-one-input"
          value={agencyUseOneText}
          onChange={handleAgencyUseOneInput}
        />
      </>
    );
  };

  const agencyUseTwoInput = () => {
    return (
      <>
        <TextInput
          id="agency-use-two"
          aria-label="Agency use two"
          characterLimit="50"
          label="Agency use 2"
          type="input"
          className="usa-input"
          showLabelError={false}
          data-testid="agency-use-two-input"
          value={agencyUseTwoText}
          onChange={handleAgencyUseTwoInput}
        />
      </>
    );
  };

  const agencyUseThreeInput = () => {
    return (
      <>
        <TextInput
          id="agency-use-three"
          aria-label="Agency use three"
          characterLimit="50"
          label="Agency use 3"
          type="input"
          className="usa-input"
          showLabelError={false}
          data-testid="agency-use-three-input"
          value={agencyUseThreeText}
          onChange={handleAgencyUseThreeInput}
        />
      </>
    );
  };

  const fuelTankSizeInput = () => {
    return (
      <>
        <Label htmlFor="fuelTankSize">
          <span className="text-bold">Fuel tank capacity</span>
        </Label>
        <NumberFormat
          className={`usa-input`}
          id={'fuelTankSize'}
          decimalSeparator="."
          displayType="input"
          type="text"
          thousandsGroupStyle="thousand"
          decimalScale={4}
          allowNegative={false}
          suffix=" gal"
          thousandSeparator
          value={tankSize}
          onValueChange={handleTankSizeInput}
          isFixedDecimalScale
        />
      </>
    );
  };

  const agencyUseFourInput = () => {
    return (
      <>
        <TextInput
          id="agency-use-four"
          aria-label="Agency use four"
          characterLimit="50"
          label="Agency use 4"
          type="input"
          className="usa-input"
          showLabelError={false}
          data-testid="agency-use-four-input"
          value={agencyUseFourText}
          onChange={handleAgencyUseFourInput}
        />
      </>
    );
  };

  return (
    <VehicleModal
      actions={
        <>
          <Button variant="unstyled" label="Cancel" onClick={close} />
          <Button
            className="margin-left-2"
            onClick={save}
            label="Save and close"
          />
        </>
      }
      title={<ModalHeader vin={vehicle?.id} errorsCount={errorsCount} />}
      onClose={close}
    >
      <div className="grid-row grid-gap grid-col-12">
        <div className="grid-col-4">{odometerInput()}</div>
        <div className="grid-col-4">{yearLookup()}</div>
      </div>

      <div className="grid-row grid-gap grid-col-12">
        <div className="grid-col-8">{makeLookup()}</div>
      </div>
      <div className="grid-row grid-gap grid-col-12">
        <div className="grid-col-8">{modelLookup()}</div>
      </div>
      <div className="grid-row grid-gap grid-col-12">
        <div className="grid-col-8">{vehicleSeriesInput()}</div>
      </div>
      <div className="grid-row grid-gap grid-col-12">
        <div className="grid-col-8">{trimInput()}</div>
      </div>
      <div className="grid-row grid-gap grid-col-12">
        <div className="grid-col-8">
          <SelectDropdown
            id="vehicleType"
            label="Vehicle group"
            value={state.itemType}
            required
            name="vehicleType"
            options={VEHICLE_TYPE_OPTIONS}
            onChange={(e) => {
              dispatch({ itemType: e.target.value });
            }}
            disabled={!hasFMVRSAdminRole}
          />
        </div>
      </div>
      <div className="grid-row grid-gap grid-col-12">
        <div className="grid-col-4">{colorLookup()}</div>
        <div className="grid-col-4">{fuelLookup()}</div>
      </div>
      <div className="grid-row grid-gap grid-col-12">
        <div className="grid-col-8">{fuelTankSizeInput()}</div>
      </div>
      <div className="grid-row grid-gap grid-col-12">
        <div className="grid-col-4">{fastReportableLookup()}</div>
      </div>
      <div className="grid-row grid-gap grid-col-12">
        <div className="grid-col-8">{gvwrInput()}</div>
      </div>

      <div className="grid-row grid-gap grid-col-12">
        <div className="grid-col-4">
          {vamExemptLookup({
            vamExemptOpts: state.vamExemptOpts,
            value: state.displayVamExempt,
            handleChange: handleVamExemptChange,
            errorMessage: errors.itemInventoryStatusCode,
          })}
        </div>
      </div>
      <div className="grid-row grid-gap grid-col-12">
        <div className="grid-col-8 margin-top-2">
          <Fieldset legend="Executive Fleet">
            <RadioButton
              name="executive-fleet-option-yes"
              id="executive-fleet-option-yes"
              value={executiveFleetOptions.Y}
              checked={selectedRadioInput === executiveFleetOptions.Y}
              label={
                <>
                  <span className="text-normal">Yes</span>
                </>
              }
              onChange={() => setSelectedRadioInput(executiveFleetOptions.Y)}
            />
            <RadioButton
              name="executive-fleet-option-no"
              id="executive-fleet-option-no"
              value={executiveFleetOptions.N}
              checked={selectedRadioInput === executiveFleetOptions.N}
              label={
                <>
                  <span className="text-normal">No</span>
                </>
              }
              onChange={() => setSelectedRadioInput(executiveFleetOptions.N)}
            />
          </Fieldset>
        </div>
      </div>
      <div className="grid-row grid-gap grid-col-12">
        <div className="grid-col-8">{additionalTagInput()}</div>
      </div>

      <div className="grid-row grid-gap grid-col-12">
        <div className="grid-col-8">{agencyUseOneInput()}</div>
      </div>
      <div className="grid-row grid-gap grid-col-12">
        <div className="grid-col-8">{agencyUseTwoInput()}</div>
      </div>
      <div className="grid-row grid-gap grid-col-12">
        <div className="grid-col-8">{agencyUseThreeInput()}</div>
      </div>
      <div className="grid-row grid-gap grid-col-12">
        <div className="grid-col-8">{agencyUseFourInput()}</div>
      </div>
    </VehicleModal>
  );
};

VehicleEdit.defaultProps = {
  onClose: () => undefined,
  onSave: () => undefined,
};

VehicleEdit.propTypes = {
  vehicle: PropTypes.shape(VehiclePropType).isRequired,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

export default VehicleEdit;
