import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@gsa/afp-component-library';

const ScheduleActions = () => {
  const history = useHistory();
  return (
    <div className="display-flex flex-justify-end">
      <Button
        data-testid="schedules-submit-btn"
        leftIcon={{ name: 'add', class: 'usa-icon--size-2' }}
        onClick={() => {
          history.push(`/reports/export`);
        }}
        label="Create scheduled report"
      />
    </div>
  );
};

export default ScheduleActions;
