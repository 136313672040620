import React from 'react';
import { Link } from 'react-router-dom';
import { fieldGetter } from '../../../helpers/field-getter';
import { unknownField } from 'utilities/unknown-field';

export const VEHICLE_OWNERSHIP = {
  AO: 'Agency owned',
  CL: 'Commercial leased vehicle',
  GF: 'GSA leased vehicle',
};

function isVehicleAssigned(ownership) {
  if (ownership === 'GF') {
    return false;
  }
  return true;
}

export function getLicensePlate(exemptPlate, licensePlate, expirationDate) {
  if (exemptPlate) {
    return 'Exempt';
  }
  if (licensePlate) {
    return (
      <Link
        to={`/license-plate/${encodeURIComponent(
          licensePlate,
        )}?tagExpirationDate=${expirationDate}`}
      >
        {fieldGetter([{ field: licensePlate }])}
      </Link>
    );
  }

  return '';
}

export function vehicleOwnership(ownership) {
  return ownership ? VEHICLE_OWNERSHIP[ownership] : '';
}

export function getVINVerificationStatus(
  pre1981,
  internationalVin,
  unverifiedVin,
  nhtsaVerified,
) {
  if (nhtsaVerified) return 'NHTSA verified';
  if (pre1981 || internationalVin || unverifiedVin) return 'User verified';
  return '';
}

export function getOfficeDetails(office, officeCode) {
  return office?.officeName ? `${officeCode} - ${office?.officeName}` : '';
}

export const displayModel = (vehicle) => {
  if (vehicle) {
    if (vehicle?.vehicleModel) {
      return vehicle.vehicleModel?.modelDescription.toUpperCase();
    }
    if (
      vehicle?.modelCode &&
      vehicle.modelCode.toUpperCase() !== unknownField &&
      vehicle.modelCode !== '-1'
    ) {
      return vehicle.modelCode.toUpperCase();
    }
  }
  return null;
};
