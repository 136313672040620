import { useLazyQuery } from '@apollo/client';
import { Alert, Button, TextInput } from '@gsa/afp-component-library';
import { usePrevious } from '@gsa/afp-shared-ui-utils';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  extractMonthYear,
  formatStatePlateExp,
  validateStatePlateExp,
} from 'utilities/common';
import { GET_TAG_ACTIVITY } from '../../../../services/data-layer';
import { isTest } from '../../../../utilities/consts';
import usePortalModal from '../../../../utilities/portal-modal';
import ExemptCheckbox from '../../../exempt-checkbox';
import { fieldGetter } from '../../helpers/field-getter';
import './registration.css';
import { LicensePlateUpdateWarning } from './vehicle-status-warnings';

export default function LicensePlateEdit({
  editing,
  onClose,
  onSave,
  licensePlate,
  lpExpDate,
  stateLicPlate,
  stateLicPlateExpDate,
  exemptPlate,
}) {
  const [LPModal, openModal, closeModal] = usePortalModal();

  const [, { data: tagDetails, loading: loadingTagDetails }] = useLazyQuery(
    GET_TAG_ACTIVITY,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const [lp, setLp] = useState(licensePlate);
  const [licExpDate, setExpDate] = useState(lpExpDate);
  const [lpstateLicPlate, setStateLicPlate] = useState(stateLicPlate);
  const [statePlateExp, setStatePlateExp] = useState(
    formatStatePlateExp(stateLicPlateExpDate),
  );
  const [isExemptPlate, setIsExemptPlate] = useState(!!exemptPlate);
  const [showExemptAlert, setShowExemptAlert] = useState(false);
  const [errors, setErrors] = useState({});
  const [errorsCount, setErrorsCount] = useState(0);

  let prevStateLicPlate;
  if (!isTest) {
    prevStateLicPlate = usePrevious(stateLicPlate);
  }

  useEffect(() => {
    if (stateLicPlate !== prevStateLicPlate) {
      setStateLicPlate(stateLicPlate);
    }
  }, [stateLicPlate]);

  useEffect(() => {
    if (tagDetails) {
      setExpDate(tagDetails?.getTag?.expirationDate);
    }
  }, [tagDetails]);

  useEffect(() => {
    if (editing) {
      openModal();
      setLp(licensePlate);
      setExpDate(lpExpDate);
      setStateLicPlate(stateLicPlate);
      setStatePlateExp(formatStatePlateExp(stateLicPlateExpDate));
      setShowExemptAlert(false); // don't show the alert on page load if it's already checked
      setIsExemptPlate(exemptPlate);
    } else {
      closeModal();
    }
  }, [editing, setLp, setExpDate]);

  const close = () => {
    setErrors({});
    setErrorsCount(0);
    // eslint-disable-next-line no-unused-expressions
    onClose && onClose();
  };

  const save = () => {
    const statePlateExpValidation = validateStatePlateExp(statePlateExp);
    const noError = Object.keys(statePlateExpValidation).length === 0;

    if (noError) {
      setErrors({});
      setErrorsCount(0);
      // eslint-disable-next-line no-unused-expressions
      onSave &&
        onSave({
          tagNumber: isExemptPlate ? null : lp,
          tagExpirationDate: isExemptPlate ? null : licExpDate,
          statePlate: lpstateLicPlate,
          stateLicPlateExpDate: extractMonthYear(statePlateExp),
          exemptPlate: isExemptPlate,
        });
    } else {
      setErrors(statePlateExpValidation);
      setErrorsCount(Object.keys(statePlateExpValidation).length);
    }
  };

  const getErrorComponent = () => {
    return (
      <Alert
        className="error-alert margin-bottom-2"
        onClose={false}
        slim
        type="error"
      >
        This form has{' '}
        <span className="text-bold">
          {errorsCount === 1
            ? `${errorsCount} error. `
            : `${errorsCount} errors. `}
        </span>
        Please correct all fields outlined in red before saving.
      </Alert>
    );
  };

  const ModalHeader = ({ errorsCount }) => {
    return (
      <div>
        {errorsCount > 0 && getErrorComponent()}
        <h2>License plate</h2>
      </div>
    );
  };

  const handleStateExpChange = (e) => {
    setStatePlateExp(e.target.value);
  };

  return (
    <LPModal
      onClose={close}
      title={<ModalHeader errorsCount={errorsCount} />}
      actions={
        <>
          <Button variant="unstyled" onClick={close} label="Cancel" />
          <Button
            className="margin-left-2"
            onClick={save}
            disabled={loadingTagDetails}
            label="Save and close"
          />
        </>
      }
    >
      <ExemptCheckbox
        exemptPlate={isExemptPlate}
        handleChange={(e) => {
          setShowExemptAlert(true);
          const { checked } = e.currentTarget;
          setIsExemptPlate(checked);
        }}
        licensePlate={licensePlate}
        showAlert={showExemptAlert}
      />
      {!isExemptPlate && !licensePlate && <LicensePlateUpdateWarning />}
      {!isExemptPlate && (
        <div className="grid-col-7">
          <TextInput
            value={lp || '—'}
            label="License Plate"
            name="tagNumber"
            id="licensePlate"
            type="text"
            disabled
            data-testid="license-plate"
          />
          <TextInput
            value={fieldGetter([{ field: licExpDate, formatter: 'exp' }])}
            label="License Plate Expiration Date"
            name="tagExpDate"
            type="text"
            disabled
            data-testid="license-plate-expiration-date"
          />
        </div>
      )}

      <div className="grid-col-7">
        <TextInput
          label="State License Plate"
          name="lpstateLicPlate"
          maxLength={20}
          value={lpstateLicPlate}
          onChange={(e) => {
            setStateLicPlate(e.target.value);
          }}
          data-testid="state-license-plate"
        />
        <TextInput
          value={statePlateExp}
          label="State license plate expiration date"
          name="statePlateExp"
          onChange={handleStateExpChange}
          type="text"
          help="Format date as MM/YYYY"
          showLabelError={false}
          errorMessage={errors.message}
          data-testid="state-license-plate-expiration-date"
        />
      </div>
    </LPModal>
  );
}

LicensePlateEdit.defaultProps = {
  editing: false,
  onClose: () => {
    // noop
  },
  onSave: () => {
    // noop
  },
  licensePlate: '',
  lpExpDate: null,
  stateLicPlate: '',
  stateLicPlateExpDate: null,
  exemptPlate: false,
};

LicensePlateEdit.propTypes = {
  editing: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  licensePlate: PropTypes.string,
  lpExpDate: PropTypes.number,
  stateLicPlate: PropTypes.string,
  stateLicPlateExpDate: PropTypes.number,
  exemptPlate: PropTypes.bool,
};
