// eslint-disable-next-line filenames/match-exported
import PropTypes from 'prop-types';
import React from 'react';

import UploadButton from './upload-button';

const Index = ({ title, isButtonDropdownItem }) => {
  return (
    <UploadButton
      data-testid="attachment-add-button"
      title={title}
      isButtonDropdownItem={isButtonDropdownItem}
    />
  );
};

Index.defaultProps = {
  title: <h2>Add Document</h2>,
};

Index.propTypes = {
  title: PropTypes.node,
};

export default Index;
