import { z } from 'zod';

export const updateVehicleStatusSchema = z.object({
  statusOprator: z
    .string()
    .min(1, { message: 'Please choose Add or Remove' })
    .nullish(),
  newStatusValue: z
    .string()
    .min(2, { message: 'Please choose one of the status' })
    .nullish(),
});
