import { gql } from '@apollo/client';

export const GET_MODIFICATION_REQUESTS_QUERY = gql`
  query getModificationRequests(
    $limit: Float
    $offset: Float
    $order: OrderBy
    $filters: [Filter!]
  ) {
    getModificationRequests(
      limit: $limit
      offset: $offset
      order: $order
      filters: $filters
    ) {
      rows {
        id
        modificationDate
        modificationTitle
        modificationStatus
        modificationNumber
        isCapitalized
        authorization {
          id
          authorizationStatus
          authorizedById
          authorizedBy {
            id
          }
        }
        reviews {
          reviewerId
          reviewDate
          reviewerTitle
          decision
        }
        attachments {
          id
          attachmentId
          entity
          entityId
          attachmentName
          attachmentType
          attachmentSize
          attachmentFileType
          attachmentVirusScanStatus
          attachmentDescription
          attachmentDocStoreUri
          attachmentFileLocation
        }
      }
      hasMore
      count
    }
  }
`;

export const GET_ATTACHMENT_SIGNED_URL_QUERY = gql`
  query GetServiceAttachmentDownloadURL(
    $fileMimeType: String!
    $fileKey: String!
  ) {
    getServiceAttachmentDownloadURL(
      fileMimeType: $fileMimeType
      fileKey: $fileKey
    )
  }
`;

export const GET_EDMS_DOWNLOAD_URL = gql(/* GraphQL */ `
  mutation GetEdmsDownloadLink($attachmentId: String!) {
    getEdmsDownloadLink(attachmentId: $attachmentId)
  }
`);
