import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { StatusBadge } from '@gsa/afp-component-library';
import { itemInventoryStatuses } from '../../utilities/consts';

export const VehicleRegistrationStatusTag = ({ vehicle, page }) => {
  const [value, setValue] = useState('');
  const [variant, setVariant] = useState('');

  useEffect(() => {
    const val = determineRegistrationStatus(vehicle);
    if (val === 'Registered' && page === 'registration-details') setValue('');
    else setValue(val);
  }, [vehicle, page]);

  useEffect(() => {
    if (!value) return;
    let varnt = '';
    if (value === 'Registered') varnt = 'Ready-Gray';
    if (value === 'Unregistered') varnt = 'Warning-Gray';
    else if (value === 'Incomplete') varnt = 'Urgent-Gray';
    setVariant(varnt);
  }, [value]);

  if (value) return <StatusBadge variant={variant}>{value}</StatusBadge>;
  return null;
};

VehicleRegistrationStatusTag.propTypes = {
  vehicle: PropTypes.object,
};

export const pointOfContactIsInvalid = (poc) => {
  return (
    !poc ||
    !poc.firstName ||
    !poc.lastName ||
    !poc.primaryAddress ||
    !poc.city ||
    !poc.stateCode ||
    !poc.postalCode ||
    !poc.primaryPhone ||
    !poc.email
  );
};

export const IsPocInvalid = (poc) => {
  return (
    !poc ||
    !poc.pocFirstName ||
    !poc.pocLastName ||
    !poc.pocAddress1 ||
    !poc.pocCity ||
    !poc.pocStateCode ||
    !poc.pocPostalCode ||
    !poc.pocPhone1 ||
    !poc.pocEmailAddress
  );
};

export const vehicleRegistrationIsIncomplete = (vehicle) => {
  const primaryPoCIsInvalid = vehicle.primaryPoC
    ? pointOfContactIsInvalid(vehicle.primaryPoC)
    : IsPocInvalid(
        vehicle?.assetContactAssociationPrimary?.pointOfContactPrimary,
      );
  const secondaryPoCIsInvalid = vehicle.secondaryPoC
    ? pointOfContactIsInvalid(vehicle.secondaryPoC)
    : IsPocInvalid(
        vehicle?.assetContactAssociationSecondary?.pointOfContactSecondary,
      );
  return (
    !vehicle.agency ||
    !vehicle.bureau ||
    !vehicle.id ||
    (!vehicle.inventoryStatus && !vehicle.itemInventoryStatusCode) ||
    !vehicle.vehicleMake ||
    !vehicle.modelCode ||
    !vehicle.modelYear ||
    !vehicle.makeColor ||
    !vehicle.fuel ||
    vehicle.fastReportable === null ||
    primaryPoCIsInvalid ||
    secondaryPoCIsInvalid
  );
};

export const determineRegistrationStatus = (vehicle) => {
  let registrationStatus = '';
  if (vehicle.isRegistered || vehicle.exemptPlate) {
    if (vehicleRegistrationIsIncomplete(vehicle)) {
      registrationStatus = 'Incomplete';
    } else {
      registrationStatus = 'Registered';
    }
  } else {
    registrationStatus = 'Unregistered';
  }
  return registrationStatus;
};
