import React, { useEffect } from 'react';
import { EmptyState, Alert } from '@gsa/afp-component-library';
import { SectionBlock } from '../../common';
import ServiceTypeSearch from './service-type-search';
import { PageMode, useServiceLine } from '../providers';
import ServiceLineItem from './service-line-item';

const SearchTypes = ({
  mode,
  searchServiceTypes,
  typeaheadData,
  addServiceItem,
  error,
}) => {
  if (mode === PageMode.View) {
    return null;
  }
  return (
    <>
      <p className="margin-top-neg-2 padding-bottom-1">
        Add each repair order line item below and the total cost wil be
        calculated based on entries.
      </p>
      <p>
        <strong>Create a Service Line Item:</strong> To create a new service
        line item, click on the service line item search box and begin typing
        the name of the service line item you are looking for (e.g., "window").
        Select the correct option from the drop down and then select the button
        "Add line item to repair order".
      </p>
      <p class="padding-bottom-1">
        <strong>Add Miscellaneous charge:</strong> To add a miscellaneous
        charge, search for "miscellaneous" in the service line item search box.
        You can select any line item as long as it has "miscellaneous" in the
        title. You then can use the parts quantity and cost or labor quantity
        and cost fields to calculate the total miscellaneous costs for the
        repair order."
      </p>
      {error && (
        <Alert
          type="error"
          data-testid="min-one-service-line-error"
          className="margin-bottom-1"
          slim
        >
          {error?.message}
        </Alert>
      )}
      <ServiceTypeSearch
        searchServiceTypes={searchServiceTypes}
        typeaheadData={typeaheadData}
        addServiceItem={addServiceItem}
      />
    </>
  );
};

const ServiceItems = ({
  mode,
  serviceLines,
  onChangeItem,
  onRemoveItem,
  dropdownData,
}) => {
  const items = serviceLines
    .filter((s) => !s.deleted)
    .map((item, index) => {
      return (
        <ServiceLineItem
          key={item.id}
          data={item}
          index={index + 1}
          mode={mode}
          onChange={onChangeItem}
          onRemove={onRemoveItem}
          dropdownData={dropdownData}
        />
      );
    });

  return <div className="grid-col-12">{items}</div>;
};

const ServiceLines = ({ repairNumber, mode }) => {
  const {
    initServiceLines,
    classes,
    reasons,
    failureCauses,
    worksAccomplished,
    serviceLines,
    updateServiceItem,
    removeServiceItem,
    addServiceItem,
    searchServiceTypes,
    typeaheadData,
    error,
  } = useServiceLine();

  const dropdownData = { classes, reasons, failureCauses, worksAccomplished };

  const handleUpdate = (item) => {
    updateServiceItem(item);
  };

  const handleRemove = (item) => {
    removeServiceItem(item);
  };

  useEffect(() => {
    initServiceLines({ repairNumber, mode });
  }, []);

  if (
    !classes?.length ||
    !reasons?.length ||
    !failureCauses?.length ||
    !worksAccomplished?.length
  ) {
    return null;
  }

  const ServiceEmptyState = () => {
    return (
      <EmptyState
        bottomText={
          <p className="margin-top-neg-1">
            Search and add a repair service to this repair order
          </p>
        }
        className="margin-top-5"
        hasBackground
        data-testid="service-line-empty-state"
      />
    );
  };

  const emptyServiceLines = () => {
    const currentServiceLines = serviceLines.filter((sl) => !sl.deleted);
    return !currentServiceLines.length;
  };

  return (
    <SectionBlock title="Order details" basic border={false}>
      <SearchTypes
        mode={mode}
        searchServiceTypes={searchServiceTypes}
        typeaheadData={typeaheadData}
        addServiceItem={addServiceItem}
        error={error?.MinOneServiceLineItem}
      />
      {emptyServiceLines() && <ServiceEmptyState />}
      {!emptyServiceLines() && (
        <ServiceItems
          mode={mode}
          serviceLines={serviceLines}
          onChangeItem={handleUpdate}
          onRemoveItem={handleRemove}
          dropdownData={dropdownData}
        />
      )}
    </SectionBlock>
  );
};

export default ServiceLines;
