import React, { useEffect, useState } from 'react';
import { Button } from '@gsa/afp-component-library';
import usePortalModal from 'utilities/portal-modal';
import { ReassignVehicleForm } from './reassign-vehicle-form';

const ReassignVehicle = ({ vehicle, onSave, onClose }) => {
  const [ReassignVehicleModal, openModal, closeModal] = usePortalModal();
  const [reportData, setReportData] = useState({ vehicle });

  useEffect(() => {
    openModal();
  }, []);

  const close = () => {
    onClose && onClose();
    closeModal();
  };

  const onSubmit = () => {
    const fsrEmail = reportData.fsrEmailByZone;
    const fmcId = reportData.fmc;
    const data = {
      fsrEmail,
      fmcId,
      assetId: reportData.vehicle?.uuid,
    };
    onSave && onSave(data);
  };

  return (
    <ReassignVehicleModal
      actions={
        <>
          <Button
            variant="unstyled"
            id="cancelReassign"
            data-testId="cancelReassign"
            label="Cancel"
            onClick={close}
          />
          <Button
            className="margin-left-2"
            type="submit"
            label="Transfer vehicle"
            form="reassign-vehicle-form"
          />
        </>
      }
      title={
        <>
          <div>
            <h1>Transfer {vehicle.tagNumber}</h1>
            <div className="font-sans-xl">VIN {vehicle.id}</div>

            <div className="font-sans-lg margin-top-2">
              Find and select a Fleet Service Representative to receive this
              transferred vehicle.
            </div>
          </div>
        </>
      }
      onClose={close}
    >
      <ReassignVehicleForm
        onSubmit={onSubmit}
        reportData={reportData}
        setReportData={setReportData}
      />
    </ReassignVehicleModal>
  );
};

export default ReassignVehicle;
