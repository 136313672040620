/* eslint-disable react/prop-types */
// eslint-disable-next-line filenames/match-exported
import React, { useEffect, useState } from 'react';
import { Button, Modal, AFPTable } from '@gsa/afp-component-library';
import { useMileageExpress } from './mileage-express-provider';
import { emdash } from 'components/common';

const MileageWithSignificantDataModal = () => {
  const [data, setData] = useState([]);
  const {
    confirmSignificanMileageData,
    dispatchAction,
    saveAllMileageExpresss,
    significantMileageEntries,
  } = useMileageExpress();

  const onDiscardAndCloseModal = () => {
    dispatchAction('SET_CONFIRM_SIGNIFICANT_MILEAGE_DATA', false);
  };

  const onSaveAndClose = () => {
    saveAllMileageExpresss();
    dispatchAction('SET_INTERCEPT_SAVE', false);
    dispatchAction('SET_CONFIRM_SIGNIFICANT_MILEAGE_DATA', false);
    dispatchAction('SET_ENTRIES_WITH_SIGNIFICANT_MILEAGE_DIFFERENCE', []);
    dispatchAction('SET_ALERT_MESSAGE', {});
  };

  useEffect(() => {
    if (significantMileageEntries?.length > 0) {
      const dataInfo = significantMileageEntries?.map(
        ({ modified, original }) => ({
          tagNumber: original?.vehicle?.tagNumber,
          previousMileage: `${
            original?.previousMileage?.toLocaleString() || emdash
          }`,
          endingMileage: `${Number(
            modified?.currentMileage,
          )?.toLocaleString()}`,
          mileageDifference: `${Math.abs(
            Number(modified?.currentMileage) -
              Number(original?.previousMileage),
          ).toLocaleString()}`,
        }),
      );

      setData(dataInfo);
    } else {
      setData([]);
    }
  }, [significantMileageEntries]);

  useEffect(() => {
    dispatchAction('RESET_MILEAGES', false);
  }, []);

  const columns = [
    { Header: 'License plate', accessor: 'tagNumber', sortable: false },
    {
      Header: 'Previous mileage',
      accessor: 'previousMileage',
      sortable: false,
    },
    { Header: 'Ending mileage', accessor: 'endingMileage', sortable: false },
    {
      Header: 'Difference',
      accessor: 'mileageDifference',
      sortable: false,
      Cell: ({ value }) => <span className="text-bold">{value}</span>,
    },
  ];

  if (!confirmSignificanMileageData) {
    return null;
  }

  return (
    <div className="afp-modal-wrapper">
      <div className="afp-modal-overlay">
        <Modal
          title={<h2>Confirm excess mileage</h2>}
          actions={
            <>
              <Button
                variant="unstyled"
                className="margin-right-2"
                onClick={onDiscardAndCloseModal}
                data-testid="discard-save-cancel-button"
                label="Cancel"
              />
              <Button
                variant="primary"
                type="submit"
                data-testid="confirm-save-all-mileageExpresss"
                label="Confirm"
                onClick={onSaveAndClose}
              />
            </>
          }
          onClose={onDiscardAndCloseModal}
          variant="large"
        >
          <div className="grid-col-11 margin-bottom-3">
            One or more mileage entries exceeds +/- 9,999 miles.{' '}
            <span className="text-bold">
              This will result in a large credit or bill for the customer.
            </span>{' '}
            Select &apos;Confirm&apos; to acknowledge the mileage is correct or
            &apos;Cancel&apos; to go back and make edits.
          </div>

          <AFPTable
            columns={columns}
            data={data}
            testId="mileageExpress-listing-table"
          />
        </Modal>
      </div>
    </div>
  );
};

export default MileageWithSignificantDataModal;
