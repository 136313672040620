import React from 'react';
import PropTypes from 'prop-types';
import { EmptyState, Spinner } from '@gsa/afp-component-library';

const TableNoData = ({ loading, hasNoData, topText, bottomText }) => {
  if (loading) return <Spinner className="padding-y-9" />;

  if (hasNoData)
    return (
      <div className="bg-gray-3 padding-y-5">
        <div className="text-center padding-y-4">
          <EmptyState
            alt="No data"
            hasBackground
            topText={topText}
            bottomText={bottomText}
          />
        </div>
      </div>
    );
  return null;
};

TableNoData.defaultProps = {
  loading: false,
  hasNoData: false,
  topText: null,
  bottomText: null,
};

TableNoData.propTypes = {
  loading: PropTypes.bool,
  hasNoData: PropTypes.bool,
  topText: PropTypes.string,
  bottomText: PropTypes.string,
};

export default TableNoData;
