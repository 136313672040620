import React from 'react';
import { RequiredFieldIndicator, TextInput } from '@gsa/afp-component-library';
import { Controller, useFormContext } from 'react-hook-form';
import { get } from 'lodash';
import moment from 'moment-timezone';
import { useMotorPool } from '../../../motor-pool-provider';
import {
  START_RESERVATION_FIELDS,
  CLOSE_COMPLETE_RESERVATION_FIELDS,
} from './reservation-constants';

const formatMapping = {
  dateTime: (value, timezone) =>
    moment.tz(value, timezone).format('L h:mm A z'),
};

const ReservationStartFields = () => {
  const {
    reservationListingSelected,
    reservationModalMode,
    selectedMotorPoolById,
  } = useMotorPool();

  const {
    formState: { errors },
    control,
  } = useFormContext();

  const formatValue = (value, format, timezone) => {
    if (!format || !formatMapping[format]) {
      return value;
    }
    return formatMapping[format](value, timezone);
  };

  const fields =
    reservationModalMode === 'START_RESERVATION'
      ? {
          ...START_RESERVATION_FIELDS,
          actualPickupDate: {
            ...START_RESERVATION_FIELDS.actualPickupDate,
            value: moment().utc().format(),
          },
          poolName: {
            ...START_RESERVATION_FIELDS.poolName,
            value: selectedMotorPoolById?.poolName,
        }
      }
      : {
          ...CLOSE_COMPLETE_RESERVATION_FIELDS,
          actualReturnDate: {
            ...CLOSE_COMPLETE_RESERVATION_FIELDS.actualReturnDate,
            value: moment().utc().format(),
          },
          poolName: {
            ...CLOSE_COMPLETE_RESERVATION_FIELDS.poolName,
            value: selectedMotorPoolById?.poolName,
        }
        };

  return (
    <div>
      {Object.values(fields).map((formField) => {
        return (
          <div key={formField.name} className={formField.wrapperClass || ''}>
            {!formField.editable ? (
              <>
                <div className="text-bold margin-top-2">
                  {formField.label}{' '}
                  {formField.required && <RequiredFieldIndicator />}
                </div>
                {formatValue(
                  formField?.value ||
                    get(
                      reservationListingSelected,
                      formField?.dataPath || formField?.name,
                    ),
                  formField.format,
                  selectedMotorPoolById?.timezone,
                )}
              </>
            ) : (
              <Controller
                name={formField.name}
                control={control}
                defaultValue={
                  formField?.value ||
                  get(
                    reservationListingSelected,
                    formField?.dataPath || formField?.name,
                  )
                }
                render={({ field, ref }) => (
                  <TextInput
                    {...field}
                    ref={ref}
                    name={formField.name}
                    id={formField.id}
                    type="text"
                    {...(formField.additionalAttributes || {})}
                    label={
                      <span className="text-bold">
                        {formField.label}{' '}
                        {formField.required && <RequiredFieldIndicator />}
                      </span>
                    }
                    errorMessage={
                      errors && errors[formField.name]
                        ? errors[formField.name].message
                        : null
                    }
                    aria-invalid={
                      errors && errors[formField.name] ? 'true' : 'false'
                    }
                  />
                )}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ReservationStartFields;
