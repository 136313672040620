import React from 'react';
import _ from 'lodash';
import { useMutation } from '@apollo/client';
import { Icon, ButtonDropdown } from '@gsa/afp-component-library';
import { CREATE_SUPPORTING_DOC } from '../../services/data-layer';
import { getRegistrationPDFPayload } from './helpers/payload-constructors';
import { setWindowLocation } from './helpers/utils';
import { isFeatureEnabled } from '../../utilities/feature-toggle';
import { determineRegistrationStatus } from '../vehicle-registration-status-tag';
import useCanPerformActions from '../../hooks/use-can-perform-actions';

const RegistrationActions = ({ vehicle }) => {
  let registrationStatus = determineRegistrationStatus(vehicle);

  const canPerformActions = useCanPerformActions();
  const canRegisterVehicle = canPerformActions.canRegister(vehicle);

  const [createSupportingDoc] = useMutation(CREATE_SUPPORTING_DOC, {
    fetchPolicy: 'no-cache',
  });

  const createPDF = () => {
    const payload = getRegistrationPDFPayload(vehicle);
    return createSupportingDoc({
      variables: {
        data: payload,
        model: 'Vehicle',
        modelPK: vehicle.id,
        documentName: 'registration',
      },
    });
  };

  const actionTrigger = (trigger) => {
    if (_.isFunction(trigger)) {
      trigger();
    }
  };

  const registeredActions = [
    {
      label: 'Download registration card',
      iconName: 'file_download',
      trigger: async () => {
        const response = await createPDF();
        setWindowLocation(response?.data?.createSupportingDoc);
      },
    },
  ];

  const unregisteredActions = canRegisterVehicle
    ? [
        {
          label: 'Register vehicle',
          iconName: 'check',
          anchorLink: `/vehicle-registration/${vehicle.id}`,
        },
      ]
    : [];

  let ACTIONS_MAPPING = isFeatureEnabled('vehicle-reg-details-history')
    ? [
        {
          label: 'View vehicle history',
          iconName: 'calendar_today',
          anchorLink: '#vehicle-history',
        },
      ]
    : [];

  // Checking vehicle status accommodates legacy data
  const isMissingOrSold =
    vehicle?.itemInventoryStatusCode === 'MS' ||
    vehicle?.itemInventoryStatusCode === 'SD';
  const isInvalid =
    vehicle?.inventoryStatus?.id === '85' ||
    vehicle?.inventoryStatus?.id === 'IV';
  if (vehicle) {
    if (
      vehicle?.registered &&
      !isMissingOrSold &&
      registrationStatus !== 'Unregistered' &&
      registrationStatus !== 'Incomplete'
    ) {
      ACTIONS_MAPPING = [...ACTIONS_MAPPING, ...registeredActions];
    } else if (!isMissingOrSold) {
      ACTIONS_MAPPING = [...ACTIONS_MAPPING, ...unregisteredActions];
    }
  }

  return (
    <div className="margin-bottom-2 display-flex flex-justify-end">
      {!isInvalid && ACTIONS_MAPPING.length > 0 && (
        <ButtonDropdown
          label="Registration Actions"
          variant="primary"
          side="right"
        >
          {_.map(ACTIONS_MAPPING, (action, index) => {
            if (_.get(action, 'hidden')) {
              return null;
            }
            return (
              <div key={`registration-action-${index}`}>
                <div className="display-flex flex-align-center width-full height-6 text-no-underline text-ink hover:text-no-underline hover:text-ink text-no-wrap min-width-100p">
                  <Icon
                    iconName={_.get(action, 'iconName')}
                    className="margin-right-2 margin-left-1 text-bottom text-base"
                  />
                  <a
                    className="text-no-underline margin-right-1"
                    aria-label={_.get(action, 'label')}
                    role="link"
                    href={
                      _.get(action, 'anchorLink')
                        ? _.get(action, 'anchorLink')
                        : '#'
                    }
                    onClick={() =>
                      _.get(action, 'trigger')
                        ? actionTrigger(_.get(action, 'trigger'))
                        : undefined
                    }
                  >
                    {_.get(action, 'label')}
                  </a>
                </div>
                <div className="width-full border-bottom border-base-lighter" />
              </div>
            );
          })}
        </ButtonDropdown>
      )}
    </div>
  );
};

export default RegistrationActions;
