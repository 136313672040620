import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Button,
  connectModal,
  Modal,
  useModal,
  Alert,
} from '@gsa/afp-component-library';

const ReconcileModal = ({
  closeModal,
  onSubmit,
  reconciliationPayload,
  setReconciliationPayload,
}) => {
  const { tagNumber } = reconciliationPayload;
  return (
    <Modal
      id="delete-service-line-modal"
      className="width-tablet"
      variant="large"
      title={<h2>Update license plate to &ldquo;received&rdquo;</h2>}
      onClose={() => {
        closeModal();
        setReconciliationPayload({});
      }}
      actions={
        <>
          <Button
            variant="unstyled"
            onClick={() => {
              setReconciliationPayload({});
              closeModal();
            }}
            label="Cancel"
          />
          <Button
            className="margin-left-2"
            onClick={onSubmit}
            disabled={reconciliationPayload?.loading}
            label={`Update license plate to "received"`}
          />
        </>
      }
    >
      <div
        data-testid="reconcile-tag-modal-mody"
        className="padding-bottom-3 padding-top-1"
      >
        You&apos;re about to reconcile license plate{' '}
        <strong>{tagNumber}</strong>. The status of{' '}
        <strong>&ldquo;received&rdquo;</strong> will be applied to both A and B
        plates (if a 2-plate set).
      </div>
    </Modal>
  );
};

ReconcileModal.defaultProps = {
  reconciliationPayload: {},
};

ReconcileModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  reconciliationPayload: PropTypes.shape({
    expirationDate: PropTypes.number,
    tagNumber: PropTypes.string,
    loading: PropTypes.bool,
    reconciledTags: PropTypes.arrayOf(Object),
  }),
  setReconciliationPayload: PropTypes.func.isRequired,
};

export default function LpReconciliation({
  reconcileTag,
  reconciliationAlert,
  reconciliationPayload,
  setReconciliationPayload,
}) {
  const { isOpen, openModal, closeModal } = useModal();

  useEffect(() => {
    if (_.get(reconciliationPayload, 'tagNumber')) {
      openModal();
    }
    if (
      _.get(reconciliationPayload, 'reconciledTags') ||
      _.get(reconciliationPayload, 'error')
    ) {
      closeModal();
    }
  }, [reconciliationPayload]);

  useEffect(() => {
    if (!_.isEmpty(reconciliationAlert)) {
      const lpAlert = document.getElementById('lpReconcileAlert');
      if (lpAlert) {
        lpAlert.scrollIntoView({
          behavior: 'smooth',
        });
        lpAlert.focus();
      }
    }
  }, [reconciliationAlert]);

  const reconcileLp = async () => {
    setReconciliationPayload({
      ...reconciliationPayload,
      loading: true,
    });
    reconcileTag({
      variables: {
        tagNumber: _.get(reconciliationPayload, 'tagNumber'),
        tagExpirationDate: _.get(reconciliationPayload, 'expirationDate'),
      },
    });
  };

  const ReconcileTagModal = connectModal(() => (
    <ReconcileModal
      closeModal={closeModal}
      onSubmit={reconcileLp}
      reconciliationPayload={reconciliationPayload}
      setReconciliationPayload={setReconciliationPayload}
    />
  ));

  return (
    <>
      {!_.isEmpty(reconciliationAlert) && (
        <Alert
          heading={_.get(reconciliationAlert, 'heading')}
          type={_.get(reconciliationAlert, 'type')}
          slim
          id="lpReconcileAlert"
          tabIndex="0"
        >
          {_.get(reconciliationAlert, 'message')}
        </Alert>
      )}
      <ReconcileTagModal isOpen={isOpen} closeModal={closeModal} />
    </>
  );
}

LpReconciliation.defaultProps = {
  reconciliationPayload: {},
  reconciliationAlert: {},
};

LpReconciliation.propTypes = {
  reconciliationPayload: PropTypes.shape({
    expirationDate: PropTypes.number,
    tagNumber: PropTypes.string,
    loading: PropTypes.bool,
    reconciledTags: PropTypes.arrayOf(Object),
  }),
  setReconciliationPayload: PropTypes.func.isRequired,
  reconcileTag: PropTypes.func.isRequired,
  reconciliationAlert: PropTypes.shape({
    message: PropTypes.shape(Object),
    type: PropTypes.string,
  }),
};
