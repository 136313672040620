import React, { useEffect } from 'react';
import { Button, Modal } from '@gsa/afp-component-library';
import { useOrder } from '../context';

export default function OrderTagDamagedModal() {
  const {
    isDamagedModalOpen,
    closeDamagedModal,
    reportDamageTag,
    tag,
    setTag,
    loadingDamagedTag,
  } = useOrder();

  useEffect(() => {
    if (!isDamagedModalOpen) {
      closeDamagedModal();
      setTag(null);
    }
  }, [isDamagedModalOpen]);

  if (!isDamagedModalOpen) {
    return null;
  }

  const reportTagDamaged = () => {
    if (tag) {
      const variables = {
        tagNumber: tag.tagNumber,
        tagExpirationDate: tag.tagExpirationDate,
      };
      reportDamageTag({
        variables,
      });
    }
  };

  return (
    <div className="afp-modal-wrapper">
      <div className="afp-modal-overlay">
        <Modal
          variant="large"
          title={<h2>Report license plate as damaged</h2>}
          onClose={closeDamagedModal}
          actions={
            <>
              <Button
                data-testid="cancel-damaged"
                variant="unstyled"
                className="margin-right-2"
                onClick={closeDamagedModal}
                label="Cancel"
              />
              <Button
                data-testid="report-damaged-plate"
                disabled={loadingDamagedTag}
                onClick={reportTagDamaged}
                label={`Change status to "missing"`}
              />
            </>
          }
        >
          <p>
            You are about to report license plate{' '}
            <span className="text-bold" data-testid="damaged-tag">
              {tag?.tagNumber}
            </span>{' '}
            as damaged and the status of{' '}
            <span className="text-bold">missing</span> will be applied to both A
            and B plates.
          </p>
        </Modal>
      </div>
    </div>
  );
}
