import React, { useState, useEffect, useMemo } from 'react';
import { Portal, Modal } from '@gsa/afp-component-library';
import FocusTrap from 'focus-trap-react';
import './portal-modal.css';

export default function PortalModal() {
  const [portalEl] = useState(document.getElementById('portal'));
  const [activeEl, setActiveEl] = useState(null);
  const [modalState, setModalState] = useState(false);

  const openModal = (refocus) => {
    if (refocus) setActiveEl(document.activeElement);
    setModalState(true);
  };

  const closeModal = () => {
    setModalState(false);
  };

  useEffect(() => {
    if (!modalState && activeEl) activeEl.focus();
  }, [modalState]);

  const PModal = useMemo(
    () =>
      ({
        children,
        actions,
        title,
        hideClose,
        onClose,
        showAlert,
        alert,
        ...props
      }) => {
        if (modalState) {
          return (
            <Portal portalEl={portalEl}>
              <div className="lp-modal-backdrop display-flex flex-align-center position-fixed left-0 top-0 right-0 bottom-0 z-100">
                <FocusTrap
                  focusTrapOptions={{
                    escapeDeactivates: false,
                  }}
                >
                  <div style={{ display: 'contents' }}>
                    <Modal
                      variant="large"
                      title={title}
                      actions={actions}
                      onClose={() => {
                        onClose && onClose();
                        closeModal();
                      }}
                      hideClose={hideClose}
                      showAlert={!!showAlert}
                      alert={alert}
                      {...props}
                    >
                      {children}
                    </Modal>
                  </div>
                </FocusTrap>
              </div>
            </Portal>
          );
        }
        return null;
      },
    [modalState],
  );

  return [PModal, openModal, closeModal, modalState];
}
