/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import {
  RequiredFieldIndicator,
  ErrorMessage,
  Accordion,
} from '@gsa/afp-component-library';
import { isEmpty } from 'lodash';
import {
  getItems,
  defaultState,
  sessionMap,
} from './custom-inventory-fields-config';
import { CustomInventoryDateFilters } from './custom-inventory-date-filters';

// eslint-disable-next-line react/prop-types
export const CustomInventoryFieldsSelection = ({
  control,
  formState,
  clearErrors,
  setValue,
}) => {
  const [state, setState] = useState(defaultState);
  useEffect(() => {
    setValue('section_options', state);
  }, [state]);

  const [watchedSectionOptions] = useWatch({
    name: ['section_options'],
    control,
  });

  useEffect(() => {
    if (watchedSectionOptions) {
      const selectedSectionOptions = { ...watchedSectionOptions };
      const formattedSectionOptions = {};
      Object.keys(selectedSectionOptions).forEach((section) => {
        if (!Array.isArray(selectedSectionOptions[section])) return;
        formattedSectionOptions[section] = {
          isOpen: true,
          checkedList: selectedSectionOptions[section],
        };
      });
      if (!isEmpty(formattedSectionOptions)) {
        setState({ ...defaultState, ...formattedSectionOptions });
      } else {
        setState({ ...defaultState, ...watchedSectionOptions });
      }
    }
  }, []);

  const checkStateChange = ({ optValue, checked, section }) => {
    let checkedListValue = [...state[section].checkedList];
    if (checked) {
      checkedListValue.push(optValue);
    } else {
      // uncheck checked all checkbox
      checkedListValue = checkedListValue.filter((value) => value !== optValue);
    }

    return checkedListValue;
  };

  const items = getItems({
    setState,
    state,
    checkStateChange,
    clearErrors,
    defaultState,
    sessionMap,
  });

  return (
    <section className="margin-bottom-6">
      <CustomInventoryDateFilters control={control} setValue={setValue} />
      <h2 className="text-primary margin-bottom-1">Report options</h2>
      {formState?.errors?.section_options && (
        <ErrorMessage>
          {formState?.errors?.section_options.message}
        </ErrorMessage>
      )}{' '}
      <div className="usa-form-group mobile-lg:grid-col-10">
        <span>
          At least one selection must be made to generate a customized inventory
          report. To remove any item from your report, deselect the
          corresponding vehicle data fields.
        </span>

        <div className="margin-top-6 margin-bottom-6">
          <b>
            The select all box is the default setting for the vehicle
            information data fields listed below. To remove any vehicle
            information from your customized inventory report that you do not
            need, deselect the corresponding vehicle information data fields.
          </b>{' '}
          <RequiredFieldIndicator />
        </div>

        <Controller
          control={control}
          name="section_options"
          defaultValue={state}
          rules={{
            validate: () =>
              Object.keys(state).flatMap((k) => state[k].checkedList).length >
                0 ||
              'To generate a customized inventory report you must have at least one  vehicle information data field selected. ',
          }}
          render={() => (
            <>
              <Accordion
                items={items}
                handleToggleFilter={(id) => {
                  if (!state[id].isOpen) {
                    setState({
                      ...state,
                      [`${id}`]: {
                        ...state[id],
                        isOpen: !state[id].isOpen,
                      },
                    });
                  } else {
                    setState({
                      ...state,
                      [`${id}`]: {
                        ...state[id],
                        isOpen: !state[id].isOpen,
                      },
                    });
                  }
                }}
              />
            </>
          )}
        />
      </div>
    </section>
  );
};
