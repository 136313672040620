import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@gsa/afp-component-library';

export const AccessoriesMultiSelect = ({
  options,
  onChange,
  selectedValues,
}) => {
  const checkStateChange = (opt, ev) => {
    onChange(opt.value, ev.target.checked);
  };

  const getClass = (opt) => {
    if (Boolean(selectedValues.find((el) => el === opt.value)))
      return 'border-accent-cool-dark bg-accent-cool-lighter';
    return 'border-base-light';
  };

  return (
    <>
      {options.map((opt) => (
        <Checkbox
          className={`width-mobile border radius-md margin-y-2 padding-top-2px padding-bottom-1 padding-x-1 ${getClass(
            opt,
          )}`}
          name={opt.value}
          key={opt.value}
          label={opt.label}
          onChange={(ev) => checkStateChange(opt, ev)}
          checked={Boolean(selectedValues.find((el) => el === opt.value))}
        />
      ))}
    </>
  );
};

AccessoriesMultiSelect.propTypes = {
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  selectedValues: PropTypes.array.isRequired,
};
