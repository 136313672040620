import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './license-plate-destruction.css';
import { Button, SelectDropdown, TextInput } from '@gsa/afp-component-library';
import { useLazyQuery, useQuery } from '@apollo/client';
import { useCurrentUser } from '@gsa/afp-shared-ui-utils';
import NextButton from '../common/next-button';
import PrevButton from '../common/prev-button';
import {
  GET_AGENCY,
  GET_OFFICES_NEXT,
  GET_CURRENT_USER,
} from '../../services/data-layer';
import OrderDetail from '../orders/widgets/table-widgets/detail-row';
import { constructAddress } from '../orders/widgets/table-widgets/order-details';
import OrderPocEdit from '../order-details/widgets/order-poc-edit';
import { convertToFormUser } from './helpers/destruction-helper';
import { useLicensePlateDestruction } from './context';
import { DEFAULT_SELECT_OPTION_LABEL } from '../../utilities/consts';
import { sortBy } from 'lodash';

function StepDestructionAgencyPoc({ setCurrentStep, setShowAlert }) {
  const { currentUser } = useCurrentUser();
  const [editPoc, setEditPoc] = useState(false);
  const [officesDropdown, setOfficesDropdown] = useState([]);
  const { destructionInitData, setDestructionInitData } =
    useLicensePlateDestruction();

  const [getUserData, { data: userData }] = useLazyQuery(GET_CURRENT_USER, {
    fetchPolicy: 'network-only',
  });

  const [getAgencyData, { data: agencyData }] = useLazyQuery(GET_AGENCY, {
    fetchPolicy: 'network-only',
  });

  const { data: allOfficesNext } = useQuery(GET_OFFICES_NEXT, {
    variables: {
      agencyCode: currentUser.customerAttrs?.registeredAgencyCode,
      bureauCode: currentUser.customerAttrs?.registeredBureauCode,
    },
    fetchPolicy: 'network-only',
  });

  const onChangeOffice = (event) => {
    const foundOffice = officesDropdown.find(
      (office) => event.target.value === office.value,
    );
    setDestructionInitData({
      ...destructionInitData,
      officeName: foundOffice?.label,
      officeId: foundOffice?.value,
    });
  };

  const setOfficeOption = () => {
    const officeOptions = sortBy(
      allOfficesNext.getOfficesNext,
      'officeCode',
    ).map((opt) => {
      return {
        value: opt.officeCode,
        label: `${opt.officeCode} - ${opt.officeName}`,
      };
    });

    setOfficesDropdown([
      {
        value: '',
        label: DEFAULT_SELECT_OPTION_LABEL,
        defaultValue: true,
        disabled: false,
      },
      ...officeOptions,
    ]);

    return officeOptions;
  };

  useEffect(() => {
    if (currentUser?.id) {
      getUserData();

      if (currentUser.customerAttrs?.registeredAgencyCode) {
        getAgencyData({
          variables: {
            id: currentUser.customerAttrs?.registeredAgencyCode,
          },
        });
      }
    }
  }, []);

  useEffect(() => {
    if (
      agencyData?.getAgency &&
      userData?.me &&
      allOfficesNext &&
      allOfficesNext.getOfficesNext
    ) {
      const userInfo = {
        ...convertToFormUser(userData.me),
        ...destructionInitData,
      };
      const officeOptions = setOfficeOption();
      const foundBureau = agencyData.getAgency?.bureaus.find((bureau) => {
        return bureau.id === currentUser.customerAttrs?.registeredBureauCode;
      });
      const foundOffice = officeOptions.find(
        (office) =>
          currentUser.customerAttrs?.registeredOfficeCode === office.value ||
          destructionInitData?.officeId === office.value,
      );

      setDestructionInitData({
        ...destructionInitData,
        ...userInfo,
        agencyName: agencyData.getAgency.name,
        agencyId: agencyData.getAgency.id,
        bureauName: foundBureau?.name,
        bureauId: foundBureau?.id,
        officeName: foundOffice?.label,
        officeId: foundOffice?.value,
      });
    }
  }, [agencyData, userData, allOfficesNext]);

  return (
    <div data-testid="destruction-step2" className="destruction-step">
      <h2 className="margin-bottom-0">Agency Information</h2>
      <p>Please review the agency information to ensure accuracy.</p>
      <TextInput
        data-testid="user-agency"
        name="userAgency"
        type="text"
        label="Agency"
        containerClassName="disabled-input"
        value={`${
          destructionInitData?.agencyId ? destructionInitData.agencyId : '- '
        } - ${
          destructionInitData?.agencyName
            ? destructionInitData.agencyName
            : ' -'
        }`}
        disabled
        required
      />
      <TextInput
        data-testid="user-bureau"
        name="userBureau"
        type="text"
        label="Bureau"
        containerClassName="disabled-input"
        value={`${
          destructionInitData?.bureauId ? destructionInitData.bureauId : '- '
        } - ${
          destructionInitData?.bureauName
            ? destructionInitData.bureauName
            : ' -'
        }`}
        disabled
        required
      />
      <SelectDropdown
        id="user-office"
        name="userOffice"
        label="Office"
        onChange={onChangeOffice}
        options={officesDropdown}
        value={destructionInitData.officeId}
      />
      <br />
      <br />
      <h2 className="margin-bottom-0">Point of contact information</h2>
      <div className="grid-row grid-gap">
        <div className="grid-col-6" data-testid="poc-info">
          <p>
            Please review the point of contact to ensure accuracy. You can
            update the information for this point of contact or change to
            different point of contact.
          </p>
          <br />
          <div className="bg-gray-3 padding-3">
            <OrderDetail
              label="Email"
              value={
                <a
                  aria-label="mailto"
                  href={`mailto:${destructionInitData?.pocEmail}`}
                >
                  {destructionInitData?.pocEmail}
                </a>
              }
            />
            <OrderDetail label="Name" value={destructionInitData?.pocName} />
            <OrderDetail
              label="Address"
              value={constructAddress(destructionInitData)}
            />
            <OrderDetail label="Phone" value={destructionInitData?.pocPhone} />
            <Button
              data-testid="poc-edit"
              onClick={() => {
                setEditPoc(true);
              }}
              variant="outline"
              className="bg-white margin-top-2"
              aria-label="edit contact"
              label="Edit"
            />
            {destructionInitData && (
              <OrderPocEdit
                poc={destructionInitData}
                disableChange
                onClose={() => {
                  setEditPoc(false);
                }}
                onSave={(updatedPoc) => {
                  setEditPoc(false);
                  setDestructionInitData(updatedPoc);
                }}
                editing={editPoc}
              />
            )}
          </div>
        </div>
      </div>
      <br />
      <div className="grid-row margin-bottom-6 margin-top-6">
        <PrevButton onClick={() => setCurrentStep(1)} />
        <NextButton
          data-testid="btn-next-step-3"
          onClick={() => {
            setShowAlert({});
            setCurrentStep(3);
          }}
        />
      </div>
    </div>
  );
}

StepDestructionAgencyPoc.defaultProps = {
  setCurrentStep: undefined,
};

StepDestructionAgencyPoc.propTypes = {
  setCurrentStep: PropTypes.func,
};

export default StepDestructionAgencyPoc;
