import vehicleFilters, {
  vehicleWithNestedFilters,
} from '../filter-structures/vehicle';
import general, { generalNestedFilter } from '../filter-structures/general';
import VehiclesBureauFilterItem from '../custom-filters/vehicles-bureau-filter-item';

export default function afm() {
  return [
    vehicleFilters.vehicleLicensePlateTypeahead,
    vehicleFilters.vehicleVINTypeahead,
    { ...general.bureau, component: VehiclesBureauFilterItem },
    vehicleFilters.vehiclePoCTypeahead,
    vehicleFilters.customerAccount,
    vehicleFilters.vehicleMakeModel,
    vehicleFilters.vehicleOwnership,
    vehicleFilters.registrationStaus,
  ];
}

export const afmVehicleNestedFilters = () => {
  return [
    vehicleWithNestedFilters.vehicleLicensePlateTypeahead,
    vehicleWithNestedFilters.vehicleVINTypeahead,
    vehicleWithNestedFilters.fsr,
    vehicleWithNestedFilters.agency,
    vehicleWithNestedFilters.vehiclePoCTypeahead,
    vehicleWithNestedFilters.customerAccount,
    vehicleWithNestedFilters.vehicleMakeModel,
    vehicleWithNestedFilters.vehicleOwnership,
    vehicleWithNestedFilters.vehicleLifecycleStatus,
    vehicleWithNestedFilters.registrationStaus,
  ];
};
