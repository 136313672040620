/* eslint-disable react/prop-types */
import React from 'react';
import { Button } from '@gsa/afp-component-library';

export default function VerifyButton({
  nhtsaVerified,
  vin,
  vinSource,
  internationalVin,
  pre1981,
  unverifiedVin,
  verifyVin,
}) {
  const handleVerify = () => {
    verifyVin(vin);
  };

  if (
    !nhtsaVerified &&
    !internationalVin &&
    !pre1981 &&
    !unverifiedVin &&
    vin &&
    !vinSource
  ) {
    return (
      <Button
        type="button"
        data-testid="validatevin"
        className="margin-left-3"
        variant="outline"
        onClick={handleVerify}
        label="Validate VIN"
      />
    );
  }
  return null;
}
