import React, { useEffect, useState } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { useLazyQuery } from '@apollo/client';
import { SelectDropdown, Spinner } from '@gsa/afp-component-library';
import {
  GET_BUREAUS_BY_PERMISSION,
  GET_OFFICES_BY_PERMISSION,
} from 'services/data-layer';
import { Redirect } from 'react-router-dom';
import { VMSOperations, VMSSubjects } from 'utilities/consts';
import { DEFAULT_OPTION } from '../config/helpers';
import useDataController from '../use-data-controller';
import { HiddenField } from '../components/hidden-field';

// remove all agencies option from the options
const getAgencyBureauOptions = (prefixedName, items = []) => {
  return items?.map((item) => ({
    value: item.id,
    label: prefixedName ? item.prefixedName : item.name,
  }))?.filter((i) => i.value !== 'ALL' && i.value !== 'N/A');
};

const getOfficeOptions = (items = []) =>
  items?.map((item) => ({
    value: item.officeCode,
    label: item.prefixedName,
  }));

const useFetchOptions = (optionQuery) => {
  const [getOptions, { data }] = useLazyQuery(optionQuery, {
    fetchPolicy: 'no-cache',
    onError(err) {
      // eslint-disable-next-line no-console
      console.warn(err);
    },
  });
  return [getOptions, data];
};

// eslint-disable-next-line react/prop-types
export const AgencySelection = ({ control, setValue, title, register }) => {
  const [agencies, setAgencies] = useState();
  const [internalError, setInternalError] = useState();
  const { query, loading } = useDataController({
    setInternalError,
    setAgencies,
  });

  const [agency, bureau, vehicleOptions] = useWatch({
    name: ['agency', 'bureau', 'vehicle_options'],
    control,
  });

  // verify opt-in permission
  useEffect(() => {
    query.agenciesQuery({
      variables: {
        operation:
          vehicleOptions === 'GF'
            ? VMSOperations.UPDATE_VEHICLE_GF
            : VMSOperations.Update,
        subject: VMSSubjects.VEHICLE,
        feature: vehicleOptions === 'GF' ? undefined : 'Basic',
        order: 'id ASC',
      },
    });
  }, []);

  const isAgencySelected = agency !== DEFAULT_OPTION.value;
  const isBureauSelected = bureau !== DEFAULT_OPTION.value;

  const [getBureaus, bureaus] = useFetchOptions(GET_BUREAUS_BY_PERMISSION);
  const [getOffices, offices] = useFetchOptions(GET_OFFICES_BY_PERMISSION);

  useEffect(() => {
    if (agency && agency !== DEFAULT_OPTION.value) {
      getBureaus({
        variables: {
          subject: VMSSubjects.VEHICLE,
          operation:
            vehicleOptions === 'GF'
              ? VMSOperations.UPDATE_VEHICLE_GF
              : VMSOperations.Update,
          agencyCode: agency,
          order: 'id ASC',
          feature: vehicleOptions === 'GF' ? undefined : 'Basic',
        },
      });
    }
  }, [agency]);

  useEffect(() => {
    if (bureau && bureau !== DEFAULT_OPTION.value && vehicleOptions !== 'GF') {
      getOffices({
        variables: {
          agencyCode: agency,
          bureauCode: bureau,
          order: 'officeCode ASC',
          operation: VMSOperations.Update,
          subject: VMSSubjects.VEHICLE,
        },
      });
    }
  }, [bureau]);

  if (agencies?.length === 0 && !internalError) {
    return <Redirect to="/unauthorized" />;
  }

  if (loading.agenciesQueryLoading) {
    return <Spinner size="small" data-testid="agencies-query-spinner" />;
  }

  return (
    <section className="margin-bottom-3">
      <h2 className="text-primary margin-bottom-1">{title || ''}</h2>
      <p>
        Records in the report will only include those that match the scope
        selected below.
      </p>
      <Controller
        control={control}
        name="agency"
        defaultValue={DEFAULT_OPTION.value}
        rules={{
          validate: (value) =>
            DEFAULT_OPTION.value !== value || 'This is a required field',
        }}
        render={({
          field: { ref, onChange, ...rest },
          formState: { errors },
        }) => (
          <SelectDropdown
            id="agency"
            data-testid="agency"
            name="agency"
            label={<b>Agency</b>}
            required
            options={[
              DEFAULT_OPTION,
              ...getAgencyBureauOptions(false, agencies),
            ]}
            errorMessage={errors.agency?.message}
            onChange={(e) => {
              setValue('bureau', DEFAULT_OPTION.value);
              setValue('office', DEFAULT_OPTION.value);
              onChange(e);
            }}
            {...rest}
          />
        )}
      />
      <Controller
        control={control}
        name="bureau"
        defaultValue={DEFAULT_OPTION.value}
        render={({ field: { ref, onChange, ...rest } }) => (
          <SelectDropdown
            id="bureau"
            data-testid="bureau"
            name="bureau"
            label={<b>Bureau</b>}
            options={[
              DEFAULT_OPTION,
              ...getAgencyBureauOptions(
                true,
                isAgencySelected ? bureaus?.getBureausByPermission : [],
              ),
            ]}
            onChange={(e) => {
              setValue('office', DEFAULT_OPTION.value);
              onChange(e);
            }}
            {...rest}
          />
        )}
      />
      {vehicleOptions !== 'GF' ? (
        <Controller
          control={control}
          name="office"
          defaultValue={DEFAULT_OPTION.value}
          render={({ field: { ref, ...rest } }) => (
            <SelectDropdown
              id="office"
              data-testid="office"
              name="office"
              label={<b>Office</b>}
              options={[
                DEFAULT_OPTION,
                ...getOfficeOptions(
                  isBureauSelected ? offices?.getOfficesByPermission : [],
                ),
              ]}
              {...rest}
            />
          )}
        />
      ) : (
        <HiddenField register={register} name="office" />
      )}
    </section>
  );
};
