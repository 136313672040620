/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Button, Modal } from '@gsa/afp-component-library';
import PocLookup from './poc-lookup';
import PocForm from './poc-form';
import { useCustomerAccounts } from '../providers/customer-account-provider';

const title = <h2>Point of contact</h2>;

const PocModalAction = ({ closeModal, deleteAndClose, mode, contact }) => {
  return (
    <>
      <Button
        variant="unstyled"
        className="margin-right-2"
        onClick={closeModal}
        data-testid="customer-account-add-cancel-button"
        label="Cancel"
      />
      {mode === 'delete' && (
        <Button
          variant="secondary"
          className="margin-right-2"
          onClick={() => deleteAndClose(contact)}
          data-testid="customer-account-delete-button"
          label="Remove contact"
        />
      )}
    </>
  );
};

const PocModal = ({
  show = false,
  mode,
  closeModal,
  saveAndClose,
  intCallCodes,
  contact,
  deleteAndClose,
}) => {
  const [showPocForm, setShowPocForm] = useState(false);
  const [pocFormMode, setPocFormMode] = useState(mode);
  const [selectedContact, setSelectedContact] = useState(null);
  const { dispatchAction } = useCustomerAccounts();

  useEffect(() => {
    if (contact) {
      setSelectedContact(contact);
      if (mode === 'edit') {
        setShowPocForm(true);
        setPocFormMode(mode);
      }
    }
  }, [contact, mode]);

  const handleCloseModal = () => {
    closeModal();
    setShowPocForm(false);
    setSelectedContact(null);
    setPocFormMode('');
  };

  const handleSaveAndClose = (value) => {
    saveAndClose(value);
    handleCloseModal();
  };
  const handlePocActions = (action, data) => {
    switch (action) {
      case 'ADD_CONTACT_TO_ACCOUNT':
        setPocFormMode('');
        handleSaveAndClose(data);
        dispatchAction('SET_ALERT_MESSAGE', {
          type: 'success',
          message: `You have added ${data.pocFirstName} ${data.pocLastName} as point of contact. Changes will be persisted on submitting customer account form.`,
          location: 'poc_section',
        });
        break;
      case 'EDIT_CONTACT':
        setSelectedContact(data);
        setShowPocForm(true);
        setPocFormMode('edit');
        break;
      case 'ADD_NEW_CONTACT':
        setSelectedContact(data);
        setPocFormMode('add');
        setShowPocForm(true);
        break;
      default:
        break;
    }
  };

  if (!show) {
    return null;
  }

  return (
    <div className="afp-modal-wrapper">
      <div className="afp-modal-overlay">
        <Modal
          title={title}
          actions={
            <PocModalAction
              closeModal={handleCloseModal}
              deleteAndClose={deleteAndClose}
              mode={mode}
              contact={contact}
            />
          }
          onClose={handleCloseModal}
          variant="large"
        >
          {!showPocForm && mode === 'add' && (
            <PocLookup handlePocLookupActions={handlePocActions} />
          )}

          {mode === 'delete' && (
            <div className="grid-row padding-top-3">
              <h3>Remove point of contact</h3>
              <p>
                Are you sure you want to remove{' '}
                <b>
                  {contact.pocFirstName} {contact.pocLastName}
                </b>{' '}
                from this account&apos;s point of contact list?{' '}
              </p>
            </div>
          )}

          {(pocFormMode === 'edit' || pocFormMode === 'add') &&
            mode !== 'delete' &&
            selectedContact && (
              <div className="grid-row padding-top-3">
                <div className="grid-col-12">
                  <PocForm
                    internationDialingCodes={intCallCodes}
                    mode={pocFormMode}
                    contact={selectedContact}
                    saveAndClose={handleSaveAndClose}
                    cancelForm={handleCloseModal}
                  />
                </div>
              </div>
            )}
        </Modal>
      </div>
    </div>
  );
};

export default PocModal;
