import React from 'react';
import { Button } from '@gsa/afp-component-library';
import { usePmExpress } from './pm-express-provider';

export default function InvoiceUpload({
  openEditModal,
  row: { index, original },
  updateCellData,
}) {
  const { modifiedPms } = usePmExpress();

  const handleOpenModal = () => {
    // eslint-disable-next-line react/prop-types
    const selectedModiedPm = modifiedPms.find(
      (md) => md.assetPmId === original?.assetPmId,
    );

    const hasInvoice = !!selectedModiedPm?.invoiceData;

    updateCellData(index, 'currentPmDate', {
      value: selectedModiedPm?.currentPmDate,
      isDirty: true,
    });

    updateCellData(
      index,
      'currentPmMileage',
      {
        value: selectedModiedPm?.currentPmMileage,
        isDirty: true,
      },
      original,
      () => openEditModal(original),
    );

    if (hasInvoice) openEditModal(original);
  };

  return (
    <div className="margin-top-2">
      <Button
        className="pm-invoice"
        label={
          // eslint-disable-next-line react/prop-types
          original?.invoiceData?.fileType && original?.invoiceData?.docStoreUri
            ? 'Edit invoice'
            : 'Add invoice'
        }
        variant="outline"
        size="medium"
        onClick={handleOpenModal}
        data-testid="upload-pm-button"
      />
    </div>
  );
}
