import { gql } from '@apollo/client';

export const UPDATE_VEHICLE_LICENSE_PLATE = gql`
  mutation UpdateVehicleLicensePlate(
    $tagInput: UpdateVehicleLicensePlateInput!
  ) {
    updateVehicleLicensePlate(tagInput: $tagInput) {
      id
    }
  }
`;
