import React from 'react';
import { VMSOperations, VMSSubjects } from 'utilities/consts';
import AgencyFilterItem from 'components/user-filters/custom-filters/agency-filter-item';
import BureauFilterItem from 'components/user-filters/custom-filters/bureau-filter-item';
import OfficeFilterItem from 'components/user-filters/custom-filters/office-filter-item';

// eslint-disable-next-line react/prop-types
const CampaignsAgencyFilterItem = ({ filter }) => {
  return (
    <AgencyFilterItem
      filter={filter}
      operation={VMSOperations.VIEW_VEHICLE_GF}
      subject={VMSSubjects.VEHICLE}
    />
  );
};

// eslint-disable-next-line react/prop-types
const CampaignsBureauFilterItem = ({ filter }) => {
  return (
    <BureauFilterItem
      filter={filter}
      operation={VMSOperations.VIEW_VEHICLE_GF}
      subject={VMSSubjects.VEHICLE}
      agencyFilterPath="$assetRecalls.vehicle.customer.customer_agency_code$"
      bureauFilterPath="$assetRecalls.vehicle.customer.customer_bureau_code$"
    />
  );
};

// eslint-disable-next-line react/prop-types
const CampaignsOfficeFilterItem = ({ filter }) => {
  return (
    <OfficeFilterItem
      filter={filter}
      operation={VMSOperations.VIEW_VEHICLE_GF}
      subject={VMSSubjects.VEHICLE}
      agencyFilterPath="$assetRecalls.vehicle.customer.customer_agency_code$"
      bureauFilterPath="$assetRecalls.vehicle.customer.customer_bureau_code$"
      officeFilterPath="$assetRecalls.vehicle.customer.customer_office_code$"
    />
  );
};

const recallTypes = [
  { label: 'Safety recall campaign', value: 'Safety' },
  { label: 'Non-safety campaign', value: 'Non-Safety' },
  { label: 'Warranty campaign', value: 'Warranty' },
];

export const generateRecallCampaignsFilterStructure = ({
  fmcsWithZoneAndRegion,
  zones,
  initialFilters,
}) => {
  const filterStructure = [
    {
      title: 'Agency',
      key: 'agency-bureau-office',
      filters: [
        {
          key: '$assetRecalls.vehicle.customer.customer_agency_code$',
          title: 'Agency',
          component: CampaignsAgencyFilterItem,
          permanent: false,
          operator: '$exact',
          value: '',
        },
        {
          key: '$assetRecalls.vehicle.customer.customer_bureau_code$',
          title: 'Bureau',
          component: CampaignsBureauFilterItem,
          placeholder: '- Select bureau -',
          noResults: 'No bureaus found',
          permanent: false,
          operator: '$in',
        },
        {
          title: 'Office',
          key: '$assetRecalls.vehicle.customer.customer_office_code$',
          component: CampaignsOfficeFilterItem,
          placeholder: '- Select office -',
          noResults: 'No offices found',
          permanent: false,
          operator: '$in',
        },
      ],
    },
    {
      title: 'Ownership type',
      key: '$assetRecalls.vehicle.ownership_type_code$',
      type: 'multiselect',
      operator: '$exact',
      value: [],
      hideClear: true,
      options: [
        { value: 'GF', label: 'GSA Leased' },
        { value: 'AO', label: 'Agency Owned' },
      ],
    },
    {
      title: 'Fleet Management Center',
      key: 'filter-fmc',
      filters: [
        {
          key: '$assetRecalls.vehicle.customer.zone_id$',
          permanent: false,
          operator: '$exact',
          label: <div className="padding-top-0">Zone</div>,
          value: initialFilters.zone ? initialFilters.zone : '',
          type: 'select',
          options:
            zones?.length > 0
              ? [{ value: '', label: '- Select zone - ' }, ...zones]
              : [{ value: '', label: '- Select zone - ' }],
        },
        {
          key: '$assetRecalls.vehicle.fmc_id$',
          permanent: false,
          label: <div className="padding-top-2">FMC</div>,
          operator: '$exact',
          value: initialFilters.fmc ? initialFilters.fmc : '',
          type: 'select',
          options:
            fmcsWithZoneAndRegion?.length > 0
              ? [
                  { value: '', label: '- Select FMC - ' },
                  ...fmcsWithZoneAndRegion.map((fmc) => ({
                    value: fmc.centerId,
                    label: fmc.label,
                  })),
                ]
              : [{ value: '', label: '- Select FMC - ' }],
        },
        {
          ariaLabel: 'Legacy Customer Number',
          key: '$assetRecalls.vehicle.customer.legacy_customer_number$',
          type: 'text',
          permanent: false,
          operator: '$exact',
          hideClear: false,
          position: 'top',
          label: (
            <div className="padding-top-2 padding-bottom-1">
              Legacy Customer Number
            </div>
          ),
          showSearchButton: true,
        },
      ],
    },
    {
      title: 'Campaign ID',
      key: 'filter-manufacturer-campaign',
      filters: [
        {
          key: '$recall_code$',
          permanent: false,
          hideClear: true,
          operator: '$like',
          value: [],
          ariaLabel: 'Search by campaign ID',
          placeholder: 'Search by campaign ID',
          prefixIcon: '',
          type: 'typeahead',
          customFieldProps: {
            inputCharNum: 3,
            debounceDelay: 500,
            promptText: 'Search requires 3 characters',
            showNoResults: false,
            clearPanelFilterOnEmpty: true,
          },
        },

        {
          key: '$nhtsa_campaign_id$',
          permanent: false,

          operator: '$like',
          value: [],
          ariaLabel: 'Search by NHTSA recall ID',
          placeholder: 'Search by NHTSA recall ID',
          prefixIcon: '',
          type: 'typeahead',
          customFieldProps: {
            inputCharNum: 3,
            debounceDelay: 500,
            promptText: 'Search requires 3 characters',
            showNoResults: false,
            clearPanelFilterOnEmpty: true,
          },
        },
      ],
    },
    {
      title: 'FSR email',
      key: 'filter-poc-fsr',
      filters: [
        {
          key: '$assetRecalls.vehicle.fsr_user_email_address$',
          permanent: false,
          operator: '$like',
          value: [],
          ariaLabel: 'Search by FSR email',
          placeholder: 'Search by FSR email',
          prefixIcon: '',
          type: 'typeahead',
          id: 'placeholder_FSR',
          customFieldProps: {
            inputCharNum: 6,
            debounceDelay: 500,
            promptText: 'Search requires 6 characters',
            showNoResults: false,
            clearPanelFilterOnEmpty: true,
          },
        },
      ],
    },
    {
      title: 'Customer email',
      key: 'filter-poc-customer',
      filters: [
        {
          key: '$assetRecalls.vehicle.assetContactAssociationPrimary.pointOfContactPrimary.email_address$',
          permanent: false,
          operator: '$like',
          value: [],
          ariaLabel: 'Search by customer email',
          placeholder: 'Search by customer email',
          prefixIcon: '',
          type: 'typeahead',
          id: 'placeholder_FSR',
          customFieldProps: {
            inputCharNum: 6,
            debounceDelay: 500,
            promptText: 'Search requires 6 characters',
            showNoResults: false,
            clearPanelFilterOnEmpty: true,
          },
        },
      ],
    },
    {
      title: 'Remedy available',
      key: '$is_remedy_available$',
      type: 'radio',
      operator: '$exact',
      hideClear: true,
      options: [
        { value: '1', label: 'Yes' },
        { value: '0', label: 'No' },
      ],
    },

    {
      title: 'Stop drive',
      key: 'filter-should_stop_drive',
      filters: [
        {
          key: '$should_stop_drive$',
          type: 'radio',
          operator: '$exact',
          id: 'should_stop_drive',
          hideClear: true,
          options: [
            { value: '1', label: 'Yes' },
            { value: '0', label: 'No' },
          ],
        },
      ],
    },
    {
      title: 'Park outside',
      key: 'filter-should_park_outside',
      filters: [
        {
          key: '$should_park_outside$',
          type: 'radio',
          id: 'should_park_outside',
          operator: '$exact',
          hideClear: true,
          options: [
            { value: '1', label: 'Yes' },
            { value: '0', label: 'No' },
          ],
        },
      ],
    },
  ];

  return filterStructure;
};
